import React, { useState } from "react";
import { Grid, Box, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import classes from "../../Assets/Styles/projects.module.css";

const SearchProject = (props) => {
  const [focused, setFocused] = useState(false);

  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    props.setSearchText(lowerCase);
  };
  return (
    <Grid container alignItems={"center"} justifyContent={"center"} gap={1}>
      <Grid md={12} xs={12} className={classes["Search-for-Project"]}>
        Search for Project
      </Grid>
      <Grid md={12} xs={12} className={classes["search-hint"]}>
        Enter the project name or client name and press search icon
      </Grid>
      <Grid
        item
        md={2}
        container
        direction={"row-reverse"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box
          sx={{
            p: 0.5,
            pb: 0,
          }}
        >
          <TextField
            variant="standard"
            fullWidth
            value={props.searchText}
            onBlur={() => {
              setFocused(false);
            }}
            onFocus={() => {
              setFocused(true);
            }}
            onChange={inputHandler}
            placeholder="Search this list…"
            InputProps={{
              startAdornment: (
                <Grid
                  item
                  paddingTop={1}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={() => {
                    props.seacrhProjects();
                  }}
                >
                  <SearchIcon
                    fontSize="small"
                    htmlColor={focused ? " #0674b9" : "#707070"}
                    style={{ paddingLeft: 5 }}
                  />
                </Grid>
              ),
              endAdornment: (
                <>
                  <Grid
                    item
                    paddingTop={1}
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                    onClick={() => {
                      props.setSearchText("");
                    }}
                  >
                    <CloseRoundedIcon
                      fontSize="small"
                      htmlColor={focused ? "#707070" : "#ffff"}
                    />
                  </Grid>
                </>
              ),
            }}
            sx={{
              fontSize: "10px",
              fontFamily: "Muli",
              backgroundColor: "white",
              borderRadius: 1,

              m: (theme) => theme.spacing(1, 0.5, 1.5),
              "& .MuiSvgIcon-root": {
                mr: 0.5,
              },
              "& .MuiInput-underline:before": {
                borderBottom: 0,
                borderColor: "white",
              },
            }}
          />
        </Box>
      </Grid>
      {props.noProjects ? (
        <Grid md={12} xs={12} className={classes["search-hint"]}>
          No Projects Found
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default SearchProject;
