import React, { useState, useEffect } from "react";
import { Grid, Modal } from "@mui/material";
import classes from "../../../Assets/Styles/Activities.module.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "55%",
  bottom: "30%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#f2f2f2",
  overflowY: "scroll",
  height: "60%",
  outline: "none",
  border: 0,
};

const StartAndDurationsModal = (props) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    props.setOpenModal(false);
  };
  useEffect(() => {
    if (props.openModal) {
      handleOpen(true);
    }
  }, [props.openModal]);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
        disableScrollLock
        keepMounted
      >
        <Grid container sx={style} item xs={4} paddingTop={1}>
          <Grid
            item
            container
            xs={6}
            alignItems={"center"}
            direction={"column"}
            gap={2}
          >
            <Grid item className={classes["modalTitles"]}>
              Start Dates:
            </Grid>
            {props.previousStartDates?.map((date, index) => (
              <Grid item key={index} className={classes["Rectangle-1519"]}>
                {date.slice(0, 10)}
              </Grid>
            ))}
          </Grid>
          <Grid
            item
            container
            xs={6}
            alignItems={"center"}
            direction={"column"}
            gap={2}
          >
            <Grid item className={classes["modalTitles"]}>
              Durations:
            </Grid>
            {props.previousDurations?.map((duration, index) => (
              <Grid item key={index} className={classes["Rectangle-1519"]}>
                {duration}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

export default StartAndDurationsModal;
