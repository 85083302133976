import React, { useState, useEffect } from "react";
import { Grid, TextField, MenuItem } from "@mui/material";
import classing from "../../../../Assets/Styles/factory.module.css";
import Barcode from "react-barcode";
const HumanResources = (props) => {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [codeErrMessage, setCodeErrMessage] = useState("");
  const [codeError, setCodeError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    if (
      !props.humanResourcesObject.GroupPermissionId ||
      !props.humanResourcesObject.TeamMemberId ||
      !props.humanResourcesObject.ShiftId ||
      codeError
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });

  return (
    <Grid
      item
      container
      paddingTop={3}
      paddingLeft={3}
      paddingRight={3}
      bgcolor={"#fff"}
      gap={2}
      paddingBottom={1}
    >
      <Grid item xs={12} className={classing["Title"]}>
        Human Resource
      </Grid>
      <Grid item sm={3.5} xs={12}>
        <Grid item className={classing["TFTitle"]} paddingLeft={1}>
          *Code
        </Grid>
        <Grid item>
          <TextField
            disabled
            value={props.humanResourcesObject.Code}
            error={codeError}
            helperText={codeError ? codeErrMessage : ""}
            fullWidth
            InputProps={{
              style: { height: "28px", fontSize: 12, fontFamily: "Muli" },
            }}
            FormHelperTextProps={{
              style: { fontSize: 8, fontFamily: "Muli" },
            }}
            placeholder="Code"
            onBlur={(e) => {
              if (!e.target.value) {
                setCodeError(true);
                setCodeErrMessage("Enter a code ");
              }
            }}
            onChange={(e) => {
              props.humanResourcesObject.Code = e.target.value;
              if (!e.target.value) {
                setCodeError(true);
                setCodeErrMessage("Enter a Code");
              } else if (e.target.value.length > 10) {
                setCodeError(true);
                setCodeErrMessage("Code should be less than 10 characters");
              } else setCodeError(false);
              forceUpdate();
            }}
          />
        </Grid>
      </Grid>
      <Grid item sm={3} xs={12}>
        <Grid item className={classing["TFTitle"]} paddingLeft={1}>
          *Role Group
        </Grid>
        <Grid item>
          <TextField
            SelectProps={{ MenuProps: { disableScrollLock: true } }}
            value={props.humanResourcesObject.GroupPermissionId}
            fullWidth
            select
            InputProps={{
              style: { height: "28px", fontSize: 12, fontFamily: "Muli" },
            }}
            onChange={(e) => {
              props.humanResourcesObject.GroupPermissionId = e.target.value;

              forceUpdate();
            }}
          >
            <MenuItem value={0} key={0} disabled>
              Role Group
            </MenuItem>
            {props.roleGroups.map((r) => (
              <MenuItem value={r.Id} key={r.Id}>
                {r.Name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid item sm={4.5} xs={12}>
        {props.humanResourcesObject.GroupPermissionId ? (
          <>
            <Grid item className={classing["TFTitle"]} paddingLeft={1}>
              *Name
            </Grid>
            <Grid item>
              <TextField
                SelectProps={{ MenuProps: { disableScrollLock: true } }}
                value={props.humanResourcesObject.TeamMemberId}
                fullWidth
                select
                InputProps={{
                  style: { height: "28px", fontSize: 12, fontFamily: "Muli" },
                }}
                onChange={(e) => {
                  props.humanResourcesObject.TeamMemberId = e.target.value;
                  props.humanResourcesObject.Code = props.roleGroups
                    .find(
                      (x) =>
                        x.Id === props.humanResourcesObject.GroupPermissionId
                    )
                    ?.TeamMembers?.filter(
                      (x) => x.Id === e.target.value
                    )[0].Code;
                    props.humanResourcesObject.Barcode = props.roleGroups
                    .find(
                      (x) =>
                        x.Id === props.humanResourcesObject.GroupPermissionId
                    )
                    ?.TeamMembers?.filter(
                      (x) => x.Id === e.target.value
                    )[0].BarCode;
                  forceUpdate();
                }}
              >
                <MenuItem value={0} key={0} disabled>
                  Name
                </MenuItem>
                {props.roleGroups
                  .find(
                    (x) => x.Id === props.humanResourcesObject.GroupPermissionId
                  )
                  ?.TeamMembers?.map((r) => (
                    <MenuItem value={r.Id} key={r.Id}>
                      {r.Name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          </>
        ) : (
          ""
        )}
      </Grid>
      <Grid item sm={3} xs={12}>
        <Grid item className={classing["TFTitle"]} paddingLeft={1}>
          *Shift Code
        </Grid>
        <Grid item>
          <TextField
            SelectProps={{ MenuProps: { disableScrollLock: true } }}
            value={props.humanResourcesObject.ShiftId}
            fullWidth
            select
            InputProps={{
              style: { height: "28px", fontSize: 12, fontFamily: "Muli" },
            }}
            onChange={(e) => {
              props.humanResourcesObject.ShiftId = e.target.value;
              forceUpdate();
            }}
          >
            <MenuItem value={0} key={0} disabled>
              Shift Code
            </MenuItem>
            {props.shiftCodes.map((r) => (
              <MenuItem value={r.Id} key={r.Id}>
                {r.Name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      {props.humanResourcesObject.Barcode ? (
        <Grid item sm={4} xs={12}>
          <Grid item className={classing["TFTitle"]} paddingLeft={1}>
            *Barcode
          </Grid>
          <Barcode
            value={props.humanResourcesObject.Barcode}
            height={30}
            format={"CODE128"}
            font="Avenir Next"
            fontOptions="600"
            textMargin={4}
            margin={0}
          />
        </Grid>
      ) : (
        <></>
      )}

      <Grid item container direction={"row"} xs={12}>
        <Grid item container xs={6}>
          {props.humanResources.find(
            (x) => x.Id === props.humanResourcesObject.Id
          ) ? (
            <button
              className={classing["DeleteButton"]}
              onClick={() => {
                props.setHumanResources(
                  props.humanResources.filter(
                    (x) => x.Id !== props.humanResourcesObject.Id
                  )
                );
                props.setHumanResourcesObject(null);
                props.setResourceType(0);
                props.setHumanResourcesObjectChosen(0);
              }}
            >
              Delete
            </button>
          ) : (
            ""
          )}
        </Grid>
        <Grid item container xs={6} direction={"row-reverse"} gap={1}>
          <button
            className={classing["SaveBut"]}
            disabled={isDisabled}
            onClick={() => {
              const tobeReplaced = props.humanResources.find(
                (x) => x.Id === props.humanResourcesObject.Id
              );
              if (tobeReplaced) {
                props.humanResources.map((obj) => {
                  if (obj.Id === tobeReplaced.Id) {
                    obj = props.humanResourcesObject;
                  }
                });
              } else {
                props.setHumanResources(
                  props.humanResources.concat(props.humanResourcesObject)
                );
                props.setNbOfResources(props.nbOfResources + 1);
              }

              props.setHumanResourcesObject(null);
              props.setResourceType(0);
              props.setHumanResourcesObjectChosen(0);
            }}
          >
            {props.humanResources.find(
              (x) => x.Id === props.humanResourcesObject.Id
            )
              ? "Update resource"
              : "Add resource"}
          </button>
          <button
            className={classing["cancelbut"]}
            onClick={() => {
              props.setHumanResourcesObject(null);
              props.setResourceType(0);
              props.setHumanResourcesObjectChosen(0);
            }}
          >
            Cancel
          </button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HumanResources;
