import React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { Box, Grid } from "@mui/material";

import useWindowDimensions from "../../../../Components/Navbar/WindowDimension";

function DatePicker(props) {

  const { width } = useWindowDimensions();
  return (
    <Grid item container xs={12}>
      <Box   sx={{
          "& > div": {
            minWidth: 256,
            height: width > 1300 ? 650 : 290,
          },
          "& > div > div, & > div > div > div, & .MuiCalendarPicker-root": {
            width: 256
          },
          "& .MuiTypography-caption": {
            width: 30,
            margin: 0
          },
          "& .PrivatePickersSlideTransition-root": {
            minHeight: 30 * 6
          },
          '& .PrivatePickersSlideTransition-root [role="row"]': {
            margin: 0
          },
          "& .MuiPickersDay-dayWithMargin": {
            margin: 0
          },
          "& .MuiPickersDay-root": {
            width: 30,
            height: 30
          }
        }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            openTo="day"
            value={props.dateSelected}
            onChange={(newValue) => {
              props.setDateSelected(newValue);
            }}
            renderInput={(params) => <TextField {...params} size={"small"} />}
          />
        </LocalizationProvider>
      </Box>
    </Grid>
  );
}

export default DatePicker;
