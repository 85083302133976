import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import DocumentModal from "./DocumentModal";
import DocumentTable from "./DocumentTable";
import SaasBack from "../../../Assets/Images/ProfileDetails/SaaSbackground.png";

const DocumentTableView = (props) => {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  const [openModal, setOpenModal] = useState(false);


  const [rowData, setRowData] = useState(null);

  const [modify, setModify] = useState(false);

  //const [statusType, setStatusType] = useState(0);

  return (
    <div
      style={{
        minHeight: "87vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <Grid paddingLeft={5} paddingTop={5}>
        <DocumentTable
          paging={false}
          tableHeight={480}
          showViewAll={false}
          ShowGoBack={true}
          setOpenModal={setOpenModal}
          setRowData={setRowData}
          viewSearch={true}
          viewFilters={true}
          modify={modify}
          setModify={setModify}
          //setStatusType={setStatusType}
          //statusType={statusType}
        />
      </Grid>
      <DocumentModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        rowData={rowData}
        setRowData={setRowData}
        setModify={setModify}
        modify={modify}
        //setStatusType={setStatusType}
      />
    </div>
  );
};

export default DocumentTableView;
