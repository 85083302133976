import React, { useState, useEffect } from "react";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import * as constFile from "../../../../StatusConst";
import classes from "../../Table/Table.module.css";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as tenantServices from "../../../Services/tenant-services.proxy";
import MenuPic from "../../../Assets/Images/Table/menuPic.png";

import fileimg from "../../../Assets/Images/Table/file.png";
import FileSubImg from "../../../Assets/Images/Table/FileSub.png";

import MulFileSubImg from "../../../Assets/Images/Table/mulFileSC.png";
import MulFileimg from "../../../Assets/Images/Table/mulFileSC.png";
import noFileimg from "../../../Assets/Images/Table/noFile.png";
import { TextField, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";

const getInviteFilterDataSC = tenantServices.getInviteFilterDataSC;
const getCollabratorsDataSC = tenantServices.getCollabratorsDataSC;
const changeStatus = tenantServices.changeStatus;
const resendInvitation = tenantServices.resendInvitation;

//Statuses
const INVITED = constFile.INVITED;
const ACTIVE = constFile.ACTIVE;
const SUSPENDED = constFile.SUSPENDED;
const ARCHIVED = constFile.ARCHIVED;

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 10px;
  box-sizing: border-box;
  min-height: 32px;
  width: 9rem;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  margin: 0.5em;
  padding: 10px;
  text-align: left;
  line-height: 1.5;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};

  &:hover {
    background: ${theme.palette.mode === "dark" ? "" : grey[100]};
    border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
  font-family: Muli;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0;
  min-width: 11rem;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  overflow: auto;
  outline: 0px;
  `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }
  `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});
export default function McTable(props) {
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );

  const [disabled, setDisabled] = useState(false);
  const [mcTable, setMcTable] = useState([]);
  const [dataLength, setdataLength] = useState();
  const [preInvite, setPreInvite] = useState(false);
  const [status, setStatus] = useState(0);
  const [statuses, setStatuses] = useState([]);
  const [isGuest] = useState(localStorage.getItem("isGuest"));

  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const [pageSize, setPageSize] = useState(25);

  const navigate = useNavigate();
  const listData = {
    Pagination: {
      PageSize: pageSize,
      PageNumber: pageNumber,
    },
    Search: searchText,
    SortList: [],
    AccountId: 1,
    StatusId: status === 0 ? null : status,
  };
  useEffect(() => {
    let apiTest = true;
    getInviteFilterDataSC(1).then((x) => {
      if (apiTest) {
        setStatuses(x.Statuses);
      }
    });
    return () => {
      apiTest = false;
    };
  }, []);

  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    let apiTest = true;
    setLoadingData(true);
    getCollabratorsDataSC(listData).then((x) => {
      if (apiTest) {
        setMcTable(x.Companies);
        setdataLength(x.Total);
        setLoadingData(false);
      }
    });
    return () => {
      apiTest = false;
    };
  }, [pageSize, pageNumber, searchText, props.modify, status]);

  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowData, setRowData] = useState();

  const handleClick = async (event, params) => {
    setRowData(params);
    props.setRowData(params);
    setAnchorEl(event.target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const columns = [
    { field: "Id", hide: true, hideable: false },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,

      renderCell: (params) => {
        return (
          <img
            src={MenuPic}
            alt="menu"
            onClick={(e) => handleClick(e, params)}
          ></img>
        );
      },
    },
    {
      field: "Name",
      width: 220,
      renderCell: (params) => (
        <span
          style={{
            color: params.row.StatusId === ARCHIVED ? "grey" : "#0674b9",
          }}
        >
          {params.row?.Name}
        </span>
      ),
    },
    {
      field: "Email",
      headerName: "Email",
      width: 300,
      resizeable: true,
      renderCell: (params) => (
        <span
          style={{
            color: params.row.StatusId === ARCHIVED ? "grey" : "#0674b9",
          }}
        >
          {params.row?.Email}
        </span>
      ),
    },
    {
      field: "Project",
      headerName: "Project",
      width: 200,
      resizeable: true,
      renderCell: (params) => (
        <span
          style={{
            color: params.row.StatusId === ARCHIVED ? "grey" : "#0674b9",
          }}
        >
          {params.row?.Project}
        </span>
      ),
    },
    {
      field: "Status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.StatusId === ACTIVE
                ? "green"
                : params.row.StatusId === INVITED
                  ? "#0674b9"
                  : params.row.StatusId === SUSPENDED
                    ? "red"
                    : "grey",
          }}
        >
          {!params.row?.Project ? "Added" : params.row?.Status}
        </span>
      ),
    },
    {
      field: "Creator",
      headerName: "Creator",
      width: 200,
      renderCell: (params) => (
        <span
          style={{
            color: params.row.StatusId === ARCHIVED ? "grey" : "#0674b9",
          }}
        >
          {params.row?.Creator}
        </span>
      ),
    },
    {
      field: "CreatedDate",
      headerName: "Created Date",
      width: 180,
      renderCell: (params) => (
        <span
          style={{
            color: params.row.StatusId === ARCHIVED ? "grey" : "#0674b9",
          }}
        >
          {params.row?.CreatedDate.slice(0, 10)}
        </span>
      ),
    },

    {
      field: "File",
      headerName: "File",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <img
          src={
            params.row.Files?.length > 0 && params.row.StatusId !== ARCHIVED
              ? localStorage.getItem("AccountType") === "1"
                ? params.row.Files?.length > 1
                  ? MulFileimg
                  : fileimg
                : params.row.Files?.length > 1
                  ? MulFileSubImg
                  : FileSubImg
              : noFileimg
          }
          style={{ width: "20px" }}
          alt={"null"}
        />
      ),
    },
  ];
  return (
    <Grid container>
      {disabled ? (
        <Grid
          item
          container
          bgcolor={"rgba(225, 227, 25, 0.5)"}
          xs={12}
          // marginLeft="5%"
          padding={1}
        >
          <Grid item md={4}></Grid>
          <Grid
            item
            md={6}
            style={{
              fontFamily: "Muli",
              fontSize: "17px",
              fontWeight: "bold",
              textAlign: "left",
              color: "#101650",
            }}
          >
            <Grid>
              {localStorage.getItem("isTeamMember") === "true" &&
                !UserPermissions?.find((x) => x.Name === "Project Manager") &&
                !UserPermissions?.find((x) => x.Name === "Admin") &&
                !UserPermissions?.find((x) => x.Name === "Engineering Manager")
                ? "You only have permission to view main contractors"
                : localStorage.getItem("isTeamMember") === "true" &&
                  (UserPermissions?.find((x) => x.Name === "Project Manager") ||
                    UserPermissions?.find(
                      (x) => x.Name === "Engineering Manager"
                    ))
                  ? "You don't have permission to archive or re-activate a main contractor"
                  : ""}
            </Grid>
          </Grid>

          <Grid item md={2} container>
            <Grid item xs={8}></Grid>
            <Grid
              item
              paddingLeft={1}
              onClick={() => {
                setDisabled(false);
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
            >
              <CloseIcon fontSize="medium" />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <Grid item container direction={"row"} md={12} paddingBottom={2}>
        <Grid item lg={8} md={4}>
          <span className={classes["Table-Title"]}>Main Contractors</span>
        </Grid>

        <Grid
          item
          lg={4}
          container
          direction={"row-reverse"}
          paddingRight={{ md: 5 }}
        >
          <button
            disabled={
              localStorage.getItem("IsSubscribedBefore") === "true" ||
              localStorage.getItem("IsSubscribedBeforeManu") === "true"
                ? true
                : false
            }
            className={classes["AddButton"]}
            onClick={() => {
              if (
                localStorage.getItem("isTeamMember") === "true" &&
                !UserPermissions?.find((x) => x.Name === "Project Manager") &&
                !UserPermissions?.find((x) => x.Name === "Admin") &&
                !UserPermissions?.find((x) => x.Name === "Engineering Manager")
              )
                for (let i = 0; i < UserPermissions?.length; i++) {
                  for (
                    let j = 0;
                    j < UserPermissions[i].Permissions.length;
                    j++
                  ) {
                    if (
                      UserPermissions[i].Permissions[j].Name ===
                      "Main Contractor"
                    ) {
                      if (
                        UserPermissions[i].Permissions[j].Actions.find(
                          (a) => a.Name === "Invite"
                        )
                      ) {
                        props.setRowData(null);
                        props.setOpenModal(true);
                        handleClose();
                        setDisabled(false);
                        break;
                      } else {
                        setDisabled(true);
                      }
                    } else {
                      setDisabled(true);
                    }
                  }
                }
              else {
                props.setRowData(null);
                props.setOpenModal(true);
                handleClose();
              }
            }}
          >
            Add
          </button>
          {props.ShowGoBack ? (
            <button
              className={classes["back-button"]}
              onClick={() => {
                navigate(-1);
              }}
            >
              Go back
            </button>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      {props.viewFilters ? (
        <Grid item md={10} container direction={"row-reverse"}>
          <Grid
            item
            md={12}
            lg={12}
            marginTop={1}
            container
            direction={"row-reverse"}
          >
            <Grid item>
              <CustomSelect
                value={status}
                onChange={(option) => {
                  setStatus(option);
                }}
              >
                <StyledOption value={0} disabled>
                  Status
                </StyledOption>
                {statuses.map((s) => (
                  <StyledOption key={s.Id} value={s.Id}>
                    {s.Name}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Grid>
            {status ? (
              <Grid item marginTop={0.6}>
                <button
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={() => {
                    setStatus(0);
                  }}
                  className={classes["Rectangle-1224"]}
                >
                  Clear filters
                </button>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      {props.viewSearch ? (
        <Grid
          item
          md={2}
          container
          direction={"row-reverse"}
          paddingRight={{ md: 4, xs: 0 }}
        >
          <Box
            sx={{
              p: 0.5,
              pb: 0,
            }}
          >
            <TextField
              variant="standard"
              fullWidth
              onChange={inputHandler}
              placeholder="Search this list…"
              InputProps={{
                startAdornment: (
                  <SearchIcon
                    fontSize="small"
                    htmlColor={"#707070"}
                    style={{ paddingLeft: 5 }}
                  />
                ),
              }}
              sx={{
                fontFamily: "Muli",
                backgroundColor: "white",
                borderRadius: 1,
                width: {
                  xs: 1,
                  sm: "auto",
                },
                m: (theme) => theme.spacing(1, 0.5, 1.5),
                "& .MuiSvgIcon-root": {
                  mr: 0.5,
                },
                "& .MuiInput-underline:before": {
                  borderBottom: 0,
                  borderColor: "white",
                },
              }}
            />
          </Box>
        </Grid>
      ) : (
        <></>
      )}
      <div
        style={{
          height: props.tableHeight,
          width: "97.5%",
          fontFamily: "Muli",
          backgroundColor: "white",
          marginTop: 10,
        }}
      >
        <DataGrid
          initialState={{
            pagination: {
              pageSize: 25,
            },
          }}
          pagination
          paginationMode="server"
          rows={mcTable ? mcTable : []}
          columns={columns}
          headerHeight={40}
          density={"compact"}
          showColumnRightBorder
          sx={{
            [`& .${gridClasses.row}`]: {
              color: "#0674b9",
              overflow: "auto",
              bgcolor: (theme) =>
                theme.palette.mode === "light" ? grey[0] : grey[500],
            },

            [`& .${gridClasses.columnHeader}`]: {
              bgcolor: "rgb(172 201 224)",
              color: "rgb(17 24 84)",
            },
            [`& .${gridClasses.columnHeaderTitle}`]: {
              fontFamily: "Muli",
              fontWeight: "bold",
            },

            [`& .${gridClasses.footerContainer}`]: {
              bgcolor: "rgb(216 235 247)",
              minHeight: "30px",
            },
            fontFamily: "Muli",
            fontSize: "13px",
          }}
          disableColumnFilter
          getRowId={(row) => row.CollaborationId}
          rowCount={dataLength}
          hideFooter={props.paging}
          onPageChange={(newPage) => {
            if (pageSize > dataLength) {
              setPageNumber(1);
            } else setPageNumber(newPage + 1);
          }}
          onPageSizeChange={(newPage) => {
            setPageSize(newPage);
          }}
          pageSize={pageSize}
          loading={loadingData}
          components={{
            NoRowsOverlay: () => (
              <Stack className={classes["EmptyMessage"]}>
                {isGuest === "true"
                  ? "Please Subscribe to access Main Contractors"
                  : "No Main Contractors to display."}
              </Stack>
            ),
          }}
        />
      </div>
      {props.showViewAll ? (
        <Grid
          item
          xs={11.68}
          container
          bgcolor={"white"}
          justifyContent={"center"}
        >
          <span
            style={{
              color: "#0674b9",
              fontFamily: "Muli",
              fontSize: "13px",
            }}
          >
            View All
          </span>
        </Grid>
      ) : (
        ""
      )}

      {localStorage.getItem("IsSubscribedBefore") === "true" ? (
        ""
      ) : (
        <Menu
          id="more-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          disableScrollLock
        >
          {rowData?.row.StatusId === INVITED ? (
            <>
              {!rowData?.row.Project ? (
                <></>
              ) : (
                <MenuItem
                  onClick={() => {
                    for (let i = 0; i < UserPermissions?.length; i++) {
                      for (
                        let j = 0;
                        j < UserPermissions[i].Permissions.length;
                        j++
                      ) {
                        if (
                          UserPermissions[i].Permissions[j].Name ===
                          "Main Contractor"
                        ) {
                          if (
                            UserPermissions[i].Permissions[j].Actions.find(
                              (a) => a.Name === "Resend Invite"
                            )
                          ) {
                            resendInvitation(rowData.id).then(() => {
                              setPreInvite(true);
                              props.setModify(!props.modify);
                              handleClose();
                            })
                            setDisabled(false);
                            break;
                          } else {
                            setDisabled(true);
                          }
                        } else {
                          setDisabled(true);
                        }
                      }
                    }
                    // resendInvitation(rowData.id);
                    // setPreInvite(true);
                    // props.setModify(!props.modify);
                    // handleClose();
                  }}
                  style={{
                    fontFamily: "Muli",
                    fontSize: "13px",
                    color: "#444",
                  }}
                >
                  Re-Invite
                </MenuItem>
              )}
            </>
          ) : rowData?.row.StatusId === ACTIVE ? (
            <MenuItem
              onClick={() => {
                for (let i = 0; i < UserPermissions?.length; i++) {
                  for (
                    let j = 0;
                    j < UserPermissions[i].Permissions.length;
                    j++
                  ) {
                    if (
                      UserPermissions[i].Permissions[j].Name ===
                      "Main Contractor"
                    ) {
                      if (
                        UserPermissions[i].Permissions[j].Actions.find(
                          (a) => a.Name === "Suspend"
                        )
                      ) {
                        changeStatus(rowData.id, SUSPENDED).then(() => {
                          props.setModify(!props.modify);
                          handleClose();
                        })
                        setDisabled(false);
                        break;
                      } else {
                        setDisabled(true);
                      }
                    } else {
                      setDisabled(true);
                    }
                  }
                }
              }}
              style={{
                fontFamily: "Muli",
                fontSize: "13px",
                color: "#444",
              }}
            >
              Suspend
            </MenuItem>
          ) : rowData?.row.StatusId === SUSPENDED ||
            (rowData?.row.StatusId === ARCHIVED && preInvite === false) ? (
            <MenuItem
              onClick={() => {
                if (
                  rowData?.row.StatusId === ARCHIVED &&
                  localStorage.getItem("isTeamMember") === "true" &&
                  !UserPermissions?.find((x) => x.Name === "Admin")
                ) {
                  for (let i = 0; i < UserPermissions?.length; i++) {
                    for (
                      let j = 0;
                      j < UserPermissions[i].Permissions.length;
                      j++
                    ) {
                      if (
                        UserPermissions[i].Permissions[j].Name ===
                        "Main Contractor"
                      ) {
                        if (
                          UserPermissions[i].Permissions[j].Actions.find(
                            (a) => a.Name === "Reactivate"
                          )
                        ) {
                          changeStatus(rowData.id, ACTIVE).then(() => {
                            props.setModify(!props.modify);
                            handleClose();
                          })
                          setDisabled(false);
                          break;
                        } else {
                          setDisabled(true);
                        }
                      } else {
                        setDisabled(true);
                      }
                    }
                  }
                } else {
                  changeStatus(rowData.id, ACTIVE).then(() => {
                    props.setModify(!props.modify);
                    handleClose();
                  })
                }
              }}
              style={{
                fontFamily: "Muli",
                fontSize: "13px",
                color: "#444",
              }}
            >
              Re-Activate
            </MenuItem>
          ) : rowData?.row.StatusId === ARCHIVED && preInvite === true ? (
            <>
              {!rowData?.row.Project ? (
                <></>
              ) : (
                <MenuItem
                  onClick={() => {
                    if (
                      localStorage.getItem("isTeamMember") === "true" &&
                      !UserPermissions?.find((x) => x.Name === "Admin")
                    ) {
                      for (let i = 0; i < UserPermissions?.length; i++) {
                        for (
                          let j = 0;
                          j < UserPermissions[i].Permissions.length;
                          j++
                        ) {
                          if (
                            UserPermissions[i].Permissions[j].Name ===
                            "Main Contractor"
                          ) {
                            if (
                              UserPermissions[i].Permissions[j].Actions.find(
                                (a) => a.Name === "Resend Invite"
                              )
                            ) {
                              changeStatus(rowData.id, INVITED).then(() => {
                                resendInvitation(rowData.id).then(() => {
                                  props.setModify(!props.modify);
                                  setPreInvite(false);
                                  handleClose();
                                })
                              })
                              setDisabled(false);
                              break;
                            } else {
                              setDisabled(true);
                            }
                          } else {
                            setDisabled(true);
                          }
                        }
                      }
                    } else {
                      changeStatus(rowData.id, INVITED).then(() => {
                        resendInvitation(rowData.id).then(() => {
                          props.setModify(!props.modify);
                          setPreInvite(false);
                          handleClose();
                        })
                      })
                    }
                  }}
                  style={{
                    fontFamily: "Muli",
                    fontSize: "13px",
                    color: "#444",
                  }}
                >
                  Re-Invite
                </MenuItem>
              )}
            </>
          ) : (
            <div></div>
          )}
          {rowData?.row.StatusId === ARCHIVED ? (
            <div></div>
          ) : (
            <MenuItem
              onClick={() => {
                if (
                  localStorage.getItem("isTeamMember") === "true" &&
                  !UserPermissions?.find((x) => x.Name === "Admin")
                ) {
                  for (let i = 0; i < UserPermissions?.length; i++) {
                    for (
                      let j = 0;
                      j < UserPermissions[i].Permissions.length;
                      j++
                    ) {
                      if (
                        UserPermissions[i].Permissions[j].Name ===
                        "Main Contractor"
                      ) {
                        if (
                          UserPermissions[i].Permissions[j].Actions.find(
                            (a) => a.Name === "Archive/Close"
                          )
                        ) {
                          if (rowData?.row.StatusId === INVITED) {
                            changeStatus(rowData.id, ARCHIVED).then(() => {
                              props.setModify(!props.modify);
                              handleClose();
                              setPreInvite(true);
                            })
                          } else {
                            changeStatus(rowData.id, ARCHIVED).then(() => {
                              props.setModify(!props.modify);
                              handleClose();
                              setPreInvite(false);
                            })
                          }
                          setDisabled(false);
                          break;
                        } else {
                          setDisabled(true);
                        }
                      } else {
                        setDisabled(true);
                      }
                    }
                  }
                } else {
                  if (rowData?.row.StatusId === INVITED) {
                    changeStatus(rowData.id, ARCHIVED).then(() => {
                      props.setModify(!props.modify);
                      handleClose();
                      setPreInvite(true);
                    })
                  } else {
                    changeStatus(rowData.id, ARCHIVED).then(() => {
                      props.setModify(!props.modify);
                      handleClose();
                      setPreInvite(false);
                    })

                  }

                }
              }}
              style={{
                fontFamily: "Muli",
                fontSize: "13px",
                color: "#444",
              }}
            >
              Archive
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              if (
                localStorage.getItem("isTeamMember") === "true" &&
                !UserPermissions?.find((x) => x.Name === "Project Manager") &&
                !UserPermissions?.find((x) => x.Name === "Admin") &&
                !UserPermissions?.find((x) => x.Name === "Engineering Manager")
              ) {
                for (let i = 0; i < UserPermissions?.length; i++) {
                  for (
                    let j = 0;
                    j < UserPermissions[i].Permissions.length;
                    j++
                  ) {
                    if (
                      UserPermissions[i].Permissions[j].Name ===
                      "Main Contractor"
                    ) {
                      if (
                        UserPermissions[i].Permissions[j].Actions.find(
                          (a) => a.Name === "View"
                        )
                      ) {
                        props.setViewAssignmentLog(true);
                        props.setProjectId(rowData?.row.ProjectId);
                        setDisabled(false);
                        break;
                      } else {
                        setDisabled(true);
                      }
                    } else {
                      setDisabled(true);
                    }
                  }
                }
              } else {
                props.setViewAssignmentLog(true);
                props.setProjectId(rowData?.row.ProjectId);
              }
            }}
            style={{
              fontFamily: "Muli",
              fontSize: "13px",
              color: "#444",
            }}
          >
            View Assignment Log
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (
                localStorage.getItem("isTeamMember") === "true" &&
                !UserPermissions?.find((x) => x.Name === "Project Manager") &&
                !UserPermissions?.find((x) => x.Name === "Admin") &&
                !UserPermissions?.find((x) => x.Name === "Engineering Manager")
              ) {
                for (let i = 0; i < UserPermissions?.length; i++) {
                  for (
                    let j = 0;
                    j < UserPermissions[i].Permissions.length;
                    j++
                  ) {
                    if (
                      UserPermissions[i].Permissions[j].Name ===
                      "Main Contractor"
                    ) {
                      if (
                        UserPermissions[i].Permissions[j].Actions.find(
                          (a) => a.Name === "Edit"
                        )
                      ) {
                        props.setContractorId(rowData.id);
                        props.setOpenModal(true);
                        setAnchorEl(null);
                        setDisabled(false);
                        break;
                      } else {
                        setDisabled(true);
                      }
                    } else {
                      setDisabled(true);
                    }
                  }
                }
              } else {
                props.setContractorId(rowData.id);
                props.setOpenModal(true);
                setAnchorEl(null);
              }
            }}
            style={{
              fontFamily: "Muli",
              fontSize: "13px",
              color: "#444",
            }}
          >
            Update Documents
          </MenuItem>
        </Menu>
      )}
    </Grid>
  );
}
