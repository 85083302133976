import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  MenuItem,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableBody,
} from "@mui/material";
import classes from "../../../../../Assets/Styles/InventorySetup.module.css";
import * as inventoryServices from "../../../../../Services/inventory-setup-services.proxy";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

const getInventoryDimensionInputData =
  inventoryServices.getInventoryDimensionInputData;
const createInventoryDimension = inventoryServices.createInventoryDimension;
const ItemDimension = (props) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [loadingData, setLoadingData] = useState(true);
  const [saving, setSaving] = useState(false);

  const [isdisabled, setIsDisabled] = useState(true);

  const [nbOfDimensions, setNbOfDimensions] = useState("2");

  const [abbrevError, setAbbrevError] = useState(false);

  const [fieldChangingId, setFieldChangingId] = useState(false);

  const [dimensions, setDimensions] = useState([
    {
      Id: 1,
      DimensionId: 1,
      UOMId: 4,
      AbbreviationDigits: 3,
      Abbreviation: null,
    },
    {
      Id: 2,
      DimensionId: 2,
      UOMId: 5,
      AbbreviationDigits: 2,
      Abbreviation: null,
    },
  ]);
  const [dimensionNames, setDimensionNames] = useState([]);
  const [uOMs, setUOMs] = useState([]);
  useEffect(() => {
    let empty = false;
    dimensions.map((dim) => {
      if ((!dim.Abbreviation || abbrevError) && !empty) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    });
  });
  useEffect(() => {
    if (props.inventoryId) {
      getInventoryDimensionInputData(props.inventoryId).then((x) => {
        setDimensionNames(x.Dimensions);
        setUOMs(x.UOMs);
        setLoadingData(false);
        if (x.Inventory) {
          setDimensions(x.Inventory.Dimensions);
          props.setInventoryId(x.Inventory.Id);
        }
      });
    }
  }, [props.inventoryId]);
  const InventoryDimensionData = {
    Id: props.inventoryId,
    Dimensions: dimensions,
  };
  const save = () => {
    setSaving(true);
    createInventoryDimension(InventoryDimensionData).then((x) => {
      setSaving(false);
      props.setActiveTab(props.activeTab + 1);
    });
  };
  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "transparent",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
          flexDirection: "column",
        }}
        open={loadingData}
      >
        <CircularProgress />

        <Grid
          item
          fontFamily={"Muli"}
          fontSize={15}
          fontWeight={"bold"}
          color={"rgb(25, 118, 210)"}
        >
          Loading Data...
        </Grid>
      </Backdrop>
      <Grid item md={0.7} sm={1.5} paddingTop={1.5}>
        <Grid item className={classes["Lfields"]}>
          No. of Dimensions
        </Grid>
        <Grid item>
          <TextField
            value={nbOfDimensions}
            fullWidth
            disabled
            InputProps={{
              style: {
                color: "#0674b9",
                height: "28px",
                fontSize: 12,
                fontFamily: "Muli",
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={10}></Grid>
      <Grid item overflow={"auto"}>
        <TableContainer sx={{ width: "100%" }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              borderCollapse: "separate",
              borderSpacing: "0px 0px",
              border: 0,
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell sx={styles.tableCell}>Dimension Name</TableCell>
                {dimensions?.map((dim, index) => (
                  <TableCell sx={styles.tableCellhead}>
                    <Grid item className={classes["Lfields"]} xs={12}>
                      Dim &nbsp;{index + 1}
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        value={dim.DimensionId}
                        fullWidth
                        select
                        disabled
                        SelectProps={{
                          MenuProps: { disableScrollLock: true },
                        }}
                        InputProps={{
                          style: {
                            color: "#707070",
                            height: "28px",
                            fontSize: 12,
                            fontFamily: "Muli",
                          },
                        }}
                      >
                        {dimensionNames.map((r) => (
                          <MenuItem value={r.Id} key={r.Id}>
                            {r.Name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={styles.tableCellBody}>Dimension UoM</TableCell>

                {dimensions?.map((dim) => (
                  <TableCell sx={styles.tableCellheadBody}>
                    <Grid item md={3.5} sm={5} xs={8}>
                      <TextField
                        value={dim.UOMId}
                        fullWidth
                        select
                        disabled
                        SelectProps={{
                          MenuProps: { disableScrollLock: true },
                        }}
                        InputProps={{
                          style: {
                            color: "#707070",
                            height: "28px",
                            fontSize: 12,
                            fontFamily: "Muli",
                          },
                        }}
                      >
                        {uOMs.map((r) => (
                          <MenuItem value={r.Id} key={r.Id}>
                            {r.Name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell sx={styles.tableCellBody}>
                  Dimension Abbreviation Digits
                </TableCell>

                {dimensions?.map((dim) => (
                  <TableCell sx={styles.tableCellheadBody}>
                    <Grid item md={3.5} sm={5} xs={8}>
                      <TextField
                        value={dim.AbbreviationDigits}
                        fullWidth
                        disabled
                        InputProps={{
                          style: {
                            color: "#707070",
                            height: "28px",
                            fontSize: 12,
                            fontFamily: "Muli",
                          },
                        }}
                      ></TextField>
                    </Grid>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell sx={styles.tableCellBody}>
                  Dimension Abbreviation
                </TableCell>

                {dimensions?.map((dim) => (
                  <TableCell sx={styles.tableCellheadBody}>
                    <Grid item md={3.5} sm={5} xs={8}>
                      <TextField
                        value={dim.Abbreviation}
                        fullWidth
                        InputProps={{
                          style: {
                            color: "#707070",
                            height: "28px",
                            fontSize: 12,
                            fontFamily: "Muli",
                          },
                        }}
                        placeholder={dim.DimensionId === 1 ? "Dia" : "lg"}
                        onChange={(e) => {
                          dim.Abbreviation = e.target.value;
                          setFieldChangingId(dim.Id);
                          if (
                            e.target.value.length !== dim.AbbreviationDigits
                          ) {
                            setAbbrevError(true);
                          } else setAbbrevError(false);
                          forceUpdate();
                        }}
                      ></TextField>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      fontFamily={"Muli"}
                      fontSize={9}
                      color={"#ea001e"}
                      height={"20px"}
                    >
                      {abbrevError && fieldChangingId === dim.Id
                        ? "Letters number must be equal to dimension Abbreviation Digits"
                        : ""}
                    </Grid>
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid
        item
        container
        xs={12}
        direction={"row-reverse"}
        gap={1}
        paddingTop={5}
        paddingRight={1.5}
      >
        <button
          className={classes["SaveBut"]}
          disabled={isdisabled}
          onClick={() => {
            save();
          }}
        >
          {saving ? "Saving..." : "Save"}
        </button>
        <button
          className={classes["cancelbut"]}
          onClick={() => {
            props.setActiveTab(props.activeTab - 1);
          }}
        >
          Go Back
        </button>
      </Grid>
    </>
  );
};
const styles = {
  tableCell: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    lineHeight: 1.17,
    textAlign: "left",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 157,
  },
  tableCellhead: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    textAlign: "left",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 266,
  },
  tableCellheadPr: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    textAlign: "left",
    maxHeight: "20px",
    width: 180,
  },
  tableCellBody: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    lineHeight: 1.17,
    textAlign: "left",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 75,
    paddingTop: 3.5,
  },
  tableCellheadBody: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    textAlign: "left",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 140,
  },
  tableCellheadPrBody: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    textAlign: "left",
    maxHeight: "20px",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    width: 180,
  },
};
export default ItemDimension;
