import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as activityConst from "../../../StatusConst";
import classes from "../../Assets/Styles/Activities.module.css";
import EyeIcon from "../../Assets/Images/Projects/Eye.png";
import ReviseIcon from "../../Assets/Images/Activity/ReviseIcon.png";
export default function BasicCard(props) {
  const navigate = useNavigate();
  return (
    <Grid
      container
      sx={{
        boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
        width: 295,
        minHeight: 168,
        opacity: props.assigned ? 0.5 : 1,
      }}
    >
      <Grid
        item
        xs={1}
        sx={{
          minHeight: 160,
          backgroundColor: "#101650",
          borderBottomLeftRadius: 5,
          borderTopLeftRadius: 5,
        }}
      ></Grid>
      <Grid item xs={11}>
        <Card
          sx={{
            boxShadow: 0,
            minHeight: 168,
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
          }}
        >
          <CardContent
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            onClick={() => {
              if (props.data.CreatorId === localStorage.getItem("UserId")) {
                navigate("/saas/execution/create", {
                  state: { id: props.data.Id, edit: true, from: "card" },
                });
              } else {
                navigate("/saas/execution/create", {
                  state: { id: props.data.Id, edit: false, from:"card" },
                });
              }
            }}
          >
            <Grid container>
              <Grid item xs={10} paddingTop={1}>
                <Typography
                  fontFamily="Muli"
                  fontSize={14}
                  fontWeight="bold"
                  textAlign="left"
                  color="#101650"
                  gutterBottom
                >
                  {props.data.Code}
                </Typography>
              </Grid>
              {props.data.RevisionNumber > 0 ? (
                <Grid item xs={2} container direction={"row-reverse"}>
                  <img src={ReviseIcon} alt='revisIcon'/>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
            <div
              style={{
                fontFamily: "Muli",
                textAlign: "left",
                color: "#707070",
                paddingTop: 5,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Muli",
                  fontSize: 11,
                  fontStyle: "italic",
                  lineHeight: 1.18,
                  textAlign: "left",
                  color: "#5fba47",
                  height: 50,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
                paddingBottom={0.5}
              >
                {props.data.Description}
              </Typography>
              <Grid container direction={"row"}>
                <Grid item xs={9.5}>
                  <Typography
                    
                    fontSize={11}
                    width={"fit-content"}
                    bgcolor={
                      props.data.Status === activityConst.DRAFT_ACTIVITY
                        ? "#d9d2d2"
                        : props.data.Status === activityConst.PLANNED
                        ? "rgba(95, 186, 71, 0.7)"
                        : props.data.Status === activityConst.IN_PROGRESS
                        ? "rgba(45, 164, 208, 0.5)"
                        : props.data.Status ===
                          activityConst.SUBMITTED_INTERNAL_APPROVAL_1
                        ? "rgba(225, 227, 25, 0.7)"
                        : activityConst.SUBMITTED_INTERNAL_APPROVAL_2
                        ? "rgba(16, 22, 80, 0.3)"
                        : activityConst.SUBMITTED_FOR_CONSULTANT
                        ? "rgba(16, 22, 80, 0.7)"
                        : ""
                    }
                    borderRadius={"2px"}
                    padding={0.4}
                    color={"#101650"}
                  >
                    {props.data.Status}
                  </Typography>
                </Grid>
                {props.data.Progress ? (
                  <Grid item paddingTop={0.5} xs={2}>
                    <Grid
                      item
                      className={classes["Rectangle-1348"]}
                      bgcolor={
                        props.data.Progress === "Delayed"
                          ? "rgba(255, 0, 0, 0.3)"
                          : "#f1f1f1"
                      }
                    >
                      {props.data.Progress}
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
              <Grid container>
                <Typography fontSize={11}>
                  {props.data.SubmittalStatus}
                </Typography>
              </Grid>
            </div>
          </CardContent>
          <Grid
            sx={{
              borderTop: "solid 1px #e1e319",
            }}
          ></Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            padding="1% 10% 1% 5%"
          >
            {props.data.RevisionNumber > 0 ? (
              <Grid item container padding={0.2}>
                <Grid item xs={6} className={classes["Activity-Versions"]}>
                  Activity Versions
                </Grid>
                <Grid
                  item
                  xs={6}
                  container
                  direction={"row-reverse"}
                  className={classes["Activity-Versions"]}
                  gap={1}
                >
                  <Grid item className={classes["Rectangle-1348"]}>
                    {props.data.RevisionNumber}
                  </Grid>
                  <Grid
                    item
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                    onClick={() => {
                      navigate("/saas/execution/RevisionsActivitiesTable", {
                        state: { id: props.data.Id, code: props.data.Code },
                      });
                    }}
                  >
                    <img src={EyeIcon} alt="view" />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item container padding={1}></Grid>
            )}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}
