import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  MenuItem,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableBody,
} from "@mui/material";
import ManageStocks from "./ManageStocks";
import classes from "../../../../../Assets/Styles/InventorySetup.module.css";
import * as inventoryServices from "../../../../../Services/inventory-setup-services.proxy";
import { useSelector } from "react-redux";
import * as permissionFunctions from "../../../../../../ReusableFunctions";
const CheckIfPermitted = permissionFunctions.CheckIfPermitted;
const getReferenceDiaInputData = inventoryServices.getReferenceDiaInputData;
const createInventoryReferenceDiaValue =
  inventoryServices.createInventoryReferenceDiaValue;

const Form2 = (props) => {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [emptyField, setEmptyField] = useState(false);
  const [emptyFieldMessage, setEmptyFieldMessage] = useState("");
  const [isdisabled, setIsDisabled] = useState(true);
  const [stocksDisabled, setStocksDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  const [referencesDiameters, setReferenceDiameters] = useState([]);

  const checkAllFilled = () => {
    let empty = false;

    referencesDiameters.map((ref) => {
      ref.Diameters.map((dia) => {
        if ((!dia.MaxLength || !dia.MinLength || !dia.Range) && !empty) {
          empty = true;
          setEmptyFieldMessage(
            "Check for Empty Field(s) in " + ref.Code + " table"
          );
          setEmptyField(true);
        }
      });
    });
    if (!empty) {
      setEmptyField(false);
      save();
    }
  };
  useEffect(() => {
    if (
      props.diaValuesSelectedInRemnants.length === 0 ||
      !props.diaValuesSelectedInRemnants.length
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });
  useEffect(() => {
    if (props.inventoryId) {
      props.setLoadingData(true);
      getReferenceDiaInputData(props.inventoryId).then((x) => {
        props.setLoadingData(false);
        props.setListOfDiaValues(x.Diameters);
        setReferenceDiameters(x.Data);
        if (x.Data[0]?.Diameters.length > 0) {
          setStocksDisabled(false);
          let diaSelected = x.Data[0]?.Diameters?.flatMap((dia) => dia.DiameterId);
          props.setDiaValuesSelectedInRemnants(diaSelected);
          props.setItemSelectedDataRemants(x.Data[0]);
          props.setItemSelectedDataRemantsId(x.Data[0].Id);
        }
      });
    }
  }, [props.inventoryId]);
  const InventoryReferenceDiaData = {
    References: referencesDiameters,
  };
  useEffect(() => {
    if (UserPermissions && !stocksDisabled) {
      CheckIfPermitted(
        UserPermissions,
        "Inventory Set-Up",
        "Manage remnant stock",
        setStocksDisabled
      );
    }
  }, [UserPermissions, stocksDisabled]);
  const save = () => {
    setSaving(true);
    createInventoryReferenceDiaValue(InventoryReferenceDiaData).then(() => {
      setSaving(false);
      props.setManageStocks(true);
    });
  };
  return props.manageStocks ? (
    <ManageStocks
      setManageStocks={props.setManageStocks}
      setLoadingData={props.setLoadingData}
      inventoryId={props.inventoryId}
      setActiveTab={props.setActiveTab}
      activeTab={props.activeTab}
      setPrintingRemnant={props.setPrintingRemnant}
    />
  ) : (
    <>
      <Grid item xs={2.3}>
        <Grid item className={classes["Item-Category"]}>
          Item Category
        </Grid>
        <Grid item>
          <TextField
            value={props.itemCategory}
            fullWidth
            select
            disabled
            SelectProps={{
              MenuProps: { disableScrollLock: true },
            }}
            InputProps={{
              style: {
                color: "#707070",
                height: "28px",
                fontSize: 12,
                fontFamily: "Muli",
              },
            }}
            onChange={(e) => {
              props.setItemCategory(e.target.value);
            }}
          >
            {props.itemValuesData.map((r) => (
              <MenuItem value={r.Id} key={r.Id}>
                {r.Name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid item xs={2.3} marginLeft={2.5}>
        <Grid item className={classes["Item-Category"]}>
          list of Dia Values in mm
        </Grid>
        <Grid item>
          <TextField
            value={props.diaValuesSelectedInRemnants}
            fullWidth
            select
            SelectProps={{
              MenuProps: { disableScrollLock: true },
              multiple: true,
            }}
            InputProps={{
              style: {
                color: "#707070",
                height: "28px",
                fontSize: 12,
                fontFamily: "Muli",
              },
            }}
            onChange={(e) => {
              e.target.value.map((diaS) => {
                if (
                  !referencesDiameters[0]?.Diameters?.filter(
                    (x) => x.DiameterId === diaS
                  )[0]
                ) {
                  referencesDiameters?.map((ref) => {
                    ref.Diameters = ref.Diameters.concat({
                      Id: null,
                      DiameterId: e.target.value[e.target.value.length - 1],
                      Name: props.listOfDiaValues.filter(
                        (x) => x.Id === diaS
                      )[0].Name,
                      MaxLength: 0,
                      MinLength: props.itemValuesData
                        .find((itm) => itm.Name === "Raw Material")
                        .Types.find((x) => x.Name === "Bar")
                        .Diameters.find(
                          (x) =>
                            x.Name ===
                            props.listOfDiaValues.filter(
                              (x) => x.Id === diaS
                            )[0].Name
                        )
                        ? props.itemValuesData
                            .find((itm) => itm.Name === "Raw Material")
                            .Types.find((x) => x.Name === "Bar")
                            .Diameters.find(
                              (x) =>
                                x.Name ===
                                props.listOfDiaValues.filter(
                                  (x) => x.Id === diaS
                                )[0].Name
                            ).MaxScrapLength
                        : 0,
                      Range: 0,
                    });
                  });
                }
              });
              referencesDiameters[0]?.Diameters?.map((DiaToDelete) => {
                if (!e.target.value.filter((x) => x === DiaToDelete.DiameterId)[0]) {
                  referencesDiameters?.map((ref) => {
                    ref.Diameters = ref.Diameters.filter(
                      (x) => x.DiameterId !== DiaToDelete.DiameterId
                    );
                  });
                }
              });

              props.setDiaValuesSelectedInRemnants(e.target.value);
            }}
          >
            {props.listOfDiaValues?.map((r) => (
              <MenuItem value={r.Id} key={r.Id}>
                {r.Name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid item xs={2.3} marginLeft={2.5}>
        <Grid item className={classes["Item-Category"]}>
          Ref Group
        </Grid>
        <Grid item>
          <TextField
            value={props.itemSelectedDataRemantsId}
            fullWidth
            select
            SelectProps={{
              MenuProps: { disableScrollLock: true },
            }}
            InputProps={{
              style: {
                color: "#707070",
                height: "28px",
                fontSize: 12,
                fontFamily: "Muli",
              },
            }}
            onChange={(e) => {
              props.setItemSelectedDataRemantsId(e.target.value);
              props.setItemSelectedDataRemants(
                referencesDiameters?.filter((x) => x.Id === e.target.value)[0]
              );
            }}
          >
            {referencesDiameters?.map((r) => (
              <MenuItem value={r.Id} key={r.Id}>
                {r.Code}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item overflow={"auto"}>
        <TableContainer sx={{ width: "100%" }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              borderCollapse: "separate",
              borderSpacing: "0px 0px",
              border: 0,
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell sx={styles.tableCellhead}>Dia Selected (mm)</TableCell>
                {props.itemSelectedDataRemants?.Diameters?.map((Dia, index) => (
                  <TableCell sx={styles.tableCellhead2}>
                    <Grid item>{Dia.Name}</Grid>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={styles.tableCellhead}>min (mm)</TableCell>
                {props.itemSelectedDataRemants?.Diameters?.map((Dia, index) => (
                  <TableCell sx={styles.tableCellhead2}>
                    <TextField
                      variant="standard"
                      value={Dia.MinLength}
                      fullWidth
                      label={"Length"}
                      type={"number"}
                      InputLabelProps={{
                        style: styles.tfInput,
                      }}
                      InputProps={{
                        disableUnderline: true,
                        style: styles.tfInput,
                        inputProps: { min: 0 },
                      }}
                      onChange={(e) => {
                        Dia.MinLength = +e.target.value;
                        console.log(props.itemSelectedDataRemants);
                        forceUpdate();
                      }}
                    />
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell sx={styles.tableCellhead}>max (mm)</TableCell>
                {props.itemSelectedDataRemants?.Diameters?.map((Dia, index) => (
                  <TableCell sx={styles.tableCellhead2}>
                    <TextField
                      variant="standard"
                      value={Dia.MaxLength}
                      fullWidth
                      label={"Length"}
                      type={"number"}
                      InputLabelProps={{
                        style: styles.tfInput,
                      }}
                      InputProps={{
                        disableUnderline: true,
                        style: styles.tfInput,
                        inputProps: { min: 0 },
                      }}
                      onChange={(e) => {
                        Dia.MaxLength = +e.target.value;
                        forceUpdate();
                      }}
                    />
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell sx={styles.tableCellhead}>Range (mm)</TableCell>
                {props.itemSelectedDataRemants?.Diameters?.map((Dia, index) => (
                  <TableCell sx={styles.tableCellhead2}>
                    <TextField
                      variant="standard"
                      value={Dia.Range}
                      fullWidth
                      label={"Length"}
                      type={"number"}
                      InputLabelProps={{
                        style: styles.tfInput,
                      }}
                      InputProps={{
                        disableUnderline: true,
                        style: styles.tfInput,
                        inputProps: { min: 0 },
                      }}
                      onChange={(e) => {
                        Dia.Range = +e.target.value;
                        forceUpdate();
                      }}
                    />
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid
        item
        container
        xs={12}
        direction={"row-reverse"}
        gap={1}
        paddingTop={5}
        paddingRight={1.5}
      >
        <button
          className={classes["SaveBut"]}
          disabled={isdisabled}
          onClick={() => {
            checkAllFilled();
          }}
        >
          {saving ? "Saving..." : "Save"}
        </button>
        <button
          className={classes["cancelbut"]}
          onClick={() => {
            props.setItemCategory(
              props.itemValuesData.filter((y) => y.Name === "Raw Material")[0]
                .Id
            );
            props.setForm1Showed(true);
          }}
        >
          Go Back
        </button>
        <button
          disabled={stocksDisabled || !localStorage.getItem("planIdManu")?.includes("Pro")}
          className={classes["cancelbut"]}
          onClick={() => {
            props.setManageStocks(true);
          }}
        >
          Manage Remnant Stock
        </button>
        <Grid
          item
          fontFamily={"Muli"}
          fontSize={12}
          fontWeight={"bold"}
          color={"#ea001e"}
          paddingTop={1}
        >
          {emptyField ? emptyFieldMessage : ""}
        </Grid>
      </Grid>
    </>
  );
};
const styles = {
  tableCell: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    lineHeight: 1.17,
    textAlign: "left",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 157,
  },
  tableCellhead: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    textAlign: "left",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 112,
  },
  tableCellhead2: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#0674b9",
    textAlign: "center",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 75,
  },
  tableCellheadPr: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    textAlign: "left",
    maxHeight: "20px",
    width: 266,
  },
  tableCellBody: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    lineHeight: 1.17,
    textAlign: "left",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 75,
    paddingTop: 3.5,
  },
  tableCellheadBody: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    textAlign: "left",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 140,
  },
  tableCellheadPrBody: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    textAlign: "left",
    maxHeight: "20px",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    width: 180,
  },
  tfInput: {
    color: "#707070",
    height: "28px",
    fontSize: 12,
    fontFamily: "Muli",
  },
};
export default Form2;
