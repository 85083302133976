import React, { useEffect, useState } from "react";
import { Grid, TextField, Button } from "@mui/material";
import classes from "../../../Assets/Styles/Activities.module.css";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import SaasBack from "../../../Assets/Images/Projects/bgTableProject.png";
import uploadIcon from "../../../Assets/Images/Settings/uploadImg.png";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { styled } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { useNavigate, useLocation } from "react-router-dom";
import * as activityServices from "../../../Services/activities-services.proxy";
import fileIcon from "../../../Assets/Images/Table/file.png";
import CloseIcon from "@mui/icons-material/Close";
const tMUploadFile = activityServices.tMUploadFile;
const tMDeleteFile = activityServices.tMDeleteFile;
const getActivitySubmittalResponseData =
  activityServices.getActivitySubmittalResponseData;
const createSubmittalResponse = activityServices.createSubmittalResponse;
const SubmittalResponse = (props) => {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);
  const navigate = useNavigate();
  const location = useLocation();
  const ACTID = location.state.id;
  const EDITRES = location.state.edit;
  const SUBID = location.state.subProjectId;
  const [loadingData, setLoadingData] = useState(false);
  const [submittalStatus, setSubmittalStatus] = useState(0);
  const [submittalStatusError, setSubmittalStatusError] = useState(false);
  const [submittalStatuses, setSubmittalStatuses] = useState([]);
  const [drawingNbs, setDrawingNbs] = useState("");
  const [submittalReference, setsubmittalReference] = useState(null);
  const [submittalReferenceError, setsubmittalReferenceError] = useState(false);
  const [submittalReferenceMessage, setsubmittalReferenceMessage] =
    useState("");
  const [description, setDescription] = useState("");
  const [activityDetails, setActivityDetails] = useState({});
  const [nbOfSheets, setnbOfSheets] = useState([]);
  const [files, setFiles] = useState([]);
  const [filesIDS, setFilesIDS] = useState([]);
  const [fileID, setFileID] = useState(null);
  const [submRes, setSubmRes] = useState({});
  const [disableAllFields, setDisableAllFields] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [deletingFile,setDeletingFile] = useState(false);
  

  useEffect(() => {
    if (ACTID) {
      setLoadingData(true);
      getActivitySubmittalResponseData(ACTID).then((x) => {
        setLoadingData(false);
        if (x.SubmittalResponse) {
          setSubmRes(x.SubmittalResponse);
          setSubmittalStatus(x.SubmittalResponse.SubmittalStatusId);
          setsubmittalReference(x.SubmittalResponse.SubmittalReference);
          if (x.SubmittalResponse.File)
            setFiles(files.concat(x.SubmittalResponse.File));
          setnbOfSheets(x.SubmittalResponse.Sheets);
        }
        setActivityDetails(x);
        setSubmittalStatuses(x.SubmittalStatuses.filter((s) => s.Name !== "N/A"));
        setDrawingNbs(x.DrawingNumber);
        setDescription(x.Description);
        let i = 0;
        let nbSheetsArr = [];
        if (!x.SubmittalResponse) {
          while (i < x.SheetsNumber) {
            nbSheetsArr.push({ SheetNumber: i, StatusId: 1 });
            i++;
          }
          setnbOfSheets(nbSheetsArr);
        }
      });
      if (EDITRES) {
        setDisableAllFields(false);
      } else {
        setDisableAllFields(true);
      }
    }
  }, [ACTID]);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setLoadingFile(true);
      tMUploadFile(e.target.files[0], SUBID).then((x) => {
        setLoadingFile(false);
        setFileID(x.FileId);
        let tempIDS = filesIDS;
        setFilesIDS(tempIDS.concat(x.FileId));
        let tempFiles = files;
        setFiles(
          tempFiles.concat({
            FileId: x.FileId,
            DisplayName: x.DisplayName,
            Type: e.target.files[0].type,
            Size: e.target.files[0].size,
          })
        );
      });
    }
  };
  const handleFileDelete = (fileIdToDelete) => {
    setDeletingFile(true);
    tMDeleteFile(fileIdToDelete, SUBID).then((x) => {
      setDeletingFile(false);
      let tempIDS = filesIDS;
      tempIDS = tempIDS.filter(function (item) {
        return item.FileId !== fileIdToDelete;
      });
      setFilesIDS(tempIDS);
      let tempFiles = files;
      tempFiles = tempFiles.filter(function (item) {
        return item.FileId !== fileIdToDelete;
      });
      setFiles(tempFiles);
      setFileID(null);
    });
  };
  const submittalData = {
    ActivityId: ACTID,
    SubmittalStatusId: submittalStatus,
    SubmittalReference: submittalReference,
    FileId: fileID,
    Sheets: nbOfSheets,
  };
  const createTheSubmitalResponse = () => {
    setLoadingData(true);
    createSubmittalResponse(submittalData).then((x) => {
      setLoadingData(false);
      navigate("/saas/execution/");
    });
  };
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <Grid container paddingBottom={5} paddingLeft={5} paddingTop={5}>
        <Backdrop
          sx={{
            color: "#fff",
            backgroundColor: "transparent",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            display: "flex",
            flexDirection: "column",
          }}
          open={loadingData}
        >
          <CircularProgress />

          <Grid
            item
            fontFamily={"Muli"}
            fontSize={15}
            fontWeight={"bold"}
            color={"rgb(25, 118, 210)"}
          >
            Loading Data...
          </Grid>
        </Backdrop>
        <Grid container item>
          <Grid item md={1} xs={2} className={classes["page-title"]}>
            Activities
          </Grid>
          <Grid
            item
            md={9.7}
            xs={7.8}
            className={classes["Line12"]}
            marginTop={1.3}
          ></Grid>
          <Grid item md={1} xs={2} paddingLeft={1}>
            <button
              className={classes["back-button"]}
              onClick={() => {
                navigate(-1);
              }}
            >
              Go Back
            </button>
          </Grid>
        </Grid>
        <Grid
          xs={10}
          container
          item
          md={9}
          bgcolor={"#fff"}
          marginTop={2}
          paddingLeft={2.5}
          paddingTop={3}
          gap={1}
          paddingBottom={3}
        >
          <Grid container direction={"row"}>
            <Grid item className={classes["Activity-Details"]}>
              Submittal Response
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              fontSize={{ xs: 12, md: 16 }}
              textAlign={{ md: "right", xs: "left" }}
              className={classes["Submittals-dates"]}
            >
              {`Response received by consultant on ${activityDetails.SubmittalResponse?.SubmittalDate
                ? new Date(
                  activityDetails.SubmittalResponse?.SubmittalDate
                ).toLocaleDateString()
                : new Date().toLocaleDateString()
                } at ${activityDetails.SubmittalResponse?.SubmittalDate
                  ? new Date(
                    activityDetails.SubmittalResponse?.SubmittalDate
                  ).toLocaleTimeString()
                  : new Date().toLocaleTimeString()
                }`}
            </Grid>
          </Grid>
          <Grid container paddingTop={2}>
            <Grid item md={2.93} xs={12}>
              <Grid item className={classes["field-title"]} xs={12}>
                *Submittal Status
              </Grid>
              <Grid item xs={11} paddingTop={1}>
                <CustomSelect
                  disabled={disableAllFields}
                  value={submittalStatus}
                  onChange={(option) => {
                    setSubmittalStatus(option);
                    setSubmittalStatusError(false);
                  }}
                  style={{ color: "#707070" }}
                  onBlur={() => {
                    if (!submittalStatus) setSubmittalStatusError(true);
                  }}
                >
                  <StyledOption value={0} disabled>
                    Submittal Status
                  </StyledOption>
                  {submittalStatuses.map((s) => (
                    <StyledOption key={s.Id} value={s.Id}>
                      {s.Name}
                    </StyledOption>
                  ))}
                </CustomSelect>
              </Grid>
              <Grid item className={classes["errorMessage"]}>
                {submittalStatusError ? "Select a Status" : <></>}
              </Grid>
            </Grid>
            <Grid item md={5.9} xs={12}>
              <Grid item className={classes["field-title-disabled"]} xs={12}>
                *Drawings Nb
              </Grid>
              <Grid
                item
                className={classes["Rectangle-1464"]}
                xs={11}
                marginTop={1}
                height={"50px"}
              >
                {drawingNbs}
              </Grid>
            </Grid>
          </Grid>
          <Grid container paddingTop={2}>
            <Grid item md={6} xs={12}>
              <Grid item container xs={12}>
                <Grid item className={classes["field-title"]} xs={12}>
                  *Submittal Reference
                </Grid>
                <Grid item xs={11} marginTop={1}>
                  <TextField
                    disabled={disableAllFields}
                    fullWidth
                    InputLabelProps={{
                      shrink: false,
                    }}
                    InputProps={{ className: classes.fieldDesign }}
                    size="small"
                    label={""}
                    value={submittalReference}
                    variant="outlined"
                    error={submittalReferenceError}
                    helperText={submittalReferenceMessage}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        setsubmittalReferenceError(true);
                        setsubmittalReferenceMessage(
                          "Enter a submit Reference"
                        );
                      }
                    }}
                    onChange={(e) => {
                      setsubmittalReference(e.target.value);
                      if (e.target.value.length > 150) {
                        setsubmittalReferenceError(true);
                        setsubmittalReferenceMessage(
                          "Reference must be less than 150 char"
                        );
                      } else {
                        setsubmittalReferenceError(false);
                        setsubmittalReferenceMessage(null);
                      }
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={11} marginTop={1}>
                  <Button
                    fullWidth
                    component="label"
                    className={classes["UploadfieldDesign"]}
                    sx={{ backgroundColor: "#f3f3f3" }}
                  >
                    <Grid container item xs={12}>
                      <Grid
                        item
                        xs={11}
                        className={classes["Upload-Submittal-Response"]}
                      >
                        Upload File
                      </Grid>
                      <Grid item xs={1} paddingTop={0.5}>
                        <img src={uploadIcon} alt={"Upload"} />
                      </Grid>
                    </Grid>

                    <input
                      disabled={disableAllFields}
                      type="file"
                      hidden
                      onChange={(e) => {
                        handleFileChange(e);
                      }}
                    />
                  </Button>
                </Grid>
                {loadingFile ?
                  <Grid item xs={12} padding={3} className={classes["field-title-disabled"]}>
                    Uploading ... &nbsp; <CircularProgress size={18} />
                  </Grid>
                  :
                  <></>
                }
                {files?.map((item) => (
                  <Grid
                    container
                    direction={"row"}
                    key={item?.FileId}
                    value={item}
                    paddingTop={2}
                    alignItems="center"
                  >
                    <Grid item xs={1} paddingLeft={1}>
                      <img src={fileIcon} alt="file" width={15} />
                    </Grid>
                    <Grid
                      item
                      xs={2.5}
                      fontSize={{ xs: 10, md: 14 }}
                      onMouseOver={(e) => {
                        e.target.style.color = "grey";
                        e.target.style.cursor = "pointer";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.color = "black";
                      }}
                      onClick={() => {
                        window.open(item.URL);
                      }}
                    >
                      <span>{item?.DisplayName.slice(0, 11)}</span>
                    </Grid>
                    <Grid
                      item
                      paddingLeft={2}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                      onClick={() => {
                        handleFileDelete(item.FileId);
                      }}
                    >
                      <Grid item xs={1}>
                        <CloseIcon fontSize="small" />
                      </Grid>
                    </Grid>
                    {deletingFile ? <Grid item className={classes["field-title-disabled"]} paddingLeft={1}> Deleting ... &nbsp; <CircularProgress size={18} /></Grid> : <></>}

                  </Grid>
                ))}
              </Grid>
              {submittalStatus === 4 || submittalStatus === 5 ? (
                <Grid item container xs={12} paddingTop={2} gap={2}>
                  <Grid item xs={5} className={classes["field-title-disabled"]}>
                    Sheets
                  </Grid>
                  <Grid item xs={5} className={classes["field-title"]}>
                    Status
                  </Grid>
                  {nbOfSheets?.map((sheet, index) => (
                    <Grid
                      container
                      paddingLeft={0.5}
                      gap={2}
                      key={sheet.SheetNumber}
                    >
                      <Grid
                        item
                        className={classes["Rectangle-1464"]}
                        xs={5}
                        height={"50px"}
                      >
                        {index + 1}
                      </Grid>
                      <Grid item xs={5}>
                        <CustomSelect
                          disabled={disableAllFields}
                          value={sheet.StatusId}
                          onChange={(option) => {
                            sheet.StatusId = option;
                            forceUpdate();
                          }}
                          style={{ color: "#707070" }}
                        >
                          <StyledOption value={0} disabled>
                            Submittal Status
                          </StyledOption>
                          {submittalStatuses.map((s) => (
                            <StyledOption key={s.Id} value={s.Id}>
                              {s.Name}
                            </StyledOption>
                          ))}
                        </CustomSelect>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                ""
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              <Grid item className={classes["field-title-disabled"]} xs={12}>
                *Description
              </Grid>
              <Grid
                item
                className={classes["Rectangle-1465"]}
                xs={11}
                marginTop={1}
                height={"50px"}
              >
                {description}
              </Grid>
            </Grid>
          </Grid>
          {EDITRES?
            submittalStatus === 2 || submittalStatus === 3 ? (
            <Grid
              container
              item
              xs={11.5}
              direction={"row-reverse"}
              paddingTop={7}
            >
              <button
                disabled={disableAllFields || loadingFile || deletingFile}
                className={classes["SaveButton"]}
                onClick={() => {
                  createTheSubmitalResponse();
                }}
              >
                Save
              </button>
            </Grid>
          ) : submittalStatus === 4 || submittalStatus === 5 ? (
            <Grid container item paddingTop={3}>
              <button
                disabled={disableAllFields}
                className={classes["AcceptButton"]}
                onClick={() => {
                  createTheSubmitalResponse();
                }}
              >
                Revise
              </button>
            </Grid>
          ) : (
            ""
          ):
          ""}
        </Grid>
      </Grid>
    </div>
  );
};
const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
    font-family: Muli;
    font-size: 17px;
    box-sizing: border-box;
    width: 100%;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid #f3f3f3;
    border-radius: 5px;
    text-align: left;
    color: #bcbcbc;
    padding:13px;
    &:hover {
      background: ${theme.palette.mode === "dark" ? "" : grey[100]};
      border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }
  
    &.${selectUnstyledClasses.focusVisible} {
      outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]
    };
    }
  
    &.${selectUnstyledClasses.expanded} {
      &::after {
        content: '▴';
      }
    }
  
    &::after {
      content: '▾';
      float: right;
    }
    `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
    font-family: Muli;
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 5px;
    margin: 10px 0;
    min-width: 11rem;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
    border-radius: 0.75em;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    overflow: auto;
    outline: 0px;
    `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
    list-style: none;
    padding: 8px;
    border-radius: 0.45em;
    cursor: default;
    
    &:last-of-type {
      border-bottom: none;
    }
  
    &.${optionUnstyledClasses.selected} {
      background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]
    };
      color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
    }
  
    &.${optionUnstyledClasses.highlighted} {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]
    };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
  
    &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
      background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]
    };
      color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
    }
  
    &.${optionUnstyledClasses.disabled} {
      color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }
  
    &:hover:not(.${optionUnstyledClasses.disabled}) {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]
    };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
    `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,

    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});

export default SubmittalResponse;
