import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import close from "../../../Assets/Images/form/x.png";
import infoTip from "../../../Assets/Images/form/info.png";
import { styled } from "@mui/material/styles";
import { MenuItem, IconButton } from "@mui/material";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import useWindowDimensions from "../../Navbar/WindowDimension";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#707070",
    },
  },
});
const BlueTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
      color: #101650;
      background-color: rgba(6, 116, 185, 0.3);
      font-size: 13px;
      width:180px;
   
  `);
const BlueTip2 = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
        color: #fff;
        background-color: rgba(6, 116, 185, 0.7);
        font-size: 13px;
        font-family: Muli;
        width:180px;
     
    `);

function Form1({
  continues,
  countries,
  info,
  highlightMsg,
  highlightError,
  setHighlightError,
  tenantId,
  registrationEmail,
  setRegistrationEmail,
}) {
  const style = {
    blueTip: {
      width: 200,
    },
  };

  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfimPassword, setShowConfirmPassword] = useState(false);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [country, setCountry] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [companyError, setCompanyError] = useState(false);
  const [companyInvalid, setCompanyInvalid] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [email, setEmail] = useState("");

  const [emailInvalid, setEmailInvalid] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [confirmPasswordInvalid, setConfirmPasswordInvalid] = useState("");
  const [countryError, setCountryError] = useState(false);
  const [registrationNumberError, setRegistrationNumberError] = useState(false);
  const [registrationNumberInvalid, setRegistrationNumberInvalid] =
    useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [mobileTip, setMobileTip] = useState(false);

  useEffect(() => {
    if (tenantId) {
      if (
        (info.Name !== "") &
        (registrationEmail !== "") &
        (info.User.Password !== "") &
        (info.User.ConfirmPassword !== "") &
        (info.CountryId !== "") &
        (info.RegistrationNumber !== "") &
        (companyError !== true) &
        (companyInvalid !== true) &
        (passwordError !== true) &
        (passwordInvalid !== true) &
        (confirmPasswordError !== true) &
        (confirmPasswordInvalid !== true) &
        (countryError !== true) &
        (registrationNumberError !== true) &
        (registrationNumberInvalid !== true) &
        (emailError !== true) &
        (emailInvalid !== true)
      ) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else {
      if (
        (info.Name !== "") &
        (info.User.Email !== "") &
        (info.User.Password !== "") &
        (info.User.ConfirmPassword !== "") &
        (info.CountryId !== "") &
        (info.RegistrationNumber !== "") &
        (companyError !== true) &
        (companyInvalid !== true) &
        (passwordError !== true) &
        (passwordInvalid !== true) &
        (confirmPasswordError !== true) &
        (confirmPasswordInvalid !== true) &
        (countryError !== true) &
        (registrationNumberError !== true) &
        (registrationNumberInvalid !== true) &
        (emailError !== true) &
        (emailInvalid !== true)
      ) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
  });
  useEffect(() => {
    if (width < 800) {
      setMobileTip(true);
    }
  });

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleClickConfirmShowPassword = () =>
    setShowConfirmPassword(!showConfimPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfimPassword);
  const openTip = () => setTooltipIsOpen(true);
  const closeTip = () => setTooltipIsOpen(false);
  useEffect(() => {
    if (highlightError === true) {
      setEmailError(true);
    }
  }, [highlightError]);

  return (
    <Box sx={{ flexGrow: 1 }} className="RegBox">
      <Grid item container md={12} spacing={2}>
        <Grid item container md={12} xs={12} direction={"row"}>
          <Grid item md={9} xs={11}>
            <div className="Join-Pilecubes"> Join Pilecubes</div>
          </Grid>

          <Grid item md={3} xs={1} container direction={"row-reverse"}>
            <IconButton
              onClick={(e) => {
                navigate("/");
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item container md={12} direction={"column"} spacing={3}>
          <Grid item>
            <CssTextField
              label="Company Name"
              value={info.Name}
              size="small"
              className="textField"
              InputLabelProps={{
                style: {
                  fontFamily: "Muli",
                  fontSize: "16px",
                },
              }}
              error={companyError}
              helperText={
                companyError
                  ? companyInvalid
                    ? "Company name must be less than 150 characters"
                    : "Enter Company Name"
                  : ""
              }
              onChange={(e) => {
                setCompanyName(e.target.value);
                info.Name = e.target.value;

                if (info.Name === "") {
                  setCompanyError(true);
                  setCompanyInvalid(false);
                } else if ((info.Name !== "") & (info.Name.length > 150)) {
                  setCompanyError(true);
                  setCompanyInvalid(true);
                } else {
                  setCompanyError(false);
                  setCompanyInvalid(false);
                }
              }}
            />
          </Grid>
          <Grid item>
            <CssTextField
              label="Registration Email"
              size="small"
              value={tenantId ? registrationEmail : info.User.Email}
              className="textField"
              InputLabelProps={{
                style: {
                  fontFamily: "Muli",
                  fontSize: "16px",
                },
              }}
              disabled={tenantId ? true : false}
              error={emailError}
              helperText={
                emailError
                  ? emailInvalid
                    ? "Enter a valid email"
                    : highlightError
                    ? `${highlightMsg}`
                    : "Enter an email address"
                  : ""
              }
              onChange={(e) => {
                setEmail(e.target.value);
                if (tenantId) setRegistrationEmail(e.target.value);
                else {
                  info.User.Email = tenantId
                    ? registrationEmail
                    : e.target.value;
                }
                if (info.User.Email === "") {
                  setEmailError(true);
                  setEmailInvalid(false);
                } else if (
                  (info.User.Email !== "") &
                    !info.User.Email.match(
                      /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/
                    ) ||
                  info.User.Email.length > 64
                ) {
                  setEmailError(true);
                  setEmailInvalid(true);
                } else {
                  setEmailError(false);
                  setEmailInvalid(false);
                }
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    edge="end"
                    onClick={openTip}
                    onMouseOver={openTip}
                    onMouseLeave={closeTip}
                  >
                    {mobileTip ? (
                      <BlueTip2
                        open={tooltipIsOpen}
                        title={
                          "This email will be used to sign in and manage your account"
                        }
                        sx={style.blueTip}
                        placement="top-start"
                      >
                        <img width={"20px"} src={infoTip} alt={close}></img>
                      </BlueTip2>
                    ) : (
                      <BlueTip
                        open={tooltipIsOpen}
                        title={
                          "This email will be used to sign in and manage your account"
                        }
                        placement="right"
                      >
                        <img width={"20px"} src={infoTip} alt={close}></img>
                      </BlueTip>
                    )}
                  </IconButton>
                ),
              }}
            ></CssTextField>
          </Grid>

          <Grid item>
            <CssTextField
              type={showPassword ? "text" : "password"}
              label="Password"
              size="small"
              className="textField"
              value={info.User.Password}
              InputLabelProps={{
                style: {
                  fontFamily: "Muli",
                  fontSize: "16px",
                },
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <Visibility htmlColor="rgba(6, 116, 185, 0.5)" />
                    ) : (
                      <VisibilityOff htmlColor="rgba(6, 116, 185, 0.5)" />
                    )}
                  </IconButton>
                ),
              }}
              error={passwordError}
              helperText={
                passwordError
                  ? passwordInvalid
                    ? "Password must contain a number, a symbol, an uppercase & lowercase and be no less than 8  or more than 20 characters."
                    : "Enter a password"
                  : ""
              }
              onChange={(e) => {
                setPassword(e.target.value);
                info.User.Password = e.target.value;
                if (info.User.Password === "") {
                  setPasswordError(true);
                  setPasswordInvalid(false);
                } else if (
                  (info.User.Password !== "") &
                    !validator.isStrongPassword(info.User.Password, {
                      minLength: 8,
                      minLowercase: 1,
                      minUppercase: 1,
                      minNumbers: 1,
                      minSymbols: 1,
                    }) ||
                  info.User.Password.length > 20
                ) {
                  if (info.User.ConfirmPassword !== "") {
                    setConfirmPasswordError(true);
                    setConfirmPasswordInvalid(true);
                  }
                  setPasswordError(true);
                  setPasswordInvalid(true);
                } else {
                  if (info.User.ConfirmPassword !== info.User.Password) {
                    setConfirmPasswordError(true);
                    setConfirmPasswordInvalid(true);
                  } else {
                    setConfirmPasswordError(false);
                    setConfirmPasswordInvalid(false);
                  }
                  setPasswordError(false);
                  setPasswordInvalid(false);
                }
              }}
            />
          </Grid>
          <Grid item>
            <CssTextField
              label="Confirm Password"
              type={showConfimPassword ? "text" : "password"}
              size="small"
              value={info.User.ConfirmPassword}
              className="textField"
              InputLabelProps={{
                style: {
                  fontFamily: "Muli",
                  fontSize: "16px",
                },
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickConfirmShowPassword}
                    onMouseDown={handleMouseDownConfirmPassword}
                    edge="end"
                  >
                    {showConfimPassword ? (
                      <Visibility htmlColor="rgba(6, 116, 185, 0.5)" />
                    ) : (
                      <VisibilityOff htmlColor="rgba(6, 116, 185, 0.5)" />
                    )}
                  </IconButton>
                ),
              }}
              error={confirmPasswordError}
              helperText={
                confirmPasswordError
                  ? confirmPasswordInvalid
                    ? "Password didn't match, try again"
                    : "Confirm Password"
                  : ""
              }
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                info.User.ConfirmPassword = e.target.value;

                if (info.User.ConfirmPassword === "") {
                  setConfirmPasswordError(true);
                  setConfirmPasswordInvalid(false);
                } else if (info.User.ConfirmPassword !== info.User.Password) {
                  setConfirmPasswordError(true);
                  setConfirmPasswordInvalid(true);
                } else {
                  setConfirmPasswordError(false);
                  setConfirmPasswordInvalid(false);
                }
              }}
            />
          </Grid>
          <Grid item>
            <CssTextField
              size="small"
              label="Country"
              className="textField"
              value={info.CountryId}
              InputLabelProps={{
                style: {
                  fontFamily: "Muli",
                  fontSize: "16px",
                },
              }}
              select
              error={countryError}
              helperText={countryError ? "Select a country" : ""}
              onChange={(e) => {
                setCountry(e.target.value);
                info.CountryId = e.target.value;
                if (info.CountryId === "") {
                  setCountryError(true);
                } else {
                  setCountryError(false);
                }
              }}
            >
              {countries.map((option) => (
                <MenuItem value={option.Id} key={option.Id}>
                  {option.Name}
                </MenuItem>
              ))}
            </CssTextField>
          </Grid>
          <Grid item>
            <CssTextField
              label="Registration Number"
              size="small"
              className="textField"
              value={info.RegistrationNumber}
              InputLabelProps={{
                style: {
                  fontFamily: "Muli",
                  fontSize: "16px",
                },
              }}
              error={registrationNumberError}
              helperText={
                registrationNumberError
                  ? registrationNumberInvalid
                    ? "Registration number must be less than 30 characters"
                    : "Enter a registration number"
                  : ""
              }
              onChange={(e) => {
                setRegistrationNumber(e.target.value);
                info.RegistrationNumber = e.target.value;
                if (info.RegistrationNumber === "") {
                  setRegistrationNumberError(true);
                  setRegistrationNumberInvalid(false);
                } else if (
                  (info.RegistrationNumber !== "") &
                  (info.RegistrationNumber.length > 30)
                ) {
                  setRegistrationNumberError(true);
                  setRegistrationNumberInvalid(true);
                } else {
                  setRegistrationNumberError(false);
                  setRegistrationNumberInvalid(false);
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid item container md={12} direction={"row"}>
          <Grid item md={6} xs={6}>
            <button
              className="buttonNext1"
              disabled={isDisabled}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#101650";
                e.target.style.cursor = "pointer";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#0b5cab";
              }}
              onClick={(e) => {
                continues(e);
                setHighlightError(false);
              }}
              variant="contained"
            >
              Next
            </button>
          </Grid>
          <Grid item md={6} xs={6} container direction={"row-reverse"}>
            <div className="step-1-of-3">step 1 of 3</div>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Form1;
