import React, { useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import "../Assets/Styles/home.css";
import SalesProjectListCards from '../Components/Sales/SalesProjectListCards';

import SaasBack from "../Assets/Images/ProfileDetails/SaaSbackground.png";

export default function Sales({ setRegister, setNaved }) {
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    let apiTest = true;
    if (localStorage.getItem("accessToken") && apiTest) {
      if (localStorage.getItem("isVerified") === "false") {
        if (localStorage.getItem("isTeamMember") === "true")
          navigate("/teamMemberRegistration");
        else {
          if (localStorage.getItem("AccountType") === "1") navigate("/main");
          if (localStorage.getItem("AccountType") === "2") navigate("/sub");
        }
      }
    } else navigate("/login");
    return () => {
      apiTest = false;
    };
  }, [navigate]);

  useEffect(() => {
    setRegister(true);
  }, [setRegister]);

  useEffect(() => {
    setNaved(false);
  }, [setNaved]);
  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);

  return (
    <div
    style={{
      minHeight: "100vh",
      backgroundImage: `url(${SaasBack})`,
      backgroundSize: "cover",
    }}
  >
<SalesProjectListCards/>
</div>
  )
  ;
}
