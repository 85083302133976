import React, { useState, useEffect } from "react";
import ProfilePicture from "../../Components/Profile/ProfileDetails/ProfilePicture";
import classes from "../../Assets/Styles/profileDetails.module.css";
import { Grid } from "@mui/material";
import ProfileTitle from "../../Components/Profile/ProfileDetails/ProfileTitle";
import CompanyInfo from "./ProfileDetails/CompanyInfo";
import SaaSBackground from "../../Assets/Images/ProfileDetails/SaaSbackground.png";
import * as profileServices from "../../Services/profile-services.proxy";
import * as teamMemberProfileServices from "../../Services/saas-services.proxy";
import "../../Assets/Styles/home.css";
import { useNavigate } from "react-router-dom";
const ContarctorDetails = profileServices.ContarctorDetails;
const TeamMemberDetails = teamMemberProfileServices.TeamMemberDetails;
const ProfileDetails = ({ setProfileSrc, setRegister, setNaved }) => {
  const navigate = useNavigate();

  useEffect(() => {
    let apiTest = true;
    if (localStorage.getItem("accessToken") && apiTest) {
      if (localStorage.getItem("isVerified") === "false") {
        if (localStorage.getItem("isTeamMember") === "true")
          navigate("/teamMemberRegistration");
        else {
          if (localStorage.getItem("AccountType") === "1") navigate("/main");
          if (localStorage.getItem("AccountType") === "2") navigate("/sub");
        }
      }
    }
    else navigate('/login');
    return () => {
      apiTest = false;
    }
  }, [navigate]);

  useEffect(() => {
    setRegister(true);
  }, [setRegister]);

  useEffect(() => {
    setNaved(false);
  }, [setNaved]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [companyName, setCompanyName] = useState("PileCubes");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [companySlogan, setCompanySlogan] = useState("");
  const [pictureSrc, setPictureSrc] = useState();
  const [companyDetails, setCompanyDetails] = useState({});
  const [fileId, setFileId] = useState(null);

  useEffect(() => {
    let apiTest = true;

    if (localStorage.getItem("isTeamMember") === "true") {
      TeamMemberDetails().then((x) => {
        if (apiTest) {
          setCompanyDetails(x);
          setCompanyName(x.Name);
          if (x?.Url) {
            setPictureSrc(x.Url);
          }

        }
      });
    } else {
      ContarctorDetails().then((x) => {
        if (apiTest) {
          setCompanyDetails(x);
          setCompanySlogan(x.Slogan);
          setCompanyWebsite(x.Website);
          if (x.UserDetails) {
            if (x.UserDetails.ProfilePicture)
              setPictureSrc(x.UserDetails.ProfilePicture?.URL);
            if (x.UserDetails.ProfilePicture)
              setFileId(x.UserDetails.ProfilePicture?.FileId);
          }
        }
      });
    }
    // if (companyDetails) {
    //   setCompanyName(companyDetails.CompanyName);
    //   setCompanySlogan(companyDetails.Slogan);
    //   setCompanyWebsite(companyDetails.Website);
    //   if (companyDetails.UserDetails) {
    //     if (companyDetails.UserDetails.ProfilePicture)
    //       setPictureSrc(companyDetails.UserDetails.ProfilePicture?.URL);
    //     if (companyDetails.UserDetails.ProfilePicture)
    //       setFileId(companyDetails.UserDetails.ProfilePicture?.FileId);
    //   }
    //   if (localStorage.getItem("isTeamMember") === "true") {
    //     if (companyDetails?.Url) {
    //       setPictureSrc(companyDetails.Url);
    //     }
    //   }
    // }
    return () => {
      apiTest = false;
    };
  }, [
    companyDetails.companyName,
    companyDetails.Slogan,
    companyDetails.Website,
  ]);
  return (
    <div className="Rectangle779">
      {companyDetails ? (
        <div>
          <Grid container justifyContent={"center"}>
            <Grid item container justifyContent={"center"} marginTop={16}>
              <Grid
                className={classes["Rectangle-955"]}
                container
                item
                md={8}
                xs={11}
              >
                <Grid item container>
                  <ProfilePicture
                    pPicture={pictureSrc}
                    setPictureSrc={setPictureSrc}
                    fileID={fileId}
                    setProfileSrc={setProfileSrc}
                  />
                </Grid>
                <Grid item container marginLeft={17} paddingBottom={11}>
                  <ProfileTitle
                    companyName={companyName}
                    companyWebsite={companyWebsite}
                    companySlogan={companySlogan}
                    setCompanyWebsite={setCompanyWebsite}
                    setCompanySlogan={setCompanySlogan}
                  />
                </Grid>
              </Grid>
              <Grid
                className={classes["Rectangle-962"]}
                marginTop={2}
                item
                container
                md={8}
                xs={11}
              >
                <CompanyInfo companyDetails={companyDetails} />
              </Grid>
            </Grid>
          </Grid>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ProfileDetails;
