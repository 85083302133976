//factory code list

export const GET_FACTORIES_CODES = "/factory/factories-code-list";
export const GET_OPTIMIZATION_CALENDAR =
  "/route/sales-order-combination-calendar";
export const CREATE_COMBINATIONS_SALES_ORDER = "/route/route-optimization";
export const GET_COMBINATIONS_SALES_ORDER =
  "/route/get-sales-order-combination";
export const ASSIGNING_ROUTE = "/route/assign-route";

//machines optimization list
export const MACHINES_OPTIMIZATIONLIST = "/optimization-runs/machine-list";

//MACINE OCCUPATION

export const MACHINE_OCCUPATION = "/optimization-runs/machine-occupation";

//OPTMIZATION RUN
export const MACHINE_OPTIMIZATION = "/optimization-runs/machine-opimization";
//OPTMIZATION RUN
export const VALIDATE_OPTIMIZATION = "/optimization-runs/validate-opimization";

export const VALIDATE_PRODUCTION_RUN = "/optimization-runs/validate-production-run";
export const DELETE_COMBINATION = "/optimization-runs/delete-combination";
