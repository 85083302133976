import axios from "axios";
import * as constFile from "../../DeliveryConsts";

export const GET_DELIVERY_CALENDAR = constFile.GET_DELIVERY_CALENDAR;

export const getDeliveryCalendar = (Date, FactoryId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_DELIVERY_CALENDAR}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        Date: Date,
        FactoryId: FactoryId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const GET_INPUT_DATA = constFile.GET_INPUT_DATA;

export const getInputData = (shipmentId, factoryId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_INPUT_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        ShipmentId: shipmentId,
        FactoryId: factoryId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const CREATE_SHIPMENT = constFile.CREATE_SHIPMENT;

export const createShipment = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + CREATE_SHIPMENT}`,
      data,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const UPDATE_SHIPMENT = constFile.UPDATE_SHIPMENT;

export const updateShipment = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + UPDATE_SHIPMENT}`,
      data,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const DELETE_SHIPMENT = constFile.DELETE_SHIPMENT;

export const deleteShipment = (shipmentId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + DELETE_SHIPMENT}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        shipmentId: shipmentId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const DELIVERY_NOTE_BY_ID = constFile.DELIVERY_NOTE_BY_ID;

export const getDeliveryNoteById = (ShipmentId, DeliveryNoteId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + DELIVERY_NOTE_BY_ID}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        ShipmentId: ShipmentId,
        DeliveryNoteId: DeliveryNoteId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};
export const DELIVERY_NOTE_SALES_ORDER_DATA =
  constFile.DELIVERY_NOTE_SALES_ORDER_DATA;

export const getDeliveryNoteSalesOrderData = (DeliveryNoteId, SalesOrderId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + DELIVERY_NOTE_SALES_ORDER_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        DeliveryNoteId: DeliveryNoteId,
        SalesOrderId: SalesOrderId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const CONFIRM_DELIVERY_NOTE = constFile.CONFIRM_DELIVERY_NOTE;

export const confirmDeliveryNote = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + CONFIRM_DELIVERY_NOTE}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const GET_DELIVERY_NOTE_CARD_LIST =
  constFile.GET_DELIVERY_NOTE_CARD_LIST;

export const getDeliveryNoteCardList = (OrderId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_DELIVERY_NOTE_CARD_LIST}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        OrderId: OrderId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const DELIVERY_NOTE_PRINT_DATA = constFile.DELIVERY_NOTE_PRINT_DATA;

export const getDeliveryNotePrintData = (deliveryNoteId, labelId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + DELIVERY_NOTE_PRINT_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        deliveryNoteId: deliveryNoteId,
        labelId: labelId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const GET_DELIVERY_NOTE_PREVIEW = constFile.GET_DELIVERY_NOTE_PREVIEW;
export const getDeliveryNotePreview = (Id, UserId) => {
  return axios
    .get(`${process.env.REACT_APP_URL + GET_DELIVERY_NOTE_PREVIEW}`, {
      params: {
        Id: Id,
        UserId: UserId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

const UPLOAD_FILE = constFile.UPLOAD_FILE;
export const uploadFile = (file) => {
  const data = new FormData();
  data.append("File", file);
  return axios
    .post(`${process.env.REACT_APP_URL + UPLOAD_FILE}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const DELETE_FILE = constFile.DELETE_FILE;
export const deleteFile = (fileId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + DELETE_FILE}`, {
      params: {
        FileId: fileId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const CONFIRM_DELIVERY = constFile.CONFIRM_DELIVERY;

export const confirmDelivery = (Id, FileId) => {
  return axios
    .post(`${process.env.REACT_APP_URL + CONFIRM_DELIVERY}`, {
      Id: Id,
      FileId: FileId,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const NEXT_DELIVER_NOTE = constFile.NEXT_DELIVER_NOTE;

export const nextdeliveryNote = (DeliveryNoteId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + NEXT_DELIVER_NOTE}`,
      { Id: DeliveryNoteId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};
export const SET_DELIVERY_NOTE_DATE = constFile.SET_DELIVERY_NOTE_DATE;

export const setDeliveryNoteDate = (DeliveryNoteId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(
      `${process.env.REACT_APP_URL + SET_DELIVERY_NOTE_DATE}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          Id: DeliveryNoteId,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};
