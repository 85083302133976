import React from "react";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { Grid } from "@mui/material";
import { NavLink } from 'react-router-dom'
import insta1 from "../Assets/Images/footer/instaR.png";
import linkedin from "../Assets/Images/footer/linkedinR.png";

export default function CopyRightMobile() {
  return (
    <div
      style={{
        backgroundColor: "#101650",
        fontFamily: "Muli",
        color: "#fff",
        paddingBottom: "10%",
        paddingTop: "5%",
        fontSize: 12,
      }}
    >
      <Grid container direction={"column"} spacing={0.5} alignItems={"center"}>
        <Grid item container direction={"row"} justifyContent={"center"}>
          <Grid item>
            <CopyrightIcon fontSize="small" />
          </Grid>
          <Grid item> Copyright {(new Date().getFullYear())} Pilecubes - All Rights Reserved. </Grid>
        </Grid>
        <Grid item container spacing={2} justifyContent={"center"}>
          <Grid item>Have a question ?</Grid>
          <Grid item>
            <a style={{ color: "#fff" }} href="mailto:qapilecubes@digitalprestige.com">
              support@pilecubes.com
            </a>
          </Grid>
        </Grid>

        <Grid item marginTop={4}>
          <NavLink
            to="/terms-and-conditions"
            style={{ color: "#fff" }}
          >
            Terms & Conditions
          </NavLink>
        </Grid>
        <Grid
          item
          container
          direction={"row"}
          spacing={1}
          justifyContent={"center"}
          marginTop={2}
        >
          <Grid item>
            <img src={linkedin} alt={"LinkdIn"} />
          </Grid>
          <Grid item>
            <img src={insta1} alt={"Insta"} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
