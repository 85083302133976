import React, { useEffect, useState } from "react";
import Logo from "../Assets/Images/Navbar/logo.png";
import { Grid } from "@mui/material";
import insta1 from "../Assets/Images/footer/insta.png";
import instaYellow from "../Assets/Images/footer/instaYellow.png";
import linkedin from "../Assets/Images/footer/linkedin.png";
import linkedinYellow from "../Assets/Images/footer/linkedinYellow.png";
import window from "../Components/Navbar/WindowDimension";
import { NavLink } from "react-router-dom";

export default function Footer({ isLoggedIn }) {
  const [mobile, setMobile] = useState(false);
  const { width } = window();

  useEffect(() => {
    if (width < 900) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [setMobile, mobile]);

  return (
    <div>
      {mobile ? (
        <div></div>
      ) : (
        <Grid
          container
          spacing={4}
          style={{
            marginTop: "5%",
            fontFamily: "Muli",
            paddingLeft: "10%",
            marginBottom: "10%",
          }}
        >
          <Grid item xs={12} md={3} container direction={"column"} spacing={2}>
            <Grid item>
              <NavLink to="/">
                <img src={Logo} alt="logo"></img>
              </NavLink>
            </Grid>
            <Grid item container spacing={1} style={{ marginTop: "5%" }}>
              <Grid item>
                <img
                  src={linkedin}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                    e.target.src = linkedinYellow;
                  }}
                  onMouseLeave={(e) => {
                    e.target.src = linkedin;
                  }}
                  alt={"Linkedin"}
                />
              </Grid>
              <Grid item>
                <img
                  src={insta1}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                    e.target.src = instaYellow;
                  }}
                  onMouseLeave={(e) => {
                    e.target.src = insta1;
                  }}
                  alt={"Instagram"}
                />
              </Grid>
            </Grid>
            <Grid item>
              <a
                style={{
                  color: "#101650",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
                href={process.env.REACT_APP_INFO}
              >
                info@pilecubes.com
              </a>
            </Grid>
          </Grid>
          {isLoggedIn ? (
            ""
          ) : (
            <Grid item xs={3} container direction={"column"} spacing={2}>
              <Grid item style={{ fontWeight: "bold" }}>
                PRODUCT
              </Grid>
              <Grid item className="footerText">
                <NavLink to="/main"
                  style={{ textDecoration: "none", color: "#101650" }}
                >
                  Main Contractor
                </NavLink>
              </Grid>
              <Grid item className="footerText">
                <NavLink to="/sub"
                  style={{ textDecoration: "none", color: "#101650" }}
                >
                  Sub Contractor
                </NavLink>
              </Grid>
            </Grid>
          )}

          <Grid item xs={3} container direction={"column"} spacing={2}>
            <Grid item style={{ fontWeight: "bold" }}>
              COMPANY
            </Grid>
            <Grid item className="footerText">
              <NavLink to="/WhyPilecubes"
                style={{ textDecoration: "none", color: "#101650" }}
              >
                Why Pilecubes
              </NavLink>
            </Grid>
            <Grid item className="footerText">
              <NavLink to="/contactUs"
                style={{ textDecoration: "none", color: "#101650" }}
              >
                Contact us
              </NavLink>
            </Grid>
            <Grid item className="footerText">
              <NavLink to="/pricing"
                style={{ textDecoration: "none", color: "#101650" }}
              >
                Pricing
              </NavLink>
            </Grid>
          </Grid>
          <Grid item xs={3} container direction={"column"} spacing={2}>
            <Grid item style={{ fontWeight: "bold" }}>
              LEGAL
            </Grid>
            <Grid item className="footerText">
              <NavLink to="/terms-and-conditions" style={{ textDecoration: "none", color: "#101650" }}>
                Terms & Conditions
              </NavLink>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
