import React, { useState, useEffect } from "react";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { Grid } from "@mui/material";
import { NavLink } from 'react-router-dom'
import CopyRightMobile from "./CopyRightMobile";
import window from "../Components/Navbar/WindowDimension";

export default function CopyRight() {
  const [mobile, setMobile] = useState(false);
  const { width } = window();

  useEffect(() => {
    if (width < 900) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [setMobile, mobile]);
  return (
    <div>
      {mobile ? (
        <CopyRightMobile />
      ) : (
        <div
          style={{
            backgroundColor: "#101650",
            fontFamily: "Muli",
            color: "#fff",
            paddingBottom: "1%",
            paddingTop: "2%",
            fontSize: 14,
          }}
        >
          <Grid container direction={"row"} spacing={1} paddingLeft={"10%"}>
            <Grid item>
              <CopyrightIcon fontSize="small" />
            </Grid>
            <Grid item> Copyright {(new Date().getFullYear())} Pilecubes - All Rights Reserved. </Grid>
            <Grid item>
              <NavLink
                to="/terms-and-conditions"
                style={{ color: "#fff" }}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
              >
                Terms & Conditions
              </NavLink>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
}
