import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import ClientTable from "./Client/ClientTable";
import DocumentTable from "./Document/DocumentTable";
import { useNavigate } from "react-router-dom";
import McTable from "./MC/McTable";
import ScTable from "./SC/ScTable";
import UserRolesTable from "./UserRoles/UserRolesTable";
import classes from "../../Assets/Styles/AdminDashboard.module.css";
import CloseIcon from "@mui/icons-material/Close";
import TeamMemberTable from "../Admin/TeamMember/TeamMemberTable";
import { useSelector } from "react-redux";
import ProductionLabelTypes from "./Production Label/ProductionLabelTypes";
import InventoryMainView from "./Inventory Setup/InventoryMainView";
import AdminViewTransport from "./Transport/AdminViewTransport";
import * as permissionFunctions from "../../../ReusableFunctions";

const CheckIfPermitted = permissionFunctions.CheckIfPermitted;

const AdminDashboard = (props) => {
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  const [disabledMS, setDisabledMS] = useState(false);
  const [disabledC, setDisabledC] = useState(false);
  const [disabledTM, setDisabledTM] = useState(false);
  const [disabledUR, setDisabledUR] = useState(false);
  const [disabledD, setDisabledD] = useState(false);
  const [disabledTrans, setDisabledTrans] = useState(false);
  const [disabledLabel, setDisabledLabel] = useState(false);
  const [disabledInventory, setDisabledInventory] = useState(false);

  useEffect(() => {
    if (UserPermissions) {
      CheckIfPermitted(UserPermissions, "Client", "View", setDisabledC);
      CheckIfPermitted(UserPermissions, "Document", "View", setDisabledD);
      CheckIfPermitted(
        UserPermissions,
        "Sub Contractor",
        "View",
        setDisabledMS
      );
      CheckIfPermitted(
        UserPermissions,
        "Main Contractor",
        "View",
        setDisabledMS
      );
      CheckIfPermitted(UserPermissions, "Team Member", "View", setDisabledTM);
      CheckIfPermitted(UserPermissions, "User Role", "View", setDisabledUR);
      CheckIfPermitted(
        UserPermissions,
        "Trucks, Trailers, Drivers",
        "View",
        setDisabledTrans
      );
      CheckIfPermitted(
        UserPermissions,
        "Label Management",
        "View",
        setDisabledLabel
      );
      CheckIfPermitted(
        UserPermissions,
        "Inventory Set-Up",
        "View",
        setDisabledInventory
      );
    }
  }, [UserPermissions]);

  useEffect(() => {
    let apiTest = true;
    if (
      apiTest &&
      ((localStorage.getItem("isTeamMember") === "true" &&
        !UserPermissions?.find((x) => x.Name === "Admin")) ||
        (!localStorage.getItem("planId") &&
          !localStorage.getItem("planIdManu")))
    )
      setDisabledUR(true);
    else setDisabledUR(false);
    return () => {
      apiTest = false;
    };
  }, [UserPermissions]);

  useEffect(() => {
    let apiTest = true;
    if (
      apiTest &&
      ((localStorage.getItem("isTeamMember") === "true" &&
        !UserPermissions?.find((x) => x.Name === "Admin") &&
        !UserPermissions?.find((x) => x.Name === "Project Manager") &&
        !UserPermissions?.find((x) => x.Name === "Engineering Manager") &&
        !UserPermissions?.find((x) => x.Name === "Team Leader")) ||
        (!localStorage.getItem("planId") &&
          !localStorage.getItem("planIdManu")))
    )
      setDisabledTM(true);
    else setDisabledTM(false);

    return () => {
      apiTest = false;
    };
  }, [UserPermissions]);

  useEffect(() => {
    let apiTest = true;
    if (
      apiTest &&
      localStorage.getItem("isTeamMember") === "true" &&
      !UserPermissions?.find((x) => x.Name === "Admin") &&
      !UserPermissions?.find((x) => x.Name === "Project Manager")
    ) {
      setDisabledC(true);
    } else setDisabledC(false);
    return () => {
      apiTest = false;
    };
  }, [UserPermissions]);

  useEffect(() => {
    let apiTest = true;
    if (
      apiTest &&
      localStorage.getItem("isTeamMember") === "true" &&
      !UserPermissions?.find((x) => x.Name === "Admin") &&
      !UserPermissions?.find((x) => x.Name === "Project Manager") &&
      !UserPermissions?.find((x) => x.Name === "Engineering Manager")
    )
      setDisabledMS(true);
    else setDisabledMS(false);
    return () => {
      apiTest = false;
    };
  }, [UserPermissions]);

  const navigate = useNavigate();
  const [isGuest, setIsGuest] = useState(localStorage.getItem("isGuest"));
  const [showMessage, setShowMessage] = useState(false);
  const AccountType = localStorage.getItem("AccountType");
  const [core, setCore] = useState(localStorage.getItem("planId"));

  return (
    <Grid
      container
      justifyContent="center"
      padding={"1% 0 0 0%"}
      rowSpacing={2}
      paddingBottom={5}
    >
      <Grid container paddingLeft={5} spacing={5} paddingTop={2}>
        {showMessage ? (
          <Grid
            item
            container
            bgcolor={"rgba(225, 227, 25, 0.5)"}
            md={12}
            paddingBottom={1}
          >
            <Grid item md={5}></Grid>
            <Grid md={5} className={classes["Upgrade-subscription-required"]}>
              {isGuest === "true"
                ? "subscription required"
                : core === "Core"
                ? "Upgrade subscription required"
                : ""}
            </Grid>
            <Grid md={2} container>
              <Grid
                item
                onClick={() => {
                  navigate("/saas/profileSettings");
                }}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
              >
                <button className={classes["Rectangle-1231"]}>Subscribe</button>
              </Grid>
              <Grid
                item
                paddingLeft={1}
                onClick={() => {
                  setShowMessage(false);
                }}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
              >
                <CloseIcon fontSize="medium" />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
        {disabledMS ? (
          UserPermissions?.map((up) =>
            up.Permissions.map((p) => {
              if (p.Name === "Main Contractor") {
                return localStorage.getItem("AccountType") === "2" ? (
                  <Grid
                    item
                    md={6}
                    xs={12}
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                    onClick={() => {
                      if (isGuest === "false") navigate("/saas/admin/Mc");
                    }}
                  >
                    <McTable
                      paging={true}
                      tableHeight={250}
                      showViewAll={true}
                      ShowGoBack={false}
                      viewSearch={false}
                      viewFilters={false}
                    />
                  </Grid>
                ) : (
                  <></>
                );
              } else return "";
            })
          )
        ) : (
          <>
            {localStorage.getItem("AccountType") === "2" ? (
              <Grid
                item
                md={6}
                xs={12}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={() => {
                  if (isGuest === "false") navigate("/saas/admin/Mc");
                }}
              >
                <McTable
                  paging={true}
                  tableHeight={250}
                  showViewAll={true}
                  ShowGoBack={false}
                  viewSearch={false}
                  viewFilters={false}
                />
              </Grid>
            ) : (
              <></>
            )}
          </>
        )}

        {/* ClientTable */}
        {disabledC ? (
          UserPermissions?.map((up) =>
            up.Permissions.map((p) => {
              if (p.Name === "Client") {
                return (
                  <Grid
                    item
                    md={6}
                    xs={12}
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                    onClick={() => {
                      if (isGuest === "false") navigate("/saas/admin/client");
                      else setShowMessage(true);
                    }}
                  >
                    <ClientTable
                      paging={true}
                      tableHeight={250}
                      showViewAll={true}
                      ShowGoBack={false}
                      viewSearch={false}
                    />
                  </Grid>
                );
              } else return "";
            })
          )
        ) : (
          <Grid
            item
            md={6}
            xs={12}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            onClick={() => {
              if (isGuest === "false") navigate("/saas/admin/client");
              else setShowMessage(true);
            }}
          >
            <ClientTable
              paging={true}
              tableHeight={250}
              showViewAll={true}
              ShowGoBack={false}
              viewSearch={false}
            />
          </Grid>
        )}
        {/* ClientTable */}
        {/* SubContractor */}
        {disabledMS ? (
          UserPermissions?.map((up) =>
            up.Permissions.map((p) => {
              if (p.Name === "Sub Contractor") {
                return (
                  <Grid
                    item
                    md={6}
                    xs={12}
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                    onClick={() => {
                      if (isGuest === "false" && core !== "Core") {
                        navigate("/saas/admin/Sc");
                      } else {
                        window.scrollTo(0, 0);
                        setShowMessage(true);
                      }
                    }}
                  >
                    <ScTable
                      paging={true}
                      tableHeight={250}
                      showViewAll={true}
                      ShowGoBack={false}
                      viewSearch={false}
                      viewFilters={false}
                    />
                  </Grid>
                );
              } else return "";
            })
          )
        ) : (
          <>
            <Grid
              item
              md={6}
              xs={12}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onClick={() => {
                if (isGuest === "false" && core !== "Core") {
                  navigate("/saas/admin/Sc");
                } else {
                  window.scrollTo(0, 0);
                  setShowMessage(true);
                }
              }}
            >
              <ScTable
                paging={true}
                tableHeight={250}
                showViewAll={true}
                ShowGoBack={false}
                viewSearch={false}
                viewFilters={false}
              />
            </Grid>
          </>
        )}
        {/* SubContractor */}
        {/* DocumentTable */}
        {UserPermissions?.map((up) =>
          up.Permissions.map((p) => {
            if (p.Name === "Document" && !disabledD) {
              return (
                <Grid
                  item
                  md={6}
                  xs={12}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={() => {
                    navigate("/saas/admin/document");
                  }}
                >
                  <DocumentTable
                    paging={true}
                    tableHeight={250}
                    showViewAll={true}
                    ShowGoBack={false}
                    viewSearch={false}
                    viewFilters={false}
                  />
                </Grid>
              );
            } else return "";
          })
        )}
        {/* DocumentTable */}

        {!disabledTM ? (
          <Grid
            item
            md={6}
            xs={12}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            onClick={() => {
              navigate("/saas/admin/TeamMember");
            }}
          >
            <TeamMemberTable
              paging={true}
              tableHeight={250}
              showViewAll={true}
              ShowGoBack={false}
              viewSearch={false}
              viewFilters={false}
            />
          </Grid>
        ) : localStorage.getItem("IsFreePlanActivated") === "true" &&
          !localStorage.getItem("planId") ? (
          ""
        ) : (
          UserPermissions?.map((up) =>
            up.Permissions.map((p) => {
              if (p.Name === "Team Member") {
                return (
                  <Grid
                    item
                    md={6}
                    xs={12}
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                    onClick={() => {
                      navigate("/saas/admin/TeamMember");
                    }}
                  >
                    <TeamMemberTable
                      paging={true}
                      tableHeight={250}
                      showViewAll={true}
                      ShowGoBack={false}
                      viewSearch={false}
                      viewFilters={false}
                    />
                  </Grid>
                );
              } else return "";
            })
          )
        )}
        {/* UserRolesTable */}
        {disabledUR ? (
          localStorage.getItem("IsFreePlanActivated") === "true" &&
          !localStorage.getItem("planId") ? (
            ""
          ) : (
            UserPermissions?.map((up) =>
              up.Permissions.map((p) => {
                if (p.Name === "User Role") {
                  return (
                    <Grid
                      item
                      marginTop={5}
                      md={6}
                      borderRadius={5}
                      height={340}
                      overflow={"scroll"}
                      xs={12}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                      onClick={() => {
                        navigate("/saas/admin/UserRoles");
                      }}
                    >
                      <UserRolesTable
                        CardWidth={"97%"}
                        RoleFontSize={"12px"}
                        bgColorRoles={"rgb(215 234 245)"}
                      />
                    </Grid>
                  );
                } else return "";
              })
            )
          )
        ) : (
          <Grid
            item
            md={6}
            marginTop={5}
            borderRadius={5}
            height={340}
            overflow={"scroll"}
            xs={12}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            onClick={() => {
              navigate("/saas/admin/UserRoles");
            }}
          >
            <UserRolesTable
              CardWidth={"97%"}
              RoleFontSize={"12px"}
              bgColorRoles={"rgb(215 234 245)"}
            />
          </Grid>
        )}
        {/* UserRolesTable */}
        {localStorage.getItem("AccountType") === "2" && !disabledLabel ? (
          <Grid
            item
            md={6}
            xs={12}
            height={340}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            onClick={() => {
              navigate("/saas/admin/productionLabel");
            }}
          >
            <ProductionLabelTypes adminPage={true} />
          </Grid>
        ) : (
          ""
        )}
        {localStorage.getItem("AccountType") === "2" &&
        !disabledInventory &&
        localStorage.getItem("isSubscribedManu") ? (
          <Grid
            height={340}
            overflow={"scroll"}
            item
            md={6}
            xs={12}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            onClick={() => {
              navigate("/saas/admin/inventory");
            }}
          >
            <InventoryMainView adminPage={true} />
          </Grid>
        ) : (
          ""
        )}
        {localStorage.getItem("AccountType") === "2" &&
        !disabledTrans &&
        localStorage.getItem("isSubscribedManu") &&
        !localStorage.getItem("planIdManu")?.includes("Core") ? (
          <Grid
            height={340}
            overflow={"auto"}
            container
            alignItems={"center"}
            justifyContent={"center"}
            item
            md={6}
            xs={12}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            onClick={() => {
              navigate("/saas/Admin/Transport");
            }}
          >
            <AdminViewTransport />
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  );
};

export default AdminDashboard;
