import * as constFile from "../../saasApiConst";
import axios from "axios";
const FormData = require("form-data");
var fileDownload = require("js-file-download");

const GET_TMS_BY_PROJECT = constFile.GET_TMS_BY_PROJECT;
export const getTmsByProject = (projectId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(
      `${process.env.REACT_APP_URL + GET_TMS_BY_PROJECT}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { projectId: projectId },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const GET_CONSULTANT_BY_PROJECT = constFile.GET_CONSULTANT_BY_PROJECT;
export const getConsultantsByProject = (projectId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(
      `${process.env.REACT_APP_URL + GET_CONSULTANT_BY_PROJECT}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { projectId: projectId },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const CREATE_RFI = constFile.CREATE_RFI;
export const createRFI = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + CREATE_RFI}`,
      data,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const UPLOAD_FILE_RFI = constFile.UPLOAD_FILE_RFI;
export const uploadFileRFI = (file, ProjectId) => {
  const token = localStorage.getItem("accessToken");
  const data = new FormData();
  data.append("File", file);
  data.append("ProjectId", ProjectId);
  return axios
    .post(`${process.env.REACT_APP_URL + UPLOAD_FILE_RFI}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const DELETE_FILE_RFI = constFile.DELETE_FILE_RFI;
export const deleteFileRFI = (fileId, ProjectId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + DELETE_FILE_RFI}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        FileId: fileId,
        ProjectId: ProjectId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const GET_RFI_LIST = constFile.GET_RFI_LIST;
export const getRFIList = (documentId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(
      `${process.env.REACT_APP_URL + GET_RFI_LIST}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          documentId: documentId,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const GET_RFI_INPUT_DATA = constFile.GET_RFI_INPUT_DATA;
export const getRFIInputData = (projectId, rfiId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(
      `${process.env.REACT_APP_URL + GET_RFI_INPUT_DATA}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          projectId: projectId,
          rfiId: rfiId,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const RESPONF_TO_RFI = constFile.RESPONF_TO_RFI;
export const responseRFI = (RFIId, Response, Files) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + RESPONF_TO_RFI}`,
      { RFIId: RFIId, Response: Response, Files: Files },

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const UPLOAD_RESPONSE_FILE_RFI = constFile.UPLOAD_RESPONSE_FILE_RFI;
export const uploadResponseFileRFI = (file, ProjectId) => {
  const token = localStorage.getItem("accessToken");
  const data = new FormData();
  data.append("File", file);
  data.append("ProjectId", ProjectId);
  return axios
    .post(`${process.env.REACT_APP_URL + UPLOAD_RESPONSE_FILE_RFI}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const DELETE_RESPONSE_FILE_RFI = constFile.DELETE_RESPONSE_FILE_RFI;
export const deleteResponseFileRFI = (fileId, ProjectId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + DELETE_RESPONSE_FILE_RFI}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        FileId: fileId,
        ProjectId: ProjectId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const GET_RFI_BY_ID = constFile.GET_RFI_BY_ID;
export const getRFIByID = (token) => {
  return axios
    .get(
      `${process.env.REACT_APP_URL + GET_RFI_BY_ID}`,

      {
        params: {
          token: token,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
const RAISE_TO_CONSULTANT = constFile.RAISE_TO_CONSULTANT;
export const raiseToConsultant = (RFIId, ConsultantId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + RAISE_TO_CONSULTANT}`,
      {
        RFIId: RFIId,
        ConsultantId: ConsultantId,
      },

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const DOWNLOAD_RFI_PDF = constFile.DOWNLOAD_RFI_PDF;
export const downloadRfiFile = (token) => {
  return axios
    .get(`${process.env.REACT_APP_URL + DOWNLOAD_RFI_PDF}`, {
      responseType: "blob",
      params: { token: token },
    })
    .then((response) => {
      fileDownload(response.data, "RFI Response Form.pdf");
      return response;
    })
    .catch((err) => {
      return err;
    });
};
