import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Factory from "../../../Assets/Images/Inventory/inventoryCard.png";
import eye from "../../../Assets/Images/Table/blue eye.png";
import { useSelector } from "react-redux";
import * as permissionFunctions from "../../../../ReusableFunctions";
const CheckIfPermitted = permissionFunctions.CheckIfPermitted;
export default function BasicCard(props) {
  const navigate = useNavigate();
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  const [disabledEdit, setDisabledEdit] = useState(false);
  const [disabledAdd, setDisabledAdd] = useState(false);

  useEffect(() => {
    if (UserPermissions) {
      CheckIfPermitted(
        UserPermissions,
        "Inventory Set-Up",
        "Create",
        setDisabledAdd
      );
      CheckIfPermitted(
        UserPermissions,
        "Inventory Set-Up",
        "Edit",
        setDisabledEdit
      );
    }
  }, [UserPermissions]);
  return (
    <Grid
      width={170}
      height={170}
      sx={{
        backgroundImage: `url(${Factory})`,
        backgroundSize: "contain",
        fontFamily: "Muli",
        backgroundRepeat: "no-repeat",
      }}
      paddingTop={7}
      paddingLeft={1}
    >
      <Grid
        item
        xs={12}
        sx={{
          fontFamily: "Muli",
          fontSize: 10,
          fontWeight: "bold",
          textAlign: "left",
          color: "#101650",
        }}
      >
        {props.data.Name.length > 54
          ? props.data.Name.slice(0, 54) + "..."
          : props.data.Name}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          fontFamily: "Muli",
          fontSize: 10,
          textAlign: "left",
          color: "#707070",
        }}
      >
        {props.data.Code}
      </Grid>

      <Grid
        item
        xs={12}
        container
        onMouseOver={(e) => {
          if (props.index !== -1) e.target.style.cursor = "pointer";
        }}
        paddingTop={2}
        sx={{
          fontFamily: "Muli",
          fontSize: 9,
          lineHeight: 1.63,
          fontWeight: "bold",
          textAlign: "left",
          color: "#2da4d0",
        }}
        alignItems="center"
        gap={2}
        onClick={() => {
          if (props.data.IsInventorySet) {
            if (!disabledEdit)
              navigate(`/saas/admin/inventory/manage?id=${props.data.Id}`);
          } else {
            if (!disabledAdd)
              navigate(`/saas/admin/inventory/manage?id=${props.data.Id}`);
          }
        }}
      >
        <Grid item>
          {props.data.IsInventorySet
            ? "Inventory Setup"
            : "Set up an Inventory"}
        </Grid>
        {props.data.IsInventorySet ? (
          <Grid item paddingLeft={2}>
            <img src={eye} width={12} alt={"see"} />
          </Grid>
        ) : (
          <Grid item fontSize={14}>
            +
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
