import * as constFile from "../pileCubesConst";
import axios from "axios";

const REQUEST_PASS = constFile.REQUEST_PASS;
const VALID_TOKEN = constFile.VALID_TOKEN;
const RESET_PASS = constFile.RESET_PASS;

export const requestPass = (email, setLoading) => {
  return axios
    .post(`${process.env.REACT_APP_URL + REQUEST_PASS}`, {
      ToAddress: email,
    })
    .then((response) => {
      setLoading(false);

      return response;
    })
    .catch((err) => {
      setLoading(false);
      return err.response.data.status;
    });
};

export const verifyToken = (token, setLoading) => {
  return axios
    .get(`${process.env.REACT_APP_URL + VALID_TOKEN}`, {
      params: { token: token },
    })
    .then((response) => {
      setLoading(false);
      return response.data;
    })
    .catch((err) => {
      setLoading(false);
      return err.response.data.status;
    });
};

export const resetPass = (token, password, setLoading) => {
  return axios
    .post(`${process.env.REACT_APP_URL + RESET_PASS}`, {
      Token: token,
      Password: password,
    })
    .then((response) => {
      setLoading(false);
      return response;
    })
    .catch((err) => {
      setLoading(false);
    });
};
