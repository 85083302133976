import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import TeamMemberTable from "./TeamMemberTable";
//import * as saasServices from "../../../Services/saas-services.proxy";
import TeamMemberModal from "./TeamMemberModal";
import SaasBack from "../../../Assets/Images/ProfileDetails/SaaSbackground.png";
import TeamMemberDetails from "./TeamMemberDetails";
import TMAssignmentLog from "./TMAssignmentLog";
const TeamMemberTableView = (props) => {
  useEffect(() => {
    props.setRegister(true);
  }, [props]);

  useEffect(() => {
    props.setNaved(false);
  }, [props]);

  const [create, setCreate] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const [viewDetails, setViewDetails] = useState(false);
  const [viewAssignmentLog, setViewAssignmentLog] = useState(false);

  const [rowData, setRowData] = useState(null);

  const [code, setCode] = useState('');

  const [modify, setModify] = useState(false);

  return (
    <div
      style={{
        minHeight: "87vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
        padding: '2.5% 0 0 2.5%'
      }}
    >
      <Grid container>
        {viewDetails ? (
          <TeamMemberDetails
            setViewDetails={setViewDetails}
            rowData={rowData}
          />
        ) : viewAssignmentLog ? (
          <TMAssignmentLog
            setViewAssignmentLog={setViewAssignmentLog}
            paging={false}
            tableHeight={480}
            showViewAll={false}
            ShowGoBack={true}
            viewSearch={true}
            viewFilters={true}
            rowData={rowData}
          />
        ) : (
          <TeamMemberTable
            code={code}
            setCode={setCode}
            paging={false}
            tableHeight={480}
            showViewAll={false}
            ShowGoBack={true}
            setOpenModal={setOpenModal}
            setRowData={setRowData}
            viewSearch={true}
            viewFilters={true}
            modify={modify}
            setModify={setModify}
            create={create}
            setViewDetails={setViewDetails}
            setViewAssignmentLog={setViewAssignmentLog}
          />
        )}
      </Grid>
      <TeamMemberModal
        code={code}
        setCode={setCode}
        openModal={openModal}
        setOpenModal={setOpenModal}
        rowData={rowData}
        setRowData={setRowData}
        setModify={setModify}
        modify={modify}
        setCreate={setCreate}
        create={create}
      />
    </div>
  );
};

export default TeamMemberTableView;
