import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  MenuItem,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableBody,
} from "@mui/material";
import classes from "../../../../../Assets/Styles/InventorySetup.module.css";
import AddValueIcon from "../../../../../Assets/Images/Factory/plusIcon.png";
import RemoveIcon from "../../../../../Assets/Images/SalesOrder/RemoveIcon.png";
import * as cartesian from "cartesian";
import * as inventoryServices from "../../../../../Services/inventory-setup-services.proxy";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

const getReferenceInputData = inventoryServices.getReferenceInputData;
const createInventoryReference = inventoryServices.createInventoryReference;
const updateInventoryReference = inventoryServices.updateInventoryReference;

const ItemReference = (props) => {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [emptyField, setEmptyField] = useState(false);
  const [emptyFieldMessage, setEmptyFieldMessage] = useState("");
  const [loadingData, setLoadingData] = useState(true);
  const [saving, setSaving] = useState(false);

  const [nbOfReferences, setNbOfReferences] = useState("3");

  const [isdisabled, setIsDisabled] = useState(false);

  const [referencesNames, setReferencesNames] = useState([]);

  const [references, setReferences] = useState([
    {
      Id: null,
      ReferenceId: 1,
      Values: [{ value: null }],
    },
    {
      Id: null,
      ReferenceId: 2,
      Values: [{ value: null }],
    },
    {
      Id: null,
      ReferenceId: 3,
      Values: [{ value: null }],
    },
  ]);
  const [referenceGroup, setReferenceGroup] = useState([]);
  const generateReferenceGroup = () => {
    let a = references[0].Values.map((val) => val.value.toString());
    let b = references[1].Values.map((val) => val.value.toString());
    let c = references[2].Values.map((val) => val.value.toString());
    let x = cartesian([
      references[0].Values.map((val) => val.value.toString()),
      references[1].Values.map((val) => val.value.toString()),
      references[2].Values.map((val) => val.value.toString()),
    ]);
    x = x.map((z) => z.join(" "));

    console.log(x);
    let tempArray = referenceGroup;
    x.map((refGrp) => {
      if (!referenceGroup.filter((rfg) => rfg.Code === refGrp)[0])
        tempArray = tempArray.concat({ Code: refGrp, Abbreviation: null });
    });
    tempArray.map((rf) => {
      if (!x.filter((rfs) => rfs === rf.Code)[0]) {
        tempArray = tempArray.filter((rfk) => rfk.Code !== rf.Code);
      }
    });
    setReferenceGroup(tempArray);
    console.log(referenceGroup);
  };

  const checkIfFieldsNotEmpty = () => {
    let empty = false;
    references.map((ref) => {
      ref.Values.map((val) => {
        if (val.value === null) {
          empty = true;
          setEmptyField(true);
          setEmptyFieldMessage(
            "There is empty fields in the table, Please check."
          );
        }
      });
    });
    if (!empty) {
      if (referenceGroup.length === 0) {
        empty = true;
        setEmptyField(true);
        setEmptyFieldMessage("Click the generate reference group button.");
      }
    }
    if (!empty) {
      referenceGroup.map((refGrp) => {
        if (!refGrp.Abbreviation) {
          empty = true;
          setEmptyField(true);
          setEmptyFieldMessage(
            "There are an empty field in Ref Group Abbreviation."
          );
        }
      });
    }
    if (!empty) {
      save();
    }
  };
  useEffect(() => {
    if (props.inventoryId) {
      getReferenceInputData(props.inventoryId).then((x) => {
        setLoadingData(false);
        setReferencesNames(x.References);
        if (x.Inventory) {
          setReferences(x.Inventory.References);
          setReferenceGroup(x.Inventory.ReferenceGroups);
        }
      });
    }
  }, [props.inventoryId]);
  const InventoryReferenceData = {
    Id: props.inventoryId,
    References: references,
    ReferenceGroups: referenceGroup,
  };
  const save = () => {
    setSaving(true);
    if (references[0].Id) {
      updateInventoryReference(InventoryReferenceData).then((x) => {
        setSaving(false);
        props.setActiveTab(props.activeTab + 1);
      });
    } else {
      createInventoryReference(InventoryReferenceData).then((x) => {
        setSaving(false);
        props.setActiveTab(props.activeTab + 1);
      });
    }
    console.log(references);
  };
  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "transparent",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
          flexDirection: "column",
        }}
        open={loadingData}
      >
        <CircularProgress />

        <Grid
          item
          fontFamily={"Muli"}
          fontSize={15}
          fontWeight={"bold"}
          color={"rgb(25, 118, 210)"}
        >
          Loading Data...
        </Grid>
      </Backdrop>
      <Grid item md={0.7} sm={1.5} paddingTop={1.5}>
        <Grid item className={classes["Lfields"]}>
          No. of Item References
        </Grid>
        <Grid item>
          <TextField
            value={nbOfReferences}
            fullWidth
            disabled
            InputProps={{
              style: {
                color: "#0674b9",
                height: "28px",
                fontSize: 12,
                fontFamily: "Muli",
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={10}></Grid>
      <Grid item overflow={"auto"}>
        <TableContainer sx={{ width: "100%" }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              borderCollapse: "separate",
              borderSpacing: "0px 0px",
              border: 0,
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell sx={styles.tableCell}>Reference Name</TableCell>
                {references?.map((ref, index) => (
                  <TableCell
                    sx={
                      index === 2
                        ? styles.tableCellheadPr
                        : styles.tableCellhead
                    }
                  >
                    <Grid item className={classes["Lfields"]} xs={12}>
                      ref &nbsp;{index + 1}
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        value={ref.ReferenceId}
                        fullWidth
                        select
                        disabled
                        SelectProps={{
                          MenuProps: { disableScrollLock: true },
                        }}
                        InputProps={{
                          style: {
                            color: "#707070",
                            height: "28px",
                            fontSize: 12,
                            fontFamily: "Muli",
                          },
                        }}
                      >
                        {referencesNames.map((r) => (
                          <MenuItem value={r.Id} key={r.Id}>
                            {r.Name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loadingData ? (
                <></>
              ) : (
                <TableRow>
                  <TableCell sx={styles.tableCellBody}>
                    *Reference Value
                  </TableCell>
                  {references?.map((ref, index) => (
                    <TableCell
                      key={index}
                      sx={
                        index === 2
                          ? styles.tableCellheadPr
                          : styles.tableCellhead
                      }
                    >
                      {ref?.Values?.map((valueRef, indexValue) => (
                        <Grid
                          width={266}
                          item
                          key={indexValue}
                          paddingTop={0.5}
                          container
                          direction={"row"}
                          gap={1}
                        >
                          {indexValue === ref?.Values.length - 1 ? (
                            <Grid
                              item
                              paddingTop={1}
                              onMouseOver={(e) => {
                                e.target.style.cursor = "pointer";
                              }}
                              onClick={() => {
                                ref.Values = ref.Values.concat({ value: null });

                                forceUpdate();
                              }}
                            >
                              <img src={AddValueIcon} width={15} alt={"add"} />
                            </Grid>
                          ) : (
                            <Grid item width={15}></Grid>
                          )}
                          <Grid item>
                            <TextField
                              value={valueRef.value}
                              fullWidth
                              InputProps={{
                                style: {
                                  color: "#707070",
                                  height: "28px",
                                  fontSize: 12,
                                  fontFamily: "Muli",
                                },
                              }}
                              placeholder={"Value"}
                              onChange={(e) => {
                                valueRef.value = e.target.value.trim();

                                forceUpdate();
                              }}
                            ></TextField>
                          </Grid>
                          {indexValue !== 0 ? (
                            <Grid
                              item
                              paddingTop={1}
                              onMouseOver={(e) => {
                                e.target.style.cursor = "pointer";
                              }}
                              onClick={() => {
                                console.log(ref.Values);
                                ref.Values = ref.Values.filter(
                                  (val, indx) => indexValue !== indx
                                );

                                forceUpdate();
                              }}
                            >
                              <img src={RemoveIcon} width={15} alt={"add"} />
                            </Grid>
                          ) : (
                            <Grid item width={15}></Grid>
                          )}
                        </Grid>
                      ))}
                    </TableCell>
                  ))}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item container xs={10.6} direction={"row-reverse"}>
        <Grid
          item

          className={classes["Generate-Reference-Group"]}
          
          onClick={() => {
            if (
              references[0].Values[0].value &&
              references[1].Values[0].value &&
              references[2].Values[0].value
            )
              generateReferenceGroup();
          }}
        >
          Click here to generate reference group
        </Grid>
      </Grid>

      <Grid container paddingTop={2}>
        <Grid item container xs={9.8} direction={"row"} paddingBottom={2}>
          <Grid item xs={6} className={classes["Ref-Family-Group"]}>
            Ref Family Group
          </Grid>
          <Grid item xs={6} className={classes["Ref-Family-Group"]}>
            *Ref Group Abbreviation
          </Grid>
        </Grid>
        {referenceGroup.map((refGrp, index) => (
          <Grid
            key={index}
            item
            container
            xs={9.8}
            direction={"row"}
            paddingTop={0.5}
          >
            <Grid item container xs={6} direction={"row"} gap={1}>
              <Grid item className={classes["Ref-Family-Group"]} paddingTop={1}>
                {index + 1}
              </Grid>
              <Grid
                item
                xs={7}
                className={classes["Rectangle-3722"]}
                marginTop={0.5}
              >
                {refGrp.Code}
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid item xs={11} paddingTop={0.5}>
                <TextField
                  value={refGrp.Abbreviation}
                  fullWidth
                  InputProps={{
                    style: {
                      color: "#707070",
                      height: "28px",
                      fontSize: 12,
                      fontFamily: "Muli",
                    },
                  }}
                  placeholder={"Abbreviation"}
                  onChange={(e) => {
                    refGrp.Abbreviation = e.target.value;

                    forceUpdate();
                  }}
                ></TextField>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              {index !== 0 ? (
                <button
                  className={classes["cancelbut"]}
                  onClick={() => {
                    setReferenceGroup(
                      referenceGroup.filter((val, indx) => index !== indx)
                    );
                  }}
                >
                  Remove Ref Group
                </button>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid
        item
        container
        xs={12}
        direction={"row-reverse"}
        gap={1}
        paddingTop={5}
        paddingRight={1.5}
      >
        <button
          className={classes["SaveBut"]}
          disabled={isdisabled}
          onClick={() => {
            checkIfFieldsNotEmpty();
          }}
        >
          {saving ? "Saving..." : "Save"}
        </button>
        <button
          className={classes["cancelbut"]}
          onClick={() => {
            props.setActiveTab(props.activeTab - 1);
          }}
        >
          Go Back
        </button>
        <Grid
          item
          fontFamily={"Muli"}
          fontSize={12}
          fontWeight={"bold"}
          color={"#ea001e"}
        >
          {emptyField ? emptyFieldMessage : ""}
        </Grid>
      </Grid>
    </>
  );
};
const styles = {
  tableCell: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    lineHeight: 1.17,
    textAlign: "left",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 157,
  },
  tableCellhead: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    textAlign: "left",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 266,
  },
  tableCellheadPr: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    textAlign: "left",
    maxHeight: "20px",
    width: 266,
  },
  tableCellBody: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    lineHeight: 1.17,
    textAlign: "left",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 75,
    paddingTop: 3.5,
  },
  tableCellheadBody: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    textAlign: "left",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 140,
  },
  tableCellheadPrBody: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    textAlign: "left",
    maxHeight: "20px",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    width: 180,
  },
};
export default ItemReference;
