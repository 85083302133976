import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import homeBg from "../../Assets/Images/Dashboard/homeBg.png";
import scanfg from "../../Assets/Images/Dashboard/scanfg.png";
import classing from "../../Assets/Styles/Dashboard/Production.module.css";
import scanrm from "../../Assets/Images/Dashboard/scanrm.png";
import scanLine from "../../Assets/Images/Dashboard/ScanLine.png";

import successScan from "../../Assets/Images/Dashboard/successScan.png";
import back from "../../Assets/Images/Dashboard/back2.png";
import { useLocation, useNavigate } from "react-router-dom";
import DashNavBar from "./DashNavBar";
import * as services from "../../Services/machine-dashboard-services.proxy";
import useScanDetection from "use-scan-detection";
import ErrorModal from "../../SharedComponents/ErrorModal";

const getRequiredItem = services.getRequiredItem;
const getItemDetails = services.getItemDetails;
const updateUsedBundle = services.updateUsedBundle;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "500px",
  transform: "translate(-50%, -50%)",
  p: 4,
  borderRadius: "12px",
  border: "solid 1px #707070",
  backgroundColor: "#0674b9",
  outline: "none",
};

const style = {
  data: {
    fontFamily: "Muli",
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1.25,
    textAlign: "center",
    color: "#000",
  },
};

export default function Scan(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const groupId = query.get("groupId");

  const [requiredItem, setRequiredItem] = useState(null);
  const [success, setSuccess] = useState(false);
  const [barcode, setBarcode] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const [error, setError] = useState("");
  const [barcodes, setBarcodes] = useState([]);
  const [notFinishData, setNotFinishData] = useState(true);

  const [selectedBarcode, setSelectedBarcode] = useState(null);
  const [loading, setLoading] = useState(false);

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [BEerror, setBEerror] = useState(null);
  const [navigateToOnClose, setNavigateToOnClose] = useState("/machine/dashboard");



  useScanDetection({
    onComplete: (code) => {
      if (groupId && code)
        if (localStorage.getItem("LineBarcode")) {
          updateUsedBundle(
            null,
            groupId,
            localStorage.getItem("LineBarcode")
          ).then((x) => {
            // if (x.status) {
            //   setError(x.error);
            //   handleOpenModal();
            // } else {
            if (x.status || x.statusCode) {
              if (x.status) setBEerror(x.error)
              if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
              setOpenErrorModal(true);

            } else {
              setError("");
              navigate(`/machine/production?groupId=${groupId}`);
            }
          });
        } else
          getItemDetails(
            groupId,
            code.replaceAll("Shift", "").replaceAll("Enter", "")
          ).then((x) => {
            // if (x.status) {
            //   setError(x.error);
            //   handleOpenModal();
            // } else {
            if (x.status || x.statusCode) {
              if (x.status) setBEerror(x.error)
              if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
              setOpenErrorModal(true);
            } else {
              props.setItemScanned(x);
              setError("");
              setSuccess(true);
            }
          });
    },
    minLength: 3,
  });

  const saveBarcodeManually = (selected_Barcode) => {
    if (localStorage.getItem("LineBarcode")) {
      updateUsedBundle(
        null,
        groupId,
        localStorage.getItem("LineBarcode")
      ).then((x) => {
        // if (x.status) {
        //   setError(x.error);
        //   handleOpenModal();
        // } else {
        if (x.status || x.statusCode) {
          if (x.status) setBEerror(x.error)
          if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
          setOpenErrorModal(true);

        } else {
          setError("");
          navigate(`/machine/production?groupId=${groupId}`);
        }
      });
    } else
      getItemDetails(
        groupId,
        selected_Barcode
      ).then((x) => {
        // if (x.status) {
        //   setError(x.error);
        //   handleOpenModal();
        // } else {
        if (x.status || x.statusCode) {
          if (x.status) setBEerror(x.error)
          if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
          setOpenErrorModal(true);
        } else {
          props.setItemScanned(x);
          setError("");
          setSuccess(true);
        }
      });
  }

  const navigate = useNavigate();

  useEffect(() => {
    if (groupId)
      setLoading(true);
    getRequiredItem(groupId).then((x) => {
      setLoading(false);
      // if (x.status) {
      //   setError(x.error);
      //   setNotFinishData(false);
      // } else {
      if (x.status || x.statusCode) {
        if (x.status) setBEerror(x.error)
        if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
        setOpenErrorModal(true);
        setNotFinishData(false);
      } else {

        if (x.Cycle.IsScanned && !x.Cycle.IsFinished) navigate(`/machine/production?groupId=${groupId}`);
        else if (x.Cycle.IsFinished) navigate(`/machine/summary?groupId=${groupId}`);
        else {
          setRequiredItem(x);
          localStorage.setItem("Category", x.Name);
          setBarcodes(x.MatchingZoneBundles);
          setBarcode(x.MatchingZoneBundles[0].Name);
          if (x.Name === "Line")
            localStorage.setItem("LineBarcode", x.MatchingZoneBundles[0].Name);
          setError("");
        }


      }
      setNotFinishData(false);
    });
  }, []);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        navigate(`/machine/item?groupId=${groupId}`);
      }, 1500);
    }
  }, [success]);

  return (
    <>
      <Grid
        sx={{
          padding: "2% 3.5% 0 3.5%",
          backgroundImage: `url(${homeBg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          paddingBottom: "10%",
          height: "100vh",
        }}
      >
        <Backdrop
          sx={{
            color: "#fff",
            backgroundColor: "transparent",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            display: "flex",
            flexDirection: "column",
          }}
          open={loading}
        >
          <CircularProgress />
          <Grid
            item
            fontFamily={"Muli"}
            fontSize={15}
            fontWeight={"bold"}
            color={"rgb(25, 118, 210)"}
          >
            Loading Data...
          </Grid>
        </Backdrop>
        <DashNavBar />
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            xs={12}
            paddingTop="3%"
          >
            <img onMouseOver={(e) => (e.target.style.cursor = "pointer")}
              onClick={() => navigate(-1)} src={back} alt="back" />
          </Grid>
          <Grid
            item
            paddingTop={{ xs: "10%", md: "2%" }}
            container
            alignItems="center"
            justifyContent="center"
          >
            {success ? (
              <Grid
                item
                sx={{
                  backgroundImage: `url(${successScan})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  width: 480,
                  height: 480,
                }}
              ></Grid>
            ) : (
              <>
                <Grid
                  item
                  sx={{
                    backgroundImage: `url(${localStorage.getItem("LineBarcode") ? scanLine : scanrm
                      })`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    width: 480,
                    height: 480,
                  }}
                  container
                  alignItems="center"
                  justifyContent="flex-start"
                  direction="column"
                  paddingTop={{ xs: "16%", md: "9%" }}
                >
                  {error ? (
                    <Grid item sx={style.data}>
                      {error}
                    </Grid>
                  ) : (
                    ""
                  )}
                  {error ? (
                    ""
                  ) : (
                    <Grid item sx={style.data}>
                      {localStorage.getItem("LineBarcode") ? "Scan" : "Use"}&nbsp;
                      {requiredItem?.Name} &nbsp;
                    </Grid>
                  )}
                  {error ? (
                    ""
                  ) : (
                    <Grid item sx={style.data}>
                      {localStorage.getItem("LineBarcode")
                        ? `with Barcode ${barcode}`
                        : ` ${requiredItem?.Grade} Φ ${requiredItem?.Diameter} Length
                  ${requiredItem?.Length}`}
                    </Grid>
                  )}
                </Grid>
                {/* <Grid item>
                  <TextField
                    select
                    value={selectedBarcode}
                    onChange={(e) => {
                      setSelectedBarcode(e.target.value);
                      saveBarcodeManually(e.target.value);
                    }}
                  >
                    {barcodes?.map((b) => (
                      <MenuItem
                        value={b.Name}
                        key={b.Id}
                      >
                        {b.Name}

                      </MenuItem>
                    ))}
                  </TextField>
                </Grid> */}
              </>
            )}
          </Grid>
        </Grid>


        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            container
            sx={modalStyle}
            justifyContent="center"
            alignItems="center"
            gap={10}
          >
            <Grid item className={classing["Do-you-want-to-reprint"]}>
              {error}
            </Grid>
            <Grid item container justifyContent="center" direction="row" gap={3}>
              <Grid
                item
                onClick={() => {
                  handleCloseModal();
                }}
                className={classing["confirm-button"]}
              >
                Close
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      </Grid>
      <ErrorModal openErrorModal={openErrorModal} setOpenErrorModal={setOpenErrorModal} BEerror={BEerror} navigateToOnClose={navigateToOnClose} />
    </>

  );
}
