import * as constFile from "../../FactoryConsts";
import axios from "axios";

const CREATE_OR_EDIR_DATA = constFile.CREATE_OR_EDIR_DATA;

export const getStorageBlockInputData = (BayId, BlockId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + CREATE_OR_EDIR_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        BayId: BayId,
        BlockId: BlockId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const CREATE_STORAGE = constFile.CREATE_STORAGE;

export const createStorage = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + CREATE_STORAGE}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const UPDATE_STORAGE = constFile.UPDATE_STORAGE;

export const updateStorage = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + UPDATE_STORAGE}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const GET_ZONES_STOCK = constFile.GET_ZONES_STOCK;

export const getZonesStock = (ZoneId, CategoryId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_ZONES_STOCK}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        ZoneId: ZoneId,
        CategoryId: CategoryId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const TRANSFER_BUNDLES = constFile.TRANSFER_BUNDLES;

export const transferBundles = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + TRANSFER_BUNDLES}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const GET_ZONES_LINES = constFile.GET_ZONES_LINES;

export const getZonesLines = (ZoneId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_ZONES_LINES}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        ZoneId: ZoneId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const DELETE_BUNDLE = constFile.DELETE_BUNDLE;

export const deleteBundle = (BundleId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + DELETE_BUNDLE}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        BundleId: BundleId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const UPDATE_BUNDLE_QUANTITY = constFile.UPDATE_BUNDLE_QUANTITY;

export const updateBundleQuantity = (BundleId, Quantity) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + UPDATE_BUNDLE_QUANTITY}`,
      {
        BundleId: BundleId,
        Quantity: Quantity,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
