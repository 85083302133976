import * as constFile from "../../projectConst";
import axios from "axios";

const GET_MILESTONES_LIST = constFile.GET_MILESTONES_LIST;

export const getMileStonesList = (ProjectId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_MILESTONES_LIST}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        ProjectId: ProjectId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const SUGGEST_MILESTONES_DATES = constFile.SUGGEST_MILESTONES_DATES;

export const suggestMileStonesDates = (
  Id,
  Type,
  SuggestedStartDate,
  SuggestedEndDate
) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + SUGGEST_MILESTONES_DATES}`,
      {
        Id: Id,
        Type: Type,
        SuggestedStartDate: SuggestedStartDate,
        SuggestedEndDate: SuggestedEndDate,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const APPROVE_MILESTONES_DATES = constFile.APPROVE_MILESTONES_DATES;

export const approveMileStonesDates = (Id, Type) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + APPROVE_MILESTONES_DATES}`,
      {
        Id: Id,
        Type: Type,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const APPROVE_SUGGESTED_MILESTONES_DATES =
  constFile.APPROVE_SUGGESTED_MILESTONES_DATES;

export const approveSuggestedDates = (Id, Type) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + APPROVE_SUGGESTED_MILESTONES_DATES}`,
      {
        Id: Id,
        Type: Type,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const SET_SUBPROJECT_PREDECESSOR = constFile.SET_SUBPROJECT_PREDECESSOR;

export const setSubprojectPredecessor = (SubProjectId, PredecessorId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + SET_SUBPROJECT_PREDECESSOR}`,
      {
        SubProjectId: SubProjectId,
        PredecessorId: PredecessorId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
