import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import classes from "../../../Assets/Styles/profileDetails.module.css";
import Barcode from "react-barcode";

const CompanyInfo = (props) => {
  const AccountType = localStorage.getItem("AccountType");
  const [speciality, setSpeciality] = useState();
  const [industry, setIndustryType] = useState();
  const [discipline, setDiscipline] = useState();
  const [trade, setTrade] = useState();

  useEffect(() => {
    if (props.companyDetails.SpecialityOther) {
      setSpeciality(props.companyDetails.SpecialityOther);
    } else {
      setSpeciality(props.companyDetails.Speciality);
    }
    if (props.companyDetails.IndustryTypeOther) {
      setIndustryType(props.companyDetails.IndustryTypeOther);
    } else {
      setIndustryType(props.companyDetails.IndustryType);
    }
    if (props.companyDetails.SubIndustryType) {
      setIndustryType(props.companyDetails.SubIndustryType);
    } else {
      setIndustryType(props.companyDetails.IndustryType);
    }
    if (props.companyDetails.TradeOther) {
      setTrade(props.companyDetails.TradeOther);
    } else {
      setTrade(props.companyDetails.Trade);
    }
    if (props.companyDetails.DisciplineCoveredOther) {
      setDiscipline(props.companyDetails.DisciplineCoveredOther);
    } else {
      setDiscipline(props.companyDetails.DisciplineCovered);
    }
  });
  const displayInfo = (
    header1,
    data1,
    disabled1,
    header2,
    data2,
    disabled2
  ) => {
    return (
      <Grid container item direction={"row"}>
        <Grid item md={6} container direction={"column"}>
          <span
            className={
              disabled1 ? classes.DetailsHeaderdisable : classes.DetailsHeader
            }
          >
            {header1}
          </span>
          <span
            className={disabled1 ? classes.infoDataDisabled : classes.infoData}
          >
            {data1}
          </span>
          <div className={classes["Line-347"]}></div>
        </Grid>
        <Grid item md={6} container direction={"column"}>
          <span
            className={
              disabled2 ? classes.DetailsHeaderdisable : classes.DetailsHeader
            }
          >
            {header2}
          </span>
          <span
            className={disabled2 ? classes.infoDataDisabled : classes.infoData}
          >
            {data2}
          </span>
          <div className={classes["Line-347"]}></div>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {props.companyDetails ? (
        localStorage.getItem("isTeamMember") === "true" ? (
          <Grid
            container
            item
            marginTop={1}
            marginLeft={2}
            direction={"column"}
            spacing={1}
            paddingBottom={2}
          >
            <Grid item marginLeft={0.5}>
              <span className={classes.infoSectionTitle}>Details</span>
            </Grid>

            <Grid container item direction={"row"}>
              <div
                className={classes["Line-356"]}
                style={{
                  backgroundColor: AccountType === "1" ? "#e1e319" : "#2da4d0",
                  borderColor: AccountType === "1" ? "#e1e319" : "#2da4d0",
                }}
              ></div>
              <div className={classes["Line-355"]}></div>
            </Grid>

            <Grid container item direction={"row"}>
              <Grid item md={12} container direction={"column"}>
                <span className={classes.DetailsHeader}>Team Member Name</span>
                <span className={classes.infoData}>
                  {props.companyDetails.Name}
                </span>
                <div className={classes["Line-347"]}></div>
              </Grid>
              <Grid item md={12} container direction={"column"}>
                <span className={classes.DetailsHeaderdisable}>
                  Registration Email
                </span>
                <span className={classes.infoDataDisabled}>
                  {props.companyDetails.Email}
                </span>
                <div className={classes["Line-347"]}></div>
              </Grid>
              <Grid item md={12} container direction={"column"}>
                <span className={classes.DetailsHeader}>Country </span>
                <span className={classes.infoData}>
                  {props.companyDetails.Country}
                </span>
                <div className={classes["Line-347"]}></div>
              </Grid>
              <Grid item md={12} container direction={"column"}>
                <span className={classes.DetailsHeader}>Address</span>
                <span className={classes.infoData}>
                  {props.companyDetails.Address}
                </span>
                <div className={classes["Line-347"]}></div>
              </Grid>
              <Grid item md={12} container direction={"column"}>
                <span className={classes.DetailsHeader}>Mobile</span>
                <span className={classes.infoData}>
                  {props.companyDetails.PhoneNumber}
                </span>
                <div className={classes["Line-347"]}></div>
              </Grid>
              <Grid item md={12} container direction={"column"}>
                <span className={classes.DetailsHeader}>Barcode</span>
                <span className={classes.infoData}>
                  <Barcode
                    value={props.companyDetails.Barcode}
                    height={40}
                    format={"CODE128"}
                    font="Avenir Next"
                    fontOptions="600"
                    textMargin={4}
                    margin={0}
                  />
                </span>
                <div className={classes["Line-347"]}></div>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            item
            marginTop={1}
            marginLeft={2}
            direction={"column"}
            spacing={1}
            paddingBottom={2}
          >
            <Grid item marginLeft={0.5}>
              <span className={classes.infoSectionTitle}>Details</span>
            </Grid>

            <Grid container item direction={"row"}>
              <div
                className={classes["Line-356"]}
                style={{
                  backgroundColor: AccountType === "1" ? "#e1e319" : "#2da4d0",
                  borderColor: AccountType === "1" ? "#e1e319" : "#2da4d0",
                }}
              ></div>
              <div className={classes["Line-355"]}></div>
            </Grid>

            <Grid container item marginLeft={0.5} marginTop={2} gap={1}>
              {displayInfo(
                "Company Name",
                props.companyDetails.CompanyName,
                false,
                "Employees",
                props.companyDetails.EmployeesRange,
                false
              )}
              {displayInfo(
                "Registration Email ",
                props.companyDetails.CompanyEmail,
                true,
                "Address",
                props.companyDetails.Address,
                false
              )}
              {displayInfo(
                "Country",
                props.companyDetails.Country,
                false,
                "Mobile",
                props.companyDetails.CompanyPhoneNumber,
                false
              )}
              {AccountType === "1" &&
                displayInfo(
                  "Registration number",
                  props.companyDetails.RegistrationNumber,
                  false,
                  "Speciality",
                  speciality,
                  false
                )}
              {AccountType === "2" &&
                displayInfo(
                  "Registration number",
                  props.companyDetails.RegistrationNumber,
                  false,
                  "Industry Type",
                  industry,
                  false
                )}
              {AccountType === "2" &&
                displayInfo(
                  "Discipline Covered",
                  discipline,
                  true,
                  "Trades",
                  trade,
                  true
                )}
            </Grid>
            <Grid item marginLeft={0.5} marginTop={2}>
              <span className={classes.infoSectionTitle}>
                Primary Contact Information
              </span>
            </Grid>

            <Grid container item direction={"row"}>
              <div
                className={classes["Line1-356"]}
                style={{
                  backgroundColor: AccountType === "1" ? "#e1e319" : "#2da4d0",
                  borderColor: AccountType === "1" ? "#e1e319" : "#2da4d0",
                }}
              ></div>
              <div className={classes["Line-355"]}></div>
            </Grid>
            {props.companyDetails.UserDetails ? (
              <Grid container item marginLeft={0.5} marginTop={2} gap={1}>
                {displayInfo(
                  "Contact Name ",
                  props.companyDetails.UserDetails.Name,
                  false,
                  "Position",
                  props.companyDetails.UserDetails.Position,
                  false
                )}
                {displayInfo(
                  "Primary Email",
                  props.companyDetails.UserDetails.PersonalEmail,
                  false,
                  "Mobile",
                  props.companyDetails.UserDetails.PhoneNumber,
                  false
                )}
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default CompanyInfo;
