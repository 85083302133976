import React, { useState, useEffect } from "react";
import { styled, Box } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import classes from "../../../Assets/Styles/Subscription.module.css";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import upgrade from "../../../Assets/Images/Settings/upgrade.png";
import downgrade from "../../../Assets/Images/Settings/downgrade.png";
import check from "../../../Assets/Images/Settings/check.png";
import checkSub from "../../../Assets/Images/Settings/checkSub.jpg";
import CheckMain from "../../../Assets/Images/Settings/checkActive.png";
import * as profileServices from "../../../Services/profile-services.proxy";
const changeSubscription = profileServices.changeSubscription;
const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  bgcolor: "#fff",
  border: "solid 1px #e9e7e7",

  outline: "none",
};

export default function UpdateSubscription(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const proPlusFeatures = [
    "Projects in multiple countries",
    " Up to 10,000 drawings",
    "Multiple projects",
    "Unlimited Users",
  ];
  const handleClose = () => {
    setOpen(false);
    props.setOpen(false);
  };
  useEffect(() => {
    if (props.open) {
      handleOpen(true);
    }
  }, [props.open]);
  const changePlan = () => {
    changeSubscription(props.newPlanPriceId).then((x) => {
      window.open(x);
    });
  };
  return (
    <div>
      <StyledModal
        disableScrollLock
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
      >
        <Box sx={{ outline: "none" }}>
          <Grid
            container
            direction={"row-reverse"}
            bgcolor={"transparent"}
            marginBottom={0.5}
          >
            <IconButton
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onClick={() => {
                handleClose();
              }}
            >
              <CloseIcon htmlColor="#fff" />
            </IconButton>
          </Grid>
          <Box sx={style} width={{ md: "42.7vw", xs: "94vw" }}>
            <Grid
              container
              direction={"column"}
              spacing={2}
              justifyContent={"center"}
              alignItems={"center"}
              paddingTop={2}
            >
              <Grid item>
                <div className={classes["Modal-title"]}>
                  {props.action === "up"
                    ? "Subscription Upgrade"
                    : "Subscription Change"}
                </div>
              </Grid>
              <Grid item width={{ md: "43.7vw", xs: "98vw" }}>
                <div className={classes["divider2"]}></div>
              </Grid>
              <Grid item>
                <img
                  src={props.action === "up" ? upgrade : downgrade}
                  alt={"attention"}
                />
              </Grid>
              <Grid
                item
                fontSize={{ xs: "13px", md: "20px" }}
                width={"90%"}
                className={classes.message}
              >
                {props.action === "down" ? (
                  <div>
                    Your subscription will change to the {props.planName} plan
                    at the end of the subscription period. Are you sure you want
                    to continue? You will no longer have the following features:
                  </div>
                ) : (
                  <div className={classes.message2}>
                    Your subscription will be upgraded to the Pro plan
                    immediately. Enjoy your added benefits:
                  </div>
                )}
              </Grid>
              <Grid container item direction={"row"}>
                <Grid
                  item
                  container
                  justifyContent={"center"}
                  className={classes.message}
                  paddingLeft={{ md: 30 }}
                >
                  {props.action === "down"
                    ? proPlusFeatures.map((option, index) => (
                        <Grid
                          item
                          className={classes["plan-description2"]}
                          key={index}
                          md={12}
                        >
                          <img
                            src={check}
                            style={{ marginRight: 10.3 }}
                            alt={"check"}
                          />

                          {option}
                        </Grid>
                      ))
                    : props.planFeatures.map((option, index) =>
                        option.IsChecked ? (
                          <Grid
                            item
                            className={classes["plan-description2"]}
                            key={index}
                            md={12}
                          >
                            <img
                              src={
                                localStorage.getItem("AccountType") === "1"
                                  ? CheckMain
                                  : checkSub
                              }
                              style={{ marginRight: 10.3 }}
                              alt={"check"}
                            ></img>

                            {option.Description}
                          </Grid>
                        ) : null
                      )}
                </Grid>
              </Grid>
              <Grid
                item
                className={classes["Rectangle-972"]}
                marginLeft={2}
                marginTop={3}
                width={{ md: "42.8vw", xs: "94.1vw" }}
              >
                <Grid
                  item
                  md={11.7}
                  xs={11}
                  container
                  direction={"row-reverse"}
                >
                  <Grid item marginLeft={2}>
                    <button
                      className={classes.SaveModal}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                      onClick={() => {
                        changePlan();
                      }}
                    >
                      continue to payment process
                    </button>
                  </Grid>
                  <Grid item>
                    <button
                      className={classes.exitModal}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </StyledModal>
    </div>
  );
}
