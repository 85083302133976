import React from "react";
import { Grid, Link } from "@mui/material";
import Logo from "../../../Assets/Images/Navbar/logo.png";
import "../../../Assets/Styles/LogIn.css";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";

function ResetLink({ continues }) {
  const navigate = useNavigate();
  return (
    <Grid
      container
      className="LoginBg"
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Grid
        item
        container
        direction={"column"}
        width="380px"
        height="397.6px"
        alignItems={"center"}
        className="FormBg"
      >
        <Grid item marginTop={5}>
          <a href="/"><img src={Logo} alt="logo" height={50} /></a>
        </Grid>
        <Grid item marginTop={4}>
          <span className="Forgot-Your-Password">Check Your Email</span>
        </Grid>
        <Grid container className="CheckEmailText" justifyContent={"center"}>
          <Grid item marginTop={2}>
            We've sent you an email with a link to finish resetting your
            password.
          </Grid>
          <Grid item marginTop={2}>
            Can't find the email? Try checking your spam folder
          </Grid>
          <Grid item marginTop={2}>
            If you still can't log in, contact your Pilecubes administrator.
          </Grid>
        </Grid>
        <Grid item marginTop={5}>
          <button
            className="return-button"
            onMouseOver={(e) => {
              e.target.style.backgroundColor = "#101650";
              e.target.style.cursor = "pointer";
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = "#1173cf";
            }}
            onClick={(e) => {
              continues(e, 1);
            }}
          >
            Return to Login
          </button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ResetLink;
