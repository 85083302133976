import React, { useState, useEffect } from "react";
import {
  Grid,
  Modal,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import classing from "../../../Admin/UserRoles/modal.module.css";
import "./contractTrade.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "30%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  width: "90%",
  height: "90%",
  overflow: "scroll",
};

const ContractTradeModal = (props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    props.setOpenModal(false);
  };
  useEffect(() => {
    if (props.openModal) {
      handleOpen(true);
    }
  }, [props.openModal]);
  const handleClose = () => {
    setOpen(false);
  };

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const deleteIds = (id1, id2) => {
    props.contractDisciplines.map((cd) => {
      if (cd.Id === id1) {
        let index = cd.Trades.findIndex((o) => o.Id === id2);
        if (index !== -1) cd.Trades.splice(index, 1);
      }
    });
    let arr = props.contractDisciplines.filter(
      (obj) => obj.Trades.length !== 0
    );
    props.setcontractDisciplines(arr);
    arr = [];
  };

  const assignTtoD = (dId, tId) => {
    props.setcontractTrades(
      props.contractDisciplines.find((d) => d.Id === dId)?.Trades
        ? props.contractDisciplines.find((d) => d.Id === dId)?.Trades
        : []
    );
    let tempDisciplineId = dId;
    let tempTradeId = tId;
    let dataTrade = { Id: tempTradeId };
    let arrTrade = props.contractTrades;
    arrTrade.push(dataTrade);
    const tradeIds = arrTrade.map((t) => t.Id);
    const newArrTrades = arrTrade.filter(
      ({ Id }, index) => !tradeIds.includes(Id, index + 1)
    );
    let dataDiscipline = { Id: dId, Trades: newArrTrades };
    let arrDiscipline = props.contractDisciplines;
    arrDiscipline.push(dataDiscipline);
    let filterDiscipline = arrDiscipline.filter((x) => x.Id === dId);
    if (filterDiscipline.length > 1) {
      const mergeTrades = filterDiscipline[0].Trades.concat(
        filterDiscipline[1].Trades
      );
      const filterIds = mergeTrades.map((t) => t.Id);
      const newFilteredTrades = mergeTrades.filter(
        ({ Id }, index) => !filterIds.includes(Id, index + 1)
      );
      const newArrayDiscipline = arrDiscipline.filter((x) => x.Id !== dId);
      const newData = { Id: dId, Trades: newFilteredTrades };
      newArrayDiscipline.push(newData);
      props.setcontractDisciplines(newArrayDiscipline);
    } else {
      const discIds = arrDiscipline.map((t) => t.Id);
      const newArrDisciplines = arrDiscipline.filter(
        ({ Id }, index) => !discIds.includes(Id, index + 1)
      );
      props.setcontractDisciplines(newArrDisciplines);
    }
    props.setcontractTrades([]);
  };

  useEffect(() => {
    if (props.getDisciplines.length > 0) {
      props.setcontractDisciplines(props.getDisciplines);
    }
  }, [props.getDisciplines]);

  return (
    <div>
      <Modal
        disableScrollLock
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container direction={"column"}>
          <Grid
            item
            container
            direction={"row-reverse"}
            paddingRight={{ xs: "4%", md: "4.5%" }}
            paddingTop={{ xs: "2%", sm: "1%", md: 0 }}
          >
            <CloseIcon
              fontSize="large"
              onClick={handleClose}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              style={{ color: "#fff" }}
            />
          </Grid>
          <Box sx={style}>
            <Grid
              item
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              padding="16.7px 28px 25.1px 57px"
            >
              <Grid
                item
                className={classing["Permission-title"]}
                fontSize={20}
                paddingTop={1}
                paddingBottom={2}
              >
                Assign Trade to Discipline
              </Grid>
              <Grid
                item
                sx={{
                  height: 0.1,
                  width: "100%",
                  backgroundColor: "#f2f2f2",
                  border: "solid 1px #f2f2f2",
                }}
              ></Grid>
              <Grid
                item
                container
                direction="row"
                alignItems="flex-end"
                paddingTop={5}
                gap={2}
              >
                <Grid item xs={1} className="trade" paddingTop="7%">
                  Subproject Trade
                </Grid>
                <Grid item xs={10} container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      fontFamily: "Muli",
                      fontSize: 12,
                      fontWeight: "bold",
                      lineHeight: 1.33,
                      textAlign: "left",
                      color: "#101650",
                    }}
                  >
                    Subproject Discipline
                  </Grid>
                  <Grid item xs={12}>
                    <TableContainer sx={{ minWidth: "1000px" }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            {props.disciplines.map((d) => (
                              <TableCell
                                key={d.Id}
                                sx={{
                                  fontFamily: "Muli",
                                  fontSize: 12,
                                  lineHeight: 1.33,
                                  textAlign: "left",
                                  color: "#101650",
                                }}
                              >
                                {d.Name}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {props.trades.map((row) => (
                            <TableRow
                              key={row.Id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  fontFamily: "Muli",
                                  fontSize: 12,
                                  lineHeight: 1.33,
                                  textAlign: "left",
                                  color: "#101650",
                                }}
                              >
                                {row.Name}
                              </TableCell>
                              {props.disciplines.map((d) => (
                                <TableCell
                                  key={d.Id}
                                  sx={{
                                    fontFamily: "Muli",
                                    fontSize: 30,
                                    lineHeight: 1.33,
                                    paddingLeft: '2%',
                                    color: localStorage.getItem("viewSubPro") === "true" ? "#bcbcbc" : "#101650",
                                  }}
                                  onMouseOver={(e) =>
                                    (e.target.style.cursor = "pointer")
                                  }
                                  align="left"
                                  onClick={() => {
                                    if (localStorage.getItem("viewSubPro") !== "true") {
                                      if (
                                        props.contractDisciplines
                                          ?.find((g) => g.Id === d.Id)
                                          ?.Trades?.find((t) => t.Id === row.Id)
                                      ) {
                                        deleteIds(d.Id, row.Id);
                                      } else {
                                        assignTtoD(d.Id, row.Id);
                                      }
                                      forceUpdate();
                                    }
                                  }}
                                >
                                  {props.contractDisciplines
                                    ?.find((g) => g.Id === d.Id)
                                    ?.Trades?.find((t) => t.Id === row.Id)
                                    ? "x"
                                    : ""}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row-reverse"
                flexWrap="wrap-reverse"
                paddingTop="5%"
              >
                <Grid item>
                  <button
                    disabled={localStorage.getItem("viewSubPro") === "true" || props.contractDisciplines.length === 0
                    }
                    className={classing.SaveModal}
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                      e.target.style.backgroundColor = "#242B64";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#0b5cab";
                    }}
                    style={{
                      backgroundColor: localStorage.getItem("viewSubPro") === "true" || props.contractDisciplines.length === 0 ? "#bcbcbc" : "#0b5cab",
                      fontFamily: "Muli",
                      textTransform: "none",
                    }}
                    onClick={() => {
                      setTimeout(() => {
                        props.setAssigned(true);
                        setOpen(false);
                      }, [1000]);
                    }}
                  >
                    Save
                  </button>
                </Grid>
                <Grid item paddingRight={2}>
                  <button
                    className={classing.exitModal}
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                      e.target.style.backgroundColor = "#f5f5f5";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#fff";
                    }}
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Modal>
    </div>
  );
};

export default ContractTradeModal;
