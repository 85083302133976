import React, { useEffect, useState, useMemo } from "react";
import { Grid } from "@mui/material";
import BayCard from "./BayCard";
import SaasBack from "../../../Assets/Images/Projects/bgTableProject.png";
import { useNavigate, useLocation } from "react-router-dom";
import * as baysServices from "../../../Services/factories-services.proxy";
import CircularProgress from "@mui/material/CircularProgress";
import classing from "../../../Assets/Styles/factory.module.css";

const getBaysCards = baysServices.getBaysCards;
function Bay(props) {
  const navigate = useNavigate();

  useEffect(() => {
    props.setRegister(true);
  }, [props]);

  useEffect(() => {
    props.setNaved(false);
  }, [props]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [factoryBays, setFactoryBays] = useState([]);
  const [baysLength, setBaysLength] = useState(0);

  const [loading, setLoading] = useState(false);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const ID = query.get("id");

  useEffect(() => {
    let apiTest = true;
    setLoading(true);
    getBaysCards(ID).then((x) => {
      if (apiTest) {
        setFactoryBays(x.BaysCard);
        setBaysLength(x.Total);
        setLoading(false);
      }
    });
    return () => {
      apiTest = false;
    };
  }, [ID]);

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <Grid
        item
        container
        paddingLeft={{ xs: "0%", sm: "1%", md: "5%" }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          container
          direction="row"
          gap={1}
          paddingTop={{ xs: "10%", sm: "7%", md: "2%" }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            container
            direction="column"
            lg={1.5}
            sm={2.5}
            xs={2.5}
            paddingTop={{ xs: "4%", sm: "2%", lg: "1%" }}
          >
            <Grid
              item
              fontSize={{ xs: "11px", sm: "18px" }}
              fontWeight="bold"
              className={classing["Title"]}
            >
              {localStorage.getItem("FactoryName")}
            </Grid>
            <Grid item fontWeight={"normal"} className={classing["Title"]}>
              Bays
            </Grid>
          </Grid>

          <Grid
            item
            lg={7.7}
            sm={5.5}
            xs={3.5}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>
          <Grid
            item
            container
            lg={0.9}
            sm={1.8}
            xs={2.5}
            className={classing["ViewText"]}
            fontSize={{ xs: "9px", sm: "12px" }}
          >
            <Grid
              item
              xs={12}
              onClick={() => {
                navigate(`/saas/baysList?id=${ID}`);
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
            >
              View all Bays
            </Grid>
          </Grid>

          <Grid item lg={0.9} sm={1}>
            <button
              className={classing["back-button"]}
              onClick={() => {
                navigate(-1);
                localStorage.removeItem("FactoryName");
              }}
            >
              Go Back
            </button>
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={11.3}
          paddingTop="2%"
          alignItems="flex-start"
          gap={2}
          marginBottom={2}
        >
          {loading ? (
            <Grid item xs={12} container justifyContent="center">
              <Grid item paddingTop="5%">
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <>
              {factoryBays?.length > 0 ? (
                <>
                  {factoryBays?.map((data, index) => (
                    <Grid item xs={6} sm={3} md={2.5} lg={1.5} key={data.Id}>
                      <BayCard data={data} index={index} factoryId = {ID}/>
                    </Grid>
                  ))}
                </>
              ) : (
                ""
              )}
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default Bay;
