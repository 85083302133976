import * as constFile from "../../pileCubesConst";
import axios from "axios";

const IOT_LOGIN = constFile.IOT_LOGIN;
export const IOTLogIn = (code, password) => {
  return axios
    .post(`${process.env.REACT_APP_URL + IOT_LOGIN}`, {
      Code: code,
      Password: password,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const HUMAN_RESOURCE_LOGIN = constFile.HUMAN_RESOURCE_LOGIN;
export const humanResourceLogin = (mashineId, barcode) => {
  return axios
    .get(`${process.env.REACT_APP_URL + HUMAN_RESOURCE_LOGIN}`, {
      params: {
        MachineId: mashineId,
        Barcode: barcode,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const MACHINE_BY_ID = constFile.MACHINE_BY_ID;
export const getMachineById = (machineId) => {
  return axios
    .get(`${process.env.REACT_APP_URL + MACHINE_BY_ID}`, {
      params: {
        MachineId: machineId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const MACHINE_DATA = constFile.MACHINE_DATA;
export const getMachineDashboardData = (machineId) => {
  return axios
    .get(`${process.env.REACT_APP_URL + MACHINE_DATA}`, {
      params: {
        MachineId: machineId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const MACHINE_BY_DATE = constFile.MACHINE_BY_DATE;
export const getOrdersDataByDate = (machineId, dateId) => {
  return axios
    .get(`${process.env.REACT_APP_URL + MACHINE_BY_DATE}`, {
      params: {
        MachineId: machineId,
        DateId: dateId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const ORDER_DATA = constFile.SALES_ORDERS_DATA_BY_GROUP_ID;
export const getOrdersDataByGroupId = (Id, machineId) => {
  return axios
    .get(`${process.env.REACT_APP_URL + ORDER_DATA}`, {
      params: {
        OptimizationGroupId: Id,
        MachineId: machineId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const CYCLE_REQUIRED_ITEM = constFile.CYCLE_REQUIRED_ITEM;
export const getRequiredItem = (groupId) => {
  return axios
    .get(`${process.env.REACT_APP_URL + CYCLE_REQUIRED_ITEM}`, {
      params: {
        optimizationGroupId: groupId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const CYCLE_BUNDLE_DETAILS = constFile.CYCLE_BUNDLE_DETAILS;
export const getItemDetails = (groupId, barcode) => {
  return axios
    .get(`${process.env.REACT_APP_URL + CYCLE_BUNDLE_DETAILS}`, {
      params: {
        MachineOptimizationGroupId: groupId,
        Barcode: barcode,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const UPDATE_USED_BUNDLE = constFile.UPDATE_USED_BUNDLE;
export const updateUsedBundle = (bundleId, groupId, lineBarcode) => {
  return axios
    .post(`${process.env.REACT_APP_URL + UPDATE_USED_BUNDLE}`, {
      BundleId: bundleId,
      MachineOptimizationGroupId: groupId,
      LineBarcode: lineBarcode,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const GET_CURRENT_CYCLE = constFile.GET_CURRENT_CYCLE;
export const getCurrentCycle = (bundleId, groupId, lineBarcode) => {
  return axios
    .get(`${process.env.REACT_APP_URL + GET_CURRENT_CYCLE}`, {
      params: {
        BundleId: bundleId,
        MachineOptimizationGroupId: groupId,
        LineBarcode: lineBarcode,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const UPDATE_CYCLE_STATUS = constFile.UPDATE_CYCLE_STATUS;
export const updateCycleStatus = (groupId, lineBarcode, statusId) => {
  return axios
    .post(`${process.env.REACT_APP_URL + UPDATE_CYCLE_STATUS}`, {
      MachineOptimizationGroupId: groupId,
      LineBarcode: lineBarcode,
      StatusId: statusId,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const GET_NEXT_CYCLE_BUNDLE = constFile.GET_NEXT_CYCLE_BUNDLE;
export const getNextCycleBundle = (groupId) => {
  return axios
    .get(`${process.env.REACT_APP_URL + GET_NEXT_CYCLE_BUNDLE}`, {
      params: {
        optimizationGroupId: groupId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const GET_PRINT_DATA = constFile.GET_PRINT_DATA;
export const getPrintData = (
  lineId,
  bundleId,
  machineId,
  categoryId,
  zoneId,
  cycleId,
  heatNumber,
  InventoryId
) => {
  return axios
    .get(`${process.env.REACT_APP_URL + GET_PRINT_DATA}`, {
      params: {
        LineId: lineId,
        BundleId: bundleId,
        MachineId: machineId,
        CategoryId: categoryId,
        ZoneId: zoneId,
        CycleId: cycleId,
        HeatNumber: heatNumber,
        InventoryId: InventoryId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const CYCLE_SUMMARY = constFile.CYCLE_SUMMARY;
export const getCycleSummary = (bundleId, groupId, lineBarcode) => {
  return axios
    .get(`${process.env.REACT_APP_URL + CYCLE_SUMMARY}`, {
      params: {
        BundleId: bundleId,
        MachineOptimizationGroupId: groupId,
        LineBarcode: lineBarcode,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const UPDATE_ZONE = constFile.UPDATE_ZONE;
export const updateZoneOrBlock = (zoneId, blockId, lineId, bundleId, cycleId) => {
  return axios
    .post(`${process.env.REACT_APP_URL + UPDATE_ZONE}`, {
      ZoneId: zoneId,
      BlockId: blockId,
      LineId: lineId,
      BundleId: bundleId,
      CycleId: cycleId
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
const HOLD_MACHINE = constFile.HOLD_MACHINE;
export const holdMachine = (Id, UserId) => {
  return axios
    .post(`${process.env.REACT_APP_URL + HOLD_MACHINE}`, {
      Id: Id,
      UserId: UserId,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
