import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import checkYellow from "../../../Assets/Images/Pricing/checkActive.png";
import checkBlue from "../../../Assets/Images/Pricing/checkSub.jpg";
import * as data from "../../../Services/pricing-data.proxy.js";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PRICING_PLANS = data.getPricingPlans;

const styles = {
  ProductFeatureGroup: {
    fontFamily: "Muli",
    fontSize: 16,
    fontWeight: "bold",
    color: "rgba(16, 22, 80, 0.7)",
    backgroundColor: "rgba(206, 210, 225, 0.2)",
    border: "solid 1px rgba(206, 210, 225, 0.5)",
    padding: "2% 0 2% 2%",
  },
  ProductFeatureAccordion: {
    color: "rgba(16, 22, 80, 0.7)",
    flexShrink: 0,
    backgroundColor: "rgba(206, 210, 225, 0.2)",
    border: "solid 1px rgba(206, 210, 225, 0.5)",
  },
  ProductName: {
    fontFamily: "Muli",
    fontSize: 16,
    fontWeight: "bold",
    color: "rgba(16, 22, 80, 0.7)",
  },
  DescriptionText: {
    fontFamily: "Muli",
    fontSize: 15,
    color: "rgba(16, 22, 80, 0.7)",
  },
};

export default function MobileFeatures({
  setLoading,
  switchType,
  subscriptionTypeId,
  setSubscriptionTypeId,
  billingId,
  setBillingId,
}) {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (index) => (event, isExpanded) => {
    setExpanded(isExpanded ? index : false);
  };
  const [data, setData] = useState();
  useEffect(() => {
    //setLoading(true);
    fetchProducts(subscriptionTypeId, billingId);
  }, [subscriptionTypeId, billingId]);

  const fetchProducts = (SubscriptionTypeId, BillingId) => {
    //setLoading(true);
    PRICING_PLANS(SubscriptionTypeId, BillingId, setLoading).then((x) =>
      setData(x)
    );
  };
  return (
    <>
      {data ? (
        <Grid container width={"357px"} marginLeft={4} marginTop={7}>
          {data.LeveledFeatureGroups.map((lfg) => (
            <Grid item container marginBottom={"5%"} key={lfg.GroupName}>
              <Grid item xs={12} sx={styles.ProductFeatureGroup}>
                {lfg.GroupName}
              </Grid>
              {lfg.FeaturesLevels ? (
                lfg.FeaturesLevels.map((fl, level) => (
                  <Grid item xs={12} key={fl.FeatureNames}>
                    {fl.FeatureNames.length === 0 ? (
                      <></>
                    ) : (
                      <Accordion
                        expanded={expanded === fl.FeatureNames}
                        onChange={handleChange(fl.FeatureNames)}
                        sx={styles.ProductFeatureAccordion}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography
                            style={{ fontFamily: "Muli", fontSize: 12 }}
                          >
                            <Grid container direction={"column"}>
                              {fl.FeatureNames.map((fn, index) => (
                                <Grid item key={index}>
                                  {fn}.
                                </Grid>
                              ))}
                            </Grid>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ backgroundColor: "white" }}>
                          {data.Products.map((p, index) => (
                            <Grid container direction={"row"} key={p.PriceId}>
                              <Grid
                                item
                                xs={6}
                                sx={styles.ProductName}
                                container
                                direction={"column"}
                              >
                                {p.Name}
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                sx={styles.DescriptionText}
                                container
                                direction={"column"}
                                borderBottom={
                                  index === data.Products.length - 1
                                    ? 0
                                    : "solid 0.1px grey"
                                }
                                alignItems={"center"}
                              >
                                {index >= level ? (
                                  <img
                                    style={{ margin: "1%" }}
                                    src={
                                      switchType === "Sub"
                                        ? checkBlue
                                        : checkYellow
                                    }
                                    alt="main"
                                    width={switchType === "Sub" ? "18%" : "20%"}
                                  />
                                ) : (
                                  <></>
                                )}
                              </Grid>
                            </Grid>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </Grid>
                ))
              ) : (
                <>
                  {lfg.AllDescriptiveFeatures ? (
                    lfg.AllDescriptiveFeatures.map((adf) => (
                      <Grid item xs={12} key={adf.Id}>
                        <Accordion
                          expanded={expanded === adf.Id}
                          onChange={handleChange(adf.Id)}
                          sx={styles.ProductFeatureAccordion}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Typography
                              style={{ fontFamily: "Muli", fontSize: 12 }}
                            >
                              {adf.Name}.
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{ backgroundColor: "white" }}
                          >
                            {data.Products.map((p, index) => (
                              <Grid container direction={"row"} key={p.PriceId}>
                                <Grid
                                  item
                                  xs={6}
                                  sx={styles.ProductName}
                                  container
                                  direction={"column"}
                                >
                                  {p.Name}
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  sx={styles.DescriptionText}
                                  container
                                  direction={"column"}
                                  borderBottom={
                                    index === data.Products.length - 1
                                      ? 0
                                      : "solid 0.1px grey"
                                  }
                                  alignItems={"center"}
                                >
                                  <div>
                                    {p.AvailableDescriptiveFeatures[adf.Id]}
                                  </div>
                                </Grid>
                              </Grid>
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    ))
                  ) : (
                    <></>
                  )}
                </>
              )}
            </Grid>
          ))}
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}
