import React, { useEffect, useState, useRef } from "react";
import MainPlan from "./PlansDescription/MainPlans";
import SubPlan from "./PlansDescription/SubPlans";
import "../../Assets/Styles/Pricing.css";
import useWindowDimensions from "../../Components/Navbar/WindowDimension";
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import TabPlanMc from "./TabPlanMc";
import TabPlanSc from "./TabPlanSc";
import MobileFeatures from "./PlansDescription/MobileFeatures";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#0674b9",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#0674b9",
    width: "146px",
    textAlign: "center",
    fontFamily: "Muli",
    fontSize: "13px",
    padding: "12px 12px 12px 12px",
    borderRadius: "12px",
  },
}));
function EngineeringPlans({
  setLoading,
  accounType,
  isLoggedIn,
  setShowContactUsPricing,
}) {
  const [isClickedMc, setIsClickedMc] = useState(false);
  const [isClickedSc, setIsClickedSc] = useState(false);
  const [activeMc, setActiveMc] = useState(false);
  const [activeSc, setActiveSc] = useState(false);
  const [switchType, setSwitchType] = useState("Main");
  const { width } = useWindowDimensions();
  const [phoneView, setphoneView] = useState(false);
  const [tooltipIsOpenMc, setTooltipIsOpenMc] = useState(false);
  const [tooltipIsOpenSc, setTooltipIsOpenSc] = useState(false);
  const [billingId, setBillingId] = useState("");
  const [subscriptionTypeId, setSubscriptionTypeId] = useState("");

  const handleClickOutside = (e) => {
    if (!myRef.current.contains(e.target)) {
      setTooltipIsOpenSc(false);
      setTooltipIsOpenMc(false);
    }
  };
  useEffect(() => {
    if (width < 1000) {
      setphoneView(true);
    } else {
      setphoneView(false);
    }
  });

  useEffect(() => {
    // setLoading(true);
    // setTimeout(() => {
    //   setLoading(false);
    // }, 1000);
    if (switchType === "Main") {
      setSubscriptionTypeId(1);
      setBillingId(1);
    }
    if (switchType === "Sub") {
      setSubscriptionTypeId(2);
      setBillingId(1);
    }
  }, [switchType]);

  useEffect(() => {
    if (accounType === 1) {
      setSwitchType("Main");
      setActiveMc(true);
      setIsClickedMc(true);

      setBillingId(1);
      setSubscriptionTypeId(1);
    } else if (accounType === 2) {
      setActiveMc(false);
      setIsClickedMc(false);
      setSwitchType("Sub");
      setActiveSc(true);
      setIsClickedSc(true);
      setBillingId(1);
      setSubscriptionTypeId(2);
    } else if (accounType !== 2 && switchType === "Main") {
      setSwitchType("Main");
      setActiveMc(true);
      setIsClickedMc(true);
    }
  }, [accounType]);
  const myRef = useRef(null);

  const executeScroll = () => window.scrollTo(0, myRef);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid item container md={12} spacing={2}>
        <Grid
          item
          container
          md={12}
          direction={"row"}
          marginLeft={{ xs: 5.5, md: 17 }}
          paddingBottom={18}
        >
          <Grid
            item
            container
            md={2}
            marginTop={{ md: 12, xs: 0 }}
            display={{ md: "block", xs: "flex" }}
            direction={{ xs: "row" }}
            marginRight={{ xs: 10,sm:0 }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid item container md={12} xs={2} direction={"row"}>
              <Grid
                item
                container
                md={2}
                xs={12}
                marginTop={0.7}
                marginLeft={{ xs: 4.7, md: 0 }}
              >
                <div
                  className={activeMc ? "MC-CircleActive" : "MC-Circle"}
                  onMouseEnter={(e) => {
                    if (isClickedMc === false) {
                      setActiveMc(true);
                      e.target.style.cursor = "pointer";
                    }
                  }}
                  onMouseOut={(e) => {
                    if (isClickedMc === false) setActiveMc(false);
                  }}
                  onClick={(e) => {
                    setIsClickedMc(true);
                    setIsClickedSc(false);
                    setSubscriptionTypeId(1);
                    setBillingId(1);
                    setActiveSc(false);
                    setActiveMc(true);
                    setSwitchType("Main");
                  }}
                >
                  MC
                </div>
              </Grid>
              <Grid item container md={10} xs={12}>
                <BootstrapTooltip
                  open={tooltipIsOpenMc}
                  title={
                    "Please create a Main contractor account in order to be allowed to subscribe to the sub contractor plan."
                  }
                  placement="bottom"
                >
                  <div
                    className="Main-Contractor"
                    style={{ opacity: activeMc ? 1 : 0.5 }}
                    onMouseEnter={(e) => {
                      if (isClickedMc === false) {
                        setActiveMc(true);
                        e.target.style.cursor = "pointer";
                      }
                    }}
                    onMouseOut={(e) => {
                      if (isClickedMc === false) setActiveMc(false);
                    }}
                    onClick={(e) => {
                      setIsClickedMc(true);
                      setIsClickedSc(false);
                      setSubscriptionTypeId(1);
                      setBillingId(1);
                      setActiveSc(false);
                      setActiveMc(true);
                      setSwitchType("Main");
                    }}
                  >
                    Main Contractor
                  </div>
                </BootstrapTooltip>
              </Grid>
            </Grid>
            <Grid
              ref={myRef}
              item
              md={12}
              xs={2}
              container
              marginTop={{ md: 4, xs: 0 }}
              marginLeft={{ md: 0, xs: 13 }}
              direction={"row"}
            >
              <Grid
                item
                container
                md={2}
                xs={12}
                marginTop={{ md: 0.7 }}
                marginLeft={{ xs: 4.4, md: 0 }}
              >
                <div
                  className={activeSc ? "SC-CircleActive" : "SC-Circle"}
                  onMouseEnter={(e) => {
                    if (isClickedSc === false) {
                      setActiveSc(true);
                      e.target.style.cursor = "pointer";
                    }
                  }}
                  onMouseLeave={(e) => {
                    if (isClickedSc === false) {
                      setActiveSc(false);
                    }
                  }}
                  onClick={(e) => {
                    setIsClickedSc(true);
                    setActiveSc(true);
                    setIsClickedMc(false);
                    setSubscriptionTypeId(2);
                    setBillingId(1);
                    setActiveMc(false);
                    setSwitchType("Sub");
                  }}
                >
                  SC
                </div>
              </Grid>

              <Grid item container md={10} xs={12}>
                <BootstrapTooltip
                  open={tooltipIsOpenSc}
                  title={
                    "Please create a sub contractor account in order to be allowed to subscribe to the sub contractor plan."
                  }
                  placement="bottom"
                >
                  <div
                    className="Sub-Contractor"
                    style={{ opacity: activeSc ? 1 : 0.5 }}
                    onMouseEnter={(e) => {
                      if (isClickedSc === false) {
                        setActiveSc(true);
                        e.target.style.cursor = "pointer";
                      }
                    }}
                    onMouseLeave={(e) => {
                      if (isClickedSc === false) {
                        setActiveSc(false);
                      }
                    }}
                    onClick={(e) => {
                      setIsClickedSc(true);
                      setActiveSc(true);
                      setIsClickedMc(false);
                      setSubscriptionTypeId(2);
                      setBillingId(1);
                      setActiveMc(false);
                      setSwitchType("Sub");
                    }}
                  >
                    Sub Contractor
                  </div>
                </BootstrapTooltip>
              </Grid>
            </Grid>
            {/*Manu Project comment on end*/}
            <Grid item paddingTop={3} md={8} className="Manu-hint">
              You can subscribe to an Engineering plan and a Manufacturing plan
              simultaneously with 2 different billing types and periods.
            </Grid>
            <Grid item paddingTop={1.5} md={8} className="Manu-hint">
              Once you are subscribed to an Engineering Plan, you will have the
              features of the Manufacturing Free Plan.
            </Grid>
          </Grid>
          <Grid
            item
            container
            md={9.5}
            alignItems={{ xs: "center", md: "normal" }}
            justifyContent={{ xs: "center", md: "normal" }}
            onClick={(e) => {
              if (switchType === "Main" && accounType === 2) {
                setTooltipIsOpenMc(true);
                executeScroll();
              }
              if (switchType === "Sub" && accounType === 1) {
                setTooltipIsOpenSc(true);
                executeScroll();
              }
            }}
          >
            {switchType === "Main" ? (
              <TabPlanMc
                setLoading={setLoading}
                accounType={accounType}
                isLoggedIn={isLoggedIn}
                subscriptionTypeId={subscriptionTypeId}
                setSubscriptionTypeId={setSubscriptionTypeId}
                billingId={billingId}
                setBillingId={setBillingId}
                setShowContactUsPricing={setShowContactUsPricing}
              />
            ) : switchType === "Sub" ? (
              <TabPlanSc
                subscriptionTypeId={subscriptionTypeId}
                setSubscriptionTypeId={setSubscriptionTypeId}
                billingId={billingId}
                setBillingId={setBillingId}
                setLoading={setLoading}
                accounType={accounType}
                isLoggedIn={isLoggedIn}
                setShowContactUsPricing={setShowContactUsPricing}
              />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        {phoneView ? (
          <Grid
            container
            alignItems={{ xs: "center", md: "normal" }}
            justifyContent={{ xs: "center", md: "normal" }}
          >
            <MobileFeatures
              setLoading={setLoading}
              switchType={switchType}
              subscriptionTypeId={subscriptionTypeId}
              setSubscriptionTypeId={setSubscriptionTypeId}
              billingId={billingId}
              setBillingId={setBillingId}
            />
          </Grid>
        ) : (
          <>
            <Grid item md={12} paddingBottom={15}>
              {switchType === "Main" ? (
                <MainPlan
                  setLoading={setLoading}
                  subscriptionTypeId={subscriptionTypeId}
                  setSubscriptionTypeId={setSubscriptionTypeId}
                  billingId={billingId}
                  setBillingId={setBillingId}
                />
              ) : switchType === "Sub" ? (
                <SubPlan
                  setLoading={setLoading}
                  subscriptionTypeId={subscriptionTypeId}
                  setSubscriptionTypeId={setSubscriptionTypeId}
                  billingId={billingId}
                  setBillingId={setBillingId}
                />
              ) : (
                ""
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}

export default EngineeringPlans;
