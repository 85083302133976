import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import classes from "../../../Assets/Styles/ProductionLabel.module.css";
import PrintingLabelPreview from "../../Admin/Production Label/PrintingLabel/PrintingLabelPreview";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import * as salesOrderServices from "../../../Services/sales-order-services.proxy";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
const printLabel = salesOrderServices.printLabel;

const PreviewPrinting = (props) => {
  const ref = useRef();
  const [heightDifference, setHeightDifference] = useState(0);
  const [salesOrderFields, setSalesOrderFields] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [cancelling, setCancelling] = useState(false);
  const [noLabels, setnoLabels] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  useEffect(() => {
    printLabel(props.SALES_ORDER_ID).then((x) => {
      setLoadingData(false);
      if (x.status === 404) {
        setnoLabels(true);
      } else {
        setSalesOrderFields(x);
      }
    });
  }, []);
  return (
    <Grid
      container
      direction={"row"}
      gap={2}
      marginTop={2}
      bgcolor={"rgb(213 232 244)"}
      paddingBottom={10}
    >
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "transparent",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
          flexDirection: "column",
        }}
        open={loadingData}
      >
        <CircularProgress />

        <Grid
          item
          fontFamily={"Muli"}
          fontSize={15}
          fontWeight={"bold"}
          color={"rgb(25, 118, 210)"}
        >
          Loading Data...
        </Grid>
      </Backdrop>
      <Grid item paddingLeft={2.5}>
        <PrintingLabelPreview
          myRef={ref}
          heightDifference={heightDifference}
          salesOrderFields={salesOrderFields}
        />
      </Grid>
      {loadingData ? (
        ""
      ) : noLabels ? (
        <Grid container alignItems={"center"} justifyContent="center">
          <Grid item xs={12} className={classes["Cant-Print-Label"]}>
            Can't Print Label.
          </Grid>
          <Grid item xs={12} className={classes["No-label-templates"]}>
            No label templates found, please contact your admin.
          </Grid>
          <Grid item>
            <button
              className={classes.cancelbut}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onClick={() => {
                setCancelling(true);
                props.setPreviewPrinting(false);
                props.setShowSaaSNavbar(true);
                setTimeout(() => {
                  setCancelling(false);
                }, 1500);
              }}
            >
              {cancelling ? "Canceling..." : "Cancel"}
            </button>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={5}>
          <Grid
            item
            marginTop={3.1}
            container
            xs={12}
            direction={"row-reverse"}
            padding={2}
          >
            <Grid item marginLeft={2}>
              <ReactToPrint content={() => ref.current}>
                <PrintContextConsumer>
                  {({ handlePrint }) => (
                    <button
                      className={classes.cancelbut}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                      onClick={() => {
                        // setHeightDifference(200);
                        handlePrint();
                      }}
                    >
                      Proceed
                    </button>
                  )}
                </PrintContextConsumer>
              </ReactToPrint>
            </Grid>
            <Grid item>
              <button
                className={classes.cancelbut}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={() => {
                  setCancelling(true);
                  props.setPreviewPrinting(false);
                  props.setShowSaaSNavbar(true);
                  setTimeout(() => {
                    setCancelling(false);
                  }, 1500);
                }}
              >
                {cancelling ? "Canceling..." : "Cancel"}
              </button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid item container justifyContent={"center"}>
              <div className={classes["Production-Label"]}>
                Print Label Preview
              </div>
            </Grid>
            <Grid
              item
              container
              justifyContent={"center"}
              alignItems={"center"}
              paddingTop={1}
              paddingBottom={1}
              direction={"row"}
              gap={2}
              className={classes["LabelInfoTitle"]}
            >
              <Grid item xs={3.2} md={2}>
                Label Name
              </Grid>
              <Grid item xs={3.2} md={2}>
                Label Size
              </Grid>
              <Grid item xs={3.2} md={2}>
                Label Type
              </Grid>
            </Grid>
            <Grid
              item
              container
              justifyContent={"center"}
              alignItems={"center"}
              direction={"row"}
              gap={2}
              className={classes["FieldData"]}
            >
              <Grid item xs={3.2} md={2}>
                {salesOrderFields?.LabelDetails?.Name
                  ? salesOrderFields.LabelDetails.Name
                  : ""}
              </Grid>
              <Grid item xs={3.2} md={2}>
                {salesOrderFields?.LabelDetails?.NumOfTags
                  ? salesOrderFields.LabelDetails.NumOfTags + " Tags"
                  : ""}
              </Grid>
              <Grid item xs={3.2} md={2}>
                {salesOrderFields?.LabelDetails?.LabelTypeName
                  ? salesOrderFields.LabelDetails.LabelTypeName
                  : ""}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default PreviewPrinting;

//
// const StyledModal = styled(ModalUnstyled)`
//   position: fixed;
//   z-index: 1300;
//   right: 0;
//   bottom: 0;
//   top: 0;
//   left: 0;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

// const Backdrop = styled("div")`
//   z-index: -1;
//   position: fixed;
//   right: 0;
//   bottom: 0;
//   top: 0;
//   left: 0;
//   background-color: rgba(0, 0, 0, 0.5);
//   -webkit-tap-highlight-color: transparent;
// `;

// const style = {
//   bgcolor: "#transparent",
//   border: "0",
//   width: "45%",
//   height: "90%",
//   outline: "none",
// };

// export default function PreviewPrintingModal(props) {
//   const [open, setOpen] = useState(false);
//   const handleOpen = () => setOpen(true);
//   const [shapeForm, setShapeForm] = useState(null);
//   const navigate = useNavigate();
//   const ref = useRef();
//   const handleClose = () => {
//     setOpen(false);
//     props.setOpen(false);
//   };
//   useEffect(() => {
//     if (props.open) {
//       handleOpen(true);
//     }
//   }, [props.open]);
//   return (
//     <div>
//       <StyledModal
//         disableScrollLock
//         aria-labelledby="unstyled-modal-title"
//         aria-describedby="unstyled-modal-description"
//         open={open}
//         onClose={handleClose}
//         BackdropComponent={Backdrop}
//       >
//         <Box sx={style}>
//           <Grid container direction={"row-reverse"}>
//             <IconButton
//               onMouseOver={(e) => {
//                 e.target.style.cursor = "pointer";
//               }}
//               onClick={() => {
//                 handleClose();
//               }}
//             >
//               <CloseIcon htmlColor="#fff" />
//             </IconButton>
//           </Grid>

//         </Box>
//       </StyledModal>
//     </div>
//   );
// }
