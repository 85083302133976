import React, { useState, useEffect } from "react";
import classes from "../../Assets/Styles/SalesProjectListCards.module.css";
import { Button, Grid } from "@mui/material";
import SalesProjectCard from "./SalesProjectCard";
import { useNavigate } from "react-router-dom";
import * as salesOrderServices from "../../Services/sales-order-services.proxy";
import CircularProgress from "@mui/material/CircularProgress";
import CalenderIcon from "../../Assets/Images/CalenderIcon.png";
import { useSelector } from "react-redux";
import * as permissionFunctions from "../../../ReusableFunctions";

const CheckIfPermitted = permissionFunctions.CheckIfPermitted;

const getProjectOrderBooksCardList =
  salesOrderServices.getProjectOrderBooksCardList;
const createSalesOrder = salesOrderServices.createSalesOrder;

function SalesProjectListCards() {
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );

  const [disabledS, setDisabledS] = useState(false);

  useEffect(() => {
    if (UserPermissions) {
      CheckIfPermitted(
        UserPermissions,
        "Sales Order",
        "Create/Place order manually",
        setDisabledS
      );
    }
  }, [UserPermissions]);

  const [salesProjects, setSalesProjects] = useState([]);
  const [proLength, setProLength] = useState(0);
  const [loading, setLoading] = useState(true);
  const [modify, setModify] = useState(false);

  useEffect(() => {
    getProjectOrderBooksCardList().then((x) => {
      setSalesProjects(x);
      setProLength(x.length);
      setLoading(false);
    });
  }, [modify]);

  const navigate = useNavigate();

  const emptyCard = [
    {
      Id: -1,
      Name: "Project Name",
      ClientName: "Client Name",
      SalesOrders: 0,
    },
  ];
  return (
    <Grid item container alignItems="center" justifyContent="center" rowGap={1}>
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        gap={1}
        paddingTop={
          localStorage.getItem("AccountType") !== "2"
            ? { xs: "10%", sm: "7%", md: "3%" }
            : { xs: "10%", sm: "7%", md: "2%" }
        }
      >
        <Grid
          item
          lg={1.5}
          sm={2.8}
          xs={4}
          fontSize={{ xs: "13px", sm: "18px" }}
          fontWeight="bold"
          className={classes["Title"]}
        >
          Project Order Books
        </Grid>
        <Grid
          item
          lg={7.5}
          sm={6}
          xs={3}
          sx={{
            border: "solid 0.1px #fff",
            backgroundColor: "#fff",
            height: 0,
          }}
        ></Grid>
        <Grid
          item
          container
          direction="column"
          lg={1.1}
          sm={2}
          xs={3.5}
          className={classes["ViewText"]}
          fontSize={{ xs: "9px", sm: "12px" }}
          paddingTop={
            localStorage.getItem("AccountType") !== "2"
              ? 0
              : { xs: "4%", sm: "1.5%", lg: "1%" }
          }
        >
          <Grid
            item
            xs={12}
            onClick={() => {
              navigate("/saas/salesProjectList");
            }}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
              e.target.style.fontWeight = "bold";
            }}
            onMouseOut={(e) => {
              e.target.style.fontWeight = "normal";
            }}
          >
            View All Order Books
          </Grid>
          <Grid
            item
            xs={12}
            onClick={() => {
              navigate("/saas/salesOrderList");
            }}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
              e.target.style.fontWeight = "bold";
            }}
            onMouseOut={(e) => {
              e.target.style.fontWeight = "normal";
            }}
          >
            View All Sales Orders
          </Grid>
          {localStorage.getItem("AccountType") === "2" ? (
            <Grid
              item
              xs={12}
              onClick={() => {
                navigate("/saas/salesOrderDashboard");
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
                e.target.style.fontWeight = "bold";
              }}
              onMouseOut={(e) => {
                e.target.style.fontWeight = "normal";
              }}
            >
              View Dashboard
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        paddingTop={localStorage.getItem("AccountType") === "1" ? 2 : 1}
        justifyContent="center"
        rowGap={2}
      >
        <Grid item lg={1.7} sm={3} xs={11}>
          <Button
            disabled={
              disabledS ||
              (salesProjects.find((p) => p.IsCreator === true) &&
                localStorage.getItem("IsFreePlanActivated") === "true")
            }
            style={{
              borderRadius: 5,
              backgroundColor:
                disabledS ||
                (salesProjects.find((p) => p.IsCreator === true) &&
                  localStorage.getItem("IsFreePlanActivated") === "true")
                  ? "lightgrey"
                  : "#e1e319",
              fontFamily: "Muli",
              fontSize: 12,
              fontWeight: "bold",
              lineHeight: 1.5,
              textAlign: "left",
              color: "#fff",
              textTransform: "none",
              padding: "10px 22px 10px 22px",
            }}
            onClick={(e) => {
              e.target.style.cursor = "pointer";
              navigate("/saas/salesOrderForm");
            }}
          >
            + Create Sales Order
          </Button>
        </Grid>
        <Grid
          item
          container
          lg={1}
          sm={1.5}
          xs={3}
          className={classes["disciplineTrade"]}
        >
          <Grid item xs={12} fontWeight="bold">
            Discipline:
          </Grid>
          <Grid item xs={12}>
            Structural
          </Grid>
        </Grid>
        <Grid
          item
          container
          lg={0.8}
          sm={1.5}
          xs={2.5}
          className={classes["disciplineTrade"]}
        >
          <Grid item xs={12} fontWeight="bold">
            Trade:
          </Grid>
          <Grid item xs={12}>
            Rebar
          </Grid>
        </Grid>
        {localStorage.getItem("AccountType") !== "1" ? (
          <Grid
            item
            container
            xs={5.5}
            sm={5}
            lg={6.7}
            className={classes["viewCalender"]}
            alignSelf="flex-start"
          >
            <Grid
              item
              xs={8}
              sm={4.5}
              md={1.7}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
                e.target.style.color = "#0674B9";
              }}
              onMouseLeave={(e) => {
                e.target.style.color = "rgba(6, 116, 185, 0.5)";
              }}
              onClick={() => {
                navigate("/saas/salesOrdersCalender");
              }}
            >
              View Calendar
            </Grid>
            <Grid
              item
              xs={3}
              sm={4}
              md={6}
              onClick={() => {
                navigate("/saas/salesOrdersCalender");
              }}
            >
              <img src={CalenderIcon} />
            </Grid>
          </Grid>
        ) : (
          <Grid item container xs={5.5} sm={5} lg={6.7}></Grid>
        )}
      </Grid>
      {salesProjects.find((p) => p.IsCreator === true) &&
      localStorage.getItem("IsFreePlanActivated") === "true" ? (
        <Grid item container xs={10} className={classes["hintMsg"]}>
          Upgrade your Manufacturing subscription to create sales orders on your
          multiple projects
        </Grid>
      ) : (
        <></>
      )}
      <Grid
        item
        container
        xs={11}
        sm={11}
        lg={10.2}
        paddingTop="2%"
        spacing={4}
        marginBottom={5}
      >
        {loading ? (
          <Grid item xs={12} container justifyContent="center">
            <Grid item paddingTop="5%">
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <>
            {salesProjects?.length > 0 ? (
              <>
                {salesProjects.map((data, index) => (
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={3}
                    lg={2.5}
                    xl={1.7}
                    key={data.Id}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <SalesProjectCard
                      data={data}
                      index={index}
                      modify={modify}
                      setModify={setModify}
                    />
                  </Grid>
                ))}
              </>
            ) : (
              <>
                {emptyCard.map((data, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={3}
                    lg={1.7}
                    key={data.Id}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <SalesProjectCard data={data} index={index} />
                  </Grid>
                ))}
                <Grid
                  item
                  xs={12}
                  sx={{
                    color: "#101650",
                    fontFamily: "Muli",
                    fontSize: "12px",
                  }}
                >
                  This space is empty. Create a sales order to get started.
                </Grid>
                {/* <Grid
                  item
                  xs={6}
                  sm={3}
                  md={3}
                  lg={1.7}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <SalesProjectCard data={emptyCard} />
                </Grid> */}
              </>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default SalesProjectListCards;
