import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import ProjectListCards from "../Components/Project/ProjectListCards";
import SaasBack from "../Assets/Images/ProfileDetails/SaaSbackground.png";
export default function Project(props) {

  const navigate = useNavigate();

  useEffect(() => {
    let apiTest = true;
    if (localStorage.getItem("accessToken") && apiTest) {
      if (localStorage.getItem("isVerified") === "false") {
        if (localStorage.getItem("isTeamMember") === "true")
          navigate("/teamMemberRegistration");
        else {
          if (localStorage.getItem("AccountType") === "1") navigate("/main");
          if (localStorage.getItem("AccountType") === "2") navigate("/sub");
        }
      }
    }
    else navigate('/login');
    return () => {
      apiTest = false;
    }
  }, [navigate]);

  useEffect(() => {
    props.setRegister(true);
  }, [props]);

  useEffect(() => {
    props.setNaved(false);
  }, [props]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <ProjectListCards
        setAssign={props.setAssign}
        edit={props.edit}
        setEdit={props.setEdit}
        view={props.view}
        setView={props.setView}
        projectId={props.projectId}
        setProjectId={props.setProjectId}
        join={props.sjoin}
        setJoin={props.setJoin}
        setSetupProfile={props.setSetupProfile}
        setSetupMenu={props.setSetupMenu}
      />
    </div>
  );
}
