import React, { useState, useEffect } from "react";
import * as incstr from "incstr";
import { exportToBlob } from "@excalidraw/excalidraw";
import {
  Grid,
  TextField,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableBody,
  MenuItem,
  IconButton,
} from "@mui/material";
import RemoveIcon from "../../../Assets/Images/SalesOrder/RemoveIcon.png";
import classes from "../../../Assets/Styles/Sales-Order.module.css";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import * as SALESORDERDCONSTS from "../../../../salesOrderConst";
import SyncIcon from "../../../Assets/Images/SalesOrder/Sync.png";
import AddProductIcon from "../../../Assets/Images/SalesOrder/addProduct.png";
import CircularProgress from "@mui/material/CircularProgress";
import ExcalidrawModal from "./ExcalidrawModal";
import CautionIcon from "../../../Assets/Images/SalesOrder/CautionIcon.png";
import * as salesOrderServices from "../../../Services/sales-order-services.proxy";
import ErrorModal from "../../../SharedComponents/ErrorModal";

const uploadFile = salesOrderServices.uploadFile;
const deleteFile = salesOrderServices.deleteFile;
const calculateActualLength = salesOrderServices.calculateActualLength;
const getDataSceneById = salesOrderServices.getDataSceneById;

var mexp = require("math-expression-evaluator");
const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 10,
  },
}))(TableRow);
const StyledTableSubRow = withStyles((theme) => ({
  root: {
    height: 120,
  },
}))(TableRow);
const SalesOrderMemberTable = (props) => {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [syncingImage, setSyncingImage] = useState(false);
  const [chosenId, setChosenId] = useState(false);
  const [chosenMemberId, setChosenMemberId] = useState(null);

  const [open, setOpen] = useState(false);
  const [lineToAddproductOn, setLineToAddproductOn] = useState(null);
  const [lineToChange, setLineToChange] = useState(null);
  const [products, setProducts] = useState([]);

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [BEerror, setBEerror] = useState(null);

  const useKeyPress = (targetKey) => {
    const [keyPressed, setKeyPressed] = useState(false);

    const downHandler = ({ key }) => {
      if (key === targetKey) setKeyPressed(true);
    };

    const upHandler = ({ key }) => {
      if (key === targetKey) setKeyPressed(false);
    };

    useEffect(() => {
      window.addEventListener("keydown", downHandler);
      window.addEventListener("keyup", upHandler);

      return () => {
        window.removeEventListener("keydown", downHandler);
        window.removeEventListener("keyup", upHandler);
      };
    }, []);

    return keyPressed;
  };
  const F9Pressed = useKeyPress("F9");
  const F8Pressed = useKeyPress("F8");

  useEffect(() => {
    if (F9Pressed && !props.disabledFromActivity && !props.disabledAllFields) {
      var existingMembers = props.members;
      props.setMembers(
        existingMembers.concat({
          OrderId: props.members.length + 1,
          Quantity: null,
          Name: null,
          TypeId: 1,
          BarMarkTypeId: 1,
          Lines: [],
        })
      );
    }
  }, [F9Pressed]);
  useEffect(() => {
    if (F8Pressed && !props.disabledFromActivity && !props.disabledAllFields) {
      props.members[
        props.memberIdSelected === 0 || !props.memberIdSelected
          ? props.members?.length - 1
          : props.memberIdSelected - 1
      ]?.Lines.push({
        OrderId:
          props.members[
            props.memberIdSelected === 0 || !props.memberIdSelected
              ? props.members?.length - 1
              : props.memberIdSelected - 1
          ].Lines.length + 1,
        BarMarkNo:
          props.members[
            props.memberIdSelected === 0 || !props.memberIdSelected
              ? props.members?.length - 1
              : props.memberIdSelected - 1
          ]?.BarMarkTypeId === 1
            ? props.members[
              props.memberIdSelected === 0 || !props.memberIdSelected
                ? props.members?.length - 1
                : props.memberIdSelected - 1
            ].Lines.length === 0
              ? 1
              : +props.members[
                props.memberIdSelected === 0 || !props.memberIdSelected
                  ? props.members?.length - 1
                  : props.memberIdSelected - 1
              ].Lines[
                props.members[
                  props.memberIdSelected === 0 || !props.memberIdSelected
                    ? props.members?.length - 1
                    : props.memberIdSelected - 1
                ].Lines.length - 1
              ].BarMarkNo + 1
            : props.members[
              props.memberIdSelected === 0 || !props.memberIdSelected
                ? props.members?.length - 1
                : props.memberIdSelected - 1
            ]?.BarMarkTypeId === 2
              ? props.members[
                props.memberIdSelected === 0 || !props.memberIdSelected
                  ? props.members?.length - 1
                  : props.memberIdSelected - 1
              ].Lines.length === 0
                ? "A"
                : incstr(
                  props.members[
                    props.memberIdSelected === 0 || !props.memberIdSelected
                      ? props.members?.length - 1
                      : props.memberIdSelected - 1
                  ].Lines[
                    props.members[
                      props.memberIdSelected === 0 || !props.memberIdSelected
                        ? props.members?.length - 1
                        : props.memberIdSelected - 1
                    ].Lines.length - 1
                  ].BarMarkNo,
                  "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
                )
              : null,
        BarsQuantity: null,
        TotalNumberOfBars: null,
        BarDiameter: null,
        ShapeCode: null,
        FormerDiameter: null,
        ActualLength: null,
        TheoreticalLength: null,
        ActualWeight: null,
        TheoreticalWeight: null,
        WeightPerMeter: null,
        Products: [],
        Shape: null,
        ShapeBlob: null,
        FileId: null,
      });
      forceUpdate();
    }
  }, [F8Pressed]);
  const sumOfWeightPerMember = (LinesToCalculateWeight) => {
    let sum = 0;
    for (let i = 0; i < LinesToCalculateWeight.length; i++) {
      sum = sum + LinesToCalculateWeight[i].ActualWeight;
    }
    // props.setSalesOrderWeightTheoretical(!sum ? 0 : sum);
    return !sum ? 0 : sum;
  };
  const syncShape = async (LineToSync) => {
    setSyncingImage(true);
    getDataSceneById(
      LineToSync.Shape.Code,
      // LineToSync.Id,
      null,
      props.factoryId
    ).then(async (x) => {
      if (x.status || x.statusCode) {
        if (x.status) setBEerror(x.error)
        if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
        setOpenErrorModal(true);
        setSyncingImage(false);

      } else {
        let dataScene = JSON.parse(x.DataScene);
        dataScene.elements.map((tobeChangedEl) => {
          if (tobeChangedEl.type === "text") {
            let displayedElement = LineToSync.Shape.Elements.find(
              (x) => x.Name === tobeChangedEl.originalText
            );
            if (
              displayedElement.MeasureTypeId ===
              SALESORDERDCONSTS.MEASURE_CALCULATED
            ) {
              let measureFuction = structuredClone(
                displayedElement.MeasureFunction.split(" ").join("")
              );

              LineToSync.Shape.Elements.map((elToReplaceIn) => {
                measureFuction = measureFuction.replace(
                  elToReplaceIn.Name,
                  JSON.stringify(elToReplaceIn.MeasureValue)
                );
              });

              measureFuction = measureFuction.replace(/arcsin/gm, "asin");
              measureFuction = measureFuction.replace(/arccos/gm, "acos");

              let evaluationResult = Math.round(mexp.eval(measureFuction.trim()));
              tobeChangedEl.text = JSON.stringify(evaluationResult);
              displayedElement.MeasureValue = evaluationResult;
            } else {
              tobeChangedEl.text = JSON.stringify(displayedElement.MeasureValue);
            }
          }
        });
        LineToSync.Shape.DataScene = JSON.stringify(dataScene);

        const blob = await exportToBlob({
          elements: dataScene.elements,
          mimeType: "image/png",
          appState: dataScene.appState,
          files: dataScene.files,
        });
        // if (LineToSync.FileId) {
        //   deleteFile(LineToSync.FileId, props.projectId);
        // }

        uploadFile(blob, props.projectId).then((x2) => {
          
          if (x2.status || x2.statusCode) {
            if (x2.status) setBEerror(x2.error)
            if (x2.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
            setOpenErrorModal(true);
            setSyncingImage(false);
    
          } else {
            LineToSync.FileId = x2.FileId;
            LineToSync.ShapeBlob = x2.URL;
            let ShapeWOAnno = structuredClone(LineToSync.Shape);
            ShapeWOAnno.Elements = ShapeWOAnno.Elements.filter(
              (ElType) => ElType.ShapeElementId !== 4
            );
            calculateActualLength(ShapeWOAnno).then((x3) => {
              LineToSync.ActualLength = x3.actualLength;
              LineToSync.TheoreticalLength = x3.theoreticalLength;
              LineToSync.WeightPerMeter = +(
                Math.PI *
                Math.pow(+LineToSync.BarDiameter / 2, 2) *
                0.00785
              ).toFixed(3);
              LineToSync.ActualWeight =
                LineToSync.TotalNumberOfBars *
                LineToSync.WeightPerMeter *
                (LineToSync.ActualLength / 1000);
              LineToSync.TheoreticalWeight =
                LineToSync.TotalNumberOfBars *
                LineToSync.WeightPerMeter *
                (LineToSync.TheoreticalLength / 1000);
              forceUpdate();
            });
          }
        });
        setSyncingImage(false);
      }


    });
  };
  return (
    <>
    <Grid
      container
      paddingTop={2}
      paddingBottom={10}
      paddingLeft={3}
      gap={2}
      paddingRight={3}
    >
      <Grid container item xs={12} direction={"row"}>
        <Grid item xs={6}>
          <button
            className={classes["AddMemberButton"]}
            disabled={props.disabledFromActivity || props.disabledAllFields}
            onClick={() => {
              let existingMembers = props.members;
              props.setMembers(
                existingMembers.concat({
                  OrderId: props.members.length + 1,
                  Quantity: null,
                  Name: null,
                  TypeId: 1,
                  BarMarkTypeId: 1,
                  Lines: [],
                })
              );
            }}
          >
            Add Member (F9)
          </button>
        </Grid>
        <Grid item xs={6} container direction={"row-reverse"}>
          <Grid item md={2.5} sm={5} xs={6}>
            <button
              disabled={props.disabledFromActivity || props.disabledAllFields}
              className={classes["AddMemberButton"]}
              onClick={() => {
                props.members[
                  props.memberIdSelected === 0 || !props.memberIdSelected
                    ? props.members?.length - 1
                    : props.memberIdSelected - 1
                ].Lines.push({
                  OrderId:
                    props.members[
                      props.memberIdSelected === 0 || !props.memberIdSelected
                        ? props.members?.length - 1
                        : props.memberIdSelected - 1
                    ].Lines.length + 1,
                  BarMarkNo:
                    props.members[
                      props.memberIdSelected === 0 || !props.memberIdSelected
                        ? props.members?.length - 1
                        : props.memberIdSelected - 1
                    ]?.BarMarkTypeId === 1
                      ? props.members[
                        props.memberIdSelected === 0 ||
                          !props.memberIdSelected
                          ? props.members?.length - 1
                          : props.memberIdSelected - 1
                      ].Lines.length === 0
                        ? 1
                        : +props.members[
                          props.memberIdSelected === 0 ||
                            !props.memberIdSelected
                            ? props.members?.length - 1
                            : props.memberIdSelected - 1
                        ].Lines[
                          props.members[
                            props.memberIdSelected === 0 ||
                              !props.memberIdSelected
                              ? props.members?.length - 1
                              : props.memberIdSelected - 1
                          ].Lines.length - 1
                        ].BarMarkNo + 1
                      : props.members[
                        props.memberIdSelected === 0 ||
                          !props.memberIdSelected
                          ? props.members?.length - 1
                          : props.memberIdSelected - 1
                      ]?.BarMarkTypeId === 2
                        ? props.members[
                          props.memberIdSelected === 0 ||
                            !props.memberIdSelected
                            ? props.members?.length - 1
                            : props.memberIdSelected - 1
                        ].Lines.length === 0
                          ? "A"
                          : incstr(
                            props.members[
                              props.memberIdSelected === 0 ||
                                !props.memberIdSelected
                                ? props.members?.length - 1
                                : props.memberIdSelected - 1
                            ].Lines[
                              props.members[
                                props.memberIdSelected === 0 ||
                                  !props.memberIdSelected
                                  ? props.members?.length - 1
                                  : props.memberIdSelected - 1
                              ].Lines.length - 1
                            ].BarMarkNo,
                            "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
                          )
                        : null,
                  BarsQuantity: null,
                  TotalNumofBars: null,
                  BarDiameter: null,
                  ShapeCode: null,
                  FormerDiameter: null,
                  ActualLength: null,
                  TheoreticalLength: null,
                  ActualWeight: null,
                  TheoreticalWeight: null,
                  WeightPerMeter: null,
                  Products: [],
                  Shape: null,
                  ShapeBlob: null,
                  FileId: null,
                });
                forceUpdate();
              }}
            >
              Line (F8)
            </button>
          </Grid>
          <Grid item md={3} sm={5} xs={6}>
            <TextField
              variant="standard" // <== changed this
              margin="normal"
              value={props.memberIdSelected}
              sx={styles.tfInput}
              select
              SelectProps={{ MenuProps: { disableScrollLock: true } }}
              onChange={(e) => {
                props.setMemberIdSelected(e.target.value);
              }}
              placeholder="Qty"
              InputProps={{
                disableUnderline: true,
                style: styles.tfInput,
              }}
            >
              <MenuItem value={0} disabled>
                Member Name
              </MenuItem>
              {props.members?.map((r, index) => (
                <MenuItem value={index + 1} key={r.Name}>
                  {r.Name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Grid>

      <TableContainer sx={{ width: "100%" }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{
            borderCollapse: "separate",
            borderSpacing: "0px 0px",
            border: 0,
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell sx={styles.tableCellEmpty}></TableCell>
              <TableCell sx={styles.tableCellEmpty}></TableCell>
              <TableCell sx={styles.tableCell}>
                *Bar Mark
                <br />
                No.
              </TableCell>
              <TableCell sx={styles.tableCellQty}>
                *Qty <br />
                <Grid item className={classes["SubTitle"]} paddingTop={1}>
                  Total No.
                  <br /> of Bars
                </Grid>
              </TableCell>
              <TableCell sx={styles.tableCell}>*Dia</TableCell>
              <TableCell sx={styles.tableCell}>
                Shape <br />
                Code
              </TableCell>
              <TableCell sx={styles.tableCellImage}></TableCell>
              <TableCell sx={styles.tableCell}>Length</TableCell>
              <TableCell sx={styles.tableCell}>Weight</TableCell>
              {props.status !== SALESORDERDCONSTS.DRAFT_ID ? (
                <TableCell sx={styles.tableCell}>Status</TableCell>
              ) : (
                <></>
              )}
            </TableRow>
          </TableHead>
          {props.members.map((member) => (
            <TableBody key={member.OrderId}>
              <StyledTableRow>
                <TableCell sx={styles.tableSubCell}>
                  <Grid
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                    onClick={() => {
                      if (
                        !props.disabledFromActivity &&
                        !props.disabledAllFields
                      ) {
                        let existingMembersToChange = props.members;

                        props.setMembers(
                          existingMembersToChange.filter(
                            (x) => x.OrderId !== member.OrderId
                          )
                        );
                      }
                    }}
                  >
                    <img src={RemoveIcon} alt={"Remove"} />
                  </Grid>
                </TableCell>
                <TableCell sx={styles.tableSubCell}>
                  {
                    <Grid item container gap={1}>
                      <Grid item xs={6}>
                        {member.OrderId}
                      </Grid>

                      {!member.RemainingQuantity &&
                        props.activityCode &&
                        props.status === SALESORDERDCONSTS.PLACED_ID ? (
                        <Grid item color={"#0674b9"} xs={6}>
                          Placed
                        </Grid>
                      ) : (
                        ""
                      )}
                    </Grid>
                  }
                </TableCell>
                <TableCell sx={styles.tableSubCell}></TableCell>
                <TableCell sx={styles.tableSubCell}>
                  {props.activityCode && member.RemainingQuantity > 0 ? (
                    <Grid container direction={"row"}>
                      <Grid item xs={4}>
                        <TextField
                          size="small"
                          variant="standard" // <== changed this
                          margin="normal"
                          disabled={
                            props.disabledAllFields &&
                              member.RemainingQuantity === 0
                              ? true
                              : false
                          }
                          fullWidth
                          name="Qunatity"
                          value={member.Quantity}
                          type={"number"}
                          sx={styles.tfInput}
                          onChange={(e) => {
                            member.Quantity = +e.target.value;

                            forceUpdate();
                          }}
                          placeholder="Qty"
                          InputProps={{
                            disableUnderline: true,
                            style: styles.tfInput, // <== added this
                            inputProps: {
                              min: 0,
                              max: member.RemainingQuantity,
                            },
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={8}
                        paddingTop={1.1}
                        container
                        paddingLeft={1}
                      >
                        of
                        {member.TotalQuantity}
                        <Grid item xs={12} spacing={1}>
                          Remaining:
                          {member.RemainingQuantity - member.Quantity < 0
                            ? "Please decrease the quantity"
                            : member.RemainingQuantity - member.Quantity}
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <TextField
                      variant="standard" // <== changed this
                      margin="normal"
                      fullWidth
                      name="Qunatity"
                      disabled={props.disabledAllFields}
                      value={member.Quantity}
                      type={"number"}
                      sx={styles.tfInput}
                      onChange={(e) => {
                        member.Quantity = +e.target.value;
                        member.Lines.map((liToChangeQuantity) => {
                          if (liToChangeQuantity.BarsQuantity) {
                            liToChangeQuantity.TotalNumberOfBars =
                              liToChangeQuantity.BarsQuantity * e.target.value;
                          }
                        });
                        forceUpdate();
                      }}
                      placeholder="Qty"
                      InputProps={{
                        disableUnderline: true,
                        style: styles.tfInput, // <== added this
                        inputProps: { min: 0 },
                      }}
                    />
                  )}
                </TableCell>
                <TableCell sx={styles.tableSubCell}></TableCell>
                <TableCell sx={styles.tableSubCell}></TableCell>
                <TableCell sx={styles.tableSubImage2}>
                  <Grid container>
                    <Grid item xs={4} className={classes["subsubTitiles"]}>
                      <TextField
                        variant="standard" // <== changed this
                        margin="normal"
                        fullWidth
                        value={member.Name}
                        sx={styles.tfInput2}
                        disabled={
                          props.disabledFromActivity || props.disabledAllFields
                        }
                        onChange={(e) => {
                          member.Name = e.target.value;
                          forceUpdate();
                        }}
                        placeholder="Member Name"
                        InputProps={{
                          disableUnderline: true,
                          style: styles.tfInput,
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} className={classes["subsubTitiles"]}>
                      <TextField
                        variant="standard" // <== changed this
                        margin="normal"
                        fullWidth
                        name="Member Type"
                        value={member.TypeId}
                        select
                        disabled={props.disabledAllFields}
                        sx={styles.tfInput2}
                        onChange={(e) => {
                          member.TypeId = e.target.value;
                          forceUpdate();
                        }}
                        SelectProps={{ MenuProps: { disableScrollLock: true } }}
                        InputProps={{
                          disableUnderline: true,
                          style: styles.tfInput,
                        }}
                      >
                        <MenuItem value={0} disabled>
                          Member Type
                        </MenuItem>
                        {props.memberTypes.map((r) => (
                          <MenuItem value={r.Id} key={r.Id}>
                            {r.Name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={4} className={classes["subsubTitiles"]}>
                      <TextField
                        variant="standard" // <== changed this
                        margin="normal"
                        fullWidth
                        name="Bar Mark"
                        value={member.BarMarkTypeId}
                        select
                        disabled={props.disabledAllFields}
                        sx={styles.tfInput2}
                        onChange={(e) => {
                          member.BarMarkTypeId = e.target.value;
                          member?.Lines.map((liToEnum, index) => {
                            liToEnum.BarMarkNo =
                              e.target.value === 1
                                ? index + 1
                                : e.target.value === 2
                                  ? index === 0
                                    ? "A"
                                    : incstr(
                                      member?.Lines[index - 1].BarMarkNo,
                                      "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
                                    )
                                  : null;
                          });
                          forceUpdate();
                        }}
                        SelectProps={{ MenuProps: { disableScrollLock: true } }}
                        InputProps={{
                          disableUnderline: true,
                          style: styles.tfInput,
                        }}
                      >
                        <MenuItem value={0} disabled>
                          Bar Mark
                        </MenuItem>
                        {props.barMarkTypes.map((r) => (
                          <MenuItem value={r.Id} key={r.Id}>
                            {r.Name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell sx={styles.tableSubCell}></TableCell>
                <TableCell sx={styles.tableSubCell}>
                  {sumOfWeightPerMember(member?.Lines)?.toFixed(2)}
                </TableCell>
                {props.status !== SALESORDERDCONSTS.DRAFT_ID ? (
                  <TableCell sx={styles.tableSubCell}></TableCell>
                ) : (
                  <></>
                )}
              </StyledTableRow>
              {member.Lines.map((Line, index) => (
                <StyledTableSubRow key={Line.OrderId}>
                  <TableCell sx={styles.tableSubSubCell}></TableCell>
                  <TableCell sx={styles.tableSubSubCell}>
                    <Grid
                      item
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                      onClick={() => {
                        if (
                          (!props.disabledFromActivity &&
                            !props.disabledAllFields) ||
                          Line.Shape === null
                        ) {
                          member.Lines = member.Lines.filter(
                            (lineTocheck) =>
                              lineTocheck.OrderId !== Line.OrderId
                          );

                          forceUpdate();
                        }
                      }}
                    >
                      <img src={RemoveIcon} alt={"Remove"} />
                    </Grid>
                  </TableCell>
                  <TableCell sx={styles.tableSubSubCell}>
                    {member.BarMarkTypeId === 3 ? (
                      <TextField
                        variant="standard" // <== changed this
                        margin="normal"
                        disabled={props.disabledAllFields}
                        fullWidth
                        value={Line.BarMarkNo}
                        sx={styles.tfInput2}
                        onChange={(e) => {
                          Line.BarMarkNo = e.target.value;
                          forceUpdate();
                        }}
                        placeholder="No."
                        InputProps={{
                          disableUnderline: true,
                          style: styles.tfInput,
                        }}
                      />
                    ) : (
                      Line.BarMarkNo
                    )}
                  </TableCell>
                  <TableCell sx={styles.tableSubSubCell}>
                    <TextField
                      variant="standard" // <== changed this
                      margin="normal"
                      disabled={
                        props.disabledFromActivity || props.disabledAllFields
                      }
                      fullWidth
                      value={Line.BarsQuantity}
                      type="number"
                      sx={styles.tfInput2}
                      onChange={(e) => {
                        Line.BarsQuantity = +e.target.value;
                        Line.TotalNumberOfBars =
                          +e.target.value * +member.Quantity;
                        forceUpdate();
                      }}
                      placeholder="qty"
                      InputProps={{
                        disableUnderline: true,
                        style: styles.tfInput,
                        inputProps: { min: 0 },
                      }}
                    />
                    <br />
                    <Grid item className={classes["SubTitle"]} paddingTop={1}>
                      {Line.BarsQuantity ? Line.TotalNumberOfBars : ""}
                    </Grid>
                  </TableCell>
                  <TableCell sx={styles.tableSubSubCell}>
                    <TextField
                      variant="standard" // <== changed this
                      margin="normal"
                      disabled={
                        props.disabledFromActivity || props.disabledAllFields
                      }
                      fullWidth
                      value={Line.BarDiameter}
                      sx={styles.tfInput2}
                      onChange={(e) => {
                        Line.BarDiameter = e.target.value;
                        forceUpdate();
                      }}
                      placeholder="Dia"
                      InputProps={{
                        disableUnderline: true,
                        style: styles.tfInput,
                      }}
                    />
                  </TableCell>

                  <TableCell sx={styles.tableSubSubCell}>
                    <TextField
                      variant="standard" // <== changed this
                      margin="normal"
                      fullWidth
                      value={Line.ShapeCode}
                      disabled={props.disabledAllFields}
                      sx={styles.tfInput2}
                      onChange={(e) => {
                        Line.ShapeCode = e.target.value;
                        let Found = false;
                        props.shapes.map((shape) => {
                          Line.ShapeBlob = null;
                          if (shape.Code === e.target.value) {
                            Found = true;
                            Line.Shape = structuredClone(shape);
                            forceUpdate();
                          } else {
                            if (!Found) {
                              Line.Shape = null;
                            }
                          }
                        });
                        forceUpdate();
                      }}
                      placeholder="Code"
                      InputProps={{
                        disableUnderline: true,
                        style: styles.tfInput,
                      }}
                    />
                  </TableCell>
                  <TableCell sx={styles.tableSubSubCell}>
                    {Line.Shape ? (
                      <Grid container direction={"column"} gap={2}>
                        <Grid item container direction={"row-reverse"} gap={2}>
                          <Grid
                            item
                            onMouseOver={(e) => {
                              e.target.style.cursor = "pointer";
                            }}
                            onClick={() => {
                              if (!props.disabledAllFields) {
                                if (Line.Id) {
                                  getDataSceneById(
                                    Line.ShapeCode,
                                    Line.Id,
                                    props.factoryId
                                  ).then((x) => {
                                    Line.Shape.DataScene = x.DataScene;
                                    setChosenId(Line.OrderId);
                                    setChosenMemberId(member.OrderId);

                                    setLineToAddproductOn(
                                      JSON.parse(Line?.Shape.DataScene)
                                    );
                                    setLineToChange(Line);
                                    setProducts(x.ShapeProducts);
                                    setOpen(true);
                                  });
                                } else {
                                  setChosenId(Line.OrderId);
                                  setLineToAddproductOn(
                                    JSON.parse(Line?.Shape.DataScene)
                                  );
                                  setLineToChange(Line);

                                  setOpen(true);
                                }
                              }
                            }}
                          >
                            <img src={AddProductIcon} alt={"addProduct"} />
                          </Grid>
                        </Grid>
                        <Grid item style={{ position: "relative" }}>
                          <img
                            width={175}
                            src={
                              Line.ShapeBlob
                                ? Line.ShapeBlob
                                : Line.Shape.File.URL
                            }
                            alt={"shapeImg"}
                          ></img>
                          {syncingImage &&
                            chosenId === Line.OrderId &&
                            chosenMemberId === member.OrderId ? (
                            <CircularProgress
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item container direction={"row"}>
                          <Grid
                            item
                            container
                            xs={11}
                            direction={"row"}
                            gap={1}
                          >
                            {Line.Shape.Elements.map((elToFill) => (
                              <Grid container direction={"row"} xs={3.3}>
                                <Grid
                                  item
                                  sx={styles.tfInput2}
                                  xs={6}
                                  paddingTop={1}
                                >
                                  {elToFill.Name}:
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    variant="standard" // <== changed this
                                    margin="normal"
                                    fullWidth
                                    disabled={
                                      elToFill.MeasureTypeId !==
                                        SALESORDERDCONSTS.MEASURE_VARIABLE ||
                                        props.disabledAllFields
                                        ? true
                                        : false
                                    }
                                    value={
                                      elToFill.MeasureValue
                                        ? elToFill.MeasureValue
                                        : elToFill.MeasureTypeId ===
                                          SALESORDERDCONSTS.MEASURE_CALCULATED
                                          ? elToFill.MeasureFunction
                                          : elToFill.MeasureValue
                                    }
                                    sx={styles.tfInput2}
                                    onChange={(e) => {
                                      elToFill.MeasureValue = +e.target.value;
                                      forceUpdate();
                                    }}
                                    placeholder="Value"
                                    InputProps={{
                                      disableUnderline: true,
                                      style: styles.tfInput,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            onMouseOver={(e) => {
                              if (!syncingImage) e.target.style.cursor = "pointer";
                              else e.target.style.cursor = "unset"
                            }}
                            paddingLeft={1.3}
                            paddingTop={1}
                            onClick={() => {
                              if (!props.disabledAllFields && !syncingImage) {
                                setChosenId(Line.OrderId);
                                setChosenMemberId(member.OrderId);
                                syncShape(Line);
                              }
                            }}
                          >
                            <IconButton>
                              <img src={SyncIcon} alt={"Sync"} />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : Line.ShapeCode !== null && Line.Shape === null ? (
                      <Grid container>
                        <Grid item xs={12} container justifyContent="left">
                          <img src={CautionIcon} alt="caution" />
                        </Grid>
                        <Grid item xs={12} className={classes["ShapeDoesNot"]}>
                          Shape code does not exist in the Shape Library
                        </Grid>
                        <Grid item xs={12}>
                          Select another shape or contact pilecubes team to add
                          the shape.
                        </Grid>
                      </Grid>
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell sx={styles.tableSubSubCell}>
                    {/* Math.round(Line.ActualLength) + */}
                    {/* " " + */}
                    {Line.TheoreticalLength?.toFixed(2)}
                  </TableCell>
                  <TableCell sx={styles.tableSubSubCell}>
                    {/* Line.ActualWeight?.toFixed(2) + */}
                    {/* " " + */}
                    {Line.TheoreticalWeight?.toFixed(2)}
                  </TableCell>
                  {props.status !== SALESORDERDCONSTS.DRAFT_ID ? (
                    <TableCell sx={styles.tableSubSubCell}>
                      {Line.Status}
                    </TableCell>
                  ) : (
                    <></>
                  )}
                </StyledTableSubRow>
              ))}
            </TableBody>
          ))}
        </Table>
        {lineToAddproductOn ? (
          <ExcalidrawModal
            openModal={open}
            setOpenModal={setOpen}
            chosenId={chosenId}
            lineToAddproductOn={lineToAddproductOn}
            setLineToAddproductOn={setLineToAddproductOn}
            projectId={props.projectId}
            members={props.members}
            lineToChange={lineToChange}
            products={products}
            chosenMemberId={chosenMemberId}
          />
        ) : (
          ""
        )}
      </TableContainer>
    </Grid>
    <ErrorModal openErrorModal={openErrorModal} setOpenErrorModal={setOpenErrorModal} BEerror={BEerror} />
    </>

  );
};

export default SalesOrderMemberTable;
const styles = {
  tableCell: {
    backgroundColor:
      localStorage.getItem("AccountType") === "1"
        ? "rgba(225, 227, 25, 0.3)"
        : "rgba(45, 164, 208, 0.3)",
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#101650",
    lineHeight: 0.91,
    textAlign: "center",
    width: "250px",
  },
  tableCellEmpty: {
    backgroundColor:
      localStorage.getItem("AccountType") === "1"
        ? "rgba(225, 227, 25, 0.3)"
        : "rgba(45, 164, 208, 0.3)",
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#101650",
    lineHeight: 0.91,
    textAlign: "center",
    width: 43,
  },
  tableCellQty: {
    backgroundColor:
      localStorage.getItem("AccountType") === "1"
        ? "rgba(225, 227, 25, 0.3)"
        : "rgba(45, 164, 208, 0.3)",
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#101650",
    lineHeight: 0.91,
    textAlign: "center",
    width: 600,
  },
  tableSubCell: {
    backgroundColor:
      localStorage.getItem("AccountType") === "1"
        ? "rgba(225, 227, 25, 0.5)"
        : "rgba(45, 164, 208, 0.5)",
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#707070",
    lineHeight: 1.45,
    textAlign: "center",
  },
  tableSubSubCell: {
    border: "solid 0.5px ",
    borderColor:
      localStorage.getItem("AccountType") === "1"
        ? "rgba(225, 227, 25, 0.5)"
        : "rgba(45, 164, 208, 0.5)",
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#707070",
    lineHeight: 1.45,
    textAlign: "center",
  },
  tableCellImage: {
    backgroundColor:
      localStorage.getItem("AccountType") === "1"
        ? "rgba(225, 227, 25, 0.3)"
        : "rgba(45, 164, 208, 0.3)",
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#101650",
    lineHeight: 0.91,
    textAlign: "center",
    width: 1000,
  },
  tableSubImage2: {
    backgroundColor:
      localStorage.getItem("AccountType") === "1"
        ? "rgba(225, 227, 25, 0.5)"
        : "rgba(45, 164, 208, 0.5)",
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#707070",
    lineHeight: 1.45,
    width: 600,
    // height: 38,
  },
  tableRow: {
    backgroundColor: "#fff",
    boxShadow: "0 12px 6px 0 rgba(0, 0, 0, 0.16)",
    maxHeight: 38,
  },
  tableCell2: {
    backgroundColor: "transparent",
    fontFamily: "Muli",
    fontSize: 13,
    color: "#0674b9",
  },
  tableBody: {
    "& > :not(:last-child)": {
      borderBottom: "25px solid red",
    },
  },
  tfInput: {
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#707070",
    lineHeight: 1.45,
    textAlign: "center",
    paddingLeft: 2,
    height: 5,
  },
  tfInput2: {
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#707070",
    textAlign: "center",
    height: 5,
  },
};
