import * as constFile from "../pileCubesConst";
import axios from "axios";
const PRICING_PLANS = constFile.PRICING_PLANS;

export const getPricingPlans = (SubscriptionTypeId,BillingId) => {
  return axios
    .get(`${process.env.REACT_APP_URL + PRICING_PLANS}`, {
      params: { BillingId: BillingId, SubscriptionTypeId: SubscriptionTypeId },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
const REQUEST_SUBSCRIPTION_INPUT_DATA =
  constFile.REQUEST_SUBSCRIPTION_INPUT_DATA;

export const getRequestSubscriptionInputData = () => {
  return axios
    .get(`${process.env.REACT_APP_URL + REQUEST_SUBSCRIPTION_INPUT_DATA}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const REQUEST_SUBSCRIPTION = constFile.REQUEST_SUBSCRIPTION;

export const requestCustomSubscription = (
  UserId,
  CompanyName,
  CompanyEmail,
  CompanyNumber,
  PersonalName,
  PersonalEmail,
  PersonalNumber,
  Position,
  CountryId,
  EmployeeRangeId,
  SubscriptionTypeId,
  AgreementAcceptance,
  IsManufacturer,
  FactoryVolume,
  NumOfFactories,
  ProductId
) => {
  return axios
    .post(`${process.env.REACT_APP_URL + REQUEST_SUBSCRIPTION}`, {
      UserId: UserId,
      CompanyName: CompanyName,
      CompanyEmail: CompanyEmail,
      CompanyNumber: CompanyNumber,
      PersonalName: PersonalName,
      PersonalEmail: PersonalEmail,
      PersonalNumber: PersonalNumber,
      Position: Position,
      CountryId: CountryId,
      EmployeeRangeId: EmployeeRangeId,
      SubscriptionTypeId: SubscriptionTypeId,
      AgreementAcceptance: AgreementAcceptance,
      IsManufacturer: IsManufacturer,
      FactoryVolume: FactoryVolume,
      NumOfFactories: NumOfFactories,
      ProductId: ProductId,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {});
};
const ACTIVATE_FREE_PLAN = constFile.ACTIVATE_FREE_PLAN;
export const activateFreePlan = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + ACTIVATE_FREE_PLAN}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
