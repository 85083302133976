import * as constFile from "../../projectConst";
import axios from "axios";
const GET_DASHBOARD_DATA = constFile.GET_DASHBOARD_DATA;

export const getDashBoardData = (ProjectId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_DASHBOARD_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        ProjectId: ProjectId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};