import React, { useState, useEffect } from "react";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import * as constFile from "../../../../StatusConst";
import classes from "../../Table/Table.module.css";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { styled } from "@mui/system";
import { Grid, CircularProgress } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuPic from "../../../Assets/Images/Table/menuPic.png";
import { TextField, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Stack } from "@mui/material";
import * as tenantServices from "../../../Services/tenant-services.proxy";
const getSCAssignmentLogFilterData =
  tenantServices.getSCAssignmentLogFilterData;

const getScAssignmentLog = tenantServices.getScAssignmentLog;
const changeSubProjectStatus = tenantServices.changeSubProjectStatus;
// const resendInvitation = tenantServices.resendInvitation;

//Statuses
const INVITED = constFile.INVITED;
const ACTIVE = constFile.ACTIVE;
const SUSPENDED = constFile.SUSPENDED;

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 10px;
  box-sizing: border-box;
  min-height: 32px;
  width: 9rem;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  margin: 0.5em;
  padding: 10px;
  text-align: left;
  line-height: 1.5;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};

  &:hover {
    background: ${theme.palette.mode === "dark" ? "" : grey[100]};
    border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
  font-family: Muli;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0;
  min-width: 11rem;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  overflow: auto;
  outline: 0px;
  `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }
  `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});

export default function ScAssignmentLog(props) {
  const [scTable, setScTable] = useState([]);
  const [dataLength, setdataLength] = useState();
  const [status, setStatus] = useState(0);
  const [statuses, setStatuses] = useState([]);
  const [project, setProject] = useState(0);
  const [projects, setProjects] = useState([]);
  const [subProject, setSubProject] = useState(0);
  const [subProjects, setSubProjects] = useState([]);
  const [discipline, setDiscipline] = useState(0);
  const [disciplines, setDisciplines] = useState([]);
  const [preInvite, setPreInvite] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const [pageSize, setPageSize] = useState(25);

  const listData = {
    Pagination: {
      PageSize: pageSize,
      PageNumber: pageNumber,
    },
    InviteeId: props.rowData?.row.InviteeId,
    Search: searchText,
    ProjectId: project === 0 ? null : project,
    PhaseId: null,
    SubProjectId: subProject === 0 ? null : subProject,
    StatusId: status === 0 ? null : status,
  };
  useEffect(() => {
    let apiTest = true;

    getSCAssignmentLogFilterData().then((x) => {
      if (apiTest) {
        setStatuses(x.Statuses);
        setProjects(x.Projects);
        if (x.Projects.length > 0 && props.projectId) setProject(props.projectId);
        setDisciplines(x.Discplines);
      }
    });
    return () => {
      apiTest = false;
    };
  }, []);

  const [loadingData, setLoadingData] = useState(false);
  useEffect(() => {
    let apiTest = true;
    setLoadingData(true);
    getScAssignmentLog(listData).then((x) => {
      if (apiTest) {
        setScTable(x.SubContractorAssignmentLogs);
        setdataLength(x.Total);
        setLoadingData(false);
      }
    });

    return () => {
      apiTest = false;
    };
  }, [
    pageSize,
    pageNumber,
    searchText,
    props.modify,
    status,
    project,
    discipline,
    subProject,
  ]);
  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowData, setRowData] = useState();

  const handleClick = async (event, params) => {
    setRowData(params);

    setAnchorEl(event.target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const columns = [
    { field: "id", hide: true, hideable: false },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,

      renderCell: (params) => {
        return (
          <img
            src={MenuPic}
            alt="menu"
            onClick={(e) => handleClick(e, params)}
          ></img>
        );
      },
    },
    {
      field: "Project",
      width: 350,
    },
    {
      field: "Phase",
      headerName: "Phase",
      width: 350,
      resizeable: true,
    },
    {
      field: "SubProject",
      headerName: "Sub Project",
      width: 350,
    },
    {
      field: "Status",
      headerName: "Status",
      width: 300,

      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.StatusId === ACTIVE
                ? "green"
                : params.row.StatusId === INVITED
                  ? "#0674b9"
                  : params.row.StatusId === SUSPENDED
                    ? "red"
                    : "grey",
          }}
        >
          {params.row?.Status}
        </span>
      ),
    },
  ];
  return (
    <Grid container>
      <Grid item container direction={"row"} md={12} paddingBottom={2}>
        <Grid item lg={8} md={4}>
          <span className={classes["Table-Title"]}>
            {props.rowData.row.Name}
          </span>
        </Grid>

        <Grid
          item
          lg={4}
          container
          direction={"row-reverse"}
          paddingRight={{ md: 5 }}
        >
          <button
            className={classes["AddButton"]}
            onClick={() => {
              props.setRowData(null);
              props.setOpenModal(true);
            }}
          >
            Add
          </button>
          {props.ShowGoBack ? (
            <button
              className={classes["back-button"]}
              onClick={() => {
                props.setViewAssignmentLog(false);
              }}
            >
              Go back
            </button>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      {props.viewFilters ? (
        <Grid item md={10} container direction={"row-reverse"}>
          <Grid
            item
            md={12}
            lg={12}
            marginTop={1}
            container
            direction={"row-reverse"}
          >
            <Grid item>
              <CustomSelect
                value={status}
                onChange={(option) => {
                  setStatus(option);
                }}
              >
                <StyledOption value={0} disabled>
                  Status
                </StyledOption>
                {statuses.map((s) => (
                  <StyledOption key={s.Id} value={s.Id}>
                    {s.Name}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Grid>

            <Grid item>
              <CustomSelect
                value={subProject}
                onChange={(option) => {
                  setSubProject(option);
                }}
              >
                <StyledOption value={0} disabled>
                  Sub Project
                </StyledOption>
                {subProjects.map((s) => (
                  <StyledOption key={s.Id} value={s.Id}>
                    {s.Name}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Grid>
            <Grid item>
              <CustomSelect
                value={project}
                onChange={(option) => {
                  setProject(option);
                }}
              >
                <StyledOption value={0} disabled>
                  Project
                </StyledOption>
                {projects.map((s) => (
                  <StyledOption key={s.Id} value={s.Id}>
                    {s.Name}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Grid>
            {status || project || subProject ? (
              <Grid item marginTop={0.6}>
                <button
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={() => {
                    setStatus(0);
                    setProject(0);
                    setSubProject(0);
                  }}
                  className={classes["Rectangle-1224"]}
                >
                  Clear filters
                </button>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      ) : (
        <></>
      )}

      {props.viewSearch ? (
        <Grid
          item
          md={2}
          container
          direction={"row-reverse"}
          paddingRight={{ md: 4, xs: 0 }}
        >
          <Box
            sx={{
              p: 0.5,
              pb: 0,
            }}
          >
            <TextField
              variant="standard"
              fullWidth
              onChange={inputHandler}
              placeholder="Search this list…"
              InputProps={{
                startAdornment: (
                  <SearchIcon
                    fontSize="small"
                    htmlColor={"#707070"}
                    style={{ paddingLeft: 5 }}
                  />
                ),
              }}
              sx={{
                fontFamily: "Muli",
                backgroundColor: "white",
                borderRadius: 1,
                width: {
                  xs: 1,
                  sm: "auto",
                },
                m: (theme) => theme.spacing(1, 0.5, 1.5),
                "& .MuiSvgIcon-root": {
                  mr: 0.5,
                },
                "& .MuiInput-underline:before": {
                  borderBottom: 0,
                  borderColor: "white",
                },
              }}
            />
          </Box>
        </Grid>
      ) : (
        <></>
      )}

      <div
        style={{
          height: props.tableHeight,
          width: "98%",
          fontFamily: "Muli",
          backgroundColor: "white",
          marginTop: 10,
        }}
      >
        <DataGrid
          initialState={{
            pagination: {
              pageSize: 25,
            },
          }}
          pagination
          paginationMode="server"
          rows={scTable ? scTable : []}
          columns={columns}
          headerHeight={40}
          density={"compact"}
          showColumnRightBorder
          sx={{
            [`& .${gridClasses.row}`]: {
              color: "#0674b9",
              overflow: "hidden",
              bgcolor: (theme) =>
                theme.palette.mode === "light" ? grey[0] : grey[500],
            },

            [`& .${gridClasses.columnHeader}`]: {
              bgcolor: "rgb(172 201 224)",
              color: "rgb(17 24 84)",
            },
            [`& .${gridClasses.columnHeaderTitle}`]: {
              fontFamily: "Muli",
              fontWeight: "bold",
            },

            [`& .${gridClasses.footerContainer}`]: {
              bgcolor: "rgb(216 235 247)",
              minHeight: "30px",
            },
            fontFamily: "Muli",
            fontSize: "13px",
          }}
          disableColumnFilter
          getRowId={(row) => row.SubProjectCollaborativeTenantId}
          rowCount={dataLength}
          hideFooter={props.paging}
          onPageChange={(newPage) => {
            if (pageSize > dataLength) {
              setPageNumber(1);
            } else setPageNumber(newPage + 1);
          }}
          onPageSizeChange={(newPage) => {
            setPageSize(newPage);
          }}
          pageSize={pageSize}
          //   loading={props.loading}
          components={{
            NoRowsOverlay: () => (
              <Stack className={classes["EmptyMessage"]}>
                {loadingData ? <CircularProgress /> : <></>}
                {loadingData ? "Loading..." : "No Assgined SubProjects."}
              </Stack>
            ),
          }}
        />
      </div>
      <Menu
        id="more-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableScrollLock
      >
        {rowData?.row.StatusId === INVITED ? (
          <MenuItem
            onClick={() => {
              changeSubProjectStatus(
                rowData.row.SubProjectCollaborativeTenantId,
                INVITED
              ).then((x) => {
                setPreInvite(true);
                props.setModify(!props.modify);
                handleClose();
              });
            }}
            style={{
              fontFamily: "Muli",
              fontSize: "13px",
              color: "#444",
              width: 150,
              display: "block",
              paddingLeft: "25%",
            }}
          >
            Re-Invite
          </MenuItem>
        ) : rowData?.row.StatusId === ACTIVE ? (
          <MenuItem
            onClick={() => {
              changeSubProjectStatus(
                rowData.row.SubProjectCollaborativeTenantId,
                SUSPENDED
              ).then((x) => {
                props.setModify(!props.modify);
                handleClose();
              });
            }}
            style={{
              fontFamily: "Muli",
              fontSize: "13px",
              color: "#444",
              width: 100,
              display: "block",
              paddingLeft: "25%",
            }}
          >
            Suspend
          </MenuItem>
        ) : rowData?.row.StatusId === SUSPENDED ? (
          <MenuItem
            onClick={() => {
              changeSubProjectStatus(
                rowData.row.SubProjectCollaborativeTenantId,
                INVITED
              );
              props.setModify(!props.modify);
              handleClose();
            }}
            style={{
              fontFamily: "Muli",
              fontSize: "13px",
              color: "#444",
            }}
          >
            Re-assign
          </MenuItem>
        ) : (
          <div></div>
        )}
      </Menu>
    </Grid>
  );
}
