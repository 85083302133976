import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import home from "../../Assets/Images/home/EngBg.png";
import { Link } from "react-scroll";
import window from "../../Components/Navbar/WindowDimension";
import mobilehome from "../../Assets/Images/home/EngBgPhone.png";
import BgManu from "../../Assets/Images/home/BGManu.png";
import ManuBgMobile from "../../Assets/Images/home/manuBGPhone.png";
import { useTheme } from "@mui/material/styles";
import Slide from "@mui/material/Slide";
import { useNavigate } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const images = [
  {
    label: "Engineering",
    imgPath: home,
  },
  {
    label: "Manufactureing",
    imgPath: BgManu,
  },
];
const imagesMobile = [
  {
    label: "Engineering",
    imgPath: mobilehome,
  },
  {
    label: "Manufactureing",
    imgPath: ManuBgMobile,
  },
];

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
export default function Description({ setBooked }) {
  const [mobile, setMobile] = useState(false);
  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const [checked, setChecked] = useState(false);
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const { width } = window();
  const containerRef = useRef(null);
  const descriptionRef = useRef(null);

  const navigate = useNavigate();
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (width < 900) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [setMobile, mobile, width]);
  useEffect(() => {
    setChecked(false);
    if (activeStep === 0) {
      setChecked(true);
      setTitle("Integrate all engineering teams on your projects");
      setDescription(
        "Pilecubes allows you to integrate engineering teams involved in your projects. From design to shop drawings, bring in-house and outsourced teams together, manage design inputs, revisions and submittals in real time."
      );
    }

    if (activeStep === 1) {
      setChecked(true);
      setTitle("Integrate your fabrication facilities with your projects ");
      setDescription(
        "Pilecubes Manufacturing SaaS allows you to integrate your rebar fabrication facilities with your clients' projects. Receive your orders online, manage your planning, track your production orders, and manage your supply chain efficiently and intuitively. Unleash the power of industry 4.0, leverage real-time data analysis with Pilecubes’ IoTs and AI engine. Integrated with our Engineering SaaS, you are able to collaborate with your customers and partners, and maximize transparency while reducing your costs all on One platform. "
      );
    }
  }, [activeStep]);
  return (
    <Box className="homePage" sx={{ flexGrow: 1 }}>
      <Grid container direction={"column"}>
        <Grid item container>
          <Grid
            item
            md={7}
            xs={12}
            direction={"column"}
            container
            spacing={4}
            spacing-xs={1}
            paddingTop={"5%"}
            paddingLeft={{ md: "10%", sm: "10%", xs: "10%" }}
          >
            <Grid item ref={containerRef}>
              <Slide
                direction="up"
                in={checked}
                container={containerRef.current}
              >
                <div className="homeTitle">{title}</div>
              </Slide>
            </Grid>
            <Grid item ref={descriptionRef}>
              <Slide
                direction="up"
                in={checked}
                container={descriptionRef.current}
              >
                <div className="homeDescription">{description}</div>
              </Slide>
            </Grid>
            <Grid item>
              {mobile ? (
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 10 }}
                >
                  <Link to="chooseProfile" smooth>
                    <Button
                      onMouseOver={(e) =>
                        (e.target.style.backgroundColor = "#d8da18")
                      }
                      onMouseLeave={(e) =>
                        (e.target.style.backgroundColor = "#e1e319")
                      }
                      style={{
                        backgroundColor: "#e1e319",
                        textTransform: "none",
                        fontFamily: "Muli",
                        fontSize: 16,
                        fontWeight: "bold",
                      }}
                      className="homeButton"
                      variant="contained"
                    >
                      Join Pilecubes
                    </Button>
                  </Link>
                  <Link to="bookDemo">
                    <Button
                      onMouseOver={(e) => {
                        e.target.style.backgroundColor = "#101650";
                        e.target.style.color = "white";
                        e.target.style.border = "0";
                      }}
                      onMouseLeave={(e) =>
                        (e.target.style.backgroundColor = "#fff")
                      }
                      style={{
                        color: "#e1e319",
                        backgroundColor: "#fff",
                        textTransform: "none",
                        fontFamily: "Muli",
                        fontSize: 16,
                        fontWeight: "bold",
                        border: "1px solid #e1e319",
                      }}
                      className="homeButton"
                      variant="contained"
                      onClick={() => {
                        setBooked(true);
                        navigate("/bookDemo");
                      }}
                    >
                      Book a Demo
                    </Button>
                  </Link>
                </div>
              ) : (
                <Link to="chooseProfile" smooth>
                  <Button
                    onMouseOver={(e) =>
                      (e.target.style.backgroundColor = "#d8da18")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.backgroundColor = "#e1e319")
                    }
                    style={{
                      backgroundColor: "#e1e319",
                      textTransform: "none",
                      fontFamily: "Muli",
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                    className="homeButton"
                    variant="contained"
                  >
                    Join Pilecubes
                  </Button>
                </Link>
              )}
            </Grid>
          </Grid>
          {!mobile ? (
            <Grid item container md={5} xs={12} direction={"row-reverse"}>
              <Grid
                item
                md={11.65}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={(e) => {
                  if (activeStep === 0) {
                    setActiveStep(1);
                  } else if (activeStep === 1) {
                    setActiveStep(0);
                  }
                }}
              >
                <AutoPlaySwipeableViews
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={activeStep}
                  onChangeIndex={handleStepChange}
                  enableMouseEvents={false}
                  interval={5000}
                  springConfig={{ tension: 300, friction: 30 }}
                >
                  {images.map((step, index) => (
                    <div key={step.label}>
                      {Math.abs(activeStep - index) <= 2 ? (
                        <Box
                          component="img"
                          sx={{
                            display: "block",
                            overflow: "hidden",
                          }}
                          src={step.imgPath}
                          alt={step.label}
                        />
                      ) : null}
                    </div>
                  ))}
                </AutoPlaySwipeableViews>
              </Grid>
              <Grid item md={0.35}></Grid>
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onClick={(e) => {
                if (activeStep === 0) {
                  setActiveStep(1);
                } else if (activeStep === 1) {
                  setActiveStep(0);
                }
              }}
            >
              <AutoPlaySwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents={false}
                interval={5000}
                springConfig={{ tension: 300, friction: 30 }}
              >
                {imagesMobile.map((step, index) => (
                  <img
                    key={step.label}
                    className="mobileHomeImg"
                    src={step.imgPath}
                    alt={step.label}
                  />
                ))}
              </AutoPlaySwipeableViews>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
