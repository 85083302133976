import React, { useEffect, useState, useMemo } from "react";
import { Grid, Button, Modal, Box, TextField, Select } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { MenuItem, FormControl, FormHelperText } from "@mui/material";
import classes from "../../../Assets/Styles/factoryTables.module.css";
import { useSelector } from "react-redux";
import validator from "validator";
import * as factoryServices from "../../../Services/factories-services.proxy";

const changeMachinePassword = factoryServices.changeMachinePassword;

function ChangePassword(props) {
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    bottom: "30%",
    transform: "translate(-50%, -50%)",
    height: "60%",
    width: "32%",
    borderRadius: "15px",
    boxShadow: " 0 3px 6px 0 rgba(0, 0, 0, 0.46)",
    border: "solid 1px #0674b9",
    backgroundColor: "#fff",
  };

  const [open, setOpen] = useState(false);

  const [password, setPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");

  const [confirmPassword, setConfirmpassword] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState(false);

  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmpasswordError] = useState(false);

  const [isdisabled, setIsDisabled] = useState(true);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [LoadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    if (
      password &&
      currentPassword &&
      confirmPassword &&
      !passwordError &&
      !confirmPasswordError
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [
    password,
    currentPassword,
    confirmPassword,
    passwordError,
    confirmPasswordError,
  ]);
  const ChangePasswordData = {
    BlockId: props.rowData?.row.Id,
    CurrentPassword: currentPassword,
    NewPassword: password,
  };

  const passwordChange = (e) => {
    setLoadingButton(true);
    changeMachinePassword(ChangePasswordData).then((x) => {
      if (x.status === 200 || x.status === 201 ) {
        setPasswordChanged(true);
       
      } else {
        setCurrentPasswordError(true);
        
      }
      setLoadingButton(false);
    });
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentPasswordError(false);
    setPasswordChanged(false);
    props.setOpenModal(false);
    
    setCurrentPassword("");
    setConfirmpassword("");
    setPassword("");
    setIsDisabled(true);
  };

  useEffect(() => {
    if (props.openModal) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [props.openModal]);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  return (
    <Modal
      disableScrollLock
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          direction="column"
          padding={2}
        >
          <Grid
            item
            sx={{
              fontFamily: "Muli",
              fontSize: "22px",
              color: "#101650",
            }}
          >
            Change IOT Password
          </Grid>
          <Grid item container direction={"row"} md={12} paddingBottom={5}>
            <Grid item container direction={"row"} marginTop={2} md={12}>
              <Grid item md={12} xs={12}>
                <span className={classes["Fields-Titles"]}>
                  *Current Password
                </span>
              </Grid>
              <Grid item md={12} xs={11.4}>
                <TextField
                  inputProps={{ maxLength: 20 }}
                  error={currentPasswordError}
                  helperText={
                    currentPasswordError
                      ? "Your current password is incorrect "
                      : ""
                  }
                  type={"password"}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& > fieldset": {
                        borderColor: "#f2f2f2",
                      },
                    },
                    "& .MuiOutlinedInput-root:hover": {
                      "& > fieldset": {
                        borderColor: "#f2f2f2",
                      },
                    },
                  }}
                  id="outlined-name"
                  size="small"
                  className={classes["Field-inputs"]}
                  onChange={(e) => {
                    setCurrentPassword(e.target.value);
                    setCurrentPasswordError(false);
                  }}
                  value={currentPassword}
                />
              </Grid>
            </Grid>

            <Grid item container direction={"row"} marginTop={4} md={12}>
              <Grid item md={12} xs={12}>
                <span className={classes["Fields-Titles"]}>*New Password</span>
              </Grid>
              <Grid item md={12} xs={11.4}>
                <TextField
                  inputProps={{ maxLength: 20 }}
                  error={passwordError}
                  helperText={
                    passwordError
                      ? "Password must contain a number, a symbol, an uppercase & lowercase and be no less than 8  or more than 20 characters."
                      : ""
                  }
                  type={"password"}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& > fieldset": {
                        borderColor: "#f2f2f2",
                      },
                    },
                    "& .MuiOutlinedInput-root:hover": {
                      "& > fieldset": {
                        borderColor: "#f2f2f2",
                      },
                    },
                  }}
                  id="outlined-name"
                  size="small"
                  className={classes["Field-inputs"]}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    if (
                      !validator.isStrongPassword(e.target.value, {
                        minLength: 8,
                        minLowercase: 1,
                        minUppercase: 1,
                        minNumbers: 1,
                        minSymbols: 1,
                      })
                    ) {
                      setPasswordError(true);
                    } else if (e.target.value === confirmPassword) {
                      setConfirmpasswordError(false);
                    } else if (e.target.value !== confirmPassword) {
                      setConfirmpasswordError(true);
                      if (
                        validator.isStrongPassword(e.target.value, {
                          minLength: 8,
                          minLowercase: 1,
                          minUppercase: 1,
                          minNumbers: 1,
                          minSymbols: 1,
                        })
                      ) {
                        setPasswordError(false);
                      }
                    } else {
                      setPasswordError(false);
                    }
                  }}
                  value={password}
                />
              </Grid>
            </Grid>
            <Grid item container direction={"row"} marginTop={5} md={12}>
              <Grid item md={12} xs={12}>
                <span className={classes["Fields-Titles"]}>
                  *Confirm New Password
                </span>
              </Grid>
              <Grid item md={12} xs={11.4}>
                <TextField
                  inputProps={{ maxLength: 20 }}
                  error={confirmPasswordError}
                  helperText={
                    confirmPasswordError ? "Password not matching" : ""
                  }
                  type={"password"}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& > fieldset": {
                        borderColor: "#f2f2f2",
                      },
                    },
                    "& .MuiOutlinedInput-root:hover": {
                      "& > fieldset": {
                        borderColor: "#f2f2f2",
                      },
                    },
                  }}
                  id="outlined-name"
                  size="small"
                  className={classes["Field-inputs"]}
                  onChange={(e) => {
                    setConfirmpassword(e.target.value);
                    if (e.target.value !== password) {
                      setConfirmpasswordError(true);
                    } else {
                      setConfirmpasswordError(false);
                    }
                  }}
                  value={confirmPassword}
                />
              </Grid>
              <Grid item marginTop={3} md={12} xs={11.4}>
                <button
                  disabled={isdisabled || LoadingButton}
                  style={{
                    backgroundColor: isdisabled ? "#bcbcbc" : "#1173cf",
                  }}
                  className={classes["button-save"]}
                  onMouseOver={(e) => {
                    e.target.style.backgroundColor = "#101650";
                    e.target.style.cursor = "pointer";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#1173cf";
                  }}
                  onClick={(e) => {
                    passwordChange(e);
                  }}
                >
                  {LoadingButton ? "Saving..." : "Save"}
                </button>
              </Grid>
              {passwordChanged ? (
                <Grid item  paddingTop={1} marginLeft={20}>
                  <span className={classes["Fields-Titles"]}>
                    Password Changed
                  </span>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default ChangePassword;
