import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import classes from "./TeamMemberModal.module.css";
import * as teamMemberServices from "../../../Services/team-member-services.proxy";
import Barcode from "react-barcode";

import TeamMemberLogo from "../../../Assets/Images/Table/TeamMemberProfile.png";
const getTMbyId = teamMemberServices.getTMbyId;

const TeamMemberDetails = (props) => {
  const [teamMemberDetails, setTeamMemberDetails] = useState({});

  useEffect(() => {
    if (props.rowData) {
      getTMbyId(props.rowData.row.Id).then((x) => {
        setTeamMemberDetails(x);
      });
    }
  }, []);
  const renderFields = (FieldName, FieldData) => {
    return (
      <Grid item container gap={2} md={3.5}>
        <Grid md={12} item className={classes["FieldHeader"]}>
          {FieldName}
        </Grid>
        <Grid md={12} item className={classes["FieldData"]}>
          {FieldData}
        </Grid>
      </Grid>
    );
  };
  return (
    <Grid container paddingTop="2%" marginLeft="7%" paddingBottom={25}>
      <Grid item xs={12} container alignItems="center" paddingBottom="3%">
        <Grid
          item
          xs={1.2}
          sx={{
            fontFamily: "Muli",
            fontSize: 18,
            fontWeight: "bold",
            lineHeight: 1.44,
            textAlign: "left",
            color: "#101650",
          }}
        >
          Team Members
        </Grid>
        <Grid
          item
          xs={8.4}
          sx={{
            border: "solid 0.1px #fff",
            backgroundColor: "#fff",
            height: 0,
          }}
        ></Grid>
        <Grid item xs={1} paddingLeft="1.5%">
          <button
            className={classes["GoBack"]}
            onClick={() => {
              props.setViewDetails(false);
            }}
          >
            Go back
          </button>
        </Grid>
      </Grid>
      <Grid item xs={10} container>
        <Grid
          item
          sx={{
            fontFamily: "Muli",
            fontSize: 21,
            fontWeight: "bold",
            lineHeight: 1.48,
            textAlign: "left",
            color: "#101650",
            backgroundColor: "#fff",
            padding: { sm: "4%", lg: "2%" },
          }}
        >
          Team Members
        </Grid>
      </Grid>
      <Grid
        container
        paddingLeft={4}
        paddingTop={5}
        gap={3}
        item
        xs={10.4}
        bgcolor="white"
        paddingBottom={5}
      >
        <Grid container gap={2} md={3.5}>
          <Grid item md={12} xs={12} className={classes["FieldHeader"]}>
            Name
          </Grid>
          <Grid item md={12} xs={12} className={classes["FieldData"]}>
            {teamMemberDetails.Name}
          </Grid>
        </Grid>
        <Grid container gap={2} md={3.5}>
          <Grid md={12} item className={classes["FieldHeader"]}>
            Profile
          </Grid>
          <Grid md={12} item>
            <img
              src={
                teamMemberDetails.ProfilePicture
                  ? teamMemberDetails.ProfilePicture.URL
                  : TeamMemberLogo
              }
              style={{ width: 25 }}
              alt={"ptofile"}
            ></img>
          </Grid>
        </Grid>

        <Grid container gap={5}>
          {renderFields("Email", teamMemberDetails.Email)}
          {renderFields("Country", teamMemberDetails.Country)}
          {renderFields("Address", teamMemberDetails.Address)}
          {renderFields("Phone Number", teamMemberDetails.PhoneNumber)}

          {renderFields("Company Name", teamMemberDetails.Company)}

          {renderFields("Role", teamMemberDetails.UserGroupPermissions)}

          {renderFields("Joining Date", teamMemberDetails.CreationTime)}
          <Grid item container gap={2} md={3.5}>
            <Grid md={12} item className={classes["FieldHeader"]}>
              Barcode
            </Grid>
            <Grid md={12} item className={classes["FieldData"]}>
            <Barcode
                    value=  {teamMemberDetails.BarCode}
                    height={40}
                    format={"CODE128"}
                    font="Avenir Next"
                    fontOptions="600"
                    textMargin={4}
                    margin={0}
                  />
            
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={11.5} container direction={"row-reverse"} marginTop={2}>
          <button
            onClick={() => {
              props.setViewDetails(false);
            }}
            className={classes["GoBack"]}
          >
            Go Back
          </button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TeamMemberDetails;
