import {
  Grid,
  TableCell,
  TableContainer,
  Table,
  TableRow,
  TableBody,
} from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import SaasBack from "../../../../../Assets/Images/SaaSbackground.png";
import classes from "../../../../../Assets/Styles/factory.module.css";
import RemoveIcon from "../../../../../Assets/Images/SalesOrder/RemoveIcon.png";
import TransferModal from "./TransferModal";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import * as storageBlockServices from "../../../../../Services/storage-block-services.proxy";
import { useSelector } from "react-redux";
import * as permissionFunctions from "../../../../../../ReusableFunctions";

const CheckIfPermitted = permissionFunctions.CheckIfPermitted;
const getZonesStock = storageBlockServices.getZonesStock;
const transferBundles = storageBlockServices.transferBundles;
const deleteBundle = storageBlockServices.deleteBundle;

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 15,
  },
}))(TableRow);
export default function ManageRM(props) {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const ZONE_ID = query?.get("id");
  const CATEGORY_ID = query?.get("categoryId");
  const bayId = query?.get("bayId");
  const factoryId = query?.get("factoryId");


  const [loadingData, setLoadingData] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bundleToTransfer, setBundleToTransfer] = useState([]);
  const [materialReceipts, setMaterialReceipts] = useState([]);
  const [remenantBunldes, setRemenantBunldes] = useState([]);

  const [storageBlocks, setStorageBlocks] = useState([]);
  const [storageId, setStorageId] = useState(0);
  const [zoneId, setZoneId] = useState(0);
  const [bundleId, setBundleId] = useState(0);
  const [modify, setModify] = useState(false);
  const [zoneCode, setZoneCode] = useState("Zone Code");
  const [blockCode, setBlockCode] = useState("Block Code");

  const [quantityToTransfer, setQuantityToTransfer] = useState(0);
  const [transfer, setTransfer] = useState(false);

  useEffect(() => {
    if (ZONE_ID && CATEGORY_ID) {
      setLoadingData(true);
      getZonesStock(ZONE_ID, CATEGORY_ID).then((x) => {
        setStorageBlocks(x.Storages);
        setLoadingData(false);
        if (x.MaterialReceipts) setMaterialReceipts(x.MaterialReceipts);
        if (x.Bundles) setRemenantBunldes(x.Bundles);
        setZoneCode(x.ZoneCode);
        setBlockCode(x.BlockCode);
      });
    }
  }, [ZONE_ID, CATEGORY_ID, modify]);
  const TransferObject = {
    BundleIds: bundleToTransfer,
    ToZoneId: zoneId,
  };
  const TransferTheBundles = () => {
    transferBundles(TransferObject).then((x) => {
      setModify(!modify);
      setQuantityToTransfer(0);
    });
  };

  const DelteTheBundle = () => {
    deleteBundle(bundleId).then((x) => {
      setModify(!modify);
    });
  };
  const [disabledTransfer, setDisabledTransfer] = useState(false);
  const [disabledStockCount, setDisabledStockCount] = useState(false);

  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  useEffect(() => {
    if (UserPermissions) {
      CheckIfPermitted(
        UserPermissions,
        "Storage Block",
        "Transfer bundle",
        setDisabledTransfer
      );
      CheckIfPermitted(
        UserPermissions,
        "Storage Block",
        "View stock count",
        setDisabledStockCount
      );
    }
  }, [UserPermissions]);
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
        objectFit: "cover",
        paddingBottom: 60,
      }}
    >
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "transparent",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
          flexDirection: "column",
        }}
        open={loadingData}
      >
        <CircularProgress />

        <Grid
          item
          fontFamily={"Muli"}
          fontSize={15}
          fontWeight={"bold"}
          color={"rgb(25, 118, 210)"}
        >
          Loading Data...
        </Grid>
      </Backdrop>
      <Grid item container paddingTop={3} rowGap={2}>
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          gap={1}
          alignItems="center"
        >
          <Grid
            item
            container
            direction="column"
            lg={1.5}
            sm={2.5}
            xs={3.7}
            fontSize={{ xs: "10px", sm: "16px" }}
            fontWeight="bold"
            className={classes["Titlestock"]}
            paddingTop={{ xs: 1.5, sm: 3 }}
          >
            <Grid item>{blockCode}</Grid>
            <Grid item>{zoneCode + ": Stock Management"}</Grid>
          </Grid>
          <Grid
            item
            xs={2}
            sm={5}
            lg={7}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>
          <Grid
            item
            xs={2}
            sm={2.5}
            lg={1}
            className={classes["ViewText"]}
            onClick={() => {
              // navigate("/saas/blocksList?factoryId=&bayId");
              navigate(
                `/saas/blocksList?BayId=${bayId}&FactoryId=${factoryId}&blockType=1`
              );

            }}
          >
            View All Blocks
          </Grid>
          <Grid item>
            <button
              className={classes["back-button"]}
              onClick={() => {
                navigate(-1);
              }}
            >
              Go Back
            </button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction={"row-reverse"}
        xs={11.5}
        md={10}
        gap={2}
        paddingTop={{ xs: 2, sm: 1 }}
      >
        <button
          className={classes["TransferButton"]}
          disabled={
            bundleToTransfer.length > 0 && !disabledTransfer ? false : true
          }
          onClick={() => {
            setOpen(true);
            setTransfer(true);
          }}
        >
          Transfer multiple bundles
        </button>
        <button
          disabled={disabledStockCount}
          className={classes["StockCount"]}
          onClick={() => {
            navigate(
              `/saas/factory/stockCount?zoneId=${ZONE_ID}&categoryId=${CATEGORY_ID}`
            );
          }}
        >
          Stock Count
        </button>
      </Grid>

      <Grid
        item
        overflow={"auto"}
        paddingTop={2}
        paddingLeft={{ xs: "2.5%", md: "5.5%" }}
        xs={11.5}
        md={12}
        className={classes["scrollTable"]}
      >
        <TableContainer sx={{ width: 1200 }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              backgroundColor: "#fff",
              borderCollapse: "separate",
              borderSpacing: "0px 0px",
              border: 0,
            }}
          >
            <TableBody>
              {CATEGORY_ID === "1"
                ? materialReceipts?.map((mr) => (
                  <>
                    <StyledTableRow key={mr.Id}>
                      <TableCell sx={styles.tableCellRmCode}>
                        <Grid item paddingLeft={0.5} paddingTop={0.5}>
                          {mr.Code}
                        </Grid>
                      </TableCell>
                      <TableCell sx={styles.tableCellRmCode}></TableCell>
                      <TableCell sx={styles.tableCellRmCode}></TableCell>
                      <TableCell sx={styles.tableCellRmCode}></TableCell>
                      <TableCell sx={styles.tableCellRmCode}></TableCell>
                      <TableCell sx={styles.tableCellRmCode}></TableCell>
                      <TableCell sx={styles.tableCellRmCode}></TableCell>
                      <TableCell sx={styles.tableCellRmCode}></TableCell>
                      <TableCell sx={styles.tableCellRmCode}></TableCell>
                    </StyledTableRow>

                    {mr?.Items?.map((Item, index) => (
                      <>
                        <StyledTableRow key={Item.Id}>
                          <TableCell sx={styles.tableCellRMItem}>
                            <Grid
                              item
                              paddingLeft={2}
                              paddingTop={0.5}
                              paddingBottom={0.5}
                            >
                              {"Item" + Item.Name}
                            </Grid>
                          </TableCell>
                          <TableCell sx={styles.tableCellRMItem}></TableCell>
                          <TableCell sx={styles.tableCellRMItem}></TableCell>

                          <TableCell sx={styles.tableCellempty}></TableCell>
                          <TableCell sx={styles.tableCellempty}></TableCell>
                          <TableCell sx={styles.tableCellempty}></TableCell>
                          <TableCell sx={styles.tableCellempty}></TableCell>
                          <TableCell sx={styles.tableCellempty}></TableCell>
                          <TableCell sx={styles.tableCellempty}></TableCell>
                        </StyledTableRow>
                        {Item?.Bundles?.map((bundle) => (
                          <StyledTableRow key={bundle.BundleId}>
                            <TableCell sx={styles.tableCellBundleCircle}>
                              <Grid
                                marginLeft={2}
                                item
                                className={
                                  bundleToTransfer.find(
                                    (x) => x === bundle.BundleId
                                  )
                                    ? classes["selectCircleSelected"]
                                    : classes["selectCircle"]
                                }
                                onClick={() => {
                                  if (
                                    bundleToTransfer.find(
                                      (x) => x === bundle.BundleId
                                    )
                                  ) {
                                    setBundleToTransfer(
                                      bundleToTransfer.filter(
                                        (x) => x !== bundle.BundleId
                                      )
                                    );
                                    setQuantityToTransfer(
                                      quantityToTransfer - +bundle.Weight
                                    );
                                  } else {
                                    setBundleToTransfer(
                                      bundleToTransfer.concat(bundle.BundleId)
                                    );
                                    setQuantityToTransfer(
                                      quantityToTransfer + +bundle.Weight
                                    );
                                  }
                                }}
                              ></Grid>
                            </TableCell>
                            <TableCell sx={styles.tableCellBundle}>
                              <Grid container direction={"column"}>
                                <Grid item className={classes["inputTitle"]}>
                                  Bundle Code
                                </Grid>
                                <Grid item className={classes["InputBorder"]}>
                                  {bundle.Code}
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell sx={styles.tableCellBundle}>
                              <Grid container direction={"column"}>
                                <Grid item className={classes["inputTitle"]}>
                                  Bundle Name
                                </Grid>
                                <Grid item className={classes["InputBorder"]}>
                                  {bundle.Name}
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell sx={styles.tableCellBundle}>
                              <Grid container direction={"column"}>
                                <Grid item className={classes["inputTitle"]}>
                                  Heat No.
                                </Grid>
                                <Grid item className={classes["InputBorder"]}>
                                  {bundle.HeatNumber}
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell sx={styles.tableCellBundle}>
                              <Grid container direction={"column"}>
                                <Grid item className={classes["inputTitle"]}>
                                  Bundle Weight (Kg)
                                </Grid>
                                <Grid item className={classes["InputBorder"]}>
                                  {bundle.Weight}
                                </Grid>
                              </Grid>
                            </TableCell>

                            <TableCell sx={styles.tableCellBundle}>
                              <Grid container direction={"column"}>
                                <Grid item className={classes["inputTitle"]}>
                                  Qty of Pcs
                                </Grid>
                                <Grid item className={classes["InputBorder"]}>
                                  {bundle.Quantity}
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell sx={styles.tableCellBundle}>
                              <Grid container direction={"column"}>
                                <Grid item className={classes["inputTitle"]}>
                                  Mtc Doc Name
                                </Grid>
                                <Grid item className={classes["InputBorder"]}>
                                  {bundle.MTC}
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell sx={styles.tableCellBundleButton}>
                              <button
                                disabled={disabledTransfer}
                                className={classes["TransferOneButton"]}
                                onClick={() => {
                                  let bundles = [];
                                  bundles = bundles.concat(bundle.BundleId);
                                  setBundleToTransfer(bundles);
                                  setQuantityToTransfer(+bundle.Weight);
                                  setOpen(true);
                                  setTransfer(true);
                                }}
                              >
                                Transfer
                              </button>
                            </TableCell>
                            <TableCell sx={styles.tableCellBundleCircle}>
                              <Grid
                                item
                                paddingLeft={3}
                                onMouseOver={(e) => {
                                  e.target.style.cursor = "pointer";
                                }}
                                onClick={() => {
                                  setOpen(true);
                                  setTransfer(false);
                                  setBundleId(bundle.BundleId);
                                }}
                              >
                                <img src={RemoveIcon} alt={"img"} />
                              </Grid>
                            </TableCell>
                          </StyledTableRow>
                        ))}
                      </>
                    ))}
                  </>
                ))
                : remenantBunldes?.map((bundle) => (
                  <>
                    <StyledTableRow key={bundle.BundleId}>
                      <TableCell sx={styles.tableCellBundleCircle}>
                        <Grid
                          marginLeft={2}
                          item
                          className={
                            bundleToTransfer.find(
                              (x) => x === bundle.BundleId
                            )
                              ? classes["selectCircleSelected"]
                              : classes["selectCircle"]
                          }
                          onClick={() => {
                            if (
                              bundleToTransfer.find(
                                (x) => x === bundle.BundleId
                              )
                            ) {
                              setBundleToTransfer(
                                bundleToTransfer.filter(
                                  (x) => x !== bundle.BundleId
                                )
                              );
                              setQuantityToTransfer(
                                quantityToTransfer - +bundle.Weight
                              );
                            } else {
                              setBundleToTransfer(
                                bundleToTransfer.concat(bundle.BundleId)
                              );
                              setQuantityToTransfer(
                                quantityToTransfer + +bundle.Weight
                              );
                            }
                          }}
                        ></Grid>
                      </TableCell>
                      <TableCell sx={styles.tableCellBundle}>
                        <Grid container direction={"column"}>
                          <Grid item className={classes["inputTitle"]}>
                            Bundle Code
                          </Grid>
                          <Grid item className={classes["InputBorder"]}>
                            {bundle.Code}
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell sx={styles.tableCellBundle}>
                        <Grid container direction={"column"}>
                          <Grid item className={classes["inputTitle"]}>
                            Bundle Name
                          </Grid>
                          <Grid item className={classes["InputBorder"]}>
                            {bundle.Name}
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell sx={styles.tableCellBundle}>
                        <Grid container direction={"column"}>
                          <Grid item className={classes["inputTitle"]}>
                            Heat No.
                          </Grid>
                          <Grid item className={classes["InputBorder"]}>
                            {bundle.HeatNumber}
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell sx={styles.tableCellBundle}>
                        <Grid container direction={"column"}>
                          <Grid item className={classes["inputTitle"]}>
                            Bundle Weight (Kg)
                          </Grid>
                          <Grid item className={classes["InputBorder"]}>
                            {bundle.Weight}
                          </Grid>
                        </Grid>
                      </TableCell>

                      <TableCell sx={styles.tableCellBundle}>
                        <Grid container direction={"column"}>
                          <Grid item className={classes["inputTitle"]}>
                            Qty of Pcs
                          </Grid>
                          <Grid item className={classes["InputBorder"]}>
                            {bundle.Quantity}
                          </Grid>
                        </Grid>
                      </TableCell>

                      <TableCell sx={styles.tableCellBundleButton}>
                        <button
                          className={classes["TransferOneButton"]}
                          onClick={() => {
                            let bundles = [];
                            bundles = bundles.concat(bundle.BundleId);
                            setBundleToTransfer(bundles);
                            setQuantityToTransfer(+bundle.Weight);
                            setOpen(true);
                            setTransfer(true);
                          }}
                        >
                          Transfer
                        </button>
                      </TableCell>
                      <TableCell sx={styles.tableCellBundleCircle}>
                        <Grid
                          item
                          paddingLeft={3}
                          onMouseOver={(e) => {
                            e.target.style.cursor = "pointer";
                          }}
                          onClick={() => {
                            setOpen(true);
                            setTransfer(false);
                            setBundleId(bundle.BundleId);
                          }}
                        >
                          <img src={RemoveIcon} alt={"img"} />
                        </Grid>
                      </TableCell>
                    </StyledTableRow>
                  </>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TransferModal
          openModal={open}
          setOpenModal={setOpen}
          storageBlocks={storageBlocks}
          setStorageBlocks={setStorageBlocks}
          storageId={storageId}
          setStorageId={setStorageId}
          zoneId={zoneId}
          setZoneId={setZoneId}
          quantityToTransfer={quantityToTransfer}
          loading={loading}
          setLoading={setLoading}
          transfer={transfer}
          setTransfer={setTransfer}
          TransferTheBundles={TransferTheBundles}
          ZONE_ID={ZONE_ID}
          DelteTheBundle={DelteTheBundle}
        />
      </Grid>
    </div>
  );
}
const styles = {
  tableCellRmCode: {
    backgroundColor: "rgba(225, 227, 25, 0.7)",
    fontFamily: "Muli",
    fontSize: 9,
    color: "#101650",
    lineHeight: 1.17,
    textAlign: "left",
    border: 0,
    padding: 0,
  },
  tableCellRMItem: {
    backgroundColor: "rgba(225, 227, 25, 0.3)",
    fontFamily: "Muli",
    fontSize: 9,
    color: "#101650",
    lineHeight: 1.17,
    textAlign: "left",
    border: 0,
    padding: 0,
  },
  tableCellempty: {
    backgroundColor: "#fff",
    border: 0,
    height: 15,
    padding: 0,
  },

  tableCellBundleCircle: {
    width: 60,
    backgroundColor: "#fff",
    borderBottom: "solid 0.5px #e1e319",
    padding: 0,
  },
  tableCellBundleButton: {
    width: 100,
    backgroundColor: "#fff",
    borderBottom: "solid 0.5px #e1e319",
    padding: 0,
  },
  tableCellBundle: {
    backgroundColor: "#fff",
    borderBottom: "solid 0.5px #e1e319",
  },
  tablebig: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    fontWeight: "bold",
    color: "#707070",
    lineHeight: 1.17,
    textAlign: "center",
    borderRight: "solid 1px #e9e7e7",
    width: 111,
  },
  tableCellDisable: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    fontWeight: "bold",
    color: "#2da4d0",
    lineHeight: 1.17,
    textAlign: "center",
    borderRight: "solid 1px #e9e7e7",
  },
  tfInput: {
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#707070",
    lineHeight: 1.45,
    paddingLeft: 2,
    height: 5,
  },
};
