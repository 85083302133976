import React, { useState, useEffect } from "react";
import { styled, Box } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import classes from "./Manu.module.css";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import checkSub from "../../../SAAS/Assets/Images/Settings/checkSub.jpg";
import ManuFreeIcon from "../../../Assets/Images/Pricing/ManuFreeIcon.png";
import * as data from "../../../Services/pricing-data.proxy.js";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
const activateFreePlan = data.activateFreePlan;
const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  bgcolor: "#transparent",
  border: "0",
  width: "45%",
  height: "60%",
  outline: "none",
};

export default function FreePlanModal(props) {
  const [open, setOpen] = useState(false);
  const [loading,setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const navigate = useNavigate();
  const FreeManuFeatures = [
    "BBS creation",
    "Shape library access",
    "Tag design",
  ];
  const handleClose = () => {
    setOpen(false);
    props.setOpen(false);
  };
  useEffect(() => {
    if (props.open) {
      handleOpen(true);
    }
  }, [props.open]);
  
  return (
    <div>
      <StyledModal
        disableScrollLock
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
      >
        <Box sx={style}>
          <Grid container direction={"row-reverse"}>
            <IconButton
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onClick={() => {
                handleClose();
              }}
            >
              <CloseIcon htmlColor="#fff" />
            </IconButton>
          </Grid>
          <Grid bgcolor={"#fff"} container direction={"column"} paddingTop={2}>
            <Grid item container justifyContent={"center"}>
              <div className={classes["Modal-title"]}>
                Subscription to Free Plan
              </div>
            </Grid>
            <Grid
              item
              width={"100%"}
              bgcolor={"#e9e7e7"}
              height={1}
              marginTop={1}
            >
              <div style={{ width: "100%", height: 1 }}></div>
            </Grid>
            <Grid
              item
              container
              justifyContent={"center"}
              alignItems={"center"}
              paddingTop={3}
              paddingBottom={3}
            >
              <img src={ManuFreeIcon} alt={"attention"} />
            </Grid>

            <Grid
              item
              container
              justifyContent={"center"}
              alignItems={"center"}
              className={classes.message}
            >
              <Grid item md={12} fontSize={16}>
                Enjoy your free subscription
              </Grid>
              {FreeManuFeatures.map((option, index) => (
                <Grid
                  item
                  className={classes["plan-description2"]}
                  key={index}
                  md={12}
                  container
                  paddingLeft={"35%"}
                  alignItems={"center"}
                >
                  <img
                    src={checkSub}
                    style={{ marginRight: 10.3 }}
                    alt={"check"}
                  />

                  {option}
                </Grid>
              ))}
            </Grid>

            <Grid
              item
              marginTop={3.1}
              xs={10}
              container
              direction={"row-reverse"}
              bgcolor={"#e9e7e7"}
              padding={2}
            >
              <Grid item marginLeft={2}>
                <button
                disabled={loading}
                  className={classes.SaveModal}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={() => {
                    setLoading(true);
                    activateFreePlan().then((x) => {
                      localStorage.setItem("IsFreePlanActivated", "true");
                      setLoading(false);
                      navigate("/login");
                    });
                  }}
                >
                 {loading? "Loading ...":"Continue to Pilecubes SaaS"} 
                </button>
              </Grid>
              <Grid item>
                <button
                  className={classes.exitModal}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cancel
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </StyledModal>
    </div>
  );
}
