import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import LoginDesign from "../Components/Login/LoginDesign";
import ResetLink from "../Components/Login/ResetPassword/ResetLink";
import ResetPassword from "../Components/Login/ResetPassword/ResetPassword";
import ResetForm from "../Components/Login/ResetPassword/ResetForm";
function Login({
  setRegister,
  setScrolled,
  setLoading,
  setToken,
  isVerified,
  setIsVerified,
  isSubscribed,
  setIsSubscribed,
  token,
  account,
  setAccount,
  setAccountName,
  setShowSaaSNavbar,
}) {
  const [step, setStep] = useState(1);
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const inviterTenantId = query.get("inviterTenantId");
  const projectId = query.get("projectId");
  const subProjectId = query.get("subProjectId");
  const RedirectURL = query.get("RedirectURL");
  const navigate = useNavigate();
  const [routing, setRouting] = useState(false);

  useEffect(() => {
    let apiTest = true;
    if (localStorage.getItem("accessToken") && apiTest) {
      if (localStorage.getItem("isVerified") === "false") {
        if (localStorage.getItem("isTeamMember") === "true")
          navigate("/teamMemberRegistration");
        else {
          if (localStorage.getItem("AccountType") === "1") navigate("/main");
          if (localStorage.getItem("AccountType") === "2") navigate("/sub");
        }
      } else {
        if (RedirectURL && routing) navigate("/saas/invitations");
        else if (
          localStorage.getItem("IsScheduled") === "true" &&
          localStorage.getItem("IsScheduledManu") === "true"
        )
          navigate("/scheduled");
        else if (
          localStorage.getItem("isSubscribed") === "true" ||
          localStorage.getItem("isSubscribedManu") === "true" ||
          localStorage.getItem("IsSubscribedBeforeManu") === "true" ||
          localStorage.getItem("isTeamMember") === "true" ||
          localStorage.getItem("IsFreePlanActivated") === "true" ||
          localStorage.getItem("isGuest") === "true" ||
          localStorage.getItem("IsSubscribedBefore") === "true"
        )
          navigate("/saas/admin");
        else navigate("/pricing");
      }
      setShowSaaSNavbar(true);
    }
    return () => {
      apiTest = false;
    };
  }, [navigate, routing]);

  useEffect(() => {
    setRegister(true);
  }, [setRegister]);

  const LoginSteps = ({ step }) => {
    switch (step) {
      case 1:
        return (
          <LoginDesign
            setShowSaaSNavbar={setShowSaaSNavbar}
            setRouting={setRouting}
            routing={routing}
            continues={continues}
            setScrolled={setScrolled}
            setLoading={setLoading}
            setToken={setToken}
            isVerified={isVerified}
            setIsVerified={setIsVerified}
            isSubscribed={isSubscribed}
            setIsSubscribed={setIsSubscribed}
            token={token}
            setAccount={setAccount}
            account={account}
            setAccountName={setAccountName}
            inviterTenantId={inviterTenantId}
            projectId={projectId}
            subProjectId={subProjectId}
            RedirectURL={RedirectURL}
          />
        );
      case 2:
        return <ResetPassword continues={continues} setLoading={setLoading} />;
      case 3:
        return <ResetLink continues={continues} setLoading={setLoading} />;

      default:
        return "nothing";
    }
  };
  const continues = (e, stepNumber) => {
    e.preventDefault();
    setStep(stepNumber);
  };
  return <LoginSteps step={step} />;
}

export default Login;
