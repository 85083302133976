import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import classing from "../../../Assets/Styles/factory.module.css";
import { styled } from "@mui/system";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import { useNavigate } from "react-router-dom";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import PlusIcon from "../../../Assets/Images/Factory/plusIcon.png";
import MachineIcon from "../../../Assets/Images/Factory/mbhighres.png";
import HandlingIcon from "../../../Assets/Images/Factory/hdhighres.png";
import StorageIcon from "../../../Assets/Images/Factory/sbhighres.png";
import * as factoryConsts from "../../../../FactoryConsts";

const FactoryControlSystem = (props) => {
  const navigate = useNavigate();
  const [dockSelected, setDockSelected] = useState(null);

  const [storageBlockSelected, setStorageBlockSelected] = useState(null);
  const [handlingBlockSelected, setHandlingBlockSelected] = useState(null);
  const [machineBlockSelected, setMachineBlockSelected] = useState(null);
  const [baySelectedId, setBaySelectedId] = useState(0);
  const [isdisabled, setIsDisabled] = useState(false);
  // useEffect(() => {
  //   let handlingCount = 0;
  //   let storageCount = 0;
  //   let machineCount = 0;

  //   props.factoryData?.Bays?.map((bay) => {
  //     bay.Handlings.map((bh) => {
  //       handlingCount++;
  //     });
  //     bay.Machines.map((bh) => {
  //       machineCount++;
  //     });
  //     bay.Storages.map((bh) => {
  //       storageCount++;
  //     });
  //   });
  //   console.log(handlingCount + storageCount + machineCount);
  //   console.log(
  //     props.modelData?.Blocks?.filter(
  //       (x) => x.TypeId === factoryConsts.MACHINE_BLOCK_ID
  //     )
  //   );

  //   if (
  //     props.modelData?.Blocks?.filter(
  //       (x) => x.TypeId === factoryConsts.MACHINE_BLOCK_ID
  //     ).length !==
  //     handlingCount + storageCount + machineCount
  //   ) {
  //     setIsDisabled(true);
  //   } else {
  //     setIsDisabled(false);
  //   }
  // });
  return (
    <Grid paddingTop={1} paddingLeft={0.8}>
      <Grid item xs={12} className={classing["Factory-Name"]}>
        {props.factoryData?.Name}
      </Grid>
      <Grid item xs={12} className={classing["CodeStyle"]}>
        {props.factoryData?.Code}
      </Grid>
      <Grid item paddingTop={2} className={classing["hint-system"]}>
        Add bays and their corresponding blocks to create the factory model
        view.
      </Grid>
      <Grid item paddingTop={2} xs={11.5} className={classing["hint-system"]}>
        After choosing each bay you can add its allocated blocks and arrange
        them accordingly.
      </Grid>
      <Grid
        item
        marginTop={3}
        className={classing["Path-4116"]}
        marginLeft={1}
      ></Grid>
      <Grid
        item
        container
        direction={"row-reverse"}
        xs={12}
        gap={0.1}
        paddingRight={2}
      >
        <Grid
          paddingLeft={1}
          paddingTop={0.5}
          item
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
          }}
          onClick={() => {
            if (!props.modelData.filter((x) => x.Id === baySelectedId)[0]) {
              props.setModelData(
                props.modelData.concat({
                  Id: props.baySelected.Id,
                  Name: `Bay# ${
                    props.factoryData?.Bays?.findIndex((object) => {
                      return object.Id === baySelectedId;
                    }) + 1
                  }`,
                  Width: 144,
                  Height: 393,
                  x: 0,
                  y: 0,
                  Blocks: [],
                  Docks: [],
                })
              );
            }
          }}
        >
          <img src={PlusIcon} width={8.6} alt={"plus"} />
        </Grid>
        <Grid item paddingTop={0.5} marginLeft={0.5}>
          <CustomSelect2
            disabled={props.disabledEdit || props.disabledCreate}
            style={{ color: "#707070", width: 75 }}
            value={baySelectedId}
            onChange={(option) => {
              setBaySelectedId(option);
              props.setBaySelected(
                props.factoryData?.Bays.filter((x) => x.Id === option)[0]
              );
            }}
          >
            <StyledOption2 value={0} disabled>
              Number
            </StyledOption2>
            {props.factoryData?.Bays?.map((s, index) => (
              <StyledOption2 key={s.Id} value={s.Id}>
                Bay # {index + 1}
              </StyledOption2>
            ))}
          </CustomSelect2>
        </Grid>
        <Grid
          item
          className={classing["menu-st"]}
          paddingLeft={0.5}
          paddingTop={1.5}
        >
          Bays
        </Grid>
      </Grid>
      <Grid
        item
        marginTop={3}
        className={classing["Path-4116"]}
        marginLeft={1}
        bgcolor={"#e9ebff"}
      ></Grid>
      <Grid
        item
        container
        direction={"row-reverse"}
        xs={12}
        gap={0.1}
        paddingRight={2}
      >
        <Grid
          paddingLeft={1}
          paddingTop={0.5}
          item
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
          }}
          onClick={() => {
            if (dockSelected) {
              let bay = props.modelData.filter(
                (x) => x.Id === baySelectedId
              )[0];

              if (!bay.Docks?.filter((x) => x.Id === dockSelected)[0])
                props.modelData
                  .filter((x) => x.Id === baySelectedId)[0]
                  .Docks.push({
                    Id: dockSelected,
                    Code: props.baySelected.Docks.filter(
                      (x) => x.Id === dockSelected
                    )[0]?.Code,
                    Width: 70,
                    Height: 197,
                    x: 0,
                    y: 0,
                  });
              props.forceUpdate();
            }
          }}
        >
          <img src={PlusIcon} width={8.6} alt={"plus"} />
        </Grid>
        <Grid item paddingTop={0.5} marginLeft={0.5}>
          <CustomSelect2
            disabled={props.disabledEdit || props.disabledCreate}
            style={{ color: "#707070", width: 75 }}
            value={dockSelected}
            onChange={(option) => {
              setDockSelected(option);
            }}
          >
            <StyledOption2 value={0} disabled>
              Number
            </StyledOption2>
            {props.baySelected?.Docks?.map((s, index) => (
              <StyledOption2 key={s.Id} value={s.Id}>
                Dock # {index + 1}
              </StyledOption2>
            ))}
          </CustomSelect2>
        </Grid>
        <Grid
          item
          className={classing["menu-st"]}
          paddingLeft={0.5}
          paddingTop={1.5}
        >
          Docks
        </Grid>
      </Grid>

      <Grid item container gap={3} paddingTop={4}>
        <Grid item container gap={1} direction={"row"}>
          <Grid item>
            <img src={StorageIcon} width={"48px"} alt={"machine"} />
          </Grid>

          <Grid item paddingTop={2.2}>
            <Grid item className={classing["BlockTitle"]}>
              Storage
            </Grid>
            <Grid item container className={classing["BlockTitle2"]}>
              Block
              <Grid item marginLeft={0.5}>
                <CustomSelect2
                  disabled={props.disabledEdit || props.disabledCreate}
                  style={{ color: "#707070" }}
                  value={storageBlockSelected}
                  onChange={(option) => {
                    setStorageBlockSelected(option);
                  }}
                >
                  <StyledOption2 value={0} disabled>
                    code/type
                  </StyledOption2>
                  {props.baySelected?.Storages?.map((s) => (
                    <StyledOption2
                      key={s.Id}
                      value={s.Id}
                      disabled={
                        props.modelData
                          .flatMap((x) => x.Blocks)
                          .filter((b) => b.Id === s.Id)[0]
                          ? true
                          : false
                      }
                    >
                      {s.Code + "/" + s.Category}
                    </StyledOption2>
                  ))}
                </CustomSelect2>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            paddingTop={3.2}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            onClick={() => {
              if (storageBlockSelected) {
                let bay = props.modelData.filter(
                  (x) => x.Id === baySelectedId
                )[0];

                if (
                  !bay.Blocks?.filter((x) => x.Id === storageBlockSelected)[0]
                )
                  props.modelData
                    .filter((x) => x.Id === baySelectedId)[0]
                    .Blocks.push({
                      Id: storageBlockSelected,
                      Code: props.baySelected.Storages.filter(
                        (x) => x.Id === storageBlockSelected
                      )[0]?.Code,
                      Width: 45,
                      Height: 45,
                      x: 50,
                      y: 50,
                      StatusId: props.baySelected.Storages.filter(
                        (x) => x.Id === storageBlockSelected
                      )[0]?.StatusId,
                      TypeId: props.baySelected.Storages.filter(
                        (x) => x.Id === storageBlockSelected
                      )[0]?.TypeId,
                      Category: props.baySelected.Storages.filter(
                        (x) => x.Id === storageBlockSelected
                      )[0]?.Category,
                    });
                props.forceUpdate();
              }
            }}
          >
            <img src={PlusIcon} width={8.6} />
          </Grid>
        </Grid>
        <Grid item container gap={1} direction={"row"}>
          <Grid item>
            <img src={MachineIcon} width={"48px"} alt={"machine"} />
          </Grid>

          <Grid item paddingTop={2.2}>
            <Grid item className={classing["BlockTitle"]}>
              Machine
            </Grid>
            <Grid item container className={classing["BlockTitle2"]}>
              Block
              <Grid item marginLeft={0.5}>
                <CustomSelect2
                  disabled={props.disabledEdit || props.disabledCreate}
                  style={{ color: "#707070" }}
                  value={machineBlockSelected}
                  onChange={(option) => {
                    setMachineBlockSelected(option);
                  }}
                >
                  <StyledOption2 value={0} disabled>
                    code
                  </StyledOption2>
                  {props.baySelected?.Machines?.map((s) => (
                    <StyledOption2
                      key={s.Id}
                      value={s.Id}
                      disabled={
                        props.modelData
                          .flatMap((x) => x.Blocks)
                          .filter((b) => b.Id === s.Id)[0]
                          ? true
                          : false
                      }
                    >
                      {s.Code}
                    </StyledOption2>
                  ))}
                </CustomSelect2>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            paddingTop={3.2}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            onClick={() => {
              if (machineBlockSelected) {
                let bay = props.modelData.filter(
                  (x) => x.Id === baySelectedId
                )[0];

                if (
                  !bay.Blocks?.filter((x) => x.Id === machineBlockSelected)[0]
                )
                  props.modelData
                    .filter((x) => x.Id === baySelectedId)[0]
                    .Blocks.push({
                      Id: machineBlockSelected,
                      Code: props.baySelected.Machines.filter(
                        (x) => x.Id === machineBlockSelected
                      )[0]?.Code,
                      Width: 45,
                      Height: 45,
                      x: 50,
                      y: 50,
                      StatusId: props.baySelected.Storages.filter(
                        (x) => x.Id === storageBlockSelected
                      )[0]?.StatusId,
                      TypeId: props.baySelected.Machines.filter(
                        (x) => x.Id === machineBlockSelected
                      )[0]?.TypeId,
                      Capacity: props.baySelected.Machines.filter(
                        (x) => x.Id === machineBlockSelected
                      )[0]?.Capacity,
                      ProductionRuns: props.baySelected.Machines.filter(
                        (x) => x.Id === machineBlockSelected
                      )[0]?.ProductionRuns,
                    });
                props.forceUpdate();
              }
            }}
          >
            <img src={PlusIcon} width={8.6} />
          </Grid>
        </Grid>
        <Grid item container gap={1} direction={"row"}>
          <Grid item>
            <img src={HandlingIcon} width={"48px"} alt={"machine"} />
          </Grid>

          <Grid item paddingTop={2.2}>
            <Grid item className={classing["BlockTitle"]}>
              Handling
            </Grid>
            <Grid item container className={classing["BlockTitle2"]}>
              Block
              <Grid item marginLeft={0.5}>
                <CustomSelect2
                  disabled={props.disabledEdit || props.disabledCreate}
                  style={{ color: "#707070" }}
                  value={handlingBlockSelected}
                  onChange={(option) => {
                    setHandlingBlockSelected(option);
                  }}
                >
                  <StyledOption2 value={0} disabled>
                    Name
                  </StyledOption2>
                  {props.baySelected?.Handlings?.map((s) => (
                    <StyledOption2
                      key={s.Id}
                      value={s.Id}
                      disabled={
                        props.modelData
                          .flatMap((x) => x.Blocks)
                          .filter((b) => b.Id === s.Id)[0]
                          ? true
                          : false
                      }
                    >
                      {s.Name}
                    </StyledOption2>
                  ))}
                </CustomSelect2>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            paddingTop={3.2}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            onClick={() => {
              if (handlingBlockSelected) {
                let bay = props.modelData.filter(
                  (x) => x.Id === baySelectedId
                )[0];

                if (
                  !bay.Blocks?.filter((x) => x.Id === handlingBlockSelected)[0]
                )
                  props.modelData
                    .filter((x) => x.Id === baySelectedId)[0]
                    .Blocks.push({
                      Id: handlingBlockSelected,
                      Code: props.baySelected.Handlings.filter(
                        (x) => x.Id === handlingBlockSelected
                      )[0]?.Code,
                      Width: 45,
                      Height: 45,
                      x: 50,
                      y: 50,
                      StatusId: props.baySelected.Storages.filter(
                        (x) => x.Id === storageBlockSelected
                      )[0]?.StatusId,
                      TypeId: props.baySelected.Handlings.filter(
                        (x) => x.Id === handlingBlockSelected
                      )[0]?.TypeId,
                      Name: props.baySelected.Handlings.filter(
                        (x) => x.Id === handlingBlockSelected
                      )[0]?.Name,
                    });
                props.forceUpdate();
              }
            }}
          >
            <img src={PlusIcon} width={8.6} />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        direction={"row-reverse"}
        gap={1}
        paddingTop={5}
        paddingRight={1.5}
      >
        <button
          className={classing["SaveBut"]}
          disabled={isdisabled}
          onClick={() => {
            props.saveLayout();
          }}
        >
          Save
        </button>
        <button
          className={classing["cancelbut"]}
          onClick={() => {
            navigate(-1);
          }}
        >
          Cancel
        </button>
      </Grid>
    </Grid>
  );
};

export default FactoryControlSystem;
const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const CustomSelect2 = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton2,
    Listbox: StyledListbox2,
    Popper: StyledPopper2,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});
const StyledButton2 = styled("button")(
  ({ theme }) => `
          font-family: Muli;
          font-size: 12px;
          box-sizing: border-box;
          width: 50px;
          height:20px;
          background: #f1f1f1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          border: 1px solid #f3f3f3;
          border-radius: 5px;
          text-align: left;
          color:#0674b9 !important;
          border: solid 1px #bcbcbc;
          &:hover {
            background: #fff;
            color:#0674b9 !important;
          }
        
          &.${selectUnstyledClasses.focusVisible} {
            outline: 3px solid ${
              theme.palette.mode === "dark" ? blue[600] : blue[100]
            };
          }
        
          &.${selectUnstyledClasses.expanded} {
            &::after {
              content: '▴';
            }
            box-shadow: 0 3px 6px 0 #0674b9;
            background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
            border: solid 1px #0674b9;
            color:#0674b9 !important;
          }
        
          &::after {
            content: '▾';
            float: right;
            padding-left:1px;
            color:#0674b9 !important;
          }
          `
);

const StyledListbox2 = styled("ul")(
  ({ theme }) => `
          font-family: Muli;
          font-size: 10px;
          box-sizing: border-box;
          padding: 5px;
          margin: 10px 0;
          width:150px;
          max-height:250px !important;
          background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
          border: 1px solid ${
            theme.palette.mode === "dark" ? grey[800] : grey[300]
          };
          border-radius: 0.75em;
          color: #444;
          overflow: auto;
          outline: 0px;
          `
);

const StyledOption2 = styled(OptionUnstyled)(
  ({ theme }) => `
          list-style: none;
          padding: 8px;
          border-radius: 0.45em;
          cursor: default;
        
          &:last-of-type {
            border-bottom: none;
          }
        
          &.${optionUnstyledClasses.selected} {
            background-color: ${
              theme.palette.mode === "dark" ? blue[900] : blue[100]
            };
            color: #fff;
          }
        
          &.${optionUnstyledClasses.highlighted} {
            background-color: ${
              theme.palette.mode === "dark" ? grey[800] : grey[100]
            };
            color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
          }
        
          &.${optionUnstyledClasses.highlighted}.${
    optionUnstyledClasses.selected
  } {
            background-color: ${
              theme.palette.mode === "dark" ? blue[900] : blue[100]
            };
            color: #0674b9;
          }
        
          &.${optionUnstyledClasses.disabled} {
            color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
          }
        
          &:hover:not(.${optionUnstyledClasses.disabled}) {
            background-color:#fff;
            color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
          }
          `
);

const StyledPopper2 = styled(PopperUnstyled)`
  z-index: 1;
`;
