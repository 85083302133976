import * as constFile from "../pileCubesConst";
import axios from "axios";
const GET_USER_DATA = constFile.GET_USER_DATA;

export const getUserData = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + GET_USER_DATA}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data });
};
const USER_INVITATION_LIST = constFile.USER_INVITATION_LIST;

export const getUserInvitationList = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + USER_INVITATION_LIST}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {});
};
const USER_ACCEPT_INVITATION = constFile.USER_ACCEPT_INVITATION;

export const UserAcceptInvitation = (PhaseId, SubProjectId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + USER_ACCEPT_INVITATION}`,
      { PhaseId: PhaseId, SubProjectId: SubProjectId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {});
};
