import React, { useEffect, useState, useMemo } from "react";
import SaasBack from "../../../../Assets/Images/ProfileDetails/SaaSbackground.png";
import { Grid, TextField } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import classing from "../../../../Assets/Styles/projects.module.css";
import classes from "./Dashboard.module.css";
import EditPen from "../../../../Assets/Images/Projects/edit.png";
import ViewEye from "../../../../Assets/Images/Projects/Eye.png";
import * as dashBoardServices from "../../../../Services/dashboard-service.proxy";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";

const ARCHIVED = 2;

const getDashBoardData = dashBoardServices.getDashBoardData;
export default function Dashboard(props) {
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );

  const [disabled, setDisabled] = useState(false);
  const [phaseCRUD, setPhaseCRUD] = useState(false);
  const [subProCRUD, setSubProCRUD] = useState(false);

  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  const [searchText, setSearchText] = useState(null);
  const [openedCard, setopenedCard] = useState([]);

  const navigate = useNavigate();
  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();

    setSearchText(lowerCase);
  };
  const [phases, setPhases] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [projectStatus, setProjectStatus] = useState(1);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const ID = query.get("id");
  useEffect(() => {
    getDashBoardData(ID).then((x) => {
      setProjectName(x.Name);
      setPhases(x.Sections);
      setProjectStatus(x.StatusId);
    });
  }, [ID]);

  const renderSubProject = (subProject, phase, response) => {
    return subProject?.map((item) => (
      <Grid paddingLeft={5} key={item.Id} xs={12} md={12}>
        <Grid xs={12} container item className={classes["Phases-Card"]}>
          <Grid
            container
            direction={"row"}
            alignItems="center"
            padding={1}
            paddingLeft={5}
          >
            <Grid item xs={2} className={classes["Card-Name"]}>
              {item.Name}
            </Grid>
            <Grid
              item
              xs={10}
              container
              direction={"row-reverse"}
              gap={3}
              paddingRight={3}
            >
              <Grid
                item
                className={classes["plus"]}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={(e) => {
                  if (openedCard.includes(item.Id)) {
                    let arrayIDs = openedCard;
                    arrayIDs = arrayIDs.filter(function (sub) {
                      return sub !== item.Id;
                    });
                    setopenedCard(arrayIDs);
                  } else {
                    let arrayIDs = openedCard;

                    setopenedCard(arrayIDs.concat(item.Id));
                  }
                }}
              >
                {item.Sections?.length > 0 ? (
                  openedCard?.includes(item.Id) ? (
                    "-"
                  ) : (
                    "+"
                  )
                ) : (
                  <></>
                )}
              </Grid>
              {
                projectStatus === ARCHIVED || item.StatusId === ARCHIVED || phase.StatusId === ARCHIVED || response === true ? ""
                  :
                  <Grid
                    item
                    className={classes["plus"]}
                    paddingTop={0.2}
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                    onClick={() => {
                      if (item.IsCreator &&
                        localStorage.getItem("IsSubscribedBefore") !== "true") {
                        localStorage.setItem("subCreator", item.IsCreator);
                        localStorage.setItem("subId", item.Id);
                        localStorage.setItem("phaseName", phase.Name);
                        localStorage.setItem("phaseId", phase.Id);
                        navigate(`/saas/projects/subprojects?id=${ID}`);
                        localStorage.removeItem("viewSubPro");
                        localStorage.setItem("PhaseCreator", phase.IsCreator);
                      } else {
                        setDisabled(true);
                        setSubProCRUD(true);
                      }
                    }}
                  >
                    <img src={EditPen} alt="Edit" width={15} />
                  </Grid>
              }

              <Grid
                item
                className={classes["plus"]}
                paddingTop={0.2}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={() => {
                  localStorage.setItem("subId", item.Id);
                  localStorage.setItem("phaseName", phase.Name);
                  localStorage.setItem("phaseId", phase.Id);
                  localStorage.setItem("PhaseCreator", phase.IsCreator);
                  localStorage.setItem("viewSubPro", true);
                  navigate(`/saas/projects/subprojects?id=${ID}`);
                  if (!item.IsCreator) localStorage.setItem("noCreator", true);
                }}
              >
                <img src={ViewEye} alt="View" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {openedCard?.includes(item.Id) ? (
          renderSubProject(item.Sections, phase, item.StatusId === ARCHIVED ? true : false)
        ) : (
          <></>
        )}
      </Grid>
    ));
  };
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <Grid container alignItems="center" justifyContent="center">
        {disabled ? (
          <Grid
            item
            container
            bgcolor={"rgba(225, 227, 25, 0.5)"}
            xs={12}
            padding={1}
          >
            <Grid item md={4}></Grid>
            <Grid
              item
              md={6}
              style={{
                fontFamily: "Muli",
                fontSize: "17px",
                fontWeight: "bold",
                textAlign: "left",
                color: "#101650",
              }}
            >
              {phaseCRUD ? (
                <Grid>You don't have permission to edit a phase</Grid>
              ) : subProCRUD ? (
                <Grid>You don't have permission to edit a subproject</Grid>
              ) : (
                <></>
              )}
            </Grid>

            <Grid item md={2} container>
              {localStorage.getItem("isTeamMember") ||
                localStorage.getItem("isGuest") ? (
                <Grid item xs={8}></Grid>
              ) : (
                <Grid
                  item
                  onClick={() => {
                    props.setSetupProfile(true);
                    props.setSetupMenu(true);
                    navigate("/saas/profileSettings");
                  }}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                >
                  <button
                    style={{
                      padding: "5px",
                      borderRadius: "5px",
                      backgroundColor: "#101650",
                      fontFamily: "Muli",
                      fontSize: "13px",
                      textAlign: "center",
                      border: 0,
                      color: "#fff",
                    }}
                  >
                    Subscribe
                  </button>
                </Grid>
              )}
              <Grid
                item
                paddingLeft={1}
                onClick={() => {
                  setDisabled(false);
                  setSubProCRUD(false);
                  setPhaseCRUD(false);
                }}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
              >
                <CloseIcon fontSize="medium" />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={12} container alignItems="center" padding="1% 0 5% 10%">
          <Grid
            item
            xs={2}
            sx={{
              fontFamily: "Muli",
              fontSize: 18,
              fontWeight: "bold",
              lineHeight: 1.44,
              textAlign: "left",
              color: "#101650",
            }}
          >
            {projectName?.length > 36
              ? projectName.slice(0, 36) + "... Tree"
              : projectName + " " + "Tree"}
          </Grid>
          <Grid
            item
            xs={8}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>
          <Grid item xs={1} paddingLeft={{ xs: "5%", md: "1.5%" }}>
            <button
              className={classing["back-button"]}
              onClick={() => {
                localStorage.removeItem("viewSubPro");
                localStorage.removeItem("subId");
                localStorage.removeItem("phaseName");
                localStorage.removeItem("phaseId");
                localStorage.removeItem("PhaseCreator");
                navigate(-1);
              }}
            >
              Go back
            </button>
          </Grid>
        </Grid>
        <Grid md={9}>
          <TextField
            fullWidth
            size="small"
            InputLabelProps={{
              sx: {
                fontSize: 12,
                marginLeft: 5,
                fontFamily: "Muli",
                paddingTop: 0.2,
              },
              shrink: false,
            }}
            onChange={inputHandler}
            label="Search…"
            InputProps={{
              startAdornment: (
                <SearchIcon
                  fontSize="small"
                  htmlColor={"#707070"}
                  style={{ marginLeft: 8 }}
                />
              ),
              style: { fontFamily: "Muli" },
            }}
            sx={{
              fontFamily: "Muli",
              backgroundColor: "white",
              borderRadius: 1,
              height: 40,
              border: "solid 1px #e9e7e7",
              width: {
                xs: 1,
                sm: 483,
              },
            }}
          />
        </Grid>
        {phases?.length === 0 ? (
          <Grid
            item
            xs={12}
            container
            sx={{
              position: "relative",
              left: "48%",
            }}
          >
            <Grid item xs={12} paddingLeft="2%">
              <CircularProgress size={24} />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                fontfamily: "Muli",
                fontSize: 18,
                lineHeight: 1.44,
                textAlign: "left",
                color: "#0674b9",
              }}
            >
              Loading ...
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
        <Grid container item md={9} paddingTop={3}>
          {phases?.map((phase) => (
            <Grid container item xs={12}>
              <Grid
                xs={12}
                container
                item
                className={classes["Phases-Card"]}
                key={phase.Id}
              >
                <Grid
                  container
                  direction={"row"}
                  alignItems="center"
                  padding={1}
                  paddingLeft={5}
                >
                  <Grid item xs={2} className={classes["Card-Name"]}>
                    {phase.Name}
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    container
                    direction={"row-reverse"}
                    gap={3}
                    paddingRight={3}
                  >
                    <Grid
                      item
                      className={classes["plus"]}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                      onClick={(e) => {
                        if (openedCard.includes(phase.Id)) {
                          let arrayIDs = openedCard;
                          arrayIDs = arrayIDs.filter(function (item) {
                            return item !== phase.Id;
                          });
                          setopenedCard(arrayIDs);
                        } else {
                          let arrayIDs = openedCard;

                          setopenedCard(arrayIDs.concat(phase.Id));
                        }
                      }}
                    >
                      {openedCard?.includes(phase.Id) ? "-" : "+"}
                    </Grid>
                    {
                      projectStatus === ARCHIVED || phase.StatusId === ARCHIVED ? ""
                        :
                        <Grid
                          item
                          className={classes["plus"]}
                          paddingTop={0.2}
                          onMouseOver={(e) => {
                            e.target.style.cursor = "pointer";
                          }}
                          onClick={() => {
                            if (phase.IsCreator === true &&
                              localStorage.getItem("IsSubscribedBefore") !== "true") {
                              navigate(
                                `/saas/projects/creating?id=${ID}&edit=${true}`
                              );
                            } else {
                              setDisabled(true);
                              setPhaseCRUD(true);
                            }
                          }}
                        >
                          <img src={EditPen} alt="Edit" width={15} />
                        </Grid>
                    }
                    <Grid
                      item
                      className={classes["plus"]}
                      paddingTop={0.2}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                      onClick={() => {
                        if (!phase.IsCreator)
                          localStorage.setItem("notCreator", true);
                        navigate(
                          `/saas/projects/creating?id=${ID}&edit=${false}`
                        );
                      }}
                    >
                      <img src={ViewEye} alt="View" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {openedCard?.includes(phase.Id) ? (
                renderSubProject(phase.Sections, phase)
              ) : (
                <></>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
}
