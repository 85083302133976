//Change Tenant Status
export const CHANGE_STATUS = "/tenant/change-invitation-status";
// Tenant Resend invitation
export const RESEND_INVITATION = "/tenant/resend-invitation";

//MC-SC-TM Statuses
export const INVITED = 1;
export const ACTIVE = 2;
export const SUSPENDED = 3;
export const ARCHIVED = 4;

//Change Approval Status
export const CHANGE_APPROVAL_STATUS = "/Document/change-approval-status";
//Unarchive document
export const UNARCHIVE_DOCUMENT = "/Document/unarchive-document";

//Document Statuses
export const DRAFT = 1;
export const APPROVED = 2;
export const APPROVED_AS_NOTED = 3;
export const REVISE_AND_RESUBMIT = 4;
export const REJECTED = 5;
export const ARCHIVED_DOCUMENT = 6;

//Acyivity Statuses
export const DRAFT_ACTIVITY = "Draft";
export const PLANNED = "Planned";
export const IN_PROGRESS = "InProgress";
export const SUBMITTED_INTERNAL_APPROVAL_1 =
  "Submitted for  1- Internal Approval";
export const SUBMITTED_INTERNAL_APPROVAL_2 =
  "Submitted for  2- Internal Approval";
export const SUBMITTED_FOR_CONSULTANT = "Submitted to Consultant";

//submitted to
export const ACTIVITY_SUBMIT_TO_TEAM_MEMBER = 1;
export const ACTIVITY_SUBMIT_TO_CONSULTANT = 2;
