import React, { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const style = {
  bgcolor: "white",
  height: 200

};

export default function GoogleMapLocation(props) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
  });


  const mapRef = useRef(null);
  const [position, setPosition] = useState({
    lat: +props.latitude,
    lng: +props.longitude,
  });
  function handleLoad(map) {
    mapRef.current = map;
  }


  useEffect(() => {
    if (props.latitude && props.longitude) {
      setPosition({ lat: +props.latitude, lng: +props.longitude });
    } else {
      setPosition({ lat: 33.20452102259101, lng: 35.04299445350869 });
    }
  }, [props.latitude, props.longitude]);
  return isLoaded ? (
    <Box sx={style}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        id="map"
        center={position}
        zoom={15}
        onLoad={handleLoad}
      // onDragEnd={handleCenter}

      >
        <Marker position={position}></Marker>
      </GoogleMap>
    </Box>
  ) : (
    <></>
  );
}
