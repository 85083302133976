import React, { useEffect, useState, useMemo } from "react";
import SaasBack from "../../../Assets/Images/Projects/bgTableProject.png";
import { Grid } from "@mui/material";
import SalesOrderDetails from "./SalesOrderDetails";
import SubprojectsList from "./SubprojectsList";
import { useNavigate, useLocation } from "react-router-dom";
import SalesOrderMemberTable from "./SalesOrderMemberTable";
import * as incstr from "incstr";
import classes from "../../../Assets/Styles/Sales-Order.module.css";
import ShapeLibraryList from "./ShapeLibraryList";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import * as SALESORDERSCONST from "../../../../salesOrderConst";
import * as salesOrderServices from "../../../Services/sales-order-services.proxy";
import DatesModal from "./DatesModal";
import moment from "moment";
import PreviewPrinting from "./PreviewPrinting";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import * as permissionFunctions from "../../../../ReusableFunctions";

const CheckIfPermitted = permissionFunctions.CheckIfPermitted;
const importSdi = salesOrderServices.importSdi;
const createOrEditSalesOrder = salesOrderServices.createOrEditSalesOrder;
const createSalesOrder = salesOrderServices.createSalesOrder;
const updateSalesOrder = salesOrderServices.updateSalesOrder;
const exportSdi = salesOrderServices.exportSdi;

export default function SalesOrderForm(props) {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);
  const [disabledPlace, setDisabledPlace] = useState(false);
  const [disabledEdit, setDisabledEdit] = useState(false);
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  useEffect(() => {
    if (UserPermissions) {
      CheckIfPermitted(
        UserPermissions,
        "Sales Order",
        "Create/Place order manually",
        setDisabledPlace
      );
      CheckIfPermitted(UserPermissions, "Sales Order", "Edit", setDisabledEdit);
    }
  }, [UserPermissions]);

  const [openModal, setOpenModal] = useState(false);
  const [suggest, setSuggest] = useState(false);
  const [validate, setValidate] = useState(false);
  const [previewPrinting, setPreviewPrinting] = useState(false);
  const [finishUpdate, setFinishUpdate] = useState(false);
  const [suggestedDate, setSuggestedDate] = useState();
  const [commentForSuggestion, setCommentForSuggestion] = useState();

  const navigate = useNavigate();
  const location = useLocation();
  const [SalesOrderDetailsInfo, setSalesOrderDetailsInfo] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [shapes, setShapes] = useState([]);
  const [selectedShape, setSelectedShape] = useState();
  const [members, setMembers] = useState([]);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [memberIdSelected, setMemberIdSelected] = useState(0);
  const [Modify, setModify] = useState(false);

  const [subProjectId, setSubProjectId] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [errorEmpty, setErrorEmpty] = useState(false);
  const [drafting, setDrafting] = useState(false);
  const [placing, setPlacing] = useState(false);
  const [productionProgress, setProductionProgress] = useState(0);

  const [disabledFromActivity, setDisabledFromActivity] = useState(false);
  const [disabledAllFields, setDisabledAllFields] = useState(false);
  const [isvalidator, setIsvalidator] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [isSuggester, setIsSuggestor] = useState(false);
  const [exceedWeight, setExceedWeight] = useState(false);

  const [isCreator, setIsCreator] = useState(false);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();

  const [SALES_ORDER_ID, setSALES_ORDER_ID] = useState(query.get("id"));

  const [previousSALES_ORDER_ID, setPreviousSALES_ORDER_ID] = useState(null);

  const [calenderDateSwapped, setcalenderDateSwapped] = useState(query.get("date"));

  const PROJECT_ID = query.get("projectId");
  const salesOrderNb = query.get("salesOrderNb");
  const [ACT_ID, setACT_ID] = useState(location.state?.ActId);
  const [chosenFileID, setChosenFileID] = useState(
    location.state?.chosenFileId
  );

  useEffect(() => {
    return () => {
      if (finishUpdate === true) {
        setcalenderDateSwapped(null)
      }
      else {
        setcalenderDateSwapped(query.get("date"))
      }
    };
  }, [finishUpdate])

  useEffect(() => {
    if (PROJECT_ID) {
      setProjectId(PROJECT_ID);
    }
  }, [PROJECT_ID]);
  //#region
  //ORDER DETAILS
  const [orderNb, setOrderNb] = useState();

  const [projectName, setProjectName] = useState();

  const [parentId, setParentId] = useState(null);

  const [factoryId, setFactoryId] = useState(null);

  const [factoryMinDaysToDeliver, setFactoryMinDaysToDeliver] = useState(0);

  const [phaseName, setPhaseName] = useState();

  const [subprojectName, setSubprojectName] = useState();

  const [customerCompanyName, setCustomerCompanyName] = useState();

  const [customerCompanyNamesList, setCustomerCompanyNamesList] = useState([]);

  const [customerOrderReference, setCustomerOrderReference] = useState();
  const [customerOrderReferenceError, setCustomerOrderReferenceError] =
    useState(false);
  const [activityCode, setActivityCode] = useState();

  const [drawingNb, setDrawingNb] = useState();
  const [drawingNbError, setDrawingNbError] = useState(false);

  const [description, setDescription] = useState();
  const [descriptionError, setDescriptionError] = useState(false);

  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [fileId, setFileId] = useState(null);
  const [fileIds, setfileIds] = useState([]);

  const [drawingRevisionNb, setDrawingRevisionNb] = useState(0);
  const [drawingRevisionNbError, setDrawingRevisionNbError] = useState(false);

  const [status, setStatus] = useState(1);
  const [sentBy, setSentBy] = useState();
  const [creationDateAndTime, setCreationDateAndTime] = useState();

  const [subcontractor, setSubcontractor] = useState(0);
  const [subcontractorError, setSubcontractorError] = useState(false);

  const [misingFMError, setmisingFMError] = useState(false);

  const [factoryNb, setFactoryNb] = useState(null);
  const [unitOfMeasures, setUnitOfMeasures] = useState();
  const [receiptDate, setReceiptDate] = useState(null);

  const [barStandard, setBarStandard] = useState(0);
  const [barStandards, setBarStandards] = useState([]);
  const [barStandardError, setBarStandardError] = useState(false);

  const [barGrade, setBarGrade] = useState(0);
  const [barGrades, setBarGrades] = useState([]);
  const [barGradeError, setBarGradeError] = useState(false);

  const [barCoating, setBarCoating] = useState(0);
  const [barCoatings, setBarCoatings] = useState([]);
  const [barCoatingError, setBarCoatingError] = useState(false);

  const [plannedDeliveryDate, setPlannedDeliveryDate] = useState();
  const [minPlannedDate, setMinPlannedDate] = useState();
  const [deliveryDateStatus, setDeliveryDateStatus] = useState(1);
  const [subcontractorsList, setSubcontractorList] = useState([]);

  const [salesOrderWeightTheoretical, setSalesOrderWeightTheoretical] =
    useState();

  const [memberTypes, setMemberTypes] = useState([]);
  const [barMarkTypes, setBarMarkTypes] = useState([]);

  const [customerCompanyNameEmpty, setCustomerCompanyEmpty] = useState(false);
  const [subContractorsEmpty, setSubContractorsEmpty] = useState(false);
  const [factoryDetailsEmpty, setFactoryDetailsEmpty] = useState(false);

  const [subProjectCreator, setSubProjectCreator] = useState();

  //#endregion
  useEffect(() => {
    if (
      !customerOrderReference ||
      customerOrderReferenceError ||
      !drawingNb ||
      drawingNbError ||
      !barGrade ||
      barGradeError ||
      !barStandard ||
      barStandardError ||
      !barCoating ||
      barCoatingError ||
      drawingNbError ||
      (!subcontractor &&
        !subProjectCreator &&
        localStorage.getItem("AccountType") === "1") ||
      subcontractorError ||
      !plannedDeliveryDate ||
      members.length === 0 ||
      (!customerCompanyName && localStorage.getItem("AccountType") === "2")
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });
  useEffect(() => {
    if (SalesOrderDetailsInfo) {
      setDisabledFromActivity(true);
      setSubprojectName(SalesOrderDetailsInfo.SubProject);
      setPhaseName(SalesOrderDetailsInfo.Phase);
      setProjectName(SalesOrderDetailsInfo.Project);
      setProjectId(SalesOrderDetailsInfo.ProjectId);
      if (!SalesOrderDetailsInfo.CustomerCompanyName) {
        if (localStorage.getItem("AccountType") === "2")
          setCustomerCompanyEmpty(true);
      } else {
        setCustomerCompanyEmpty(false);
        setCustomerCompanyName(SalesOrderDetailsInfo.CustomerCompanyName);
      }

      setCustomerCompanyName(SalesOrderDetailsInfo.CustomerCompanyName);
      if (SalesOrderDetailsInfo.SubContractors.length === 0) {
        if (localStorage.getItem("AccountType") === "1") {
          setSubContractorsEmpty(true);
        } else {
          setSubContractorsEmpty(false);
        }
      } else {
        setSubContractorsEmpty(false);
        setSubcontractorList(SalesOrderDetailsInfo.SubContractors);
      }

      setSubcontractorList(SalesOrderDetailsInfo.SubContractors);
      setMemberTypes(SalesOrderDetailsInfo.MemberTypes);
      setBarMarkTypes(SalesOrderDetailsInfo.BarMarTypes);
      setSubProjectId(SalesOrderDetailsInfo.SalesOrderDetails.SubProjectId);
      setOrderNb(SalesOrderDetailsInfo.SalesOrderDetails.Code);
      setCustomerOrderReference(
        SalesOrderDetailsInfo.SalesOrderDetails.CustomerOrderReference
      );
      setActivityCode(SalesOrderDetailsInfo.Activitycode);
      setDrawingNb(SalesOrderDetailsInfo.DrawingNumber);
      setDescription(SalesOrderDetailsInfo.Description)
      setDrawingRevisionNb(SalesOrderDetailsInfo.DrawingRevisionNumber);
      setMembers(SalesOrderDetailsInfo.SalesOrderDetails.Members);
      setSubcontractor(SalesOrderDetailsInfo.SubProjectCreator);
      setSubProjectCreator(SalesOrderDetailsInfo.SubProjectCreator);


      if (
        localStorage.getItem("AccountType") === "2" ||
        SalesOrderDetailsInfo?.PhaseContract
      ) {
        setFactoryMinDaysToDeliver(
          SalesOrderDetailsInfo?.PhaseContract?.Factory
            .MinimumDeliveryDaysPerSalesorder
        );

        let minimumDate = moment(
          SalesOrderDetailsInfo?.SalesOrderDetails.CreationTime
        ).add(
          SalesOrderDetailsInfo?.PhaseContract?.Factory
            .MinimumDeliveryDaysPerSalesorder,
          "days"
        );
        minimumDate = minimumDate.format("llll");
        setMinPlannedDate(minimumDate);
        setPlannedDeliveryDate(minimumDate)
        setFactoryNb(SalesOrderDetailsInfo.PhaseContract?.Factory.Name);
        setFactoryId(SalesOrderDetailsInfo.PhaseContract?.Factory.Id);

        setBarGrade(SalesOrderDetailsInfo.PhaseContract?.BarGradeId);
        setBarCoating(SalesOrderDetailsInfo.PhaseContract?.BarCoatingId);
        setBarStandard(SalesOrderDetailsInfo.PhaseContract?.BarStandardId);
        setBarCoatings(
          SalesOrderDetailsInfo.PhaseContract?.Factory.References?.length > 0
            ? SalesOrderDetailsInfo.PhaseContract?.Factory.References?.filter(
              (ref) => ref.ReferenceId === 3
            )[0]?.Values
            : []
        );
        setBarStandards(
          SalesOrderDetailsInfo.PhaseContract?.Factory.References?.length > 0
            ? SalesOrderDetailsInfo.PhaseContract?.Factory.References?.filter(
              (ref) => ref.ReferenceId === 1
            )[0]?.Values
            : []
        );
        setBarGrades(
          SalesOrderDetailsInfo.PhaseContract?.Factory.References?.length > 0
            ? SalesOrderDetailsInfo.PhaseContract?.Factory.References?.filter(
              (ref) => ref.ReferenceId === 2
            )[0]?.Values
            : []
        );
      }
    }
  }, [SalesOrderDetailsInfo]);
  useEffect(() => {
    if ((subProjectId || SALES_ORDER_ID) && !SalesOrderDetailsInfo) {
      setLoadingData(true);
      createOrEditSalesOrder(
        SALES_ORDER_ID,
        SALES_ORDER_ID ? null : subProjectId,
        null
      ).then((x) => {
        setOrderNb(x.Code);
        setSubprojectName(x.SubProject);
        setPhaseName(x.Phase);
        setProjectName(x.Project);
        setProjectId(x.ProjectId);
        setSubProjectCreator(x.SubProjectCreator);
        if (!x.CustomerCompanyName) {
          if (localStorage.getItem("AccountType") === "2")
            setCustomerCompanyEmpty(true);
        } else {
          setCustomerCompanyEmpty(false);
          setCustomerCompanyName(x.CustomerCompanyName);
        }
        setCustomerCompanyName(x.CustomerCompanyName);
        if (x.SubContractors.length === 0) {
          if (localStorage.getItem("AccountType") === "1") {
            setSubContractorsEmpty(true);
          } else {
            setSubContractorsEmpty(false);
          }
        } else {
          setSubContractorsEmpty(false);
          setSubcontractorList(x.SubContractors);
        }
        setSubcontractorList(x.SubContractors);

        setFactoryNb(x.PhaseContract?.Factory.Name);
        setFactoryId(x.PhaseContract?.Factory.Id);

        setBarGrade(x.PhaseContract?.BarGradeId);
        setBarCoating(x.PhaseContract?.BarCoatingId);
        setBarStandard(x.PhaseContract?.BarStandardId);
        setBarCoatings(
          x.PhaseContract?.Factory.References?.length > 0
            ? x.PhaseContract?.Factory.References?.filter(
              (ref) => ref.ReferenceId === 3
            )[0]?.Values
            : []
        );
        setBarStandards(
          x.PhaseContract?.Factory.References?.length > 0
            ? x.PhaseContract?.Factory.References?.filter(
              (ref) => ref.ReferenceId === 1
            )[0]?.Values
            : []
        );
        setBarGrades(
          x.PhaseContract?.Factory.References?.length > 0
            ? x.PhaseContract?.Factory.References?.filter(
              (ref) => ref.ReferenceId === 2
            )[0]?.Values
            : []
        );
        setMemberTypes(x.MemberTypes);
        setBarMarkTypes(x.BarMarTypes);
        // if (localStorage.getItem("AccountType") === "1") {
        //   setCustomerCompanyName(localStorage.getItem("companyName"));
        // }
        if (SALES_ORDER_ID) {
          setParentId(x.SalesOrderDetails?.ParentId);
          setActivityCode(x.Activitycode);
          setACT_ID(x.SalesOrderDetails?.ActivityId);
          setSubProjectId(x.SalesOrderDetails.SubProjectId);
          setOrderNb(x.SalesOrderDetails.Code);
          setSuggestedDate(x.SalesOrderDetails.SuggestedDate);
          setCommentForSuggestion(x.SalesOrderDetails.Comment);
          setCustomerOrderReference(x.SalesOrderDetails.CustomerOrderReference);
          setFiles(x.SalesOrderDetails.Files ? x.SalesOrderDetails.Files : []);
          let tempFileIds = [];
          x.SalesOrderDetails?.Files?.map((f) => {
            tempFileIds.push({ FileId: f.FileId });
          });
          setfileIds(tempFileIds);
          setDrawingNb(x.SalesOrderDetails.DrawingNumber);
          setDescription(x.SalesOrderDetails.Description);
          setDrawingRevisionNb(x.SalesOrderDetails.DrawingRevisionNumber);
          setStatus(x.SalesOrderDetails.StatusId);
          if (x.SalesOrderDetails.StatusId !== SALESORDERSCONST.DRAFT_ID) {
            setDisabledAllFields(true);
          }
          setSubcontractor(x.SalesOrderDetails.SubProjectCollaborativeTenantId);
          if (x.SalesOrderDetails.ProducedPercentage)
            setProductionProgress(x.SalesOrderDetails.ProducedPercentage + "%");
          setDeliveryDateStatus(x.SalesOrderDetails.DeliveryStatusId);
          setCreationDateAndTime(x.SalesOrderDetails.CreationTime);
          setSentBy(x.SalesOrderDetails.Creator);
          setPlannedDeliveryDate(x.SalesOrderDetails.DeliveryDate);
          setReceiptDate(x.SalesOrderDetails.ReceiptDate);
          setSalesOrderWeightTheoretical(x.SalesOrderDetails.TheoreticalWeight);
          setMembers(x.SalesOrderDetails.Members);
          setIsvalidator(x.SalesOrderDetails.IsValidator);
          setIsSuggestor(x.SalesOrderDetails.IsSuggester);
          setSubProjectCreator(x.SubProjectCreator);
          setIsCreator(x.SalesOrderDetails.IsCreator);
          setCustomerCompanyEmpty(false);
          setSubContractorsEmpty(false);
          setBarGrade(x.SalesOrderDetails.BarGradeId);
          setBarCoating(x.SalesOrderDetails.BarCoatingId);
          setBarStandard(x.SalesOrderDetails.BarStandardId);
          if (x.SubContractors.length === 0) {

            setFactoryMinDaysToDeliver(
              x.PhaseContract?.Factory.MinimumDeliveryDaysPerSalesorder
            );

            let minimumDate = moment(x.SalesOrderDetails.CreationTime).add(
              x.PhaseContract?.Factory.MinimumDeliveryDaysPerSalesorder,
              "days"
            );
            minimumDate = minimumDate.format("llll");
            setMinPlannedDate(minimumDate);
            // console.log(new Date(minimumDate).toDateString())
          } else {
            console.log("subs exists");
            let subtmp = x.SubContractors.find(
              (s) =>
                s.Id === x.SalesOrderDetails.SubProjectCollaborativeTenantId
            );
            console.log(x.SubContractors);
            console.log(x.SalesOrderDetails.SubProjectCollaborativeTenantId);
            console.log(subtmp);
            setFactoryMinDaysToDeliver(
              subtmp?.Factory?.MinimumDeliveryDaysPerSalesorder
            );

            let minimumDate = moment(x?.SalesOrderDetails?.CreationTime).add(
              subtmp?.Factory?.MinimumDeliveryDaysPerSalesorder,
              "days"
            );
            minimumDate = minimumDate.format("llll");
            setMinPlannedDate(minimumDate);
          }
        }

        if (SALES_ORDER_ID === null) {
          setFactoryMinDaysToDeliver(
            x.PhaseContract?.Factory.MinimumDeliveryDaysPerSalesorder
          );

          let minimumDate = moment(new Date()).add(
            x.PhaseContract?.Factory.MinimumDeliveryDaysPerSalesorder,
            "days"
          );
          minimumDate = minimumDate.format("llll");
          setPlannedDeliveryDate(minimumDate);
          setMinPlannedDate(minimumDate);
        }

        setLoadingData(false);
        if (calenderDateSwapped) {
          console.log("swapped")
          if (
            new Date(calenderDateSwapped).getTime() !==
            new Date(x.SalesOrderDetails.DeliveryDate).getTime() &&
            new Date(calenderDateSwapped).getTime() !==
            new Date(x.SalesOrderDetails.SuggestedDate).getTime() &&
            x.SalesOrderDetails.StatusId !== 1
          ) {
            console.log("open")
            setOpenModal(true);
            setSuggest(true);
          }
        }
      });
    }
  }, [Modify]);
  const salesOrderData = {
    Id: SALES_ORDER_ID ? SALES_ORDER_ID : null,
    SubProjectId: subProjectId,
    ParentId: previousSALES_ORDER_ID ? previousSALES_ORDER_ID : null,
    ActivityId: ACT_ID,
    FactoryId: factoryId,
    SubProjectCollaborativeTenantId:
      localStorage.getItem("AccountType") === "2" ? null : subcontractor,
    Code: orderNb,
    CustomerOrderReference: customerOrderReference,
    DisciplineId: 3,
    TradeId: 1,
    StatusId: status,
    Files: fileIds,
    BarGrade: barGrade,
    BarCoating: barCoating,
    BarStandard: barStandard,
    PlannedDeliveryDate: plannedDeliveryDate,
    DeliveryStatusId: deliveryDateStatus,
    DrawingNumber: drawingNb,
    Description: description,
    DrawingRevisionNumber: drawingRevisionNb,
    SelecttionStatusId: 1,
    ReceiptDate: receiptDate,
    BarStandardId: barStandard,
    BarGradeId: barGrade,
    BarCoatingId: barCoating,

    Members: members,
  };

  useEffect(() => {
    if (disabledFromActivity || disabledAllFields) {
    } else {
      if (selectedShape && memberIdSelected === 0) {
        let membersLines = members?.flatMap((x) => x.Lines);

        let nullableLine = membersLines?.find((x) => x.Shape === null);
        if (nullableLine) {
          // let memberBarMark = members.find((x) => x.Lines.includes(nullableLine));
          nullableLine.Shape = structuredClone(selectedShape);
          nullableLine.ShapeCode = selectedShape.subCode
            ? selectedShape.Code + selectedShape.subCode
            : selectedShape.Code;

          forceUpdate();

          setSelectedShape(null);
        } else {
          members[members?.length - 1]?.Lines.push({
            OrderId:
              members.length > 0
                ? members[members?.length - 1].Lines.length + 1
                : 1,
            BarMarkNo:
              members[members?.length - 1]?.BarMarkTypeId === 1
                ? members[members?.length - 1].Lines.length === 0
                  ? 1
                  : +members[members?.length - 1].Lines[
                    members[members?.length - 1].Lines.length - 1
                  ].BarMarkNo + 1
                : members[members?.length - 1]?.BarMarkTypeId === 2
                  ? members[members?.length - 1].Lines.length === 0
                    ? "A"
                    : incstr(
                      members[members?.length - 1].Lines[
                        members[members?.length - 1].Lines.length - 1
                      ].BarMarkNo,
                      "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
                    )
                  : null,
            BarsQuantity: null,
            TotalNumberOfBars: null,
            BarDiameter: null,
            ShapeCode: selectedShape.subCode
              ? selectedShape.Code + selectedShape.subCode
              : selectedShape.Code,
            FormerDiameter: 1,
            FileId: null,
            ActualLength: null,
            TheoreticalLength: null,
            ActualWeight: null,
            TheoreticalWeight: null,
            WeightPerMeter: 1,
            Products: [],
            Shape: structuredClone(selectedShape),
            ShapeBlob: null,
          });
          forceUpdate();
          setSelectedShape(null);
        }
      } else if (
        selectedShape &&
        memberIdSelected !== 0 &&
        memberIdSelected !== null
      ) {
        members[memberIdSelected - 1]?.Lines.push({
          OrderId:
            members.length > 0
              ? members[memberIdSelected - 1].Lines.length + 1
              : 1,
          BarMarkNo:
            members[memberIdSelected - 1]?.BarMarkTypeId === 1
              ? members[memberIdSelected - 1].Lines.length === 0
                ? 1
                : +members[memberIdSelected - 1].Lines[
                  members[memberIdSelected - 1].Lines.length - 1
                ].BarMarkNo + 1
              : members[memberIdSelected - 1]?.BarMarkTypeId === 2
                ? members[memberIdSelected - 1].Lines.length === 0
                  ? "A"
                  : incstr(
                    members[memberIdSelected - 1].Lines[
                      members[memberIdSelected - 1].Lines.length - 1
                    ].BarMarkNo,
                    "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
                  )
                : null,
          BarsQuantity: null,
          TotalNumberOfBars: null,
          BarDiameter: null,
          ShapeCode: selectedShape.subCode
            ? selectedShape.Code + selectedShape.subCode
            : selectedShape.Code,
          FormerDiameter: null,
          ActualLength: null,
          TheoreticalLength: null,
          ActualWeight: null,
          TheoreticalWeight: null,
          WeightPerMeter: null,
          Products: [],
          Shape: structuredClone(selectedShape),
          ShapeBlob: null,
          FileId: null,
        });
        forceUpdate();
        setSelectedShape(null);
      }
    }
  }, [selectedShape, memberIdSelected]);
  useEffect(() => {
    setFactoryDetailsEmpty(false);
    if (subcontractor && subcontractorsList.length>0) {
      const subcontractorChosen = subcontractorsList.filter(
        (x) => x.Id === subcontractor
      );
      setUnitOfMeasures(
        subcontractorChosen[0]?.MeasurementSystemId ===
          SALESORDERSCONST.METRIC_MEASURES
          ? "mm--kg"
          : "inches--pounds"
      );
      setBarCoatingError(false);
      setBarStandardError(false);
      setBarGradeError(false);
      if (subcontractorChosen[0]?.FactoryName === null)
        setFactoryDetailsEmpty(true);
      else setFactoryDetailsEmpty(false);
      setFactoryNb(subcontractorChosen[0]?.FactoryName);
      setFactoryId(subcontractorChosen[0]?.Factory?.Id);
      setFactoryMinDaysToDeliver(
        subcontractorChosen[0]?.Factory?.MinimumDeliveryDaysPerSalesorder
      );
      let minimumDate = moment(new Date()).add(
        subcontractorChosen[0]?.Factory?.MinimumDeliveryDaysPerSalesorder,
        "days"
      );
      minimumDate = minimumDate.format("llll");
      console.log(minimumDate);
      setMinPlannedDate(minimumDate);
      setPlannedDeliveryDate(minimumDate);

      setBarCoatings(
        subcontractorChosen[0]?.Factory?.References?.length > 0
          ? subcontractorChosen[0]?.Factory?.References?.filter(
            (x) => x.ReferenceId === 3
          )[0]?.Values
          : []
      );
      setBarStandards(
        subcontractorChosen[0]?.Factory?.References?.length > 0
          ? subcontractorChosen[0]?.Factory?.References?.filter(
            (x) => x.ReferenceId === 1
          )[0]?.Values
          : []
      );
      setBarGrades(
        subcontractorChosen[0]?.Factory?.References?.length > 0
          ? subcontractorChosen[0]?.Factory?.References?.filter(
            (x) => x.ReferenceId === 2
          )[0]?.Values
          : []
      );
      if (!SALES_ORDER_ID) {
        setBarStandard(subcontractorChosen[0]?.BarStandardId);
        setBarCoating(subcontractorChosen[0]?.BarCoatingId);
        setBarGrade(subcontractorChosen[0]?.BarGradeId);
      }
    }
  }, [subcontractor]);
  const saveSalesOrder = async () => {
    if (SALES_ORDER_ID) {
      await updateSalesOrder(salesOrderData).then((x) => {
        setDrafting(false);
        setPlacing(false);
        setModify(!Modify);
        if (x.status === 409) {
          setExceedWeight(true);
        } else if (x.status === 500) {
          setExceedWeight(true);
          setmisingFMError(true);
        }
      });
    } else {
      await createSalesOrder(salesOrderData).then((x) => {
        setDrafting(false);
        setPlacing(false);
        if (x.status === 409) {
          setExceedWeight(true);
        } else if (x.status === 500) {
          setExceedWeight(true);
          setmisingFMError(true);
        } else if (x.statusCode === 500) {
          setExceedWeight(true);
          setmisingFMError(true);
        } else {
          navigate(`/saas/salesOrderListCard?id=${projectId}`);
        }
      });
    }
  };
  const CheckAllMembersFields = () => {
    let stopArray = false;
    members.map((mem) => {
      if (
        mem.Quantity === null ||
        !mem.Name ||
        !mem.TypeId ||
        mem.Lines.length === 0
      ) {
        setErrorEmpty(true);
        stopArray = true;
      } else {
        mem.Lines.map((line) => {
          if (
            line.BarsQuantity === null ||
            !line.BarDiameter ||
            !line.ShapeCode ||
            !line.ActualLength
          ) {
            setErrorEmpty(true);
            stopArray = true;
          } else {
            if (!stopArray) {
              setErrorEmpty(false);
            }
          }
        });
      }
    });
    if (!stopArray) {
      setPlacing(true);
      salesOrderData.StatusId = SALESORDERSCONST.PLACED_ID;
      saveSalesOrder();

      setErrorEmpty(false);
    }
  };
  // from activity
  useEffect(() => {
    if (chosenFileID) {
      setLoadingData(true);
      importSdi(ACT_ID, chosenFileID).then((x) => {
        setSalesOrderDetailsInfo(x);
        setLoadingData(false);
      });
    }
  }, [chosenFileID]);

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
        objectFit: "cover",
      }}
    >
      {exceedWeight ? (
        <Grid
          item
          className={classes["ExceedWeight"]}
          container
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid item xs={7} paddingTop={3} paddingBottom={2}>
            {misingFMError
              ? "Please set up your factory master parameters before creation"
              : ACT_ID
                ? "Cannot create a sales order! Sales order weight exceeds the predefined maximum limit in the master parameters,  select some members or reduce the member quantity"
                : "Cannot create a sales order! Sales order weight exceeds the predefined maximum limit in the master parameters, reduce the member quantity or remove some members"}
          </Grid>
          <Grid
            item
            xs={1}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            onClick={(e) => {
              setExceedWeight(false);
            }}
          >
            <CloseIcon fontSize="small" htmlColor={"#3a3a3a"} />
          </Grid>
        </Grid>
      ) : (
        <></>
      )}

      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={2}
        paddingTop={previewPrinting ? 0 : 3}
      >
        {previewPrinting ? (
          <PreviewPrinting
            setPreviewPrinting={setPreviewPrinting}
            SALES_ORDER_ID={SALES_ORDER_ID}
            setShowSaaSNavbar={props.setShowSaaSNavbar}
          />
        ) : (
          <>
            <Backdrop
              sx={{
                color: "#fff",
                backgroundColor: "transparent",
                zIndex: (theme) => theme.zIndex.drawer + 1,
                display: "flex",
                flexDirection: "column",
              }}
              open={loadingData}
            >
              <CircularProgress />

              <Grid
                item
                fontFamily={"Muli"}
                fontSize={15}
                fontWeight={"bold"}
                color={"rgb(25, 118, 210)"}
              >
                Loading Data...
              </Grid>
            </Backdrop>
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              gap={1}
            >
              <Grid
                item
                lg={0.8}
                sm={2}
                fontSize={{ xs: "13px", sm: "18px" }}
                sx={{
                  fontWeight: "bold",
                  fontFamily: "Muli",
                  color: "#101650",
                }}
              >
                Sales Order
              </Grid>
              <Grid
                item
                lg={8.8}
                sm={5.8}
                xs={3}
                sx={{
                  border: "solid 0.1px #fff",
                  backgroundColor: "#fff",
                  height: 0,
                }}
              ></Grid>
              <Grid
                item
                lg={1}
                sm={2}
                fontSize={{ xs: "9px", sm: "12px" }}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                  e.target.style.fontWeight = "bold";
                }}
                onMouseOut={(e) => {
                  e.target.style.fontWeight = "normal";
                }}
                onClick={() => {
                  localStorage.removeItem("ProjectName");
                  navigate("/saas/salesOrderList");
                }}
              >
                View all Sales Orders
              </Grid>
              <Grid item xs={2.5} sm={1.3} lg={0.6}>
                <button
                  className={classes["back-button"]}
                  onClick={() => {
                    if (finishUpdate)
                      navigate('/saas/salesOrdersCalender')
                    else
                      navigate(-1);
                  }}
                >
                  Go Back
                </button>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              xs={11.4}
              spacing={1}
              marginBottom={4}
              justifyContent="center"
            >
              <Grid item xs={11.6} sm={8.5} lg={9} backgroundColor="#fff">
                <SalesOrderDetails
                  disabledEdit={disabledEdit}
                  customerCompanyNameEmpty={customerCompanyNameEmpty}
                  subContractorsEmpty={subContractorsEmpty}
                  ACT_ID={ACT_ID}
                  orderNb={orderNb}
                  projectName={projectName}
                  phaseName={phaseName}
                  subprojectName={subprojectName}
                  customerCompanyName={customerCompanyName}
                  customerCompanyNamesList={customerCompanyNamesList}
                  setCustomerCompanyNamesList={setCustomerCompanyNamesList}
                  customerOrderReference={customerOrderReference}
                  setCustomerOrderReference={setCustomerOrderReference}
                  customerOrderReferenceError={customerOrderReferenceError}
                  setCustomerOrderReferenceError={
                    setCustomerOrderReferenceError
                  }
                  activityCode={activityCode}
                  drawingNb={drawingNb}
                  setDrawingNb={setDrawingNb}
                  drawingNbError={drawingNbError}
                  description={description}
                  setDescription={setDescription}
                  descriptionError={descriptionError}
                  setDescriptionError={setDescriptionError}
                  setDrawingNbError={setDrawingNbError}
                  file={file}
                  setFile={setFile}
                  files={files}
                  setFiles={setFiles}
                  fileId={fileId}
                  setFileId={setFileId}
                  fileIds={fileIds}
                  drawingRevisionNb={drawingRevisionNb}
                  setDrawingRevisionNb={setDrawingRevisionNb}
                  drawingRevisionNbError={drawingRevisionNbError}
                  setDrawingRevisionNbError={setDrawingRevisionNbError}
                  status={status}
                  sentBy={sentBy}
                  creationDateAndTime={creationDateAndTime}
                  subcontractor={subcontractor}
                  setSubcontractor={setSubcontractor}
                  subcontractorError={subcontractorError}
                  setSubcontractorError={setSubcontractorError}
                  factoryNb={factoryNb}
                  unitOfMeasures={unitOfMeasures}
                  receiptDate={receiptDate}
                  plannedDeliveryDate={plannedDeliveryDate}
                  setPlannedDeliveryDate={setPlannedDeliveryDate}
                  deliveryDateStatus={deliveryDateStatus}
                  subcontractorsList={subcontractorsList}
                  setSubcontractorList={setSubcontractorList}
                  salesOrderWeightTheoretical={salesOrderWeightTheoretical}
                  projectId={projectId}
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                  suggest={suggest}
                  setSuggest={setSuggest}
                  validate={validate}
                  setValidate={setValidate}
                  suggestedDate={suggestedDate}
                  setSuggestedDate={setSuggestedDate}
                  commentForSuggestion={commentForSuggestion}
                  setCommentForSuggestion={setCommentForSuggestion}
                  disabledFromActivity={disabledFromActivity}
                  disabledAllFields={disabledAllFields}
                  isvalidator={isvalidator}
                  isSuggestor={isSuggester}
                  isCreator={isCreator}
                  calenderDateSwapped={calenderDateSwapped}
                  setPreviewPrinting={setPreviewPrinting}
                  setShowSaaSNavbar={props.setShowSaaSNavbar}
                  SALES_ORDER_ID={SALES_ORDER_ID}
                  modify={Modify}
                  setModify={setModify}
                  subProjectId={subProjectId}
                  subProjectCreator={subProjectCreator}
                  barStandard={barStandard}
                  setBarStandard={setBarStandard}
                  barStandardError={barStandardError}
                  setBarStandardError={setBarStandardError}
                  barGrade={barGrade}
                  setBarGrade={setBarGrade}
                  barGradeError={barGradeError}
                  setBarGradeError={setBarGradeError}
                  barCoating={barCoating}
                  setBarCoating={setBarCoating}
                  barCoatingError={barCoatingError}
                  setBarCoatingError={setBarCoatingError}
                  barStandards={barStandards}
                  barGrades={barGrades}
                  barCoatings={barCoatings}
                  factoryMinDaysToDeliver={factoryMinDaysToDeliver}
                  minPlannedDate={minPlannedDate}
                  productionProgress={productionProgress}
                  factoryDetailsEmpty={factoryDetailsEmpty}
                />
                <Grid
                  item
                  fontFamily={"Muli"}
                  fontSize={17}
                  fontWeight={"bold"}
                  color={"#ea001e"}
                >
                  {SalesOrderDetailsInfo
                    ? "**Please sync all shapes before saving or placing sales order"
                    : ""}
                </Grid>
                <SalesOrderMemberTable
                  members={members}
                  setMembers={setMembers}
                  shapes={shapes}
                  memberIdSelected={memberIdSelected}
                  setMemberIdSelected={setMemberIdSelected}
                  memberTypes={memberTypes}
                  barMarkTypes={barMarkTypes}
                  projectId={projectId}
                  disabledFromActivity={disabledFromActivity}
                  disabledAllFields={disabledAllFields}
                  activityCode={activityCode}
                  status={status}
                  setSalesOrderWeightTheoretical={
                    setSalesOrderWeightTheoretical
                  }
                  factoryId={factoryId}
                />

                {status === SALESORDERSCONST.DRAFT_ID ? (
                  <Grid
                    item
                    container
                    xs={12}
                    direction={"row"}
                    paddingLeft={2.5}
                    paddingBottom={2.5}
                  >
                    <Grid item container direction={"row"} xs={6} gap={2}>
                      <button
                        className={classes["CancelBut"]}
                        onClick={() => {
                          navigate("/saas/sales");
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        disabled={
                          isDisabled || (disabledPlace && SALES_ORDER_ID)
                        }
                        className={classes["Place-Order"]}
                        onClick={() => {
                          setDrafting(true);
                          saveSalesOrder();
                        }}
                      >
                        {drafting ? "Saving.." : "Save As Draft"}
                      </button>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      direction={"row-reverse"}
                      container
                      paddingRight={2.5}
                      gap={2}
                    >
                      <button
                        disabled={
                          isDisabled || (disabledPlace && SALES_ORDER_ID) || placing
                        }
                        className={classes["Place-Order"]}
                        onClick={() => {
                          CheckAllMembersFields();
                        }}
                      >
                        {placing ? "Placing..." : "Place Order"}
                      </button>
                      <Grid
                        item
                        fontFamily={"Muli"}
                        fontSize={17}
                        fontWeight={"bold"}
                        color={"#ea001e"}
                      >
                        {errorEmpty
                          ? "There is empty fields in the table, Please check."
                          : ""}
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    item
                    container
                    xs={12}
                    direction={"row-reverse"}
                    paddingRight={2.5}
                    paddingBottom={2.5}
                    gap={2}
                  >
                    {parentId === null && ACT_ID ? (
                      <button
                        disabled={isDisabled}
                        className={classes["Place-Order"]}
                        onClick={() => {
                          setPlacing(true);
                          setLoadingData(true);
                          let memToChange = members.map((m1) => {
                            if (m1.RemainingQuantity > 0 && m1.Quantity) {
                              return { Id: m1.Id, Quantity: m1.Quantity };
                            }
                          });
                          memToChange = memToChange.filter((x) => x != null);
                          const parent = {
                            Id: SALES_ORDER_ID,
                            Members: memToChange,
                          };

                          createOrEditSalesOrder(null, null, null, parent).then(
                            (x) => {
                              setPreviousSALES_ORDER_ID(SALES_ORDER_ID);
                              setSALES_ORDER_ID(null);
                              setPlacing(false);
                              setLoadingData(false);
                              setSubprojectName(x.SubProject);
                              setPhaseName(x.Phase);
                              setProjectName(x.Project);
                              setProjectId(x.ProjectId);
                              setCustomerCompanyName(x.CustomerCompanyName);
                              setSubcontractorList(x.SubContractors);
                              setMemberTypes(x.MemberTypes);
                              setBarMarkTypes(x.BarMarTypes);
                              setSubProjectId(x.SalesOrderDetails.SubProjectId);
                              setOrderNb(x.SalesOrderDetails.Code);
                              setSuggestedDate(null);
                              setCustomerOrderReference(" ");
                              setFiles(null);
                              setfileIds(null);
                              setDrawingNb(x.DrawingNumber);
                              setDescription(x.Description)
                              setDrawingRevisionNb(x.DrawingRevisionNumber);
                              setStatus(1);
                              setSubcontractor(0);
                              setDeliveryDateStatus(1);
                              setCreationDateAndTime(null);
                              setSentBy(null);
                              setPlannedDeliveryDate(null);
                              setReceiptDate(null);
                              setSalesOrderWeightTheoretical(null);
                              setMembers(x.SalesOrderDetails.Members);
                              setDisabledFromActivity(true);
                              setDisabledAllFields(false);
                            }
                          );
                        }}
                      >
                        {placing ? "Placing..." : "Place Selected"}
                      </button>
                    ) : (
                      ""
                    )}

                    <button
                      className={classes["Place-Order"]}
                      onClick={() => {
                        setExporting(true);
                        exportSdi(SALES_ORDER_ID).then((x) => {
                          setExporting(false);
                        });
                      }}
                    >
                      {exporting ? "Exporting..." : "Export SDI"}
                    </button>
                  </Grid>
                )}
              </Grid>
              <Grid item container direction="column" sm={3.5} lg={3}>
                <Grid item height={1000} overflow={"auto"}>
                  <SubprojectsList
                    subProjectId={subProjectId}
                    setSubProjectId={setSubProjectId}
                    projectId={projectId}
                    setProjectId={setProjectId}
                    SALES_ORDER_ID={SALES_ORDER_ID}
                    modify={Modify}
                    setModify={setModify}
                    SalesOrderDetailsInfo={SalesOrderDetailsInfo}
                    salesOrderNb={salesOrderNb}
                    setCustomerCompanyEmpty={setCustomerCompanyEmpty}
                    setSubContractorsEmpty={setSubContractorsEmpty}
                    setSubcontractorError={setSubcontractorError}
                    setCustomerCompanyName={setCustomerCompanyName}
                    setCustomerOrderReference={setCustomerOrderReference}
                    setDrawingNb={setDrawingNb}
                    setDrawingRevisionNb={setDrawingRevisionNb}
                    setSubcontractor={setSubcontractor}
                    setFactoryId={setFactoryId}
                    setFactoryNb={setFactoryNb}
                    setCustomerOrderReferenceError={
                      setCustomerOrderReferenceError
                    }
                    setDrawingNbError={setDrawingNbError}
                    setBarStandardError={setBarStandardError}
                    setBarCoatingError={setBarCoatingError}
                    setBarGradeError={setBarGradeError}
                  />
                </Grid>
                <Grid item height={810} overflow={"auto"} marginTop={5}>
                  <ShapeLibraryList
                    shapes={shapes}
                    setShapes={setShapes}
                    // selectedShape={selectedShape}
                    setSelectedShape={setSelectedShape}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <DatesModal
        finishUpdate={finishUpdate}
        setFinishUpdate={setFinishUpdate}
        openModal={openModal}
        setOpenModal={setOpenModal}
        suggest={suggest}
        setSuggest={setSuggest}
        validate={validate}
        setValidate={setValidate}
        suggestedDate={suggestedDate}
        setSuggestedDate={setSuggestedDate}
        commentForSuggestion={commentForSuggestion}
        setCommentForSuggestion={setCommentForSuggestion}
        modify={Modify}
        setModify={setModify}
        calenderDateSwapped={calenderDateSwapped}
        SALES_ORDER_ID={SALES_ORDER_ID}
        plannedDeliveryDate={plannedDeliveryDate}
        minPlannedDate={minPlannedDate}
      />
    </div>
  );
}
