import React, { useState, useEffect, useMemo } from "react";
import { Grid, Link } from "@mui/material";
import Logo from "../../../Assets/Images/Navbar/logo.png";
import "../../../Assets/Styles/LogIn.css";
import { IconButton } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import validator from "validator";
import * as resetPass from "../../../Services/reset-pass.proxy";
const verifyToken = resetPass.verifyToken;
const reset_Pass = resetPass.resetPass;
function ResetForm({ setRegister, setLoading }) {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmpassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmpasswordError] = useState("");
  const [inputsError, setInputsError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfimPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickConfirmShowPassword = () =>
    setShowConfirmPassword(!showConfimPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfimPassword);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();

  const resetToken = query.get("token");
  const [validToken, setValidToken] = useState("");
  const [resetSuccessful, setResetSuccessful] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    setRegister(true);
  }, [setRegister]);
  useEffect(() => {
    verifyToken(resetToken, setLoading).then((res) => {
      //not found exception

      if (res === 403) setValidToken("invalid");
      else {
        setValidToken(resetToken);
      }
    });
  }, [resetToken, setLoading]);

  const passwordReset = () => {
    //setLoading(true);
    setSubmitLoading(true);
    reset_Pass(validToken, password, setLoading).then((res) => {
      if (res.status === 201) {
        setValidToken("invalid");
        setSubmitLoading(false);
        setResetSuccessful(true);
      }
    });
  };
  return (
    <Grid
      container
      className="LoginBg"
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Grid
        item
        container
        direction={"column"}
        width="380px"
        height="100%"
        alignItems={"center"}
        className="FormBg"
        paddingBottom={5}
      >
        <Grid item marginTop={5}>
          <a href="/">
            <img src={Logo} alt="logo" height={50} />
          </a>
        </Grid>
        {validToken === "invalid" ? (
          <>
            <Grid item marginTop={3}>
              <span
                className="Reset-Your-Pasword"
                style={{ color: resetSuccessful ? "#101650" : "red" }}
              >
                {resetSuccessful
                  ? "Password has been changed"
                  : "Reset link has been expired!"}
              </span>
            </Grid>
            <Grid item marginTop={1.5}>
              <button
                className="return-button"
                onMouseOver={(e) => {
                  e.target.style.backgroundColor = "#101650";
                  e.target.style.cursor = "pointer";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "#1173cf";
                }}
                onClick={(e) => navigate("/login")}
              >
                Return to login
              </button>
            </Grid>
          </>
        ) : (
          <>
            <Grid item marginTop={3}>
              <span className="Reset-Your-Pasword">Reset Your Pasword</span>
            </Grid>
            <Grid item marginTop={2}>
              <span className="login-errors">
                {inputsError ? "Empty fields." : ""}
              </span>
            </Grid>
            <Grid item direction={"column"} marginTop={3}>
              <Grid item>
                <span className="FiedLabels">New Password</span>
              </Grid>
              <Grid item>
                <TextField
                  inputProps={{ maxLength: 20 }}
                  error={passwordError}
                  helperText={
                    passwordError
                      ? "Password must contain a number, a symbol, an uppercase & lowercase and be no less than 8  or more than 20 characters."
                      : ""
                  }
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <Visibility htmlColor="rgba(6, 116, 185, 0.5)" />
                        ) : (
                          <VisibilityOff htmlColor="rgba(6, 116, 185, 0.5)" />
                        )}
                      </IconButton>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& > fieldset": {
                        borderColor: "#f2f2f2",
                      },
                    },
                    "& .MuiOutlinedInput-root:hover": {
                      "& > fieldset": {
                        borderColor: "#f2f2f2",
                      },
                    },
                  }}
                  id="outlined-name"
                  size="small"
                  className="FieldsInput"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    if (
                      !validator.isStrongPassword(e.target.value, {
                        minLength: 8,
                        minLowercase: 1,
                        minUppercase: 1,
                        minNumbers: 1,
                        minSymbols: 1,
                      })
                    ) {
                      setPasswordError(true);
                    } else if (e.target.value === confirmPassword) {
                      setConfirmpasswordError(false);
                    } else if (e.target.value !== confirmPassword) {
                      setConfirmpasswordError(true);
                      if (
                        validator.isStrongPassword(e.target.value, {
                          minLength: 8,
                          minLowercase: 1,
                          minUppercase: 1,
                          minNumbers: 1,
                          minSymbols: 1,
                        })
                      ) {
                        setPasswordError(false);
                      }
                    } else {
                      setPasswordError(false);
                    }
                  }}
                  value={password}
                />
              </Grid>
            </Grid>
            <Grid item direction={"column"} marginTop={1}>
              <Grid item>
                <span className="FiedLabels">Confirm New Password</span>
              </Grid>
              <Grid item>
                <TextField
                  inputProps={{ maxLength: 20 }}
                  error={confirmPasswordError}
                  helperText={
                    confirmPasswordError ? "Password not matching" : ""
                  }
                  type={showConfimPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickConfirmShowPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                        edge="end"
                      >
                        {showConfimPassword ? (
                          <Visibility htmlColor="rgba(6, 116, 185, 0.5)" />
                        ) : (
                          <VisibilityOff htmlColor="rgba(6, 116, 185, 0.5)" />
                        )}
                      </IconButton>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& > fieldset": {
                        borderColor: "#f2f2f2",
                      },
                    },
                    "& .MuiOutlinedInput-root:hover": {
                      "& > fieldset": {
                        borderColor: "#f2f2f2",
                      },
                    },
                  }}
                  id="outlined-name"
                  size="small"
                  className="FieldsInput"
                  onChange={(e) => {
                    setConfirmpassword(e.target.value);
                    if (e.target.value !== password) {
                      setConfirmpasswordError(true);
                    } else {
                      setConfirmpasswordError(false);
                    }
                  }}
                  value={confirmPassword}
                />
              </Grid>
              <Grid item marginTop={1.5}>
                <button
                  disabled={submitLoading}
                  className="return-button"
                  onMouseOver={(e) => {
                    e.target.style.backgroundColor = "#101650";
                    e.target.style.cursor = "pointer";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#1173cf";
                  }}
                  onClick={(e) => {
                    if (inputsError || passwordError) {
                      setInputsError(true);
                    } else if (confirmPasswordError) {
                      setConfirmpasswordError(true);
                    } else {
                      passwordReset();
                    }
                  }}
                >
                  {submitLoading ? "Submitting..." : "Submit"}
                </button>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default ResetForm;
