import { Button, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ActivityCard from "./ActivityCard";
import * as activityServices from "../../Services/activities-services.proxy";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import * as permissionsFunctions from "../../../ReusableFunctions";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

const CheckIfPermitted = permissionsFunctions.CheckIfPermitted;
const getActivitiesCardList = activityServices.getActivitiesCardList;
export default function Activities(props) {
  const navigate = useNavigate();
  const data0 = {
    Code: "Activity Code",
    Description:
      "Summary Activity Description...Summary Activity Description...",
    ActivityStatus: "",
    SubmittalStatus: "",
  };
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  const [activities, setActivities] = useState([]);
  const [total, setTotal] = useState(0);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getActivitiesCardList().then((x) => {
      setTotal(x.length);
      setActivities(x);
      setLoading(false);
    });
  }, []);
  const planType = localStorage.getItem("planId");

  const [disabled, setDisabled] = useState(false);
  const [createDisabled, setCreateDisabled] = useState(false);
  useEffect(() => {
    if (
      localStorage.getItem("IsFreePlanActivated") === "true" &&
      localStorage.getItem("isSubscribed") === "false"
    ) {
      setDisabled(true);
    } else if (
      !localStorage.getItem("isSubscribed") &&
      localStorage.getItem("isGuest") !== "true"
    ) {
      setDisabled(true);
    }
  }, []);
  useEffect(() => {
    if (UserPermissions) {
      CheckIfPermitted(
        UserPermissions,
        "Drawing Activity",
        "Create",
        setCreateDisabled
      );
    }
    if (!localStorage.getItem("isSubscribed")) {
      setCreateDisabled(true);
    }
  }, [UserPermissions]);

  return (
    <Grid
      container
      justifyContent="center"
      padding={"1% 0 0 0%"}
      rowSpacing={2}
      paddingBottom={5}
    >
      {disabled ? (
        <Grid
          item
          container
          bgcolor={"rgba(225, 227, 25, 0.5)"}
          xs={12}
          padding={1}
          justifyContent={"center"}
          alignItems={"center"}
          gap={2}
        >
          <Grid
            item
            style={{
              fontFamily: "Muli",
              fontSize: "17px",
              fontWeight: "bold",
              textAlign: "left",
              color: "#101650",
            }}
          >
            {localStorage.getItem("IsFreePlanActivated") === "true" ||
            !localStorage.getItem("isSubscribed")
              ? "Subscribe to Engineering Plan to create activities."
              : planType === "Pro"
              ? "Upgrade subscription required (Pro+)"
              : planType === "Core"
              ? "Upgrade subscription required (Pro or Pro+)"
              : localStorage.getItem("isGuest") === "true"
              ? "Please subscribe to create Activity"
              : UserPermissions.find(
                  (permission) =>
                    permission.Name === "Engineer" ||
                    permission.Name === "Document Controller"
                )
              ? "You dont have the permission"
              : ""}
          </Grid>

          <Grid item md={2} container>
            {localStorage.getItem("isTeamMember") === "true" ? (
              <Grid item xs={8}></Grid>
            ) : (
              <Grid
                item
                onClick={() => {
                  navigate("/saas/profileSettings");
                }}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
              >
                <button
                  style={{
                    padding: "5px",
                    borderRadius: "5px",
                    backgroundColor: "#101650",
                    fontFamily: "Muli",
                    fontSize: "13px",
                    textAlign: "center",
                    border: 0,
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/saas/profileSettings");
                  }}
                >
                  Subscribe
                </button>
              </Grid>
            )}
            <Grid
              item
              paddingLeft={1}
              onClick={() => {
                setDisabled(false);
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
            >
              <CloseIcon fontSize="medium" />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <Grid item xs={12} container rowSpacing={3} paddingLeft={14}>
        <Grid item xs={12} container alignItems="center">
          <Grid
            item
            xs={1}
            sx={{
              fontFamily: "Muli",
              fontSize: 18,
              fontWeight: "bold",
              lineHeight: 1.44,
              textAlign: "left",
              color: "#101650",
            }}
          >
            Activities
          </Grid>
          <Grid
            item
            xs={8.9}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>
          <Grid
            item
            xs={2}
            onMouseOver={(e) => (e.target.style.cursor = "pointer")}
            sx={{
              fontFamily: "Muli",
              fontSize: 14,
              lineHeight: 1.36,
              textAlign: "left",
              color: "#101650",
              paddingLeft: "1%",
            }}
            onClick={() => {
              navigate("/saas/execution/activitiesTable");
            }}
          >
            View all Activities
          </Grid>
        </Grid>

        <Grid item xs={6} container columnSpacing={2}>
          <Grid item>
            <Button
              disabled={
                localStorage.getItem("IsSubscribedBefore") === "true"
                  ? true
                  : createDisabled &&
                    localStorage.getItem("isGuest") === "false"
                  ? true
                  : false
              }
              onClick={() => {
                // let ActivytyPermissions = UserPermissions.map((role) =>
                //   role.Permissions.find(
                //     (permission) => permission.Name === "Drawing Activity"
                //   )
                // );
                if (
                  (total === 1000 && planType === "Core") ||
                  (total === 5000 && planType === "Pro") ||
                  UserPermissions.find(
                    (permission) =>
                      permission.Name === "Engineer" ||
                      permission.Name === "Document Controller"
                  )
                ) {
                  setDisabled(true);
                } else {
                  navigate("/saas/execution/create");
                }
              }}
              style={{
                borderRadius: 5,
                backgroundColor:
                  localStorage.getItem("IsSubscribedBefore") === "true"
                    ? "#707070"
                    : createDisabled &&
                      localStorage.getItem("isGuest") === "false"
                    ? "#707070"
                    : "#101650",
                fontFamily: "Muli",
                fontSize: 12,
                fontWeight: "bold",
                textAlign: "left",
                color: "#fff",
                textTransform: "none",
                padding: "10px",
              }}
            >
              + Create an Activity
            </Button>
          </Grid>
        </Grid>
        {loading ? (
          <Grid item xs={12} container justifyContent="center">
            <Grid item paddingTop="5%">
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} container spacing={4}>
              {activities.length > 0 ? (
                <>
                  {activities.map((d) =>
                    d !== null ? (
                      <Grid item key={d.Id}>
                        <ActivityCard data={d} />
                      </Grid>
                    ) : (
                      ""
                    )
                  )}
                </>
              ) : (
                <Grid
                  item
                  xs={4.5}
                  md={3.5}
                  lg={3}
                  xl={2.2}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={() => {
                    navigate("");
                  }}
                >
                  <ActivityCard data={data0} />
                </Grid>
              )}
            </Grid>
            {activities.length === 0 ? (
              <Grid
                item
                xs={12}
                sx={{
                  fontFamily: "Muli",
                  fontSize: 12,
                  lineHeight: 1.5,
                  textAlign: "left",
                  color: "#101650",
                  paddingBottom: "30px",
                }}
              >
                This Space is empty. Create an activity to get started.
              </Grid>
            ) : (
              <></>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}
