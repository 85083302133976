import React, { useState, useEffect, useMemo } from "react";
import "../../Assets/Styles/home.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { Box, Grid } from "@mui/material";
import SaasBack from "../../Assets/Images/SaaSbackground.png";
import classes from "../../Assets/Styles/ProductionPlanning.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import { TextField } from "@mui/material";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { styled } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Tooltip } from "@mui/material";
import * as loadingServices from '../../Services/loading-services.proxy';
import Backdrop from "@mui/material/Backdrop";
import dotsEditIcon from "../../Assets/Images/DotsEdit.png";
import { useSelector } from "react-redux";
import * as permissionFunctions from '../../../ReusableFunctions';
import useWindowDimensions from "../../../Components/Navbar/WindowDimension";

const CheckIfPermitted = permissionFunctions.CheckIfPermitted;


const getLoadingCalendar = loadingServices.getLoadingCalendar;

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
      font-family: Muli;
      font-size: 10px;
      box-sizing: border-box;
      height: 35px;
      width: 7rem;
      background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]
    };
      border-radius: 0.75em;
      margin-top: 0.7em;
      padding: 10px;
      text-align: left;
      line-height: 1.5;
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};

      &:hover {
        background: ${theme.palette.mode === "dark" ? "" : grey[100]};
        border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]
    };
      }

      &.${selectUnstyledClasses.focusVisible} {
        outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]
    };
      }

      &.${selectUnstyledClasses.expanded} {
        &::after {
          content: '▴';
        }
      }

      &::after {
        content: '▾';
        float: right;
      }
      cursor: pointer;
      `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
      font-family: "Muli";
      font-size: 0.6rem;
      box-sizing: border-box;
      padding: 5px;
      width: 8rem;
      height:130px;
      background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]
    };
      border-radius: 0.75em;
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      overflow: scroll;
      outline: 0px;

      `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
      list-style: none;
      padding: 8px;
      border-radius: 0.45em;
      cursor: pointer;
      margin-top: 2px;

      &:last-of-type {
        border-bottom: none;
      }

      &.${optionUnstyledClasses.selected} {
        background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]
    };
        color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
      }

      &.${optionUnstyledClasses.highlighted} {
        background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]
    };
        color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      }

      &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected
    } {
        background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]
    };
        color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
      }

      &.${optionUnstyledClasses.disabled} {
        color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
      }

      &:hover:not(.${optionUnstyledClasses.disabled}) {
        background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]
    };
        color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      }
      `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 7,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor:
      theme.palette.mode === 'light' ? '#101650' : '#308fe8',
  },
}));

export default function Calendar(props) {

  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );

  const navigate = useNavigate();
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const [factoryFilter, setFactoryFilter] = useState(
    query.get("fid") ? query.get("fid") : 0
  );
  const [factoriesList, setFactoriesList] = useState([]);
  const [hint, setHint] = useState(false);
  const [dateSelected, setDateSelected] = useState(new Date());

  const [loadingList, setLoadingList] = useState([]);

  const [loading, setLoading] = useState(false);

  const { width } = useWindowDimensions();

  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);
  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  useEffect(() => {
    if (dateSelected) {
      setLoading(true);
      getLoadingCalendar(
        new Date(dateSelected).toISOString().slice(0, 10),
        factoryFilter === 0 ? null : factoryFilter
      ).then((x) => {
        setFactoryFilter(x?.Factories[0]?.Id)
        setLoadingList(x.Loads);
        setFactoriesList(x.Factories)
        setLoading(false);
      });
    }
  }, [dateSelected, factoryFilter]);

  const [disabledL, setDisabledL] = useState(false);

  useEffect(() => {
    if (UserPermissions) {
      CheckIfPermitted(
        UserPermissions,
        "Loading",
        "Create",
        setDisabledL
      );
    }
  }, [UserPermissions]);

  function LinearProgressWithLabel(props) {
    return (
      <Grid item xs={12} container alignItems='center' direction='column' justifyContent='flex-start'>
        <Grid item container alignItems='center' justifyContent='space-between' >
          <Grid item sx={{ color: "#707070", fontFamily: 'Muli', fontSize: '10px', fontWeight: 'bold' }}>
            Loaded
          </Grid>
          <Grid item sx={{ color: "text.secondary", fontSize: '10px' }}>
            {`${Math.round(props.value)}%`}
          </Grid>
        </Grid>
        <Grid item sx={{ width: '100%', mr: 1 }}>
          <BorderLinearProgress variant="determinate" value={props.value ? props.value : 0} />
        </Grid>
      </Grid>
    );
  }

  const BlueTip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      componentsProps={{ tooltip: { className: className } }}
    />
  ))(`
          color: #101650;
          background-color: rgba(6, 116, 185, 0.3);
          font-size: 10px;
    `);

  return (
    <div
      style={{
        // minHeight: "90vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <Grid container padding={1} rowSpacing={2} paddingTop={2.5}>
        <Grid item xs={5} className={classes["title"]}>
          Loading Preparation
        </Grid>
        <Grid item container xs={7} direction="row-reverse">
          <Grid item xs={6} sm={4} md={1.5} >
            <CustomSelect
              value={factoryFilter}
              onChange={(option) => {
                setFactoryFilter(option);
              }}
            >
              <StyledOption value={0} disabled>
                Factory Name
              </StyledOption>
              {factoriesList?.map((f) => (
                <StyledOption key={f.Id} value={f.Id}>
                  {f.Code}
                </StyledOption>
              ))}
            </CustomSelect>
          </Grid>
          {hint ? (
            <Grid
              item
              xs={12}
              sm={3}
              className={classes["title"]}
              fontSize={15}
              paddingTop={1.5}
            >
              {disabledL ? "You don't have permission to create/update a load" : "Select Factory:"}
            </Grid>
          ) : (
            <></>
          )}
        </Grid>

        <Grid
          item
          container
          direction="row"
          rowSpacing={1}
          marginBottom={3}
          marginTop={2}
        >
          <Grid item container xs={12} md={2}>
            <Box
              sx={{
                "& > div": {
                  minWidth: 256,
                  height: width > 1300 ? 650 : 290,
                },
                "& > div > div, & > div > div > div, & .MuiCalendarPicker-root":
                {
                  width: 256,
                },
                "& .MuiTypography-caption": {
                  width: 30,
                  margin: 0,
                },
                "& .PrivatePickersSlideTransition-root": {
                  minHeight: 30 * 6,
                },
                '& .PrivatePickersSlideTransition-root [role="row"]': {
                  margin: 0,
                },
                "& .MuiPickersDay-dayWithMargin": {
                  margin: 0,
                },
                "& .MuiPickersDay-root": {
                  width: 30,
                  height: 30,
                },
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  openTo="day"
                  value={dateSelected}
                  onChange={(newValue) => {
                    setDateSelected(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size={"small"} />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
          <Grid item xs={12} md={9.9} marginLeft={1.5}>
            <Backdrop
              sx={{
                color: "#fff",
                backgroundColor: "transparent",
                zIndex: (theme) => theme.zIndex.drawer + 1,
                display: "flex",
                flexDirection: "column",
              }}
              open={loading}
            >
              <CircularProgress />

              <Grid
                item
                fontFamily={"Muli"}
                fontSize={15}
                fontWeight={"bold"}
                color={"rgb(25, 118, 210)"}
              >
                Loading Data...
              </Grid>
            </Backdrop>
            <Grid item container direction="row" xs={12}>
              <Grid item xs={12} className={classes["monthyear"]}>
                {new Date(dateSelected).toDateString()}
              </Grid>
              <Grid item container xs={12} direction="row">
                <Grid
                  item
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={(e) => {
                    let d = moment(dateSelected).subtract(7, "days");
                    d = d.format("llll");
                    setDateSelected(new Date(d));
                  }}
                >
                  <ArrowLeftIcon />
                </Grid>
                <Grid
                  item
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={(e) => {
                    let d = moment(dateSelected).add(7, "days");
                    d = d.format("llll");
                    setDateSelected(new Date(d));
                  }}
                >
                  <ArrowRightIcon />
                </Grid>
              </Grid>
              {loadingList?.map((dayList, index) => (
                <>
                  <Grid
                    item
                    container
                    direction="column"
                    key={index}
                    lg={1.7}
                    sm={2.9}
                    marginRight={0.15}
                  >
                    <Grid
                      item
                      container
                      className={classes["Day"]}
                      padding={1}
                      direction="row"
                      fontWeight="bold"
                      key={index}
                      onMouseOver={(e) => {
                        e.target.style.backgroundColor =
                          "rgba(6, 116, 185, 0.5)";
                        e.target.style.cursor = "pointer";
                      }}
                      onMouseOut={(e) => {
                        e.target.style.backgroundColor = "";
                      }}
                      backgroundColor={
                        new Date(dateSelected).toISOString().slice(0, 10) ===
                          dayList?.Date
                          ? "rgba(6, 116, 185, 0.5)"
                          : ""
                      }
                    >
                      <Grid item xs={5}>
                        {dayList?.Day}
                      </Grid>
                      <Grid item xs={7} container direction="row-reverse">
                        <button
                          className={classes["SelectOrdersButton"]}
                          onClick={() => {
                            if (disabledL === false) {
                              if (factoryFilter !== 0)
                                navigate(
                                  `/saas/salesOrderList?factoryId=${factoryFilter}&Date=${dayList?.Date}&isLoading=true`
                                );
                              else setHint(true);
                            }
                            else setHint(true);
                          }}
                        >
                          Create Load
                        </button>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      backgroundColor="#fff"
                      container
                      padding={0.8}
                      lg={1.7}
                      sm={3}
                      rowGap={1.5}
                      minHeight="565px"
                      maxHeight="565px"
                      alignContent="flex-start"
                      overflow="auto"
                    >
                      <Grid item className={classes["Date"]} height={25} paddingTop={1}>
                        {dayList?.Date}
                      </Grid>

                      {dayList?.Loads?.map((l, loadIndex) => (
                        <>
                          <Grid
                            item
                            key={l.Id}
                            container
                            backgroundColor="#e1e2ee"
                            color="#000000"
                            padding={2}
                            style={{ fontFamily: "Muli", color: "#6e6e6e" }}
                            overflow={"auto"}
                            // height="120px"
                            fontSize="10px"
                          >
                            <Grid item container justifyContent="space-between" xs={12} fontSize="16px" color="#000">
                              <Grid item
                                onMouseOver={(e) => {
                                  e.target.style.cursor = "pointer";
                                }}
                                onClick={() => {
                                  navigate(`/saas/loading/ViewResult?LoadId=${l?.Id}&LoadDock=Load ${index + 1}-${loadIndex + 1} |`);
                                }}
                              >Load: {index + 1} - {loadIndex + 1}</Grid>
                              {
                                l?.SalesOrders.find(o => o.LoadedPercentage > 0) ?
                                  <BlueTip
                                    title="Unable to edit load"
                                    placement="top"
                                  >
                                    <Grid item>
                                      <img src={dotsEditIcon} />
                                    </Grid>
                                  </BlueTip>
                                  :
                                  <Grid item
                                    onMouseOver={e => e.target.style.cursor = 'pointer'}
                                    onClick={() => {
                                      if (disabledL === false) {
                                        if (factoryFilter !== 0)
                                          navigate(`/saas/salesOrderList?factoryId=${factoryFilter}&Date=${dayList?.Date}&isLoading=true&LoadId=${l?.Id}`)
                                        else setHint(true);
                                      }
                                      else setHint(true);
                                    }}
                                  >
                                    <img src={dotsEditIcon} />
                                  </Grid>
                              }
                            </Grid>
                            <Grid item xs={12} paddingTop={1}
                              onMouseOver={(e) => {
                                e.target.style.cursor = "pointer";
                              }}
                              onClick={() => {
                                navigate(`/saas/loading/ViewResult?LoadId=${l?.Id}&LoadDock=Load ${index + 1}-${loadIndex + 1} |`);
                              }}>
                              {l?.DockName}
                            </Grid>
                            <Grid item xs={12}
                              onMouseOver={(e) => {
                                e.target.style.cursor = "pointer";
                              }}
                              onClick={() => {
                                navigate(`/saas/loading/ViewResult?LoadId=${l?.Id}&LoadDock=Load ${index + 1}-${loadIndex + 1} |`);
                              }}>
                              {l?.TrailerName}
                            </Grid>
                            <Grid item xs={12}
                              paddingTop={1}
                              container alignItems='flex-start' justifyContent='center'
                              onMouseOver={(e) => {
                                e.target.style.cursor = "pointer";
                              }}
                              onClick={() => {
                                navigate(`/saas/loading/ViewResult?LoadId=${l?.Id}&LoadDock=Load ${index + 1}-${loadIndex + 1} |`);
                              }}>
                              <Grid xs={12} item>Sales Order Codes:</Grid>
                              {l?.SalesOrders?.map(((o, index) => (
                                <Grid item xs={12} key={index} container gap={{ xs: 1, sm: 0 }} alignItems='flex-end' paddingTop={{ sm: '5%' }}>
                                  <Grid item xs={4} sm={12}>{o?.Code}</Grid>
                                  <Grid item xs={6} sm={12}>
                                    <LinearProgressWithLabel variant="determinate" value={o?.LoadedPercentage} />
                                  </Grid>
                                </Grid>
                              )))}
                            </Grid>
                            <Grid item xs={12} paddingTop={1}
                              sx={{
                                color: "#101650",
                                fontFamily: 'Muli',
                                fontSize: '10px',
                                fontWeight: 'bold'
                              }}>
                              {l?.IsVerified === true ? "Ready to ship" : ""}
                            </Grid>
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  </Grid>
                </>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
const BlueTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
          width: 168px;
          height: 52px;
          padding: 5px;
          border-radius: 6px;
          box-shadow: 0 3px 6px 0 #d36d6d;
          background-color: #fff;
          font-family: Muli;
            text-align: left;
            color: #000;

  `);
