import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect, useMemo } from "react";
import AppBar from "@mui/material/AppBar";
import logo2 from "../Assets/Images/Navbar/logo.png";
import loginIcon from "../Assets/Images/Navbar/firstIcon.png";
import hoveredLoginIcon from "../Assets/Images/Navbar/hoveredIcon.png";
import "../Assets/Styles/Navbar.css";
import useWindowDimensions from "../Components/Navbar/WindowDimension";
import PersistentDrawerRight from "../Components/Navbar/NavDrawer";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import DemoModal from "../Components/Home/DemoModal";
import { useSelector } from "react-redux";
import iotIcon from "../Assets/Images/Navbar/iotIcon.png";

export default function Navbar({
  setIsSubscribed,
  setIsVerified,
  naved,
  setNaved,
  booked,
  setBooked,
  scrolled,
  setScrolled,
  register,
  isLoggedIn,
  setIsLoggedIn,
  accounType,
  accountName,
  setAccount,
  setToken,
  isSubscribed,
  setAccountName,
  setProfileSrc,
  profileSrc,
  terms,
}) {
  const IsUserVerified = useSelector((state) => state.userData.IsUserVerified);
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [showLogOut, setShowLogOut] = useState(false);
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const RedirectURL = query.get("RedirectURL");
  const token = query.get("token");
  useEffect(() => {
    if (RedirectURL && !token) {
      navigate(`/login?RedirectURL=${RedirectURL}`);
    }
  }, [RedirectURL]);
  useEffect(() => {
    if (!isLoggedIn) {
      setProfileSrc(loginIcon);
    }
  }, [setProfileSrc, isLoggedIn]);
  if (width > 1095) {
    if (!register)
      return (
        <AppBar
          position="sticky"
          style={{ backgroundColor: "#fff", boxShadow: "none" }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container>
              <Grid item md={2.75} marginLeft="10%" marginTop="1%" height={45}>
                <img
                  src={logo2}
                  alt="Logo"
                  onClick={() => {
                    setNaved(true);
                    navigate("/");
                  }}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                />
              </Grid>
              <Grid
                item
                md={7}
                container
                direction={"row-reverse"}
                alignItems={"center"}
              >
                <img
                  onClick={() => {
                    if (!isLoggedIn) navigate("/login");
                  }}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";

                    if (isLoggedIn) {
                      setShowLogOut(true);
                    }
                  }}
                  onMouseOut={(e) => {
                    if (!isLoggedIn) {
                      setProfileSrc(loginIcon);
                    }
                    if (isLoggedIn) {
                      setTimeout(() => {
                        setShowLogOut(false);
                      }, 3000);
                    }
                  }}
                  src={profileSrc}
                  alt={"login"}
                  style={{
                    marginTop: "1%",
                    marginLeft: isLoggedIn ? IsUserVerified === true ? "0%" : "5%" : '1%',
                    borderRadius: "50px",
                    border: 0,
                    width: 48,
                    height: 48,
                  }}
                ></img>
                <Link
                  to={isLoggedIn ? "" : "/login"}
                  className={isLoggedIn ? "accountName" : "login"}
                  style={{ padding: isLoggedIn ? "1% 1% 0 0" : 0 }}
                  onMouseOver={(e) => {
                    if (!isLoggedIn) {
                      e.target.style.fontWeight = "bolder";
                    }
                  }}
                  onMouseLeave={(e) => {
                    if (!isLoggedIn) {
                      e.target.style.fontWeight = "normal";
                    }
                  }}
                >
                  {isLoggedIn ? accountName : "Login"}
                </Link>
                {isLoggedIn ? (
                  <></>
                ) : (
                  <>
                    <div className="verLine" style={{ marginTop: "1%" }}></div>
                    <a
                      className="email"
                      href={process.env.REACT_APP_INFO}
                    >
                      info@pilecubes.com
                    </a>
                  </>
                )}
              </Grid>
              {showLogOut ? (
                <Grid item md={0.2} color={"black"} marginTop={2.1}>
                  <span
                    className="Logout"
                    onClick={() => {
                      localStorage.clear();
                      setAccountName("");
                      setIsVerified("");
                      setIsSubscribed("");
                      setToken("");
                      setAccount();
                      navigate("/");
                      setIsLoggedIn(false);
                    }}
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                  >
                    Logout
                  </span>
                </Grid>
              ) : (
                ""
              )}
              <Grid item md={12} container direction={"row"} height={50}>
                <Grid
                  item
                  md={6}
                  container
                  style={{ marginLeft: "15%" }}
                  height={50}
                >
                  <ul className="list-container">
                    <li>
                      <NavLink
                        activeclassname="active"
                        to="/WhyPilecubes"
                        style={{ paddingBottom: "5.5%" }}
                      >
                        Why Pilecubes?
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        activeclassname="active"
                        to="/contactUs"
                        style={{ paddingBottom: "6.5%" }}
                      >
                        Contact Us
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        activeclassname="active"
                        to="/pricing"
                        style={{ paddingBottom: "8%" }}
                      >
                        Pricing
                      </NavLink>
                    </li>
                  </ul>
                </Grid>
                <Grid item md={1.8}></Grid>
                <Grid item md={0.3} marginRight={1}
                  className="iot"
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer"
                  }}
                  onClick={() => navigate('/iotLogin')}
                > <img src={iotIcon} width="40px" height="40px" /></Grid>
                <Grid
                  item
                  lg={2}
                  md={2}
                  className="demobutton"
                // style={{ marginLeft: "9.5%" }}
                >
                  <DemoModal setNaved={setNaved} />
                </Grid>
              </Grid>
            </Grid>
            {naved ? (
              <div
                style={{
                  height: 1,
                  backgroundColor: "#101650",
                  border: "solid 1px #101650",
                  boxShadow: "0 9px 12px 0 rgba(0,0,0,0.16)",
                }}
              ></div>
            ) : terms ? (
              <Grid
                sx={{
                  height: 1,
                  margin: "11.5px 0 0",
                  boxShadow: "0 9px 12px 0 rgba(0, 0, 0, 0.16)",
                  border: "solid 1px #e1e319",
                  backgroundColor: "#e1e319",
                }}
              ></Grid>
            ) : (
              <></>
            )}
          </Box>
        </AppBar>
      );
    else return <></>;
  } else {
    if (!register)
      return (
        <div style={{ marginBottom: "5%" }}>
          <PersistentDrawerRight
            setIsSubscribed={setIsSubscribed}
            setIsVerified={setIsVerified}
            booked={booked}
            setBooked={setBooked}
            scrolled={scrolled}
            setScrolled={setScrolled}
            isLoggedIn={isLoggedIn}
            setIsLoggedIn={setIsLoggedIn}
            accounType={accounType}
            accountName={accountName}
            setToken={setToken}
            profileSrc={profileSrc}
            setProfileSrc={setProfileSrc}
          />
        </div>
      );
    else return <></>;
  }
}
