import * as constFile from "../pileCubesConst";
import axios from "axios";
const LOG_IN = constFile.LOG_IN;

export const logIn = (
  email,
  password,
  inviterTenantId,
  projectId,
  subProjectId,
  setLoading
) => {
  return axios
    .post(`${process.env.REACT_APP_URL + LOG_IN}`, {
      Email: email,
      Password: password,
      InviterTenantId: inviterTenantId,
      ProjectId: projectId,
      SubProjectId: subProjectId,
    })
    .then((response) => {
      setLoading(false);
      return response;
    })
    .catch((err) => {
      setLoading(false);
      return err;
    });
};
