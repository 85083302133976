import { Grid, Modal, Box, Select, MenuItem } from "@mui/material";
import React, { useEffect, useState, useRef, useCallback } from "react";
import classes from "../../../Assets/Styles/Sales-Order.module.css";

import {
  Excalidraw,
  exportToCanvas,
  exportToSvg,
  exportToBlob,
} from "@excalidraw/excalidraw";
import * as salesOrderServices from "../../../Services/sales-order-services.proxy";

const uploadFile = salesOrderServices.uploadFile;
const deleteFile = salesOrderServices.deleteFile;
const resolvablePromise = () => {
  let resolve;
  let reject;
  const promise = new Promise((_resolve, _reject) => {
    resolve = _resolve;
    reject = _reject;
  });
  promise.resolve = resolve;
  promise.reject = reject;
  return promise;
};
function ExcalidrawModal(props) {
  const [open, setOpen] = React.useState(false);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const handleOpen = () => {
    setOpen(true);
    props.setOpenModal(true);
  };

  const handleClose = () => {
    props.setOpenModal(false);
    props.setLineToAddproductOn(null);
    setOpen(false);
  };

  useEffect(() => {
    if (props.openModal) {
      handleOpen(true);
    }
  }, [props.openModal]);

  const sceneDataNew = {
    elements: props.lineToAddproductOn?.elements,
    appState: {
      viewBackgroundColor: "#ffffff",
      isLibraryMenuDocked: true,
      isLibraryOpen: true,
      activeTool: {
        customType: null,
        lastActiveToolBeforeEraser: null,
        locked: false,
        type: "line",
        currentChartType: "bar",
        currentItemBackgroundColor: "transparent",
        currentItemEndArrowhead: "arrow",
        currentItemFillStyle: "hachure",
        currentItemFontFamily: 1,
        currentItemFontSize: 20,
        currentItemLinearStrokeSharpness: "round",
        currentItemOpacity: 100,
        currentItemRoughness: 1,
        currentItemStartArrowhead: null,
        currentItemStrokeColor: "#000000",
        currentItemStrokeSharpness: "sharp",
        currentItemStrokeStyle: "solid",
        currentItemStrokeWidth: 1,
      },
    },
    files: props.lineToAddproductOn?.files,
    scrollToContent: true,

    libraryItems: props.lineToAddproductOn?.libraryItems,
  };
  const excalidrawRef = useRef(null);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    bottom: "30%",
    transform: "translate(-50%, -50%)",
    height: "75%",
    width: "75%",
    borderRadius: "15px",
    boxShadow: " 0 3px 6px 0 rgba(0, 0, 0, 0.46)",
    border: "solid 1px #0674b9",
    backgroundColor: "#fff",
  };
  const [viewModeEnabled, setViewModeEnabled] = useState(false);
  const [zenModeEnabled, setZenModeEnabled] = useState(false);
  const [gridModeEnabled, setGridModeEnabled] = useState(true);
  const [theme, setTheme] = useState("light");

  const initialStatePromiseRef = useRef({ promise: null });
  if (!initialStatePromiseRef.current.promise) {
    initialStatePromiseRef.current.promise = resolvablePromise();
  }
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch("/rocket.jpeg");
      const imageData = await res.blob();
      const reader = new FileReader();
      reader.readAsDataURL(imageData);

      reader.onload = function () {
        initialStatePromiseRef.current.promise.resolve(sceneDataNew);
      };
    };
    fetchData();

    const onHashChange = () => {
      const hash = new URLSearchParams(window.location.hash.slice(1));

      const libraryUrl = hash.get("addLibrary");
      if (libraryUrl) {
        excalidrawRef.current.importLibrary(libraryUrl, hash.get("token"));
      }
    };
    window.addEventListener("hashchange", onHashChange, false);
    return () => {
      window.removeEventListener("hashchange", onHashChange);
    };
  }, []);

  const onLinkOpen = useCallback((element, event) => {
    const link = element.link;
    const { nativeEvent } = event.detail;
    const isNewTab = nativeEvent.ctrlKey || nativeEvent.metaKey;
    const isNewWindow = nativeEvent.shiftKey;
    const isInternalLink =
      link.startsWith("/") || link?.includes(window.location.origin);
    if (isInternalLink && !isNewTab && !isNewWindow) {
      // signal that we're handling the redirect ourselves
      event.preventDefault();
      // do a custom redirect, such as passing to react-router
      // ...
    }
  }, []);

  useEffect(() => {
    if (excalidrawRef.current) {
      excalidrawRef.current.updateScene(sceneDataNew);
    }
  }, [excalidrawRef]);

  const Sync = async () => {
    console.log("hii");
    props.lineToChange.Shape.DataScene = JSON.stringify({
      elements: excalidrawRef.current.getSceneElements(),
      appState: excalidrawRef.current.appState,
      files: excalidrawRef.current.getFiles(),
      scrollToContent: true,

      libraryItems: [],
    });
    const blob = await exportToBlob({
      elements: excalidrawRef.current.getSceneElements(),
      mimeType: "image/png",
      appState: excalidrawRef.current.appState,
      files: excalidrawRef.current.getFiles(),
    });
    console.log(props.chosenId, props.chosenMemberId);
    props.members.map((mem) => {
      mem.Lines.map((line) => {
        if (
          line.OrderId === props.chosenId &&
          mem.OrderId === props.chosenMemberId
        ) {
          if (line.FileId) {
            deleteFile(line.FileId, props.projectId);
          }

          uploadFile(blob, props.projectId).then((x) => {
            line.FileId = x.FileId;
            line.ShapeBlob = x.URL;

            forceUpdate();
            handleClose();
          });
        }
      });
    });
  };
  return (
    <Modal
      disableScrollLock
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} paddingBottom={10}>
        <Grid
          container
          padding={3}
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            sx={{
              fontFamily: "Muli",
              fontSize: "18px",
              color: "#101650",
              fontWeight: "bold",
              alignContent: "center",
            }}
          >
            Add Product
          </Grid>
          <div
            className="excalidraw-wrapper"
            style={{ backgroundColor: "rgb(252 250 250)" }}
          >
            <Excalidraw
              ref={excalidrawRef}
              initialData={initialStatePromiseRef.current.promise}
              onChange={(elements, state, files) => {
                // console.log(excalidrawRef.current.getSceneElements());
                // props.LineToChange.Shape.DataScene.elements = JSON.stringify(
                //   excalidrawRef.current.getSceneElements()
                // );
                // props.setDataElements(excalidrawRef.current.getSceneElements());
                // props.setDataFiles(excalidrawRef.current.getFiles());
              }}
              onLibraryChange={(libraryItems) => {
                // console.log(libraryItems);
              }}
              viewModeEnabled={viewModeEnabled}
              zenModeEnabled={zenModeEnabled}
              gridModeEnabled={gridModeEnabled}
              theme={theme}
              name="Shape"
              UIOptions={{
                canvasActions: { loadScene: false, export: false },
              }}
              onLinkOpen={onLinkOpen}
            />
          </div>
          <Grid item container xs={12} direction={"row"}>
            <Grid item xs={6} md={4}>
              <Grid
                item
                xs={12}
                sx={{ fontFamily: "Muli", color: "#707070", color: "#101650" }}
              >
                Select the Products you added on the shape
              </Grid>
              <Select
                MenuProps={{ disableScrollLock: true }}
                value={props.lineToChange?.Products}
                fullWidth
                sx={{ height: "45px" }}
                multiple
                onChange={(e) => {
                  props.lineToChange.Products = e.target.value;
                  forceUpdate();
                }}
              >
                <MenuItem value={0} key={0} disabled>
                  Added Products
                </MenuItem>
                {props.products?.map((r) => (
                  <MenuItem
                    value={r.Id}
                    key={r.Id}
                    style={{ display: "block" }}
                  >
                    {r.Name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid
              item
              container
              xs={6}
              md={8}
              direction={"row-reverse"}
              paddingRight={2.5}
              paddingTop={2.5}
            >
              <button
                disabled={
                  props.lineToChange?.Products?.length === 0 ||
                  !props.lineToChange?.Products
                    ? true
                    : false
                }
                className={classes["Place-Order"]}
                onClick={() => {
                  Sync();
                }}
              >
                SyncChanges
              </button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default ExcalidrawModal;
