import React, { useEffect } from "react";
import ProductionLabelTypes from "./ProductionLabelTypes";

const ProductionLabelView = (props) => {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  return <ProductionLabelTypes />;
};

export default ProductionLabelView;
