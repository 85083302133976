import { MenuItem, TextField, Grid } from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../Assets/Styles/home.css";
import classes from "../../../Assets/Styles/Activities.module.css";
import classing from "../../Admin/Document/DocumentModal.module.css";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import remove from "../../../Assets/Images/Subprojects/remove.png";
import * as RoutesServices from "../../../Services/routes-services.proxy";
import { useSelector } from "react-redux";
import * as permissionFunctions from "../../../../ReusableFunctions";

const CheckIfPermitted = permissionFunctions.CheckIfPermitted;
const getInputData = RoutesServices.getInputData;
const createRoutes = RoutesServices.createRoutes;
const updateRoutes = RoutesServices.updateRoutes;
const getMachinesInputData = RoutesServices.getMachinesInputData;
const createMachineRoutes = RoutesServices.createMachineRoutes;
const updateMachineRoutes = RoutesServices.updateMachineRoutes;

const styles = {
  label: {
    fontFamily: "Muli",
    fontSize: 9,
    lineHeight: 1.56,
    textAlign: "left",
    color: "#bcbcbc",
  },
  values: {
    fontSize: "12px",
    fontFamily: "Muli",
  },
};

export default function Factory(props) {
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const ID = query.get("id");
  const Number = query.get("number");

  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [machineLoading, setMachineLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [machineIsDisabled, setMachineIsDisabled] = useState(true);

  const [routeType, setRouteType] = useState(0);
  const [errorRouteType, setErrorRouteType] = useState("");
  const [name, setName] = useState("");
  const [errorName, setErrorName] = useState(false);
  const [code, setCode] = useState("");
  const [errorCode, setErrorCode] = useState(false);
  const [defaultRoute, setDefaultRoute] = useState(false);
  const [routeTypes, setRouteTypes] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [route, setRoute] = useState(null);
  const [routeId, setRouteId] = useState(null);

  const [machines, setMachines] = useState([]);
  const [machine, setMachine] = useState(null);
  const [machinesList, setmachinesList] = useState([]);
  const [machineCategories, setMachineCategories] = useState([]);

  const [machinesListId, setMachinesListId] = useState(0);
  const [machineCategory, setMachineCategory] = useState(0);
  const [order, setOrder] = useState("");
  const [machinesListError, setMachinesListError] = useState(false);
  const [machineCategoryError, setMachineCategoryError] = useState(false);
  const [orderError, setOrderError] = useState(false);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [modify, setModify] = useState(false);
  const [machineModify, setMachineModify] = useState(false);
  const [add, setAdd] = useState(false);
  const [addMachine, setAddMachine] = useState(false);
  const [addBtn, setAddBtn] = useState(false);
  const [addMachineBtn, setAddMachineBtn] = useState(false);
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  const [disabledCreate, setDisabledCreate] = useState(false);
  const [disabledEdit, setDisabledEdit] = useState(false);
  useEffect(() => {
    if (UserPermissions) {
      CheckIfPermitted(UserPermissions, "Route", "Create", setDisabledCreate);
      CheckIfPermitted(UserPermissions, "Route", "Edit", setDisabledEdit);
    }
  }, [UserPermissions]);

  useEffect(() => {
    let apiTest = true;
    getInputData(ID).then((x) => {
      if (apiTest) {
        setRouteTypes(x.RouteTypes);
        setRoutes(x.Routes);
        if (x.Routes.length === 0) {
          setAdd(true);
        }
      }
    });
    return () => {
      apiTest = false;
    };
  }, [modify]);

  useEffect(() => {
    let apiTest = true;
    if (routeId) {
      getMachinesInputData(routeId, routeType, ID).then((x) => {
        if (apiTest) {
          setMachines(x.RouteMachines);
          setMachineCategories(x.MachineCategories);
          if (machineCategory) {
            // console.log(x.MachineCategories((c) => c.Id === machineCategory));
            setmachinesList(
              x.MachineCategories((c) => c.Id === machineCategory)?.Machines
            );
          }
          if (x.RouteMachines?.length === 0) {
            setAddMachine(true);
          }
        }
      });
    }
    return () => {
      apiTest = false;
    };
  }, [routeId, machineModify]);

  useEffect(() => {
    if (errorName || errorRouteType || !name || !routeType) setIsDisabled(true);
    else setIsDisabled(false);
  });

  useEffect(() => {
    if (
      machineCategoryError ||
      machinesListError ||
      orderError ||
      !machineCategory ||
      !machinesListId ||
      !order
    )
      setMachineIsDisabled(true);
    else setMachineIsDisabled(false);
  });

  const data = {
    Code: code,
    Name: name,
    RouteTypeId: routeType,
    IsDefault: defaultRoute,
    BayId: ID,
  };

  // const addRoute = () => {
  //     setRoute(data);
  //     let temp = data;
  //     let arr = routes;
  //     arr.push(temp);
  //     setRoutes(arr);
  //     arr = [];
  //     temp = null;
  //     setCode("");
  //     setName("");
  //     setRouteType(0);
  //     setDefaultRoute(false);
  // }

  const save = () => {
    setLoading(true);
    if (data.Name && data.RouteTypeId) {
      let temp = data;
      let arr = routes;
      arr.push(temp);
      setRoutes(arr);
      if (!arr.find((obj) => obj.Id)) {
        createRoutes(arr).then(() => {
          setCode("");
          setName("");
          setRouteType(0);
          setDefaultRoute(false);
          arr = [];
          temp = null;
          setLoading(false);
          setModify(!modify);
          if (addBtn) {
            setAdd(true);
          } else {
            setAdd(false);
          }
        });
      } else {
        updateRoutes({
          Routes: arr,
          BayId: ID,
        }).then(() => {
          setCode("");
          setName("");
          setRouteType(0);
          setDefaultRoute(false);
          arr = [];
          temp = null;
          setLoading(false);
          setModify(!modify);
          if (addBtn) {
            setAdd(true);
          } else {
            setAdd(false);
          }
        });
      }
    } else {
      if (!routes.find((obj) => obj.Id)) {
        createRoutes(routes).then(() => {
          setLoading(false);
          setModify(!modify);
          if (addBtn) {
            setAdd(true);
          } else {
            setAdd(false);
          }
        });
      } else {
        updateRoutes({
          Routes: routes,
          BayId: ID,
        }).then(() => {
          setLoading(false);
          setModify(!modify);
          if (addBtn) {
            setAdd(true);
          } else {
            setAdd(false);
          }
        });
      }
    }
  };

  const deleteRoute = (item) => {
    const index = routes.indexOf(item);
    if (index > -1) {
      routes.splice(index, 1);
    }
    if (routes.length === 0) {
      setLoading(true);
      updateRoutes({
        Routes: routes,
        BayId: ID,
      }).then(() => {
        setLoading(false);
        setModify(!modify);
      });
    }
  };

  const machineData = {
    MachineId: machinesListId,
    CategoryId: machineCategory,
    Order: order,
  };

  // const addMachine = () => {
  //     setMachine(machineData);
  //     let temp = machineData;
  //     let arr = machines;
  //     arr.push(temp);
  //     setMachines(arr);
  //     arr = [];
  //     temp = null;
  //     setMachineCategory(0);
  //     setMachinesListId(0);
  //     setOrder("");
  // }

  const saveMachine = () => {
    setMachineLoading(true);
    if (machineData.CategoryId && machineData.MachineId && machineData.Order) {
      let temp = machineData;
      let arr = machines;
      arr.push(temp);
      setMachines(arr);
      if (!arr.find((obj) => obj.Id)) {
        createMachineRoutes({
          Machines: arr,
          RouteId: routeId,
        }).then(() => {
          arr = [];
          temp = null;
          setMachineLoading(false);
          setMachine(null);
          setMachines([]);
          setMachineModify(!machineModify);
          setMachineCategory(0);
          setMachinesListId(0);
          setOrder("");
          if (addMachineBtn) setAddMachine(true);
          else setAddMachine(false);
        });
      } else
        updateMachineRoutes({
          Machines: arr,
          RouteId: routeId,
        }).then(() => {
          arr = [];
          temp = null;
          setMachineLoading(false);
          setMachine(null);
          setMachines([]);
          setMachineModify(!machineModify);
          setMachineCategory(0);
          setMachinesListId(0);
          setOrder("");
          if (addMachineBtn) setAddMachine(true);
          else setAddMachine(false);
        });
    } else {
      if (!machines.find((obj) => obj.Id)) {
        createMachineRoutes({
          Machines: machines,
          RouteId: routeId,
        }).then(() => {
          setMachineLoading(false);
          setMachine(null);
          setMachines([]);
          setMachineModify(!machineModify);
          if (addMachineBtn) setAddMachine(true);
          else setAddMachine(false);
        });
      }
      updateMachineRoutes({
        Machines: machines,
        RouteId: routeId,
      }).then(() => {
        setMachineLoading(false);
        setMachine(null);
        setMachines([]);
        setMachineModify(!machineModify);
        if (addMachineBtn) setAddMachine(true);
        else setAddMachine(false);
      });
    }
  };

  const deleteMachine = (item) => {
    const index = machines.indexOf(item);
    if (index > -1) {
      machines.splice(index, 1);
    }
    if (machines?.length === 0) {
      setMachineLoading(true);
      updateMachineRoutes({
        Machines: machines,
        RouteId: routeId,
      }).then(() => {
        setMachineLoading(false);
        setMachine(null);
        setMachines([]);
        setMachineModify(!machineModify);
      });
    }
  };

  return (
    <Grid className="Rectangle779" padding="2.5% 0 0 8%">
      <Grid item xs={12} container rowSpacing={3}>
        <Grid item xs={12} container alignItems="center">
          <Grid
            item
            xs={2.5}
            sm={1.5}
            lg={1.2}
            sx={{
              fontFamily: "Muli",
              fontSize: 18,
              fontWeight: "bold",
              lineHeight: 1.44,
              textAlign: "left",
              color: "#101650",
            }}
          >
            Bay #{Number}
          </Grid>
          <Grid
            item
            xs={2}
            sm={6}
            lg={8}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>
          <Grid
            item
            xs={4.5}
            sm={2.5}
            lg={1.4}
            onMouseOver={(e) => (e.target.style.cursor = "pointer")}
            sx={{
              fontFamily: "Muli",
              fontSize: 14,
              lineHeight: 1.36,
              textAlign: "left",
              color: "#101650",
              paddingLeft: "1%",
            }}
            onClick={() => {
              navigate(`/saas/factory/routes?id=${ID}&number=${Number}`);
            }}
          >
            View All Routes
          </Grid>
          <Grid item>
            <button
              className={classes["back-button"]}
              onClick={() => {
                navigate(-1);
              }}
            >
              Go Back
            </button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} gap={2} alignItems="flex-start">
        <Grid item xs={11} md={6} container paddingTop="1%">
          <Grid item xs={12} gap={0.5} container alignItems="flex-end">
            <Grid
              item
              sx={{
                fontFamily: "Muli",
                fontSize: 18,
                textAlign: "left",
                color: "#101650",
              }}
              xs={2.5}
            >
              Route Details
            </Grid>
            <Grid
              item
              sx={{
                fontFamily: "Muli",
                fontSize: 12,
                fontWeight: "bold",
                textAlign: "left",
                color: "#0674b9",
              }}
              xs={3}
            >
              Press Plus icon to add Routes
            </Grid>
            <Grid
              item
              sx={{
                fontFamily: "Muli",
                fontSize: 12,
                fontWeight: "bold",
                textAlign: "left",
                color: "#0674b9",
              }}
              xs={6}
            >
              Add Machine Sequence upon Route selection after saving
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            gap={1.5}
            sx={{
              borderRadius: 3,
              backgroundColor: "#fff",
              padding: "2% 0 1% 0",
              marginTop: "1.5%",
            }}
          >
            {routes?.map((route, index) => (
              <Grid
                item
                key={index}
                xs={12}
                container
                gap={1}
                alignItems="flex-end"
                padding="1% 0 1% 2.5%"
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={() => {
                  // console.log(route.RouteTypeId);
                  setRouteId(route.Id);
                  setRouteType(route.RouteTypeId);
                }}
                sx={{
                  backgroundColor:
                    routeId === route.Id ? "rgba(6, 116, 185, 0.3)" : "#fff",
                }}
              >
                <Grid item xs={3}>
                  <Grid
                    item
                    sx={styles.label}
                    style={{
                      color: routeId === route.Id ? "#707070" : "#bcbcbc",
                    }}
                    paddingLeft={1}
                  >
                    *Manufacturing Route type
                  </Grid>
                  <Grid item>
                    <TextField
                      disabled={disabledEdit}
                      value={route.RouteTypeId}
                      fullWidth
                      select
                      SelectProps={{
                        MenuProps: {
                          disableScrollLock: true,

                          PaperProps: {
                            sx: {
                              maxHeight: {
                                xs: "150px",
                              },
                            },
                          },
                        },
                        style: {
                          height: "28px",
                          fontSize: 12,
                          fontFamily: "Muli",
                        },
                      }}
                      onChange={(e) => {
                        route.RouteTypeId = e.target.value;
                        forceUpdate();
                      }}
                    >
                      <MenuItem value={0} key={0} disabled sx={styles.values}>
                        Choose a Route Type
                      </MenuItem>
                      {routeTypes?.map((r, index) => (
                        <MenuItem value={r.Id} key={index} sx={styles.values}>
                          {r.Name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid
                    item
                    sx={styles.label}
                    style={{
                      color: routeId === route.Id ? "#707070" : "#bcbcbc",
                    }}
                    paddingLeft={1}
                  >
                    *Name
                  </Grid>
                  <Grid item>
                    <TextField
                      disabled={disabledEdit}
                      value={route.Name}
                      fullWidth
                      InputProps={{
                        style: {
                          height: "28px",
                          fontSize: 12,
                          fontFamily: "Muli",
                        },
                      }}
                      inputProps={{ maxLength: 50 }}
                      placeholder="Enter a route name"
                      onChange={(e) => {
                        route.Name = e.target.value;
                        forceUpdate();
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid
                    item
                    sx={styles.label}
                    style={{
                      color: routeId === route.Id ? "#707070" : "#bcbcbc",
                    }}
                    paddingLeft={1}
                  >
                    *Code
                  </Grid>
                  <Grid item>
                    <TextField
                      disabled
                      value={route.Code}
                      fullWidth
                      InputProps={{
                        style: {
                          height: "28px",
                          fontSize: 12,
                          fontFamily: "Muli",
                        },
                      }}
                      inputProps={{ maxLength: 10 }}
                      placeholder="Enter a code"
                      onChange={(e) => {
                        route.Code = e.target.value;
                        forceUpdate();
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={1.5}>
                  <Grid
                    item
                    sx={{
                      fontFamily: "Muli",
                      fontSize: 12,
                      lineHeight: 1.5,
                      textAlign: "right",
                      color: "#707070",
                    }}
                  >
                    Default route
                  </Grid>
                  <Grid item container direction="row" alignItems="center">
                    <Grid
                      item
                      xs={5}
                      container
                      direction="column"
                      alignItems="center"
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                      onClick={() => {
                        if (!disabledEdit) {
                          route.IsDefault = true;
                          forceUpdate();
                        }
                      }}
                    >
                      <Grid
                        item
                        sx={styles.label}
                        style={{
                          color: routeId === route.Id ? "#707070" : "#bcbcbc",
                        }}
                      >
                        Yes
                      </Grid>
                      <Grid
                        item
                        sx={{
                          width: 16,
                          height: 16,
                          borderRadius: 1,
                          backgroundColor: route.IsDefault ? "#e1e319" : "#fff",
                          border: route.IsDefault ? 0 : "solid 1px #707070",
                        }}
                      ></Grid>
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      container
                      direction="column"
                      alignItems="center"
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                      onClick={() => {
                        route.IsDefault = false;
                        forceUpdate();
                      }}
                    >
                      <Grid
                        item
                        sx={styles.label}
                        style={{
                          color: routeId === route.Id ? "#707070" : "#bcbcbc",
                        }}
                      >
                        No
                      </Grid>
                      <Grid
                        item
                        sx={{
                          width: 16,
                          height: 16,
                          borderRadius: 1,
                          border: route.IsDefault ? "solid 1px #707070" : 0,
                          backgroundColor: route.IsDefault ? "#fff" : "#e1e319",
                        }}
                      ></Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={0.5}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={() => {
                    if (!disabledEdit) {
                      deleteRoute(route);
                      forceUpdate();
                    }
                  }}
                >
                  <img src={remove} alt="cancel" width="50%" />
                </Grid>
              </Grid>
            ))}
            {add ? (
              <Grid
                item
                xs={12}
                container
                gap={1}
                alignItems="flex-start"
                padding="1% 0 1% 2.5%"
              >
                <Grid item xs={3}>
                  <Grid item sx={styles.label} paddingLeft={1}>
                    *Manufacturing Route type
                  </Grid>
                  <Grid item>
                    <TextField
                      value={routeType}
                      error={errorRouteType}
                      helperText={errorRouteType ? "Enter a route type" : ""}
                      fullWidth
                      select
                      SelectProps={{
                        MenuProps: {
                          disableScrollLock: true,

                          PaperProps: {
                            sx: {
                              maxHeight: {
                                xs: "150px",
                              },
                            },
                          },
                        },
                        style: {
                          height: "28px",
                          fontSize: 12,
                          fontFamily: "Muli",
                        },
                      }}
                      onBlur={(e) => {
                        if (!e.target.value) setErrorRouteType(true);
                      }}
                      onChange={(e) => {
                        setErrorRouteType(false);
                        setRouteType(e.target.value);
                      }}
                    >
                      <MenuItem value={0} key={0} disabled sx={styles.values}>
                        Route Types
                      </MenuItem>
                      {routeTypes?.map((r, index) => (
                        <MenuItem value={r.Id} key={index} sx={styles.values}>
                          {r.Name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid item sx={styles.label} paddingLeft={1}>
                    *Name
                  </Grid>
                  <Grid item>
                    <TextField
                      // disabled={}
                      value={name}
                      error={errorName}
                      helperText={errorName ? "Enter a name" : ""}
                      fullWidth
                      InputProps={{
                        style: {
                          height: "28px",
                          fontSize: 12,
                          fontFamily: "Muli",
                        },
                      }}
                      inputProps={{ maxLength: 50 }}
                      placeholder="Route Name"
                      onBlur={(e) => {
                        if (!e.target.value) setErrorName(true);
                      }}
                      onChange={(e) => {
                        if (!e.target.value) setErrorName(true);
                        else setErrorName(false);
                        setName(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid item sx={styles.label} paddingLeft={1}>
                    *Code
                  </Grid>
                  <Grid item>
                    <TextField
                      disabled
                      value={code}
                      error={errorCode}
                      helperText={errorCode ? "Enter a code" : ""}
                      fullWidth
                      InputProps={{
                        style: {
                          height: "28px",
                          fontSize: 12,
                          fontFamily: "Muli",
                        },
                      }}
                      inputProps={{ maxLength: 10 }}
                      placeholder="Code"
                      onBlur={(e) => {
                        if (!e.target.value) setErrorCode(true);
                      }}
                      onChange={(e) => {
                        if (!e.target.value) setErrorCode(true);
                        else setErrorCode(false);
                        setCode(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={1.5}>
                  <Grid
                    item
                    sx={{
                      fontFamily: "Muli",
                      fontSize: 12,
                      lineHeight: 1.5,
                      textAlign: "right",
                      color: "#707070",
                    }}
                  >
                    Default route
                  </Grid>
                  <Grid item container direction="row" alignItems="center">
                    <Grid
                      item
                      xs={5}
                      container
                      direction="column"
                      alignItems="center"
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                      onClick={() => setDefaultRoute(true)}
                    >
                      <Grid item sx={styles.label}>
                        Yes
                      </Grid>
                      <Grid
                        item
                        sx={{
                          width: 16,
                          height: 16,
                          borderRadius: 1,
                          backgroundColor: defaultRoute ? "#e1e319" : "#fff",
                          border: defaultRoute ? 0 : "solid 1px #707070",
                        }}
                      ></Grid>
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      container
                      direction="column"
                      alignItems="center"
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                      onClick={() => {
                        setDefaultRoute(false);
                      }}
                    >
                      <Grid item sx={styles.label}>
                        No
                      </Grid>
                      <Grid
                        item
                        sx={{
                          width: 16,
                          height: 16,
                          borderRadius: 1,
                          border: defaultRoute ? "solid 1px #707070" : 0,
                          backgroundColor: defaultRoute ? "#fff" : "#e1e319",
                        }}
                      ></Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
            <Grid
              xs={12}
              item
              container
              direction="row"
              alignItems={"flex-end"}
              justifyContent="space-between"
            >
              <Grid
                item
                xs={1}
                onMouseOver={(e) => {
                  if (!data.Name) e.target.style.cursor = "pointer";
                }}
                onClick={() => {
                  if (!disabledCreate) {
                    setAddBtn(true);
                    if (!data.Name) {
                      setAdd(true);
                    } else {
                      save();
                    }
                  }
                }}
                paddingLeft="2%"
              >
                <AddCircleOutlinedIcon
                  htmlColor="#e1e319"
                  sx={{
                    width: "18.9px",
                    height: "18.9px",
                  }}
                />
              </Grid>
              <Grid item xs={3.5} container>
                <Grid item paddingRight={2}>
                  <button
                    className={classing.exitModal}
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                      e.target.style.backgroundColor = "#f5f5f5";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#fff";
                    }}
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </button>
                </Grid>
                <Grid item>
                  <button
                    disabled={routes.length === 0 && isDisabled ? true : false}
                    className={classing.SaveModal}
                    onMouseOver={(e) => {
                      if (routes.length > 0 || !isDisabled)
                        e.target.style.cursor = "pointer";
                      e.target.style.backgroundColor = "#242B64";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#0b5cab";
                    }}
                    style={{
                      backgroundColor:
                        routes.length === 0 && isDisabled
                          ? "#bcbcbc"
                          : "#0b5cab",
                      fontFamily: "Muli",
                      textTransform: "none",
                      opacity: loading ? 0.7 : 1,
                    }}
                    onClick={() => {
                      setAddBtn(false);
                      save();
                    }}
                  >
                    {loading ? "Saving..." : "Save"}
                  </button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {routeType &&
        routeId &&
        routes.find((obj) => obj.Id === routeId && obj.RouteTypeId !== 1) ? (
          <Grid item xs={11} md={4} container paddingTop="1%">
            <Grid item xs={12} gap={0.5} container alignItems="flex-end">
              <Grid
                item
                sx={{
                  fontFamily: "Muli",
                  fontSize: 18,
                  textAlign: "left",
                  color: "#101650",
                }}
                xs={2.5}
              >
                Machines
              </Grid>
              <Grid
                item
                sx={{
                  fontFamily: "Muli",
                  fontSize: 12,
                  fontWeight: "bold",
                  textAlign: "left",
                  color: "#0674b9",
                }}
                xs={6}
              >
                Press Plus icon to add Machines
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              gap={1.5}
              sx={{
                borderRadius: 3,
                backgroundColor: "#fff",
                padding: "2% 0 1% 0",
                marginTop: "1.5%",
              }}
            >
              {machines?.map((machine, index) => (
                <Grid
                  item
                  key={index}
                  xs={12}
                  container
                  gap={1}
                  alignItems="flex-end"
                  padding="1% 0 1% 2.5%"
                >
                  <Grid item xs={3.7}>
                    <Grid item sx={styles.label} paddingLeft={1}>
                      *Machine Category
                    </Grid>
                    <Grid item>
                      <TextField
                        disabled={disabledEdit}
                        value={machine?.CategoryId}
                        fullWidth
                        select
                        SelectProps={{
                          MenuProps: {
                            disableScrollLock: true,

                            PaperProps: {
                              sx: {
                                maxHeight: {
                                  xs: "150px",
                                },
                              },
                            },
                          },
                          style: {
                            height: "28px",
                            fontSize: 12,
                            fontFamily: "Muli",
                          },
                        }}
                        onChange={(e) => {
                          machine.CategoryId = e.target.value;
                          setmachinesList(
                            machineCategories.find(
                              (c) => c.Id === e.target.value
                            )?.Machines
                          );
                          forceUpdate();
                        }}
                      >
                        <MenuItem value={0} key={0} disabled sx={styles.values}>
                          Choose a Category
                        </MenuItem>
                        {machineCategories?.map((c, index) => (
                          <MenuItem
                            value={c.Id}
                            key={index}
                            sx={styles.values}
                            disabled={
                              (machine.CategoryId === 4 && c.Id !== 4) ||
                              (machine.CategoryId !== 4 && c.Id === 4)
                            }
                          >
                            {c.Name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid item xs={3.7}>
                    <Grid item sx={styles.label} paddingLeft={1}>
                      *List of Machines
                    </Grid>
                    <Grid item>
                      <TextField
                        disabled={disabledEdit}
                        value={machine?.MachineId}
                        fullWidth
                        select
                        SelectProps={{
                          MenuProps: {
                            disableScrollLock: true,

                            PaperProps: {
                              sx: {
                                maxHeight: {
                                  xs: "150px",
                                },
                              },
                            },
                          },
                          style: {
                            height: "28px",
                            fontSize: 12,
                            fontFamily: "Muli",
                          },
                        }}
                        onChange={(e) => {
                          machine.MachineId = e.target.value;
                          forceUpdate();
                        }}
                      >
                        <MenuItem value={0} key={0} disabled sx={styles.values}>
                          Choose a Machine
                        </MenuItem>
                        {machineCategories
                          .find((c) => c.Id === machine.CategoryId)
                          ?.Machines?.map((l, index) => (
                            <MenuItem
                              value={l.Id}
                              key={index}
                              sx={styles.values}
                            >
                              {l.Name}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid item xs={3.3}>
                    <Grid item sx={styles.label} paddingLeft={1}>
                      *Order
                    </Grid>
                    <Grid item>
                      <TextField
                        disabled={disabledEdit}
                        value={machine?.Order}
                        fullWidth
                        InputProps={{
                          style: {
                            height: "28px",
                            fontSize: 12,
                            fontFamily: "Muli",
                          },
                        }}
                        inputProps={{ maxLength: 10 }}
                        placeholder="Entre an Order"
                        onChange={(e) => {
                          machine.Order = e.target.value;
                          forceUpdate();
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={0.5}
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                    onClick={() => {
                      if (!disabledEdit) {
                        deleteMachine(machine);
                        forceUpdate();
                      }
                    }}
                  >
                    <img src={remove} alt="cancel" width="70%" />
                  </Grid>
                </Grid>
              ))}
              {addMachine ? (
                <Grid
                  item
                  xs={12}
                  container
                  gap={0.5}
                  alignItems="flex-start"
                  padding="1% 0 1% 2.5%"
                >
                  <Grid item xs={4}>
                    <Grid item sx={styles.label} paddingLeft={1}>
                      *Machine Category
                    </Grid>
                    <Grid item>
                      <TextField
                        value={machineCategory}
                        error={machineCategoryError}
                        helperText={
                          machineCategoryError ? "Enter a category" : ""
                        }
                        fullWidth
                        select
                        SelectProps={{
                          MenuProps: {
                            disableScrollLock: true,

                            PaperProps: {
                              sx: {
                                maxHeight: {
                                  xs: "150px",
                                },
                              },
                            },
                          },
                          style: {
                            height: "28px",
                            fontSize: 12,
                            fontFamily: "Muli",
                          },
                        }}
                        onBlur={(e) => {
                          if (!e.target.value) setMachineCategoryError(true);
                        }}
                        onChange={(e) => {
                          setMachineCategoryError(false);
                          setMachineCategory(e.target.value);
                          console.log(
                            machineCategories.find(
                              (c) => c.Id === e.target.value
                            ).Machines
                          );
                          setmachinesList(
                            machineCategories.find(
                              (c) => c.Id === e.target.value
                            )?.Machines
                          );
                        }}
                      >
                        <MenuItem value={0} key={0} disabled sx={styles.values}>
                          Categories
                        </MenuItem>
                        {machineCategories?.map((c, index) => (
                          <MenuItem
                            value={c.Id}
                            key={index}
                            sx={styles.values}
                            disabled={
                              (machines.find((m) => m.CategoryId === 4) &&
                                c.Id !== 4) ||
                              (machines.find((m) => m.CategoryId !== 4) &&
                                c.Id === 4)
                            }
                          >
                            {c.Name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid item sx={styles.label} paddingLeft={1}>
                      *List of Machines
                    </Grid>
                    <Grid item>
                      <TextField
                        value={machinesListId}
                        error={machinesListError}
                        helperText={machinesListError ? "Enter a machine" : ""}
                        fullWidth
                        select
                        SelectProps={{
                          MenuProps: {
                            disableScrollLock: true,

                            PaperProps: {
                              sx: {
                                maxHeight: {
                                  xs: "150px",
                                },
                              },
                            },
                          },
                          style: {
                            height: "28px",
                            fontSize: 12,
                            fontFamily: "Muli",
                          },
                        }}
                        onBlur={(e) => {
                          if (!e.target.value) setMachinesListError(true);
                        }}
                        onChange={(e) => {
                          setMachinesListError(false);
                          setMachinesListId(e.target.value);
                        }}
                      >
                        <MenuItem value={0} key={0} disabled sx={styles.values}>
                          Machines
                        </MenuItem>
                        {machinesList?.map((l, index) => (
                          <MenuItem value={l.Id} key={index} sx={styles.values}>
                            {l.Name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid item xs={3.5}>
                    <Grid item sx={styles.label} paddingLeft={1}>
                      *Order
                    </Grid>
                    <Grid item>
                      <TextField
                        // disabled={}
                        value={order}
                        error={orderError}
                        helperText={orderError ? "Enter an order" : ""}
                        fullWidth
                        InputProps={{
                          style: {
                            height: "28px",
                            fontSize: 12,
                            fontFamily: "Muli",
                          },
                        }}
                        inputProps={{ maxLength: 10 }}
                        placeholder="Order"
                        onBlur={(e) => {
                          if (!e.target.value) setOrderError(true);
                        }}
                        onChange={(e) => {
                          if (!e.target.value) setOrderError(true);
                          else setOrderError(false);
                          setOrder(e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
              <Grid
                xs={12}
                item
                container
                direction="row"
                alignItems={"flex-end"}
                justifyContent="space-between"
              >
                <Grid
                  item
                  xs={1}
                  onMouseOver={(e) => {
                    if (!machineData.Order) e.target.style.cursor = "pointer";
                  }}
                  onClick={() => {
                    setAddMachineBtn(true);
                    if (!machineData.Order) {
                      setAddMachine(true);
                    } else {
                      saveMachine();
                    }
                  }}
                  paddingLeft="2%"
                >
                  <AddCircleOutlinedIcon
                    htmlColor="#e1e319"
                    sx={{
                      width: "18.9px",
                      height: "18.9px",
                    }}
                  />
                </Grid>
                <Grid item xs={5} container>
                  <Grid item paddingRight={2}>
                    <button
                      className={classing.exitModal}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                        e.target.style.backgroundColor = "#f5f5f5";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "#fff";
                      }}
                      onClick={() => {
                        setRouteId(null);
                        setRouteType(0);
                      }}
                    >
                      Cancel
                    </button>
                  </Grid>
                  <Grid item>
                    <button
                      disabled={
                        machines?.length === 0 && machineIsDisabled
                          ? true
                          : false
                      }
                      className={classing.SaveModal}
                      onMouseOver={(e) => {
                        if (machines?.length >= 0 || !machineIsDisabled)
                          e.target.style.cursor = "pointer";
                        e.target.style.backgroundColor = "#242B64";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "#0b5cab";
                      }}
                      style={{
                        backgroundColor:
                          machines?.length === 0 && machineIsDisabled
                            ? "#bcbcbc"
                            : "#0b5cab",
                        fontFamily: "Muli",
                        textTransform: "none",
                        opacity: machineLoading ? 0.7 : 1,
                      }}
                      onClick={() => {
                        setAddMachineBtn(false);
                        saveMachine();
                      }}
                    >
                      {machineLoading ? "Saving..." : "Save"}
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
}
