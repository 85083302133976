import React, { useEffect, useState, useMemo } from "react";
import { Grid, Modal, Box } from "@mui/material";
import classes from "../../Assets/Styles/InventoryManagement.module.css";

function HeatNbModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    bottom: "30%",
    transform: "translate(-50%, -50%)",
    height: "37%",
    width: "30%",
    borderRadius: "15px",
    boxShadow: " 0 3px 6px 0 rgba(0, 0, 0, 0.46)",
    backgroundColor: "#fff",
    
    
  };

  const [open, setOpen] = useState(false);

  
  const handleClose = () => {
    props.setHeatNbsArray([]);
 
    setOpen(false);
    props.setOpenModal(false);
  };

  useEffect(() => {
    if (props.openModal) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [props.openModal]);

  return (
    <Modal
      disableScrollLock
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} >
        <Grid
          container
          spacing={2}
          justifyContent="center"
          direction="column"
          padding={5}
          sx={{
            fontFamily: "Muli",
            fontSize: "18px",
            color: "#101650",
            fontWeight: "bold",
          }}
        >
          <Grid item>
            Mtc Doc Name: &nbsp;{" "}
            <span style={{ fontWeight: "normal" }}>
              {" "}
               {props.fileMtcDocName.length > 25?
                props.fileMtcDocName.slice(0,25)+" ...":props.fileMtcDocName}
               
            </span>
          </Grid>

          <Grid item container direction="column" gap={1.5}>
            <Grid item>Heat Numbers Values:</Grid>
            <Grid item  className={classes["RectangleWithScroll"]} height="80px" width="80%">
            {props.heatNbsArray.map((item, i) => (
              <Grid item key={i} value={item} fontWeight="normal" fontSize="15px" xs={12} marginBottom={1} paddingLeft={1}>
                {item}
              </Grid>
            ))}
            </Grid>
          </Grid>
          <Grid item container direction="row-reverse" gap={1}>
            <button className={classes["cancel-button"]} onClick={handleClose}>
              Cancel
            </button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default HeatNbModal;
