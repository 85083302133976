import * as constFile from "../../FactoryConsts";
import axios from "axios";

const INPUT_DATA = constFile.INPUT_DATA;
export const getInputData = (factoryId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(
      `${process.env.REACT_APP_URL + INPUT_DATA}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          factoryId: factoryId,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const CREATE = constFile.CREATE;
export const createfactory = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + CREATE}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const UPDATE = constFile.UPDATE;
export const updatefactory = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + UPDATE}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const FACTORY_LIST = constFile.FACTORY_LIST;
export const getFactoriesList = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + FACTORY_LIST}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      return error.response.data;
    });
};

const FACTORY_CARD_LIST = constFile.FACTORY_CARD_LIST;
export const getFactoriesCards = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + FACTORY_CARD_LIST}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const BAYS_CARD_LIST = constFile.BAYS_CARD_LIST;
export const getBaysCards = (id) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + BAYS_CARD_LIST}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        factoryId: id,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const BAYS_LIST = constFile.BAYS_LIST;
export const getBaysList = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + BAYS_LIST}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      return error.response.data;
    });
};

const MACHINES_LIST = constFile.MACHINES_LIST;
export const getMachinesList = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + MACHINES_LIST}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      return error.response.data;
    });
};

const HANDLING_LIST = constFile.HANDLING_LIST;
export const getHandlingList = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + HANDLING_LIST}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      return error.response.data;
    });
};

const STORAGE_LIST = constFile.STORAGE_LIST;
export const getStorageList = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + STORAGE_LIST}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      return error.response.data;
    });
};

const GET_PARAMETERS_INPUT_DATA = constFile.GET_PARAMETERS_INPUT_DATA;
export const getParametersInputData = (FactoryId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_PARAMETERS_INPUT_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        FactoryId: FactoryId,
      },
    })

    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      return error.response.data;
    });
};


const GET_BY_ID = constFile.GET_BY_ID;
export const getParametersById = (FactoryId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_BY_ID}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        FactoryId: FactoryId,
      },
    })

    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      return error.response.data;
    });
};

const CREATE_PARAMETERS = constFile.CREATE_PARAMETERS;
export const createParameters = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + CREATE_PARAMETERS}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      return error.response.data;
    });
};

const UPDATE_PARAMETERS = constFile.UPDATE_PARAMETERS;
export const updateParameters = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + UPDATE_PARAMETERS}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      return error.response.data;
    });
};

const CHANGE_STATUS = constFile.CHANGE_STATUS;
export const changeBlockStatus = (BlockId, StatusId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + CHANGE_STATUS}`,
      {
        BlockId: BlockId,
        StatusId: StatusId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      return error.response.data;
    });
};
const FACTORTY_LAYOUT_DATA = constFile.FACTORTY_LAYOUT_DATA;
export const getFactoryLayoutData = (Id) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + FACTORTY_LAYOUT_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        Id: Id,
      },
    })

    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      return error.response.data;
    });
};
const FACTORTY_LAYOUT_CREATE = constFile.FACTORTY_LAYOUT_CREATE;
export const createFactoryLayout = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + FACTORTY_LAYOUT_CREATE}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      return error.response.data;
    });
};
const FACTORTY_LAYOUT_UPDATE = constFile.FACTORTY_LAYOUT_UPDATE;
export const updateFactoryLayout = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + FACTORTY_LAYOUT_UPDATE}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      return error.response.data;
    });
};

const CHANGE_MACHINE_PASSWORD = constFile.CHANGE_MACHINE_PASSWORD;
export const changeMachinePassword = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + CHANGE_MACHINE_PASSWORD}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};
