import { Grid } from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import homeBg from "../../Assets/Images/Dashboard/logInBgColor.png";
import previousArrow from "../../Assets/Images/Dashboard/back1.png";
import classes from "../../Assets/Styles/Dashboard/AssigendOrders.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CircularProgress } from "@mui/material";
import DashNavbar from "./DashNavBar";
import * as orderServices from "../../Services/machine-dashboard-services.proxy";
import ErrorModal from "../../SharedComponents/ErrorModal";

const getOrdersDataByGroupId = orderServices.getOrdersDataByGroupId;

const rowStyle = {
  backgroundColor: "white",
  border: "solid 1px #e1e319",
  fontFamily: "Muli",
  fontSize: 15,
  color: "#101650",
  padding: "5px",
};

const tableHeadStyle = {
  fontSize: "13px",
  fontWeight: "bold",
  lineHeight: "1",
  textAlign: "center",
  color: "#000",
  padding: "10px",
};

const tableHeadStyle2 = {
  fontSize: "15px",
  fontWeight: "normal",
  lineHeight: "0.7",
  textAlign: "start",
  color: "#000",
  padding: "10px 0px 10px 0px",
  border: "solid 1px #e3e4a7",
};

const dotsStyle = {
  fontFamily: "Muli",
  fontSize: 28,
  fontWeight: "bold",
  lineHeight: "1.25",
  color: "#101650",
  backgroundColor: "white",
  border: "solid 1px #e1e319",
  padding: "7px",
};

const numListStyle = {
  backgroundColor: "white",
  fontFamily: "Muli",
  fontSize: 20,
  fontWeight: "bold",
  lineHeight: "1.27",
  color: "#101650",
  border: "solid 1px #e1e319",
  padding: "9px",
};

export default function AssignedOrder() {
  const navigate = useNavigate();
  const [disableOnHold, setDisableOnHold] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  Array.prototype.sum = function (prop) {
    var total = 0;
    for (var i = 0, _len = this.length; i < _len; i++) {
      total += +this[i][prop];
    }
    return total;
  };
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const groupId = query.get("groupId");

  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formerDiameter, setFormerDiameter] = useState(null);

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [BEerror, setBEerror] = useState(null);
  const [navigateToOnClose, setNavigateToOnClose] = useState("/machine/dashboard");



  useEffect(() => {
    if (groupId) {
      setLoading(true);
      getOrdersDataByGroupId(groupId, localStorage.getItem("machineId")).then((x) => {
        setLoading(false);
        if (x.status || x.statusCode) {
          if (x.status) setBEerror(x.error)
          if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
          setOpenErrorModal(true);
        } else {
          if (x[0].FormerDiameter) setFormerDiameter(x[0].FormerDiameter);
          setOrders(x);
        }
      }
      );
    }
  }, []);

  return (
    <>
      <Grid
        sx={{
          padding: "2% 3.5% 0 3.5%",
          backgroundImage: `url(${homeBg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          paddingBottom: "10%",
          minHeight: "100vh",
        }}
      >
        <DashNavbar
          disableOnHold={disableOnHold}
          setDisableOnHold={setDisableOnHold}
        />
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justifyContent="center"
          paddingTop="2.5%"
        >
          <Grid item xs={12} paddingLeft="2%">
            <img
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(-1);
              }}
              src={previousArrow}
              alt="previous page"
            />
          </Grid>
          {formerDiameter ? (
            <Grid
              item
              xs={12}
              container
              alignItems="center"
              justifyContent="center"
            >
              <Grid item className={classes["Production-Order"]}>
                Former Diameter :&nbsp;{formerDiameter}mm
              </Grid>
            </Grid>
          ) : (
            <></>
          )}

          <Grid
            item
            container
            xs={12}
            gap={2}
            alignItems="center"
            justifyContent="center"
            paddingTop={{ xs: "2.5%", md: "1.5%" }}
          >
            {!loading ? (
              orders?.map((order, index) => (
                <Grid item container key={index} justifyContent="flex-start">
                  <Grid
                    item
                    xs={12}
                    paddingBottom="0.5%"
                    className={classes["Production-Order"]}
                  >
                    Production Order
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    paddingBottom="0.5%"
                    className={classes["PRO-DEMO---0001"]}
                  >
                    {order?.Code}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    style={{ backgroundColor: "transparent" }}
                  >
                    <TableContainer
                      sx={{
                        "&::-webkit-scrollbar": {
                          marginLeft: 10,
                          width: 6,
                        },
                        "&::-webkit-scrollbar-track": {
                          backgroundColor: "transparent",
                          boxShadow: "0 0",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "rgba(225, 227, 25, 0.5)",
                          borderRadius: 21,
                          boxShadow: "0 0",
                        },
                      }}
                      style={{
                        borderRadius: 0,
                        maxHeight: "550px",
                        boxShadow: "0 0",
                      }}
                      component={Paper}
                    >
                      <Table aria-label="simple table">
                        {order?.Members?.map((member, memberIndex) => (
                          <TableBody key={memberIndex}>
                            <TableRow
                              style={{
                                backgroundColor: "#e3e4a7",
                                boxShadow: "0 0",
                                outline: "none",
                                border: 0,
                              }}
                            >
                              <TableCell sx={tableHeadStyle2}></TableCell>
                              <TableCell colSpan={4} sx={tableHeadStyle2}>
                                Percentage Of Accomplishment:{" "}
                                {(
                                  (member?.Lines.filter(
                                    (l) => l.StatusId === 3
                                  ).sum("Weight") /
                                    member?.Lines.sum("Weight")) *
                                  100
                                ).toFixed(2)}
                                %
                              </TableCell>

                              <TableCell colSpan={8} sx={tableHeadStyle2}>
                                Total Weight:{" "}
                                {member?.Lines.sum("Weight").toFixed(2)} Kgs
                              </TableCell>
                            </TableRow>
                            <TableRow
                              style={{
                                backgroundColor: "rgba(225, 227, 25, 0.5)",
                                boxShadow: "0 0",
                                outline: "none",
                                border: "solid 1.1px #e1e319",
                                borderTop: 0,
                              }}
                            >
                              <TableCell align="center" sx={{ fontSize: "20px" }}>
                                #
                              </TableCell>

                              <TableCell
                                align="center"
                                style={{ minWidth: "90px" }}
                                sx={tableHeadStyle}
                              >
                                Order Code
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ minWidth: "90px" }}
                                sx={tableHeadStyle}
                              >
                                MEMBER <br></br> QTY
                              </TableCell>
                              <TableCell align="center" sx={tableHeadStyle}>
                                BAR <br></br> Mark <br></br> NO.
                              </TableCell>

                              <TableCell
                                align="center"
                                style={{ minWidth: "60px" }}
                                sx={tableHeadStyle}
                              >
                                TOTAL<br></br>
                                NO.<br></br>
                                OF BARS
                              </TableCell>

                              <TableCell align="center" sx={tableHeadStyle}>
                                BAR <br></br> ϕ
                              </TableCell>

                              <TableCell align="center" sx={tableHeadStyle}>
                                SHAPE
                              </TableCell>

                              <TableCell align="center" sx={tableHeadStyle}>
                                BAR <br></br> LENGTH
                              </TableCell>

                              <TableCell align="center" sx={tableHeadStyle}>
                                WEIGHT <br></br> KGS.
                              </TableCell>

                              <TableCell align="center" sx={tableHeadStyle}>
                                STATUS
                              </TableCell>

                              <TableCell
                                align="center"
                                sx={tableHeadStyle}
                              ></TableCell>
                            </TableRow>
                            {member?.Lines?.map((row, lineIndex) => (
                              <TableRow key={lineIndex}>
                                <TableCell align="center" style={numListStyle}>
                                  {lineIndex + 1}
                                </TableCell>
                                <TableCell align="center" style={rowStyle}>
                                  {order?.Code}
                                </TableCell>
                                <TableCell align="center" style={rowStyle}>
                                  {row?.MemberName} <br></br>
                                  {row?.MemberQuantity}
                                </TableCell>
                                <TableCell align="center" style={rowStyle}>
                                  {row?.BarMarkNumber}
                                </TableCell>
                                <TableCell align="center" style={rowStyle}>
                                  {row?.TotalNumberOfBars}
                                </TableCell>
                                <TableCell align="center" style={rowStyle}>
                                  {row?.BarDiameter}
                                </TableCell>
                                <TableCell align="center" style={rowStyle}>
                                  <img
                                    width="250px"
                                    height="75px"
                                    src={row?.ShapeURL}
                                    alt="shape"
                                  />{" "}
                                </TableCell>
                                <TableCell align="center" style={rowStyle}>
                                  {row?.BarLength}
                                </TableCell>
                                <TableCell align="center" style={rowStyle}>
                                  {row?.Weight}
                                </TableCell>
                                <TableCell align="center" style={rowStyle}>
                                  {row?.Status}
                                </TableCell>
                                <TableCell align="center" style={dotsStyle}>
                                  {" "}
                                  ...{" "}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        ))}
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              ))
            ) : (
              <CircularProgress />
            )}
          </Grid>

          <Grid
            item
            container
            paddingTop="3%"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Grid item>
              <button
                disabled={orders.length === 0}
                onClick={() => {
                  navigate(`/machine/scanItem?groupId=${groupId}`);
                }}
                className={classes["next-button"]}
                style={{
                  backgroundColor: orders.length === 0 ? "lightgrey" : "#101650",
                  border: 0,
                }}
              >
                {" "}
                Next{" "}
              </button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ErrorModal openErrorModal={openErrorModal} setOpenErrorModal={setOpenErrorModal} BEerror={BEerror} navigateToOnClose={navigateToOnClose} />
    </>
  );
}
