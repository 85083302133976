import * as constFile from "../../FactoryConsts";
import axios from "axios";

const GET_HANDLING_INPUT_DATA = constFile.GET_HANDLING_INPUT_DATA;

export const getHandlingBlockInputData = (BayId ,blockId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_HANDLING_INPUT_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        BayId: BayId,
        BlockId: blockId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const CREATE_HANDLING = constFile.CREATE_HANDLING;

export const createHandling = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + CREATE_HANDLING}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const UPDATE_HANDLING = constFile.UPDATE_HANDLING;

export const updateHandling = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + UPDATE_HANDLING}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
