import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import checkMain from "../../../Assets/Images/Pricing/checkActive.png";
import * as data from "../../../Services/pricing-data.proxy.js";

const PRICING_PLANS = data.getPricingPlans;

export default function BasicTable({
  setLoading,
  subscriptionTypeId,
  setSubscriptionTypeId,
  billingId,
  setBillingId,
}) {
  const [plans, setPlans] = useState();

  useEffect(() => {
    //setLoading(true);
    fetchProducts(subscriptionTypeId, billingId);
  }, [billingId, subscriptionTypeId]);

  const fetchProducts = (SubscriptionTypeId, BillingId) => {
    //setLoading(true);
    PRICING_PLANS(SubscriptionTypeId, BillingId, setLoading).then((x) =>
      setPlans(x)
    );
  };

  return (
    <>
      {plans ? (
        <TableContainer
          sx={{ maxWidth: "80%" }}
          style={{ marginLeft: "10%", marginTop: 20 }}
        >
          <Table aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontFamily: "Muli",
                    fontSize: 22,
                    fontWeight: "bold",
                    lineHeight: 1.18,
                    textAlign: "left",
                    color: "#101650",
                  }}
                >
                  Compare features
                </TableCell>

                {plans.Products.map((product) => (
                  <TableCell key={product.PriceId}>
                    <Grid
                      container
                      direction={"column"}
                      fontFamily="Muli"
                      lineHeight={1.44}
                      textAlign="center"
                      color="#101650"
                      justifyContent={"center"}
                    >
                      <Grid item fontSize={18} fontWeight="bold">
                        {product.Name}
                      </Grid>
                      <Grid item fontSize={product.Name === "Pro +"? 18:25} fontWeight={300}>
                        {product.Name === "Pro +"
                          ? "Custom Pricing"
                          : product.Price + " " + product.CurrencySymbol}
                      </Grid>
                      <Grid item fontSize={12}>
                        / {product.BillingPlan}
                      </Grid>
                      <Grid item padding={1}></Grid>
                      <Grid item fontSize={12}>
                        {product.Type}
                      </Grid>
                    </Grid>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {plans.FeatureGroups.map((fg) => (
                <>
                  <TableRow key={fg.Id}>
                    <TableCell
                      size="medium"
                      style={{
                        fontFamily: "Muli",
                        fontSize: 22,
                        fontWeight: "bold",
                        lineHeight: 1.18,
                        textAlign: "left",
                        color: "#101650",
                      }}
                    >
                      {fg.Name}
                    </TableCell>
                    {plans.Products.map((p) => (
                      <TableCell key={p.PriceId}></TableCell>
                    ))}
                  </TableRow>
                  {fg.Features.map((f) => (
                    <TableRow key={f.Id}>
                      <TableCell
                        style={{
                          fontFamily: "Muli",
                          fontSize: 15,
                          lineHeight: 2.07,
                          textAlign: "left",
                          color: "#101650",
                          border: 0,
                        }}
                      >
                        {f.Name}
                      </TableCell>
                      {plans.Products.map((p) => (
                        <TableCell key={p.PriceId}>
                          {p.AvailableFeatures[f.Id][0] &&
                          p.AvailableFeatures[f.Id][1] ? (
                            <div
                              style={{
                                fontFamily: "Muli",
                                fontSize: 15,
                                lineHeight: 2.07,
                                textAlign: "center",
                                color: "#101650",
                              }}
                            >
                              {p.AvailableFeatures[f.Id][1]}
                            </div>
                          ) : p.AvailableFeatures[f.Id][0] ? (
                            <img
                              src={checkMain}
                              style={{ marginLeft: 100 }}
                            ></img>
                          ) : (
                            <></>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <></>
      )}
    </>
  );
}
