import * as constFile from "../../projectConst";
import axios from "axios";
const TEAM_MEMBER_BY_SUBPROJECT = constFile.TEAM_MEMBER_BY_SUBPROJECT;

export const teamMemberBySubProject = (SubProjectId, disciplineId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + TEAM_MEMBER_BY_SUBPROJECT}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        SubProjectId: SubProjectId,
        disciplineId: disciplineId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};
const TEAM_MEMBER_GROUP_PERMISSION_LIST =
  constFile.TEAM_MEMBER_GROUP_PERMISSION_LIST;

export const teamMemberPermissionList = (Search) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + TEAM_MEMBER_GROUP_PERMISSION_LIST}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        Search: Search,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};

const GET_TEAM_MEMBER_BY_ID = constFile.GET_TEAM_MEMBER_BY_ID;

export const getTMbyId = (Id) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_TEAM_MEMBER_BY_ID}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        Id: Id,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};
const ASSIGN_TEAM_MEMBER = constFile.ASSIGN_TEAM_MEMBER;

export const assignTeamMember = (SubProjectId, DisciplineId, TeamMembers) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + ASSIGN_TEAM_MEMBER}`,
      {
        SubProjectId: SubProjectId,
        DisciplineId: DisciplineId,
        TeamMembers: TeamMembers,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => { return err.response.data; });
};
const UPDATE_ASSIGN_TEAM_MEMBER = constFile.UPDATE_ASSIGN_TEAM_MEMBER;

export const updateAssignTeamMember = (
  SubProjectId,
  DisciplineId,
  TeamMembers
) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + UPDATE_ASSIGN_TEAM_MEMBER}`,
      {
        SubProjectId: SubProjectId,
        DisciplineId: DisciplineId,
        TeamMembers: TeamMembers,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const GET_DISCIPLINES_OF_SUBPROJECT = constFile.GET_DISCIPLINES_OF_SUBPROJECT;

export const getDisciplinesOfSub = (SubProject) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_DISCIPLINES_OF_SUBPROJECT}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        SubProject: SubProject,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};
