
import React, { useState, useEffect } from "react";
import { Grid, Dialog } from "@mui/material";
import classes from "../../../Assets/Styles/Activities.module.css";
import { useNavigate } from "react-router-dom";



const InvalidAccessModal = (props) => {
    const [open, setOpen] = useState(false);

    const navigate = useNavigate();

    const handleOpen = () => {
        setOpen(true);
        props.setOpenInvalidModal(false);
    };
    useEffect(() => {
        if (props.openInvalidModal) {
            handleOpen(true);
        }
    }, [props.openInvalidModal]);



    return (

        <Dialog
            open={open}

        >

            <Grid item container xs={12} direction="column" rowGap={2} padding={7} alignItems="center">

                <Grid
                    item
                    style={{ fontFamily: "Muli", fontSize: "20px" }}
                >
                    The view of the activity details is not available.
                </Grid>
                <Grid item marginTop={1} className={classes["OkButton"]} onClick={() =>{
                if(props.from === "card") navigate("/saas/execution")
                else navigate("/saas/execution/activitiesTable")
                 }} > Ok</Grid>
            </Grid>

        </Dialog>
    );
};

export default InvalidAccessModal;
