import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import BgMain from "../../../SAAS/Assets/Images/ProfileDetails/SaaSbackground.png";
import check from "../../../Assets/Images/Pricing/CheckSubpx.png";
import Form1 from "./Form1";
import Form2 from "./Form2";
import ManuContactUsBG from "../../../Assets/Images/Pricing/ManuContactUsBg.png";
import * as pricingRequestService from "../../../Services/pricing-data.proxy";
import SuccessContact from "./SuccessContact";
const getRequestSubscriptionInputData =
  pricingRequestService.getRequestSubscriptionInputData;
const requestCustomSubscription =
  pricingRequestService.requestCustomSubscription;
const PLanCoreFeatures = [
  { Name: "Core Manufacturing Plan:", Checked: false },
  { Name: "Inventory Management", Checked: true },
  { Name: "Order Book Management", Checked: true },
  { Name: "Production Management", Checked: true },
  { Name: "Delivery Management", Checked: true },
  { Name: "Standard Collaboration", Checked: true },
];
const PLanProFeatures = [
  { Name: "Pro Manufacturing Plan:", Checked: false },
  { Name: "Everything in Core plus,", Checked: false },
  { Name: "Real Time Production Management", Checked: true },
  { Name: "Real Time Inventory Management", Checked: true },
  { Name: "Real Time Workforce Management", Checked: true },
  { Name: "Real Time Load Management", Checked: true },
  { Name: "Real Time Traceability", Checked: true },
  { Name: "Real Time Collaboration", Checked: true },
  { Name: "Standard Machine Integration", Checked: true },
  { Name: "Pro Analytics", Checked: true },
];
const PLanProPlusFeatures = [
  { Name: "Pro+ Manufacturing Plan", Checked: true },
  { Name: "Everything in Pro plus,", Checked: false },
  { Name: "Native AI Engine", Checked: true },
  { Name: "AI Powered Collaboration", Checked: true },
  { Name: "Advanced Machine Integration", Checked: true },
];
export default function ContactUsPricing() {
  const styles = {
    backgroundContainer: {
      backgroundImage: `url(${
        localStorage.getItem("SubscriptionTypeId") === "3"
          ? ManuContactUsBG
          : BgMain
      })`,
      backgroundPosition: "center",
      backgroundRepeat: "no-Repeat",
      backgroundSize: "cover",
      minHeight: "100vh",
    },

    ProfileText: {
      fontFamily: "Muli",
      fontSize: "30px",
      fontWeight: "bold",
      color: "#101650",
    },
    YouGetText: {
      fontFamily: "Muli",
      color: "#101650",
      fontSize: "13px",
    },
    YouGetTextTitle: {
      fontFamily: "Muli",
      color: "rgba(16, 22, 80, 0.5)",
      fontSize: "18px",
    },
    Pro: {
      fontFamily: "Muli",
      color: "#101650",
      fontSize: "13px",
      fontWeight: "bold",
    },
  };
  const [step, setStep] = useState(1);
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [employeesRange, setEmployeesRange] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countries, setcountries] = useState([]);
  const [employeesRanges, setemployeesRanges] = useState([]);
  const [personalName, setPersonalName] = useState("");
  const [position, setPosition] = useState("");
  const [personalEmail, setPersonalEmail] = useState("");
  const [personalPhoneNumber, setPersonalPhoneNumber] = useState("");
  const [agreeCheck, setAgreeCheck] = useState(false);
  const [nbOfFactories, setNbOfFactories] = useState(null);
  const [factoriesVolume, setFactoriesVolume] = useState(null);
  const [loggedInManu, setLoggerdInManu] = useState(false);
  const [subscriptionTypeId, setSubscriptionTypeId] = useState(
    localStorage.getItem("SubscriptionTypeId") === "1"
      ? 1
      : localStorage.getItem("SubscriptionTypeId") === "2"
      ? 2
      : 3
  );
  const IsManufacturer =
    localStorage.getItem("SubscriptionTypeId") === "3" ? true : false;
  const ProductId = +localStorage.getItem("ProductId");
  const [submitLoading,setSubmitLoading] = useState(false);


  const continues = (e) => {
    e.preventDefault();
    setStep(step + 1);
  };
  const back = (e) => {
    e.preventDefault();
    setStep(step - 1);
  };
  useEffect(() => {
    if (
      localStorage.getItem("UserId") &&
      localStorage.getItem("SubscriptionTypeId") !== "3"
    ) {
      setStep(3);
    }
  });
  useEffect(() => {
    let isApiSubscribed = true;
    getRequestSubscriptionInputData().then((x) => {
      if (isApiSubscribed) {
        setcountries(x.Countries);
        setemployeesRanges(x.EmployeeRanges);
      }
    });

    return () => {
      isApiSubscribed = false;
    };
  }, []);
  const sendContactUs = () => {
    setSubmitLoading(true);
    requestCustomSubscription(
      localStorage.getItem("UserId") ? localStorage.getItem("UserId") : null,
      companyName,
      email,
      phoneNumber,
      personalName,
      personalEmail,
      personalPhoneNumber,
      position,
      country,
      employeesRange,
      subscriptionTypeId,
      agreeCheck,
      IsManufacturer,
      factoriesVolume ? +factoriesVolume : null,
      nbOfFactories ? +nbOfFactories : null,
      ProductId !== null ? +ProductId : null
    ).then((x) => {
      setSubmitLoading(false);
      setStep(3);
    });
  };
  const [planFeatures, setPlanFeatures] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("Plan Name")) {
      if (localStorage.getItem("Plan Name") === "Core") {
        setPlanFeatures(PLanCoreFeatures);
      } else if (localStorage.getItem("Plan Name") === "Pro") {
        setPlanFeatures(PLanProFeatures);
      } else if (localStorage.getItem("Plan Name") === "Pro +") {
        setPlanFeatures(PLanProPlusFeatures);
      }
    }
  }, []);
  useEffect(() => {
    if (localStorage.getItem("Plan Name") && localStorage.getItem("UserId")) {
      setLoggerdInManu(true);
      setStep(2);
    }
  }, []);
  return (
    <>
      <div style={styles.backgroundContainer}>
        <Grid
          className="mainR"
          container
          rowGap={4}
          paddingLeft={{ xs: 1 }}
          paddingTop={13}
        >
          <Grid item container rowSpacing={{ xs: 2 }} justifyContent={"center"}>
            <Grid
              item
              md={12}
              lg={4}
              container
              direction={"column"}
              spacing={3}
              paddingLeft={{ xs: 3 }}
            >
              <Grid item sx={styles.ProfileText}>
                {localStorage.getItem("SubscriptionTypeId") === "3"
                  ? "Great; you're adding Manufacturing Plan "
                  : "Let's get in touch!"}
              </Grid>
              <Grid item sx={styles.YouGetTextTitle} width={285}>
                Pilecubes is what contractors are using to work seamlessly and
                accomplish more, together.
              </Grid>
              {localStorage.getItem("SubscriptionTypeId") === "3" ? (
                <Grid item sx={styles.YouGetText} container gap={1}>
                  {planFeatures?.map((feature, index) => (
                    <Grid item container gap={1}>
                      {feature.Checked ? (
                        <Grid item>
                          <img src={check} alt="check"></img>
                        </Grid>
                      ) : (
                        ""
                      )}
                      <Grid item> {feature.Name}</Grid>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Grid
                  item
                  sx={styles.YouGetText}
                  container
                  spacing={{ md: 3, xs: 1 }}
                >
                  <Grid item container spacing={1}>
                    <Grid item container spacing={1} direction="row">
                      <Grid item container direction="row">
                        Everything in
                        <Grid sx={styles.Pro} paddingLeft={0.2}>
                          Pro
                        </Grid>
                        , plus
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container spacing={1}>
                    <Grid item>
                      <img src={check} alt="check"></img>
                    </Grid>

                    <Grid item> Unlimited drawings</Grid>
                  </Grid>
                  <Grid item container spacing={1}>
                    <Grid item bgcolor={"transparent"}>
                      <img src={check} alt="check"></img>
                    </Grid>
                    <Grid item>Unlimited projects</Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid
              item
              md={12}
              lg={5}
              marginLeft={{ xs: 2 }}
              paddingBottom={{ xs: 12 }}
            >
              {step === 1 ? (
                <Form1
                  continues={continues}
                  companyName={companyName}
                  email={email}
                  country={country}
                  employeesRange={employeesRange}
                  phoneNumber={phoneNumber}
                  setEmail={setEmail}
                  setCompanyName={setCompanyName}
                  setCountry={setCountry}
                  setEmployeesRange={setEmployeesRange}
                  setPhoneNumber={setPhoneNumber}
                  countries={countries}
                  employeesRanges={employeesRanges}
                />
              ) : step === 2 ? (
                <Form2
                submitLoading={submitLoading}
                  back={back}
                  personalName={personalName}
                  setPersonalName={setPersonalName}
                  position={position}
                  setPosition={setPosition}
                  personalEmail={personalEmail}
                  setPersonalEmail={setPersonalEmail}
                  personalPhoneNumber={personalPhoneNumber}
                  setPersonalPhoneNumber={setPersonalPhoneNumber}
                  countries={countries}
                  agreeCheck={agreeCheck}
                  setAgreeCheck={setAgreeCheck}
                  sendContactUs={sendContactUs}
                  nbOfFactories={nbOfFactories}
                  setNbOfFactories={setNbOfFactories}
                  factoriesVolume={factoriesVolume}
                  setFactoriesVolume={setFactoriesVolume}
                  loggedInManu={loggedInManu}
                />
              ) : step === 3 ? (
                <SuccessContact />
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
