import React, { useEffect, useState } from "react";
import { Grid, Button, Modal, Box, Select } from "@mui/material";
import { MenuItem, FormControl, FormHelperText } from "@mui/material";
import classes from "../../Assets/Styles/InventorySetup.module.css";
import AssistanceIconWhite from "../../Assets/Images/Inventory/AssistanceIconWhite.png";
import AssistanceIconBlueBorder from "../../Assets/Images/Inventory/AssistanceIconBlueBorder.png";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import * as saasServices from "../../Services/saas-services.proxy";
import * as permissionFunctions from "../../../ReusableFunctions";

const CheckIfPermitted = permissionFunctions.CheckIfPermitted;

const requestSupport = saasServices.requestSupport;

function AssistanceModal(props) {


  const [open, setOpen] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [successfullyRequested, setSuccessfullyRequested] = useState(false);
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );

  const [disableR, setDisableR] = useState(false);

  useEffect(() => {
    if (UserPermissions) {
      CheckIfPermitted(
        UserPermissions,
        "Pilecubes Request Team Help",
        "Request implementation support",
        setDisableR
      );
    }
  }, [UserPermissions]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    bottom: "30%",
    transform: "translate(-50%, -50%)",
    borderRadius: "15px",
    boxShadow: " 0 3px 6px 0 rgba(0, 0, 0, 0.46)",
    border: "solid 1px #0674b9",
    backgroundColor: successfullyRequested ? "#fff" : "#0674b9",
  };


  const request = () => {
    setRequesting(true);

    requestSupport().then((x) => {
      if (x.response === 504 || x.response === 401) {
        setSuccessfullyRequested(false);
      } else {
        setSuccessfullyRequested(true);
      }
      setRequesting(false);
    });
  };
  const handleClose = () => {
    setSuccessfullyRequested(false);
    setOpen(false);
    props.setOpenModal(false);

  };

  useEffect(() => {
    if (props.openModal) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [props.openModal]);

  return (
    <Modal
      disableScrollLock
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} width={{ xs: "90%", sm: "60%", md: "38%" }} height={{ xs: "50%", sm: "40%", md: "60%" }}>
        {successfullyRequested ? (
          <Grid
            container
            spacing={2}
            padding={2}
            alignContent="center"
            direction="column"
            textAlign="center"
            fontFamily="Muli"
          >
            <Grid item container direction="row-reverse">
              <CloseIcon fontSize="large" htmlColor="#101650"
                onMouseOver={(e) => e.target.style.cursor = "pointer"}
                onClick={() => handleClose()}
              />
            </Grid>
            <Grid item>
              <img
                src={AssistanceIconBlueBorder}
                width="120px"
                height="110px "
              />
            </Grid>
            <Grid
              item
              fontSize={{ xs: "15px", md: "30px" }}
              fontWeight="bold"
              color="#0674b9"
              marginTop={3}
            >
              Thank you for reaching out
            </Grid>
            <Grid item fontSize="20px" color="#101650" >
              Pilecubes Team will respond soon <br /> to support and help out.
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            spacing={2}
            alignContent="center"
            direction="column"
            textAlign="center"
            padding={2}
          >

            <Grid item>
              <img src={AssistanceIconWhite} width="120px" height="110px " />
            </Grid>
            <Grid
              item
              className={classes["whiteTextModal"]}
              fontSize={{ xs: "15px", md: "30px" }}
              fontWeight="bold"
            >
              {" "}
              Need Help?
            </Grid>
            <Grid item className={classes["whiteTextModal"]} fontSize={{ xs: "15px", md: "20px" }}>
              No problem. We can help in the creation of your factory, adding
              blocks (machines storage blocks, handling) and adding routes and
              inventory setup.
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              gap={4}
              alignItems="center"
              marginTop={{ xs: 1, md: 3 }}
            >
              <Grid item>
                <button
                  disabled={disableR}
                  className={classes["RequestButton"]}
                  onClick={() => {
                    if (disableR === false)
                      request()
                  }
                  }
                >
                  {requesting ? "Requesting..." : "Request"}
                </button>
              </Grid>
              <Grid
                item
                className={classes["noThanks"]}
                onClick={() => handleClose()}
              >
                No thanks
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
    </Modal>
  );
}

export default AssistanceModal;
