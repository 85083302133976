import { Grid, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import logo from "../../Assets/Images/Dashboard/logo.png";
import icon from "../../Assets/Images/Dashboard/icon.png";
import { useNavigate } from "react-router-dom";
import classes from "../../Assets/Styles/factory.module.css";

import HoldMachineModal from "./HoldMachineModal";

export default function DashNavBar(props) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const machineId = localStorage.getItem("machineId");

  return (
    <Grid
      item
      xs={12}
      container
      alignItems="center"
      justifyContent="space-between"
      sx={{
        borderRadius: "15px",
        backgroundColor: " #fff",
        paddingTop: { xs: "0.5%", md: "0.1%" },
        marginTop: { xs: "1%", md: 0 },
        paddingRight: { xs: "1.5%", md: 0 },
      }}
    >
      <Grid item xs={5} container gap={4} alignItems="center">
        <Grid item xs={4} paddingLeft="5%">
          <img src={logo} alt="logo" width={110} />
        </Grid>
        <Grid
          item
          xs={6}
          md={3.2}
          sx={{
            borderBottom: "solid 4px #e1e319",
            paddingBottom: "3%",
          }}
          container
          justifyContent="center"
        >
          <Grid
            item
            marginTop="10%"
            onMouseOver={(e) => (e.target.style.cursor = "pointer")}
            onClick={() => navigate("/machine/connect")}
            sx={{
              fontFamily: "Muli",
              fontSize: 16,
              fontWeight: "bold",
              lineHeight: 1.25,
              textAlign: "left",
              color: "#080707",
            }}
          >
            Machine Dashboard
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={6}
        container
        direction="row-reverse"
        gap={3}
        alignItems="center"
      >
        <Grid
          item
          paddingRight="5%"
          onMouseOver={(e) => (e.target.style.cursor = "pointer")}
          onClick={handleClick}
        >
          <img src={icon} alt="icon" />
        </Grid>
        <Grid
          item
          sx={{
            fontFamily: "Muli",
            fontSize: 16,
            fontWeight: "bold",
            lineHeight: 1.25,
            textAlign: "left",
            color: "#101650",
          }}
        >
          {localStorage.getItem("companyName")}
        </Grid>
        <button
          disabled={props.disableOnHold}
          className={classes["SaveBut"]}
          onClick={() => {
            setOpenModal(true);
            
          }}
        >
          Hold Machine
        </button>
      </Grid>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        disableScrollLock
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: -30,
        }}
      >
        <MenuItem
          onClick={() => {
            localStorage.clear();
            localStorage.setItem("machineId", machineId);
            setTimeout(() => {
              navigate("/machine/login");
            }, 1000);
            handleClose();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
      <HoldMachineModal openModal={openModal} setOpenModal={setOpenModal} />
    </Grid>
  );
}
