import React from "react";
import classes from "../../Assets/Styles/projects.module.css";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ProjectList = (props) => {
  const navigate = useNavigate();

  return (
    <Grid container justifyContent={"center"} alignItems={"center"}>
      <Grid
        item
        xs={8}
        container
        direction={"row-reverse"}
        className={classes["ACTION"]}
      >
        ACTION
      </Grid>
      {props.projects.map((project) => (
        <Grid
          key={project.Id}
          item
          container
          xs={9}
          marginTop={3}
          className={classes["projectCard"]}
          alignItems="center"
          paddingLeft="3%"
        >
          <Grid item xs={3} container>
            <Grid item xs={12} className={classes["Project-Name"]}>
              {project.Name}
            </Grid>
            <Grid item xs={12} className={classes["Client-Name"]}>
              {project.ClientName}
            </Grid>
          </Grid>
          <Grid item xs={7.5} container></Grid>
          <Grid item xs={1.5} container>
            <button
              onMouseOver={(e) => (e.target.style.cursor = "pointer")}
              onClick={() => {
                props.setView(true);
                props.setEdit(false);
                props.setJoin(true);
                navigate(`/saas/projects/creating?id=${project.Id}`);
              }}
              className={classes["ActionButtons"]}
            >
              Join
            </button>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default ProjectList;
