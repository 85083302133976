import axios from "axios";
const CONTACT = '/contact-us';

export const postMsg = (data) => {
    return axios
        .post(`${process.env.REACT_APP_URL + CONTACT}`, data)
        .then((response) => {
            return response;
        })
        .catch((err) => {
            return err.response.data;
        });
};
