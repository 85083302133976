import { Grid } from '@mui/material';
import React, { useEffect } from 'react'
import backgroundImg from '../SAAS/Assets/Images/ProfileDetails/SaaSbackground.png';
import crash from '../Assets/Images/SystemCrash-02.png'

const style = {
    backgroundContainer: {
        backdropFilter: 'blur(30px)',
        backgroundImage: `url(${backgroundImg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100vw',
        minHeight: '100vh',
    },
}
export default function SystemCrash(props) {
    useEffect(() => {
        props.setRegister(true);
    }, [props.setRegister]);

    useEffect(() => {
        props.setNaved(false);
    }, [props.setNaved]);
    return (
        <Grid container sx={style.backgroundContainer} alignItems='center' justifyContent='center'>
            <img src={crash} alt="system crash" />
        </Grid>
    )
}
