import {
  Grid,
  TableCell,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableHead,
} from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SaasBack from "../../../../../../Assets/Images/SaaSbackground.png";
import classes from "../../../../../../Assets/Styles/factory.module.css";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import PlusRow from "../../../../../../Assets/Images/Factory/plusRow.png";
import CreateStockCountModal from "./CreateStockCountModal";
import * as stockCountServices from "../../../../../../Services/stock-count-services.proxy";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import * as permissionFunctions from "../../../../../../../ReusableFunctions";

const CheckIfPermitted = permissionFunctions.CheckIfPermitted;
const getStockCounts = stockCountServices.getStockCounts;
const createStockCount = stockCountServices.createStockCount;
const updateStockCount = stockCountServices.updateStockCount;

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 15,
  },
}))(TableRow);

const StockCount = (props) => {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const [zoneId, setZoneId] = useState(query?.get("zoneId"));
  const [categoryId, setCategoryId] = useState(query?.get("categoryId"));
  const [loadingData, setLoadingData] = useState(false);
  const [open, setOpen] = useState(false);

  const [stockCounts, setStockCounts] = useState([]);

  const [countLoading, setCountLoading] = useState(false);

  const [lineId, setLineId] = useState();
  const [expand, setExpand] = useState(false);

  const [countObject, setCountObject] = useState(null);
  const [modify, setModify] = useState(false);

  useEffect(() => {
    gettingStockCounts();
  }, [zoneId, modify]);

  const gettingStockCounts = () => {
    setLoadingData(true);
    setCountObject(null);
    getStockCounts(zoneId).then((x) => {
      setStockCounts(x);
      setLoadingData(false);
    });
  };

  const addingStockCount = (Title, Summary) => {
    setCountLoading(true);
    createStockCount(null, zoneId, Title, Summary).then((x) => {
      setCountLoading(false);
      setOpen(false);
      setModify(!modify);
      // navigate(
      //   `/saas/factory/ScanMaterial?zoneId=${zoneId}&stockCountId=${x}&categoryId=${categoryId}`
      // );
    });
  };

  const updatingStockCount = (Title, Summary) => {
    setCountLoading(true);
    updateStockCount(countObject.Id, zoneId, Title, Summary).then(() => {
      setCountLoading(false);
      setOpen(false);
      gettingStockCounts();
    });
  };
  const [disableCreate, setDisableCreate] = useState(false);
  const [disabledStockCount, setDisabledStockCount] = useState(false);
  const [disabledScanMaterial, setDisabledScanMaterial] = useState(false);


  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  useEffect(() => {
    if (UserPermissions) {
      CheckIfPermitted(
        UserPermissions,
        "Storage Block",
        "Create stock count",
        setDisableCreate
      );
      CheckIfPermitted(
        UserPermissions,
        "Storage Block",
        "View stock count results",
        setDisabledStockCount
      );
      CheckIfPermitted(
        UserPermissions,
        "Storage Block",
        "Scan material",
        setDisabledScanMaterial
      );
    }
  }, [UserPermissions]);
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
        objectFit: "cover",
        paddingBottom: 60,
      }}
    >
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "transparent",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
          flexDirection: "column",
        }}
        open={loadingData}
      >
        <CircularProgress />

        <Grid
          item
          fontFamily={"Muli"}
          fontSize={15}
          fontWeight={"bold"}
          color={"rgb(25, 118, 210)"}
        >
          Loading Data...
        </Grid>
      </Backdrop>
      <Grid item container paddingTop={3} rowGap={2}>
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          gap={1}
          alignItems="center"
        >
          <Grid
            item
            container
            direction="column"
            lg={1}
            sm={1.5}
            xs={2}
            fontSize={{ xs: "10px", sm: "16px" }}
            fontWeight="bold"
            className={classes["Titlestock"]}
            paddingTop={{ xs: 1.5, sm: 3 }}
          >
            <Grid item>Stock Count</Grid>
          </Grid>
          <Grid
            marginTop={1.5}
            item
            xs={6}
            sm={8}
            lg={7.5}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>

          <Grid item paddingTop={1}>
            <button
              className={classes["back-button"]}
              onClick={() => {
                navigate(-1);
              }}
            >
              Go Back
            </button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        overflow={"auto"}
        container
        paddingTop={2}
        justifyContent="center"
        alignItems="center"
        paddingLeft={{ xs: "2.5%", md: "5.5%" }}
        xs={11.5}
        md={12}
      >
        <TableContainer sx={{ width: 700 }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              backgroundColor: "#fff",
              borderCollapse: "separate",
              borderSpacing: "0px 0px",
              border: 0,
              padding: 5,
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell sx={styles.tableCellHeadEmpty}></TableCell>
                <TableCell sx={styles.tableCellHead}>Stock Count</TableCell>
                <TableCell sx={styles.tableCellHead2}>
                  Stock <br /> Count Summary
                </TableCell>
                <TableCell sx={styles.tableCellHead3}>
                  <Grid
                    container
                    direction={"row"}
                    justifyContent="space-between"
                  >
                    <Grid item xs={8}>
                      Creation Date
                    </Grid>
                    <Grid item>
                      <Grid
                        item
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                        }}
                        onClick={() => {
                          if (!disableCreate) setOpen(true);
                        }}
                      >
                        <img src={PlusRow} alt={"img"} />
                      </Grid>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableHead>

            {stockCounts?.map((count, index) => (
              <TableBody key={count.Id}>
                <StyledTableRow>
                  <TableCell sx={styles.tableCellHeadEmpty}>
                    {expand && lineId === index ? (
                      <Grid
                        item
                        container
                        direction="column"
                        rowGap={1}
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                        }}
                        onClick={() => {
                          setExpand(false);
                        }}
                      >
                        <Grid item>
                          <ExpandLessIcon
                            fontSize="small"
                            htmlColor="#0674b9"
                          />
                        </Grid>
                        <Grid item>
                          <button
                            className={classes["ViewMore"]}
                            onClick={() => {
                              setCountObject(stockCounts[index]);
                              setOpen(true);
                            }}
                          >
                            Edit
                          </button>
                        </Grid>
                        <Grid item>
                          <button
                            disabled={
                              count.NumberOfScannedBundle === 0
                                ? disabledScanMaterial
                                : disabledStockCount
                            }
                            className={classes["ViewMore"]}
                            onClick={() => {
                              if (count.NumberOfScannedBundle === 0) {
                                navigate(
                                  `/saas/factory/ScanMaterial?zoneId=${zoneId}&stockCountId=${count.Id}&categoryId=${categoryId}`
                                );
                              } else {
                                navigate(
                                  `/saas/factory/ScanResult?zoneId=${zoneId}&stockCountId=${count.Id}&categoryId=${categoryId}`
                                );
                              }
                            }}
                          >
                            {count.NumberOfScannedBundle === 0
                              ? "Scan Material"
                              : "View Count"}
                          </button>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid
                        item
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                        }}
                        onClick={() => {
                          setExpand(true);
                          setLineId(index);
                        }}
                      >
                        <Grid item>
                          <ExpandMoreIcon
                            fontSize="small"
                            htmlColor="#0674b9"
                          />
                        </Grid>
                      </Grid>
                    )}
                  </TableCell>

                  <TableCell sx={styles.tableCellHead}>
                    <Grid
                      item
                      onMouseOver={(e) => (e.target.style.cursor = "pointer")}
                      onClick={() => {
                        navigate(
                          `/saas/factory/ScanResult?zoneId=${zoneId}&stockCountId=${count.Id}&categoryId=${categoryId}`
                        );
                      }}
                    >
                      {count.Title}
                    </Grid>
                  </TableCell>
                  <TableCell sx={styles.tableCellHead2}>
                    {count.Summary}
                  </TableCell>
                  <TableCell sx={styles.tableCellHead3}>
                    {count.CreationDate?.slice(0, 10)}
                  </TableCell>
                </StyledTableRow>
              </TableBody>
            ))}
          </Table>
        </TableContainer>
      </Grid>
      <CreateStockCountModal
        openModal={open}
        setOpenModal={setOpen}
        addingStockCount={addingStockCount}
        countLoading={countLoading}
        countObject={countObject}
        setCountObject={setCountObject}
        updatingStockCount={updatingStockCount}
      />
    </div>
  );
};
const styles = {
  tableCellHead: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#101650",
    lineHeight: 1.17,
    textAlign: "left",
    border: 0,
    borderRight: "solid 1px #c4c1c1",
    borderBottom: "solid 1px #c4c1c1",
    width: 80,
  },
  tableCellHeadEmpty: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#101650",
    lineHeight: 1.17,
    textAlign: "left",
    border: 0,
    width: 80,
  },
  tableCellHead2: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#101650",
    lineHeight: 1.17,
    textAlign: "left",
    border: 0,
    borderBottom: "solid 1px #c4c1c1",
    borderRight: "solid 1px #c4c1c1",
    wordBreak: "break-all",
  },
  tableCellHead3: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#101650",
    lineHeight: 1.17,
    textAlign: "left",
    border: 0,
    borderBottom: "solid 1px #c4c1c1",
    wordBreak: "break-all",
  },
  tableCellRMItem: {
    backgroundColor: "rgba(225, 227, 25, 0.3)",
    fontFamily: "Muli",
    fontSize: 9,
    color: "#101650",
    lineHeight: 1.17,
    textAlign: "left",
    border: 0,
    padding: 0,
  },
  tableCellempty: {
    backgroundColor: "#fff",
    border: 0,
    height: 15,
    padding: 0,
  },

  tableCellBundleCircle: {
    width: 60,
    backgroundColor: "#fff",
    borderBottom: "solid 0.5px #e1e319",
    padding: 0,
  },
  tableCellBundleButton: {
    width: 100,
    backgroundColor: "#fff",
    borderBottom: "solid 0.5px #e1e319",
    padding: 0,
  },
  tableCellBundle: {
    backgroundColor: "#fff",
    borderBottom: "solid 0.5px #e1e319",
  },
  tablebig: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    fontWeight: "bold",
    color: "#707070",
    lineHeight: 1.17,
    textAlign: "center",
    borderRight: "solid 1px #e9e7e7",
    width: 111,
  },
  tableCellDisable: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    fontWeight: "bold",
    color: "#2da4d0",
    lineHeight: 1.17,
    textAlign: "center",
    borderRight: "solid 1px #e9e7e7",
  },
  tfInput: {
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#707070",
    lineHeight: 1.45,
    paddingLeft: 2,
    height: 5,
  },
};
export default StockCount;
