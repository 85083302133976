import React, { useEffect, useState } from "react";
import { Grid, Modal, Box, TextField } from "@mui/material";
import { MenuItem } from "@mui/material";
import classes from "../../../Assets/Styles/Transport.module.css";
import * as transportServices from "../../../Services/transport-services.proxy";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import moment from "moment";
const getTruckInputData = transportServices.getTruckInputData;
const createTruck = transportServices.createTruck;
const updateTruck = transportServices.updateTruck;
function TruckModal(props) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    props.setOpenModal(false);
    setTruckName(null);
    setTruckNameError(false);
    setTruckCode(null);
    setMake(null);
    setMakeError(false);
    setType(null);
    setTypeError(false);
    setStatus("Active");
    setLicensePlate(null);
    setLicensePlateError(false);
    setDriver(null);
    setDriverError(false);
    setIsDisabled(true);
    setTrailer(null);
    setTrailerError(false);
    setExpiryDate(null);
    setExpiryDateError(false);
    setModel(null);
    setModelError(false);
    setCompanyName(null);
    setCompanyNameError(false);
    props.setTruckId(null);
  };

  useEffect(() => {
    if (props.openModal) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [props.openModal]);

  //fiedls

  const [truckName, setTruckName] = useState(null);
  const [truckNameError, setTruckNameError] = useState(false);
  const [truckCode, setTruckCode] = useState(null);
  const [make, setMake] = useState(null);
  const [makeError, setMakeError] = useState(false);
  const [type, setType] = useState(null);
  const [typeError, setTypeError] = useState(false);
  const [model, setModel] = useState(null);
  const [modelError, setModelError] = useState(false);
  const [status, setStatus] = useState("Active");
  const [licensePlate, setLicensePlate] = useState(null);
  const [licensePlateError, setLicensePlateError] = useState(false);
  const [companyName, setCompanyName] = useState(null);
  const [companyNameError, setCompanyNameError] = useState(false);
  const [driver, setDriver] = useState(null);
  const [driverError, setDriverError] = useState(false);
  const [trailer, setTrailer] = useState(null);
  const [trailerError, setTrailerError] = useState(false);
  const [expiryDate, setExpiryDate] = useState(null);
  const [expiryDateError, setExpiryDateError] = useState(false);
  const [types, setTypes] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [trailers, setTrailers] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (
      !truckName ||
      !make ||
      !type ||
      !licensePlate ||
      !driver ||
      !trailer ||
      !expiryDate ||
      !companyName ||
      truckNameError ||
      makeError ||
      typeError ||
      licensePlateError ||
      driverError ||
      trailerError ||
      expiryDateError ||
      companyNameError ||
      modelError
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (open) {
      setLoadingData(true);
      getTruckInputData(props.truckId ? props.truckId : null).then((x) => {
        setLoadingData(false);
        setTruckCode(x.Truck.Code);
        setDrivers(x.Drivers);
        setTrailers(x.Trailers);
        setTypes(x.Types);

        if (props.truckId) {
          setTruckName(x.Truck.Name);
          setMake(x.Truck.Make);
          setType(x.Truck.TypeId);
          setStatus(x.Truck.Status);
          setLicensePlate(x.Truck.LicensePlate);
          setDriver(x.Truck.DriverId);
          setTrailer(x.Truck.TrailerId);
          setExpiryDate(
            moment(new Date(x.Truck.LicensePlateExpiryDate)).format(
              "YYYY-MM-DD"
            )
          );
          setModel(x.Truck.Model);
          setCompanyName(x.Truck.CompanyName)
        }
      });
    }
  }, [open]);
  const truckDetails = {
    Id: props.truckId ? props.truckId : null,
    Name: truckName,
    Code: truckCode,
    TypeId: type,
    Make: make,
    Model: model,
    LicensePlate: licensePlate,
    LicensePlateExpiryDate: expiryDate,
    DriverId: driver,
    TrailerId: trailer,
    CompanyName: companyName,
  };
  const save = () => {
    setLoading(true);
    if (props.truckId) {
      updateTruck(truckDetails).then((x) => {
        setLoading(false);
        handleClose();
        props.setModify(!props.modify);
      });
    } else {
      createTruck(truckDetails).then((x) => {
        setLoading(false);
        handleClose();
        props.setModify(!props.modify);
      });
    }
  };
  return (
    <Modal
      disableScrollLock
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={style}
        width={{ xs: "90%", sm: "65%", md: "50%" }}
        height={{ xs: "80%", sm: "70%", md: "65%" }}
        paddingTop={4}
        overflow={"auto"}
      >
        <Backdrop
          sx={{
            color: "#fff",
            backgroundColor: "transparent",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            display: "flex",
            flexDirection: "column",
          }}
          open={loadingData}
        >
          <CircularProgress />

          <Grid
            item
            fontFamily={"Muli"}
            fontSize={15}
            fontWeight={"bold"}
            color={"rgb(25, 118, 210)"}
          >
            Loading Data...
          </Grid>
        </Backdrop>
        <Grid item className={classes["Truck-Detail"]}>
          Truck Detail
        </Grid>
        <Grid
          marginTop={2}
          xs={12}
          container
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid item width={"90%"} className={classes["Path-4142"]}></Grid>
        </Grid>
        <Grid
          item
          container
          paddingTop={2}
          paddingLeft={8}
          paddingRight={8}
          rowGap={1.5}
        >
          <Grid item xs={7}>
            <Grid item className={classes["FieldLa"]}>
              Truck Name
            </Grid>
            <Grid item xs={9} paddingTop={0.5}>
              <TextField
                value={truckName}
                variant={"standard"}
                fullWidth
                error={truckNameError}
                helperText={truckNameError ? "Enter a Name" : ""}
                onBlur={(e) => {
                  if (!e.target.value) setTruckNameError(true);
                }}
                InputProps={{
                  style: {
                    height: "26px",
                    fontSize: 12,
                    fontFamily: "Muli",
                    backgroundColor: "#fff",
                    border: "solid 0.3px #707070",
                    paddingLeft: 10,
                  },
                }}
                onChange={(e) => {
                  setTruckName(e.target.value);
                  if (e.target.value) setTruckNameError(false);
                }}
              ></TextField>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid item className={classes["FieldLa"]}>
              Truck Code
            </Grid>
            <Grid item xs={10} paddingTop={0.5}>
              <TextField
                value={truckCode}
                variant={"standard"}
                fullWidth
                disabled
                InputProps={{
                  style: {
                    height: "26px",
                    fontSize: 12,
                    fontFamily: "Muli",
                    backgroundColor: "#fff",
                    border: "solid 0.3px #707070",
                    paddingLeft: 10,
                  },
                }}
              ></TextField>
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <Grid item className={classes["FieldLa"]}>
              Truck Type
            </Grid>
            <Grid item xs={9} paddingTop={0.5}>
              <TextField
                SelectProps={{ MenuProps: { disableScrollLock: true } }}
                value={type}
                variant={"standard"}
                fullWidth
                select
                error={typeError}
                helperText={typeError ? "Enter type" : ""}
                onBlur={(e) => {
                  if (!e.target.value) setTypeError(true);
                }}
                InputProps={{
                  style: {
                    height: "26px",
                    fontSize: 12,
                    fontFamily: "Muli",
                    backgroundColor: "#fff",
                    border: "solid 0.3px #707070",
                    paddingLeft: 10,
                  },
                }}
                onChange={(e) => {
                  setType(e.target.value);
                  if (e.target.value) setTypeError(false);
                }}
              >
                {types?.map((r) => (
                  <MenuItem key={r.Id} value={r.Id}>
                    {r.Name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid item className={classes["FieldLa"]}>
              Truck Status
            </Grid>
            <Grid item xs={10} paddingTop={0.5}>
              <TextField
                value={status}
                variant={"standard"}
                fullWidth
                disabled
                InputProps={{
                  style: {
                    height: "26px",
                    fontSize: 12,
                    fontFamily: "Muli",
                    backgroundColor: "#fff",
                    border: "solid 0.3px #707070",
                    paddingLeft: 10,
                  },
                }}
              ></TextField>
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <Grid item className={classes["FieldLa"]}>
              Make
            </Grid>
            <Grid item xs={9} paddingTop={0.5}>
              <TextField
                value={make}
                variant={"standard"}
                fullWidth
                error={makeError}
                helperText={makeError ? "Enter make" : ""}
                onBlur={(e) => {
                  if (!e.target.value) setMakeError(true);
                }}
                InputProps={{
                  style: {
                    height: "26px",
                    fontSize: 12,
                    fontFamily: "Muli",
                    backgroundColor: "#fff",
                    border: "solid 0.3px #707070",
                    paddingLeft: 10,
                  },
                }}
                onChange={(e) => {
                  setMake(e.target.value);
                  if (e.target.value) setMakeError(false);
                }}
              ></TextField>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid item className={classes["FieldLa"]}>
              Truck Model
            </Grid>
            <Grid item xs={10} paddingTop={0.5}>
              <TextField
                value={model}
                variant={"standard"}
                fullWidth
                error={modelError}
                helperText={modelError ? "Enter model" : ""}
                onBlur={(e) => {
                  if (!e.target.value) setModelError(true);
                }}
                InputProps={{
                  style: {
                    height: "26px",
                    fontSize: 12,
                    fontFamily: "Muli",
                    backgroundColor: "#fff",
                    border: "solid 0.3px #707070",
                    paddingLeft: 10,
                  },
                }}
                onChange={(e) => {
                  setModel(e.target.value);
                  if (e.target.value) setModelError(false);
                }}
              ></TextField>
            </Grid>
          </Grid>

          <Grid item xs={7}>
            <Grid item className={classes["FieldLa"]}>
              Default Driver Name
            </Grid>
            <Grid item xs={9} paddingTop={0.5}>
              <TextField
                SelectProps={{ MenuProps: { disableScrollLock: true } }}
                value={driver}
                variant={"standard"}
                fullWidth
                select
                error={driverError}
                helperText={driverError ? "Enter driver" : ""}
                onBlur={(e) => {
                  if (!e.target.value) setDriverError(true);
                }}
                InputProps={{
                  style: {
                    height: "26px",
                    fontSize: 12,
                    fontFamily: "Muli",
                    backgroundColor: "#fff",
                    border: "solid 0.3px #707070",
                    paddingLeft: 10,
                  },
                }}
                onChange={(e) => {
                  setDriver(e.target.value);
                  if (e.target.value) setDriverError(false);
                }}
              >
                {drivers?.map((r) => (
                  <MenuItem key={r.Id} value={r.Id}>
                    {r.Name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid item className={classes["FieldLa"]}>
              License Plate No.
            </Grid>
            <Grid item xs={10} paddingTop={0.5}>
              <TextField
                value={licensePlate}
                variant={"standard"}
                fullWidth
                error={licensePlateError}
                helperText={licensePlateError ? "Enter  License Plate" : ""}
                onBlur={(e) => {
                  if (!e.target.value) setLicensePlateError(true);
                }}
                InputProps={{
                  style: {
                    height: "26px",
                    fontSize: 12,
                    fontFamily: "Muli",
                    backgroundColor: "#fff",
                    border: "solid 0.3px #707070",
                    paddingLeft: 10,
                  },
                }}
                onChange={(e) => {
                  setLicensePlate(e.target.value);
                  if (e.target.value) setLicensePlateError(false);
                }}
              ></TextField>
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <Grid item className={classes["FieldLa"]}>
              Default Trailer Name
            </Grid>
            <Grid item xs={9} paddingTop={0.5}>
              <TextField
                SelectProps={{ MenuProps: { disableScrollLock: true } }}
                value={trailer}
                variant={"standard"}
                fullWidth
                select
                error={trailerError}
                helperText={trailerError ? "Enter trailer" : ""}
                onBlur={(e) => {
                  if (!e.target.value) setTrailerError(true);
                }}
                InputProps={{
                  style: {
                    height: "26px",
                    fontSize: 12,
                    fontFamily: "Muli",
                    backgroundColor: "#fff",
                    border: "solid 0.3px #707070",
                    paddingLeft: 10,
                  },
                }}
                onChange={(e) => {
                  setTrailer(e.target.value);
                  if (e.target.value) setTrailerError(false);
                }}
              >
                {trailers?.map((r) => (
                  <MenuItem key={r.Id} value={r.Id}>
                    {r.Name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid item className={classes["FieldLa"]}>
              License Expiry Date
            </Grid>
            <Grid item xs={6} paddingTop={0.5}>
              <TextField
                value={expiryDate}
                variant={"standard"}
                fullWidth
                type={"date"}
                error={expiryDateError}
                helperText={expiryDateError ? "Enter expiry Date" : ""}
                onBlur={(e) => {
                  if (!e.target.value) setExpiryDateError(true);
                }}
                InputProps={{
                  style: {
                    height: "26px",
                    fontSize: 12,
                    fontFamily: "Muli",
                    backgroundColor: "#fff",
                    border: "solid 0.3px #707070",
                    paddingLeft: 10,
                  },
                }}
                onChange={(e) => {
                  setExpiryDate(e.target.value);
                  if (e.target.value) setExpiryDateError(false);
                }}
              ></TextField>
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <Grid item className={classes["FieldLa"]}>
              Company Name
            </Grid>
            <Grid item xs={9} paddingTop={0.5}>
              <TextField
                value={companyName}
                variant={"standard"}
                fullWidth
                error={companyNameError}
                helperText={companyNameError ? "Enter a company name" : ""}
                onBlur={(e) => {
                  if (!e.target.value) setCompanyNameError(true);
                }}
                InputProps={{
                  style: {
                    height: "26px",
                    fontSize: 12,
                    fontFamily: "Muli",
                    backgroundColor: "#fff",
                    border: "solid 0.3px #707070",
                    paddingLeft: 10,
                  },
                }}
                onChange={(e) => {
                  setCompanyName(e.target.value);
                  if (e.target.value) setCompanyNameError(false);
                }}
              ></TextField>
            </Grid>
          </Grid>

          <Grid item container xs={11} direction={"row-reverse"} paddingTop={5}>
            <button
              className={classes["SaveBut"]}
              disabled={isDisabled}
              onClick={() => {
                save();
              }}
            >
              {props.trailerId
                ? loading
                  ? "Updating..."
                  : "update"
                : loading
                ? "saving..."
                : "save"}
            </button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "30%",
  transform: "translate(-50%, -50%)",
  borderRadius: "15px",
  boxShadow: " 0 3px 6px 0 rgba(0, 0, 0, 0.46)",
  border: "solid 1px #0674b9",
  backgroundColor: "#fff",
};
export default TruckModal;
