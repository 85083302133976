import * as constFile from "../../projectConst";
import axios from "axios";
const FormData = require("form-data");

const PROJECTS = constFile.PROJECTS;

export const getProjectById = (projectId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + PROJECTS}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        projectId: projectId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};

//delete contract
const DELETE_P_CONTRACT = constFile.DELETE_P_CONTRACT
export const deleteContract = (contractId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + DELETE_P_CONTRACT}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        Id: contractId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
}

//Create Project
export const createProject = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + PROJECTS}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};

//Update Project
const UPDATE_PROJECT = constFile.UPDATE_PROJECT;
export const updateProject = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + UPDATE_PROJECT}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};

const PROJECTS_TABLE_LIST = constFile.PROJECTS_TABLE_LIST;
export const getProjectsList = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + PROJECTS_TABLE_LIST}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};

const PROJECTS_ASSIGNED_TEABLE = constFile.PROJECTS_ASSIGNED_TEABLE;
export const getAssignedProjectsList = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + PROJECTS_ASSIGNED_TEABLE}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};

const PROJECTS_CARD_LIST = constFile.PROJECTS_CARD_LIST;
export const getProjectCardsList = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + PROJECTS_CARD_LIST}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};

const PROJECTS_STATUS = constFile.PROJECTS_STATUS;
export const changeProjectStatus = (projectId, statusId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + PROJECTS_STATUS}`,
      {
        ProjectId: projectId,
        StatusId: statusId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};

const PHASES_STATUS = constFile.PHASES_STATUS;
export const changePhaseStatus = (phaseId, statusId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + PHASES_STATUS}`,
      {
        PhaseId: phaseId,
        StatusId: statusId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};

const PROJECTS_ASSIGNED_CARD = constFile.PROJECTS_ASSIGNED_CARD;
export const getProjectAssignedCard = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + PROJECTS_ASSIGNED_CARD}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};

const PROJECT_INPUT_DATA = constFile.PROJECT_INPUT_DATA;
export const getProjectInputData = (projectId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + PROJECT_INPUT_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        projectId: projectId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};

//join Project
const JOIN_PROJECT = constFile.JOIN_PROJECT;
export const joinProject = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + JOIN_PROJECT}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};

const PHASES = constFile.PHASES;
export const getPhaseById = (phaseId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + PHASES}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        PhaseId: phaseId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};

//Create Phase
export const createPhase = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + PHASES}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};

const PHASES_LIST = constFile.PHASES_LIST;
//Get Phases by Project Id
export const getPhases = (projectId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + PHASES_LIST}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        projectId: projectId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};

const PHASES_ASSIGNED_DATA = constFile.PHASES_ASSIGNED_DATA;
//get assigned phases
export const getAssignedPhases = (projectId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + PHASES_ASSIGNED_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        ProjectId: projectId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};


const PHASES_INPUT_DATA = constFile.PHASES_INPUT_DATA;
//get input data
export const getPhaseData = (phaseId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + PHASES_INPUT_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        PhaseId: phaseId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};

const UPDATE_PHASE = constFile.UPDATE_PHASE;
//Create Phase
export const updatePhase = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + UPDATE_PHASE}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};

const DELETE_PHASE = constFile.DELETE_PHASE;
//Delete phase
export const deletephase = (phaseId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + DELETE_PHASE}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { phaseId: phaseId },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};

//Upload profile
const PHASE_UPLOAD_PROFILE = constFile.PHASE_UPLOAD_PROFILE;
export const uploadProfile = (file, projectId) => {
  const token = localStorage.getItem("accessToken");
  const data = new FormData();
  data.append("File", file);
  data.append("ProjectId", projectId ? projectId : "");
  data.append("PhaseId", "");
  return axios
    .post(`${process.env.REACT_APP_URL + PHASE_UPLOAD_PROFILE}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};

//Delete File
const PHASE_DELETE_PROFILE = constFile.PHASE_DELETE_PROFILE;
export const deleteFile = (fileId, projectId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + PHASE_DELETE_PROFILE}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        FileId: fileId,
        ProjectId: projectId ? projectId : "",
        PhaseId: "",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};

//Upload contract file
const CONTRACTS_UPLOAD_FILE = constFile.CONTRACTS_UPLOAD_FILE;
export const uploadContractFile = (file, projectId) => {
  const token = localStorage.getItem("accessToken");
  const data = new FormData();
  data.append("File", file);
  data.append("ProjectId", projectId ? projectId : "");
  data.append("PhaseId", "");
  return axios
    .post(`${process.env.REACT_APP_URL + CONTRACTS_UPLOAD_FILE}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};

//Delete contract File
const CONTRACTS_DELETE_FILE = constFile.CONTRACTS_DELETE_FILE;
export const deleteContractFile = (fileId, projectId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + CONTRACTS_DELETE_FILE}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        FileId: fileId,
        ProjectId: projectId ? projectId : "",
        PhaseId: "",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};
//Upload profile consultant
const ULPOAD_PROFILE_CONSULTANT = constFile.ULPOAD_PROFILE_CONSULTANT;
export const uploadProfileConsultant = (file) => {
  const token = localStorage.getItem("accessToken");
  const data = new FormData();
  data.append("File", file);
  return axios
    .post(`${process.env.REACT_APP_URL + ULPOAD_PROFILE_CONSULTANT}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};
//Upload document consultant
const ULPOAD_DOCUMENT_CONSULTANT = constFile.ULPOAD_DOCUMENT_CONSULTANT;
export const uploadDocument = (file) => {
  const token = localStorage.getItem("accessToken");
  const data = new FormData();
  data.append("File", file);
  return axios
    .post(`${process.env.REACT_APP_URL + ULPOAD_DOCUMENT_CONSULTANT}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};
//Delete Profile consultant
const DELETE_PROFILE_CONSULTANT = constFile.DELETE_PROFILE_CONSULTANT;
export const deleteProfileConsultant = (fileId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + DELETE_PROFILE_CONSULTANT}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        FileId: fileId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};
//Delete Documnet consultant
const DELETE_DOCUMENT_CONSULTANT = constFile.DELETE_DOCUMENT_CONSULTANT;
export const deleteDocumentConsultant = (fileId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + DELETE_DOCUMENT_CONSULTANT}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        FileId: fileId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};
const PHASES_TABLE_LIST = constFile.PHASES_TABLE_LIST;
export const getPhasesList = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + PHASES_TABLE_LIST}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};
const JOINABLE_PROJECTS = constFile.JOINABLE_PROJECTS;

export const getJoinableProjects = (search) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + JOINABLE_PROJECTS}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        search: search,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};

const CONSULTANT = constFile.CONSULTANT;

export const getConsultantById = (consultantId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + CONSULTANT}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        consultantId: consultantId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};


const UPDATE_FACTORY = constFile.UPDATE_FACTORY;
export const updateContractFields = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + UPDATE_FACTORY}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err.response.data; });
};