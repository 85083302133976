import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Box, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import homeBg from '../../Assets/Images/Dashboard/logInBgColor.png'
import back from '../../Assets/Images/Dashboard/back1.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { useMemo } from 'react'
import * as dashboardServices from '../../Services/machine-dashboard-services.proxy';
import DashNavBar from './DashNavBar'

const getOrdersDataByDate = dashboardServices.getOrdersDataByDate;

export default function Orders() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    Array.prototype.sum = function (prop) {
        var total = 0
        for (var i = 0, _len = this.length; i < _len; i++) {
            total += +this[i][prop]
        }
        return total
    }

    const useQuery = () => {
        const { search } = useLocation();
        return useMemo(() => new URLSearchParams(search), [search]);
    };
    const query = useQuery();
    const dateId = query.get("dateId");
    const machineId = query.get("machineId");

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        margin: '5% 0 5% 0',
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor:
                theme.palette.mode === 'light' ?
                    dateId === "1" ? '#ff5b5b' : dateId === "2" ? '#2da4d0' : '#e1e319'
                    : '#308fe8',
        },
    }));

    function LinearProgressWithLabel(props) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <BorderLinearProgress variant="determinate" value={props.value ? props.value : 0} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">
                        {
                            `${Math.round(
                                props.value,
                            )}%`
                        }
                    </Typography>
                </Box>
            </Box>
        );
    }

    const style = {
        border: dateId === "1" ? 'solid 1px #ff5b5b' : dateId === "2" ? 'solid 1px #2da4d0' : 'solid 1px #e1e319',
        backgroundColor: '#fff',
        fontFamily: 'Muli',
        fontSize: 18,
        lineHeight: 1.28,
        textAlign: 'center',
        color: '#000',
    }

    const navigate = useNavigate();

    const [orders, setOrders] = useState([])

    useEffect(() => {
        let apiTest = true;
        if (machineId && dateId)
            getOrdersDataByDate(machineId, dateId).then((x) => {
                if (apiTest && x)
                    setOrders(x);
            })
        return () => {
            apiTest = false;
        }
    }, [])

    return (
        <Grid
            sx={{
                padding: '2% 3.5% 0 3.5%',
                backgroundImage: `url(${homeBg})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                paddingBottom: '10%',
                minHeight: '100vh'
            }}>
            <DashNavBar />
            <Grid item xs={12} container alignItems='center' justifyContent='flex-start' padding='3% 0 0 2%' gap={2}>
                <Grid item xs={12}>
                    <img
                        onMouseOver={(e) => e.target.style.cursor = 'pointer'}
                        onClick={() => navigate(-1)}
                        src={back} alt="back" /></Grid>
                <Grid item xs={12} sx={{
                    fontFamily: 'Muli',
                    fontSize: 26,
                    fontWeight: 'bold',
                    lineHeight: 1.27,
                    textAlign: 'left',
                    color: '#101650',
                }}>{dateId === "1" ? "Delayed" : dateId === "2" ? "Today's" : "Future Planned"} Orders</Grid>
                <Grid item xs={11}>
                    <Table sx={{ minWidth: 600 }} >
                        <TableHead>
                            <TableRow>
                                <TableCell style={style}>Production Order Code</TableCell>
                                <TableCell style={style}>Scheduled Production Date</TableCell>
                                <TableCell style={style}>Total Pcs</TableCell>
                                <TableCell style={style}>Kgs</TableCell>
                                <TableCell style={style}>% Accomplished</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ border: 0 }}></TableCell>
                            </TableRow>
                            {orders.length > 0 ?
                                orders?.map((order, index) => (
                                    <TableRow key={index}
                                        onMouseOver={(e) => e.target.style.cursor = 'pointer'}
                                        onClick={() => navigate(`/machine/assignedLines?groupId=${order?.Id}`)}
                                    >
                                        <TableCell style={style}>{order?.OrderCode}</TableCell>
                                        <TableCell style={style}>{order?.ProductionDate}</TableCell>
                                        <TableCell style={style}>{order?.TotalNbOfPieces}</TableCell>
                                        <TableCell style={style}>{order?.TotalWeight}</TableCell>
                                        <TableCell style={style}><LinearProgressWithLabel variant="determinate" value={order?.PercentageOfAccomplishment} /></TableCell>
                                    </TableRow>
                                ))
                                : ""}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Grid>
    )
}
