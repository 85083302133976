import { Grid } from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SaasBack from "../../Assets/Images/Projects/bgTableProject.png";
import classes from "../../Assets/Styles/projects.module.css";
import Planned from "../../Assets/Images/SalesOrderStatuses/Planned.png";
import Processing from "../../Assets/Images/SalesOrderStatuses/Processing.png";
import Produced from "../../Assets/Images/SalesOrderStatuses/Produced.png";
import Loading from "../../Assets/Images/SalesOrderStatuses/Loading.png";
import Delivered from "../../Assets/Images/SalesOrderStatuses/Delivered.png";
import PlannedIcon from "../../Assets/Images/SalesOrderStatuses/PlannedIcon.png";
import ProcessingIcon from "../../Assets/Images/SalesOrderStatuses/ProcessingIcon.png";
import ProducedIcon from "../../Assets/Images/SalesOrderStatuses/ProducedIcon.png";
import LoadingIcon from "../../Assets/Images/SalesOrderStatuses/LoadingIcon.png";
import DeliveredIcon from "../../Assets/Images/SalesOrderStatuses/DeliveredIcon.png";
import PlannedWhite from "../../Assets/Images/SalesOrderStatuses/PlannedWhite.png";
import ProcessingWhite from "../../Assets/Images/SalesOrderStatuses/ProcessingWhite.png";
import ProducedWhite from "../../Assets/Images/SalesOrderStatuses/ProducedWhite.png";
import LoadingWhite from "../../Assets/Images/SalesOrderStatuses/LoadingWhite.png";
import DeliveredWhite from "../../Assets/Images/SalesOrderStatuses/DeliveredWhite.png";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { styled } from "@mui/system";
import * as services from "../../Services/sales-order-services.proxy";
import CircularProgress from "@mui/material/CircularProgress";

const getSalesOrderDashboard = services.getSalesOrderDashboard;
const getSalesProjectOrderDashboard = services.getSalesProjectOrderDashboard;

const styles = {
  active: {
    backgroundImage:
      "linear-gradient(to right, #e1e319, rgba(225, 227, 25, 0.3))",
  },
  notActive: {
    backgroundImage: "linear-gradient(to right, #fff, #fff)",
  },
};

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
      font-family: IBM Plex Sans, sans-serif;
      font-size: 10px;
      box-sizing: border-box;
      min-height: 32px;
      width: 9rem;
      background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]
    };
      border-radius: 0.75em;
      margin: 0.5em;
      padding: 10px;
      text-align: left;
      line-height: 1.5;
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    
      &:hover {
        background: ${theme.palette.mode === "dark" ? "" : grey[100]};
        border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
      }
    
      &.${selectUnstyledClasses.focusVisible} {
        outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]
    };
      }
    
      &.${selectUnstyledClasses.expanded} {
        &::after {
          content: '▴';
        }
      }
    
      &::after {
        content: '▾';
        float: right;
      }
      `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
      font-family: Muli;
      font-size: 0.875rem;
      box-sizing: border-box;
      padding: 5px;
      margin: 10px 0;
      min-width: 11rem;
      height:150px;
      overflow: scroll;
      background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]
    };
      border-radius: 0.75em;
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      overflow: auto;
      outline: 0px;
      `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
      list-style: none;
      padding: 8px;
      border-radius: 0.45em;
      cursor: default;
    
      &:last-of-type {
        border-bottom: none;
      }
    
      &.${optionUnstyledClasses.selected} {
        background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]
    };
        color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
      }
    
      &.${optionUnstyledClasses.highlighted} {
        background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]
    };
        color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      }
    
      &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
        background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]
    };
        color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
      }
    
      &.${optionUnstyledClasses.disabled} {
        color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
      }
    
      &:hover:not(.${optionUnstyledClasses.disabled}) {
        background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]
    };
        color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      }
      `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});

export default function SalesOrderDashboard(props) {
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const ID = query.get("id");

  useEffect(() => {
    if (ID) {
      setProject(ID);
    }
  }, [ID]);

  useEffect(() => {
    props.setRegister(true);
  }, [props]);

  useEffect(() => {
    props.setNaved(false);
  }, [props]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [statusId, setStatusId] = useState(null);

  const [statuses, setStatuses] = useState([]);

  const [project, setProject] = useState(null);

  const [projects, setProjects] = useState([]);

  const [dashboardStatuses, setDashboardStatuses] = useState([]);
  const [dashboardProjects, setDashboardProjects] = useState([]);
  const [total, setTotal] = useState(0);

  const navigate = useNavigate();

  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    let apiTest = true;
    setLoadingData(true);
    getSalesOrderDashboard(project).then((x) => {
      if (apiTest) {
        setProjects(x.Projects);
        setStatuses(x.Statuses.filter(x => x.Id > 1 && x.Id < 7));
        let filteredDashborad = x.DashboardStatuses.filter(x => x.Id > 1 && x.Id < 7);
        setDashboardStatuses(filteredDashborad);
        let sum = 0;
        filteredDashborad.map((d) => {
          sum = sum + d.NumberSaleOfTons;
        });
        setTotal(sum);
        setLoadingData(false);
        filteredDashborad = [];
      }
    });
    return () => {
      apiTest = false;
    };
  }, [project]);

  useEffect(() => {
    let apiTest = true;
    if (statusId === null && project === null) {
      getSalesOrderDashboard(project).then((x) => {
        if (apiTest) {
          let sum = 0;
          let filteredDashborad = x.DashboardStatuses.filter(x => x.Id > 1 && x.Id < 7);
          filteredDashborad.map((d) => {
            sum = sum + d.NumberSaleOfTons;
          });
          setTotal(sum);
          filteredDashborad = [];
        }
      });
    }
    return () => {
      apiTest = false;
    }
  }, [statusId, project])

  useEffect(() => {
    let apiTest = true;
    setLoadingData(true);
    getSalesProjectOrderDashboard(statusId).then((x) => {
      if (apiTest) {
        setDashboardProjects(x);
        let sum = 0;
        x.map((d) => {
          sum = sum + d.NumberOfTons;
        });
        setTotal(sum);
        setLoadingData(false);
      }
    });
    return () => {
      apiTest = false;
    };
  }, [statusId]);

  return (
    <div
      style={{
        minHeight: "90vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <Grid container paddingTop={5} paddingLeft={2.5}>
        <Grid item container md={11.95} paddingBottom={2} paddingLeft={3.5}>
          <Grid
            item
            xs={12}
            sm={7}
            md={7}
            sx={{
              fontFamily: "Muli",
              fontSize: 18,
              fontWeight: "bold",
              lineHeight: 1.44,
              textAlign: "left",
              color: "#101650",
            }}
          >
            Dashboard
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            sm={5}
            container
            direction={"row-reverse"}
            paddingRight={{ md: 1 }}
          >
            <Grid item md={1.5} xs={3}>
              <button
                className={classes["back-button"]}
                onClick={() => {
                  navigate(-1);
                  //   localStorage.removeItem("idp");
                  //   localStorage.removeItem("proname");
                }}
              >
                Go back
              </button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          direction={"row-reverse"}
          alignItems="center"
          paddingRight={{ md: 2, xs: 0 }}
        >
          <Grid item>
            <CustomSelect
              value={project}
              onChange={(option) => {
                setProject(option);
                setStatusId(null);
              }}
            >
              <StyledOption value={null} disabled>
                Project Name
              </StyledOption>
              {projects?.map((p, index) => (
                <StyledOption key={index} value={p.Id}>
                  {p.Name}
                </StyledOption>
              ))}
            </CustomSelect>
          </Grid>
          {project || statusId ? (
            <Grid item>
              <span
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={() => {
                  setProject(null);
                  setStatusId(null);
                }}
                style={{
                  fontFamily: "Muli",
                  fontSize: 10,
                  color: "#000",
                  paddingTop: 25,
                  paddingRight: 15,
                }}
              >
                Clear filters
              </span>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        <Grid
          item
          xs={11.65}
          container
          gap={5}
          sx={{ borderRadius: 5, backgroundColor: "#fff" }}
          padding="1%"
          marginLeft="1.5%"
        >
          <Grid item xs={12} container>
            <Grid
              item
              xs={2.5}
              sx={{
                fontFamily: "Muli",
                fontSize: 18,
                fontWeight: "bold",
                lineHeight: 1.44,
                textAlign: "left",
                color: "#101650",
              }}
            >
              Sales Order Status
            </Grid>
            <Grid
              item
              xs={1.5}
              sx={{
                opacity: 0.9,
                fontFamily: "Muli",
                fontSize: 16,
                fontWeight: "bold",
                lineHeight: 1.5,
                textAlign: "left",
                color: "#707070",
              }}
            >
              {project
                ? projects.find((p) => p.Id === project)?.Name
                : "All Projects"}
            </Grid>
            <Grid
              item
              xs={1.5}
              sx={{
                opacity: 0.9,
                fontFamily: "Muli",
                fontSize: 16,
                fontWeight: "bold",
                lineHeight: 1.5,
                textAlign: "left",
                color: "#707070",
              }}
            >
              TOTAL: {total?.toFixed(2)} Tons
            </Grid>
          </Grid>
          <Grid
            item
            xs={11.5}
            sx={{ borderLeft: "solid 1px #707070" }}
            container
            alignItems="center"
          >
            {statuses?.map((status, index) => (
              <Grid
                key={index}
                item
                container
                xs={2.4}
                alignItems="flex-start"
                justifyContent="center"
                sx={statusId === status.Id ? styles.active : styles.notActive}
                style={{
                  borderRight: "solid 1px #707070",
                  textAlign: "center",
                  color: "#0674b9",
                  fontFamily: "Muli",
                  fontSize: 15,
                  lineHeight: 1.47,
                  height: 40,
                  paddingTop: "0.7%",
                }}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={(e) => {
                  setStatusId(status.Id);
                  setProject(null);
                }}
              >
                <Grid item>
                  <img
                    src={
                      status.Name === "Placed"
                        ? statusId === status.Id
                          ? ProcessingWhite
                          : ProcessingIcon
                        : status.Name === "Planned"
                          ? statusId === status.Id
                            ? PlannedWhite
                            : PlannedIcon
                          : status.Name === "InProduction"
                            ? statusId === status.Id
                              ? ProducedWhite
                              : ProducedIcon
                            : status.Name === "Loading"
                              ? statusId === status.Id
                                ? LoadingWhite
                                : LoadingIcon
                              : statusId === status.Id
                                ? DeliveredWhite
                                : DeliveredIcon
                    }
                    width="80%"
                    alt="icons"
                  />
                </Grid>
                <Grid item>{status.Name === "InProduction" ? "Production" : status.Name}</Grid>
              </Grid>
            ))}
          </Grid>
          {loadingData ? (
            <Grid
              item
              xs={11.5}
              container
              alignItem="center"
              justifyContent="center"
              minHeight={250}
            >
              <Grid item paddingTop="5%">
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={11.5} container>
              {statusId === null ? (
                dashboardStatuses?.map((status, index) => (
                  <Grid
                    item
                    xs={2.4}
                    justifyContent="center"
                    alignItems="center"
                    key={index}
                    container
                    minHeight={250}
                  >
                    <Grid
                      item
                      justifyContent="center"
                      alignItems="center"
                      onMouseOver={(e) => {
                        if (status.NumberOfSalesOrder > 0)
                          e.target.style.cursor = "pointer";
                      }}
                      onClick={() => {
                        if (status.NumberOfSalesOrder > 0)
                          if (project) {
                            navigate(
                              `/saas/salesOrderList?id=${project}&statusId=${status.Id}`
                            );
                            localStorage.setItem(
                              "ProjectName",
                              projects.find((p) => p.Id === project).Name
                            );
                          }
                          else {
                            navigate(
                              `/saas/salesOrderList?statusId=${status.Id}`
                            );
                          }
                      }}
                      sx={{
                        backgroundImage:
                          status.Name === "Placed"
                            ? `url(${Processing})`
                            : status.Name === "Planned"
                              ? `url(${Planned})`
                              : status.Name === "InProduction"
                                ? `url(${Produced})`
                                : status.Name === "Loading"
                                  ? `url(${Loading})`
                                  : `url(${Delivered})`,
                        backgroundSize: "contains",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        height: 140,
                        paddingBottom: "5%",
                      }}
                      container
                    >
                      <Grid
                        item
                        sx={{
                          fontFamily: "Muli",
                          fontSize: 25,
                          fontWeight: "bold",
                          lineHeight: 1.44,
                          color: "#0674b9",
                        }}
                      >
                        {status.NumberOfSalesOrder}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      sx={{
                        fontFamily: "Muli",
                        fontSize: 13,
                        lineHeight: 1.46,
                        color: "#0674b9",
                      }}
                    >
                      {status.NumberSaleOfTons} Tons
                    </Grid>
                  </Grid>
                ))
              ) : dashboardProjects.length === 0 ? (
                <Grid
                  item
                  container
                  alignItem="center"
                  justifyContent="center"
                  minHeight={250}
                >
                  <Grid
                    item
                    sx={{
                      textAlign: "center",
                      color: "#0674b9",
                      fontFamily: "Muli",
                      fontSize: 15,
                      lineHeight: 1.47,
                    }}
                    paddingTop="5%"
                  >
                    No data to display
                  </Grid>
                </Grid>
              ) : (
                dashboardProjects?.map((project, index) => (
                  <Grid
                    item
                    xs={2.4}
                    minHeight={dashboardProjects.length < 6 ? 250 : "none"}
                    justifyContent="center"
                    alignItems="center"
                    key={index}
                    container
                    onMouseOver={(e) => (e.target.style.cursor = "pointer")}
                    onClick={() => {
                      navigate(
                        `/saas/salesOrderList?id=${project.Id}&statusId=${statusId}`
                      );
                      localStorage.setItem(
                        "ProjectName",
                        projects.find((p) => p.Id === project.Id).Name
                      );
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        backgroundImage:
                          statuses.find((s) => s.Id === statusId).Name ===
                            "Placed"
                            ? `url(${Processing})`
                            : statuses.find((s) => s.Id === statusId).Name ===
                              "Planned"
                              ? `url(${Planned})`
                              : statuses.find((s) => s.Id === statusId).Name ===
                                "InProduction"
                                ? `url(${Produced})`
                                : statuses.find((s) => s.Id === statusId).Name ===
                                  "Loading"
                                  ? `url(${Loading})`
                                  : `url(${Delivered})`,
                        backgroundSize: "contains",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        height: 140,
                        paddingBottom: "5%",
                      }}
                      container
                    >
                      <Grid
                        item
                        sx={{
                          fontFamily: "Muli",
                          fontSize: 25,
                          fontWeight: "bold",
                          lineHeight: 1.44,
                          color: "#0674b9",
                        }}
                      >
                        {project.SalesOrders}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        fontFamily: "Muli",
                        fontSize: 13,
                        fontWeight: "bold",
                        lineHeight: 1.46,
                        color: "#0674b9",
                        textAlign: "center",
                      }}
                    >
                      {project.Name}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        fontFamily: "Muli",
                        fontSize: 13,
                        lineHeight: 1.46,
                        color: "#0674b9",
                        textAlign: "center",
                      }}
                    >
                      {project.NumberOfTons} Tons
                    </Grid>
                  </Grid>
                ))
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
