import React, { useState, useEffect } from "react";
import { styled, Box } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import classes from "../../../Assets/Styles/ProductionLabel.module.css";
import ShapeWEl from "../../../Assets/Images/ProductionLabel/shapewEl.png";
import ShapeWOEl from "../../../Assets/Images/ProductionLabel/shapewoel.png";

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  bgcolor: "#transparent",
  border: "0",
  width: "45%",
  height: "60%",
  outline: "none",
};

export default function ShapeFormModal(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [shapeForm, setShapeForm] = useState(null);
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
    props.setOpen(false);
  };
  useEffect(() => {
    if (props.open) {
      handleOpen(true);
    }
  }, [props.open]);
  return (
    <div>
      <StyledModal
        disableScrollLock
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
      >
        <Box sx={style}>
          <Grid container direction={"row-reverse"}>
            <IconButton
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onClick={() => {
                handleClose();
              }}
            >
              <CloseIcon htmlColor="#fff" />
            </IconButton>
          </Grid>
          <Grid bgcolor={"#fff"} container direction={"column"} paddingTop={2}>
            <Grid item container justifyContent={"center"}>
              <div className={classes["Production-Label"]}>Shape Selected</div>
            </Grid>
            <Grid
              item
              width={"100%"}
              bgcolor={"#e9e7e7"}
              height={1}
              marginTop={1}
            >
              <div style={{ width: "100%", height: 1 }}></div>
            </Grid>
            <Grid
              item
              container
              justifyContent={"center"}
              alignItems={"center"}
              paddingTop={1}
              paddingBottom={3}
              className={classes["Choose-options"]}
              fontSize={13}
            >
              Choose Shape style you desire in the label template
            </Grid>
            <Grid
              item
              container
              xs={12}
              gap={3}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid
                item
                container
                justifyContent={"center"}
                alignItems={"center"}
                xs={5}
              >
                <img src={ShapeWOEl} alt={"shape"} />
                <Grid item container xs={10.5} direction={"row-reverse"}>
                  <Grid
                    item
                    className={
                      shapeForm === 1
                        ? classes["DefaultLabelChecked"]
                        : classes["DefaultLabel"]
                    }
                    onClick={() => {
                      setShapeForm(1);
                    }}
                  ></Grid>
                </Grid>
              </Grid>

              <Grid
                item
                container
                justifyContent={"center"}
                alignItems={"center"}
                xs={5}
              >
                <img src={ShapeWEl} alt={"shape"} />
                <Grid item container xs={10.5} direction={"row-reverse"}>
                  <Grid
                    item
                    className={
                      shapeForm === 2
                        ? classes["DefaultLabelChecked"]
                        : classes["DefaultLabel"]
                    }
                    onClick={() => {
                      setShapeForm(2);
                    }}
                  ></Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              marginTop={3.1}
              xs={10}
              container
              direction={"row-reverse"}
              bgcolor={"#e9e7e7"}
              padding={2}
            >
              <Grid item marginLeft={2}>
                <button
                  disabled={shapeForm ? false : true}
                  className={classes.cancelbut}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={() => {
                    props.setSelectedLabelRawsFields(
                      props.selectedlabelRawsFields.concat({
                        Id: props.selectedlabelRawsFields.length + 1,
                        LabelRowId: props.fieldChosen.Id,
                        Data: props.fieldChosen.Name,
                        AdditionalFeatures: JSON.stringify({
                          ShapeSelected: shapeForm === 1 ? "A" : "B",
                          ShapeCode: "Shape Code",
                        }),
                        x: 0,
                        y: 0,
                        Width: 187,
                        Height: 96,

                        // ShapeForm: shapeForm,
                      })
                    );
                    handleClose();
                  }}
                >
                  Proceed
                </button>
              </Grid>
              <Grid item>
                <button
                  className={classes.cancelbut}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cancel
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </StyledModal>
    </div>
  );
}
