import React from "react";
import { useLottie } from "lottie-react";
import LoadingAni from "../../Assets/Animation/loading";
export default function Animation() {
  const options = {
    animationData: LoadingAni,
    loop: true,
    autoplay: true,
  };
  
  const { View } = useLottie(options);
  return (
    <>
      {View}
    </>
  );
}