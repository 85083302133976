import React, { useEffect, useState, useMemo } from "react";

import SaasBack from "../../../../Assets/Images/Projects/bgTableProject.png";
import { Grid, TextField, Button } from "@mui/material";
import classes from "../../../../Assets/Styles/RFIS.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import FileIcon from "../../../../Assets/Images/Table/file.png";
import { styled } from "@mui/system";
import { Tooltip } from "@mui/material";
import uploadIcon from "../../../../Assets/Images/Settings/uploadImg.png";
import fileIcon from "../../../../Assets/Images/Table/file.png";
import CloseIcon from "@mui/icons-material/Close";
import BlueEye from "../../../../Assets/Images/Table/blue eye.png";
import * as rfiServices from "../../../../Services/rfi-services.proxy";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import DocumentModal from "./DocumentModal";
const getRFIInputData = rfiServices.getRFIInputData;
const uploadResponseFileRFI = rfiServices.uploadResponseFileRFI;
const deleteResponseFileRFI = rfiServices.deleteResponseFileRFI;
const responseRFI = rfiServices.responseRFI;
const raiseToConsultant1 = rfiServices.raiseToConsultant;

const RFIResponse = (props) => {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  // const RFI_ID = query.get("id");
  // const PROJECT_ID = query.get("projectid");
  const navigate = useNavigate();
  const location = useLocation();
  // const RFIID = location.state?.id;
  // const PROJECTID = location.state?.ProjectId;

  const projectId= query.get("projectid")?query.get("projectid"):location.state?.ProjectId;
  const refId = query.get("id")?query.get("id"):location.state?.id
  
  const [files, setFiles] = useState([]);
  const [filesIDS, setFilesIDS] = useState([]);
  const [response, setResponse] = useState();
  const [responseError, setResponseError] = useState(false);
  const [responseErrorMessage, setResponseErrorMessage] = useState();
  const [rfiDetails, setRfiDetails] = useState({});
  const [consultant, setConsultant] = useState(null);
  const [consultantError, setConsultantError] = useState(false);
  const [respond, setRespond] = useState(false);
  const [raiseToConsultant, setRaiseToConsultant] = useState(false);
  const [open, setOpen] = useState(false);
  const [documentId, setDocumentId] = useState(false);
  const [sending, setSending] = useState(false);


  useEffect(() => {
    // if (RFIID && PROJECTID) {
    //   getRFIInputData(PROJECTID, RFIID).then((x) => {
    //     setRfiDetails(x);
    //   });
    // } else if (RFI_ID && PROJECT_ID) {
      getRFIInputData(projectId, refId).then((x) => {
        setRfiDetails(x);
      });
    // }
  }, [refId, projectId]);
  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      uploadResponseFileRFI(e.target.files[0], projectId).then((x) => {
        let tempIDS = filesIDS;
        setFilesIDS(tempIDS.concat(x.FileId));
        let tempFiles = files;
        setFiles(
          tempFiles.concat({
            FileId: x.FileId,
            DisplayName: x.DisplayName,
            Type: e.target.files[0].type,
            Size: e.target.files[0].size,
          })
        );
      });
    }
  };
  const handleFileDelete = (fileIdToDelete) => {
    deleteResponseFileRFI(fileIdToDelete, projectId).then((x) => {
      let tempIDS = filesIDS;
      tempIDS = tempIDS.filter(function (item) {
        return item.FileId !== fileIdToDelete;
      });
      setFilesIDS(tempIDS);
      let tempFiles = files;
      tempFiles = tempFiles.filter(function (item) {
        return item.FileId !== fileIdToDelete;
      });
      setFiles(tempFiles);
    });
  };
  const respondTheRfi = () => {
    setSending(true);
    responseRFI(
      // RFIID ? RFIID : RFI_ID
      refId, response, files).then((x) => {
      setSending(false);
      navigate("/saas/admin/RFI/List");
    });
  };
  const raiseRFiToConsultant = () => {
    setSending(true);
    raiseToConsultant1(refId, consultant).then((x) => {
      setSending(false);
      navigate("/saas/admin/RFI/List");
    });
  };
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <Grid container paddingLeft={{ md: 15, xs: 4 }} paddingTop={5}>
        <Grid item xs={12} container alignItems="center">
          <Grid
            item
            md={0.6}
            xs={1.7}
            sx={{
              fontFamily: "Muli",
              fontSize: 18,
              fontWeight: "bold",
              lineHeight: 1.44,
              textAlign: "left",
              color: "#101650",
            }}
          >
            RFI
          </Grid>
          <Grid
            item
            md={10}
            xs={7.3}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>
          <Grid
            item
            md={1}
            xs={3}
            onMouseOver={(e) => (e.target.style.cursor = "pointer")}
            sx={{
              fontFamily: "Muli",
              fontSize: 14,
              lineHeight: 1.36,
              textAlign: "left",
              color: "#101650",
              paddingLeft: "1%",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <button className={classes["GoBack"]}>Go Back </button>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={11}
          bgcolor={"#fff"}
          marginTop={4}
          paddingBottom={25}
        >
          <Grid
            item
            xs={12}
            md={6}
            marginTop={4}
            marginLeft={{ md: "7%", xs: 2 }}
          >
            <Grid item xs={12} className={classes["DataTitle"]}>
              Subject:
            </Grid>
            <Grid item xs={12} className={classes["Actualdata"]} paddingTop={1}>
              {rfiDetails?.RFI?.Subject}
            </Grid>
            <Grid item xs={12} className={classes["DataTitle"]} paddingTop={5}>
              Description:
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              className={classes["Actualdata"]}
              paddingTop={1}
            >
              {rfiDetails?.RFI?.Description}
            </Grid>
            {respond ? (
              ""
            ) : rfiDetails?.RFI?.CanRespond &&
              rfiDetails?.RFI?.StatusId !== 3 ? (
              <Grid container item xs={12} gap={2}>
                <Grid
                  item
                  md={3}
                  xs={5}
                  marginTop={4}
                  className={classes["RespondButton"]}
                  onClick={() => {
                    setRespond(true);
                    setRaiseToConsultant(false);
                    setConsultant(null);
                  }}
                >
                  Respond
                </Grid>
                <Grid container item lg={4} md={6} xs={6}>
                  <Grid
                    item
                    marginTop={4}
                    xs={12}
                    className={
                      localStorage.getItem("AccountType") === "1"
                        ? classes["RespondButton"]
                        : classes["RaiseButtonSub"]
                    }
                    onClick={() => {
                      if (localStorage.getItem("AccountType") === "1") {
                        setRaiseToConsultant(true);
                        setRespond(false);
                        setResponse(null);
                      }
                    }}
                  >
                    {rfiDetails?.RFI?.AssigneeType === 1
                      ? rfiDetails?.RFI?.RaisedTo
                        ? `Re-Raise to ${rfiDetails?.RFI?.RaisedTo}`
                        : "Raise to Consultant"
                      : "Re-Raise to Consultant"}
                  </Grid>
                  {raiseToConsultant ? (
                    <Grid item xs={12} paddingTop={1}>
                      <Grid item className={classes["field-title"]} xs={12}>
                        *Consultant
                      </Grid>
                      <Grid item xs={11.5} paddingTop={1}>
                        <CustomSelect
                          style={{ color: "#707070" }}
                          value={consultant}
                          onChange={(option) => {
                            setConsultant(option);
                            setConsultantError(false);
                          }}
                          onBlur={(e) => {
                            if (!consultant) setConsultantError(true);
                          }}
                        >
                          <StyledOption value={0} disabled>
                            Consultant
                          </StyledOption>
                          {rfiDetails?.Consultants?.map((s) => (
                            <StyledOption key={s.Id} value={s.Id}>
                              {s.Name}
                            </StyledOption>
                          ))}
                        </CustomSelect>
                      </Grid>
                      <Grid item className={classes["errorMessage"]}>
                        {consultantError ? "Select a Consultant" : <></>}
                      </Grid>
                      <Grid item paddingTop={1}>
                        <button
                          className={classes["SendButton"]}
                          onClick={() => {
                            raiseRFiToConsultant();
                          }}
                        >
                          {sending ? "Raising..." : "Raise"}
                        </button>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            ) : (
              ""
            )}
            {rfiDetails?.RFI?.StatusId === 3 ? (
              <Grid container xs={12}>
                <Grid
                  item
                  xs={12}
                  className={classes["DataTitle"]}
                  paddingTop={5}
                >
                  Response:
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={8}
                  className={classes["Actualdata"]}
                  paddingTop={1}
                >
                  {rfiDetails?.RFI?.Response}
                </Grid>
                {rfiDetails?.RFI?.ResponseFiles?.map((file) => (
                  <Grid
                    item
                    container
                    xs={12}
                    key={file.FileId}
                    gap={2}
                    paddingTop={2}
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                    onClick={() => {
                      window.open(file.URL);
                    }}
                  >
                    <Grid item>
                      <img src={FileIcon} alt="b"></img>
                    </Grid>
                    <Grid item className={classes["Actualdata"]}>
                      {file.DisplayName}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            ) : (
              ""
            )}
            {respond ? (
              <Grid item xs={10.5} marginTop={4}>
                <Grid item xs={12} className={classes["DataTitle"]}>
                  Response:
                </Grid>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: false,
                  }}
                  rows={6}
                  multiline
                  InputProps={{ className: classes.DescriptionfieldDesign }}
                  size="small"
                  label={""}
                  value={response}
                  variant="outlined"
                  error={responseError}
                  helperText={responseErrorMessage}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setResponseError(true);
                      setResponseErrorMessage("Enter a Response");
                    }
                  }}
                  onChange={(e) => {
                    setResponse(e.target.value);
                    if (e.target.value.length > 255) {
                      setResponseError(true);
                      setResponseErrorMessage(
                        "Response must be less than 255 char"
                      );
                    } else {
                      setResponseError(false);
                      setResponseErrorMessage(null);
                    }
                  }}
                />
                <Grid item xs={5} marginTop={1}>
                  <BlueTip
                    title={"You can add multiple files"}
                    placement="bottom-start"
                    interactive="true"
                  >
                    <Button
                      fullWidth
                      component="label"
                      className={classes["fieldUploadDesign"]}
                      sx={{ backgroundColor: "#f3f3f3" }}
                    >
                      <Grid container item xs={12}>
                        <Grid item xs={11} className={classes["fileText"]}>
                          Upload File
                        </Grid>
                        <Grid item xs={1} paddingTop={0.5}>
                          <img src={uploadIcon} alt={"Upload"} />
                        </Grid>
                      </Grid>
                      <input
                        type="file"
                        hidden
                        onChange={(e) => {
                          handleFileChange(e);
                        }}
                      />
                    </Button>
                  </BlueTip>
                </Grid>
                <Grid item md={12} xs={12} paddingTop={1}>
                  {files.map((item) => (
                    <Grid
                      container
                      direction={"row"}
                      key={item.FileId}
                      value={item}
                    >
                      <Grid item xs={1} paddingLeft={1}>
                        <img src={fileIcon} alt="file" width={15} />
                      </Grid>
                      <Grid
                        item
                        xs={2.5}
                        fontSize={{ xs: 10, md: 14 }}
                        onMouseOver={(e) => {
                          e.target.style.color = "grey";
                          e.target.style.cursor = "pointer";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.color = "black";
                        }}
                        onClick={() => {
                          window.open(item.URL);
                        }}
                      >
                        <span>{item.DisplayName.slice(0, 11)}</span>
                      </Grid>
                      <Grid
                        item
                        paddingLeft={2}
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                        }}
                        onClick={() => {
                          handleFileDelete(item.FileId);
                        }}
                      >
                        <Grid item xs={1}>
                          <CloseIcon fontSize="small" />
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={12} md={5} marginTop={4} marginLeft={{ md: 0, xs: 2 }}>
            <Grid container item xs={12}>
              <Grid container item xs={5}>
                <Grid item xs={12} className={classes["DataTitle"]}>
                  Status:
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={classes["Actualdata"]}
                  paddingTop={1}
                >
                  {rfiDetails?.RFI?.Status}
                </Grid>
              </Grid>
              <Grid container item xs={5}>
                <Grid item xs={12} className={classes["DataTitle"]}>
                  Assignee:
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={classes["Actualdata"]}
                  paddingTop={1}
                >
                  {rfiDetails?.RFI?.Assignee}
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} paddingTop={5}>
              <Grid container item xs={5}>
                <Grid item xs={12} className={classes["DataTitle"]}>
                  Reference:
                </Grid>
                {rfiDetails?.RFI?.Documents?.map((file) => (
                  <Grid
                    item
                    container
                    xs={12}
                    key={file.Id}
                    gap={2}
                    paddingTop={2}
                  >
                    <Grid item className={classes["Actualdata"]}>
                      {file.Name}
                    </Grid>
                    <Grid
                      item
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                      onClick={() => {
                        setDocumentId(file.Id);
                        setOpen(true);
                      }}
                    >
                      <img src={BlueEye} alt="b"></img>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={5}>
                <Grid item xs={12} className={classes["DataTitle"]}>
                  File Uploads:
                </Grid>
                {rfiDetails?.RFI?.Files?.map((file) => (
                  <Grid
                    item
                    container
                    xs={12}
                    key={file.Id}
                    gap={2}
                    paddingTop={2}
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                    onClick={() => {
                      window.open(file.URL);
                    }}
                  >
                    <Grid item>
                      <img src={FileIcon} alt="b"></img>
                    </Grid>
                    <Grid item className={classes["Actualdata"]}>
                      {file.DisplayName}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          {respond ? (
            <Grid
              item
              container
              xs={12}
              gap={3}
              paddingRight={5}
              direction={"row-reverse"}
            >
              <Grid item>
                <button
                  className={classes["SendButton"]}
                  disabled={response ? false : true}
                  onClick={() => {
                    respondTheRfi();
                  }}
                >
                  {sending ? "Sending..." : "Send"}
                </button>
              </Grid>
              <Grid item>
                <button
                  className={classes["cancelButton"]}
                  onClick={() => {
                    setRespond(false);
                  }}
                >
                  Cancel
                </button>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        <DocumentModal
          openModal={open}
          setOpenModal={setOpen}
          documentId={documentId}
        />
      </Grid>
    </div>
  );
};
const BlueTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
      color:#0674b9;
      background-color: rgba(6, 116, 185, 0.3);
      font-size: 13px;
      width:180px;
   
  `);
const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
      font-family: Muli;
      font-size: 17px;
      box-sizing: border-box;
      width: 100%;
      background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border: 1px solid #f3f3f3;
      border-radius: 5px;
      text-align: left;
      color: #bcbcbc;
      padding:13px;
      &:hover {
        background: ${theme.palette.mode === "dark" ? "" : grey[100]};
        border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
      }
    
      &.${selectUnstyledClasses.focusVisible} {
        outline: 3px solid ${
          theme.palette.mode === "dark" ? blue[600] : blue[100]
        };
      }
    
      &.${selectUnstyledClasses.expanded} {
        &::after {
          content: '▴';
        }
      }
    
      &::after {
        content: '▾';
        float: right;
      }
      `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
      font-family: Muli;
      font-size: 0.875rem;
      box-sizing: border-box;
      padding: 5px;
      margin: 10px 0;
      width:14vw;
      background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[800] : grey[300]
      };
      border-radius: 0.75em;
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      overflow: auto;
      outline: 0px;
      `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
      list-style: none;
      padding: 8px;
      border-radius: 0.45em;
      cursor: default;
    
      &:last-of-type {
        border-bottom: none;
      }
    
      &.${optionUnstyledClasses.selected} {
        background-color: ${
          theme.palette.mode === "dark" ? blue[900] : blue[100]
        };
        color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
      }
    
      &.${optionUnstyledClasses.highlighted} {
        background-color: ${
          theme.palette.mode === "dark" ? grey[800] : grey[100]
        };
        color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      }
    
      &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
        background-color: ${
          theme.palette.mode === "dark" ? blue[900] : blue[100]
        };
        color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
      }
    
      &.${optionUnstyledClasses.disabled} {
        color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
      }
    
      &:hover:not(.${optionUnstyledClasses.disabled}) {
        background-color: ${
          theme.palette.mode === "dark" ? grey[800] : grey[100]
        };
        color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      }
      `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});
export default RFIResponse;
