import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import classes from "../../../Assets/Styles/profileDetails.module.css";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, Select, Tooltip } from "@mui/material";
import * as profileServices from "../../../Services/profile-services.proxy";
import { styled } from "@mui/material/styles";
import infoTip from "../../../../Assets/Images/form/info.png";
import { useNavigate } from "react-router-dom";
import Animation from "../../../../Components/Home/Animation";
import PhoneInput from "react-phone-input-2";

const mainContarctorEditDetails = profileServices.mainContarctorEditDetails;
const updateProfile = profileServices.updateProfile;
const mainUpdate = profileServices.mainUpdate;
const deleteOldFile = profileServices.deleteOldFile;
const subContarctorEditDetails = profileServices.subContarctorEditDetails;
const updateSubProfile = profileServices.updateSubProfile;
const subUpdate = profileServices.subUpdate;
const deleteOldSubFile = profileServices.deleteOldSubFile;
const saveProfilePicture = profileServices.saveProfilePicture;

const getTeamInputData = profileServices.getTMProfileInputData;
const updateTeam = profileServices.updateTMProfileInputData;

const BlueTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
      color: #101650;
      background-color: rgba(6, 116, 185, 0.3);
      font-size: 13px;
      width:168px;
  `);

const styles = {
  main: {
    fontFamily: "Muli",
    fontSize: 22,
    fontWeight: "bold",
    lineHeight: 1.5,
    textAlign: "left",
    color: "#101650",
    paddingTop: "1%",
  },
  fieldsNames: {
    fontFamily: "Muli",
    fontSize: "12px",
    fontWeight: 300,
    lineHeight: 1.5,
    textAlign: "right",
    color: "#101650",
  },
  responseMsg: {
    fontfamily: "Muli",
    fontsize: "14px",
    fontweight: "bold",
    lineheight: 1.43,
    textalign: "left",
    color: "rgba(16, 22, 80, 0.7)",
  },
  responseErrorMsg: {
    fontfamily: "Muli",
    fontsize: "14px",
    fontweight: "bold",
    lineheight: 1.43,
    textalign: "left",
    color: "rgba(255, 0, 0, 0.48)",
  },
  requiredInfo: {
    fontFamily: "Muli",
    fontSize: "12px",
    fontWeight: 300,
    lineHeight: 1.5,
    textAlign: "right",
    color: "rgba(16, 22, 80, 0.7)",
  },
  errorFieldsNames: {
    fontFamily: "Muli",
    fontSize: "12px",
    fontWeight: 300,
    lineHeight: 1.5,
    textAlign: "right",
    color: "#ea001e",
  },
};

export default function EditProfile({
  active5,
  setActive5,
  active,
  setActive,
  setCn,
  cn,
  setProfileSrc,
}) {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const AccountType = localStorage.getItem("AccountType");
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [registrationEmail, setRegistrationEmail] = useState("");
  const [registrationNumber, setregistrationNumber] = useState("");
  const [address, setAddress] = useState("");

  const [companyPhoneNumber, setCompanyPhoneNumber] = useState("");

  const [contactName, setContactName] = useState("");
  const [position, setPosition] = useState("");
  const [personalPhoneNumber, setPersonalPhoneNumber] = useState("");
  const [primaryEmail, setPrimaryEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [slogan, setSlogan] = useState("");
  const [countries, setCountries] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [country, setCountry] = useState("");
  const [employee, setEmployee] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [otherSpeciality, setOtherSpeciality] = useState("");
  const [industryType, setIndustryType] = useState("");
  const [subIndustryType, setSubIndustryType] = useState("");
  const [otherIndustryType, setOtherIndustryType] = useState("");
  const [discipline, setDiscipline] = useState("");
  const [trade, setTrade] = useState("");
  const [file, setFile] = useState(null);
  const [companyError, setCompanyError] = useState(false);
  const [companyInvalid, setCompanyInvalid] = useState(false);
  const [registrationNumberError, setRegistrationNumberError] = useState(false);
  const [registrationNumberInvalid, setRegistrationNumberInvalid] =
    useState(false);
  const [AddressError, setAddressError] = useState(false);
  const [AddressInvalid, setAddressInvalid] = useState(false);

  const [companyPhoneNumberError, setCompanyPhoneNumberError] = useState(false);

  const [specialityError, setSpecialityError] = useState(false);
  const [specialityInvalid, setSpecialityInvalid] = useState(false);
  const [positionError, setPositionError] = useState(false);
  const [positionInvalid, setPositionInvalid] = useState(false);
  const [contactNameError, setContactNameError] = useState(false);
  const [contactNameInvalid, setContactNameInvalid] = useState(false);
  const [personalPhoneNumberError, setPersonalPhoneNumberError] =
    useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [websiteInvalid, setWebsiteInvalid] = useState(false);
  const [sloganInvalid, setSloganInvalid] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [fileId, setFileId] = useState(null);
  const [previousFileId, setPreviousFileId] = useState(null);
  const [isDone, setIsDone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let apiTest = true;
    if (localStorage.getItem("isTeamMember") !== "true") {
      if (localStorage.getItem("AccountType") === "1") {
        mainContarctorEditDetails().then((x) => {
          if (apiTest) {
            setData(x);
            setCompanyName(x.MainContactorCompanyDetails.CompanyName);
            setRegistrationEmail(x.MainContactorCompanyDetails.CompanyEmail);
            setCountries(x.Countries);
            setCountry(x.MainContactorCompanyDetails.CountryId);
            setregistrationNumber(
              x.MainContactorCompanyDetails.RegistrationNumber
            );
            setEmployees(x.EmployeesRanges);
            setEmployee(x.MainContactorCompanyDetails.EmployeesRangeId);
            setAddress(x.MainContactorCompanyDetails.Address);
            setCompanyPhoneNumber(
              x.MainContactorCompanyDetails.CompanyPhoneNumber
            );
            setSpecialities(x.Specialities);
            setSpeciality(x.MainContactorCompanyDetails.SpecialityId);
            setWebsite(x.MainContactorCompanyDetails.Website);
            setSlogan(x.MainContactorCompanyDetails.Slogan);
            setFile(x.MainContactorCompanyDetails.UserDetails.ProfilePicture);
            if (file)
              setFileId(
                x.MainContactorCompanyDetails.UserDetails.ProfilePicture.FileId
              );
            if (x.MainContactorCompanyDetails.SpecialityOther)
              setOtherSpeciality(x.MainContactorCompanyDetails.SpecialityOther);
            setContactName(x.MainContactorCompanyDetails.UserDetails.Name);
            setPosition(x.MainContactorCompanyDetails.UserDetails.Position);
            setPersonalPhoneNumber(
              x.MainContactorCompanyDetails.UserDetails.PhoneNumber
            );
            setPrimaryEmail(
              x.MainContactorCompanyDetails.UserDetails.PersonalEmail
            );
            setWebsite(x.MainContactorCompanyDetails.Website);
            setSlogan(x.MainContactorCompanyDetails.Slogan);
          }
        });
      } else if (localStorage.getItem("AccountType") === "2") {
        subContarctorEditDetails().then((x) => {
          if (apiTest) {
            setData(x);
            setCompanyName(x.SubContactorCompanyDetails.Name);
            setRegistrationEmail(
              x.SubContactorCompanyDetails.RegistrationEmail
            );
            setCountries(x.Countries);
            setCountry(x.SubContactorCompanyDetails.CountryId);
            setregistrationNumber(
              x.SubContactorCompanyDetails.RegistrationNumber
            );
            setEmployees(x.EmployeesRanges);
            setEmployee(x.SubContactorCompanyDetails.EmployeesRangeId);
            setAddress(x.SubContactorCompanyDetails.Address);
            setCompanyPhoneNumber(
              x.SubContactorCompanyDetails.CompanyPhoneNumber
            );
            setIndustries(x.IndustryType);
            setIndustryType(x.SubContactorCompanyDetails.IndustryTypeId);
            if (x.SubContactorCompanyDetails.IndustryTypeOther)
              setOtherIndustryType(
                x.SubContactorCompanyDetails.IndustryTypeOther
              );
            if (x.SubContactorCompanyDetails.SubIndustryTypeId)
              setSubIndustryType(
                x.SubContactorCompanyDetails.SubIndustryTypeId
              );
            if (x.SubContactorCompanyDetails.DisciplineCoveredOther)
              setDiscipline(
                x.SubContactorCompanyDetails.DisciplineCoveredOther
              );
            else setDiscipline(x.SubContactorCompanyDetails.DisciplineCovered);
            if (x.SubContactorCompanyDetails.TradeOther)
              setTrade(x.SubContactorCompanyDetails.TradeOther);
            else setTrade(x.SubContactorCompanyDetails.Trade);
            setWebsite(x.SubContactorCompanyDetails.Website);
            setSlogan(x.SubContactorCompanyDetails.Slogan);
            setFile(x.SubContactorCompanyDetails.File);
            if (file) setFileId(x.SubContactorCompanyDetails.File.FileId);
            setContactName(x.SubContactorCompanyDetails.UserDetails.Name);
            setPosition(x.SubContactorCompanyDetails.UserDetails.Position);
            setPersonalPhoneNumber(
              x.SubContactorCompanyDetails.UserDetails.PhoneNumber
            );
            setPrimaryEmail(
              x.SubContactorCompanyDetails.UserDetails.PersonalEmail
            );
            setWebsite(x.SubContactorCompanyDetails.Website);
            setSlogan(x.SubContactorCompanyDetails.Slogan);
          }
        });
      } else {
        console.log("");
      }
    } else {
      getTeamInputData().then((x) => {
        setData(x);
        if (apiTest) {
          setCountries(x.Countries);
          setCountry(x.TeamMember.CountryId);
          setCompanyName(x.TeamMember.Name);
          setRegistrationEmail(x.TeamMember.Email);
          setAddress(x.TeamMember.Address);
          setCompanyPhoneNumber(x.TeamMember.PhoneNumber);
        }
      });
    }
    return () => {
      apiTest = false;
    };
  }, []);

  useEffect(() => {
    if (
      specialities &&
      speciality &&
      speciality !== specialities.find((x) => x.Name === "Others").Id
    ) {
      setSpecialityError(false);
      setSpecialityInvalid(false);
    }
  }, [speciality]);

  useEffect(() => {
    if (
      industries &&
      industryType &&
      industryType !== industries.find((x) => x.Name === "Others").Id
    ) {
      setSpecialityError(false);
      setSpecialityInvalid(false);
    }
  }, [industryType]);

  useEffect(() => {
    if (
      companyError ||
      companyInvalid ||
      registrationNumberError ||
      registrationNumberInvalid ||
      AddressError ||
      AddressInvalid ||
      specialityError ||
      specialityInvalid ||
      positionError ||
      positionInvalid ||
      contactNameError ||
      contactNameInvalid ||
      emailError ||
      emailInvalid ||
      websiteInvalid ||
      sloganInvalid ||
      companyPhoneNumberError
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });

  useEffect(() => {
    if (isDisabled) {
      setResponseMsg("Please enter the correct data to continue.");
    } else {
      if (isDone) {
        setResponseMsg("Your settings have been successfully saved.");
      } else {
        setResponseMsg("");
      }
    }
  }, [isDisabled]);

  const handleFileMainChange = (e) => {
    if (previousFileId) {
      deleteOldFile(previousFileId);
    }
    if (e.target.files[0]) {
      if (fileId) setPreviousFileId(fileId);

      updateProfile(e.target.files[0]).then((x) => {
        setFile(x);
        setFileId(x.FileId);
      });
    }
  };

  const handleFileSubChange = (e) => {
    if (previousFileId) {
      deleteOldSubFile(previousFileId);
    }
    if (e.target.files[0]) {
      if (fileId) setPreviousFileId(fileId);

      updateSubProfile(e.target.files[0]).then((x) => {
        setFile(x);
        setFileId(x.FileId);
      });
    }
  };

  const updateMainData = {
    CompanyName: companyName,
    RegistrationNumber: registrationNumber,
    Address: address,
    CompanyPhoneNumber: companyPhoneNumber,
    Website: website,
    Slogan: slogan,
    File: {
      FileId: fileId,
    },
    CountryId: country,
    SpecialityId: speciality,
    SpecialityOther: otherSpeciality ? otherSpeciality : null,
    EmployeesRangeId: employee,
    UserDetails: {
      Name: contactName,
      PersonalEmail: primaryEmail,
      Position: position,
      PhoneNumber: personalPhoneNumber,
    },
  };

  const updateSubData = {
    Name: companyName,
    RegistrationNumber: registrationNumber,
    Address: address,
    CompanyPhoneNumber: companyPhoneNumber,
    Website: website,
    Slogan: slogan,
    File: {
      FileId: fileId,
    },
    CountryId: country,
    IndustryTypeId: industryType,
    SubIndustryTypeId: subIndustryType ? subIndustryType : null,
    IndustryTypeOther: otherIndustryType ? otherIndustryType : null,
    EmployeesRangeId: employee,
    UserDetails: {
      Name: contactName,
      PersonalEmail: primaryEmail,
      Position: position,
      PhoneNumber: personalPhoneNumber,
    },
  };

  const updateTMData = {
    Name: companyName,
    CountryId: country,
    Address: address,
    PhoneNumber: companyPhoneNumber,
  };

  const save = () => {
    setIsLoading(true);
    if (file) localStorage.setItem("ProfileUrl", file?.URL);
    if (fileId === null) {
      localStorage.removeItem("ProfileUrl");
    }
    saveProfilePicture(fileId);
    if (localStorage.getItem("isTeamMember") !== "true") {
      if (AccountType === "1") {
        mainUpdate(updateMainData).then((x) => {
          setIsDone(true);
          setIsLoading(false);

          setResponseMsg("Your settings have been successfully saved.");
          localStorage.setItem("companyName", updateMainData.CompanyName);
          setCn(!cn);
          setTimeout(() => {
            setResponseMsg("");
          }, 3500);
        });
      } else {
        subUpdate(updateSubData).then((x) => {
          setIsDone(true);
          setIsLoading(false);
          setResponseMsg("Your settings have been successfully saved.");
          localStorage.setItem("companyName", updateSubData.Name);
          setCn(!cn);
          setTimeout(() => {
            setResponseMsg("");
          }, 3500);
        });
      }
    } else {
      updateTeam(updateTMData).then((x) => {
        setIsDone(true);
        setIsLoading(false);
        setResponseMsg("Your settings have been successfully saved.");
        localStorage.setItem("companyName", updateTMData.Name);
        setCn(!cn);
        setTimeout(() => {
          setResponseMsg("");
        }, 3500);
      });
    }
  };
  return (
    <Grid container paddingLeft="3%" paddingBottom="3%" gap={1}>
      <Grid item xs={12} sx={styles.main}>
        My Profile
      </Grid>
      {data ? (
        <>
          <Grid item xs={12} container>
            <Grid item xs={12} marginLeft={0.5}>
              <span className={classes.infoSectionTitle}>Details</span>
            </Grid>
            <Grid item xs={12} container direction={"row"}>
              <div
                className={classes["Line-356"]}
                style={{
                  backgroundColor:
                    localStorage.getItem("isTeamMember") !== "true"
                      ? AccountType === "1"
                        ? "#e1e319"
                        : "#2da4d0"
                      : "#8BBFE24D",
                  borderColor:
                    localStorage.getItem("isTeamMember") !== "true"
                      ? AccountType === "1"
                        ? "#e1e319"
                        : "#2da4d0"
                      : "#8BBFE24D",
                }}
              ></div>
              <div
                className={classes["Line-355"]}
                style={{ width: "90%" }}
              ></div>
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs={9.5}></Grid>
              <Grid item sx={styles.requiredInfo}>
                * = Required Information
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              alignItems="flex-start"
              padding="2% 0 0.5% 1%"
              gap={1}
            >
              <Grid item xs={3} sx={styles.fieldsNames}>
                *Company Name
              </Grid>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  value={companyName}
                  size="small"
                  error={companyError}
                  helperText={
                    companyError
                      ? companyInvalid
                        ? "Company name must be less than 150 characters"
                        : "Enter your company name"
                      : ""
                  }
                  onChange={(e) => {
                    setCompanyName(e.target.value);
                    if (e.target.value === "") {
                      setCompanyError(true);
                      setCompanyInvalid(false);
                    } else if (e.target.value.length > 150) {
                      setCompanyError(true);
                      setCompanyInvalid(true);
                    } else {
                      setCompanyError(false);
                      setCompanyInvalid(false);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid
                item
                xs={3}
                sx={styles.fieldsNames}
                style={{ color: "#b8b8b8" }}
              >
                Registration Email
              </Grid>
              <Grid item xs={5}>
                <TextField
                  value={registrationEmail}
                  size="small"
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={2.5}
                onMouseOver={() => setTooltipIsOpen(true)}
                onMouseLeave={() => setTooltipIsOpen(false)}
              >
                <BlueTip
                  open={tooltipIsOpen}
                  title={"The registration email cannot be changed "}
                  placement="right"
                >
                  <img
                    width="20px"
                    src={infoTip}
                    alt="info"
                    style={{ opacity: 0.5 }}
                  ></img>
                </BlueTip>
              </Grid>
              <Grid item xs={3} sx={styles.fieldsNames}>
                *Country
              </Grid>
              <Grid item xs={5}>
                <FormControl style={{ width: "100%" }}>
                  <Select
                    MenuProps={{ disableScrollLock: true }}
                    value={country}
                    size="small"
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    {countries
                      ? countries.map((c) => (
                        <MenuItem key={c.Id} value={c.Id}>
                          {c.Name}
                        </MenuItem>
                      ))
                      : ""}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1}></Grid>
              {localStorage.getItem("isTeamMember") !== "true" ? (
                <>
                  <Grid item xs={3} sx={styles.fieldsNames}>
                    *Registration Number
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      value={registrationNumber}
                      size="small"
                      error={registrationNumberError}
                      helperText={
                        registrationNumberError
                          ? registrationNumberInvalid
                            ? "Registration number must be less than 30 characters"
                            : "Enter yor registration number"
                          : ""
                      }
                      onChange={(e) => {
                        setregistrationNumber(e.target.value);
                        if (e.target.value === "") {
                          setRegistrationNumberError(true);
                          setRegistrationNumberInvalid(false);
                        } else if (e.target.value.length > 30) {
                          setRegistrationNumberError(true);
                          setRegistrationNumberInvalid(true);
                        } else {
                          setRegistrationNumberError(false);
                          setRegistrationNumberInvalid(false);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={3} sx={styles.fieldsNames}>
                    *Employees
                  </Grid>
                  <Grid item xs={5}>
                    <FormControl style={{ width: "100%" }}>
                      <Select
                        MenuProps={{ disableScrollLock: true }}
                        value={employee}
                        onChange={(e) => setEmployee(e.target.value)}
                        size="small"
                      >
                        {employees
                          ? employees.map((e) => (
                            <MenuItem key={e.Id} value={e.Id}>
                              {e.Name}
                            </MenuItem>
                          ))
                          : ""}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={1}></Grid>
                </>
              ) : (
                <></>
              )}
              <Grid item xs={3} sx={styles.fieldsNames}>
                *Address
              </Grid>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  value={address}
                  size="small"
                  error={AddressError}
                  helperText={
                    AddressError
                      ? AddressInvalid
                        ? "Address must be less than 150 characters"
                        : "Enter your company address"
                      : ""
                  }
                  onChange={(e) => {
                    setAddress(e.target.value);

                    if (e.target.value === "") {
                      setAddressError(true);
                      setAddressInvalid(false);
                    } else if (e.target.value.length > 150) {
                      setAddressError(true);
                      setAddressInvalid(true);
                    } else {
                      setAddressError(false);
                      setAddressInvalid(false);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3} sx={styles.fieldsNames}>
                *Phone Number
              </Grid>
              <Grid item xs={5}>
                <PhoneInput
                  enableAreaCodes={true}
                  value={companyPhoneNumber}
                  onChange={(phone) => {
                    setCompanyPhoneNumber(phone);
                    if (phone === "" || phone.length < 7 || phone.length > 25) {
                      setCompanyPhoneNumberError(true);
                    } else {
                      setCompanyPhoneNumberError(false);
                    }
                  }}
                  country={"lb"}
                  style={{
                    borderColor: companyPhoneNumberError ? "#ea001e" : "",
                  }}
                  inputStyle={{
                    borderColor: companyPhoneNumberError ? "#ea001e" : "",
                    width: "100%",
                    height: 15,
                    fontFamily: "Muli",
                    fontsize: "16px",
                  }}
                />
                {companyPhoneNumberError ? (
                  <span
                    style={{
                      fontFamily: "Muli",
                      color: "#ea001e",
                      fontSize: "12px",
                    }}
                  >
                    Phone number must be between 7 and 25 characters
                  </span>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item xs={1}></Grid>
              {localStorage.getItem("isTeamMember") !== "true" ? (
                <>
                  {AccountType === "1" ? (
                    <>
                      <Grid item xs={3} sx={styles.fieldsNames}>
                        *Speciality
                      </Grid>
                      <Grid item xs={5}>
                        <FormControl style={{ width: "100%" }}>
                          <Select
                            MenuProps={{ disableScrollLock: true }}
                            value={speciality}
                            onChange={(e) => {
                              setSpeciality(e.target.value);
                              if (
                                e.target.value !==
                                specialities.find((x) => x.Name === "Others").Id
                              )
                                setOtherSpeciality("");
                            }}
                            size="small"
                          >
                            {specialities
                              ? specialities.map((s) => (
                                <MenuItem key={s.Id} value={s.Id}>
                                  {s.Name}
                                </MenuItem>
                              ))
                              : ""}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={1}></Grid>
                      {specialities && speciality ? (
                        speciality ===
                          specialities.find((x) => x.Name === "Others").Id ? (
                          <>
                            <Grid item xs={3} sx={styles.fieldsNames}>
                              *Other Speciality
                            </Grid>
                            <Grid item xs={5}>
                              <TextField
                                fullWidth
                                value={otherSpeciality}
                                size="small"
                                error={specialityError}
                                helperText={
                                  specialityError
                                    ? specialityInvalid
                                      ? "Speciality must be less than 150 characters"
                                      : "Enter your speciality"
                                    : ""
                                }
                                onChange={(e) => {
                                  setOtherSpeciality(e.target.value);
                                  if (e.target.value === "") {
                                    setSpecialityError(true);
                                    setSpecialityInvalid(false);
                                  } else if (e.target.value.length > 150) {
                                    setSpecialityError(true);
                                    setSpecialityInvalid(true);
                                  } else {
                                    setSpecialityError(false);
                                    setSpecialityInvalid(false);
                                  }
                                }}
                              />
                            </Grid>
                            <Grid item xs={1}></Grid>
                          </>
                        ) : (
                          <></>
                        )
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <>
                      <Grid item xs={3} sx={styles.fieldsNames}>
                        *Industry Type
                      </Grid>
                      <Grid item xs={5}>
                        <FormControl style={{ width: "100%" }}>
                          <Select
                            MenuProps={{ disableScrollLock: true }}
                            value={industryType}
                            onChange={(e) => {
                              setIndustryType(e.target.value);
                              if (
                                e.target.value !==
                                industries.find(
                                  (x) => x.SubIndustryTypes.length > 0
                                ).Id
                              )
                                setSubIndustryType("");
                              if (
                                e.target.value !==
                                industries.find((x) => x.Name === "Others").Id
                              )
                                setOtherIndustryType("");
                            }}
                            size="small"
                          >
                            {industries
                              ? industries.map((i) => (
                                <MenuItem key={i.Id} value={i.Id}>
                                  {i.Name}
                                </MenuItem>
                              ))
                              : ""}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={1}></Grid>
                      {industries && industryType ? (
                        industryType ===
                          industries.find((x) => x.Name === "Others").Id ? (
                          <>
                            <Grid item xs={3} sx={styles.fieldsNames}>
                              *Other Industry
                            </Grid>
                            <Grid item xs={5}>
                              <TextField
                                fullWidth
                                value={
                                  otherIndustryType ? otherIndustryType : ""
                                }
                                size="small"
                                error={specialityError}
                                helperText={
                                  specialityError
                                    ? specialityInvalid
                                      ? "Industry must be less than 150 characters"
                                      : "Enter your industry"
                                    : ""
                                }
                                onChange={(e) => {
                                  setOtherIndustryType(e.target.value);
                                  if (e.target.value === "") {
                                    setSpecialityError(true);
                                    setSpecialityInvalid(false);
                                  } else if (e.target.value.length > 150) {
                                    setSpecialityError(true);
                                    setSpecialityInvalid(true);
                                  } else {
                                    setSpecialityError(false);
                                    setSpecialityInvalid(false);
                                  }
                                }}
                              />
                            </Grid>
                            <Grid item xs={1}></Grid>
                          </>
                        ) : industryType ===
                          industries.find((x) => x.SubIndustryTypes.length > 0)
                            .Id ? (
                          <>
                            <Grid item xs={3} sx={styles.fieldsNames}>
                              *SubIndustry Type
                            </Grid>
                            <Grid item xs={5}>
                              <FormControl style={{ width: "100%" }}>
                                <Select
                                  MenuProps={{ disableScrollLock: true }}
                                  value={subIndustryType ? subIndustryType : 1}
                                  onChange={(e) =>
                                    setSubIndustryType(e.target.value)
                                  }
                                  size="small"
                                >
                                  {industries.find((x) => x.Id === industryType)
                                    ? industries
                                      .find((x) => x.Id === industryType)
                                      .SubIndustryTypes.map((i) => (
                                        <MenuItem key={i.Id} value={i.Id}>
                                          {i.Name}
                                        </MenuItem>
                                      ))
                                    : ""}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={1}></Grid>
                          </>
                        ) : (
                          ""
                        )
                      ) : (
                        " "
                      )}
                      <Grid
                        item
                        xs={3}
                        sx={styles.fieldsNames}
                        style={{ color: "#b8b8b8" }}
                      >
                        Discipline Covered
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          value={discipline}
                          size="small"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={1}></Grid>
                      <Grid
                        item
                        xs={3}
                        sx={styles.fieldsNames}
                        style={{ color: "#b8b8b8" }}
                      >
                        Trade
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          value={trade}
                          size="small"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={1}></Grid>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
          {localStorage.getItem("isTeamMember") !== "true" ? (
            <Grid item xs={12} container>
              <Grid item xs={12} marginLeft={0.5}>
                <span className={classes.infoSectionTitle}>
                  Primary Contact Information
                </span>
              </Grid>
              <Grid item xs={12} container direction={"row"}>
                <div
                  className={classes["Line-356"]}
                  style={{
                    backgroundColor:
                      AccountType === "1" ? "#e1e319" : "#2da4d0",
                    borderColor: AccountType === "1" ? "#e1e319" : "#2da4d0",
                    width: "25%",
                  }}
                ></div>
                <div
                  className={classes["Line-355"]}
                  style={{ width: "72%" }}
                ></div>
              </Grid>
              <Grid
                item
                xs={12}
                container
                alignItems="flex-start"
                padding="2% 0 0.5% 1%"
                gap={1}
              >
                <Grid item xs={3} sx={styles.fieldsNames}>
                  *Contact Name
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    value={contactName}
                    size="small"
                    error={contactNameError}
                    helperText={
                      contactNameError
                        ? contactNameInvalid
                          ? "Contact name must be less than 64 characters"
                          : "Enter your contact name"
                        : ""
                    }
                    onChange={(e) => {
                      setContactName(e.target.value);

                      if (e.target.value === "") {
                        setContactNameError(true);
                        setContactNameInvalid(false);
                      } else if (e.target.value.length > 64) {
                        setContactNameError(true);
                        setContactNameInvalid(true);
                      } else {
                        setContactNameError(false);
                        setContactNameInvalid(false);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={3} sx={styles.fieldsNames}>
                  *Position
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    value={position}
                    size="small"
                    error={positionError}
                    helperText={
                      positionError
                        ? positionInvalid
                          ? "Postion must be less than 20 characters"
                          : "Enter your position"
                        : ""
                    }
                    onChange={(e) => {
                      setPosition(e.target.value);
                      if (e.target.value === "") {
                        setPositionError(true);
                        setPositionInvalid(false);
                      } else if (e.target.value.length > 20) {
                        setPositionError(true);
                        setPositionInvalid(true);
                      } else {
                        setPositionError(false);
                        setPositionInvalid(false);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={3} sx={styles.fieldsNames}>
                  *Phone Number
                </Grid>
                <Grid item xs={5}>
                  <PhoneInput
                    enableAreaCodes={true}
                    value={personalPhoneNumber}
                    onChange={(phone) => {
                      setPersonalPhoneNumber(phone);
                      if (
                        phone === "" ||
                        phone.length < 7 ||
                        phone.length > 25
                      ) {
                        setPersonalPhoneNumberError(true);
                      } else {
                        setPersonalPhoneNumberError(false);
                      }
                    }}
                    country={"lb"}
                    style={{
                      borderColor: personalPhoneNumberError ? "#ea001e" : "",
                    }}
                    inputStyle={{
                      borderColor: personalPhoneNumberError ? "#ea001e" : "",
                      width: "100%",
                      height: 15,
                      fontFamily: "Muli",
                      fontsize: "16px",
                    }}
                  />
                  {personalPhoneNumberError ? (
                    <span
                      style={{
                        fontFamily: "Muli",
                        color: "#ea001e",
                        fontSize: "12px",
                      }}
                    >
                      Phone number must be between 7 and 25 characters
                    </span>
                  ) : (
                    <></>
                  )}
                  {/* <TextField
                  fullWidth
                  value={personalPhoneNumber}
                  size="small"
                  error={personalPhoneNumberError}
                  helperText={
                    personalPhoneNumberError
                      ? personalPhoneNumberInvalid
                        ? "Phone number must be between 7 and 25 characters"
                        : "Enter your company phone number"
                      : ""
                  }
                  onChange={(e) => {
                    setPersonalPhoneNumber(e.target.value);
                    if (e.target.value === "") {
                      setPersonalPhoneNumberError(true);
                      setPersonalPhoneNumberInvalid(false);
                    } else if (
                      e.target.value.length < 7 ||
                      e.target.value.length > 25
                    ) {
                      setPersonalPhoneNumberError(true);
                      setPersonalPhoneNumberInvalid(true);
                    } else {
                      setPersonalPhoneNumberError(false);
                      setPersonalPhoneNumberInvalid(false);
                    }
                  }}
                /> */}
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={3} sx={styles.fieldsNames}>
                  *Primary Email
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    value={primaryEmail}
                    size="small"
                    error={emailError}
                    helperText={
                      emailError
                        ? emailInvalid
                          ? "Enter a valid email"
                          : "Enter an email adress"
                        : ""
                    }
                    onChange={(e) => {
                      setPrimaryEmail(e.target.value);
                      if (e.target.value === "") {
                        setEmailError(true);
                        setEmailInvalid(false);
                      } else if (
                        !e.target.value.match(
                          /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/
                        ) ||
                        e.target.value > 64
                      ) {
                        setEmailError(true);
                        setEmailInvalid(true);
                      } else {
                        setEmailError(false);
                        setEmailInvalid(false);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
          {localStorage.getItem("isTeamMember") === "true" ? (
            <></>
          ) : (
            <Grid item xs={12} container>
              <Grid item xs={12} marginLeft={0.5}>
                <span className={classes.infoSectionTitle}>
                  Additional Information
                </span>
              </Grid>
              <Grid item xs={12} container direction={"row"}>
                <div
                  className={classes["Line-356"]}
                  style={{
                    backgroundColor:
                      localStorage.getItem("isTeamMember") !== "true"
                        ? AccountType === "1"
                          ? "#e1e319"
                          : "#2da4d0"
                        : "#8BBFE24D",
                    borderColor:
                      localStorage.getItem("isTeamMember") !== "true"
                        ? AccountType === "1"
                          ? "#e1e319"
                          : "#2da4d0"
                        : "#8BBFE24D",
                    width: "20%",
                  }}
                ></div>
                <div
                  className={classes["Line-355"]}
                  style={{ width: "78%" }}
                ></div>
              </Grid>
              <Grid
                item
                xs={12}
                container
                alignItems="flex-start"
                padding="2% 0 0.5% 1%"
                gap={1}
              >
                <Grid item xs={3} sx={styles.fieldsNames}>
                  Website
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    value={website ? website : ""}
                    size="small"
                    error={websiteInvalid}
                    helperText={websiteInvalid ? "Enter a valid website" : ""}
                    onChange={(e) => {
                      setWebsite(e.target.value);
                      if (
                        e.target.value !== "" &&
                        (!e.target.value.match(
                          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
                        ) ||
                          e.target.value > 64)
                      ) {
                        setWebsiteInvalid(true);
                      } else {
                        setWebsiteInvalid(false);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={3} sx={styles.fieldsNames} paddingBottom="10%">
                  Slogan/ Description
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    value={slogan ? slogan : ""}
                    multiline
                    rows={4}
                    size="small"
                    error={sloganInvalid}
                    helperText={
                      sloganInvalid
                        ? "Slogan/Description must be less than 255 characters"
                        : ""
                    }
                    onChange={(e) => {
                      setSlogan(e.target.value);
                      if (
                        e.target.value !== "" &&
                        e.target.value.length > 255
                      ) {
                        setSloganInvalid(true);
                      } else {
                        setSloganInvalid(false);
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} container paddingTop="1%" alignItems="flex-start">
            <Grid item xs={4}></Grid>
            <Grid
              item
              xs={5}
              sx={isDisabled ? styles.responseErrorMsg : styles.responseMsg}
            >
              {responseMsg}
            </Grid>
            <Grid item xs={3} container gap={1}>
              <Grid item>
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    backgroundColor: "white",
                    color: "rgba(16, 22, 80, 0.7)",
                    fontFamily: "Muli",
                    textTransform: "none",
                    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                  }}
                  onClick={() => {
                    if (previousFileId)
                      if (AccountType === "1")
                        deleteOldFile(previousFileId);
                      else
                        deleteOldSubFile(previousFileId);
                    setIsDone(false);
                    setActive5(false);
                    setActive(true);
                    navigate("/saas/admin");
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={isDisabled}
                  variant="contained"
                  size="small"
                  style={{
                    backgroundColor: isDisabled ? "#bcbcbc" : "#0b5cab",
                    fontFamily: "Muli",
                    textTransform: "none",
                  }}
                  onClick={save}
                >
                  {isLoading ? "Saving..." : "Save"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid container alignItems="flex-start" justifyContent="center">
          <Grid item xs={12} md={8}>
            <Animation />
          </Grid>
        </Grid>
      )}

    </Grid>
  );
}
