import React, { useEffect, useState } from "react";
import { Grid, TextField } from "@mui/material";
import classes from "../../../Assets/Styles/Activities.module.css";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { styled } from "@mui/system";
import ActivityResponsibility from "./ActivityResponsibility";
import StartAndDurationsModal from "./StartAndDurationsModal";
import CommentSection from "./CommentSections";
import TMActivity from "./TMActivity";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { useNavigate } from "react-router-dom";
import * as activityConst from "../../../../StatusConst";
import * as activityServices from "../../../Services/activities-services.proxy";
import ProgressBar from "./ProgressBar";
import * as pileCubesConst from "../../../../StatusConst";
import * as moment from "moment";
import * as salesServices from "../../../Services/sales-order-services.proxy";
import * as permissionsFunctions from "../../../../ReusableFunctions";
import { useSelector } from "react-redux";
import InvalidAccessModal from "./InvalidAccessModal";

const CheckIfPermitted = permissionsFunctions.CheckIfPermitted;
const getActivitiesCreateEditData =
  activityServices.getActivitiesCreateEditData;
const getTMBySub = activityServices.getTMBySub;
const addActivity = activityServices.addActivity;
const updateActivity = activityServices.updateActivity;
const reviseActivity = activityServices.reviseActivity;
const acceptActivity = activityServices.acceptActivity;
const tMSubmitActivity = activityServices.tMSubmitActivity;
const ActivityDetails = (props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  //const [showDocuments, setShowDocuments] = useState(true);
  const [openInvalidModal, setOpenInvalidModal] = useState(false)

  const [subProject, setSubProject] = useState(null);
  const [phase, setPhase] = useState(null);
  const [project, setProject] = useState(null);
  const [disicipline, setDisicipline] = useState(0);
  const [disiciplineError, setDisiciplineError] = useState(false);
  const [disiciplines, setDisiciplines] = useState([]);
  const [type, setType] = useState(1);
  const [typeError, setTypeError] = useState(false);
  const [types, setTypes] = useState([]);
  const [predecessor, setPredecessor] = useState(0);
  const [predecessors, setPredecessors] = useState([]);
  const [trade, setTrade] = useState(0);
  const [tradeError, setTradeError] = useState(false);
  const [trades, setTrades] = useState([]);
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState(false);
  const [codeErrorMessage, setCodeErrorMessage] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState("");
  const [drawingNB, setDrawingNB] = useState("");
  const [drawingNBError, setDrawingNBError] = useState(false);
  const [drawingNBErrorMessage, setDrawingNBErrorMessage] = useState("");
  const [numOfSheets, setNumOfSheets] = useState("");
  const [numOfSheetsError, setNumOfSheetsError] = useState(false);
  const [numOfSheetsErrorMessage, setNumOfSheetsErrorMessage] = useState("");
  const [sheetSize, setSheetSize] = useState(1);
  const [sheetSizeError, setSheetSizeError] = useState(false);
  const [sheetSizes, setSheetSizes] = useState([]);
  const [planDuration, setPlanDuration] = useState("");
  const [planDurationError, setPlanDurationError] = useState(false);
  const [planDurationErrorMessage, setPlanDurationErrorMessage] = useState("");
  const [submittalStatus, setSubmittalStatus] = useState(1);
  //const [submittalStatusError, setSubmittalStatusError] = useState(false);
  const [submittalStatuses, setSubmittalStatuses] = useState([]);
  const [status, setStatus] = useState("Draft");
  const [plannedStartDate, setPlannedStartDate] = useState("");
  const [plannedStartDateError, setPlannedStartDateError] = useState(false);
  const [plannedStartDateErrorMessage, setPlannedStartDateErrorMessage] =
    useState("");
  const [revisionNO, setRevisionNO] = useState(null);
  const [creator, setCreator] = useState(null);
  const [creatorId, setCreatorId] = useState(null);

  const [actualStartTime, setActualStartTime] = useState(null);
  const [actualStartDate, setActualStartDate] = useState(null);
  const [actualEndDate, setActualEndDate] = useState(null);
  const [actualEndTime, setActualEndTime] = useState(null);
  const [startTime, setStartTime] = useState("");
  const [startTimeError, setStartTimeError] = useState(false);
  const [startTimeErrorMessage, setStartTimeErrorMessage] = useState("");

  const [loadingData, setLoadingData] = useState(false);

  const [endTime, setEndTime] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [teamMember, setTeamMember] = useState(0);
  const [teamMemberError, setTeamMemberError] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [disableAllFields, setDisableAllFields] = useState(false);
  const [previousStartDates, setPreviousStartDates] = useState([]);
  const [previousDurations, setPreviousDurations] = useState([]);
  const [ActivityProgress, setActivityProgress] = useState("0%");
  const [activityAccepted, setActivityAccepted] = useState(false);

  const [assignedUserId, setAssignedUserId] = useState(null);
  const [files, setFiles] = useState([]);
  const [fileCategories, setFileCategories] = useState([]);
  const [engineeringManger, setEngineeringManger] = useState(0);
  const [engineeringMangers, setEngineeringMangers] = useState([]);
  const [revised, setRevised] = useState(false);
  const [revisedFieldDisabled, setRevisedFieldDisabled] = useState(false);
  const [activitySubmittals, setActivitySubmittals] = useState([]);
  const [consultant, setConsultant] = useState({});
  const [submittedById, setSubmittedById] = useState(null);
  const [engineeringMangersMC, setEngineeringMangersMC] = useState([]);
  const [loadingOnSave, setLoadingOnSave] = useState(false);
  const [viewAccessToken, setViewAccessToken] = useState(null);

  const [chosenFileId, setChosenFileId] = useState(0);
  const [canCreateSo, setCanCreateSo] = useState(false);

  const [BEerror, setBEerror] = useState(null);

  useEffect(() => {
    if (props.viewOnly) {
      setDisableAllFields(true);
    }
  }, [props.viewOnly]);
  useEffect(() => {
    if (
      !disicipline ||
      !code ||
      !type ||
      !trade ||
      !description ||
      !drawingNB ||
      !numOfSheets ||
      !sheetSize ||
      !planDuration ||
      !plannedStartDate ||
      !startTime ||
      !teamMember ||
      disiciplineError ||
      codeError ||
      typeError ||
      tradeError ||
      descriptionError ||
      drawingNBError ||
      numOfSheetsError ||
      sheetSizeError ||
      planDurationError ||
      plannedStartDateError ||
      startTimeError ||
      teamMemberError
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });
  useEffect(() => {
    if (props.subProjectId || props.activityId || props.activityDetailsId) {
      setLoadingData(true);
      getActivitiesCreateEditData(
        props.activityId,
        props.subProjectId,
        props.activityDetailsId
      ).then((x) => {
        if (x.status) {
          setBEerror(x.status.message);
          setOpenInvalidModal(true)
        }
        else {

          setProject(x.Project);
          setPhase(x.Phase);
          setSubProject(x.SubPRoject);
          setDisiciplines(x.Disciplines);
          setTypes(x.Types);
          setPredecessors(x.Predecessors);
          setTrades(x.Trades);
          setSheetSizes(x.SheetSize);
          setSubmittalStatuses(x.SubmittalStatuses);
          setFileCategories(x.Categories);
          setEngineeringMangersMC(x.EngineeringManagers?.filter((e)=> e.Name !== localStorage.getItem("companyName")));
          if (props.activityId || props.activityDetailsId) {
            props.setSubProjectId(x.Activity.SubProjectId);
            props.setProjectId(x.Activity.ProjectId);
            props.getProjectDetails(x.Activity.ProjectId);
            setCode(x.Activity.Code);
            setActivityProgress(x.Activity.Progress);
            props.setActivityDetailsId(x.Activity.ActivityDetailsId);
            setPreviousStartDates(x.Activity.PreviousStartDates);
            setPreviousDurations(x.Activity.PreviousDurations);
            setDescription(x.Activity.Description);
            setDrawingNB(x.Activity.DrawingsNumber);
            setNumOfSheets(x.Activity.SheetsNumber);
            setPlanDuration(x.Activity.PlannedDuration);
            setPlannedStartDate(x.Activity.PlannedStartDate);
            setRevisionNO(x.Activity.RevisionNumber);
            setPreviousStartDates(x.Activity.PreviousStartDates);
            setDisicipline(x.Activity.DisciplineId);
            setTrade(x.Activity.TradeId);
            setSheetSize(x.Activity.SheetSizeId);
            setSubmittalStatus(x.Activity.SubmittalStatusId);
            setStatus(x.Activity.Status);
            setType(x.Activity.TypeId);
            setActualStartDate(x.Activity.ActualStartDate);
            setActualStartTime(
              x.Activity.ActualStartTime
                ? moment(
                  x.Activity.ActualStartDate +
                  " " +
                  x.Activity.ActualStartTime +
                  " " +
                  "GMT+0000"
                ).format("hh:mm:ss a")
                : null
            );
            setActualEndDate(x.Activity.ActualEndDate);
            setActualEndTime(
              x.Activity.ActualEndTime
                ? moment(
                  x.Activity.ActualEndDate +
                  " " +
                  x.Activity.ActualEndTime +
                  " " +
                  "GMT+0000"
                ).format("hh:mm:ss a")
                : null
            );
            let TM = x.Activity.TeamMembers?.at(0);
            setTeamMember(TM.TeamMemberId);
            setStartTime(TM.StartTime);
            setEndTime(TM.EndTime);
            setEndDate(TM.EndDate);
            setAssignedUserId(TM.UserId);
            setFiles(TM.Files);
            setCreator(x.Activity.Creator);
            setCreatorId(x.Activity.CreatorId);
            setViewAccessToken(x.Activity.ViewAccessToken);
            setActivityAccepted(x.Activity.IsAccepted);
            x.Activity?.ActivitySubmittals?.map((submittal) =>
              submittal.GroupPermission === "Engineering Manager" ||
                submittal.GroupPermission === "Project Manager"
                ? setSubmittedById(submittal.Id)
                : null
            );
            setActivitySubmittals(x.Activity.ActivitySubmittals);
            setConsultant(x.Consultant);
          }

        }
        setLoadingData(false);

      });
    }
  }, [props.modify]);
  useEffect(() => {
    if (props.subProjectId) {
      getTMBySub(props.subProjectId).then((x) => {
        setTeamMembers(x.TeamMembers);
        setEngineeringMangers(
          x.TeamMembers.filter(
            (tm) => tm.UserGroupPermissions === "Engineering Manager"
          )
        );
      });
    }
  }, [props.subProjectId]);
  const ActivityDetails = {
    Id: props.activityId,
    SubProjectId: props.subProjectId,
    PredecessorId: predecessor === 0 ? null : predecessor,
    Code: code,
    TypeId: type,
    DisciplineId: disicipline,
    TradeId: trade,
    Description: description,
    DrawingsNumber: drawingNB,
    SheetsNumber: numOfSheets,
    SheetSizeId: sheetSize,
    PlannedDuration: planDuration,
    PlannedStartDate: plannedStartDate,
    SubmittalStatusId: 1,
    TeamMember: {
      StartTime: startTime,
      TeamMemberId: teamMember,
    },
  };
  const addTheActivity = () => {
    setLoadingOnSave(true);
    addActivity(ActivityDetails).then((x) => {
      if (x.status === 500) {
        if (x.error === "Stick to the business days") {
          setPlannedStartDateError(true);
          setPlannedStartDateErrorMessage(
            "Stick to the standard working days that are set by your company."
          );
        } else if (x.error === "Stick to the scheduled time interval") {
          setStartTimeError(true);
          setStartTimeErrorMessage(
            "Stick to the standard working hours that are set by your company."
          );
        }
      } else {
        props.setModify(!props.modify);
        navigate("/saas/execution");
      }
      setLoadingOnSave(false);
    });
  };
  const updateTheActivity = () => {
    setLoadingOnSave(true);
    updateActivity(ActivityDetails).then((x) => {
      if (x.status === 500) {
        if (x.error === "Stick to the business days") {
          setPlannedStartDateError(true);
          setPlannedStartDateErrorMessage(
            "Stick to the standard working days that are set by your company."
          );
        } else if (x.error === "Stick to the scheduled time interval") {
          setStartTimeError(true);
          setStartTimeErrorMessage(
            "Stick to the standard working hours that are set by your company."
          );
        }
      } else {
        props.setModify(!props.modify);
        navigate("/saas/execution");
      }
      setLoadingOnSave(false);
    });
  };
  const createARevision = () => {
    setDisableAllFields(false);
    setSubmittalStatus(1);
    setRevised(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
    setStatus("Draft");
    setRevisedFieldDisabled(true);
    setDescription("");
    setNumOfSheets("");
    setSheetSize("");
    setPlanDuration("");
    setPlannedStartDate("");
    setPreviousDurations([]);
    setPreviousStartDates([]);
    setRevisionNO(revisionNO + 1);
    setActualStartDate("");
    setActualStartTime("");
    setActualEndDate("");
    setActualEndTime("");
    setStartTime("");
    setEndTime("");
    setEndDate("");
  };
  const addTheRevision = () => {
    setLoadingOnSave(true);
    reviseActivity(ActivityDetails).then((x) => {
      if (x.status === 500) {
        if (x.error === "Stick to the business days") {
          setPlannedStartDateError(true);
          setPlannedStartDateErrorMessage(
            "Stick to the standard working days that are set by your company."
          );
        } else if (x.error === "Stick to the scheduled time interval") {
          setStartTimeError(true);
          setStartTimeErrorMessage(
            "Stick to the standard working hours that are set by your company."
          );
        }
      } else {
        props.setModify(!props.modify);
        navigate("/saas/execution");
      }
      setLoadingOnSave(false);
    });
  };

  const acceptTheActivity = () => {
    acceptActivity(props.activityId).then((x) => {
      setActivityAccepted(true);
      // props.setModify(!props.modify);
    });
  };
  const submitTheActivity = (receiverId, submitTo) => {
    setLoadingData(true);
    tMSubmitActivity(
      props.activityId,
      receiverId,
      submitTo,
      props.subProjectId
    ).then((x) => {
      setLoadingData(false);
      props.setModify(!props.modify);
    });
  };
  useEffect(() => {
    if (UserPermissions) {
      CheckIfPermitted(
        UserPermissions,
        "Sales Order",
        "Import SDI",
        setCanCreateSo
      );
    }
  }, [UserPermissions]);
  return (
    <>
      <Grid container paddingBottom={5} paddingLeft={2} direction={"column"}>
        <Backdrop
          sx={{
            color: "#fff",
            backgroundColor: "transparent",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            display: "flex",
            flexDirection: "column",
          }}
          open={loadingData}
        >
          <CircularProgress />

          <Grid
            item
            fontFamily={"Muli"}
            fontSize={15}
            fontWeight={"bold"}
            color={"rgb(25, 118, 210)"}
          >
            Loading Data...
          </Grid>
        </Backdrop>
        <Grid item container>
          <Grid item className={classes["Activity-Details"]} paddingTop={3}>
            {revised ? "Revised Activity Details" : "Activity Details"}
          </Grid>
          <Grid
            item
            md={10.5}
            xs={12}
            paddingLeft={{ lg: 11, md: 0, xs: 0 }}
            container
          >
            <ProgressBar status={status} />
          </Grid>
        </Grid>

        <Grid item paddingTop={3} paddingBottom={2}>
          {props.subProjectId ? (
            <button
              className={classes["show-document"]}
              onMouseOver={(e) => (e.target.style.cursor = "pointer")}
              onClick={() => {
                props.setViewTable(true);
              }}
            >
              Subproject Documents
            </button>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item container direction={"row"} gap={1}>
          <Grid item md={2.93} xs={12}>
            <Grid item className={classes["field-title"]} xs={12}>
              *Subproject Name
            </Grid>
            <Grid
              item
              className={classes["Rectangle-1464"]}
              xs={11}
              marginTop={1}
            >
              {subProject ? subProject : "Activity Reference"}
            </Grid>
          </Grid>
          <Grid item md={2.93} xs={12}>
            <Grid item className={classes["field-title"]} xs={12}>
              *Phase Name
            </Grid>
            <Grid
              item
              className={classes["Rectangle-1464"]}
              xs={11}
              marginTop={1}
            >
              {phase ? phase : "Activity Reference"}
            </Grid>
          </Grid>
          <Grid item md={2.93} xs={12}>
            <Grid item className={classes["field-title"]} xs={12}>
              *Project Name
            </Grid>
            <Grid
              item
              className={classes["Rectangle-1464"]}
              xs={11}
              marginTop={1}
            >
              {project ? project : "Activity Reference"}
            </Grid>
          </Grid>
          <Grid item md={2.93} xs={12}>
            <Grid item className={classes["field-title"]} xs={12}>
              *Disciplines
            </Grid>
            <Grid item xs={11} paddingTop={1}>
              <CustomSelect
                value={disicipline}
                disabled={disableAllFields || revisedFieldDisabled}
                onChange={(option) => {
                  setDisicipline(option);
                  setDisiciplineError(false);
                }}
                style={{ color: "#707070" }}
                onBlur={() => {
                  if (!disicipline) setDisiciplineError(true);
                }}
              >
                <StyledOption value={0} disabled>
                  Discipline
                </StyledOption>
                {disiciplines.map((s) => (
                  <StyledOption key={s.Id} value={s.Id}>
                    {s.Name}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Grid>
            <Grid item className={classes["errorMessage"]}>
              {disiciplineError ? "Select a discipline" : <></>}
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction={"row"} gap={1}>
          <Grid item md={2.93} xs={12}>
            <Grid item className={classes["field-title"]} xs={12}>
              *Code
            </Grid>
            <Grid item xs={11} marginTop={1}>
              <TextField
                fullWidth
                disabled={disableAllFields || revisedFieldDisabled}
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{ className: classes.fieldDesign }}
                size="small"
                label={""}
                value={code}
                variant="outlined"
                error={codeError}
                helperText={codeErrorMessage}
                onBlur={(e) => {
                  if (!e.target.value) {
                    setCodeError(true);
                    setCodeErrorMessage("Enter a code");
                  }
                }}
                onChange={(e) => {
                  setCode(e.target.value);
                  if (e.target.value.length > 30) {
                    setCodeError(true);
                    setCodeErrorMessage("Code must be less than 30 char");
                  } else {
                    setCodeError(false);
                    setCodeErrorMessage(null);
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid item md={2.93} xs={12}>
            <Grid item className={classes["field-title"]} xs={12}>
              *Type
            </Grid>
            <Grid item xs={11} paddingTop={1}>
              <CustomSelect
                value={type}
                style={{ color: "#707070" }}
                disabled={disableAllFields || revisedFieldDisabled}
                onChange={(option) => {
                  setType(option);
                  setTypeError(false);
                }}
                onBlur={(e) => {
                  if (!type) setTypeError(true);
                }}
              >
                <StyledOption value={0} disabled>
                  Type
                </StyledOption>
                {types.map((s) => (
                  <StyledOption key={s.Id} value={s.Id}>
                    {s.Name}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Grid>
            <Grid item className={classes["errorMessage"]}>
              {typeError ? "Select a Type" : <></>}
            </Grid>
          </Grid>
          <Grid item md={2.93} xs={12}>
            <Grid item className={classes["field-title"]} xs={12}>
              Predecessor
            </Grid>
            <Grid item xs={11} paddingTop={1}>
              <CustomSelect
                value={predecessor}
                disabled={disableAllFields || revisedFieldDisabled}
                style={{ color: "#707070" }}
                onChange={(option) => {
                  setPredecessor(option);
                }}
              >
                <StyledOption value={0} disabled>
                  Predecessor
                </StyledOption>
                {predecessors.map((s) => (
                  <StyledOption key={s.Id} value={s.Id}>
                    {s.Code}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Grid>
          </Grid>
          <Grid item md={2.93} xs={12}>
            <Grid item className={classes["field-title"]} xs={12}>
              *Trade
            </Grid>
            <Grid item xs={11} paddingTop={1}>
              <CustomSelect
                disabled={disableAllFields || revisedFieldDisabled}
                value={trade}
                style={{ color: "#707070" }}
                onChange={(option) => {
                  setTrade(option);
                  setTradeError(false);
                }}
                onBlur={(e) => {
                  if (!trade) setTradeError(true);
                }}
              >
                <StyledOption value={0} disabled>
                  Trade
                </StyledOption>
                {trades.map((s) => (
                  <StyledOption key={s.Id} value={s.Id}>
                    {s.Name}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Grid>
            <Grid item className={classes["errorMessage"]}>
              {tradeError ? "Select a Trade" : <></>}
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction={"row"} gap={0}>
          <Grid item md={6.2} xs={12}>
            <Grid item className={classes["field-title"]} xs={12}>
              *Description
            </Grid>
            <Grid item xs={11} marginTop={1}>
              <TextField
                disabled={disableAllFields}
                fullWidth
                InputLabelProps={{
                  shrink: false,
                }}
                rows={6}
                multiline
                InputProps={{ className: classes.DescriptionfieldDesign }}
                size="small"
                label={""}
                value={description}
                variant="outlined"
                error={descriptionError}
                helperText={descriptionErrorMessage}
                onBlur={(e) => {
                  if (!e.target.value) {
                    setDescriptionError(true);
                    setDescriptionErrorMessage("Enter a Description");
                  }
                }}
                onChange={(e) => {
                  setDescription(e.target.value);
                  if (e.target.value.length > 255) {
                    setDescriptionError(true);
                    setDescriptionErrorMessage("Code must be less than 255 char");
                  } else {
                    setDescriptionError(false);
                    setDescriptionErrorMessage(null);
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} md={5.8}>
            <Grid item md={12} xs={12}>
              <Grid item className={classes["field-title"]} xs={12}>
                *Drawings Nb
              </Grid>
              <Grid item xs={11} marginTop={1}>
                <TextField
                  disabled={disableAllFields || revisedFieldDisabled}
                  fullWidth
                  InputLabelProps={{
                    shrink: false,
                  }}
                  InputProps={{ className: classes.fieldDesign }}
                  size="small"
                  label={""}
                  value={drawingNB}
                  variant="outlined"
                  error={drawingNBError}
                  helperText={drawingNBErrorMessage}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setDrawingNBError(true);
                      setDrawingNBErrorMessage("Enter a Number");
                    }
                  }}
                  onChange={(e) => {
                    setDrawingNB(e.target.value);
                    if (e.target.value.length > 150) {
                      setDrawingNBError(true);
                      setDrawingNBErrorMessage(
                        "Number must be less than 150 char"
                      );
                    } else {
                      setDrawingNBError(false);
                      setDrawingNBErrorMessage(null);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} md={12} paddingTop={3.8}>
              <Grid item md={6} xs={12}>
                <Grid item className={classes["field-title"]} xs={12}>
                  *Nb of sheets
                </Grid>
                <Grid item xs={11} marginTop={1}>
                  <TextField
                    fullWidth
                    disabled={disableAllFields}
                    InputLabelProps={{
                      shrink: false,
                    }}
                    InputProps={{ className: classes.fieldDesign }}
                    size="small"
                    label={""}
                    value={numOfSheets}
                    variant="outlined"
                    error={numOfSheetsError}
                    helperText={numOfSheetsErrorMessage}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        setNumOfSheetsError(true);
                        setNumOfSheetsErrorMessage("Enter a Number");
                      }
                    }}
                    onChange={(e) => {
                      setNumOfSheets(e.target.value);
                      if (e.target.value.length > 30) {
                        setNumOfSheetsError(true);
                        setNumOfSheetsErrorMessage(
                          "Number of sheets must be less than 30 char"
                        );
                      } else {
                        setNumOfSheetsError(false);
                        setNumOfSheetsErrorMessage(null);
                      }
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container item md={6} xs={12}>
                <Grid item className={classes["field-title"]} xs={12}>
                  *Sheet Size
                </Grid>
                <Grid item xs={11} marginTop={1}>
                  <CustomSelect
                    value={sheetSize}
                    disabled={disableAllFields}
                    style={{ color: "#707070" }}
                    onChange={(option) => {
                      setSheetSize(option);
                      setSheetSizeError(false);
                    }}
                    onBlur={(e) => {
                      if (!sheetSize) setSheetSizeError(true);
                    }}
                  >
                    <StyledOption value={0} disabled>
                      Sheet Size
                    </StyledOption>
                    {sheetSizes.map((s) => (
                      <StyledOption key={s.Id} value={s.Id}>
                        {s.Name}
                      </StyledOption>
                    ))}
                  </CustomSelect>
                </Grid>
                <Grid item className={classes["errorMessage"]}>
                  {sheetSizeError ? "Select a sheet size" : <></>}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction={"row"} gap={1}>
          <Grid item md={2.93} xs={12}>
            <Grid item className={classes["field-title"]} xs={12}>
              *Planned Duration (/hr)
            </Grid>
            <Grid item xs={11} marginTop={1}>
              <TextField
                fullWidth
                disabled={disableAllFields}
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{ className: classes.fieldDesign }}
                size="small"
                label={""}
                type={"number"}
                value={planDuration}
                variant="outlined"
                error={planDurationError}
                helperText={planDurationErrorMessage}
                onBlur={(e) => {
                  if (!e.target.value) {
                    setPlanDurationError(true);
                    setPlanDurationErrorMessage("Enter a duration");
                  }
                }}
                onChange={(e) => {
                  setPlanDuration(e.target.value);
                  setPlanDurationError(false);
                  setPlanDurationErrorMessage(null);
                }}
              />
            </Grid>
          </Grid>
          {status === "InProgress" ? (
            <Grid item md={2.93} xs={12}>
              <Grid item className={classes["field-title-disabled"]} xs={12}>
                Activity Progress
              </Grid>
              <Grid
                item
                className={classes["Rectangle-1464"]}
                xs={11}
                marginTop={1}
                height={"50px"}
                bgcolor={
                  ActivityProgress === "Delayed"
                    ? "rgba(255, 0, 0, 0.3)"
                    : "rgba(45, 164, 208, 0.3)"
                }
              >
                {ActivityProgress === "Delayed"
                  ? ActivityProgress
                  : `${ActivityProgress < 1 ? 1 : ActivityProgress}%`}
              </Grid>
            </Grid>
          ) : (
            <Grid item md={2.93} xs={12}></Grid>
          )}

          <Grid item md={5.9} xs={12}>
            <Grid item className={classes["field-title-disabled"]} xs={12}>
              Status
            </Grid>
            <Grid
              item
              className={classes["Rectangle-1464"]}
              xs={11}
              marginTop={1}
              height={"50px"}
            >
              {status}
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction={"row"} gap={1}>
          <Grid item md={2.93} xs={12}>
            <Grid item className={classes["field-title"]} xs={12}>
              *Planned Start Date
            </Grid>
            <Grid item xs={11} marginTop={1}>
              <TextField
                fullWidth
                disabled={disableAllFields}
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{ className: classes.fieldDesign }}
                size="small"
                label={""}
                type={"date"}
                value={plannedStartDate}
                variant="outlined"
                error={plannedStartDateError}
                helperText={plannedStartDateErrorMessage}
                onBlur={(e) => {
                  if (!e.target.value) {
                    setPlannedStartDateError(true);
                    setPlannedStartDateErrorMessage("Enter a start Date");
                  }
                }}
                onChange={(e) => {
                  setPlannedStartDate(e.target.value);
                  setPlannedStartDateError(false);
                  setPlannedStartDateErrorMessage(null);
                }}
              />
            </Grid>
          </Grid>

          <Grid item md={2.93} xs={12}>
            <Grid item className={classes["field-title-disabled"]} xs={12}>
              Previous Dates and Durations
            </Grid>
            <Grid
              item
              className={classes["Rectangle-1464"]}
              xs={11}
              marginTop={1}
              bgcolor={"#f3f3f3"}
              height={"50px"}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onClick={() => {
                setOpen(true);
              }}
            >
              Dates And Durations
            </Grid>
          </Grid>
          <Grid item md={2.93} xs={12}>
            <Grid item className={classes["field-title-disabled"]} xs={12}>
              Revision Nb
            </Grid>
            <Grid
              item
              className={classes["Rectangle-1464"]}
              xs={11}
              marginTop={1}
              height={"50px"}
            >
              {revisionNO}
            </Grid>
          </Grid>
          <Grid item md={2.93} xs={12}>
            <Grid item className={classes["field-title-disabled"]} xs={12}>
              Creator
            </Grid>
            <Grid
              item
              className={classes["Rectangle-1464"]}
              xs={11}
              marginTop={1}
              height={"50px"}
            >
              {creator}
            </Grid>
          </Grid>
        </Grid>
        <Grid item container gap={1} paddingTop={1}>
          <Grid item md={2.93} xs={12}>
            <Grid item className={classes["field-title-disabled"]} xs={12}>
              Actual Start Date
            </Grid>
            <Grid
              item
              className={classes["Rectangle-1464"]}
              xs={11}
              marginTop={1}
              height={"50px"}
            >
              {actualStartDate
                ? new Date(actualStartDate)?.toLocaleDateString()
                : null}
            </Grid>
          </Grid>

          <Grid item md={2.93} xs={12}>
            <Grid item className={classes["field-title-disabled"]} xs={12}>
              Actual Start Time
            </Grid>
            <Grid
              item
              className={classes["Rectangle-1464"]}
              xs={11}
              marginTop={1}
              height={"50px"}
            >
              {actualStartTime?.slice(0, 5)}
            </Grid>
          </Grid>
          <Grid item md={2.93} xs={12}>
            <Grid item className={classes["field-title-disabled"]} xs={12}>
              Actual End Date
            </Grid>
            <Grid
              item
              className={classes["Rectangle-1464"]}
              xs={11}
              marginTop={1}
              height={"50px"}
            >
              {actualEndDate
                ? new Date(actualEndDate)?.toLocaleDateString()
                : null}
            </Grid>
          </Grid>
          <Grid item md={2.93} xs={12}>
            <Grid item className={classes["field-title-disabled"]} xs={12}>
              Actual End Time
            </Grid>
            <Grid
              item
              className={classes["Rectangle-1464"]}
              xs={11}
              marginTop={1}
              height={"50px"}
            >
              {actualEndTime?.slice(0, 5)}
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction={"row"} gap={1} paddingTop={1}>
          <ActivityResponsibility
            disableAllFields={disableAllFields}
            startTime={startTime}
            setStartTime={setStartTime}
            startTimeError={startTimeError}
            setStartTimeError={setStartTimeError}
            endTime={endTime}
            endDate={endDate}
            teamMember={teamMember}
            teamMemberError={teamMemberError}
            teamMembers={teamMembers}
            setTeamMember={setTeamMember}
            setTeamMemberError={setTeamMemberError}
            startTimeErrorMessage={startTimeErrorMessage}
            setStartTimeErrorMessage={setStartTimeErrorMessage}
            revisedFieldDisabled={revisedFieldDisabled}
          />
        </Grid>
        {disableAllFields ? (
          <></>
        ) : (
          <Grid
            item
            container
            direction={"row-reverse"}
            gap={2}
            paddingTop={1}
            paddingRight={5.5}
          >
            <Grid item>
              <button
                disabled={
                  isDisabled ||
                    loadingOnSave ||
                    localStorage.getItem("IsSubscribedBefore") === "true"
                    ? true
                    : false
                }
                className={classes["SaveButton"]}
                onClick={() => {
                  if (revised) {
                    addTheRevision();
                  } else {
                    if (props.activityId) {
                      updateTheActivity();
                    } else {
                      addTheActivity();
                    }
                  }
                }}
              >
                {loadingOnSave ? "Saving.." : "Save"}
              </button>
            </Grid>
            <Grid item className={classes["BackButton"]}>
              Cancel
            </Grid>
          </Grid>
        )}

        <Grid item container paddingTop={1}>
          <TMActivity
            actualStartDate={actualStartDate}
            activityId={props.activityId}
            assignedUserId={assignedUserId}
            subProjectId={props.subProjectId}
            files={files}
            setFiles={setFiles}
            modify={props.modify}
            setModify={props.setModify}
            fileCategories={fileCategories}
            actualEndDate={actualEndDate}
            creatorId={creatorId}
            viewAccessToken={viewAccessToken}
          />
        </Grid>

        <Grid container>
          {activitySubmittals.map((submittal) => (
            <Grid
              item
              className={classes["Submitted-status"]}
              xs={12}
              paddingRight={3}
            >
              {submittal.GroupPermission === null
                ? " Submitted on " +
                new Date(submittal.SubmittalDate)
                  .toLocaleDateString()
                  .slice(0, 10) +
                " at " +
                moment(
                  submittal.SubmittalDate +
                  " " +
                  submittal.SubmittalTime +
                  " " +
                  "GMT+0000"
                ).format("hh:mm:ss a") +
                " to " +
                submittal.Name
                : " Submitted on " +
                new Date(submittal.SubmittalDate)
                  .toLocaleDateString()
                  .slice(0, 10) +
                " at " +
                moment(
                  submittal.SubmittalDate +
                  " " +
                  submittal.SubmittalTime +
                  " " +
                  "GMT+0000"
                ).format("hh:mm:ss a") +
                " to " +
                submittal.Name +
                " for internal approval"}
            </Grid>
          ))}
        </Grid>

        {status === activityConst.SUBMITTED_INTERNAL_APPROVAL_1 &&
          localStorage.getItem("UserId") === creatorId &&
          activityAccepted === false ? (
          <Grid container paddingTop={2} gap={8}>
            <Grid item xs={12} md={5}>
              <button
                className={classes["AcceptButton"]}
                onClick={() => {
                  acceptTheActivity();
                }}
              >
                Accept
              </button>
            </Grid>
            <Grid
              item
              container
              direction={{ md: "row-reverse", xs: "row" }}
              xs={12}
              md={6}
            >
              <button
                className={classes["AcceptButton"]}
                onClick={() => {
                  createARevision();
                }}
              >
                Revise
              </button>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        {status === activityConst.SUBMITTED_INTERNAL_APPROVAL_1 &&
          localStorage.getItem("UserId") === creatorId &&
          activityAccepted ? (
          <Grid paddingTop={2}>
            <Grid item md={2.7} xs={12}>
              <Grid item className={classes["field-title"]} xs={12}>
                *Engineering Manager Name
              </Grid>
              <Grid item xs={11} paddingTop={1}>
                <CustomSelect
                  value={engineeringManger}
                  style={{ color: "#707070" }}
                  onChange={(option) => {
                    setEngineeringManger(option);
                  }}
                >
                  <StyledOption value={0} disabled>
                    Engineer Manager
                  </StyledOption>
                  {engineeringMangers.map((s) => (
                    <StyledOption key={s.Id} value={s.Id}>
                      {s.Name}
                    </StyledOption>
                  ))}
                </CustomSelect>
              </Grid>
            </Grid>
            <Grid xs={2.5} item className={classes["Path-1279"]} marginTop={2}>
              <button
                className={classes["submitActivityTo"]}
                disabled={engineeringManger ? false : true}
                onClick={() => {
                  submitTheActivity(
                    engineeringManger,
                    pileCubesConst.ACTIVITY_SUBMIT_TO_TEAM_MEMBER
                  );
                }}
              >
                Submit to engineering manager
              </button>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        {status === activityConst.SUBMITTED_INTERNAL_APPROVAL_2 &&
          localStorage.getItem("UserId") === submittedById &&
          localStorage.getItem("AccountType") === "2" ? (
          <Grid paddingTop={2}>
            <Grid item md={2.7} xs={12}>
              <Grid item className={classes["field-title"]} xs={12}>
                *Engineering Manager Name
              </Grid>
              <Grid item xs={11} paddingTop={1}>
                <CustomSelect
                  value={engineeringManger}
                  style={{ color: "#707070" }}
                  onChange={(option) => {
                    setEngineeringManger(option);
                  }}
                >
                  <StyledOption value={0} disabled>
                    Engineer Manager
                  </StyledOption>
                  {engineeringMangersMC.map((s) => (
                    <StyledOption key={s.Id} value={s.Id}>
                      {s.Name}
                    </StyledOption>
                  ))}
                </CustomSelect>
              </Grid>
            </Grid>
            <Grid xs={2.5} item className={classes["Path-1279"]} marginTop={2}>
              <button
                className={classes["submitActivityTo"]}
                disabled={engineeringManger ? false : true}
                onClick={() => {
                  submitTheActivity(
                    engineeringManger,
                    pileCubesConst.ACTIVITY_SUBMIT_TO_TEAM_MEMBER
                  );
                }}
              >
                Submit to Engineer Manager
              </button>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        {status === activityConst.SUBMITTED_INTERNAL_APPROVAL_2 &&
          submittedById === localStorage.getItem("UserId") &&
          localStorage.getItem("AccountType") === "1" ? (
          <Grid item paddingTop={2}>
            <Grid xs={2.5} item className={classes["Path-1279"]} marginTop={2}>
              <button
                className={classes["submitActivityTo"]}
                onClick={() => {
                  submitTheActivity(
                    consultant.Id,
                    pileCubesConst.ACTIVITY_SUBMIT_TO_CONSULTANT
                  );
                }}
              >
                Submit to Consultant
              </button>
            </Grid>
          </Grid>
        ) : (
          ""
        )}

        {status === activityConst.SUBMITTED_FOR_CONSULTANT &&
          submittedById === localStorage.getItem("UserId") &&
          submittalStatus === 1 ? (
          <Grid
            container
            direction={"row"}
            paddingTop={5}
            gap={{ md: 5, xs: 2 }}
            paddingLeft={2}
          >
            <Grid
              item
              className={classes["Consultant-Response-Received"]}
              paddingTop={2}
            >
              Consultant Response Received:
            </Grid>
            <Grid item className={classes["Path-1280"]}>
              <button
                className={classes["yesResponse"]}
                onClick={() => {
                  navigate("/saas/execution/SubmittalResponse", {
                    state: {
                      id: props.activityId,
                      subProjectId: props.subProjectId,
                      edit: true,
                    },
                  });
                }}
              >
                Yes
              </button>
            </Grid>
            <Grid item className={classes["Path-1281"]}>
              <button
                className={classes["NosubmitActivityTo"]}
                onClick={() => {
                  submitTheActivity(
                    consultant.Id,
                    pileCubesConst.ACTIVITY_SUBMIT_TO_CONSULTANT
                  );
                }}
              >
                NO Re-Submit to Consultant
              </button>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        {submittalStatus !== 1 ? (
          <Grid container direction={"row"} paddingTop={2}>
            <Grid container direction={"row"} gap={2} item md={6} xs={12}>
              <Grid item>
                <button
                  className={classes["Union-106"]}
                  onClick={() => {
                    navigate("/saas/execution/SubmittalResponse", {
                      state: {
                        id: props.activityId,
                        subProjectId: props.subProjectId,
                        edit: false,
                      },
                    });
                  }}
                >
                  View Submittal Response
                </button>
              </Grid>
              <Grid item className={classes["Rejected"]}>
                {submittalStatus === 2
                  ? "Approved"
                  : submittalStatus === 3
                    ? "Approved as Noted"
                    : submittalStatus === 4
                      ? "Revised & Resubmitted"
                      : submittalStatus === 5
                        ? "Rejected"
                        : ""}
              </Grid>
            </Grid>
            {(submittalStatus === 2 || submittalStatus === 3) &&
              disicipline === 3 &&
              trade === 1 ? (
              <Grid
                item
                container
                md={6}
                xs={12}
                direction="column-reverse"
                paddingRight={2.5}
                gap={2}
              >
                <Grid item>
                  <CustomSelect
                    value={chosenFileId}
                    disabled={canCreateSo ? true : false}
                    style={{ color: "#707070" }}
                    onChange={(option) => {
                      setChosenFileId(option);
                    }}
                  >
                    <StyledOption value={0} disabled>
                      Choose file
                    </StyledOption>
                    {files.map((s) =>
                      s.Extension === ".sdi" ? (
                        <StyledOption key={s.FileId} value={s.FileId}>
                          {s.DisplayName}
                        </StyledOption>
                      ) : null
                    )}
                  </CustomSelect>
                </Grid>
                <Grid item>
                  <button
                    disabled={chosenFileId ? false : true}
                    className={classes["Union-106"]}
                    style={{ backgroundColor: "#5fba47", fontWeight: "bold" }}
                    onClick={() => {
                      navigate("/saas/salesOrderForm", {
                        state: {
                          chosenFileId: chosenFileId,
                          ActId: props.activityId,
                        },
                      });
                    }}
                  >
                    Create Sales Order
                  </button>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
            {creatorId === localStorage.getItem("UserId") ? (
              <Grid
                container
                direction={"row-reverse"}
                gap={2}
                item
                md={6}
                xs={12}
                paddingRight={3}
              >
                {submittalStatus === 4 || submittalStatus === 5 ? (
                  <Grid
                    item
                    container
                    direction={{ md: "row-reverse", xs: "row" }}
                    xs={12}
                    md={6}
                  >
                    <button
                      className={classes["AcceptButton"]}
                      onClick={() => {
                        createARevision();
                      }}
                    >
                      Revise
                    </button>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        ) : (
          ""
        )}
        <Grid item container paddingTop={1}>
          {props.activityId || props.activityDetailsId ? (
            <CommentSection
              activityId={props.activityDetailsId}
              revised={revised}
            />
          ) : (
            <></>
          )}
        </Grid>

        <StartAndDurationsModal
          openModal={open}
          setOpenModal={setOpen}
          previousDurations={previousDurations}
          previousStartDates={previousStartDates}
        />
      </Grid>
      <InvalidAccessModal from={props.from} openInvalidModal={openInvalidModal} setOpenInvalidModal={setOpenInvalidModal} BEerror={BEerror} />
    </>

  );
};
const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
    font-family: Muli;
    font-size: 17px;
    box-sizing: border-box;
    width: 100%;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid #f3f3f3;
    border-radius: 5px;
    text-align: left;
    color: #bcbcbc;
    padding:13px;
    &:hover {
      background: ${theme.palette.mode === "dark" ? "" : grey[100]};
      border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }
  
    &.${selectUnstyledClasses.focusVisible} {
      outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]
    };
    }
  
    &.${selectUnstyledClasses.expanded} {
      &::after {
        content: '▴';
      }
    }
  
    &::after {
      content: '▾';
      float: right;
    }
    `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
    font-family: Muli;
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 5px;
    margin: 10px 0;
    min-width: 11rem;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
    border-radius: 0.75em;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    overflow: auto;
    outline: 0px;
    `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
    list-style: none;
    padding: 8px;
    border-radius: 0.45em;
    cursor: default;
    
    &:last-of-type {
      border-bottom: none;
    }
  
    &.${optionUnstyledClasses.selected} {
      background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]
    };
      color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
    }
  
    &.${optionUnstyledClasses.highlighted} {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]
    };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
  
    &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
      background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]
    };
      color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
    }
  
    &.${optionUnstyledClasses.disabled} {
      color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }
  
    &:hover:not(.${optionUnstyledClasses.disabled}) {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]
    };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
    `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,

    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});

export default ActivityDetails;
