import * as constFile from "../../FactoryConsts";
import axios from "axios";

const ROUTES_LIST = constFile.ROUTES_LIST;
export const getRoutesList = (bayId) => {
    const token = localStorage.getItem("accessToken");
    return axios
        .get(
            `${process.env.REACT_APP_URL + ROUTES_LIST}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    bayId: bayId
                }
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err;
        });
};

const ROUTES_INPUT_DATA = constFile.ROUTES_INPUT_DATA;
export const getInputData = (bayId) => {
    const token = localStorage.getItem("accessToken");
    return axios
        .get(
            `${process.env.REACT_APP_URL + ROUTES_INPUT_DATA}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    bayId: bayId
                }
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err;
        });
};

const CREATE_ROUTES = constFile.CREATE_ROUTES;
export const createRoutes = (data) => {
    const token = localStorage.getItem("accessToken");
    return axios
        .post(
            `${process.env.REACT_APP_URL + CREATE_ROUTES}`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err;
        });
};

const UPDATE_ROUTES = constFile.UPDATE_ROUTES;
export const updateRoutes = (data) => {
    const token = localStorage.getItem("accessToken");
    return axios
        .post(
            `${process.env.REACT_APP_URL + UPDATE_ROUTES}`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err;
        });
};

const MACHINES_INPUT_DATA = constFile.MACHINES_INPUT_DATA;
export const getMachinesInputData = (RouteId, RouteTypeId, BayId) => {
    const token = localStorage.getItem("accessToken");
    return axios
        .post(
            `${process.env.REACT_APP_URL + MACHINES_INPUT_DATA}`,
            {
                RouteId: RouteId,
                RouteTypeId: RouteTypeId,
                BayId: BayId
            },

            {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err;
        });
};

const CREATE_MACHINE_ROUTES = constFile.CREATE_MACHINE_ROUTES;
export const createMachineRoutes = (data) => {
    const token = localStorage.getItem("accessToken");
    return axios
        .post(
            `${process.env.REACT_APP_URL + CREATE_MACHINE_ROUTES}`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err;
        });
};

const UPDATE_MACHINE_ROUTES = constFile.UPDATE_MACHINE_ROUTES;
export const updateMachineRoutes = (data) => {
    const token = localStorage.getItem("accessToken");
    return axios
        .post(
            `${process.env.REACT_APP_URL + UPDATE_MACHINE_ROUTES}`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err;
        });
};