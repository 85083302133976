import React, { useState, useEffect } from "react";
import SaasBack from "../../Assets/Images/SaaSbackground.png";
import { Grid } from "@mui/material";
import classes from "../../Assets/Styles/InventoryManagement.module.css";
import { useNavigate } from "react-router-dom";
import InventoryMangementCard from "./InventoryManagementCard";
import CircularProgress from "@mui/material/CircularProgress";
import * as InventoryManagementServices from "../../Services/InventoryManagement-services.proxy";
import CloseIcon from "@mui/icons-material/Close";
import ErrorModal from "../../SharedComponents/ErrorModal";

const getInventoriesCards = InventoryManagementServices.getInventoriesCardList;

function InventoryManagementListCard(props) {
  const navigate = useNavigate();
  const [inventoryManagementListCards, setInventoryManagementListCards] =
    useState([]);

  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [disableI, setDisableI] = useState(false);

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [BEerror, setBEerror] = useState(null);

  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setLoading(true);
    getInventoriesCards().then((x) => {
      if (x.status || x.statusCode) {
        if (x.status) setBEerror(x.error)
        if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
        setOpenErrorModal(true);
      } else {
        setInventoryManagementListCards(x.Inventories);
      }
      setLoading(false);
    });
  }, []);

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
        objectFit: "cover",
      }}
    >
      <Grid item>
        {alert ? (
          <Grid
            item
            container
            bgcolor={"rgba(225, 227, 25, 0.5)"}
            md={12}
            paddingTop={2}
            paddingBottom={1}
          >
            <Grid item md={4}></Grid>
            <Grid
              item
              md={6}
              style={{
                fontFamily: "Muli",
                fontSize: "17px",
                fontWeight: "bold",
                textAlign: "left",
                color: "#101650",
              }}
            >
              <Grid>
                {disableI ?
                  "You Don't have permission to create/edit a Material Receipts"
                  :
                  "Upgrade Manu Subscription Required To Create Material Receipts"
                }
              </Grid>
            </Grid>
            {
              disableI ? ""
                :
                <Grid item md={2} container>
                  <Grid
                    item
                    onClick={() => {
                      props.setSetupProfile(true);
                      props.setSetupMenu(true);
                      navigate("/saas/profileSettings");
                    }}
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                  >
                    <button
                      style={{
                        padding: "5px",
                        borderRadius: "5px",
                        backgroundColor: "#101650",
                        fontFamily: "Muli",
                        fontSize: "13px",
                        textAlign: "center",
                        border: 0,
                        color: "#fff",
                      }}
                    >
                      Subscribe
                    </button>
                  </Grid>
                  <Grid
                    item
                    paddingLeft={1}
                    onClick={() => {
                      setAlert(false);
                    }}
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                  >
                    <CloseIcon fontSize="medium" />
                  </Grid>
                </Grid>
            }

          </Grid>
        ) : (
          <></>
        )}
        <Grid
          item
          container
          paddingLeft={{ xs: "0%", sm: "1%", md: "5%" }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            container
            direction="row"
            gap={1}
            paddingTop={{ xs: "10%", sm: "7%", md: "2%" }}
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              lg={1.8}
              sm={3.2}
              xs={4.5}
              className={classes["title"]}
              fontSize={{ xs: 13, sm: 18 }}
            >
              Inventory Management
            </Grid>

            <Grid
              item
              lg={7.8}
              sm={4}
              xs={2}
              sx={{
                border: "solid 0.1px #fff",
                backgroundColor: "#fff",
                height: 0,
              }}
            ></Grid>
            <Grid
              item
              lg={1.5}
              sm={3.8}
              xs={4}
              className={classes["viewText"]}
              fontSize={{ xs: 10, sm: 14 }}
              onClick={() => {
                navigate(
                  "/saas/admin/inventoryManagement/materialReceiptTableList"
                );
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
                e.target.style.fontWeight = "bold";
              }}
              onMouseOut={(e) => {
                e.target.style.fontWeight = "normal";
              }}
            >
              View All Material Receipts
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={11.3}
            paddingTop="2%"
            alignItems="flex-start"
            gap={2}
            marginBottom={2}
          >
            {loading ? (
              <Grid item xs={12} container justifyContent="center">
                <Grid item paddingTop="10%">
                  <CircularProgress />
                </Grid>
              </Grid>
            ) : (
              <>
                {inventoryManagementListCards?.length > 0 ? (
                  <>
                    {inventoryManagementListCards?.map((data, index) => (
                      <Grid item xs={5.5} sm={3} md={1.5} key={data.Id}>
                        <InventoryMangementCard data={data} index={index} alert={alert} setAlert={setAlert} disableI={disableI} setDisableI={setDisableI} />
                      </Grid>
                    ))}
                  </>
                ) : (
                  <Grid
                    item
                    style={{
                      fontFamily: "Muli",
                      fontSize: "13px",
                      color: "#101650",
                    }}
                  >
                    {" "}
                    No Inventories
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <ErrorModal openErrorModal={openErrorModal} setOpenErrorModal={setOpenErrorModal} BEerror={BEerror} />
    </div>
  );
}

export default InventoryManagementListCard;
