
import React, { useState, useEffect } from "react";
import { Grid, Dialog } from "@mui/material";
import { useNavigate } from "react-router-dom";



const ErrorModal = (props) => {
    const [open, setOpen] = useState(false);

    const navigate = useNavigate();

    const handleOpen = () => {
        setOpen(true);
        props.setOpenErrorModal(true);
    };

    const handleClose = () => {
        setOpen(false);
        props.setOpenErrorModal(false);
        if(props.setModify) props.setModify(!props.modify)
        if(props.navigateToOnClose) navigate(props.navigateToOnClose);
        


    };

    useEffect(() => {
        if (props.openErrorModal) {
            handleOpen(true);
        }
    }, [props.openErrorModal]);



    return (

        <Dialog
            open={open}
            onClose={handleClose} >

            <Grid item container xs={12} direction="column" rowGap={2} padding={5} alignItems="center" width="50vw">

                <Grid
                    item
                    style={{ fontFamily: "Muli", fontSize: "20px", color: "#101650" }}
                >

                    <Grid item overflow="auto" height="80px" marginTop={2} paddingBottom={2}>
                        {props.BEerror}
                    </Grid>

                </Grid>

                <Grid item textAlign="right"
                    style={{
                        fontFamily: "Muli",
                        fontSize: "20px",
                        backgroundColor: "#1173cf",
                        borderRadius: "5px",
                        padding: "8px 20px 8px 20px",
                        color: "#fff"
                    }}
                    onMouseOver={(e) => e.target.style.cursor = "pointer"}
                    onClick={handleClose}
                > Ok  </Grid>
            </Grid>

        </Dialog>
    );
};

export default ErrorModal;
