import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import Animation from "../../Home/Animation";
import Form1 from "./Form1";
import Form2 from "./Form2";
import Form3 from "./Form3";
import * as constFile from "../../../pileCubesConst";
import axios from "axios";
import VerifyCode from "../VerifyCode/VerifyCode";
const INPUT_API = constFile.INPUT_DATA;

function MainContractor({
  setLoading,
  account,
  setToken,
  isVerified,
  setIsVerified,
  setAccountName,
  inviterTenantId,
  projectId,
  subProjectId,
  tenantId,
}) {
  const [countries, setCountries] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [employeesRanges, setEmployeesRanges] = useState([]);
  const [highlightError, setHighlightError] = useState(false);
  const [highlightMsg, setHighlightMsg] = useState("");
  const [registrationEmail, setRegistrationEmail] = useState("");

  const [info, setInfo] = useState({
    TenantId: tenantId,
    isDisabled: "",
    Name: "",
    RegistrationNumber: "",
    Address: "",
    CompanyPhoneNumber: "+961",
    AgreementAcceptance: false,
    CountryId: "",
    SpecialityId: "",
    SpecialityOther: "",
    ShowSpecialityOther: false,
    EmployeesRangeId: "",
    User: {
      UserId: null,
      Password: "",
      ConfirmPassword: "",
      Email: tenantId ? registrationEmail : "",
      AccountTypeId: 0,
      UserDetails: {
        Name: "",
        PersonalEmail: "",
        Position: "",
        PhoneNumber: "",
        UserId: null,
      },
    },
    InviterTenantId: inviterTenantId,
    ProjectId: projectId,
    SubProjectId: subProjectId,
  });

  useEffect(() => {
    //setLoading(true);
    let isApiSubscribed = true;
    axios
      .get(`${process.env.REACT_APP_URL + INPUT_API}`, {
        params: { tenantId: tenantId },
      })
      .then((response) => {
        if (isApiSubscribed) {
          setCountries(response.data["Countries"]);
          setSpecialities(response.data["Specialities"]);
          setEmployeesRanges(response.data["EmployeesRanges"]);
          if (tenantId)
            setRegistrationEmail(
              response.data.MainContactorCompanyDetails.CompanyEmail
            );
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
    return () => {
      // cancel the subscription
      isApiSubscribed = false;
    };
  }, []);

  const [step, setStep] = useState(1);

  useEffect(() => {
    if (isVerified === "false") {
      setStep(4);
    }
  }, [isVerified]);

  useEffect(() => {
    if (highlightError === true) {
      setStep(1);
    }
  }, [highlightError]);

  const Forms = ({ step }) => {
    switch (step) {
      case 1:
        return (
          <Form1
            continues={continues}
            countries={countries}
            info={info}
            setHighlightError={setHighlightError}
            highlightError={highlightError}
            highlightMsg={highlightMsg}
            tenantId={tenantId}
            registrationEmail={registrationEmail}
            setRegistrationEmail={setRegistrationEmail}
          />
        );
      case 2:
        return (
          <Form2
            continues={continues}
            back={back}
            specialities={specialities}
            employeesRanges={employeesRanges}
            countries={countries}
            info={info}
          />
        );
      case 3:
        return (
          <Form3
            setLoading={setLoading}
            continues={continues}
            back={back}
            countries={countries}
            info={info}
            account={account}
            setToken={setToken}
            setHighlightError={setHighlightError}
            setHighlightMsg={setHighlightMsg}
            setAccountName={setAccountName}
            registrationEmail={registrationEmail}
            tenantId={tenantId}
            inviterTenantId={inviterTenantId}
            projectId={projectId}
            subProjectId={subProjectId}
          />
        );
      case 4:
        return (
          <VerifyCode
            setLoading={setLoading}
            setIsVerified={setIsVerified}
            setToken={setToken}
          />
        );
      default:
        return "nothing";
    }
  };

  const continues = (e) => {
    e.preventDefault();
    setStep(step + 1);
  };
  const back = (e) => {
    e.preventDefault();
    setStep(step - 1);
  };
  return (
    <>
      {countries.length > 0 &&
      specialities.length > 0 &&
      employeesRanges.length > 0 ? (
        <Forms step={step} />
      ) : (
        <Box
          style={{
            width: 450,
            height: 463,
            padding: "45px 27.9px 0 26px",
            opacity: 0.79,
            boxShadow: "0 6px 12px 0 rgba(0, 0, 0, 0.16)",
            border: "solid 1px #eaf0f5",
            backgroundColor: "#fff",
          }}
        >
          <Animation />
        </Box>
      )}
    </>
  );
}

export default MainContractor;
