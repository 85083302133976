import { Grid } from '@mui/material'
import React, { useState, useEffect } from 'react'
import homeBg from '../../Assets/Images/Dashboard/homeBg.png'
import connecting from '../../Assets/Images/Dashboard/connecting.png'
import connected from '../../Assets/Images/Dashboard/connected.png'
import failed from '../../Assets/Images/Dashboard/failed.png'
import { useNavigate } from 'react-router-dom'
import DashNavBar from './DashNavBar';

export default function Home() {
    const [connect, setConnect] = useState("");

    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setTimeout(() => {
            navigate('/machine/dashboard')
        }, 3000);
    }, [])

    return (
        <Grid
            sx={{
                padding: '2% 3.5% 0 3.5%',
                backgroundImage: `url(${homeBg})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                paddingBottom: '10%',
                height: '100vh'
            }}>
            <DashNavBar />
            <Grid item xs={12} container alignItems='center' justifyContent='center'>
                <Grid item padding={{ xs: '20% 0 0 15%', md: '7% 0 0 7%' }}
                    onMouseOver={(e) => {
                        if (connect === "failed") e.target.style.cursor = 'pointer'
                        else e.target.style.cursor = 'unset'
                    }}
                    onClick={() => {
                        if (connect === "failed") setConnect("");
                    }}>
                    <img src={connect === "connected" ? connected : connect === "failed" ? failed : connecting} alt='connect' width='80%' />
                </Grid>
            </Grid>
        </Grid >
    )
}
