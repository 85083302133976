import * as constFile from "../../saasApiConst";
import axios from "axios";
//Get shedule
const GET_SCHEDULE = constFile.GET_SCHEDULE;
export const getUserSchedule = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_SCHEDULE}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
export const updateSchedule = (
  StartTime,
  EndTime,
  WorkingDays,
  NonWorkingDays,
  WorkingShifts
) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + GET_SCHEDULE}`,
      {
        StartTime: StartTime,
        EndTime: EndTime,
        WorkingDays: WorkingDays,
        NonWorkingDays: NonWorkingDays,
        WorkingShifts: WorkingShifts,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
