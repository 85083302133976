import classes from "../../Assets/Styles/Dashboard/ProductionCompletion.module.css";
import { Backdrop, Button, CircularProgress, Grid } from "@mui/material";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import homeBg from "../../Assets/Images/Dashboard/logInBgColor.png";
import { useReactToPrint } from "react-to-print";
import { ComponentToPrint } from "./ComponentToPrint";
import DashNavBar from "./DashNavBar";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { styled } from "@mui/system";
import * as services from "../../Services/machine-dashboard-services.proxy";
import back from "../../Assets/Images/Dashboard/back1.png";
import { Close } from "@mui/icons-material";
import ErrorModal from "../../SharedComponents/ErrorModal";

const getPrintData = services.getPrintData;
const updateZoneOrBlock = services.updateZoneOrBlock;

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const StyledButton = styled("button")(
  ({ theme }) => `
    font-family: Muli;
    font-size: 12px;
    box-sizing: border-box;
    width: 237px;
    height: 63px;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#c6e1d0"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
    border-top-left-radius: 0.75em;
    border-top-right-radius: 0.75em;
    margin: 0.5em;
    padding: 10px;
    text-align: left;
    line-height: 1.5;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};

    &:hover {
      background: ${theme.palette.mode === "dark" ? "" : grey[100]};
      border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }

    &.${selectUnstyledClasses.focusVisible} {
      outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]
    };
    }

    &.${selectUnstyledClasses.expanded} {
      &::after {
        content: '▴';
      }
    }

    &::after {
      content: '▾';
      float: right;
    }
    `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
    font-family: Muli;
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 5px;
    margin: 10px 0;
    width: 237px;
    height: 273px;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#c6e1d0"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
    border-radius: 0.75em;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    overflow: auto;
    outline: 0px;
    `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
    list-style: none;
    padding: 15px;
    cursor: default;
    &:last-of-type {
      border-bottom: none;
    }
    font-size: 16px;
    &.${optionUnstyledClasses.selected} {
      background-color: ${theme.palette.mode === "dark" ? blue[900] : "#abcbb7"
    };
      color: ${theme.palette.mode === "dark" ? blue[100] : "#101650"};
    }

    &.${optionUnstyledClasses.highlighted} {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]
    };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }

    &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
      background-color: ${theme.palette.mode === "dark" ? blue[900] : "#abcbb7"
    };
      color: ${theme.palette.mode === "dark" ? blue[100] : "#101650"};
    }

    &.${optionUnstyledClasses.disabled} {
      color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }

    &:hover:not(.${optionUnstyledClasses.disabled}) {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]
    };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
    `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});

const HandlingStyledButton = styled("button")(
  ({ theme }) => `
    font-family: Muli;
    font-size: 12px;
    box-sizing: border-box;
    width: 237px;
    height: 63px;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#88a5be"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
    border-top-left-radius: 0.75em;
    border-top-right-radius: 0.75em;
    margin: 0.5em;
    padding: 10px;
    text-align: left;
    line-height: 1.5;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};

    &:hover {
      background: ${theme.palette.mode === "dark" ? "" : grey[100]};
      border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }

    &.${selectUnstyledClasses.focusVisible} {
      outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]
    };
    }

    &.${selectUnstyledClasses.expanded} {
      &::after {
        content: '▴';
      }
    }

    &::after {
      content: '▾';
      float: right;
    }
    `
);

const HandlingStyledListbox = styled("ul")(
  ({ theme }) => `
    font-family: Muli;
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 5px;
    margin: 10px 0;
    width: 237px;
    height: 273px;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#88a5be"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
    border-radius: 0.75em;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    overflow: auto;
    outline: 0px;
    `
);

const HandlingStyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
    list-style: none;
    padding: 15px;
    cursor: default;
    &:last-of-type {
      border-bottom: none;
    }
    font-size: 16px;
    &.${optionUnstyledClasses.selected} {
      background-color: ${theme.palette.mode === "dark" ? blue[900] : "#abcbb7"
    };
      color: ${theme.palette.mode === "dark" ? blue[100] : "#101650"};
    }

    &.${optionUnstyledClasses.highlighted} {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]
    };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }

    &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
      background-color: ${theme.palette.mode === "dark" ? blue[900] : "#abcbb7"
    };
      color: ${theme.palette.mode === "dark" ? blue[100] : "#101650"};
    }

    &.${optionUnstyledClasses.disabled} {
      color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }

    &:hover:not(.${optionUnstyledClasses.disabled}) {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]
    };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
    `
);

const HandlingStyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const HandlingCustomSelect = React.forwardRef(function CustomSelect(
  props,
  ref
) {
  const components = {
    Root: HandlingStyledButton,
    Listbox: HandlingStyledListbox,
    Popper: HandlingStyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});

const styles = {
  selectTitle: {
    fontFamily: "Muli",
    fontSize: 15,
    lineHeight: 1.27,
    textAlign: "left",
    color: "#444",
    paddingLeft: "5%",
  },
};

const MachinePrintLabel = (props) => {
  let navigate = useNavigate();

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const lineId = query.get("lineId");
  const CycleId = query.get("CycleId");
  const [BundleId, setBundleId] = useState(query.get("BundleId"));
  const CategoryId = query.get("CategoryId");
  const IsLine = query.get("IsLine");

  const [data, setData] = useState(null);

  const [loading, setLoading] = useState(false);

  const [storageBlocks, setStorageBlocks] = useState([]);

  const [handlingBlocks, setHandlingBlocks] = useState([]);

  const [zones, setZones] = useState([]);

  const [storageId, setStorageId] = useState(null);
  const [zoneId, setZoneId] = useState(null);
  const [handlingId, setHandlingId] = useState(null);

  const [confirmed, setConfirmed] = useState(false);

  const [loadingData, setLoadingData] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [BEerror, setBEerror] = useState(null);
  const [navigateToOnClose, setNavigateToOnClose] = useState(-1);

  const [disableSelection, setDisableSelection] = useState(false);

  useEffect(() => {
    let apiTest = true;
    setLoadingData(true);
    getPrintData(
      lineId ? lineId : null,
      lineId ? null : BundleId,
      localStorage.getItem("machineId"),
      lineId ? 3 : CategoryId,
      null,
      CycleId ? +CycleId : null,
      null,
      localStorage.getItem("InventoryId")
    ).then((x) => {

      if (apiTest) {
        if (x.status || x.statusCode) {
          if (x.status) setBEerror(x.error)
          if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
          setOpenErrorModal(true);
        } else {
          if (x.BundleId) setBundleId(x.BundleId);
          setData(x);
          setLoading(x.IsLoading);
          setStorageBlocks(x.Blocks.filter((b) => b.BlockTypeId === 1));
          setHandlingBlocks(x.Blocks.filter((b) => b.BlockTypeId === 3));
        }
        setLoadingData(false);


      }
    });
    return () => {
      apiTest = false;
    };
  }, []);

  const addInZone = () => { };

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [printing, setPrinting] = useState(false);
  const [loadingPhase, setLoadingPhase] = useState(false);

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if ((lineId || BundleId) && (zoneId || handlingId || loadingPhase))
      setDisabled(false);
    else setDisabled(true);
  }, [zoneId, handlingId, loadingPhase]);

  const updateToFinishCycle = () => {
    setPrinting(true);
    localStorage.setItem("GenRemenant", "false");
    if (lineId) {
      if (handlingId)
        // updateZoneOrBlock(null, handlingId, lineId, null, parseInt(CycleId)).then((x) => {
        updateZoneOrBlock(null, handlingId, lineId, BundleId, parseInt(CycleId)).then((x) => {
          setPrinting(false);
          if (x.status || x.statusCode) {
            if (x.status) setBEerror(x.error)
            if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
            setOpenErrorModal(true);

          } else {
            setHandlingId(null);
            if (!IsLine) {
              setDisableSelection(true);
              setZoneId(null);
              setStorageId(null);
              setStorageBlocks([]);
              setHandlingBlocks([]);
              setZones([]);
              handlePrint();
            }
            else navigate(-1)
          }

        });
      if (zoneId)
        // updateZoneOrBlock(zoneId, null, lineId, null, CycleId).then((x) => {
        updateZoneOrBlock(zoneId, null, lineId, BundleId, CycleId).then((x) => {
          setPrinting(false);
          if (x.status || x.statusCode) {
            if (x.status) setBEerror(x.error)
            if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
            setOpenErrorModal(true);
            setLoadingData(false);

          } else {
            setZoneId(null);
            setStorageId(null);
            if (!IsLine) {
              handlePrint();
              setZoneId(null);
              setStorageId(null);
              setStorageBlocks([]);
              setHandlingBlocks([]);
              setZones([]);
              setDisableSelection(true);

            }
            else navigate(-1)


          }

        });
    } else {
      if (BundleId && zoneId) {
        updateZoneOrBlock(zoneId, null, null, BundleId, CycleId).then((x) => {
          setPrinting(false);
          if (x.status || x.statusCode) {
            if (x.status) setBEerror(x.error)
            if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
            setOpenErrorModal(true);
            setLoadingData(false);

          } else {
            handlePrint();
            setZoneId(null);
            setStorageId(null);
            setStorageBlocks([]);
            setHandlingBlocks([]);
            setZones([]);
            setDisableSelection(true);


          }

        });
      }
    }
  };

  return (
    <>
      <Grid
        container
        sx={{
          backgroundImage: `url(${homeBg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          paddingBottom: "10%",
          minHeight: "100vh",
        }}
      >
        {data ? (
          <Grid item container>
            <Grid item container xs={12} direction="row" gap={5}>
              <Grid item>
                <ComponentToPrint
                  ref={componentRef}
                  heightDifference={0}
                  salesOrderFields={data?.TagData}
                />
              </Grid>
              <Grid item paddingTop={3}>
                <Grid item container xs={12} justifyContent="space-between">
                  <Grid
                    item
                    sx={{
                      fontFamily: "Muli",
                      fontSize: 25,
                      fontWeight: 600,
                      lineHeight: 1.24,
                      textAlign: "left",
                      color: "#101650",
                      paddingLeft: "1%",
                    }}
                  >
                    {lineId
                      ? loading
                        ? "Finished Goods"
                        : "WIP Goods"
                      : localStorage.getItem("Category") === "Remnant" ||
                        localStorage.getItem("GenRemenant") === "true"
                        ? "Remnant"
                        : "Raw Material"}
                  </Grid>
                  <Grid item>
                    <img
                      onMouseOver={(e) => (e.target.style.cursor = "pointer")}
                      onClick={() => navigate(-1)}
                      src={back} alt="back" />
                  </Grid>
                </Grid>
                <Grid item container xs={12} alignSelf="flex-start">
                  <Grid
                    item
                    xs={5}
                    md={4.7}
                    lg={4.5}
                    xl={4.4}
                    className={classes["Label-name-Labe-size"]}
                  >
                    Label name
                  </Grid>
                  <Grid item xs={6}>
                    <span className={classes["Height-127mm-Width-75mm"]}>
                      {" "}
                      {data?.LabelName}
                    </span>
                  </Grid>
                </Grid>
                <Grid item container paddingTop={3} xs={12}>
                  <Grid
                    item
                    xs={5}
                    md={4.7}
                    lg={4.5}
                    xl={4.4}
                    className={classes["Label-name-Labe-size"]}
                  >
                    Label size
                  </Grid>

                  <Grid
                    item
                    container
                    xs={7}
                    directon="column"
                    className={classes["Height-127mm-Width-75mm"]}
                  >
                    <Grid item xs={12}>
                      Height {data?.LabelHeight}
                    </Grid>

                    <Grid item xs={12}>
                      Width {data?.LabelWidth}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} container marginTop={3}>
                  {handlingId || loadingPhase ? (
                    ""
                  ) : (
                    <>
                      {/* storage */}
                      <Grid
                        item
                        xs={6}
                        container
                        direction="column"
                        alignIems="center"
                        justifyContent="center"
                      >
                        <Grid item sx={styles.selectTitle}>
                          Storage Block
                        </Grid>
                        <Grid item>
                          <CustomSelect
                            value={storageId}
                            onChange={(option) => {
                              if (!disableSelection) {
                                setStorageId(option);
                                setZones(
                                  storageBlocks.find((s) => s.BlockId === option)
                                    ?.Zones
                                );
                                // console.log(
                                //   storageBlocks.find((s) => s.BlockId === option)
                                // );
                              }
                            }}
                          >
                            <StyledOption value={null} disabled>
                              Storage
                            </StyledOption>
                            {lineId
                              ? loading
                                ? storageBlocks
                                  ?.filter((b) => b.CategoryId === 5)
                                  .map((s, index) => (
                                    <StyledOption key={index} value={s.BlockId}>
                                      {s.Code}
                                    </StyledOption>
                                  ))
                                : storageBlocks
                                  ?.filter((b) => b.CategoryId === 4)
                                  .map((s, index) => (
                                    <StyledOption key={index} value={s.BlockId}>
                                      {s.Code}
                                    </StyledOption>
                                  ))
                              : localStorage.getItem("Category") === "Remnant" ||
                                localStorage.getItem("GenRemenant") === "true"
                                ? storageBlocks
                                  ?.filter((b) => b.CategoryId === 2)
                                  .map((s, index) => (
                                    <StyledOption key={index} value={s.BlockId}>
                                      {s.Code}
                                    </StyledOption>
                                  ))
                                : storageBlocks
                                  ?.filter((b) => b.CategoryId === 1)
                                  .map((s, index) => (
                                    <StyledOption key={index} value={s.BlockId}>
                                      {s.Code}
                                    </StyledOption>
                                  ))}
                          </CustomSelect>
                        </Grid>
                      </Grid>
                      {/* zone */}
                      <Grid
                        item
                        xs={6}
                        container
                        direction="column"
                        alignIems="center"
                        justifyContent="center"
                      >
                        <Grid item sx={styles.selectTitle}>
                          Zone
                        </Grid>
                        <Grid item>
                          <CustomSelect
                            value={zoneId}
                            onChange={(option) => {
                              console.log(option);
                              setZoneId(option);
                            }}
                          >
                            <StyledOption value={null} disabled>
                              Zone
                            </StyledOption>
                            {zones?.map((s, index) => (
                              <StyledOption key={index} value={s.Id}>
                                {s.Code}
                              </StyledOption>
                            ))}
                          </CustomSelect>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {lineId && !zoneId ? (
                    loading ? (
                      //  loadingPhase
                      <Grid
                        item
                        container
                        direction="column"
                        alignIems="center"
                        justifyContent="center"
                        paddingTop="2%"
                      >
                        <Button
                          onClick={() => {
                            setLoadingPhase(true);
                          }}
                          sx={{
                            width: "156px",
                            height: "53px",
                            padding: "17px 15px",
                            borderRadius: "7px",
                            boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.61)",
                            border: "solid 3px #101650",
                            fontFamily: "Muli",
                            fontSize: 15,
                            fontWeight: "bold",
                            lineHeight: 1.27,
                            textAlign: "left",
                            color: "#101650",
                            textTransform: "none",
                          }}
                        >
                          Loading Phase
                        </Button>
                      </Grid>
                    ) : (
                      // handling
                      <>
                        {handlingId || loadingPhase || zoneId ? (
                          ""
                        ) : (
                          <Grid
                            item
                            sx={styles.selectTitle}
                            style={{ fontWeight: "bold", marginTop: "5%" }}
                          >
                            Or
                          </Grid>
                        )}

                        <Grid
                          item
                          container
                          direction="column"
                          alignIems="center"
                          justifyContent="center"
                          marginTop={3}
                        >
                          <Grid item sx={styles.selectTitle}>
                            Handling Block
                          </Grid>
                          <Grid item>
                            <HandlingCustomSelect
                              value={handlingId}
                              onChange={(option) => {
                                setHandlingId(option);
                              }}
                            >
                              <HandlingStyledOption value={null} disabled>
                                Handling
                              </HandlingStyledOption>
                              {handlingBlocks?.map((s, index) => (
                                <HandlingStyledOption
                                  key={index}
                                  value={s.BlockId}
                                >
                                  {s.Code}
                                </HandlingStyledOption>
                              ))}
                            </HandlingCustomSelect>
                          </Grid>
                        </Grid>
                      </>
                    )
                  ) : (
                    ""
                  )}
                </Grid>
                {confirmed ? (
                  <Grid
                    item
                    container
                    direction="row-reverse"
                    alignIems="center"
                    justifyContent="center"
                    marginLeft="20%"
                    paddingTop="2%"
                  >
                    <Button
                      onClick={() => {
                        setStorageId(null);
                        setZoneId(null);
                        setHandlingId(null);
                        setConfirmed(false);
                        setLoadingPhase(false);
                      }}
                      sx={{
                        width: "103px",
                        height: "47px",
                        padding: "10px",
                        borderRadius: "5px",
                        backgroundColor: "#fff",
                        fontFamily: "Muli",
                        fontSize: "18px",
                        lineHeight: 1.28,
                        textAlign: "left",
                        color: "#0674b9",
                        textTransform: "none",
                        cursor: "pointer",
                      }}
                    >
                      Reset
                    </Button>
                  </Grid>
                ) : zoneId || handlingId || loadingPhase ? (
                  <Grid
                    item
                    container
                    direction="row-reverse"
                    alignIems="center"
                    justifyContent="center"
                    marginLeft="20%"
                    paddingTop="2%"
                  >
                    <Button
                      onClick={() => setConfirmed(true)}
                      sx={{
                        width: "103px",
                        height: "47px",
                        padding: "10px",
                        borderRadius: "5px",
                        backgroundColor: "#fff",
                        fontFamily: "Muli",
                        fontSize: "18px",
                        lineHeight: 1.28,
                        textAlign: "left",
                        color: "#0674b9",
                        textTransform: "none",
                        cursor: "pointer",
                      }}
                    >
                      Confirm
                    </Button>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <Grid
              item
              paddingTop="9%"
              container
              direction="row-reverse"
              xs={10.5}
            >
              <button
                className={classes["print-button"]}
                disabled={disabled || !confirmed || printing}
                onClick={() => updateToFinishCycle()}
              >
                {IsLine
                  ? printing
                    ? "Assigning ..."
                    : "Assign"
                  : printing
                    ? "Printing ..."
                    : "Print"}
              </button>
            </Grid>
          </Grid>
        ) : (
          <Backdrop
            sx={{
              color: "#fff",
              backgroundColor: "transparent",
              zIndex: (theme) => theme.zIndex.drawer + 1,
              display: "flex",
              flexDirection: "column",
            }}
            open={loadingData}
          >
            <CircularProgress />
            <Grid
              item
              fontFamily={"Muli"}
              fontSize={15}
              fontWeight={"bold"}
              color={"rgb(25, 118, 210)"}
            >
              Loading Data...
            </Grid>
          </Backdrop>
        )}
      </Grid>
      <ErrorModal openErrorModal={openErrorModal} setOpenErrorModal={setOpenErrorModal} BEerror={BEerror} navigateToOnClose={navigateToOnClose} />

    </>
  );
};

export default MachinePrintLabel;
