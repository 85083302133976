import { Grid,Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React from 'react';

export default function SentMobile({setBooked}) {
    const navigate = useNavigate();
    return (
    <Grid container direction={'column'} spacing={2.5} alignItems={'center'} padding={'3.5% 3.5% 0 3.5%'}>
        <Grid item>
            <div style={{marginLeft:'1%',fontSize:20,fontFamily:'Muli',lineHeight:1.14,textAlign:'left',color: '#080707'}}>
            Pilecubes received your email and will connect you with a specialist from our team to walk you through our 1st of a kind global construction SaaS.
            </div>
        </Grid>
        <Grid item>
        <button className="submitButton1"
                style={{ backgroundColor: "#e1e319", color: "#fff", borderRadius: 7 , textTransform:"none",width:80}}
                onClick={()=>{setBooked(false);navigate('/')}}
        >
            Got it
        </button>
        </Grid>
    </Grid>
  );
}
