import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Table from "../../Table/Table";
import ClientModal from "./ClientModal";
import * as saasServices from "../../../Services/saas-services.proxy";
import logoimg from "../../../Assets/Images/Table/logo.png";
import fileimg from "../../../Assets/Images/Table/file.png";
import ClientTable from "./ClientTable";
import SaasBack from "../../../Assets/Images/ProfileDetails/SaaSbackground.png";
// const getClientsData = saasServices.getClientsData;
// const deleteClient = saasServices.deleteClient;

const ClientTableView = (props) => {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  const [openModal, setOpenModal] = useState(false);

  const [viewSearch, setViewSearch] = useState(true);
  const [ShowGoBack, setShowGoBack] = useState(true);

  const [rowData, setRowData] = useState(null);
  const [paging, setPaging] = useState(true);
  const [modify, setModify] = useState(false);

  return (
    <div
      style={{
        minHeight: "87vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <Grid paddingLeft={5} paddingTop={5}>
        <ClientTable
          paging={false}
          tableHeight={480}
          showViewAll={false}
          ShowGoBack={true}
          openModal={openModal}
          setOpenModal={setOpenModal}
          setRowData={setRowData}
          viewSearch={true}
          modify={modify}
        />
      </Grid>
      <ClientModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        rowData={rowData}
        setRowData={setRowData}
        setModify={setModify}
        modify={modify}
      />
    </div>
  );
};

export default ClientTableView;
