import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import SaasBack from "../../Assets/Images/Projects/bgTableProject.png";
import classes from "../../Assets/Styles/projects.module.css";
import { TextField, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Stack } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
//import * as constFile from "../../../projectConst";
import MenuPic from "../../Assets/Images/Table/menuPic.png";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { styled } from "@mui/system";
import * as activityServices from "../../Services/activities-services.proxy";
import DeleteActivityModal from "./DeleteActivityModal";
import * as permissionsFunctions from "../../../ReusableFunctions";
const CheckIfPermitted = permissionsFunctions.CheckIfPermitted;
const getActivitiesList = activityServices.getActivitiesList;
const deleteActivity = activityServices.deleteActivity;

const planType = localStorage.getItem("planId");

//Statuses
// const ACTIVE = constFile.PACTIVE;
// const ARCHIVED = constFile.PARCHIVED;

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
    font-family: IBM Plex Sans, sans-serif;
    font-size: 10px;
    box-sizing: border-box;
    min-height: 32px;
    width: 9rem;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
    border-radius: 0.75em;
    margin: 0.5em;
    padding: 10px;
    text-align: left;
    line-height: 1.5;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  
    &:hover {
      background: ${theme.palette.mode === "dark" ? "" : grey[100]};
      border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }
  
    &.${selectUnstyledClasses.focusVisible} {
      outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]
    };
    }
  
    &.${selectUnstyledClasses.expanded} {
      &::after {
        content: '▴';
      }
    }
  
    &::after {
      content: '▾';
      float: right;
    }
    `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
    font-family: Muli;
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 5px;
    margin: 10px 0;
    min-width: 11rem;
    height:200px;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
    border-radius: 0.75em;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    overflow: auto;
    outline: 0px;
    `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
    list-style: none;
    padding: 8px;
    border-radius: 0.45em;
    cursor: default;
  
    &:last-of-type {
      border-bottom: none;
    }
  
    &.${optionUnstyledClasses.selected} {
      background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]
    };
      color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
    }
  
    &.${optionUnstyledClasses.highlighted} {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]
    };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
  
    &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
      background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]
    };
      color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
    }
  
    &.${optionUnstyledClasses.disabled} {
      color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }
  
    &:hover:not(.${optionUnstyledClasses.disabled}) {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]
    };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
    `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});
export default function ActivitiesTable(props) {
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );

  const [disabled, setDisabled] = useState(false);
  const [createDisabled, setCreateDisabled] = useState(false);
  useEffect(() => {
    if (UserPermissions) {
      CheckIfPermitted(
        UserPermissions,
        "Drawing Activity",
        "Create",
        setCreateDisabled
      );
    }
    if (!localStorage.getItem("isSubscribed")) {
      setCreateDisabled(true);
    }
  }, [UserPermissions]);
  useEffect(() => {
    props.setRegister(true);
  }, [props]);

  useEffect(() => {
    props.setNaved(false);
  }, [props]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [ActivityTable, setActivityTable] = useState([]);
  const [dataLength, setdataLength] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const [pageSize, setPageSize] = useState(25);
  const [modify, setModify] = useState(false);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();
  //FILTERS
  const [discipline, setDiscipline] = useState(0);
  const [disciplines, setDisciplines] = useState([]);
  const [teamMember, setTeamMember] = useState(0);
  const [teamMembers, setTeamMembers] = useState([]);
  const [status, setStatus] = useState(0);
  const [statuses, setStatuses] = useState([]);
  const [approvalStatus, setApprovalStatus] = useState(0);
  const [approvalStatuses, setApprovalStatuses] = useState([]);
  const [type, setType] = useState(0);
  //const [types, setTypes] = useState([]);
  const [trade, setTrade] = useState(0);
  const [trades, setTrades] = useState([]);
  const [open, setOpen] = useState(false);

  const [loadingData, setLoadingData] = useState(false);
  const listData = {
    Pagination: {
      PageSize: pageSize,
      PageNumber: pageNumber,
    },
    Search: searchText,
    SortList: [],
    TradeId: trade === 0 ? null : trade,
    DisciplineId: discipline === 0 ? null : discipline,
    StatusId: status === 0 ? null : status,
    SubmittalStatusId: approvalStatus === 0 ? null : approvalStatus,
    TeamMemberId: teamMember === 0 ? null : teamMember,
    TypeId: type === 0 ? null : type,
  };

  useEffect(() => {
    let apiTest = true;
    setLoadingData(true);
    getActivitiesList(listData).then((x) => {
      if (apiTest) {
        setActivityTable(x.Activities);
        setTotal(x.Total);
        setdataLength(x.Total);
        setTeamMembers(x.TeamMembers);
        setDisciplines(x.Disciplines);
        setTrades(x.Trades);
        setStatuses(x.Statuses);
        setApprovalStatuses(x.SubmittalStatuses);
        setLoadingData(false);
      }
    });
    return () => {
      apiTest = false;
    };
  }, [
    searchText,
    pageNumber,
    pageSize,
    modify,
    trade,
    discipline,
    approvalStatus,
    status,
    teamMember,
  ]);

  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowData, setRowData] = useState();
  const [modalMessage, setModalMessage] = useState();

  const handleClick = async (event, params) => {
    setRowData(params);

    setAnchorEl(event.target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const deleteTheActivity = () => {
    deleteActivity(rowData.row.Id).then(() => {
      setModify(!modify);
    });
  };
  const columns = [
    {
      field: "action",
      headerName: "Actions",
      sortable: false,

      renderCell: (params) => {
        return (
          <img
            src={MenuPic}
            alt="menu"
            onClick={(e) => handleClick(e, params)}
          ></img>
        );
      },
    },
    {
      field: "Code",
      width: 150,
    },
    {
      field: "RevisionNumber",
      headerName: "Revision No.",
      width: 100,
    },
    {
      field: "Description",
      width: 200,
      resizeable: true,
    },
    {
      field: "Type",
      width: 150,
    },
    {
      field: "StartDate",
      headerName: "Start Date",
      width: 150,
      renderCell: (params) => (
        <span>{params.row?.StartDate?.slice(0, 10)}</span>
      ),
    },
    {
      field: "EndDate",
      headerName: "End Date",
      width: 150,
      renderCell: (params) => <span>{params.row?.EndDate?.slice(0, 10)}</span>,
    },
    {
      field: "Status",
      headerName: "Activity Status",
      width: 150,
    },
    {
      field: "SubmittalStatus",
      headerName: "Submittal Status",
      width: 150,
    },
    {
      field: "TeamMember",
      headerName: "Assignee",
      width: 150,
    },
    {
      field: "PlannedDuration",
      headerName: "Planned Duration",
      width: 150,
    },
    {
      field: "SubProject",
      headerName: "Subproject Name",
      width: 200,
    },
    {
      field: "Phase",
      headerName: "Phase Name",
      width: 200,
    },
    {
      field: "Project",
      headerName: "Project Name",
      width: 200,
    },
    {
      field: "Discipline",
      width: 150,
    },
    {
      field: "Trade",
      width: 150,
    },
    {
      field: "Creator",
      width: 150,
    },
  ];
  return (
    <div
      style={{
        minHeight: "90vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      {disabled ? (
        <Grid
          item
          container
          bgcolor={"rgba(225, 227, 25, 0.5)"}
          xs={12}
          marginTop={1}
          padding={1}
        >
          <Grid item md={4}></Grid>
          <Grid
            item
            md={6}
            style={{
              fontFamily: "Muli",
              fontSize: "17px",
              fontWeight: "bold",
              textAlign: "left",
              color: "#101650",
            }}
          >
            <Grid>
              {planType === "Pro"
                ? "Upgrade subscription required (Pro+)"
                : planType === "Core"
                  ? "Upgrade subscription required (Pro or Pro+)"
                  : localStorage.getItem("isGuest") === "true"
                    ? "Please subscribe to create Activity"
                    : ""}
            </Grid>
          </Grid>

          <Grid item md={2} container>
            {localStorage.getItem("isTeamMember") ? (
              <Grid item xs={8}></Grid>
            ) : (
              <Grid
                item
                onClick={() => {
                  props.setSetupProfile(true);
                  props.setSetupMenu(true);
                  navigate("/saas/profileSettings");
                }}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
              >
                <button
                  style={{
                    padding: "5px",
                    borderRadius: "5px",
                    backgroundColor: "#101650",
                    fontFamily: "Muli",
                    fontSize: "13px",
                    textAlign: "center",
                    border: 0,
                    color: "#fff",
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    props.setSetupProfile(true);
                    props.setSetupMenu(true);
                    navigate("/saas/profileSettings");
                  }}
                >
                  Subscribe
                </button>
              </Grid>
            )}
            <Grid
              item
              paddingLeft={1}
              onClick={() => {
                setDisabled(false);
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
            >
              <CloseIcon fontSize="medium" />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <Grid container paddingTop={5} paddingLeft={2.5}>
        <Grid
          item
          container
          direction={"row"}
          md={12}
          paddingBottom={2}
          paddingLeft={4}
        >
          <Grid item xs={3} sm={7} md={7} container direction={"row"}>
            <div style={{ display: "flex", direction: "row", flex: 1 }}>
              <span className={classes["table-header"]}>Activities</span>
              <Grid
                className={classes["Line-523"]}
                display={{ xs: "none", md: "inherit" }}
              ></Grid>
            </div>
          </Grid>

          <Grid
            item
            xs={7}
            md={5}
            sm={5}
            container
            direction={"row-reverse"}
            paddingRight={{ md: 5 }}
          >
            <Grid item md={2} xs={2.5} paddingLeft={4}>
              <button
                disabled={
                  localStorage.getItem("IsSubscribedBefore") === "true"
                  ? true
                  : createDisabled &&
                    localStorage.getItem("isGuest") === "false"
                  ? true
                  : false
                  // createDisabled ||
                  //   localStorage.getItem("IsSubscribedBefore") === "true"
                  //   ? true
                  //   : false
                }
                className={classes["AddButton"]}
                onClick={() => {
                  if (
                    (total === 1000 && planType === "Core") ||
                    (total === 5000 && planType === "Pro")
                  )
                    setDisabled(true);
                  else {
                    navigate(`/saas/execution/create?from="table"`);
                  }
                }}
              >
                Add
              </button>
            </Grid>
            <Grid item md={1} xs={2.5} paddingLeft={1}>
              <button
                className={classes["back-button"]}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Go back
              </button>
            </Grid>
            <Grid item md={9} xs={6}>
              <Grid
                className={classes["Line-523"]}
                display={{ xs: "none", md: "inherit" }}
              ></Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={10} container direction={"row-reverse"}>
          <Grid
            item
            md={12}
            lg={12}
            marginTop={1}
            container
            direction={"row-reverse"}
          >
            <Grid item>
              <CustomSelect
                value={trade}
                onChange={(option) => {
                  setTrade(option);
                }}
              >
                <StyledOption value={0} disabled>
                  Trade
                </StyledOption>
                {trades.map((s) => (
                  <StyledOption key={s.Id} value={s.Id}>
                    {s.Name}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Grid>
            {/* <Grid item>
              <CustomSelect
                value={trade}
                onChange={(option) => {
                  setType(option);
                }}
              >
                <StyledOption value={0} disabled>
                  Type
                </StyledOption>
                {types.map((s) => (
                  <StyledOption key={s.Id} value={s.Id}>
                    {s.Name}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Grid> */}
            <Grid item>
              <CustomSelect
                value={approvalStatus}
                onChange={(option) => {
                  setApprovalStatus(option);
                }}
              >
                <StyledOption value={0} disabled>
                  Approval Status
                </StyledOption>
                {approvalStatuses.map((s) => (
                  <StyledOption key={s.Id} value={s.Id}>
                    {s.Name}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Grid>
            <Grid item>
              <CustomSelect
                value={status}
                onChange={(option) => {
                  setStatus(option);
                }}
              >
                <StyledOption value={0} disabled>
                  Status
                </StyledOption>
                {statuses.map((s) => (
                  <StyledOption key={s.Id} value={s.Id}>
                    {s.Name}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Grid>
            <Grid item>
              <CustomSelect
                value={teamMember}
                onChange={(option) => {
                  setTeamMember(option);
                }}
              >
                <StyledOption value={0} disabled>
                  Assignee
                </StyledOption>
                {teamMembers.map((s) => (
                  <StyledOption key={s.Id} value={s.Id}>
                    {s.Name}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Grid>
            <Grid item>
              <CustomSelect
                value={discipline}
                onChange={(option) => {
                  setDiscipline(option);
                }}
              >
                <StyledOption value={0} disabled>
                  Discipline Reference
                </StyledOption>
                {disciplines.map((s) => (
                  <StyledOption key={s.Id} value={s.Id}>
                    {s.Name}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Grid>
            {discipline || approvalStatus || status || teamMember || trade ? (
              <Grid item marginTop={0.6}>
                <span
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={() => {
                    setStatus(0);
                    setTrade(0);
                    setApprovalStatus(0);
                    setTeamMember(0);
                    setDiscipline(0);
                    setType(0);
                  }}
                  style={{
                    fontFamily: "Muli",
                    fontSize: 10,
                    color: "#707070",
                    paddingTop: 25,
                    paddingRight: 15,
                  }}
                >
                  Clear filters
                </span>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Grid
          item
          md={2}
          container
          direction={"row-reverse"}
          paddingRight={{ md: 4, xs: 0 }}
        >
          <Box
            sx={{
              p: 0.5,
              pb: 0,
            }}
          >
            <TextField
              variant="standard"
              fullWidth
              onChange={inputHandler}
              placeholder="Search this list…"
              InputProps={{
                startAdornment: (
                  <SearchIcon
                    fontSize="small"
                    htmlColor={"#707070"}
                    style={{ paddingLeft: 5 }}
                  />
                ),
              }}
              sx={{
                fontFamily: "Muli",
                backgroundColor: "white",
                borderRadius: 1,
                width: {
                  xs: 1,
                  sm: "auto",
                },
                m: (theme) => theme.spacing(1, 0.5, 1.5),
                "& .MuiSvgIcon-root": {
                  mr: 0.5,
                },
                "& .MuiInput-underline:before": {
                  borderBottom: 0,
                  borderColor: "white",
                },
              }}
            />
          </Box>
        </Grid>

        <div
          style={{
            height: 500,
            width: "96.5%",
            fontFamily: "Muli",
            backgroundColor: "white",
            marginTop: 10,
            marginLeft: "2%",
          }}
        >
          <DataGrid
            initialState={{
              pagination: {
                pageSize: 25,
              },
            }}
            pagination
            paginationMode="server"
            rows={ActivityTable ? ActivityTable : []}
            columns={columns}
            headerHeight={40}
            density={"compact"}
            showColumnRightBorder
            sx={{
              [`& .${gridClasses.row}`]: {
                color: "#0674b9",
                overflow: "auto",
                bgcolor: (theme) =>
                  theme.palette.mode === "light" ? grey[0] : grey[500],
              },

              [`& .${gridClasses.columnHeader}`]: {
                bgcolor: "rgb(211 225 238)",
                color: "rgb(17 24 84)",
              },
              [`& .${gridClasses.columnHeaderTitle}`]: {
                fontFamily: "Muli",
                fontWeight: "bold",
              },

              [`& .${gridClasses.footerContainer}`]: {
                bgcolor: "rgb(216 235 247)",
                minHeight: "30px",
              },
              fontFamily: "Muli",
              fontSize: "13px",
            }}
            disableColumnFilter
            getRowId={(row) => row.Id}
            rowCount={dataLength}
            hideFooter={props.paging}
            onPageChange={(newPage) => {
              if (pageSize > dataLength) {
                setPageNumber(1);
              } else setPageNumber(newPage + 1);
            }}
            onPageSizeChange={(newPage) => {
              setPageSize(newPage);
            }}
            pageSize={pageSize}
            loading={loadingData}
            components={{
              NoRowsOverlay: () => (
                <Stack className={classes["EmptyMessage"]}>
                  No Activities to display
                </Stack>
              ),
            }}
          />
        </div>

        <Menu
          id="more-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <MenuItem
            onClick={() => {
              navigate("/saas/execution/create", {
                state: { id: rowData.row.Id, edit: false, from: "table" },
              });
            }}
            style={{
              fontFamily: "Muli",
              fontSize: "13px",
              color: "#444",
            }}
          >
            View Activity
          </MenuItem>
          <Grid>
            <MenuItem
              onClick={() => {
                navigate("/saas/execution/RevisionsActivitiesTable", {
                  state: { id: rowData.row.Id, code: rowData?.row?.Code },
                });
              }}
              style={{
                fontFamily: "Muli",
                fontSize: "13px",
                color: "#444",
              }}
            >
              View Activity Versions
            </MenuItem>
          </Grid>

          {rowData?.row?.CreatorId === localStorage.getItem("UserId") ? (
            <Grid>
              <MenuItem
                onClick={() => {
                  navigate("/saas/execution/create", {
                    state: { id: rowData.row.Id, edit: true, from : "table" },
                  });
                }}
                style={{
                  fontFamily: "Muli",
                  fontSize: "13px",
                  color: "#444",
                }}
              >
                Edit
              </MenuItem>

              <MenuItem
                onClick={() => {
                  if (rowData.row.Status !== "Planned") {
                    setModalMessage(
                      "You can't delete this activity because it is  " +
                      rowData.row.Status
                    );
                  }
                  setOpen(true);
                  handleClose();
                }}
                style={{
                  fontFamily: "Muli",
                  fontSize: "13px",
                  color: "#444",
                }}
              >
                Delete
              </MenuItem>
            </Grid>
          ) : (
            ""
          )}
        </Menu>
        <DeleteActivityModal
          openModal={open}
          setOpenModal={setOpen}
          deleteTheActivity={deleteTheActivity}
          modalMessage={modalMessage}
          setModalMessage={setModalMessage}
        />
      </Grid>
    </div>
  );
}
