import React, { useState, useEffect, useMemo } from "react";
import SaasBack from "../../Assets/Images/SaaSbackground.png";
import { Grid } from "@mui/material";
import classes from "../../Assets/Styles/InventoryManagement.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { TextField } from "@mui/material";
import { styled } from "@mui/system";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuPic from "../../Assets/Images/Table/menuPic.png";

import { Stack } from "@mui/material";
import * as InventoryManagementServices from "../../Services/InventoryManagement-services.proxy";
import { useSelector } from "react-redux";
import * as permissionFunctions from "../../../ReusableFunctions";
import ErrorModal from "../../SharedComponents/ErrorModal";

const CheckIfPermitted = permissionFunctions.CheckIfPermitted;

const getMRTables = InventoryManagementServices.getMRTableList;


function MaterialReceiptTable(props) {
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );

  const [diableI, setDisableI] = useState(false);

  useEffect(() => {
    if (UserPermissions) {
      CheckIfPermitted(
        UserPermissions,
        "Inventory Management",
        "Edit",
        setDisableI
      );
    }
  }, [UserPermissions]);
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  const navigate = useNavigate();
  const [searchText, setSearchText] = useState(null);
  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();


  const [typeId, setTypeId] = useState(null);
  const [types, setTypes] = useState([]);

  const [materialReceiptTableData, setMaterialReceiptsTableData] = useState([]);
  const [dataLength, setdataLength] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);


  const [anchorEl, setAnchorEl] = useState(null);
  const [rowData, setRowData] = useState(null);

  const [loadingData, setLoadingData] = useState(false);

  const [inventoryId, setInventoryId] = useState(0);
  const [inventoriesList, setInventoriesList] = useState([]);

  const handleClick = async (event, params) => {
    setRowData(params);

    setAnchorEl(event.target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const columns = [
    { field: "Id", hide: true, hideable: false },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      position: "sticky",
      renderCell: (params) => {
        return (
          <img
            src={MenuPic}
            alt="menu"
            onClick={(e) => handleClick(e, params)}
          ></img>
        );
      },
    },
    {
      field: "Code",
      headerName: "Material Receipt Code",
      width: 200,
    },
    {
      field: "Inventory Name",
      width: 190,
      renderCell: (params) => (
        <span>
          {inventoriesList?.find((inv) => inv.Id === params.row?.InventoryId)?.Name}

        </span>

      ),
    },
    {
      field: "PurchaseOrderRef",
      headerName: "Purchase Order Ref",
      width: 150,
    },
    {
      field: "Input Date",
      width: 150,
      renderCell: (params) => (
        <span>
          {params.row?.InputDate.slice(0, 10)}
        </span>

      ),
    },
    {
      field: "SupplierCode",
      headerName: "Supplier Code",
      width: 150,
    },
    {
      field: "SupplierName",
      headerName: "Supplier Name",
      width: 150,
    },
    {
      field: "SupplierAddress",
      headerName: "Supplier Address",
      width: 180,
    },
    {
      field: "SupplierRef",
      headerName: "Supplier Ref",
      width: 180,
    },
    {
      field: "TotalQuantity",
      headerName: "Total Quantity",
      width: 150,
    },
    {
      field: "TotalPrice",
      headerName: "Total Price",
      width: 150,
    },
    {
      field: "ItemsNumber",
      headerName: "Items Number",
      width: 150,
    },
    {
      field: "MTCsNumber",
      headerName: "MTCs Number",
      width: 150,
    },




  ];
  const listData = {
    Pagination: {
      PageSize: pageSize,
      PageNumber: pageNumber,
    },
    Search: searchText,
    SortList: [],
    InventoryId: inventoryId,
  };

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [BEerror, setBEerror] = useState(null);

  useEffect(() => {
    let apiTest = true;

    setLoadingData(true);
    getMRTables(listData).then((x) => {
      if (apiTest) {
        if (x.status || x.statusCode) {
          if (x.status) setBEerror(x.error)
          if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
          setOpenErrorModal(true);
        } else {
          setMaterialReceiptsTableData(x.Stocks);
          setInventoriesList(x.Inventories);
          if (query.get("inventoryId")) {
            setInventoryId(query.get("inventoryId"))
          }
          setdataLength(x.Total);

        }
        setLoadingData(false);

      }
    });




    return () => {
      apiTest = false;
    };
  }, [pageSize, pageNumber, searchText, inventoryId]);

  const blue = {
    100: "#DAECFF",
    200: "#99CCF3",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
  };

  const grey = {
    100: "#E7EBF0",
    200: "#E0E3E7",
    300: "#CDD2D7",
    400: "#B2BAC2",
    500: "#A0AAB4",
    600: "#6F7E8C",
    700: "#3E5060",
    800: "#2D3843",
    900: "#1A2027",
  };

  const StyledButton = styled("button")(
    ({ theme }) => `
    font-family: Muli;
    font-size: 10px;
    box-sizing: border-box;
    height: 35px;
    width: 9rem;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
    border-radius: 0.75em;
    margin-top: 0.7em;
    padding: 10px;
    text-align: left;
    line-height: 1.5;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  
    &:hover {
      background: ${theme.palette.mode === "dark" ? "" : grey[100]};
      border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }
  
    &.${selectUnstyledClasses.focusVisible} {
      outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]};
    }
  
    &.${selectUnstyledClasses.expanded} {
      &::after {
        content: '▴';
      }
    }
  
    &::after {
      content: '▾';
      float: right;
    }
    cursor: pointer;
    `
  );

  const StyledListbox = styled("ul")(
    ({ theme }) => `
    font-family: "Muli";
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 5px;
    width: 9rem;
    height:120px;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
    border-radius: 0.75em;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    overflow: scroll;
    outline: 0px;
    
    `
  );

  const StyledOption = styled(OptionUnstyled)(
    ({ theme }) => `
    list-style: none;
    padding: 8px;
    border-radius: 0.45em;
    cursor: pointer;
  
    &:last-of-type {
      border-bottom: none;
    }
  
    &.${optionUnstyledClasses.selected} {
      background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
      color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
    }
  
    &.${optionUnstyledClasses.highlighted} {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
  
    &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
      background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
      color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
    }
  
    &.${optionUnstyledClasses.disabled} {
      color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }
  
    &:hover:not(.${optionUnstyledClasses.disabled}) {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
    `
  );

  const StyledPopper = styled(PopperUnstyled)`
    z-index: 1;
  `;

  const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
    const components = {
      Root: StyledButton,
      Listbox: StyledListbox,
      Popper: StyledPopper,
      ...props.components,
    };

    return <SelectUnstyled {...props} ref={ref} components={components} />;
  });
  const [isDisabled, setIsDisabled] = useState(false);

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
        objectFit: "cover",
      }}
    >

      <Grid container padding={{ xs: 1.5, sm: 3.5 }} rowSpacing={0.5}>
        <Grid item container direction="row">
          <Grid item xs={9.8} sm={10.8} md={11.4} className={classes["title"]}>
            All Material Receipts
          </Grid>
          <Grid item container direction="row-reverse" xs={2} sm={1.2} md={0.6}>
            <button
              className={classes["back-button"]}
              onClick={() => {
                navigate(-1);
              }}
            >
              Go Back
            </button>
          </Grid>

        </Grid>
        <Grid item container xs={12} direction="row-reverse" gap={1}>
          <Grid item>

            <TextField
              variant="standard"
              fullWidth
              onChange={inputHandler}
              placeholder="Search this list…"
              InputProps={{
                startAdornment: (
                  <SearchIcon
                    fontSize="small"
                    htmlColor={"#707070"}
                    style={{ paddingLeft: 5 }}
                  />
                ),
              }}
              sx={{
                fontFamily: "Muli",
                backgroundColor: "white",
                borderRadius: 1,
                width: {
                  xs: 1,
                  sm: "auto",
                },
                m: (theme) => theme.spacing(1, 0, 1.5),
                "& .MuiSvgIcon-root": {
                  mr: 0.5,
                },
                "& .MuiInput-underline:before": {
                  borderBottom: 0,
                  borderColor: "white",
                },
              }}
            />
          </Grid>
          <Grid item>
            <CustomSelect
              value={inventoryId}
              onChange={(option) => {
                setInventoryId(option);
              }}
            >
              <StyledOption value={0} disabled>
                Inventory
              </StyledOption>
              {inventoriesList.map((f) => (
                <StyledOption key={f.Id} value={f.Id}>
                  {f.Name}
                </StyledOption>
              ))}
            </CustomSelect>
          </Grid>
          {inventoryId ? (
            <Grid item marginTop={0.6}>
              <button
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={() => {
                  setInventoryId(0);
                }}
                className={classes["Rectangle-1224"]}
              >
                Clear filters
              </button>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        <Grid item container xs={12}>
          <Grid
            container
            style={{
              height: "100vh",
              fontFamily: "Muli",
              backgroundColor: "white",
            }}
          >
            <DataGrid
              initialState={{
                pagination: {
                  pageSize: 25,
                },
              }}
              pagination
              paginationMode="server"
              rows={materialReceiptTableData ? materialReceiptTableData : []}
              columns={columns}
              headerHeight={40}
              density={"compact"}
              showColumnRightBorder
              sx={{
                [`& .${gridClasses.row}`]: {
                  color: "#0674b9",
                  overflow: "auto",
                  bgcolor: (theme) =>
                    theme.palette.mode === "light" ? grey[0] : grey[500],
                },

                [`& .${gridClasses.columnHeader}`]: {
                  bgcolor: "rgb(172 201 224)",
                  color: "rgb(17 24 84)",
                },
                [`& .${gridClasses.columnHeaderTitle}`]: {
                  fontFamily: "Muli",
                  fontWeight: "bold",
                },

                [`& .${gridClasses.footerContainer}`]: {
                  bgcolor: "rgb(216 235 247)",
                  minHeight: "30px",
                },
                fontFamily: "Muli",
                fontSize: "13px",
              }}
              disableColumnFilter
              getRowId={(row) => row.Id}
              rowCount={dataLength}
              hideFooter={props.paging}
              onPageChange={(newPage) => {
                if (pageSize > dataLength) {
                  setPageNumber(1);
                } else setPageNumber(newPage + 1);
              }}
              onPageSizeChange={(newPage) => {
                setPageSize(newPage);
              }}
              pageSize={pageSize}
              loading={loadingData}
              components={{
                NoRowsOverlay: () => (
                  <Stack className={classes["EmptyMessage"]}>
                    No rows to display
                  </Stack>
                ),
              }}
            />

            <Menu
              id="more-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}

              disableScrollLock
            >
              <MenuItem
                disabled={diableI}
                onClick={(event) => {
                  if (diableI === false) {
                    setRowData(rowData);
                    navigate(`/saas/admin/inventoryManagement/materialReceiptCreationForm?mrId=${rowData?.row.Id}&inventoryId=${rowData.row.InventoryId}`)
                    handleClose();
                  }
                }}
                style={{
                  fontFamily: "Muli",
                  fontSize: "13px",
                  color: "#444",
                  width: 90,
                  display: "block",
                  textAlign: "center"
                }}
              >
                Edit
              </MenuItem>


            </Menu>
          </Grid>
        </Grid>

      </Grid>
      <ErrorModal openErrorModal={openErrorModal} setOpenErrorModal={setOpenErrorModal} BEerror={BEerror} />
    </div>
  );
}

export default MaterialReceiptTable;
