import * as constFile from "../../saasApiConst";
import { CHANGE_STATUS, RESEND_INVITATION } from "../../StatusConst";
import axios from "axios";
const FormData = require("form-data");

const INVITE = constFile.INVITE;

export const inviteTenant = (tenetData) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + INVITE}`, tenetData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const UPLOAD_CONTRACT = constFile.UPLOAD_CONTRACT;
export const uploadContract = (file) => {
  const token = localStorage.getItem("accessToken");
  const data = new FormData();
  data.append("File", file);
  return axios
    .post(`${process.env.REACT_APP_URL + UPLOAD_CONTRACT}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
//get inpt data mc/sc
const GET_DATA = constFile.GET_DATA;
export const getDataMCSC = (Id) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        Id: Id,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err });
};

const UPDATE_DOCUMENTS = constFile.UPDATE_DOCUMENTS;
export const updateDocuments = (CollaborativeTenantId,DocumentIds) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + UPDATE_DOCUMENTS}`,
      {
        CollaborativeTenantId: CollaborativeTenantId,
        Documents: DocumentIds
      }
      , {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { return err });
};

//get invite fITLER data SC
const INVITE_FILTER_DATA_SC = constFile.INVITE_FILTER_DATA_SC;
export const getInviteFilterDataSC = (accountTypeId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + INVITE_FILTER_DATA_SC}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        accountTypeId: accountTypeId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { });
};
//GET COLLABRATORS SC
const GET_COLLABRATORS_SC = constFile.GET_COLLABRATORS_SC;
export const getCollabratorsDataSC = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + GET_COLLABRATORS_SC}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
//get invite fITLER data SC
const INVITE_FILTER_DATA_MC = constFile.INVITE_FILTER_DATA_MC;
export const getInviteFilterDataMC = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + INVITE_FILTER_DATA_MC}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { });
};
//GET COLLABRATORS SC
const GET_COLLABRATORS_MC = constFile.GET_COLLABRATORS_MC;
export const getCollabratorsDataMC = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + GET_COLLABRATORS_MC}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const changeStatus = (rowId, statusId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + CHANGE_STATUS}`,
      {
        CollaborationId: rowId,
        StatusId: statusId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const resendInvitation = (rowId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + RESEND_INVITATION}`,
      {
        CollaborationId: rowId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
//GET MC ASSIGNMENT LOG
const GET_MC_ASSIGNMENT_LOG = constFile.GET_MC_ASSIGNMENT_LOG;
export const getMcAssignmentLog = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + GET_MC_ASSIGNMENT_LOG}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
//get mc ASSGNMENT fITLER data MC
const GET_MC_ASSIGNMENT_LOG_FILTER_DATA =
  constFile.GET_MC_ASSIGNMENT_LOG_FILTER_DATA;
export const getMCAssignmentLogFilterData = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_MC_ASSIGNMENT_LOG_FILTER_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { });
};
//chanhe project status
const CHANGE_PHASE_STATUS = constFile.CHANGE_PHASE_STATUS;
export const changePhaseStatus = (PhaseCollaborationId, StatusId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + CHANGE_PHASE_STATUS}`,
      { PhaseCollaborationId: PhaseCollaborationId, StatusId: StatusId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
//GET SC ASSIGNMENT LOG
const GET_SC_ASSIGNMENT_LOG = constFile.GET_SC_ASSIGNMENT_LOG;
export const getScAssignmentLog = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + GET_SC_ASSIGNMENT_LOG}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
//get SC ASSGNMENT fITLER data MC
const GET_SC_ASSIGNMENT_LOG_FILTER_DATA =
  constFile.GET_SC_ASSIGNMENT_LOG_FILTER_DATA;
export const getSCAssignmentLogFilterData = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_SC_ASSIGNMENT_LOG_FILTER_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => { });
};
//chanhe project status
const CHANGE_SUBPROJECT_STATUS = constFile.CHANGE_SUBPROJECT_STATUS;
export const changeSubProjectStatus = (
  SubProjectCollaborativeTenantDisciplineStatusId,
  StatusId
) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + CHANGE_SUBPROJECT_STATUS}`,
      {
        SubProjectCollaborativeTenantId:
          SubProjectCollaborativeTenantDisciplineStatusId,
        StatusId: StatusId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
