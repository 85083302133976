import React, { useEffect, useState, useMemo } from "react";
import { Grid, Button, Modal, Box, TextField, Select } from "@mui/material";
import { MenuItem, FormControl, FormHelperText } from "@mui/material";
import classes from "../../../Assets/Styles/factoryTables.module.css";
import { useSelector } from "react-redux";
import * as transportServices from "../../../Services/transport-services.proxy";
const getStatusesList = transportServices.getStatusesList;
const getDriverStatusesList = transportServices.getDriverStatusesList;
const changeTruckStatus = transportServices.changeTruckStatus;
const changeTrailerStatus = transportServices.changeTrailerStatus;
const changeDriverStatus = transportServices.changeDriverStatus;

function ChangeStatusModal(props) {
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    bottom: "30%",
    transform: "translate(-50%, -50%)",
    height: "30%",
    width: "32%",
    borderRadius: "15px",
    boxShadow: " 0 3px 6px 0 rgba(0, 0, 0, 0.46)",
    border: "solid 1px #0674b9",
    backgroundColor: "#fff",
  };

  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [status, setStatus] = useState(0);
  const [statuses, setStatuses] = useState([]);

  const handleClose = () => {
    setOpen(false);
    props.setOpenModal(false);
  };

  useEffect(() => {
    if (props.openModal) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [props.openModal]);
  useEffect(() => {
    if (open) {
      if (props.truckSelected || props.trailerSelected)
        getStatusesList().then((x) => {
          setStatuses(x);
        });
      else {
        getDriverStatusesList().then((x) => {
          setStatuses(x);
        });
      }
    }
  }, [open]);
  const changeStatus = () => {
    setLoading(true);
    if (props.truckSelected) {
      changeTruckStatus(props.truckId, status).then((x) => {
        handleClose();
        props.setModify(!props.modify);
      });
    } else if (props.trailerSelected) {
      changeTrailerStatus(props.trailerId, status).then((x) => {
        handleClose();
        props.setModify(!props.modify);
      });
    } else if (props.driverSelected) {
      changeDriverStatus(props.driverId, status).then((x) => {
        handleClose();
        props.setModify(!props.modify);
      });
    }
    setLoading(false);
  };
  return (
    <Modal
      disableScrollLock
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          direction="column"
          padding={5}
        >
          <Grid
            item
            sx={{
              fontFamily: "Muli",
              fontSize: "18px",
              color: "#101650",
              fontWeight: "bold",
            }}
          >
            Change
            {props.trailerSelected
              ? "Trailer "
              : props.driverSelected
              ? "Driver"
              : "Truck"}
            Status
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <Select
                MenuProps={{ disableScrollLock: true }}
                value={status}
                fullWidth
                disabled={
                  props.currentStatus === "Loaded" ||
                  props.currentStatus === "Assigned"
                    ? true
                    : false
                }
                sx={{ height: "45px" }}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
              >
                <MenuItem value={0} key={0} disabled>
                  Status
                </MenuItem>
                {statuses.map((r) => (
                  <MenuItem
                    disabled={
                      props.currentStatus === r.Name || r.Id === 2
                        ? true
                        : false
                    }
                    value={r.Id}
                    key={r.Id}
                    style={{ display: "block" }}
                  >
                    {r.Name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText sx={{ color: "#d32f2f" }}>
                {/* { props.subcontractorError
                    ? subcontractorErrorMsg
                    :props.subContractorsEmpty
                    ? "There is no manu contracts on this subproject"
                    :""} */}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item container direction="row-reverse" marginTop={2} gap={1}>
            <button
              className={classes["update-button"]}
              onClick={() => {
                changeStatus();
              }}
            >
              {loading ? "Updating" : "Update Status"}
            </button>
            <button className={classes["update-button"]} onClick={handleClose}>
              Cancel
            </button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default ChangeStatusModal;
