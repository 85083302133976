import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Factory from "../../Assets/Images/Factory/Factory.png";
import eye from "../../Assets/Images/Table/blue eye.png";
import { useSelector } from "react-redux";
import * as permissionFunctions from "../../../ReusableFunctions";

const CheckIfPermitted = permissionFunctions.CheckIfPermitted;
export default function BasicCard(props) {
  const navigate = useNavigate();
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  const [disabledView, setDisabledView] = useState(false);
  const [disabledMp, setDisabledMp] = useState(false);

  useEffect(() => {
    if (UserPermissions) {
      CheckIfPermitted(
        UserPermissions,
        "Factory Set-Up",
        "View",
        setDisabledView
      );
      CheckIfPermitted(
        UserPermissions,
        "Factory Master Parameters",
        "View",
        setDisabledMp
      );
    }
  }, [UserPermissions]);
  return (
    <Grid
      container
      sx={{
        backgroundImage: `url(${Factory})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        fontFamily: "Muli",
        padding: "20% 12% 2% 25%",
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          fontFamily: "Muli",
          fontSize: 12,
          fontWeight: "bold",
          lineHeight: 1.63,
          textAlign: "left",
          color: "#101650",
        }}
      >
        {props.data.Name.length > 14
          ? props.data.Name.slice(0, 14) + "..."
          : props.data.Name}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          fontFamily: "Muli",
          fontSize: 10,
          lineHeight: 1.63,
          textAlign: "left",
          color: "#707070",
        }}
      >
        {props.data.Code}
      </Grid>
      <Grid item xs={12} container alignItems="center" gap={3.4}>
        <Grid
          item
          sx={{
            fontFamily: "Muli",
            fontSize: 10,
            lineHeight: 1.63,
            textAlign: "left",
            color: "#707070",
          }}
        >
          No. of Bays
        </Grid>
        <Grid
          item
          sx={{
            width: 27,
            height: 14,
            borderRadius: 7,
            backgroundColor: "#f1f1f1",
            fontSize: 10,
            textAlign: "center",
          }}
        >
          {props.data.BaysNumber}
        </Grid>
      </Grid>
      <Grid item xs={12} container alignItems="center" gap={2.5}>
        <Grid
          item
          sx={{
            fontFamily: "Muli",
            fontSize: 10,
            lineHeight: 1.63,
            textAlign: "left",
            color: "#707070",
          }}
        >
          No. of Blocks
        </Grid>
        <Grid
          item
          sx={{
            width: 27,
            height: 14,
            borderRadius: 7,
            backgroundColor: "#f1f1f1",
            fontSize: 10,
            textAlign: "center",
          }}
        >
          {props.data.BlocksNumber}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        onMouseOver={(e) => {
          if (props.index !== -1) e.target.style.cursor = "pointer";
        }}
        sx={{
          fontFamily: "Muli",
          fontSize: 10,
          lineHeight: 1.63,
          fontWeight: "bold",
          textAlign: "left",
          color: "#2da4d0",
        }}
        alignItems="center"
        gap={3}
        onClick={() => {
          if (!disabledView && props.index !== -1)
            navigate(`/saas/factory/create?id=${props.data.Id}`);
        }}
      >
        <Grid item> View Factory</Grid>
        <Grid item paddingTop={0.5}>
          <img src={eye} width="80%" />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        onMouseOver={(e) => {
          if (props.index !== -1) e.target.style.cursor = "pointer";
        }}
        sx={{
          fontFamily: "Muli",
          fontSize: 9,
          fontWeight: "bold",
          fontStyle: "italic",
          lineHeight: 1.56,
          textAlign: "left",
          color: "#2da4d0",
        }}
        onClick={() => {
          if (!disabledMp && props.index !== -1) {
            navigate(`/saas/masterParameters?id=${props.data.Id}`);
            localStorage.setItem("FactoryName", props.data.Name);
          }
        }}
      >
        Master Parameters
      </Grid>
      {localStorage.getItem("isSubscribedManu") ? (
        <Grid
          item
          xs={12}
          container
          onMouseOver={(e) => {
            if (props.index !== -1) e.target.style.cursor = "pointer";
          }}
          onClick={() => {
            if (props.index !== -1)
              navigate(`/saas/factory/bay?id=${props.data.Id}`);
            localStorage.setItem("FactoryName", props.data.Name);
          }}
          gap={7.5}
          sx={{
            fontFamily: "Muli",
            fontSize: 9,
            fontWeight: "bold",
            lineHeight: 1.56,
            textAlign: "left",
            color: "#2da4d0",
          }}
        >
          <Grid item sx={{ fontStyle: "italic" }}>
            Blocks
          </Grid>
          <Grid item>+</Grid>
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
}
