import React, { useEffect, useState, useMemo } from "react";
import { Grid, Button, Modal, Box, TextField } from "@mui/material";
import classes from "../../../Assets/Styles/Sales-Order.module.css";
import { useLocation, useNavigate } from "react-router-dom";

function HoldOrderModal(props) {

    const navigate = useNavigate();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    bottom: "30%",
    transform: "translate(-50%, -50%)",
    height: "20%",
    width: "32%",
    borderRadius: "15px",
    boxShadow: " 0 3px 6px 0 rgba(0, 0, 0, 0.46)",
    border: "solid 1px #0674b9",
    backgroundColor: "#fff",
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    props.setOpenModal(true);
  };

  const handleClose = () => {
    props.setOpenModal(false);
    setOpen(false);
    
    
  };

  useEffect(() => {
    if (props.openModal) {
      handleOpen(true);
    }
    if(!props.openModal)
    handleClose(false);
  }, [props.openModal]);





  


  return (
    <Modal
      disableScrollLock
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid
          container
          padding={3}
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            sx={{
              fontFamily: "Muli",
              fontSize: "18px",
              color: "#101650",
              fontWeight: "bold",
              alignContent: "center",
            }}
          >
            Are you sure you want to put this sales order on hold?
          </Grid>
          <Grid
              item
              container
              direction="row-reverse"
              spacing={2}
              paddingTop={4}
              alignItems="center"
            >
              <Grid item>
                <button
                disabled={props.loadingHold}
                  className={classes["save-button"]}
                  onClick = {()=>props.holdingSO()}
               
                >
                  {props.loadingHold ? "Confirming" : "Confirm"}
                </button>
              </Grid>
              <Grid item>
                <button
                  className={classes["back-button"]}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cancel
                </button>
              </Grid>

          
            </Grid>
       
        </Grid>
      </Box>
    </Modal>
  );
}

export default HoldOrderModal;
