import React, { useEffect, useState, useMemo } from "react";
import { Grid } from "@mui/material";
import bgTm from "../../Assets/Images/TeamMember/bgTeamMember.png";
import Logo from "../../Assets/Images/Navbar/logo1.png";
import check from "../../SAAS/Assets/Images/Settings/checkActive.png";
import Form1 from "./Form1";
import Form2 from "./Form2";
import VerifyCode from "./VerifyCode";
import { useNavigate, useLocation } from "react-router-dom";
import jwt from "jwt-decode";
import * as inputService from "../../Services/user-service.proxy";
import * as teamMemberService from "../../SAAS/Services/saas-services.proxy";
const getInputData = teamMemberService.teamMemberRegisterData;
const teamMemberRegister = teamMemberService.teamMemberRegister;
const styles = {
  backgroundContainer: {
    backgroundImage: `url(${bgTm})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-Repeat",
    backgroundSize: "cover",
    minHeight: "100vh",
  },

  ProfileText: {
    fontFamily: "Muli",
    fontSize: "36px",
    fontWeight: "bold",
    color: "#181818",
  },
  YouGetText: {
    fontFamily: "Muli",
    color: "#181818",
    fontSize: "15px",
  },
  YouGetTextTitle: {
    fontFamily: "Muli",
    color: "rgba(16, 22, 80, 0.5)",
    fontSize: "18px",
  },
  Pro: {
    fontFamily: "Muli",
    color: "#101650",
    fontSize: "13px",
    fontWeight: "bold",
  },
  InvitedMemberRegistration: {
    fontFamily: "Muli",
    fonSize: "19px",
    textAlign: "left",
    color: "#e1e319",
  },
};
const TeamMemberRegistration = (props) => {
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(true);
    props.setTerms(false);
  }, [props.setNaved]);
  const query = useQuery();
  const [accessToken, setAccessToken] = useState(query.get("token"));
  const [userId, setUserId] = useState(null);

  const [step, setStep] = useState(1);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [fileId, setFileId] = useState(null);
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [agreeCheck, setAgreeCheck] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);

  const [isVerified, setIsVerified] = useState(
    localStorage.getItem("isVerified")
  );

  const [personalPhoneNumber, setPersonalPhoneNumber] = useState("");
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    if (accessToken) {
      setEmail(jwt(accessToken).Email);
      setUserId(jwt(accessToken).UserId);
      getInputData(accessToken).then((x) => {
        if (x.status === 404) {
          setInvalidToken(true);
        } else {
          setCountries(x.data);
        }
      });
    }
  }, [accessToken]);
  const continues = (e) => {
    e.preventDefault();
    setStep(step + 1);
  };
  const back = (e) => {
    e.preventDefault();
    setStep(step - 1);
  };
  useEffect(() => {
    if (isVerified === "false") {
      setStep(3);
    }
  }, [isVerified]);
  const register = () => {
    teamMemberRegister(
      name,
      email,
      password,
      fileId,
      country,
      address,
      personalPhoneNumber,
      agreeCheck,
      accessToken
    ).then((x) => {
      localStorage.setItem("accessToken", x.accessToken);
      localStorage.setItem("isVerified", "false");
      localStorage.setItem("companyName", name);
      localStorage.setItem("isTeamMember", "true");
      //localStorage.setItem("companyPlan", x.plan);
      setTimeout(() => {
        setStep(3);
      }, 2000);
    });
  };
  return (
    <div style={styles.backgroundContainer}>
      <Grid
        className="mainR"
        container
        rowGap={4}
        paddingLeft={{ xs: 1 }}
        paddingTop={2}
      >
        <Grid item container rowSpacing={{ xs: 2 }} justifyContent={"center"}>
          <Grid item xs={12} paddingLeft={{ md: 15 }}>
            <a href="/">
              <img src={Logo} alt="logo" width={"200px"} />
            </a>
          </Grid>
          <Grid
            item
            md={12}
            lg={4}
            container
            direction={"column"}
            spacing={1}
            paddingLeft={{ xs: 10, md: 15 }}
          >
            <Grid item sx={styles.InvitedMemberRegistration}>
              Invited Member Registration
            </Grid>
            <Grid item sx={styles.ProfileText}>
              Team Member
            </Grid>

            <Grid
              item
              sx={styles.YouGetText}
              container
              spacing={{ md: 3, xs: 1 }}
            >
              <Grid item container spacing={1}>
                <Grid item>
                  <img src={check} alt="check"></img>
                </Grid>

                <Grid item md={7.5} sm={7.5} xs={7.5}>
                  Pilecubes is what contractors are using to work seamlessly and
                  accomplish more, together.
                </Grid>
              </Grid>
              {invalidToken ? (
                <Grid
                  item
                  xs={12}
                  fontFamily={"Muli"}
                  fontSize={15}
                  fontWeight={"bold"}
                  color={"red"}
                >
                  The link has expired.
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          <Grid
            item
            md={12}
            lg={5}
            marginLeft={{ xs: 2 }}
            paddingBottom={{ xs: 12 }}
          >
            {step === 1 ? (
              <Form1
                continues={continues}
                name={name}
                setName={setName}
                email={email}
                setEmail={setEmail}
                setPassword={setPassword}
                setConfirmPassword={setConfirmPassword}
                fileId={fileId}
                setFileId={setFileId}
                password={password}
                confirmPassword={confirmPassword}
                userId={userId}
              />
            ) : step === 2 ? (
              <Form2
                back={back}
                country={country}
                setCountry={setCountry}
                address={address}
                setAddress={setAddress}
                personalPhoneNumber={personalPhoneNumber}
                setPersonalPhoneNumber={setPersonalPhoneNumber}
                agreeCheck={agreeCheck}
                setAgreeCheck={setAgreeCheck}
                register={register}
                countries={countries}
              />
            ) : step === 3 ? (
              <VerifyCode setToken={props.setToken} />
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default TeamMemberRegistration;
