import React, { useState } from "react";
import { Grid, TextField } from "@mui/material";
import classes from "./TeamMember.module.css";
import SearchIcon from "@mui/icons-material/Search";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/system";

const TeamMemberList = (props) => {
  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    props.setSearchText(lowerCase);
  };
  const [expanded, setExpanded] = useState(false);

  const handleChange = (index) => (event, isExpanded) => {
    setExpanded(isExpanded ? index : false);
  };
  return (
    <Grid container>
      <Grid item className={classes["all-team"]}>
        ALL TEAM MEMBERS PER ROLE
      </Grid>
      <Grid item>
        <TextField
          size="small"
          fullWidth
          value={props.searchText}
          onChange={inputHandler}
          placeholder="Search this list…"
          InputProps={{
            startAdornment: (
              <SearchIcon
                fontSize="small"
                htmlColor={"#707070"}
                style={{ paddingLeft: 5 }}
              />
            ),
          }}
          sx={{
            fontFamily: "Muli",
            backgroundColor: "white",
            borderRadius: 1,

            width: {
              xs: 1,
              sm: "auto",
            },
            m: (theme) => theme.spacing(1, 0.5, 1.5),
            "& .MuiSvgIcon-root": {
              mr: 0.5,
            },
            "& .MuiInput-underline:before": {
              borderBottom: 0,

              borderColor: "white",
            },
          }}
        />
      </Grid>
      <Grid item container className={classes["BoxRoles"]} overflow={"scroll"}>
        {props.teamMemberRoles?.map((role, index) => (
          <Accordion
            expanded={expanded === role.Id}
            onChange={handleChange(role.Id)}
            sx={styles.ProductFeatureAccordion}
            style={{ boxShadow: "0px 0px 0px" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography
                style={{ fontFamily: "Muli", fontSize: 18, color: "#101650" }}
              >
                {role.Name}
              </Typography>
            </AccordionSummary>

            <AccordionDetails style={{ backgroundColor: "#fcfafa" }}>
              <Grid container gap={2}>
                {role.TeamMembers?.map((teamMember, index) => (
                  <BlueTip title={"Assign"} placement="right-start" interactive>
                    <Grid
                      item
                      xs={8}
                      sx={styles.ProductName}
                      bgcolor={"white"}
                      padding={1}
                      borderRadius={2}
                      container
                      direction={"row"}
                      onClick={() => {
                        if (props.discipline) {
                          if (
                            props.UserRole !== "Document Controller" &&
                            props.UserRole !== "Engineer"
                          ) {
                            if (
                              (props.UserRole === "Project Manager" &&
                                teamMember.UserGroupPermissions === "Admin") ||
                              (props.UserRole === "Engineering Manager" &&
                                (teamMember.UserGroupPermissions === "Admin" ||
                                  teamMember.UserGroupPermissions ===
                                  "Project Manager")) ||
                              (props.UserRole === "Team Leader" &&
                                (teamMember.UserGroupPermissions === "Admin" ||
                                  teamMember.UserGroupPermissions ===
                                  "Project Manager" ||
                                  teamMember.UserGroupPermissions ===
                                  "Engineering Manager"))
                            ) {
                            } else {
                              if (
                                !props.teamMembers.find(
                                  (t) => t.Id === teamMember.Id
                                )
                              ) {
                                let temp = teamMember;
                                let arr = props.teamMembers;
                                arr.push(temp);
                                props.setTeamMembers(arr);
                                props.forceUpdate();
                              }
                            }
                          }
                        }
                        else {
                          props.setChooseDiscError(true)
                          window.scrollTo(0,0);

                        }

                      }}
                    >
                      <Grid item xs={6}>
                        {teamMember.Name}
                      </Grid>
                      <Grid item xs={6} textAlign={"right"}>
                        +
                      </Grid>
                    </Grid>
                  </BlueTip>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </Grid>
  );
};

export default TeamMemberList;
const BlueTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
      color:#0674b9;
      background-color: #fcfafa;
      font-size: 13px;
      width:50px;
   
  `);
const styles = {
  ProductFeatureGroup: {
    fontFamily: "Muli",
    fontSize: 16,
    fontWeight: "bold",
    color: "rgba(16, 22, 80, 0.7)",
    backgroundColor: "rgba(206, 210, 225, 0.2)",
    border: "solid 1px rgba(206, 210, 225, 0.5)",
    padding: "2% 0 2% 2%",
  },
  ProductFeatureAccordion: {
    color: "rgba(16, 22, 80, 0.7)",
    flexShrink: 0,
    backgroundColor: "#fcfafa",
    border: 0,

    width: "100%",
  },
  ProductName: {
    fontFamily: "Muli",
    fontSize: 14,
    fontWeight: "bold",
    color: "#0674b9",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#0674b9",
      cursor: "pointer",
    },
  },
  DescriptionText: {
    fontFamily: "Muli",
    fontSize: 15,
    color: "rgba(16, 22, 80, 0.7)",
  },
};
