import React, { useState, useEffect } from "react";
import classes from "./McModal.module.css";
import { Grid, Button, Tooltip, Modal, Box, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as tenantServices from "../../../Services/tenant-services.proxy";

import fileIcon from "../../../Assets/Images/Table/file.png";
import fileIconSub from "../../../Assets/Images/Table/FileSub.png";

import infoTip from "../../../../Assets/Images/form/info.png";

const inviteTenant = tenantServices.inviteTenant;
const uploadContract = tenantServices.uploadContract;
const getInviteFilterDataSC = tenantServices.getInviteFilterDataSC;
const updateDocuments = tenantServices.updateDocuments;
const getDataMCSC = tenantServices.getDataMCSC;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "30%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  height: "60%",
  width: "58%",
  overflow: "scroll",
};

const BlueTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`

width:140px;
        color: #101650;
        background-color: rgba(6, 116, 185, 0.3);
        font-size: 13px;
     
    `);
const McModal = (props) => {
  const AccountType = localStorage.getItem("AccountType");

  const [open, setOpen] = React.useState(false);

  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  const [name, setName] = useState(null);
  const [nameError, setNameError] = useState(false);

  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [invalidMsg, setInvalidMsg] = useState("");
  const [status, setStatus] = useState(null);
  const [creator, setCreator] = useState(null);
  const [createdDate, setCreatedDate] = useState(null);
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [fileId, setFileId] = useState(null);
  const [fileIds] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [disable, setDisable] = useState(false);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const openTip = () => setTooltipIsOpen(true);
  const closeTip = () => setTooltipIsOpen(false);

  const handleOpen = () => {
    setOpen(true);
    props.setOpenModal(false);
  };
  useEffect(() => {
    if (props.openModal) {
      handleOpen(true);
    }
  }, [props.openModal]);
  const handleClose = () => {
    setName("");
    setDisable(false);
    setNameError(false);
    setEmail("");
    setStatus("")
    setCreator("");
    setCreatedDate("");
    setEmailError(false);
    setFiles([]);
    setOpen(false);
    props.setContractorId(null);
  };
  useEffect(() => {
    if (!name || !email || nameError || emailError) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });

  useEffect(() => {
    // side effects
    let apiTest = true;
    if (props.contractorId)
      getDataMCSC(props.contractorId).then((x) => {
        if (apiTest) {
          setDisable(true);
          setName(x.InvitedCompanyName);
          setEmail(x.InviteeEmail);
          setStatus(x.Status);
          setCreator(x.Creator);
          setCreatedDate(x.CreationDate);
          setFiles(x.Documents);
          if (x.Documents) {
            x.Documents.map(file => {
              let temp = {
                FileId: file.FileId
              }
              fileIds.push(temp);
              temp = [];
            })
          }
        }
      });
    // cleanup
    return () => {
      apiTest = false;
    };
  }, [props.contractorId]);

  useEffect(() => {
    // side effects
    let apiTest = true;
    getInviteFilterDataSC(1);
    // cleanup
    return () => {
      apiTest = false;
    };
  }, []);

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      uploadContract(e.target.files[0]).then((x) => {
        setFile(x);
        setFileId(x.FileId);
      });
    }
  };

  useEffect(() => {
    if (file) {
      let tempFile = null;
      tempFile = file;
      files.push(tempFile);
    }
  }, [file]);

  useEffect(() => {
    if (fileId) {
      let tempFileId = null;
      tempFileId = fileId;
      let fileData = { FileId: tempFileId };
      fileIds.push(fileData);
    }
  }, [fileId]);

  const data = {
    InviteeEmail: email,
    InvitedCompanyName: name,
    AccountTypeId: 1,
    Files: fileIds,
  };

  const [loading, setLoading] = useState(false);

  const Invite = () => {
    if (props.contractorId) {
      updateDocuments(props.contractorId, fileIds).then((x) => {
        setLoading(false);
        props.setModify(!props.modify);
        handleClose();
      })
    }
    else {
      inviteTenant(data).then((x) => {
        if (x.response?.data.status) {
          setEmailError(true);
          setEmailInvalid(true);
          setInvalidMsg(x.response?.data.error)
          setLoading(false);
        } else {
          setLoading(false);
          props.setModify(!props.modify);
          props.setRowData(null);
          handleClose();
        }
      });
    }
  };

  const deleteItem = (item) => {
    const index = files.indexOf(item);
    if (index > -1) {
      files.splice(index, 1);
      fileIds.splice(index, 1); // 2nd parameter means remove one item only
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container direction={"column"}>
          <Grid
            item
            alignSelf={"flex-end"}
            paddingRight="20.5%"
            paddingTop="99px"
          >
            <CloseIcon
              fontSize="large"
              onClick={handleClose}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              style={{ color: "#fff" }}
            />
          </Grid>
          <Grid item>
            <Box sx={style}>
              <Grid container alignItems="center" direction="column" gap={1}>
                <Grid item className={classes.newMC} paddingTop={1}>
                  New Main Contractor
                </Grid>

                <Grid
                  item
                  className={classes["Line-402"]}
                  alignSelf={"center"}
                  justifySelf={"center"}
                  width="90%"
                ></Grid>
                <Grid item container paddingLeft={"5%"} gap={1}>
                  <Grid
                    item
                    className={classes["MC-Information"]}
                    alignSelf="flex-start"
                    xs={12}
                  >
                    Main Contractor Guest
                  </Grid>
                  {AccountType === "1" ? (
                    <Grid
                      item
                      className={classes["Line-403-Main"]}
                      width="176px"
                    />
                  ) : (
                    <Grid
                      item
                      className={classes["Line-403-Sub"]}
                      width="176px"
                    />
                  )}

                  <Grid item container direction={"row"} paddingTop={1}>
                    <Grid item container direction={"column"} md={6} xs={12}>
                      <Grid item>
                        <span className={classes["field-label"]}>*Name</span>
                      </Grid>
                      <Grid item>
                        <TextField
                          disabled={disable}
                          className={classes["Field-input"]}
                          value={name}
                          error={nameError}
                          helperText={nameError ? "Enter name" : ""}
                          id="outlined-name"
                          size="small"
                          InputProps={{ style: { height: "30px" } }}
                          inputProps={{ maxLength: 150 }}
                          onBlur={(e) => {
                            if (!e.target.value) setNameError(true);
                          }}
                          onChange={(e) => {
                            setNameError(false);
                            setName(e.target.value);
                          }}
                        />
                      </Grid>

                      <Grid item container direction={"column"}>
                        <Grid item>
                          <span className={classes["field-label"]}>*Email</span>
                        </Grid>
                        <Grid item>
                          <TextField
                            disabled={disable}
                            error={emailError}
                            className={classes["Field-input"]}
                            value={email}
                            id="outlined-name"
                            size="small"
                            helperText={
                              emailError
                                ? emailInvalid
                                  ? invalidMsg ? invalidMsg
                                    : "Enter a valid email address"
                                  : "Enter an email address"
                                : ""
                            }
                            InputProps={{ style: { height: "30px" } }}
                            inputProps={{ maxLength: 64 }}
                            onBlur={(e) => {
                              if (!e.target.value) setEmailError(true);
                            }}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              if (
                                !e.target.value ||
                                !e.target.value.match(
                                  /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/
                                )
                              ) {
                                setEmailError(true);
                                setEmailInvalid(true);
                              } else {
                                setEmailError(false);
                                setEmailInvalid(false);
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item container direction={"column"} md={6} xs={12}>
                      <Grid item>
                        <span className={classes["field-label2"]}>Status</span>
                      </Grid>
                      <Grid item>
                        <TextField
                          value={status}
                          disabled
                          className={classes["Field-input"]}
                          id="outlined-name"
                          size="small"
                          InputProps={{ style: { height: "30px" } }}
                        />
                      </Grid>
                      <Grid item container direction={"row"}>
                        <Grid item container direction={"column"} xs={6}>
                          <Grid item>
                            <span className={classes["field-label2"]}>
                              Creator
                            </span>
                          </Grid>
                          <Grid item>
                            <TextField
                              value={creator}
                              disabled
                              className={classes["Field-input2"]}
                              id="outlined-name"
                              size="small"
                              InputProps={{ style: { height: "30px" } }}
                            />
                          </Grid>
                        </Grid>

                        <Grid item container direction={"column"} xs={6}>
                          <Grid item>
                            <span className={classes["field-label2"]}>
                              Created Date
                            </span>
                          </Grid>
                          <Grid item>
                            <TextField
                              value={createdDate?.slice(0, 10)}
                              disabled
                              className={classes["Field-input2"]}
                              id="outlined-name"
                              size="small"
                              InputProps={{ style: { height: "30px" } }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item container direction={"row"}>
                        <Grid item paddingBottom={1} xs={4.8}>
                          <Button
                            component="label"
                            InputProps={{ style: { height: "30px" } }}
                            style={{
                              // width: file ? "100%" : "50%",
                              width: "100%",
                              marginTop: "23px",
                              textTransform: "none",
                              fontFamily: "Muli",
                              fontSize: "12px",
                              lineHeight: 1.45,
                              textAlign: "left",
                              color: "#101650",
                              backgroundColor: "white",
                              borderRadius: "6px",
                              border: "solid 1px #b8b8b8",
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              height: "30px",
                            }}
                          >
                            <FileUploadOutlinedIcon
                              htmlColor="#529bd7"
                              style={{
                                alignSelf: "center",
                                paddingRight: "5%",
                              }}
                            />
                            <span style={{ alignSelf: "center" }}>
                              Upload File
                            </span>
                            <input
                              type="file"
                              hidden
                              onChange={handleFileChange}
                            />
                          </Button>
                        </Grid>

                        <Grid item marginRight={1} xs={1} paddingTop={3}>
                          <IconButton
                            edge="end"
                            onClick={openTip}
                            onMouseOver={openTip}
                            onMouseLeave={closeTip}
                          >
                            <BlueTip
                              open={tooltipIsOpen}
                              title={
                                files?.length > 0
                                  ? "Click on file to download"
                                  : "you can upload any document related to the contractor or a contract."
                              }
                              placement="right"
                            >
                              <img
                                width={"15px"}
                                src={infoTip}
                                alt={"close"}
                              ></img>
                            </BlueTip>
                          </IconButton>
                        </Grid>
                      </Grid>
                      {files?.length >= 0 ? (
                        files.map((item) => (
                          <Grid
                            container
                            direction={"row"}
                            key={item.FileId}
                            value={item}
                          >
                            <Grid item xs={1} paddingLeft={1}>
                              <img
                                src={
                                  AccountType === "1" ? fileIcon : fileIconSub
                                }
                                alt="file"
                                width={15}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={2.5}
                              fontSize={{ xs: 10, md: 14 }}
                              onMouseOver={(e) => {
                                e.target.style.color = "grey";
                                e.target.style.cursor = "pointer";
                              }}
                              onMouseLeave={(e) => {
                                e.target.style.color = "black";
                              }}
                              onClick={() => {
                                window.open(file.URL);
                              }}
                            >
                              <span>{item.DisplayName.slice(0, 11)}</span>
                            </Grid>
                            <Grid
                              item
                              paddingLeft={2}
                              onMouseOver={(e) => {
                                e.target.style.cursor = "pointer";
                              }}
                              onClick={() => {
                                deleteItem(item);
                                setFile(null);
                                setFileId(null);
                                forceUpdate();
                              }}
                            >
                              <Grid item xs={1}>
                                <CloseIcon fontSize="small" />
                              </Grid>
                            </Grid>
                          </Grid>
                        ))
                      ) : (
                        <p>hello</p>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent={"flex-end"}
                  direction="row"
                  alignItems={"flex-end"}
                  paddingRight={5}
                  paddingBottom={1}
                  paddingTop={2}
                >
                  <Grid item paddingRight={2}>
                    <button
                      className={classes.exitModal}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                        e.target.style.backgroundColor = "#f5f5f5";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "#fff";
                      }}
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </button>
                  </Grid>
                  <Grid item>
                    <button
                      disabled={isDisabled}
                      className={classes.SaveModal}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                        e.target.style.backgroundColor = "#242B64";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "#0b5cab";
                      }}
                      style={{
                        backgroundColor: isDisabled ? "#bcbcbc" : "#0b5cab",
                        fontFamily: "Muli",
                        textTransform: "none",
                        opacity: loading ? 0.7 : 1,
                      }}
                      onClick={() => {
                        setLoading(true);
                        Invite();
                      }}
                    >
                      {loading ? "Loading..." : props.contractorId ? "Update" : "Invite"}
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

export default McModal;
