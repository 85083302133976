import axios from "axios";

// const GET_TOKEN = '/forge-api/token';

// const STATUS = '/forge-api/translation-status';

// export const getToken = () => {
//     return axios
//         .get(`${process.env.REACT_APP_URL + GET_TOKEN}`)
//         .then((response) => {
//             return response.data;
//         })
//         .catch((err) => { return (err) });
// };

const GET_URL = "/forge-api/obtain-signed-url";
export const getSignedUrl = (fileName) => {
  return axios
    .get(`${process.env.REACT_APP_URL + GET_URL}`, {
      params: {
        fileName: fileName,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const FINALIZE = "/forge-api/finalize-upload-and-translate";
export const finalizeUpload = (fileName, uploadKey) => {
  return axios
    .post(`${process.env.REACT_APP_URL + FINALIZE}`, {
      FileName: fileName,
      UploadKey: uploadKey,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

//const FormData = require("form-data");
export const binaryFile = (signedUrl, file, setProgress) => {
  // const data = new FormData();
  // data.append("File", file);
  const onUploadProgress = (event) => {
    const percentCompleted = Math.round((event.loaded * 100) / event.total);

    setProgress(percentCompleted);
  };
  return axios
    .put(`${signedUrl}`, file, {
      headers: {
        "Content-Type": "application/octet-stream",
      },
      onUploadProgress,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

// export const getTranslationStatus = (token, fileName, fileUrn) => {
//     return axios
//         .get(`${process.env.REACT_APP_URL + STATUS}`, {
//             params: {
//                 AccessToken: token,
//                 FileName: fileName,
//                 FileUrn: fileUrn
//             },
//         })
//         .then((response) => {
//             return response.data;
//         })
//         .catch((err) => { return (err) });
// };
const TRANSLATION_STATUS = "/forge-api/translation-status";
export const getTranslationStatus = (FileUrn, FileName) => {
  return axios
    .get(`${process.env.REACT_APP_URL + TRANSLATION_STATUS}`, {
      params: {
        FileName: FileName,
        FileUrn: FileUrn,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
