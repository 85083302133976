import React, { useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import eye from "../../Assets/Images/Table/blue eye.png";

export default function BasicCard(props) {
  const navigate = useNavigate();

  return (
    <Grid
      container
      sx={{
        boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
        width: 168,
        minheight: 156,
        opacity: 1,
      }}
    >
      <Grid
        item
        container
        // height={35}
        xs={12}
        sx={{
          backgroundColor: "#e1e319",
          height: 24,
          borderTopRightRadius: 5,
          borderTopLeftRadius: 5,
          position: "relative",
        }}
        direction="row-reverse"
      >
        <Grid
          item
          backgroundColor="#fff"
          marginRight={3}
          top={-7}
          height={35}
          padding="5px 5px 0 5px"
          position={"absolute"}
          borderLeft={" solid 2px #e1e319"}
          borderRight={" solid 2px #e1e319"}
          style={{
            fontFamily: "Muli",
            fontWeight: "bold",
            color: "#0674b9",
            fontSize: "7px",
          }}
        >
          New Orders
          <div style={{ padding: "3px 0 0 4px" }}>
            <Grid
              item
              sx={{
                width: 27,
                height: 14,
                borderRadius: 7,
                backgroundColor: "#f1f1f1",
                fontSize: 10,
                textAlign: "center",
              }}
            >
              {props.data.Id == -1 ? "0" : props.data.DraftSalesOrderCount}
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Grid
        item
        xs={1}
        sx={{
          minHeight: 160,
          borderRight: " 1px solid #e1e319",
          backgroundColor: "#fff",
          borderBottomLeftRadius: 5,
        }}
      ></Grid>
      <Grid item xs={11}>
        <Card
          sx={{
            boxShadow: 0,
            minHeight: 160,
          }}
        >
          <CardContent>
            <Typography
              fontFamily="Muli"
              fontSize={14}
              lineHeight={1.1}
              fontWeight="bold"
              textAlign="left"
              color="#101650"
              gutterBottom
              height={30}
            >
              {props.data.Name.length > 29
                ? props.data.Name.slice(0, 29) + "..."
                : props.data.Name}
            </Typography>
            <div
              style={{
                fontFamily: "Muli",
                textAlign: "left",
                color: "#707070",
                paddingTop: 5,
              }}
            >
              <Typography fontSize={11} paddingBottom={0.5}>
                {props.data.ClientName}
              </Typography>
              <Grid
                container
                justifyContent="space-between"
                paddingBottom={0.5}
              >
                <Grid item fontSize={11}>
                  Sales Orders
                </Grid>
                <Grid
                  item
                  sx={{
                    width: 27,
                    height: 14,
                    borderRadius: 7,
                    backgroundColor: "#f1f1f1",
                    fontSize: 12,
                    textAlign: "center",
                  }}
                >
                  {props.data.SalesOrders}
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                paddingBottom={0.5}
                onClick={() => {
                  if (props.data.SalesOrders !== 0) {
                    navigate(`/saas/salesOrderListCard?id=${props.data.Id}`);
                    localStorage.setItem("ProjectName", props.data.Name);
                  }
                }}
                onMouseOver={(e) => {
                  if (props.data.SalesOrders === 0)
                    e.target.style.cursor = "unset";
                  else {
                    e.target.style.cursor = "pointer";
                    e.target.style.fontWeight = "bold";
                  }
                }}
                onMouseOut={(e) => {
                  e.target.style.fontWeight = "normal";
                }}
              >
                <Grid
                  item
                  fontSize={11}
                  sx={{
                    color: "#0674b9",
                  }}
                >
                  View Sales Orders
                </Grid>
                <Grid
                  item
                  sx={{
                    width: 27,
                    height: 14,
                    textAlign: "center",
                  }}
                >
                  <img src={eye} />
                </Grid>
              </Grid>
              {localStorage.getItem("AccountType") === "1" ? (
                <Grid
                  container
                  justifyContent="space-between"
                  onClick={() => {
                    if (props.data.Id !== -1)
                      navigate(`/saas/salesOrderDashboard?id=${props.data.Id}`);
                  }}
                  onMouseOver={(e) => {
                    if (props.data.Id !== -1) {
                      e.target.style.cursor = "pointer"
                    e.target.style.fontWeight = "bold"
                    }
                    else{
                      e.target.style.cursor = "unset";
               

                    } 
                  }}
                  onMouseOut={(e) => {
                  e.target.style.fontWeight = "normal";
                }}
                >
                  <Grid
                    item
                    fontSize={11}
                    sx={{
                      color: "#0674b9",
                    }}
                  >
                    View Dashboard
                  </Grid>
                  <Grid
                    item
                    sx={{
                      width: 27,
                      height: 14,
                      textAlign: "center",
                    }}
                  >
                    <img src={eye} />
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
            </div>
          </CardContent>
          {localStorage.getItem("AccountType") === "1" ||
          (localStorage.getItem("AccountType") === "2" &&
            props.data.IsCreator === true) ? (
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              padding="1% 10% 3% 5%"
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
                e.target.style.fontWeight = "bold";
              }}
              onClick={() => {
                navigate(
                  `/saas/salesOrderForm?projectId=${props.data.Id}&salesOrderNb=${props.data.SalesOrders}`
                );
              }}
              onMouseOut={(e) => {
                e.target.style.fontWeight = "normal";
              }}
            >
              <Grid
                item
                sx={{
                  fontFamily: "Muli",
                  fontSize: 11,
                  fontStyle: "italic",
                  lineHeight: 1.45,
                  textAlign: "left",
                  color: "#5fba47",
                }}
              >
                Create an Order
              </Grid>
              <Grid
                item
                sx={{
                  fontFamily: "Muli",
                  fontSize: 14,
                  fontWeight: "bold",
                  lineHeight: 1.43,
                  textAlign: "left",
                  color: "#5fba47",
                }}
              >
                +
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </Card>
      </Grid>
    </Grid>
  );
}
