export const RAWMAMTERIAL_TYPE = 1;
export const REMNANTS_TYPE = 2;
export const PRODUCTS_TYPE = 3;

export const BARS_TYPE_RM = 1;
export const COILS_TYPE_RM = 2;
export const DECOILED_TYPE_RM = 3;

export const BARS_TYPE_RN = 4;
export const COILS_TYPE_RN = 5;
export const DECOILED_TYPE_RN = 6;

export const SLINGS_TYPE = 9;
export const COUPLER_TYPE = 7;
export const ENDANCHOR_TYPE = 8;
export const THREAD_TYPE = 10;
export const HALF_THREAD_TYPE = 11;
//inventory
export const GET_INVENTORY_INPUT_DATA = "/inventories/input-data";
export const CREATE_INVENTORY = "/inventories/create";
export const UPDATE_INVENTORY = "/inventories/update";
export const GET_INVETORY_LIST_CARD = "/inventories/list";
export const GET_INVENTORY_DIMENSION_INPUT_DATA =
  "/inventories/dimension-input-data";
export const CREATE_INVENTORY_DIMENSION = "/inventories/save-dimension";
export const GET_INVENTORY_REFERENCES_INPUT_DATA =
  "/inventories/reference-input-data";
export const CREATE_INVENTORY_REFERENCES = "/inventories/create-reference";
export const UPDATE_INVENTORY_REFERENCES = "/inventories/update-reference";
export const GET_INVENTORY_VALUE_INPUT_DATA =
  "/inventories/type-value-input-data";
export const CREATE_INVENTORY_VALUE = "/inventories/create-item-type-value";
export const UPDATE_INVENTORY_VALUE = "/inventories/update-item-type-value";
export const GET_INVENTORY_REFERENCE_DIA_INPUT_DATA =
  "/inventories/reference-value-input-data";
export const CREATE_INVENTORY_REFERENCE_DIA =
  "/inventories/save-item-reference-diameter";
  
export const GET_REMENANT_STOCK_INPUT_DATA =
  "/inventories/remnant-stock-input-data";
export const CREATE_REMENANT_STOCK = "/inventories/create-remnant-stock";
export const UPDATE_REMENANT_STOCK = "/inventories/update-remnant-stock";
export const GET_CUSTOMIZED_CODE_INPUT_DATA =
  "/inventories/get-customized-code-description";
export const CREATE_CUSTOMIZED_CODE = "/inventories/customize-code-description";

export const GET_REMENANT_STOCK_LABEL_LIST = "/inventories/remnant-stock-label-list";

export const PRINT_RE_TAGS = "/label/print-remnant-stock";
