import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import SaasBack from "../../../Assets/Images/SaaSbackground.png";
import { Grid } from "@mui/material";
import classes from "../../../Assets/Styles/factoryTables.module.css";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { TextField } from "@mui/material";
import { styled } from "@mui/system";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuPic from "../../../Assets/Images/Table/menuPic.png";
import { Stack } from "@mui/material";
import * as factoryServices from "../../../Services/factories-services.proxy";

const getBaysPerFactory = factoryServices.getBaysList;

function BayTableView(props) {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  const navigate = useNavigate();

  const [searchText, setSearchText] = useState(null);
  const [baysTableData, setBaysTableData] = useState([]);
  const [dataLength, setdataLength] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);

  const location = useLocation();
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();

  const [factoryId, setFactoryId] = useState(query.get("id"));

  const [loadingData, setLoadingData] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowData, setRowData] = useState(null);

  const [bayNb, setBayNb] = useState(1);

  useEffect(() => {
    let apiTest = true;
    setLoadingData(true);
    getBaysPerFactory(listData).then((x) => {
      if (apiTest) {
        setBaysTableData(x.Bays);
        setdataLength(x.Total);
        setLoadingData(false);
      }
    });

    return () => {
      apiTest = false;
    };
  }, [pageSize, pageNumber, searchText]);

  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);

  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };

  const listData = {
    Pagination: {
      PageSize: pageSize,
      PageNumber: pageNumber,
    },
    Search: searchText,
    SortList: [],
    FactoryId: factoryId,
  };

  const handleClick = async (event, params) => {
    setRowData(params);

    setAnchorEl(event.target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    { field: "Id", hide: true, hideable: false },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      position: "sticky",
      renderCell: (params) => {
        return (
          <img
            src={MenuPic}
            alt="menu"
            onClick={(e) => handleClick(e, params)}
          ></img>
        );
      },
    },

    {
      field: "Bay Number",
      width: 342,
      renderCell: (index) => index.api.getRowIndex(index.row.Id) + 1,
    },
    {
      field: "StorageBlocksNumber",
      headerName: "Number of Storage Blocks",
      width: 342,
    },
    {
      field: "MachineBlocksNumber",
      headerName: "Number of Machine Blocks",
      width: 342,
    },
    {
      field: "HandlingBlocksNumber",
      headerName: "Number of Handling Blocks",
      width: 342,
    },
  ];

  const blue = {
    100: "#DAECFF",
    200: "#99CCF3",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
  };

  const grey = {
    100: "#E7EBF0",
    200: "#E0E3E7",
    300: "#CDD2D7",
    400: "#B2BAC2",
    500: "#A0AAB4",
    600: "#6F7E8C",
    700: "#3E5060",
    800: "#2D3843",
    900: "#1A2027",
  };

  const StyledButton = styled("button")(
    ({ theme }) => `
    font-family: Muli;
    font-size: 10px;
    box-sizing: border-box;
    height: 35px;
    width: 9rem;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
    border-radius: 0.75em;
    margin-top: 0.7em;
    padding: 10px;
    text-align: left;
    line-height: 1.5;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  
    &:hover {
      background: ${theme.palette.mode === "dark" ? "" : grey[100]};
      border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }
  
    &.${selectUnstyledClasses.focusVisible} {
      outline: 3px solid ${
        theme.palette.mode === "dark" ? blue[600] : blue[100]
      };
    }
  
    &.${selectUnstyledClasses.expanded} {
      &::after {
        content: '▴';
      }
    }
  
    &::after {
      content: '▾';
      float: right;
    }
    cursor: pointer;
    `
  );

  const StyledListbox = styled("ul")(
    ({ theme }) => `
    font-family: "Muli";
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 5px;
    width: 9rem;
    height:120px;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
    border-radius: 0.75em;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    overflow: scroll;
    outline: 0px;
    
    `
  );

  const StyledOption = styled(OptionUnstyled)(
    ({ theme }) => `
    list-style: none;
    padding: 8px;
    border-radius: 0.45em;
    cursor: pointer;
  
    &:last-of-type {
      border-bottom: none;
    }
  
    &.${optionUnstyledClasses.selected} {
      background-color: ${
        theme.palette.mode === "dark" ? blue[900] : blue[100]
      };
      color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
    }
  
    &.${optionUnstyledClasses.highlighted} {
      background-color: ${
        theme.palette.mode === "dark" ? grey[800] : grey[100]
      };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
  
    &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
      background-color: ${
        theme.palette.mode === "dark" ? blue[900] : blue[100]
      };
      color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
    }
  
    &.${optionUnstyledClasses.disabled} {
      color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }
  
    &:hover:not(.${optionUnstyledClasses.disabled}) {
      background-color: ${
        theme.palette.mode === "dark" ? grey[800] : grey[100]
      };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
    `
  );

  const StyledPopper = styled(PopperUnstyled)`
    z-index: 1;
  `;

  const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
    const components = {
      Root: StyledButton,
      Listbox: StyledListbox,
      Popper: StyledPopper,
      ...props.components,
    };

    return <SelectUnstyled {...props} ref={ref} components={components} />;
  });

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
        objectFit: "cover",
      }}
    >
      <Grid container padding={{ xs: 1.5, sm: 3.5 }} rowSpacing={0.5}>
       
          <Grid item xs={9.8} sm={10.9} md={11.5} className={classes["title"]}>
            {localStorage.getItem("FactoryName")}
          </Grid>

          <Grid item xs={1.5} sm={1} md={0.5}>
            <button
              className={classes["back-button"]}
              onClick={() => {
                navigate(-1);
              }}
            >
              Go Back
            </button>
          </Grid>
          <Grid item className={classes["title"]} fontWeight="normal">All Bays</Grid>
       

        <Grid item container xs={12} marginTop={3}>
          <Grid
            container
            style={{
              height: "100vh",
              fontFamily: "Muli",
              backgroundColor: "white",
            }}
          >
            <DataGrid
              initialState={{
                pagination: {
                  pageSize: 25,
                },
              }}
              pagination
              paginationMode="server"
              rows={baysTableData ? baysTableData : []}
              columns={columns}
              headerHeight={40}
              density={"compact"}
              showColumnRightBorder
              sx={{
                [`& .${gridClasses.row}`]: {
                  color: "#0674b9",
                  overflow: "auto",
                  bgcolor: (theme) =>
                    theme.palette.mode === "light" ? grey[0] : grey[500],
                },

                [`& .${gridClasses.columnHeader}`]: {
                  bgcolor: "rgb(172 201 224)",
                  color: "rgb(17 24 84)",
                },
                [`& .${gridClasses.columnHeaderTitle}`]: {
                  fontFamily: "Muli",
                  fontWeight: "bold",
                },

                [`& .${gridClasses.footerContainer}`]: {
                  bgcolor: "rgb(216 235 247)",
                  minHeight: "30px",
                },
                fontFamily: "Muli",
                fontSize: "13px",
              }}
              disableColumnFilter
              getRowId={(row) => row.Id}
              rowCount={dataLength}
              hideFooter={props.paging}
              onPageChange={(newPage) => {
                if (pageSize > dataLength) {
                  setPageNumber(1);
                } else setPageNumber(newPage + 1);
              }}
              onPageSizeChange={(newPage) => {
                setPageSize(newPage);
              }}
              pageSize={pageSize}
              loading={loadingData}
              components={{
                NoRowsOverlay: () => (
                  <Stack className={classes["EmptyMessage"]}>
                    No rows to display
                  </Stack>
                ),
              }}
            />

            <Menu
              id="more-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              disableScrollLock
            >
              <MenuItem
                onClick={(event) => {
                  setRowData(rowData);

                  navigate(
                    `/saas/blocksList?BayId=${rowData?.row.Id}&BayNb=${
                      rowData.api.getRowIndex(rowData.row.Id) + 1
                    }&FactoryId=${factoryId}`
                  );

                  handleClose();
                }}
                style={{
                  fontFamily: "Muli",
                  fontSize: "13px",
                  color: "#444",
                  width: 100,
                  display: "block",
                }}
              >
                View Blocks
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default BayTableView;
