import React, { useEffect, useState } from "react";
import { Grid, Modal, Box, TextField, MenuItem } from "@mui/material";
import classes from "../../../../../../Assets/Styles/factory.module.css";
import { useNavigate } from "react-router-dom";
function CreateStockCountModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    bottom: "30%",
    transform: "translate(-50%, -50%)",
    borderRadius: "17px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.36)",
    border: "solid 1px #0674b9",
    backgroundColor: "#101650",
    outline: 0,
    
  };
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [notEnough, setNotEnough] = useState(false);
  const [title, setTitle] = useState(null);
  const [content, setContent] = useState(null);
  const [contentErrorMessage, setContentErrorMessage] = useState(null);
  const [titleErrorMessage, setTitleErrorMessage] = useState(null);

  const handleClose = () => {
    setOpen(false);
    props.setOpenModal(false);
    props.setCountObject(null);
    setTitle(null);
    setContent(null);
    setTitleErrorMessage(false);
    setContentErrorMessage(false);
    

  };

  useEffect(() => {
    if (props.openModal) {
      setOpen(true);
      if(props.countObject !== null){
        console.log(props.countObject)
        setTitle(props.countObject?.Title);
        setContent(props.countObject?.Summary);
      }
    } else {
      handleClose();
    }
  }, [props.openModal]);

 

  return (
    <Modal
      disableScrollLock
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropProps={{ style: { backgroundColor: "white", opacity: 0.5 } }}
    >
      <Box
        sx={style}
        height={{
          md: contentErrorMessage && titleErrorMessage ? "45%" : "40%",
          xs: contentErrorMessage && titleErrorMessage ? "50%" : "45%",
          sm: contentErrorMessage && titleErrorMessage ? "35%" : "30%",
        }}
        width={{ md: "30%", xs: "75%", sm: "60%" }}
      >
        <Grid container spacing={2} direction="column" padding={5}>
          <Grid item xs={12} marginTop={3}>
            <Grid item className={classes["drop-title"]} fontSize={"10px"}>
              Title of the Count
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={title}
                fullWidth
                InputProps={{
                  style: {
                    height: "24px",
                    fontSize: 12,
                    fontFamily: "Muli",
                    backgroundColor: "rgba(255, 255, 255, 0.93)",
                  },
                }}
                error={titleErrorMessage ? true : false}
                helperText={titleErrorMessage ? titleErrorMessage : null}
                onBlur={(e) => {
                  if (!e.target.value) {
                    setTitleErrorMessage("Enter a title");
                  }
                }}
                onChange={(e) => {
                  setTitle(e.target.value);
                  if (e.target.value.length > 65) {
                    setTitleErrorMessage("Title must be less than 65 chars");
                  } else {
                    setTitleErrorMessage(null);
                  }
                }}
              ></TextField>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item className={classes["drop-title"]} fontSize={"10px"}>
              Summary
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={content}
                fullWidth
                rows={4}
                multiline
                InputProps={{
                  style: {
                    height: "76px",
                    fontSize: 12,
                    fontFamily: "Muli",
                    backgroundColor: "rgba(255, 255, 255, 0.93)",
                  },
                }}
                error={contentErrorMessage ? true : false}
                helperText={contentErrorMessage ? contentErrorMessage : null}
                onBlur={(e) => {
                  if (!e.target.value) {
                    setContentErrorMessage("Enter a summary");
                  }
                }}
                onChange={(e) => {
                  setContent(e.target.value);
                  if (e.target.value.length > 2555) {
                    setContentErrorMessage("Title must be less than 255 chars");
                  } else {
                    setContentErrorMessage(null);
                  }
                }}
              ></TextField>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          container
          direction={"row"}
          justifyContent="center"
          alignItems={"center"}
          gap={10}
          marginBottom={2}
        >
          <button
            className={classes["AddCount"]}
            disabled={
              title && content && !contentErrorMessage && !titleErrorMessage
                ? false
                : true
            }
            onClick={() => {
              if(props.countObject === null){
                props.addingStockCount(title,content);
              }
              else{
                props.updatingStockCount(title,content);
              }
              
              
            }}
          >
           {props.countObject !== null?
            props.countLoading === true?
            "Updating..."
            :
            "Update"
            :
            props.countLoading === true?
            "Adding.."
            :
            "Add"

           }
          </button>
        </Grid>
      </Box>
    </Modal>
  );
}

export default CreateStockCountModal;
