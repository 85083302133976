import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link, useNavigate, NavLink } from "react-router-dom";
import Logo from "../../Assets/Images/Navbar/logo1.png";
import "../../Assets/Styles/Navbar.css";
import CloseIcon from "@mui/icons-material/Close";

import loginIcon from "../../Assets/Images/Navbar/firstIcon.png";
import hoveredLoginIcon from "../../Assets/Images/Navbar/hoveredIcon.png";
import { Button } from "@mui/material";
const drawerWidth = 329;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export default function PersistentDrawerRight({
  booked,
  setBooked,
  setScrolled,
  isLoggedIn,
  accounType,
  accountName,
  setIsLoggedIn,
  setIsSubscribed,
  setIsVerified,
  setToken,
  profileSrc,
  setProfileSrc,
}) {
  const navigate = useNavigate();
  const [showLogOut1, setShowLogOut1] = useState(false);
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [activeIOT,setActiveIOT] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <CssBaseline />
      <AppBar position="fixed" open={open} style={{ backgroundColor: "#fff" }}>
        <Toolbar>
          {booked ? (
            <Typography
              noWrap
              component="div"
              style={{
                display: "flex",
                alignItems: "center",
                paddingRight: "18%",
              }}
            >
              <Link to="/">
                <img
                  src={Logo}
                  width="90%"
                  alt="Logo"
                  style={{ marginTop: "2.5%" }}
                  onClick={(e) => {
                    setBooked(false);
                    setActive(false);
                    setActive2(false);
                    setActive3(false);
                    setActiveIOT(false);
                  }}
                />
              </Link>
              <Link to="/" style={{ textDecoration: "none" }}>
                <Button
                  style={{
                    color: "#101650",
                    border: "1px solid #101650",
                    textTransform: "none",
                  }}
                  onClick={(e) => {
                    setScrolled(true);
                    setBooked(false);
                  }}
                >
                  Join Pilecubes
                </Button>
              </Link>
            </Typography>
          ) : (
            <Typography
              variant="h6"
              noWrap
              sx={{ flexGrow: 1 }}
              component="div"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Link to="/">
                <img
                  src={Logo}
                  alt="Logo"
                  width="150px"
                  style={{ marginTop: "2.5%" }}
                />
              </Link>
            </Typography>
          )}
          <IconButton
            style={{ color: "#101650" }}
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{ ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="temporary"
        anchor="right"
        open={open}
        onEscapeKeyDown={handleDrawerClose}
        onBackdropClick={handleDrawerClose}
      >
        <DrawerHeader>
        
          <ListItem
            button
            onClick={(e) => {
              setActive(false);
              setActive2(false);
              setActive3(false);
              setBooked(false);
              setScrolled(false);
              navigate("/");
              handleDrawerClose();
            }}
          >
            <ListItemText primary={"Home"} className="NavTitle" />
          </ListItem>

          <IconButton onClick={handleDrawerClose} style={{ marginLeft: "25%" }}>
            <CloseIcon style={{ color: "#0674b9" }} />
          </IconButton>
        </DrawerHeader>

        <List style={{ marginLeft: "3%" }}>
          <ListItem
            button
            onClick={(e) => {
              setActive(true);
              setActive2(false);
              setActive3(false);
              setActiveIOT(false);
              navigate("/whyPileCubes");
              handleDrawerClose();
            }}
          >
            <ListItemText
              primary={
                <Typography
                  style={{ fontWeight: active ? "bolder" : "normal" }}
                  className={active ? "buttonsDrawerActive" : "buttonsDrawer"}
                >
                  Why PileCubes?
                </Typography>
              }
            />
          </ListItem>

          <ListItem
            button
            onClick={(e) => {
              setActive(false);
              setActive2(true);
              setActive3(false);
              setActiveIOT(false);
              navigate("/contactUs");
              handleDrawerClose();
            }}
          >
            <ListItemText
              primary={
                <Typography
                  style={{ fontWeight: active2 ? "bolder" : "normal" }}
                  className={active2 ? "buttonsDrawerActive" : "buttonsDrawer"}
                >
                  Contact Us
                </Typography>
              }
            />
          </ListItem>

          <ListItem
            button
            onClick={(e) => {
              setActive(false);
              setActive2(false);
              setActive3(true);
              setActiveIOT(false);
              navigate("/pricing");
              handleDrawerClose();
            }}
          >
            <ListItemText
              primary={
                <Typography
                  style={{ fontWeight: active3 ? "bolder" : "normal" }}
                  className={active3 ? "buttonsDrawerActive" : "buttonsDrawer"}
                >
                  Pricing
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            button
            onClick={(e) => {
              setActive(false);
              setActive2(false);
              setActive3(false);
              setActiveIOT(true);
              navigate('/iotLogin')
                            handleDrawerClose();
            }}
          >
            <ListItemText
              primary={
                <Typography
                  style={{ fontWeight: activeIOT? "bolder" : "normal" }}
                  className={activeIOT ? "buttonsDrawerActive" : "buttonsDrawer"}
                >
                  IOT Login
                </Typography>
              }
            />
          </ListItem>


          <ListItem
            button
            onClick={() => {
              if (isLoggedIn) {
                setShowLogOut1(true);
              } else {
                navigate("/login");
                handleDrawerClose();
                setBooked(false);
              }
            }}
          >
            <ListItemIcon>
              <img
                onClick={() => {
                  if (!isLoggedIn) navigate("/login");
                }}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                  if (!isLoggedIn) {
                    setProfileSrc(hoveredLoginIcon);
                  }
                }}
                onMouseOut={(e) => {
                  if (!isLoggedIn) {
                    setProfileSrc(loginIcon);
                  }
                }}
                src={profileSrc}
                alt={"login"}
                style={{
                  marginTop: "1%",
                  marginLeft: "3%",
                  borderRadius: "50px",
                  border: 0,
                }}
              ></img>
            </ListItemIcon>
            <ListItemText
              primary={isLoggedIn ? accountName : "Login"}
              className={isLoggedIn ? "accountName" : "buttonsDrawer"}
            />
          </ListItem>
          {showLogOut1 ? (
            <ListItem>
              <ListItemText
                className="buttonsDrawer"
                primary="Logout"
                onMouseOver={(e)=> e.target.style.cursor="pointer"}
                onClick={() => {
                  localStorage.clear();
                  setIsVerified("");
                  setIsSubscribed("");
                  setToken("");
                  setIsLoggedIn(false);
                  setShowLogOut1(false);
                  navigate("/");
                  handleDrawerClose();
                }}
              />
            </ListItem>
          ) : (
            <></>
          )}
        </List>
      </Drawer>
    </Box>
  );
}
