import React, { useState, useEffect, useMemo } from "react";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { grey } from "@mui/material/colors";
import classes from "../../Table/Table.module.css";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuPic from "../../../Assets/Images/Table/menuPic.png";
import { TextField, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import * as consts from "../../../../FactoryConsts";
import { useLocation } from "react-router-dom";

export default function StorageBlockTable(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowData, setRowData] = useState(null);

  const location = useLocation();
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();

  const [bayNb, setBayNb] = useState(query.get("BayNb"));
  const [bayId, setBayId] = useState(query.get("BayId"));

  const navigate = useNavigate();

  const handleClick = async (event, params) => {
    setRowData(params);

    setAnchorEl(event.target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const columns = [
    { field: "Id", hide: true, hideable: false },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      position: "sticky",
      renderCell: (params) => {
        return (
          <img
            src={MenuPic}
            alt="menu"
            onClick={(e) => handleClick(e, params)}
          ></img>
        );
      },
    },

    {
      field: "Code",
      headerName: "Code",
      width: 300,
    },
    {
      field: "Status",
      headerName: "Block Status",
      width: 200,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.Status === consts.ARCHIVED
                ? "grey"
                : params.row.Status === consts.ACTIVE
                ? "green"
                : "#0674B9",
          }}
        >
          {params.row?.Status}
        </span>
      ),
    },
    {
      field: "CategoryId",
      headerName: "Category",
      width: 200,
      renderCell: (params) => (
        <span>
          {
            props.storageCategories.find((c) => c.Id === params.row?.CategoryId)
              ?.Name
          }
        </span>
      ),
    },

    {
      field: "Type",
      headerName: "Type",
      width: 200,
    },
    // {
    //   field: "MainCharacteristicValues",
    //   headerName: "Main Characteristic Values",
    //   width: 320,
    // },
    {
      field: "NumberOfZones",
      headerName: "No. of Zones",
      width: 200,
    },
    {
      field: "NumberOfResources",
      headerName: " No. of Monitored Resources",
      width: 260,
    },
  ];
  return (
    <Grid
      container
      style={{
        height: "100vh",
        fontFamily: "Muli",
        backgroundColor: "white",
      }}
    >
      <DataGrid
        initialState={{
          pagination: {
            pageSize: 25,
          },
        }}
        pagination
        paginationMode="server"
        rows={props.storageBlocksTableData ? props.storageBlocksTableData : []}
        columns={columns}
        headerHeight={40}
        density={"compact"}
        showColumnRightBorder
        sx={{
          [`& .${gridClasses.row}`]: {
            color: "#0674b9",
            overflow: "auto",
            bgcolor: (theme) =>
              theme.palette.mode === "light" ? grey[0] : grey[500],
          },

          [`& .${gridClasses.columnHeader}`]: {
            bgcolor: "rgb(172 201 224)",
            color: "rgb(17 24 84)",
          },
          [`& .${gridClasses.columnHeaderTitle}`]: {
            fontFamily: "Muli",
            fontWeight: "bold",
          },

          [`& .${gridClasses.footerContainer}`]: {
            bgcolor: "rgb(216 235 247)",
            minHeight: "30px",
          },
          fontFamily: "Muli",
          fontSize: "13px",
        }}
        disableColumnFilter
        getRowId={(row) => row.Id}
        rowCount={props.dataLength}
        hideFooter={props.paging}
        onPageChange={(newPage) => {
          if (props.pageSize > props.dataLength) {
            props.setPageNumber(1);
          } else props.setPageNumber(newPage + 1);
        }}
        onPageSizeChange={(newPage) => {
          props.setPageSize(newPage);
        }}
        pageSize={props.pageSize}
        loading={props.loadingData}
        components={{
          NoRowsOverlay: () => (
            <Stack className={classes["EmptyMessage"]}>
              No rows to display
            </Stack>
          ),
        }}
      />

      <Menu
        id="more-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableScrollLock
      >
        <MenuItem
          
          onClick={(event) => {
            setRowData(rowData);
            navigate(
              `/saas/factory/bay/storageBlock?storageId=${rowData.row.Id}&id=${bayId}&bayNb=${bayNb}&FactoryId=${props.factoryId}&blockType=1`
            );
            handleClose();
          }}
          style={{
            fontFamily: "Muli",
            fontSize: "13px",
            color: "#444",
            display: "block",
          }}
        >
         View Details
        </MenuItem>
        <MenuItem
        disabled={props.disabledChangeStatusSB}
          onClick={() => {
            setRowData(rowData);
            props.setBlockId(rowData?.row.Id);
            props.setOpenModal(true);
            props.setRowData(rowData);
            handleClose();
          }}
          style={{
            fontFamily: "Muli",
            fontSize: "13px",
            color: "#444",
            width: 120,
            display: "block",
          }}
        >
          Change Status
        </MenuItem>
      </Menu>
    </Grid>
  );
}
