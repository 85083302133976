import React, { useState, useEffect } from "react";
import SaasBack from "../../../Assets/Images/ProfileDetails/SaaSbackground.png";
import classes from "../../../Assets/Styles/ProductionLabel.module.css";
import { Grid } from "@mui/material";
import RemIcon from "../../../Assets/Images/ProductionLabel/mainremn.png";
import FGIcon from "../../../Assets/Images/ProductionLabel/mainfinis.png";
import RMIcon from "../../../Assets/Images/ProductionLabel/mainrm.png";
import DNIcon from "../../../Assets/Images/ProductionLabel/DeliveryNoteIcon.png";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import * as ProductionLabelConsts from "../../../../productionLabelConst";
import { useSelector } from "react-redux";
import * as permissionFunctions from "../../../../ReusableFunctions";
const CheckIfPermitted = permissionFunctions.CheckIfPermitted;

const ProductionLabelTypes = (props) => {
  const navigate = useNavigate();
  const [showMessage, setShowMessage] = useState(false);
  const [disabelRME, setDisabelRME] = useState(false);
  const [disableFGDN, setDisableFGDN] = useState(false);
  const [disableAssign, setDisableAssign] = useState(false);

  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  useEffect(() => {
    if (UserPermissions) {
      CheckIfPermitted(
        UserPermissions,
        "Label Management",
        "create RM/ RE",
        setDisabelRME
      );
      CheckIfPermitted(
        UserPermissions,
        "Label Management",
        "create FG/ DN",
        setDisableFGDN
      );
      CheckIfPermitted(
        UserPermissions,
        "Label Management",
        "Assign",
        setDisableAssign
      );
    }
  }, [UserPermissions]);
  return (
    <div
      style={{
        minHeight: props.adminPage ? "100%" : "85vh",
        backgroundImage: props.adminPage ? null : `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      {showMessage ? (
        <Grid
          item
          container
          bgcolor={"rgba(225, 227, 25, 0.5)"}
          md={12}
          paddingBottom={1}
          paddingTop={1}
        >
          <Grid item md={3}></Grid>
          <Grid md={5} className={classes["Upgrade-subscription-required"]}>
            Upgrade your plan to create a label for Raw Material and Remnants
          </Grid>
          <Grid md={2} container>
            <Grid
              item
              onClick={() => {
                navigate("/saas/profileSettings");
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
            >
              <button className={classes["Rectangle-1231"]}>Subscribe</button>
            </Grid>
            <Grid
              item
              paddingLeft={1}
              onClick={() => {
                setShowMessage(false);
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
            >
              <CloseIcon fontSize="medium" />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <Grid
        container
        paddingTop={props.adminPage ? 0 : showMessage ? 0 : 4}
        paddingLeft={3}
      >
        <Grid container item xs={12} alignItems={"center"}>
          <Grid item xs={4} className={classes["Production-Label"]}>
            Production Labels
          </Grid>
          <Grid
            item
            xs={8}
            container
            direction={"row-reverse"}
            gap={2}
            paddingRight={5}
          >
            <Grid item>
              <button
                className={classes["Go-Back"]}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Go Back
              </button>
            </Grid>
            <Grid item>
              <button
                className={classes["LabelTemp"]}
                onClick={() => {
                  navigate("/saas/admin/productionLabel/table");
                }}
              >
                Go to List
              </button>
            </Grid>

            <Grid item>
              <button
                className={classes["LabelTemp"]}
                disabled={disableAssign}
                onClick={() => {
                  navigate(
                    `/saas/admin/productionLabel/projectLabel?category=${1}`
                  );
                }}
              >
                Label Template/ Project
              </button>
            </Grid>
            <Grid item>
              <button
                className={classes["LabelTemp"]}
                disabled={disableAssign}
                onClick={() => {
                  navigate(
                    `/saas/admin/productionLabel/projectLabel?category=${2}`
                  );
                }}
              >
                Label Template/ Inventory
              </button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          paddingTop={7}
          item
          xs={12}
          alignItems="center"
          justifyContent={"center"}
          gap={2}
        >
          <Grid
            xs={2.5}
            item
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            onClick={() => {
              if (
                localStorage.getItem("AccountType") === "2" &&
                (localStorage.getItem("IsFreePlanActivated") === "true" || localStorage.getItem("planIdManu")?.includes("Core") || localStorage.getItem("isGuest") === "true")
              ) {
                setShowMessage(true);
              } else {
                if (!disabelRME)
                  navigate(
                    `/saas/admin/productionLabel/create?typeId=${ProductionLabelConsts.RAW_MATERIAL_ID}`
                  );
              }
            }}
          >
            <img
              src={RMIcon}
              width={props.adminPage ? "100%" : "85%"}
              alt={"Raw Materials"}
            />
          </Grid>
          <Grid
            xs={2.5}
            item
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            onClick={() => {
              if (
                localStorage.getItem("AccountType") === "2" &&
                (localStorage.getItem("IsFreePlanActivated") === "true" || localStorage.getItem("planIdManu")?.includes("Core") || localStorage.getItem("isGuest") === "true")
              ) {
                setShowMessage(true);
              } else {
                if (!disabelRME)
                  navigate(
                    `/saas/admin/productionLabel/create?typeId=${ProductionLabelConsts.REMENANT_ID}`
                  );
              }
            }}
          >
            <img
              src={RemIcon}
              width={props.adminPage ? "100%" : "85%"}
              alt={"Remant"}
            />
          </Grid>
          <Grid
            xs={2.5}
            item
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            onClick={() => {
              if (!disableFGDN)
                navigate(
                  `/saas/admin/productionLabel/create?typeId=${ProductionLabelConsts.FINISHED_GOODS_ID}`
                );
            }}
          >
            <img
              src={FGIcon}
              width={props.adminPage ? "100%" : "85%"}
              alt={"Finished Goods"}
            />
          </Grid>
          <Grid
            xs={2.5}
            item
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            onClick={() => {
              if (!disableFGDN)
                navigate(
                  `/saas/admin/productionLabel/create?typeId=${ProductionLabelConsts.DELIVERY_NOTE_ID}`
                );
            }}
          >
            <img
              src={DNIcon}
              width={props.adminPage ? "100%" : "85%"}
              alt={"Finished Goods"}
            />
          </Grid>
        </Grid>
        <Grid
          container
          paddingTop={4}
          item
          xs={12}
          alignItems="center"
          justifyContent={"center"}
        >
          <Grid item xs={12} className={classes["chooseLabelMain"]}>
            Chooses the Type of The Label
          </Grid>
          <Grid item xs={12} className={classes["chooseLabel"]}>
            or Select Label Template for each project
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProductionLabelTypes;
