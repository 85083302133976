import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import PlanSection from "../Components/Pricing/PlanSection";
import ContactUsPricing from "../Components/Pricing/ContactUsPricing/ContactUsPricing";
function Pricing({
  setRegister,
  setNaved,
  setLoading,
  accounType,
  isLoggedIn,
  account,
  token,
  isVerified,
  isSubscribed,
  setContactUspricing,
  setTerms,
}) {
  const [showContactUsPricing, setShowContactUsPricing] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setRegister(false);
  }, [setRegister]);

  useEffect(() => {
    let apiTest = true;
    if (localStorage.getItem("accessToken") && apiTest) {
      if (localStorage.getItem("isVerified") === "false") {
        if (localStorage.getItem("isTeamMember") === "true")
          navigate("/teamMemberRegistration");
        else {
          if (localStorage.getItem("AccountType") === "1") navigate("/main");
          if (localStorage.getItem("AccountType") === "2") navigate("/sub");
        }
      } else {
        if (localStorage.getItem("isGuest") === "true")
          navigate("/saas/invitations");
        else if (localStorage.getItem("IsScheduled") === "true")
          navigate("/scheduled");
        else if (
          localStorage.getItem("isSubscribed") === "true" ||
          localStorage.getItem("isSubscribedManu") === "true" ||
          localStorage.getItem("IsSubscribedBeforeManu") === "true" ||
          localStorage.getItem("isTeamMember") === "true" ||
          localStorage.getItem("IsFreePlanActivated") === "true" ||
          localStorage.getItem("IsSubscribedBefore") === "true"
        )
          navigate("/saas/admin");
        else navigate("/pricing");
      }
    }
    return () => {
      apiTest = false;
    };
  }, [navigate]);

  useEffect(() => {
    setNaved(false);
    setTerms(false);
  }, [setNaved]);

  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid item container md={12} spacing={2}>
        <Grid item md={12}>
          {showContactUsPricing ? (
            <ContactUsPricing />
          ) : (
            <PlanSection
              setLoading={setLoading}
              accounType={accounType}
              isLoggedIn={isLoggedIn}
              setShowContactUsPricing={setShowContactUsPricing}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default Pricing;
