import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import classes from "../../../../Assets/Styles/ProductionLabel.module.css";
import { Rnd } from "react-rnd";
import Barcode from "react-barcode";
const PrintingLabelPreview = (props) => {
  const [labelWidth, setLabelWidth] = useState(null);
  const [labelHeight, setLabelHeight] = useState(null);
  const [labelSize, setLabelSize] = useState(4);
  const [PaperSize, setPaperSize] = useState(null);

  useEffect(() => {
    if (props.salesOrderFields) {
      if (props.salesOrderFields?.LabelDetails?.NumOfTags === 4) {
        setPaperSize(860);
        setLabelWidth(860);
        setLabelHeight(303.75);
      } else if (props.salesOrderFields?.LabelDetails?.NumOfTags === 6) {
        setPaperSize(860);
        setLabelWidth(430);
        setLabelHeight(405);
      } else if (props.salesOrderFields?.LabelDetails?.NumOfTags === 8) {
        setPaperSize(860);
        setLabelWidth(430);
        setLabelHeight(303.75);
      }
      if (props.salesOrderFields?.LabelDetails?.LabelSizeId === null) {
        setPaperSize(297);
        setLabelWidth(297);
        setLabelHeight(567);
      }
    }
  }, [props.salesOrderFields]);
  return labelWidth && labelHeight ? (
    <Grid container width={PaperSize} ref={props.myRef} spacing={0}>
      {props.salesOrderFields?.Tages?.map((tag) => (
        <Grid
          container
          height={labelHeight}
          width={labelWidth}
          className={classes["tagForm"]}
        >
          {tag.Fields.map((field) =>
            field.LabelRowName === "Hole Space" ? (
              <Rnd
                disableDragging
                default={{
                  x: field.x + 11,
                  y: field.y + 15,
                  width: field.Width,
                  height: field.Height,
                }}
                style={{
                  border: "solid 1px #101650",
                  borderStyle: "dashed",
                  borderRadius: "50px",
                }}
                minHeight={field.Height}
                minWidth={field.Width}
                maxWidth={field.Width}
                maxHeight={field.Height}
              ></Rnd>
            ) : field.LabelRowName === "Shape Selected" ? (
              <Rnd
                disableDragging
                default={{
                  x: field.x + 11,
                  y: field.y + 15,
                  width: field.Width,
                  height: field.Height,
                }}
                minHeight={field.Height}
                minWidth={field.Width}
                maxWidth={field.Width}
                maxHeight={field.Height}
              >
                <Grid container direction={"column-reverse"}>
                  <Grid
                    item
                    xs={12}
                    className={classes["FieldTitle"]}
                    fontSize={8}
                  >
                    {JSON.parse(field.AdditionalFeatures).ShapeSelected === "B"
                      ? JSON.parse(field.AdditionalFeatures).elements?.map(
                          (el) => el.Name + ": " + el.MeasureValue + " "
                        )
                      : ""}
                  </Grid>
                  <Grid item xs={12} className={classes["FieldTitle"]}>
                    <img
                      src={field.Data}
                      width={field.Width - 10}
                      alt={"shape Image"}
                    />
                  </Grid>
                  <Grid container>
                    <Grid item xs={10.5} className={classes["FieldTitle"]}>
                      {JSON.parse(field.AdditionalFeatures).ShapeCode}
                    </Grid>
                  </Grid>
                </Grid>
              </Rnd>
            ) : field.LabelRowName === "Barcode" ? (
              <Rnd
                disableDragging
                default={{
                  x: field.x + 11,
                  y: field.y + 15,
                  width: field.Width,
                  height: field.Height,
                }}
                minHeight={field.Height}
                minWidth={field.Width}
                maxWidth={field.Width}
                maxHeight={field.Height}
              >
                <Grid container>
                  <Grid item xs={12} className={classes["FieldDataPrinted"]}>
                    {field.LabelRowName}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    width={field.Width}
                    height={field.Height - 15}
                    className={classes["FieldTitle"]}
                  >
                    <Barcode
                      value={field.Data}
                      height={40}
                      format={"CODE128"}
                      font="Avenir Next"
                      fontOptions="600"
                      textMargin={4}
                      margin={10}
                    />
                  </Grid>
                </Grid>
              </Rnd>
            ) : (
              <Rnd
                disableDragging
                default={{
                  x: field.x + 11,
                  y: field.y + 15,
                  width: field.Width,
                  height: field.Height,
                }}
                minHeight={field.Height}
                minWidth={field.Width}
                maxWidth={field.Width}
                maxHeight={field.Height}
              >
                <Grid container>
                  <Grid item xs={12} className={classes["FieldDataPrinted"]}>
                    {field.LabelRowName}
                  </Grid>

                  <Grid item xs={12} className={classes["FieldTitle"]}>
                    {field.Data}
                  </Grid>
                </Grid>
              </Rnd>
            )
          )}
        </Grid>
      ))}
    </Grid>
  ) : (
    ""
  );
};

export default PrintingLabelPreview;
