import React, { useState, useEffect } from "react";
import { Grid, TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import classes from "../../../Assets/Styles/profile.module.css";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import RemoveIcon from "../../../Assets/Images/Projects/delete.png";
import * as scheduleServices from "../../../Services/schedule-services.proxy";
import PlusIcon from "../../../Assets/Images/Factory/plusRow.png";
import DeleteIcon from "../../../Assets/Images/Inventory/deleteYellow.png";

const getUserSchedule = scheduleServices.getUserSchedule;
const updateSchedule = scheduleServices.updateSchedule;

export default function ManageDaysHours() {
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState("");
  const [date, setDate] = useState("");
  const [chosenHolidays, setChosenHolidays] = useState([]);
  const [settingUpdated, setSettingUpdated] = useState(false);
  const [workingShifts, setWorkingShifts] = useState([]);
  const [modify, setModify] = useState(false);
  const handleStartTimeChange = (newValue) => {
    setStartTime(newValue);
  };
  const handleEndTimeChange = (newValue) => {
    setEndTime(newValue);
  };
  const [daysOftheWeek, setDaysOftheWeek] = useState([]);
  const [savingData, setSavingData] = useState(false);
  const handleDaySelection = (day) => {
    if (day.IsSelected) {
      day.IsSelected = false;
    } else {
      day.IsSelected = true;
    }
    forceUpdate();
  };
  const handleDateChange = (newValue) => {
    if (chosenHolidays.includes(newValue)) {
    } else {
      if (newValue !== null) {
        setDate(newValue);
        let arr = chosenHolidays;
        setChosenHolidays(
          arr.concat({ Id: null, Date: newValue?.toISOString() })
        );
      }
    }
  };
  useEffect(() => {
    getUserSchedule().then((x) => {
      setStartTime(
        x.StartTime ? x.StartTime : '09:00'
        // ? new Date(`${new Date().getDate()} ${x.StartTime} GMT+0000`)
        // : new Date(`${new Date().getDate()} 9:00 `)
      );
      setEndTime(
        x.EndTime ? x.EndTime : '17:00'
        // ? new Date(`${new Date().getDate()} ${x.EndTime} GMT+0000`)
        // : new Date(`${new Date().getDate()} 17:00 `)
      );
      setChosenHolidays(x.NonWorkingDays ? x.NonWorkingDays : []);
      setDaysOftheWeek(x.WorkingDays);
      setWorkingShifts(x.WorkingShifts);
    });
  }, [modify]);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const save = () => {
    setSavingData(true);
    updateSchedule(
      startTime,
      endTime,
      daysOftheWeek,
      chosenHolidays,
      workingShifts
    ).then((x) => {
      setSettingUpdated(true);
      setModify(!modify);
      setSavingData(false);
      setTimeout(() => {
        setSettingUpdated(false);
      }, 2000);
    });
  };
  return (
    <Grid container gap={2} paddin
      gTop={0.5} paddingLeft={4} paddingBottom={2}>
      <Grid item xs={12} className={classes["Working-hours"]}>
        Working Hours
      </Grid>
      <Grid item container xs={12} direction={"row"} paddingTop={1}>
        <Grid item md={5} xs={11.5}>
          <TextField
            variant="outlined"
            error={false}
            fullWidth
            type={"time"}
            label={"Start Time"}
            InputLabelProps={{ shrink: true }}
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
          />
        </Grid>
        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid item md={5} xs={11.5}>
            <TimePicker
              label={"Start Time"}
              value={startTime}
              onChange={handleStartTimeChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  error={false}
                  fullWidth
                />
              )}
            />
          </Grid>
        </LocalizationProvider> */}
        <Grid item md={1} xs={12} className={classes["To"]} paddingTop={1}>
          To
        </Grid>
        <Grid item md={5} xs={11.5}>
          <TextField
            variant="outlined"
            error={false}
            fullWidth
            type={"time"}
            label={"End Time"}
            InputLabelProps={{ shrink: true }}
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
          />
        </Grid>
        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid item md={5} xs={11.5}>
            <TimePicker
              label={"End Time"}
              value={endTime}
              onChange={handleEndTimeChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  error={false}
                  fullWidth
                />
              )}
            />
          </Grid>
        </LocalizationProvider> */}
      </Grid>
      <Grid container item xs={12} paddingTop={1}>
        <Grid item sm={6} gap={0}>
          <Grid item xs={12} className={classes["Working-titles"]}>
            Standard Working Days
          </Grid>
          <Grid container paddingTop={1} gap={1.5}>
            {daysOftheWeek.map((day) => (
              <Grid xs={12} item container direction={"row"} key={day.DayId}>
                <Grid
                  item
                  xs={0.5}
                  className={
                    day.IsSelected
                      ? classes["Checked-box"]
                      : classes["not-checked-box"]
                  }
                  marginTop={0.5}
                  onClick={() => {
                    handleDaySelection(day);
                  }}
                ></Grid>
                <Grid item xs={5} className={classes["Days"]} paddingLeft={2.5}>
                  {day.Name}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item sm={6}>
          <Grid item xs={12} className={classes["Working-titles"]}>
            Non-Working Days
          </Grid>
          <Grid item md={10} sm={11} paddingTop={1}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                inputFormat="MM/dd/yyyy"
                value={date}
                // shouldDisableDate={disableChosenHolidays}
                InputProps={{
                  style: {
                    fontFamily: "Muli",
                    color: "#707070",
                  },
                }}
                onChange={handleDateChange}
                renderInput={(params) => (
                  <TextField {...params} error={false} fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid
            container
            item
            xs={12}
            paddingTop={2}
            paddingLeft={1.5}
            gap={1.5}
          >
            {chosenHolidays?.map((day, index) => (
              <Grid item xs={12} container direction={"row"}>
                <Grid item xs={3.5} key={index} className={classes["date"]}>
                  {day?.Date?.slice(0, 10)}
                </Grid>
                <Grid
                  item
                  xs={1}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={(e) => {
                    let Holidays = chosenHolidays;
                    Holidays = Holidays.filter(function (item) {
                      return item !== day;
                    });
                    setChosenHolidays(Holidays);
                  }}
                >
                  <img src={RemoveIcon} alt={"Remove"} />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid item container direction={"row"} gap={2}>
        <Grid item className={classes["Working-hours"]}>
          Working Shift Hours
        </Grid>
        <Grid
          item
          paddingTop={0.7}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
          }}
          onClick={() => {
            setWorkingShifts(
              workingShifts.concat({
                Id: null,
                Code: null,
                StartTime: new Date(),
                EndTime: new Date(),
              })
            );
          }}
        >
          <img src={PlusIcon} alt={"img"} />
        </Grid>
      </Grid>
      {workingShifts.length > 0 ? workingShifts?.map((x, index) => (
        <Grid item container>
          <Grid item xs={12} container direction={"row"}>
            <Grid item xs={3}>
              <TextField
                value={x.Code}
                fullWidth
                size={"medium"}
                variant={"outlined"}
                InputProps={{
                  style: {
                    fontSize: 18,
                    fontFamily: "Muli",
                    backgroundColor: "#fff",
                    paddingLeft: 6,
                  },
                }}
                label={"Shift Code"}
                onChange={(e) => {
                  x.Code = e.target.value;
                  forceUpdate();
                }}
              ></TextField>
            </Grid>
            <Grid
              item
              paddingTop={1.8}
              paddingLeft={2}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onClick={() => {
                setWorkingShifts(
                  workingShifts.filter((x, inx) => inx !== index)
                );
              }}
            >
              <img src={RemoveIcon} alt={"img"} />
            </Grid>
          </Grid>
          <Grid item container xs={12} direction={"row"} paddingTop={3}>
            <Grid item md={5} xs={11.5}>
              <TextField
                variant="outlined"
                error={false}
                fullWidth
                type={"time"}
                label={"Start Time"}
                InputLabelProps={{ shrink: true }}
                value={x.StartTime}
                onChange={(e) => {
                  x.StartTime = e.target.value;
                  forceUpdate();
                }}
              />
            </Grid>

            <Grid item md={1} xs={12} className={classes["To"]} paddingTop={1}>
              To
            </Grid>

            <Grid item md={5} xs={11.5}>
              <TextField
                type={"time"}
                label={"End Time"}
                value={x.EndTime}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  x.EndTime = e.target.value;
                  forceUpdate();
                }}
                variant="outlined"
                error={false}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      )) : ""}

      <Grid
        item
        container
        xs={12}
        direction={"row-reverse"}
        gap={2}
        paddingRight={2}
        paddingTop={3}
      >
        <Grid
          item
          className={classes["SaveButton"]}
          onClick={() => {
            save();
          }}
        >
          {savingData ? "Saving..." : "Save"}
        </Grid>
        <Grid item className={classes["BackButton"]}>
          Cancel
        </Grid>
        {settingUpdated ? (
          <Grid item className={classes["successfully-saved"]}>
            Your settings have been successfully saved.
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
}
