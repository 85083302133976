import React, { useState } from "react";
import tableIcons from "./tableIcons";
import classes from "./Table.module.css";
import { useNavigate } from "react-router-dom";
import MaterialTable, { MTableToolbar } from "@material-table/core";
import { Grid } from "@mui/material";
import Animation from "../../../Components/Home/Animation";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { styled } from "@mui/system";
const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 10px;
  box-sizing: border-box;
  min-height: 32px;
  width: 9rem;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  margin: 0.5em;
  padding: 10px;
  text-align: left;
  line-height: 1.5;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};

  &:hover {
    background: ${theme.palette.mode === "dark" ? "" : grey[100]};
    border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
  font-family: Muli;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0;
  min-width: 11rem;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  overflow: auto;
  outline: 0px;
  `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }
  `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});

function renderValue(option) {
  if (option === null) {
    return <span style={{ fontFamily: "Muli", fontSize: 12 }}></span>;
  }

  return <span>{option.label}</span>;
}

export default function Table(props) {
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [pageX, setpageX] = useState();
  const [pageY, setpageY] = useState();


  const handleClick = (event) => {
    setpageX(event.pageX);
    setpageY(event.pageY);
    setAnchorEl(event.target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {props.tableData ? (
        <Grid container padding={5}>
          <Grid
            item
            marginLeft={5.2}
            container
            direction={"row"}
            md={12}
            paddingBottom={2}
          >
            <Grid item lg={2} md={4}>
              <span className={classes["Table-Title"]}>{props.tableTitle}</span>
            </Grid>

            <Grid item lg={9.7} container direction={"row-reverse"}>
              <button
                className={classes["AddButton"]}
                onClick={() => {
                  props.setRowData(null);
                  props.setOpenModal(true);
                }}
              >
                Add
              </button>
              {props.ShowGoBack ? (
                <button
                  className={classes["back-button"]}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Go back
                </button>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
          <Grid item md={12} xs={11}>
            <MaterialTable
              icons={tableIcons}
              actions={[
                {
                  icon: ArrowDropDownIcon,
                  tooltip: "More",
                  isFreeAction: false,
                  onClick: (event, rowData) => {
                    props.setRowData(rowData);
                    handleClick(event);
                  },
                },
              ]}
              title={""}
              style={{
                backgroundColor: "transparent",
                border: "0",
                boxShadow:"0 0 0",
              }}
              options={{
                actionsColumnIndex: -1,
                // fixedColumns: {

                //   right: 1,
                // },
                paginationType: "stepped",
                paging: props.paging,
                numberOfPagesAround: 2,
                pageSizeOptions: [5, 10, 20, 50],
                // selection:true,
                padding: "dense",

                search: props.viewSearch,

                selectionProps: (rowData) => ({
                  disabled: rowData.name === "Mehmet",
                  color: "primary",
                }),
                headerSelectionProps: {
                  color: "primary",
                },
                columnResizable: true,
                tableLayout: "fixed",
                searchFieldStyle: {
                  backgroundColor: "#fff",
                  borderRadius: 5,
                  border: "solid 1px #e9e7e7",
                  borderBottom: 0,
                  width: "95%",
                  paddingLeft: 10,
                },

                headerStyle: {
                  backgroundColor: "rgba(45, 164, 208, 0.01)",
                  color: "#101650",
                  fontWeight: "bold",
                  fontFamily: "Muli",
                  fontSize: "13px",
                  height: "30px",
                  borderTop: "solid 1px #fff",
                  borderLeft: "solid 1px #fff",
                  textAlign: "right",
                  padding: "0px",
                },
                rowStyle: (rowData) => ({
                  backgroundColor:
                    selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
                  borderBottom: "solid 1px #f2f2f2",
                  padding: "0px",
                  lineHeight: 0.43,
                  height: "10px",
                  fontFamily: "Muli",
                  fontSize: "13px",
                  color: "#0674b9",
                  width: 20,
                }),
              }}
              onRowClick={(evt, selectedRow) =>
                setSelectedRow(selectedRow.tableData.id)
              }
              columns={props.tableFields}
              data={props.tableData}
              totalCount={13}
              page={props.pageNumber}
              localization={{
                toolbar: {
                  searchPlaceholder: "Search this list...",
                },
                body: {
                  emptyDataSourceMessage: (
                    <h1
                      style={{
                        fontFamily: "Muli",
                        backgroundColor: "white",
                        marginLeft: "-100px",
                        textAlign: "center",
                        color: "rgba(6, 116, 185, 0.5)",
                        fontSize: 18,
                        fontWeight: "mormal",
                      }}
                    >
                      No records to display
                    </h1>
                  ),
                },
              }}
              components={{
                Toolbar: (props2) => (
                  <Grid item container>
                    <Grid item xs={12} md={9} container direction={"column"}>
                      {props.tableTitle === "Document" ? (
                        <Grid
                          item
                          md={12}
                          lg={12}
                          marginTop={1}
                          container
                          direction={"row-reverse"}
                        >
                          <Grid item>
                            <CustomSelect
                              renderValue={renderValue}
                              defaultValue={0}
                              style={{ width: "4.5rem" }}
                            >
                              <StyledOption value={0} disabled>
                                Creator
                              </StyledOption>

                              <StyledOption value={10}>Ten</StyledOption>
                              <StyledOption value={20}>Twenty</StyledOption>
                              <StyledOption value={30}>Thirty</StyledOption>
                            </CustomSelect>
                          </Grid>
                          <Grid item>
                            <CustomSelect
                              renderValue={renderValue}
                              defaultValue={0}
                              style={{ width: "4rem" }}
                            >
                              <StyledOption value={0} disabled>
                                Type
                              </StyledOption>

                              <StyledOption value={10}>Ten</StyledOption>
                              <StyledOption value={20}>Twenty</StyledOption>
                              <StyledOption value={30}>Thirty</StyledOption>
                            </CustomSelect>
                          </Grid>
                          <Grid item>
                            <CustomSelect
                              renderValue={renderValue}
                              defaultValue={0}
                              style={{ width: "7rem" }}
                            >
                              <StyledOption value={0} disabled>
                                Approval Status
                              </StyledOption>

                              <StyledOption value={10}>Ten</StyledOption>
                              <StyledOption value={20}>Twenty</StyledOption>
                              <StyledOption value={30}>Thirty</StyledOption>
                            </CustomSelect>
                          </Grid>
                          <Grid item>
                            <CustomSelect
                              renderValue={renderValue}
                              defaultValue={0}
                              style={{ width: "4rem" }}
                            >
                              <StyledOption value={0} disabled>
                                Status
                              </StyledOption>

                              <StyledOption value={10}>Ten</StyledOption>
                              <StyledOption value={20}>Twenty</StyledOption>
                              <StyledOption value={30}>Thirty</StyledOption>
                            </CustomSelect>
                          </Grid>
                          <Grid item>
                            <CustomSelect
                              renderValue={renderValue}
                              defaultValue={0}
                              style={{ width: "7.5rem" }}
                            >
                              <StyledOption value={0} disabled>
                                Project Reference
                              </StyledOption>

                              <StyledOption value={10}>Ten</StyledOption>
                              <StyledOption value={20}>Twenty</StyledOption>
                              <StyledOption value={30}>Thirty</StyledOption>
                            </CustomSelect>
                          </Grid>
                          <Grid item>
                            <CustomSelect
                              renderValue={renderValue}
                              defaultValue={0}
                            >
                              <StyledOption value={0} disabled>
                                Discipline Reference
                              </StyledOption>

                              <StyledOption value={10}>Ten</StyledOption>
                              <StyledOption value={20}>Twenty</StyledOption>
                              <StyledOption value={30}>Thirty</StyledOption>
                            </CustomSelect>
                          </Grid>
                        </Grid>
                      ) : (
                        <></>
                      )}
                    </Grid>
                    <Grid item xs={12} md={3} direction={"row-reverse"}>
                      <MTableToolbar {...props2} />
                    </Grid>
                  </Grid>
                ),
              }}
              onPageChange={(page, pageSize) => {
                props.setPageNumber(page);
              }}
              onRowsPerPageChange={(pageSize) => {
                props.setPageSize(pageSize);
              }}
            />
            <Menu
              id="more-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: pageY,
                horizontal: pageX,
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem
                onClick={(event, rowData) => {
                  props.setOpenModal(true);
                  handleClose();
                }}
                style={{
                  fontFamily: "Muli",
                  fontSize: "13px",
                  color: "#444",
                  width: 100,
                  display: "block",
                  paddingLeft: "30%",
                }}
              >
                Edit
              </MenuItem>
              <MenuItem
                onClick={() => {
                  props.handleDelete();
                  handleClose();
                }}
                style={{
                  fontFamily: "Muli",
                  fontSize: "13px",
                  color: "#444",
                  width: 100,
                  display: "block",
                  paddingLeft: "28%",
                }}
              >
                Archive
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                }}
                style={{
                  fontFamily: "Muli",
                  fontSize: "13px",
                  color: "#444",
                  width: 100,
                  display: "block",
                  paddingLeft: "25%",
                }}
              >
                Download
              </MenuItem>
            </Menu>
            {props.showViewAll ? (
              <Grid bgcolor={"white"} justifyContent={"center"}>
                <span
                  style={{
                    marginLeft: "50%",
                    color: "#0674b9",
                    fontFamily: "Muli",
                    fontSize: "13px",
                  }}
                >
                  View All
                </span>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          padding={10}
        >
          <Grid item xs={12} sx={{ backgroundColor: "white" }} container>
            <Grid item md={4}></Grid>
            <Grid item xs={12} md={4}>
              <Animation />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
