import React, { useState, useEffect, useRef } from "react";
import { Box, Modal } from "@mui/material";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const style = {
  position: "absolute",
  top: "70%",
  left: "50%",
  bottom: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  height: "400px",
  width: "500px",
};

export default function LocationTest(props) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
  });

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    props.setOpenModal(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (props.openModal) {
      handleOpen();
    }
  }, [props.openModal]);

  const [map, setMap] = React.useState(null);

  const mapRef = useRef(null);
  const [position, setPosition] = useState({
    lat: +props.latitude,
    lng: +props.longitude,
  });
  function handleLoad(map) {
    mapRef.current = map;
  }

  function handleCenter() {
    if (!mapRef.current) return;

    const newPos = mapRef.current.getCenter().toJSON();
    setPosition(newPos);
  }
  useEffect(() => {
    if (props.latitude && props.longitude) {
      setPosition({ lat: +props.latitude, lng: +props.longitude });
    } else {
      setPosition({ lat: 33.20452102259101, lng: 35.04299445350869 });
    }
  }, [props.latitude, props.longitude]);
  return isLoaded ? (
    <Modal
      disableScrollLock
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          id="map"
          center={position}
          zoom={4}
          onLoad={handleLoad}
          // onDragEnd={handleCenter}
          onClick={(e) => {
            const newPos = mapRef.current.getCenter().toJSON();
            setPosition(newPos);
            props.setLatitude(newPos.lat);
            props.setLongitude(newPos.lng);
          }}
        >
          <Marker position={position}></Marker>
        </GoogleMap>
      </Box>
    </Modal>
  ) : (
    <></>
  );
}
