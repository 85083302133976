import * as constFile from "../pileCubesConst";
import axios from "axios";
const CREATE_CHECKOUT_SESSION = constFile.CREATE_CHECKOUT_SESSION;

export const createCheckoutSession = (priceId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + CREATE_CHECKOUT_SESSION}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        PriceId: priceId,
      },
    })
    .then((response) => {
      
      return response.data;
    })
    .catch((err) => {
      
    });
};
