export const PROJECTS = "/projects";
export const UPDATE_PROJECT = "/projects/update";
export const PROJECTS_TABLE_LIST = "/projects/table-list";
export const PROJECTS_CARD_LIST = "/projects/card-list";
export const PROJECT_INPUT_DATA = "/projects/create-or-edit-data";
export const DELETE_P_CONTRACT = '/projects/delete-contract';
export const PROJECTS_ASSIGNED_CARD = "/projects/assigned-projects-card-list";
export const PROJECTS_ASSIGNED_TEABLE =
  "/projects/assigned-projects-table-list";
export const PROJECTS_STATUS = "/projects/change-status";
export const PACTIVE = 1;
export const PARCHIVED = 2;

export const PHASES = "/phases";
export const PHASES_LIST = "/phases/list";
export const PHASES_TABLE_LIST = "/phases/list-by-projectId";
export const PHASES_INPUT_DATA = "/phases/create-or-edit-input-data";
export const PHASES_ASSIGNED_DATA = "/phases/Created-or-assigned-phases";
export const UPDATE_PHASE = "/phases/update";
export const DELETE_PHASE = "/phases/delete";
export const PHASES_STATUS = "/phases/change-status";
export const PHASE_UPLOAD_PROFILE = "/phases/upload-picture";
export const PHASE_DELETE_PROFILE = "/phases/delete-picture";

export const CONTRACTS_UPLOAD_FILE = "/contracts/upload-file";
export const CONTRACTS_DELETE_FILE = "/contracts/delete-file";
//Upload Document consultant
export const ULPOAD_DOCUMENT_CONSULTANT = "/consultants/upload-document";
//Upload Document consultant
export const DELETE_DOCUMENT_CONSULTANT = "/consultants/delete-document";
//Upload profile consultant
export const ULPOAD_PROFILE_CONSULTANT = "/consultants/upload-profile";
//Upload profile consultant
export const DELETE_PROFILE_CONSULTANT = "/consultants/delete-profile";
//Joinable projects
export const JOINABLE_PROJECTS = "/projects/joinable-projects";
//Join project
export const JOIN_PROJECT = "/projects/join-project";
// get consultant
export const CONSULTANT = "/consultants";

//SubProjects
export const SUBPROJECTS = "/sub-projects";
export const SUBPRO_LIST = "/sub-projects/list";
export const SUBPRO_STATUS = "/sub-projects/change-status";
export const SUBPRO_INPUT_DATA = "/sub-projects/create-or-edit-data";
export const SUBPRO_UPDATE = "/sub-projects/update";
export const SUBPRO_UPLOAD_FILE = "/sub-projects/upload-picture";
export const SUBPRO_DELETE_FILE = "/sub-projects/delete-picture";
export const SUBPRO_INPUT_CREATE =
  "/sub-projects/sub-project-collaborative-tenant-create-data";
export const SUBPRO_INPUT_UPDATE =
  "/sub-projects/sub-project-collaborative-tenant-edit-data";
export const SUBPRO_CREATE_TENANT =
  "/sub-projects/create-sub-project-collaborative-tenant";
export const SUBPRO_UPDATE_TENANT =
  "/sub-projects/update-sub-project-collaborative-tenant";
export const DELETE_CONTRACT = '/sub-projects/delete-contract';
//team Member
export const TEAM_MEMBER_BY_SUBPROJECT = "/team-member/list-by-sub-project";
//team Member group permission list
export const TEAM_MEMBER_GROUP_PERMISSION_LIST =
  "/team-member/team-member-group-permission-list";
//get team member by ID
export const GET_TEAM_MEMBER_BY_ID = "/team-member";
//assign team member to subproject
export const ASSIGN_TEAM_MEMBER = "/sub-projects/assign-team-member";
//assign team member to subproject
export const UPDATE_ASSIGN_TEAM_MEMBER =
  "/sub-projects/update-assign-team-member";
//Project MilesTones List
export const GET_MILESTONES_LIST = "/milestone/list-by-projectId";
//SUGGEST MILESTONES DATES
export const SUGGEST_MILESTONES_DATES = "/milestone/suggest-dates";
//APPROVE MILESTONES DATES
export const APPROVE_MILESTONES_DATES = "/milestone/approve-dates";
//APPROVE SUGGESTED DATES
export const APPROVE_SUGGESTED_MILESTONES_DATES =
  "/milestone/accept-suggested-dates";
//get discplines of subproject
export const GET_DISCIPLINES_OF_SUBPROJECT = "/disciplines/list-by-sub-project";
//get DASHBOARD DATA
export const GET_DASHBOARD_DATA = "/dashboard/get-project-by-id";
//Save Subproject Predecessor 
export const SET_SUBPROJECT_PREDECESSOR = "/sub-projects/save-predecessor";
//Save Subproject Predecessor 
export const GET_PHASES_ASSIGNED_LIST = "/phases/create-subproject-phases-list";
// update factory nb
export const UPDATE_FACTORY = "/sub-projects/update-contract";