import React, { useEffect, useMemo } from "react";
import ContractorGrid from "../Components/Main/MainDesign/ContractorGrid";
import "../Assets/Styles/MainContractor.css";
import { useNavigate, useLocation } from "react-router-dom";
export default function Main({
  setRegister,
  setLoading,
  account,
  setToken,
  isVerified,
  setIsVerified,
  setAccountName,
  token,
  isSubscribed,
}) {
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const inviterTenantId = query.get("inviterTenantId");
  const projectId = query.get("projectId");
  const subProjectId = query.get("subProjectId");
  const tenantId = query.get("tenantId");
  const IsManufacturing = query.get("IsManufacturing");

  const navigate = useNavigate();

  useEffect(() => {
    let apiTest = true;
    if (localStorage.getItem("accessToken") && apiTest) {
      if (localStorage.getItem("isVerified") === "false") {
        if (localStorage.getItem("isTeamMember") === "true")
          navigate("/teamMemberRegistration");
        else {
          if (localStorage.getItem("AccountType") === "1") navigate("/main");
          if (localStorage.getItem("AccountType") === "2") navigate("/sub");
        }
      } else {
        if (localStorage.getItem("isGuest") === "true")
          navigate("/saas/invitations");
        else if (localStorage.getItem("IsScheduled") === "true")
          navigate("/scheduled");
        else if (
          localStorage.getItem("isSubscribed") === "true" ||
          localStorage.getItem("isSubscribedManu") === "true" ||
          localStorage.getItem("IsSubscribedBeforeManu") === "true" ||
          localStorage.getItem("isTeamMember") === "true" ||
          localStorage.getItem("IsFreePlanActivated") === "true" ||
          localStorage.getItem("IsSubscribedBefore") === "true"
        )
          navigate("/saas/admin");
        else navigate("/pricing");
      }
    }
    return () => {
      apiTest = false;
    };
  }, [navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setRegister(true);
  }, [setRegister]);

  return (
    <ContractorGrid
      setLoading={setLoading}
      account={account}
      setToken={setToken}
      isVerified={isVerified}
      setIsVerified={setIsVerified}
      setAccountName={setAccountName}
      inviterTenantId={inviterTenantId}
      projectId={projectId}
      subProjectId={subProjectId}
      tenantId={tenantId}
      IsManufacturing={IsManufacturing}
    />
  );
}
