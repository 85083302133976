import React, { useState, useEffect } from "react";
import classes from "./TeamMemberModal.module.css";
import {
  Grid,
  Button,
  Tooltip,
  Modal,
  Box,
  TextField,
  Select,
  FormControl,
  FormHelperText,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { MenuItem } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import * as SaasServices from "../../../Services/saas-services.proxy";
import * as permissionsFunctions from "../../../../ReusableFunctions";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import fileIconM from "../../../Assets/Images/Table/file.png";
import fileIconSub from "../../../Assets/Images/Table/FileSub.png";
import infoTip from "../../../../Assets/Images/form/info.png";
const CheckIfRolePermitted = permissionsFunctions.CheckIfRolePermitted;
const getTeamMemberInputData = SaasServices.getTeamMemberInputData;
const addTeamMember = SaasServices.addTeamMember;
const updateTeamMember = SaasServices.updateTeamMember;
const uploadTeamMemberDocument = SaasServices.uploadTeamMemberDocument;
const deleteTeamMemberDocument = SaasServices.deleteTeamMemberDocument;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "30%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  height: "55%",
  width: "58%",
  overflow: "scroll",
};

const BlueTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
          color: #101650;
          background-color: rgba(6, 116, 185, 0.3);
          font-size: 13px;
         
       
      `);

const TeamMemberModal = (props) => {
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  const [UserRole, setUserRole] = useState("");
  useEffect(() => {
    if (UserPermissions) {
      if (UserPermissions.find((x) => x.Name === "Admin")) {
        setUserRole("Admin");
      } else if (UserPermissions.find((x) => x.Name === "Project Manager")) {
        setUserRole("Project Manager");
      } else if (
        UserPermissions.find((x) => x.Name === "Engineering Manager")
      ) {
        setUserRole("Engineering Manage");
      } else if (UserPermissions.find((x) => x.Name === "Team Leader")) {
        setUserRole("Team Leader");
      } else if (UserPermissions.find((x) => x.Name === "Engineer")) {
        setUserRole("Engineer");
      } else if (
        UserPermissions.find((x) => x.Name === "Document Controller")
      ) {
        setUserRole("Document Controller");
      }
    }
  }, [UserPermissions]);
  const [disabled, setDisabled] = useState(false);
  const AccountType = localStorage.getItem("AccountType");

  const [open, setOpen] = React.useState(false);

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [nameErrorMessage, setNameErrorMessage] = useState(false);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState(false);

  const [role, setRole] = useState([]);
  const [roleError, setRoleError] = useState(false);

  const [roleCategory, setRoleCategory] = useState([]);
  const [roleCategoryError, setRoleCategoryError] = useState(false);

  const [factoryName, setFactoryName] = useState([]);
  const [factories, setFactories] = useState([]);
  const [factoriyError, setFactoriyError] = useState(false);

  const [project, setProject] = useState([]);

  const [status, setStatus] = useState("");
  const [statusName, setStatusName] = useState("");
  const [creator, setCreator] = useState("");
  const [createdDate, setCreatedDate] = useState("");

  const [roles, setRoles] = useState([]);

  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [fileId, setFileId] = useState(null);
  const [previousFileId, setPreviousFileId] = useState(null);
  const [fileIds, setFileIds] = useState([]);

  const [isDisabled, setIsDisabled] = useState(true);

  const [UserId, setUserId] = useState(null);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const openTip = () => setTooltipIsOpen(true);
  const closeTip = () => setTooltipIsOpen(false);

  const [toolFtipIsOpen, setToolFtipIsOpen] = useState(false);
  const [successSaved, setSuccessSaved] = useState(false);

  const openFTip = () => setToolFtipIsOpen(true);
  const closeFTip = () => setToolFtipIsOpen(false);

  useEffect(() => {
    if (props.rowData) {
      setUserId(props.rowData.id);
    } else {
      setUserId(null);
    }
  }, [props, setUserId, UserId]);

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (props.openModal) {
      handleOpen(true);
    }
  }, [props.openModal]);

  useEffect(() => {
    let apiTest = true;
    setLoadingData(true);
    getTeamMemberInputData(UserId ? UserId : null).then((x) => {
      if (apiTest) {
        if (UserId) {
          props.setCode("")
          setName(x.TeamMember.Name);
          setEmail(x.TeamMember.Email);
          setStatus(x.TeamMember.StatusId);
          setStatusName(x.TeamMember.Status);
          setCreator(x.TeamMember.Creator);
          setCreatedDate(x.TeamMember.CreationTime.slice(0, 10));
          setRole(x.TeamMember.GroupPermissions);
          setFiles(x.TeamMember.Documents);
          setFactoryName(x.TeamMember.Factories)

        } else {
          props.setCode(x.TeamMember.Code);
          setName("");
          setEmail("");
          setRole([]);
          setStatus("");
          setStatusName("");
          setCreator("");
          setCreatedDate("");
          setFiles([]);
          setFileIds([]);
        }
        setLoadingData(false);
        setRoles(x.GroupPermission);
        setFactories(x.Factories);
      }
    });
    return () => {
      apiTest = false;
    };
  }, [UserId]);

  useEffect(() => {
    if (
      !name ||
      !email ||
      role.length === 0 ||
      nameError ||
      emailError ||
      roleError
    )
      setIsDisabled(true);
    else setIsDisabled(false);
  }, [name,
    email,
    role.length,
    nameError,
    emailError,
    roleError]);

  const handleClose = () => {
    setName("");
    setEmail("");
    setRole([]);
    setCreator("");
    setCreatedDate("");
    setStatus("");
    setStatusName("");
    setFiles([]);
    setFileIds([]);
    setNameError(false);
    setEmailError(false);
    setRoleError(false);
    setUserId(null);
    setOpen(false);
    props.setOpenModal(false);
  };

  const handleRole = (event) => {
    const {
      target: { value },
    } = event;
    setRole(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleFactoryName = (event) => {
    const {
      target: { value },
    } = event;
    setFactoryName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleRoleCategory = (event) => {
    const {
      target: { value },
    } = event;
    setRoleCategory(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleFileChange = (e) => {
    if (previousFileId) {
      deleteTeamMemberDocument(previousFileId);
    }
    if (e.target.files[0]) {
      if (fileId) setPreviousFileId(fileId);
      uploadTeamMemberDocument(e.target.files[0]).then((x) => {
        setFile(x);
        setFileId(x.FileId);
      });
    }
  };

  useEffect(() => {
    if (file) {
      let tempFile = null;
      tempFile = file;
      files.push(tempFile);
    }
  }, [file]);

  useEffect(() => {
    if (fileId) {
      let tempFileId = null;
      tempFileId = fileId;
      let fileData = { FileId: tempFileId };
      fileIds.push(fileData);
    }
  }, [fileId]);

  const deleteItem = (item) => {
    const index = files.indexOf(item);
    if (index > -1) {
      files.splice(index, 1);
      fileIds.splice(index, 1); // 2nd parameter means remove one item only
    }
  };

  const data = {
    Id: UserId,
    Name: name,
    Code: UserId ? "" : props.code,
    Email: email,
    StatusId: status ? status : null,
    ProfilePicture: null,
    Documents: fileIds,
    GroupPermissions: role,
    Factories: factoryName,
  };

  const [loading, setLoading] = useState(false);
  const save = () => {
    if (data.Id === null)
      addTeamMember(data).then((x) => {
        if (x.response?.data?.status) {
          setEmailError(true);
          setEmailErrorMessage("Email Already Exists");
          setLoading(false);
        } else {
          props.setModify(!props.modify);
          props.setCreate(!props.create);
          setLoading(false);
          setSuccessSaved(true);
          setTimeout(() => {
            props.setRowData(null);
            handleClose();
            setSuccessSaved(false);
          }, [1000]);
        }
      });
    else
      updateTeamMember(data).then((x) => {
        props.setModify(!props.modify);
        setLoading(false);
        setSuccessSaved(true);
        setTimeout(() => {
          setSuccessSaved(false);
          props.setRowData(null);
          handleClose();
        }, [1000]);
      });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container direction={"column"}>
          <Grid
            item
            alignSelf={"flex-end"}
            paddingRight="20.5%"
            paddingTop="120px"
          >
            <CloseIcon
              fontSize="large"
              onClick={handleClose}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              style={{ color: "#fff" }}
            />
          </Grid>
          <Grid item>
            <Box sx={style}>
              {loadingData ? (
                <Grid container alignItems="center" gap={1} justifyContent="center" paddingTop={15}>
                  <CircularProgress />
                  <Grid
                    item
                    fontFamily={"Muli"}
                    fontSize={15}
                    fontWeight={"bold"}
                    color={"rgb(25, 118, 210)"}
                  >
                    Loading ...
                  </Grid>
                </Grid>
              ) : (
                <>
                  <Grid
                    container
                    alignItems="center"
                    direction="column"
                    gap={1}
                  >
                    <Grid item className={classes.newTeamMember} paddingTop={1}>
                      {UserId ? "Edit Team Member" : "New Team Member"}
                    </Grid>
                    <Grid
                      item
                      className={classes["Line-402"]}
                      alignSelf={"center"}
                      justifySelf={"center"}
                      width="90%"
                    ></Grid>
                    <Grid
                      item
                      container
                      width="90%"
                      gap={1}
                      alignSelf={"center"}
                      justifySelf={"center"}
                    >
                      <Grid
                        item
                        className={classes["TM-Information"]}
                        alignSelf="flex-start"
                        xs={12}
                      >
                        Details
                      </Grid>
                      {AccountType === "1" ? (
                        <Grid
                          item
                          className={classes["Line-403-Main"]}
                          width="55px"
                        />
                      ) : (
                        <Grid
                          item
                          className={classes["Line-403-Sub"]}
                          width="55px"
                        />
                      )}
                      <Grid item container direction={"row"} spacing={2}>
                        <Grid
                          item
                          container
                          direction={"column"}
                          md={6}
                          xs={12}
                          spacing={1}
                        >
                          <Grid item container direction={"column"}>
                            <Grid item className={classes["field-label"]}>
                              *Name
                            </Grid>
                            <Grid item>
                              <TextField
                                disabled={UserId ? true : false}
                                fullWidth
                                value={name}
                                error={nameError}
                                helperText={nameError ? nameErrorMessage : ""}
                                id="outlined-name"
                                size="small"
                                InputProps={{ style: { height: "30px" } }}
                                onBlur={(e) => {
                                  if (!e.target.value) {
                                    setNameError(true);
                                    setNameErrorMessage("Enter a name");
                                  } else if (e.target.value.length > 50) {
                                    setNameError(true);
                                    setNameErrorMessage(
                                      " Name must be less than 50 char"
                                    );
                                  }
                                }}
                                onChange={(e) => {
                                  if (!e.target.value) {
                                    setNameError(true);
                                    setNameErrorMessage("Enter a name");
                                  } else if (e.target.value.length > 50) {
                                    setNameError(true);
                                    setNameErrorMessage(
                                      " Name must be less than 50 char"
                                    );
                                  } else setNameError(false);
                                  setName(e.target.value);
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid item container direction={"column"}>
                            <Grid item className={classes["field-label"]}>
                              *Email
                            </Grid>
                            <Grid item>
                              <TextField
                                disabled={UserId ? true : false}
                                fullWidth
                                value={email}
                                error={emailError}
                                helperText={emailError ? emailErrorMessage : ""}
                                id="outlined-name"
                                size="small"
                                InputProps={{ style: { height: "30px" } }}
                                onBlur={(e) => {
                                  if (!e.target.value) {
                                    setEmailError(true);
                                    setEmailErrorMessage(
                                      "Enter an email address"
                                    );
                                  }
                                }}
                                onChange={(e) => {
                                  if (!e.target.value) {
                                    setEmailError(true);
                                    setEmailErrorMessage(
                                      "Enter an email address"
                                    );
                                  } else if (e.target.value.length > 64) {
                                    setEmailError(true);
                                    setEmailErrorMessage(
                                      "Email must be less than 64 char"
                                    );
                                  } else if (
                                    !e.target.value.match(
                                      /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/
                                    )
                                  ) {
                                    setEmailError(true);
                                    setEmailErrorMessage(
                                      "Enter a valid email address"
                                    );
                                  } else setEmailError(false);
                                  setEmail(e.target.value);
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid item container direction={"column"}>
                            <Grid item className={classes["field-label"]}>
                              *Role Group
                            </Grid>
                            <Grid item>
                              <FormControl fullWidth error={roleError}>
                                <Select
                                  fullWidth
                                  value={role}
                                  id="outlined-name"
                                  size="small"
                                  sx={{ height: "30px" }}
                                  multiple
                                  onBlur={(e) => {
                                    if (e.target.value.length === 0)
                                      setRoleError(true);
                                  }}
                                  onChange={(e) => {
                                    setRoleError(false);
                                    handleRole(e);
                                  }}
                                >
                                  {roles.map((r) => (
                                    <MenuItem
                                      value={r.Id}
                                      key={r.Id}
                                      disabled={
                                        (UserRole === "Project Manager" &&
                                          r.Name === "Admin") ||
                                          (UserRole === "Engineering Manager" &&
                                            (r.Name === "Admin" ||
                                              r.Name === "Project Manager")) ||
                                          (UserRole === "Team Leader" &&
                                            (r.Name === "Admin" ||
                                              r.Name === "Project Manager" ||
                                              r.Name ===
                                              "Engineering Manager")) ||
                                          (UserRole !== "Admin" &&
                                            (r.Name !== "Project Manager" ||
                                              r.Name !== "Engineering Manager" ||
                                              r.Name !== "Team Leader" ||
                                              r.Name !== "Document Controller"))
                                          ? CheckIfRolePermitted(
                                            UserPermissions,
                                            "Team Member",
                                            r.Name
                                          )
                                            ? true
                                            : false
                                          : false
                                      }
                                      style={{ display: "block" }}
                                    >
                                      {r.Name}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText>
                                  {roleError ? "Choose role" : ""}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid item container direction={"column"}>
                            <Grid item>
                              <IconButton
                                edge="end"
                                onClick={openFTip}
                                onMouseOver={openFTip}
                                onMouseLeave={closeFTip}
                              >
                                <BlueTip
                                  width="100px"
                                  open={toolFtipIsOpen}
                                  title={"Required fro manufacturing Role only"}
                                  placement="right"
                                >
                                  <img
                                    width={"15px"}
                                    src={infoTip}
                                    alt={"close"}
                                  ></img>
                                </BlueTip>
                              </IconButton>
                            </Grid>
                            <Grid item className={classes["field-label"]}>
                              Factory Name
                            </Grid>
                            <Grid item>
                              <FormControl fullWidth error={factoriyError}>
                                <Select
                                  fullWidth
                                  value={factoryName}
                                  id="outlined-name"
                                  size="small"
                                  sx={{ height: "30px" }}
                                  multiple
                                  // onBlur={(e) => {
                                  //   if (e.target.value.length === 0)
                                  //     setFactoriyError(true);
                                  // }}
                                  onChange={(e) => {
                                    setFactoriyError(false);
                                    handleFactoryName(e);
                                  }}
                                >
                                  {factories.map((r) => (
                                    <MenuItem
                                      value={r.Id}
                                      key={r.Id}
                                      style={{ display: "block" }}
                                    >
                                      {r.Name}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText>
                                  {factoriyError ? "Choose Factory Name" : ""}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          container
                          direction={"column"}
                          md={6}
                          xs={12}
                          spacing={1}
                        >
                          <Grid item container direction="row" spacing={1.5}>
                            <Grid item container direction={"column"} xs={12}>
                              <Grid item className={classes["field-label2"]}>
                                Status
                              </Grid>
                              <Grid item>
                                <TextField
                                  fullWidth
                                  value={statusName}
                                  disabled
                                  className={classes["Field-input"]}
                                  id="outlined-name"
                                  size="small"
                                  InputProps={{ style: { height: "30px" } }}
                                />
                              </Grid>
                            </Grid>
                            <Grid item container direction={"column"} xs={6}>
                              <Grid item className={classes["field-label2"]}>
                                Creator
                              </Grid>
                              <Grid item>
                                <TextField
                                  fullWidth
                                  value={creator}
                                  disabled
                                  className={classes["Field-input"]}
                                  id="outlined-name"
                                  size="small"
                                  InputProps={{ style: { height: "30px" } }}
                                />
                              </Grid>
                            </Grid>
                            <Grid item container direction={"column"} xs={6}>
                              <Grid item className={classes["field-label2"]}>
                                Created Date
                              </Grid>
                              <Grid item>
                                <TextField
                                  fullWidth
                                  value={createdDate}
                                  disabled
                                  className={classes["Field-input"]}
                                  id="outlined-name"
                                  size="small"
                                  InputProps={{ style: { height: "30px" } }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item container direction="row" spacing={2}>
                            <Grid
                              item
                              xs={12}
                              container
                              alignItems="center"
                              marginTop="0.8%"
                            >
                              <Grid item xs={5.8}>
                                <Button
                                  component="label"
                                  style={{
                                    width: "100%",
                                    marginTop: "12px",
                                    textTransform: "none",
                                    fontFamily: "Muli",
                                    fontSize: "12px",
                                    lineHeight: 1.45,
                                    textAlign: "left",
                                    color: "#101650",
                                    backgroundColor: "white",
                                    borderRadius: "6px",
                                    border: "solid 1px #b8b8b8",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    height: "30px",
                                  }}
                                >
                                  <FileUploadOutlinedIcon
                                    htmlColor="#529bd7"
                                    style={{
                                      alignSelf: "center",
                                      paddingRight: "5%",
                                    }}
                                  />
                                  <span style={{ alignSelf: "center" }}>
                                    Upload Document
                                  </span>
                                  <input
                                    type="file"
                                    hidden
                                    onChange={handleFileChange}
                                  />
                                </Button>
                              </Grid>
                              <Grid item xs={1} paddingBottom={1}>
                                {files.length > 0 ? (
                                  <Grid
                                    item
                                    marginRight={1}
                                    xs={1}
                                    paddingTop={3}
                                  >
                                    <IconButton
                                      edge="end"
                                      onClick={openTip}
                                      onMouseOver={openTip}
                                      onMouseLeave={closeTip}
                                    >
                                      <BlueTip
                                        width="100px"
                                        open={tooltipIsOpen}
                                        title={"Click on file to download"}
                                        placement="right"
                                      >
                                        <img
                                          width={"15px"}
                                          src={infoTip}
                                          alt={"close"}
                                        ></img>
                                      </BlueTip>
                                    </IconButton>
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Grid>
                              {files?.map((item) => (
                                <Grid
                                  container
                                  key={item?.FileId}
                                  value={item?.FileId}
                                  paddingTop={1}
                                >
                                  <Grid item xs={1} paddingLeft={1}>
                                    <img
                                      src={
                                        AccountType === "1"
                                          ? fileIconM
                                          : fileIconSub
                                      }
                                      alt="file"
                                      width={15}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={2.5}
                                    onMouseOver={(e) => {
                                      e.target.style.color = "grey";
                                      e.target.style.cursor = "pointer";
                                    }}
                                    onMouseLeave={(e) => {
                                      e.target.style.color = "black";
                                    }}
                                    onClick={() => {
                                      window.open(item?.URL);
                                    }}
                                  >
                                    <span>
                                      {item?.DisplayName?.slice(0, 10)}
                                    </span>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={1}
                                    paddingLeft={2}
                                    paddingTop={0.5}
                                    onMouseOver={(e) => {
                                      e.target.style.cursor = "pointer";
                                    }}
                                    onClick={() => {
                                      deleteItem(item);
                                      setFile(null);
                                      setFileId(null);
                                      forceUpdate();
                                    }}
                                  >
                                    <CloseIcon fontSize="small" />
                                  </Grid>
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent={"flex-end"}
                    direction="row"
                    alignItems={"flex-end"}
                    paddingRight={"5%"}
                    paddingTop={5}
                    paddingBottom={3}
                  >
                    {disabled ? (
                      <>
                        <Grid
                          item
                          paddingBottom="0.5%"
                          paddingRight={2}
                          sx={{
                            fontFamily: "Muli",
                            fontSize: 17,
                            fontWeight: "bold",
                            lineHeight: 1.47,
                            textAlign: "left",
                            color: "#f00",
                          }}
                        >
                          You can only select roles lower than yours.
                        </Grid>
                        <Grid
                          item
                          onClick={() => {
                            setDisabled(false);
                          }}
                          onMouseOver={(e) => {
                            e.target.style.cursor = "pointer";
                          }}
                          paddingRight={3}
                        >
                          <CloseIcon />
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                    {successSaved ?
                      <Grid item
                      paddingRight={2}
                        sx={{
                          fontFamily: "Muli",
                          fontSize: 15,
                          lineHeight: 1.47,
                          textAlign: "left",
                          color: "green",
                        }}>{UserId ? "Successfully Updated" : "Successfully Added "} </Grid>
                      :
                      <></>}
                    <Grid item paddingRight={2}>
                      <button
                        className={classes.Cancel}
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                          e.target.style.backgroundColor = "#f5f5f5";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor = "#fff";
                        }}
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        Cancel
                      </button>
                    </Grid>
                    <Grid item>
                      <button
                        disabled={isDisabled}
                        className={classes.Invite}
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                          e.target.style.backgroundColor = "#242B64";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor = "#0b5cab";
                        }}
                        style={{
                          backgroundColor: isDisabled ? "#bcbcbc" : "#0b5cab",
                          fontFamily: "Muli",
                          textTransform: "none",
                          opacity: loading ? 0.7 : 1,
                        }}
                        onClick={() => {
                          setLoading(true);
                          save();
                        }}
                      >
                        {loading ? "Loading..." : UserId ? "Save" : "Invite"}
                      </button>
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};
export default TeamMemberModal;
