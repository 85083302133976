import React, { useEffect, useState, useMemo } from "react";
import { Grid, Button, Modal, Box, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import yesIcon from "../../../Assets/Images/yesIcon.png";
import savedIcon from "../../../Assets/Images/savedIcon.png";
import cancelIcon from "../../../Assets/Images/cancelIcon.png";
import canceledIcon from "../../../Assets/Images/canceledIcon.png";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as salesOrderServices from "../../../Services/sales-order-services.proxy";
import { setQuarter } from "date-fns";
import { useNavigate, useLocation } from "react-router-dom";
import * as salesOrderConst from "../../../../salesOrderConst";
import moment from "moment";

const acceptSuggestedDate = salesOrderServices.acceptSuggestedDate;
const suggestDate = salesOrderServices.suggestDate;
const changeStatus = salesOrderServices.changeSalesOrderStatus;

function DatesModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    bottom: "30%",
    transform: "translate(-50%, -50%)",
    height: props.suggest ? "48%" : "25%",
    width: "32%",
    borderRadius: "15px",
    boxShadow: " 0 3px 6px 0 rgba(0, 0, 0, 0.46)",
    border: "solid 1px #0674b9",
    backgroundColor: "#fff",
  };

  const navigate = useNavigate()
  const [open, setOpen] = useState(false);
  const [canceled, setCanceled] = useState(false);
  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [salesOrderId, setSalesOrderId] = useState();
  const [suggestion, setSuggestion] = useState();
  const [comment, setComment] = useState();
  const [minimumDays, setMinimumDays] = useState();

  const handleOpen = () => {
    setOpen(true);
    props.setOpenModal(true);
  };

  const handleClose = () => {
    setResponseMessage("");
    props.setOpenModal(false);
    setOpen(false);
    props.setSuggest(false);
    props.setValidate(false);
    setCanceled(false);
    setSaved(false);
    setLoading(false);
    props.setFinishUpdate(true)
    navigate(`/saas/salesOrderForm?id=${props.SALES_ORDER_ID}`)
  };

  useEffect(() => {
    if (props.openModal) {
      handleOpen(true);
      var todayDate = new Date();
      var minDate = new Date(props.minPlannedDate);

      if (props.calenderDateSwapped && new Date(props.calenderDateSwapped) >= minDate) {
        setSuggestion(new Date(props.calenderDateSwapped));
      }
      else {
        if (minDate < todayDate) {
          setMinimumDays(todayDate);
          setSuggestion(todayDate);
        } else {
          setMinimumDays(minDate);
          setSuggestion(minDate);
        }
      }




    }
  }, [props.openModal, props.calenderDateSwapped]);



  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const ID = query.get("id");

  useEffect(() => {
    if (ID) {
      setSalesOrderId(ID);
    }
  }, [ID]);

  const acceptDate = () => {
    acceptSuggestedDate(salesOrderId).then((x) => {
      if (x.status) {
        setResponseMessage(x.error);
      } else {
        changeStatus(props.SALES_ORDER_ID, salesOrderConst.PLANNED_ID).then(
          () => {
            props.setModify(!props.Modify);
            handleClose();
          }
        );
      }
      setLoading(false);

    });
  };

  const suggestNewDate = () => {
    suggestDate(ID, new Date(suggestion).toISOString(), comment).then((x) => {
      if (x.status) {
        setResponseMessage(x.error);
      }
      props.setModify(!props.Modify);
      handleClose();

      setLoading(false);
    });
  };
  const onKeyDown = (e) => {
    e.preventDefault();
  };

  return (
    <Modal
      disableScrollLock
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid
          container
          padding={3}
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            sx={{
              fontFamily: "Muli",
              fontSize: "18px",
              color: "#101650",
              fontWeight: "bold",
              alignContent: "center",
            }}
          >
            Sales Order Delivery Date
          </Grid>
          <Grid
            item
            sx={{
              fontFamily: "Muli",
              fontSize: "17px",
              color: "#0674b9",
              width: "100%",
            }}
          >
            {props.validate
              ? " You selected to Validate Dates for Sales Order. Are you sure you want to Validate dates?"
              : "You selected to Suggest New Dates for Sales Order. Are you sure you want to change dates?"}
          </Grid>
          {props.suggest ? (
            <>
              <Grid item xs={12} marginTop={2}>
                <TextField
                  value={comment}
                  fullWidth
                  multiline
                  rows={2}
                  placeholder="Add a Comment"
                  onChange={(e) => setComment(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    value={suggestion}

                    minDate={minimumDays}
                    onChange={(newValue) => {
                      setSuggestion(newValue);
                    }}

                    renderInput={(params) => (
                      <TextField size={"small"} onKeyDown={onKeyDown}  {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </>
          ) : (
            <></>
          )}
          <Grid
            item
            container
            xs={6}
            justifyContent="space-between"
            sx={{
              fontFamily: "Muli",
              fontSize: "13px",
              color: "#101650",
              fontWeight: "bold",
            }}
            marginTop={props.suggest ? 3 : 0}
          >
            <Grid
              item
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
                setCanceled(true);
              }}
              onMouseLeave={() => setCanceled(false)}
              onClick={() => {
                query.set("date", "");

                handleClose()
              }}
            >
              <img src={canceled ? canceledIcon : cancelIcon} /> Cancel
            </Grid>
            <Grid
              item
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
                setSaved(true);
              }}
              onMouseLeave={() => setSaved(false)}
              onClick={() => {
                {
                  query.set("date", "");
                  props.validate ? acceptDate() : suggestNewDate();
                }
                setLoading(true);
              }}
            >
              <img src={saved ? savedIcon : yesIcon} />
              {loading ? " Saving..." : " Save"}
            </Grid>
          </Grid>

          {responseMessage?
            <Grid item xs={12} textAlign="center" paddingBottom={2} sx={{
              fontFamily: "Muli",
              color: "red", 
            }} >{responseMessage}</Grid>
            :
            <></>}
        </Grid>
      </Box>
    </Modal>
  );
}

export default DatesModal;
