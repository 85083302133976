import * as constFile from "../../productionLabelConst";
import axios from "axios";

const LABEL_INPUT_DATA = constFile.LABEL_INPUT_DATA;
export const getProductionLabelInputData = (labelId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(
      `${process.env.REACT_APP_URL + LABEL_INPUT_DATA}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          labelId: labelId,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const PRODUCTION_LABEL_LIST = constFile.PRODUCTION_LABEL_LIST;
export const getProductionLabelList = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + PRODUCTION_LABEL_LIST}`,
      data,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const CREATE_PRODUCTION_LABEL = constFile.CREATE_PRODUCTION_LABEL;
export const createProductionLabel = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + CREATE_PRODUCTION_LABEL}`,
      data,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const UPDATE_PRODUCTION_LABEL = constFile.UPDATE_PRODUCTION_LABEL;
export const updateProductionLabel = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + UPDATE_PRODUCTION_LABEL}`,
      data,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const PROJECT_LABEL_LIST = constFile.PROJECT_LABEL_LIST;
export const getProjectLabelList = (Search, Category) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(
      `${process.env.REACT_APP_URL + PROJECT_LABEL_LIST}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          Search: Search,
          Category: Category,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

const SAVE_PROJECT_LABEL = constFile.SAVE_PROJECT_LABEL;
export const saveProjectlabel = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + SAVE_PROJECT_LABEL}`,
      data,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
