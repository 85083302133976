import React, { useState } from "react";
import { Grid } from "@mui/material";
import classes from "./UserRolesTable.module.css";
import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const AddedUserRoles = (props) => {
  //const [searchText, setSearchText] = useState("");
  const [ascending, setAscending] = useState(true);

  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    props.setSearch(lowerCase);
  };

  return (
    <Grid container paddingBottom={25}>
      <Grid container xs={12}>
        <Grid item xs={3.15} className={classes["RowsTitles"]}>
          Added User Roles
        </Grid>
        <Grid
          item
          lg={7.5}
          md={7.5}
          sm={7.5}
          xs={7}
          container
          direction={"row-reverse"}
        >
          <Grid item xs={12} sm={4}>
            <TextField
              variant="standard"
              fullWidth
              onChange={inputHandler}
              placeholder="Search this list…"
              value={props.search}
              InputProps={{
                startAdornment: (
                  <SearchIcon
                    fontSize="small"
                    htmlColor={"#707070"}
                    style={{ paddingLeft: 5 }}
                  />
                ),
              }}
              sx={{
                fontFamily: "Muli",
                backgroundColor: "white",
                borderRadius: 1,

                m: (theme) => theme.spacing(1, 0.5, 1.5),
                "& .MuiSvgIcon-root": {
                  mr: 0.5,
                },
                "& .MuiInput-underline:before": {
                  borderBottom: 0,
                  borderColor: "white",
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction={"row"}
        className={classes["RowsTitles"]}
        marginTop={2}
        paddingLeft={2}
      >
        <Grid item md={3.5} xs={4.5} sm={3.6} container direction={"row"}>
          <Grid item>Name</Grid>
          {ascending ? (
            <Grid
              item
              paddingLeft={1}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onClick={() => {
                props.setField("group.Name");
                props.setAsc(false);
                setAscending(false);
              }}
            >
              <ArrowDownwardIcon htmlColor="#0674b9" fontSize={"small"} />
            </Grid>
          ) : (
            <Grid
              item
              paddingLeft={1}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onClick={() => {
                props.setField("group.Name");
                props.setAsc(true);
                setAscending(true);
              }}
            >
              <ArrowUpwardIcon htmlColor="#0674b9" fontSize={"small"} />
            </Grid>
          )}
        </Grid>
        <Grid item md={5} xs={7} sm={4.5}>
          Description
        </Grid>
        <Grid item md={3.5} xs={0} display={{ sm: "inherit", xs: "none" }}>
          Permissions
        </Grid>
      </Grid>
      {props.usersRoles.map((r) =>
        !r.IsSystem ? (
          <Grid
            key={r.Id}
            item
            container
            direction={"row"}
            className={classes.Rows}
            marginTop={2}
            md={10.7}
            xs={10.7}
            alignItems="center"
            spacing={1}
          >
            <Grid
              item
              className={classes.RoleGroup}
              fontSize={"18px"}
              lg={3.9}
              xs={5}
              md={4}
              sm={4}
            >
              {r.Name}
            </Grid>
            <Grid
              item
              className={classes.Description}
              lg={5.6}
              xs={5}
              md={5.2}
              sm={5}
            >
              {r.Description}
            </Grid>

            <Grid item lg={2.4} xs={12} md={2} sm={2}>
              <button
                disabled={
                  props.editPermission ||
                  localStorage.getItem("IsSubscribedBefore") === "true"
                    ? true
                    : false
                }
                className={classes.PermissionButton}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                  e.target.style.backgroundColor = "#0674b9";
                  e.target.style.color = "#fff";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "#fff";
                  e.target.style.color = "#0674b9";
                }}
                onClick={() => {
                  props.setShowEdit(true);
                  props.setOpenModal(true);

                  props.setUserRoleId(r.Id);
                }}
              >
                Edit Role
              </button>
            </Grid>
          </Grid>
        ) : (
          <></>
        )
      )}
    </Grid>
  );
};

export default AddedUserRoles;
