import React, { useState, useEffect } from "react";
import { Grid, TextField, MenuItem } from "@mui/material";
import classing from "../../../../Assets/Styles/factory.module.css";
import Barcode from "react-barcode";
const EquipmentResources = (props) => {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [codeErrMessage, setCodeErrMessage] = useState("");
  const [codeError, setCodeError] = useState(false);
  const [nameErrMessage, setNameErrMessage] = useState("");
  const [nameError, setNameError] = useState(false);
  const [nameManuErrMessage, setNameManuErrMessage] = useState("");
  const [nameManuError, setNameManuError] = useState(false);
  const [productIdErrMessage, setProductIdErrMessage] = useState("");
  const [productIdError, setProductIdError] = useState(false);
  const [modalYearErrMessage, setModalYearErrMessage] = useState("");
  const [modalYearError, setModalYearError] = useState(false);
  const [serialNumberErrMessage, setSerialNumberErrMessage] = useState("");
  const [serialNumberError, setSerialNumberError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    if (
      !props.equipmentResourcesObject.Name ||
      codeError ||
      nameError ||
      nameManuError ||
      productIdError ||
      modalYearError ||
      serialNumberError
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });

  return (
    <Grid
      item
      container
      paddingTop={3}
      paddingLeft={3}
      paddingRight={3}
      bgcolor={"#fff"}
      rowGap={1}
      columnGap={2}
      paddingBottom={1}
    >
      <Grid item xs={12} className={classing["Title"]}>
        Equipment Resource
      </Grid>
      <Grid item sm={3.5} xs={12}>
        <Grid item className={classing["TFTitle"]} paddingLeft={1}>
          *Code
        </Grid>
        <Grid item>
          <TextField
            disabled
            value={props.equipmentResourcesObject.Code}
            error={codeError}
            helperText={codeError ? codeErrMessage : ""}
            fullWidth
            InputProps={{
              style: { height: "28px", fontSize: 12, fontFamily: "Muli" },
            }}
            FormHelperTextProps={{
              style: { fontSize: 8, fontFamily: "Muli" },
            }}
            placeholder="Code"
            onBlur={(e) => {
              if (!e.target.value) {
                setCodeError(true);
                setCodeErrMessage("Enter a code ");
              }
            }}
            onChange={(e) => {
              props.equipmentResourcesObject.Code = e.target.value;
              if (!e.target.value) {
                setCodeError(true);
                setCodeErrMessage("Enter a Code");
              } else if (e.target.value.length > 10) {
                setCodeError(true);
                setCodeErrMessage("Code should be less than 10 characters");
              } else setCodeError(false);
              forceUpdate();
            }}
          />
        </Grid>
      </Grid>
      <Grid item sm={7.5} xs={12}>
        <Grid item className={classing["TFTitle"]} paddingLeft={1}>
          *Name
        </Grid>
        <Grid item>
          <TextField
            value={props.equipmentResourcesObject.Name}
            error={nameError}
            helperText={nameError ? nameErrMessage : ""}
            fullWidth
            InputProps={{
              style: { height: "28px", fontSize: 12, fontFamily: "Muli" },
            }}
            FormHelperTextProps={{
              style: { fontSize: 8, fontFamily: "Muli" },
            }}
            placeholder="Name"
            onBlur={(e) => {
              if (!e.target.value) {
                setNameError(true);
                setNameErrMessage("Enter a Name ");
              }
            }}
            onChange={(e) => {
              props.equipmentResourcesObject.Name = e.target.value;
              if (!e.target.value) {
                setNameError(true);
                setNameErrMessage("Enter a Name");
              } else if (e.target.value.length > 50) {
                setNameError(true);
                setNameErrMessage("Name should be less than 50 characters");
              } else setNameError(false);
              forceUpdate();
            }}
          />
        </Grid>
      </Grid>
      <Grid item sm={7.5} xs={12}>
        <Grid item className={classing["TFTitle"]} paddingLeft={1}>
          Manufacturer
        </Grid>
        <Grid item>
          <TextField
            value={props.equipmentResourcesObject.Manufacturer}
            error={nameManuError}
            helperText={nameManuError ? nameManuErrMessage : ""}
            fullWidth
            InputProps={{
              style: { height: "28px", fontSize: 12, fontFamily: "Muli" },
            }}
            FormHelperTextProps={{
              style: { fontSize: 8, fontFamily: "Muli" },
            }}
            placeholder="Manufacturer Name"
            onChange={(e) => {
              props.equipmentResourcesObject.Manufacturer = e.target.value;
              if (e.target.value.length > 50) {
                setNameManuError(true);
                setNameManuErrMessage("Name should be less than 50 characters");
              } else setNameManuError(false);
              forceUpdate();
            }}
          />
        </Grid>
      </Grid>
      <Grid item sm={3.5} xs={12}>
        <Grid item className={classing["TFTitle"]} paddingLeft={1}>
          Type
        </Grid>
        <Grid item>
          <TextField
            SelectProps={{ MenuProps: { disableScrollLock: true } }}
            value={props.equipmentResourcesObject.EquipmentTypeId}
            fullWidth
            select
            InputProps={{
              style: { height: "28px", fontSize: 12, fontFamily: "Muli" },
            }}
            onChange={(e) => {
              props.equipmentResourcesObject.EquipmentTypeId = e.target.value;
              forceUpdate();
            }}
          >
            <MenuItem value={0} key={0} disabled>
              Type
            </MenuItem>
            {props.equipmentTypes.map((r) => (
              <MenuItem value={r.Id} key={r.Id}>
                {r.Name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid item sm={7.5} xs={12}>
        <Grid item className={classing["TFTitle"]} paddingLeft={1}>
          Manufacturer product ID
        </Grid>
        <Grid item>
          <TextField
            value={props.equipmentResourcesObject.ManufacturerProductId}
            error={productIdError}
            helperText={productIdError ? productIdErrMessage : ""}
            fullWidth
            InputProps={{
              style: { height: "28px", fontSize: 12, fontFamily: "Muli" },
            }}
            FormHelperTextProps={{
              style: { fontSize: 8, fontFamily: "Muli" },
            }}
            placeholder="Manufacturer product ID"
            onChange={(e) => {
              props.equipmentResourcesObject.ManufacturerProductId =
                e.target.value;
              if (e.target.value.length > 50) {
                setProductIdError(true);
                setProductIdErrMessage(
                  "Product Id should be less than 50 characters"
                );
              } else setProductIdError(false);
              forceUpdate();
            }}
          />
        </Grid>
      </Grid>
      <Grid item sm={3.5} xs={12}>
        <Grid item className={classing["TFTitle"]} paddingLeft={1}>
          Modal Year
        </Grid>
        <Grid item>
          <TextField
            value={props.equipmentResourcesObject.ModelYear}
            error={modalYearError}
            helperText={modalYearError ? modalYearErrMessage : ""}
            fullWidth
            InputProps={{
              style: { height: "28px", fontSize: 12, fontFamily: "Muli" },
            }}
            FormHelperTextProps={{
              style: { fontSize: 8, fontFamily: "Muli" },
            }}
            placeholder="Modal Year"
            onChange={(e) => {
              props.equipmentResourcesObject.ModelYear = e.target.value;
              if (e.target.value.length > 50) {
                setModalYearError(true);
                setModalYearErrMessage(
                  "Modal Year should be less than 10 characters"
                );
              } else setModalYearError(false);
              forceUpdate();
            }}
          />
        </Grid>
      </Grid>
      <Grid item sm={7.5} xs={12}>
        <Grid item className={classing["TFTitle"]} paddingLeft={1}>
          Manufacturer Serial Number
        </Grid>
        <Grid item>
          <TextField
            value={props.equipmentResourcesObject.ManufacturerSerialNumber}
            error={serialNumberError}
            helperText={serialNumberError ? serialNumberErrMessage : ""}
            fullWidth
            InputProps={{
              style: { height: "28px", fontSize: 12, fontFamily: "Muli" },
            }}
            FormHelperTextProps={{
              style: { fontSize: 8, fontFamily: "Muli" },
            }}
            placeholder="Manufacturer Serial Number"
            onChange={(e) => {
              props.equipmentResourcesObject.ManufacturerSerialNumber =
                e.target.value;
              if (e.target.value.length > 10) {
                setSerialNumberError(true);
                setSerialNumberErrMessage(
                  "Serial Number should be less than 10 characters"
                );
              } else setSerialNumberError(false);
              forceUpdate();
            }}
          />
        </Grid>
      </Grid>
      <Grid item container direction={"row"} xs={12}>
        <Grid item container xs={6}>
          {props.equipmentResources.find(
            (x) => x.Id === props.equipmentResourcesObject.Id
          ) ? (
            <button
              className={classing["DeleteButton"]}
              onClick={() => {
                props.setEquipmentResources(
                  props.equipmentResources.filter(
                    (x) => x.Id !== props.equipmentResourcesObject.Id
                  )
                );
                props.setEquipmentResourcesObject(null);
                props.setResourceType(0);
                props.setEquipmentResourcesObjectChosen(0);
              }}
            >
              Delete
            </button>
          ) : (
            ""
          )}
        </Grid>
        <Grid item container xs={6} direction={"row-reverse"} gap={1}>
          <button
            className={classing["SaveBut"]}
            disabled={isDisabled}
            onClick={() => {
              const tobeReplaced = props.equipmentResources.find(
                (x) => x.Id === props.equipmentResourcesObject.Id
              );
              if (tobeReplaced) {
                props.equipmentResources.map((obj) => {
                  if (obj.Id === tobeReplaced.Id) {
                    obj = props.equipmentResourcesObject;
                  }
                });
              } else {
                props.setEquipmentResources(
                  props.equipmentResources.concat(
                    props.equipmentResourcesObject
                  )
                );
                props.setNbOfResources(props.nbOfResources + 1);
              }

              props.setEquipmentResourcesObject(null);
              props.setResourceType(0);
              props.setEquipmentResourcesObjectChosen(0);
            }}
          >
            {props.equipmentResources.find(
              (x) => x.Id === props.equipmentResourcesObject.Id
            )
              ? "Update resource"
              : "Add resource"}
          </button>
          <button
            className={classing["cancelbut"]}
            onClick={() => {
              props.setEquipmentResourcesObject(null);
              props.setResourceType(0);
              props.setEquipmentResourcesObjectChosen(0);
            }}
          >
            Cancel
          </button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EquipmentResources;
