import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import classes from "../../Admin/Client/ClientModal.module.css";
import { TextField } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import infoTip from "../../../../Assets/Images/form/info.png";
import { IconButton } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { MenuItem } from "@mui/material";
import fileIcon from "../../../Assets/Images/Table/file.png";
import fileIconSub from "../../../Assets/Images/Table/FileSub.png";
import * as ProjectServices from "../../../Services/projects-services.proxy";

const uploadProfile = ProjectServices.uploadProfileConsultant;
const uploadDocument = ProjectServices.uploadDocument;
const deleteProfileConsultant = ProjectServices.deleteProfileConsultant;
const deleteDocumentConsultant = ProjectServices.deleteDocumentConsultant;
const getConsultantById = ProjectServices.getConsultantById;

const style = {
  position: "absolute",
  top: "53%",
  left: "50%",
  bottom: "30%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  width: "62%",
  height: "90%",
  overflow: "scroll",
  display: "block",
};
const BlueTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
      color: #101650;
      background-color: rgba(6, 116, 185, 0.3);
      font-size: 13px;
     
   
  `);
const ConsultantModal = (props) => {
  const [open, setOpen] = useState(false);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);

  const [isDisabled, setIsDisabled] = useState(true);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);

  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState(false);

  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState(false);

  const [creator, setCreator] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [website, setWebsite] = useState("");
  const [description, setDescription] = useState("");

  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [fileId, setFileId] = useState(null);
  const [previousFileId, setPreviousFileId] = useState(null);
  const [fileIds, setFileIds] = useState([]);

  const [logo, setLogo] = useState(null);
  const [logoId, setLogoId] = useState(null);
  const [previousLogoId, setPreviousLogoId] = useState(null);
  const [consultantId, setConsultantId] = useState(null);

  const AccountType = localStorage.getItem("AccountType");
  const [personalName, setPersonalName] = useState("");
  const [personalNameError, setPersonalNameError] = useState(false);
  const [position, setPosition] = useState("");
  const [positionError, setPositionError] = useState(false);
  const [personalEmail, setPersonalEmail] = useState("");
  const [personalEmailError, setPersonalEmailError] = useState(false);
  const [personalEmailInvalid, setPersonalEmailInvalid] = useState(false);
  const [personalPhoneNumber, setPersonalPhoneNumber] = useState("");
  const [personalPhoneNumberError, setPersonalPhoneNumberError] =
    useState(false);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    let apiTest = true;
    // if (props.consultantInformation && !props.phaseConsultantName.Id) {
    //   setName(props.consultantInformation?.Name);
    //   setNameError(false);
    //   setEmail(props.consultantInformation?.Email);
    //   setAddress(props.consultantInformation?.Address);
    //   setPhoneNumber(props.consultantInformation?.PhoneNumber);
    //   setWebsite(props.consultantInformation?.Name);
    //   setDescription(props.consultantInformation?.Name);
    //   setCountry(props.consultantInformation?.CountryId);
    //   setPersonalName(props.consultantInformation?.ContactName);
    //   setPersonalNameError(false);
    //   setPersonalEmail(props.consultantInformation?.ContactEmail);
    //   setPosition(props.consultantInformation?.ContactPosition);
    //   setPersonalPhoneNumber(props.consultantInformation?.ContactPhoneNumber);
    //   setCreator(props.consultantInformation?.CreatorName);
    //   setCreatedDate(props.consultantInformation?.CreationDate);
    //   if (props.consultantInformation?.Logo)
    //     setLogo(props.consultantInformation?.Logo);
    //   if (props.consultantInformation?.File) {
    //     setFiles(props.consultantInformation?.File);
    //     props.consultantInformation?.File?.map((d) => {
    //       let tempFileId = null;
    //       tempFileId = d.FileId;
    //       let fileData = { FileId: tempFileId };
    //       fileIds.push(fileData);
    //     });
    //   }
    // } else 
    if (props.phaseConsultantName?.Id) {
      getConsultantById(props.phaseConsultantName?.Id).then((x) => {
        if (x) {
          if (apiTest) {
            setName(x.Name);
            setCountry(x.CountryId);
            setAddress(x.Address);
            if (x.Logo) {
              setLogo(x.Logo);
              setLogoId(x.Logo.FileId)
            }
            setAddress(x.Address);
            setPhoneNumber(x.PhoneNumber);
            setWebsite(x.Website);
            setDescription(x.Description);
            setEmail(x.Email);
            if (x.Files) setFiles(x.Files);
            x.Files?.map((d) => {
              let tempFileId = null;
              tempFileId = d.FileId;
              let fileData = { FileId: tempFileId };
              fileIds.push(fileData);
            });
            setPersonalEmail(x.ContactEmail);
            setPersonalName(x.ContactName);
            setPosition(x.ContactPosition);
            setPersonalPhoneNumber(x.ContactPhoneNumber);
            setCreator(x.Creator);
            setCreatedDate(x.CreationDate);
          }
        }
      });
    } else {
      setName("");
      setCountry("");
      setAddress("");
      setFile("");
      setLogo(null);
      setAddress("");
      setPhoneNumber("");
      setWebsite("");
      setDescription("");
      setEmail("");
      setPersonalEmail("");
      setPersonalName("");
      setPosition("");
      setPersonalPhoneNumber("");
      setCreator("");
      setCreatedDate("");
      setFiles([]);
      setFileIds([]);
    }
    return () => {
      apiTest = false;
    };
  }, [props.phaseConsultantName]);

  useEffect(() => {
    if (
      !name ||
      !email ||
      !country ||
      !address ||
      !phoneNumber ||
      !personalName ||
      !position ||
      !personalEmail ||
      !personalPhoneNumber ||
      emailError ||
      emailInvalid ||
      countryError ||
      addressError ||
      phoneNumberError ||
      personalEmailError ||
      personalEmailInvalid ||
      personalPhoneNumberError
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });

  const handleFileChange = (e) => {
    if (previousFileId) {
      deleteDocumentConsultant(previousFileId);
    }
    if (e.target.files[0]) {
      if (fileId) setPreviousFileId(fileId);
      uploadDocument(e.target.files[0]).then((x) => {
        setFile(x);
        setFileId(x.FileId);
      });
    }
  };

  const handleLogoChange = (e) => {
    if (e.target.files[0]) {
      if (previousLogoId) {
        deleteProfileConsultant(previousLogoId);
      }
      if (logoId) setPreviousLogoId(logoId);
      uploadProfile(e.target.files[0]).then((x) => {
        setLogo(x);
        setLogoId(x.FileId);
      });
    }
  };

  useEffect(() => {
    if (file) {
      let tempFile = file;
      files.push(tempFile);
      tempFile = null;
    }
  }, [file]);

  useEffect(() => {
    if (fileId) {
      let tempFileId = fileId;
      let fileData = { FileId: tempFileId };
      fileIds.push(fileData);
      tempFileId = null;
    }
  }, [fileId]);

  const deleteItem = (item) => {
    const index = files.indexOf(item);
    if (index > -1) {
      files.splice(index, 1);
      fileIds.splice(index, 1); // 2nd parameter means remove one item only
    }
  };

  const data = {
    Id: props.newcons === true ? null : consultantId,
    Name: name,
    Email: email,
    Address: address,
    PhoneNumber: phoneNumber,
    Website: website,
    Description: description,
    CountryId: country,
    CountryCode: "961",
    ContactName: personalName,
    ContactPosition: position,
    ContactEmail: personalEmail,
    ContactPhoneNumber: personalPhoneNumber,
    ContactCountryCode: "961",
    Logo: {
      FileId: logoId,
    },
    Files: fileIds.length > 0 ? fileIds : null,
  };

  const save = () => {
    props.setConsultantInformation(data);
    props.setPhaseConsultantError(false);
    setTimeout(() => {
      handleClose();
    }, 1200);
  };

  const openTip = () => setTooltipIsOpen(true);
  const closeTip = () => setTooltipIsOpen(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    props.setOpenModal(false);
    if (!props.consultantInformation) {
      setName("");
      setCountry("");
      setAddress("");
      setFile("");
      setLogo(null);
      setAddress("");
      setPhoneNumber("");
      setWebsite("");
      setDescription("");
      setEmail("");
      setPersonalEmail("");
      setPersonalName("");
      setPosition("");
      setCreator("");
      setCreatedDate("");
      setPersonalPhoneNumber("");
      setFiles([]);
      setFileIds([]);
      setNameError(false);
      setEmailError(false);
      setCountryError(false);
      setAddressError(false);
      setPersonalEmailError(false);
      setPersonalNameError(false);
      setPositionError(false);
      setPhoneNumberError(false);
      setPersonalPhoneNumberError(false);
    }
  };
  useEffect(() => {
    if (props.openModal) {
      handleOpen(true);
    }
  }, [props.openModal]);

  return (
    <div>
      <Modal
        disableScrollLock
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container direction={"column"}>
          <Grid
            item
            alignSelf={"flex-end"}
            paddingRight="18.5%"
            paddingTop="13px"
          >
            <CloseIcon
              fontSize="large"
              onClick={handleClose}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              style={{ color: "#fff" }}
            />
          </Grid>
          <Grid item>
            <Box sx={style}>
              <Grid container alignItems="center" direction="column" gap={1}>
                <Grid item className={classes.newClient} paddingTop={1}>
                  Consultant
                </Grid>

                <Grid
                  item
                  className={classes["Line-402"]}
                  alignSelf={"center"}
                  justifySelf={"center"}
                  width="92%"
                ></Grid>
                <Grid item container paddingLeft={"4%"} gap={1}>
                  <Grid
                    item
                    xs={12}
                    className={classes["Client-Information"]}
                    alignSelf="flex-start"
                  >
                    Consultant Information
                  </Grid>
                  {AccountType === "1" ? (
                    <Grid
                      item
                      className={classes["Line-403-Main"]}
                      width="140px"
                      alignSelf="flex-start"
                    />
                  ) : (
                    <Grid
                      item
                      className={classes["Line-403-Sub"]}
                      width="140px"
                      alignSelf="flex-start"
                    />
                  )}

                  <Grid item container direction={"row"}>
                    <Grid item md={6} xs={12}>
                      <Grid item container direction={"column"}>
                        <Grid item>
                          <span className={classes["field-label"]}>*Name</span>
                        </Grid>
                        <Grid item>
                          <TextField
                            disabled={
                              props.consultantInformation ||
                                props.phaseConsultantName?.Id
                                ? true
                                : false
                            }
                            className={classes["Field-input"]}
                            value={name}
                            error={nameError}
                            id="outlined-name"
                            size="small"
                            helperText={
                              nameError ? "Enter a consultant name" : ""
                            }
                            InputProps={{ style: { height: "30px" } }}
                            inputProps={{ maxLength: 150 }}
                            onBlur={(e) => {
                              if (!e.target.value) setNameError(true);
                            }}
                            onChange={(e) => {
                              setNameError(false);
                              setName(e.target.value);
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container direction={"column"}>
                        <Grid item>
                          <span className={classes["field-label"]}>*Email</span>
                        </Grid>
                        <Grid item>
                          <TextField
                            disabled={
                              props.consultantInformation ||
                                props.phaseConsultantName?.Id
                                ? true
                                : false
                            }
                            error={emailError}
                            className={classes["Field-input"]}
                            value={email}
                            id="outlined-name"
                            size="small"
                            helperText={
                              emailError
                                ? emailInvalid
                                  ? "Enter a valid email address"
                                  : "Enter an email address"
                                : ""
                            }
                            InputProps={{ style: { height: "30px" } }}
                            inputProps={{ maxLength: 64 }}
                            onBlur={(e) => {
                              if (!e.target.value) setEmailError(true);
                            }}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              if (
                                !e.target.value ||
                                !e.target.value.match(
                                  /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/
                                )
                              ) {
                                setEmailError(true);
                                setEmailInvalid(true);
                              } else {
                                setEmailError(false);
                                setEmailInvalid(false);
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container direction={"column"}>
                        <Grid item>
                          <span className={classes["field-label"]}>
                            *Country
                          </span>
                        </Grid>
                        <Grid item>
                          <TextField
                            disabled={
                              props.consultantInformation ||
                                props.phaseConsultantName?.Id
                                ? true
                                : false
                            }
                            select
                            error={countryError}
                            className={classes["Field-input"]}
                            value={country}
                            helperText={countryError ? "Select a country" : ""}
                            id="outlined-name"
                            size="small"
                            InputProps={{ style: { height: "30px" } }}
                            onBlur={(e) => {
                              if (!e.target.value) setCountryError(true);
                            }}
                            onChange={(e) => {
                              setCountry(e.target.value);
                              setCountryError(false);
                            }}
                          >
                            {props.countries.map((option) => (
                              <MenuItem
                                value={option.Id}
                                key={option.Id}
                                style={{ display: "block" }}
                              >
                                {option.Name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                      <Grid item container direction={"column"}>
                        <Grid item>
                          <span className={classes["field-label"]}>
                            *Address
                          </span>
                        </Grid>
                        <Grid item>
                          <TextField
                            disabled={
                              props.consultantInformation ||
                                props.phaseConsultantName?.Id
                                ? true
                                : false
                            }
                            className={classes["Field-input"]}
                            error={addressError}
                            value={address}
                            id="outlined-name"
                            size="small"
                            InputProps={{ style: { height: "30px" } }}
                            inputProps={{ maxLength: 150 }}
                            helperText={addressError ? " Enter an address" : ""}
                            onBlur={(e) => {
                              if (!e.target.value) setAddressError(true);
                            }}
                            onChange={(e) => {
                              setAddress(e.target.value);
                              setAddressError(false);
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container direction={"row"}>
                        <Grid item container direction={"column"} xs={6}>
                          <Grid item>
                            <span className={classes["field-label2"]}>
                              Creator
                            </span>
                          </Grid>
                          <Grid item>
                            <TextField
                              value={creator}
                              disabled
                              className={classes["Field-input2"]}
                              id="outlined-name"
                              size="small"
                              InputProps={{ style: { height: "30px" } }}
                            />
                          </Grid>
                        </Grid>
                        <Grid item container direction={"column"} xs={6}>
                          <Grid item>
                            <span className={classes["field-label2"]}>
                              Created Date
                            </span>
                          </Grid>
                          <Grid item>
                            <TextField
                              value={createdDate}
                              disabled
                              className={classes["Field-input2"]}
                              id="outlined-name"
                              size="small"
                              InputProps={{ style: { height: "30px" } }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Grid item container direction={"column"}>
                        <Grid item>
                          <span className={classes["field-label"]}>
                            *Phone Number
                          </span>
                        </Grid>
                        <Grid item>
                          <PhoneInput
                            disabled={
                              props.consultantInformation ||
                                props.phaseConsultantName?.Id
                                ? true
                                : false
                            }
                            placeholder="Enter phone number"
                            enableAreaCodes={true}
                            onBlur={(e) => {
                              if (!e.target.value) setPhoneNumberError(true);
                            }}
                            value={phoneNumber}
                            onChange={(phone) => {
                              setPhoneNumber(phone);
                              if (
                                phone === "" ||
                                phone.length < 7 ||
                                phone.length > 25
                              ) {
                                setPhoneNumberError(true);
                              } else {
                                setPhoneNumberError(false);
                              }
                            }}
                            country={"lb"}
                            style={{
                              borderColor: phoneNumberError ? "#ea001e" : "",
                            }}
                            inputStyle={{
                              borderColor: phoneNumberError ? "#ea001e" : "",
                              width: "90%",
                              height: 15,
                              fontFamily: "Muli",
                              fontsize: "16px",
                            }}
                          />
                          {phoneNumberError ? (
                            <span
                              style={{
                                fontFamily: "Muli",
                                color: "#ea001e",
                                fontSize: "12px",
                              }}
                            >
                              Phone number must be between 7 and 25 characters
                            </span>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item container direction={"column"}>
                        <Grid item>
                          <span className={classes["field-label"]}>
                            Website
                          </span>
                        </Grid>
                        <Grid item>
                          <TextField
                            disabled={
                              props.consultantInformation ||
                                props.phaseConsultantName?.Id
                                ? true
                                : false
                            }
                            className={classes["Field-input"]}
                            value={website}
                            id="outlined-name"
                            size="small"
                            InputProps={{ style: { height: "30px" } }}
                            inputProps={{ maxLength: 150 }}
                            onChange={(e) => {
                              setWebsite(e.target.value);
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container direction={"column"}>
                        <Grid item>
                          <span className={classes["field-label"]}>
                            Description
                          </span>
                        </Grid>
                        <Grid item>
                          <TextField
                            disabled={
                              props.consultantInformation ||
                                props.phaseConsultantName?.Id
                                ? true
                                : false
                            }
                            className={classes["Field-input"]}
                            value={description}
                            id="outlined-name"
                            size="small"
                            InputProps={{ style: { height: "80px" } }}
                            inputProps={{ maxLength: 255 }}
                            onChange={(e) => {
                              setDescription(e.target.value);
                            }}
                          />
                        </Grid>
                      </Grid>
                      {files.length === 0 ? (
                        <IconButton
                          edge="end"
                          onClick={openTip}
                          onMouseOver={openTip}
                          onMouseLeave={closeTip}
                        >
                          <BlueTip
                            width="400px"
                            open={tooltipIsOpen}
                            title={
                              "You can  upload any document related to the consultant or a contract."
                            }
                            placement="right"
                          >
                            <img
                              width={"20px"}
                              src={infoTip}
                              alt={"close"}
                            ></img>
                          </BlueTip>
                        </IconButton>
                      ) : (
                        <Grid item paddingTop={2}></Grid>
                      )}
                      <Grid item container direction={"row"}>
                        <Grid item container direction={"column"} xs={6}>
                          <Grid item>
                            <Button
                              disabled={
                                props.consultantInformation ||
                                  props.phaseConsultantName?.Id
                                  ? true
                                  : false
                              }
                              component="label"
                              style={{
                                width: "80%",
                                textTransform: "none",
                                fontFamily: "Muli",
                                fontSize: "12px",
                                lineHeight: 1.45,
                                textAlign: "left",
                                color: "#101650",
                                backgroundColor: "white",
                                borderRadius: "6px",
                                border: "solid 1px #b8b8b8",
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                height: "30px",
                              }}
                            >
                              <FileUploadOutlinedIcon htmlColor="#529bd7" />
                              <span style={{ marginLeft: "5%" }}>
                                Upload File
                              </span>
                              <input
                                type="file"
                                hidden
                                onChange={handleFileChange}
                              />
                            </Button>
                          </Grid>
                          {files?.map((item) => (
                            <Grid
                              item
                              container
                              paddingTop={1}
                              key={item?.FileId}
                            >
                              <Grid
                                item
                                xs={2}
                                paddingLeft={1}
                                paddingTop={0.5}
                              >
                                <img
                                  src={
                                    AccountType === "1" ? fileIcon : fileIconSub
                                  }
                                  alt="file"
                                  width={15}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={5}
                                paddingTop={0.5}
                                onMouseOver={(e) => {
                                  e.target.style.color = "grey";
                                  e.target.style.cursor = "pointer";
                                }}
                                onMouseLeave={(e) => {
                                  e.target.style.color = "black";
                                }}
                                onClick={() => {
                                  window.open(item?.URL);
                                }}
                              >
                                {item?.DisplayName?.slice(0, 10)}
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                paddingTop={0.8}
                                onMouseOver={(e) => {
                                  if (props.consultantInformation ||
                                    props.phaseConsultantName?.Id)
                                    e.target.style.cursor = "unset";
                                  else
                                    e.target.style.cursor = "pointer";
                                }}
                                onClick={() => {
                                  if (props.consultantInformation ||
                                    props.phaseConsultantName?.Id)
                                    console.log("")
                                  else {
                                    deleteItem(item);
                                    setFile(null);
                                    setFileId(null);
                                    forceUpdate();
                                  }
                                }}
                              >
                                <CloseIcon fontSize="small" />
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                        <Grid item container direction={"column"} xs={6}>
                          <Grid item>
                            <Button
                              disabled={
                                props.consultantInformation ||
                                  props.phaseConsultantName?.Id
                                  ? true
                                  : false
                              }
                              component="label"
                              style={{
                                width: "80%",
                                textTransform: "none",
                                fontFamily: "Muli",
                                fontSize: "12px",
                                lineHeight: 1.45,
                                textAlign: "left",
                                color: "#101650",
                                backgroundColor: "white",
                                borderRadius: "6px",
                                border: "solid 1px #b8b8b8",
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                height: "30px",
                              }}
                            >
                              <FileUploadOutlinedIcon
                                htmlColor="#529bd7"
                                style={{
                                  alignSelf: "center",
                                  paddingRight: "5%",
                                }}
                              />
                              <span style={{ alignSelf: "center" }}>
                                Upload Logo
                              </span>
                              <input
                                type="file"
                                hidden
                                onChange={handleLogoChange}
                              />
                            </Button>
                          </Grid>
                          {logo && logoId ? (
                            <Grid item container paddingTop={1}>
                              <Grid
                                item
                                xs={2}
                                paddingLeft={1}
                                paddingTop={0.8}
                              >
                                <img
                                  src={logo?.URL}
                                  alt="logo"
                                  width={18}
                                  height={18}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                paddingTop={0.8}
                                onMouseOver={(e) => {
                                  e.target.style.color = "grey";
                                  e.target.style.cursor = "pointer";
                                }}
                                onMouseLeave={(e) => {
                                  e.target.style.color = "black";
                                }}
                                onClick={() => {
                                  window.open(logo?.URL);
                                }}
                              >
                                {logo?.DisplayName?.slice(0, 5)}
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                paddingTop={1}
                                onMouseOver={(e) => {
                                  if (props.consultantInformation ||
                                    props.phaseConsultantName?.Id)
                                    e.target.style.cursor = "unset";
                                  else
                                    e.target.style.cursor = "pointer";
                                }}
                                onClick={() => {
                                  if (props.consultantInformation ||
                                    props.phaseConsultantName?.Id)
                                    console.log("")
                                  else {
                                    setLogo(null);
                                    setLogoId(null);
                                  }
                                }}
                              >
                                <CloseIcon fontSize="small" />
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid item xs={6}></Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className={classes["Client-Information"]}
                    alignSelf="flex-start"
                  >
                    Contact Information
                  </Grid>
                  {AccountType === "1" ? (
                    <Grid
                      item
                      className={classes["Line-403-Main"]}
                      width="160px"
                      alignSelf="flex-start"
                    />
                  ) : (
                    <Grid
                      item
                      className={classes["Line-403-Sub"]}
                      width="160px"
                      alignSelf="flex-start"
                    />
                  )}

                  <Grid item container direction={"row"}>
                    <Grid item md={6} xs={12}>
                      <Grid item container direction={"column"}>
                        <Grid item>
                          <span className={classes["field-label"]}>
                            *Personal Contact Name
                          </span>
                        </Grid>
                        <Grid item>
                          <TextField
                            disabled={
                              props.consultantInformation ||
                                props.phaseConsultantName?.Id
                                ? true
                                : false
                            }
                            className={classes["Field-input"]}
                            value={personalName}
                            error={personalNameError}
                            id="outlined-name"
                            size="small"
                            helperText={
                              personalNameError ? "Enter a personal name" : ""
                            }
                            InputProps={{ style: { height: "30px" } }}
                            inputProps={{ maxLength: 64 }}
                            onBlur={(e) => {
                              if (!e.target.value) setPersonalNameError(true);
                            }}
                            onChange={(e) => {
                              setPersonalNameError(false);
                              setPersonalName(e.target.value);
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container direction={"column"}>
                        <Grid item>
                          <span className={classes["field-label"]}>
                            *Position
                          </span>
                        </Grid>
                        <Grid item>
                          <TextField
                            disabled={
                              props.consultantInformation ||
                                props.phaseConsultantName?.Id
                                ? true
                                : false
                            }
                            className={classes["Field-input"]}
                            value={position}
                            error={positionError}
                            id="outlined-name"
                            size="small"
                            helperText={
                              positionError ? "Enter a job position" : ""
                            }
                            InputProps={{ style: { height: "30px" } }}
                            inputProps={{ maxLength: 20 }}
                            onBlur={(e) => {
                              if (!e.target.value) setPositionError(true);
                            }}
                            onChange={(e) => {
                              setPositionError(false);
                              setPosition(e.target.value);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Grid item container direction={"column"}>
                        <Grid item>
                          <span className={classes["field-label"]}>
                            *Personal Email
                          </span>
                        </Grid>
                        <Grid item>
                          <TextField
                            disabled={
                              props.consultantInformation ||
                                props.phaseConsultantName?.Id
                                ? true
                                : false
                            }
                            error={personalEmailError}
                            className={classes["Field-input"]}
                            value={personalEmail}
                            id="outlined-name"
                            size="small"
                            helperText={
                              personalEmailError
                                ? personalEmailInvalid
                                  ? "Enter a valid email address"
                                  : "Enter an email address"
                                : ""
                            }
                            InputProps={{ style: { height: "30px" } }}
                            inputProps={{ maxLength: 64 }}
                            onBlur={(e) => {
                              if (!e.target.value) setPersonalEmailError(true);
                            }}
                            onChange={(e) => {
                              setPersonalEmail(e.target.value);
                              if (
                                !e.target.value ||
                                !e.target.value.match(
                                  /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/
                                )
                              ) {
                                setPersonalEmailError(true);
                                setPersonalEmailInvalid(true);
                              } else {
                                setPersonalEmailError(false);
                                setPersonalEmailInvalid(false);
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container direction={"column"}>
                        <Grid item>
                          <span className={classes["field-label"]}>
                            *Phone Number
                          </span>
                        </Grid>
                        <Grid item>
                          <PhoneInput
                            disabled={
                              props.consultantInformation ||
                                props.phaseConsultantName?.Id
                                ? true
                                : false
                            }
                            placeholder="Enter phone number"
                            enableAreaCodes={true}
                            onBlur={(e) => {
                              if (!e.target.value)
                                setPersonalPhoneNumberError(true);
                            }}
                            value={personalPhoneNumber}
                            onChange={(phone) => {
                              setPersonalPhoneNumber(phone);
                              if (
                                phone === "" ||
                                phone.length < 7 ||
                                phone.length > 20
                              ) {
                                setPersonalPhoneNumberError(true);
                              } else {
                                setPersonalPhoneNumberError(false);
                              }
                            }}
                            country={"lb"}
                            style={{
                              borderColor: personalPhoneNumberError
                                ? "#ea001e"
                                : "",
                            }}
                            inputStyle={{
                              borderColor: personalPhoneNumberError
                                ? "#ea001e"
                                : "",
                              width: "90%",
                              height: "10px",
                              fontFamily: "Muli",
                              fontsize: "16px",
                            }}
                          />
                          {personalPhoneNumberError ? (
                            <span
                              style={{
                                fontFamily: "Muli",
                                color: "#ea001e",
                                fontSize: "12px",
                              }}
                            >
                              Phone number must be less than 25 char and more
                              than 7
                            </span>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent={"flex-end"}
                  direction="row"
                  alignItems={"flex-end"}
                  paddingRight={5}
                  paddingBottom={1}
                  paddingTop={4}
                >
                  <Grid item paddingRight={2}>
                    <button
                      className={classes.exitModal}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                        e.target.style.backgroundColor = "#f5f5f5";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "#fff";
                      }}
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </button>
                  </Grid>
                  <Grid item>
                    <button
                      disabled={
                        isDisabled ||
                          props.consultantInformation ||
                          props.phaseConsultantName?.Id
                          ? true
                          : false
                      }
                      className={classes.SaveModal}
                      onMouseOver={(e) => {
                        if (
                          isDisabled ||
                          props.consultantInformation ||
                          props.phaseConsultantName?.Id
                        )
                          e.target.style.cursor = "unset";
                        else e.target.style.cursor = "pointer";
                        e.target.style.backgroundColor = "#242B64";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "#0b5cab";
                      }}
                      style={{
                        backgroundColor:
                          isDisabled ||
                            props.consultantInformation ||
                            props.phaseConsultantName?.Id
                            ? "#bcbcbc"
                            : "#0b5cab",
                        fontFamily: "Muli",
                        textTransform: "none",
                      }}
                      onClick={() => {
                        save();
                      }}
                    >
                      Save
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

export default ConsultantModal;
