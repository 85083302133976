import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import { Box } from "@mui/system";
import { Grid, Tooltip } from "@mui/material";
import Animation from "../../../../Components/Home/Animation";
import classes from "../../../Assets/Styles/Subscription.module.css";
import toolHint from "../../../Assets/Images/Settings/info.png";
import useWindowDimensions from "../../../../Components/Navbar/WindowDimension";
import CancelSubscription from "./CancelSubscription";
import UpdateSubscription from "./UpdateSubscription";
import { useNavigate } from "react-router-dom";
import PlansDescription from "./PlansDescription";
import * as profileServices from "../../../Services/profile-services.proxy";
import * as pricingsPlans from "../../../../Services/pricing-data.proxy.js";
import * as CheckOut from "../../../../Services/create-checkout-session.proxy";
import * as pricingRequestService from "../../../../Services/pricing-data.proxy";
import MobilePlansDescription from "./MobilePlansDescriptions";

const getPricingPlans = profileServices.getPricingPlans;
const PRICING_PLANS = pricingsPlans.getPricingPlans;
const CREATE_CHECKOUT_SESSION = CheckOut.createCheckoutSession;
const requestCustomSubscription =
  pricingRequestService.requestCustomSubscription;
export default function PlanDetails(props) {
  const [planColor, setPlanColor] = useState("rgba(206, 210, 225, 0.97)");
  const [openModal, setOpenModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [action, setAction] = useState();
  const [planName, setPlanName] = useState();
  const [planFeatures, setPlanFeatures] = useState([]);
  const [pricingEngineering, setPricingEngineering] = useState(true);
  const [subscriptionType, setSubscriptionType] = useState(
    localStorage.getItem("AccountType") === "1" ? 1 : 2
  );
  const [billingId, setBillingId] = useState(1);
  const [plans, setPlans] = useState({});
  const [newPlanPriceId, setNewPlanPriceId] = useState();

  const Tab = styled(TabUnstyled)`
    font-family: Muli;
    color: #101650;
    cursor: pointer;
    font-size: 18px;

    background-color: transparent;
    width: 128px;
    height: 39px;

    margin: 6px 14px;
    border: none;
    border-radius: 19px;

    &:focus {
      color: #101650;
      border-radius: 19px;

      outline-offset: 2px;
    }

    &.${tabUnstyledClasses.selected} {
      background-color: rgba(45, 164, 208, 0.3);
      color: #0674b9;
    }

    &.${buttonUnstyledClasses.disabled} {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `;

  const TabPanel = styled(TabPanelUnstyled)`
    width: 100%;
    font-family: Muli;
    font-size: 20px;
  `;

  const TabsList = styled(TabsListUnstyled)`
 width:100%
  background-color: #fff;

  display: flex;
  align-items: center;
 
  align-content: space-between;
`;
  const Tab1 = styled(TabUnstyled)`
    font-family: Muli;
    color: #101650;
    cursor: pointer;
    height: 550px;
    width: ${subscriptionType === 3 ? "280px" : "280px"};

    border: solid 1px rgba(206, 210, 225, 0.97);
    background-color: transparent;
    .plan-subscribed {
      font-family: Muli;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #101650;
    }
    .check {
      display: inline-block;
      &:after {
        content: "";
        display: block;
        width: 7px;
        height: 12px;
        border: solid rgba(206, 210, 225, 0.97);
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
    .cancelButton {
      width: 102px;
      height: 37px;
      border: 0;
      background-color: #f2f2f2;
      font-family: Muli;
      font-size: 16px;

      line-height: 1.5;

      text-align: center;
      color: #0674b9;
      cursor: unset;
      pointer-events: none;
    }
    .upgradeButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: ${subscriptionType === 3 ? "255px" : "255px"};
      height: 37.2px;
      background-color: #f2f2f2;
      font-family: Muli;
      font-size: 16px;
      line-height: 1.5;
      text-align: center;
      border: 0;
      color: #0674b9;
      cursor: unset;
      pointer-events: none;
    }
    .hintMessage {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 150px;
      height: 50px;
      border-radius: 8px;
      background-color: #fff;
      font-family: Muli;
      font-size: 10px;
      font-weight: bold;
      margin-left: 15%;
      line-height: 1.1;

      text-align: center;
      color: #fff;
    }

    &:hover {
      box-shadow: 0 7px 19px 0 ${planColor};
      .check {
        display: inline-block;
        &:after {
          content: "";
          display: block;
          width: 7px;
          height: 12px;
          border: solid;
          border-color: ${planColor};
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }
      .hintMessage {
        color: #101650;
        background-color: rgba(6, 116, 185, 0.3);
      }
    }

    &.${tabUnstyledClasses.selected} {
      color: #0674b9;
      box-shadow: 0 7px 19px 0 ${planColor};
      .check {
        display: inline-block;
        &:after {
          content: "";
          display: block;
          width: 7px;
          height: 12px;
          border: solid;
          border-color: ${planColor};
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }
      .cancelButton {
        background-color: #0674b9;
        border: 0px;
        color: #fff;

        cursor: pointer;
        pointer-events: visible;
      }
      .upgradeButton {
        color: #fff;
        background-color: #0674b9;
        cursor: pointer;
        pointer-events: visible;
      }
      .hintMessage {
        color: #101650;
        background-color: rgba(6, 116, 185, 0.3);
      }
    }

    &.${buttonUnstyledClasses.disabled} {
      opacity: 1;
      cursor: not-allowed;
    }
  `;

  const TabsList1 = styled(TabsListUnstyled)`
    background-color: #fff;
    display: flex;
    ${"" /* width: 100%; */}
  `;

  const AccountType = localStorage.getItem("AccountType");
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [directions, setDirections] = useState("row");
  const [phoneView, setPhoneView] = useState(false);
  const [pricingPlans, setPricingPlans] = useState([]);
  const [defaultPlan, setDefaultPlan] = useState(2);
  const [isGuest, setIsGuest] = useState(localStorage.getItem("isGuest"));
  const userId = localStorage.getItem("UserId");
  const [modify, setModify] = useState(false);
  const [loadingTabs, setLoadingTabs] = useState(false);
  useEffect(() => {
    if (AccountType === "1") {
      setPlanColor("#e1e319");
    } else if (AccountType === "2") {
      setPlanColor("#2da4d0");
    }
  }, [AccountType]);
  useEffect(() => {
    if (width < 1200) {
      setDirections("column");
      setPhoneView(true);
    } else {
      setDirections("row");
      setPhoneView(false);
    }
  });

  useEffect(() => {
    if (isGuest === "true") {
      PRICING_PLANS(1, subscriptionType).then((x) => {
        setPlans(x);
        setPricingPlans(x.Products);
      });

      setDefaultPlan(1);
    } else {
      getPricingPlans(billingId, subscriptionType).then((x) => {
        setPlans(x);

        setPricingPlans(x.Products);
      });
    }
  }, [modify]);
  useEffect(() => {
    if (pricingPlans) {
      if (!pricingPlans.find((x) => x.Status === "CURRENT"))
        setDefaultPlan(pricingPlans[2]);
      else setDefaultPlan(pricingPlans.find((x) => x.Status === "CURRENT"));
    }
  }, [pricingPlans]);

  const changePlans = (BillingId, SubscriptionTypeId) => {
    setLoadingTabs(true);
    if (isGuest === "true") {
      PRICING_PLANS(SubscriptionTypeId,BillingId).then((x) => {
        setPlans(x);
        setPricingPlans(x.Products);
        setLoadingTabs(false);
      });

      setDefaultPlan(1);
    } else {
      getPricingPlans(BillingId, SubscriptionTypeId).then((x) => {
        setPlans(x);
        setPricingPlans(x.Products);
        setLoadingTabs(false);
      });
    }
  };
  const createCheckoutSession = (pricingId) => {
    //setLoading(true);
    CREATE_CHECKOUT_SESSION(pricingId).then((x) => window.open(x));
  };
  const requestSubscription = (ProductId) => {
    // //setLoading(true);
    if (subscriptionType === 3) {
      localStorage.setItem("SubscriptionType", 3);
      localStorage.setItem("ProductId", ProductId);
      props.setShowContactUsPricing(true);
    } else {
      localStorage.removeItem("SubscriptionType");
      localStorage.removeItem("ProductId");
      localStorage.removeItem("Plan Name");
      requestCustomSubscription(
        userId,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        localStorage.getItem("AccountType") === "1" ? 1 : 2
      ).then((x) => {
        props.setShowContactUsPricing(true);
      });
    }
  };
  const planForm = (planDetails, defaultValue) => {
    return (
      <TabsUnstyled defaultValue={defaultValue}>
        {loadingTabs ? (
          <Grid container justifyContent={"center"} alignItems={"center"}>
            <Animation />
          </Grid>
        ) : (
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              item
              container
              lg={11.3}
              md={9}
              direction={"row"}
              alignItems={phoneView ? "center" : "inherit"}
              justifyContent={phoneView ? "center" : "inherit"}
              style={{
                overflowX: "scroll",
                overflowY: "hidden",
                minHeight: 640,
              }}
            >
              <TabsList1
                style={{
                  flexDirection: directions,
                  gap: 30,
                  paddingLeft: 15,
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: pricingEngineering ? "nowrap" : "wrap",
                  paddingBottom: 50,
                }}
              >
                {pricingPlans?.map((plan) => (
                  <Tab1
                    key={plan.PriceId}
                    value={plan.PriceId}
                    style={{ marginTop: 38 }}
                    disabled={false}
                  >
                    <Grid item container md={4} xs={12}>
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid item container md={12}>
                          <Grid
                            item
                            container
                            md={12}
                            spacing={1}
                            marginLeft={3}
                          >
                            <Grid item md={12}>
                              <div className={classes["plan-name"]}>
                                {plan.Name}
                              </div>
                            </Grid>
                            <Grid item container md={12} direction={"row"}>
                              <Grid item xs={7} container>
                                {plan.Price === 0 ? (
                                  <div className={classes["Free-Plan"]}>
                                    Free Plan
                                  </div>
                                ) : (
                                  <>
                                    <div
                                      className={classes["plan-price"]}
                                      style={{
                                        fontSize:
                                          plan.Name === "Pro +" ||
                                          plan.Price === null
                                            ? 18
                                            : 33,
                                      }}
                                    >
                                      {plan.Name === "Pro +" ||
                                      plan.Price === null
                                        ? "Custom Pricing"
                                        : plan.CurrencySymbol + "" + plan.Price}
                                    </div>
                                  </>
                                )}
                              </Grid>
                              {plan.Price === 0 ? null : (
                                <Grid
                                  item
                                  md={5}
                                  xs={5}
                                  container
                                  alignItems={"center"}
                                >
                                  <div className={classes["plan-period"]}>
                                    / {plan.BillingPlan}
                                  </div>
                                </Grid>
                              )}
                            </Grid>
                            <Grid item md={12}>
                              <div className={classes["plan-Type"]}>
                                {plan.Type}
                              </div>
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            md={12}
                            marginTop={2}
                            marginLeft={3}
                            height={240}
                            width={"fit-content"}
                          >
                            {plan.ProductDescription.map((option, index) => (
                              <div
                                className={classes["plan-description"]}
                                key={index}
                              >
                                {option.IsChecked ? (
                                  <div
                                    className="check"
                                    style={{ marginRight: 10.3 }}
                                  ></div>
                                ) : null}
                                {option.Description}
                              </div>
                            ))}
                          </Grid>
                          {plan.Status === "SCHEDULED" ? (
                            <div
                              className={classes["plan-subscribed"]}
                              style={{
                                paddingTop: 90,
                                paddingLeft: 80,
                              }}
                            >
                              Scheduled
                            </div>
                          ) : plan.Price === 0 ? (
                            <Grid item paddingTop={10} md={12}>
                              {plan.Status === "CURRENT" ? (
                                <div
                                  style={{ paddingLeft: 10 }}
                                  className={classes["Free-Plan"]}
                                >
                                  Subscribed
                                </div>
                              ) : (
                                <div
                                  style={{
                                    paddingLeft: 10,
                                    fontFamily: "Muli",
                                    fontSize: 15,
                                    fontWeight: "bold",
                                    color: "rgb(92, 230, 92)",
                                  }}
                                  onClick={(e) => {
                                    requestSubscription();
                                  }}
                                >
                                  {localStorage.getItem("AccountType") === "2"
                                    ? "Activated"
                                    : ""}
                                </div>
                              )}
                            </Grid>
                          ) : (plan.Name === "Pro +" || plan.Price === null) &&
                            plan.Status !== "CURRENT" &&
                            plan.Status !== "DISABLED" ? (
                            <Grid item paddingTop={10}>
                              <div
                                style={{ paddingLeft: 10 }}
                                className="upgradeButton"
                                onClick={(e) => {
                                  localStorage.setItem("Plan Name", plan.Name);
                                  requestSubscription(plan.ProductId);
                                }}
                              >
                                Contact Us
                              </div>
                            </Grid>
                          ) : plan.Status === "DISABLED" ? (
                            <Grid
                              container
                              item
                              direction={"row"}
                              paddingTop={10}
                            >
                              <Grid item md={2} marginTop={2}>
                                <img src={toolHint} alt={"hint"} width={20} />
                              </Grid>
                              <Grid item md={6}>
                                <div className="hintMessage">
                                  Sorry, downgrade to Core plan is not
                                  available.
                                </div>
                              </Grid>
                            </Grid>
                          ) : plan.Status === "CURRENT" ? (
                            <Grid
                              container
                              item
                              direction={"row"}
                              paddingTop={10}
                              gap={5}
                            >
                              <Grid item marginTop={0.5} paddingLeft={1}>
                                <div
                                  className={classes["plan-subscribed"]}
                                  style={{
                                    paddingLeft:
                                      plan.Price === null ||
                                      plan.Name === "Pro +"
                                        ? 80
                                        : 0,
                                  }}
                                >
                                  Current plan
                                </div>
                              </Grid>
                              {plan.Price === null || plan.Name === "Pro +" ? (
                                ""
                              ) : (
                                <Grid item>
                                  <button
                                    className="cancelButton"
                                    onClick={() => {
                                      setOpenModal(true);
                                    }}
                                  >
                                    Cancel plan
                                  </button>
                                </Grid>
                              )}
                            </Grid>
                          ) : plan.Status === "UPGRADE" ? (
                            <Grid item paddingTop={10}>
                              <button
                                className="upgradeButton"
                                onClick={() => {
                                  setPlanName(plan.Name);
                                  setPlanFeatures(plan.ProductDescription);
                                  setAction("up");
                                  setOpenUpdateModal(true);
                                  setNewPlanPriceId(plan.PriceId);
                                  localStorage.setItem("PlanChosen", plan.Name);
                                }}
                              >
                                Upgrade to {plan.Name} plan
                              </button>
                            </Grid>
                          ) : plan.Status === "DOWNGRADE" ? (
                            <Grid item paddingLeft={0.5} paddingTop={10}>
                              <button
                                className="upgradeButton"
                                onClick={() => {
                                  setPlanName(plan.Name);
                                  setPlanFeatures(plan.ProductDescription);
                                  setAction("down");
                                  setOpenUpdateModal(true);
                                  setNewPlanPriceId(plan.PriceId);
                                  localStorage.setItem("PlanChosen", plan.Name);
                                }}
                              >
                                Change to {plan.Name} plan
                              </button>
                            </Grid>
                          ) : plan.Status === "CHOOSE" ? (
                            <Grid item paddingLeft={0.5} paddingTop={10}>
                              <button
                                className="upgradeButton"
                                onClick={() => {
                                  setPlanName(plan.Name);
                                  setPlanFeatures(plan.ProductDescription);
                                  setAction("up");
                                  setOpenUpdateModal(true);
                                  setNewPlanPriceId(plan.PriceId);
                                  localStorage.setItem("PlanChosen", plan.Name);
                                }}
                              >
                                Choose plan
                              </button>
                            </Grid>
                          ) : plan.Status === "CANCELED" ? (
                            <Grid item paddingLeft={0.5} paddingTop={10}>
                              <span className="upgradeButton">
                                Canceled plan
                              </span>
                            </Grid>
                          ) : (
                            <Grid item paddingLeft={0.5} paddingTop={10}>
                              <button
                                className="upgradeButton"
                                onClick={() => {
                                  createCheckoutSession(plan.PriceId);
                                  localStorage.setItem("PlanChosen", plan.Name);
                                }}
                              >
                                Choose plan
                              </button>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                  </Tab1>
                ))}
              </TabsList1>
            </Grid>
          </Box>
        )}
      </TabsUnstyled>
    );
  };
  return (
    <>
      {pricingPlans && defaultPlan ? (
        <>
          <Grid
            item
            container
            xs={12}
            justifyContent={"center"}
            alignItems={"center"}
            direction={"row"}
            gap={2}
            marginTop={2}
            marginBottom={2}
          >
            <Grid
              item
              onClick={() => {
                setSubscriptionType(
                  localStorage.getItem("AccountType") === "1" ? 1 : 2
                );
                changePlans(
                  1,
                  localStorage.getItem("AccountType") === "1" ? 1 : 2
                );

                setPricingEngineering(true);

              }}
              className={
                pricingEngineering
                  ? classes["PricingTypeActive"]
                  : classes["PricingTypeNotActive"]
              }
            >
              Engineering Plan
            </Grid>
            <Grid
              item
              onClick={() => {
                setSubscriptionType(3);
                changePlans(1, 3);
                setPricingEngineering(false);
              }}
              className={
                pricingEngineering
                  ? classes["PricingTypeNotActive"]
                  : classes["PricingTypeActive"]
              }
            >
              Manufacturing Plan
            </Grid>
          </Grid>
          <Grid item paddingBottom={2}>
            <TabsUnstyled
              defaultValue={defaultPlan.BillingPlan === "month" ? 0 : 1}
            >
              <TabsList
                style={{
                  marginBottom: phoneView ? 20 : 0,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Tab
                  onClick={() => {
                    changePlans(1, subscriptionType);
                  }}
                >
                  Monthly
                </Tab>
                <Tab
                  onClick={() => {
                    changePlans(2, subscriptionType);
                  }}
                >
                  Annually
                </Tab>
              </TabsList>
              <TabPanel value={0}>
                {planForm(pricingPlans.Products, defaultPlan.PriceId)}
              </TabPanel>
              <TabPanel value={1}>
                {planForm(pricingPlans.Products, defaultPlan.PriceId)}
              </TabPanel>
            </TabsUnstyled>
            <Grid
              paddingTop={2}
              item
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
            >
              <BlueTip
                title={
                  "Once you are subscribed to a Standard Engineering Plan, you will have the features of the Manufacturing Free Plan."
                }
                placement="right"
              >
                <img width={"15px"} src={toolHint} alt={"close"}></img>
              </BlueTip>
            </Grid>
          </Grid>
          <CancelSubscription
            open={openModal}
            setOpen={setOpenModal}
            subscribedPlanEndDate={props.subscribedPlanEndDate}
            modify={modify}
            setModify={setModify}
          />
          <UpdateSubscription
            open={openUpdateModal}
            setOpen={setOpenUpdateModal}
            action={action}
            planName={planName}
            planFeatures={planFeatures}
            newPlanPriceId={newPlanPriceId}
          />
          {plans ? (
            phoneView ? (
              <MobilePlansDescription Plans={plans} />
            ) : (
              <PlansDescription Plans={plans} />
            )
          ) : (
            <Grid container justifyContent={"center"} alignItems={"center"}>
              <Animation />
            </Grid>
          )}
        </>
      ) : (
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Animation />
        </Grid>
      )}
    </>
  );
}
const BlueTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`

width: fit-content !important;
        color: #101650;
        background-color: rgba(6, 116, 185, 0.3);
        font-size: 13px;
     
    `);
