import React from "react";
import sent from "../../Assets/Images/main/emailSent.png";
import { Grid } from "@mui/material";
import * as constFile from "../../pileCubesConst";

const emailApi = constFile.EMAIL_DEMO;
export default function SentEmail({ handleClose }) {
  return (
    <Grid item container direction={"column"} spacing={5.5} alignItems={'center'}>
      <Grid item>
        <img src={sent} alt="email" />
      </Grid>
      <Grid item container direction={"column"}>
        <div
          style={{
            marginLeft:'5%',
            fontFamily: "Muli",
            color: "#080707",
            fontSize: 13,
            lineHeight:1.5,
            width:'90%',
            textAlign:'left'
          }}
        >
          Pilecubes received your email and will connect you with a specialist
          from our team to walk you through our 1st of a kind global
          construction SaaS.
        </div>
      </Grid>
      <Grid item >
        <button
          className="submitButton1"
          onMouseOver={(e) => {
            e.target.style.backgroundColor = "#D8DA18";
            e.target.style.cursor = "pointer";
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = "#e1e319";
          }}
          style={{
            backgroundColor: "#e1e319",
            color: "#fff",
            borderRadius: 7,
            textTransform: "none",
            width:120
          }}
          onClick={handleClose}
        >
          Got it
        </button>
      </Grid>
    </Grid>
  );
}
