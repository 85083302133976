//Block Status Consts
export const ACTIVE_ID = 1;
export const ONHOLD_ID = 2;
export const INACTIVE_ID = 3;
export const ARCHIVED_ID = 4;
export const ACTIVE = "Active";
export const ONHOLD = "Onhold";
export const INACTIVE = "Inactive";
export const ARCHIVED = "Archived";

//Change Block Status
export const CHANGE_STATUS = "/block/change-status";

//storage block
export const CREATE_STORAGE = "/block/create-storage";
export const UPDATE_STORAGE = "/block/update-storage";
export const CREATE_OR_EDIR_DATA = "/block/storage-input-data";
export const UPLOAD_FILE_MACHINE = "/block/upload-catalog";
export const DELETE_FILE_MACHINE = "/block/delete-catalog";
export const STORAGE_LIST = "/block/storage-list";
export const GET_ZONES_STOCK = "/block/zone-stock-input-data";
export const TRANSFER_BUNDLES = "/block/transfer-bundle-data";
export const GET_ZONES_LINES = "/sales-orders/zone-sales-orders";
export const DELETE_BUNDLE = "/block/delete-zone-bundle";
export const UPDATE_BUNDLE_QUANTITY = "/block/update-product-bundle-quantity";

//Machine Block
export const CREATE_OR_EDIR_DATA_MACHINE = "/block/machine-input-data";
export const CREATE_MACHINE = "/block/create-machine";
export const UPDATE_MACHINE = "/block/update-machine";
export const MACHINES_LIST = "/block/machine-list";
export const CHANGE_MACHINE_PASSWORD = "/block/change-machine-password";
export const HOLDED_ORDERS_MACHINE = "/block/machine-combinations";
export const SET_BACKUP_MACHINE = "/block/set-backup-machine";

//handling Block
export const GET_HANDLING_INPUT_DATA = "/block/handling-input-data";
export const CREATE_HANDLING = "/block/create-handling";
export const UPDATE_HANDLING = "/block/update-handling";
export const HANDLING_LIST = "/block/handling-list";

export const CUTTING_MACHINE_ID = 1;
export const STIRRUP_MACHINE_ID = 4;
export const BENDING_2D_MACHINE_ID = 2;
export const BENDING_3D_MACHINE_ID = 3;

////////////////Factory//////////

//input-data
export const INPUT_DATA = "/factory/input-data";
//create
export const CREATE = "/factory/create";
//update
export const UPDATE = "/factory/update";
//list
export const FACTORY_LIST = "/factory/list";
//list_card
export const FACTORY_CARD_LIST = "/factory/cards-list";

///////////////////Bays////////////////

//list_card
export const BAYS_CARD_LIST = "/factory/bays-cards-list";
//list
export const BAYS_LIST = "/factory/bays-list";

///routes

//list
export const ROUTES_LIST = "/route/routes-list";
//inputData
export const ROUTES_INPUT_DATA = "/route/routes-input-data";
//create
export const CREATE_ROUTES = "/route/create-routes";
//update
export const UPDATE_ROUTES = "/route/update-routes";
//machineInputData
export const MACHINES_INPUT_DATA = "/route/machines-input-data";
//createMachineRoutes
export const CREATE_MACHINE_ROUTES = "/route/create-machine-routes";
//updateMachineRoutes
export const UPDATE_MACHINE_ROUTES = "/route/update-machine-routes";

//masterParameters
//getDataById
export const GET_BY_ID = "/factory-master-parameters/get-by-id";
//Create
export const CREATE_PARAMETERS = "/factory-master-parameters";
//Update
export const UPDATE_PARAMETERS = "/factory-master-parameters/update";
//Input Data
export const GET_PARAMETERS_INPUT_DATA =
  "/factory-master-parameters/get-input-data";

//Block types
export const StORAGE_BLOCK_ID = 1;
export const MACHINE_BLOCK_ID = 2;
export const HANDLING_BLOCK_ID = 3;

//Factory Layout
export const FACTORTY_LAYOUT_DATA = "/factory/layout-data";
export const FACTORTY_LAYOUT_CREATE = "/factory/create-layout";
export const FACTORTY_LAYOUT_UPDATE = "/factory/update-layout";
