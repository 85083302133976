//Label Types
export const RAW_MATERIAL_ID = "1";
export const REMENANT_ID = "2";
export const FINISHED_GOODS_ID = "3";
export const DELIVERY_NOTE_ID = "4";

// Label Api
//get input data
export const LABEL_INPUT_DATA = "/label/input-data";

//get List
export const PRODUCTION_LABEL_LIST = "/label/list";

//create label
export const CREATE_PRODUCTION_LABEL = "/label/create";

//Update Label
export const UPDATE_PRODUCTION_LABEL = "/label/update";

//get project label List
export const PROJECT_LABEL_LIST = "/label/project-label-list";

//save project label
export const SAVE_PROJECT_LABEL = "/label/save-tag-label";
