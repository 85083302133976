import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import SaasBack from "../../Assets/Images/Projects/bgTableProject.png";
import classes from "../../Assets/Styles/projects.module.css";
import { TextField, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Stack } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as constFile from "../../../projectConst";
import MenuPic from "../../Assets/Images/Table/menuPic.png";
import * as projectServices from "../../Services/projects-services.proxy";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

const getAssignedProjectsList = projectServices.getAssignedProjectsList;
const changeStatus = projectServices.changeProjectStatus;

const planType = localStorage.getItem("planId");
const companyPlan = localStorage.getItem("companyPlanId");

//Statuses
const ACTIVE = constFile.PACTIVE;
const ARCHIVED = constFile.PARCHIVED;

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};
export default function Tables(props) {
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );

  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [projectTable, setProjectTable] = useState([]);
  const [dataLength, setdataLength] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const [pageSize, setPageSize] = useState(25);
  const [modify, setModify] = useState(false);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  const listData = {
    Pagination: {
      PageSize: pageSize,
      PageNumber: pageNumber,
    },
    Search: searchText,
    SortList: [],
  };

  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    let apiTest = true;
    setLoadingData(true);
    getAssignedProjectsList(listData).then((x) => {
      if (apiTest) {
        setProjectTable(x.Projects);
        setdataLength(x.Total);
        setLoadingData(false);
      }
    });
    return () => {
      apiTest = false;
    };
  }, [searchText, pageNumber, pageSize, modify]);

  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowData, setRowData] = useState();
  const handleClick = async (event, params) => {
    setRowData(params);

    setAnchorEl(event.target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const columns = [
    {
      field: "action",
      headerName: "Actions",
      sortable: false,

      renderCell: (params) => {
        return (
          <img
            src={MenuPic}
            alt="menu"
            onClick={(e) => handleClick(e, params)}
          ></img>
        );
      },
    },
    {
      field: "Name",
      headerName: "Project Name",
      width: 200,
      renderCell: (params) => (
        <span
          style={{
            color: params.row.Status === "Archived" ? "grey" : "#0674b9",
          }}
        >
          {params.row?.Name}
        </span>
      ),
    },
    {
      field: "Country",
      headerName: "Project Country",
      width: 200,
      resizeable: true,
      renderCell: (params) => (
        <span
          style={{
            color: params.row.Status === "Archived" ? "grey" : "#0674b9",
          }}
        >
          {params.row?.Country}
        </span>
      ),
    },
    {
      field: "ClientName",
      headerName: "Client Name",
      width: 150,
      renderCell: (params) => (
        <span
          style={{
            color: params.row.Status === "Archived" ? "grey" : "#0674b9",
          }}
        >
          {params.row?.ClientName}
        </span>
      ),
    },
    {
      field: "StartDate",
      headerName: "Start Date",
      width: 150,
      renderCell: (params) => (
        <span
          style={{
            color: params.row.Status === "Archived" ? "grey" : "#0674b9",
          }}
        >
          {new Date(params.row?.StartDate).toLocaleDateString()}
        </span>
      ),
    },
    {
      field: "EndDate",
      headerName: "End Date",
      width: 150,
      renderCell: (params) => (
        <span
          style={{
            color: params.row.Status === "Archived" ? "grey" : "#0674b9",
          }}
        >
          {new Date(params.row?.EndDate).toLocaleDateString()}
        </span>
      ),
    },
    {
      field: "Status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => (
        <span
          style={{
            color: params.row.Status === "Archived" ? "grey" : "green",
          }}
        >
          {params.row?.Status}
        </span>
      ),
    },
    {
      field: "Type",
      headerName: "Project Type",
      width: 150,
      renderCell: (params) => (
        <span
          style={{
            color: params.row.Status === "Archived" ? "grey" : "#0674b9",
          }}
        >
          {params.row?.Type}
        </span>
      ),
    },
    {
      field: "Visibility",
      headerName: "Project Visibility",
      width: 150,
      renderCell: (params) => (
        <span
          style={{
            color: params.row.Status === "Archived" ? "grey" : "#0674b9",
          }}
        >
          {params.row?.Visibility}
        </span>
      ),
    },
    {
      field: "PhaseCount",
      headerName: "Phases No.",
      width: 100,
      renderCell: (params) => (
        <span
          style={{
            color: params.row.Status === "Archived" ? "grey" : "#0674b9",
          }}
        >
          {params.row?.PhaseCount}
        </span>
      ),
    },
  ];
  return (
    <div
      style={{
        minHeight: "87vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      {disabled ? (
        <Grid
          item
          container
          bgcolor={"rgba(225, 227, 25, 0.5)"}
          xs={12}
          marginTop={1}
          padding={1}
        >
          <Grid item md={4}></Grid>
          <Grid
            item
            md={6}
            style={{
              fontFamily: "Muli",
              fontSize: "17px",
              fontWeight: "bold",
              textAlign: "left",
              color: "#101650",
            }}
          >
            <Grid>
              {localStorage.getItem("IsSubscribedBefore") === "true"
                ? "Please subscribe before you start working"
                : planType === "Pro" &&
                  localStorage.getItem("isTeamMember") === "false"
                ? "Upgrade subscription required (Pro+)"
                : planType === "Core" &&
                  localStorage.getItem("isTeamMember") === "false"
                ? "Upgrade subscription required (Pro or Pro+)"
                : localStorage.getItem("isGuest") === "true" ||
                  !rowData?.row.IsCreator
                ? "You don't have permission to edit or archive/reactivate project"
                : localStorage.getItem("isTeamMember") === "true" &&
                  !UserPermissions?.find((x) => x.Name === "Project Manager") &&
                  !UserPermissions?.find((x) => x.Name === "Admin")
                ? "You don't have permission to create/edit or archive/reactivate a project"
                : localStorage.getItem("isTeamMember") === "true" &&
                  (UserPermissions?.find((x) => x.Name === "Project Manager") ||
                    UserPermissions?.find((x) => x.Name === "Admin"))
                ? companyPlan === "Core"
                  ? "Upgrade subscription for Company required (Pro or Pro+)"
                  : companyPlan === "Pro"
                  ? "Upgrade subscription for Company required (Pro+)"
                  : ""
                : ""}
            </Grid>
          </Grid>

          <Grid item md={2} container>
            {(localStorage.getItem("isTeamMember") ||
              localStorage.getItem("isGuest")) &&
            localStorage.getItem("IsSubscribedBefore") !== "true" ? (
              <Grid item xs={8}></Grid>
            ) : (
              <Grid
                item
                onClick={() => {
                  props.setSetupProfile(true);
                  props.setSetupMenu(true);
                  navigate("/saas/profileSettings");
                }}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
              >
                <button
                  style={{
                    padding: "5px",
                    borderRadius: "5px",
                    backgroundColor: "#101650",
                    fontFamily: "Muli",
                    fontSize: "13px",
                    textAlign: "center",
                    border: 0,
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    props.setSetupProfile(true);
                    props.setSetupMenu(true);
                    navigate("/saas/profileSettings");
                  }}
                >
                  Subscribe
                </button>
              </Grid>
            )}
            <Grid
              item
              paddingLeft={1}
              onClick={() => {
                setDisabled(false);
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
            >
              <CloseIcon fontSize="medium" />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <Grid container paddingTop={5} paddingLeft={2.5}>
        <Grid item xs={12} container alignItems="center" paddingLeft="5%">
          <Grid
            item
            xs={2}
            md={1.5}
            sx={{
              fontFamily: "Muli",
              fontSize: 18,
              fontWeight: "bold",
              lineHeight: 1.44,
              textAlign: "left",
              color: "#101650",
            }}
          >
            Assigned Projects
          </Grid>
          <Grid
            item
            xs={7}
            md={9}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>
          <Grid item xs={1.3} md={0.7} paddingLeft={1}>
            <button
              className={classes["back-button"]}
              onClick={() => {
                localStorage.removeItem("idp");
                localStorage.removeItem("proname");
                localStorage.removeItem("isProjectCreator");
                navigate(-1);
              }}
            >
              Go back
            </button>
          </Grid>
        </Grid>
        <Grid
          item
          md={12}
          container
          direction={"row-reverse"}
          paddingRight={{ md: 10, xs: 0 }}
        >
          <Box
            sx={{
              p: 0.5,
              pb: 0,
            }}
          >
            <TextField
              variant="standard"
              fullWidth
              onChange={inputHandler}
              placeholder="Search this list…"
              InputProps={{
                startAdornment: (
                  <SearchIcon
                    fontSize="small"
                    htmlColor={"#707070"}
                    style={{ paddingLeft: 5 }}
                  />
                ),
              }}
              sx={{
                fontFamily: "Muli",
                backgroundColor: "white",
                borderRadius: 1,
                width: {
                  xs: 1,
                  sm: "auto",
                },
                m: (theme) => theme.spacing(1, 0.5, 1.5),
                "& .MuiSvgIcon-root": {
                  mr: 0.5,
                },
                "& .MuiInput-underline:before": {
                  borderBottom: 0,
                  borderColor: "white",
                },
              }}
            />
          </Box>
        </Grid>

        <div
          style={{
            height: 500,
            width: "96.5%",
            fontFamily: "Muli",
            backgroundColor: "white",
            marginTop: 10,
            marginLeft: "2%",
          }}
        >
          <DataGrid
            initialState={{
              pagination: {
                pageSize: 25,
              },
            }}
            pagination
            paginationMode="server"
            rows={projectTable ? projectTable : []}
            columns={columns}
            headerHeight={40}
            density={"compact"}
            showColumnRightBorder
            sx={{
              [`& .${gridClasses.row}`]: {
                color: "#0674b9",
                overflow: "auto",
                bgcolor: (theme) =>
                  theme.palette.mode === "light" ? grey[0] : grey[500],
              },

              [`& .${gridClasses.columnHeader}`]: {
                bgcolor: "rgb(211 225 238)",
                color: "rgb(17 24 84)",
              },
              [`& .${gridClasses.columnHeaderTitle}`]: {
                fontFamily: "Muli",
                fontWeight: "bold",
              },

              [`& .${gridClasses.footerContainer}`]: {
                bgcolor: "rgb(216 235 247)",
                minHeight: "30px",
              },
              fontFamily: "Muli",
              fontSize: "13px",
            }}
            disableColumnFilter
            getRowId={(row) => row.Id}
            rowCount={dataLength}
            hideFooter={props.paging}
            onPageChange={(newPage) => {
              if (pageSize > dataLength) {
                setPageNumber(1);
              } else setPageNumber(newPage + 1);
            }}
            onPageSizeChange={(newPage) => {
              setPageSize(newPage);
            }}
            pageSize={pageSize}
            loading={loadingData}
            components={{
              NoRowsOverlay: () => (
                <Stack className={classes["EmptyMessage"]}>
                  No projects to display
                </Stack>
              ),
            }}
          />
        </div>

        <Menu
          id="more-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={(e) => {
            e.preventDefault();
          }}
          disableScrollLock
        >
          <MenuItem
            onClick={() => {
              navigate("/saas/projects/phases");
              localStorage.setItem("idp", rowData?.row.Id);
              localStorage.setItem("proname", rowData?.row.Name);
              localStorage.setItem("isProjectCreator", rowData?.row.IsCreator);
            }}
            style={{
              fontFamily: "Muli",
              fontSize: "13px",
              color: "#444",
              textAlign: "center",
              display: "block",
            }}
          >
            View Phases
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (localStorage.getItem("IsSubscribedBefore") === "true")
                console.log("");
              else navigate(`/saas/projects/dashboard?id=${rowData?.row.Id}`);
            }}
            style={{
              fontFamily: "Muli",
              fontSize: "13px",
              color: "#444",
              textAlign: "center",
              display: "block",
            }}
          >
            View Project Tree
          </MenuItem>
          <>
            {localStorage.getItem("isSubscribed") === "true" ||
            (localStorage.getItem("isTeamMember") === "true" &&
              (localStorage.getItem("companyPlanId")?.includes("Core") ||
                localStorage.getItem("companyPlanId")?.includes("Pro"))) ||
            localStorage.getItem("isGuest") === "true" ? (
              <MenuItem
                onClick={() => {
                  if (localStorage.getItem("IsSubscribedBefore") === "true")
                    console.log("");
                  else
                    navigate(
                      `/saas/projects/milesstones?id=${rowData?.row.Id}`
                    );
                }}
                style={{
                  fontFamily: "Muli",
                  fontSize: "13px",
                  color: "#444",
                  textAlign: "center",
                  display: "block",
                }}
              >
                View Milestone
              </MenuItem>
            ) : (
              ""
            )}
          </>
          {/* {
            localStorage.getItem("IsSubscribedBefore") === "true" ? <></>
              : <>
                {rowData?.row.Status === "Archived" ? (
                  <MenuItem
                    onClick={() => {
                      if (
                        localStorage.getItem("isGuest") === "true" ||
                        (localStorage.getItem("isTeamMember") === "true" &&
                          !UserPermissions?.find(
                            (x) => x.Name === "Project Manager"
                          ) &&
                          !UserPermissions?.find((x) => x.Name === "Admin")) ||
                        !rowData?.row.IsCreator
                      ) {
                        setDisabled(true);
                      } else {
                        changeStatus(rowData.id, ACTIVE);
                        setModify(!modify);
                        handleClose();
                      }
                    }}
                    style={{
                      fontFamily: "Muli",
                      fontSize: "13px",
                      color: "#444",
                      textAlign: "center",
                      display: "block",
                    }}
                  >
                    Re-Activate
                  </MenuItem>
                ) : (
                  <MenuItem
                    onClick={() => {
                      if (
                        localStorage.getItem("isGuest") === "true" ||
                        (localStorage.getItem("isTeamMember") === "true" &&
                          !UserPermissions?.find(
                            (x) => x.Name === "Project Manager"
                          ) &&
                          !UserPermissions?.find((x) => x.Name === "Admin")) ||
                        !rowData?.row.IsCreator
                      ) {
                        setDisabled(true);
                      } else {
                        changeStatus(rowData.id, ARCHIVED);
                        setModify(!modify);
                        handleClose();
                      }
                    }}
                    style={{
                      fontFamily: "Muli",
                      fontSize: "13px",
                      color: "#444",
                      textAlign: "center",
                      display: "block",
                    }}
                  >
                    Archive
                  </MenuItem>
                )}
              </>
          } */}
        </Menu>
      </Grid>
    </div>
  );
}
