import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Grid, Modal, TextField } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import classes from "./modal.module.css";
import * as permissionServices from "../../../Services/permissions-group.proxy";

const EDITUserRole = permissionServices.EDITUserRole;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "30%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  height: "90%",
  width: "85%",
  overflow: "scroll",
};

const PermissionsModal = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
    props.setOpenModal(false);
  };
  useEffect(() => {
    if (props.openModal) {
      handleOpen(true);
    }
  }, [props.openModal]);
  const handleClose = () => {
    setOpen(false);
    props.setUserRoleId();
    props.setpermissionList([]);
  };
  const ModalHeader = props.modalTitle;
  const [titleError, setTitleError] = useState(false);
  const [roleDescriptionError, setRoleDescriptionError] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const editTheRole = () => {
    setLoadingButton(true);
    EDITUserRole(
      props.userRoleId,
      props.modalTitle,
      props.userRoleDescription,
      props.permissionList
    ).then((x) => {
      props.setModify(!props.modify);
      setLoadingButton(false);
    });
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container direction={"column"}>
          <Grid sx={style} container>
            {props.permissionList?.length === 0 ? (
              <Grid
                container
                direction={"column"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <CircularProgress />
                <Grid
                  item
                  fontFamily={"Muli"}
                  fontSize={15}
                  fontWeight={"bold"}
                  color={"rgb(25, 118, 210)"}
                >
                  Loading ...
                </Grid>
              </Grid>
            ) : (
              <Grid
                item
                container
                direction="column"
                marginTop={2}
                marginLeft={5}
              >
                <Grid item container direction="column">
                  <Grid container>
                    <Grid
                      item
                      paddingTop={1}
                      className={classes["Modal-Title"]}
                      md={2}
                    >
                      {ModalHeader}
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row-reverse"}
                      md={9}
                      xs={9}
                    >
                      <CloseIcon
                        fontSize="large"
                        onClick={handleClose}
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                        }}
                        style={{ color: "#707070" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {props.showEdit ? (
                  <Grid container gap={2} direction={"row"}>
                    <Grid item md={3.5} xs={9} paddingTop={3}>
                      <TextField
                        fullWidth
                        label="Title"
                        value={props.modalTitle}
                        size="small"
                        className={classes["RoleTitleInput"]}
                        InputLabelProps={{
                          style: {
                            fontFamily: "Muli",
                            fontSize: "12px",
                          },
                        }}
                        inputProps={{
                          style: { height: 20, backgroundColor: "#fff" },
                          maxLength: 50,
                        }}
                        error={titleError}
                        helperText={titleError ? "Enter a title" : ""}
                        onBlur={() => {
                          if (!props.modalTitle) {
                            setTitleError(true);
                          }
                        }}
                        onChange={(e) => {
                          props.setModalTitle(e.target.value);
                          setTitleError(false);
                        }}
                      />
                    </Grid>
                    <Grid item sm={7}></Grid>
                    <Grid item md={4.5} xs={11}>
                      <TextField
                        fullWidth
                        label="Description"
                        size="large"
                        value={props.userRoleDescription}
                        className={classes["RoleDescInput"]}
                        multiline
                        InputLabelProps={{
                          style: {
                            fontFamily: "Muli",
                            fontSize: "12px",
                          },
                        }}
                        inputProps={{
                          style: { height: 75 },
                          maxLength: 1000,
                        }}
                        error={roleDescriptionError}
                        helperText={
                          roleDescriptionError ? "Enter a description " : ""
                        }
                        sx={{ backgroundColor: "#fff" }}
                        onBlur={() => {
                          if (!props.userRoleDescription) {
                            setRoleDescriptionError(true);
                          }
                        }}
                        onChange={(e) => {
                          props.setuserRoleDescription(e.target.value);
                          setRoleDescriptionError(false);
                        }}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid
                  item
                  className={classes["Permission-title"]}
                  fontSize={18}
                  paddingTop={3}
                >
                  Permissions
                </Grid>
                <Grid item container gap={1.1} direction={"row"} paddingTop={2}>
                  {props.permissionList.map((permission) => (
                    <Grid
                      item
                      container
                      lg={2.9}
                      md={3.5}
                      sm={5.5}
                      alignContent={"flex-start"}
                    >
                      <Grid
                        item
                        className={classes["Action-title"]}
                        key={permission.Id}
                      >
                        {permission.Name}
                      </Grid>
                      {permission.Actions.map((action) => (
                        <Grid
                          item
                          container
                          direction={"row"}
                          gap={1}
                          xs={12}
                          key={action.Id}
                        >
                          <Grid
                            item
                            onClick={(e) => {
                              if (props.showEdit || props.showAdd) {
                                if (action.Name === "All") {
                                  if (action.IsSelected === false) {
                                    permission.Actions.map(
                                      (action) => (action.IsSelected = true)
                                    );
                                  } else {
                                    permission.Actions.map(
                                      (action) => (action.IsSelected = false)
                                    );
                                  }
                                } else if (action.IsSelected === true) {
                                  action.IsSelected = false;
                                } else if (action.IsSelected === false) {
                                  action.IsSelected = true;
                                  permission.Actions.map((action) => {
                                    if (action.Name === "View") {
                                      action.IsSelected = true;
                                    }
                                  });
                                }

                                forceUpdate();
                              }
                            }}
                            className={
                              action.IsSelected
                                ? classes["FullChecked"]
                                : classes["Not-checked"]
                            }
                            marginTop={0.4}
                          ></Grid>
                          <Grid item className={classes["Action-permission"]}>
                            {action.Name}
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  ))}
                  {props.showEdit || props.showAdd ? (
                    <Grid
                      container
                      md={12}
                      item
                      paddingRight={10}
                      direction={"row-reverse"}
                    >
                      <Grid item>
                        <button
                          className={classes.SaveModal}
                          onMouseOver={(e) => {
                            e.target.style.cursor = "pointer";
                            e.target.style.backgroundColor = "#242B64";
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.backgroundColor = "#0b5cab";
                          }}
                          style={{
                            backgroundColor: "#0b5cab",
                            fontFamily: "Muli",
                            textTransform: "none",
                          }}
                          onClick={() => {
                            if (props.showEdit) editTheRole();
                            setTimeout(() => {
                              setOpen(false);
                            }, [2500]);
                          }}
                        >
                          {loadingButton ? "Saving..." : "Save"}
                        </button>
                      </Grid>
                      <Grid item paddingRight={2}>
                        <button
                          className={classes.exitModal}
                          onMouseOver={(e) => {
                            e.target.style.cursor = "pointer";
                            e.target.style.backgroundColor = "#f5f5f5";
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.backgroundColor = "#fff";
                          }}
                          onClick={() => {
                            handleClose();
                          }}
                        >
                          Cancel
                        </button>
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

export default PermissionsModal;
