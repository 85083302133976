import React from "react";
import TruckIcon from "../../../Assets/Images/Loading/Truck.png";
import DriverIcon from "../../../Assets/Images/Loading/Driver.png";
import TrailerIcon from "../../../Assets/Images/Loading/trailer.png";
import { Grid } from "@mui/material";
import classes from "../../../Assets/Styles/Transport.module.css";

const AdminViewTransport = () => {
  return (
    <Grid
      item
      container
      alignItems={"center"}
      justifyContent={"center"}
      gap={4}
      paddingTop={8}
    >
      <Grid item>
        <img src={TruckIcon} alt={"img"} />
      </Grid>
      <Grid item>
        <img src={TrailerIcon} alt={"img"} />
      </Grid>
      <Grid item>
        <img src={DriverIcon} alt={"img"} />
      </Grid>
      <Grid item xs={12} className={classes["Manage-trucks-and-trailers"]}>
        Manage trucks, trailers and drivers.
      </Grid>
    </Grid>
  );
};

export default AdminViewTransport;
