import React, { useEffect, useState, useMemo } from "react";
import { Grid, Button } from "@mui/material";
import classes from "../../../Assets/Styles/Delivery.module.css";
import SaasBack from "../../../Assets/Images/SaaSbackground.png";
import UploadIcon from "../../../Assets/Images/Delivery/UploadIcon.png";
import CameraIcon from "../../../Assets/Images/Delivery/cameraIcon.png";
import FolderIcon from "../../../Assets/Images/Delivery/folderIcon.png";
import { useLocation } from "react-router-dom";
import DeliveryNoteForm from "./DeliveryNoteForm";
import CloseIcon from "@mui/icons-material/Close";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ConfirmModal from "./ConfirmModal";
import * as DeliveryServices from "../../../Services/delivery-services.proxy";

const confirmDelivery = DeliveryServices.confirmDelivery;
const uploadingFile = DeliveryServices.uploadFile;
const deletingFile = DeliveryServices.deleteFile;
const getDeliveryNotePreview = DeliveryServices.getDeliveryNotePreview;

const DeliveryResponse = (props) => {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);
  useEffect(() => {
    props.setNaved(false);
    props.setTerms(false);
  }, [props.setNaved]);
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const [signedFile, setSignedFile] = useState(null);
  const [signedFileId, setSignedFileId] = useState(null);
  const [previousSignedFileId, setPreviousSignedFileId] = useState(null);

  const [openModal, setOpenModal] = useState(false);
  const [confirmingLoading, setConfirmingLoading] = useState(false);

  const [deliveryNoteDetails, setDeliveryNoteDetails] = useState(null);
  const [modify,setModify] = useState(false);

  const query = useQuery();

  const deliveryNoteId = query.get("id");
  const userId = query.get("user");

  useEffect(() => {
    if (deliveryNoteId && userId)
      getDeliveryNotePreview(deliveryNoteId, userId).then((x) => {
        setDeliveryNoteDetails(x);
      });
  }, [deliveryNoteId, userId,modify]);

  const handleFileChange = (e) => {
    if (previousSignedFileId) {
      deletingFile(previousSignedFileId).then((x) => {});
    }
    if (e.target.files[0]) {
      if (signedFileId) setPreviousSignedFileId(signedFileId);

      uploadingFile(e.target.files[0]).then((x) => {
        setSignedFile(x);
        setSignedFileId(x.FileId);
      });
    }
  };

  const confirmingDeliveryNote = () => {
    setConfirmingLoading(true);
    confirmDelivery(deliveryNoteId, signedFileId).then((x) => {
      setConfirmingLoading(false);

      setOpenModal(false);
      setModify(!modify)
    });
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
        objectFit: "cover",
      }}
    >
      <>
        {deliveryNoteDetails?.DeliveryNote?.AcknowledgementStatus === "Confirmed" ? (
          <Grid
            item
            fontFamily={"Muli"}
            fontSize={15}
            fontWeight={"bold"}
            color={"#ea001e"}
            paddingTop={5}
          >
            Delivery note Already confirmed.
          </Grid>
        ) : (
          <Grid
            container
            direction={"row"}
            paddingTop={{ md: 3, xs: 5 }}
            paddingLeft={2}
          >
            <Grid item xs={5.5} className={classes["ResTitle"]}>
              Delivery Note
            </Grid>
            <Grid item xs={6} container direction={"row-reverse"}>
              <Grid item>
                <Button
                  fullWidth
                  component="label"
                  sx={{
                    borderRadius: "5px",
                    border: "solid 1px #e9e7e7",
                    backgroundColor: "#fff",
                    fontFamily: "Muli",
                    fontSize: "10px",
                    textAlign: "left",
                    color: "#707070",
                    textTransform: "none",
                  }}
                >
                  <img src={UploadIcon} width={15} alt={"img"} />
                  &nbsp; Signed Delivery Note
                  <input
                    type="file"
                    hidden
                    onChange={(e) => {
                      handleFileChange(e);
                    }}
                  />
                </Button>
              </Grid>
            </Grid>
            {signedFile ? (
              <>
                <Grid
                  item
                  container
                  marginTop={2}
                  paddingRight={3}
                  xs={12}
                  direction="row"
                  spacing={1.5}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={4}
                    container
                    direction="row"
                    columnGap={0.5}
                    backgroundColor="#fff"
                    borderRadius="5px"
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                    onClick={() => {
                      window.open(signedFile.URL);
                    }}
                  >
                    <Grid item>
                      <CameraAltIcon fontSize="small" />
                    </Grid>
                    <Grid item> {signedFile.DisplayName.slice(0, 5)} ...</Grid>
                  </Grid>

                  <Grid
                    item
                    alignSelf="center"
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                    onClick={() => {
                      setSignedFile(null);
                      setSignedFileId(null);
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  marginTop={2}
                  paddingRight={3}
                  xs={12}
                  spacing={1.5}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <button
                    className={classes["save-button"]}
                    onClick={() => {
                      setOpenModal(true);
                    }}
                  >
                    Confirm
                  </button>
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Grid>
        )}
      </>
      <Grid item container xs={11.5} paddingLeft={2}>
        {deliveryNoteDetails ? (
          <DeliveryNoteForm deliveryDetails={deliveryNoteDetails} />
        ) : (
          <></>
        )}
      </Grid>
      <Grid item>
        <ConfirmModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          confirmingDeliveryNote={confirmingDeliveryNote}
          confirmingLoading={confirmingLoading}
        />
      </Grid>
    </div>
  );
};

export default DeliveryResponse;
