import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import SaasBack from "../../../Assets/Images/SaaSbackground.png";
import MachineBlockTable from "./MachineBlockTable";
import StorageBlockTable from "./StorageBlockTable";
import HandlingBlockTable from "./HandlingBlockTable";
import { Grid } from "@mui/material";
import classes from "../../../Assets/Styles/factoryTables.module.css";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { TextField } from "@mui/material";
import { styled } from "@mui/system";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import handlingBlockIcon from "../../../Assets/Images/Factory/handlingBlock.png";
import handlingBlockIconYellow from "../../../Assets/Images/Factory/handlingBlockYellow.png";
import machineBlockIcon from "../../../Assets/Images/Factory/machineBlock.png";
import machineBlockIconYellow from "../../../Assets/Images/Factory/machineBlockYellow.png";
import storageBlockIcon from "../../../Assets/Images/Factory/storageBlock.png";
import storageBlockIconYellow from "../../../Assets/Images/Factory/storageBlockYellow.png";
import ChangeStatusesModal from "./ChangeStatusModal";
import * as factoryServices from "../../../Services/factories-services.proxy";
import { useSelector } from "react-redux";
import * as permissionFunctions from "../../../../ReusableFunctions";

const CheckIfPermitted = permissionFunctions.CheckIfPermitted;
const getMachineBlocksList = factoryServices.getMachinesList;
const getStorageBlocksList = factoryServices.getStorageList;
const getHandlingBlocksList = factoryServices.getHandlingList;
const changeBlockStatus = factoryServices.changeBlockStatus;

function BlockTableView(props) {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const [storageBlocksTableData, setStorageBlocksTableData] = useState([]);
  const [machinesBlocksTableData, setMachinesBlocksTableData] = useState([]);
  const [handlingBlocksTableData, setHandlingBlocksTableData] = useState([]);

  const [dataLength, setdataLength] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);

  const [categoryFilter, setCategoryFilter] = useState(0);
  const [machineCategoriesList, setMachineCategoriesList] = useState([]);

  const [subCategoryFilter, setSubCategoryFilter] = useState(0);
  const [machineSubCategoriesList, setMachineSubCategoriesList] = useState([]);

  const [blockStatusFilter, setBlockStatusFilter] = useState(0);
  const [statuses, setStatuses] = useState([]);

  const [loadingData, setLoadingData] = useState(false);
  const [modify, setModify] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const [blockId, setBlockId] = useState();
  const [rowData, setRowData] = useState(null);
  const [updatingLoading, setUpdatingLoading] = useState(false);

  const [storageCategories, setStorageCategories] = useState([]);

  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };
  const location = useLocation();
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();

  const [bayNb, setBayNb] = useState(query.get("BayNb"));
  const [bayId, setBayId] = useState(query.get("BayId"));
  const [factoryId, setFactoryId] = useState(query.get("FactoryId"));
  const [blockType, setBlockType] = useState(
    query.get("blockType") ? parseInt(query.get("blockType")) : 1
  );

  const [displayYellowImg, setDisplayYellowImg] = useState(
    query.get("blockType") ? parseInt(query.get("blockType")) : 1
  );
  const [tagsOnMachine, setTagsOnMachine] = useState([]);

  const listData = {
    Pagination: {
      PageSize: pageSize,
      PageNumber: pageNumber,
    },
    Search: searchText,
    SortList: [],
    BayId: bayId,
    CategoryId: categoryFilter === 0 ? null : categoryFilter,
    SubCategoryId: subCategoryFilter === 0 ? null : subCategoryFilter,
    FactoryId: factoryId,
    StatusId: blockStatusFilter === 0 ? null : blockStatusFilter,
  };

  const changeStatus = (BlockId, StatusId) => {
    changeBlockStatus(BlockId, StatusId).then((x) => {
      if (x.status === 404 || x.status === 409 || x.status === 500) {
      } else {
        if (x?.length > 0) {
          setTagsOnMachine(x);
        } else {
          setUpdatingLoading(false);
          setOpenModal(false);
          setModify(!modify);
        }
      }
    });
  };

  useEffect(() => {
    let apiTest = true;
    setLoadingData(true);

    // if((query.get("blockType"))){
    //   setBlockType(query.get("blockType"));
    //   setDisplayYellowImg(query.get("blockType"));
    // }

    if (blockType === 1) {
      getStorageBlocksList(listData).then((x) => {
        if (apiTest) {
          setStorageBlocksTableData(x.Storages);
          setStatuses(x.Statuses);
          setdataLength(x.Total);
          setStorageCategories(x.Catgories);
          setLoadingData(false);
        }
      });
    } else if (blockType === 2) {
      getMachineBlocksList(listData).then((x) => {
        if (apiTest) {
          setMachinesBlocksTableData(x.Machines);
          setStatuses(x.Statuses);
          setdataLength(x.Total);
          setLoadingData(false);
          setMachineCategoriesList(x.Categories);
          setMachineSubCategoriesList(x.SubCategories);
        }
      });
    } else {
      getHandlingBlocksList(listData).then((x) => {
        if (apiTest) {
          setHandlingBlocksTableData(x.Handlings);
          setStatuses(x.Statuses);
          setdataLength(x.Total);
          setLoadingData(false);
        }
      });
    }

    return () => {
      apiTest = false;
    };
  }, [
    pageSize,
    pageNumber,
    searchText,
    blockType,
    categoryFilter,
    subCategoryFilter,
    modify,
    blockStatusFilter,
  ]);

  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  const [disabledAddHb, setDisabledAddHb] = useState(false);
  const [disabledAddMB, setDisabledAddMB] = useState(false);
  const [disabledAddSB, setDisabledAddSB] = useState(false);
  const [disabledViewHb, setDisabledViewHb] = useState(false);
  const [disabledViewMB, setDisabledViewMB] = useState(false);
  const [disabledViewSB, setDisabledViewSB] = useState(false);
  const [disabledChangeStatusHB, setDisabledChangeStatusHB] = useState(false);
  const [disabledChangeStatusMB, setDisabledChangeStatusMB] = useState(false);
  const [disabledChangeStatusSB, setDisabledChangeStatusSB] = useState(false);
  const [disabledChangePasswordMB, setDisabledChangePasswordMB] =
    useState(false);

  useEffect(() => {
    if (UserPermissions) {
      CheckIfPermitted(
        UserPermissions,
        "Handling Block",
        "Create",
        setDisabledAddHb
      );
      CheckIfPermitted(
        UserPermissions,
        "Machine Block",
        "Create",
        setDisabledAddMB
      );
      CheckIfPermitted(
        UserPermissions,
        "Storage Block",
        "Create",
        setDisabledAddSB
      );
      CheckIfPermitted(
        UserPermissions,
        "Handling Block",
        "Update Status",
        setDisabledChangeStatusHB
      );
      CheckIfPermitted(
        UserPermissions,
        "Machine Block",
        "Update Status",
        setDisabledChangeStatusMB
      );
      CheckIfPermitted(
        UserPermissions,
        "Storage Block",
        "Update Status",
        setDisabledChangeStatusSB
      );
      CheckIfPermitted(
        UserPermissions,
        "Machine Block",
        "Change Password",
        setDisabledChangePasswordMB
      );
      CheckIfPermitted(
        UserPermissions,
        "Handling Block",
        "View",
        setDisabledViewHb
      );
      CheckIfPermitted(
        UserPermissions,
        "Machine Block",
        "View",
        setDisabledViewMB
      );
      CheckIfPermitted(
        UserPermissions,
        "Storage Block",
        "View",
        setDisabledViewSB
      );
    }
  }, [UserPermissions]);
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
        objectFit: "cover",
      }}
    >
      <Grid container padding={{ xs: 1.5, sm: 3.5 }} alignItems="center">
        <Grid item xs={12} sm={3.8} md={3} className={classes["title"]}>
          {localStorage.getItem("FactoryName")} <br /> Bay {bayNb} -{" "}
          {blockType === 1
            ? "Storage Blocks"
            : blockType === 2
            ? "Machine Blocks"
            : "Handling Blocks"}
        </Grid>

        <Grid item xs={4} sm={2} md={1}>
          <img
            src={
              displayYellowImg === 1 || blockType === 1
                ? storageBlockIconYellow
                : storageBlockIcon
            }
            width="90px"
            height="89px"
            onMouseOver={(e) => {
              if (blockType !== 1) {
                setDisplayYellowImg(1);
                e.target.style.cursor = "pointer";
              }
            }}
            onMouseOut={(e) => {
              if (blockType !== 1) {
                setDisplayYellowImg(0);
              }
            }}
            onClick={(e) => {
              if (!disabledViewSB) {
                setBlockType(1);
                setSearchText("");
              }
            }}
          />
        </Grid>

        <Grid item xs={4} sm={2} md={1}>
          <img
            src={
              displayYellowImg === 2 || blockType === 2
                ? machineBlockIconYellow
                : machineBlockIcon
            }
            width="90px"
            height="89px"
            onMouseOver={(e) => {
              if (blockType !== 2) {
                setDisplayYellowImg(2);
                e.target.style.cursor = "pointer";
              }
            }}
            onMouseOut={(e) => {
              if (blockType !== 2) {
                setDisplayYellowImg(0);
              }
            }}
            onClick={(e) => {
              if (!disabledViewMB) {
                setBlockType(2);
                setSearchText("");
              }
            }}
          />
        </Grid>
        <Grid item xs={4} sm={2} md={5.9}>
          <img
            src={
              displayYellowImg === 3 || blockType === 3
                ? handlingBlockIconYellow
                : handlingBlockIcon
            }
            width="90px"
            height="89px"
            onMouseOver={(e) => {
              if (blockType !== 3) {
                setDisplayYellowImg(3);
                e.target.style.cursor = "pointer";
              }
            }}
            onMouseOut={(e) => {
              if (blockType !== 3) {
                setDisplayYellowImg(0);
              }
            }}
            onClick={(e) => {
              if (!disabledAddHb) {
                setBlockType(3);
                setSearchText("");
              }
            }}
          />
        </Grid>

        <Grid item xs={2} sm={1.2} md={0.6} marginLeft={{ xs: 32.5, sm: 0 }}>
          <button
            className={classes["back-button"]}
            onClick={() => {
              navigate(-1);
            }}
          >
            Go Back
          </button>
        </Grid>
        <Grid item xs={2} sm={1} md={0.5}>
          <button
            className={classes["add-button"]}
            onClick={() => {
              if (blockType === 1 && !disabledAddSB)
                navigate(
                  `/saas/factory/bay/storageBlock?id=${bayId}&bayNb=${bayNb}&FactoryId=${factoryId}&blockType=${blockType}`
                );
              else if (blockType === 2 && !disabledAddMB)
                navigate(
                  `/saas/factory/bay/machineBlock?id=${bayId}&bayNb=${bayNb}&FactoryId=${factoryId}&blockType=${blockType}`
                );
              else if (blockType === 3 && !disabledAddHb)
                navigate(
                  `/saas/factory/bay/handlingBlock?id=${bayId}&bayNb=${bayNb}&FactoryId=${factoryId}&blockType=${blockType}`
                );
            }}
          >
            Add
          </button>
        </Grid>

        <Grid item container direction="row-reverse" spacing={1}>
          <Grid item>
            <TextField
              value={searchText}
              variant="standard"
              fullWidth
              onChange={inputHandler}
              placeholder="Search this list…"
              InputProps={{
                startAdornment: (
                  <SearchIcon
                    fontSize="small"
                    htmlColor={"#707070"}
                    style={{ paddingLeft: 5 }}
                  />
                ),
              }}
              sx={{
                fontFamily: "Muli",
                backgroundColor: "white",
                borderRadius: 1,
                width: {
                  xs: 1,
                  sm: "auto",
                },
                m: (theme) => theme.spacing(1, 0.5, 1.5),
                "& .MuiSvgIcon-root": {
                  mr: 0.5,
                },
                "& .MuiInput-underline:before": {
                  borderBottom: 0,
                  borderColor: "white",
                },
              }}
            />
          </Grid>
          <Grid item>
            <CustomSelect
              value={blockStatusFilter}
              onChange={(option) => {
                setBlockStatusFilter(option);
              }}
            >
              <StyledOption value={0} disabled>
                Status
              </StyledOption>
              {statuses.map((f) => (
                <StyledOption key={f.Id} value={f.Id}>
                  {f.Name}
                </StyledOption>
              ))}
            </CustomSelect>
          </Grid>
          {blockType === 2 ? (
            <>
              <Grid item>
                <CustomSelect
                  value={subCategoryFilter}
                  onChange={(option) => {
                    setSubCategoryFilter(option);
                  }}
                >
                  <StyledOption value={0} disabled>
                    Sub Category
                  </StyledOption>
                  {machineSubCategoriesList.map((f) => (
                    <StyledOption key={f.Id} value={f.Id}>
                      {f.Name}
                    </StyledOption>
                  ))}
                </CustomSelect>
              </Grid>
              <Grid item>
                <CustomSelect
                  value={categoryFilter}
                  onChange={(option) => {
                    setCategoryFilter(option);
                  }}
                >
                  <StyledOption value={0} disabled>
                    Category
                  </StyledOption>
                  {machineCategoriesList.map((f) => (
                    <StyledOption key={f.Id} value={f.Id}>
                      {f.Name}
                    </StyledOption>
                  ))}
                </CustomSelect>
              </Grid>
              {categoryFilter || subCategoryFilter || blockStatusFilter ? (
                <Grid item marginTop={0.6}>
                  <button
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                    onClick={() => {
                      setCategoryFilter(0);
                      setSubCategoryFilter(0);
                      setBlockStatusFilter(0);
                    }}
                    className={classes["Rectangle-1224"]}
                  >
                    Clear filters
                  </button>
                </Grid>
              ) : (
                ""
              )}
            </>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item container xs={12}>
          {blockType === 1 ? (
            <StorageBlockTable
              storageBlocksTableData={storageBlocksTableData}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              pageSize={pageSize}
              loadingData={loadingData}
              modify={modify}
              setModify={setModify}
              changeStatus={changeStatus}
              openModal={openModal}
              setOpenModal={setOpenModal}
              setBlockId={setBlockId}
              blockId={blockId}
              rowData={rowData}
              setRowData={setRowData}
              factoryId={factoryId}
              storageCategories={storageCategories}
              disabledChangeStatusSB={disabledChangeStatusSB}
            />
          ) : blockType === 2 ? (
            <MachineBlockTable
              machinesBlocksTableData={machinesBlocksTableData}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              pageSize={pageSize}
              loadingData={loadingData}
              setModify={setModify}
              changeStatus={changeStatus}
              openModal={openModal}
              setOpenModal={setOpenModal}
              setBlockId={setBlockId}
              rowData={rowData}
              setRowData={setRowData}
              factoryId={factoryId}
              disabledChangeStatusMB={disabledChangeStatusMB}
              disabledChangePasswordMB={disabledChangePasswordMB}
            />
          ) : (
            <HandlingBlockTable
              handlingBlocksTableData={handlingBlocksTableData}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              pageSize={pageSize}
              loadingData={loadingData}
              setModify={setModify}
              changeStatus={changeStatus}
              openModal={openModal}
              setOpenModal={setOpenModal}
              setBlockId={setBlockId}
              rowData={rowData}
              setRowData={setRowData}
              factoryId={factoryId}
              disabledChangeStatusHB={disabledChangeStatusHB}
            />
          )}
        </Grid>
      </Grid>
      <Grid item>
        <ChangeStatusesModal
          statuses={statuses}
          changeStatus={changeStatus}
          openModal={openModal}
          setOpenModal={setOpenModal}
          modify={modify}
          setModify={setModify}
          blockId={blockId}
          setBlockId={setBlockId}
          rowData={rowData}
          setRowData={setRowData}
          updatingLoading={updatingLoading}
          blockType={blockType}
          setUpdatingLoading={setUpdatingLoading}
          tagsOnMachine={tagsOnMachine}
          navigate={navigate}
          factoryId={factoryId}
        />
      </Grid>
    </div>
  );
}
const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: Muli;
  font-size: 10px;
  box-sizing: border-box;
  height: 35px;
  width: 11rem;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  margin-top: 0.7em;
  padding: 10px;
  text-align: left;
  line-height: 1.5;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};

  &:hover {
    background: ${theme.palette.mode === "dark" ? "" : grey[100]};
    border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  cursor: pointer;
  `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
  font-family: "Muli";
  font-size: 0.6rem;
  box-sizing: border-box;
  padding: 5px;
  width: 11rem;
  height:130px;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  overflow: scroll;
  outline: 0px;
  
  `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  cursor: pointer;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }
  `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});

export default BlockTableView;
