import React, { useEffect, useState, useMemo } from "react";
import {
  Grid,
  Button,
  Modal,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
} from "@mui/material";
import classes from "../../../Assets/Styles/Delivery.module.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

function ShipmentModal(props) {
  const [isDisabled,setIsDisabled] = useState(true);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    bottom: "30%",
    transform: "translate(-50%, -50%)",
    height: props.suggest ? "48%" : "25%",
    width: "40%",
    height: "65%",
    borderRadius: "15px",
    boxShadow: " 0 3px 6px 0 rgba(0, 0, 0, 0.46)",
    border: "solid 1px #0674b9",
    backgroundColor: "#fff",
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    props.setOpenModal(true);
  };

  const handleClose = () => {
    props.setShipmentId(null);
    props.setDate(null);
    props.setShipmentNb(null);
    props.setTimeSlotCode(null);
    props.setTruckId(null);
    props.setDriverId(null);
    props.setTrailerId(null);
    props.setLoads([]);
    props.setSalesOrdersCodes([]);

    props.setOpenModal(false);
    setOpen(false);
  };

  useEffect(() => {
    if (props.openModal === true) handleOpen();
    if (props.openModal === false) handleClose();
  }, [props.openModal]);

  useEffect(()=>{
    if(props.timeSlotCode === null || props.truckId === null || props.driverId === null || props.trailerId === null) setIsDisabled(true)
    else setIsDisabled(false);
  },[props.timeSlotCode,props.truckId,props.driverId,props.trailerId])

  return (
    <Modal
      disableScrollLock
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Backdrop
          sx={{
            color: "#fff",
            backgroundColor: "transparent",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            display: "flex",
            flexDirection: "column",
          }}
          open={props.modalLoading}
        >
          <CircularProgress />
          <Grid
            item
            fontFamily={"Muli"}
            fontSize={15}
            fontWeight={"bold"}
            color={"rgb(25, 118, 210)"}
          >
            Loading Data...
          </Grid>
        </Backdrop>
        <Grid container padding={2} rowGap={1.5}>
          <Grid item className={classes["ShipmentFormTitle"]} xs={12}>
            Shipment Details
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              border: "solid 0.1px #707070",
              backgroundColor: "#707070",
              height: 0,
            }}
          ></Grid>
          <Grid item container direction="row" spacing={2} xs={12} padding={2}>
            <Grid item container direction="column" xs={6}>
              <Grid item>Date</Grid>
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    value={props.date}
                    onChange={(newValue) => {
                      props.setDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField size={"small"} {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid item container direction="column" xs={6}>
              <Grid item>Time Slot Code</Grid>
              <Grid item width="100%">
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={props.timeSlotCode}
                    MenuProps={{
                      disableScrollLock: true,
                      PaperProps: {
                        sx: {
                          maxHeight: {
                            xs: "130px",
                          },
                        },
                      },
                    }}
                    default={0}
                    fullWidth
                    sx={{
                      height: "34px",
                      fontFamily: "Muli",
                      fontSize: "13px",
                    }}
                    onChange={(e) => {
                      props.setTimeSlotCode(e.target.value);
                    }}
                  >
                    <MenuItem value={0} key={0} disabled>
                      Time Slot
                    </MenuItem>
                    {props.timeSlots?.map((s) => (
                      <MenuItem
                        value={s.Id}
                        key={s.Id}
                        style={{
                          display: "block",
                          fontFamily: "Muli",
                          fontSize: "13px",
                        }}
                      >
                        {s.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container direction="column" xs={6}>
              <Grid item>Shipment Number</Grid>
              <Grid item className={classes["Rectangle"]}>
                {props.shipmentNb}
              </Grid>
            </Grid>
            <Grid item container direction="column" xs={6}>
              <Grid item>Truck Name {props.trucksList.length === 0 ? <span style={{fontFamily:"Muli",fontSize:"9px",color:"red"}}>No Available Loads - Create a new load</span>:""}</Grid>
              <Grid item width="100%">
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={props.truckId}
                    MenuProps={{
                      disableScrollLock: true,
                      PaperProps: {
                        sx: {
                          maxHeight: {
                            xs: "130px",
                          },
                        },
                      },
                    }}
                    default={0}
                    fullWidth
                    sx={{
                      height: "34px",
                      fontFamily: "Muli",
                      fontSize: "13px",
                    }}
                    onChange={(e) => {
                      props.setTruckId(e.target.value);

                      let truckObject = props.trucksList?.find(
                        (t) => t.Id === e.target.value
                      );

                      props.setDriverId(truckObject.DefaultDriverData?.Id);
                      props.setTrailerId(truckObject.DefaultTrailerData?.Id);
                      props.setLoads(truckObject.DefaultTrailerData?.Loads);
                      props.setSalesOrdersCodes(
                        truckObject.DefaultTrailerData?.SalesOrderCodes
                      );
                    }}
                  >
                    <MenuItem value={0} key={0} disabled>
                      Truck Name
                    </MenuItem>
                    {props.trucksList?.map((t) => (
                      <MenuItem
                        key={t.Id}
                        value={t.Id}
                        style={{
                          display: "block",
                          fontFamily: "Muli",
                          fontSize: "13px",
                        }}
                      >
                        {t.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item container direction="column" xs={6}>
              <Grid item>Driver Name</Grid>
              <Grid item width="100%">
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={props.driverId}
                    MenuProps={{
                      disableScrollLock: true,
                      PaperProps: {
                        sx: {
                          maxHeight: {
                            xs: "130px",
                          },
                        },
                      },
                    }}
                    default={0}
                    fullWidth
                    sx={{
                      height: "34px",
                      fontFamily: "Muli",
                      fontSize: "13px",
                    }}
                    onChange={(e) => {
                      props.setDriverId(e.target.value);
                    }}
                  >
                    <MenuItem value={0} key={0} disabled>
                      Driver Name
                    </MenuItem>
                    {props.driversList?.map((d) => (
                      <MenuItem
                        value={d.Id}
                        key={d.Id}
                        style={{
                          display: "block",
                          fontFamily: "Muli",
                          fontSize: "13px",
                        }}
                      >
                        {d.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item container direction="column" xs={6}>
              <Grid item>Trailer Name</Grid>
              <Grid item width="100%">
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={props.trailerId}
                    MenuProps={{
                      disableScrollLock: true,
                      PaperProps: {
                        sx: {
                          maxHeight: {
                            xs: "130px",
                          },
                        },
                      },
                    }}
                    default={0}
                    fullWidth
                    sx={{
                      height: "34px",
                      fontFamily: "Muli",
                      fontSize: "13px",
                    }}
                    onChange={(e) => {
                      props.setTrailerId(e.target.value);
                      let trailerObject = props.trailersList?.find(
                        (t) => t.Id === e.target.value
                      );
                      props.setLoads(trailerObject.Loads);
                      props.setSalesOrdersCodes(trailerObject.SalesOrderCodes);
                    }}
                  >
                    <MenuItem value={0} key={0} disabled>
                      Trailer Name
                    </MenuItem>
                    {props.trailersList?.map((t) => (
                      <MenuItem
                        value={t.Id}
                        key={t.Id}
                        style={{
                          display: "block",
                          fontFamily: "Muli",
                          fontSize: "13px",
                        }}
                      >
                        {t.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container direction="column" xs={6}>
              <Grid item>Load</Grid>
              <Grid item className={classes["Rectangle"]}>
                {props.loads?.map((l) => (
                  <Grid item key={l.Id}>
                    {l.Name}
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid item container direction="column" xs={6}>
              <Grid item>Sales Orders</Grid>
              <Grid item className={classes["RectangleWithScroll"]}>
                {props.salesOrdersCodes?.map((s, index) => (
                  <Grid item key={index}>
                    {s} &nbsp;
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row-reverse"
              spacing={2}
              paddingTop={1}
              alignItems="center"
            >
              <Grid item>
                <button
                  className={classes["save-button"]}
                  disabled={isDisabled}
                  onClick={() => {
                    if(props.shipmentId === null)
                    props.creatingShipment();
                    else props.updatingShipment();
                  }}
                >
                  {props.shipmentLoading ? "Saving" : "Save"}
                </button>
              </Grid>
              <Grid item>
                <button
                  className={classes["cancel-button"]}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cancel
                </button>
              </Grid>
              {props.shipmentSuccess ? (
                <Grid item className={classes["successMsg"]}>
                {props.shipmentId === null? "Successfully Created" : "Successfully Updated"}  
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default ShipmentModal;
