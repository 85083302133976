import React, { useEffect, useState, useMemo } from "react";
import { Grid } from "@mui/material";
import SaasBack from "../../../../../../Assets/Images/SaaSbackground.png";
import classes from "../../../../../../Assets/Styles/factory.module.css";
import ScanImg from "../../../../../../Assets/Images/Stock Count/scanRm.png";
import ScanSuccesImg from "../../../../../../Assets/Images/Stock Count/SuccessScan.png";
import useScanDetection from 'use-scan-detection';
import { useNavigate, useLocation } from "react-router-dom";
import * as stockCountServices from "../../../../../../Services/stock-count-services.proxy";

const insertMaterialBarcodes = stockCountServices.insertMaterialBarcodes;

const ScanMaterial = (props) => {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const [succesScanned, setSuccessScanned] = useState(false);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const zoneId = query?.get("zoneId");
  const stockCountId = query?.get("stockCountId");
  const categoryId = query?.get("categoryId");
  const [finishLoading, setFinishLoading] = useState(false);
  // const [barcodes, setBarcodes] = useState(["VLN-94910140", "OAW-72552732", "FWY-11358923", "HCA-98707983", "WVJ-18439723", "WAD-29541034"])
  const [barcodes, setBarcodes] = useState([]);
  useScanDetection({
    onComplete: (code) => {
      if (!barcodes?.includes(code.replace("Shift", "").replace("Enter", ""))) {
        setBarcodes(barcodes.concat(code.replaceAll("Shift", "").replaceAll("Enter", "")));
      }
    },
    minLength: 3
  })

  const inserBarcodes = () => {
    setFinishLoading(true);
    insertMaterialBarcodes(stockCountId, barcodes).then((x) => {
      setFinishLoading(false);
      navigate(`/saas/factory/ScanResult?zoneId=${zoneId}&stockCountId=${stockCountId}&categoryId=${categoryId}`);
    })
  }


  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
        objectFit: "cover",
        paddingBottom: 60,
      }}
    >
      <Grid item container paddingTop={3} rowGap={2}>
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          gap={1}
          alignItems="center"
        >
          <Grid
            item
            container
            direction="column"
            lg={1}
            sm={1.5}
            xs={2}
            fontSize={{ xs: "10px", sm: "16px" }}
            fontWeight="bold"
            className={classes["Titlestock"]}
            paddingTop={{ xs: 1.5, sm: 3 }}
          >
            <Grid item>Stock Scan</Grid>
          </Grid>
          <Grid
            marginTop={1.5}
            item
            xs={6}
            sm={8}
            lg={7.5}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>
          <Grid item paddingTop={1}>
            <button
              className={classes["back-button"]}
              onClick={() => {
                navigate(-1);
              }}
            >
              Go Back
            </button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item container xs={8}>
          <Grid
            container
            item
            paddingTop={10}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <img src={ScanImg} alt={"scan"} />
          </Grid>
          <Grid
            container
            item
            direction={"row"}
            paddingTop={10}
            justifyContent={"center"}
            alignItems={"center"}
            gap={5}
          >
            <button
              className={classes["FinishScan"]}
              onClick={() => {
                inserBarcodes();
              }}
            >
              {finishLoading ? "Finishing" : "Finish"}
            </button>
          </Grid>
        </Grid>
        <Grid item xs={2.5} bgcolor='#fff' height={400} borderRadius={5} marginTop={10} padding={5} color='#101650' overflow='auto'>
          Barcodes Scanned:<br />
          {barcodes.map(b => <>{b}<br /></>)}</Grid>
      </Grid>
    </div>
  );
};

export default ScanMaterial;
