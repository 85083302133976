import { Grid, Table, TableBody, TableCell, TableContainer, TableRow, Paper, CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../Assets/Styles/home.css";
import classes from "../../../Assets/Styles/Activities.module.css";
import { useMemo } from "react";
import * as routesServices from '../../../Services/routes-services.proxy';

const getRoutesList = routesServices.getRoutesList;

const styles = {
    label: {
        fontFamily: 'Muli',
        fontSize: 12,
        fontWeight: 'bold',
        lineHeight: 1.5,
        textAlign: 'left',
        color: '#707070',
    }
}


export default function Factory(props) {
    useEffect(() => {
        props.setRegister(true);
    }, [props.setRegister]);

    useEffect(() => {
        props.setNaved(false);
    }, [props.setNaved]);

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    const useQuery = () => {
        const { search } = useLocation();
        return useMemo(() => new URLSearchParams(search), [search]);
    };
    const query = useQuery();
    const Number = query.get("number");
    const ID = query.get("id");

    const navigate = useNavigate();
    const [rows, setRows] = useState([])
    const [codes, setCodes] = useState([]);

    useEffect(() => {
        let apiTest = true;
        getRoutesList(ID).then((x) => {
            if (apiTest) {
                setRows(x);
                setCodes(x[x?.map(a => a?.Data.length).indexOf(Math.max(...x?.map(a => a?.Data.length)))]?.Data)
            }
        })
        return () => {
            apiTest = false;
        }
    }, [])

    return (
        <Grid className="Rectangle779" padding='2.5% 0 0 8%'>
            <Grid
                item
                xs={12}
                container
                rowSpacing={3}
            >
                <Grid item xs={12} container alignItems="center" gap={1}>
                    <Grid
                        item
                        xs={2.5}
                        sm={1.5}
                        lg={1}
                        sx={{
                            fontFamily: "Muli",
                            fontSize: 18,
                            fontWeight: 'bold',
                            lineHeight: 1.44,
                            textAlign: "left",
                            color: "#101650",
                        }}
                    >Bay #{Number}</Grid>
                    <Grid
                        item
                        xs={6.5}
                        sm={8.5}
                        lg={9.4}
                        sx={{
                            border: "solid 0.1px #fff",
                            backgroundColor: "#fff",
                            height: 0,
                        }}
                    >
                    </Grid>
                    <Grid item>
                        <button
                            className={classes["back-button"]}
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            Go Back
                        </button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container xs={12} gap={2} alignItems='flex-start'>
                <Grid item xs={11}
                    md={
                        rows[rows?.map(a => a?.Data.length).indexOf(Math.max(...rows?.map(a => a?.Data.length)))]?.Data.length <= 3 ? 6
                            : rows[rows?.map(a => a?.Data.length).indexOf(Math.max(...rows?.map(a => a?.Data.length)))]?.Data.length <= 6 ? 8 : 11
                    }
                    container paddingTop='1%'>
                    <Grid item xs={12}
                        sx={{
                            fontFamily: 'Muli',
                            fontSize: 18,
                            textAlign: 'left',
                            color: '#101650',
                        }}>Routes
                    </Grid>
                    <Grid item xs={12} paddingTop='5%'>
                        {
                            rows.length > 0 ?
                                <TableContainer >
                                    <Table aria-label="a dense table">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell
                                                    sx={styles.label} >
                                                    Route Code</TableCell>
                                                <TableCell
                                                    sx={styles.label} >
                                                    Route type</TableCell>
                                                {
                                                    codes.map((code, index) => (
                                                        <TableCell key={index} sx={styles.label} style={{ textAlign: 'center' }} >Machine Code</TableCell>
                                                    ))
                                                }
                                            </TableRow>
                                            {rows.map((row) => (
                                                <TableRow
                                                    component={Paper}
                                                    key={row.Id}
                                                    sx={{
                                                        '&:last-child td, &:last-child th': { border: 0 },
                                                        backgroundColor: row.Id === 2 ? 'rgba(225, 227, 25, 0.3)'
                                                            : row.Id === 3 ? 'rgba(225, 227, 25, 0.5)'
                                                                : row.Id === 4 ? 'rgba(45, 164, 208, 0.3)'
                                                                    : row.Id === 5 ? 'rgba(45, 164, 208, 0.5)'
                                                                        : row.Id === 6 ? 'rgba(95, 186, 71, 0.3)'
                                                                            : row.Id === 7 ? 'rgba(95, 186, 71, 0.5)'
                                                                                : row.Id === 8 ? 'rgba(0, 0, 0, 0.3)'
                                                                                    : 'transparent'
                                                    }}
                                                >
                                                    <TableCell sx={{
                                                        backgroundColor: '#fff',
                                                        fontFamily: 'Muli',
                                                        fontSize: 12,
                                                        fontWeight: 'bold',
                                                        lineHeight: 1.5,
                                                        color: '#101650'
                                                    }}>
                                                        {row.Code}
                                                    </TableCell>
                                                    <TableCell sx={{
                                                        backgroundColor: '#fff',
                                                        fontFamily: 'Muli',
                                                        fontSize: 12,
                                                        fontWeight: 'bold',
                                                        lineHeight: 1.5,
                                                        color: '#101650'
                                                    }}>
                                                        {row.Name}
                                                    </TableCell>
                                                    {
                                                        codes.map((code, index) =>
                                                            <TableCell
                                                                key={index}
                                                                align="center"
                                                                sx={{
                                                                    fontFamily: 'Muli',
                                                                    fontSize: 12,
                                                                    fontWeight: 'bold',
                                                                    lineHeight: 1.5,
                                                                    color: '#707070'
                                                                }}>
                                                                {row?.Data[index]?.Name}
                                                            </TableCell>
                                                        )
                                                    }

                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <CircularProgress />
                        }

                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    );
}
