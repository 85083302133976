import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import classes from "../../../Assets/Styles/ProductionLabel.module.css";
import { Rnd } from "react-rnd";
import QRCode from "react-qr-code";

const DeliveryNoteForm = (props) => {
  return (
    <Grid bgcolor={"white"} ref={props.myRef}>
      <Grid container height={380} width={890} className={classes["tagForm2"]}>
        {props.firstSectionFields?.map((field) => (
          <Rnd
            disableDragging
            default={{
              x: field.x + 11,
              y: field.y + 15,
              width: field.Width,
              height: field.Height,
            }}
            minHeight={field.Height}
            minWidth={field.Width}
            maxWidth={field.Width}
            maxHeight={field.Height}
          >
            <Grid container>
              <Grid item xs={12} className={classes["FieldDataPrinted"]}>
                {field.LabelRowName}
              </Grid>
              {field.LabelRowName === "QR Code" ? (
                <QRCode
                  size={field.Width}
                  style={{
                    height: field.Height,
                    maxWidth: "100%",
                    width: "100%",
                  }}
                  value={field.Data}
                  viewBox={`0 0 256 256`}
                />
              ) : field.LabelRowName === "Company Profile Pic" ? (
                <img
                  src={field.Data}
                  width={field.Width}
                  height={field.Height}
                  alt={"img"}
                ></img>
              ) : (
                <Grid item xs={12} className={classes["FieldTitle"]}>
                  {field.LabelRowName === "Delivery Date" ? field.Data?.substring(0, 10) :
                      field.Data}
                </Grid>
              )}
            </Grid>
          </Rnd>
        ))}
      </Grid>
      <Grid container width={890} className={classes["tagForm"]}>
        {props.secondSectionFields?.map((mem, index) => (
          <Grid item container minHeight={300}>
            {mem.Fields.map((field) =>
              field?.LabelRowName === "Lines" ? (
                field.Data.map((line) => (
                  <Grid item container minHeight={300}>
                    {line.map((subField) => (
                      <Rnd
                        disableDragging
                        default={{
                          x: subField.x + 11,
                          y: subField.y + 15,
                          width: subField.Width,
                          height: subField.Height,
                        }}
                        minHeight={subField.Height}
                        minWidth={subField.Width}
                        maxWidth={subField.Width}
                        maxHeight={subField.Height}
                      >
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            className={classes["FieldDataPrinted"]}
                          >
                            {subField.LabelRowName}
                          </Grid>

                          <Grid item xs={12} className={classes["FieldTitle"]}>
                            {subField.LabelRowName === "Shape" ? (
                              <img
                                src={subField.Data}
                                width={150}
                                alt={"img"}
                              ></img>
                            ) : (
                              subField.Data
                            )}
                          </Grid>
                        </Grid>
                      </Rnd>
                    ))}
                  </Grid>
                ))
              ) : (
                <Rnd
                  disableDragging
                  default={{
                    x: field.x + 11,
                    y: field.y + 15,
                    width: field.Width,
                    height: field.Height,
                  }}
                  minHeight={field.Height}
                  minWidth={field.Width}
                  maxWidth={field.Width}
                  maxHeight={field.Height}
                >
                  <Grid container>
                    <Grid item xs={12} className={classes["FieldDataPrinted"]}>
                      {field.LabelRowName}
                    </Grid>

                    <Grid item xs={12} className={classes["FieldTitle"]}>
                      {field.Data}
                    </Grid>
                  </Grid>
                </Rnd>
              )
            )}
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        minHeight={50}
        width={890}
        className={classes["tagForm2"]}
      >
        {props.thirdSectionFields?.map((mem) => (
          <Grid item container minHeight={75}>
            {mem.Fields.map((field) => (
              <Rnd
                disableDragging
                default={{
                  x: field.x + 11,
                  y: field.y + 15,
                  width: field.Width,
                  height: field.Height,
                }}
                minHeight={field.Height}
                minWidth={field.Width}
                maxWidth={field.Width}
                maxHeight={field.Height}
              >
                <Grid container>
                  <Grid item xs={12} className={classes["FieldDataPrinted"]}>
                    {field.LabelRowName}
                  </Grid>

                  <Grid item xs={12} className={classes["FieldTitle"]}>
                    {field.Data}
                  </Grid>
                </Grid>
              </Rnd>
            ))}
          </Grid>
        ))}
      </Grid>
      {props.fourthSectionFields.length === 0 ? (
        <></>
      ) : (
        <Grid
          container
          height={270}
          width={890}
          className={classes["tagForm2"]}
        >
          {props.fourthSectionFields?.map((field) => (
            <Rnd
              disableDragging
              default={{
                x: field.x + 11,
                y: field.y + 15,
                width: field.Width,
                height: field.Height,
              }}
              minHeight={field.Height}
              minWidth={field.Width}
              maxWidth={field.Width}
              maxHeight={field.Height}
            >
              <Grid container>
                <Grid item xs={12} className={classes["FieldDataPrinted"]}>
                  {field.LabelRowName}
                </Grid>

                <Grid item xs={12} className={classes["FieldTitle"]}>
                  {field.Data}
                </Grid>
              </Grid>
            </Rnd>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default DeliveryNoteForm;
