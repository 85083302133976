import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { Rnd } from "react-rnd";
import classes from "../../../Assets/Styles/ProductionLabel.module.css";
import RemoveIcon from "../../../Assets/Images/ProductionLabel/remove.png";

const TagTemaplate = (props) => {
  const [labelWidth, setLabelWidth] = useState(0);
  const [labelHeight, setLabelHeight] = useState(0);
  const [modify, setModify] = useState(false);

  useEffect(() => {
    if (props.labelSize) {
      if (props.labelSize === 4) {
        setLabelWidth(835);
        setLabelHeight(295);
      } else if (props.labelSize === 6) {
        setLabelWidth(417.5);
        setLabelHeight(393.33);
      } else if (props.labelSize === 8) {
        setLabelWidth(417.5);
        setLabelHeight(295);
      }
    }
  }, [props.labelSize]);
  useEffect(() => {}, [props.labelRawsDictionary]);
  return props.labelSize === null ? (
    <Grid container height={480} width={285} className={classes["tagForm"]}>
      {props.selectedlabelRawsFields?.map((field) =>
        props.labelRawsDictionary[field.LabelRowId] === "Hole Space" ? (
          <Rnd
            bounds={"parent"}
            default={{
              x: field.x,
              y: field.y,
              width: field.Width,
              height: field.Height,
            }}
            onDragStop={(e, d) => {
              field.x = d.x;
              field.y = d.y;
            }}
            minWidth={field.Width}
            minHeight={field.Width}
            maxWidth={field.Width}
            maxHeight={field.Width}
          >
            <Grid
              style={{
                border: "solid 1px #101650",
                borderStyle: "dashed",
                backgroundColor: "transparent",
                borderRadius: "50px",
              }}
              minWidth={field.Width}
              minHeight={field.Width}
              maxWidth={field.Width}
              maxHeight={field.Width}
              item
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onClick={(e) => {
                if (e.detail === 2)
                  props.setSelectedLabelRawsFields(
                    props.selectedlabelRawsFields.filter(
                      (x) => x.Id !== field.Id
                    )
                  );
              }}
            ></Grid>
          </Rnd>
        ) : props.labelRawsDictionary[field.LabelRowId] === "Shape Selected" ? (
          <Rnd
            bounds={"parent"}
            default={{
              x: field.x,
              y: field.y,
              width: field.Width,
              height: field.Height,
            }}
            onDragStop={(e, d) => {
              field.x = d.x;
              field.y = d.y;
            }}
            style={{ border: "solid 1px #101650", borderStyle: "dashed" }}
            minWidth={field.Width}
            minHeight={field.Height}
            maxWidth={field.Width}
            maxHeight={field.Height}
          >
            <Grid container direction={"column-reverse"}>
              <Grid item xs={12} className={classes["FieldTitle"]}>
                {props.labelRawsDictionary[field.LabelRowId]}
              </Grid>
              <Grid container height={field.Height - 20}>
                <Grid item xs={10.5} className={classes["FieldTitle"]}>
                  {JSON.parse(field.AdditionalFeatures).ShapeCode}
                </Grid>
                <Grid
                  item
                  xs={1.5}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={(e) => {
                    props.setSelectedLabelRawsFields(
                      props.selectedlabelRawsFields.filter(
                        (x) => x.Id !== field.Id
                      )
                    );
                  }}
                >
                  <img src={RemoveIcon} alt={"remove"} />
                </Grid>
              </Grid>
            </Grid>
          </Rnd>
        ) : props.labelRawsDictionary[field.LabelRowId] === "Barcode" ? (
          <Rnd
            bounds={"parent"}
            default={{
              x: field.x,
              y: field.y,
              width: field.Width,
              height: field.Height,
            }}
            onDragStop={(e, d) => {
              field.x = d.x;
              field.y = d.y;
            }}
            onResizeStop={(e, l, d, o, j) => {
              field.Width = field.Width + o.width;
              field.Height = field.Height + o.height;
              setModify(!modify);
            }}
            style={{ border: "solid 1px #101650", borderStyle: "dashed" }}
            minWidth={field.Width}
            minHeight={field.Height}
            maxWidth={field.Width}
            maxHeight={field.Height}
          >
            <Grid container>
              <Grid item xs={10.5} className={classes["FieldData"]}>
                {props.labelRawsDictionary[field.LabelRowId]}
              </Grid>
              <Grid
                item
                xs={1.5}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={(e) => {
                  props.setSelectedLabelRawsFields(
                    props.selectedlabelRawsFields.filter(
                      (x) => x.Id !== field.Id
                    )
                  );
                }}
              >
                <img src={RemoveIcon} alt={"remove"} />
              </Grid>
              <Grid item xs={12} className={classes["FieldTitle"]}>
                {field.Data}
              </Grid>
            </Grid>
          </Rnd>
        ) : (
          <Rnd
            bounds={"parent"}
            default={{
              x: field.x,
              y: field.y,
              width: field.Width,
              height: field.Height,
            }}
            onDragStop={(e, d) => {
              field.x = d.x;
              field.y = d.y;
            }}
            onResizeStop={(e, l, d, o, j) => {
              field.Width = field.Width + o.width;
              field.Height = field.Height + o.height;
              setModify(!modify);
            }}
            style={{ border: "solid 1px #101650", borderStyle: "dashed" }}
            minWidth={40}
            minHeight={40}
            maxWidth={210}
            maxHeight={65}
          >
            <Grid container>
              <Grid item xs={10.5} className={classes["FieldData"]}>
                {props.labelRawsDictionary[field.LabelRowId]}
              </Grid>
              <Grid
                item
                xs={1.5}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={(e) => {
                  props.setSelectedLabelRawsFields(
                    props.selectedlabelRawsFields.filter(
                      (x) => x.Id !== field.Id
                    )
                  );
                }}
              >
                <img
                  src={RemoveIcon}
                  alt={"remove"}
                  
                />
              </Grid>
              <Grid item xs={12} className={classes["FieldTitle"]}>
                {field.Data}
              </Grid>
            </Grid>
          </Rnd>
        )
      )}
    </Grid>
  ) : (
    <Grid container width={835} height={1180}>
      <Grid
        container
        height={labelHeight}
        width={labelWidth}
        className={classes["tagForm"]}
      >
        {props.selectedlabelRawsFields?.map((field) =>
          props.labelRawsDictionary[field.LabelRowId] === "Hole Space" ? (
            <Rnd
              bounds={"parent"}
              default={{
                x: field.x,
                y: field.y,
                width: field.Width,
                height: field.Height,
              }}
              onDragStop={(e, d) => {
                field.x = d.x;
                field.y = d.y;
              }}
              minWidth={field.Width}
              minHeight={field.Width}
              maxWidth={field.Width}
              maxHeight={field.Width}
            >
              <Grid
                style={{
                  border: "solid 1px #101650",
                  borderStyle: "dashed",
                  backgroundColor: "transparent",
                  borderRadius: "50px",
                }}
                minWidth={field.Width}
                minHeight={field.Width}
                maxWidth={field.Width}
                maxHeight={field.Width}
                item
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={(e) => {
                  if (e.detail === 2)
                    props.setSelectedLabelRawsFields(
                      props.selectedlabelRawsFields.filter(
                        (x) => x.Id !== field.Id
                      )
                    );
                }}
              ></Grid>
            </Rnd>
          ) : props.labelRawsDictionary[field.LabelRowId] ===
            "Shape Selected" ? (
            <Rnd
              bounds={"parent"}
              default={{
                x: field.x,
                y: field.y,
                width: field.Width,
                height: field.Height,
              }}
              onDragStop={(e, d) => {
                field.x = d.x;
                field.y = d.y;
              }}
              style={{ border: "solid 1px #101650", borderStyle: "dashed" }}
              minWidth={field.Width}
              minHeight={field.Height}
              maxWidth={field.Width}
              maxHeight={field.Height}
            >
              <Grid container direction={"column-reverse"}>
                <Grid item xs={12} className={classes["FieldTitle"]}>
                  {props.labelRawsDictionary[field.LabelRowId]}
                </Grid>
                <Grid container height={field.Height - 20}>
                  <Grid item xs={10.5} className={classes["FieldTitle"]}>
                    {JSON.parse(field.AdditionalFeatures).ShapeCode}
                  </Grid>
                  <Grid
                    item
                    xs={1.5}
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                    onClick={(e) => {
                      props.setSelectedLabelRawsFields(
                        props.selectedlabelRawsFields.filter(
                          (x) => x.Id !== field.Id
                        )
                      );
                    }}
                  >
                    <img src={RemoveIcon} alt={"remove"} />
                  </Grid>
                </Grid>
              </Grid>
            </Rnd>
          ) : props.labelRawsDictionary[field.LabelRowId] === "Barcode" ? (
            <Rnd
              bounds={"parent"}
              default={{
                x: field.x,
                y: field.y,
                width: field.Width,
                height: field.Height,
              }}
              onDragStop={(e, d) => {
                field.x = d.x;
                field.y = d.y;
              }}
              onResizeStop={(e, l, d, o, j) => {
                field.Width = field.Width + o.width;
                field.Height = field.Height + o.height;
                setModify(!modify);
              }}
              style={{ border: "solid 1px #101650", borderStyle: "dashed" }}
              minWidth={field.Width}
              minHeight={field.Height}
              maxWidth={field.Width}
              maxHeight={field.Height}
            >
              <Grid container>
                <Grid item xs={10.5} className={classes["FieldData"]}>
                  {props.labelRawsDictionary[field.LabelRowId]}
                </Grid>
                <Grid
                  item
                  xs={1.5}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={(e) => {
                    props.setSelectedLabelRawsFields(
                      props.selectedlabelRawsFields.filter(
                        (x) => x.Id !== field.Id
                      )
                    );
                  }}
                >
                  <img src={RemoveIcon} alt={"remove"} />
                </Grid>
                <Grid item xs={12} className={classes["FieldTitle"]}>
                  {field.Data}
                </Grid>
              </Grid>
            </Rnd>
          ) : (
            <Rnd
              bounds={"parent"}
              default={{
                x: field.x,
                y: field.y,
                width: field.Width,
                height: field.Height,
              }}
              onDragStop={(e, d) => {
                field.x = d.x;
                field.y = d.y;
              }}
              onResizeStop={(e, l, d, o, j) => {
                field.Width = field.Width + o.width;
                field.Height = field.Height + o.height;
                setModify(!modify);
              }}
              style={{ border: "solid 1px #101650", borderStyle: "dashed" }}
              minWidth={40}
              minHeight={40}
              maxWidth={210}
              maxHeight={65}
            >
              <Grid container>
                <Grid item xs={10.5} className={classes["FieldData"]}>
                  {props.labelRawsDictionary[field.LabelRowId]}
                </Grid>
                <Grid
                  item
                  xs={1.5}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={(e) => {
                    props.setSelectedLabelRawsFields(
                      props.selectedlabelRawsFields.filter(
                        (x) => x.Id !== field.Id
                      )
                    );
                  }}
                >
                  <img src={RemoveIcon} alt={"remove"} />
                </Grid>
                <Grid item xs={12} className={classes["FieldTitle"]}>
                  {field.Data}
                </Grid>
              </Grid>
            </Rnd>
          )
        )}
      </Grid>
      <Grid
        container
        height={labelHeight}
        width={labelWidth}
        className={classes["tagForm"]}
      ></Grid>
      <Grid
        container
        height={labelHeight}
        width={labelWidth}
        className={classes["tagForm"]}
      ></Grid>
      <Grid
        container
        height={labelHeight}
        width={labelWidth}
        className={classes["tagForm"]}
      ></Grid>
      {props.labelSize === 6 || props.labelSize === 8 ? (
        <>
          <Grid
            container
            height={labelHeight}
            width={labelWidth}
            className={classes["tagForm"]}
          ></Grid>
          <Grid
            container
            height={labelHeight}
            width={labelWidth}
            className={classes["tagForm"]}
          ></Grid>
        </>
      ) : (
        ""
      )}
      {props.labelSize === 8 ? (
        <>
          <Grid
            container
            height={labelHeight}
            width={labelWidth}
            className={classes["tagForm"]}
          ></Grid>
          <Grid
            container
            height={labelHeight}
            width={labelWidth}
            className={classes["tagForm"]}
          ></Grid>
        </>
      ) : (
        ""
      )}
    </Grid>
  );
};

export default TagTemaplate;
