import { Button, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ProjectCard from "./ProjectCard";
import * as projectServices from "../../Services/projects-services.proxy";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

const getProjectCardsList = projectServices.getProjectCardsList;
const getProjectAssignedCard = projectServices.getProjectAssignedCard;
const planType = localStorage.getItem("planId");
const companyPlan = localStorage.getItem("companyPlanId");

export default function ProjectListCards(props) {
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  const navigate = useNavigate();
  const [minimize, setMinimize] = useState(false);
  const data0 = {
    Name: "Project Demo Name",
    ClientName: "Client Name",
    PhaseCount: 0,
    SubProjectCount: 0,
  };

  const [projects, setProjects] = useState([]);
  const [proLength, setProLength] = useState(0);
  const [subRule, setSubRule] = useState(false);
  const [assignedProjects, setAssignedProjects] = useState([]);

  const [loadingC, setLoadingC] = useState(false);
  const [loadingA, setLoadingA] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setLoadingC(true);
    getProjectCardsList().then((x) => {
      setProjects(x.Projects);
      setProLength(x.Projects?.length);
      setLoadingC(false);
    });
  }, []);

  useEffect(() => {
    setLoadingA(true);
    getProjectAssignedCard().then((x) => {
      setAssignedProjects(x.Projects);
      setLoadingA(false);
    });
  }, []);

  const [isDisabled, setIsDisabled] = useState(false);

  return (
    <Grid
      container
      justifyContent="center"
      padding={isDisabled ? 0 : "3% 0 0 10%"}
      rowSpacing={2}
      paddingBottom={5}
    >
      {isDisabled ? (
        <Grid
          item
          container
          bgcolor={"rgba(225, 227, 25, 0.5)"}
          md={12}
          marginTop={2}
          paddingBottom={1}
        >
          <Grid item md={4}></Grid>
          <Grid
            item
            md={6}
            style={{
              fontFamily: "Muli",
              fontSize: "17px",
              fontWeight: "bold",
              textAlign: "left",
              color: "#101650",
            }}
          >
            <Grid>
              {localStorage.getItem("IsSubscribedBefore") === "true"
                ? "Please subscribe before you start working again"
                : localStorage.getItem("planId")?.includes("Pro") &&
                  !localStorage.getItem("planId")?.includes("+") &&
                  localStorage.getItem("isTeamMember") === "false"
                ? "Upgrade subscription required (Pro+)"
                : localStorage.getItem("planId")?.includes("Core") &&
                  localStorage.getItem("isTeamMember") === "false"
                ? "Upgrade subscription required (Pro or Pro+)"
                : localStorage.getItem("IsFreePlanActivated") === "true" &&
                  !localStorage.getItem("planId")
                ? "Upgrade subscription required Engineering(Pro or Pro+) or Manufacturing"
                : localStorage.getItem("isGuest") === "true"
                ? localStorage.getItem("AccountType") !== "1" && subRule
                  ? "You don't have permission to create a subproject"
                  : "Please subscribe to join or create project"
                : localStorage.getItem("AccountType") !== "1" && subRule
                ? "You don't have permission to create a subproject"
                : localStorage.getItem("isTeamMember") === "true" &&
                  !UserPermissions?.find((x) => x.Name === "Project Manager") &&
                  !UserPermissions?.find((x) => x.Name === "Admin")
                ? subRule
                  ? "You don't have permission to create a subproject"
                  : "You don't have permission to create or join a project"
                : localStorage.getItem("isTeamMember") === "true" &&
                  (UserPermissions?.find((x) => x.Name === "Project Manager") ||
                    UserPermissions?.find((x) => x.Name === "Admin"))
                ? companyPlan === "Core"
                  ? "Upgrade subscription for Company required (Pro or Pro+)"
                  : companyPlan === "Pro"
                  ? "Upgrade subscription for Company required (Pro+)"
                  : ""
                : ""}
            </Grid>
          </Grid>
          <Grid item md={2} container>
            {localStorage.getItem("IsSubscribedBefore") !== "true" &&
            (localStorage.getItem("isTeamMember") === "true" ||
              (localStorage.getItem("AccountType") !== "1" && subRule)) ? (
              <Grid item xs={8}></Grid>
            ) : (
              <Grid
                item
                onClick={() => {
                  props.setSetupProfile(true);
                  props.setSetupMenu(true);
                  navigate("/saas/profileSettings");
                }}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
              >
                <button
                  style={{
                    padding: "5px",
                    borderRadius: "5px",
                    backgroundColor: "#101650",
                    fontFamily: "Muli",
                    fontSize: "13px",
                    textAlign: "center",
                    border: 0,
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    props.setSetupProfile(true);
                    props.setSetupMenu(true);
                    navigate("/saas/profileSettings");
                  }}
                >
                  Subscribe
                </button>
              </Grid>
            )}

            <Grid
              item
              paddingLeft={1}
              onClick={() => {
                setIsDisabled(false);
                setSubRule(false);
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
            >
              <CloseIcon fontSize="medium" />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <Grid
        item
        xs={12}
        container
        rowSpacing={3}
        padding={isDisabled ? "3% 0 0 10%" : 0}
      >
        <Grid item xs={12} container alignItems="center" gap={0.5}>
          <Grid
            item
            xs={2}
            sm={2}
            lg={1.4}
            sx={{
              fontFamily: "Muli",
              fontSize: 18,
              fontWeight: "bold",
              lineHeight: 1.44,
              textAlign: "left",
              color: "#101650",
            }}
          >
            Your Projects
          </Grid>
          <Grid
            item
            xs={2.9}
            sm={6}
            lg={8.5}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>
          <Grid
            item
            xs={3}
            sm={2.5}
            md={1.5}
            onMouseOver={(e) => (e.target.style.cursor = "pointer")}
            sx={{
              fontFamily: "Muli",
              fontSize: 14,
              lineHeight: 1.36,
              textAlign: "left",
              color: "#101650",
              paddingLeft: "1%",
            }}
            onClick={() => {
              navigate("/saas/projects/created");
            }}
          >
            View all Projects
          </Grid>
        </Grid>
        <Backdrop
          sx={{
            color: "#fff",
            backgroundColor: "transparent",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            display: "flex",
            flexDirection: "column",
          }}
          open={loadingA || loadingC}
        >
          <CircularProgress />

          <Grid
            item
            fontFamily={"Muli"}
            fontSize={15}
            fontWeight={"bold"}
            color={"rgb(25, 118, 210)"}
          >
            Loading Data...
          </Grid>
        </Backdrop>
        <Grid item xs={12} sm={6} container columnSpacing={2}>
          <Grid item>
            <Button
              onClick={() => {
                if (
                  localStorage.getItem("IsSubscribedBefore") === "true" ||
                  (proLength === 1 &&
                    localStorage.getItem("planId")?.includes("Core")) ||
                  (proLength === 1 &&
                    localStorage.getItem("IsFreePlanActivated") === "true" &&
                    !localStorage.getItem("planId")) ||
                  (proLength === 5 &&
                    localStorage.getItem("planId")?.includes("Pro") &&
                    !localStorage.getItem("planId")?.includes("+")) ||
                  localStorage.getItem("isGuest") === "true" ||
                  (localStorage.getItem("isTeamMember") === "true" &&
                    !UserPermissions?.find(
                      (x) => x.Name === "Project Manager"
                    ) &&
                    !UserPermissions?.find((x) => x.Name === "Admin")) ||
                  (localStorage.getItem("isTeamMember") === "true" &&
                    UserPermissions?.find(
                      (x) => x.Name === "Project Manager"
                    ) &&
                    UserPermissions?.find((x) => x.Name === "Admin") &&
                    ((companyPlan === "Pro" && proLength === 5) ||
                      (companyPlan === "Core" && proLength === 1) ||
                      (proLength === 1 &&
                        localStorage.getItem("IsFreePlanActivated") ===
                          "true" &&
                        !localStorage.getItem("planId"))))
                ) {
                  for (let i = 0; i < UserPermissions?.length; i++) {
                    for (
                      let j = 0;
                      j < UserPermissions[i].Permissions?.length;
                      j++
                    ) {
                      if (
                        UserPermissions[i].Permissions[j].Name === "Project" &&
                        !(
                          proLength === 1 &&
                          localStorage.getItem("IsFreePlanActivated") ===
                            "true" &&
                          !localStorage.getItem("planId")
                        )
                      ) {
                        if (
                          UserPermissions[i].Permissions[j].Actions.find(
                            (a) => a.Name === "Create"
                          )
                        )
                          if (
                            !(companyPlan === "Pro" && proLength === 5) &&
                            !(companyPlan === "Core" && proLength === 1) &&
                            !(
                              proLength === 1 &&
                              localStorage.getItem("IsFreePlanActivated") ===
                                "true" &&
                              !localStorage.getItem("planId")
                            ) &&
                            localStorage.getItem("isGuest") !== "true" &&
                            localStorage.getItem("IsSubscribedBefore") !==
                              "true"
                          ) {
                            props.setView(false);
                            props.setEdit(false);
                            props.setProjectId(null);
                            navigate("/saas/projects/creating");
                            setIsDisabled(false);
                            break;
                          } else {
                            setIsDisabled(true);
                          }
                      } else {
                        setIsDisabled(true);
                      }
                    }
                  }
                } else {
                  props.setView(false);
                  props.setEdit(false);
                  props.setProjectId(null);
                  navigate("/saas/projects/creating");
                }
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = "#101650")}
              onMouseLeave={(e) => (e.target.style.backgroundColor = "#e1e319")}
              style={{
                borderRadius: 5,
                backgroundColor: "#e1e319",
                fontFamily: "Muli",
                fontSize: 12,
                fontWeight: "bold",
                lineHeight: 1.5,
                textAlign: "left",
                color: "#fff",
                textTransform: "none",
                padding: "10px",
              }}
            >
              + Create Project
            </Button>
          </Grid>
          <Grid item>
            <Button
              onMouseOver={(e) => (e.target.style.backgroundColor = "#101650")}
              onMouseLeave={(e) => (e.target.style.backgroundColor = "#0674b9")}
              onClick={() => {
                if (
                  localStorage.getItem("IsSubscribedBefore") === "true" ||
                  (proLength === 1 &&
                    localStorage.getItem("planId")?.includes("Core")) ||
                  (proLength === 5 &&
                    localStorage.getItem("planId") === "Pro") ||
                  (localStorage.getItem("isGuest") === "true" &&
                    !UserPermissions?.find((x) => x.Name === "Engineer")) ||
                  (localStorage.getItem("isTeamMember") === "true" &&
                    !UserPermissions?.find(
                      (x) => x.Name === "Project Manager"
                    ) &&
                    !UserPermissions?.find((x) => x.Name === "Admin")) ||
                  (localStorage.getItem("isTeamMember") === "true" &&
                    (UserPermissions?.find(
                      (x) => x.Name === "Project Manager"
                    ) ||
                      UserPermissions?.find((x) => x.Name === "Admin")) &&
                    ((companyPlan === "Pro" && proLength === 5) ||
                      (companyPlan === "Core" && proLength === 1)))
                ) {
                  for (let i = 0; i < UserPermissions?.length; i++) {
                    for (
                      let j = 0;
                      j < UserPermissions[i].Permissions?.length;
                      j++
                    ) {
                      if (
                        UserPermissions[i].Permissions[j].Name === "Project"
                      ) {
                        if (
                          !(companyPlan === "Pro" && proLength === 5) &&
                          !(companyPlan === "Core" && proLength === 1) &&
                          !(
                            proLength === 1 &&
                            localStorage.getItem("IsFreePlanActivated") ===
                              "true" &&
                            !localStorage.getItem("planId")
                          ) &&
                          UserPermissions[i].Permissions[j].Actions.find(
                            (a) => a.Name === "Join /Search published projects"
                          ) &&
                          localStorage.getItem("isGuest") !== "true" &&
                          localStorage.getItem("IsSubscribedBefore") !== "true"
                        ) {
                          navigate("/saas/projects/search");
                          setIsDisabled(false);
                          break;
                        } else {
                          setIsDisabled(true);
                        }
                      } else {
                        setIsDisabled(true);
                      }
                    }
                  }
                } else {
                  navigate("/saas/projects/search");
                }
              }}
              style={{
                borderRadius: 5,
                backgroundColor: "#0674b9",
                fontFamily: "Muli",
                fontSize: 12,
                fontWeight: "bold",
                lineHeight: 1.5,
                textAlign: "left",
                color: "#fff",
                textTransform: "none",
                padding: "10px",
              }}
            >
              + Join Project
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} container columnSpacing={4} rowSpacing={4}>
          {projects?.length > 0 ? (
            <>
              {projects.map((d, index) => (
                <Grid item xs={10} sm={5} md={3.8} lg={2.5} key={d.Id}>
                  <ProjectCard
                    setSubRule={setSubRule}
                    setDisabled={setIsDisabled}
                    data={d}
                    index={index}
                    edit={props.edit}
                    setEdit={props.setEdit}
                    view={props.view}
                    setView={props.setView}
                    projectId={props.projectId}
                    setProjectId={props.setProjectId}
                    setJoin={props.setJoin}
                  />
                </Grid>
              ))}
            </>
          ) : (
            <Grid item xs={10} sm={4.5} md={3.5} lg={2.5} xl={2.2}>
              <ProjectCard
                data={data0}
                index={-1}
                assigned={false}
                projectId={props.projectId}
                setProjectId={props.setProjectId}
              />
            </Grid>
          )}
        </Grid>
        {projects?.length === 0 ? (
          <Grid
            item
            xs={12}
            sx={{
              fontFamily: "Muli",
              fontSize: 12,
              lineHeight: 1.5,
              textAlign: "left",
              color: "#101650",
              paddingBottom: "30px",
            }}
          >
            This Space is empty. Create a Project to get started.
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        container
        rowSpacing={2}
        padding={isDisabled ? "3% 0 0 10%" : 0}
      >
        <Grid item xs={12} container alignItems="center" paddingBottom="20px">
          <Grid
            item
            xs={2.4}
            sm={1.4}
            sx={{
              fontFamily: "Muli",
              fontSize: 18,
              fontWeight: "bold",
              lineHeight: 1.44,
              textAlign: "left",
              color: "#101650",
            }}
          >
            Assigned Projects
          </Grid>
          <Grid
            item
            xs={5.25}
            sm={8.25}
            sx={{
              border: "solid 1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>
          {minimize ? (
            <Grid
              item
              xs={0.5}
              sm={0.3}
              sx={{
                fontFamily: "Muli",
                fontSize: 27,
                lineHeight: 1.43,
                textAlign: "left",
                color: "#101650",
                paddingLeft: "1%",
                paddingBottom: "0.3%",
              }}
              onClick={() => setMinimize(false)}
              onMouseOver={(e) => (e.target.style.cursor = "pointer")}
            >
              +
            </Grid>
          ) : (
            <>
              <Grid
                item
                xs={1}
                sm={0.3}
                sx={{
                  fontFamily: "Muli",
                  fontSize: 14,
                  lineHeight: 1.43,
                  textAlign: "left",
                  color: "#101650",
                  paddingLeft: "1%",
                  paddingBottom: { sm: "0.7%", xs: "1.5%" },
                }}
                onClick={() => setMinimize(true)}
                onMouseOver={(e) => (e.target.style.cursor = "pointer")}
              >
                __
              </Grid>
              <Grid
                item
                sm={2}
                xs={3}
                onMouseOver={(e) => (e.target.style.cursor = "pointer")}
                sx={{
                  fontFamily: "Muli",
                  fontSize: 14,
                  lineHeight: 1.36,
                  textAlign: "left",
                  color: "#101650",
                  paddingLeft: "0.5%",
                }}
                onClick={() => {
                  navigate("/saas/projects/assigned");
                }}
              >
                View all Projects
              </Grid>
            </>
          )}
        </Grid>
        {minimize ? (
          <></>
        ) : (
          <Grid item xs={12} container columnSpacing={4} rowSpacing={2}>
            {assignedProjects?.length > 0 ? (
              <>
                {assignedProjects.map((d, index) => (
                  <Grid
                    item
                    xs={10}
                    sm={5}
                    md={2.8}
                    lg={2.5}
                    key={d.Id}
                    // paddingBottom={2}
                  >
                    <ProjectCard
                      setSubRule={setSubRule}
                      setDisabled={setIsDisabled}
                      data={d}
                      index={index}
                      edit={props.edit}
                      setEdit={props.setEdit}
                      view={props.view}
                      setView={props.setView}
                      projectId={props.projectId}
                      setProjectId={props.setProjectId}
                      setJoin={props.setJoin}
                    />
                  </Grid>
                ))}
              </>
            ) : (
              <Grid item xs={10} sm={4.5} md={3.5} lg={2.5} xl={2.2}>
                <ProjectCard
                  data={data0}
                  index={-1}
                  assigned={true}
                  projectId={props.projectId}
                  setProjectId={props.setProjectId}
                />
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
