import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Logo from "../../Assets/Images/Dashboard/logo.png";
import "../../Assets/Styles/Dashboard/LogIn.css";
import TextField from "@mui/material/TextField";
import { IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import * as loginApi from "../../../Services/log-in.proxy";
import { getUserData } from "../../../Services/user-data-service.proxy";
import jwt from "jwt-decode";

const LogInFunction = loginApi.logIn;

export default function LoginByEmail(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [inputsError, setInputsError] = useState(false);
  const [inputsInvalid, setInputsInvalid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [password, setPassword] = useState("");
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [success, setSuccess] = useState(false);
  const [unauthMsg, setUnauthMsg] = useState("");

  const handleSubmit = (event) => {
    if (!loadingProgress) {
      setSuccess(false);
      setLoadingProgress(true);
    }
    event.preventDefault();
    if (email === "" || password === "") {
      setInputsError(true);
      setSuccess(false);
      setLoadingProgress(false);
    } else {
      LogInFunction(email, password, null, null, null, props.setLoading).then(
        (res) => {
          if (res.response?.data.status) {
            setUnauthMsg(res.response?.data.error);
            setInputsError(true);
            setInputsInvalid(true);
            setSuccess(false);
            setLoadingProgress(false);
          } else {
            localStorage.setItem("accessToken", res.data.accessToken);
            getUserData().then((userData) => {
              userData?.Plans?.map((plan) => {
                if (
                  plan.SubscriptionTypeId === 1 ||
                  plan.SubscriptionTypeId === 2
                ) {
                  localStorage.setItem("PlanNameEngineering", plan.PlanName);
                  localStorage.setItem("IsScheduled", plan.IsScheduled);
                  localStorage.setItem("StartDate", plan.StartDate);
                  localStorage.setItem("EndDate", plan.EndDate);
                  localStorage.setItem("isSubscribed", plan.IsSubscribed);
                  localStorage.setItem("planId", plan.ProductType);
                  localStorage.setItem("companyPlanId", plan.ProductType);
                  localStorage.setItem(
                    "IsSubscribedBefore",
                    plan.IsSubscribedBefore
                  );
                } else if (plan.SubscriptionTypeId === 3) {
                  localStorage.setItem("PlanNameManu", plan.PlanName);
                  localStorage.setItem("IsScheduledManu", plan.IsScheduled);
                  localStorage.setItem("StartDateManuPlan", plan.StartDate);
                  localStorage.setItem("EndDateManuPlan", plan.EndDate);
                  localStorage.setItem("isSubscribedManu", plan.IsSubscribed);
                  localStorage.setItem("planIdManu", plan.ProductType);
                  localStorage.setItem("companyPlanIdManu", plan.ProductType);
                  localStorage.setItem(
                    "IsSubscribedBeforeManu",
                    plan.IsSubscribedBefore
                  );
                }
              });
              localStorage.setItem("isVerified", userData.IsUserVerified);
              localStorage.setItem("companyName", userData.DisplayName);
              localStorage.setItem("isGuest", userData.IsGuest);
              localStorage.setItem("isTeamMember", userData.IsTeamMember);
              localStorage.setItem(
                "IsFreePlanActivated",
                userData.IsFreePlanActivated
              );
              localStorage.setItem(
                "AccountType",
                jwt(res.data.accessToken).Roles[0]
              );
              localStorage.setItem("UserId", jwt(res.data.accessToken).Id);
              localStorage.setItem(
                "TenantId",
                jwt(res.data.accessToken).TenantId
              );
              setSuccess(true);
              setLoadingProgress(false);
              navigate("/machine/connect");
            });
          }
        }
      );
    }
  };

  const buttonSx = {
    ...(success && {
      bgcolor: "#1173CF",
      "&:hover": {
        bgcolor: "#101650",
      },
    }),
  };

  return (
    <Grid
      container
      className="LoginBg"
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Grid
        item
        container
        direction={"column"}
        width="380px"
        height="517.6px"
        alignItems={"center"}
        className="FormBg"
      >
        <Grid item marginTop={5}>
          <a href="/">
            <img src={Logo} alt="logo" height={50} />
          </a>
        </Grid>
        <Grid item marginTop={1}>
          <span className="LoginText">Log in to your account</span>
        </Grid>
        <Grid item marginTop={2}>
          <span className="login-errors">
            {inputsError
              ? inputsInvalid
                ? unauthMsg ===
                  "You can't login since you're company not subscribed"
                  ? unauthMsg
                  : "Incorrect email address and / or password."
                : "Empty fields."
              : ""}
          </span>
        </Grid>
        <Grid
          item
          container
          direction={"column"}
          marginTop={1}
          justifyContent="center"
          alignItems={"center"}
        >
          <Grid item marginRight={38}>
            <span className="FiedLabels">Email</span>
          </Grid>
          <Grid item>
            <TextField
              value={email}
              error={inputsError}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    borderColor: "#f2f2f2",
                  },
                },
              }}
              id="outlined-name1"
              size="small"
              className="FieldsInput"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction={"column"}
          marginTop={1}
          justifyContent="center"
          alignItems={"center"}
        >
          <Grid item marginRight={35}>
            <span className="FiedLabels">Password</span>
          </Grid>
          <Grid item>
            <TextField
              type={showPassword ? "text" : "password"}
              error={inputsError}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    borderColor: "#f2f2f2",
                  },
                },
              }}
              id="outlined-name"
              size="small"
              className="FieldsInput"
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <Visibility htmlColor="rgba(6, 116, 185, 0.5)" />
                    ) : (
                      <VisibilityOff htmlColor="rgba(6, 116, 185, 0.5)" />
                    )}
                  </IconButton>
                ),
              }}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              value={password}
            />
          </Grid>
        </Grid>
        <Grid item marginTop={2}>
          <Box sx={{ m: 1, position: "relative" }}>
            <Button
              className="loginButton"
              variant="contained"
              sx={buttonSx}
              disabled={loadingProgress}
              onClick={handleSubmit}
            >
              Log In
            </Button>
            {loadingProgress && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </Grid>
        <Grid item className="HorizontalLine" marginTop={1}></Grid>
        <Grid item className="LoginLinks" paddingRight={20} marginTop={2}>
          <div
            className="LoginLinks"
            onClick={(e) => {
              navigate("/machine/login");
            }}
            onMouseOver={(e) => (e.target.style.cursor = "pointer")}
          >
            Don't have an account? Go Back.
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
