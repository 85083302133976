import React, { useEffect, useState, useMemo } from "react";
import { Grid, Button, Modal, Box, TextField, Select } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { MenuItem, FormControl, FormHelperText } from "@mui/material";
import classes from "../../../Assets/Styles/factoryTables.module.css";
import { useSelector } from "react-redux";

function ChangeStatusModal(props) {
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    bottom: "30%",
    transform: "translate(-50%, -50%)",
    height: props.tagsOnMachine.length > 0 ? "60%" : "30%",
    width: "32%",
    borderRadius: "15px",
    boxShadow: " 0 3px 6px 0 rgba(0, 0, 0, 0.46)",
    border: "solid 1px #0674b9",
    backgroundColor: "#fff",
  };

  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [status, setStatus] = useState(0);

  const handleClose = () => {
    setOpen(false);
    props.setOpenModal(false);
  };

  useEffect(() => {
    if (props.openModal) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [props.openModal]);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const ID = query.get("id");
  return (
    <Modal
      disableScrollLock
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          direction="column"
          padding={5}
        >
          <Grid
            item
            sx={{
              fontFamily: "Muli",
              fontSize: "18px",
              color: "#101650",
              fontWeight: "bold",
            }}
          >
            Change Block Status
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <Select
                MenuProps={{ disableScrollLock: true }}
                value={status}
                fullWidth
                sx={{ height: "45px" }}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
              >
                <MenuItem value={0} key={0} disabled>
                  Status
                </MenuItem>
                {props.statuses.map((r) => (
                  <MenuItem
                    disabled={props.rowData?.row.Status === r.Name}
                    value={r.Id}
                    key={r.Id}
                    style={{ display: "block" }}
                  >
                    {r.Name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText sx={{ color: "#d32f2f" }}>
                {/* { props.subcontractorError
                    ? subcontractorErrorMsg
                    :props.subContractorsEmpty
                    ? "There is no manu contracts on this subproject"
                    :""} */}
              </FormHelperText>
            </FormControl>
          </Grid>
          {props.tagsOnMachine?.length > 0 ? (
            <>
              <Grid
                paddingTop={1}
                sx={{
                  fontFamily: "Muli",
                  fontSize: "15px",
                  color: "#101650",
                  fontWeight: "bold",
                }}
              >
                Please reassign below lines on different machine before changing
                status
              </Grid>
              <Grid item container xs={12} direction={"row"}>
                <Grid
                  item
                  xs={4}
                  sx={{
                    fontFamily: "Muli",
                    fontSize: "12px",
                    color: "#101650",
                    fontWeight: "bold",
                  }}
                >
                  Sales Order
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    fontFamily: "Muli",
                    fontSize: "12px",
                    color: "#101650",
                    fontWeight: "bold",
                  }}
                >
                  Member
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    fontFamily: "Muli",
                    fontSize: "12px",
                    color: "#101650",
                    fontWeight: "bold",
                  }}
                >
                  No.
                </Grid>
              </Grid>
              <Grid item height={"100px"} overflow={"auto"}>
                {props.tagsOnMachine.map((x) => (
                  <Grid item container xs={12} direction={"row"}>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        fontFamily: "Muli",
                        fontSize: "10px",
                        color: "#101650",
                        fontWeight: "bold",
                      }}
                    >
                      {x.SalesOrderCode}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        fontFamily: "Muli",
                        fontSize: "10px",
                        color: "#101650",
                        fontWeight: "bold",
                      }}
                    >
                      {x.MemberName}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        fontFamily: "Muli",
                        fontSize: "10px",
                        color: "#101650",
                        fontWeight: "bold",
                      }}
                    >
                      {x.BarMarkNo}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid item marginTop={1}>
                <button
                  className={classes["update-button"]}
                  onClick={() => {
                    props.navigate(
                      `/saas/productionPlanning/Calendar?fid=${props.factoryId}`
                    );
                  }}
                >
                  Go to Sales Order Combination
                </button>
              </Grid>
            </>
          ) : (
            <></>
          )}

          <Grid item container direction="row-reverse" marginTop={2} gap={1}>
            <button
              className={classes["update-button"]}
              onClick={() => {
                props.setUpdatingLoading(true);
                props.changeStatus(props.blockId, status);
              }}
            >
              {props.blockType === 2
                ? UserPermissions?.find(
                    (x) => x.Name === "Production Coordinator"
                  )
                  ? props.updatingLoading
                    ? "Updating"
                    : "Update Status"
                  : "Request Change Status"
                : props.updatingLoading
                ? "Updating"
                : "Update Status"}
            </button>
            <button className={classes["update-button"]} onClick={handleClose}>
              Cancel
            </button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default ChangeStatusModal;
