import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import classes from "../../Assets/Styles/InventoryManagement.module.css";
import { useNavigate } from "react-router-dom";
import InventoryCardImg from "../../Assets/Images/inventoryCard1.png"
import { useSelector } from "react-redux";
import * as permissionFunctions from "../../../ReusableFunctions";

const CheckIfPermitted = permissionFunctions.CheckIfPermitted;

function InventoryManagementCard(props) {
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );

  useEffect(() => {
    if (UserPermissions) {
      CheckIfPermitted(
        UserPermissions,
        "Inventory Management",
        "Create",
        props.setDisableI
      );
    }
  }, [UserPermissions]);
  const navigate = useNavigate();
  return (
    <Grid
      container
      sx={{
        backgroundImage: `url(${InventoryCardImg})`,
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat",
        fontFamily: "Muli",
      }}
      direction="column"
      gap={1}
      justifyContent="center"
      padding="40% 15% 19% 15%"
      borderRadius="5px"
    >
      <Grid item className={classes["darkTitle"]}>
        {props.data.InventoryCode}
      </Grid>
      <Grid item className={classes["greyText"]}>
        {props.data.FactoryCode}
      </Grid>
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        className={classes["greyText"]} onMouseOver={(e) => {
          e.target.style.cursor = "pointer"
          e.target.style.fontWeight = "bold"
        }}
        onMouseOut={(e) => {
          e.target.style.fontWeight = "normal"
        }}
        onClick={(e) => {
          navigate(
            `/saas/admin/inventoryManagement/materialReceiptTableList?inventoryId=${props.data.Id}`
          );
        }}

      >
        <Grid item

        >Material Receipts</Grid>
        <Grid
          item
          sx={{
            width: 28,
            height: 17,
            borderRadius: 7,
            backgroundColor: "#f1f1f1",
            fontSize: 12,
            textAlign: "center",
          }}
        >
          {props.data.NumberOfStocks}
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        className={classes["blueText"]}
        justifyContent="space-between"
        paddingTop={1}
      >
        <Grid item width="90px" onMouseOver={(e) => {
          e.target.style.cursor = "pointer";
          e.target.style.fontWeight = "bold"
        }}
          onMouseOut={(e) => {
            e.target.style.fontWeight = "normal";
          }}
          onClick={() => {
            if (props.disableI || localStorage.getItem("IsFreePlanActivated") === "true" ||
              localStorage.getItem("planIdManu")?.includes("Core")) {
              props.setAlert(true)
            }
            else {

              navigate(
                `/saas/admin/inventoryManagement/materialReceiptCreationForm?inventoryId=${props.data.Id}`
              );
            }

          }}>
          {" "}
          Add Material Receipt
        </Grid>
        <Grid
          item
          fontWeight="bold"
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
          }}
          onClick={() => {
            if (props.disableI || localStorage.getItem("IsFreePlanActivated") === "true" ||
              localStorage.getItem("planIdManu")?.includes("Core")) {
              props.setAlert(true)
            }
            else {

              navigate(
                `/saas/admin/inventoryManagement/materialReceiptCreationForm?inventoryId=${props.data.Id}`
              );
            }
          }}
        >
          +
        </Grid>
      </Grid>
    </Grid>
  );
}

export default InventoryManagementCard;
