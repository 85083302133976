import "./App.css";
import WebFont from "webfontloader";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SaasNavbar from "./Routes/SaasNavbar";
import Navbar from "./Routes/Navbar";
import Footer from "./Routes/Footer";
import CopyRight from "./Routes/CopyRight";
import LoadingModal from "./Components/LoadingIndicator";
import MainIcon from "./Assets/Images/Navbar/mainIcon.png";
import SubIcon from "./Assets/Images/Navbar/subIcon.png";
import jwt from "jwt-decode";
import { userDataActions } from "./store/index";
import { useDispatch } from "react-redux";
import TipsModal from "./SAAS/Components/TipsModal/TipsModal";
import TMIcon from "./Assets/Images/Navbar/TMRevised.png";
import * as pilecubesConst from "./pileCubesConst";
import * as userDataServices from "./Services/user-data-service.proxy";
import { AxiosInterceptor } from "./Routes/AxiosInterceptor";
import Navigate from "./Routes/Navigate";
import UnderMaintenance from "./Pages/UnderMaintenance";
const getUserData = userDataServices.getUserData;
const TIPS_EVENTS = pilecubesConst.TIPS_EVENTS;
const NOTIFICATIONS_EVENTS = pilecubesConst.NOTIFICATIONS_EVENTS;
const USER_NOTIFICATIONS_EVENTS = pilecubesConst.USER_NOTIFICATIONS_EVENTS;

function App() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Muli"],
      },
    });
  }, []);
  const [token, setToken] = useState(localStorage.getItem("accessToken"));
  const [booked, setBooked] = useState(false);
  const [naved, setNaved] = useState(true);
  const [terms, setTerms] = useState(true);
  const [scrolled, setScrolled] = useState(false);
  const [register, setRegister] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [accounType, setAccounType] = useState(null);
  const [accountName, setAccountName] = useState("");
  const [account, setAccount] = useState();
  const [isVerified, setIsVerified] = useState();
  const [isSubscribed, setIsSubscribed] = useState();
  const [profileSrc, setProfileSrc] = useState();
  const [setupProfile, setSetupProfile] = useState(false);
  const [setupMenu, setSetupMenu] = useState(false);
  const [active5, setActive5] = useState(false);
  const [active, setActive] = useState(true);
  const [cn, setCn] = useState(true);
  const [contactUspricing, setContactUspricing] = useState(false);
  const [OpenTipsModal, setOpenTipsModal] = useState(false);
  const [tipMessage, setTipMessage] = useState(false);
  const [showSaaSNavbar, setShowSaaSNavbar] = useState(true);
  const [delayedToDate, setdelayedToDate] = useState("");
  const [modify, setModify] = useState(false);
  const dispatch = useDispatch();


  useEffect(() => {
    if (token) {
      getUserData().then((x) => {
        if (x.statusCode === 401 || x.statusCode === 500 || x.error || !x) {
          localStorage.clear();
        }
        else {
          x?.Plans?.map((plan) => {
            if (
              plan.SubscriptionTypeId === 1 ||
              plan.SubscriptionTypeId === 2
            ) {
              localStorage.setItem("PlanNameEngineering", plan.PlanName);
              localStorage.setItem("IsScheduled", plan.IsScheduled);
              localStorage.setItem("StartDate", plan.StartDate);
              localStorage.setItem("EndDate", plan.EndDate);
              localStorage.setItem("isSubscribed", plan.IsSubscribed);
              localStorage.setItem("planId", plan.ProductType);
              localStorage.setItem("companyPlanId", plan.ProductType);
              localStorage.setItem(
                "IsSubscribedBefore",
                plan.IsSubscribedBefore
              );
              setIsSubscribed(true);
            } else if (plan.SubscriptionTypeId === 3) {
              localStorage.setItem("PlanNameManu", plan.PlanName);
              localStorage.setItem("IsScheduledManu", plan.IsScheduled);
              localStorage.setItem("StartDateManuPlan", plan.StartDate);
              localStorage.setItem("EndDateManuPlan", plan.EndDate);
              localStorage.setItem("isSubscribedManu", plan.IsSubscribed);
              localStorage.setItem("planIdManu", plan.ProductType);
              localStorage.setItem("companyPlanIdManu", plan.ProductType);
              localStorage.setItem(
                "IsSubscribedBeforeManu",
                plan.IsSubscribedBefore
              );
              setIsSubscribed(true);
            }
          });

          localStorage.setItem("isVerified", x.IsUserVerified);
          localStorage.setItem("companyName", x.DisplayName);
          localStorage.setItem("isGuest", x.IsGuest);
          localStorage.setItem("isTeamMember", x.IsTeamMember);
          localStorage.setItem(
            "IsFreePlanActivated",
            x.IsFreePlanActivated
          );
          // if (jwt(token)?.Roles?.includes(2) && x?.Plans?.length === 0) localStorage.setItem("IsFreePlanActivated", true);
          if (x.ProfilePictureUrl) {
            setProfileSrc(x.ProfilePictureUrl);
            localStorage.setItem("ProfileUrl", x.ProfilePictureUrl);
          }
          dispatch(userDataActions.setDisplayName(x.DisplayName));
          dispatch(userDataActions.setIsUserVerified(x.IsUserVerified));
          dispatch(userDataActions.setIsUserSubscribed(x.IsUserSubscribed));
          dispatch(userDataActions.setIsGuest(x.IsGuest));
          dispatch(userDataActions.setIsTeamMember(x.IsTeamMember));
          dispatch(userDataActions.setProfilePictureUrl(x.ProfilePictureUrl));
          dispatch(userDataActions.setCompanyName(x.CompanyName));
          dispatch(userDataActions.setUserData(x.Notifications));
          dispatch(
            userDataActions.setNotificationDisplayCounter(
              x.NotificationDisplayCounter
            )
          );
          dispatch(userDataActions.setUserPermissions(x.GroupPermissions));
          if (x.TipsDelayedTo) {
            setdelayedToDate(x.TipsDelayedTo);
          } else {
            setdelayedToDate(new Date());
          }
        }
      });
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      setIsLoggedIn(true);
      setIsVerified(localStorage.getItem("isVerified"));
      setIsSubscribed(localStorage.getItem("isSubscribed"));
      setAccountName(localStorage.getItem("companyName"));
      setAccount(jwt(token));
    } else setIsLoggedIn(false);
  }, [token, isSubscribed, accountName, cn]);

  useEffect(() => {
    if (localStorage.getItem("ProfileUrl")) {
      setProfileSrc(localStorage.getItem("ProfileUrl"));
    } else {
      if (localStorage.getItem("isTeamMember") === "true") {
        setProfileSrc(TMIcon);
      } else if (account) {
        if (
          account.Roles?.includes(1) ||
          localStorage.getItem("AccountType") === "1"
        ) {
          setAccounType(1);
          setProfileSrc(MainIcon);
        } else if (
          account.Roles?.includes(2) ||
          localStorage.getItem("AccountType") === "2"
        ) {
          setAccounType(2);
          setProfileSrc(SubIcon);
        }
      }
    }
  }, [setAccounType, account]);

  useEffect(() => {
    if (token) {
      const subscription = new EventSource(
        `${process.env.REACT_APP_URL + NOTIFICATIONS_EVENTS}`
      );
      subscription.onmessage = (message) => {
        dispatch(userDataActions.addNotification(message.data));
        dispatch(userDataActions.IncrementNotificationCounter());
      };
      subscription.onerror = (message) => { };
    }
  }, [token]);
  useEffect(() => {
    if (token) {
      const subscription = new EventSource(
        `${process.env.REACT_APP_URL + USER_NOTIFICATIONS_EVENTS}`,
        {
          params: {
            userId: localStorage.getItem("UserId"),
          },
        }
      );

      subscription.onmessage = (message) => {
        dispatch(userDataActions.addNotification(message.data));
        dispatch(userDataActions.IncrementNotificationCounter());
      };
      subscription.onerror = (message) => { };
    }
  }, [token]);
  useEffect(() => {
    if (token && delayedToDate && !modify) {
      const TipsSubscription = new EventSource(
        `${process.env.REACT_APP_URL + TIPS_EVENTS}`
      );

      TipsSubscription.onmessage = (message) => {
        if (delayedToDate) {
          const currentDate = new Date();
          const toDate = new Date(delayedToDate);
          if (currentDate > toDate) {
            const messageObject = JSON.parse(message.data);
            setTipMessage(messageObject.Content);
            setTimeout(() => {
              setOpenTipsModal(true);
            }, 500);
          }
        }
      };
      TipsSubscription.onerror = (message) => { };
    }
  }, [token, delayedToDate]);
  return (
    <Router>
      {localStorage.getItem("machineId") ?
        <>
        </>
        :
        <>
          {localStorage.getItem("isVerified") === "true" &&
            (localStorage.getItem("isSubscribed") === "true" ||
              localStorage.getItem("isSubscribedManu") === "true" ||
              localStorage.getItem("IsSubscribedBeforeManu") === "true" ||
              localStorage.getItem("isGuest") === "true" ||
              localStorage.getItem("isTeamMember") === "true" ||
              localStorage.getItem("IsFreePlanActivated") === "true" ||
              localStorage.getItem("IsSubscribedBefore") === "true") &&
            showSaaSNavbar ? (
            <SaasNavbar
              active={active}
              setActive={setActive}
              active5={active5}
              setActive5={setActive5}
              setupProfile={setupProfile}
              setSetupProfile={setSetupProfile}
              setSetupMenu={setSetupMenu}
              booked={booked}
              setBooked={setBooked}
              naved={naved}
              setNaved={setNaved}
              setScrolled={setScrolled}
              scrolled={scrolled}
              register={register}
              isLoggedIn={isLoggedIn}
              setIsLoggedIn={setIsLoggedIn}
              accounType={accounType}
              accountName={accountName}
              setIsSubscribed={setIsSubscribed}
              setIsVerified={setIsVerified}
              account={account}
              setAccount={setAccount}
              setToken={setToken}
              isSubscribed={isSubscribed}
              setAccountName={setAccountName}
              setProfileSrc={setProfileSrc}
              profileSrc={profileSrc}
            />
          ) : (
            <Navbar
              terms={terms}
              setTerms={setTerms}
              booked={booked}
              setBooked={setBooked}
              naved={naved}
              setNaved={setNaved}
              setScrolled={setScrolled}
              scrolled={scrolled}
              register={register}
              isLoggedIn={isLoggedIn}
              setIsLoggedIn={setIsLoggedIn}
              accounType={accounType}
              accountName={accountName}
              setIsSubscribed={setIsSubscribed}
              setIsVerified={setIsVerified}
              account={account}
              setAccount={setAccount}
              setToken={setToken}
              isSubscribed={isSubscribed}
              setAccountName={setAccountName}
              setProfileSrc={setProfileSrc}
              profileSrc={profileSrc}
            />
          )}
        </>
      }
      <AxiosInterceptor>
        <Navigate
          terms={terms}
          setTerms={setTerms}
          token={token}
          booked={booked}
          setBooked={setBooked}
          naved={naved}
          setNaved={setNaved}
          setScrolled={setScrolled}
          scrolled={scrolled}
          register={register}
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          accounType={accounType}
          accountName={accountName}
          setIsSubscribed={setIsSubscribed}
          setIsVerified={setIsVerified}
          account={account}
          setAccount={setAccount}
          setToken={setToken}
          isSubscribed={isSubscribed}
          setAccountName={setAccountName}
          setProfileSrc={setProfileSrc}
          profileSrc={profileSrc}
          isVerified={isVerified}
          setRegister={setRegister}
          setLoading={setLoading}
          setContactUspricing={setContactUspricing}
          active={active}
          setActive={setActive}
          active5={active5}
          setActive5={setActive5}
          setupProfile={setupProfile}
          setupMenu={setupMenu}
          setSetupProfile={setSetupProfile}
          setSetupMenu={setSetupMenu}
          setCn={setCn}
          cn={cn}
          setShowSaaSNavbar={setShowSaaSNavbar}
        />
      </AxiosInterceptor>
      {!register ? (
        <>
          {
            localStorage.getItem("machineId") ?
              <></>
              :
              <>
                <Footer isLoggedIn={isLoggedIn} />
                <CopyRight />
              </>
          }

        </>
      ) : (
        <></>
      )}
      <LoadingModal loading={loading} />
      <TipsModal
        openModal={OpenTipsModal}
        setOpenModal={setOpenTipsModal}
        tipMessage={tipMessage}
        setdelayedToDate={setdelayedToDate}
        modify={modify}
        setModify={setModify}
      />
    </Router>
    // <Router>
    //   <Routes>
    //     <Route path="*" element={<UnderMaintenance />} />
    //   </Routes>
    // </Router>
  );
}
export default App;
