import * as constFile from "../../FactoryConsts";
import axios from "axios";
const FormData = require("form-data");
const CREATE_OR_EDIR_DATA_MACHINE = constFile.CREATE_OR_EDIR_DATA_MACHINE;

export const getMachineBlockInputData = (blockId, BayId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + CREATE_OR_EDIR_DATA_MACHINE}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        BlockId: blockId,
        BayId: BayId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
const UPLOAD_FILE_MACHINE = constFile.UPLOAD_FILE_MACHINE;
export const uploadFileMachine = (file, BayId) => {
  const token = localStorage.getItem("accessToken");
  const data = new FormData();
  data.append("File", file);
  data.append("BayId", BayId);
  return axios
    .post(`${process.env.REACT_APP_URL + UPLOAD_FILE_MACHINE}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const DELETE_FILE_MACHINE = constFile.DELETE_FILE_MACHINE;
export const deleteFileMachine = (fileId, BayId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + DELETE_FILE_MACHINE}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        FileId: fileId,
        BayId: BayId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const CREATE_MACHINE = constFile.CREATE_MACHINE;

export const createMachine = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + CREATE_MACHINE}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const UPDATE_MACHINE = constFile.UPDATE_MACHINE;

export const updateMachine = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + UPDATE_MACHINE}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const HOLDED_ORDERS_MACHINE = constFile.HOLDED_ORDERS_MACHINE;

export const holdedOrdersOnMachine = (Id) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + HOLDED_ORDERS_MACHINE}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        Id: Id,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
const SET_BACKUP_MACHINE = constFile.SET_BACKUP_MACHINE;

export const setBackupMachine = (Id, BackUpMachineId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + SET_BACKUP_MACHINE}`,
      {
        Id: Id,
        BackUpMachineId: BackUpMachineId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
