import * as constFile from "../../TransportConst";
import axios from "axios";

//#region driver
const GET_DRIVER_LIST = constFile.GET_DRIVER_LIST;
export const getDriverList = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + GET_DRIVER_LIST}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const GET_DRIVER_INPUT_DATA = constFile.GET_DRIVER_INPUT_DATA;
export const getDriverInputData = (driverId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_DRIVER_INPUT_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        driverId: driverId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const CREATE_DRIVER = constFile.CREATE_DRIVER;
export const createDriver = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + CREATE_DRIVER}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const UPDATE_DRIVER = constFile.UPDATE_DRIVER;
export const updateDriver = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + UPDATE_DRIVER}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const CHANGE_DRIVER_STATUS = constFile.CHANGE_DRIVER_STATUS;
export const changeDriverStatus = (Id, StatusId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + CHANGE_DRIVER_STATUS}`,
      {
        Id: Id,
        StatusId: StatusId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
//#endregion

//#region trailer
const GET_TRAILER_LIST = constFile.GET_TRAILER_LIST;
export const getTrailerList = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + GET_TRAILER_LIST}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const GET_TRAILER_INPUT_DATA = constFile.GET_TRAILER_INPUT_DATA;
export const getTrailerInputData = (TrailerId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_TRAILER_INPUT_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        TrailerId: TrailerId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const CREATE_TRAILER = constFile.CREATE_TRAILER;
export const createTrailer = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + CREATE_TRAILER}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const UPDATE_TRAILER = constFile.UPDATE_TRAILER;
export const updateTrailer = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + UPDATE_TRAILER}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const CHANGE_TRAILER_STATUS = constFile.CHANGE_TRAILER_STATUS;
export const changeTrailerStatus = (Id, StatusId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + CHANGE_TRAILER_STATUS}`,
      {
        Id: Id,
        StatusId: StatusId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
//#endregion

//#region truck
const GET_TRUCK_LIST = constFile.GET_TRUCK_LIST;
export const getTruckList = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + GET_TRUCK_LIST}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const GET_TRUCK_INPUT_DATA = constFile.GET_TRUCK_INPUT_DATA;
export const getTruckInputData = (truckId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_TRUCK_INPUT_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        truckId: truckId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const CREATE_TRUCK = constFile.CREATE_TRUCK;
export const createTruck = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + CREATE_TRUCK}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const UPDATE_TRUCK = constFile.UPDATE_TRUCK;
export const updateTruck = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + UPDATE_TRUCK}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const CHANGE_TRUCK_STATUS = constFile.CHANGE_TRUCK_STATUS;
export const changeTruckStatus = (Id, StatusId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + CHANGE_TRUCK_STATUS}`,
      {
        Id: Id,
        StatusId: StatusId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
//#endregion

//getstatus
const GET_DRIVER_STATUSES = constFile.GET_DRIVER_STATUSES;
export const getDriverStatusesList = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_DRIVER_STATUSES}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
const GET_STATUSES = constFile.GET_STATUSES;
export const getStatusesList = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_STATUSES}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};
