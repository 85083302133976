import React, { useState, useEffect } from "react";
import classes from "./UserRolesTable.module.css";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
//import styled from "@emotion/styled";
//import Card from "./Card";
import * as permissionServices from "../../../Services/permissions-group.proxy";
const getUserRolesList = permissionServices.getUserRolesList;
const UserRolesTable = (props) => {
  const navigate = useNavigate();

  // const CardContainer = styled`
  // border-radius: 5px;
  // background-color: #fff;
  // `;

  //const [permission, setPermission] = useState("");
  const [usersRoles, setUsersRoles] = useState([]);
  const [search, setSearch] = useState("");
  const [field, setField] = useState(null);
  const [asc, setAsc] = useState(null);
  const data = {
    Search: search,
    SortString: {
      Field: field,
      Asc: asc,
    },
  };
  useEffect(() => {
    let apiTest = true;

    getUserRolesList(data).then((x) => {
      if (apiTest) {
        setUsersRoles(x.PermissionGroups);
      }
    });

    return () => {
      apiTest = false;
    };
  }, []);
  return (
    <>
      {usersRoles ? (
        <Grid container direction="column">
          <Grid
            item
            container
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
          >
            <Grid
              item
              className={classes.tableTitle}
              paddingLeft={props.RoleFontSize === "18px" ? "10%" : "1%"}
            >
              User Roles
            </Grid>
            {props.RoleFontSize === "18px" ? (
              <Grid item paddingRight={"10%"}>
                <button
                  className={classes.back}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                    e.target.style.backgroundColor = "#f5f5f5";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#fff";
                  }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Go Back
                </button>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
          <Grid
            container
            direction={"row"}
            paddingLeft={props.RoleFontSize === "18px" ? "10%" : "5%"}
            className={classes["RowsTitles"]}
            fontSize={props.RoleFontSize}
            marginTop={2}
          >
            <Grid item lg={2.8} xs={6} md={2.8} sm={3.5}>
              Default Role Group
            </Grid>
            <Grid item lg={5.3} xs={4} md={5.3} sm={4.2}>
              Description
            </Grid>
            <Grid item lg={4} xs={0} md={3.5} display={{ xs: "none", sm: "contents" }}>
              Permissions
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction={"column"}
            alignItems="center"
            borderRadius={5}
            bgcolor={props.bgColorRoles}
          >
            {usersRoles.map((r) =>
              r.IsSystem ? (
                <Grid
                  key={r.Id}
                  item
                  container
                  direction={"row"}
                  className={classes.Rows}
                  marginTop={2}
                  width={props.CardWidth}
                  alignItems="center"
                  spacing={1}
                >
                  <Grid
                    item
                    className={classes.RoleGroup}
                    fontSize={props.RoleFontSize}
                    lg={3}
                    md={2.9}
                    xs={6}
                    sm={3.7}
                  >
                    {r.Name}
                  </Grid>
                  <Grid item className={classes.Description} lg={6} xs={5} md={6} sm={4.8}>
                    {r.Description}
                  </Grid>

                  <Grid item lg={3} xs={12} md={2} sm={3}>
                    <button
                      className={classes.PermissionButton}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                        e.target.style.backgroundColor = "#0674b9";
                        e.target.style.color = "#fff";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "#fff";
                        e.target.style.color = "#0674b9";
                      }}
                      onClick={() => {
                        props.setUserRoleId(r.Id);
                        props.setOpenModal(true);
                        props.setShowEdit(false);
                      }}
                    >
                      View Permission
                    </button>
                  </Grid>
                </Grid>
              ) : (
                ""
              )
            )}
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};

export default UserRolesTable;
