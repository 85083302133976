import React, { useEffect, useState } from "react";
import Form1 from "./Form1";
import Form2 from "./Form2";
import Form3 from "./Form3";
import Box from "@mui/material/Box";
import Animation from "../../Home/Animation";
import * as constFile from "../../../pileCubesConst";
import axios from "axios";
import VerifyCode from "../VerifyCode/VerifyCode";
const INPUT_API = constFile.INPUT_DATA;
const INPUT_API2 = constFile.INPUT_DATA2;

function SubContractor({
  setLoading,
  setToken,
  isVerified,
  setIsVerified,
  setAccountName,
  inviterTenantId,
  projectId,
  subProjectId,
  tenantId,
}) {
  const [countries, setCountries] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [employeesRanges, setEmployeesRanges] = useState([]);
  const [industryType, setIndustryType] = useState([]);
  const [engineeringServices, setEngineeringServices] = useState([]);
  const [highlightError, setHighlightError] = useState(false);
  const [highlightMsg, setHighlightMsg] = useState("");
  const [disciplineCovered, setDisciplineCovered] = useState([]);
  const [trade, setTrade] = useState([]);
  const [registrationEmail, setRegistrationEmail] = useState("");

  useEffect(() => {
    //setLoading(true);
    let isApiSubscribed = true;
    axios
      .get(`${process.env.REACT_APP_URL + INPUT_API}`)
      .then((response) => {
        if (isApiSubscribed) {
          setCountries(response.data["Countries"]);
          setSpecialities(response.data["Specialities"]);
          setEmployeesRanges(response.data["EmployeesRanges"]);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
    return () => {
      // cancel the subscription
      isApiSubscribed = false;
    };
  }, []);
  useEffect(() => {
    //setLoading(true);
    let isApiSubscribed = true;
    axios
      .get(`${process.env.REACT_APP_URL + INPUT_API2}`, {
        params: { tenantId: tenantId },
      })
      .then((response) => {
        if (isApiSubscribed) {
          setIndustryType(response.data["IndustryType"]);
          setDisciplineCovered(response.data["DisciplineCovered"]);
          setTrade(response.data["Trades"]);
          setEngineeringServices(
            response.data.IndustryType.find(
              (x) => x.Name === "Engineering Services"
            ).SubIndustryTypes
          );
          if (tenantId)
            setRegistrationEmail(
              response.data.SubContactorCompanyDetails.RegistrationEmail
            );
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
    return () => {
      // cancel the subscription
      isApiSubscribed = false;
    };
  }, []);

  const [step, setStep] = useState(1);
  useEffect(() => {
    if (isVerified === "false") {
      setStep(4);
    }
  }, [isVerified]);

  useEffect(() => {
    if (highlightError === true) {
      setStep(1);
    }
  }, [highlightError]);

  const [info, setInfo] = useState({
    TenantId: tenantId,
    Name: "",
    RegistrationNumber: "",
    Address: "",
    CompanyPhoneNumber: "+961",
    AgreementAcceptance: false,
    CountryId: "",
    DisciplineCoveredId: "",
    DisciplineCoveredOther: "",
    ShowDisciplineCoveredOther: false,
    TradedId: "",
    TradeOther: "",
    ShowTradeOther: false,
    IndustryTypeId: "",
    SubIndustryTypeId: null,
    ShowSubIndustryType: false,
    subTypesData: [],
    IndustryTypeOther: "",
    ShowIndustryTypeOther: false,

    EmployeesRangeId: "",
    User: {
      UserId: null,
      Password: "",
      Email: "",
      UserDetails: {
        Name: "",
        PersonalEmail: "",
        Position: "",
        PhoneNumber: "",
        UserId: null,
      },
    },
    InviterTenantId: inviterTenantId,
    ProjectId: projectId,
    SubProjectId: subProjectId,
  });
  const Forms = ({ step }) => {
    switch (step) {
      case 1:
        return (
          <Form1
            continues={continues}
            countries={countries}
            info={info}
            highlightMsg={highlightMsg}
            highlightError={highlightError}
            setHighlightError={setHighlightError}
            tenantId={tenantId}
            registrationEmail={registrationEmail}
            setRegistrationEmail={setRegistrationEmail}
          />
        );
      case 2:
        return (
          <Form2
            continues={continues}
            back={back}
            specialities={specialities}
            employeesRanges={employeesRanges}
            countries={countries}
            info={info}
            industryType={industryType}
            engineeringServices={engineeringServices}
            disciplineCovered={disciplineCovered}
            trade={trade}
          />
        );
      case 3:
        return (
          <Form3
            continues={continues}
            back={back}
            countries={countries}
            info={info}
            setLoading={setLoading}
            setToken={setToken}
            setHighlightMsg={setHighlightMsg}
            setHighlightError={setHighlightError}
            setAccountName={setAccountName}
            tenantId={tenantId}
            registrationEmail={registrationEmail}
            inviterTenantId={inviterTenantId}
            projectId={projectId}
            subProjectId={subProjectId}
          />
        );
      case 4:
        return (
          <VerifyCode
            setLoading={setLoading}
            setIsVerified={setIsVerified}
            setToken={setToken}
          />
        );
      default:
        return "nothing";
    }
  };

  const continues = (e) => {
    e.preventDefault();
    setStep(step + 1);
  };
  const back = (e) => {
    e.preventDefault();
    setStep(step - 1);
  };
  return (
    <>
      {countries.length > 0 &&
      specialities.length > 0 &&
      employeesRanges.length > 0 &&
      trade.length > 0 &&
      disciplineCovered.length > 0 &&
      engineeringServices.length > 0 &&
      industryType.length > 0 ? (
        <Forms step={step} />
      ) : (
        <Box
          style={{
            width: 450,
            height: 460,
            padding: "45px 27.9px 0 26px",
            opacity: 0.79,
            boxShadow: "0 6px 12px 0 rgba(0, 0, 0, 0.16)",
            border: "solid 1px #eaf0f5",
            backgroundColor: "#fff",
          }}
        >
          <Animation />
        </Box>
      )}
    </>
  );
}

export default SubContractor;
