import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import classes from "../../Assets/Styles/Dashboard/Production.module.css";
import * as services from "../../Services/machine-dashboard-services.proxy";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
const holdMachine = services.holdMachine;

const style = {
  position: "relative",
  transform: "translate(-50%, -50%)",
  bgcolor: "#c2dcf2",
  top: "50%",
  left: "50%",
  boxShadow: "0 3px 6px 0 #707070",
  display: "block",
  border: 0,
  outline: "none",
  borderRadius: "5px",
};

const HoldMachineModal = (props) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    props.setOpenModal(false);
  };
  useEffect(() => {
    if (props.openModal) {
      handleOpen(true);
    }
  }, [props.openModal]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
        disableScrollLock
        keepMounted
      >
        <Grid sx={style} width={{ xs: "75%", md: "28%" }} padding={2}>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            item
            fontSize={18}
            fontFamily={"Muli"}
            fontWeight={"bold"}
          >
            Are you sure you want to hold this machine?
          </Grid>
          <Grid
            item
            direction={"row"}
            container
            justifyContent={"center"}
            alignItems={"center"}
            paddingTop={5}
            gap={10}
          >
            <button
              className={classes["cancel-button"]}
              onClick={() => {
                console.log("hii    ");
                handleClose();
              }}
            >
              Cancel
            </button>

            <button
              className={classes["save-button"]}
              onClick={() => {
                holdMachine(
                  localStorage.getItem("machineId"),
                  localStorage.getItem("UserId")
                ).then(() => {
                  navigate("/machine/connect");
                  handleClose();
                });
              }}
            >
              Hold
            </button>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

export default HoldMachineModal;
