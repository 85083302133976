import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function BasicCard(props) {
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  const navigate = useNavigate();

  const [hoverSub, setHoverSub] = useState(false);

  useEffect(() => {
    // side effects
    let apiTest = true;
    if (apiTest) {
      props.setProjectId(props.data.Id);
    }

    // cleanup
    return () => {
      apiTest = false;
    };
  }, [props]);

  return (
    <Grid
      container
      sx={{
        boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
        minWidth: 220,
        minHeight: 160,
        opacity: props.assigned ? 0.5 : 1,
      }}
    >
      <Grid
        item
        xs={1}
        sx={{
          minHeight: 160,
          backgroundColor:
            props.index === -1 && props.assigned === false
              ? "#e1e319"
              : props.index === -1 && props.assigned === true
              ? "#0674b9"
              : props.index % 4 === 0
              ? "#e1e319"
              : (props.index + 1) % 4 === 0
              ? "#101650"
              : (props.index + 2) % 4 === 0
              ? "#0674b9"
              : "#5fba47",
          borderBottomLeftRadius: 5,
          borderTopLeftRadius: 5,
        }}
      ></Grid>
      <Grid item xs={11}>
        <Card
          sx={{
            boxShadow: 0,
            minHeight: 160,
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
          }}
        >
          <CardContent>
            <Typography
              fontFamily="Muli"
              fontSize={14}
              lineHeight={1.1}
              fontWeight="bold"
              textAlign="left"
              color="#101650"
              gutterBottom
              height={30}
            >
              {props.data.Name.length > 54
                ? props.data.Name.slice(0, 54) + "..."
                : props.data.Name}
            </Typography>
            <div
              style={{
                fontFamily: "Muli",
                textAlign: "left",
                color: "#707070",
                paddingTop: 5,
              }}
            >
              <Typography fontSize={11} paddingBottom={0.5}>
                {props.data.ClientName}
              </Typography>
              <Grid
                container
                justifyContent="space-between"
                paddingBottom={0.5}
              >
                <Grid item fontSize={11}>
                  Phases
                </Grid>
                <Grid
                  item
                  sx={{
                    width: 27,
                    height: 14,
                    borderRadius: 7,
                    backgroundColor: "#f1f1f1",
                    fontSize: 12,
                    textAlign: "center",
                  }}
                >
                  {props.data.PhaseCount}
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                paddingBottom={0.5}
              >
                <Grid item fontSize={11}>
                  Sub Projects
                </Grid>
                <Grid
                  item
                  sx={{
                    width: 27,
                    height: 14,
                    borderRadius: 7,
                    backgroundColor: "#f1f1f1",
                    fontSize: 12,
                    textAlign: "center",
                  }}
                >
                  {props.data.SubProjectCount}
                </Grid>
              </Grid>
            </div>
            {props.index === -1 ? (
              <div
                onMouseOver={(e) => (e.target.style.cursor = "pointer")}
                style={{
                  color: "#0674b9",
                  fontSize: 11,
                  fontFamily: "Muli",
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                {props.assigned ? (
                  <>Assigned project will appear here</>
                ) : (
                  <>Create your 1st project here</>
                )}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: "1%",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {localStorage.getItem("isSubscribed") === "true" ||
                (localStorage.getItem("isTeamMember") === "true" &&
                  (localStorage.getItem("companyPlanId")?.includes("Core") ||
                    localStorage.getItem("companyPlanId")?.includes("Pro"))) ||
                localStorage.getItem("isGuest") === "true" ? (
                  <div
                    onMouseOver={(e) => {
                      if (localStorage.getItem("IsSubscribedBefore") === "true")
                        e.target.style.cursor = "unset";
                      else e.target.style.cursor = "pointer";
                    }}
                    style={{
                      color: "#0674b9",
                      fontSize: 11,
                      fontFamily: "Muli",
                      fontWeight: "bold",
                      textAlign: "left",
                    }}
                    onClick={() => {
                      if (localStorage.getItem("IsSubscribedBefore") === "true")
                        console.log("");
                      else
                        navigate(
                          `/saas/projects/milesstones?id=${props.data.Id}`
                        );
                    }}
                  >
                    View milestones
                  </div>
                ) : (
                  ""
                )}
                <div
                  onMouseOver={(e) => (e.target.style.cursor = "pointer")}
                  style={{
                    color: "#0674b9",
                    fontSize: 11,
                    fontFamily: "Muli",
                    fontWeight: "bold",
                    textAlign: "left",
                    marginLeft:
                      localStorage.getItem("isSubscribed") !== "true"
                        ? "1%"
                        : "5%",
                  }}
                  onClick={() => {
                    navigate(`/saas/projects/dashboard?id=${props.data.Id}`);
                  }}
                >
                  View project tree
                </div>
              </div>
            )}
          </CardContent>
          <Grid
            sx={{
              borderTop: "solid 1px #e1e319",
            }}
          ></Grid>
          {props.index === -1 ? (
            <Grid height={25.4}></Grid>
          ) : (
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              padding="2% 10% 2% 5%"
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
                setHoverSub(true);
              }}
              onMouseLeave={() => setHoverSub(false)}
              onClick={() => {
                if (
                  localStorage.getItem("IsSubscribedBefore") === "true" ||
                  ((localStorage.getItem("isGuest") === "true" ||
                    !props.data.IsCreator) &&
                    localStorage.getItem("AccountType") !== "1") ||
                  (localStorage.getItem("isTeamMember") === "true" &&
                    !UserPermissions?.find(
                      (x) => x.Name === "Project Manager"
                    ) &&
                    !UserPermissions?.find((x) => x.Name === "Admin") &&
                    !UserPermissions?.find(
                      (x) => x.Name === "Engineering Manager"
                    ))
                ) {
                  for (let i = 0; i < UserPermissions?.length; i++) {
                    for (
                      let j = 0;
                      j < UserPermissions[i].Permissions.length;
                      j++
                    ) {
                      if (
                        UserPermissions[i].Permissions[j].Name === "Sub Project"
                      ) {
                        if (
                          UserPermissions[i].Permissions[j].Actions.find(
                            (a) => a.Name === "Create"
                          ) &&
                          !(
                            (localStorage.getItem("isGuest") === "true" ||
                              !props.data.IsCreator) &&
                            localStorage.getItem("AccountType") !== "1"
                          ) &&
                          localStorage.getItem("IsSubscribedBefore") !== "true"
                        ) {
                          localStorage.removeItem("subId");
                          localStorage.removeItem("phaseId");
                          localStorage.removeItem("phaseName");
                          localStorage.removeItem("viewSubPro");
                          localStorage.setItem(
                            "projectCreator",
                            props.data.IsCreator
                          );
                          navigate(
                            `/saas/projects/subprojects?id=${props.data.Id}`
                          );
                          props.setDisabled(false);
                          props.setSubRule(false);
                          break;
                        } else {
                          props.setDisabled(true);
                          props.setSubRule(true);
                        }
                      } else {
                        props.setDisabled(true);
                        props.setSubRule(true);
                      }
                    }
                  }
                } else {
                  localStorage.removeItem("subId");
                  localStorage.removeItem("phaseId");
                  localStorage.removeItem("phaseName");
                  localStorage.removeItem("viewSubPro");
                  localStorage.setItem("projectCreator", props.data.IsCreator);
                  navigate(`/saas/projects/subprojects?id=${props.data.Id}`);
                }
              }}
            >
              <Grid
                item
                sx={{
                  fontFamily: "Muli",
                  fontSize: 11,
                  fontStyle: "italic",
                  lineHeight: 1.45,
                  textAlign: "left",
                  color: "#5fba47",
                  fontWeight: hoverSub ? "bold" : "normal",
                }}
                paddingBottom="2%"
              >
                Create a subproject
              </Grid>
              <Grid
                item
                sx={{
                  fontFamily: "Muli",
                  fontSize: 14,
                  fontWeight: "bold",
                  lineHeight: 1.43,
                  textAlign: "left",
                  color: "#5fba47",
                  fontWeight: hoverSub ? "bold" : "normal",
                }}
                paddingBottom="2%"
              >
                +
              </Grid>
            </Grid>
          )}
        </Card>
      </Grid>
    </Grid>
  );
}
