import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import mc from "../../Assets/Images/home/mc-icon.png";
import sc from "../../Assets/Images/home/sc-icon.png";
import check from "../../Assets/Images/home/check.png";
import checkmc from "../../Assets/Images/home/mc.png";
import checksc from "../../Assets/Images/home/sc.png";
import window from "../../Components/Navbar/WindowDimension";

export default function Choose() {
  const [chosen, setChosen] = useState(false);
  const [chosen1, setChosen1] = useState(false);
  const navigate = useNavigate();
  const [mobile, setMobile] = useState(false);
  const { width } = window();

  useEffect(() => {
    if (width < 900) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  });

  return (
    <Grid container spacing={5} paddingBottom={"10%"}>
      <Grid item md={4} xs={12} container direction={"column"} spacing={2}>
        <Grid item>
          <div className="Choose-your-Profile">Choose your Profile</div>
        </Grid>
        <Grid item>
          <div className="Profile-desc">
            Contractors are the core of construction. Join Pilecubes and manage
            your projects online.
          </div>
        </Grid>
      </Grid>
      <Grid item md={8} xs={12} container spacing={2}>
        <Grid item md={6} xs={6} container direction={"column"} spacing={1}>
          {mobile ? (
            <Grid
              item
              container
              direction={"row"}
              onClick={() => navigate("/main")}
            >
              <Grid item xs={12}>
                <img src={mc} alt="mc" width="100%" />
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "50%",
                    marginLeft: "5%",
                  }}
                >
                  <div className="ChooseMe">Choose Me</div>
                  <div
                    style={{
                      height: 0,
                      backgroundColor: "#e1e319",
                      border: "solid 0.1px #e1e319",
                    }}
                  ></div>
                </div>
              </Grid>
            </Grid>
          ) : (
            <Grid item>
              <img
                src={mc}
                alt="mc"
                width={189.5}
                height={189.5}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                  setChosen(true);
                }}
                onMouseLeave={() => setChosen(false)}
                onClick={() => navigate("/main")}
              />
            </Grid>
          )}
          <Grid item>
            <div className="ellipseMC" style={{ marginLeft: "5%" }}>
              MC
            </div>
          </Grid>
          <Grid item>
            <div
              className="Main-text"
              style={{ marginLeft: "5%", lineHeight: 1 }}
            >
              Main Contractor
            </div>
          </Grid>
          {!mobile ? (
            <Grid item>
              <div
                onMouseOver={(e) => {
                  setChosen(true);
                  e.target.style.cursor = "pointer";
                }}
                onMouseLeave={(e) => setChosen(false)}
                onClick={() => navigate("/main")}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "30%",
                  gap: "5%",
                  marginLeft: "5%",
                }}
              >
                {chosen ? (
                  <div
                    className="chooseRadio"
                    style={{
                      border: "solid 3px #e1e319",
                      borderRadius: 50,
                      backgroundColor: "#e1e319",
                    }}
                  ></div>
                ) : (
                  <div
                    className="chooseRadio"
                    style={{
                      border: "solid 3px #e1e319",
                      borderRadius: 50,
                    }}
                  ></div>
                )}
                <div
                  style={{
                    display: "felx",
                    flexDirection: "column",
                    marginLeft: "5%",
                  }}
                >
                  <div className="ChooseMe">Choose Me</div>
                  <div
                    style={{
                      height: 0,
                      backgroundColor: "#e1e319",
                      border: "solid 0.1px #e1e319",
                    }}
                  ></div>
                </div>
              </div>
            </Grid>
          ) : (
            <Grid item></Grid>
          )}
          <Grid item container spacing={1} marginLeft={"3%"}>
            <Grid item container md={12} alignItems={"center"}>
              <Grid item>
                <div className="manageCheck">With subscription to Engineering, you get:</div>
              </Grid>
            </Grid>
          
            <Grid item container md={12} alignItems={"center"}>
              <Grid item md={1} xs={2}>
                <img src={check} alt="check"></img>
              </Grid>
              <Grid item md={11} xs={10}>
                <div className="manageCheck">
                  Manage multiple engineering disciplines
                </div>
              </Grid>
            </Grid>
            <Grid item container md={12} alignItems={"center"}>
              <Grid item md={1} xs={2}>
                <img src={check} alt="check"></img>
              </Grid>
              <Grid item md={11} xs={10}>
                <div className="manageCheck">
                  Invite multiple subcontractors
                </div>
              </Grid>
            </Grid>
            <Grid item container md={12} alignItems={"center"}>
              <Grid item md={1} xs={2}>
                <img src={check} alt="check"></img>
              </Grid>
              <Grid item md={11} xs={10}>
                <div className="manageCheck">Manage internal teams</div>
              </Grid>
            </Grid>
            <Grid item container md={12} alignItems={"center"}>
              <Grid item md={1} xs={2}>
                <img src={check} alt="check"></img>
              </Grid>
              <Grid item md={11} xs={10}>
                <div className="manageCheck">Manage multiple projects</div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} xs={6} container direction={"column"} spacing={1}>
          {mobile ? (
            <Grid
              item
              container
              direction={"row"}
              onClick={() => navigate("/sub")}
              marginLeft={4}
            >
              <Grid item xs={12}>
                <img src={sc} alt="sc" width="100%" />
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "50%",
                    marginLeft: "5%",
                  }}
                >
                  <div className="ChooseMe">Choose Me</div>
                  <div
                    style={{
                      height: 0,
                      backgroundColor: "#2da4d0",
                      border: "solid 0.1px #2da4d0",
                    }}
                  ></div>
                </div>
              </Grid>
            </Grid>
          ) : (
            <Grid item>
              <img
                src={sc}
                alt="sc"
                width={189.5}
                height={189.5}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                  setChosen1(true);
                }}
                onMouseLeave={() => setChosen1(false)}
                onClick={() => navigate("/sub")}
              />
            </Grid>
          )}
          {!mobile ? (
            <Grid item>
              <div className="ellipseSC" style={{ marginLeft: "5%" }}>
                SC
              </div>
            </Grid>
          ) : (
            <Grid item marginLeft={5.5}>
              <div className="ellipseSC">SC</div>
            </Grid>
          )}
          {!mobile ? (
            <Grid item>
              <div className="Main-text" style={{ marginLeft: "5%" }}>
                Sub Contractor
              </div>
            </Grid>
          ) : (
            <Grid item marginLeft={5.5}>
              <div className="Main-text" style={{ lineHeight: 1 }}>
                Sub Contractor
              </div>
            </Grid>
          )}
          {!mobile ? (
            <Grid item>
              <div
                onMouseOver={(e) => {
                  setChosen1(true);
                  e.target.style.cursor = "pointer";
                }}
                onMouseLeave={(e) => setChosen1(false)}
                onClick={() => navigate("/sub")}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "30%",
                  gap: "5%",
                  marginLeft: "5%",
                }}
              >
                {chosen1 ? (
                  <div
                    className="chooseRadio"
                    style={{
                      border: "solid 3px #2da4d0",
                      borderRadius: 50,
                      backgroundColor: "#2da4d0",
                    }}
                  ></div>
                ) : (
                  <div
                    className="chooseRadio"
                    style={{
                      border: "solid 3px #2da4d0",
                      borderRadius: 50,
                    }}
                  ></div>
                )}
                <div
                  style={{
                    display: "felx",
                    flexDirection: "column",
                    marginLeft: "5%",
                  }}
                >
                  <div className="ChooseMe">Choose Me</div>
                  <div
                    style={{
                      height: 0,
                      backgroundColor: "#2da4d0",
                      border: "solid 0.1px #2da4d0",
                    }}
                  ></div>
                </div>
              </div>
            </Grid>
          ) : (
            <Grid item></Grid>
          )}
          <Grid item container spacing={1} marginLeft={{ md: "3%", xs: 5.2 }}>
            <Grid item container md={12} alignItems={"center"}>
              <Grid item>
                <div className="manageCheck">With subscription, you get:</div>
              </Grid>
            </Grid>
            <Grid item container md={12} alignItems={"center"}>
              <Grid item md={1} xs={2}>
                <img src={check} alt="check"></img>
              </Grid>
              <Grid item md={11} xs={10}>
                <div className="manageCheck">Manage multiple projects</div>
              </Grid>
            </Grid>
       
            <Grid item container md={12} alignItems={"center"}>
              <Grid item md={1} xs={2}>
                <img src={check} alt="check"></img>
              </Grid>
              <Grid item md={11} xs={10}>
                <div className="manageCheck">
                  Invite multiple main contractors
                </div>
              </Grid>
            </Grid>
            <Grid item container md={12} alignItems={"center"}>
              <Grid item md={1} xs={2}>
                <img src={check} alt="check"></img>
              </Grid>
              <Grid item md={11} xs={10}>
                <div className="manageCheck">Manage internal teams</div>
              </Grid>
            </Grid>
            <Grid item container md={12} alignItems={"center"}>
              <Grid item md={1} xs={2}>
                <img src={check} alt="check"></img>
              </Grid>
              <Grid item md={11} xs={10}>
                <div className="manageCheck">
                  Manage multiple subcontractors
                </div>
              </Grid>
            </Grid>
            <Grid item container md={12} alignItems={"center"}>
              <Grid item md={1} xs={2}>
                <img src={check} alt="check"></img>
              </Grid>
              <Grid item md={11} xs={10}>
                <div className="manageCheck">Manage multiple disciplines</div>
              </Grid>
            </Grid>
            <Grid item container md={12} alignItems={"center"}>
              <Grid item paddingTop={2}>
                <div className="manageCheck">
                  With subscription to Manufacturing, you get:
                </div>
              </Grid>
            </Grid>
            <Grid item container md={12} alignItems={"center"}>
              <Grid item md={1} xs={2}>
                <img src={check} alt="check"></img>
              </Grid>
              <Grid item md={11} xs={10}>
                <div className="manageCheck">Online Sales Order</div>
              </Grid>
            </Grid>
            <Grid item container md={12} alignItems={"center"}>
              <Grid item md={1} xs={2}>
                <img src={check} alt="check"></img>
              </Grid>
              <Grid item md={11} xs={10}>
                <div className="manageCheck">Production Label Management </div>
              </Grid>
            </Grid>
            <Grid item container md={12} alignItems={"center"}>
              <Grid item md={1} xs={2}>
                <img src={check} alt="check"></img>
              </Grid>
              <Grid item md={11} xs={10}>
                <div className="manageCheck">Delivery Note </div>
              </Grid>
            </Grid>
       
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
