import React, { useEffect, useState, useRef } from "react";
import "../../Assets/Styles/Pricing.css";
import useWindowDimensions from "../../Components/Navbar/WindowDimension";
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import EngineeringPlans from "./EngineerinPlans";
import classes from "./ManufacturingPricing/Manu.module.css";
import ManufacturingPricing from "./ManufacturingPricing/ManufacturingPricing";
function PlanSection({
  setLoading,
  accounType,
  isLoggedIn,
  setShowContactUsPricing,
}) {
  const { width } = useWindowDimensions();
  const [phoneView, setphoneView] = useState(false);
  const [pricingEngineering, setPricingEngineering] = useState(true);
  useEffect(() => {
    if (width < 1000) {
      setphoneView(true);
    } else {
      setphoneView(false);
    }
  });

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid item container md={12} spacing={2} marginTop={7}>
        <Grid
          item
          container
          md={12}
          xs={11}
          sm={10}
          spacing={2}
          alignItems="center"
        >
          {phoneView ? (
            <Grid item md={12} justifyContent="center" marginLeft={{ xs: 3 }}>
              <div className="titlePhone">
                <span className="text-style-1"> Pilecubes </span>
                is the best online construction management SaaS that is easy to
                use, saves you time and money.
              </div>
            </Grid>
          ) : (
            <>
              <Grid item md={12}>
                <div className="headline"> Choose your subscription plan</div>
              </Grid>
              <Grid item md={12}>
                <div className="subHeadline">
                  Pilecubes is the best online construction management SaaS that
                  is easy to use, saves you time and money.
                </div>
              </Grid>
            </>
          )}
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent={"center"}
          alignItems={"center"}
          direction={"row"}
          gap={2}
          marginTop={2}
        >
          <Grid
            item
            onClick={() => {
              setPricingEngineering(true);
            }}
            className={
              pricingEngineering
                ? classes["PricingTypeActive"]
                : classes["PricingTypeNotActive"]
            }
          >
            Engineering Plan
          </Grid>
          <Grid
            item
            onClick={() => {
              setPricingEngineering(false);
            }}
            className={
              pricingEngineering
                ? classes["PricingTypeNotActive"]
                : classes["PricingTypeActive"]
            }
          >
            Manufacturing Plan
          </Grid>
        </Grid>
        {pricingEngineering ? (
          <Grid item container marginTop={{ md: 2 }}>
            <EngineeringPlans
              setLoading={setLoading}
              accounType={accounType}
              isLoggedIn={isLoggedIn}
              setShowContactUsPricing={setShowContactUsPricing}
            />
          </Grid>
        ) : (
          <Grid item container marginTop={{ md: 2 }}>
            <ManufacturingPricing
              setLoading={setLoading}
              accounType={accounType}
              isLoggedIn={isLoggedIn}
              setShowContactUsPricing={setShowContactUsPricing}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default PlanSection;
