import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import classes from "./TeamMember.module.css";
import * as teamMemberServices from "../../../../Services/team-member-services.proxy";

import TeamMemberLogo from "../../../../Assets/Images/Table/TeamMemberProfile.png";
const getTMbyId = teamMemberServices.getTMbyId;

const TeamMemberDetails = (props) => {
  const [teamMemberDetails, setTeamMemberDetails] = useState({});

  useEffect(() => {
    if (props.teamMemberId) {
      getTMbyId(props.teamMemberId).then((x) => {
        setTeamMemberDetails(x);
      });
    }
  }, []);
  const renderFields = (FieldName, FieldData) => {
    return (
      <Grid item container gap={2} md={3.5}>
        <Grid md={12} item className={classes["FieldHeader"]}>
          {FieldName}
        </Grid>
        <Grid md={12} item className={classes["FieldData"]}>
          {FieldData}
        </Grid>
      </Grid>
    );
  };
  return (
    <Grid container>
      <Grid container item direction={"row"}>
        <Grid item md={1}></Grid>
      </Grid>
      <Grid container paddingLeft={4} paddingTop={5} gap={3}>
        <Grid container gap={2} md={3.5}>
          <Grid item md={12} xs={12} className={classes["FieldHeader"]}>
            Name
          </Grid>
          <Grid item md={12} xs={12} className={classes["FieldData"]}>
            {teamMemberDetails.Name}
          </Grid>
        </Grid>
        <Grid container gap={2} md={3.5}>
          <Grid md={12} item className={classes["FieldHeader"]}>
            Profile
          </Grid>
          <Grid md={12} item>
            <img
              src={
                teamMemberDetails.ProfilePicture
                  ? teamMemberDetails.ProfilePicture.URL
                  : TeamMemberLogo
              }
              style={{ width: 25 }}
              alt={"ptofile"}
            ></img>
          </Grid>
        </Grid>

        <Grid container gap={5}>
          {renderFields("Email", teamMemberDetails.Email)}
          {renderFields("Country", teamMemberDetails.Country)}
          {renderFields("Address", teamMemberDetails.Address)}
          {renderFields("Phone Number", teamMemberDetails.PhoneNumber)}

          {renderFields("Company Name", teamMemberDetails.Company)}

          {renderFields("Role", teamMemberDetails.UserGroupPermissions)}

          {renderFields(
            "Joining Date",
            teamMemberDetails.CreationTime?.slice(0, 10)
          )}
        </Grid>
      </Grid>
      <Grid item md={11.5} container direction={"row-reverse"} marginTop={5}>
        <button
          onMouseOver={(e) => {
            e.target.style.backgroundColor = "#0674b9";
            e.target.style.color = "#fff";
            e.target.style.cursor = "pointer";
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = "#fff";
            e.target.style.color = "#0674b9";
          }}
          onClick={() => {
            props.setViewDetails(false);
          }}
          className={classes["GoBack"]}
        >
          Go Back
        </button>
      </Grid>
    </Grid>
  );
};

export default TeamMemberDetails;