import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import homeBg from "../../Assets/Images/Dashboard/logInBgColor.png";
import DashNavBar from "./DashNavBar";
import Analyse from "./Analyse";

export default function Dashboard() {
  const [disableOnHold, setDisableOnHold] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid
      sx={{
        padding: "2% 3.5% 0 3.5%",
        backgroundImage: `url(${homeBg})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        paddingBottom: "10%",
        minHeight: "100vh",
      }}
    >
      <DashNavBar
        disableOnHold={disableOnHold}
        setDisableOnHold={setDisableOnHold}
      />
      <Analyse
        disableOnHold={disableOnHold}
        setDisableOnHold={setDisableOnHold}
      />
    </Grid>
  );
}
