import * as constFile from "../../pileCubesConst";
import {
  GET_TMPROFILE_DATA,
  UPDATE_TMPROFILE_DATA,
  GET_TMPROFILE_INPUT_DATA,
} from "../../saasApiConst";
import axios from "axios";

const FormData = require("form-data");
const MAIN_CONTRACTOR_DATA = constFile.MAIN_CONTRACTOR_DATA;
const MAIN_CONTRACTOR_EDIT_DATA = constFile.MAIN_CONTRACTOR_EDIT_DATA;
const UPDATE_ADDITIONAL_INFO_MC = constFile.UPDATE_ADDITIONAL_INFO_MC;
const UPDATE_ADDITIONAL_INFO_SC = constFile.UPDATE_ADDITIONAL_INFO_SC;

const UPDATE_PROFILE_PICTURE_MC = constFile.UPDATE_PROFILE_PICTURE_MC;
const DELETE_PROFILE_PICTURE_MC = constFile.DELETE_PROFILE_PICTURE_MC;
const SAVE_PROFILE_PICTURE_MC = constFile.SAVE_PROFILE_PICTURE_MC;
const UPLOAD_PROFILE = constFile.UPLOAD_PROFILE;
const UPDATE_PROFILE = constFile.UPDATE_PROFILE;
const DELETE_OLD_FILE = constFile.DELETE_OLD_FILE;
const CHANGE_PASSWORD = constFile.CHANGE_PASSWORD;
const SUB_CONTRACTOR_DATA = constFile.SUB_CONTRACTOR_DATA;
const DELETE_OLD_SUB_FILE = constFile.DELETE_OLD_SUB_FILE;
const SUB_CONTRACTOR_EDIT_DATA = constFile.SUB_CONTRACTOR_EDIT_DATA;
const UPLOAD_SUB_PROFILE = constFile.UPLOAD_SUB_PROFILE;
const UPDATE_SUB_PROFILE = constFile.UPDATE_SUB_PROFILE;
const REMOVE_PROFILE_PICTURE = constFile.REMOVE_PROFILE_PICTURE;
const MANAGED_PRODUCTS = constFile.MANAGED_PRODUCTS;
const CANCEL_SUBSCRIPTION = constFile.CANCEL_SUBSCRIPTION;
const CHANGE_SUBSCRIPTION = constFile.CHANGE_SUBSCRIPTION;

//GETTING MAIN AND SUB CONTRACTOR DATA
export const ContarctorDetails = () => {
  const token = localStorage.getItem("accessToken");
  const AccountType = localStorage.getItem("AccountType");
  let DATA_API = "";
  if (AccountType === "1") {
    DATA_API = MAIN_CONTRACTOR_DATA;
  } else if (AccountType === "2") {
    DATA_API = SUB_CONTRACTOR_DATA;
  }
  return axios
    .get(`${process.env.REACT_APP_URL + DATA_API}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {});
};

//GETITNG MAIN CONTRACTOR DATA FOR UPDATE
export const mainContarctorEditDetails = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + MAIN_CONTRACTOR_EDIT_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
//UPDATE SLOGAN WEBSITE
export const updateCompanyInfo = (Slogan, Website) => {
  const token = localStorage.getItem("accessToken");
  const AccountType = localStorage.getItem("AccountType");
  let ApiUpdate = "";
  if (AccountType === "1") {
    ApiUpdate = UPDATE_ADDITIONAL_INFO_MC;
  } else if (AccountType === "2") {
    ApiUpdate = UPDATE_ADDITIONAL_INFO_SC;
  }
  return axios
    .post(
      `${process.env.REACT_APP_URL + ApiUpdate}`,

      { Website: Website, Slogan: Slogan },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//UPDATE PROFILE PICTURE MC
export const updateProfilePicture = (File) => {
  const token = localStorage.getItem("accessToken");
  const data = new FormData();
  data.append("File", File);
  return axios
    .post(`${process.env.REACT_APP_URL + UPDATE_PROFILE_PICTURE_MC}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
//SAVE PROFILE
export const saveProfilePicture = (FileId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + SAVE_PROFILE_PICTURE_MC}`,

      { FileId: FileId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
//dELTE PROFILE PICTURE MC
export const deleteProfilePicture = (FileId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + DELETE_PROFILE_PICTURE_MC}`,
      { FileId: FileId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {});
};

//UploadPhoto-EditProfile
export const updateProfile = (file) => {
  const token = localStorage.getItem("accessToken");
  const data = new FormData();
  data.append("File", file);
  return axios
    .post(`${process.env.REACT_APP_URL + UPLOAD_PROFILE}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//UpdateMainProfile
export const mainUpdate = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + UPDATE_PROFILE}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//DELETE OLD FILE
export const deleteOldFile = (fileId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + DELETE_OLD_FILE}`,
      { FileId: fileId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//CHANGE PASSWORD
export const changePassword = (CurrentPassword, NewPassword) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + CHANGE_PASSWORD}`,
      { CurrentPassword: CurrentPassword, NewPassword: NewPassword },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

//GETITNG SUB CONTRACTOR DATA FOR UPDATE
export const subContarctorEditDetails = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + SUB_CONTRACTOR_EDIT_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//UploadPhoto-EditProfile-SUB
export const updateSubProfile = (file) => {
  const token = localStorage.getItem("accessToken");
  const data = new FormData();
  data.append("File", file);
  return axios
    .post(`${process.env.REACT_APP_URL + UPLOAD_SUB_PROFILE}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//UpdateSubProfile
export const subUpdate = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + UPDATE_SUB_PROFILE}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//DELETE OLD SUB FILE
export const deleteOldSubFile = (fileId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + DELETE_OLD_SUB_FILE}`,
      { FileId: fileId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
//remove PROFILE PICTURE MC
export const removeProfilePicture = (FileId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + REMOVE_PROFILE_PICTURE}`,
      { FileId: FileId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {});
};
//MANAGED PRODUCTS

export const getPricingPlans = (BillingId,SubscriptionTypeId) => {

  const token = localStorage.getItem("accessToken");

  return axios
    .get(
      `${process.env.REACT_APP_URL + MANAGED_PRODUCTS}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },

        params: { BillingId: BillingId ,
          SubscriptionTypeId:SubscriptionTypeId
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
//cancel subscription

export const cancelSubscription = (BillingId) => {
  const token = localStorage.getItem("accessToken");

  return axios
    .get(
      `${process.env.REACT_APP_URL + CANCEL_SUBSCRIPTION}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
//CHANGE SUBSCRUPTION
export const changeSubscription = (PriceId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + CHANGE_SUBSCRIPTION}`,

      { PriceId: PriceId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//get TM input data
export const getTMProfileInputData = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_TMPROFILE_INPUT_DATA}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
//get TM profile data
export const getTMProfileData = () => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + GET_TMPROFILE_DATA}`, "", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
//update TM profile data
export const updateTMProfileInputData = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + UPDATE_TMPROFILE_DATA}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
