import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { IconButton } from "@mui/material";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import validator from "validator";
import classes from "../../../Assets/Styles/changePassword.module.css";
import * as profileServices from "../../../Services/profile-services.proxy";
const changePassword = profileServices.changePassword;

function ChangePassword({ setRegister, setLoading }) {
  const [password, setPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");

  const [confirmPassword, setConfirmpassword] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState(false);

  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmpasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfimPassword, setShowConfirmPassword] = useState(false);
  const [isdisabled, setIsDisabled] = useState(true);
  const [passwordChanged, setpasswordChanged] = useState(false);
  const [LoadingButton, setLoadingButton] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickConfirmShowPassword = () =>
    setShowConfirmPassword(!showConfimPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfimPassword);
  useEffect(() => {
    if (
      password &&
      currentPassword &&
      confirmPassword &&
      !passwordError &&
      !confirmPasswordError
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [
    password,
    currentPassword,
    confirmPassword,
    passwordError,
    confirmPasswordError,
  ]);
  const passwordChange = (e) => {
    setLoadingButton(true);
    changePassword(currentPassword, password)
      .then((x) => {
        if (x.status === 201) {
          localStorage.setItem("accessToken", x.data.accessToken);
          setpasswordChanged(true);
          setCurrentPassword("");
          setConfirmpassword("");
          setPassword("");
          setIsDisabled(true);
        } else {
          setCurrentPasswordError(true);
        }
        setLoadingButton(false);
      })
  };
  return (
    <Grid container item marginLeft={2} marginTop={1}>
      <Grid item className={classes["page-title"]}>
        Change My Password
      </Grid>
      <Grid item container direction={"row"} md={12} paddingBottom={5}>
        <Grid item container direction={"row"} marginTop={2} md={12}>
          <Grid item md={12} xs={12}>
            <span className={classes["Fields-Titles"]}>*Current Password</span>
          </Grid>
          <Grid item md={12} xs={11.4}>
            <TextField
              inputProps={{ maxLength: 20 }}
              error={currentPasswordError}
              helperText={
                currentPasswordError
                  ? "Your current password is incorrect "
                  : ""
              }
              type={"password"}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    borderColor: "#f2f2f2",
                  },
                },
                "& .MuiOutlinedInput-root:hover": {
                  "& > fieldset": {
                    borderColor: "#f2f2f2",
                  },
                },
              }}
              id="outlined-name"
              size="small"
              className={classes["Field-inputs"]}
              onChange={(e) => {
                setCurrentPassword(e.target.value);
                setCurrentPasswordError(false);
              }}
              value={currentPassword}
            />
          </Grid>
        </Grid>

        <Grid item container direction={"row"} marginTop={4} md={12}>
          <Grid item md={12} xs={12}>
            <span className={classes["Fields-Titles"]}>*New Password</span>
          </Grid>
          <Grid item md={12} xs={11.4}>
            <TextField
              inputProps={{ maxLength: 20 }}
              error={passwordError}
              helperText={
                passwordError
                  ? "Password must contain a number, a symbol, an uppercase & lowercase and be no less than 8  or more than 20 characters."
                  : ""
              }
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <Visibility htmlColor="rgba(6, 116, 185, 0.5)" />
                    ) : (
                      <VisibilityOff htmlColor="rgba(6, 116, 185, 0.5)" />
                    )}
                  </IconButton>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    borderColor: "#f2f2f2",
                  },
                },
                "& .MuiOutlinedInput-root:hover": {
                  "& > fieldset": {
                    borderColor: "#f2f2f2",
                  },
                },
              }}
              id="outlined-name"
              size="small"
              className={classes["Field-inputs"]}
              onChange={(e) => {
                setPassword(e.target.value);
                if (
                  !validator.isStrongPassword(e.target.value, {
                    minLength: 8,
                    minLowercase: 1,
                    minUppercase: 1,
                    minNumbers: 1,
                    minSymbols: 1,
                  })
                ) {
                  setPasswordError(true);
                } else if (e.target.value === confirmPassword) {
                  setConfirmpasswordError(false);
                } else if (e.target.value !== confirmPassword) {
                  setConfirmpasswordError(true);
                  if (
                    validator.isStrongPassword(e.target.value, {
                      minLength: 8,
                      minLowercase: 1,
                      minUppercase: 1,
                      minNumbers: 1,
                      minSymbols: 1,
                    })
                  ) {
                    setPasswordError(false);
                  }
                } else {
                  setPasswordError(false);
                }
              }}
              value={password}
            />
          </Grid>
        </Grid>
        <Grid item container direction={"row"} marginTop={4} md={12}>
          <Grid item md={12} xs={12}>
            <span className={classes["Fields-Titles"]}>
              *Confirm New Password
            </span>
          </Grid>
          <Grid item md={12} xs={11.4}>
            <TextField
              inputProps={{ maxLength: 20 }}
              error={confirmPasswordError}
              helperText={confirmPasswordError ? "Password not matching" : ""}
              type={showConfimPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickConfirmShowPassword}
                    onMouseDown={handleMouseDownConfirmPassword}
                    edge="end"
                  >
                    {showConfimPassword ? (
                      <Visibility htmlColor="rgba(6, 116, 185, 0.5)" />
                    ) : (
                      <VisibilityOff htmlColor="rgba(6, 116, 185, 0.5)" />
                    )}
                  </IconButton>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    borderColor: "#f2f2f2",
                  },
                },
                "& .MuiOutlinedInput-root:hover": {
                  "& > fieldset": {
                    borderColor: "#f2f2f2",
                  },
                },
              }}
              id="outlined-name"
              size="small"
              className={classes["Field-inputs"]}
              onChange={(e) => {
                setConfirmpassword(e.target.value);
                if (e.target.value !== password) {
                  setConfirmpasswordError(true);
                } else {
                  setConfirmpasswordError(false);
                }
              }}
              value={confirmPassword}
            />
          </Grid>
          <Grid item marginTop={3} md={12} xs={11.4}>
            <button
              disabled={isdisabled || LoadingButton}
              style={{
                backgroundColor: isdisabled ? "#bcbcbc" : "#1173cf",
              }}
              className={classes["button-save"]}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#101650";
                e.target.style.cursor = "pointer";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#1173cf";
              }}
              onClick={(e) => {
                passwordChange(e);
              }}
            >
              {LoadingButton ? "Saving..." : "Save"}
            </button>
          </Grid>
          {passwordChanged ? (
            <Grid item md={12} xs={12} marginLeft={27} paddingTop={1}>
              <span className={classes["Fields-Titles"]}>Password Changed</span>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ChangePassword;
