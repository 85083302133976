import React, { useEffect, useState, useMemo } from "react";
import classes from "../../Assets/Styles/SalesProjectListCards.module.css";
import { Grid } from "@mui/material";
import SalesOrderCard from "./SalesOrderCard";
import SaasBack from "../../Assets/Images/Projects/bgTableProject.png";
import { useNavigate, useLocation } from "react-router-dom";
import * as salesOrderServices from "../../Services/sales-order-services.proxy";
import CircularProgress from "@mui/material/CircularProgress";
import classing from "../../Assets/Styles/projects.module.css";
import ErrorModal from "../../SharedComponents/ErrorModal";

const getSalesOrderCards = salesOrderServices.getSalesOrderCards;
function SalesOrderListCard(props) {
  const navigate = useNavigate();

  useEffect(() => {
    props.setRegister(true);
  }, [props]);

  useEffect(() => {
    props.setNaved(false);
  }, [props]);
  useEffect(() => {
    props.setShowSaaSNavbar(true);
  }, [props]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [salesOrders, setSalesOrders] = useState([]);
  const [ordersLength, setOrdersLength] = useState(0);

  const [loading, setLoading] = useState(true);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const ID = query.get("id");

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [BEerror, setBEerror] = useState(null);

  useEffect(() => {
    let apiTest = true;
    getSalesOrderCards(ID).then((x) => {
      if (apiTest) {
        if (x.status || x.statusCode) {
          if (x.status) setBEerror(x.error)
          if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
          setOpenErrorModal(true);
        } else {
          setSalesOrders(x);
          setOrdersLength(x.length);
        }


        setLoading(false);
      }
    });
    return () => {
      apiTest = false;
    };
  }, [ID]);

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <Grid
        item
        container
        paddingLeft={{ xs: "0%", sm: "1%", md: "5%" }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          container
          direction="row"
          gap={1}
          paddingTop={{ xs: "10%", sm: "7%", md: "2%" }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            container
            direction="column"
            lg={1.5}
            sm={2.5}
            xs={2.5}
            paddingTop={{ xs: "4%", sm: "2%", lg: "1%" }}
          >
            <Grid
              item
              fontSize={{ xs: "11px", sm: "18px" }}
              fontWeight="bold"
              className={classes["Title"]}
            >
              {localStorage.getItem("ProjectName")}
            </Grid>
            <Grid
              item
              fontSize={{ xs: "10px", sm: "15px" }}
              fontFamily="Muli"
              className={classes["Title"]}
            >
              Sales Orders
            </Grid>
          </Grid>

          <Grid
            item
            lg={7.7}
            sm={5.5}
            xs={3.5}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>
          <Grid
            item
            container
            lg={0.9}
            sm={1.8}
            xs={2.5}
            className={classes["ViewText"]}
            fontSize={{ xs: "9px", sm: "12px" }}
            paddingTop={{ xs: "4%", sm: "2%", lg: "1%" }}
          >
            <Grid
              item
              xs={12}
              onClick={() => {
                navigate(`/saas/salesOrderList?id=${ID}`);
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
                e.target.style.fontWeight = "bold";
              }}
              onMouseOut={(e) => {
                e.target.style.fontWeight = "normal";
              }}
            >
              View All Orders
            </Grid>

            <Grid
              item
              xs={12}
              onClick={() => {
                navigate(`/saas/salesOrderDashboard?id=${ID}`);
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
                e.target.style.fontWeight = "bold";
              }}
              onMouseOut={(e) => {
                e.target.style.fontWeight = "normal";
              }}
            >
              View Dashboard
            </Grid>
          </Grid>

          <Grid item lg={0.9} sm={1}>
            <button
              className={classing["back-button"]}
              onClick={() => {
                navigate(-1);
                localStorage.removeItem("ProjectName");
              }}
            >
              Go Back
            </button>
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={11.5}
          paddingTop="2%"
          alignItems="flex-start"
          gap={2}
        // marginBottom={2}
        >
          {loading ? (
            <Grid item xs={12} container justifyContent="center">
              <Grid item paddingTop="5%">
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <>
              {salesOrders?.length > 0 ? (
                <>
                  {salesOrders?.map((data, index) => (
                    <Grid item xs={5.7} sm={3} md={2.5} lg={1.8} xl={1.6} key={data.Id}>
                      <SalesOrderCard data={data} index={index} />
                    </Grid>
                  ))}
                </>
              ) : (
                <Grid
                  item
                  style={{
                    fontFamily: "Muli",
                    fontSize: "13px",
                    color: "#101650",
                  }}
                >
                  {" "}
                  No Sales Orders for this project{" "}
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <ErrorModal openErrorModal={openErrorModal} setOpenErrorModal={setOpenErrorModal} BEerror={BEerror} />

    </div>
  );
}

export default SalesOrderListCard;
