import React, { useEffect, useState, useMemo } from "react";
import SaasBack from "../../../Assets/Images/ProfileDetails/SaaSbackground.png";
import { Grid } from "@mui/material";
import classes from "./MilesStones.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import BlueCircle from "../../../Assets/Images/MileStones/blueCircle.png";
import YellowCircle from "../../../Assets/Images/MileStones/YellowCircle.png";
import DarkBlueCircle from "../../../Assets/Images/MileStones/DarkBlueCircle.png";
import GreenCircle from "../../../Assets/Images/MileStones/GreenCircle.png";
import ListPhases from "./ListPhases";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";

const MilesStones = (props) => {
  useEffect(() => {
    props.setRegister(true);
  }, [props]);

  useEffect(() => {
    props.setNaved(false);
  }, [props]);

  const navigate = useNavigate();
  const type = localStorage.getItem("AccountType");
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const [projectName, setProjectName] = useState("");

  const query = useQuery();
  const ProjectId = query.get("id");
  const drawCircles = (imgSrc, circleStatus) => {
    return (
      <Grid item container direction={"row"}>
        <Grid item xs={3} paddingTop={1.2}>
          <img src={imgSrc} alt={"blue"} />
        </Grid>
        <Grid item xs={7}>
          {circleStatus}
        </Grid>
      </Grid>
    );
  };
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
        paddingTop: "3%",
      }}
    >
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12} container alignItems="center" padding="0 0 2% 10%">
          <Grid
            item
            xs={1.8}
            sx={{
              fontFamily: "Muli",
              fontSize: 18,
              fontWeight: "bold",
              lineHeight: 1.44,
              textAlign: "left",
              color: "#101650",
            }}
          >
            {projectName + " "}Milestones
          </Grid>
          <Grid
            item
            xs={8}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>
          <Grid item xs={1} paddingLeft={{ xs: "5%", md: "1.5%" }}>
            <button
              className={classes["back-button"]}
              onClick={() => {
                navigate(-1);
              }}
            >
              Go back
            </button>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid
            item
            container
            md={1}
            xs={12}
            marginLeft={2}
            marginTop={7}
            direction="column"
            className={classes["status"]}
            gap={{ md: 5, xs: 0 }}
          >
            {drawCircles(BlueCircle, "Request Dates Approval")}
            {drawCircles(GreenCircle, "Approve Dates")}
            {drawCircles(YellowCircle, "Suggest New Dates")}
            {drawCircles(DarkBlueCircle, "Accept Suggested Dates")}
            <Grid item container direction={"row"}>
              <Grid item xs={3} paddingTop={1.2}>
                <EventRepeatIcon htmlColor="#101650" />
              </Grid>
              <Grid item xs={7}>
                View Suggested Dates
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={10.5} xs={12}>
            <Grid container direction={"row"}>
              <Grid item xs={2}></Grid>
              <Grid
                item
                container
                direction={"row"}
                bgcolor={"#fff"}
                md={8.88}
                xs={10}
                padding={2.4}
                className={classes["ColumnsTitles"]}
              >
                <Grid item xs={2.4}>
                  Start Date
                </Grid>
                <Grid item xs={2.4}>
                  End Date
                </Grid>
                <Grid item xs={2.4}>
                  Action
                </Grid>
                <Grid item xs={1.6}>
                  Status
                </Grid>
                <Grid item xs={2.4}>
                  Predecessor
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} container>
              <Grid
                item
                container
                xl={0.05}
                lg={0.06}
                md={0.09}
                sm={0.1}
                xs={0.1}
                sx={{
                  backgroundColor:
                    type === "1"
                      ? "#e1e319"
                      : type === "2"
                        ? "#2da4d0"
                        : "lightgrey",
                }}
              ></Grid>
              <Grid
                item
                md={10.83}
                xs={12}
                bgcolor={"white"}
                container
                direction={"row"}
                paddingTop={3}
                paddingLeft={1}
                paddingBottom={10}
              >
                <ListPhases
                  ProjectId={ProjectId}
                  setProjectName={setProjectName}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default MilesStones;
