import React, { useEffect } from "react";
import whyPC from ".././Assets/Images/home/whypc.png";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import "../Assets/Styles/whyPileCubes.css";
function WhyPileCubes({ setRegister, setNaved, setTerms }) {
  useEffect(() => {
    setRegister(false);
  }, [setRegister]);

  useEffect(() => {
    setNaved(false);
    setTerms(true);
  }, [setNaved]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }} className="whyyy">
      <Grid container>
        <Grid item md={1.2} xs={2}></Grid>
        <Grid item md={1.4} className="sideBg">
          <div
            style={{
              backgroundImage: `url(${whyPC})`,
              backgroundSize: "cover",
              width: 168,
              height: window.innerHeight,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span className="infos" style={{ marginTop: "35%" }}>
              Questions ?
            </span>
            <a
              href={process.env.REACT_APP_INFO}
              style={{ textDecoration: "none" }}
            >
              <span className="infos" style={{ marginTop: "5%" }}>
                info@pilecubes.com
              </span>
            </a>
          </div>
        </Grid>
        <Grid item md={7} xs={8} className="desc">
          <Grid item md={11}>
            <div className="descriptionTitle">Terms of Service</div>
          </Grid>
          <Grid item md={11} style={{ marginTop: "3%" }}>
            <div
              className="description2"
              style={{
                fontFamily: "Muli",
                fontSize: 15,
                lineHeight: 1.47,
                textAlign: "left",
                color: "#101650",
                fontWeight: "normal",
              }}
            >
              Please refer back to this page soon.
            </div>
          </Grid>
        </Grid>
        <Grid item md={2.4} xs={2}></Grid>
      </Grid>
    </Box>
  );
}

export default WhyPileCubes;
