import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import classes from "../../Assets/Styles/TipsModal.module.css";
import ProjectLogo from "../../Assets/Images/projectLogo.png";
import * as tipsServices from "../../../Services/notification-service.proxy";
const snoozeTip = tipsServices.snoozeTip;
const style = {
  position: "relative",
  top: "82%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#c2dcf2",

  boxShadow: "0 3px 6px 0 #707070",
  display: "block",
  overflowX: "scroll",
  border: 0,
  outline: "none",
  borderRadius: "5px",
};

const TipsModal = (props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    props.setOpenModal(false);
  };
  useEffect(() => {
    if (props.openModal) {
      handleOpen(true);
    }
  }, [props.openModal]);
  const snoozeTheTip = (period) => {
    snoozeTip(period).then((x) => {
      handleClose();
      props.setModify(true);
      props.setdelayedToDate(new Date(x));
    });
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
        disableScrollLock
        keepMounted
      >
        <Grid
          sx={style}
          width={{ xs: "75%", md: "28%" }}
          left={{ xs: "50%", md: "85%" }}
        >
          <Grid container gap={1}>
            <Grid
              item
              container
              direction={"row"}
              paddingTop={0.5}
              paddingLeft={{ md: 1.5, xs: 0 }}
            >
              <Grid item xs={1.4} paddingLeft={{ md: 2, xs: 0 }}>
                <img src={ProjectLogo} alt={"logo"} style={{ width: 25 }}></img>
              </Grid>
              <Grid
                item
                xs={2}
                className={classes["TipsModalTitle"]}
                paddingTop={0.5}
              >
                Tips
              </Grid>
              <Grid
                xs={8.2}
                container
                item
                direction={"row-reverse"}
                onClick={() => {
                  handleClose();
                }}
              >
                <CloseIcon htmlColor="#101650" />
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes["Line-540"]}></Grid>
            <Grid
              item
              xs={12}
              md={12}
              className={classes["modal-content"]}
              paddingTop={1}
              paddingLeft={{ xs: 0, md: 2 }}
              paddingRight={1}
            >
              {props.tipMessage}
            </Grid>
            <Grid
              item
              container
              xs={11.3}
              direction={"row-reverse"}
              paddingTop={3}
              gap={1}
            >
              <button
                className={classes["SnoozeButton"]}
                onClick={() => {
                  snoozeTheTip(2);
                }}
              >
                Snooze 7 days
              </button>
              <button
                className={classes["SnoozeButton"]}
                onClick={() => {
                  snoozeTheTip(1);
                }}
              >
                Snooze 1 day
              </button>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

export default TipsModal;
