import {
  Grid,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableBody,
} from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import SaasBack from "../../../../../Assets/Images/SaaSbackground.png";
import classes from "../../../../../Assets/Styles/factory.module.css";
import { withStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import * as storageBlockServices from "../../../../../Services/storage-block-services.proxy";
const getZonesLines = storageBlockServices.getZonesLines;
const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 10,
  },
}))(TableRow);
const StyledTableSubRow = withStyles((theme) => ({
  root: {
    height: 100,
  },
}))(TableRow);
export default function ManageFGS(props) {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const ZONE_ID = query?.get("id");
  const CATEGORY_ID = query?.get("id");
  const bayId = query?.get("bayId");
  const factoryId = query?.get("factoryId");
  const blockCode = query?.get('blockCode');
  const zoneCode = query?.get('zoneCode');
  
  const [salesOrders, setSalesOrder] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const sumOfWeightPerMember = (LinesToCalculateWeight) => {
    let sum = 0;
    for (let i = 0; i < LinesToCalculateWeight.length; i++) {
      sum = sum + LinesToCalculateWeight[i].ActualWeight;
    }
    return !sum ? 0 : sum.toFixed(2);
  };
  useEffect(() => {
    if (ZONE_ID && CATEGORY_ID) {
      setLoadingData(true);
      getZonesLines(ZONE_ID).then((x) => {
        setLoadingData(false);
        setSalesOrder(x)
      });
    }
  }, [ZONE_ID, CATEGORY_ID]);

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
        objectFit: "cover",
      }}
    >
      <Grid item container paddingTop={3} rowGap={2}>
        <Backdrop
          sx={{
            color: "#fff",
            backgroundColor: "transparent",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            display: "flex",
            flexDirection: "column",
          }}
          open={loadingData}
        >
          <CircularProgress />

          <Grid
            item
            fontFamily={"Muli"}
            fontSize={15}
            fontWeight={"bold"}
            color={"rgb(25, 118, 210)"}
          >
            Loading Data...
          </Grid>
        </Backdrop>
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          gap={1}
          alignItems="center"
        >
          <Grid
            item
            container
            direction="column"
            lg={1.5}
            sm={2.5}
            xs={3.7}
            fontSize={{ xs: "13px", sm: "16px" }}
            fontWeight="bold"
            className={classes["Title"]}
            paddingTop={{ xs: 1.5, sm: 3 }}
          >
            <Grid item>{blockCode}</Grid>
            <Grid item>{zoneCode}: Stock Management</Grid>
          </Grid>
          <Grid
            item
            xs={1}
            sm={5}
            lg={7}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>
          <Grid
            item
            xs={3.7}
            sm={2.5}
            lg={1}
            className={classes["ViewText"]}
            onClick={() => {
              // navigate("/saas/blocksList?factoryId=&bayId");
              navigate(
                `/saas/blocksList?BayId=${bayId}&FactoryId=${factoryId}&blockType=1`
              );
            }}
          >
            View All Blocks
          </Grid>
          <Grid item>
            <button
              className={classes["back-button"]}
              onClick={() => {
                navigate(-1);
              }}
            >
              Go Back
            </button>
          </Grid>
        </Grid>
        <Grid item container xs={11} md={7} marginLeft={{ sm: 5, md: 15 }}>
          <TableContainer sx={{ width: "100%" }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{
                borderCollapse: "separate",
                borderSpacing: "0px 0px",
                border: 0,
                backgroundColor: "#fff",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={styles.tableCell}>
                    *Bar Mark
                    <br />
                    No.
                  </TableCell>
                  <TableCell sx={styles.tableCellQty}>
                    *Qty <br />
                    <Grid item className={classes["SubTitle"]} paddingTop={1}>
                      Total No.
                      <br /> of Bars
                    </Grid>
                  </TableCell>
                  <TableCell sx={styles.tableCell}>*Dia</TableCell>
                  <TableCell sx={styles.tableCell}>
                    Shape <br />
                    Code
                  </TableCell>
                  <TableCell sx={styles.tableCell}>Length</TableCell>
                  <TableCell sx={styles.tableCell}>Weight</TableCell>
                  <TableCell sx={styles.tableCell}>Status</TableCell>
                </TableRow>
              </TableHead>
              {salesOrders?.map((so) => (
                <>
                  <StyledTableRow>
                    <TableCell sx={styles.tableSubCellSo}>{so.Code}</TableCell>
                    <TableCell sx={styles.tableSubCellSo}></TableCell>
                    <TableCell sx={styles.tableSubCellSo}></TableCell>
                    <TableCell sx={styles.tableSubCellSo}></TableCell>
                    <TableCell sx={styles.tableSubCellSo}></TableCell>
                    <TableCell sx={styles.tableSubCellSo}></TableCell>
                    <TableCell sx={styles.tableSubCellSo}></TableCell>
                  </StyledTableRow>
                  {so.Members.map((member) => (
                    <TableBody key={member.OrderId}>
                      <StyledTableRow>
                        <TableCell sx={styles.tableSubCell}>
                          {member.OrderId}
                        </TableCell>
                        <TableCell sx={styles.tableSubCell}>
                          {member.Quantity}
                        </TableCell>
                        <TableCell sx={styles.tableSubCell}></TableCell>
                        <TableCell sx={styles.tableSubImage2}>
                          <Grid container>
                            <Grid
                              item
                              xs={4}
                              className={classes["subsubTitiles"]}
                            >
                              {member.Name}
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              className={classes["subsubTitiles"]}
                            >
                              {member.Type}
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              className={classes["subsubTitiles"]}
                            >
                              {member.BarMarkType}
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell sx={styles.tableSubCell}></TableCell>
                        <TableCell sx={styles.tableSubCell}>
                          {sumOfWeightPerMember(member?.Lines)}
                        </TableCell>
                        <TableCell sx={styles.tableSubCell}></TableCell>
                      </StyledTableRow>
                      {member.Lines.map((Line, index) => (
                        <StyledTableSubRow key={Line.OrderId}>
                          <TableCell sx={styles.tableSubSubCell}>
                            {Line.BarMarkNo}
                          </TableCell>
                          <TableCell sx={styles.tableSubSubCell}>
                            {Line.BarsQuantity}
                            <br />
                            <Grid
                              item
                              className={classes["SubTitle"]}
                              paddingTop={1}
                            >
                              {Line.BarsQuantity ? Line.TotalNumberOfBars : ""}
                            </Grid>
                          </TableCell>
                          <TableCell sx={styles.tableSubSubCell}>
                            {Line.BarDiameter}
                          </TableCell>

                          <TableCell sx={styles.tableSubSubCell}>
                            <Grid container direction={"column"} gap={2}>
                              <Grid item style={{ position: "relative" }}>
                                <img
                                  width={175}
                                  src={
                                    Line.ShapeBlob
                                      ? Line.ShapeBlob
                                      : Line.Shape.File.URL
                                  }
                                  alt={"shapeImg"}
                                ></img>
                              </Grid>
                              <Grid item container direction={"row"}>
                                <Grid
                                  item
                                  container
                                  xs={11}
                                  direction={"row"}
                                  gap={1}
                                >
                                  {Line.Shape.Elements.map((elToFill) => (
                                    <Grid container direction={"row"} xs={3.3}>
                                      <Grid
                                        item
                                        sx={styles.tfInput2}
                                        xs={6}
                                        paddingTop={1}
                                      >
                                        {elToFill.Name}:
                                      </Grid>
                                      <Grid item xs={6} paddingTop={1}>
                                        {elToFill.MeasureValue}
                                      </Grid>
                                    </Grid>
                                  ))}
                                </Grid>
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell sx={styles.tableSubSubCell}>
                            {Line.ActualLength}
                          </TableCell>
                          <TableCell sx={styles.tableSubSubCell}>
                            {Line.ActualWeight}
                          </TableCell>
                          <TableCell sx={styles.tableSubSubCell}>
                            {Line.Status}
                          </TableCell>
                        </StyledTableSubRow>
                      ))}
                    </TableBody>
                  ))}
                </>
              ))}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
}
const styles = {
  tableCell: {
    padding: 0,
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#101650",
    lineHeight: 0.91,
    textAlign: "center",
    width: "250px",
  },
  tableCellEmpty: {
    backgroundColor: "rgba(225, 227, 25, 0.3)",
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#101650",
    lineHeight: 0.91,
    textAlign: "center",
    width: 43,
  },
  tableCellQty: {
    padding: 0,
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#101650",
    lineHeight: 0.91,
    textAlign: "center",
    width: 600,
  },
  tableSubCellSo: {
    backgroundColor: "rgba(225, 227, 25, 0.7)",
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#707070",
    lineHeight: 1.45,
    textAlign: "left",
    padding: 0,
  },
  tableSubCell: {
    backgroundColor: "rgba(225, 227, 25, 0.3)",
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#707070",
    lineHeight: 1.45,
    textAlign: "center",
    padding: 0,
  },
  tableSubSubCell: {
    border: "solid 1px",
    borderColor: "#e1e319",
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#707070",
    lineHeight: 1.45,
    textAlign: "center",
    padding: 0,
  },
  tableCellImage: {
    backgroundColor: "#fff",
    padding: 0,
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#101650",
    lineHeight: 0.91,
    textAlign: "center",
    width: 1000,
  },
  tableSubImage2: {
    backgroundColor: "rgba(225, 227, 25, 0.3)",
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#707070",
    lineHeight: 1.45,
    width: 600,
    padding: 0,
    // height: 38,
  },
  tableRow: {
    backgroundColor: "#fff",
    boxShadow: "0 12px 6px 0 rgba(0, 0, 0, 0.16)",
    maxHeight: 38,
  },
  tableCell2: {
    backgroundColor: "transparent",
    fontFamily: "Muli",
    fontSize: 13,
    color: "#0674b9",
  },
  tableBody: {
    "& > :not(:last-child)": {
      borderBottom: "25px solid red",
    },
  },
  tfInput: {
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#707070",
    lineHeight: 1.45,
    textAlign: "center",
    paddingLeft: 2,
    height: 5,
  },
  tfInput2: {
    fontFamily: "Muli",
    fontSize: 11,
    fontWeight: "bold",
    color: "#707070",
    textAlign: "center",
    height: 5,
  },
};
