import React, { useEffect, useState } from "react";
import { Grid, Modal, Box, TextField } from "@mui/material";
import { MenuItem } from "@mui/material";
import classes from "../../../Assets/Styles/Transport.module.css";
import * as transportServices from "../../../Services/transport-services.proxy";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
const getDriverInputData = transportServices.getDriverInputData;
const createDriver = transportServices.createDriver;
const updateDriver = transportServices.updateDriver;

function DriverModal(props) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    props.setOpenModal(false);
    setName(null);
    setNameError(false);
    setCode(null);
    setIdNumber(null);
    setIdNumberError(false);
    setPhone(null);
    setPhoneError(false);
    setEmail(null);
    setEmailError(false);
    setAddress(null);
    setAdressError(false);
    setIsDisabled(true);
    setCompanyName(null);
    setCompanyNameError(false);
    props.setDriverId(null);
  };

  useEffect(() => {
    if (props.openModal) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [props.openModal]);

  useEffect(() => {
    if (open) {
      setLoadingData(true);
      getDriverInputData(props.driverId ? props.driverId : null).then((x) => {
        setLoadingData(false);
        setCode(x.Code);
        if (props.driverId) {
          setCode(x.Code);
          setName(x.Name);
          setAddress(x.Address);
          setEmail(x.Email);
          setIdNumber(x.IDNumber);
          setPhone(x.PhoneNumber);
          setCompanyName(x.CompanyName);
          setStatus(x.Status);
        }
      });
    }
  }, [open]);
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(false);

  //fiedls
  const [name, setName] = useState(null);
  const [nameError, setNameError] = useState(false);
  const [code, setCode] = useState(null);
  const [idNumber, setIdNumber] = useState(null);
  const [idNumberError, setIdNumberError] = useState(false);
  const [phone, setPhone] = useState(null);
  const [phoneError, setPhoneError] = useState(false);
  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const [address, setAddress] = useState(null);
  const [addressError, setAdressError] = useState(false);
  const [status, setStatus] = useState("Active");
  const [companyName, setCompanyName] = useState(null);
  const [companyNameError, setCompanyNameError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (
      !name ||
      !idNumber ||
      !phone ||
      !address ||
      nameError ||
      idNumberError ||
      phoneError ||
      addressError ||
      !companyName ||
      companyNameError
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });

  const driverDetails = {
    Id: props.driverId ? props.driverId : null,
    Name: name,
    Code: code,
    IDNumber: idNumber,
    Address: address,
    PhoneNumber: phone,
    Email: email,
    CompanyName: companyName,
  };
  const save = () => {
    setLoading(true);
    if (props.driverId) {
      updateDriver(driverDetails).then((x) => {
        setLoading(false);
        handleClose();
        props.setModify(!props.modify);
      });
    } else {
      createDriver(driverDetails).then((x) => {
        setLoading(false);
        handleClose();
        props.setModify(!props.modify);
      });
    }
  };

  return (
    <Modal
      disableScrollLock
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={style}
        width={{ xs: "90%", sm: "65%", md: "50%" }}
        height={{ xs: "50%", sm: "50%", md: "45%" }}
        paddingTop={4}
        overflow={"auto"}
      >
        <Backdrop
          sx={{
            color: "#fff",
            backgroundColor: "transparent",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            display: "flex",
            flexDirection: "column",
          }}
          open={loadingData}
        >
          <CircularProgress />

          <Grid
            item
            fontFamily={"Muli"}
            fontSize={15}
            fontWeight={"bold"}
            color={"rgb(25, 118, 210)"}
          >
            Loading Data...
          </Grid>
        </Backdrop>
        <Grid item className={classes["Truck-Detail"]}>
          Driver Detail
        </Grid>
        <Grid
          marginTop={2}
          xs={12}
          container
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid item width={"90%"} className={classes["Path-4142"]}></Grid>
        </Grid>
        <Grid
          item
          container
          paddingTop={2}
          paddingLeft={8}
          paddingRight={8}
          rowGap={1.5}
        >
          <Grid item xs={7}>
            <Grid item className={classes["FieldLa"]}>
              Driver Name
            </Grid>
            <Grid item xs={9} paddingTop={0.5}>
              <TextField
                value={name}
                variant={"standard"}
                fullWidth
                error={nameError}
                helperText={nameError ? "Enter a Name" : ""}
                onBlur={(e) => {
                  if (!e.target.value) setNameError(true);
                }}
                InputProps={{
                  style: {
                    height: "26px",
                    fontSize: 12,
                    fontFamily: "Muli",
                    backgroundColor: "#fff",
                    border: "solid 0.3px #707070",
                    paddingLeft: 10,
                  },
                }}
                onChange={(e) => {
                  setName(e.target.value);
                  if (e.target.value) setNameError(false);
                }}
              ></TextField>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid item className={classes["FieldLa"]}>
              Driver Code
            </Grid>
            <Grid item xs={10} paddingTop={0.5}>
              <TextField
                value={code}
                variant={"standard"}
                fullWidth
                disabled
                InputProps={{
                  style: {
                    height: "26px",
                    fontSize: 12,
                    fontFamily: "Muli",
                    backgroundColor: "#fff",
                    border: "solid 0.3px #707070",
                    paddingLeft: 10,
                  },
                }}
              ></TextField>
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <Grid item className={classes["FieldLa"]}>
              Driver ID Number
            </Grid>
            <Grid item xs={9} paddingTop={0.5}>
              <TextField
                value={idNumber}
                variant={"standard"}
                fullWidth
                error={idNumberError}
                helperText={idNumberError ? "Enter an id Number" : ""}
                onBlur={(e) => {
                  if (!e.target.value) setIdNumberError(true);
                }}
                InputProps={{
                  style: {
                    height: "26px",
                    fontSize: 12,
                    fontFamily: "Muli",
                    backgroundColor: "#fff",
                    border: "solid 0.3px #707070",
                    paddingLeft: 10,
                  },
                }}
                onChange={(e) => {
                  setIdNumber(e.target.value);
                  if (e.target.value) setIdNumberError(false);
                }}
              ></TextField>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid item className={classes["FieldLa"]}>
              Driver Phone Number
            </Grid>
            <Grid item xs={10} paddingTop={0.5}>
              <TextField
                value={phone}
                variant={"standard"}
                fullWidth
                error={phoneError}
                helperText={phoneError ? "Enter Phone Number" : ""}
                onBlur={(e) => {
                  if (!e.target.value) setPhoneError(true);
                }}
                InputProps={{
                  style: {
                    height: "26px",
                    fontSize: 12,
                    fontFamily: "Muli",
                    backgroundColor: "#fff",
                    border: "solid 0.3px #707070",
                    paddingLeft: 10,
                  },
                }}
                onChange={(e) => {
                  setPhone(e.target.value);
                  if (e.target.value) setPhoneError(false);
                }}
              ></TextField>
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <Grid item className={classes["FieldLa"]}>
              Driver Address
            </Grid>
            <Grid item xs={9} paddingTop={0.5}>
              <TextField
                value={address}
                variant={"standard"}
                fullWidth
                error={addressError}
                helperText={addressError ? "Enter Address" : ""}
                onBlur={(e) => {
                  if (!e.target.value) setAdressError(true);
                }}
                InputProps={{
                  style: {
                    height: "26px",
                    fontSize: 12,
                    fontFamily: "Muli",
                    backgroundColor: "#fff",
                    border: "solid 0.3px #707070",
                    paddingLeft: 10,
                  },
                }}
                onChange={(e) => {
                  setAddress(e.target.value);
                  if (e.target.value) setAdressError(false);
                }}
              ></TextField>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid item className={classes["FieldLa"]}>
              Driver E-mail
            </Grid>
            <Grid item xs={10} paddingTop={0.5}>
              <TextField
                value={email}
                variant={"standard"}
                fullWidth
                error={emailError}
                helperText={emailError ? "Enter  Email Error" : ""}
                onBlur={(e) => {
                  if (!e.target.value) setEmailError(true);
                }}
                InputProps={{
                  style: {
                    height: "26px",
                    fontSize: 12,
                    fontFamily: "Muli",
                    backgroundColor: "#fff",
                    border: "solid 0.3px #707070",
                    paddingLeft: 10,
                  },
                }}
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (e.target.value) setEmailError(false);
                }}
              ></TextField>
            </Grid>
          </Grid>

          <Grid item xs={7}>
            <Grid item className={classes["FieldLa"]}>
              Company Name
            </Grid>
            <Grid item xs={9} paddingTop={0.5}>
              <TextField
                value={companyName}
                variant={"standard"}
                fullWidth
                error={companyNameError}
                helperText={companyNameError ? "Enter a company name" : ""}
                onBlur={(e) => {
                  if (!e.target.value) setCompanyNameError(true);
                }}
                InputProps={{
                  style: {
                    height: "26px",
                    fontSize: 12,
                    fontFamily: "Muli",
                    backgroundColor: "#fff",
                    border: "solid 0.3px #707070",
                    paddingLeft: 10,
                  },
                }}
                onChange={(e) => {
                  setCompanyName(e.target.value);
                  if (e.target.value) setCompanyNameError(false);
                }}
              ></TextField>
            </Grid>
          </Grid>

          <Grid item xs={5}>
            <Grid item className={classes["FieldLa"]}>
              Driver Status
            </Grid>
            <Grid item xs={9} paddingTop={0.5}>
              <TextField
                value={status}
                variant={"standard"}
                fullWidth
                disabled
                InputProps={{
                  style: {
                    height: "26px",
                    fontSize: 12,
                    fontFamily: "Muli",
                    backgroundColor: "#fff",
                    border: "solid 0.3px #707070",
                    paddingLeft: 10,
                  },
                }}
              ></TextField>
            </Grid>
          </Grid>
          <Grid item container xs={11} direction={"row-reverse"} paddingTop={5}>
            <button
              className={classes["SaveBut"]}
              disabled={isDisabled}
              onClick={() => {
                save();
              }}
            >
              {props.driverId
                ? loading
                  ? "Updating..."
                  : "update"
                : loading
                ? "saving..."
                : "save"}
            </button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "30%",
  transform: "translate(-50%, -50%)",
  borderRadius: "15px",
  boxShadow: " 0 3px 6px 0 rgba(0, 0, 0, 0.46)",
  border: "solid 1px #0674b9",
  backgroundColor: "#fff",
};
export default DriverModal;
