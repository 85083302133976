import React, { useState } from "react";
import { Grid, Link } from "@mui/material";
import Logo from "../../../Assets/Images/Navbar/logo.png";
import "../../../Assets/Styles/LogIn.css";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import * as resetPass from "../../../Services/reset-pass.proxy";
const RESET_PASS = resetPass.requestPass;
function ResetPassword({ continues, setLoading }) {
  const [email, setEmail] = useState("");
  const [inputsError, setInputsError] = useState(false);
  const [inputsInvalid, setInputsInvalid] = useState(false);
  const navigate = useNavigate();
  const sendLink = (e, email) => {
    // setLoading(true);
    RESET_PASS(email, setLoading).then((res) => {
      if (res === 409) {
        setInputsError(true);
        setInputsInvalid(true);
      } else {
        continues(e, 3);
      }
    });
  };
  return (
    <Grid
      container
      className="LoginBg"
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Grid
        item
        container
        direction={"column"}
        width="380px"
        height="397.6px"
        alignItems={"center"}
        className="FormBg"
      >
        <Grid item marginTop={5}>
          <a href="/">
            <img src={Logo} alt="logo" height={50} />
          </a>
        </Grid>
        <Grid item marginTop={2}>
          <span className="Forgot-Your-Password">Forgot Your Password</span>
        </Grid>
        <Grid item marginTop={4}>
          <span className="logging-in-To-reset">
            Having trouble logging in?
          </span>
        </Grid>
        <Grid item marginTop={-1}>
          <span className="logging-in-To-reset">
            To reset your password, enter your registration email.
          </span>
        </Grid>
        <Grid item marginTop={2}>
          <span className="login-errors">
            {inputsError
              ? inputsInvalid
                ? "Invalid Email!"
                : "Empty field"
              : ""}
          </span>
        </Grid>
        <Grid item direction={"column"} marginTop={1}>
          <Grid item>
            <span className="FiedLabels">Registration Email </span>
          </Grid>
          <Grid item>
            <TextField
              type={"email"}
              value={email}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    borderColor: "#F2F2F2",
                  },
                },
                "& .MuiOutlinedInput-root:hover": {
                  "& > fieldset": {
                    borderColor: "#F2F2F2",
                  },
                },
              }}
              id="outlined-name"
              size="small"
              className="FieldsInput"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <Grid
              item
              container
              direction={"row"}
              marginTop={2}
              className="RememberMe"
              columnSpacing={1}
            >
              <Grid item>
                <button
                  className="cancel-button"
                  onMouseOver={(e) => {
                    e.target.style.backgroundColor = "lightgrey";
                    e.target.style.cursor = "pointer";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#fff";
                  }}
                  onClick={(e) => {
                    continues(e, 1);
                  }}
                >
                  Cancel
                </button>
              </Grid>
              <Grid item>
                <button
                  className="continue-button"
                  onMouseOver={(e) => {
                    e.target.style.backgroundColor = "#101650";
                    e.target.style.cursor = "pointer";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#1173CF";
                  }}
                  onClick={(e) => {
                    if (email) {
                      sendLink(e, email);
                    } else {
                      setInputsError(true);
                    }
                  }}
                >
                  Continue
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default ResetPassword;
