import React, { useState, useEffect, useMemo } from "react";
import SaasBack from "../../../../Assets/Images/Projects/bgTableProject.png";
import { Grid, TextField, MenuItem } from "@mui/material";
import classing from "../../../../Assets/Styles/factory.module.css";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useLocation } from "react-router-dom";
import * as handlingBlockServices from "../../../../Services/handling-block-services.proxy";
import { useSelector } from "react-redux";
import * as permissionFunctions from "../../../../../ReusableFunctions";

const CheckIfPermitted = permissionFunctions.CheckIfPermitted;
const createHandling = handlingBlockServices.createHandling;
const updateHandling = handlingBlockServices.updateHandling;
const getHandlingBlockInputData =
  handlingBlockServices.getHandlingBlockInputData;

const HandlingCreation = (props) => {
  useEffect(() => {
    props.setRegister(true);
  }, [props]);

  useEffect(() => {
    props.setNaved(false);
  }, [props]);
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  const [disabledEdit, setDisabledEdit] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [successMsgSaved, setSuccessMsgSaved] = useState(false);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const [BAY_ID, setBAY_ID] = useState(query?.get("id"));
  const [BAY_Nb, setBAY_Nb] = useState(query?.get("bayNb"));
  const [factoryId, setFactoryId] = useState(query.get("FactoryId"));

  const [Handling_ID, setHandling_ID] = useState(query?.get("handlingId"));
  const tableBlockType = query.get("blockType");

  const [modify, setModify] = useState(false);
  const [isdisabled, setIsDisabled] = useState(false);

  const [codeError, setCodeError] = useState(false);
  const [codeErrMessage, setCodeErrMessage] = useState("");
  const [code, setCode] = useState(null);

  const [nameError, setNameError] = useState(false);
  const [nameErrMessage, setNameErrMessage] = useState("");
  const [name, setName] = useState(null);

  const [manufacturerError, setManufacturerError] = useState(false);
  const [manufacturerErrMessage, setManufacturerErrMessage] = useState("");
  const [manufacturer, setManufacturer] = useState(null);

  const [manufacturerProductIdError, setManufacturerProductIdError] =
    useState(false);
  const [manufacturerProductIdErrMessage, setManufacturerProductIdErrMessage] =
    useState("");
  const [manufacturerProductId, setManufacturerProductId] = useState(null);

  const [manufacturerSerialNumError, setManufacturerSerialNumError] =
    useState(false);
  const [manufacturerSerialNumErrMessage, setManufacturerSerialNumErrMessage] =
    useState("");
  const [manufacturerSerialNum, setManufacturerSerialNum] = useState(null);

  const [modelError, setModelError] = useState(false);
  const [modelErrMessage, setModelErrMessage] = useState("");
  const [model, setModel] = useState(null);

  const [numOfPocketsError, setNumOfPocketsError] = useState(false);
  const [numOfPocketsErrMessage, setNumOfPocketsErrMessage] = useState("");
  const [numOfPockets, setNumOfPockets] = useState(null);

  const [capacityError, setCapacityError] = useState(false);
  const [capacityErrMessage, setCapacityErrMessage] = useState("");
  const [capacity, setCapacity] = useState(null);

  const [speedError, setSpeedError] = useState(false);
  const [speedErrMessage, setSpeedErrMessage] = useState("");
  const [speed, setSpeed] = useState(null);
  const [length, setLength] = useState(null);
  const [width, setWidth] = useState(null);
  const [status, setStatus] = useState(1);
  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    if (
      !code ||
      !name ||
      !model ||
      !numOfPockets ||
      speed === null ||
      !capacity ||
      !width ||
      !length ||
      codeError ||
      nameError ||
      modelError ||
      numOfPocketsError ||
      speedError ||
      capacityError
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });
  useEffect(() => {
    if (BAY_ID) {
      getHandlingBlockInputData(BAY_ID, Handling_ID ? Handling_ID : null).then(
        (x) => {
          setStatuses(x.Statuses);
          setLoading(false);
          setCode(x.HandlingBlock.Code);
          if (Handling_ID) {
            setCode(x.HandlingBlock.Code);
            setBAY_ID(x.HandlingBlock.BayId);
            setName(x.HandlingBlock.Name);
            setManufacturer(x.HandlingBlock.Manufacturer);
            setManufacturerProductId(x.HandlingBlock.ManufacturerProductId);
            setManufacturerSerialNum(x.HandlingBlock.ManufacturerSerialNumber);
            setLength(x.HandlingBlock.Length);
            setWidth(x.HandlingBlock.Width);
            setModel(x.HandlingBlock.ModelYear);
            setNumOfPockets(x.HandlingBlock.PocketsNumber);
            setSpeed(x.HandlingBlock.Speed);
            setCapacity(x.HandlingBlock.Capacity);
            setStatus(x.HandlingBlock.StatusId);
          }
        }
      );
    }
  }, [Handling_ID, modify, BAY_ID]);
  const HandlingData = {
    Id: Handling_ID,
    Name: name,
    Code: code,
    Manufacturer: manufacturer,
    ManufacturerProductId: manufacturerProductId,
    ManufacturerSerialNumber: manufacturerSerialNum,
    ModelYear: model,
    PocketsNumber: numOfPockets,
    Speed: speed,
    Capacity: capacity,
    Length: length,
    Width: width,
    BayId: BAY_ID,
  };
  const saveHandling = () => {
    if (Handling_ID) {
      updateHandling(HandlingData).then(() => {
        setModify(!modify);
        setSaveLoading(false);
        setSuccessMsgSaved(true);
        setTimeout(() => {
          setSuccessMsgSaved(false);
        }, 3000);
      });
    } else {
      createHandling(HandlingData).then(() => {
        setSaveLoading(false);
        setSuccessMsgSaved(true);
        setTimeout(() => {
          setSuccessMsgSaved(false);
        }, 3000);
        if (tableBlockType)
          navigate(
            `/saas/blocksList?BayId=${BAY_ID}&BayNb=${BAY_Nb}&FactoryId=${factoryId}&blockType=3`
          );
        else navigate(-1);
      });
    }
  };
  useEffect(() => {
    if (UserPermissions && Handling_ID) {
      CheckIfPermitted(
        UserPermissions,
        "Handling Block",
        "Edit",
        setDisabledEdit
      );
    }
  }, [UserPermissions]);
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <Grid
        item
        container
        paddingLeft={{ xs: "0%", sm: "1%", md: "5%" }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          container
          direction="row"
          gap={1}
          paddingTop={{ xs: "10%", sm: "7%", md: "2%" }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            container
            direction="column"
            lg={1.5}
            sm={2.5}
            xs={2.5}
            paddingTop={{ xs: "4%", sm: "2%", lg: "1%" }}
          >
            <Grid
              item
              fontSize={{ xs: "11px", sm: "18px" }}
              fontWeight="bold"
              className={classing["Title"]}
            >
              Bay # {BAY_Nb}
            </Grid>
          </Grid>

          <Grid
            item
            lg={8.6}
            sm={7.3}
            xs={6}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>

          <Grid item lg={0.9} sm={1}>
            <button
              className={classing["back-button"]}
              onClick={() => {
                if (tableBlockType)
                  navigate(
                    `/saas/blocksList?BayId=${BAY_ID}&BayNb=${BAY_Nb}&FactoryId=${factoryId}&blockType=3`
                  );
                else navigate(-1);
              }}
            >
              Go Back
            </button>
          </Grid>
        </Grid>
        <Grid
          container
          item
          paddingLeft={{ xs: "0%", sm: "1%", md: "3.2%" }}
          fontWeight={"normal"}
          direction={"row"}
        >
          <Grid
            item
            xs={12}
            paddingLeft={{ xs: 2, md: 0 }}
            className={classing["Title"]}
          >
            Handling Block
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={11.3}
          paddingTop="1%"
          alignItems="flex-start"
          gap={2}
          marginBottom={2}
        >
          {loading ? (
            <Grid item xs={12} container justifyContent="center">
              <Grid item paddingTop="5%">
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <Grid item container xs={12} gap={2}>
              <Grid
                item
                sm={11}
                xs={12}
                md={8}
                lg={6.5}
                rowGap={1}
                columnGap={2}
                bgcolor={"#fff"}
                paddingBottom={2}
                paddingTop={3}
                paddingLeft={3}
                container
              >
                <Grid item sm={3} xs={12}>
                  <Grid item className={classing["TFTitle"]} paddingLeft={1}>
                    *Code
                  </Grid>
                  <Grid item>
                    <TextField
                      value={code}
                      error={codeError}
                      helperText={codeError ? codeErrMessage : ""}
                      disabled
                      fullWidth
                      InputProps={{
                        style: {
                          height: "40px",
                          fontSize: 12,
                          fontFamily: "Muli",
                        },
                      }}
                      FormHelperTextProps={{
                        style: { fontSize: 8, fontFamily: "Muli" },
                      }}
                      placeholder="Code"
                      onBlur={(e) => {
                        if (!e.target.value) {
                          setCodeError(true);
                          setCodeErrMessage("Enter a code ");
                        }
                      }}
                      onChange={(e) => {
                        setCode(e.target.value);
                        if (!e.target.value) {
                          setCodeError(true);
                          setCodeErrMessage("Enter a Code");
                        } else if (e.target.value.length > 10) {
                          setCodeError(true);
                          setCodeErrMessage(
                            "Code should be less than 10 characters"
                          );
                        } else setCodeError(false);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item sm={5} xs={12}>
                  <Grid item className={classing["TFTitle"]} paddingLeft={1}>
                    *Name
                  </Grid>
                  <Grid item>
                    <TextField
                      value={name}
                      disabled={disabledEdit}
                      error={nameError}
                      helperText={nameError ? nameErrMessage : ""}
                      fullWidth
                      InputProps={{
                        style: {
                          height: "40px",
                          fontSize: 12,
                          fontFamily: "Muli",
                        },
                      }}
                      FormHelperTextProps={{
                        style: { fontSize: 8, fontFamily: "Muli" },
                      }}
                      placeholder="Name"
                      onBlur={(e) => {
                        if (!e.target.value) {
                          setNameError(true);
                          setNameErrMessage("Enter a name ");
                        }
                      }}
                      onChange={(e) => {
                        setName(e.target.value);
                        if (!e.target.value) {
                          setNameError(true);
                          setNameErrMessage("Enter a name");
                        } else if (e.target.value.length > 50) {
                          setNameError(true);
                          setNameErrMessage(
                            "name should be less than 50 characters"
                          );
                        } else setNameError(false);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item sm={5} xs={12}>
                  <Grid item className={classing["TFTitle"]} paddingLeft={1}>
                    Manufacturer
                  </Grid>
                  <Grid item>
                    <TextField
                      value={manufacturer}
                      disabled={disabledEdit}
                      error={manufacturerError}
                      helperText={
                        manufacturerError ? manufacturerErrMessage : ""
                      }
                      fullWidth
                      InputProps={{
                        style: {
                          height: "40px",
                          fontSize: 12,
                          fontFamily: "Muli",
                        },
                      }}
                      FormHelperTextProps={{
                        style: { fontSize: 8, fontFamily: "Muli" },
                      }}
                      placeholder="Manufacturer"
                      onChange={(e) => {
                        setManufacturer(e.target.value);
                        if (e.target.value.length > 50) {
                          setManufacturerError(true);
                          setManufacturerErrMessage(
                            "Manufacturer should be less than 50 characters"
                          );
                        } else setManufacturerError(false);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item sm={5} xs={12}>
                  <Grid item className={classing["TFTitle"]} paddingLeft={1}>
                    Manufacturer product ID
                  </Grid>
                  <Grid item>
                    <TextField
                      disabled={disabledEdit}
                      value={manufacturerProductId}
                      error={manufacturerProductIdError}
                      helperText={
                        manufacturerProductIdError
                          ? manufacturerProductIdErrMessage
                          : ""
                      }
                      fullWidth
                      InputProps={{
                        style: {
                          height: "40px",
                          fontSize: 12,
                          fontFamily: "Muli",
                        },
                      }}
                      FormHelperTextProps={{
                        style: { fontSize: 8, fontFamily: "Muli" },
                      }}
                      placeholder="Product Id"
                      onChange={(e) => {
                        setManufacturerProductId(e.target.value);
                        if (e.target.value.length > 50) {
                          setManufacturerProductIdError(true);
                          setManufacturerProductIdErrMessage(
                            "Product Id should be less than 50 characters"
                          );
                        } else setManufacturerProductIdError(false);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item sm={5} xs={12}>
                  <Grid item className={classing["TFTitle"]} paddingLeft={1}>
                    Manufacturer Serial Number
                  </Grid>
                  <Grid item>
                    <TextField
                      disabled={disabledEdit}
                      value={manufacturerSerialNum}
                      error={manufacturerSerialNumError}
                      helperText={
                        manufacturerSerialNumError
                          ? manufacturerSerialNumErrMessage
                          : ""
                      }
                      fullWidth
                      InputProps={{
                        style: {
                          height: "40px",
                          fontSize: 12,
                          fontFamily: "Muli",
                        },
                      }}
                      FormHelperTextProps={{
                        style: { fontSize: 8, fontFamily: "Muli" },
                      }}
                      placeholder="Serial Number"
                      onChange={(e) => {
                        setManufacturerSerialNum(e.target.value);
                        if (e.target.value.length > 50) {
                          setManufacturerSerialNumError(true);
                          setManufacturerSerialNumErrMessage(
                            "Serial Number should be less than 50 characters"
                          );
                        } else setManufacturerSerialNumError(false);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item sm={3} xs={12}>
                  <Grid item className={classing["TFTitle"]} paddingLeft={1}>
                    *Handling block Model year
                  </Grid>
                  <Grid item>
                    <TextField
                      disabled={disabledEdit}
                      value={model}
                      error={modelError}
                      helperText={modelError ? modelErrMessage : ""}
                      fullWidth
                      InputProps={{
                        style: {
                          height: "40px",
                          fontSize: 12,
                          fontFamily: "Muli",
                        },
                      }}
                      FormHelperTextProps={{
                        style: { fontSize: 8, fontFamily: "Muli" },
                      }}
                      placeholder="Model"
                      onChange={(e) => {
                        setModel(e.target.value);
                        if (e.target.value.length > 10) {
                          setModelError(true);
                          setModelErrMessage(
                            "Model Year should be less than 10 characters"
                          );
                        } else setModelError(false);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item sm={3} xs={12}>
                  <Grid item className={classing["TFTitle"]} paddingLeft={1}>
                    *No. of pockets
                  </Grid>
                  <Grid item>
                    <TextField
                      disabled={disabledEdit}
                      value={numOfPockets}
                      error={numOfPocketsError}
                      helperText={
                        numOfPocketsError ? numOfPocketsErrMessage : ""
                      }
                      type="number"
                      fullWidth
                      InputProps={{
                        style: {
                          height: "40px",
                          fontSize: 12,
                          fontFamily: "Muli",
                        },
                        inputProps: { min: 0 },
                      }}
                      FormHelperTextProps={{
                        style: { fontSize: 8, fontFamily: "Muli" },
                      }}
                      placeholder="Number"
                      onBlur={(e) => {
                        if (!e.target.value) {
                          setNumOfPocketsError(true);
                          setNumOfPocketsErrMessage("Enter a Number ");
                        }
                      }}
                      onChange={(e) => {
                        setNumOfPockets(e.target.value);
                        setNumOfPocketsError(false);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item sm={2.5} xs={12}>
                  <Grid item className={classing["TFTitle"]} paddingLeft={1}>
                    *Speed (m/s)
                  </Grid>
                  <Grid item>
                    <TextField
                      disabled={disabledEdit}
                      value={speed}
                      error={speedError}
                      helperText={speedError ? speedErrMessage : ""}
                      type="number"
                      fullWidth
                      InputProps={{
                        style: {
                          height: "40px",
                          fontSize: 12,
                          fontFamily: "Muli",
                        },
                        inputProps: { min: 0 },
                      }}
                      FormHelperTextProps={{
                        style: { fontSize: 8, fontFamily: "Muli" },
                      }}
                      placeholder="speed"
                      onBlur={(e) => {
                        if (!e.target.value) {
                          setSpeedError(true);
                          setSpeedErrMessage("Enter a speed ");
                        }
                      }}
                      onChange={(e) => {
                        setSpeed(e.target.value);
                        setSpeedError(false);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item sm={2.5} xs={12}>
                  <Grid item className={classing["TFTitle"]} paddingLeft={1}>
                    *Capacity (tons)
                  </Grid>
                  <Grid item>
                    <TextField
                      disabled={disabledEdit}
                      value={capacity}
                      error={capacityError}
                      helperText={capacityError ? capacityErrMessage : ""}
                      type="number"
                      fullWidth
                      InputProps={{
                        style: {
                          height: "40px",
                          fontSize: 12,
                          fontFamily: "Muli",
                        },
                        inputProps: { min: 0 },
                      }}
                      FormHelperTextProps={{
                        style: { fontSize: 8, fontFamily: "Muli" },
                      }}
                      placeholder="Capacity"
                      onBlur={(e) => {
                        if (!e.target.value) {
                          setCapacityError(true);
                          setCapacityErrMessage("Enter a Number ");
                        }
                      }}
                      onChange={(e) => {
                        setCapacity(e.target.value);
                        setCapacityError(false);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item sm={3} container xs={12}>
                  <Grid item className={classing["TFTitle"]} paddingLeft={1}>
                    *Dimensions (L, W) in (m)
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      disabled={disabledEdit}
                      value={length}
                      type="number"
                      fullWidth
                      InputProps={{
                        style: {
                          height: "40px",
                          fontSize: 12,
                          fontFamily: "Muli",
                        },
                        inputProps: { min: 0 },
                      }}
                      placeholder="Length"
                      onChange={(e) => {
                        setLength(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      disabled={disabledEdit}
                      value={width}
                      type="number"
                      fullWidth
                      InputProps={{
                        style: {
                          height: "40px",
                          fontSize: 12,
                          fontFamily: "Muli",
                        },
                        inputProps: { min: 0 },
                      }}
                      placeholder="Width"
                      onChange={(e) => {
                        setWidth(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item sm={2.5} xs={12}>
                  <Grid item className={classing["TFTitle"]} paddingLeft={1}>
                    Block Status
                  </Grid>
                  <Grid item>
                    <TextField
                      value={status}
                      disabled
                      fullWidth
                      select
                      InputProps={{
                        style: {
                          height: "40px",
                          fontSize: 12,
                          fontFamily: "Muli",
                        },
                      }}
                    >
                      {statuses.map((r) => (
                        <MenuItem value={r.Id} key={r.Id}>
                          {r.Name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction={"row-reverse"}
                  gap={2}
                  paddingRight={3}
                  alignItems="center"
                >
                  <button
                    className={classing["SaveBut"]}
                    disabled={isdisabled || saveLoading}
                    onClick={() => {
                      saveHandling();
                      setSaveLoading(true);
                    }}
                  >
                    {saveLoading ? "Saving" : "Save"}
                  </button>
                  <button
                    onClick={() => {
                      if (tableBlockType)
                        navigate(
                          `/saas/blocksList?BayId=${BAY_ID}&BayNb=${BAY_Nb}&FactoryId=${factoryId}&blockType=3`
                        );
                      else navigate(-1);
                    }}
                    className={classing["cancelbut"]}
                  >
                    Cancel
                  </button>
                  {successMsgSaved ? (
                    <>
                      <Grid item className={classing["TFTitle"]}>
                        Succesfully Saved
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default HandlingCreation;
