import React, { useEffect, useState } from "react";
import "../Assets/Styles/Home.css";
import { Grid } from "@mui/material";
import Description from "../Components/Home/Description";
import Choose from "../Components/Home/Choose";
import { useNavigate } from "react-router-dom";

export default function Home({
  setNaved,
  setRegister,
  setBooked,
  scrolled,
  setScrolled,
  isVerified,
  isSubscribed,
  token,
  account,
  setTerms,
}) {
  const [chosen, setChosen] = useState(false);
  const [chosen1, setChosen1] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let apiTest = true;
    if (localStorage.getItem("accessToken") && apiTest) {
      if (localStorage.getItem("isVerified") === "false") {
        if (localStorage.getItem("isTeamMember") === "true")
          navigate("/teamMemberRegistration");
        else {
          if (localStorage.getItem("AccountType") === "1") navigate("/main");
          if (localStorage.getItem("AccountType") === "2") navigate("/sub");
        }
      } else {
        if (localStorage.getItem("isGuest") === "true")
          navigate("/saas/invitations");
        else if (localStorage.getItem("IsScheduled") === "true")
          navigate("/scheduled");
        else if (
          localStorage.getItem("isSubscribed") === "true" ||
          localStorage.getItem("isSubscribedManu") === "true" ||
          localStorage.getItem("IsSubscribedBeforeManu") === "true" ||
          localStorage.getItem("isTeamMember") === "true" ||
          localStorage.getItem("IsFreePlanActivated") === "true" ||
          localStorage.getItem("IsSubscribedBefore") === "true"
        )
          navigate("/saas/admin");
        else navigate("/pricing");
      }
    }
    if(localStorage.getItem("machineId")) navigate('/machine/login');
    return () => {
      apiTest = false;
    };
  }, [navigate]);

  useEffect(() => {
    setRegister(false);
  }, [setRegister]);

  useEffect(() => {
    setNaved(true);
    setTerms(false);
  }, [setNaved]);

  useEffect(() => {
    if (scrolled) {
      window.scrollTo(0, window.innerHeight / 0.7);
    } else {
      window.scrollTo(0, 0);
    }
  }, [scrolled, setScrolled]);

  return (
    <Grid container direction={"column"}>
      <Description setBooked={setBooked} />
      <div
        id="chooseProfile"
        name="chooseProfile"
        style={{ padding: "3%" }}
      ></div>
      <div
        style={{
          height: 70,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: "1%",
        }}
      >
        <div
          style={{
            width: "70%",
            height: 0,
            backgroundColor: "#101650",
            border: "solid 0.05px #101650",
          }}
        ></div>
        <span className="getStarted">Let's get started</span>
      </div>
      <div style={{ width: "80%", paddingLeft: "10%", paddingBottom: "5%" }}>
        <Choose />
      </div>
    </Grid>
  );
}
