import React, { useEffect, useState, useMemo } from "react";
import { Grid, Modal, Box } from "@mui/material";
import classes from "../../../Assets/Styles/Delivery.module.css";

function DeleteModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    bottom: "30%",
    transform: "translate(-50%, -50%)",
    height: props.suggest ? "48%" : "25%",
    width: "35%",
    height: "20%",
    borderRadius: "15px",
    boxShadow: " 0 3px 6px 0 rgba(0, 0, 0, 0.46)",
    border: "solid 1px #0674b9",
    backgroundColor: "#fff",
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    
    props.setOpenDeleteModal(true);
  };

  const handleClose = () => {
    props.setShipmentId(null);
    props.setOpenDeleteModal(false);
    setOpen(false);
  };

  useEffect(() => {
    if (props.openDeleteModal === true) handleOpen();
    else handleClose();
  }, [props.openDeleteModal]);

  return (
    <Modal
      disableScrollLock
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid
          container
          padding={2}
          rowGap={3}
          justifyContent="center"
          paddingTop={4}
        >
          <Grid item className={classes["DeleteMsg"]}>
            Are you sure you want to delete the shipment?
          </Grid>
          <Grid item container spacing={2} justifyContent="center">
           
            <Grid item>
              <button
                className={classes["cancel-button"]}
                onClick={() => {
                  handleClose();
                }}
              >
                No
              </button>
            </Grid>
            <Grid item>
              <button className={classes["save-button"]} onClick={() => {
                props.deletingShipment();
              }}>
                {props.shipmentLoading? "Deleting...":"Yes"}
              </button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default DeleteModal;
