import React, { useEffect, useState } from "react";
import { Grid, TextField, Button } from "@mui/material";
import icon from "../Assets/Images/home/mobilehome.png";
import backgroundImg from "../SAAS/Assets/Images/ProfileDetails/SaaSbackground.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import PhoneInput from "react-phone-input-2";
import sent from "../Assets/Images/main/email.png";
import close from "../Assets/Images/form/x.png";
import { postMsg } from "../Services/contact-us-service.proxy";
import ReCAPTCHA from "react-google-recaptcha";
import Slide from "@mui/material/Slide";
import CircularProgress from "@mui/material/CircularProgress";

const SITE_KEY = "6Lc_lIogAAAAAFHB9_WVk4YrGzk6jkY3bkbroKio";

const style = {
  backgroundContainer: {
    backdropFilter: "blur(30px)",
    backgroundImage: `url(${backgroundImg})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100vw",
    padding: "1% 0 5% 2%",
    minHeight: "100vh",
  },
  title: {
    fontFamily: "Muli",
    fontSize: 22,
    fontWeight: "bold",
    textAlign: "left",
    color: "#101650",
  },
  info: {
    fontFamily: "Muli",
    fontSize: 20,
    textAlign: "left",
    color: "#101650",
  },
  desc: {
    fontFamily: "Muli",
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "left",
    color: "#101650",
  },
  label: {
    fontFamily: "Muli",
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "left",
    color: "#101650",
  },
  selectButton: {
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: "#2da4d0",
    fontFamily: "Muli",
    fontSize: 12,
    fontWeight: "bold",
    lineHeight: 1.5,
    textAlign: "left",
    color: "#fff",
    textTransform: "none",
    height: 35,
  },
};

export default function ContactUs({ setNaved, setTerms }) {
  useEffect(() => {
    setNaved(false);
    setTerms(false);
  }, [setNaved]);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);

  const [phone, setPhone] = useState("");
  const [phoneInvalid, setPhoneInvalid] = useState("");

  const [company, setCompany] = useState("");

  const INVESTOR = 1;
  const FEEDBACK = 2;
  const PARTNERCHIP = 3;
  const OTHER = 4;

  const [messageType, setMessageType] = useState("");
  const [messageTypeError, setMessageTypeError] = useState(false);

  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);

  const [captcha, setCaptcha] = useState("");
  const recaptcha = React.createRef();

  const [disable, setDisable] = useState(true);
  const [show, setShow] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      !name ||
      nameError === true ||
      !email ||
      emailError === true ||
      !messageType ||
      messageTypeError === true ||
      !captcha ||
      !description ||
      descriptionError === true
    )
      setDisable(true);
    else setDisable(false);
  });

  const data = {
    Name: name,
    Email: email,
    PhoneNumber: phone,
    CompanyName: company,
    MessageTypeId: messageType,
    Message: description,
  };

  const onChange = (value) => {
    setCaptcha(value);
  };

  const submit = () => {
    recaptcha.current.reset();
    setLoading(true);
    postMsg(data).then((x) => {
      setShow(true);
      setName("");
      setEmail("");
      setPhone("");
      setCompany("");
      setMessageType("");
      setDescription("");
      setCaptcha("");
      setDisable(true);
      setLoading(false);
    });
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <Grid
      container
      sx={style.backgroundContainer}
      direction="row-reverse"
      rowSpacing={1}
    >
      {show ? (
        <Slide direction="left" in={show} mountOnEnter unmountOnExit enter>
          <Grid
            item
            xs={11.5}
            md={3.5}
            marginTop={10}
            paddingRight={{ xs: 4, md: 0 }}
            paddingBottom="5%"
          >
            <Grid
              item
              container
              direction={"column"}
              alignItems={"center"}
              marginTop={1}
              md={9}
              sx={{
                boxShadow: " 0 17px 12px 0 rgba(0, 0, 0, 0.16)",
                border: "solid 1px #eaf0f5",
                backgroundColor: "#fff",
              }}
              paddingBottom={5}
            >
              <Grid
                item
                md={12}
                xs={12}
                paddingRight={2}
                container
                onClick={(e) => {
                  setShow(false);
                }}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                direction={"row-reverse"}
                paddingTop={2}
              >
                <img src={close} />
              </Grid>
              <Grid item marginTop={3}>
                <img src={sent} alt="sent" />
              </Grid>
              <Grid item marginTop={7}>
                <div
                  style={{
                    fontFamily: "Muli",
                    fontSize: 25,
                    fontWeight: "bold",
                    lineHeight: 1.44,
                    textAlign: "center",
                    color: "#101650",
                  }}
                >
                  Thank you for contacting Pilecubes
                </div>
              </Grid>
              <Grid
                item
                marginTop={2}
                sx={{
                  fontFamily: "Muli",
                  fontSize: 15,
                  lineHeight: 1.47,
                  textAlign: "left",
                  color: "#101650",
                }}
                alignItems={"center"}
              >
                Our team will get back to you shortly.
              </Grid>
            </Grid>
          </Grid>
        </Slide>
      ) : (
        <Grid item xs={11.5} md={3.5} marginTop="6%"></Grid>
      )}
      <Grid
        item
        xs={12}
        md={7.5}
        container
        rowSpacing={1}
        height={{ md: "650px" }}
      >
        <Grid
          item
          xs={12}
          md={8}
          container
          paddingRight={{ xs: 5, md: 18.5 }}
          gap={{ xs: 1.5, md: 1 }}
        >
          <Grid item xs={12} sx={style.title}>
            How would you like to contact Pilecubes?
          </Grid>
          <Grid item xs={12} sx={style.desc}>
            Please fill in your contact information & leave a message. We'll get
            back to
            <br />
            you shortly.
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={12} sx={style.label}>
              *Name
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                inputProps={{
                  style: {
                    height: "5px",
                    backgroundColor: "#fff",
                    borderRadius: 5,
                  },
                  maxLength: 150,
                }}
                value={name}
                error={nameError}
                helperText={nameError ? "Please enter your name" : ""}
                onBlur={(e) => {
                  if (!e.target.value) setNameError(true);
                  else setNameError(false);
                }}
                onChange={(e) => {
                  if (!e.target.value) setNameError(true);
                  else setNameError(false);
                  setName(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={12} sx={style.label}>
              *Your (business) email address
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                inputProps={{
                  style: {
                    height: "5px",
                    backgroundColor: "#fff",
                    borderRadius: 5,
                  },
                  maxLength: 150,
                }}
                value={email}
                error={emailError}
                helperText={
                  emailError
                    ? emailInvalid
                      ? "Please enter a valid email"
                      : "Please enter your email"
                    : ""
                }
                onBlur={(e) => {
                  if (!e.target.value) setEmailError(true);
                  else setEmailError(false);
                }}
                onChange={(e) => {
                  if (!e.target.value) {
                    setEmailError(true);
                    setEmailInvalid(false);
                  } else if (
                    !e.target.value.match(
                      /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/
                    )
                  ) {
                    setEmailError(true);
                    setEmailInvalid(true);
                  } else {
                    setEmailError(false);
                    setEmailInvalid(false);
                  }
                  setEmail(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={12} sx={style.label}>
              Phone number
            </Grid>
            <Grid item xs={12}>
              <PhoneInput
                placeholder="Enter phone number"
                enableAreaCodes={true}
                value={phone}
                onChange={(phone) => {
                  setPhone(phone);
                }}
                country={"lb"}
                inputStyle={{
                  borderRadius: 5,
                  backgroundColor: "#fff",
                  height: "5px",
                  width: "100%",
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={12} sx={style.label}>
              Company name
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                inputProps={{
                  style: {
                    height: "5px",
                    backgroundColor: "#fff",
                    borderRadius: 5,
                  },
                  maxLength: 150,
                }}
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container gap={{ xs: 1.5, md: 1 }}>
            <Grid item xs={12} sx={style.label}>
              *Type of message
            </Grid>
            <Grid item xs={12} container gap={1}>
              <Grid item>
                <Button
                  variant="contained"
                  sx={style.selectButton}
                  style={{
                    width: 180,
                    opacity: messageType === INVESTOR ? 1 : 0.5,
                  }}
                  onClick={(e) => {
                    setMessageType(INVESTOR);
                    setMessageTypeError(false);
                  }}
                >
                  Investor Relationships
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  sx={style.selectButton}
                  style={{
                    width: 92.4,
                    opacity: messageType === FEEDBACK ? 1 : 0.5,
                  }}
                  onClick={(e) => {
                    setMessageType(FEEDBACK);
                    setMessageTypeError(false);
                  }}
                >
                  Feedback
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  sx={style.selectButton}
                  style={{
                    width: 95.7,
                    opacity: messageType === PARTNERCHIP ? 1 : 0.5,
                  }}
                  onClick={(e) => {
                    setMessageType(PARTNERCHIP);
                    setMessageTypeError(false);
                  }}
                >
                  Partnership
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  sx={style.selectButton}
                  style={{
                    width: 84,
                    opacity: messageType === OTHER ? 1 : 0.5,
                  }}
                  onClick={(e) => {
                    setMessageType(OTHER);
                    setMessageTypeError(false);
                  }}
                >
                  Other
                </Button>
              </Grid>
            </Grid>
            <div
              style={{
                color: "#d32f2f",
                fontSize: 12,
                padding: "1% 0 0 2%",
                lineHeight: 1,
              }}
            >
              {messageTypeError ? "Please enter a type" : ""}
            </div>
            <Grid item xs={12} paddingTop={1}>
              <TextField
                fullWidth
                multiline
                maxRows={6}
                label="We'd love to hear how we can help"
                style={{ backgroundColor: "#fff", borderRadius: 5 }}
                inputProps={{
                  style: {
                    backgroundColor: "#fff",
                    borderRadius: 5,
                  },
                  maxLength: 255,
                }}
                value={description}
                error={descriptionError}
                onBlur={(e) => {
                  if (!e.target.value) setDescriptionError(true);
                  else setDescriptionError(false);
                }}
                onChange={(e) => {
                  if (!messageType) setMessageTypeError(true);
                  else setMessageTypeError(false);
                  if (!e.target.value) setDescriptionError(true);
                  else setDescriptionError(false);
                  setDescription(e.target.value);
                }}
              />
              <div
                style={{
                  color: "#d32f2f",
                  fontSize: 12,
                  padding: "1% 0 0 2%",
                  lineHeight: 1,
                }}
              >
                {descriptionError ? "Please enter your message" : ""}
              </div>
            </Grid>
            <Grid item xs={12}>
              <ReCAPTCHA
                ref={recaptcha}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={onChange}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} paddingTop="1%">
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                sx={{
                  boxShadow: "0 3px 6px 0 #2da4d0",
                  backgroundColor: "#fff",
                  fontFamily: "Muli",
                  fontSize: 12,
                  fontWeight: "bold",
                  lineHeight: 1.5,
                  textAlign: "left",
                  color: "#0674b9",
                  textTransform: "none",
                  width: 112,
                  height: 35,
                }}
                disabled={disable}
                onMouseOver={(e) => {
                  e.target.style.color = "#fff";
                  e.target.style.backgroundColor = "#2da4d0";
                }}
                onMouseLeave={(e) => {
                  e.target.style.color = "#0674b9";
                  e.target.style.backgroundColor = "#fff";
                }}
                onClick={submit}
              >
                Submit
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          container
          paddingBottom="5%"
          paddingLeft={{ xs: "5%", md: 0 }}
        >
          <Grid item xs={12}></Grid>
          <Grid item xs={12} paddingTop="10%">
            <img
              src={icon}
              alt="icon"
              style={{ width: "280px", height: "280px" }}
            />
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12} sx={style.title}>
            Get in touch:
          </Grid>
          <Grid
            item
            xs={12}
            container
            alignItems="flex-start"
            direction={"row"}
            gap={1}
          >
            <Grid item paddingTop={1}>
              <LocationOnIcon htmlColor="#101650" />
            </Grid>
            <Grid item sx={style.info}>
              <a
                href="https://goo.gl/maps/uAXLTiQpRNyCUXFs9"
                style={{ textDecoration: "none", color: "#101650" }}
              >
                Cyprus, Nicosia 1011,
                <br />
                Ledras Street 192
              </a>
            </Grid>
          </Grid>
          <Grid item xs={12} container alignItems="flex-start">
            <Grid item xs={1.7}>
              <EmailIcon htmlColor="#101650" />
            </Grid>
            <Grid item sx={style.info}>
              <a
                href={process.env.REACT_APP_INFO}
                style={{ textDecoration: "none", color: "#101650" }}
              >
                info@pilecubes.com
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
