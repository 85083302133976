import { createSlice, configureStore } from "@reduxjs/toolkit";

const initialState = {
  userData: [],
  notficationDisplayCounter: 0,
  TipsDelayedTo: "",
  UserPermissions: [],
  CompanyName: "",
  DisplayName: "",
  IsUserVerified: false,
  IsGuest: false,
  IsTeamMember: false,
  IsUserSubscribed: false,
  ProfilePictureUrl: "",
  numOfInvitations: 0,
};
const userDataSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    setProfilePictureUrl(state, action) {
      state.ProfilePictureUrl = action.payload;
    },
    setDisplayName(state, action) {
      state.DisplayName = action.payload;
    },
    setIsUserVerified(state, action) {
      state.IsUserVerified = action.payload;
    },
    setIsGuest(state, action) {
      state.IsGuest = action.payload;
    },
    setIsTeamMember(state, action) {
      state.IsTeamMember = action.payload;
    },
    setIsUserSubscribed(state, action) {
      state.IsUserSubscribed = action.payload;
    },
    setCompanyName(state, action) {
      state.CompanyName = action.payload;
    },
    setUserData(state, action) {
      state.userData = action.payload;
    },
    setNotificationDisplayCounter(state, action) {
      state.notficationDisplayCounter = action.payload;
    },
    setNumOfInvitations(state, action) {
      state.numOfInvitations = action.payload;
    },
    addNotification(state, action) {
      return {
        ...state,
        userData: [...state.userData, action.payload],
      };
    },
    IncrementNotificationCounter(state) {
      state.notficationDisplayCounter++;
    },
    DecrementNotificationCounter(state) {
      state.notficationDisplayCounter--;
    },
    DecrementInvitationsCounter(state) {
      state.numOfInvitations--;
    },
    setTipsDelayedTo(state, action) {
      state.TipsDelayedTo = action.payload;
      // console.log(state.TipsDelayedTo);
    },
    setUserPermissions(state, action) {
      state.UserPermissions = action.payload;
    },
  },
});

const store = configureStore({
  reducer: { userData: userDataSlice.reducer },
});
export const userDataActions = userDataSlice.actions;
export default store;
