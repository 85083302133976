import React from "react";
import { Grid } from "@mui/material";
import DraftIcon from "../../Assets/Images/SalesOrder/Statuses/draftNavy.png";
import PlacedIcon from "../../Assets/Images/SalesOrder/Statuses/placedNavy.png";
import PlannedIcon from "../../Assets/Images/SalesOrder/Statuses/plannedNavy.png";
import ProductionIcon from "../../Assets/Images/SalesOrder/Statuses/draftNavy.png";
import LoadingIcon from "../../Assets/Images/SalesOrder/Statuses/loadingNavy.png";
import DeliveredIcon from "../../Assets/Images/SalesOrder/Statuses/deliveredNavy.png";
import salesOrderCard from "../../Assets/Images/SalesOrderStatuses/salesOrderCard.png";
import salesOrderCardYellow from "../../Assets/Images/SalesOrderStatuses/salesOrderCardYellow.png";
import fullyFlag from "../../Assets/Images/fullyFlag.png";
import partiallyFlag from "../../Assets/Images/partiallyFlag.png";
import { useNavigate } from "react-router-dom";
import * as salesOrderConst from "../../../salesOrderConst";

export default function SalesOrderCard(props) {
  const navigate = useNavigate();
  return (
    <Grid
      item
      container
      gap={1}
      onMouseOver={(e) => {
        e.target.style.cursor = "pointer";
      }}
      onClick={() => {
        navigate(`/saas/salesOrderForm?id=${props.data.Id}`);
      }}
      sx={{
        backgroundImage:
          props.data.StatusId === salesOrderConst.DELIVERED_ID
            ? `url(${salesOrderCardYellow})`
            : `url(${salesOrderCard})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        fontFamily: "Muli",
      }}
      direction="column"
      // justifyContent="center"
      // padding="10% 10% 25% 20%"
      height="16rem"
      paddingLeft={{ xs: 3, sm: 4 }}
      paddingTop={{ xs: 1.8, sm: 3 }}
      xs={12}
    >
      <Grid item sx={{ color: "#0674b9", fontSize: 10 }}>
        <img
          src={props.data.SelectionId === 1 ? partiallyFlag : fullyFlag}
          width="20px"
          height="25px"
        />
      </Grid>
      <Grid
        item
        sx={{
          fontSize: "14px",
          color: "#101650",
          fontWeight: "bold",
        }}
        paddingTop={{ xs: 1, sm: 0.2, md: 1.2 }}
      >
        {props.data.Code.length > 18
          ? "..." + props.data.Code.slice(props.data.Code.length - 15)
          : props.data.Code}{" "}
      </Grid>
      <Grid
        item
        container
        direction="row"
        spacing={1}
        paddingTop={{ xs: 1, sm: 2 }}
        alignContent="center"
      >
        <Grid
          item
          paddingTop={0.5}
          sx={{
            color:
              props.data.StatusId === salesOrderConst.DRAFT_ID
                ? "#0674b9"
                : props.data.StatusId === salesOrderConst.PLACED_ID
                ? "#5fba47"
                : props.data.StatusId === salesOrderConst.PLANNED_ID
                ? "#2da4d0"
                : props.data.StatusId === salesOrderConst.IN_PRODUCTION_ID
                ? "#e1e319"
                : props.data.StatusId === salesOrderConst.LOADING_ID
                ? "#rgba(16, 22, 80, 0.5)"
                : props.data.StatusId === salesOrderConst.DELIVERED_ID
                ? "#101650"
                : props.data.StatusId === salesOrderConst.HOLD_ID
                ? "red"
                : props.data.StatusId === salesOrderConst.INTERRUPTED_ID
                ? "#ffb303"
                : "",
            fontSize: 12,
            fontStyle: "italic",
          }}
        >
          {props.data.StatusId === salesOrderConst.DRAFT_ID
            ? salesOrderConst.DRAFT
            : props.data.StatusId === salesOrderConst.PLACED_ID
            ? salesOrderConst.PLACED
            : props.data.StatusId === salesOrderConst.PLANNED_ID
            ? salesOrderConst.PLANNED
            : props.data.StatusId === salesOrderConst.IN_PRODUCTION_ID
            ? salesOrderConst.IN_PRODUCTION
            : props.data.StatusId === salesOrderConst.LOADING_ID
            ? salesOrderConst.LOADING
            : props.data.StatusId === salesOrderConst.DELIVERED_ID
            ? salesOrderConst.DELIVERED
            : props.data.StatusId === salesOrderConst.HOLD_ID
            ? salesOrderConst.ON_HOLD
            : props.data.StatusId === salesOrderConst.INTERRUPTED_ID
            ? salesOrderConst.INTERRUPTED
            : props.data.StatusId === salesOrderConst.PRODUCED_ID?
            salesOrderConst.PRODUCED
            :""}
        </Grid>
        {props.data.StatusId !== salesOrderConst.HOLD_ID &&
          props.data.StatusId !== salesOrderConst.INTERRUPTED_ID &&
          props.data.StatusId !== salesOrderConst.PRODUCED_ID?
          <Grid item>
          <img
            src={
              props.data.StatusId === salesOrderConst.DRAFT_ID
                ? DraftIcon
                : props.data.StatusId === salesOrderConst.PLACED_ID
                ? PlacedIcon
                : props.data.StatusId === salesOrderConst.PLANNED_ID
                ? PlannedIcon
                : props.data.StatusId === salesOrderConst.IN_PRODUCTION_ID
                ? ProductionIcon
                : props.data.StatusId === salesOrderConst.LOADING_ID
                ? LoadingIcon
                : props.data.StatusId === salesOrderConst.DELIVERED_ID
                ? DeliveredIcon
                : ""
            }
            width="80%"
            alt="icons"
          />
        </Grid>
        :
        <></>
          }
     
      </Grid>
      {props.data.IsDelayed === true ? (
        <Grid
          item
          sx={{
            color: "rgba(255, 0, 0, 0.48)",
            fontSize: "9px",
            fontFamily: "Muli",
            fontWeight: "bold",
          }}
        >
          Delay in Delivery
        </Grid>
      ) : props.data.DeliveryStatusId === 2 ? (
        <Grid
          item
          sx={{
            color: "#e1e319",
            fontSize: "9px",
            fontFamily: "Muli",
            fontWeight: "bold",
          }}
        >
          Requested
        </Grid>
      ) : (
        ""
      )}
      <Grid
        item
        sx={{
          color:
            props.data.IsDelayed === true
              ? "rgba(255, 0, 0, 0.48)"
              : props.data.DeliveryStatusId === 1
              ? "#0674b9"
              : props.data.DeliveryStatusId === 2
              ? "#e1e319"
              : "#5fba47",
        }}
      >
        {props.data.DeliveryDate?.slice(0, 10)}
      </Grid>
      <Grid item sx={{ color: "#0674b9", fontSize: 10 }} paddingBottom={2}>
        {props.data.SelectionId === 1 ? "Partially" : "Fully"} Ordered
      </Grid>
    </Grid>
  );
}
