import React, { useEffect, useState, useMemo } from "react";
import SaasBack from "../../../Assets/Images/SaaSbackground.png";
import { useNavigate, useLocation } from "react-router-dom";
import classes from "../../../Assets/Styles/factory.module.css";
import {
  Backdrop,
  CircularProgress,
  Dialog,
  Grid,
  Button,
} from "@mui/material";
import LabelNote from "./LabelNote";
import * as DeliveryServices from "../../../Services/delivery-services.proxy";
import fileIcon from "../../../Assets/Images/FileIcon.png";

const getDeliveryNoteById = DeliveryServices.getDeliveryNoteById;
const nextdeliveryNote = DeliveryServices.nextdeliveryNote;

export default function DeliveryNote(props) {
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const deliveryNoteId = query.get("deliveryNoteId");
  const shipmentId = query.get("shipmentId");

  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const [loadingData, setLoadingData] = useState(false);

  const [validated, setValidated] = useState(false);

  const freeCore = localStorage.getItem("planIdManu")?.includes("Pro") ? false : true;

  const [open, setOpen] = useState(null);
  const handleClick = (event) => {
    setOpen(event.currentTarget);
  };
  const [labels, setLabels] = useState([]);
  const [labelId, setLabelId] = useState(0);

  const [deliveryDetails, setDeliveryDetails] = useState();

  const [hasNext, setHasNext] = useState(false);
  const [productsAttached, setProductsAttached] = useState(false);


  const handleClose = () => {
    if (shipmentId) {
      navigate(
        `/saas/Delivery/DeliveryNote/print?shipmentId=${shipmentId}&labelId=${labelId}&deliveryNoteId=${deliveryNoteId}&HasNext=${hasNext}`
      );
    } else {
      navigate(
        `/saas/Delivery/DeliveryNote/print?labelId=${labelId}&deliveryNoteId=${deliveryNoteId}&HasNext=${hasNext}`
      );
    }

    setOpen(null);
  };

  useEffect(() => {
    setLoadingData(true);
    if (shipmentId || deliveryNoteId)
      getDeliveryNoteById(
        shipmentId ? shipmentId : null,
        deliveryNoteId ? deliveryNoteId : null
      ).then((x) => {
        setDeliveryDetails(x);
        setLoadingData(false);
        setLabels(x.Labels);
        setHasNext(x.HasNext);
      });
  }, [shipmentId, deliveryNoteId]);
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
        objectFit: "cover",
      }}
    >
      <Grid item container paddingTop={3} rowGap={2} paddingBottom={2}>
        <Backdrop
          sx={{
            color: "#fff",
            backgroundColor: "transparent",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            display: "flex",
            flexDirection: "column",
          }}
          open={loadingData}
        >
          <CircularProgress />

          <Grid
            item
            fontFamily={"Muli"}
            fontSize={15}
            fontWeight={"bold"}
            color={"rgb(25, 118, 210)"}
          >
            Loading Data...
          </Grid>
        </Backdrop>
        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="center"
          gap={1}
          alignItems="center"
        >
          <Grid
            item
            container
            direction="column"
            lg={2}
            sm={3}
            xs={4}
            className={classes["Title"]}
            paddingTop={{ xs: 1.5, sm: 3 }}
          >
            <Grid
              item
              sx={{
                fontSize: { xs: "14px", sm: "18px" },
                fontWeight: "bold",
              }}
            >
              Shipment Details
            </Grid>
            <Grid
              item
              sx={{
                fontSize: { xs: "12px", sm: "15px" },
                fontWeight: "bold",
              }}
            >
              {query.get("shipmentNb")} | {query.get("loadNb")}
            </Grid>
            <Grid
              item
              sx={{
                fontSize: { xs: "13px", sm: "16px" },
              }}
            >
              {deliveryDetails?.SalesOrder.Code} - {deliveryDetails?.DeliveryNote.Satuts}
            </Grid>
          </Grid>
          <Grid
            item
            xs={1}
            sm={6}
            lg={8}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>
          <Grid item>
            <button
              className={classes["back-button"]}
              onClick={() => {
                navigate("/saas/Delivery/Calendar");
              }}
            >
              Go Back
            </button>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          paddingLeft={{ xs: 0, sm: "2%" }}
          justifyContent="center"
          alignItems="flex-start"
          direction="row-reverse"
          gap={1}
        >
          <Grid item xs={12} md={4} paddingTop="4%">
            {deliveryNoteId ? (
              <>
                {localStorage.getItem("planIdManu")?.includes("Pro") &&
                  hasNext ? (
                  <button
                    style={{
                      border: 0,
                      width: "71px",
                      height: "32px",
                      borderRadius: "5px",
                      backgroundColor: "#fff",
                      fontFamily: "Muli",
                      fontSize: "13px",
                      color: "#0674b9",
                      cursor: "pointer",
                      marginLeft: "2%",
                    }}
                    onClick={() => {
                      nextdeliveryNote(deliveryNoteId).then(() => {
                        navigate(
                          `/saas/Delivery/DeliveryNote?shipmentId=${shipmentId}`
                        );
                      });
                    }}
                  >
                    Next
                  </button>
                ) : (
                  ""
                )}

                <button
                  style={{
                    border: 0,
                    width: "71px",
                    height: "32px",
                    borderRadius: "5px",
                    backgroundColor: "#0674b9",
                    fontFamily: "Muli",
                    fontSize: "13px",
                    color: "#fff",
                    cursor: "pointer",
                    marginLeft: localStorage
                      .getItem("planIdManu")
                      ?.includes("Pro")
                      ? "5%"
                      : "15%",
                  }}
                  onClick={handleClick}
                >
                  Print
                </button>
                <Dialog
                  onClose={() => setOpen(null)}
                  open={open}
                  hideBackdrop={true}
                  sx={{
                    margin: {
                      xs: "0 0 300px 100px",
                      sm: "0 200px 600px 0",
                      md:
                        labels.length > 2 ? "85px 0 0 770px" : "45px 0 0 770px",
                    },
                  }}
                >
                  <Grid
                    item
                    container
                    direction="column"
                    width="156px"
                    padding="5%"
                  >
                    <Grid
                      item
                      sx={{
                        fontFamily: "Muli",
                        fontSize: "13px",
                        fontWeight: "bold",
                        lineHeight: 1.23,
                        textAlign: "left",
                        color: "#0674b9",
                      }}
                    >
                      Select template
                    </Grid>
                    {labels.map((l) => (
                      <Grid
                        key={l.Id}
                        item
                        container
                        alignItems="center"
                        gap={1}
                      >
                        <Grid
                          item
                          sx={{
                            width: "10px",
                            height: "10px",
                            border: "solid 1px #707070",
                            cursor: "pointer",
                            backgroundColor:
                              labelId === l.Id ? "#707070" : "#fff",
                          }}
                          onClick={() => {
                            setLabelId(l.Id);
                          }}
                        />
                        <Grid
                          item
                          sx={{
                            fontFamily: "Muli",
                            fontSize: "13px",
                            lineHeight: 1.23,
                            textAlign: "left",
                            color: "#0674b9",
                          }}
                        >
                          {l.Name}
                        </Grid>
                      </Grid>
                    ))}

                    <Grid item container direction="row-reverse">
                      <Grid item>
                        <button
                        disabled={!labelId}
                          style={{
                            border: 0,
                            fontFamily: "Muli",
                            fontSize: "8px",
                            lineHeight: 1.25,
                            textAlign: "left",
                            color: "#fff",
                            width: "44px",
                            height: "20px",
                            borderRadius: "2px",
                            backgroundColor: labelId?"#0674b9":"#707070",
                            cursor: labelId?"pointer":"unset",
                          }}
                          onClick={handleClose}
                        >
                          Confirm
                        </button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Dialog>
              </>
            ) : !deliveryDetails?.DeliveryNote?.IsConfirmed ? (
              <Button
                sx={{
                  width: "183px",
                  height: "32px",
                  borderRadius: "5px",
                  backgroundColor: "#0674b9",
                  fontFamily: "Muli",
                  fontSize: "13px",
                  color: "#fff",
                  textTransform: "none",
                  marginLeft: "2%",
                }}
                onClick={() =>
                  navigate(
                    `/saas/Delivery/SaleOrderNote?deliveryNoteId=${deliveryDetails.DeliveryNote.Id}&shipmentId=${shipmentId}`
                  )
                }
              >
                View Products Attached
              </Button>
            ) : (
              <></>
            )}
            {deliveryDetails?.DeliveryNote?.File ? (
              <Grid item container direction={"row"} marginTop={3} gap={1}>
                <Grid
                  item
                  container
                  direction={"row"}
                  backgroundColor="#fff"
                  width="150px"
                  alignItems="center"
                  paddingLeft={0.5}
                  borderRadius="5px"
                >
                  <Grid item>
                    <img src={fileIcon} alt="file" width={15} /> &nbsp;
                  </Grid>
                  <Grid item fontFamily="Muli" color="#707070" style={{
                    width: "100px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                  >
                    {deliveryDetails.DeliveryNote?.File?.DisplayName}
                  </Grid>
                </Grid>
                <Grid
                  item
                  onClick={() => {
                    window.open(deliveryDetails.DeliveryNote?.File.URL);
                  }}
                >
                  <button className={classes["save-button"]}>Download</button>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={12} md={7} alignItems="center">
            <Grid
              item
              xs={12}
              sx={{
                fontFamily: "Muli",
                fontSize: "18px",
                fontWeight: "bold",
                lineHeight: 1.28,
                textAlign: "left",
                color: "#101650",
              }}
            >
              Delivery Note
            </Grid>
            {deliveryDetails ? (
              <LabelNote deliveryDetails={deliveryDetails} />
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
