import React, { useEffect, useState, useMemo } from "react";
import { Grid } from "@mui/material";
import classes from "../../../../Assets/Styles/RFIS.module.css";
import logo2 from "../../../../../Assets/Images/Navbar/logo.png";
import FileIcon from "../../../../Assets/Images/Table/file.png";
import { useLocation } from "react-router-dom";
import * as rfiServices from "../../../../Services/rfi-services.proxy";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/system";
import infoTip from "../../../../../Assets/Images/form/info.png";
const getRFIByID = rfiServices.getRFIByID;
const downloadRfiFile = rfiServices.downloadRfiFile;
const RFISubmittals = (props) => {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);
  useEffect(() => {
    props.setNaved(false);
    props.setTerms(false);
  }, [props.setNaved]);
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const [rfiDetails, setRfiDetails] = useState({});
  // const [rfiDetails, setRfiDetails] = useState({});

  const query = useQuery();
  const RFIID = query.get("token");
  const [linkExpired, setLinkExpired] = useState(false);
  useEffect(() => {
    getRFIByID(RFIID).then((x) => {
      if (x.status === 406) {
        setLinkExpired(true);
      }
      setRfiDetails(x);
    });
  }, [RFIID]);
  const downloadTheRfi = (e) => {
    e.preventDefault();

    downloadRfiFile(RFIID);
  };
  return (
    <Grid container>
      <Grid item xs={12} paddingTop={3} paddingLeft={5}>
        <img src={logo2} alt={"logo"} />
      </Grid>
      <Grid container paddingTop={10}>
        <Grid item xs={12} className={classes["Request-for-Information"]}>
          Request for Information
        </Grid>
        <Grid
          item
          xs={12}
          alignItems={"center"}
          justifyContent={"center"}
          container
        >
          <Grid
            item
            xs={10}
            alignSelf="center"
            className={classes["Line-564"]}
          ></Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          paddingLeft={{ md: 15, xs: 0 }}
          paddingTop={{ md: 12, xs: 0 }}
          gap={10}
        >
          <Grid item xs={12} md={3.5}>
            <Grid item xs={12} className={classes["DataTitle"]}>
              Subject:
            </Grid>
            <Grid item xs={12} className={classes["Actualdata"]} paddingTop={1}>
              {rfiDetails?.Subject}
            </Grid>
            <Grid item xs={12} className={classes["DataTitle"]} paddingTop={5}>
              Description:
            </Grid>
            <Grid item xs={12} className={classes["Actualdata"]} paddingTop={1}>
              {rfiDetails?.Description}
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
            <Grid item xs={12} className={classes["DataTitle"]}>
              Status:
            </Grid>
            <Grid item xs={12} className={classes["Actualdata"]} paddingTop={1}>
              {rfiDetails?.Status}
            </Grid>
            <Grid item xs={12} className={classes["DataTitle"]} paddingTop={5}>
              Reference:
            </Grid>
            {rfiDetails?.Documents?.map((document) => (
              <Grid
                key={document.Id}
                item
                xs={12}
                className={classes["Actualdata"]}
                paddingTop={1}
              >
                {document.Name}
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} md={3}>
            <Grid item xs={12} className={classes["DataTitle"]}>
              Assignee:
            </Grid>
            <Grid item xs={12} className={classes["Actualdata"]} paddingTop={1}>
              {rfiDetails?.Assignee}
            </Grid>
            <Grid item xs={12} className={classes["DataTitle"]} paddingTop={5}>
              File Uploads:
            </Grid>
            {rfiDetails?.Files?.map((file) => (
              <Grid
                container
                direction={"row"}
                item
                className={classes["Actualdata"]}
                paddingTop={1}
                key={file.FileId}
                gap={1}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={() => {
                  window.open(file.URL);
                }}
              >
                <Grid item>
                  <img src={FileIcon} alt={"file"} />
                </Grid>
                <Grid item className={classes["Actualdata"]}>
                  {file.DisplayName}
                </Grid>
              </Grid>
            ))}
            <BlueTip title={"Click on each file to download"} placement="right">
              <img
                width={"15px"}
                style={{ marginTop: "15px" }}
                src={infoTip}
                alt={"close"}
              ></img>
            </BlueTip>
            <Grid
              item
              className={classes["DownloadAll"]}
              
              marginTop={2}
              onClick={(e) => {
                downloadTheRfi(e);
              }}
            >
              Download Response Form
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            alignItems={"center"}
            justifyContent={"center"}
            fontFamily={"Muli"}
            color={"#ea001e"}
          >
            {linkExpired
              ? "This link has expired please contact the company to send you another one."
              : ""}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
const BlueTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
      color: #101650;
      background-color: rgba(6, 116, 185, 0.3);
      font-size: 13px;
      width:200px;
   
  `);
export default RFISubmittals;
