import React, { useEffect, useState, useMemo } from "react";
import classes from "../../Assets/Styles/Delivery.module.css"
import { Grid } from "@mui/material";
import DeliveryCard from "./DeliveryNoteCard";
import SaasBack from "../../Assets/Images/Projects/bgTableProject.png";
import { useNavigate, useLocation } from "react-router-dom";
import * as DeliveryServices from "../../Services/delivery-services.proxy";
import CircularProgress from "@mui/material/CircularProgress";

const getDeliveryNoteCardList = DeliveryServices.getDeliveryNoteCardList;
function DeliveryNoteListCard(props) {
  const navigate = useNavigate();

  useEffect(() => {
    props.setRegister(true);
  }, [props]);

  useEffect(() => {
    props.setNaved(false);
  }, [props]);
  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);

  const [deliveriesCards, setDeliveriesCards] = useState([
  ]);

  const [loading, setLoading] = useState(false);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const salesOrderId = query.get("salesOrderId");

  useEffect(() => {
    let apiTest = true;
    setLoading(true);
    getDeliveryNoteCardList(salesOrderId).then((x) => {
      if (apiTest) {
        setDeliveriesCards(x);
        setLoading(false);
      }
    });
    return () => {
      apiTest = false;
    };
  }, [salesOrderId]);

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <Grid
        item
        container
        paddingLeft={{ xs: "0%", sm: "1%", md: "5%" }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          container
          direction="row"
          gap={1}
          paddingTop={6}
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            lg={2.5}
            sm={2.5}
            xs={2.5}
            className={classes["CardsTitle"]}
          >
           Sales Order - Delivery Note Detail
          
          </Grid>

          <Grid
            item
            lg={8}
            sm={5.5}
            xs={3.5}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>
  

          <Grid item lg={0.9} sm={1}>
            <button
              className={classes["back-button"]}
              onClick={() => {
                navigate(-1);
              }}
            >
              Go Back
            </button>
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={11.5}
          paddingTop="2%"
          alignItems="flex-start"
          gap={2}
          // marginBottom={2}
        >
          {loading ? (
            <Grid item xs={12} container justifyContent="center">
              <Grid item paddingTop="5%">
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <>
              {deliveriesCards?.length > 0 ? (
                <>
                  {deliveriesCards?.map((data, index) => (
                    <Grid item xs={5.7} sm={3} md={2.5} lg={1.8} xl={1.6} key={data.Id}>
                      <DeliveryCard data={data} index={index} />
                    </Grid>
                  ))}
                </>
              ) : (
                <Grid
                  item
                  style={{
                    fontFamily: "Muli",
                    fontSize: "13px",
                    color: "#101650",
                  }}
                >
                  
                  No Deliveries
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default DeliveryNoteListCard;
