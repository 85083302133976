import React, { useState, useEffect, useMemo } from "react";
import SaasBack from "../../../../Assets/Images/Projects/bgTableProject.png";
import {
  Grid,
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useLocation } from "react-router-dom";
import classing from "../../../../Assets/Styles/factory.module.css";
import { withStyles } from "@material-ui/core/styles";
import * as machineBlockServices from "../../../../Services/machine-block-services.proxy";
import ErrorModal from "../../../../SharedComponents/ErrorModal";
const holdedOrdersOnMachine = machineBlockServices.holdedOrdersOnMachine;
const setBackupMachine = machineBlockServices.setBackupMachine;

const HoldedOrders = (props) => {
  useEffect(() => {
    props.setRegister(true);
  }, [props]);

  useEffect(() => {
    props.setNaved(false);
  }, [props]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const machineId = query?.get("id");
  const MachineName = query?.get("name");
  const [machinesList, setMachinesList] = useState([]);
  const [overloaded, setOverloaded] = useState(false);

  const [backupId, setBackupId] = useState(null);
  const [combinations, setCombinations] = useState([]);

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [BEerror, setBEerror] = useState(null);
  const [navigateToOnClose, setNavigateToOnClose] = useState(-1);
  const [replacing, setReplacing] = useState(false);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      height: 10,
    },
  }))(TableRow);

  const StyledTableSubRow = withStyles((theme) => ({
    root: {
      height: 58,
    },
  }))(TableRow);
  const AddBlankSpace = (MachineRoutes) => {
    if (MachineRoutes.length === Codes.length) {
      return <></>;
    } else {
      let spaceWidth = Codes.length - MachineRoutes.length;
      var indents = [];
      for (var i = 0; i < spaceWidth; i++) {
        indents.push(
          <TableCell sx={styles.grey} width="50px">
            &nbsp;
          </TableCell>
        );
      }
      return indents;
    }
  };
  const [Codes, setCodes] = useState([]);
  useEffect(() => {
    setLoading(true);
    if (machineId) {
      holdedOrdersOnMachine(machineId).then((x) => {
        if (x.status || x.statusCode) {
          if (x.status) setBEerror("No orders on this machine.")
          if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
          setOpenErrorModal(true);
        } else {
          setCombinations(x.Combinations ? x.Combinations : []);
          setMachinesList(x.Machines ? x.Machines : []);
        }
        setLoading(false);
      });
    }
  }, [machineId]);
  const replaceMachine = () => {
    setReplacing(true);
    setBackupMachine(machineId, backupId).then((x) => {
      setReplacing(false);
      if (x.status || x.statusCode) {
        if (x.status) setBEerror(x.error)
        if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
        setOpenErrorModal(true);
      } else {
        navigate(-1);
      }

    });
  };
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <Grid
        item
        container
        paddingLeft={{ xs: "0%", sm: "1%", md: "5%" }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          container
          direction="row"
          gap={1}
          paddingTop={{ xs: "10%", sm: "7%", md: "2%" }}
        >
          <Grid
            item
            container
            direction="column"
            xs={5}
            paddingTop={{ xs: "4%", sm: "2%", lg: "1%" }}
          >
            <Grid
              item
              fontSize={{ xs: "11px", sm: "18px" }}
              fontWeight="bold"
              className={classing["Title"]}
            >
              Onhold Orders on {MachineName}
            </Grid>
          </Grid>

          <Grid item xs={5.5} container direction={"row-reverse"}>
            <button
              className={classing["back-button"]}
              onClick={() => {
                navigate(-1);
              }}
            >
              Go Back
            </button>
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          paddingTop="1%"
          alignItems="flex-start"
          gap={2}
          marginBottom={2}
        >
          {loading ? (
            <Grid item xs={12} container justifyContent="center">
              <Grid item paddingTop="5%">
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item container direction={"row"} gap={2}>
                <Grid
                  item
                  fontSize={16}
                  fontFamily={"Muli"}
                  color={"#101650"}
                  paddingTop={1}
                >
                  Choose one of the matching machines (same category and same
                  machine setup):
                </Grid>
                <Grid item>
                  <TextField
                    SelectProps={{ MenuProps: { disableScrollLock: true } }}
                    value={backupId}
                    fullWidth
                    select
                    sx={{ minWidth: 120 }}
                    InputProps={{
                      style: {
                        height: "40px",
                        fontSize: 12,
                        fontFamily: "Muli",
                        backgroundColor: "white",
                      },
                    }}
                    onChange={(e) => {
                      setBackupId(e.target.value);
                    }}
                  >
                    <MenuItem value={0} key={0} disabled>
                      Input
                    </MenuItem>
                    {machinesList.map((r) => (
                      <MenuItem value={r.Id} key={r.Name}>
                        {r.Name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <button
                  className={classing["SaveBut"]}
                  // disabled={backupId ? false : true}
                  disabled={backupId ? false : replacing ? true : true}
                  onClick={() => {
                    replaceMachine();
                  }}
                >
                  {replacing ? "Replacing..." : "Replace"}
                </button>
              </Grid>
              {combinations?.map((co, indexCo) => (
                <Grid
                  item
                  xs={11}
                  sx={{
                    borderRadius: "5px",
                    backgroundColor: "#fff",
                  }}
                  marginTop={2}
                >
                  <TableContainer component={Paper}>
                    <Table
                      aria-label="simple table"
                      sx={{
                        borderCollapse: "separate",
                        borderSpacing: "0px 0px",
                        border: "solid 0.5px ",
                        borderColor: "rgba(225, 227, 25, 0.5)",
                        maxWidth: 2000,
                      }}
                    >
                      <TableHead>
                        <StyledTableRow
                          key={indexCo}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                            backgroundColor: "rgba(225, 227, 25, 0.7)",
                          }}
                        >
                          <TableCell sx={styles.blue}>
                            Production Order #{indexCo + 1} placed on {co.Date}
                          </TableCell>
                          <TableCell sx={styles.blue}></TableCell>
                          <TableCell sx={styles.blue}></TableCell>
                          <TableCell sx={styles.blue}></TableCell>
                          <TableCell sx={styles.blue}></TableCell>
                          <TableCell sx={styles.blue}></TableCell>
                          <TableCell sx={styles.blue}></TableCell>
                          <TableCell sx={styles.blue}></TableCell>
                          <TableCell sx={styles.blue}></TableCell>
                          <TableCell sx={styles.blue}></TableCell>
                        </StyledTableRow>
                        <TableRow>
                          <TableCell sx={styles.blue}>Bar Mark No.</TableCell>
                          <TableCell sx={styles.blue}>
                            <Grid
                              container
                              direction="column"
                              alignItems="center"
                            >
                              <Grid item>Qty</Grid>
                              <Grid item width={50} sx={styles.miniBlue}>
                                Total No. of Bars{" "}
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell sx={styles.blue}>Bar Dia.</TableCell>
                          <TableCell sx={styles.blue}>Shape</TableCell>
                          <TableCell sx={styles.blue}>Length</TableCell>
                          <TableCell sx={styles.blue}>Weight</TableCell>

                          <TableCell sx={styles.grey}>Status</TableCell>
                          <TableCell sx={styles.grey}>
                            <Grid
                              container
                              direction="column"
                              alignItems="center"
                            >
                              <Grid item>Route Code</Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {co.SalesOrders?.map((row, index) => (
                        <TableBody>
                          <StyledTableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                              backgroundColor: "rgba(225, 227, 25, 0.7)",
                            }}
                          >
                            <TableCell sx={styles.blue}>
                              Sales Order {row.Code}
                            </TableCell>
                            <TableCell sx={styles.blue}></TableCell>
                            <TableCell sx={styles.blue}></TableCell>
                            <TableCell sx={styles.blue}></TableCell>
                            <TableCell sx={styles.blue}></TableCell>
                            <TableCell sx={styles.blue}></TableCell>
                          </StyledTableRow>
                          {row?.Members?.map((member, index) => (
                            <>
                              <StyledTableRow
                                key={index}
                                sx={{
                                  backgroundColor: "rgba(225, 227, 25, 0.3)",
                                }}
                              >
                                <TableCell sx={styles.minGrey}>
                                  {member.OrderId}
                                </TableCell>
                                <TableCell sx={styles.minGrey}>
                                  {member.Quantity}
                                </TableCell>
                                <TableCell sx={styles.minGrey}>
                                  &nbsp;
                                </TableCell>
                                <TableCell sx={styles.minGrey}>
                                  <Grid container>
                                    <Grid item xs={3} textAlign={"left"}>
                                      {member.Name}
                                    </Grid>
                                    <Grid item xs={3} textAlign={"center"}>
                                      {member.Type}
                                    </Grid>
                                    <Grid item xs={6} textAlign={"right"}>
                                      {member.BarMarkType}
                                    </Grid>
                                  </Grid>
                                </TableCell>
                                <TableCell sx={styles.minGrey}>
                                  &nbsp;
                                </TableCell>
                                <TableCell sx={styles.minGrey}>
                                  {/* {member?.Lines?.sum("ActualWeight").toFixed(3)} */}
                                </TableCell>
                              </StyledTableRow>
                              {member?.Lines?.map((line, i) => (
                                <>
                                  <StyledTableSubRow key={i}>
                                    <TableCell sx={styles.miniminiGrey}>
                                      {line.BarMarkNo}
                                    </TableCell>
                                    <TableCell sx={styles.miniminiGrey}>
                                      <Grid
                                        container
                                        direction="column"
                                        alignItems="center"
                                      >
                                        <Grid item>{line.BarsQuantity}</Grid>
                                        <Grid
                                          item
                                          width={50}
                                          sx={styles.miniBlue}
                                        >
                                          {line.BarsQuantity * member.Quantity}
                                        </Grid>
                                      </Grid>
                                    </TableCell>
                                    <TableCell sx={styles.miniminiGrey}>
                                      {line.BarDiameter}
                                    </TableCell>
                                    <TableCell sx={styles.miniminiGrey}>
                                      <img
                                        src={line.ShapeBlob}
                                        alt={"shape"}
                                        width={150}
                                      />
                                    </TableCell>
                                    <TableCell sx={styles.miniminiGrey}>
                                      {line.ActualLength}
                                    </TableCell>
                                    <TableCell sx={styles.miniminiGrey}>
                                      {line.ActualWeight}
                                    </TableCell>

                                    <TableCell
                                      sx={styles.blue}
                                      style={{ fontSize: "12px" }}
                                    >
                                      Planned
                                    </TableCell>
                                    <TableCell
                                      sx={styles.minGrey}
                                      style={{ fontSize: "12px" }}
                                    >
                                      {!line.Routes || line?.Routes.length === 0
                                        ? "No Routes"
                                        : line.Routes.find(
                                          (r) => r.IsAssigned === true
                                        )?.Code}
                                    </TableCell>

                                    <>
                                      {line?.Routes?.find(
                                        (r) => r.IsAssigned === true
                                      ) ? (
                                        <>
                                          {line?.Routes?.find(
                                            (r) => r.IsAssigned === true
                                          )?.RouteMachines?.map((d, indle) => (
                                            <TableCell
                                              onMouseOver={(e) =>
                                              (e.target.style.cursor =
                                                "pointer")
                                              }
                                              sx={styles.minGrey}
                                              style={{
                                                backgroundColor:
                                                  line.Routes?.find(
                                                    (r) => r.IsAssigned === true
                                                  )?.RouteTypeId === 1
                                                    ? "rgba(225, 227, 25, 0.3)"
                                                    : line.Routes?.find(
                                                      (r) =>
                                                        r.IsAssigned === true
                                                    )?.RouteTypeId === 2
                                                      ? "rgba(225, 227, 25, 0.5)"
                                                      : line.Routes?.find(
                                                        (r) =>
                                                          r.IsAssigned === true
                                                      )?.RouteTypeId === 3
                                                        ? "rgba(45, 164, 208, 0.3)"
                                                        : line.Routes?.find(
                                                          (r) =>
                                                            r.IsAssigned === true
                                                        )?.RouteTypeId === 4
                                                          ? "rgba(95, 186, 71, 0.3)"
                                                          : line.Routes?.find(
                                                            (r) =>
                                                              r.IsAssigned === true
                                                          )?.RouteTypeId === 5
                                                            ? "rgba(95, 186, 71, 0.5)"
                                                            : line.Routes?.find(
                                                              (r) =>
                                                                r.IsAssigned === true
                                                            )?.RouteTypeId === 6
                                                              ? "rgba(0, 0, 0, 0.3)"
                                                              : "rgba(45, 164, 208, 0.5)",
                                                fontSize: "12px",
                                              }}
                                            >
                                              {d.Name}
                                            </TableCell>
                                          ))}

                                          {AddBlankSpace(
                                            line.Routes?.find(
                                              (r) => r.IsAssigned === true
                                            )?.RouteMachines
                                          )}
                                        </>
                                      ) : (
                                        Codes?.map((d, index) => (
                                          <TableCell
                                            key={index}
                                            sx={styles.grey}
                                            width="50px"
                                          >
                                            &nbsp;
                                          </TableCell>
                                        ))
                                      )}
                                    </>

                                    <TableCell
                                      sx={styles.minGrey}
                                      width={75}
                                      height={60}
                                    >
                                      <Grid
                                        sx={{
                                          borderRadius: 2,
                                          border: "solid 1px #f3f3f3",
                                          backgroundColor:
                                            line.Routes?.find(
                                              (r) => r.IsAssigned === true
                                            )?.ReservedCapacity > 100
                                              ? "rgba(255, 0, 0, 0.3)"
                                              : "rgba(45, 164, 208, 0.3)",
                                          width: 70,
                                          height: 55,
                                          fontSize: 17,
                                          color: "#101650",
                                          marginLeft: "5px",
                                          paddingTop: "15%",
                                        }}
                                      >
                                        {
                                          line.Routes?.find(
                                            (r) => r.IsAssigned === true
                                          )?.ReservedCapacity
                                        }
                                        %
                                      </Grid>
                                    </TableCell>
                                  </StyledTableSubRow>
                                </>
                              ))}
                            </>
                          ))}
                        </TableBody>
                      ))}
                    </Table>
                  </TableContainer>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>
      <ErrorModal openErrorModal={openErrorModal} setOpenErrorModal={setOpenErrorModal} BEerror={BEerror} navigateToOnClose={navigateToOnClose}/>

    </div>
  );
};

export default HoldedOrders;
const styles = {
  blue: {
    fontFamily: "Muli",
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "center",
    color: "#101650",
    padding: 0,
    borderTop: "solid 0.5px ",
    borderColor: "rgba(225, 227, 25, 0.5)",
  },
  errorCell: {
    fontFamily: "Muli",
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    color: "#ea001e",
    padding: 0,
    borderTop: "solid 0.5px ",
    borderColor: "rgba(225, 227, 25, 0.5)",
  },
  grey: {
    fontFamily: "Muli",
    fontSize: 13,
    fontWeight: "bold",
    textAlign: "center",
    color: "#707070",
    borderTop: "solid 0.5px ",
    borderColor: "rgba(225, 227, 25, 0.5)",
  },
  miniBlue: {
    fontFamily: "Muli",
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "center",
    color: "#0674b9",
  },
  minGrey: {
    fontFamily: "Muli",
    fontSize: 11,
    textAlign: "center",
    color: "#707070",
    padding: 0,
    borderTop: "solid 0.5px ",
    borderColor: "rgba(225, 227, 25, 0.5)",
  },
  miniminiGrey: {
    fontFamily: "Muli",
    fontSize: 11,
    textAlign: "center",
    color: "#707070",
    padding: 0,
    border: "solid 0.5px ",
    borderColor: "rgba(225, 227, 25, 0.5)",
  },
};
