import React, { useEffect, useState } from "react";
import SaasBack from "../../../../Assets/Images/Projects/bgTableProject.png";
import { useNavigate, useLocation } from "react-router-dom";
import { Grid, TextField, Button } from "@mui/material";
import classes from "../../../../Assets/Styles/RFIS.module.css";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/system";
import uploadIcon from "../../../../Assets/Images/Settings/uploadImg.png";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import * as rfiServices from "../../../../Services/rfi-services.proxy";
import fileIcon from "../../../../Assets/Images/Table/file.png";
import CloseIcon from "@mui/icons-material/Close";
import * as permissionsFunctions from "../../../../../ReusableFunctions";
import { useSelector } from "react-redux";
const CheckIfPermitted = permissionsFunctions.CheckIfPermitted;
const getConsultantsByProject = rfiServices.getConsultantsByProject;
const getTmsByProject = rfiServices.getTmsByProject;
const uploadFileRFI = rfiServices.uploadFileRFI;
const deleteFileRFI = rfiServices.deleteFileRFI;
const createRFI = rfiServices.createRFI;

const AddRFI = (props) => {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);
  const location = useLocation();
  const documentsChosen = location.state.documentsChosen;
  const [documentToSend, setDocumentToSend] = useState([]);
  const [cantSelectTM, setCantSelectTM] = useState(false);
  const [cantSelectCons, setCantSelectCons] = useState(false);

  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  useEffect(() => {
    if (UserPermissions) {
      CheckIfPermitted(
        UserPermissions,
        "RFI",
        "Raise To Member",
        setCantSelectTM
      );
      CheckIfPermitted(
        UserPermissions,
        "RFI",
        "Raise To Consultant",
        setCantSelectCons
      );
    }
  }, [UserPermissions]);
  useEffect(() => {
    if (documentsChosen) {
      for (let i in documentsChosen) {
        documentToSend.push({
          Id: documentsChosen[i].Id,
          Name: documentsChosen[i].Name,
        });
      }
    }
  }, [documentsChosen]);
  const ProjectId = location.state.ProjectId;
  const navigate = useNavigate();
  const [subject, setSubject] = useState();
  const [subjectError, setSubjectError] = useState(false);
  const [subjectErrorMessage, setSubjectErrorMessage] = useState();
  const [description, setDescription] = useState();
  const [descriptionError, setDescriptionError] = useState(false);
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState();
  const [status, setStatus] = useState();
  const [teamMemberIsSelected, setTeamMemberIsSelected] = useState(true);
  const [consultantIsSelected, setConsultantIsSelected] = useState(false);
  const [teamMember, setTeamMember] = useState(null);
  const [teamMemberError, setTeamMemberError] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [consultant, setConsultant] = useState(null);
  const [consultantError, setConsultantError] = useState(false);
  const [consultants, setConsultants] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [files, setFiles] = useState([]);
  const [filesIDS, setFilesIDS] = useState([]);
  const [sending, setSending] = useState(false);

  useEffect(() => {
    getConsultantsByProject(ProjectId).then((x) => {
      setConsultants(x);
    });
    getTmsByProject(ProjectId).then((x) => {
      setTeamMembers(x);
    });
  }, []);
  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      uploadFileRFI(e.target.files[0], ProjectId).then((x) => {
        let tempIDS = filesIDS;
        setFilesIDS(tempIDS.concat(x.FileId));
        let tempFiles = files;
        setFiles(
          tempFiles.concat({
            FileId: x.FileId,
            DisplayName: x.DisplayName,
            Type: e.target.files[0].type,
            Size: e.target.files[0].size,
          })
        );
      });
    }
  };
  const handleFileDelete = (fileIdToDelete) => {
    deleteFileRFI(fileIdToDelete, ProjectId).then((x) => {
      let tempIDS = filesIDS;
      tempIDS = tempIDS.filter(function (item) {
        return item.FileId !== fileIdToDelete;
      });
      setFilesIDS(tempIDS);
      let tempFiles = files;
      tempFiles = tempFiles.filter(function (item) {
        return item.FileId !== fileIdToDelete;
      });
      setFiles(tempFiles);
    });
  };
  const RFIData = {
    Id: null,
    Subject: subject,
    Description: description,
    Files: files,
    AssigneeType: teamMemberIsSelected ? 1 : 2,
    AssigneeId: teamMemberIsSelected ? teamMember : consultant,
    ProjectId: ProjectId,
    DocumentIds: null,
    DocumentNames: null,
    Documents: documentToSend,
  };
  const createTheRFI = () => {
    setSending(true);
    createRFI(RFIData).then((x) => {
      setSending(false);
      navigate("/saas/admin/RFI/List");
    });
  };
  useEffect(() => {
    if (
      !subject ||
      !description ||
      subjectError ||
      descriptionError ||
      (teamMemberIsSelected && !teamMember) ||
      (consultantIsSelected && !consultant) ||
      teamMemberError ||
      consultantError
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [
    subject,
    description,
    teamMember,
    consultant,
    subjectError,
    descriptionError,
    teamMemberError,
    consultantError,
  ]);
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <Grid container paddingLeft={{ md: 15, xs: 4 }} paddingTop={5}>
        <Grid item xs={12} container alignItems="center">
          <Grid
            item
            md={0.5}
            xs={2}
            sx={{
              fontFamily: "Muli",
              fontSize: 18,
              fontWeight: "bold",
              lineHeight: 1.44,
              textAlign: "left",
              color: "#101650",
            }}
          >
            RFI
          </Grid>
          <Grid
            item
            md={10}
            xs={7}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>
          <Grid
            item
            md={1}
            xs={3}
            onMouseOver={(e) => (e.target.style.cursor = "pointer")}
            sx={{
              fontFamily: "Muli",
              fontSize: 14,
              lineHeight: 1.36,
              textAlign: "left",
              color: "#101650",
              paddingLeft: "1%",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <button className={classes["GoBack"]}>Go Back </button>
          </Grid>
        </Grid>
        <Grid item xs={10.8} bgcolor={"#fff"} marginTop={3} paddingBottom={5}>
          <Grid item xs={12} className={classes["Request-for-Information"]}>
            Request for Information
          </Grid>
          <Grid
            item
            xs={12}
            alignItems={"center"}
            justifyContent={"center"}
            container
          >
            <Grid
              item
              xs={9}
              alignSelf="center"
              className={classes["Line-564"]}
              marginTop={0.5}
            ></Grid>
          </Grid>
          <Grid
            item
            container
            direction={"row"}
            xs={12}
            paddingTop={5}
            paddingLeft={3}
          >
            <Grid item xs={12} md={6}>
              <Grid item container direction={"row"} gap={1}>
                <Grid item xs={12}>
                  <Grid item className={classes["field-title"]} xs={12}>
                    *Subject
                  </Grid>
                  <Grid item xs={10.5} marginTop={1}>
                    <TextField
                      fullWidth
                      InputLabelProps={{
                        shrink: false,
                      }}
                      InputProps={{ className: classes.fieldDesign }}
                      size="small"
                      label={""}
                      value={subject}
                      variant="outlined"
                      error={subjectError}
                      helperText={subjectErrorMessage}
                      onBlur={(e) => {
                        if (!e.target.value) {
                          setSubjectError(true);
                          setSubjectErrorMessage("Enter a subject");
                        }
                      }}
                      onChange={(e) => {
                        setSubject(e.target.value);
                        if (e.target.value.length > 150) {
                          setSubjectError(true);
                          setSubjectErrorMessage(
                            "Subject must be less than 150 char"
                          );
                        } else {
                          setSubjectError(false);
                          setSubjectErrorMessage(null);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Grid item className={classes["field-title"]} xs={12}>
                    *Description
                  </Grid>
                  <Grid item xs={10.5} marginTop={1}>
                    <TextField
                      fullWidth
                      InputLabelProps={{
                        shrink: false,
                      }}
                      rows={6}
                      multiline
                      InputProps={{ className: classes.DescriptionfieldDesign }}
                      size="small"
                      label={""}
                      value={description}
                      variant="outlined"
                      error={descriptionError}
                      helperText={descriptionErrorMessage}
                      onBlur={(e) => {
                        if (!e.target.value) {
                          setDescriptionError(true);
                          setDescriptionErrorMessage("Enter a Description");
                        }
                      }}
                      onChange={(e) => {
                        setDescription(e.target.value);
                        if (e.target.value.length > 255) {
                          setDescriptionError(true);
                          setDescriptionErrorMessage(
                            "Code must be less than 255 char"
                          );
                        } else {
                          setDescriptionError(false);
                          setDescriptionErrorMessage(null);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item md={4.5} xs={12}>
                  <Grid item xs={11} marginTop={1}>
                    <BlueTip
                      title={"You can add multiple files"}
                      placement="bottom-start"
                      interactive="true"
                    >
                      <Button
                        fullWidth
                        component="label"
                        className={classes["fieldUploadDesign"]}
                        sx={{ backgroundColor: "#f3f3f3" }}
                      >
                        <Grid container item xs={12}>
                          <Grid item xs={11} className={classes["fileText"]}>
                            Upload File
                          </Grid>
                          <Grid item xs={1} paddingTop={0.5}>
                            <img src={uploadIcon} alt={"Upload"} />
                          </Grid>
                        </Grid>
                        <input
                          type="file"
                          hidden
                          onChange={(e) => {
                            handleFileChange(e);
                          }}
                        />
                      </Button>
                    </BlueTip>
                  </Grid>
                </Grid>
                <Grid item md={7} xs={12}>
                  {files.map((item) => (
                    <Grid
                      paddingTop={1}
                      container
                      direction={"row"}
                      key={item.FileId}
                      value={item}
                    >
                      <Grid item xs={1} paddingLeft={1}>
                        <img src={fileIcon} alt="file" width={15} />
                      </Grid>
                      <Grid
                        item
                        xs={2.5}
                        fontSize={{ xs: 10, md: 14 }}
                        onMouseOver={(e) => {
                          e.target.style.color = "grey";
                          e.target.style.cursor = "pointer";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.color = "black";
                        }}
                        onClick={() => {
                          window.open(item.URL);
                        }}
                      >
                        <span>{item.DisplayName.slice(0, 11)}</span>
                      </Grid>
                      <Grid
                        item
                        paddingLeft={2}
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                        }}
                        onClick={() => {
                          handleFileDelete(item.FileId);
                        }}
                      >
                        <Grid item xs={1}>
                          <CloseIcon fontSize="small" />
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid item container dirextion={"row"} xs={12}>
                <Grid item md={6} xs={12}>
                  <Grid item className={classes["field-title"]} xs={12}>
                    *Assignee
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    direction={"row"}
                    paddingTop={2}
                    gap={1}
                  >
                    <Grid
                      item
                      container
                      xs={5}
                      gap={1}
                      style={{
                        opacity: cantSelectTM ? 0.4 : 1,
                      }}
                    >
                      <Grid
                        item
                        className={
                          cantSelectTM
                            ? classes["notCheckedSub"]
                            : teamMemberIsSelected
                            ? classes["checked"]
                            : classes["notChecked"]
                        }
                        onClick={() => {
                          setTeamMemberIsSelected(true);
                          setConsultantIsSelected(false);
                          setConsultant(null);
                        }}
                      ></Grid>
                      <Grid
                        item
                        className={classes["AssigneOption"]}
                        paddingTop={0.5}
                      >
                        Team Member
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={5}
                      gap={1}
                      style={{
                        opacity:
                          localStorage.getItem("AccountType") === "1" &&
                          !cantSelectCons
                            ? 1
                            : 0.4,
                      }}
                    >
                      <Grid
                        item
                        className={
                          localStorage.getItem("AccountType") === "1" &&
                          !cantSelectCons
                            ? consultantIsSelected
                              ? classes["checked"]
                              : classes["notChecked"]
                            : classes["notCheckedSub"]
                        }
                        onClick={() => {
                          if (localStorage.getItem("AccountType") === "1") {
                            setTeamMemberIsSelected(false);
                            setConsultantIsSelected(true);
                            setTeamMember(null);
                          }
                        }}
                      ></Grid>
                      <Grid
                        item
                        className={classes["AssigneOption"]}
                        paddingTop={0.5}
                      >
                        Consultant
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Grid
                    item
                    className={classes["field-title-disabled"]}
                    xs={12}
                  >
                    Status
                  </Grid>
                  <Grid
                    item
                    className={classes["Rectangle-1464"]}
                    xs={11}
                    marginTop={1}
                    height={"50px"}
                  >
                    {status ? status : "Draft"}
                  </Grid>
                </Grid>
              </Grid>
              {teamMemberIsSelected ? (
                <Grid item xs={12}>
                  <Grid item className={classes["field-title"]} xs={12}>
                    *Team member
                  </Grid>
                  <Grid item xs={11.5} paddingTop={1}>
                    <CustomSelect
                      style={{ color: "#707070" }}
                      value={teamMember}
                      disabled={cantSelectTM}
                      onChange={(option) => {
                        setTeamMember(option);
                        setTeamMemberError(false);
                      }}
                      onBlur={(e) => {
                        if (!teamMember) setTeamMemberError(true);
                      }}
                    >
                      <StyledOption value={0} disabled>
                        Team Member
                      </StyledOption>
                      {teamMembers?.map((s) => (
                        <StyledOption
                          key={s.TeamMemberId}
                          value={s.TeamMemberId}
                        >
                          {s.Name}
                        </StyledOption>
                      ))}
                    </CustomSelect>
                  </Grid>
                  <Grid item className={classes["errorMessage"]}>
                    {teamMemberError ? "Select a Team Member" : <></>}
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Grid item className={classes["field-title"]} xs={12}>
                    *Consultant
                  </Grid>
                  <Grid item xs={11.5} paddingTop={1}>
                    <CustomSelect
                      style={{ color: "#707070" }}
                      value={consultant}
                      onChange={(option) => {
                        setConsultant(option);
                        setConsultantError(false);
                      }}
                      onBlur={(e) => {
                        if (!consultant) setConsultantError(true);
                      }}
                    >
                      <StyledOption value={0} disabled>
                        Consultant
                      </StyledOption>
                      {consultants?.map((s) => (
                        <StyledOption key={s.Id} value={s.Id}>
                          {s.Name}
                        </StyledOption>
                      ))}
                    </CustomSelect>
                  </Grid>
                  <Grid item className={classes["errorMessage"]}>
                    {consultantError ? "Select a Consultant" : <></>}
                  </Grid>
                </Grid>
              )}
              <Grid item md={12} xs={12}>
                <Grid item className={classes["field-title-disabled"]} xs={12}>
                  Document Reference
                </Grid>
                <Grid container item xs={12} gap={2}>
                  {documentsChosen.map((documentChosen) => (
                    <Grid
                      key={documentChosen.Id}
                      item
                      className={classes["Rectangle-1464"]}
                      xs={5.5}
                      marginTop={1}
                      height={"50px"}
                    >
                      {documentChosen.Name}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              gap={3}
              paddingRight={5}
              direction={"row-reverse"}
            >
              <Grid item>
                <button
                  className={classes["SendButton"]}
                  disabled={isDisabled}
                  onClick={() => {
                    createTheRFI();
                  }}
                >
                  {sending ? "Sending..." : "Send"}
                </button>
              </Grid>
              <Grid item>
                <button className={classes["cancelButton"]}>Cancel</button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
const BlueTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
      color:#0674b9;
      background-color: rgba(6, 116, 185, 0.3);
      font-size: 13px;
      width:180px;
   
  `);
const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
      font-family: Muli;
      font-size: 17px;
      box-sizing: border-box;
      width: 100%;
      background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border: 1px solid #f3f3f3;
      border-radius: 5px;
      text-align: left;
      color: #bcbcbc;
      padding:13px;
      &:hover {
        background: ${theme.palette.mode === "dark" ? "" : grey[100]};
        border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
      }
    
      &.${selectUnstyledClasses.focusVisible} {
        outline: 3px solid ${
          theme.palette.mode === "dark" ? blue[600] : blue[100]
        };
      }
    
      &.${selectUnstyledClasses.expanded} {
        &::after {
          content: '▴';
        }
      }
    
      &::after {
        content: '▾';
        float: right;
      }
      `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
      font-family: Muli;
      font-size: 0.875rem;
      box-sizing: border-box;
      padding: 5px;
      margin: 10px 0;
      width:38vw;
      max-height:250px !important;
      background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[800] : grey[300]
      };
      border-radius: 0.75em;
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      overflow: auto;
      outline: 0px;
      `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
      list-style: none;
      padding: 8px;
      border-radius: 0.45em;
      cursor: default;
    
      &:last-of-type {
        border-bottom: none;
      }
    
      &.${optionUnstyledClasses.selected} {
        background-color: ${
          theme.palette.mode === "dark" ? blue[900] : blue[100]
        };
        color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
      }
    
      &.${optionUnstyledClasses.highlighted} {
        background-color: ${
          theme.palette.mode === "dark" ? grey[800] : grey[100]
        };
        color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      }
    
      &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
        background-color: ${
          theme.palette.mode === "dark" ? blue[900] : blue[100]
        };
        color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
      }
    
      &.${optionUnstyledClasses.disabled} {
        color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
      }
    
      &:hover:not(.${optionUnstyledClasses.disabled}) {
        background-color: ${
          theme.palette.mode === "dark" ? grey[800] : grey[100]
        };
        color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      }
      `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});
export default AddRFI;
