import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import classes from "./TeamMember.module.css";
import { MenuItem } from "@mui/material";

import "react-phone-input-2/lib/bootstrap.css";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#707070",
      height: "40px",
    },
  },
});

function Form2(props) {
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const [addressError, setAddressError] = useState(false);
  const [addressInvalid, setAddressInvalid] = useState(false);

  const [countryError, setCountryError] = useState(false);

  const [agreeCheckError, SetAgreeCheckError] = useState(false);

  const [isDisabled, setIsDisabled] = useState(true);
  const [isoCodes, setisoCodes] = useState([]);
  const [fulled, setFulled] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  useEffect(() => {
    for (var i = 0; i < props.countries.length; i++) {
      isoCodes[i] = props.countries[i].IsoCode;
    }
    if (isoCodes.length > 0) {
      setFulled(true);
    }
  });
  useEffect(() => {
    if (
      !props.country ||
      !props.address ||
      !props.personalPhoneNumber ||
      !props.agreeCheck ||
      phoneNumberError ||
      addressError ||
      addressInvalid ||
      countryError
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });

  return (
    <Box sx={{ flexGrow: 1 }} className={classes["formDesign"]}>
      <Grid item container md={12} spacing={2}>
        <Grid item container md={12} xs={12}>
          <Grid item md={9} xs={11}>
            <div className={classes["form-title"]}>
              Primary Contact Information
            </div>
          </Grid>

          <Grid item md={3} xs={1} container direction={"row-reverse"}>
            <IconButton
              onClick={(e) => {
                navigate("/");
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item container md={12} direction={"column"} spacing={3}>
          <Grid item>
            <CssTextField
              size="small"
              label="Country"
              className={classes["Fields-styles"]}
              value={props.country}
              InputLabelProps={{
                style: {
                  fontFamily: "Muli",
                  fontSize: "16px",
                },
              }}
              select
              error={countryError}
              helperText={countryError ? "Select a country" : ""}
              onChange={(e) => {
                if (e.target.value === "") {
                  setCountryError(true);
                } else {
                  setCountryError(false);
                  props.setCountry(e.target.value);
                }
              }}
            >
              {props.countries.map((option) => (
                <MenuItem value={option.Id} key={option.Id}>
                  {option.Name}
                </MenuItem>
              ))}
            </CssTextField>
          </Grid>

          <Grid item>
            <CssTextField
              label="Address"
              size="small"
              className={classes["Fields-styles"]}
              value={props.address}
              InputLabelProps={{
                style: {
                  fontFamily: "Muli",
                  fontSize: "16px",
                },
              }}
              error={addressError}
              helperText={
                addressError
                  ? addressInvalid
                    ? "Address must be less than 150 characters"
                    : "Enter your address"
                  : ""
              }
              onChange={(e) => {
                props.setAddress(e.target.value);
                if (e.target.value === "") {
                  setAddressError(true);
                  setAddressInvalid(false);
                } else if (
                  (e.target.value !== "") &
                  (e.target.value.length > 150)
                ) {
                  setAddressError(true);
                  setAddressInvalid(true);
                } else {
                  setAddressError(false);
                  setAddressInvalid(false);
                }
              }}
            />
          </Grid>

          <Grid item height={"5%"}>
            {fulled ? (
              <PhoneInput
                placeholder="Enter phone number"
                onlyCountries={isoCodes}
                enableAreaCodes={true}
                value={props.personalPhoneNumber}
                onChange={(phone) => {
                  props.setPersonalPhoneNumber(phone);
                  if (
                    props.personalPhoneNumber === "" ||
                    props.personalPhoneNumber.length < 4 ||
                    props.personalPhoneNumber.length > 25
                  ) {
                    setPhoneNumberError(true);
                  } else {
                    setPhoneNumberError(false);
                  }
                }}
                country={"lb"}
                inputStyle={{
                  borderColor: "#707070",
                  width: "100%",
                  height: 40,
                  fontFamily: "Muli",
                  fontsize: "16px",
                }}
              />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Grid item container md={12} direction={"row"}>
          <Grid item md={1}>
            <input
              className={classes["checksquare"]}
              type="checkbox"
              defaultChecked={props.agreeCheck}
              onChange={(e) => {
                props.setAgreeCheck(e.target.checked);

                SetAgreeCheckError(!e.target.checked);
              }}
            ></input>
          </Grid>

          <Grid item md={11}>
            <span className={classes["agreePolicy"]}>
              <span className={classes["text-style-1"]}>I agree to the </span>
              <a href="/#" style={{ textDecoration: "none", color: "#0b5cab" }}>
                Terms and Conditions.
              </a>
            </span>
          </Grid>
          {agreeCheckError ? (
            <span className={classes["CheckBoxError"]}>
              Please read and agree to the terms and conditions.
            </span>
          ) : (
            ""
          )}
        </Grid>
        <Grid item md={12}>
          <span className={classes["agreeMessage"]}>
            By Submitting, you agree to the processing of your personal data by
            Pilecubes as described in the
            <span className={classes["text-style-1"]}> Privacy Statement</span>
            <span className={classes["text-style-2"]}>.</span>
          </span>
        </Grid>
        <Grid item container md={12} direction={"row"} marginTop={3.5}>
          <Grid item md={8} xs={8}>
            <Button
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#CED2E1";
                e.target.style.cursor = "pointer";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#fff";
              }}
              onClick={(e) => {
                props.back(e);
                e.target.style.backgroundColor = "#fff";
              }}
              variant="contained"
              style={{
                textTransform: "none",
                borderRadius: "5px",
                width: "71px",
                height: "32px",
                fontSize: "19px",
                fontFamily: "Muli",
                backgroundColor: "#fff",
                color: "rgba(16, 22, 80, 0.7)",
                marginBottom: 7,
              }}
            >
              Back
            </Button>
            <button
              className={classes["buttonNextForm"]}
              disabled={isDisabled || submitting}
              style={{ marginLeft: 20, width: 'fit-content' }}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#101650";
                e.target.style.cursor = "pointer";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#0b5cab";
              }}
              onClick={(e) => {
                setSubmitting(true);
                props.register();
                setTimeout(() => {
                  setSubmitting(true);
                }, 2000);
                // register(e);
              }}
              variant="contained"
            >
              {submitting ? "Submitting..." : "Submit"}
            </button>
          </Grid>
          <Grid item md={4} xs={4} container direction={"row-reverse"}>
            <div className="step-1-of-3">step 2 of 2</div>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Form2;

// function register(e) {
//   console.log(info);
//   console.log(
//     registrationEmail,
//     tenantId,
//     inviterTenantId,
//     projectId,
//     subProjectId
//   );
//   axios
//     .post(`${process.env.REACT_APP_URL + MC_REGISTER}`, {
//       TenantId: tenantId,
//       Name: info.Name,
//       RegistrationNumber: info.RegistrationNumber,
//       Address: info.Address,
//       CompanyPhoneNumber: info.CompanyPhoneNumber,
//       AgreementAcceptance: true,
//       CountryId: info.CountryId,
//       SpecialityId: info.SpecialityId,
//       SpecialityOther: info.SpecialityOther,
//       EmployeesRangeId: info.EmployeesRangeId,
//       User: {
//         UserId: info.User.UserId,
//         Password: info.User.Password,
//         Email: tenantId ? null : info.User.Email,
//         UserDetails: {
//           Name: info.User.UserDetails.Name,
//           PersonalEmail: info.User.UserDetails.PersonalEmail,
//           Position: info.User.UserDetails.Position,
//           PhoneNumber: info.User.UserDetails.PhoneNumber,
//           UserId: info.User.UserDetails.UserId,
//         },
//       },
//       InviterTenantId: inviterTenantId,
//       ProjectId: projectId,
//       SubProjectId: subProjectId,
//     })
//     .then((res) => {
//       localStorage.setItem("accessToken", res.data.accessToken);
//       localStorage.setItem("isVerified", "false");
//       localStorage.setItem("companyName", info.Name);
//       localStorage.setItem("AccountType", 1);
//       localStorage.setItem(
//         "isGuest",
//         jwt(localStorage.getItem("accessToken")).IsGuest
//       );
//       setAccountName(info.Name);
//       continues(e);
//     })
//     .catch((err) => {
//       setOpen(true);
//       setErrMsg(err.response.data.error);
//     });
// }
