import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { Rnd } from "react-rnd";
import classes from "../../../../Assets/Styles/ProductionLabel.module.css";
import RemoveIcon from "../../../../Assets/Images/ProductionLabel/remove.png";

const DeliveryNoteTemplate = (props) => {
  const [labelWidth, setLabelWidth] = useState(0);
  const [labelHeight, setLabelHeight] = useState(0);
  const [modify, setModify] = useState(false);

  return (
    <Grid width={890} height={1180} bgcolor={"#fff"} gap={0}>
      <Grid container height={350} width={890} className={classes["tagForm2"]}>
        {props.selectedlabelRawsFields
          ?.filter((x) => x.GroupId === 1 || x.GroupId === 2 || x.GroupId === 5)
          .map((field) => (
            <Rnd
              bounds={"parent"}
              default={{
                x: field.x,
                y: field.y,
                width: field.Width,
                height: field.Height,
              }}
              onDragStop={(e, d) => {
                field.x = d.x;
                field.y = d.y;
              }}
              onResizeStop={(e, l, d, o, j) => {
                field.Width = field.Width + o.width;
                field.Height = field.Height + o.height;
                setModify(!modify);
              }}
              style={{ border: "solid 1px #101650", borderStyle: "dashed" }}
              minWidth={40}
              minHeight={40}
              maxWidth={400}
              maxHeight={400}
            >
              <Grid container>
                <Grid item xs={10.5} className={classes["FieldData"]}>
                  {props.labelRawsDictionary[field.LabelRowId]}
                </Grid>
                <Grid
                  item
                  xs={1.5}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={(e) => {
                    props.setSelectedLabelRawsFields(
                      props.selectedlabelRawsFields.filter(
                        (x) => x.Id !== field.Id
                      )
                    );
                  }}
                >
                  <img src={RemoveIcon} alt={"remove"} />
                </Grid>
                <Grid item xs={12} className={classes["FieldTitle"]}>
                  {field.Data}
                </Grid>
              </Grid>
            </Rnd>
          ))}
      </Grid>
      <Grid container height={500} width={890} className={classes["tagForm"]}>
        {props.selectedlabelRawsFields
          ?.filter((x) => x.GroupId === 3)
          .map((field) => (
            <Rnd
              bounds={"parent"}
              default={{
                x: field.x,
                y: field.y,
                width: field.Width,
                height: field.Height,
              }}
              onDragStop={(e, d) => {
                field.x = d.x;
                field.y = d.y;
              }}
              onResizeStop={(e, l, d, o, j) => {
                field.Width = field.Width + o.width;
                field.Height = field.Height + o.height;
                setModify(!modify);
              }}
              style={{ border: "solid 1px #101650", borderStyle: "dashed" }}
              minWidth={40}
              minHeight={40}
              maxWidth={400}
              maxHeight={400}
            >
              <Grid container>
                <Grid item xs={10.5} className={classes["FieldData"]}>
                  {props.labelRawsDictionary[field.LabelRowId]}
                </Grid>
                <Grid
                  item
                  xs={1.5}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={(e) => {
                    props.setSelectedLabelRawsFields(
                      props.selectedlabelRawsFields.filter(
                        (x) => x.Id !== field.Id
                      )
                    );
                  }}
                >
                  <img src={RemoveIcon} alt={"remove"} />
                </Grid>
                <Grid item xs={12} className={classes["FieldTitle"]}>
                  {field.Data}
                </Grid>
              </Grid>
            </Rnd>
          ))}
      </Grid>
      <Grid container height={50} width={890} className={classes["tagForm2"]}>
        {props.selectedlabelRawsFields
          ?.filter((x) => x.GroupId === 6)
          .map((field) => (
            <Rnd
              bounds={"parent"}
              default={{
                x: field.x,
                y: field.y,
                width: field.Width,
                height: field.Height,
              }}
              onDragStop={(e, d) => {
                field.x = d.x;
                field.y = d.y;
              }}
              onResizeStop={(e, l, d, o, j) => {
                field.Width = field.Width + o.width;
                field.Height = field.Height + o.height;
                setModify(!modify);
              }}
              style={{ border: "solid 1px #101650", borderStyle: "dashed" }}
              minWidth={40}
              minHeight={40}
              maxWidth={400}
              maxHeight={400}
            >
              <Grid container>
                <Grid item xs={10.5} className={classes["FieldData"]}>
                  {props.labelRawsDictionary[field.LabelRowId]}
                </Grid>
                <Grid
                  item
                  xs={1.5}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={(e) => {
                    props.setSelectedLabelRawsFields(
                      props.selectedlabelRawsFields.filter(
                        (x) => x.Id !== field.Id
                      )
                    );
                  }}
                >
                  <img src={RemoveIcon} alt={"remove"} />
                </Grid>
                <Grid item xs={12} className={classes["FieldTitle"]}>
                  {field.Data}
                </Grid>
              </Grid>
            </Rnd>
          ))}
      </Grid>
      <Grid container height={270} width={890} className={classes["tagForm2"]}>
        {props.selectedlabelRawsFields
          ?.filter((x) => x.GroupId === 4)
          .map((field) => (
            <Rnd
              bounds={"parent"}
              default={{
                x: field.x,
                y: field.y,
                width: field.Width,
                height: field.Height,
              }}
              onDragStop={(e, d) => {
                field.x = d.x;
                field.y = d.y;
              }}
              onResizeStop={(e, l, d, o, j) => {
                field.Width = field.Width + o.width;
                field.Height = field.Height + o.height;
                setModify(!modify);
              }}
              style={{ border: "solid 1px #101650", borderStyle: "dashed" }}
              minWidth={40}
              minHeight={40}
              maxWidth={400}
              maxHeight={400}
            >
              <Grid container>
                <Grid item xs={10.5} className={classes["FieldData"]}>
                  {props.labelRawsDictionary[field.LabelRowId]}
                </Grid>
                <Grid
                  item
                  xs={1.5}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={(e) => {
                    props.setSelectedLabelRawsFields(
                      props.selectedlabelRawsFields.filter(
                        (x) => x.Id !== field.Id
                      )
                    );
                  }}
                >
                  <img src={RemoveIcon} alt={"remove"} />
                </Grid>
                <Grid item xs={12} className={classes["FieldTitle"]}>
                  {field.Data}
                </Grid>
              </Grid>
            </Rnd>
          ))}
      </Grid>
    </Grid>
  );
};

export default DeliveryNoteTemplate;
