import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import emailIcon from "../../Assets/Images/main/email.png";
import { Grid, TextField } from "@mui/material";
import axios from "axios";
import * as constFile from "../../pileCubesConst";
import Animation from "./Animation";
import SentEmail from "./EmailSent";
import CloseIcon from "@mui/icons-material/Close";
import "../../Assets/Styles/Navbar.css";
const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 430,
};
const emailApi = constFile.EMAIL_DEMO;
export default function DemoModal({ setNaved }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    setNaved(false);
  };
  const handleClose = () => {
    setOpen(false);
    setSent(false);
    setIsDisabled(true);
    if (window.location.pathname.toString() === "/") setNaved(true);
    else setNaved(false);
  };
  const [email, setEmail] = useState("");
  const [emailInValid, setEmailInValid] = useState(false);
  const [disabled, setIsDisabled] = useState(true);

  const [sent, setSent] = useState(false);

  const sendDemo = () => {
    axios
      .post(`${process.env.REACT_APP_URL}/email/request-demo`, {
        FromAddress: email,
      })
      .then((res) => {
        setSent(true);
        setAnime(true);
        setTimeout(() => {
          setAnime(false);
        }, 3000);
      })
      .catch((err) => {
        return err;
      });
  };
  const [anime, setAnime] = useState(false);

  return (
    <div>
      <Button
        variant="contained"
        style={{
          backgroundColor: "#E1E319",
          fontWeight: "bold",
          textTransform: "none",
        }}
        onMouseOver={(e) => {
          e.target.style.backgroundColor = "#D8DA18";
          e.target.style.cursor = "pointer";
        }}
        onMouseOut={(e) => {
          e.target.style.backgroundColor = "#E1E319";
        }}
        onClick={handleOpen}
      >
        Book a Demo
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <CloseIcon
            onClick={handleClose}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            style={{ color: "#e1e319", marginLeft: "95%", marginBottom: "1%" }}
          />
          <Box
            style={{
              backgroundColor: "white",
              borderTop: "solid 5px #e1e319",
              boxShadow: 24,
              padding: "10% 3% 10% 3%",
            }}
          >
            {sent ? (
              <Grid container>
                {anime ? (
                  <Animation />
                ) : (
                  <SentEmail handleClose={handleClose} />
                )}
              </Grid>
            ) : (
              <Grid container direction={"column"} spacing={6}>
                <Grid item xs={12} alignSelf={"center"}>
                  <img src={emailIcon} alt="email" width={"90%"} />
                </Grid>
                <Grid item container direction={"column"}>
                  <span
                    style={{
                      fontFamily: "Muli",
                      color: "#080707",
                      fontSize: 13,
                      lineHeight: 1.46,
                      textAlign: "left",
                    }}
                  >
                    {" "}
                    Enter email address*
                  </span>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    error={emailInValid}
                    helperText={
                      emailInValid ? "Please enter a valid email address " : ""
                    }
                    onChange={(e) => {
                      setEmail(e.target.value);
                      if (
                        e.target.value === "" ||
                        !e.target.value.match(
                          /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/
                        )
                      ) {
                        setEmailInValid(true);
                        setIsDisabled(true);
                      } else {
                        setIsDisabled(false);
                        setEmailInValid(false);
                      }
                    }}
                  />
                </Grid>
                <Grid item alignSelf={"center"}>
                  <button
                    className="submitButton1"
                    disabled={disabled}
                    onMouseOver={(e) => {
                      e.target.style.backgroundColor = "#D8DA18";
                      e.target.style.cursor = "pointer";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#e1e319";
                    }}
                    onClick={(e) => {
                      sendDemo();
                      e.target.style.backgroundColor = "#e1e319";
                    }}
                  >
                    Start booking
                  </button>
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
