import React, { useState, useEffect, useMemo } from "react";
import { Backdrop, CircularProgress, Grid } from "@mui/material";
import SaasBack from "../../../../Assets/Images/ProfileDetails/SaaSbackground.png";
import classes from "./TeamMember.module.css";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useLocation } from "react-router-dom";
import TeamMemberDetails from "./TeamMemberDetails";
import PropTypes from "prop-types";
import { Tooltip } from "@mui/material";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { styled } from "@mui/system";
import deletImg from "../../../../Assets/Images/Projects/delete.png";
import TeamMemberList from "./TeamMemberList";
import TeamMemberModal from "./TeamMemberModal";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import * as tMservices from "../../../../Services/team-member-services.proxy";

const teamMemberBySubProject = tMservices.teamMemberBySubProject;
const teamMemberPermissionList = tMservices.teamMemberPermissionList;
const assignTeamMember = tMservices.assignTeamMember;
const updateAssignTeamMember = tMservices.updateAssignTeamMember;
const getDisciplinesOfSub = tMservices.getDisciplinesOfSub;

const TeamMember = (props) => {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  const [UserRole, setUserRole] = useState("");
  useEffect(() => {
    if (UserPermissions) {
      if (UserPermissions.find((x) => x.Name === "Admin")) {
        setUserRole("Admin");
      } else if (UserPermissions.find((x) => x.Name === "Project Manager")) {
        setUserRole("Project Manager");
      } else if (
        UserPermissions.find((x) => x.Name === "Engineering Manager")
      ) {
        setUserRole("Engineering Manage");
      } else if (UserPermissions.find((x) => x.Name === "Team Leader")) {
        setUserRole("Team Leader");
      } else if (UserPermissions.find((x) => x.Name === "Engineer")) {
        setUserRole("Engineer");
      } else if (
        UserPermissions.find((x) => x.Name === "Document Controller")
      ) {
        setUserRole("Document Controller");
      }
    }
  }, [UserPermissions]);
  const [searchText, setSearchText] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [teamMemberRoles, setTeamMemberRoles] = useState([]);
  const [viewDetails, setViewDetails] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [disciplines, setDisciplines] = useState([]);
  const [discipline, setDiscipline] = useState(null);
  const [assignmentData, setAssignmentData] = useState({});
  const [savingData, setSavingData] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const SubID = query.get("subId");
  useEffect(() => {
    let apiTest = true;
    if (SubID) {
      setLoadingData(true);
      teamMemberBySubProject(SubID, discipline).then((x) => {
        if (apiTest) {
          setAssignmentData(x);
          setTeamMembers(x.TeamMembers);
          setProjectName(x.Project);
          setPhaseName(x.Phase);
          setSubProjectName(x.SubProject);
          setLoadingData(false);

        }
      });
    }
    return () => {
      apiTest = false;
    };
  }, [SubID, discipline]);

  useEffect(() => {
    setLoadingData(true);
    teamMemberPermissionList(searchText).then((x) => {
      setLoadingData(false)
      setTeamMemberRoles(x?.filter((r) => r.Name !== "Operator" && r.Name !== "Loading Supervisor" && r.Name !== "Storekeeper" && r.Name !== "Factory/Production Manager" && r.Name !== "Project Coordinator" && r.Name !== "Production Coordinator"));
    });
  }, [searchText]);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const navigate = useNavigate();
  const [projectName, setProjectName] = useState("");

  const [phaseName, setPhaseName] = useState("");
  const [subProjectName, setSubProjectName] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [teamMemberId, setTeamMemberId] = useState(null);
  const [msg, setMsg] = useState("HIiii");
  const [msgColor, setMsgColor] = useState("#fff");
  const [chooseDiscError, setChooseDiscError] = useState(false);

  useEffect(() => {
    if (SubID) {
      getDisciplinesOfSub(SubID).then((x) => {
        setDisciplines(x);
      });
    }
  }, [SubID]);
  useEffect(() => {
    if (!discipline) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [discipline]);
  const save = () => {
    setSavingData(true);
    setLoadingData(true);

    assignTeamMember(SubID, discipline, teamMembers).then((x) => {
      setMsgColor("#101650");
      setMsg("Team Member(s) Assigned");
      setSavingData(false);
      setLoadingData(false);


      setTimeout(() => {
        setMsgColor("#fff");
        setMsg("");
      }, 2000);
    });
  };
  const update = () => {
    setSavingData(true);
    setLoadingData(true);
    updateAssignTeamMember(SubID, discipline, teamMembers).then((x) => {
      if (x.status === 404 || x.status === 409 || x.status === 500) {
        setMsgColor("#f00");
        setMsg(x.error);
      } else {
        setMsgColor("#101650");
        setMsg("Team Member(s) Updated");
        setTimeout(() => {
          setMsgColor("#fff");
          setMsg("");
        }, 2000);
      }
      setSavingData(false);
      setLoadingData(false);

    });
  };
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "transparent",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
          flexDirection: "column",
        }}
        open={loadingData}
      >
        <CircularProgress />

        <Grid
          item
          fontFamily={"Muli"}
          fontSize={15}
          fontWeight={"bold"}
          color={"rgb(25, 118, 210)"}
        >
          Loading ...
        </Grid>
      </Backdrop>
      <Grid container paddingTop="2%" marginLeft="7%" paddingBottom={25}>
        <Grid item xs={12} container alignItems="center" paddingBottom="3%">
          <Grid
            item
            xs={1.2}
            sx={{
              fontFamily: "Muli",
              fontSize: 18,
              fontWeight: "bold",
              lineHeight: 1.44,
              textAlign: "left",
              color: "#101650",
            }}
          >
            Team Members
          </Grid>
          <Grid
            item
            xs={8.4}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>
          <Grid item xs={1} paddingLeft="1.5%">
            <button
              className={classes["back-button"]}
              onClick={() => {
                navigate(-1);
              }}
            >
              Go back
            </button>
          </Grid>
        </Grid>
        <Grid item xs={10} container>
          <Grid
            item
            sx={{
              fontFamily: "Muli",
              fontSize: 21,
              lineHeight: 1.48,
              textAlign: "left",
              color: "#101650",
              backgroundColor: "#fff",
              padding: { sm: "4%", lg: "2%" },
            }}
            style={{
              borderLeft:
                localStorage.getItem("AccountType") === "1"
                  ? "7px solid #e1e319"
                  : localStorage.getItem("AccountType") === "2"
                    ? "7px solid #2da4d0"
                    : "7px solid lightgrey",
            }}
          >
            Team Members
          </Grid>
        </Grid>
        <Grid item xs={11} container>
          <Grid
            item
            xl={0.05}
            lg={0.06}
            md={0.09}
            sm={0.1}
            sx={{
              backgroundColor:
                localStorage.getItem("AccountType") === "1"
                  ? "#e1e319"
                  : localStorage.getItem("AccountType") === "2"
                    ? "#2da4d0"
                    : "lightgrey",
            }}
          ></Grid>
          {viewDetails ? (
            <Grid
              item
              xs={11}
              bgcolor={"white"}
              container
              direction={"row"}
              paddingTop={3}
              paddingLeft={3}
              paddingBottom={10}
            >
              <TeamMemberDetails
                teamMemberId={teamMemberId}
                setViewDetails={setViewDetails}
              />
            </Grid>
          ) : (
            <Grid
              item
              xs={11}
              bgcolor={"white"}
              container
              direction={"row"}
              paddingTop={3}
              paddingLeft={3}
              paddingBottom={10}
            >
              <Grid item md={8} xs={12}>
                <Grid
                  item
                  container
                  direction={"row"}
                  className={classes["Project-Name"]}
                  gap={7}
                >
                  <Grid item>{projectName}</Grid>
                  <Grid item>
                    <Grid item>{phaseName}</Grid>
                  </Grid>
                  <Grid item>
                    <Grid item>{subProjectName}</Grid>
                  </Grid>
                  <Grid item>
                    <CustomSelect
                      defaultValue={0}
                      onChange={(option) => {
                        setDiscipline(option);
                        setChooseDiscError(false)
                      }}
                    >
                      <StyledOption value={0} disabled>
                        *Disciplines
                      </StyledOption>
                      {disciplines?.map((c) => (
                        <StyledOption key={c.Id} value={c.Id}>
                          {c.Name}
                        </StyledOption>
                      ))}
                    </CustomSelect>
                  </Grid>
                  {chooseDiscError ?
                    <Grid item style={{ fontFamily: "Muli", color: "red", fontSize: "15px" }}>
                      Please select the discipline first
                    </Grid> :
                    <></>}
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={classes["Project-Name"]}
                  paddingTop={3}
                >
                  Assign Team Members
                </Grid>
                <Grid item xs={12} paddingTop={2}>
                  <button
                    className={classes["addButton"]}
                    disabled={
                      UserRole === "Document Controller" ||
                        UserRole === "Engineer"
                        ? true
                        : false
                    }
                    onClick={() => {
                      setOpenModal(true);
                    }}
                  >
                    Add New Team Member +
                  </button>
                </Grid>
                <Grid
                  item
                  container
                  lg={12}
                  md={12}
                  xs={12}
                  direction={"row"}
                  className={classes["TableHeaders"]}
                  gap={{ md: "20%", xs: "10%" }}
                  paddingLeft={{ md: "9.5%", xs: 0 }}
                  paddingTop={2}
                >
                  <Grid item>Team Member Name</Grid>
                  <Grid item>Role</Grid>
                  <Grid item>Company Name</Grid>
                </Grid>
                {teamMembers?.map((member, index) => (
                  <Grid
                    item
                    key={member.Id}
                    container
                    lg={12}
                    md={12}
                    xs={12}
                    direction={"row"}
                    paddingTop={1.5}
                  >
                    <Tooltip
                      title={"Remove Team Member"}
                      placement="top-start"
                      interactive
                    >
                      <Grid
                        height={25}
                        item
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                        }}
                        onClick={() => {
                          if (discipline) {
                            setTeamMembers(
                              teamMembers.filter((x) => x.Id !== member.Id)
                            );
                            forceUpdate();
                          }
                          else {
                            window.scrollTo(0, 0);
                            setChooseDiscError(true);

                          }
                        }}
                      >
                        <img src={deletImg} alt={"remove"}></img>
                      </Grid>
                    </Tooltip>
                    <Tooltip title={"View Team Member"} placement="top-start">
                      <Grid
                        height={25}
                        item
                        paddingLeft={"2%"}
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                        }}
                        onClick={() => {
                          setTeamMemberId(member.Id);
                          setViewDetails(true);
                        }}
                      >
                        <RemoveRedEyeIcon
                          fontSize="small"
                          htmlColor="rgba(6, 116, 185, 0.5)"
                        />
                      </Grid>
                    </Tooltip>
                    <Grid item paddingLeft={"3.5%"}>
                      <Grid item className={classes["Data-Field"]}>
                        {member.Name}
                      </Grid>
                      <Grid item md={12} paddingTop={1.5}>
                        <div className={classes["Field-Line"]}></div>
                      </Grid>
                    </Grid>
                    <Grid item paddingLeft={"4.5%"}>
                      <Grid item className={classes["Data-Field"]}>
                        <span>{member.UserGroupPermissions}</span>
                      </Grid>
                      <Grid item md={12} paddingTop={1.5}>
                        <div className={classes["Field-Line2"]}></div>
                      </Grid>
                    </Grid>
                    <Grid item paddingLeft={"4%"}>
                      <Grid item className={classes["Data-Field"]}>
                        {member.Company}
                      </Grid>
                      <Grid item md={12} paddingTop={1.5}>
                        <div className={classes["Field-Line"]}></div>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
                paddingLeft={{ md: "7%", xs: "0" }}
                paddingTop={{ xs: 4, md: 0 }}
              >
                <TeamMemberList
                  searchText={searchText}
                  setSearchText={setSearchText}
                  teamMemberRoles={teamMemberRoles}
                  setTeamMemberRoles={setTeamMemberRoles}
                  setTeamMembers={setTeamMembers}
                  teamMembers={teamMembers}
                  forceUpdate={forceUpdate}
                  UserRole={UserRole}
                  discipline={discipline}
                  chooseDiscError={chooseDiscError}
                  setChooseDiscError={setChooseDiscError}
                />
              </Grid>
              <TeamMemberModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                teamMemberId={teamMemberId}
                setTeamMemberId={setTeamMemberId}
                setTeamMembers={setTeamMembers}
                teamMembers={teamMembers}
                forceUpdate={forceUpdate}
                projectName={projectName}
                phaseName={phaseName}
                subProjectName={subProjectName}
                UserRole={UserRole}
              />
              <Grid
                item
                container
                xs={12}
                direction={"row-reverse"}
                paddingTop={3}
                paddingRight={3}
              >
                <button
                  disabled={isDisabled}
                  className={classes["addButton"]}
                  style={{ padding: "5px 15px 5px 15px" }}
                  onClick={() => {
                    if (teamMembers.length === 0 || teamMembers.length > 0) {
                      update();
                    } else {
                      save();
                    }
                  }}
                >
                  {savingData ? "Saving..." : "Save"}
                </button>
                <span
                  style={{
                    paddingRight: "3%",
                    paddingTop: "1%",
                    fontFamily: "Muli",
                    fontSize: 16,
                    fontWeight: "bold",
                    lineHeight: 1.5,
                    textAlign: "left",
                    color: msgColor,
                  }}
                >
                  {msg}
                </span>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
const BlueTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
      color: #101650;
      background-color: rgba(6, 116, 185, 0.3);
      font-size: 13px;
   
  `);
const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: Muli;
  font-size: 15px;
  height:23px;
  width: 100%;
  font-weight:bold;
 
  background-color: #fff;
  border-radius: 5px;
  border:0;
  color: rgba(16, 22, 80, 0.5);

  &:hover {
    background: ${theme.palette.mode === "dark" ? "" : grey[100]};
    border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
    padding-left:20px;
  }
  `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
  font-family: Muli;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  max-height:250px !important;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  overflow: auto;
  outline: 0px;
  `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
 
  padding: 8px;
  border-radius: 0.45em;
  cursor: default;
  font-family:Muli;
  font-size:15px;
  &:last-of-type {
    border-bottom: none;  
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }
  `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});

CustomSelect.propTypes = {
  /**
   * The components used for each slot inside the Select.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  components: PropTypes.shape({
    Listbox: PropTypes.elementType,
    Popper: PropTypes.func,
    Root: PropTypes.elementType,
  }),
};
export default TeamMember;
