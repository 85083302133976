import React, { useState, useEffect, useMemo } from "react";
import SaasBack from "../../../Assets/Images/Projects/bgTableProject.png";
import { Grid } from "@mui/material";
import classing from "../../../Assets/Styles/factory.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import FactoryControlSystem from "./FactoryControlSystem";
import DesignBoard from "./DesignBoard";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import * as factoryServices from "../../../Services/factories-services.proxy";
import { useSelector } from "react-redux";
import * as permissionFunctions from "../../../../ReusableFunctions";

const CheckIfPermitted = permissionFunctions.CheckIfPermitted;
const getFactoryLayoutData = factoryServices.getFactoryLayoutData;
const createFactoryLayout = factoryServices.createFactoryLayout;
const updateFactoryLayout = factoryServices.updateFactoryLayout;

const FactoryModel = (props) => {
  useEffect(() => {
    props.setRegister(true);
  }, [props]);

  useEffect(() => {
    props.setNaved(false);
  }, [props]);
  const navigate = useNavigate();
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  const [disabledEdit, setDisabledEdit] = useState(false);
  const [disabledCreate, setDisabledCreate] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [modify, setModify] = useState(false);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const query = useQuery();
  const FACTORY_ID = query?.get("id");
  const [factoryData, setFactoryData] = useState({});
  const [modelData, setModelData] = useState([]);
  const [modelDataTemp, setModelDataTemp] = useState([]);

  const [baySelected, setBaySelected] = useState();
  useEffect(() => {
    setLoadingData(true);
    if (FACTORY_ID) {
      getFactoryLayoutData(FACTORY_ID).then((x) => {
        setFactoryData(x.factoryData);
        if (x.factoryLayout?.Bays && x.factoryLayout?.Bays?.length > 0) {
          setModelData(x.factoryLayout.Bays);
        }
        setLoadingData(false);
      });
    }
  }, [modify]);
  const LayoutData = {
    Id: FACTORY_ID,
    Bays: modelData,
  };
  const saveLayout = () => {
    // if (modelDataTemp.length > 0) {
    updateFactoryLayout(LayoutData).then((x) => {
      setModify(!modify);
    });
    // } else {
    // createFactoryLayout(LayoutData).then((x) => {
    // navigate("/saas/factory");
    // });
    // }
  };
  useEffect(() => {
    if (UserPermissions && modelData.factoryLayout?.Bays?.length > 0) {
      CheckIfPermitted(
        UserPermissions,
        "Factory Model",
        "Edit",
        setDisabledEdit
      );
    } else {
      CheckIfPermitted(
        UserPermissions,
        "Factory Model",
        "Create",
        setDisabledCreate
      );
    }
  }, [UserPermissions, modelData]);
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <Grid
        item
        container
        paddingLeft={{ xs: "0%", sm: "1%", md: "5%" }}
        alignItems="center"
        justifyContent="center"
      >
        <Backdrop
          sx={{
            color: "#fff",
            backgroundColor: "transparent",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            display: "flex",
            flexDirection: "column",
          }}
          open={loadingData}
        >
          <CircularProgress />

          <Grid
            item
            fontFamily={"Muli"}
            fontSize={15}
            fontWeight={"bold"}
            color={"rgb(25, 118, 210)"}
          >
            Loading Data...
          </Grid>
        </Backdrop>
        <Grid
          item
          container
          direction="row"
          gap={1}
          paddingTop={{ xs: "10%", sm: "7%", md: "2%" }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            container
            direction="column"
            lg={1.5}
            sm={2.5}
            xs={2.5}
            paddingTop={{ xs: "4%", sm: "2%", lg: "1%" }}
          >
            <Grid
              item
              fontSize={{ xs: "11px", sm: "18px" }}
              fontWeight="bold"
              className={classing["Title"]}
            >
              Factory View
            </Grid>
          </Grid>

          <Grid
            item
            lg={7.7}
            sm={5.5}
            xs={3.5}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>
          <Grid
            item
            container
            lg={0.9}
            sm={1.8}
            xs={2.5}
            className={classing["ViewText"]}
            fontSize={{ xs: "9px", sm: "12px" }}
          >
            <Grid item xs={12}></Grid>
          </Grid>

          <Grid item lg={0.9} sm={1}>
            <button
              className={classing["back-button"]}
              onClick={() => {
                navigate(-1);
              }}
            >
              Go Back
            </button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container direction={"row"} xs={12}>
        <Grid
          item
          container
          width={"175px"}
          height={"630px"}
          marginLeft={{ md: "7%", sm: "3%" }}
          className={classing["Rectangle-3008"]}
          marginTop={3}
        >
          <FactoryControlSystem
            factoryData={factoryData}
            setBaySelected={setBaySelected}
            baySelected={baySelected}
            modelData={modelData}
            setModelData={setModelData}
            forceUpdate={forceUpdate}
            saveLayout={saveLayout}
            disabledEdit={disabledEdit}
            disabledCreate={disabledCreate}
          />
        </Grid>
        <Grid item marginLeft={6} marginTop={3}>
          <DesignBoard
            modelData={modelData}
            setModelData={setModelData}
            disabledEdit={disabledEdit}
            disabledCreate={disabledCreate}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default FactoryModel;
