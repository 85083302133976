import React, { useState, useEffect } from "react";
import { Backdrop, CircularProgress, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import InventoryCard from "./InventoryCard";
import SaasBack from "../../../Assets/Images/Projects/bgTableProject.png";
import classes from "../../../Assets/Styles/InventorySetup.module.css";
import * as inventoryServices from "../../../Services/inventory-setup-services.proxy";
import { useSelector } from "react-redux";
import * as permissionFunctions from "../../../../ReusableFunctions";

const CheckIfPermitted = permissionFunctions.CheckIfPermitted;

const getInventoryListCard = inventoryServices.getInventoryListCard;
const InventoryMainView = (props) => {
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [disableTH, setDisableTH] = useState(false);
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    if (UserPermissions) {
      CheckIfPermitted(
        UserPermissions,
        "Pilecubes Request Team Help",
        "View",
        setDisableTH
      );
    }
  }, [UserPermissions]);

  const data0 = {
    Name: "Factory Name",
    Code: "code",
    BaysNumber: 0,
    BlocksNumber: 0,
  };
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  const [factories, setFactories] = useState([]);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    getInventoryListCard().then((x) => {
      setFactories(x);
      setLoadingData(false);
    });
  }, []);
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: props.adminPage ? null : `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "transparent",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
          flexDirection: "column",
        }}
        open={loadingData}
      >
        <CircularProgress />

        <Grid
          item
          fontFamily={"Muli"}
          fontSize={15}
          fontWeight={"bold"}
          color={"rgb(25, 118, 210)"}
        >
          Loading ...
        </Grid>
      </Backdrop>

      <Grid item xs={12} container rowSpacing={3} padding="2.5% 0 0 8%">
        {props.adminPage !== true ? (
          <Grid item xs={12} container alignItems="center">
            <Grid
              item
              xs={2.5}
              sm={2}
              lg={1.4}
              sx={{
                fontFamily: "Muli",
                fontSize: 18,
                fontWeight: "bold",
                lineHeight: 1.44,
                textAlign: "left",
                color: "#101650",
              }}
            >
              Inventory Setup
            </Grid>
            <Grid
              item
              xs={5}
              sm={8.2}
              lg={8.8}
              sx={{
                border: "solid 0.1px #fff",
                backgroundColor: "#fff",
                height: 0,
              }}
            ></Grid>

            <Grid item paddingLeft={1}>
              <button
                className={classes["Go-Back"]}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Go Back
              </button>
            </Grid>



          </Grid>
        ) : (
          <></>
        )}

        <Grid item xs={12} container columnSpacing={2} rowSpacing={2}>
          {factories?.length > 0 ? (
            <>
              {factories.map((d, index) => (
                <Grid
                  item
                  xs={6}
                  sm={2.7}
                  md={1.9}
                  lg={1.5}
                  xl={props.adminPage ? 2.5 : 1.2}
                  key={d.Id}
                >
                  <InventoryCard data={d} index={index} />
                </Grid>
              ))}
            </>
          ) : (
            <Grid item xs={10} sm={4.5} md={3.5} lg={2.5} xl={2.2}>
              <InventoryCard data={data0} index={-1} />
            </Grid>
          )}
        </Grid>
        {props.adminPage === true ? (
          <Grid
            item
            container
            justifyContent={"center"}
            alignItems={"center"}
            xs={12}
            sx={{
              fontFamily: "Muli",
              fontSize: 17,
              lineHeight: 1.5,
              textAlign: "center",
              color: "#101650",
            }}
          >
            Manage The Inventory Setup
            <Grid item xs={12} fontSize={14}>
              add an inventory to a specific factory
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </div>
  );
};

export default InventoryMainView;
