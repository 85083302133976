import * as constFile from "../../projectConst";
import axios from "axios";
const FormData = require("form-data");

const SUBPROJECTS = constFile.SUBPROJECTS;

export const getSubProjectById = (subProjectId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + SUBPROJECTS}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        subProjectId: subProjectId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const createSubProject = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + SUBPROJECTS}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const SUBPRO_INPUT_DATA = constFile.SUBPRO_INPUT_DATA;
export const getSubProjectInputData = (subProjectId, projectId, parentId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + SUBPRO_INPUT_DATA}`,
      {
        ProjectId: projectId,
        SubProjectId: subProjectId,
        ParentSubProjectId: parentId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const SUBPRO_LIST = constFile.SUBPRO_LIST;
export const getSubProjects = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + SUBPRO_LIST}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const SUBPRO_STATUS = constFile.SUBPRO_STATUS;
export const changeStatus = (subprojectId, statusId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + SUBPRO_STATUS}`,
      {
        SubProjectId: subprojectId,
        StatusId: statusId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

const SUBPRO_UPDATE = constFile.SUBPRO_UPDATE;
export const updateSubProject = (data) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + SUBPRO_UPDATE}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//Upload profile
const SUBPRO_UPLOAD_FILE = constFile.SUBPRO_UPLOAD_FILE;
export const uploadProfile = (file, phaseId) => {
  const token = localStorage.getItem("accessToken");
  const data = new FormData();
  data.append("File", file);
  data.append("ProjectId", "");
  data.append("PhaseId", phaseId);
  return axios
    .post(`${process.env.REACT_APP_URL + SUBPRO_UPLOAD_FILE}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//Delete File
const SUBPRO_DELETE_FILE = constFile.SUBPRO_DELETE_FILE;
export const deleteFile = (fileId, phaseId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + SUBPRO_DELETE_FILE}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        FileId: fileId,
        ProjectId: "",
        PhaseId: phaseId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//create subContractors
const SUBPRO_CREATE_TENANT = constFile.SUBPRO_CREATE_TENANT;
export const createSubContractors = (subProjectId, subContractors) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + SUBPRO_CREATE_TENANT}`,
      {
        SubProjectId: subProjectId,
        InvitedCompanies: subContractors,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//update subContractors
const SUBPRO_UPDATE_TENANT = constFile.SUBPRO_UPDATE_TENANT;
export const updateSubContractors = (subProjectId, subContractors) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(
      `${process.env.REACT_APP_URL + SUBPRO_UPDATE_TENANT}`,
      {
        SubProjectId: subProjectId,
        InvitedCompanies: subContractors,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//get subContractors create data
const SUBPRO_INPUT_CREATE = constFile.SUBPRO_INPUT_CREATE;
export const getSubCreateInputData = (subProjectId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + SUBPRO_INPUT_CREATE}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        subProjectId: subProjectId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//get subContractors edit data
const SUBPRO_INPUT_UPDATE = constFile.SUBPRO_INPUT_UPDATE;
export const getSubUpdateInputData = (subProjectId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + SUBPRO_INPUT_UPDATE}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        subProjectId: subProjectId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

//delete contract
const DELETE_CONTRACT = constFile.DELETE_CONTRACT
export const deleteContract = (contractId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .delete(`${process.env.REACT_APP_URL + DELETE_CONTRACT}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        Id : contractId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
}

//get subContractors create data
const GET_PHASES_ASSIGNED_LIST = constFile.GET_PHASES_ASSIGNED_LIST;
export const getAssignedSubprojectPhases = (ProjectId) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .get(`${process.env.REACT_APP_URL + GET_PHASES_ASSIGNED_LIST}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        ProjectId: ProjectId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
