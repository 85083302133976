import React, { useEffect } from "react";
import AdminDashboard from "../Components/Admin/AdminDashboard";
import { useNavigate } from "react-router-dom";
import "../Assets/Styles/home.css";
export default function Admin({
  setRegister,
  setNaved,
  setSetupProfile,
  setSetupMenu,
}) {
  const navigate = useNavigate();

  useEffect(() => {
    let apiTest = true;
    if (localStorage.getItem("accessToken") && apiTest) {
      if (localStorage.getItem("isVerified") === "false") {
        if (localStorage.getItem("isTeamMember") === "true")
          navigate("/teamMemberRegistration");
        else {
          if (localStorage.getItem("AccountType") === "1") navigate("/main");
          if (localStorage.getItem("AccountType") === "2") navigate("/sub");
        }
      }
    }
    else navigate('/login');
    return () => {
      apiTest = false;
    }
  }, [navigate]);

  useEffect(() => {
    setRegister(true);
  }, [setRegister]);

  useEffect(() => {
    setNaved(false);
  }, [setNaved]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      className="Rectangle779"
      style={{ overflow: "hidden", overflowX: "hidden" }}
    >
      <div>
        <AdminDashboard
          setSetupProfile={setSetupProfile}
          setSetupMenu={setSetupMenu}
        />
      </div>
    </div>
  );
}
