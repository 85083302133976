import { Backdrop, CircularProgress, Grid } from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import homeBg from "../../Assets/Images/Dashboard/logInBgColor.png";
import classes from "../../Assets/Styles/Dashboard/Production.module.css";
import printIcon from "../../Assets/Images/Dashboard/print-icon.png";
import DashNavBar from "./DashNavBar";
import windowDimensoin from "../../../Components/Navbar/WindowDimension";
import * as services from "../../Services/machine-dashboard-services.proxy";
import back from "../../Assets/Images/Dashboard/back1.png";
import ErrorModal from "../../SharedComponents/ErrorModal";

const getCurrentCycle = services.getCurrentCycle;
const updateCycleStatus = services.updateCycleStatus;

export default function ProductionFlow(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const groupId = query.get("groupId");

  const { width } = windowDimensoin();

  const [loading, setLoading] = useState(false);

  const [started, setStarted] = useState(false);

  const [finished, setFinished] = useState(false);

  const [cycle, setCycle] = useState(null);
  const [disableOnHold, setDisableOnHold] = useState(false);

  const [loadingPage, setLoadingPage] = useState(false);

  Array.prototype.sum = function (prop) {
    var total = 0;
    for (var i = 0, _len = this.length; i < _len; i++) {
      total += +this[i][prop];
    }
    return total;
  };

  useEffect(() => {
    let apiTest = true;
    setLoadingPage(true);
    getCurrentCycle(null, groupId, null).then((x) => {
      setLoadingPage(false);
      if (apiTest) {

        if (x.status || x.statusCode) {
          if (x.status) setBEerror(x.error)
          if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
          setOpenErrorModal(true);

        } else {
          setCycle(x);

        }
      }
    });
    return () => {
      apiTest = false;
    };
  }, []);

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [BEerror, setBEerror] = useState(null);

  return (
    <>
      <Grid
        sx={{
          padding: "2% 3.5% 0 3.5%",
          backgroundImage: `url(${homeBg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          paddingBottom: "10%",
          minHeight: "100vh",
        }}
      >
        <Backdrop
          sx={{
            color: "#fff",
            backgroundColor: "transparent",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            display: "flex",
            flexDirection: "column",
          }}
          open={loadingPage}
        >
          <CircularProgress />
          <Grid
            item
            fontFamily={"Muli"}
            fontSize={15}
            fontWeight={"bold"}
            color={"rgb(25, 118, 210)"}
          >
            Loading Data...
          </Grid>
        </Backdrop>
        <DashNavBar
          disableOnHold={disableOnHold}
          setDisableOnHold={setDisableOnHold}
        />
        <Grid item container padding="4% 0% 0% 4%">
          <Grid
            item
            xs={12}

          >
            <img
              onMouseOver={(e) => (e.target.style.cursor = "pointer")}
              onClick={() => navigate("/machine/dashboard")}
              src={back} alt="back" />
          </Grid>
          <Grid item container xs={12} alignItems="center">
            <Grid item container gap={0.5} xs={9}>
              <Grid item xs={12} className={classes["Production-Order-Code"]}>
                Cycle Order
              </Grid>
              {+localStorage.getItem("machineCategory") === 1 ? (
                <Grid item xs={12} className={classes["Production-Order-Code"]}>
                  Load : {cycle?.Repititions} Pcs.
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item container xs={3}>
              {finished ? (
                <></>
              ) : (
                <button
                  disabled={(loading)}
                  className={classes["close-button"]}
                  onClick={() => {
                    setLoading(true);
                    if (started)
                      updateCycleStatus(
                        groupId,
                        localStorage.getItem("LineBarcode")
                          ? localStorage.getItem("LineBarcode")
                          : null,
                        3
                      ).then((x) => {
                        setLoading(false);
                        if (x.status || x.statusCode) {
                          if (x.status) setBEerror(x.error)
                          if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
                          setOpenErrorModal(true);

                        } else {
                          setFinished(true);
                          setStarted(false);
                        }


                      });
                    else {
                      updateCycleStatus(
                        groupId,
                        localStorage.getItem("LineBarcode")
                          ? localStorage.getItem("LineBarcode")
                          : null,
                        2
                      ).then((x) => {
                        setLoading(false);
                        if (x.status || x.statusCode) {
                          if (x.status) setBEerror(x.error)
                          if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
                          setOpenErrorModal(true);
                        } else {
                          setFinished(false);
                          setStarted(true);
                          setDisableOnHold(true);
                        }
                      });
                    }
                  }}
                >
                  {started
                    ? loading
                      ? "Finishing ..."
                      : "Finish"
                    : loading
                      ? "Starting ..."
                      : "Start"}
                </button>
              )}
            </Grid>
          </Grid>
          <Grid item container paddingTop="4.5%" gap={2}>
            <Grid item container xs={12} alignItems="center">
              <Grid item sm={2.2} md={2.3} lg={1.9} />
              <Grid
                item
                container
                sm={+localStorage.getItem("machineCategory") === 1 ? 3 : 2}
                className={classes["TOTAL-NO-OF-BARS"]}
              >
                <Grid item sm={3}>
                  {+localStorage.getItem("machineCategory") === 1
                    ? "CUTTING"
                    : "SHAPE"}
                  <br /> LENGTH
                  <br />
                  mm.
                </Grid>
                {+localStorage.getItem("machineCategory") === 1 ? (
                  <Grid item sm={3}>
                    NO. <br /> OF CUTS
                  </Grid>
                ) : (
                  <></>
                )}
                {+localStorage.getItem("machineCategory") === 1 ? (
                  <Grid item sm={3}>
                    QTY TO BE
                    <br />
                    PRODUCED
                  </Grid>
                ) : (
                  <></>
                )}

                <Grid item sm={3}>
                  INITIAL
                  <br />
                  QTY
                </Grid>
              </Grid>
            </Grid>
            {cycle?.Lines?.map((line, index) => (
              <Grid item key={index} container gap={{ sm: 1, md: 1.2, lg: 2.2 }}>
                {index === 0 ? (
                  <Grid
                    item
                    container
                    sm={2}
                    md={2.1}
                    lg={1.7}
                    alignItems="center"
                    className={classes["Rectangle-4027"]}
                  >
                    <Grid item xs={11.5}>
                      Diameter: {cycle?.Diameter}
                    </Grid>
                    <Grid item xs={11.5}>
                      Standard: {cycle?.Standard}
                    </Grid>
                    <Grid item xs={11.5}>
                      Grade: {cycle?.Grade}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    item
                    container
                    sm={2}
                    md={2.1}
                    lg={1.7}
                    alignItems="center"
                  >
                    <Grid item xs={11.5}></Grid>
                    <Grid item xs={11.5}></Grid>
                    <Grid item xs={11.5}></Grid>
                  </Grid>
                )}
                <Grid
                  item
                  container
                  sm={+localStorage.getItem("machineCategory") === 1 ? 3 : 2}
                  md={+localStorage.getItem("machineCategory") === 1 ? 3 : 2}
                  lg={+localStorage.getItem("machineCategory") === 1 ? 3 : 2}
                >
                  <Grid
                    item
                    container
                    alignItems="center"
                    className={classes["Rectangle-4119"]}
                  >
                    <Grid item sm={3}>
                      {line?.Length}
                    </Grid>
                    {+localStorage.getItem("machineCategory") === 1 ? (
                      <Grid item sm={3}>
                        {line?.NumOfBars}
                      </Grid>
                    ) : (
                      <></>
                    )}

                    {+localStorage.getItem("machineCategory") === 1 ? (
                      <Grid item sm={3}>
                        {line?.NumOfBars * cycle?.Repititions}
                      </Grid>
                    ) : (
                      <></>
                    )}

                    <Grid item sm={3}>
                      {line?.TotalNumOfBars}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  style={{ minHeight: "87px" }}
                  sm={+localStorage.getItem("machineCategory") === 1 ? 3.5 : 4}
                  lg={+localStorage.getItem("machineCategory") === 1 ? 3 : 4}
                  justifyContent="center"
                  alignItems="center"
                  className={classes["shape-rectangle"]}
                >
                  <Grid item>
                    {line?.ShapeUrl ? (
                      <img
                        src={line?.ShapeUrl}
                        width="100%"
                        height={
                          +localStorage.getItem("machineCategory") === 1
                            ? 90
                            : 150
                        }
                        alt="shape"
                      />
                    ) : (
                      <div style={{ height: 90 }}></div>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  sm={2}
                  md={2}
                  lg={1.7}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item className={classes["machine-status"]}>
                    <Grid
                      item
                      container
                      direction="row"
                      alignItems="center"
                      gap={1}
                    >
                      <Grid item>
                        {started
                          ? "In Production"
                          : finished
                            ? "Produced"
                            : "Released for production"}
                      </Grid>
                      {started ? (
                        <>
                          {width < 1007 ? (
                            <Grid item container justifyContent="center">
                              <Grid item>
                                <CircularProgress size={28} />
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid item>
                              <Grid item container justifyContent="center">
                                <CircularProgress size={28} />
                              </Grid>
                            </Grid>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                {line?.IsTerminated === true && started && finished ? (
                  <Grid
                    item
                    container
                    sm={0.6}
                    paddingTop={1}
                    md={0.6}
                    lg={1.5}
                    justifyContent={{ xs: "center", lg: "flex-start" }}
                    alignItems="center"
                  >
                    <Grid item>
                      <img
                        src={printIcon}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate(`/machine/print?lineId=${line?.LineId}`);
                        }}
                        alt="print label"
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            ))}
          </Grid>
          {finished ? (
            <Grid
              item
              container
              marginTop={{ xs: 10, lg: 7 }}
              sm={12}
              lg={10}
              direction="row-reverse"
            >
              <Grid
                item
                onClick={() => {
                  navigate(`/machine/summary?groupId=${groupId}`);
                }}
                className={classes["close-button"]}
              >
                Summary
              </Grid>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
      <ErrorModal openErrorModal={openErrorModal} setOpenErrorModal={setOpenErrorModal} BEerror={BEerror} />

    </>
  );
}
