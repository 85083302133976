import React from "react";
import { Grid } from "@mui/material";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/system";
import * as salesOrderConst from "../../../../salesOrderConst";
import classes from "../../../Assets/Styles/Activities.module.css";
import DownArrow from "../../../Assets/Images/Activity/arrow down.png";

const ProgressBar = (props) => {
  return (
    <>
      <Grid
        container
        item
        xs={12}
        direction={"row"}
        gap={{ md: "14.2%", sm: "10.2%", xs: "7.6%" }}
        paddingLeft={{ lg: 9, md: 2, xs: 1.9 }}
      >
  

        {props.status === salesOrderConst.PLACED_ID ? (
          <Grid item>
            <img src={DownArrow} alt={"arrow"} height={17} />
          </Grid>
        ) : (
          <Grid item width={23}></Grid>
        )}
        {props.status === salesOrderConst.PLANNED_ID ? (
          <Grid item justifyContent={"center"}>
            <img src={DownArrow} alt={"arrow"} height={17} />
          </Grid>
        ) : (
          <Grid item width={23}></Grid>
        )}
        {props.status === salesOrderConst.IN_PRODUCTION_ID ? (
          <Grid item>
            <img src={DownArrow} alt={"arrow"} height={17} />
          </Grid>
        ) : (
          <Grid item width={23}></Grid>
        )}
        {props.status === salesOrderConst.LOADING_ID ? (
          <Grid item>
            <img src={DownArrow} alt={"arrow"} height={17} />
          </Grid>
        ) : (
          <Grid item width={23}></Grid>
        )}
        {props.status === salesOrderConst.DELIVERED_ID ? (
          <Grid item>
            <img src={DownArrow} alt={"arrow"} height={17} />
          </Grid>
        ) : (
          <Grid item width={23}></Grid>
        )}
      </Grid>
      <Grid
        item
        height={22}
        container
        xs={12}
        direction={"row"}
        gap={{ md: "14.1%", sm: "10%", xs: "7.4%" }}
        className={classes["Rectangle-1607"]}
        paddingLeft={{ lg: 9, md: 2, xs: 2 }}
      >
        <BlueTip
          title={"Placed"}
          popper={"true"}
          placement={
            props.status === salesOrderConst.PLACED_ID ? "bottom" : "top"
          }
          interactive={"true"}
        >
          <Grid
            item
            className={classes["Ellipse-grey"]}
            bgcolor={"#0674b9"}
          ></Grid>
        </BlueTip>
        <BlueTip
          title={"Planned"}
          popper={"true"}
          placement={
            props.status === salesOrderConst.PLANNED_ID ? "bottom" : "top"
          }
          interactive={"true"}
        >
          <Grid
            item
            className={classes["Ellipse-grey"]}
            bgcolor={"#2da4d0"}
          ></Grid>
        </BlueTip>
        <BlueTip
          title={"In Production"}
          popper={"true"}
          placement={
            props.status === salesOrderConst.IN_PRODUCTION_ID ? "bottom" : "top"
          }
          interactive={"true"}
        >
          <Grid
            item
            className={classes["Ellipse-grey"]}
            bgcolor={"#e1e319"}
          ></Grid>
        </BlueTip>
        <BlueTip
          title={"Loading"}
          popper={"true"}
          placement={
            props.status === salesOrderConst.LOADING_ID ? "bottom" : "top"
          }
          interactive={"true"}
        >
          <Grid
            item
            className={classes["Ellipse-grey"]}
            bgcolor={"rgba(16, 22, 80, 0.5)"}
          ></Grid>
        </BlueTip>
        <BlueTip
          title={"Delivered"}
          popper={"true"}
          placement={
            props.status === salesOrderConst.DELIVERED_ID ? "bottom" : "top"
          }
          interactive={"true"}
        >
          <Grid
            item
            className={classes["Ellipse-grey"]}
            bgcolor={"rgba(16, 22, 80, 0.7)"}
          ></Grid>
        </BlueTip>
      </Grid>
    </>
  );
};

export default ProgressBar;
const BlueTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
        height:1px;
        background-color: #fff;
        font-family: Muli;
  font-size: 10px;
  font-style: italic;
  text-align: center;
  color: #101650;
    `);
