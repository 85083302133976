import React, { useState, useEffect } from "react";

import { Grid } from "@mui/material";
import sent from "../../../../Assets/Images/sub/submail.png";
import BgMain from "../../../../SAAS/Assets/Images/ProfileDetails/SaaSbackground.png";

import close from "../../../../Assets/Images/form/x.png";
import check from "../../../../Assets/Images/Pricing/CheckSubpx.png";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import infoTip from "../../../../Assets/Images/form/info.png";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import ManuContactUsBG from "../../../../Assets/Images/Pricing/ManuContactUsBg.png";

import CloseIcon from "@mui/icons-material/Close";
import * as pricingRequestService from "../../../../Services/pricing-data.proxy";
const requestCustomSubscription =
  pricingRequestService.requestCustomSubscription;
const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#707070",
      height: "40px",
    },
  },
});
const BlueTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
      color: #101650;
      background-color: rgba(6, 116, 185, 0.3);
      font-size: 13px;
      width:180px;
   
  `);
const PLanCoreFeatures = [
  { Name: "Core Manufacturing Plan:", Checked: false },
  { Name: "Inventory Management", Checked: true },
  { Name: "Order Book Management", Checked: true },
  { Name: "Production Management", Checked: true },
  { Name: "Delivery Management", Checked: true },
  { Name: "Standard Collaboration", Checked: true },
];
const PLanProFeatures = [
  { Name: "Pro Manufacturing Plan:", Checked: false },
  { Name: "Everything in Core plus,", Checked: false },
  { Name: "Real Time Production Management", Checked: true },
  { Name: "Real Time Inventory Management", Checked: true },
  { Name: "Real Time Workforce Management", Checked: true },
  { Name: "Real Time Load Management", Checked: true },
  { Name: "Real Time Traceability", Checked: true },
  { Name: "Real Time Collaboration", Checked: true },
  { Name: "Standard Machine Integration", Checked: true },
  { Name: "Pro Analytics", Checked: true },
];
const PLanProPlusFeatures = [
  { Name: "Pro+ Manufacturing Plan", Checked: true },
  { Name: "Everything in Pro plus,", Checked: false },
  { Name: "Native AI Engine", Checked: true },
  { Name: "AI Powered Collaboration", Checked: true },
  { Name: "Advanced Machine Integration", Checked: true },
];
export default function ContactUsPricing() {
  const style = {
    blueTip: {
      width: 200,
    },
  };

  const navigate = useNavigate();
  const [nbOfFactories, setNbOfFactories] = useState(null);
  const [factoriesVolume, setFactoriesVolume] = useState(null);
  const subscriptonTypeId = localStorage.getItem("SubscriptionType");
  const [showFields, setShowFields] = useState(
    localStorage.getItem("SubscriptionType") === "3" ? true : false
  );
  const [planFeatures, setPlanFeatures] = useState([]);
  const [loading,setLoading] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("Plan Name")) {
      if (localStorage.getItem("Plan Name") === "Core") {
        setPlanFeatures(PLanCoreFeatures);
      } else if (localStorage.getItem("Plan Name") === "Pro") {
        setPlanFeatures(PLanProFeatures);
      } else if (localStorage.getItem("Plan Name") === "Pro +") {
        setPlanFeatures(PLanProPlusFeatures);
      }
    }
  }, []);
  const styles = {
    backgroundContainer: {
      backgroundImage: `url(${
        localStorage.getItem("SubscriptionType") === "3"
          ? ManuContactUsBG
          : BgMain
      })`,
      backgroundPosition: "center",
      backgroundRepeat: "no-Repeat",
      backgroundSize: "cover",
      minHeight: "100vh",
    },

    ProfileText: {
      fontFamily: "Muli",
      fontSize: "30px",
      fontWeight: "bold",
      color: "#101650",
    },
    YouGetText: {
      fontFamily: "Muli",
      color: "#101650",
      fontSize: "13px",
    },
    YouGetTextTitle: {
      fontFamily: "Muli",
      color: "rgba(16, 22, 80, 0.5)",
      fontSize: "18px",
    },
    Pro: {
      fontFamily: "Muli",
      color: "#101650",
      fontSize: "13px",
      fontWeight: "bold",
    },
    backgroundContainer2: {
      boxShadow: "0 6px 12px 0 rgba(6, 116, 185, 0.5)",
      border: "solid 1px #eaf0f5",
      backgroundColor: "#fff",
    },

    thanksMessage: {
      fontFamily: "Muli",
      fontSize: "25px",
      fontWeight: "bold",
      color: "#0674b9",
    },
    messageText: {
      fontFamily: "Muli",
      color: "#101650",
      fontSize: "15px",
      textAlign: "center",
    },
    titlem: {
      fontFamily: "Muli",
      fontSize: "16px",
      fontWeight: "bold",
      textAlign: "left",
      color: "#181818",
    },
    RectangleSubmit: {
      padding: "5px",
      borderRadius: "5px",
      backgroundColor: "#0b5cab",
      fontFamily: "Muli",
      fontSize: "19px",
      textAlign: "center",
      color: "#fff",
      border: 0,
    },
  };
  const requestSubscription = () => {
    setLoading(true);

    requestCustomSubscription(
      localStorage.getItem("UserId"),
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      3,
      null,
      true,
      factoriesVolume,
      nbOfFactories,
      localStorage.getItem("ProductId")
    ).then((x) => {
      setShowFields(false);
      setLoading(false);
      
    });
  };
  return (
    <>
      <div style={styles.backgroundContainer}>
        <Grid container rowGap={4} paddingLeft={{ xs: 1 }} paddingTop={13}>
          <Grid item container rowSpacing={{ xs: 2 }} justifyContent={"center"}>
            <Grid
              item
              md={12}
              lg={4}
              container
              direction={"column"}
              spacing={3}
              paddingLeft={{ xs: 3 }}
            >
              <Grid item sx={styles.ProfileText}>
                {localStorage.getItem("SubscriptionType") === "3"
                  ? "Great; you're adding Manufacturing Plan "
                  : "Let's get in touch!"}
              </Grid>
              <Grid item sx={styles.YouGetTextTitle} width={285}>
                Pilecubes is what contractors are using to work seamlessly and
                accomplish more, together.
              </Grid>
              {localStorage.getItem("SubscriptionType") === "3" ? (
                <Grid item sx={styles.YouGetText} container gap={1}>
                  {planFeatures?.map((feature, index) => (
                    <Grid item container gap={1}>
                      {feature.Checked ? (
                        <Grid item>
                          <img src={check} alt="check"></img>
                        </Grid>
                      ) : (
                        ""
                      )}
                      <Grid item> {feature.Name}</Grid>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Grid
                  item
                  sx={styles.YouGetText}
                  container
                  spacing={{ md: 3, xs: 1 }}
                >
                  <Grid item container spacing={1}>
                    <Grid item container spacing={1} direction="row">
                      <Grid item container direction="row">
                        Everything in
                        <Grid sx={styles.Pro} paddingLeft={0.2}>
                          Pro
                        </Grid>
                        , plus
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container spacing={1}>
                    <Grid item>
                      <img src={check} alt="check"></img>
                    </Grid>

                    <Grid item> Unlimited drawings</Grid>
                  </Grid>
                  <Grid item container spacing={1}>
                    <Grid item bgcolor={"transparent"}>
                      <img src={check} alt="check"></img>
                    </Grid>
                    <Grid item>Unlimited projects</Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid
              item
              md={12}
              lg={5}
              marginLeft={{ xs: 2 }}
              paddingBottom={{ xs: 12 }}
            >
              <Grid
                item
                container
                direction={"column"}
                alignItems={"center"}
                marginTop={1}
                md={9}
                sx={styles.backgroundContainer2}
                paddingBottom={showFields ? 2 : 15}
              >
                {showFields ? (
                  <>
                    <Grid item container gap={2} paddingLeft={2} paddingTop={2}>
                      <Grid item container md={12} xs={12} direction={"row"}>
                        <Grid item md={9} xs={11}>
                          <div style={styles.titlem}>Manufacturing Info</div>
                        </Grid>

                        <Grid
                          md={2.2}
                          xs={1}
                          container
                          item
                          direction={"row-reverse"}
                          onClick={(e) => {
                            navigate("/saas/admin");
                          }}
                        >
                          <img src={close} width={"15px"} />
                        </Grid>
                      </Grid>
                      <Grid item md={11}>
                        <CssTextField
                          label="Number of factories"
                          size="small"
                          value={nbOfFactories}
                          type="number"
                          className="textField"
                          InputLabelProps={{
                            style: {
                              fontFamily: "Muli",
                              fontSize: "16px",
                            },
                          }}
                          onChange={(e) => {
                            setNbOfFactories(e.target.value);
                          }}
                          InputProps={{
                            inputProps: { min: 0 },
                            endAdornment: (
                              <IconButton edge="end">
                                <BlueTip
                                  title={
                                    "Number of factories: The number of factories that the company wants to implement the manufacturing solution. Factory volume: The estimated average monthly volume per factory (per ton)."
                                  }
                                  sx={style.blueTip}
                                  placement="right"
                                >
                                  <img
                                    width={"20px"}
                                    src={infoTip}
                                    alt={"close"}
                                  ></img>
                                </BlueTip>
                              </IconButton>
                            ),
                          }}
                        ></CssTextField>
                      </Grid>
                      <Grid item md={11}>
                        <CssTextField
                          label="Factory volume"
                          size="small"
                          value={factoriesVolume}
                          type="number"
                          className="textField"
                          InputLabelProps={{
                            style: {
                              fontFamily: "Muli",
                              fontSize: "16px",
                            },
                          }}
                          onChange={(e) => {
                            setFactoriesVolume(e.target.value);
                          }}
                          InputProps={{
                            inputProps: { min: 0 },
                            endAdornment: (
                              <IconButton edge="end">
                                <BlueTip
                                  title={
                                    "Factory volume: The estimated average monthly volume per factory (per ton)."
                                  }
                                  sx={style.blueTip}
                                  placement="right"
                                >
                                  <img
                                    width={"20px"}
                                    src={infoTip}
                                    alt={"close"}
                                  ></img>
                                </BlueTip>
                              </IconButton>
                            ),
                          }}
                        ></CssTextField>
                      </Grid>
                      <Grid item md={11} container direction={"row-reverse"}>
                        <button
                          style={styles.RectangleSubmit}
                          disabled={
                            nbOfFactories && factoriesVolume ? false : true
                          }
                          onMouseOver={(e) => {
                            if (nbOfFactories && factoriesVolume)
                              e.target.style.cursor = "pointer";
                          }}
                          onClick={() => {
                            requestSubscription();
                          }}
                        >
                          {loading? "submitting ...":"Submit"}
                        </button>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid
                      item
                      md={12}
                      xs={12}
                      paddingRight={2}
                      container
                      onClick={(e) => {
                        navigate("/saas/admin");
                      }}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                      direction={"row-reverse"}
                      paddingTop={2}
                    >
                      <img src={close} />
                    </Grid>
                    <Grid item marginTop={5}>
                      <img src={sent} alt="sent" />
                    </Grid>
                    <Grid item marginTop={7}>
                      <div style={styles.thanksMessage}>
                        Thank you for reaching out
                      </div>
                    </Grid>
                    <Grid
                      item
                      marginTop={2}
                      sx={styles.messageText}
                      alignItems={"center"}
                      width={252}
                    >
                      Pilecubes Team will respond soon to manage your
                      subscription.
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
