import loadingLogo from "../../Assets/Images/home/loadingLogo.png";

export default {
  v: "5.8.1",
  fr: 24,
  ip: 0,
  op: 72,
  w: 540,
  h: 405,
  nm: "loading ",
  ddd: 0,

  assets: [{ id: "image_0", w: 350, h: 350, u: "", p: loadingLogo, e: 0 }],

  fonts: {
    list: [{ fName: "Muli", fFamily: "Muli", fStyle: "Regular", ascent: 250 }],
  },
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 5,
      nm: "Loading . . .",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [215.75, 278.375, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      t: {
        d: {
          k: [
            {
              s: {
                s: 19,
                f: "Muli",
                t: "Loading . . .",
                ca: 0,
                j: 0,
                tr: 0,
                lh: 34,
                ls: 0,
                fc: [0.024, 0.455, 0.725],
              },
              t: 0,
            },
          ],
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: [
          {
            nm: "Animator 1",
            s: {
              t: 0,
              xe: { a: 0, k: 0, ix: 7 },
              ne: { a: 0, k: 0, ix: 8 },
              a: { a: 0, k: 100, ix: 4 },
              b: 1,
              rn: 0,
              sh: 1,
              sm: { a: 0, k: 0, ix: 6 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 0,
                    s: [0],
                  },
                  { t: 60.060546875, s: [100] },
                ],
                ix: 1,
              },
              r: 1,
            },
            a: { o: { a: 0, k: 0, ix: 9 } },
          },
        ],
      },
      ip: 0,
      op: 72,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "Shape Layer 2",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [351, 282.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [32.5, 32.5], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.023529411765, 0.454901960784, 0.725490196078, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 3, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [-40.25, -64.5], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [92.247, 92.247], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "tm",
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 23,
                s: [0],
              },
              { t: 48, s: [95] },
            ],
            ix: 1,
          },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 0,
                s: [0],
              },
              { t: 24, s: [95] },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: "Trim Paths 1",
          mn: "ADBE Vector Filter - Trim",
          hd: false,
        },
      ],
      ip: 0,
      op: 72,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "Shape Layer 1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [270, 202.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [32.5, 32.5], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.023529411765, 0.454901960784, 0.725490196078, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 3, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [-40.25, -64.5], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [92.247, 92.247], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "tm",
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 23,
                s: [0],
              },
              { t: 48, s: [95] },
            ],
            ix: 1,
          },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 0,
                s: [0],
              },
              { t: 24, s: [95] },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: "Trim Paths 1",
          mn: "ADBE Vector Filter - Trim",
          hd: false,
        },
      ],
      ip: 0,
      op: 72,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: "Artboard – 12.png",
      cl: "png",
      refId: "image_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [270, 202.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [175, 175, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 72,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
  chars: [
    {
      ch: "L",
      size: 19,
      style: "Regular",
      w: 54.15,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [52.246, 0],
                      [52.246, -7.861],
                      [19.189, -7.861],
                      [19.189, -71.094],
                      [9.863, -71.094],
                      [9.863, 0],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "L",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "L",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Muli",
    },
    {
      ch: "o",
      size: 19,
      style: "Regular",
      w: 61.43,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-4.558, 4.818],
                      [0, 7.748],
                      [4.492, 4.834],
                      [8.073, 0],
                      [4.606, -4.85],
                      [0, -7.877],
                      [-4.574, -4.817],
                      [-8.105, 0],
                    ],
                    o: [
                      [4.557, -4.817],
                      [0, -7.91],
                      [-4.492, -4.834],
                      [-8.105, 0],
                      [-4.607, 4.851],
                      [0, 7.748],
                      [4.573, 4.818],
                      [8.008, 0],
                    ],
                    v: [
                      [49.658, -6.152],
                      [56.494, -25],
                      [49.756, -44.116],
                      [30.908, -51.367],
                      [11.841, -44.092],
                      [4.932, -25],
                      [11.792, -6.152],
                      [30.811, 1.074],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "o",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [2.815, 3.418],
                      [0, 5.664],
                      [-2.832, 3.467],
                      [-5.273, 0],
                      [-2.816, -3.467],
                      [0, -5.762],
                      [2.783, -3.418],
                      [5.273, 0],
                    ],
                    o: [
                      [-2.816, -3.418],
                      [0, -5.762],
                      [2.832, -3.467],
                      [5.241, 0],
                      [2.815, 3.467],
                      [0, 5.664],
                      [-2.783, 3.418],
                      [-5.339, 0],
                    ],
                    v: [
                      [18.579, -11.377],
                      [14.355, -25],
                      [18.604, -38.843],
                      [30.762, -44.043],
                      [42.847, -38.843],
                      [47.07, -25],
                      [42.896, -11.377],
                      [30.811, -6.25],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "o",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "o",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Muli",
    },
    {
      ch: "a",
      size: 19,
      style: "Regular",
      w: 62.16,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [2.734, 3.532],
                      [0, 5.632],
                      [-2.718, 3.451],
                      [-5.209, 0],
                      [-2.67, -3.434],
                      [0, -5.534],
                      [2.62, -3.467],
                      [4.98, 0],
                    ],
                    o: [
                      [-2.734, -3.532],
                      [0, -5.598],
                      [2.718, -3.45],
                      [4.948, 0],
                      [2.669, 3.435],
                      [0, 5.859],
                      [-2.621, 3.467],
                      [-5.241, 0],
                    ],
                    v: [
                      [18.066, -11.548],
                      [13.965, -25.293],
                      [18.042, -38.867],
                      [29.932, -44.043],
                      [41.357, -38.892],
                      [45.361, -25.439],
                      [41.431, -11.45],
                      [30.029, -6.25],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "a",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-2.832, 1.709],
                      [-1.433, 2.995],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.913, 1.726],
                      [3.548, 0],
                      [3.645, -2.376],
                      [1.904, -3.955],
                      [0, -4.883],
                      [-1.058, -3.141],
                      [-1.97, -2.393],
                      [-3.044, -1.399],
                      [-3.744, 0],
                    ],
                    o: [
                      [2.832, -1.709],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.498, -2.702],
                      [-2.914, -1.725],
                      [-4.785, 0],
                      [-3.646, 2.377],
                      [-1.904, 3.955],
                      [0, 3.548],
                      [1.057, 3.142],
                      [1.969, 2.393],
                      [3.043, 1.399],
                      [3.711, 0],
                    ],
                    v: [
                      [38.574, -1.489],
                      [44.971, -8.545],
                      [44.971, 0],
                      [53.955, 0],
                      [53.955, -50.098],
                      [45.068, -50.098],
                      [45.068, -42.139],
                      [38.452, -48.779],
                      [28.76, -51.367],
                      [16.113, -47.803],
                      [7.788, -38.306],
                      [4.932, -25.049],
                      [6.519, -15.015],
                      [11.06, -6.714],
                      [18.579, -1.025],
                      [28.76, 1.074],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "a",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "a",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Muli",
    },
    {
      ch: "d",
      size: 19,
      style: "Regular",
      w: 61.72,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [2.571, 3.451],
                      [0, 5.664],
                      [-2.637, 3.467],
                      [-4.753, 0],
                      [-2.702, -3.434],
                      [0, -5.501],
                      [2.669, -3.483],
                      [4.915, 0],
                    ],
                    o: [
                      [-2.572, -3.45],
                      [0, -5.696],
                      [2.637, -3.467],
                      [4.752, 0],
                      [2.702, 3.435],
                      [0, 5.859],
                      [-2.67, 3.484],
                      [-4.785, 0],
                    ],
                    v: [
                      [18.115, -11.426],
                      [14.258, -25.098],
                      [18.213, -38.843],
                      [29.297, -44.043],
                      [40.479, -38.892],
                      [44.531, -25.488],
                      [40.527, -11.475],
                      [29.15, -6.25],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "d",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-2.881, 1.888],
                      [-1.009, 2.605],
                      [0, 0],
                      [0, 0],
                      [0.032, 2.995],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.864, 1.726],
                      [3.841, 0],
                      [3.564, -2.295],
                      [1.823, -3.955],
                      [0, -5.143],
                      [-4.281, -4.736],
                      [-6.706, 0],
                    ],
                    o: [
                      [2.881, -1.888],
                      [0, 0],
                      [0, 0],
                      [-0.195, -5.664],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.107, -2.409],
                      [-2.865, -1.725],
                      [-4.818, 0],
                      [-3.564, 2.295],
                      [-1.823, 3.955],
                      [0, 7.845],
                      [4.28, 4.736],
                      [4.264, 0],
                    ],
                    v: [
                      [38.55, -1.758],
                      [44.385, -8.496],
                      [44.58, 0],
                      [53.711, 0],
                      [53.369, -12.988],
                      [53.369, -72.559],
                      [44.336, -72.559],
                      [44.336, -42.578],
                      [38.379, -48.779],
                      [28.32, -51.367],
                      [15.747, -47.925],
                      [7.666, -38.55],
                      [4.932, -24.902],
                      [11.353, -6.03],
                      [27.832, 1.074],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "d",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "d",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Muli",
    },
    {
      ch: "i",
      size: 19,
      style: "Regular",
      w: 25.39,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [17.334, -62.744],
                      [17.334, -71.094],
                      [8.447, -71.094],
                      [8.447, -62.744],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "i",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [17.334, 0],
                      [17.334, -50.098],
                      [8.398, -50.098],
                      [8.398, 0],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "i",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "i",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Muli",
    },
    {
      ch: "n",
      size: 19,
      style: "Regular",
      w: 60.74,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [-3.093, 2.556],
                      [-3.777, 0],
                      [-1.628, -2.571],
                      [0, -5.436],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.995, 3.825],
                      [6.315, 0],
                      [3.108, -1.611],
                      [1.725, -2.864],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-0.082, -2.311],
                      [0, -2.213],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.26, -3.32],
                      [3.092, -2.555],
                      [4.102, 0],
                      [1.627, 2.572],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, -7.78],
                      [-2.995, -3.825],
                      [-3.451, 0],
                      [-3.109, 1.611],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.098, 1.205],
                      [0.081, 2.312],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [17.676, 0],
                      [17.676, -31.396],
                      [22.705, -40.21],
                      [33.008, -44.043],
                      [41.602, -40.186],
                      [44.043, -28.174],
                      [44.043, 0],
                      [53.027, 0],
                      [53.027, -28.223],
                      [48.535, -45.63],
                      [34.57, -51.367],
                      [24.731, -48.95],
                      [17.48, -42.236],
                      [17.383, -50.098],
                      [8.105, -50.098],
                      [8.252, -48.291],
                      [8.521, -43.018],
                      [8.643, -36.23],
                      [8.643, 0],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "n",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "n",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Muli",
    },
    {
      ch: "g",
      size: 19,
      style: "Regular",
      w: 61.87,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [2.571, 3.076],
                      [0, 5.566],
                      [-2.734, 3.451],
                      [-4.785, 0],
                      [-2.621, -3.271],
                      [0, -5.501],
                      [2.637, -3.304],
                      [5.078, 0],
                    ],
                    o: [
                      [-2.572, -3.076],
                      [0, -5.598],
                      [2.734, -3.45],
                      [4.688, 0],
                      [2.62, 3.271],
                      [0, 5.566],
                      [-2.637, 3.304],
                      [-4.688, 0],
                    ],
                    v: [
                      [18.115, -12.329],
                      [14.258, -25.293],
                      [18.359, -38.867],
                      [29.639, -44.043],
                      [40.601, -39.136],
                      [44.531, -25.977],
                      [40.576, -12.671],
                      [29.004, -7.715],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "g",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-3.288, 1.367],
                      [-2.002, 2.473],
                      [-0.977, 3.255],
                      [0, 3.972],
                      [0, 0],
                      [-0.359, 5.404],
                      [0, 0],
                      [0, 0],
                      [2.669, 1.628],
                      [3.809, 0],
                      [3.58, -2.262],
                      [1.774, -3.906],
                      [0, -5.11],
                      [-4.379, -4.411],
                      [-6.934, 0],
                      [-2.654, 1.644],
                      [-1.4, 2.507],
                      [0, 0],
                      [2.93, -2.865],
                      [5.338, 0],
                      [5.534, 3.157],
                      [0, 0],
                      [-6.576, 0],
                    ],
                    o: [
                      [3.288, -1.367],
                      [2.002, -2.474],
                      [0.977, -3.255],
                      [0, 0],
                      [0, -3.58],
                      [0, 0],
                      [0, 0],
                      [-1.465, -2.539],
                      [-2.67, -1.627],
                      [-4.948, 0],
                      [-3.581, 2.263],
                      [-1.775, 3.906],
                      [0, 7.52],
                      [4.378, 4.411],
                      [3.873, 0],
                      [2.653, -1.643],
                      [0, 0],
                      [0, 5.794],
                      [-2.93, 2.864],
                      [-5.632, 0],
                      [0, 0],
                      [5.762, 2.832],
                      [4.362, 0],
                    ],
                    v: [
                      [39.355, 18.359],
                      [47.29, 12.598],
                      [51.758, 4.004],
                      [53.223, -6.836],
                      [53.223, -36.621],
                      [53.76, -50.098],
                      [44.482, -50.098],
                      [44.336, -42.676],
                      [38.135, -48.926],
                      [28.418, -51.367],
                      [15.625, -47.974],
                      [7.593, -38.721],
                      [4.932, -25.195],
                      [11.499, -7.3],
                      [28.467, -0.684],
                      [38.257, -3.149],
                      [44.336, -9.375],
                      [44.336, -4.15],
                      [39.941, 8.838],
                      [27.539, 13.135],
                      [10.791, 8.398],
                      [9.375, 16.162],
                      [27.881, 20.41],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "g",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "g",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Muli",
    },
    { ch: " ", size: 19, style: "Regular", w: 29.1, data: {}, fFamily: "Muli" },
    {
      ch: ".",
      size: 19,
      style: "Regular",
      w: 26.51,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [18.848, 0],
                      [18.848, -10.986],
                      [7.373, -10.986],
                      [7.373, 0],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: ".",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: ".",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Muli",
    },
  ],
};
