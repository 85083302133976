import React, { useEffect, useState } from "react";
import SaasBack from "../../../../Assets/Images/Projects/bgTableProject.png";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Grid,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableBody,
} from "@mui/material";
import classes from "../../../../Assets/Styles/RFIS.module.css";
import * as rfiServices from "../../../../Services/rfi-services.proxy";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
const getRFIList = rfiServices.getRFIList;
const RFIList = (props) => {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);
  const location = useLocation();
  const documentId = location?.state?.documentID;
  const navigate = useNavigate();
  const [RFIList, setRFIList] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  useEffect(() => {
    setLoadingData(true);
    getRFIList(documentId ? documentId : null).then((x) => {
      setRFIList(x);
      setLoadingData(false);
    });
  }, []);
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "transparent",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
          flexDirection: "column",
        }}
        open={loadingData}
      >
        <CircularProgress />

        <Grid
          item
          fontFamily={"Muli"}
          fontSize={15}
          fontWeight={"bold"}
          color={"rgb(25, 118, 210)"}
        >
          Loading RFIS...
        </Grid>
      </Backdrop>
      <Grid container paddingLeft={{ md: 15, xs: 4 }} paddingTop={5}>
        <Grid item xs={12} container alignItems="center">
          <Grid
            item
            md={1}
            xs={3.5}
            sx={{
              fontFamily: "Muli",
              fontSize: 18,
              fontWeight: "bold",
              lineHeight: 1.44,
              textAlign: "left",
              color: "#101650",
            }}
          >
            View all RFIs
          </Grid>
          <Grid
            item
            md={9.5}
            xs={5.5}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>
          <Grid
            item
            md={1}
            xs={3}
            onMouseOver={(e) => (e.target.style.cursor = "pointer")}
            sx={{
              fontFamily: "Muli",
              fontSize: 14,
              lineHeight: 1.36,
              textAlign: "left",
              color: "#101650",
              paddingLeft: "1%",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <button className={classes["GoBack"]}>Go Back </button>
          </Grid>
        </Grid>

        <TableContainer sx={{ paddingTop: 8, width: "90%" }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ borderCollapse: "separate", borderSpacing: "0px 20px" }}
          >
            <TableHead>
              <TableRow>
                <TableCell sx={styles.tableCell}>Actions</TableCell>
                <TableCell sx={styles.tableCell}>Subject</TableCell>
                <TableCell sx={styles.tableCell}>Status</TableCell>
                <TableCell sx={styles.tableCell}>Description</TableCell>
                <TableCell sx={styles.tableCell}>Reference</TableCell>
                <TableCell sx={styles.tableCell}>Assignee</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {RFIList.map((rfi) => (
                <TableRow sx={styles.tableRow} key={rfi.Id}>
                  <TableCell sx={styles.tableCell2} style={{ width: 120 }}>
                    <button
                      className={classes["ViewRFI"]}
                      onClick={() => {
                        navigate("/saas/admin/RFI/Response", {
                          state: { id: rfi.Id, ProjectId: rfi.ProjectId },
                        });
                      }}
                    >
                      View RFI
                    </button>
                    <button
                      className={classes["ViewRFI"]}
                      style={{ marginTop: 10 }}
                      onClick={() => {
                        navigate("/saas/admin/document", {
                          state: { RFIID: rfi.Id },
                        });
                      }}
                    >
                      View Documents
                    </button>
                  </TableCell>
                  <TableCell sx={styles.tableCell2}>{rfi.Subject}</TableCell>
                  <TableCell sx={styles.tableCell2}>{rfi.Status}</TableCell>
                  <TableCell
                    sx={styles.tableCell2}
                    style={{
                      width: "200px",
                      overflowWrap: "break-word",
                    }}
                  >
                    {rfi.Description}
                  </TableCell>
                  <TableCell sx={styles.tableCell2}>
                    {rfi.ReferenceDocuments.map((ref, index) => (
                      <div key={index}>{ref}</div>
                    ))}
                  </TableCell>
                  <TableCell sx={styles.tableCell2}>{rfi.Assignee}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {RFIList?.length === 0 && !loadingData ? (
          <Grid
            item
            container
            xs={12}
            alignItems={"center"}
            justifyContent={"center"}
            fontFamily={"Muli"}
          >
            No RFIs available
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </div>
  );
};
const styles = {
  tableCell: {
    backgroundColor: "transparent",
    fontFamily: "Muli",
    fontSize: 13,
    fontWeight: "bold",
    color: "#101650",
  },
  tableRow: {
    backgroundColor: "#fff",
    boxShadow: "0 12px 6px 0 rgba(0, 0, 0, 0.16)",
    spacing: 8,
    height: 107,
  },
  tableCell2: {
    backgroundColor: "transparent",
    fontFamily: "Muli",
    fontSize: 13,
    color: "#0674b9",
  },
  tableBody: {
    "& > :not(:last-child)": {
      borderBottom: "25px solid red",
    },
  },
};
export default RFIList;
