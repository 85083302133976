import React, { useState, useEffect } from "react";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import classes from "../../Table/Table.module.css";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { styled } from "@mui/system";
import { useNavigate, useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as saasServices from "../../../Services/saas-services.proxy";
import MenuPic from "../../../Assets/Images/Table/menuPic.png";
import FileSubImg from "../../../Assets/Images/Table/FileSub.png";
import fileimg from "../../../Assets/Images/Table/file.png";
import noFileimg from "../../../Assets/Images/Table/noFile.png";
import { TextField, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Stack } from "@mui/material";
import * as constStatus from "../../../../StatusConst";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { Tooltip } from "@mui/material";
import * as proAndSubServices from "../../../Services/activities-services.proxy";
const getProjectList = proAndSubServices.getProjectList;

const getDocumentsData = saasServices.getDocumentsData;
const getDocumentFilters = saasServices.getDocumentFilters;
const changeApprovalStatus = saasServices.changeApprovalStatus;
const unarchiveDocument = saasServices.unarchiveDocument;

const DRAFT = constStatus.DRAFT;
// const APPROVED = constStatus.APPROVED;
// const APPROVED_AS_NOTED = constStatus.APPROVED_AS_NOTED;
// const REVISE_AND_RESUBMIT = constStatus.REVISE_AND_RESUBMIT;
const REJECTED = constStatus.REJECTED;
const ARCHIVED_DOCUMENT = constStatus.ARCHIVED_DOCUMENT;

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 10px;
  box-sizing: border-box;
  min-height: 32px;
  width: 9rem;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  margin: 0.5em;
  padding: 10px;
  text-align: left;
  line-height: 1.5;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};

  &:hover {
    background: ${theme.palette.mode === "dark" ? "" : grey[100]};
    border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
  font-family: Muli;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0;
  max-height:250px !important;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  overflow: auto;
  outline: 0px;
  `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }
  `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});

function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}

export default function DocumentTable(props) {
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  const location = useLocation();
  const [disabled, setDisabled] = useState(false);
  const AccountType = localStorage.getItem("AccountType");
  const [documentTable, setDocumentTable] = useState([]);
  const [dataLength, setdataLength] = useState();

  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const [pageSize, setPageSize] = useState(25);

  const [discipline, setDiscipline] = useState(0);
  const [disciplines, setDisciplines] = useState([]);

  const [project, setProject] = useState(0);
  const [projects, setProjects] = useState([]);

  const [status, setStatus] = useState(0);
  const [statuses, setStatuses] = useState([]);

  const [approval, setApproval] = useState(0);
  const [approvals, setApprovals] = useState([]);

  const [type, setType] = useState(0);
  const [types, setTypes] = useState([]);

  const [creator, setCreator] = useState(0);
  const [creators, setCreators] = useState([]);

  const [loadingData, setLoadingData] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const RFIID = location.state?.RFIID;
  const [viewRfi, setViewRfi] = useState(false);
  const [raiseRfi, setRaiseRfi] = useState(false);
  useEffect(() => {
    for (let i = 0; i < UserPermissions?.length; i++) {
      for (let j = 0; j < UserPermissions[i].Permissions.length; j++) {
        if (UserPermissions[i].Permissions[j].Name === "RFI") {
          if (
            UserPermissions[i].Permissions[j].Actions.find(
              (a) => a.Name === "View"
            )
          ) {
            setViewRfi(false);
            break;
          } else {
            setViewRfi(true);
          }
        } else {
          setViewRfi(true);
        }
      }
    }
    for (let i = 0; i < UserPermissions?.length; i++) {
      for (let j = 0; j < UserPermissions[i].Permissions.length; j++) {
        if (UserPermissions[i].Permissions[j].Name === "RFI") {
          if (
            UserPermissions[i].Permissions[j].Actions.find(
              (a) => a.Name === "Create"
            )
          ) {
            setRaiseRfi(false);
            break;
          } else {
            setRaiseRfi(true);
          }
        } else {
          setRaiseRfi(true);
        }
      }
    }
  }, [UserPermissions]);

  useEffect(() => {
    let apiTest = true;
    setLoadingData(true);
    getDocumentFilters().then((x) => {
      if (apiTest) {
        setDisciplines(x.Discipline);
        setStatuses(x.Status);
        setApprovals(x.ApprovalStatus);
        setCreators(x.Creators);
        setTypes(x.Types);
        setLoadingData(false);
      }
    });
    getProjectList().then((x) => {
      setProjects(x);
    });
    return () => {
      apiTest = false;
    };
  }, []);

  const navigate = useNavigate();
  const listData = {
    Pagination: {
      PageSize: pageSize,
      PageNumber: pageNumber,
    },
    Search: searchText,
    SortList: [],
    DropDownFilter: {
      ProjectId: project === 0 ? null : project,
      DisciplineId: discipline === 0 ? null : discipline,
      StatusId: status === 0 ? null : status,
      ApprovalStatusId: approval === 0 ? null : approval,
      CreatorId: creator === 0 ? null : creator,
      Type: type === 0 ? null : type,
    },
    RFIId: RFIID ? RFIID : null,
  };

  useEffect(() => {
    let apiTest = true;
    getDocumentsData(listData).then((x) => {
      if (apiTest) {
        setDocumentTable(x.Documents);
        setdataLength(x.Total);
        //  setLoading(false)
      }
    });
    return () => {
      apiTest = false;
    };
  }, [
    pageSize,
    pageNumber,
    searchText,
    props.modify,
    project,
    discipline,
    status,
    approval,
    creator,
    type,
  ]);
  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowData, setRowData] = useState();

  const handleClick = async (event, params) => {
    setRowData(params);

    setAnchorEl(event.target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const columns = [
    { field: "Id", hide: true, hideable: false },
    {
      field: "action",
      headerName: "Actions",
      renderCell: (params) => {
        return (
          <Grid container>
            <img
              src={MenuPic}
              alt="menu"
              onClick={(e) => handleClick(e, params)}
            ></img>
          </Grid>
        );
      },
    },
    {
      field: "Name",
      width: 250,
      renderCell: (params) => (
        <Grid
          style={{
            color:
              params.row.ApprovalStatusId === ARCHIVED_DOCUMENT
                ? "grey"
                : "#0674b9",
          }}
        >
          {params.row?.Name}
        </Grid>
      ),
    },
    {
      field: "Number",
      headerName: "No.",
      width: 120,
      resizeable: true,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.ApprovalStatusId === ARCHIVED_DOCUMENT
                ? "grey"
                : "#0674b9",
          }}
        >
          {params.row?.Number}
        </span>
      ),
    },
    {
      field: "Discipline",
      headerName: "Discipline",
      width: 200,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.ApprovalStatusId === ARCHIVED_DOCUMENT
                ? "grey"
                : "#0674b9",
          }}
        >
          {params.row?.Discipline}
        </span>
      ),
    },

    {
      field: "Project",
      headerName: "Project",
      width: 250,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.ApprovalStatusId === ARCHIVED_DOCUMENT
                ? "grey"
                : "#0674b9",
          }}
        >
          {params.row?.Project}
        </span>
      ),
    },
    {
      field: "Phase",
      headerName: "Phase",
      width: 250,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.ApprovalStatusId === ARCHIVED_DOCUMENT
                ? "grey"
                : "#0674b9",
          }}
        >
          {params.row?.Phase}
        </span>
      ),
    },
    {
      field: "SubProject",
      headerName: "Subproject",
      width: 250,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.ApprovalStatusId === ARCHIVED_DOCUMENT
                ? "grey"
                : "#0674b9",
          }}
        >
          {params.row?.SubProject}
        </span>
      ),
    },

    {
      field: "Status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.ApprovalStatusId === ARCHIVED_DOCUMENT ||
              params.row.Status === "Draft"
                ? "grey"
                : "#0674b9",
          }}
        >
          {params.row?.Status}
        </span>
      ),
    },
    {
      field: "ApprovalStatus",
      headerName: "Approval Status",
      width: 150,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.ApprovalStatusId === ARCHIVED_DOCUMENT ||
              params.row.ApprovalStatusId === DRAFT
                ? "grey"
                : params.row.ApprovalStatusId === REJECTED
                ? "red"
                : "green",
          }}
        >
          {params.row?.ApprovalStatus}
        </span>
      ),
    },

    {
      field: "Type",
      headerName: "Type",
      width: 130,
      sortable: false,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.ApprovalStatusId === ARCHIVED_DOCUMENT
                ? "grey"
                : "#0674b9",
          }}
        >
          {params.row?.File.Extension}
        </span>
      ),
    },
    {
      field: "Size",
      headerName: "Size",
      width: 90,
      sortable: false,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.ApprovalStatusId === ARCHIVED_DOCUMENT
                ? "grey"
                : "#0674b9",
          }}
        >
          {bytesToSize(params.row.File.Size)}
        </span>
      ),
    },
    {
      field: "Version",
      headerName: "Version",
      width: 70,
      resizeable: true,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.ApprovalStatusId === ARCHIVED_DOCUMENT
                ? "grey"
                : "#0674b9",
          }}
        >
          {params.row?.Version}
        </span>
      ),
    },
    {
      field: "RevisionNumber",
      headerName: "Revision No.",
      width: 100,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.ApprovalStatusId === ARCHIVED_DOCUMENT
                ? "grey"
                : "#0674b9",
          }}
        >
          {params.row?.RevisionNumber}
        </span>
      ),
    },
    {
      field: "RevisionDate",
      headerName: "Revision Date",
      width: 150,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.ApprovalStatusId === ARCHIVED_DOCUMENT
                ? "grey"
                : "#0674b9",
          }}
        >
          {params.row?.RevisionDate.slice(0, 10)}
        </span>
      ),
    },
    {
      field: "File",
      headerName: "File",
      width: 90,
      sortable: false,
      renderCell: (params) => (
        <img
          src={
            params.row.File && params.row.ApprovalStatusId !== ARCHIVED_DOCUMENT
              ? AccountType === "1"
                ? fileimg
                : FileSubImg
              : noFileimg
          }
          style={{ width: "20%" }}
          alt={"null"}
        />
      ),
    },
    {
      field: "CreationDate",
      headerName: "Upload Date",
      width: 150,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.ApprovalStatusId === ARCHIVED_DOCUMENT
                ? "grey"
                : "#0674b9",
          }}
        >
          {params.row?.CreationDate.slice(0, 10)}
        </span>
      ),
    },
    {
      field: "Creator",
      headerName: "Creator",
      width: 150,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.ApprovalStatusId === ARCHIVED_DOCUMENT
                ? "grey"
                : "#0674b9",
          }}
        >
          {params.row?.Creator}
        </span>
      ),
    },
  ];
  return (
    <Grid container>
      {disabled ? (
        <Grid
          item
          container
          bgcolor={"rgba(225, 227, 25, 0.5)"}
          xs={10}
          marginLeft="5%"
          padding={1}
        >
          <Grid item md={4}></Grid>
          <Grid
            item
            md={6}
            style={{
              fontFamily: "Muli",
              fontSize: "17px",
              fontWeight: "bold",
              textAlign: "left",
              color: "#101650",
            }}
          >
            <Grid>
              {localStorage.getItem("isTeamMember") === "true" &&
              !UserPermissions?.find((x) => x.Name === "Project Manager") &&
              !UserPermissions?.find((x) => x.Name === "Admin") &&
              !UserPermissions?.find((x) => x.Name === "Engineering Manager") &&
              !UserPermissions?.find((x) => x.Name === "Document Controller")
                ? "You don't have permission to create/edit or archive/restore a document"
                : ""}
            </Grid>
          </Grid>

          <Grid item md={2} container>
            <Grid item xs={8}></Grid>
            <Grid
              item
              paddingLeft={1}
              onClick={() => {
                setDisabled(false);
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
            >
              <CloseIcon fontSize="medium" />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <Grid item container direction={"row"} md={12} paddingBottom={2}>
        <Grid item lg={2} md={4}>
          <span className={classes["Table-Title"]}>Documents</span>
        </Grid>

        <Grid item lg={9.7} container direction={"row-reverse"} gap={2}>
          <button
            className={classes["AddButton"]}
            disabled={
              localStorage.getItem("IsSubscribedBefore") === "true"
                ? true
                : false
            }
            style={{ marginLeft: 0 }}
            onClick={() => {
              if (
                localStorage.getItem("isTeamMember") === "true" &&
                !UserPermissions?.find((x) => x.Name === "Project Manager") &&
                !UserPermissions?.find((x) => x.Name === "Admin") &&
                !UserPermissions?.find(
                  (x) => x.Name === "Engineering Manager"
                ) &&
                !UserPermissions?.find((x) => x.Name === "Document Controller")
              )
                for (let i = 0; i < UserPermissions?.length; i++) {
                  for (
                    let j = 0;
                    j < UserPermissions[i].Permissions.length;
                    j++
                  ) {
                    if (UserPermissions[i].Permissions[j].Name === "Document") {
                      if (
                        UserPermissions[i].Permissions[j].Actions.find(
                          (a) => a.Name === "Create"
                        )
                      ) {
                        props.setRowData(null);
                        props.setOpenModal(true);
                        handleClose();
                        setDisabled(false);
                        break;
                      } else {
                        setDisabled(true);
                      }
                    } else {
                      setDisabled(true);
                    }
                  }
                }
              else {
                props.setRowData(null);
                props.setOpenModal(true);
              }
            }}
          >
            Add
          </button>
          {props.ShowGoBack ? (
            <button
              className={classes["back-button"]}
              onClick={() => {
                navigate(-1);
              }}
            >
              Go back
            </button>
          ) : (
            <></>
          )}
          <BlueTip
            title={"Select Documents in the same project from the table "}
            placement="bottom-start"
            popper
            interactive
          >
            <button
              className={classes["RfiButtons"]}
              disabled={
                raiseRfi ||
                localStorage.getItem("IsSubscribedBefore") === "true"
                  ? true
                  : false
              }
              onClick={() => {
                if (selectionModel.length > 0)
                  navigate("/saas/admin/RFI/create", {
                    state: {
                      documentsChosen: selectionModel,
                      ProjectId: project,
                    },
                  });
              }}
              style={{ marginLeft: 0 }}
            >
              Raise RFI
            </button>
          </BlueTip>
          <button
            disabled={viewRfi}
            className={classes["RfiButtons"]}
            onClick={() => {
              navigate("/saas/admin/RFI/List");
            }}
          >
            View all RFIs
          </button>
        </Grid>
      </Grid>
      {props.viewFilters ? (
        <Grid item md={10} container direction={"row-reverse"}>
          <Grid
            item
            md={12}
            lg={12}
            marginTop={1}
            container
            direction={"row-reverse"}
          >
            <Grid item>
              <CustomSelect
                value={creator}
                onChange={(option) => {
                  setCreator(option);
                }}
              >
                <StyledOption value={0} disabled>
                  Creator
                </StyledOption>
                {creators.map((c) => (
                  <StyledOption key={c.Id} value={c.Id}>
                    {c.Name}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Grid>
            <Grid item>
              <CustomSelect
                value={type}
                onChange={(option) => {
                  setType(option);
                }}
              >
                <StyledOption value={0} disabled>
                  Type
                </StyledOption>
                {types.map((t) => (
                  <StyledOption key={t} value={t}>
                    {t}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Grid>
            <Grid item>
              <CustomSelect
                value={approval}
                onChange={(option) => {
                  setApproval(option);
                }}
              >
                <StyledOption value={0} disabled>
                  Approval Status
                </StyledOption>
                {approvals.map((a) => (
                  <StyledOption key={a.Id} value={a.Id}>
                    {a.Name}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Grid>
            <Grid item>
              <CustomSelect
                value={status}
                onChange={(option) => {
                  setStatus(option);
                }}
              >
                <StyledOption value={0} disabled>
                  Status
                </StyledOption>
                {statuses.map((s) => (
                  <StyledOption key={s.Id} value={s.Id}>
                    {s.Name}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Grid>
            <Grid item>
              <CustomSelect
                value={project}
                style={{ width: "7.5rem" }}
                onChange={(option) => {
                  setProject(option);
                }}
              >
                <StyledOption value={0} disabled>
                  Project Reference
                </StyledOption>
                {projects.map((p) => (
                  <StyledOption key={p.Id} value={p.Id}>
                    {p.Name}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Grid>
            <Grid item>
              <CustomSelect
                value={discipline}
                onChange={(option) => {
                  setDiscipline(option);
                }}
              >
                <StyledOption value={0} disabled>
                  Discipline Reference
                </StyledOption>
                {disciplines.map((d) => (
                  <StyledOption key={d.Id} value={d.Id}>
                    {d.Name}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Grid>
            {project || status || discipline || approval || type || creator ? (
              <Grid item marginTop={0.6}>
                <button
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={() => {
                    setApproval(0);
                    setCreator(0);
                    setType(0);
                    setStatus(0);
                    setProject(0);
                    setDiscipline(0);
                  }}
                  className={classes["Rectangle-1224"]}
                >
                  Clear filters
                </button>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      ) : (
        <></>
      )}

      {props.viewSearch ? (
        <Grid
          item
          md={2}
          container
          direction={"row-reverse"}
          paddingRight={{ md: 4, xs: 0 }}
        >
          <Box
            sx={{
              p: 0.5,
              pb: 0,
            }}
          >
            <TextField
              variant="standard"
              fullWidth
              onChange={inputHandler}
              placeholder="Search this list…"
              InputProps={{
                startAdornment: (
                  <SearchIcon
                    fontSize="small"
                    htmlColor={"#707070"}
                    style={{ paddingLeft: 5 }}
                  />
                ),
              }}
              sx={{
                fontFamily: "Muli",
                backgroundColor: "white",
                borderRadius: 1,
                width: {
                  xs: 1,
                  sm: "auto",
                },
                m: (theme) => theme.spacing(1, 0.5, 1.5),
                "& .MuiSvgIcon-root": {
                  mr: 0.5,
                },
                "& .MuiInput-underline:before": {
                  borderBottom: 0,
                  borderColor: "white",
                },
              }}
            />
          </Box>
        </Grid>
      ) : (
        <></>
      )}

      <div
        style={{
          height: props.tableHeight,
          width: "98%",
          fontFamily: "Muli",
          backgroundColor: "white",
          marginTop: 10,
        }}
      >
        <DataGrid
          disableSelectionOnClick
          checkboxSelection
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);

            const selectedRowData = documentTable.filter((row) =>
              selectedIDs.has(row.Id.toString())
            );
            if (selectedRowData.length < 1) {
              setProject(0);
            }
            if (!project) {
              projects.map((project) =>
                project.Name === selectedRowData[0].Project
                  ? setProject(project.Id)
                  : ""
              );
            }
            setSelectionModel(selectedRowData);
          }}
          initialState={{
            pagination: {
              pageSize: 25,
            },
          }}
          pagination
          paginationMode="server"
          rows={documentTable ? documentTable : []}
          columns={columns}
          headerHeight={40}
          density={"compact"}
          showColumnRightBorder
          sx={{
            [`& .${gridClasses.row}`]: {
              color: "#0674b9",
              overflow: "auto",
              bgcolor: (theme) =>
                theme.palette.mode === "light" ? grey[0] : grey[500],
            },

            [`& .${gridClasses.columnHeader}`]: {
              bgcolor: "rgb(172 201 224)",
              color: "rgb(17 24 84)",
            },
            [`& .${gridClasses.columnHeaderTitle}`]: {
              fontFamily: "Muli",
              fontWeight: "bold",
            },

            [`& .${gridClasses.footerContainer}`]: {
              bgcolor: "rgb(216 235 247)",
              minHeight: "30px",
            },
            [`& .${gridClasses.columnHeaderCheckbox}`]: {
              display: "inherit",
              pointerEvents: "none",
            },
            [`& .${gridClasses.checkboxInput}`]: {
              color: "rgb(172 201 224)",
            },
            fontFamily: "Muli",
            fontSize: "13px",
          }}
          disableColumnFilter
          getRowId={(row) => row.Id}
          rowCount={dataLength}
          hideFooter={props.paging}
          onPageChange={(newPage) => {
            if (pageSize > dataLength) {
              setPageNumber(1);
            } else setPageNumber(newPage + 1);
          }}
          onPageSizeChange={(newPage) => {
            setPageSize(newPage);
          }}
          pageSize={pageSize}
          loading={loadingData}
          components={{
            NoRowsOverlay: () => (
              <Stack className={classes["EmptyMessage"]}>
                No Documents to display.
              </Stack>
            ),
          }}
        />
      </div>
      {props.showViewAll ? (
        <Grid
          item
          xs={11.75}
          container
          bgcolor={"white"}
          justifyContent={"center"}
        >
          <span
            style={{
              color: "#0674b9",
              fontFamily: "Muli",
              fontSize: "13px",
            }}
          >
            View All
          </span>
        </Grid>
      ) : (
        ""
      )}
      {localStorage.getItem("IsSubscribedBefore") === "true" ? (
        ""
      ) : (
        <Menu
          id="more-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          disableScrollLock
        >
          <MenuItem
            onClick={() => {
              if (
                localStorage.getItem("isTeamMember") === "true" &&
                !UserPermissions?.find((x) => x.Name === "Project Manager") &&
                !UserPermissions?.find((x) => x.Name === "Admin") &&
                !UserPermissions?.find(
                  (x) => x.Name === "Engineering Manager"
                ) &&
                !UserPermissions?.find((x) => x.Name === "Document Controller")
              )
                for (let i = 0; i < UserPermissions?.length; i++) {
                  for (
                    let j = 0;
                    j < UserPermissions[i].Permissions.length;
                    j++
                  ) {
                    if (UserPermissions[i].Permissions[j].Name === "Document") {
                      if (
                        UserPermissions[i].Permissions[j].Actions.find(
                          (a) => a.Name === "Edit"
                        )
                      ) {
                        props.setRowData(rowData);
                        props.setOpenModal(true);
                        handleClose();
                        setDisabled(false);
                        break;
                      } else {
                        setDisabled(true);
                      }
                    } else {
                      setDisabled(true);
                    }
                  }
                }
              else {
                props.setRowData(rowData);
                props.setOpenModal(true);
                handleClose();
              }
            }}
            style={{
              fontFamily: "Muli",
              fontSize: "13px",
              color: "#444",
            }}
          >
            Edit
          </MenuItem>
          {rowData?.row.ApprovalStatusId === ARCHIVED_DOCUMENT ? (
            <MenuItem
              onClick={() => {
                if (
                  localStorage.getItem("isTeamMember") === "true" &&
                  !UserPermissions?.find((x) => x.Name === "Project Manager") &&
                  !UserPermissions?.find((x) => x.Name === "Admin") &&
                  !UserPermissions?.find(
                    (x) => x.Name === "Engineering Manager"
                  ) &&
                  !UserPermissions?.find(
                    (x) => x.Name === "Document Controller"
                  )
                )
                  setDisabled(true);
                else {
                  unarchiveDocument(rowData.id).then((x) =>
                    setTimeout(() => {
                      props.setModify(!props.modify);
                    }, 1000)
                  );
                  handleClose();
                }
              }}
              style={{
                fontFamily: "Muli",
                fontSize: "13px",
                color: "#444",
              }}
            >
              Restore
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() => {
                if (
                  localStorage.getItem("isTeamMember") === "true" &&
                  !UserPermissions?.find((x) => x.Name === "Project Manager") &&
                  !UserPermissions?.find((x) => x.Name === "Admin") &&
                  !UserPermissions?.find(
                    (x) => x.Name === "Engineering Manager"
                  ) &&
                  !UserPermissions?.find(
                    (x) => x.Name === "Document Controller"
                  )
                )
                  setDisabled(true);
                else {
                  changeApprovalStatus(rowData.id, ARCHIVED_DOCUMENT).then(
                    setTimeout(() => {
                      props.setModify(!props.modify);
                    }, 1000)
                  );
                  handleClose();
                }
              }}
              style={{
                fontFamily: "Muli",
                fontSize: "13px",
                color: "#444",
              }}
            >
              Archive
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              window.open(rowData.row.File.URL);
              handleClose();
            }}
            style={{
              fontFamily: "Muli",
              fontSize: "13px",
              color: "#444",
            }}
          >
            Download Document
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/saas/admin/RFI/List", {
                state: {
                  documentID: rowData.id,
                },
              });
              handleClose();
            }}
            style={{
              fontFamily: "Muli",
              fontSize: "13px",
              color: "#444",
            }}
          >
            View Document RFIS
          </MenuItem>
        </Menu>
      )}
    </Grid>
  );
}
const BlueTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
      color: #fff;
      background-color: rgba(6, 116, 185, 0.3);
      font-size: 13px;
      width:200px;
   
  `);
