import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import BayCardImg from "../../../Assets/Images/Factory/Bay.png";
import PlusIcon from "../../../Assets/Images/Factory/plusIcon.png";
import handlingBlockIcon from "../../../Assets/Images/Factory/handlingBlock.png";
import handlingBlockIconYellow from "../../../Assets/Images/Factory/handlingBlockYellow.png";
import machineBlockIcon from "../../../Assets/Images/Factory/machineBlock.png";
import machineBlockIconYellow from "../../../Assets/Images/Factory/machineBlockYellow.png";
import storageBlockIcon from "../../../Assets/Images/Factory/storageBlock.png";
import storageBlockIconYellow from "../../../Assets/Images/Factory/storageBlockYellow.png";
import { useNavigate, useLocation } from "react-router-dom";
import classing from "../../../Assets/Styles/factory.module.css";
import { useSelector } from "react-redux";
import * as permissionFunctions from "../../../../ReusableFunctions";

const CheckIfPermitted = permissionFunctions.CheckIfPermitted;
const BayCard = (props) => {
  const navigate = useNavigate();
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  const [disabledRoutes, setDisabledRoutes] = useState(false);
  const [disabledAddHb, setDisabledAddHb] = useState(false);
  const [disabledAddMB, setDisabledAddMB] = useState(false);
  const [disabledAddSB, setDisabledAddSB] = useState(false);

  useEffect(() => {
    if (UserPermissions) {
      CheckIfPermitted(UserPermissions, "Route", "View", setDisabledRoutes);

      CheckIfPermitted(
        UserPermissions,
        "Handling Block",
        "Create",
        setDisabledAddHb
      );
      CheckIfPermitted(
        UserPermissions,
        "Machine Block",
        "Create",
        setDisabledAddMB
      );
      CheckIfPermitted(
        UserPermissions,
        "Storage Block",
        "Create",
        setDisabledAddSB
      );
    }
  }, [UserPermissions]);
  return (
    <Grid
      width={130}
      height={305}
      container
      style={{ backgroundImage: `url(${BayCardImg})`, backgroundSize: "cover" }}
      paddingTop={3}
      paddingLeft={2.5}
    >
      <Grid item className={classing["BayCode"]} paddingLeft={0.5}>
        Bay #{props.index + 1}
      </Grid>
      <Grid item container gap={0.3} direction={"row"} paddingTop={3.5}>
        <Grid item>
          <img
            src={storageBlockIcon}
            width={41}
            alt={"storage"}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
              e.target.src = storageBlockIconYellow;
            }}
            onMouseOut={(e) => {
              e.target.src = storageBlockIcon;
            }}
            onClick={() => {
              navigate(
                `/saas/blocksList?BayId=${props.data.Id}&BayNb=${
                  props.index + 1
                }&FactoryId=${props.factoryId}&blockType=1`
              );
            }}
          />
        </Grid>
        <Grid
          item
          paddingTop={1}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
          }}
          onClick={() => {
            if (!disabledAddSB)
              navigate(
                `/saas/factory/bay/storageBlock?id=${props.data.Id}&bayNb=${
                  props.index + 1
                }`
              );
          }}
        >
          <img src={PlusIcon} width={10} />
        </Grid>

        <Grid
          item
          paddingTop={1}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.color = "#101650";
          }}
          onMouseOut={(e) => {
            e.target.style.color = "#707070";
          }}
          onClick={() => {
            navigate(
              `/saas/blocksList?BayId=${props.data.Id}&BayNb=${
                props.index + 1
              }&FactoryId=${props.factoryId}&blockType=1`
            );
          }}
        >
          <Grid item className={classing["BlockTitle"]}>
            Storage
          </Grid>
          <Grid item container className={classing["BlockTitle2"]}>
            Block
            <Grid item className={classing["Rectangle-3010"]} marginLeft={0.5}>
              {+" " + props.data.StorageBlocksNumber}
            </Grid>
          </Grid>
          <Grid item className={classing["Resources"]} paddingTop={0.5}>
            Human Resources: {props.data.HumanResourcesSBNumber}
          </Grid>
          <Grid item className={classing["Resources"]} paddingTop={1}>
            Equipment Resources: {props.data.EquipmentResourcesSBNumber}
          </Grid>
        </Grid>
      </Grid>

      <Grid item container gap={0.3} direction={"row"}>
        <Grid item>
          <img
            src={machineBlockIcon}
            width={41}
            alt={"machine"}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
              e.target.src = machineBlockIconYellow;
            }}
            onMouseOut={(e) => {
              e.target.src = machineBlockIcon;
            }}
            onClick={() => {
              navigate(
                `/saas/blocksList?BayId=${props.data.Id}&BayNb=${
                  props.index + 1
                }&FactoryId=${props.factoryId}&blockType=2`
              );
            }}
          />
        </Grid>
        <Grid
          item
          paddingTop={1}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
          }}
          onClick={() => {
            if (!disabledAddMB)
              navigate(
                `/saas/factory/bay/machineBlock?id=${props.data.Id}&bayNb=${
                  props.index + 1
                }`
              );
          }}
        >
          <img src={PlusIcon} width={10} />
        </Grid>

        <Grid
          item
          paddingTop={1}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.color = "#101650";
          }}
          onMouseOut={(e) => {
            e.target.style.color = "#707070";
          }}
          onClick={() => {
            navigate(
              `/saas/blocksList?BayId=${props.data.Id}&BayNb=${
                props.index + 1
              }&FactoryId=${props.factoryId}&blockType=1`
            );
          }}
        >
          <Grid item className={classing["BlockTitle"]}>
            Machine
          </Grid>
          <Grid item container className={classing["BlockTitle2"]}>
            Block
            <Grid item className={classing["Rectangle-3010"]} marginLeft={0.5}>
              {+" " + props.data.MachineBlocksNumber}
            </Grid>
          </Grid>
          <Grid item className={classing["Resources"]} paddingTop={0.5}>
            Human Resources: {props.data.HumanResourcesMBNumber}
          </Grid>
          <Grid item className={classing["Resources"]} paddingTop={1}>
            Equipment Resources: {props.data.EquipmentResourcesMBNumber}
          </Grid>
        </Grid>
      </Grid>
      <Grid item container gap={0.3} direction={"row"}>
        <Grid item>
          <img
            src={handlingBlockIcon}
            width={41}
            alt={"handling"}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
              e.target.src = handlingBlockIconYellow;
            }}
            onMouseOut={(e) => {
              e.target.src = handlingBlockIcon;
            }}
            onClick={() => {
              navigate(
                `/saas/blocksList?BayId=${props.data.Id}&BayNb=${
                  props.index + 1
                }&FactoryId=${props.factoryId}&blockType=3`
              );
            }}
          />
        </Grid>
        <Grid
          item
          paddingTop={1}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
          }}
          onClick={() => {
            if (!disabledAddHb)
              navigate(
                `/saas/factory/bay/handlingBlock?id=${props.data.Id}&bayNb=${
                  props.index + 1
                }`
              );
          }}
        >
          <img src={PlusIcon} width={10} />
        </Grid>

        <Grid
          item
          paddingTop={1}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.color = "#101650";
          }}
          onMouseOut={(e) => {
            e.target.style.color = "#707070";
          }}
           onClick={() => {
            navigate(
              `/saas/blocksList?BayId=${props.data.Id}&BayNb=${
                props.index + 1
              }&FactoryId=${props.factoryId}&blockType=1`
            );
          }}
        >
          <Grid item className={classing["BlockTitle"]}>
            Handling
          </Grid>
          <Grid item container className={classing["BlockTitle2"]}>
            Block
            <Grid item className={classing["Rectangle-3010"]} marginLeft={0.5}>
              {+" " + props.data.HandlingBlocksNumber}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction={"row-reverse"}
        paddingRight={1.2}
        gap={0.5}
        onMouseOver={(e) => (e.target.style.cursor = "pointer")}
        onClick={() => {
          if (!disabledRoutes)
            navigate(
              `/saas/factory/route/create?id=${props.data.Id}&number=${
                props.index + 1
              }`
            );
        }}
      >
        <Grid item className={classing["Plus"]}>
          +
        </Grid>
        <Grid item className={classing["Routes"]} paddingTop={0.5}>
          Routes
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BayCard;
