import { Backdrop, CircularProgress, Grid, Tooltip } from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import homeBg from "../../Assets/Images/Dashboard/logInBgColor.png";
import classes from "../../Assets/Styles/Dashboard/Production.module.css";
import printIcon from "../../Assets/Images/Dashboard/print-icon.png";
import DashNavBar from "./DashNavBar";
import * as services from "../../Services/machine-dashboard-services.proxy";
import Modal from "@mui/material/Modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import back from "../../Assets/Images/Dashboard/back1.png";
import ErrorModal from "../../SharedComponents/ErrorModal";
import InfoIcon from '@mui/icons-material/Info';

const getNextCycleBundle = services.getNextCycleBundle;
const updateUsedBundle = services.updateUsedBundle;
const getCycleSummary = services.getCycleSummary;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "500px",
  transform: "translate(-50%, -50%)",
  p: 4,
  borderRadius: "12px",
  border: "solid 1px #707070",
  backgroundColor: "#0674b9",
  outline: "none",
};

const tableHeadStyle = {
  fontFamily: "Muli",
  fontSize: "12px",
  fontWeight: "bold",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "1.08",
  letterSpacing: "normal",
  textAlign: "center",
  color: "#000",
  padding: "5px",
  backgroundColor: "white",
  border: 0,
};

const rowStyle = {
  fontFamily: "Muli",
  fontSize: "14px",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "1.29",
  letterSpacing: "normal",
  textAlign: "center",
  color: "#101650",
  padding: "5px",
  backgroundColor: "white",
};

const producedTextStyle = {
  fontFamily: "Muli",
  fontSize: "14px",
  fontWeight: 600,
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "1.29",
  letterSpacing: "normal",
  textAlign: "center",
  color: "#101650",
  padding: "5px",
  backgroundColor: "white",
};

export default function ProductionFlow(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const groupId = query.get("groupId");

  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);

  const [cycle, setCycle] = useState(null);

  const [summary, setSummary] = useState(null);

  const [groupFinished, setGroupFinished] = useState(false);

  const [notAllPrintedOrAssigned, setNotAllPrintedOrAssigned] = useState(false);

  Array.prototype.sum = function (prop) {
    var total = 0;
    for (var i = 0, _len = this.length; i < _len; i++) {
      total += +this[i][prop];
    }
    return total;
  };

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const [loadingData, setLoadingData] = useState(false);
  const [isLine, setIsLine] = useState(false);

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [BEerror, setBEerror] = useState(null);

  const [openRemainingTooltip, setOpenRemainingTooltip] = useState(false);
  const [openGeneratedTooltip, setOpenGeneratedTooltip] = useState(false);
  const [openLineTooltip, setOpenLineTooltip] = useState(false);
  const [lineIndex, setLineIndex] = useState(null);


  useEffect(() => {
    let apiTest = true;
    setLoadingData(true);
    getCycleSummary(
      null,
      groupId,
      localStorage.getItem("LineBarcode")
        ? localStorage.getItem("LineBarcode")
        : null
    ).then((x) => {
      setLoadingData(false);
      if (apiTest) {
        if (x.status) {
          setBEerror(x.error)
          setOpenErrorModal(true);
        }
        else {
          setCycle(x);
          setSummary(x.Summary);
          if (!x.Summary) setIsLine(true);
          if (x?.Lines) {
            x?.Lines?.map((l) => {
              if (!l.IsPrinted && l.IsTerminated) setNotAllPrintedOrAssigned(true)
            })
          }
        }

      }
    });
    return () => {
      apiTest = false;
    };
  }, []);

  return (
    <>
      <Grid
        sx={{
          padding: "2% 3.5% 0 3.5%",
          backgroundImage: `url(${homeBg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          paddingBottom: "10%",
          minHeight: "100vh",
        }}
      >
        <DashNavBar />
        <Grid item container padding="4% 0% 0% 4%">
          <Backdrop
            sx={{
              color: "#fff",
              backgroundColor: "transparent",
              zIndex: (theme) => theme.zIndex.drawer + 1,
              display: "flex",
              flexDirection: "column",
            }}
            open={loadingData}
          >
            <CircularProgress />
            <Grid
              item
              fontFamily={"Muli"}
              fontSize={15}
              fontWeight={"bold"}
              color={"rgb(25, 118, 210)"}
            >
              Loading Data...
            </Grid>
          </Backdrop>
          <Grid
            item
            xs={12}

          >
            <img
              onMouseOver={(e) => (e.target.style.cursor = "pointer")}
              onClick={() => navigate("/machine/dashboard")}
              src={back} alt="back" />
          </Grid>
          <Grid
            item
            container
            xs={12}
            className={classes["Production-Code"]}
            alignItems="center"
            gap={3}
          >
            <Grid item>Production Code</Grid>
          </Grid>

          {/* blue box */}
          {localStorage.getItem("Category") !== "Line" ? (
            <Grid
              item
              container
              xs={11}
              lg={9}
              xl={8}
              marginTop={3}
              gap={0.6}
              className={classes["Rectangle-4045"]}
            >
              <Grid item container xs={12} alignItems="center">
                <Grid item xs={9}>
                  Material Initial Quantity :
                  {summary?.TypeId === 2 ? "Coil" : "Bar"}
                </Grid>
                <Grid
                  item
                  container
                  xs={3}
                  direction="row-reverse"
                  alignItems="center"
                >
                  <Grid item width="48px"></Grid>
                  <Grid item>
                    {summary?.MaterialInitialQuantity} Pcs &nbsp;
                    {summary?.MaterialInitialWeight?.toFixed(2)} kgs
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container xs={12}>
                <Grid item xs={9}>
                  Used Material Quantity :{" "}
                  {summary?.TypeId === 2 ? "Coil" : "Bar"}
                </Grid>
                <Grid item container xs={3} direction="row-reverse">
                  <Grid item width="48px"></Grid>
                  <Grid item>
                    {summary?.UsedMaterialQuantity} Pcs &nbsp;
                    {summary?.UsedMaterialWeight?.toFixed(2)} kgs
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container xs={12} alignItems="center">
                <Grid item xs={7}>
                  Remaining Material : {summary?.TypeId === 2 ? "Coil" : "Bar"}
                </Grid>
                <Grid
                  item
                  container
                  xs={5}
                  direction="row-reverse"
                  alignItems="center"
                >
                  {!summary?.RemainingRemnantQuantity &&
                    !summary?.RemainingRMQuantity ? (
                    <Grid item width="48px"></Grid>
                  ) : (
                    <>
                      {cycle.IsRemainingPrinted ?
                        <Grid item fontSize="12px">
                          <Tooltip
                            onClose={() => setOpenRemainingTooltip(false)}
                            open={openRemainingTooltip}
                            title="Already Printed"

                          >
                            <InfoIcon onClick={() => setOpenRemainingTooltip(!openRemainingTooltip)} style={{ cursor: "pointer", width: "18px", height: "18px" }} />
                          </Tooltip></Grid>
                        :
                        <></>}

                      <Grid item paddingTop="3.5%">
                        {/* <Tooltip title={cycle.IsRemainingPrinted ? "Already printed" : ""} placement="right"> */}
                        <Grid item container direction="row" alignItems="center">
                          <img
                            src={printIcon}
                            onClick={() => {
                              if (!cycle.IsRemainingPrinted) {
                                localStorage.setItem(
                                  "RemainingBundleId",
                                  summary?.BundleId
                                );
                                navigate(
                                  `/machine/print?CycleId=${cycle?.CycleId}&BundleId=${summary?.BundleId
                                  }&CategoryId=${1}`
                                );
                              }

                            }}
                            width="48px"
                            height="42px"
                            alt="print icon"
                            style={{ cursor: !cycle.IsRemainingPrinted ? "pointer" : "unset" }}
                          />
                        </Grid>

                        {/* </Tooltip> */}

                      </Grid>
                    </>
                  )}

                  <Grid item>
                    {summary?.RemainingRemnantQuantity
                      ? summary?.RemainingRemnantQuantity
                      : summary?.RemainingRMQuantity
                        ? summary?.RemainingRMQuantity
                        : 0}
                    Pcs &nbsp;
                    {summary?.RemainingRemnantWeight
                      ? summary?.RemainingRemnantWeight?.toFixed(2)
                      : summary?.RemainingRMWeight
                        ? summary?.RemainingRMWeight?.toFixed(2)
                        : 0}
                    kgs
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={7}>
                  Generated Material<span style={{ fontSize: "12px" }}> (Remnant/Offcut)</span> :
                </Grid>
                <Grid
                  item
                  container
                  xs={5}
                  direction="row-reverse"
                  alignItems="center"
                >
                  {!summary?.GeneratedMaterialQuantity ? (
                    <Grid item width="48px"></Grid>
                  ) : (
                    <>
                      {cycle.IsRemnantPrinted ?
                        <Grid item fontSize="12px">
                          <Tooltip
                            onClose={() => setOpenGeneratedTooltip(false)}
                            open={openGeneratedTooltip}
                            title="Already Printed"
                          >
                            <InfoIcon onClick={() => setOpenGeneratedTooltip(!openGeneratedTooltip)} style={{ cursor: "pointer", width: "18px", height: "18px" }} />
                          </Tooltip>
                        </Grid>
                        :
                        <></>}
                      <Grid item paddingTop="3.5%">
                        {/* <Tooltip title={cycle.IsRemnantPrinted ? "Already printed" : ""} placement="right"> */}

                        <img
                          src={printIcon}
                          onClick={() => {
                            if (!cycle.IsRemnantPrinted) {
                              localStorage.setItem("RemnantId", cycle?.CycleId);
                              localStorage.setItem("GenRemenant", "true");
                              navigate(
                                `/machine/print?CycleId=${cycle?.CycleId}&BundleId=${summary?.BundleId
                                }&CategoryId=${2}`
                              );
                            }

                          }}
                          width="48px"
                          height="42px"
                          alt="print icon"
                          style={{ cursor: !cycle.IsRemnantPrinted ? "pointer" : "unset" }}
                        />
                        {/* </Tooltip> */}
                      </Grid>
                    </>
                  )}

                  <Grid item>
                    {summary?.GeneratedMaterialQuantity
                      ? summary?.GeneratedMaterialQuantity
                      : 0}
                    Pcs &nbsp;
                    {summary?.GeneratedMaterialWeight
                      ? summary?.GeneratedMaterialWeight?.toFixed(2)
                      : 0}
                    Kgs
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={9}>
                  Generated Scrap
                </Grid>
                <Grid item container xs={3} direction="row-reverse">
                  <Grid item width="48px"></Grid>
                  <Grid item>
                    {/* {(summary?.GeneratedScapWeight * cycle?.Repititions)?.toFixed(
                      2
                    )} */}
                    {(summary?.GeneratedScapWeight)?.toFixed(2)}
                    kgs
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            ""
          )}

          {/* header box */}
          <Grid item container xs={12} lg={10} xl={9} marginTop={1.5}>
            <TableContainer
              sx={{
                "&::-webkit-scrollbar": {
                  marginLeft: 10,
                  width: 6,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                  boxShadow: "0 0",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "rgba(225, 227, 25, 0.5)",
                  borderRadius: 21,
                  boxShadow: "0 0",
                },
              }}
              style={{ backgroundColor: "transparent", boxShadow: "0 0 " }}
              component={Paper}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{ minWidth: "120px", maxWidth: "180px" }}
                      sx={{ padding: "0px", border: 0 }}
                    >
                      <Grid item container className={classes["Rectangle-40"]}>
                        <Grid item xs={11.5}>
                          Diameter: {cycle?.Diameter}
                        </Grid>
                        <Grid item xs={11.5}>
                          Standard: {cycle?.Standard}
                        </Grid>
                        <Grid item xs={11.5}>
                          Grade: {cycle?.Grade}
                        </Grid>
                        <Grid item xs={11.5}>
                          Coating: {cycle?.Coating}
                        </Grid>
                      </Grid>
                    </TableCell>

                    <TableCell align="center" sx={tableHeadStyle} />

                    <TableCell align="center" sx={tableHeadStyle}>
                      SHAPE <br></br>
                      LENGTH
                      <br />
                      (mm.)
                    </TableCell>

                    <TableCell
                      align="center"
                      style={{ minWidth: "70px" }}
                      sx={tableHeadStyle}
                    >
                      TOTAL NO. <br></br>
                      OF BARS
                      <br />
                      (Pcs.)
                    </TableCell>
                    <TableCell align="center" sx={tableHeadStyle}>
                      WEIGHT
                      <br />
                      (kg.)
                    </TableCell>
                    <TableCell align="center" sx={tableHeadStyle}>
                      PRODUCTION <br></br>
                      DURATION
                      <br />
                      (min.)
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        borderTopRightRadius: "8px",
                        borderBottomRightRadius: "8px",
                        border: 0,
                      }}
                      sx={tableHeadStyle}
                    >
                      STATUS
                    </TableCell>

                    <TableCell align="center" sx={{ border: 0 }} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{ height: "15px", padding: 0, border: 0 }}
                    ></TableCell>
                  </TableRow>
                  {cycle?.Lines?.map((line, index) => (
                    <TableRow key={index}>
                      <TableCell align="center" sx={{ border: 0 }} />
                      <TableCell
                        align="center"
                        sx={{
                          padding: "5px 0px 5px 0px",
                          backgroundColor: "white",
                          borderTopLeftRadius: index === 0 ? "8px" : "0px",
                          borderBottomLeftRadius:
                            index === cycle?.Lines.length - 1 ? "8px" : "0px",
                        }}
                      >
                        <img
                          src={line.ShapeUrl}
                          alt="shape"
                          width="220px"
                          height="80px"
                        />
                      </TableCell>
                      <TableCell align="center" sx={rowStyle}>
                        {line.Length}
                      </TableCell>
                      <TableCell align="center" sx={rowStyle}>
                        {line.NumOfBars * cycle?.Repititions}
                      </TableCell>
                      <TableCell align="center" sx={rowStyle}>
                        {line.Weight}
                      </TableCell>
                      <TableCell align="center" sx={rowStyle}>
                        {cycle?.Duration?.toFixed(2)}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={producedTextStyle}
                        style={{
                          borderTopRightRadius: index === 0 ? "8px" : "0px",
                          borderBottomRightRadius:
                            index === cycle?.Lines.length - 1 ? "8px" : "0px",
                        }}
                      >
                        {line.IsTerminated ? "PRODUCED" : "IN PRODUCTION"}
                      </TableCell>
                      {line.IsTerminated === true ? (
                        <TableCell
                          align="center"
                          sx={{
                            padding: "5px",
                            backgroundColor: "transparent",
                            border: 0,
                          }}
                        >
                          {isLine ? (
                            <Grid item container direction="row" alignItems="center">
                              {/* <Tooltip title={line.IsPrinted ? "Already Assigned" : ""} placement="right"> */}
                              <Grid
                                item
                                fontSize={15}
                                fontFamily={"Muli"}
                                border={"solid 2px #101650"}
                                padding={1}
                                fontWeight={"bold"}
                                onMouseOver={(e) => {
                                  if (!line.IsPrinted) e.target.style.cursor = "pointer";
                                  else e.target.style.cursor = "unset";
                                }}
                                onClick={() => {
                                  if (!line.IsPrinted) {
                                    navigate(
                                      `/machine/print?CycleId=${cycle?.CycleId}&lineId=${line.LineId}&BundleId=${summary?.BundleId}&IsLine=${isLine}`
                                    );
                                  }
                                }
                                }
                              >
                                Assign Block
                              </Grid>
                              <span style={{ fontSize: "12px", marginLeft: "8px" }}>{line.IsPrinted ?
                                <Tooltip
                                  onClose={() => setOpenLineTooltip(false)}
                                  open={openLineTooltip && lineIndex === index}
                                  title="Already Assigned"

                                >
                                  <InfoIcon onClick={() => {
                                    setLineIndex(index)
                                    setOpenLineTooltip(!openLineTooltip)
                                  }} style={{ cursor: "pointer", width: "18px", height: "18px" }} />
                                </Tooltip> : ""
                              }</span>
                              {/* </Tooltip> */}
                            </Grid>
                          ) : (
                            <Grid item container direction="row" alignItems="center">
                              {/* <Tooltip title={line.IsPrinted ? "Already printed" : ""} placement="right"> */}
                              <img
                                src={printIcon}
                                onClick={() => {
                                  if (!line.IsPrinted) {
                                    navigate(
                                      `/machine/print?CycleId=${cycle?.CycleId}&lineId=${line.LineId}&BundleId=${summary?.BundleId}`
                                    );
                                  }

                                }
                                }
                                width="48px"
                                height="42px"
                                alt="print icon"
                                style={{ cursor: !line.IsPrinted ? "pointer" : "unset" }}
                              />
                              {/* </Tooltip> */}
                              <span style={{ fontSize: "12px" }}> {line.IsPrinted ?
                                <Tooltip
                                  onClose={() => setOpenLineTooltip(false)}
                                  open={openLineTooltip && lineIndex === index}
                                  title="Already Printed"

                                >
                                  <InfoIcon onClick={() => {
                                    setLineIndex(index)
                                    setOpenLineTooltip(!openLineTooltip)
                                  }} style={{ cursor: "pointer", width: "18px", height: "18px" }} />
                                </Tooltip> : ""
                              }</span>

                            </Grid>
                          )}
                        </TableCell>
                      ) : (
                        ""
                      )}
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{ height: "15px", padding: 0, border: 0 }}
                    ></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="center" sx={{ border: 0 }} />
                    <TableCell
                      align="left"
                      sx={{
                        padding: "5px 0px 5px 20px",
                        backgroundColor: "white",
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                      }}
                    >
                      <Grid item className={classes["total-style"]}>
                        Total
                      </Grid>
                    </TableCell>
                    <TableCell align="center" sx={rowStyle}>
                      {cycle?.Lines?.sum("Length")}
                    </TableCell>
                    <TableCell align="center" sx={rowStyle}>
                      {cycle?.Lines?.sum("NumOfBars") * cycle?.Repititions}
                    </TableCell>
                    <TableCell align="center" sx={rowStyle}>
                      {cycle?.Lines?.sum("Weight").toFixed(2)}
                    </TableCell>
                    <TableCell align="center" sx={rowStyle}>
                      {cycle?.Duration?.toFixed(2)}
                    </TableCell>
                    <TableCell align="center" sx={{ border: 0 }} />
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid
            item
            container
            marginTop={{ xs: 10, lg: 7 }}
            sm={12}
            lg={10}
            xl={9}
            direction="row-reverse"
          >
            <button
              onClick={() => {
                if (groupFinished) {
                  navigate("/machine/dashboard");
                  localStorage.removeItem("RemnantId");
                  localStorage.removeItem("RemainingBundleId");
                } else {
                  setLoading(true);
                  getNextCycleBundle(groupId).then((x) => {
                    if (x.status) {
                      setError(x.error);
                      handleOpenModal();
                    } else if (x === "finished") {
                      setLoading(false);
                      setGroupFinished(true);
                      setError("");
                    } else if (x === "rescan") {
                      {
                        setLoading(false);
                        navigate(`/machine/scanItem?groupId=${groupId}`);
                        localStorage.removeItem("RemnantId");
                        localStorage.removeItem("RemainingBundleId");
                        setError("");
                      }
                    } else {
                      updateUsedBundle(
                        x,
                        groupId,
                        localStorage.getItem("LineBarcode")
                          ? localStorage.getItem("LineBarcode")
                          : null
                      ).then(() => {
                        setLoading(false);
                        navigate(`/machine/production?groupId=${groupId}`);
                        localStorage.removeItem("RemnantId");
                        localStorage.removeItem("RemainingBundleId");
                        setError("");
                      });
                    }
                  });
                }
              }}
              className={classes["close-button"]}
              disabled={loading || notAllPrintedOrAssigned || (summary?.GeneratedMaterialQuantity && !cycle.IsRemnantPrinted) || (summary?.RemainingRMQuantity && !cycle.IsRemainingPrinted)}
            >
              {groupFinished ? "Finish" : loading ? "Continue . . ." : "Continue"}
            </button>
          </Grid>
        </Grid>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            container
            sx={modalStyle}
            justifyContent="center"
            alignItems="center"
            gap={10}
          >
            <Grid item className={classes["Do-you-want-to-reprint"]}>
              {error}
            </Grid>
            <Grid item container justifyContent="center" direction="row" gap={3}>
              <Grid
                item
                onClick={() => {
                  handleCloseModal();
                }}
                className={classes["confirm-button"]}
              >
                Close
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      </Grid>
      <ErrorModal openErrorModal={openErrorModal} setOpenErrorModal={setOpenErrorModal} BEerror={BEerror} />

    </>
  );
}
