import React, { useState, useEffect, useMemo } from "react";
import SaasBack from "../../Assets/Images/SaaSbackground.png";
import classes from "../../Assets/Styles/InventoryManagement.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  Tooltip,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import fileIcon from "../../Assets/Images/Table/file.png";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import deleteYellow from "../../Assets/Images/Inventory/deleteYellow.png";
import plusYellow from "../../Assets/Images/Inventory/plusYellow.png";
import HeatNbModal from "./HeatNbModal";
import * as InventoryManagementServices from "../../Services/InventoryManagement-services.proxy";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { styled } from "@mui/system";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import * as constFile from "../../../InventoryManagementConst";
import { render } from "@testing-library/react";
import ErrorModal from "../../SharedComponents/ErrorModal";

const createMR = InventoryManagementServices.createMR;
const updateMRUpperData = InventoryManagementServices.updateMRUpperData;
const updateMRItems = InventoryManagementServices.updateMRItems;

const MRInputData = InventoryManagementServices.MRInputData;

const uploadFile = InventoryManagementServices.uploadFile;
const deleteFile = InventoryManagementServices.deleteFile;

const getRMCode = InventoryManagementServices.getRMCode;
const getRMDescription = InventoryManagementServices.getRMDescription;

function MaterialReceiptCreationForm(props) {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();

  const [inventoryId, setInventoryId] = useState(query.get("inventoryId"));
  const [mrId, setMRId] = useState(query.get("mrId"));

  const BlueTip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      componentsProps={{ tooltip: { className: className } }}
    />
  ))(`
  
  width:110px;
          color: #fff;
          background-color: rgba(6, 116, 185, 0.3);
          font-size: 13px;
       
      `);

  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const openTip = () => setTooltipIsOpen(true);
  const closeTip = () => setTooltipIsOpen(false);

  const [openCurrencyForm, setOpenCurrencyForm] = useState(false);
  const [currencyName, setCurrencyName] = useState(null);
  const [currencyCode, setCurrencyCode] = useState(null);
  const [currencySymbol, setCurrencySymbol] = useState(null);

  /////////   Lists to set from Input Data   ////////

  const [currenciesList, setCurrenciesList] = useState([]);
  const [tradesList, setTradesList] = useState([]);
  const [itemCategoriesList, setItemCategoriesList] = useState([]);
  const [itemRefGroupsList, setItemRefGroupsList] = useState([]);
  const [storageBlocksList, setStorageBlocksList] = useState([]);
  const [originalStorageBlocksList, setOriginalStorageBlocksList] = useState(
    []
  );

  //////////   MR Upper Data Fields ///////////

  const [materialReceiptCode, setMaterialReceiptCode] = useState(null);

  const [purchaseOrderRef, setPurchaseOrderRef] = useState(null);
  const [purchaseOrderRefError, setPurchaseOrderRefError] = useState(false);

  const [inputDate, setInputDate] = useState(null);
  const [supplierCode, setSupplierCode] = useState(null);

  const [supplierName, setSupplierName] = useState(null);
  const [supplierNameError, setSupplierNameError] = useState(false);
  const [supplierNameErrorMsg, setSupplierNameErrorMsg] = useState(null);

  const [supplierAddress, setSupplierAddress] = useState(null);

  const [supplierRef, setSupplierRef] = useState(null);
  const [supplierRefError, setSupplierRefError] = useState(false);
  const [supplierRefErrorMsg, setSupplierRefErrorMsg] = useState(null);

  const [comment, setComment] = useState(null);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  const [currencyId, setCurrencyId] = useState(null);
  const [currencyIdError, setCurrencyIdError] = useState(false);

  //////////  Files -- MTCS Doc Names /////////

  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [fileId, setFileId] = useState(null);
  const [fileIds, setFileIds] = useState([]);

  const [fileMtcDocName, setFileMtcDocName] = useState("");
  const [heatNbsArray, setHeatNbsArray] = useState([]);
  const [bundleMtcDocNamesList, setBundleMtcDocNamesList] = useState([]);
  const [bundleToViewHeats, setBundleToViewHeats] = useState();

  /////////////  Loading & Updating States   ///////////

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [BEerror, setBEerror] = useState(null);

  const [isDisabled, setIsDisabled] = useState(true);

  const [someRequiredFieldsEmpty, setSomeRequiredFieldsEmpty] = useState(false);
  const [requiredHelperMsg, setRequiredHelperMsg] = useState(null);

  const [successMRCreate, setSuccessMRCreate] = useState(false);
  const [successItemsUpdate, setSuccessItemsUpdate] = useState(false);
  const [successMRUpperDataUpdate, setSuccessMRUpperDataUpdate] =
    useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingFileMsg, setLoadingFileMsg] = useState(false);
  const [loadingMRInputData,setLoadingMRInputData] = useState(false);
  const [dependencyIndex, setDependencyIndex] = useState();
  const [canDeleteFile, setCanDeleteFile] = useState(true);

  const [updatingUOM, setUpdatingUOM] = useState(false);
  const [updatingRM, setUpdatingRM] = useState(false);
  const [updatingDescription, setUpdatingDescription] = useState(false);
  const [updatingBundles, setUpdatingBundles] = useState(false);
  const [updatingTotalPrice, setUpdatingTotalPrice] = useState(false);
  const [updatingTotalQuantity, setUpdatingTotalQuantity] = useState(false);

  const [lineId, setLineId] = useState();
  const [exceedQtyError, setExceedQtyError] = useState(false);

  ///////////   Item States   //////////

  const [itemIndex, setItemIndex] = useState(0);
  const [itemsArray, setItemsArray] = useState([]);
  const [originalItemsArrayLength, setOriginalItemsArrayLength] = useState();
  const [itemObject, setItemObject] = useState();
  const [itemObjectChosen, setItemObjectChosen] = useState(0);

  //////////////////////   Data Objects Templates //////////////////////////////////

  const MRData = {
    Id: mrId ? mrId : null,
    Code: materialReceiptCode,
    PurchaseOrderRef: purchaseOrderRef,
    SupplierCode: supplierCode,
    SupplierName: supplierName,
    SupplierAddress: supplierAddress,
    SupplierRef: supplierRef,
    Comments: comment,
    TotalQuantity: totalQuantity,
    TotalPrice: totalPrice,
    CurrencyId: currencyId,
    InventoryId: inventoryId,
    InputDate: inputDate,
    MTCs: fileIds,
    Items: itemsArray,
  };

  const [currencyDataObject, setCurrencyDataObject] = useState();
  const InitialCreateEmptyItems = {
    Id: null,
    Code: materialReceiptCode,
    PurchaseOrderRef: purchaseOrderRef,
    SupplierCode: supplierCode,
    SupplierName: supplierName,
    SupplierAddress: supplierAddress,
    SupplierRef: supplierRef,
    Comments: comment,
    TotalQuantity: totalQuantity,
    TotalPrice: totalPrice,
    CurrencyId: currencyId,
    InventoryId: inventoryId,
    InputDate: inputDate,
    MTCs: fileIds,
    Currency: currencyDataObject ? currencyDataObject : null,
    Items: [],
  };

  const MRUpperData = {
    Id: mrId,
    Code: materialReceiptCode,
    PurchaseOrderRef: purchaseOrderRef,
    SupplierCode: supplierCode,
    SupplierName: supplierName,
    SupplierAddress: supplierAddress,
    SupplierRef: supplierRef,
    Comments: comment,
    TotalQuantity: totalQuantity,
    TotalPrice: totalPrice + "",
    CurrencyId: currencyId,
    InventoryId: inventoryId,
    InputDate: inputDate,
    MTCs: fileIds,
    Currency: currencyDataObject ? currencyDataObject : null,
  };

  const ItemDataObject = {
    Id: null,
    TradeId: 1,
    CategoryId: null,
    TypeCodeId: null,
    RefId: null,
    DiaValueId: null,
    LengthId: null,
    Diameter: null,
    Length: null,
    RMCode: null,
    RMDescription: null,
    ProductCode: null,
    Quantity: 0,
    UoM: null,
    UnitPrice: 0,
    TotalPrice: 0,
    BundlesNumber: 0,
    Bundles: [],
    ZoneId: null,
    StorageId: null,
  };

  useEffect(() => {
    getMRInputData();
  }, [inventoryId, mrId]);

  ////////////////////////   Create and Update Functions  /////////////////////

  const getMRInputData = () => {
    setSomeRequiredFieldsEmpty(false);
    setLoadingMRInputData(true);
    MRInputData(inventoryId, mrId).then((x) => {
      if (x.status || x.statusCode) {
        if (x.status) setBEerror(x.error)
        if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
        setOpenErrorModal(true);
      } else {
        setMaterialReceiptCode(x.Code);
        setItemCategoriesList(x.CategoryTypes);
        setStorageBlocksList(x.StorageZones);
        setOriginalStorageBlocksList(x.StorageZones);

        setItemRefGroupsList(x.ReferenceGroup);

        setTradesList(x.Trades);
        setCurrenciesList(x.Currencies);

        if (mrId) {
          setMaterialReceiptCode(x.Stock.Code);
          setPurchaseOrderRef(x.Stock.PurchaseOrderRef);
          setSupplierCode(x.Stock.SupplierCode);
          setSupplierName(x.Stock.SupplierName);
          setSupplierAddress(x.Stock.SupplierAddress);
          setSupplierRef(x.Stock.SupplierRef);
          setComment(x.Stock.Comments);
          setTotalQuantity(x.Stock.TotalQuantity);
          setTotalPrice(x.Stock.TotalPrice);
          setCurrencyId(x.Stock.CurrencyId);
          setInputDate(x.Stock.InputDate);
          setFiles(x.Stock.MTCs);
          setOriginalItemsArrayLength(x.Stock.Items.length);
          let arr = [];

          for (let i = 0; i < x.Stock.MTCs.length; i++) {
            let mtcTemp = {
              Id: x.Stock.MTCs[i].Id,
              Name: x.Stock.MTCs[i].DisplayName,
              HeatNbs: [],
            };

            arr.push(mtcTemp);
            setBundleMtcDocNamesList(arr);
            let arrF = fileIds;
            let fileIdTemp = { FileId: x.Stock.MTCs[i].FileId };

            arrF.push(fileIdTemp);
            setFileIds(arrF);
            // console.log({ arr });
            // console.log({ arrF });
          }
          // let arr=[];
          // mts.map((mtc) => {
          //   let fileData = { Id: mtc.Id , Name: mtc.DisplayName};
          //   arr.push(fileData);
          //   fileData = null;
          // });
          // setMtcDocNamesArray(arr)

          if (x.Stock.Items.length > 0) {
            setItemsArray(x.Stock.Items);
            setItemObjectChosen(0);
            setItemObject(x.Stock.Items[0]);
          }

          if (x.Stock.Items.length === 0 && itemsArray.length === 0) {
            ItemDataObject.CategoryId = x.CategoryTypes[0].Id;
            itemsArray.push(ItemDataObject);
            setItemObjectChosen(0);
            setItemObject(itemsArray[0]);
          }
        }
        if (mrId === null && itemsArray.length === 0) {
          ItemDataObject.CategoryId = x.CategoryTypes[0].Id;
          itemsArray.push(ItemDataObject);
          setItemObjectChosen(0);
          setItemObject(itemsArray[0]);
        }
        setItemIndex(0);
        setTimeout(() => {
          setSuccessMRCreate(false);
          setSuccessItemsUpdate(false);
          setSuccessMRUpperDataUpdate(false);
        }, 5000);
      }

      setLoadingMRInputData(false);

    });
  };

  const MRCreate = (data) => {
    setLoading(true);
    createMR(data).then((x) => {
      setLoading(false);
      if (x.status || x.statusCode) {
        if (x.status) setBEerror(x.error)
        if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
        setOpenErrorModal(true);
      } else {
        let newMRId = x;
        setMRId(x);
        setSuccessMRCreate(true);

        navigate(
          `/saas/admin/inventoryManagement/materialReceiptCreationForm?mrId=${newMRId}&inventoryId=${inventoryId}`
        );
      }
    });
  };

  const MRUpperDataUpdate = () => {
    setLoading(true);
    updateMRUpperData(MRUpperData).then((x) => {
      setLoading(false);
      if (x.status || x.statusCode) {
        if (x.status) setBEerror(x.error)
        if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
        setOpenErrorModal(true);
      } else {
        setSuccessMRUpperDataUpdate(true);
        getMRInputData();
      }

    });
  };

  const MRItemsUpdate = () => {
    setLoading(true);

    updateMRItems(mrId, itemsArray).then((x) => {
      setLoading(false);
      if (x.status || x.statusCode) {
        if (x.status) setBEerror(x.error)
        if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
        setOpenErrorModal(true);
      } else {
        setSuccessItemsUpdate(true);
        MRUpperDataUpdate();
      }

    });
  };

  useEffect(() => {
    if (
      !supplierName ||
      !supplierRef ||
      !currencyId ||
      !purchaseOrderRef ||
      purchaseOrderRefError ||
      supplierNameError ||
      supplierRefError ||
      currencyIdError ||
      updatingRM ||
      updatingUOM ||
      updatingBundles ||
      updatingDescription ||
      updatingTotalPrice ||
      updatingTotalQuantity ||
      exceedQtyError ||
      files.length == 0
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });

  //////////////////////   Files Handling   //////////////////////////

  useEffect(() => {
    if (file) {
      let tempFile = null;
      tempFile = file;
      files.push(tempFile);
    }
  }, [file]);

  useEffect(() => {
    if (fileId) {
      let tempFileId = null;
      tempFileId = fileId;
      let fileData = { FileId: tempFileId };
      fileIds.push(fileData);
      // if (mrId) MRUpperDataUpdate();
    }
  }, [fileId]);

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setLoadingFile(true);
      setLoadingFileMsg("Uploading File");
      uploadFile(e.target.files[0], inventoryId).then((x) => {
        if (x.status || x.statusCode) {
          if (x.status) setBEerror(x.error)
          if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
          setOpenErrorModal(true);
        } else {
          setFile(x);
          setFileId(x.FileId);
        }
        // if (mrId === null) {
        //   let mtcTemp = { Id: x.Id, Name: x.DisplayName, HeatNbs: [] };
        //   let arr = bundleMtcDocNamesList;
        //   arr.push(mtcTemp);
        //   setBundleMtcDocNamesList(arr);
        // }

        setLoadingFile(false);
      });
    }
  };

  const FileDelete = (item) => {
    const index = files.indexOf(item);

    let dependencyConflict = false;

    itemsArray?.map((it, index) => {
      let currentIndex = index + 1;
      it.Bundles?.map((b) => {
        if (b.MTCId === item.Id) {
          dependencyConflict = true;
          setDependencyIndex(currentIndex);
          setCanDeleteFile(false);
          setTimeout(() => {
            setCanDeleteFile(true);
          }, 4000);
        }
      });
    });

    if (!dependencyConflict) {
      setDependencyIndex(0);
      if (mrId === null) setLoadingFile(true);
      setLoadingFileMsg("Deleting File");
      if (index > -1) {
        files.splice(index, 1);
        fileIds.splice(index, 1);

        // if (mrId) MRUpperDataUpdate();
        if (mrId === null) {
          bundleMtcDocNamesList.filter((x) => x.Id === item.Id);
          const objWithIdIndex = bundleMtcDocNamesList.findIndex(
            (obj) => obj.Id === item.Id
          );
          bundleMtcDocNamesList.splice(objWithIdIndex, 1);
        }

        setLoadingFile(false);

        // 2nd parameter means remove one item only
      }
      // deleteFile(item.FileId, inventoryId).then(() => {

      //   if (index > -1) {
      //     files.splice(index, 1);
      //     fileIds.splice(index, 1);
      //     if (mrId) MRUpperDataUpdate();
      //     if (mrId === null) {
      //       bundleMtcDocNamesList.filter((x) => x.Id === item.Id);
      //       const objWithIdIndex = bundleMtcDocNamesList.findIndex(
      //         (obj) => obj.Id === item.Id
      //       );
      //       bundleMtcDocNamesList.splice(objWithIdIndex, 1);
      //     }

      //     setLoadingFile(false);

      //     // 2nd parameter means remove one item only
      //   }
      // });
    }
  };

  ////////////////////////   Rendering Functions    ////////////////////

  const renderBundleFields = (
    itemBundlesArray,
    itemUoM,
    itemCategoryId,
    itemId
  ) => {
    return (
      <Grid item container xs={12}>
        {itemBundlesArray?.map((b, index) => (
          <Grid
            item
            container
            xs={12}
            direction="row"
            spacing={2}
            paddingTop={2}
            key={index}
          >
            <Grid item container xs={12} md={1} gap={1}>
              <Grid item xs={12}>
                Bundle Name
              </Grid>
              <Grid
                item
                xs={2.2}
                md={12}
                sx={{
                  borderRadius: 1,
                  border: "solid 1px rgba(206, 210, 225, 0.97)",
                  fontFamily: "Muli",
                  color: "#0674b9",
                  fontSize: 12,
                  height: 34,
                  padding: "0.4rem 0.4rem 0.4rem 0.6rem",
                }}
              >
                Bundle {index + 1}
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="column"
              xs={6}
              sm={2}
              md={1.5}
              gap={1}
            >
              <Grid item>*Heat No.</Grid>
              <Grid item>
                <TextField
                  disabled={b.Id !== null}
                  value={b.HeatNum}
                  InputProps={{
                    className: classes.textField,
                  }}
                  onChange={(e) => {
                    b.HeatNum = e.target.value;
                    forceUpdate();
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="column"
              xs={6}
              sm={2}
              md={1.5}
              gap={1}
            >
              <Grid item>Bundle Weight (kg)</Grid>
              <Grid item className={classes["Rectangle"]}>
                {b.Weight}
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="column"
              xs={6}
              sm={2}
              md={1.5}
              gap={1}
            >
              <Grid item>Qty of Pieces </Grid>
              <Grid item className={classes["Rectangle"]}>
                {b.QuantityOfPcs}
              </Grid>
            </Grid>
            <Grid item container direction="column" xs={6} sm={2} gap={1}>
              <Grid item>*Mtc Doc Name</Grid>
              <Grid item width="100%">
                <FormControl fullWidth>
                  <Select
                    disabled={b.Id !== null}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    MenuProps={{
                      disableScrollLock: true,

                      PaperProps: {
                        sx: {
                          maxHeight: {
                            xs: "130px",
                          },
                        },
                      },
                    }}
                    value={b.MTCId ? b.MTCId : 0}
                    fullWidth
                    sx={{
                      height: "34px",
                      fontFamily: "Muli",
                      fontSize: "13px",
                    }}
                    defaultValue={0}
                    onChange={(e) => {
                      b.MTCId = e.target.value;
                      forceUpdate();
                    }}
                  >
                    <MenuItem value={0} key={0} disabled>
                      Mtc Doc Name
                    </MenuItem>
                    {bundleMtcDocNamesList?.map((r) => (
                      <MenuItem value={r.Id} key={r.Id}>
                        {r.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item container direction="column" xs={6} sm={2} gap={1}>
              <Grid item>*Storage Block</Grid>
              <Tooltip title={storageBlocksList?.find((s) => s.BlockId === b.StorageId)?.Code + " / " + storageBlocksList?.find((s) => s.BlockId === b.StorageId)?.StorageType} placement="top-start">
                <Grid item width="100%">
                  <FormControl fullWidth>
                    <Select
                      disabled={b.Id !== null}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={b.StorageId ? b.StorageId : 0}
                      MenuProps={{
                        disableScrollLock: true,
                        PaperProps: {
                          sx: {
                            maxHeight: {
                              xs: "130px",
                            },
                          },
                        },
                      }}
                      default={0}
                      fullWidth
                      sx={{
                        height: "34px",
                        fontFamily: "Muli",
                        fontSize: "13px",
                      }}
                      onChange={(e) => {
                        let oldZoneId = b.ZoneId;
                        let oldStorageId = b.StorageId;

                        b.StorageId = e.target.value;
                        b.ZoneId = null;
                        addOldZoneCapacity(oldZoneId, oldStorageId, b.Weight);
                        forceUpdate();
                      }}
                    >
                      <MenuItem value={0} key={0} disabled>
                        Storage Block
                      </MenuItem>
                      {storageBlocksList
                        ?.filter(
                          (s) =>
                            s.CategoryId ===
                            itemCategoriesList.find(
                              (c) => c.Id === itemCategoryId
                            )?.CategoryId
                        )
                        ?.map((block) => (
                          <MenuItem
                            value={block.BlockId}
                            key={block.BlockId}
                            style={{
                              display: "block",
                              fontFamily: "Muli",
                              fontSize: "13px",
                            }}
                          >
                            {block.Code + "/" + block.StorageType}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Tooltip>
            </Grid>
            <Grid item container direction="column" xs={6} sm={2} gap={1}>
              <Grid item>*Storage Zone</Grid>
              <Grid item width="100%">
                <FormControl fullWidth>
                  <Select
                    disabled={b.Id !== null}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    MenuProps={{
                      disableScrollLock: true,
                      PaperProps: {
                        sx: {
                          maxHeight: {
                            xs: "130px",
                          },
                        },
                      },
                    }}
                    value={b.ZoneId ? b.ZoneId : 0}
                    default={0}
                    fullWidth
                    sx={{
                      height: "34px",
                      fontFamily: "Muli",
                      fontSize: "13px",
                    }}
                    onChange={(e) => {
                      let oldZoneId = b.ZoneId;
                      setLineId(index);
                      checkRMZoneCapacity(
                        b.StorageId,
                        e.target.value,
                        b.Weight,
                        b
                      );
                      addOldZoneCapacity(oldZoneId, b.StorageId, b.Weight);

                      forceUpdate();
                    }}
                  >
                    <MenuItem value={0} key={0} disabled>
                      Storage Zone
                    </MenuItem>
                    {storageBlocksList
                      .filter((x) => x.BlockId === b.StorageId)[0]
                      ?.Zones?.map((r) => (
                        <MenuItem
                          value={r.Id}
                          key={r.Id}
                          style={{
                            display: "block",
                            fontFamily: "Muli",
                            fontSize: "13px",
                          }}
                        >
                          {itemId === null
                            ? r.Code +
                            " - Available Capacity : " +
                            r.AvailableCapacity +
                            " Kg"
                            : r.Code}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {lineId === index &&
                      exceedQtyError &&
                      itemId === itemObjectChosen
                      ? "This bundle weight exceeds zone capacity"
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  };

  const renderItemFields = (itemObject, index) => {
    return (
      <>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12} className={classes["title"]} fontSize="15px">
            {" "}
            Item {itemsArray.indexOf(itemObject) + 1}
          </Grid>
          <Grid item container direction="column" xs={12} sm={2} md={1} gap={1}>
            <Grid item>*Item Trade</Grid>
            <Grid item width="90px">
              {" "}
              <FormControl fullWidth>
                <Select
                  disabled={mrId === null}
                  MenuProps={{
                    disableScrollLock: true,
                    PaperProps: {
                      sx: {
                        maxHeight: {
                          xs: "130px",
                        },
                      },
                    },
                  }}
                  value={itemObject.TradeId ? itemObject.TradeId : 0}
                  fullWidth
                  sx={{
                    height: "34px",
                    fontFamily: "Muli",
                    fontSize: "13px",
                  }}
                  defaultValue={0}
                  onChange={(e) => {
                    itemObject.TradeId = e.target.value;

                    forceUpdate();
                  }}
                >
                  <MenuItem value={0} key={0} disabled>
                    Trade
                  </MenuItem>
                  {tradesList?.map((r) => (
                    <MenuItem
                      value={r.Id}
                      key={r.Id}
                      style={{
                        display: "block",
                        fontFamily: "Muli",
                        fontSize: "13px",
                      }}
                    >
                      {r.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            className={classes["categorytitle"]}
            xs={12}
            sm={4}
            gap={1}
            marginTop={{ xs: 0, md: 2 }}
          >
            <Grid item xs={12} md={2.5}>
              Item Category:
            </Grid>
            {itemCategoriesList?.map((c, index) => (
              <>
                <Grid
                  item
                  key={c.Id}
                  className={
                    itemObject.CategoryId === c.Id
                      ? classes["CheckedBox"]
                      : classes["notCheckedBox"]
                  }
                  onMouseOver={(e) => {
                    if (mrId === null || itemObject.Id !== null)
                      e.target.style.cursor = "unset";
                    else e.target.style.cursor = "pointer";
                  }}
                  onClick={(e) => {
                    if (mrId && itemObject.Id === null) {
                      let oldBundlesArray = itemObject.Bundles;
                      itemObject.CategoryId = c.Id;
                      itemObject.RefId = null;
                      itemObject.DiaValueId = null;
                      itemObject.LengthId = null;
                      itemObject.RMCode = null;
                      itemObject.TypeCodeId = null;
                      itemObject.RMDescription = null;
                      itemObject.ProductCode = null;
                      itemObject.Quantity = 0;
                      itemObject.UoM = null;
                      itemObject.UnitPrice = 0;
                      itemObject.TotalPrice = 0;
                      itemObject.BundlesNumber = 0;
                      oldBundlesArray?.map((b) => {
                        addOldZoneCapacity(b.ZoneId, b.StorageId, b.Weight);
                      });

                      forceUpdate();
                    }
                  }}
                ></Grid>
                <Grid item>{c.Name}</Grid>
              </>
            ))}
          </Grid>

          <Grid
            item
            container
            direction="row"
            xs={12}
            sm={6}
            alignContent="center"
            className={classes["categorytitle"]}
            gap={1}
            marginTop={{ xs: 0, md: 2 }}
          >
            <Grid item xs={12} md={2}>
              Item Type Code:
            </Grid>

            {itemCategoriesList
              .find((c) => c.Id === itemObject.CategoryId)
              ?.Types?.map((c) => (
                <>
                  <Grid
                    item
                    key={c.Id}
                    className={
                      itemObject.TypeCodeId === c.Id
                        ? classes["CheckedBox"]
                        : classes["notCheckedBox"]
                    }
                    onMouseOver={(e) => {
                      if (mrId === null || itemObject.Id !== null)
                        e.target.style.cursor = "unset";
                      else e.target.style.cursor = "pointer";
                    }}
                    onClick={(e) => {
                      if (mrId && itemObject.Id === null) {
                        itemObject.TypeCodeId = c.Id;
                        itemObject.Bundles = [];
                        itemObject.Quantity = 0;
                        itemObject.UnitPrice = 0;
                        itemObject.TotalPrice = 0;

                        forceUpdate();
                        updateUoM(itemObject);
                      }
                    }}
                  ></Grid>
                  <Grid item>{c.Name}</Grid>
                </>
              ))}
          </Grid>
          {itemCategoriesList.find((c) => c.Id === itemObject.CategoryId)
            ?.Name === "Raw Material" ? (
            <>
              <Grid
                item
                container
                direction="column"
                xs={6}
                sm={3}
                md={2.5}
                spacing={1}
              >
                <Grid item>*Item Ref Group</Grid>
                <Grid item width="100%">
                  {" "}
                  <FormControl fullWidth>
                    <Select
                      disabled={mrId === null || itemObject.Id !== null}
                      MenuProps={{
                        disableScrollLock: true,
                        PaperProps: {
                          sx: {
                            maxHeight: {
                              xs: "130px",
                            },
                          },
                        },
                      }}
                      value={itemObject.RefId ? itemObject.RefId : 0}
                      fullWidth
                      sx={{
                        height: "34px",
                        fontFamily: "Muli",
                        fontSize: "13px",
                      }}
                      defaultValue={0}
                      onChange={(e) => {
                        itemObject.RefId = e.target.value;
                        updateRMCode(itemObject);
                        updateRMDescription(itemObject);
                        forceUpdate();
                      }}
                    >
                      <MenuItem value={0} key={0} disabled>
                        Item Ref Group
                      </MenuItem>
                      {itemRefGroupsList.map((r) => (
                        <MenuItem
                          value={r.Id}
                          key={r.Id}
                          style={{
                            display: "block",
                            fontFamily: "Muli",
                            fontSize: "13px",
                          }}
                        >
                          {r.Name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                item
                container
                direction="column"
                xs={6}
                sm={3}
                md={1.25}
                spacing={1}
              >
                <Grid item>*Item Dia Value (mm)</Grid>
                <Grid item width="100%">
                  {" "}
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      disabled={mrId === null || itemObject.Id !== null}
                      MenuProps={{
                        disableScrollLock: true,
                        PaperProps: {
                          sx: {
                            maxHeight: {
                              xs: "130px",
                            },
                          },
                        },
                      }}
                      value={itemObject.DiaValueId ? itemObject.DiaValueId : 0}
                      fullWidth
                      sx={{
                        height: "34px",
                        fontFamily: "Muli",
                        fontSize: "13px",
                      }}
                      defaultValue={0}
                      onChange={(e) => {
                        itemObject.DiaValueId = e.target.value;
                        itemObject.Diameter = itemCategoriesList
                          .find((x) => x.Id === itemObject.CategoryId)
                          ?.Types?.find((t) => t.Id === itemObject.TypeCodeId)
                          ?.Diameters?.find(
                            (d) => d.Id === e.target.value
                          ).Name;

                        forceUpdate();
                      }}
                    >
                      <MenuItem value={0} key={0} disabled>
                        Dia Value
                      </MenuItem>
                      {itemCategoriesList
                        .find((x) => x.Id === itemObject.CategoryId)
                        ?.Types?.find((t) => t.Id === itemObject.TypeCodeId)
                        ?.Diameters.map((r) => (
                          <MenuItem
                            value={r.Id}
                            key={r.Id}
                            style={{
                              display: "block",
                              fontFamily: "Muli",
                              fontSize: "13px",
                            }}
                          >
                            {r.Name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="column"
                xs={6}
                sm={3}
                md={1.25}
                spacing={1}
              >
                <Grid item>*Item Length (mm)</Grid>
                <Tooltip
                  title={
                    itemCategoriesList
                      .find((x) => x.Id === itemObject.CategoryId)
                      ?.Types?.find((t) => t.Id === itemObject.TypeCodeId)
                      ?.Diameters?.find((d) => d.Id === itemObject.DiaValueId)
                      ?.Values.find((r) => r.Id === itemObject.LengthId)?.Length.toLocaleString()
                  }
                  placement="top-start">
                  <Grid item width="100%">
                    {" "}
                    <FormControl fullWidth>
                      <Select
                        disabled={mrId === null || itemObject.Id !== null}
                        MenuProps={{
                          disableScrollLock: true,
                          PaperProps: {
                            sx: {
                              maxHeight: {
                                xs: "130px",
                              },
                            },
                          },
                        }}
                        value={itemObject.LengthId ? itemObject.LengthId : 0}
                        fullWidth
                        sx={{
                          height: "34px",
                          fontFamily: "Muli",
                          fontSize: "13px",
                        }}
                        defaultValue={0}
                        onChange={(e) => {
                          itemObject.LengthId = e.target.value;
                          itemObject.Length = itemCategoriesList
                            .find((x) => x.Id === itemObject.CategoryId)
                            ?.Types?.find((t) => t.Id === itemObject.TypeCodeId)
                            ?.Diameters?.find(
                              (d) => d.Id === itemObject.DiaValueId
                            )
                            ?.Values?.find(
                              (l) => l.Id === e.target.value
                            )?.Length;
                          forceUpdate();
                          updateBundlesNumber(itemObject);

                          updateRMCode(itemObject);
                          updateRMDescription(itemObject);
                        }}
                      >
                        <MenuItem value={0} key={0} disabled>
                          Length
                        </MenuItem>
                        {itemCategoriesList
                          .find((x) => x.Id === itemObject.CategoryId)
                          ?.Types?.find((t) => t.Id === itemObject.TypeCodeId)
                          ?.Diameters?.find((d) => d.Id === itemObject.DiaValueId)
                          ?.Values.map((r) => (
                            <MenuItem
                              value={r.Id}
                              key={r.Id}
                              style={{
                                display: "block",
                                fontFamily: "Muli",
                                fontSize: "13px",
                              }}
                            >
                              {r.Length.toLocaleString()}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Tooltip>
              </Grid>
            </>
          ) : (
            <></>
          )}
          {itemCategoriesList.find((c) => c.Id === itemObject.CategoryId)
            ?.Name === "Raw Material" ? (
            <Grid
              item
              container
              direction="column"
              xs={6}
              sm={3}
              md={3}
              gap={1}
            >
              <Grid item>Item RM Code</Grid>
              <Grid item className={classes["Rectangle"]}>
                {updatingRM
                  ? "Updating ..."
                  : itemObject.RMCode
                    ? itemObject.RMCode
                    : "RM Code"}
              </Grid>
            </Grid>
          ) : (
            <Grid
              item
              container
              direction="column"
              xs={6}
              sm={3}
              md={2}
              gap={1}
            >
              <Grid item>*Item Product Code</Grid>
              <Grid item>
                <TextField
                  disabled={mrId === null || itemObject.Id !== null}
                  value={itemObject.ProductCode}
                  placeholder="Product Code"
                  InputProps={{
                    className: classes.textField,
                  }}
                  onChange={(e) => {
                    itemObject.ProductCode = e.target.value;

                    forceUpdate();
                  }}
                />
              </Grid>
            </Grid>
          )}
          {itemCategoriesList.find((c) => c.Id === itemObject.CategoryId)
            ?.Name === "Raw Material" ? (
            <Grid item container direction="column" xs={6} sm={3.5} gap={1}>
              <Grid item>Item RM Description</Grid>
              <Grid
                item
                className={classes["RectangleWithScroll"]}
                height="40px"
              >
                {updatingDescription
                  ? "Updating..."
                  : itemObject.RMDescription
                    ? itemObject.RMDescription
                    : "RM Description"}
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
          <Grid
            item
            container
            direction="column"
            xs={6}
            sm={3}
            md={1.5}
            spacing={1}
          >
            <Grid item>Item Quantity</Grid>
            <Grid item>
              <TextField
                type="number"
                disabled={mrId === null || itemObject.Id !== null}
                value={itemObject.Quantity}
                defaultValue={0}
                InputProps={{
                  className: classes.textField,
                  inputProps: { min: 0 },
                }}
                onChange={(e) => {
                  let oldItemQuantity = itemObject.Quantity;
                  let oldZoneId = itemObject.ZoneId;
                  let oldBundlesArray = itemObject.Bundles;
                  itemObject.Quantity = parseInt(e.target.value);
                  if (
                    itemCategoriesList.find(
                      (c) => c.Id === itemObject.CategoryId
                    )?.Name === "Product" &&
                    itemObject.ZoneId !== null
                  ) {
                    itemObject.ZoneId = null;
                    addOldZoneCapacity(
                      oldZoneId,
                      itemObject.StorageId,
                      oldItemQuantity
                    );
                  } else {
                    oldBundlesArray?.map((b) => {
                      addOldZoneCapacity(b.ZoneId, b.StorageId, b.Weight);
                    });
                  }
                  forceUpdate();
                  updateBundlesNumber(itemObject);
                  updateItemTotalPrice(itemObject);
                  if (
                    itemCategoriesList.find(
                      (c) => c.Id === itemObject.CategoryId
                    )?.Name === "Raw Material"
                  ) {
                    updateMRTotalQuantity();
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid item container direction="column" xs={6} sm={1.5} gap={1}>
            <Grid item>Item UoM</Grid>
            <Grid item className={classes["Rectangle"]}>
              {updatingUOM
                ? "Updating ..."
                : itemObject.UoM
                  ? itemObject.UoM
                  : "Item UoM"}
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            xs={6}
            sm={3}
            md={1.5}
            spacing={1}
          >
            <Grid item>*Unit Price</Grid>
            <Grid item>
              <TextField
                type="number"
                disabled={mrId === null || itemObject.Id !== null}
                value={itemObject.UnitPrice}
                defaultValue={0}
                InputProps={{
                  className: classes.textField,
                  inputProps: { min: 0 },
                }}
                onChange={(e) => {
                  itemObject.UnitPrice = parseInt(e.target.value);

                  forceUpdate();
                  updateItemTotalPrice(itemObject);
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            xs={6}
            sm={2}
            md={1.5}
            gap={1}
          >
            <Grid item>Total Price</Grid>
            <Grid item className={classes["Rectangle"]}>
              {itemObject.TotalPrice.toLocaleString()}
            </Grid>
          </Grid>
          {itemCategoriesList.find((c) => c.Id === itemObject.CategoryId)
            ?.Name === "Product" ? (
            <>
              <Grid item container direction="column" xs={6} sm={2} gap={1}>
                <Grid item>*Storage Block</Grid>
                <Tooltip title={storageBlocksList?.find((s) => s.BlockId === itemObject.StorageId)?.Code + " / " + storageBlocksList?.find((s) => s.BlockId === itemObject.StorageId)?.StorageType} placement="top-start">
                  <Grid item width="100%">
                    <FormControl fullWidth>
                      <Select
                        disabled={itemObject.Id !== null}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={itemObject.StorageId ? itemObject.StorageId : 0}
                        MenuProps={{
                          disableScrollLock: true,
                          PaperProps: {
                            sx: {
                              maxHeight: {
                                xs: "130px",
                              },
                            },
                          },
                        }}
                        default={0}
                        fullWidth
                        sx={{
                          height: "34px",
                          fontFamily: "Muli",
                          fontSize: "13px",
                        }}
                        onChange={(e) => {
                          let oldZoneId = itemObject.ZoneId;
                          let oldStorageId = itemObject.StorageId;
                          itemObject.StorageId = e.target.value;
                          itemObject.ZoneId = null;
                          addOldZoneCapacity(
                            oldZoneId,
                            oldStorageId,
                            itemObject.Quantity
                          );
                          forceUpdate();
                        }}
                      >
                        <MenuItem value={0} key={0} disabled>
                          Storage Block
                        </MenuItem>
                        {storageBlocksList
                          ?.filter(
                            (s) =>
                              s.CategoryId ===
                              itemCategoriesList.find(
                                (c) => c.Id === itemObject.CategoryId
                              )?.CategoryId
                          )
                          .map((block) => (
                            <MenuItem
                              value={block.BlockId}
                              key={block.BlockId}
                              style={{
                                display: "block",
                                fontFamily: "Muli",
                                fontSize: "13px",
                              }}
                            >
                              {block.Code + "/" + block.StorageType}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Tooltip>

              </Grid>
              <Grid item container direction="column" xs={6} sm={2} gap={1}>
                <Grid item>*Storage Zone</Grid>
                <Grid item width="100%">
                  <FormControl fullWidth>
                    <Select
                      disabled={
                        itemObject.Id !== null || itemObject.Quantity <= 0
                      }
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      MenuProps={{
                        disableScrollLock: true,
                        PaperProps: {
                          sx: {
                            maxHeight: {
                              xs: "130px",
                            },
                          },
                        },
                      }}
                      value={itemObject.ZoneId ? itemObject.ZoneId : 0}
                      default={0}
                      fullWidth
                      sx={{
                        height: "34px",
                        fontFamily: "Muli",
                        fontSize: "13px",
                      }}
                      onChange={(e) => {
                        let oldZoneId = itemObject.ZoneId;
                        setLineId(index);
                        checkProductZoneCapacity(
                          itemObject.StorageId,
                          e.target.value,
                          itemObject.Quantity,
                          itemObject
                        );

                        addOldZoneCapacity(
                          oldZoneId,
                          itemObject.StorageId,
                          itemObject.Quantity
                        );

                        forceUpdate();
                      }}
                    >
                      <MenuItem value={0} key={0} disabled>
                        Storage Zone
                      </MenuItem>
                      {storageBlocksList
                        .filter((x) => x.BlockId === itemObject.StorageId)[0]
                        ?.Zones?.map((r) => (
                          <MenuItem
                            value={r.Id}
                            key={r.Id}
                            style={{
                              display: "block",
                              fontFamily: "Muli",
                              fontSize: "13px",
                            }}
                          >
                            {itemObject.Id === null
                              ? r.Code +
                              " - Available Capacity : " +
                              r.AvailableCapacity +
                              " Kg"
                              : r.Code}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText sx={{ color: "#d32f2f" }}>
                      {lineId === index && exceedQtyError
                        ? "This Product Qty exceeds zone capacity"
                        : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </>
          ) : (
            <></>
          )}
          {itemCategoriesList.find((c) => c.Id === itemObject.CategoryId)
            ?.Name === "Raw Material" ? (
            <>
              <Grid
                item
                container
                direction="column"
                xs={6}
                sm={2}
                md={1.5}
                gap={1}
              >
                <Grid item>No. Bundles</Grid>
                <Grid item className={classes["Rectangle"]}>
                  {updatingBundles ? "Updating ..." : itemObject.BundlesNumber}
                </Grid>
              </Grid>
              {!updatingBundles && itemObject.BundlesNumber > 0 ? (
                <Grid item container xs={12}>
                  {renderBundleFields(
                    itemObject.Bundles,
                    itemObject.UoM,
                    itemObject.CategoryId,
                    itemObject.Id
                  )}
                </Grid>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </Grid>
      </>
    );
  };

  //////////////////////   Updating Functions   ///////////////////

  const updateUoM = (itemObject) => {
    setUpdatingUOM(true);

    itemObject.UoM = itemCategoriesList
      .find((x) => x.Id === itemObject.CategoryId)
      ?.Types?.find((t) => t.Id === itemObject.TypeCodeId)?.ReceiptUOM;
    forceUpdate();

    setTimeout(() => {
      setUpdatingUOM(false);
    }, 2000);
  };

  const updateRMCode = (itemObject) => {
    if (
      itemObject.RefId &&
      itemObject.TypeCodeId &&
      inventoryId &&
      itemObject.DiaValueId &&
      itemObject.LengthId
    ) {
      setUpdatingRM(true);
      getRMCode(
        inventoryId,
        itemObject.RefId,
        itemObject.DiaValueId,
        itemObject.TypeCodeId,
        itemObject.LengthId
      ).then((x) => {
        if (x.status || x.statusCode) {
          if (x.status) setBEerror(x.error)
          if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
          setOpenErrorModal(true);
        } else {
          itemObject.RMCode = x;
          forceUpdate();
        }
        setUpdatingRM(false);
      });
    }
  };

  const updateRMDescription = (itemObject) => {
    if (
      itemObject.RefId &&
      itemObject.TypeCodeId &&
      inventoryId &&
      itemObject.DiaValueId &&
      itemObject.LengthId
    ) {
      setUpdatingDescription(true);
      getRMDescription(
        inventoryId,
        itemObject.RefId,
        itemObject.DiaValueId,
        itemObject.TypeCodeId,
        itemObject.LengthId
      ).then((x) => {
        itemObject.RMDescription = x;
        forceUpdate();
        setUpdatingDescription(false);
      });
    }
  };

  const updateBundlesNumber = (itemObject) => {
    if (
      itemObject.LengthId &&
      itemObject.DiaValueId &&
      itemObject.Quantity > 0
    ) {
      setUpdatingBundles(true);
      let weight = itemCategoriesList
        .find((x) => x.Id === itemObject.CategoryId)
        ?.Types?.find((t) => t.Id === itemObject.TypeCodeId)
        ?.Diameters?.find((d) => d.Id === itemObject.DiaValueId)
        ?.Values?.find((l) => l.Id === itemObject.LengthId)?.Weight;

      let length = itemCategoriesList
        .find((x) => x.Id === itemObject.CategoryId)
        ?.Types?.find((t) => t.Id === itemObject.TypeCodeId)
        ?.Diameters?.find((d) => d.Id === itemObject.DiaValueId)
        ?.Values?.find((l) => l.Id === itemObject.LengthId)?.Length;

      let Dia = itemCategoriesList
        .find((x) => x.Id === itemObject.CategoryId)
        ?.Types?.find((t) => t.Id === itemObject.TypeCodeId)
        ?.Diameters?.find((d) => d.Id === itemObject.DiaValueId).Name;

      let weightPerMeter = (Math.PI * Math.pow(Dia / 2, 2) * 0.00785).toFixed(
        3
      );
      console.log("length", length);
      console.log("weightpermeter", weightPerMeter)
      let quantityInkg;
      if (itemObject.UoM === "Ton") {
        quantityInkg = itemObject.Quantity * 1000;
        console.log("Qty in kg", quantityInkg);

      }
      else {
        quantityInkg = itemObject.Quantity;

      }

      itemObject.BundlesNumber = Math.ceil(quantityInkg / weight);
      forceUpdate();
      let rest = quantityInkg % weight;
      console.log("rest", rest)

      itemObject.Bundles.splice(0, itemObject.Bundles.length);

      for (let i = 0; i < itemObject.BundlesNumber; i++) {
        let BundleDataObject = {
          Id: null,
          HeatNum: null,
          MTCId: null,
          ZoneId: null,
          StorageId: null,
          Weight: 0,
          QuantityOfPcs: null,
        };
        itemObject.Bundles.push(BundleDataObject);
      }

      let isCoil = false;

      if (itemCategoriesList.find((c) => c.Id === itemObject.CategoryId)?.Name === "Raw Material"
        && itemCategoriesList.find((c) => c.Id === itemObject.CategoryId)?.Types.find((t) => t.Id === itemObject.TypeCodeId).Name === "Coil") {
        isCoil = true;
      }
      else {
        isCoil = false;
      }



      for (let i = 0; i < itemObject.BundlesNumber; i++) {
        if (rest === 0) {
          itemObject.Bundles[i].Weight = weight;
          if (isCoil) {
            itemObject.Bundles[i].QuantityOfPcs = 1;
          }
          else {
            itemObject.Bundles[i].QuantityOfPcs = Math.ceil(
              weight / ((length / 1000) * weightPerMeter)
            );
          }

        } else {
          if (i === itemObject.BundlesNumber - 1) {
            itemObject.Bundles[i].Weight = rest;
            if (isCoil) {
              itemObject.Bundles[i].QuantityOfPcs = 1;
            }
            else {
              itemObject.Bundles[i].QuantityOfPcs = Math.ceil(
                rest / ((length / 1000) * weightPerMeter)
              );
            }
          } else {
            itemObject.Bundles[i].Weight = weight;
            if (isCoil) {
              itemObject.Bundles[i].QuantityOfPcs = 1

            }
            else {
              itemObject.Bundles[i].QuantityOfPcs = Math.ceil(
                weight / ((length / 1000) * weightPerMeter)
              );
            }

          }
        }
        forceUpdate();
      }
      setTimeout(() => {
        setUpdatingBundles(false);
      }, 2000);
    }
  };

  const updateItemTotalPrice = (itemObject) => {
    itemObject.TotalPrice = itemObject.Quantity * itemObject.UnitPrice;
    forceUpdate();
    updateMRTotalPrice();
  };

  const updateMRTotalPrice = () => {
    setUpdatingTotalPrice(true);
    let total = itemsArray.reduce(function (prev, current) {
      return prev + +current.TotalPrice;
    }, 0);
    setTotalPrice(total);
    setTimeout(() => {
      setUpdatingTotalPrice(false);
    }, 2000);
  };

  const updateMRTotalQuantity = () => {
    setUpdatingTotalQuantity(true);
    let sum = 0;

    itemsArray.map((item) => {
      if (
        itemCategoriesList?.find((x) => x.Id === item?.CategoryId)?.Name ===
        "Raw Material"
      ) {
        if (item.UoM === "Kg") sum = sum + item?.Quantity / 1000;
        if (item.UoM === "Ton") sum = sum + item?.Quantity;
      }
    });

    setTotalQuantity(sum);

    setTimeout(() => {
      setUpdatingTotalQuantity(false);
    }, 2000);
  };

  ////////////////////////////   Checking Functions   ////////////////////////

  const checkRequiredFieldsInItemsArray = (action) => {
    let empty = false;
    itemsArray.map((item, index) => {
      let currentIndex = index + 1;

      if (item.TypeCodeId === null || item.TradeId === null) {
        empty = true;
        setSomeRequiredFieldsEmpty(true);
        setItemIndex(currentIndex);
        setRequiredHelperMsg(
          "Item Type Code is missing in Item " + currentIndex
        );
      }
      if (item.Quantity <= 0 || item.UnitPrice <= 0) {
        console.log("hii");
        empty = true;
        setSomeRequiredFieldsEmpty(true);
        setItemIndex(currentIndex);
        setRequiredHelperMsg(
          "Item Quantity And Item Unit Price should be > 0 in Item " +
          currentIndex
        );
      }

      if (
        itemCategoriesList.find((x) => x.Id === item.CategoryId).Name ===
        "Raw Material"
      ) {
        if (!item.RefId || !item.LengthId || !item.DiaValueId) {
          empty = true;
          setSomeRequiredFieldsEmpty(true);
          setItemIndex(currentIndex);
          setRequiredHelperMsg(
            "Item Ref Group Or Length or Dia is missing in Item " + currentIndex
          );
        }
        if (item.Bundles.length > 0) {
          item.Bundles.map((bundle, bIndex) => {
            let currentBundleIndex = bIndex + 1;
            if (
              !bundle.HeatNum ||
              !bundle.MTCId ||
              !bundle.ZoneId ||
              !bundle.StorageId
            ) {
              empty = true;
              setSomeRequiredFieldsEmpty(true);
              setItemIndex(currentIndex);
              setRequiredHelperMsg(
                "Missing Fields in Bundle " +
                currentBundleIndex +
                " in Item " +
                currentIndex
              );
            }
          });
        }
      } else if (
        itemCategoriesList.find((x) => x.Id === item.CategoryId).Name ===
        "Product"
      ) {
        if (!item.ProductCode) {
          empty = true;
          setSomeRequiredFieldsEmpty(true);
          setItemIndex(currentIndex);
          setRequiredHelperMsg(
            "Product Code is missing in Item " + currentIndex
          );
        }
        if (item.StorageId === null || item.ZoneId === null) {
          empty = true;
          setSomeRequiredFieldsEmpty(true);
          setItemIndex(currentIndex);
          setRequiredHelperMsg(
            "Item Storage Block or Storage Zone is missing in Item " +
            currentIndex
          );
        }
      }
    });
    if (!empty) {
      setItemIndex(0);
      setSomeRequiredFieldsEmpty(false);
      setRequiredHelperMsg("");
      if (action === "adding") {
        ItemDataObject.CategoryId = itemCategoriesList[0].Id;
        // console.log("pushing cz adding a new item plus");
        itemsArray.push(ItemDataObject);

        forceUpdate();

        setItemObject(itemsArray[itemsArray.length - 1]);

        setItemObjectChosen(itemsArray.length - 1);
      }

      if (action === "updateItems") {
        MRItemsUpdate();
      }
    }
  };

  const checkProductZoneCapacity = (
    storageId,
    zoneId,
    productQuantity,
    itemObjectOrBundleObject
  ) => {
    let ZoneChosen = storageBlocksList
      .find((s) => s.BlockId === storageId)
      ?.Zones.find((z) => z.Id === zoneId);

    if (productQuantity > ZoneChosen.AvailableCapacity) {
      setExceedQtyError(true);
    } else {
      itemObjectOrBundleObject.ZoneId = zoneId;
      let QuantityINKg = productQuantity;
      ZoneChosen.AvailableCapacity =
        ZoneChosen.AvailableCapacity - QuantityINKg;
      setExceedQtyError(false);
    }
    forceUpdate();
  };

  const checkRMZoneCapacity = (
    storageId,
    zoneId,
    bundleWeight,
    itemObjectOrBundleObject
  ) => {
    let ZoneChosen = storageBlocksList
      .find((s) => s.BlockId === storageId)
      ?.Zones.find((z) => z.Id === zoneId);

    if (bundleWeight > ZoneChosen.AvailableCapacity) {
      setExceedQtyError(true);
    } else {
      itemObjectOrBundleObject.ZoneId = zoneId;
      let BundleWeightINKg = bundleWeight;
      ZoneChosen.AvailableCapacity =
        ZoneChosen.AvailableCapacity - BundleWeightINKg;
      setExceedQtyError(false);
    }
    forceUpdate();
  };

  const addOldZoneCapacity = (
    zoneId,
    storageId,
    bundleWeightOrItemQuantity
  ) => {
    if (zoneId !== null && storageId !== null) {
      let ZoneChosen = storageBlocksList
        .find((s) => s.BlockId === storageId)
        ?.Zones.find((z) => z.Id === zoneId);

      ZoneChosen.AvailableCapacity =
        ZoneChosen.AvailableCapacity + bundleWeightOrItemQuantity;
      forceUpdate();
    }
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
        objectFit: "cover",
      }}
    >
      <Grid container paddingTop={4} justifyContent="center" rowGap={2}>
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap={1.5}
        >
          <Grid item className={classes["title"]}>
            Material Receipt
          </Grid>
          <Grid
            item
            sm={5}
            md={6.8}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          >
            {" "}
          </Grid>
          <Grid
            item
            xs={5}
            sm={2.6}
            md={1.5}
            container
            direction="column"
            paddingTop={1}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
              e.target.style.fontWeight = "bold";
            }}
            onMouseOut={(e) => {
              e.target.style.fontWeight = "normal";
            }}
          >
            <Grid
              item
              className={classes["viewText"]}
              onClick={() => {
                navigate(
                  "/saas/admin/inventoryManagement/materialReceiptTableList"
                );
              }}
            >
              View All Material Receipt
            </Grid>
            <Grid
              item
              className={classes["viewTextBlue"]}
              onClick={() => {
                navigate(
                  `/saas/admin/inventoryManagement/materialReceiptBundlesPrintView?MRId=${mrId}`
                );
              }}
            >
              View Bundles of All Items
            </Grid>
          </Grid>
          <Grid item>
            {" "}
            <button
              className={classes["back-button"]}
              onClick={() => {
                navigate(-1);
              }}
            >
              Go Back
            </button>
          </Grid>
        </Grid>

        <Grid
          item
          container
          sm={11}
          md={10}
          backgroundColor="#fff"
          borderRadius="5px"
          padding={"2% 2% 2% 0"}
          margin={"2% 2% 2% 2%"}
          className={classes["fieldsTitle"]}
          paddingBottom={10}
          marginBottom={10}
          columnSpacing={2}
          rowGap={1.5}
        >
          <Backdrop
            sx={{
              color: "#fff",
              backgroundColor: "transparent",
              zIndex: (theme) => theme.zIndex.drawer + 1,
              display: "flex",
              flexDirection: "column",
            }}
            open={loading || loadingMRInputData}
          >
            <CircularProgress />

            <Grid
              item
              fontFamily={"Muli"}
              fontSize={15}
              fontWeight={"bold"}
              color={"rgb(25, 118, 210)"}
            >
              Loading Data...
            </Grid>
          </Backdrop>
          <Grid item container direction="column" xs={6} sm={3} md={2} gap={1}>
            <Grid item>Material Receipt Code</Grid>

            <Grid item className={classes["Rectangle"]}>
              {materialReceiptCode
                ? materialReceiptCode
                : "Material Receipt Code"}
            </Grid>
          </Grid>
          <Grid item container direction="column" xs={6} sm={3} md={2} gap={1}>
            <Grid item>*Purchase Order Ref</Grid>

            <Grid item>
              <TextField
                value={purchaseOrderRef}
                error={purchaseOrderRefError}
                helperText={
                  purchaseOrderRefError ? "Enter a Purchase Order Ref" : ""
                }
                multiline
                rows={1}
                placeholder="Purchase Order Ref"
                InputProps={{ className: classes.textField }}
                onBlur={(e) => {
                  if (!e.target.value) {
                    setPurchaseOrderRefError(true);
                  }
                }}
                onChange={(e) => {
                  setPurchaseOrderRef(e.target.value);
                  if (!e.target.value) {
                    setPurchaseOrderRefError(true);
                  } else setPurchaseOrderRefError(false);
                }}
              />
            </Grid>
          </Grid>
          <Grid item container direction="column" xs={6} sm={3} md={2} gap={1}>
            <Grid item>Input Date</Grid>

            <Grid item className={classes["Rectangle"]}>
              {inputDate ? inputDate.slice(0, 10) : "Input Date"}
            </Grid>
          </Grid>
          <Grid item container direction="column" xs={6} sm={3} md={2} gap={1}>
            <Grid item>Supplier Code</Grid>

            <Grid item>
              <TextField
                value={supplierCode}
                multiline
                rows={1}
                placeholder="Supplier Code"
                InputProps={{ className: classes.textField }}
                onChange={(e) => {
                  setSupplierCode(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            xs={6}
            sm={3}
            md={2}
            spacing={1}
          >
            <Grid item>*Supplier Name</Grid>
            <Grid item>
              <TextField
                value={supplierName}
                error={supplierNameError}
                helperText={supplierNameError ? supplierNameErrorMsg : ""}
                multiline
                rows={1}
                placeholder="Supplier Name"
                InputProps={{ className: classes.textField }}
                onBlur={(e) => {
                  if (!e.target.value) {
                    setSupplierNameError(true);
                    setSupplierNameErrorMsg("Enter a Supplier Name");
                  }
                }}
                onChange={(e) => {
                  setSupplierName(e.target.value);
                  if (!e.target.value) {
                    setSupplierNameError(true);
                    setSupplierNameErrorMsg("Enter a Supplier Name");
                  } else if (e.target.value.length > 15) {
                    setSupplierNameError(true);
                    setSupplierNameErrorMsg(
                      "Supplier Name should be less than 15 characters"
                    );
                  } else setSupplierNameError(false);
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            xs={6}
            sm={3}
            md={2}
            spacing={1}
          >
            <Grid item>Supplier Address</Grid>
            <Grid item>
              <TextField
                value={supplierAddress}
                multiline
                rows={1}
                placeholder="Supplier Address"
                InputProps={{ className: classes.textField }}
                onChange={(e) => {
                  setSupplierAddress(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            xs={6}
            sm={3}
            md={2}
            spacing={1}
          >
            <Grid item>*Supplier Ref</Grid>
            <Grid item>
              <TextField
                value={supplierRef}
                error={supplierRefError}
                helperText={supplierRefError ? supplierRefErrorMsg : ""}
                multiline
                rows={1}
                placeholder="Supplier Ref"
                InputProps={{ className: classes.textField }}
                onBlur={(e) => {
                  if (!e.target.value) {
                    setSupplierRefError(true);
                    setSupplierRefErrorMsg("Enter a Supplier Ref");
                  }
                }}
                onChange={(e) => {
                  setSupplierRef(e.target.value);
                  if (!e.target.value) {
                    setSupplierRefError(true);
                    setSupplierRefErrorMsg("Enter a Supplier Ref");
                  } else if (e.target.value.length > 15) {
                    setSupplierRefError(true);
                    setSupplierRefErrorMsg(
                      "Supplier Ref should be less than 15 characters"
                    );
                  } else setSupplierRefError(false);
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            xs={6}
            sm={3}
            md={2}
            spacing={1}
          >
            <Grid item>Comment</Grid>
            <Grid item>
              <TextField
                value={comment}
                rows={1}
                placeholder="Comment"
                InputProps={{ className: classes.textField }}
                onChange={(e) => {
                  setComment(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid item container direction="column" xs={6} sm={3} md={2} gap={1}>
            <Grid item>Total Quantity (Tons)</Grid>
            <Grid item className={classes["Rectangle"]}>
              {updatingTotalQuantity
                ? "Updating ..."
                : totalQuantity
                  ? Number(totalQuantity).toLocaleString()
                  : 0}
            </Grid>
          </Grid>

          <Grid item container direction="column" xs={6} sm={3} md={2} gap={1}>
            <Grid item>Total Price</Grid>
            <Grid item className={classes["Rectangle"]}>
              {updatingTotalPrice
                ? "Updating ..."
                : totalPrice ? Number(totalPrice).toLocaleString()
                  : 0}
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            xs={6}
            sm={3}
            md={2}
            spacing={1}
          >
            <Grid item>*Currency</Grid>

            <Grid item width="100%">
              {" "}
              <FormControl fullWidth error={currencyIdError}>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                    PaperProps: {
                      sx: {
                        maxHeight: {
                          xs: "130px",
                        },
                      },
                    },
                  }}
                  value={currencyId ? currencyId : 0}
                  fullWidth
                  sx={{
                    height: "34px",
                    fontFamily: "Muli",
                    fontSize: "13px",
                  }}
                  defaultValue={0}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setCurrencyIdError(true);
                    }
                  }}
                  onChange={(e) => {
                    setCurrencyId(e.target.value);

                    if (!e.target.value) {
                      setCurrencyIdError(true);
                    } else setCurrencyIdError(false);
                  }}
                >
                  <MenuItem value={0} key={0} disabled>
                    Currency
                  </MenuItem>
                  {currenciesList?.map((r) => (
                    <MenuItem
                      value={r.Id}
                      key={r.Id}
                      style={{
                        display: "block",
                        fontFamily: "Muli",
                        fontSize: "13px",
                      }}
                    >
                      {r.Name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText sx={{ color: "#d32f2f" }}>
                  {currencyIdError ? "Choose a Currency" : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          {openCurrencyForm ? (
            <Grid
              item
              container
              direction="column"
              rowGap={0.5}
              style={{ backgroundColor: "rgba(6, 116, 185, 0.3)" }}
              className={classes["fieldsTitleCurrency"]}
              justifyContent="flex-start"
              alignItems="flex-start"
              padding={1}
              xs={6}
              sm={3}
              md={1.9}
              borderRadius="5px"
              marginLeft={0.5}
              paddingTop={2}
            >
              <Grid item>*Currency Name</Grid>
              <Grid item>
                <TextField
                  value={currencyName}
                  placeholder="Currency Name"
                  InputProps={{ className: classes.textFieldCurrency }}
                  onChange={(e) => {
                    setCurrencyName(e.target.value);
                  }}
                />
              </Grid>
              <Grid item>*Currency Code</Grid>
              <Grid item>
                <TextField
                  value={currencyCode}
                  placeholder="Currency Code"
                  InputProps={{ className: classes.textFieldCurrency }}
                  onChange={(e) => {
                    setCurrencyCode(e.target.value);
                  }}
                />
              </Grid>
              <Grid item>*Currency Symbol</Grid>
              <Grid item>
                <TextField
                  value={currencySymbol}
                  placeholder="Currency Symbol"
                  InputProps={{ className: classes.textFieldCurrency }}
                  onChange={(e) => {
                    setCurrencySymbol(e.target.value);
                  }}
                />
              </Grid>
              <Grid
                item
                container
                direction="row-reverse"
                spacing={2}
                paddingTop={4}
                alignItems="center"
              >
                <Grid item>
                  <button
                    disabled={!currencyName || !currencyCode || !currencySymbol}
                    className={classes["save-button"]}
                    onClick={() => {
                      let tmpCurrenciesList = currenciesList;
                      let currencyObject = {
                        Id: currenciesList.length + 1,
                        Name: currencyName,
                        Code: currencyCode,
                        Symbol: currencySymbol,
                      };
                      setCurrencyDataObject(currencyObject);
                      setCurrenciesList(
                        tmpCurrenciesList.concat(currencyObject)
                      );
                      setCurrencyId(currencyObject.Id);
                      forceUpdate();
                      setCurrencyName(null);
                      setCurrencyCode(null);
                      setCurrencySymbol(null);
                      setOpenCurrencyForm(false);
                    }}
                  >
                    Add
                  </button>
                </Grid>
                <Grid item>
                  <button
                    className={classes["cancel-button"]}
                    onClick={() => {
                      setCurrencyName(null);
                      setCurrencyCode(null);
                      setCurrencySymbol(null);
                      setOpenCurrencyForm(false);
                    }}
                  >
                    Cancel
                  </button>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item height="20px">
              <IconButton
                edge="end"
                onClick={openTip}
                onMouseOver={openTip}
                onMouseLeave={closeTip}
              >
                <BlueTip
                  open={tooltipIsOpen}
                  title="Add new currency"
                  placement="right"
                >
                  <img
                    src={plusYellow}
                    alt={"close"}
                    width="18px"
                    onMouseOver={(e) => (e.target.style.cursor = "pointer")}
                    onClick={(e) => setOpenCurrencyForm(true)}
                  ></img>
                </BlueTip>
              </IconButton>
            </Grid>
          )}

          <Grid item container direction="column" xs={12} spacing={1}>
            <Grid item>*Mill Test Certificate </Grid>
            <Grid
              item
              container
              direction="row"
              spacing={2}
              alignItems="center"
            >
              <Grid item>
                <Button
                  component="label"
                  style={{
                    width: "190px",
                    textTransform: "none",
                    fontFamily: "Muli",
                    fontSize: "12px",
                    textAlign: "left",
                    color: "#101650",
                    backgroundColor: "white",
                    border: "solid 1px #b8b8b8",
                    display: "flex",
                    justifyContent: "flex-start",
                    height: "34px",
                  }}
                >
                  <FileUploadOutlinedIcon
                    htmlColor="#529bd7"
                    style={{
                      alignSelf: "center",
                      paddingRight: "5%",
                    }}
                  />
                  <span style={{ alignSelf: "center" }}>Upload File</span>
                  <input type="file" hidden onChange={handleFileChange} />
                </Button>
              </Grid>
              {loadingFile ? (
                <>
                  <Grid item className={classes["blueText"]}>
                    {" "}
                    {loadingFileMsg}{" "}
                  </Grid>
                  <Grid item>
                    <CircularProgress size={20} />
                  </Grid>
                </>
              ) : (
                <></>
              )}
              {!canDeleteFile ? (
                <Grid item className={classes["errorMsg"]}>
                  {" "}
                  Cannot Delete this File since it has dependency with some
                  Bundles in Item {dependencyIndex}
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          {files?.length >= 0 ? (
            files?.map((mtc) => (
              <Grid
                item
                container
                direction="row"
                spacing={1.5}
                alignItems="center"
                key={mtc.FileId}
              >
                <Grid
                  item
                  xs={1.5}
                  sm={2}
                  md={2}
                  marginTop={2}
                  textAlign="center"
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={() => {
                    window.open(mtc.URL);
                  }}
                >
                  <img src={fileIcon} />
                </Grid>

                <Grid
                  item
                  container
                  direction="column"
                  xs={5.5}
                  sm={4}
                  md={2.5}
                  spacing={1}
                >
                  <Grid item>*Mtc Doc Name</Grid>
                  <Grid item>
                    <TextField
                      placeholder="Mtc Doc Name"
                      value={mtc.DisplayName}
                      InputProps={{ className: classes.textField }}
                      onChange={(e) => {
                        mtc.DisplayName = e.target.value;
                        forceUpdate();
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  className={classes["blueText"]}
                  xs={3.5}
                  sm={2}
                  md={1}
                  alignSelf="center"
                  marginTop={2}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                    e.target.style.fontWeight = "bold";
                  }}
                  onMouseOut={(e) => (e.target.style.fontWeight = "normal")}
                  onClick={() => {
                    setFileMtcDocName(mtc.DisplayName);

                    itemsArray?.map((i) => {
                      i.Bundles?.map((b) => {
                        if (b.MTCId === mtc.Id) {
                          if (!heatNbsArray.includes(b.HeatNum)) {
                            heatNbsArray.push(b.HeatNum);
                          }
                        }
                      });
                    });

                    setOpenModal(true);
                  }}
                >
                  View Heat No.
                </Grid>
                <Grid
                  item
                  alignSelf="center"
                  marginTop={2}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={() => {
                    FileDelete(mtc);
                    setFile(null);
                    setFileId(null);
                    forceUpdate();
                  }}
                >
                  {" "}
                  <img src={deleteYellow} />
                </Grid>
              </Grid>
            ))
          ) : (
            <></>
          )}

          {mrId ? (
            <Grid
              item
              container
              direction="row-reverse"
              spacing={2}
              paddingTop={4}
              alignItems="center"
            >
              <Grid item>
                <button
                  disabled={isDisabled}
                  className={classes["save-button"]}
                  onClick={() => {
                    MRUpperDataUpdate();
                  }}
                >
                  Update MR
                </button>
              </Grid>

              {successMRUpperDataUpdate && !loading ? (
                <>
                  <Grid item className={classes["greenText"]}>
                    Successfully Updated
                  </Grid>
                </>
              ) : (
                <></>
              )}
            </Grid>
          ) : (
            <Grid
              item
              container
              direction="row-reverse"
              spacing={2}
              paddingTop={4}
              alignItems="center"
            >
              <Grid item>
                <button
                  disabled={isDisabled}
                  className={classes["save-button"]}
                  onClick={() => {
                    MRCreate(InitialCreateEmptyItems);
                  }}
                >
                  Create
                </button>
              </Grid>

              {successMRCreate && !loading ? (
                <>
                  <Grid item className={classes["greenText"]}>
                    Succesfully Created
                  </Grid>
                </>
              ) : (
                <></>
              )}
            </Grid>
          )}

          <Grid
            item
            container
            direction="row"
            xs={12}
            spacing={1}
            paddingTop={3.5}
          >
            <Grid item className={classes["itemsTitle"]}>
              Items
            </Grid>
            <Grid
              item
              onMouseOver={(e) => {
                if (
                  !updatingRM ||
                  !updatingBundles ||
                  updatingTotalPrice ||
                  exceedQtyError
                )
                  e.target.style.cursor = "pointer";
              }}
              onClick={() => {
                checkRequiredFieldsInItemsArray("adding");
              }}
            >
              <img src={plusYellow} />
            </Grid>
          </Grid>
          <Grid item container direction="row" spacing={2} alignItems="center">
            <Grid item className={classes["blueText"]}>
              {" "}
              User add the items found in the receipt
            </Grid>

            <Grid item className={classes["Title"]} fontSize="14px">
              Items:
            </Grid>
            <Grid item>
              <CustomSelect
                style={{ color: "#707070" }}
                value={itemObjectChosen}
                onChange={(option) => {
                  setItemObjectChosen(option);
                  const objectToView = itemsArray[option];
                  setItemObject(objectToView);
                }}
              >
                {itemsArray?.map((item, index) => (
                  <StyledOption key={index} value={index}>
                    Item {index + 1}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Grid>
          </Grid>

          {itemObject ? (
            <Grid item container xs={12}>
              {renderItemFields(itemObject, itemObjectChosen)}
            </Grid>
          ) : (
            <></>
          )}

          <Grid
            item
            container
            direction="row-reverse"
            spacing={2}
            paddingTop={4}
            alignItems="center"
          >
            <Grid item>
              <button
                // disabled={mrId ? isDisabled : true}
                disabled={
                  originalItemsArrayLength === itemsArray.length
                    ? true
                    : mrId
                      ? isDisabled
                      : true
                }
                className={classes["save-button"]}
                onClick={() => {
                  checkRequiredFieldsInItemsArray("updateItems");
                }}
              >
                {mrId ? "Update Items" : "Add Items"}
              </button>
            </Grid>

            <Grid item>
              <button
                className={classes["cancel-button"]}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </button>
            </Grid>
            {someRequiredFieldsEmpty ? (
              <Grid item className={classes["errorMsg"]}>
                {/* Some Required Fields are Empty in Item {itemIndex} */}
                {requiredHelperMsg}
              </Grid>
            ) : (
              ""
            )}

            <Grid item className={classes["greenText"]}>
              {successItemsUpdate && !loading
                ? "Items Succesfully Updated"
                : ""}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <HeatNbModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          fileMtcDocName={fileMtcDocName}
          heatNbsArray={heatNbsArray}
          setHeatNbsArray={setHeatNbsArray}
        />
      </Grid>
      <ErrorModal openErrorModal={openErrorModal} setOpenErrorModal={setOpenErrorModal} BEerror={BEerror} />

    </div>
  );
}

export default MaterialReceiptCreationForm;

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: Muli;
  font-size: 10px;
  box-sizing: border-box;
  height: 35px;
  width: 9rem;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  padding: 10px;
  text-align: left;
  line-height: 1.5;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};

  &:hover {
    background: ${theme.palette.mode === "dark" ? "" : grey[100]};
    border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  cursor: pointer;
  `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
  font-family: "Muli";
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  width: 9rem;
  height:120px;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  overflow: scroll;
  outline: 0px;
  
  `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 5px;
  border-radius: 0.45em;
  cursor: pointer;
  margin-top: 3px;
  margin-bottom: 3px;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }
  `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});
