import React, { useState, useEffect, useMemo } from "react";
import { Grid, TextField, MenuItem } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import SaasBack from "../../../../Assets/Images/Projects/bgTableProject.png";
import classes from "../../../../Assets/Styles/InventorySetup.module.css";
import CreationFrom from "./Item Category/CreationFrom";
import ItemDimension from "./Item Dimensions/ItemDimension";
import ItemReference from "./Item References/ItemReference";
import ItemValues from "./Item Values/ItemValues";
import ItemCode from "./Item Code And Description/ItemCode";
import PrintingRemenant from "./Item Values/PrintingRemenant";
const InventoryControlSystem = (props) => {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);
  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);
  const navigate = useNavigate();
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const [factoryId, setFactoryId] = useState(query?.get("id"));
  const [activeTab, setActiveTab] = useState(ITEM_CATEGORIES);
  const [inventoryId, setInventoryId] = useState(null);
  const [code, setCode] = useState(null);
  const [printingRemnant, setPrintingRemnant] = useState(null);
  const [form1Showed, setForm1Showed] = useState(true);
  const [manageStocks, setManageStocks] = useState(false);

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      {printingRemnant ? (
        <PrintingRemenant
          setShowSaaSNavbar={props.setShowSaaSNavbar}
          setPrintingRemnant={setPrintingRemnant}
        />
      ) : (
        <>
          {" "}
          <Grid item xs={12} container rowSpacing={3} padding="2.5% 0 0 8%">
            <Grid item xs={12} container alignItems="center">
              <Grid
                item
                xs={10}
                sm={code ? 6 : 2}
                md={code ? 4 : 1.4}
                lg={code ? 3.4 : 1.4}
                sx={{
                  fontFamily: "Muli",
                  fontSize: 18,
                  fontWeight: "bold",
                  lineHeight: 1.44,
                  textAlign: "left",
                  color: "#101650",
                }}
              >
                Inventory Setup - {code ? code : " "}
              </Grid>
              <Grid
                item
                xs={0}
                sm={code ? 4.2 : 8.2}
                md={code ? 6.4 : 9}
                lg={code ? 7 : 9}
                sx={{
                  border: "solid 0.1px #fff",
                  backgroundColor: "#fff",
                  height: 0,
                }}
              ></Grid>

              <Grid
                item
                paddingLeft={2}
                onClick={() => {
                  navigate(-1);
                }}
              >
                <button className={classes["Go-Back"]}>Go Back</button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container padding="2.5% 0 0 8%">
            <Grid item container direction={"row"} xs={12} sm={11.4} md={10.9}>
              <Grid
                item
                paddingTop={2}
                fontSize={{ lg: 21, md: 18, sm: 15, xs: 12 }}
                className={
                  activeTab === ITEM_CATEGORIES
                    ? classes["ActiveInventoryTabCategory"]
                    : classes["Inventory-Tabs"]
                }
                xs={2.4}
              >
                Item Categories
              </Grid>
              <Grid
                item
                paddingTop={2}
                fontSize={{ lg: 21, md: 18, sm: 15, xs: 12 }}
                className={
                  activeTab === ITEM_DIMENSIONS
                    ? classes["ActiveInventoryTab"]
                    : classes["Inventory-Tabs"]
                }
                xs={2.4}
              >
                Item Dimensions
              </Grid>
              <Grid
                item
                paddingTop={2}
                fontSize={{ lg: 21, md: 18, sm: 15, xs: 12 }}
                className={
                  activeTab === ITEM_REFERENCES
                    ? classes["ActiveInventoryTab"]
                    : classes["Inventory-Tabs"]
                }
                xs={2.4}
              >
                Item References
              </Grid>
              <Grid
                item
                paddingTop={2}
                fontSize={{ lg: 21, md: 18, sm: 15, xs: 12 }}
                className={
                  activeTab === ITEM_VALUES
                    ? classes["ActiveInventoryTab"]
                    : classes["Inventory-Tabs"]
                }
                xs={2.4}
              >
                Item Values
              </Grid>
              <Grid
                item
                fontSize={{ lg: 21, md: 18, sm: 15, xs: 12 }}
                className={
                  activeTab === ITEM_CODE
                    ? classes["ActiveInventoryTab"]
                    : classes["Inventory-Tabs"]
                }
                xs={2.4}
              >
                Item Code &<Grid item> Description</Grid>
              </Grid>
            </Grid>
            <Grid
              container
              gap={3}
              item
              xs={12}
              sm={11.4}
              md={10.9}
              className={classes["FormDesign"]}
              paddingTop={5}
              paddingLeft={5.5}
              paddingBottom={5}
            >
              {activeTab === ITEM_CATEGORIES ? (
                <CreationFrom
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  factoryId={factoryId}
                  setFactoryId={setFactoryId}
                  inventoryId={inventoryId}
                  setInventoryId={setInventoryId}
                  code={code}
                  setCode={setCode}
                />
              ) : activeTab === ITEM_DIMENSIONS ? (
                <ItemDimension
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  inventoryId={inventoryId}
                  setInventoryId={setInventoryId}
                />
              ) : activeTab === ITEM_REFERENCES ? (
                <ItemReference
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  inventoryId={inventoryId}
                  setInventoryId={setInventoryId}
                />
              ) : activeTab === ITEM_VALUES ? (
                <ItemValues
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  inventoryId={inventoryId}
                  setInventoryId={setInventoryId}
                  setPrintingRemnant={setPrintingRemnant}
                  form1Showed={form1Showed}
                  setForm1Showed={setForm1Showed}
                  manageStocks={manageStocks}
                  setManageStocks={setManageStocks}
                />
              ) : activeTab === ITEM_CODE ? (
                <ItemCode
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  inventoryId={inventoryId}
                  setInventoryId={setInventoryId}
                />
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};
const ITEM_CATEGORIES = 1;
const ITEM_DIMENSIONS = 2;
const ITEM_REFERENCES = 3;
const ITEM_VALUES = 4;
const ITEM_CODE = 5;

export default InventoryControlSystem;
