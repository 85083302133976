import * as constFile from "../../LoadingConst";
import axios from "axios";

//create-load
const CREATE_LOAD = constFile.CREATE_LOAD;
export const createLoad = (data) => {
    const token = localStorage.getItem("accessToken");
    return axios
        .post(`${process.env.REACT_APP_URL + CREATE_LOAD}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

//update-load
const UPDATE_LOAD = constFile.UPDATE_LOAD;
export const updateLoad = (data) => {
    const token = localStorage.getItem("accessToken");
    return axios
        .post(`${process.env.REACT_APP_URL + UPDATE_LOAD}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

//get-load-input-data
const GET_INPUT_DATA = constFile.GET_INPUT_DATA;
export const getInputData = (factoryId, loadId) => {
    const token = localStorage.getItem("accessToken");
    return axios
        .get(`${process.env.REACT_APP_URL + GET_INPUT_DATA}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                FactoryId: factoryId,
                LoadId: loadId
            }
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const GET_LOADING_CALENDAR = constFile.GET_LOADING_CALENDAR;
export const getLoadingCalendar = (Date, FactoryId) => {
    const token = localStorage.getItem("accessToken");
    return axios
        .get(`${process.env.REACT_APP_URL + GET_LOADING_CALENDAR}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                Date: Date,
                FactoryId: FactoryId,
            },
        })

        .then((response) => {
            return response.data;
        })

        .catch((error) => {
            return error.response.data;
        });
};

//add-line/scan-barcodes
const ADD_LINES = constFile.ADD_LINES;
export const scanBarcodes = (data) => {
    const token = localStorage.getItem("accessToken");
    return axios
        .post(`${process.env.REACT_APP_URL + ADD_LINES}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

//validate-lines
const VALIDATE_LINE = constFile.VALIDATE_LINE;
export const validateLines = (loadId) => {
    const token = localStorage.getItem("accessToken");
    return axios
        .get(`${process.env.REACT_APP_URL + VALIDATE_LINE}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                Id: loadId
            }
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

//get-lines-input-data
const GET_LINE_DATA = constFile.GET_LINE_DATA;
export const getLinesData = (loadId) => {
    const token = localStorage.getItem("accessToken");
    return axios
        .get(`${process.env.REACT_APP_URL + GET_LINE_DATA}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                Id: loadId
            }
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const GET_LOADING_ORDERS = constFile.GET_LOADING_ORDERS;
export const getLoadingSalesOrderTable = (data) => {
    const token = localStorage.getItem("accessToken");
    return axios
        .post(
            `${process.env.REACT_APP_URL + GET_LOADING_ORDERS}`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )

        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};