import React, { useState, useEffect } from "react";
import { Button, Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import classes from "./Create.module.css";
import ClientModal from "./ClientModal";
import CircularProgress from "@mui/material/CircularProgress";


const styles = {
  label: {
    fontFamily: "Muli",
    fontSize: 12,
    fontWeight: "bold",
    lineHeight: 1.5,
    textAlign: "left",
    color: "#101650",
  },
  error: {
    fontFamily: "Muli",
    fontSize: 12,
    lineHeight: 1.5,
    textAlign: "left",
    color: "#d32f2f",
    paddingTop: "1.5%",
  },
};

export default function ProjectDetails(props) {
  const [pnError, setPnError] = useState(false);
  const [pnInvalid, setPnInvalid] = useState(false);

  const [ptError, setPtError] = useState(false);
  const [ptoError, setPtoError] = useState(false);
  const [pvError, setPvError] = useState(false);
  const [pcError, setPcError] = useState(false);
  const [paError, setPaError] = useState(false);
  const [paInvalid, setPaInvalid] = useState(false);

  const [pCodeError,setPCodeError] = useState(false);
  const [pcInvalid, setPcInvalid] = useState(false);

  const [pdInvalid, setPdInvalid] = useState(false);
  const [cnError, setCnError] = useState(false);
  const [psError, setPsError] = useState(false);
  const [peError, setPeError] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [modify, setModify] = useState(false);

  useEffect(() => {
    let apitest = true;
    if (props.client && apitest) {
      let arr = props.clients;
      let arr2 = arr.filter((obj) => obj.Name !== props.client.Name);
      arr2.push(props.client);
      props.setClientName(props.client);
      props.setClients(arr2);
      arr = [];
      arr2 = [];
    }
    return () => {
      apitest = false;
    };
  }, [props.client]);

  useEffect(() => {
    if (
      !props.projectName ||
      !props.projectType ||
      !props.projectVisibility ||
      !props.projectCountry ||
      !props.projectAddress ||
      !props.clientName ||
      !props.projectStarts ||
      !props.projectEnds ||
      !props.projectCode || 
      pnError ||
      pnInvalid ||
      ptError ||
      pvError ||
      pcError ||
      paError ||
      paInvalid ||
      pcInvalid ||
      pCodeError || 
      cnError ||
      psError ||
      peError ||
      ptoError
    ) {
      props.setIsDisabled(true);
    } else {
      props.setIsDisabled(false);
    }
  });
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="flex-start"
      spacing={2}
      bgcolor="#fff"
      padding="5% 0 3% 0"
    >
      {props.projectId && !props.projectName ? (
        <Grid
          item
          xs={12}
          container
          sx={{
            position: "relative",
            left: "45%",
          }}
        >
          <Grid item xs={12} paddingLeft="2%">
            <CircularProgress size={24} />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              fontfamily: "Muli",
              fontSize: 18,
              lineHeight: 1.44,
              textAlign: "left",
              color: "#0674b9",
            }}
          >
            Loading ...
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <Grid item xs={4.2} container>
        <Grid item xs={12} sx={styles.label}>
          *Project Name
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            disabled={props.viewdisable}
            variant="standard"
            value={props.projectName}
            error={pnError}
            helperText={
              pnError
                ? pnInvalid
                  ? "Project Name must be less than 150 characters"
                  : "Enter a Project Name"
                : ""
            }
            onBlur={(e) => {
              if (!e.target.value) setPnError(true);
            }}
            onChange={(e) => {
              props.setProjectName(e.target.value);
              if (e.target.value.length > 150) {
                setPnError(true);
                setPnInvalid(true);
              } else {
                setPnError(false);
                setPnInvalid(false);
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={1.6}></Grid>
      <Grid item xs={4.2} container>
        <Grid item xs={12} sx={styles.label} marginTop="1.5%">
          *Project Type
        </Grid>
        <Grid item xs={12}>
          <FormControl
            variant="standard"
            fullWidth
            disabled={props.viewdisable}
          >
            <Select
              error={ptError}
              MenuProps={{ disableScrollLock: true }}
              value={props.projectType}
              onBlur={(e) => {
                if (!e.target.value) setPtError(true);
              }}
              onChange={(e) => {
                props.setProjectType(e.target.value);
                setPtError(false);
                if (
                  e.target.value !==
                  props.types?.find((x) => x.Name === "Others")?.Id
                )
                  props.setProjectTypeOther("");
              }}
            >
              {props.types?.map((option, index) => (
                <MenuItem
                  value={option.Id}
                  key={index}
                  style={{ display: "block" }}
                >
                  {option.Name}
                </MenuItem>
              ))}
            </Select>
            <div style={styles.error}>
              {ptError ? "Enter a Project Type" : ""}
            </div>
          </FormControl>
          {props.projectType ===
            props.types?.find((x) => x.Name === "Others")?.Id ? (
            <>
              <Grid item sx={styles.label}>
                *Other Type
              </Grid>
              <Grid item>
                <TextField
                  variant="standard"
                  fullWidth
                  value={props.projectTypeOther}
                  size="small"
                  error={ptoError}
                  helperText={ptoError ? "Enter a Project Type" : ""}
                  onBlur={(e) => {
                    if (!e.target.value) setPtoError(true);
                  }}
                  onChange={(e) => {
                    props.setProjectTypeOther(e.target.value);
                    setPtError(false);
                  }}
                />
              </Grid>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <Grid item xs={4.2} container>
        <Grid item xs={12} sx={styles.label}>
          *Project Visibility
        </Grid>
        <Grid item xs={12}>
          <FormControl
            variant="standard"
            fullWidth
            disabled={props.viewdisable}
          >
            <Select
              MenuProps={{ disableScrollLock: true }}
              error={pvError}
              value={props.projectVisibility}
              onBlur={(e) => {
                if (!e.target.value) setPvError(true);
              }}
              onChange={(e) => {
                props.setProjectVisibility(e.target.value);
                setPvError(false);
              }}
            >
              {props.visibilities?.map((option, index) => (
                <MenuItem
                  value={option.Id}
                  key={index}
                  style={{ display: "block" }}
                >
                  {option.Name}
                </MenuItem>
              ))}
            </Select>
            <div style={styles.error}>
              {pvError ? "Enter a Project Visibility" : ""}
            </div>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={1.6}></Grid>
      <Grid item xs={4.2} container>
        <Grid item xs={12} sx={styles.label}>
          *Project Country
        </Grid>
        <Grid item xs={12}>
          <FormControl
            variant="standard"
            fullWidth
            disabled={props.viewdisable}
          >
            <Select
              MenuProps={{ disableScrollLock: true }}
              error={pcError}
              value={props.projectCountry}
              onBlur={(e) => {
                if (!e.target.value) setPcError(true);
              }}
              onChange={(e) => {
                props.setProjectCountry(e.target.value);
                setPcError(false);
              }}
            >
              {props.countries?.map((option, index) => (
                <MenuItem
                  value={option.Id}
                  key={index}
                  style={{ display: "block" }}
                >
                  {option.Name}
                </MenuItem>
              ))}
            </Select>
            <div style={styles.error}>
              {pcError ? "Enter a Project Country" : ""}
            </div>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={4.2} container>
        <Grid item xs={12} sx={styles.label}>
          *Project Code
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={props.viewdisable}
            fullWidth
            variant="standard"
            value={props.projectCode}
            error={pCodeError}
            helperText={pCodeError? pcInvalid ? "Code must be less than 30 characters" : "Enter a Project Code": ""}
            onBlur={(e) => {
              if (!e.target.value) setPCodeError(true);
            }}
            onChange={(e) => {
              props.setProjectCode(e.target.value);
              if (!e.target.value) setPCodeError(true);
              if (e.target.value.length > 30) {
                setPCodeError(true);
                setPcInvalid(true);
              } else {
                setPCodeError(false);
                setPcInvalid(false);
                
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={1.6}></Grid>
      <Grid item xs={4.2} container>
        <Grid item xs={12} sx={styles.label}>
          *Project Address
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={props.viewdisable}
            fullWidth
            variant="standard"
            value={props.projectAddress}
            error={paError}
            helperText={
              paError
                ? paInvalid
                  ? "Address must be less than 150 characters"
                  : "Enter an address"
                : ""
            }
            onBlur={(e) => {
              if (!e.target.value) setPaError(true);
            }}
            onChange={(e) => {
              props.setProjectAddress(e.target.value);
              if (e.target.value.length > 150) {
                setPaError(true);
                setPaInvalid(true);
              } else {
                setPaError(false);
                setPaInvalid(false);
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={4.2} container>
        <Grid item xs={12} sx={styles.label}>
          Project Description
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={props.viewdisable}
            fullWidth
            variant="standard"
            multiline
            value={props.projectDescription}
            error={pdInvalid}
            helperText={
              pdInvalid ? "Description must be less than 255 characters" : ""
            }
            onChange={(e) => {
              if (e.target.value.length > 255) {
                setPdInvalid(true);
              } else {
                setPdInvalid(false);
                props.setProjectDescription(e.target.value);
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={1.6}></Grid>
      <Grid item xs={4.2} container>
        <Grid item xs={12} marginTop="1.5%" container alignItems="center">
          <Grid item sx={styles.label}>
            *Client Name
          </Grid>
          <Grid item paddingLeft={1}>
            <button
              onClick={() => {
                setOpenModal(true);
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
                e.target.style.color = "#fff";
                e.target.style.backgroundColor =
                  localStorage.getItem("AccountType") === "1" ? "#e1e319" : localStorage.getItem("AccountType") === "2" ? "#2da4d0" : "#444";
              }}
              onMouseLeave={(e) => {
                e.target.style.cursor = "pointer";
                e.target.style.color =
                  localStorage.getItem("AccountType") === "1" ? "#e1e319" : localStorage.getItem("AccountType") === "2" ? "#2da4d0" : "#444";
                e.target.style.backgroundColor = "#fff";
              }}
              className={
                localStorage.getItem("AccountType") === "1" ? classes.addButtonMC : classes.addButtonSC
              }
            >
              {props.clientName?.Id ? "View" : "Add +"}
            </button>
          </Grid>
          {(props.clientName?.Id) && !props.viewdisable ? (
            <Grid item paddingLeft={1}>
              <button
                onClick={() => {
                  setOpenModal(true);
                  props.setClient(null);
                  props.setClientName("");
                }}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                  e.target.style.color = "#fff";
                  e.target.style.backgroundColor =
                    localStorage.getItem("AccountType") === "1"
                      ? "#e1e319"
                      : localStorage.getItem("AccountType") === "2"
                        ? "#2da4d0"
                        : "#444";
                }}
                onMouseLeave={(e) => {
                  e.target.style.cursor = "pointer";
                  e.target.style.color =
                    localStorage.getItem("AccountType") === "1"
                      ? "#e1e319"
                      : localStorage.getItem("AccountType") === "2"
                        ? "#2da4d0"
                        : "#444";
                  e.target.style.backgroundColor = "#fff";
                }}
                className={
                  localStorage.getItem("AccountType") === "1" ? classes.addButtonMC : classes.addButtonSC
                }
              >
                Add +
              </button>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={12}>
          <FormControl
            variant="standard"
            fullWidth
            disabled={props.viewdisable}
          >
            <Select
              MenuProps={{ disableScrollLock: true }}
              error={cnError}
              value={props.clientName}
              onBlur={(e) => {
                if (!e.target.value) setCnError(true);
              }}
              onChange={(e) => {
                props.setClientName(e.target.value);
                setCnError(false);
              }}
            >
              {props.clients?.map((option, index) => (
                <MenuItem
                  value={option}
                  key={index}
                  style={{ display: "block" }}
                >
                  {option?.Name}
                </MenuItem>
              ))}
            </Select>
            <div style={styles.error}>
              {cnError ? "Enter a client name" : ""}
            </div>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={5.8}></Grid>
      <Grid item xs={2.1} container>
        <Grid item xs={12} sx={styles.label}>
          *Project starts
        </Grid>
        <Grid item xs={12} paddingTop="5%">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              disabled={(props.viewdisable && (!props.join || props.addPhase)) || props.projectMileStoned}
              openTo="day"
              
              views={["year", "month", "day"]}
              label="dd/mm/yyyy"
              disableMaskedInput
              inputFormat="dd/MM/yyyy"
              value={props.projectStarts}
              onChange={(newValue) => {
                var date = new Date(newValue);
                if (newValue === "") {
                  setPsError(true);
                } else {
                  setPsError(false);
                }
                props.setProjectStarts(newValue.toLocaleDateString());
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  InputLabelProps={{
                    style: {
                      fontFamily: "Muli",
                      fontSize: "15px",
                    },
                  }}
                  error={psError}
                  onBlur={() => {
                    if (!props.projectStarts) setPsError(true);
                  }}
                  helperText={psError ? "Enter a date" : <></>}
                  size="small"
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid item xs={2.1} container>
        <Grid item xs={12} sx={styles.label}>
          *Project ends
        </Grid>
        <Grid item xs={12} paddingTop="5%">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              disabled={(props.viewdisable && (!props.join || props.addPhase)) || props.projectMileStoned}
              openTo="day"
              
              minDate={new Date(props.projectStarts)}
              views={["year", "month", "day"]}
              label="dd/mm/yyyy"
              disableMaskedInput
              inputFormat="dd/MM/yyyy"
              value={props.projectEnds}
              onChange={(newValue) => {
                var date = new Date(newValue);
                if (newValue === "") {
                  setPeError(true);
                } else {
                  setPeError(false);
                }
                props.setProjectEnds(newValue.toLocaleDateString());
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  InputLabelProps={{
                    style: {
                      fontFamily: "Muli",
                      fontSize: "15px",
                    },
                  }}
                  error={peError}
                  onBlur={() => {
                    if (!props.projectStarts) setPeError(true);
                  }}
                  helperText={peError ? "Enter a date" : <></>}
                  size="small"
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid item container direction="row-reverse" paddingRight={5}>
        <Button
          disabled={props.isDisabled && !props.join}
          style={{
            borderRadius: 5,
            boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
            backgroundColor: props.isDisabled ? "lightgrey" : "#fff",
            fontFamily: "Muli",
            fontSize: 13,
            fontWeight: "bold",
            lineHeight: 1.46,
            textAlign: "left",
            color: props.isDisabled ? "#fff" : "#0674b9",
            textTransform: "none",
          }}
          onClick={() => {
            props.setItem(false);
            props.setItem1(true);
          }}
        >
          Next
        </Button>
      </Grid>
      <ClientModal
        projectId={props.projectId}
        setCnError={setCnError}
        client={props.client}
        openModal={openModal}
        setOpenModal={setOpenModal}
        setModify={setModify}
        modify={modify}
        setClientName={props.setClientName}
        clientName={props.clientName}
        countries={props.countries}
        setClient={props.setClient}
        viewdisable={props.viewdisable}
        setViewdisable={props.setViewdisable}
      />
    </Grid>
  );
}
