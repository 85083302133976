import React, { useState, useEffect, useMemo } from "react";
import SaasBack from "../../../../Assets/Images/Projects/bgTableProject.png";
import { Grid } from "@mui/material";
import classing from "../../../../Assets/Styles/factory.module.css";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useLocation } from "react-router-dom";
import CreateForm from "./CreateForm";
import { styled } from "@mui/system";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import HumanResources from "./HumanResources";
import EquipmentResources from "./EquipmentResources";
import * as storageBlockServices from "../../../../Services/storage-block-services.proxy";
import { useSelector } from "react-redux";
import * as permissionFunctions from "../../../../../ReusableFunctions";

const CheckIfPermitted = permissionFunctions.CheckIfPermitted;
const getStorageBlockInputData = storageBlockServices.getStorageBlockInputData;
const createStorage = storageBlockServices.createStorage;
const updateStorage = storageBlockServices.updateStorage;

export default function StorageCreation(props) {
  useEffect(() => {
    props.setRegister(true);
  }, [props]);

  useEffect(() => {
    props.setNaved(false);
  }, [props]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [successMsgSaved, setSuccessMsgSaved] = useState(false);
  const [isdisabled, setIsdisabled] = useState(false);
  const [emptyField, setEmptyField] = useState(false);
  const [modify, setModify] = useState(false);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const [BAY_ID, setBAY_ID] = useState(query?.get("id"));
  const [BAY_Nb, setBAY_Nb] = useState(query?.get("bayNb"));

  const STORAGE_ID = query?.get("storageId");
  //#region
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState(false);
  const [type, setType] = useState(0);
  const [category, setCategory] = useState(0);

  const [categories, setCategories] = useState([]);
  const [unitMeasures, setUnitMeasures] = useState([]);

  const [status, setStatus] = useState(1);
  const [mainCharacteristiques, setMainCharacteristiques] = useState(null);
  const [nbOfZones, setNbOfZones] = useState(1);
  const [nbOfResources, setNbOfResources] = useState(0);
  const [zones, setZones] = useState([
    {
      Id: 1,
      OrderId: 1,
      Code: "",
      Length: 0,
      Height: 0,
      Width: 0,
      Diameters: [],
      InputType: "",
      CapacityPerItem: 0,
      CapacityMeasureUnitId: null,
    },
  ]);
  const [resourceType, setResourceType] = useState(0);
  const [resourceTypes, setResourceTypes] = useState([]);
  const [humanResources, setHumanResources] = useState([]);
  const [humanResourcesObject, setHumanResourcesObject] = useState();
  const [humanResourcesObjectChosen, setHumanResourcesObjectChosen] =
    useState(0);
  const [equipmentResources, setEquipmentResources] = useState([]);
  const [equipmentResourcesObject, setEquipmentResourcesObject] = useState();
  const [equipmentResourcesObjectChosen, setEquipmentResourcesObjectChosen] =
    useState(0);

  const [roleGroups, setRoleGroups] = useState([]);
  const [shiftCodes, setShiftCodes] = useState([]);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  //#endregion

  const tableBlockType = query.get("blockType");
  const [factoryId, setFactoryId] = useState(query.get("FactoryId"));

  useEffect(() => {
    if (
      !code ||
      codeError ||
      !category ||
      (!type && category !== 5 && category !== 4) ||
      humanResourcesObject ||
      equipmentResourcesObject
    ) {
      setIsdisabled(true);
    } else {
      setIsdisabled(false);
    }
  });

  useEffect(() => {
    console.log(BAY_ID);
    if (BAY_ID || STORAGE_ID) {
      getStorageBlockInputData(
        BAY_ID ? BAY_ID : null,
        STORAGE_ID ? STORAGE_ID : null
      ).then((x) => {
        console.log(x);
        // let dias = [
        //   { Id: 1, Name: "2" },
        //   { Id: 2, Name: "4" },
        //   { Id: 3, Name: "6" },
        //   { Id: 4, Name: "8" },
        // ];
        // let res = "";
        // dias.map((dia) => {
        //   res = res + dia.Name + " ";
        // });
        // res = res + "of type" + type;
        // setMainCharacteristiques(res);
        setCategories(x.StorageCategories);
        setCode(x.StorageBlock.Code);
        setResourceTypes(x.ResourceTypes);
        setShiftCodes(x.Shifts);
        setEquipmentTypes(x.EquipmentTypes);
        setUnitMeasures(x.CapacityMeasureUnits);
        setRoleGroups(x.RoleGroupTeamMembers);
        if (STORAGE_ID) {
          setBAY_ID(x.StorageBlock.BayId);
          setCode(x.StorageBlock.Code);
          setCategory(x.StorageBlock.CategoryId);
          setType(x.StorageBlock.TypeId);
          // setMainCharacteristiques(x.StorageBlock.MainCharacteristicValues);
          if (x.StorageBlock.Zones?.flatMap((x) => x.Diameters).length > 0) {
            const arrDiameters = x.StorageBlock.Zones?.flatMap(
              (x) => x.Diameters
            );
            const arrDiametersName = arrDiameters?.flatMap((x) => x.Name);

            setMainCharacteristiques(
              // arrDiametersName
              //   .filter(
              //     (item, index) => arrDiametersName.indexOf(item) === index
              //   )
              //   .join(" ") +
              x.StorageBlock.MainCharacteristicValues +
              " of Type " +
              x.StorageCategories.filter(
                (m) => m.Id === x.StorageBlock.CategoryId
              )[0].Name
            );
          }
          setNbOfZones(x.StorageBlock.NumberOfZones);
          setNbOfResources(x.StorageBlock.NumberOfResources);
          setZones(x.StorageBlock.Zones);
          setHumanResources(
            x.StorageBlock.Resources.filter((x) => x.TypeId === 1)
          );
          setEquipmentResources(
            x.StorageBlock.Resources.filter((x) => x.TypeId === 2)
          );
        }
        setLoading(false);
      });
    }
  }, [STORAGE_ID, modify, BAY_ID]);
  const StorageBlockData = {
    Id: STORAGE_ID ? STORAGE_ID : null,
    BayId: BAY_ID,
    Code: code,
    CategoryId: category,
    TypeId: type === 0 ? 1 : type,
    MainCharacteristicValues: mainCharacteristiques,
    Zones: zones,
    Resources: humanResources.concat(equipmentResources),
  };
  const CheckTableFields = () => {
    let StopError = false;
    zones.map((zone) => {
      if (
        !zone.Code ||
        !zone.Length ||
        !zone.Height ||
        !zone.Width ||
        !zone.CapacityPerItem
      ) {
        setEmptyField(true);
        StopError = true;
      } else {
        if (!StopError) {
          setEmptyField(false);
        }
      }
    });
    if (!StopError) {
      SaveStorageBlock();
    }
  };
  const SaveStorageBlock = () => {
    console.log(StorageBlockData);
    setSaveLoading(true);
    if (STORAGE_ID) {
      updateStorage(StorageBlockData).then((x) => {
        setModify(!modify);
        setSaveLoading(false);
        setSuccessMsgSaved(true);
        setTimeout(() => {
          setSuccessMsgSaved(false);
        }, 3000);
      });
    } else {
      createStorage(StorageBlockData).then((x) => {
        setSaveLoading(false);
        setSuccessMsgSaved(true);
        setTimeout(() => {
          setSuccessMsgSaved(false);
        }, 3000);
        if (tableBlockType)
          navigate(
            `/saas/blocksList?BayId=${BAY_ID}&BayNb=${BAY_Nb}&FactoryId=${factoryId}&blockType=1`
          );
        else navigate(-1);
      });
    }
  };
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  const [disabledEdit, setDisabledEdit] = useState(false);
  const [disabledViewStock, setDisabledViewStock] = useState(false);
  const [disabledAddResource, setDisabledAddResource] = useState(false);

  useEffect(() => {
    if (UserPermissions && STORAGE_ID) {
      CheckIfPermitted(
        UserPermissions,
        "Storage Block",
        "Edit",
        setDisabledEdit
      );
      CheckIfPermitted(
        UserPermissions,
        "Storage Block",
        "View stock",
        setDisabledViewStock
      );
      CheckIfPermitted(
        UserPermissions,
        "Storage Block",
        "Add resource",
        setDisabledAddResource
      );
    }
  }, [UserPermissions]);
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <Grid
        item
        container
        paddingLeft={{ xs: "0%", sm: "1%", md: "5%" }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          container
          direction="row"
          gap={1}
          paddingTop={{ xs: "10%", sm: "7%", md: "2%" }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            container
            direction="column"
            lg={1.5}
            sm={2.5}
            xs={2.5}
            paddingTop={{ xs: "4%", sm: "2%", lg: "1%" }}
          >
            <Grid
              item
              fontSize={{ xs: "11px", sm: "18px" }}
              fontWeight="bold"
              className={classing["Title"]}
            >
              Bay # {BAY_Nb}
            </Grid>
          </Grid>

          <Grid
            item
            lg={8.6}
            sm={7.3}
            xs={6}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>

          <Grid item lg={0.9} sm={1}>
            <button
              className={classing["back-button"]}
              onClick={() => {
                if (tableBlockType)
                  navigate(
                    `/saas/blocksList?BayId=${BAY_ID}&BayNb=${BAY_Nb}&FactoryId=${factoryId}&blockType=1`
                  );
                else navigate(-1);
              }}
            >
              Go Back
            </button>
          </Grid>
        </Grid>
        <Grid
          container
          item
          paddingLeft={{ xs: "0%", sm: "1%", md: "3.2%" }}
          fontWeight={"normal"}
          direction={"row"}
        >
          <Grid
            item
            md={1.5}
            xs={2.5}
            paddingLeft={{ xs: 2, md: 0 }}
            className={classing["Title"]}
          >
            Storage Block
          </Grid>
          {humanResources.length > 0 ? (
            <Grid item container xs={3} direction={"row"} gap={0.5}>
              <Grid item className={classing["Title"]}>
                Human Resources
              </Grid>
              <Grid item>
                <CustomSelect2
                  style={{ color: "#707070" }}
                  value={humanResourcesObjectChosen}
                  onChange={(option) => {
                    setHumanResourcesObjectChosen(option);
                    const objectToView = humanResources.filter(
                      (x) => x.Code === option
                    );
                    setHumanResourcesObject(objectToView[0]);
                  }}
                >
                  <StyledOption value={0} disabled>
                    code
                  </StyledOption>
                  {humanResources?.map((s) => (
                    <StyledOption key={s.Id} value={s.Code}>
                      {s.Code}
                    </StyledOption>
                  ))}
                </CustomSelect2>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
          {equipmentResources.length > 0 ? (
            <Grid item container xs={3} direction={"row"} gap={0.5}>
              <Grid item className={classing["Title"]}>
                Equipment Resources
              </Grid>
              <Grid item>
                <CustomSelect2
                  style={{ color: "#707070" }}
                  value={equipmentResourcesObjectChosen}
                  onChange={(option) => {
                    setEquipmentResourcesObjectChosen(option);
                    const objectToView = equipmentResources.filter(
                      (x) => x.Name === option
                    );
                    setEquipmentResourcesObject(objectToView[0]);
                  }}
                >
                  <StyledOption value={0} disabled>
                    Name
                  </StyledOption>
                  {equipmentResources?.map((s) => (
                    <StyledOption key={s.Id} value={s.Name}>
                      {s.Name}
                    </StyledOption>
                  ))}
                </CustomSelect2>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        <Grid
          item
          container
          xs={11.3}
          paddingTop="1%"
          alignItems="flex-start"
          gap={2}
          marginBottom={2}
        >
          {loading ? (
            <Grid item xs={12} container justifyContent="center">
              <Grid item paddingTop="5%">
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <Grid item container xs={12} gap={2}>
              <Grid
                item
                sm={11}
                xs={12}
                md={11}
                lg={9}
                gap={1}
                bgcolor={"#fff"}
                paddingBottom={20}
              >
                <CreateForm
                  BAY_ID={BAY_ID}
                  factoryId={factoryId}
                  code={code}
                  setCode={setCode}
                  codeError={codeError}
                  setCodeError={setCodeError}
                  type={type}
                  setType={setType}
                  category={category}
                  setCategory={setCategory}
                  categories={categories}
                  status={status}
                  mainCharacteristiques={mainCharacteristiques}
                  setMainCharacteristiques={setMainCharacteristiques}
                  nbOfResources={nbOfResources}
                  nbOfZones={nbOfZones}
                  setNbOfResources={setNbOfResources}
                  setNbOfZones={setNbOfZones}
                  zones={zones}
                  setZones={setZones}
                  unitMeasures={unitMeasures}
                  disabledEdit={disabledEdit}
                  disabledViewStock={disabledViewStock}
                />
                <Grid
                  item
                  container
                  xs={12}
                  direction={"row"}
                  paddingLeft={3}
                  paddingRight={3}
                  gap={2}
                >
                  <Grid item>
                    <CustomSelect
                      style={{ color: "#707070" }}
                      value={resourceType}
                      disabled={
                        disabledAddResource ||
                          localStorage?.getItem("planIdManu")?.includes("Core")
                          ? true
                          : humanResourcesObject && equipmentResourcesObject
                            ? true
                            : false
                      }
                      onChange={(option) => {
                        setResourceType(option);
                        if (option === 1) {
                          setHumanResourcesObject({
                            Id: humanResources.length + 1,
                            Code: null,
                            GroupPermissionId: 0,
                            TeamMemberId: 0,
                            ShiftId: 0,
                            TypeId: 1,
                            Barcode: null,
                          });
                        } else if (option === 2) {
                          setEquipmentResourcesObject({
                            Id: equipmentResources.length + 1,
                            Code: null,
                            Name: null,
                            EquipmentTypeId: null,
                            TypeId: 2,
                            Manufacturer: null,
                            ManufacturerProductId: null,
                            ModelYear: null,
                            ManufacturerSerialNumber: "",
                          });
                        }
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      <StyledOption value={0} disabled>
                        + Add a Resource
                      </StyledOption>
                      {resourceTypes?.map((s) => (
                        <StyledOption key={s.Id} value={s.Id}>
                          {s.Name}
                        </StyledOption>
                      ))}
                    </CustomSelect>
                  </Grid>
                  <Grid
                    item
                    className={classing["titlesForResources"]}
                    paddingTop={1}
                  >
                    Human Resources: &nbsp; {+" " + humanResources?.length}
                  </Grid>
                  <Grid
                    item
                    className={classing["titlesForResources"]}
                    paddingTop={1}
                  >
                    Equipment Resources: &nbsp;{" "}
                    {+" " + equipmentResources?.length}
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction={"row-reverse"}
                  gap={2}
                  paddingRight={3}
                  alignItems="center"
                >
                  <button
                    className={classing["SaveBut"]}
                    disabled={isdisabled || saveLoading}
                    onClick={() => {
                      CheckTableFields();
                    }}
                  >
                    {saveLoading ? "Saving" : "Save"}
                  </button>
                  <button
                    onClick={() => {
                      if (tableBlockType)
                        navigate(
                          `/saas/blocksList?BayId=${BAY_ID}&BayNb=${BAY_Nb}&FactoryId=${factoryId}&blockType=1`
                        );
                      else navigate(-1);
                    }}
                    className={classing["cancelbut"]}
                  >
                    Cancel
                  </button>
                  {successMsgSaved ? (
                    <>
                      <Grid item className={classing["TFTitle"]}>
                        Succesfully Saved
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  <Grid
                    item
                    fontFamily={"Muli"}
                    fontSize={12}
                    fontWeight={"bold"}
                    color={"#ea001e"}
                  >
                    {emptyField
                      ? "There is empty fields in the table, Please check."
                      : ""}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4.5} gap={2}>
                {humanResourcesObject ? (
                  <HumanResources
                    humanResourcesObject={humanResourcesObject}
                    setHumanResourcesObject={setHumanResourcesObject}
                    humanResources={humanResources}
                    setHumanResources={setHumanResources}
                    roleGroups={roleGroups}
                    shiftCodes={shiftCodes}
                    nbOfResources={nbOfResources}
                    setNbOfResources={setNbOfResources}
                    setHumanResourcesObjectChosen={
                      setHumanResourcesObjectChosen
                    }
                    setResourceType={setResourceType}
                  />
                ) : (
                  ""
                )}
                {equipmentResourcesObject ? (
                  <Grid item marginTop={2}>
                    <EquipmentResources
                      equipmentResourcesObject={equipmentResourcesObject}
                      setEquipmentResourcesObject={setEquipmentResourcesObject}
                      equipmentResources={equipmentResources}
                      nbOfResources={nbOfResources}
                      setNbOfResources={setNbOfResources}
                      setEquipmentResources={setEquipmentResources}
                      equipmentTypes={equipmentTypes}
                      setEquipmentResourcesObjectChosen={
                        setEquipmentResourcesObjectChosen
                      }
                      setResourceType={setResourceType}
                    />
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
        font-family: Muli;
        font-size: 12px;
        box-sizing: border-box;
        width: 150px;
        height:35px;
        background: #0674b9;
        border: 1px solid #f3f3f3;
        border-radius: 5px;
        text-align: left;
        color: #fff !important;
        padding:5px 7px 5px 5px ;
        border: solid 1px #bcbcbc;
        &:hover {
          background: #fff;
          color:#0674b9 !important;
          cursor: pointer;
        }
      
        &.${selectUnstyledClasses.focusVisible} {
          outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]
    };
        }
      
        &.${selectUnstyledClasses.expanded} {
          &::after {
            content: '▴';
          }
          box-shadow: 0 3px 6px 0 #0674b9;
          background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
          border: solid 1px #0674b9;
          color:#0674b9 !important;
        }
      
        &::after {
          content: '▾';
          float: right;
          padding-left:8px;
        }
        `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
        font-family: Muli;
        font-size: 10px;
        box-sizing: border-box;
        padding: 5px;
        margin: 10px 0;
        width:150px;
        max-height:250px !important;
        background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
        border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]
    };
        border-radius: 0.75em;
        color: #444;
        overflow: auto;
        outline: 0px;
        cursor: pointer
        `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
        list-style: none;
        padding: 8px;
        border-radius: 0.45em;
        cursor: pointer;
      
        &:last-of-type {
          border-bottom: none;
        }
      
        &.${optionUnstyledClasses.selected} {
          background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]
    };
          color: #fff;
        }
      
        &.${optionUnstyledClasses.highlighted} {
          background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]
    };
          color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
        }
      
        &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected
    } {
          background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]
    };
          color: #0674b9;
        }
      
        &.${optionUnstyledClasses.disabled} {
          color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
        }
      
        &:hover:not(.${optionUnstyledClasses.disabled}) {
          background-color:#fff;
          color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
        }
        `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});
const CustomSelect2 = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton2,
    Listbox: StyledListbox2,
    Popper: StyledPopper2,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});
const StyledButton2 = styled("button")(
  ({ theme }) => `
        font-family: Muli;
        font-size: 12px;
        box-sizing: border-box;
        width: 75px;
        height:20px;
        background: #f1f1f1;
       
        border: 1px solid #f3f3f3;
        border-radius: 5px;
        text-align: left;
        color:#0674b9 !important;
        padding:5px 7px 20px 7px ;
        border: solid 1px #bcbcbc;
        &:hover {
          background: #fff;
          color:#0674b9 !important;
        }
      
        &.${selectUnstyledClasses.focusVisible} {
          outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]
    };
        }
      
        &.${selectUnstyledClasses.expanded} {
          &::after {
            content: '▴';
          }
          box-shadow: 0 3px 6px 0 #0674b9;
          background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
          border: solid 1px #0674b9;
          color:#0674b9 !important;
        }
      
        &::after {
          content: '▾';
          float: right;
          padding-left:8px;
          color:#0674b9 !important;
        }
        `
);

const StyledListbox2 = styled("ul")(
  ({ theme }) => `
        font-family: Muli;
        font-size: 10px;
        box-sizing: border-box;
        padding: 5px;
        margin: 10px 0;
        width:150px;
        max-height:250px !important;
        background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
        border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]
    };
        border-radius: 0.75em;
        color: #444;
        overflow: auto;
        outline: 0px;
        `
);

const StyledOption2 = styled(OptionUnstyled)(
  ({ theme }) => `
        list-style: none;
        padding: 8px;
        border-radius: 0.45em;
        cursor: default;
      
        &:last-of-type {
          border-bottom: none;
        }
      
        &.${optionUnstyledClasses.selected} {
          background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]
    };
          color: #fff;
        }
      
        &.${optionUnstyledClasses.highlighted} {
          background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]
    };
          color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
        }
      
        &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected
    } {
          background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]
    };
          color: #0674b9;
        }
      
        &.${optionUnstyledClasses.disabled} {
          color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
        }
      
        &:hover:not(.${optionUnstyledClasses.disabled}) {
          background-color:#fff;
          color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
        }
        `
);

const StyledPopper2 = styled(PopperUnstyled)`
  z-index: 1;
`;
