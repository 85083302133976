import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import close from "../../Assets/Images/form/x.png";
import infoTip from "../../Assets/Images/form/info.png";
import { styled } from "@mui/material/styles";
import { MenuItem, IconButton } from "@mui/material";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import classes from "./TeamMember.module.css";
import useWindowDimensions from "../Navbar/WindowDimension";
import PhoneInput from "react-phone-input-2";
import validator from "validator";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import Close from "@mui/icons-material/Close";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import * as teamMemberServices from "../../SAAS/Services/saas-services.proxy";
const uploadTeamMemberProfile = teamMemberServices.uploadTeamMemberProfile;
const deleteTeamMemberProfile = teamMemberServices.deleteTeamMemberProfile;
const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#707070",
    },
  },
});
const BlueTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
      color: #101650;
      background-color: rgba(6, 116, 185, 0.3);
      font-size: 13px;
      width:180px;
   
  `);
const BlueTip2 = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
        color: #fff;
        background-color: rgba(6, 116, 185, 0.7);
        font-size: 13px;
        font-family: Muli;
        width:180px;
     
    `);

function Form1(props) {
  const style = {
    blueTip: {
      width: 200,
    },
  };

  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfimPassword, setShowConfirmPassword] = useState(false);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [nameInvalid, setNameInvalid] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [confirmPasswordInvalid, setConfirmPasswordInvalid] = useState("");

  const [emailInvalid, setEmailInvalid] = useState("");
  const [file, setFile] = useState(null);
  const [previousFileId, setPreviousFileId] = useState(null);

  const [isDisabled, setIsDisabled] = useState(false);
  const [mobileTip, setMobileTip] = useState(false);

  useEffect(() => {
    if (
      !props.name ||
      !props.email ||
      !props.password ||
      !props.confirmPassword ||
      nameError ||
      nameInvalid ||
      emailError ||
      emailInvalid ||
      passwordError ||
      passwordInvalid ||
      confirmPasswordError ||
      confirmPasswordInvalid
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  });
  useEffect(() => {
    if (width < 800) {
      setMobileTip(true);
    }
  });
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleClickConfirmShowPassword = () =>
    setShowConfirmPassword(!showConfimPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfimPassword);
  const openTip = () => setTooltipIsOpen(true);
  const closeTip = () => setTooltipIsOpen(false);
  const handleFileChange = (e) => {
    if (previousFileId) {
      deleteTeamMemberProfile(previousFileId);
    }
    if (e.target.files[0]) {
      if (props.fileId) setPreviousFileId(props.fileId);
      setFile(e.target.files[0]);
      uploadTeamMemberProfile(e.target.files[0], props.userId).then((x) => {
        setFile(x);
        props.setFileId(x.FileId);
      });
    }
  };
  return (
    <Box sx={{ flexGrow: 1 }} className={classes["formDesign"]}>
      <Grid item container md={12} spacing={2}>
        <Grid item container md={12} xs={12} direction={"row"}>
          <Grid item md={9} xs={11}>
            <div className={classes["form-title"]}> Join Pilecubes</div>
          </Grid>

          <Grid item md={3} xs={1} container direction={"row-reverse"}>
            <IconButton
              onClick={(e) => {
                navigate("/");
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item container md={12} direction={"column"} spacing={3}>
          <Grid item>
            <CssTextField
              label="Team Member Name"
              value={props.name}
              size="small"
              className={classes["Fields-styles"]}
              InputLabelProps={{
                style: {
                  fontFamily: "Muli",
                  fontSize: "16px",
                },
              }}
              error={nameError}
              helperText={
                nameError
                  ? nameInvalid
                    ? "Name must be less than 150 characters"
                    : "Enter your Name"
                  : ""
              }
              onChange={(e) => {
                props.setName(e.target.value);
                if (e.target.value === "") {
                  setNameError(true);
                  setNameInvalid(false);
                } else if ((e.target.value !== "") & (e.target.value > 150)) {
                  setNameError(true);
                  setNameInvalid(true);
                } else {
                  setNameError(false);
                  setNameInvalid(false);
                }
              }}
            />
          </Grid>
          <Grid item>
            <CssTextField
              label="Registration Email"
              size="small"
              disabled
              value={props.email}
              className={classes["Fields-styles"]}
              InputLabelProps={{
                style: {
                  fontFamily: "Muli",
                  fontSize: "16px",
                },
              }}
              error={emailError}
              helperText={
                emailError
                  ? emailInvalid
                    ? "Enter a valid email"
                    : "Enter an email address"
                  : ""
              }
              onChange={(e) => {
                props.setEmail(e.target.value);
                if (e.target.value === "") {
                  setEmailError(true);
                  setEmailInvalid(false);
                } else if (
                  (e.target.value !== "") &
                    !e.target.value.match(
                      /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/
                    ) ||
                  e.target.value.length > 64
                ) {
                  setEmailError(true);
                  setEmailInvalid(true);
                } else {
                  setEmailError(false);
                  setEmailInvalid(false);
                }
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    edge="end"
                    onClick={openTip}
                    onMouseOver={openTip}
                    onMouseLeave={closeTip}
                  >
                    {mobileTip ? (
                      <BlueTip2
                        open={tooltipIsOpen}
                        title={
                          "This email will be used to sign in and manage your account"
                        }
                        sx={style.blueTip}
                        placement="top-start"
                      >
                        <img width={"20px"} src={infoTip} alt={close}></img>
                      </BlueTip2>
                    ) : (
                      <BlueTip
                        open={tooltipIsOpen}
                        title={
                          "This email will be used to sign in and manage your account"
                        }
                        placement="right"
                      >
                        <img width={"20px"} src={infoTip} alt={close}></img>
                      </BlueTip>
                    )}
                  </IconButton>
                ),
              }}
            ></CssTextField>
          </Grid>
          <Grid item>
            <CssTextField
              type={showPassword ? "text" : "password"}
              label="Password"
              size="small"
              className={classes["Fields-styles"]}
              value={props.password}
              InputLabelProps={{
                style: {
                  fontFamily: "Muli",
                  fontSize: "16px",
                },
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <Visibility htmlColor="rgba(6, 116, 185, 0.5)" />
                    ) : (
                      <VisibilityOff htmlColor="rgba(6, 116, 185, 0.5)" />
                    )}
                  </IconButton>
                ),
              }}
              error={passwordError}
              helperText={
                passwordError
                  ? passwordInvalid
                    ? "Password must contain a number, a symbol, an uppercase & lowercase and be no less than 8  or more than 20 characters."
                    : "Enter a password"
                  : ""
              }
              onChange={(e) => {
                props.setPassword(e.target.value);

                if (e.target.value === "") {
                  setPasswordError(true);
                  setPasswordInvalid(false);
                } else if (
                  (e.target.value !== "") &
                    !validator.isStrongPassword(e.target.value, {
                      minLength: 8,
                      minLowercase: 1,
                      minUppercase: 1,
                      minNumbers: 1,
                      minSymbols: 1,
                    }) ||
                  e.target.value.length > 20
                ) {
                  if (props.confirmPassword !== "") {
                    setConfirmPasswordError(true);
                    setConfirmPasswordInvalid(true);
                  }
                  setPasswordError(true);
                  setPasswordInvalid(true);
                } else {
                  if (props.confirmPassword !== e.target.value) {
                    setConfirmPasswordError(true);
                    setConfirmPasswordInvalid(true);
                  } else {
                    setConfirmPasswordError(false);
                    setConfirmPasswordInvalid(false);
                  }
                  setPasswordError(false);
                  setPasswordInvalid(false);
                }
              }}
            />
          </Grid>
          <Grid item>
            <CssTextField
              label="Confirm Password"
              type={showConfimPassword ? "text" : "password"}
              size="small"
              value={props.confirmPassword}
              className={classes["Fields-styles"]}
              InputLabelProps={{
                style: {
                  fontFamily: "Muli",
                  fontSize: "16px",
                },
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickConfirmShowPassword}
                    onMouseDown={handleMouseDownConfirmPassword}
                    edge="end"
                  >
                    {showConfimPassword ? (
                      <Visibility htmlColor="rgba(6, 116, 185, 0.5)" />
                    ) : (
                      <VisibilityOff htmlColor="rgba(6, 116, 185, 0.5)" />
                    )}
                  </IconButton>
                ),
              }}
              error={confirmPasswordError}
              helperText={
                confirmPasswordError
                  ? confirmPasswordInvalid
                    ? "Password didn't match, try again"
                    : "Confirm Password"
                  : ""
              }
              onChange={(e) => {
                props.setConfirmPassword(e.target.value);

                if (e.target.value === "") {
                  setConfirmPasswordError(true);
                  setConfirmPasswordInvalid(false);
                } else if (e.target.value !== props.password) {
                  setConfirmPasswordError(true);
                  setConfirmPasswordInvalid(true);
                } else {
                  setConfirmPasswordError(false);
                  setConfirmPasswordInvalid(false);
                }
              }}
            />
          </Grid>
          <Grid item>
            <Button
              component="label"
              InputProps={{ style: { height: "30px" } }}
              style={{
                width: "35%",

                textTransform: "none",
                fontFamily: "Muli",
                fontSize: "12px",
                lineHeight: 1.45,
                textAlign: "left",
                color: "#101650",
                backgroundColor: "white",
                borderRadius: "6px",
                border: "solid 1px #b8b8b8",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                height: "30px",
              }}
            >
              <FileUploadOutlinedIcon
                htmlColor="#529bd7"
                style={{
                  alignSelf: "center",
                  paddingRight: "5%",
                }}
              />
              <span style={{ alignSelf: "center" }}>Upload Profile</span>
              <input type="file" hidden onChange={handleFileChange} required />
            </Button>
          </Grid>
          {file ? (
            <Grid item container direction={"row"} gap={1}>
              <Grid item>{file.DisplayName}</Grid>
              <Grid
                item
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={() => {
                  deleteTeamMemberProfile(props.fileId).then((x) => {
                    props.setFileId(null);
                    setFile(null);
                  });
                }}
              >
                <Close />
              </Grid>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        <Grid item container md={12} direction={"row"}>
          <Grid item md={6} xs={6}>
            <button
              className={classes["buttonNextForm"]}
              disabled={isDisabled}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#101650";
                e.target.style.cursor = "pointer";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#0b5cab";
              }}
              onClick={(e) => {
                props.continues(e);
              }}
              variant="contained"
            >
              Next
            </button>
          </Grid>
          <Grid item md={6} xs={6} container direction={"row-reverse"}>
            <div className={classes["form-number"]}>step 1 of 2</div>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Form1;
