import React, { useState, useEffect } from "react";
import { Grid, TextField, MenuItem, Button } from "@mui/material";
import classing from "../../../../Assets/Styles/factory.module.css";
import * as MACHINECONSTS from "../../../../../FactoryConsts";
const CuttingLength = (props) => {
  useEffect(() => {
    console.log(props.shapeTypesSelected);
  }, [props.shapeTypesSelected]);
  return (
    <Grid
      container
      paddingTop={3}
      paddingLeft={3}
      paddingRight={3}
      // width={700}s

      paddingBottom={1}
    >
      {props.category === MACHINECONSTS.CUTTING_MACHINE_ID ||
      props.category === MACHINECONSTS.STIRRUP_MACHINE_ID ? (
        <Grid item container>
          <Grid item xs={12} paddingTop={1} className={classing["Title"]}>
            Cutting Length
          </Grid>

          <Grid container gap={2} direction={"row"}>
            <Grid item sm={3} xs={12}>
              <Grid item className={classing["TFTitle"]} paddingLeft={1}>
                *Min (mm)
              </Grid>
              <Grid item>
                <TextField
                   disabled={props.disabledEdit}
                  value={props.minCuttingLength}
                  fullWidth
                  type="number"
                  InputProps={{
                    style: { height: "40px", fontSize: 12, fontFamily: "Muli" },
                    inputProps: { min: 0 },
                  }}
                  placeholder="Number"
                  onChange={(e) => {
                    props.setMinCuttingLength(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item sm={3} xs={12}>
              <Grid item className={classing["TFTitle"]} paddingLeft={1}>
                *Max (mm)
              </Grid>
              <Grid item>
                <TextField
                   disabled={props.disabledEdit}
                  value={props.maxCuttingLength}
                  fullWidth
                  type="number"
                  InputProps={{
                    style: { height: "40px", fontSize: 12, fontFamily: "Muli" },
                    inputProps: { min: 0 },
                  }}
                  placeholder="Number"
                  onChange={(e) => {
                    props.setMaxCuttingLength(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
      {props.category === MACHINECONSTS.STIRRUP_MACHINE_ID ||
      props.category === MACHINECONSTS.BENDING_2D_MACHINE_ID ||
      props.category === MACHINECONSTS.BENDING_3D_MACHINE_ID ? (
        <>
          <Grid item container>
            <Grid item xs={12} paddingTop={1} className={classing["Title"]}>
              Length (1 bending head)
            </Grid>

            <Grid container gap={2} direction={"row"}>
              <Grid item sm={3} xs={12}>
                <Grid item className={classing["TFTitle"]} paddingLeft={1}>
                  *Min(mm)
                </Grid>
                <Grid item>
                  <TextField
                     disabled={props.disabledEdit}
                    value={props.minBendingLength}
                    fullWidth
                    type="number"
                    InputProps={{
                      style: {
                        height: "40px",
                        fontSize: 12,
                        fontFamily: "Muli",
                      },
                      inputProps: { min: 0 },
                    }}
                    placeholder="Number"
                    onChange={(e) => {
                      props.setMinBendingLength(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item sm={3} xs={12}>
                <Grid item className={classing["TFTitle"]} paddingLeft={1}>
                  *Max (mm)
                </Grid>
                <Grid item>
                  <TextField
                     disabled={props.disabledEdit}
                    value={props.maxBendingLength}
                    fullWidth
                    type="number"
                    InputProps={{
                      style: {
                        height: "40px",
                        fontSize: 12,
                        fontFamily: "Muli",
                      },
                      inputProps: { min: 0 },
                    }}
                    placeholder="Number"
                    onChange={(e) => {
                      props.setMaxBendingLength(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container paddingTop={1}>
            <Grid container gap={2} direction={"row"}>
              <Grid item sm={3.5} xs={12}>
                <Grid item className={classing["TFTitle"]} paddingLeft={1}>
                  *No. of bending heads
                </Grid>
                <Grid item>
                  <TextField
                     disabled={props.disabledEdit}
                    value={props.bendingNo}
                    fullWidth
                    type="number"
                    InputProps={{
                      style: {
                        height: "40px",
                        fontSize: 12,
                        fontFamily: "Muli",
                      },
                      inputProps: { min: 1 },
                    }}
                    placeholder="Number"
                    onChange={(e) => {
                      props.setBendingNo(e.target.value);
                      if (e.target.value === 1) {
                        props.setMinLengthbtwBendingPinsLength(0);
                      }
                    }}
                  />
                </Grid>
              </Grid>
              {props.bendingNo > 1 ? (
                <Grid item sm={3.5} xs={12}>
                  <Grid item className={classing["TFTitle"]} paddingLeft={1}>
                    *Min. length b/w bending pins (mm)
                  </Grid>
                  <Grid item>
                    <TextField
                       disabled={props.disabledEdit}
                      value={props.minLengthBtwBendingPinsLength}
                      fullWidth
                      type="number"
                      InputProps={{
                        style: {
                          height: "40px",
                          fontSize: 12,
                          fontFamily: "Muli",
                          inputProps: { min: 0 },
                        },
                      }}
                      placeholder="Number"
                      onChange={(e) => {
                        props.setMinLengthbtwBendingPinsLength(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
              <Grid item sm={3.5} xs={12}>
                <Grid item className={classing["TFTitle"]} paddingLeft={1}>
                  *Min. no of bends
                </Grid>
                <Grid item>
                  <TextField
                     disabled={props.disabledEdit}
                    value={props.minBendingNo}
                    fullWidth
                    type="number"
                    InputProps={{
                      style: {
                        height: "40px",
                        fontSize: 12,
                        fontFamily: "Muli",
                      },
                      inputProps: { min: 0 },
                    }}
                    placeholder="Number"
                    onChange={(e) => {
                      props.setMinBendingNo(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item sm={3.5} xs={12}>
                <Grid item className={classing["TFTitle"]} paddingLeft={1}>
                  *Max. no of bends
                </Grid>
                <Grid item>
                  <TextField
                     disabled={props.disabledEdit}
                    value={props.maxBendingNo}
                    fullWidth
                    type="number"
                    InputProps={{
                      style: {
                        height: "40px",
                        fontSize: 12,
                        fontFamily: "Muli",
                      },
                      inputProps: { min: 0 },
                    }}
                    placeholder="Number"
                    onChange={(e) => {
                      props.setMaxBendingNo(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container paddingTop={1} direction={"row"} gap={4}>
            <Grid
              item
              className={classing["Shape-types-accepted"]}
              paddingTop={3.5}
            >
              Shape types accepted
            </Grid>
            {props.shapeTypes.map((shapeType) => (
              <Grid item key={shapeType.Id}>
                <Grid item className={classing["Label-Printer"]}>
                  {shapeType.Name}
                </Grid>
                <Grid
                  item
                  className={
                    props.shapeTypesSelected?.find((x) => x.Id === shapeType.Id)
                      ? classing["CheckedBoxMachine"]
                      : classing["notCheckedBox"]
                  }
                  onClick={() => {
                    if (
                      props.shapeTypesSelected?.find(
                        (x) => x.Id === shapeType.Id
                      )
                    ) {
                      props.setShapeTypesSelected(
                        props.shapeTypesSelected?.filter(
                          (x) => x.Id !== shapeType.Id
                        )
                      );
                    } else {
                      let temp = props.shapeTypesSelected;
                      temp = temp.concat(shapeType);
                      props.setShapeTypesSelected(temp);
                    }
                  }}
                  marginTop={1}
                ></Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item container>
            <Grid container gap={2} direction={"row"} paddingTop={1}>
              <Grid item sm={3.5} xs={12}>
                <Grid item className={classing["TFTitle"]} paddingLeft={1}>
                  *Max. length - X direction (mm)
                </Grid>
                <Grid item>
                  <TextField
                     disabled={props.disabledEdit}
                    value={props.maxXLength}
                    fullWidth
                    type="number"
                    InputProps={{
                      style: {
                        height: "40px",
                        fontSize: 12,
                        fontFamily: "Muli",
                      },
                      inputProps: { min: 0 },
                    }}
                    placeholder="Number"
                    onChange={(e) => {
                      props.setMaxXLength(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item sm={3.5} xs={12}>
                <Grid item className={classing["TFTitle"]} paddingLeft={1}>
                  *Max. length - Y direction (mm)
                </Grid>
                <Grid item>
                  <TextField
                     disabled={props.disabledEdit}
                    value={props.maxYLength}
                    fullWidth
                    type="number"
                    InputProps={{
                      style: {
                        height: "40px",
                        fontSize: 12,
                        fontFamily: "Muli",
                      },
                      inputProps: { min: 0 },
                    }}
                    placeholder="Number"
                    onChange={(e) => {
                      props.setMaxYLength(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              {props.category === MACHINECONSTS.BENDING_3D_MACHINE_ID ||
              props.category === MACHINECONSTS.STIRRUP_MACHINE_ID ? (
                <Grid item sm={3.5} xs={12}>
                  <Grid item className={classing["TFTitle"]} paddingLeft={1}>
                    *Max. length - Z direction (mm)
                  </Grid>
                  <Grid item>
                    <TextField
                     disabled={props.disabledEdit}
                    
                      value={props.maxZLength}
                      fullWidth
                      type="number"
                      InputProps={{
                        style: {
                          height: "40px",
                          fontSize: 12,
                          fontFamily: "Muli",
                        },
                        inputProps: { min: 0 },
                      }}
                      placeholder="Number"
                      onChange={(e) => {
                        props.setMaxZLength(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          <Grid item container direction={"row"} gap={4} paddingTop={1}>
            <Grid item className={classing["Label-Printer"]} paddingTop={3.5}>
            Bidirectional
            </Grid>
            <Grid item>
              <Grid item className={classing["Label-Printer"]}>
                Yes
              </Grid>
              <Grid
                item
                onClick={() => {
                  props.setBidirectional(true);
                }}
                className={
                  props.Bidirectional
                    ? classing["CheckedBox"]
                    : classing["notCheckedBox"]
                }
                marginTop={1}
              ></Grid>
            </Grid>
            <Grid item>
              <Grid item className={classing["Label-Printer"]}>
                No
              </Grid>
              <Grid
                item
                onClick={() => {
                  props.setBidirectional(false);
                }}
                className={
                  props.Bidirectional
                    ? classing["notCheckedBox"]
                    : classing["CheckedBox"]
                }
                marginTop={1}
              ></Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        ""
      )}
    </Grid>
  );
};

export default CuttingLength;
