export const CheckIfPermitted = (
  UserPermissions,
  PermissionGroup,
  Action,
  setDisable
) => {
  for (let i = 0; i < UserPermissions?.length; i++) {
    for (let j = 0; j < UserPermissions[i].Permissions.length; j++) {
      if (UserPermissions[i].Permissions[j].Name === PermissionGroup) {
        if (
          UserPermissions[i].Permissions[j].Actions.find(
            (a) => a.Name === Action
          )
        ) {
          setDisable(false);
          break;
        } else {
          setDisable(true);
        }
      } else {
        setDisable(true);
      }
    }
  }
};

export const CheckIfRolePermitted = (
  UserPermissions,
  PermissionGroup,
  roleName
) => {
  for (let i = 0; i < UserPermissions?.length; i++) {
    for (let j = 0; j < UserPermissions[i].Permissions.length; j++) {
      if (UserPermissions[i].Permissions[j].Name === PermissionGroup) {
        if (
          (UserPermissions[i].Permissions[j].Actions.find(
            (a) => a.Name === "Invite Admin/special roles"
          ) &&
            roleName === "Admin") ||
          (UserPermissions[i].Permissions[j].Actions.find(
            (a) => a.Name === "Invite Project Manager"
          ) &&
            roleName === "Project Manager") ||
          (UserPermissions[i].Permissions[j].Actions.find(
            (a) => a.Name === "Invite Engineering Manager"
          ) &&
            roleName === "Engineering Manager") ||
          (UserPermissions[i].Permissions[j].Actions.find(
            (a) => a.Name === "Invite Team Leader"
          ) &&
            roleName === "Team Leader") ||
          (UserPermissions[i].Permissions[j].Actions.find(
            (a) => a.Name === "Invite Engineering"
          ) &&
            roleName === "Engineer") ||
          (UserPermissions[i].Permissions[j].Actions.find(
            (a) => a.Name === "Invite Document Controller"
          ) &&
            roleName === "Document Controller")
        ) {
          return false;

          break;
        } else {
          if (j === UserPermissions[i].Permissions[j].Actions.length)
            return true;
        }
      } else {
        if (i === UserPermissions?.length) return true;
      }
    }
  }
  return true;
};
