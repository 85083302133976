import { Grid } from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { useNavigate, useLocation } from "react-router-dom";
import SaasBack from "../../Assets/Images/Projects/bgTableProject.png";
import classes from "../../Assets/Styles/projects.module.css";
import { TextField, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Stack } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuPic from "../../Assets/Images/Table/menuPic.png";
import { useSelector } from "react-redux";
import * as statusConst from "../../../salesOrderConst";
import * as salesOrderServices from "../../Services/sales-order-services.proxy";
import * as productionServices from "../../Services/production-planning-services";
import * as loadingServices from "../../Services/loading-services.proxy";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { styled } from "@mui/system";
import { Close } from "@mui/icons-material";
// import ErrorModal from "./ErrorModal";
import ErrorModal from "../../SharedComponents/ErrorModal";



const getSalesOrderTable = salesOrderServices.getSalesOrderTable;
const createCombinationSales = productionServices.createCombinationSales;
const getInputData = loadingServices.getInputData;
const createLoad = loadingServices.createLoad;
const updateLoad = loadingServices.updateLoad;
const getLoadingSalesOrderTable = loadingServices.getLoadingSalesOrderTable;

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: Muli;
  font-size: 12px;
  box-sizing: border-box;
  min-height: 32px;
  width: 10rem;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  margin: 0.5em;
  padding: 10px;
  text-align: left;
  line-height: 1.5;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};

  &:hover {
    background: ${theme.palette.mode === "dark" ? "" : grey[100]};
    border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
  font-family: Muli;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0;
  max-height:250px !important;
  min-width: 11rem;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  overflow: auto;
  outline: 0px;
  `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  cursor: default;
  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }
  `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});

export default function SalesOrderList(props) {
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    props.setRegister(true);
  }, [props]);

  useEffect(() => {
    props.setNaved(false);
  }, [props]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [docks, setDocks] = useState([]);
  const [trailers, setTrailers] = useState([]);
  const [dockId, setDockId] = useState(0);
  const [trailerId, setTrailerId] = useState(0);

  const [salesOrderTable, setSalesOrderTable] = useState([]);
  const [dataLength, setdataLength] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const [pageSize, setPageSize] = useState(25);
  const [modify, setModify] = useState(false);
  const [total, setTotal] = useState(0);
  const [planning, setplanning] = useState(false);
  const [creatingload, setCreatingload] = useState(false);

  // const [combinationBEerror, setCombinationBEerror] = useState(null);
  const [BEerror, setBEerror] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false)

  const navigate = useNavigate();

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();

  const [statusId, setStatusId] = useState(
    query.get("statusId") ? +query.get("statusId") : 0
  );
  const [selectionId, setSelectionId] = useState(0);
  const [projectId, setProjectId] = useState(
    query.get("id") ? query.get("id") : null
  );
  const [factoryId, setFactoryId] = useState(
    query.get("factoryId") ? query.get("factoryId") : null
  );
  const [dateSelected, setDateSelected] = useState(
    query.get("Date") ? query.get("Date") : null
  );
  const [loadId, setLoadId] = useState(
    query.get("LoadId") ? query.get("LoadId") : null
  );

  const [selectedSalesOrder, setSelectedSalesOrder] = useState([]);

  const isLoading =
    query.get("isLoading") === true || query.get("isLoading") === "true"
      ? true
      : false;

  const [loadingData, setLoadingData] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [deliveryStatus, setDeliveryStatus] = useState(0);

  const [projects, setProjects] = useState([]);
  const [statusesList, setStatusesList] = useState([]);
  const [selectionsList, setSelectionsList] = useState([]);
  const [deliveryStatuses, setDeliveryStatuses] = useState([]);
  const [customers, setCustomers] = useState([]);

  const listData = {
    Pagination: {
      PageSize: pageSize,
      PageNumber: pageNumber,
    },
    Search: searchText,
    FactoryId: factoryId,
    ProjectId: projectId,
    // StatusId: statusId === 0 || !statusId ? null : statusId,
    Statuses: statusId? [].concat(statusId.toString()):factoryId && !isLoading === true? ["3","8"]:factoryId && isLoading === true?["4","5","9"]:[],
    SelectionId: selectionId === 0 || !selectionId ? null : selectionId,
    DeliveryStatusId: factoryId && !isLoading === true ? 3 : deliveryStatus === 0 || !deliveryStatus ? null : deliveryStatus,
    CustomerId: customerId,
    IsInCombination: factoryId? false : true
  };

  useEffect(() => {
    let apiTest = true;
    setLoadingData(true);
    if (isLoading === true) {
      getLoadingSalesOrderTable(listData).then((x) => {
        if (apiTest) {
          setDeliveryStatuses(x.DeliveryStatuses.filter((d) => d.Id === 3));
          setStatusesList(
            x.Statuses.filter((s) => s.Id === 4 || s.Id === 5 || s.Id === 9)
          );
          setSalesOrderTable(
            x.SalesOrders
            // .filter(
            //   (order) => order.StatusId === 9 || order.StatusId === 5 || order.StatusId === 4 || order.Id === 6
            // )
          );
          setdataLength(x.Total);
          setLoadingData(false);
          setSelectionsList(x.Selections);
          setProjects(x.Projects);
          setCustomers(x.Customers);
          if (isLoading === true && factoryId)
            getInputData(factoryId, loadId).then((x) => {
              if (apiTest && x) {
                setDocks(x.Docks);
                setTrailers(x.Trailers);
                if (loadId) {
                  setDockId(x.Load.DockId);
                  setTrailerId(x.Load.TrailerId);
                  setDateSelected(x.Load.Date);
                  setSelectedSalesOrder(x.Load.SalesOrders);
                }
              }
            });
        }
      });
    } else {
      getSalesOrderTable(listData).then((x) => {
        if (apiTest) {
          setDeliveryStatuses(x.DeliveryStatuses);
          if(factoryId) setStatusesList(x.Statuses.filter((s)=> s.Id === 3 || s.Id === 8));
          else setStatusesList(x.Statuses);
          if (factoryId) setSalesOrderTable(x?.SalesOrders?.filter((s) => (s.StatusId === 3 && !s.IsInCombination) || s.StatusId === 8));
          else setSalesOrderTable(x.SalesOrders);
          setdataLength(x.Total);
          setLoadingData(false);
          setSelectionsList(x.Selections);
          setProjects(x.Projects);
          setCustomers(x.Customers);
          if (isLoading === true && factoryId)
            getInputData(factoryId, loadId).then((x) => {
              if (apiTest && x) {
                setDocks(x.Docks);
                setTrailers(x.Trailers);
                if (loadId) {
                  setDockId(x.Load.DockId);
                  setTrailerId(x.Load.TrailerId);
                  setDateSelected(x.Load.Date);
                  setSelectedSalesOrder(x.Load.SalesOrders);
                }
              }
            });
        }
      });
    }
    return () => {
      apiTest = false;
    };
  }, [
    searchText,
    pageNumber,
    pageSize,
    modify,
    selectionId,
    statusId,
    projectId,
    customerId,
    deliveryStatus,
    factoryId,
  ]);

  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [rowData, setRowData] = useState();

  const handleClick = async (event, params) => {
    setRowData(params);

    setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: "action",
      headerName: "Actions",
      sortable: false,

      renderCell: (params) => {
        return (
          <img
            src={MenuPic}
            alt="menu"
            onClick={(e) => handleClick(e, params)}
          ></img>
        );
      },
    },
    {
      field: "Code",
      headerName: "Order No.",
      width: 250,
      renderCell: (params) => <span>{params.row?.Code}</span>,
    },
    {
      field: "CustomerName",
      headerName: "Customer Name",
      width: 200,
      hide: localStorage.getItem("AccountType") === "1" ? true : false,
      renderCell: (params) => <span>{params.row?.CustomerName}</span>,
    },
    {
      field: "CustomerOrderReference",
      headerName: "Customer Order Reference",
      width: 200,
      renderCell: (params) => <span>{params.row?.CustomerOrderReference}</span>,
    },
    {
      field: "ProjectName",
      headerName: "Project Name",
      width: 200,
      renderCell: (params) => <span>{params.row?.ProjectName}</span>,
    },
    {
      field: "ActivityCode",
      headerName: "Activity Code",
      width: 150,
      renderCell: (params) => <span>{params.row?.ActivityCode}</span>,
    },
    {
      field: "StatusId",
      headerName: "Status",
      width: 150,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row?.StatusId === statusConst.DRAFT_ID
                ? "#0674b9"
                : params.row?.StatusId === statusConst.PLACED_ID
                  ? "#5fba47"
                  : params.row?.StatusId === statusConst.PLANNED_ID
                    ? "#2da4d0"
                    : params.row?.StatusId === statusConst.IN_PRODUCTION_ID
                      ? "#e1e319"
                      : params.row?.StatusId === statusConst.LOADING_ID
                        ? "rgba(16, 22, 80, 0.5)"
                        : params.row?.StatusId === statusConst.DELIVERED_ID
                          ? "#101650"
                          : params.row?.StatusId === statusConst.HOLD_ID
                            ? "red"
                            : params.row?.StatusId === statusConst.INTERRUPTED_ID
                              ? "#ffb303"
                              : params.row?.StatusId === statusConst.PRODUCED_ID
                                ? "#5fba47"
                                : "",
          }}
        >
          {params.row?.StatusId === statusConst.DRAFT_ID
            ? statusConst.DRAFT
            : params.row?.StatusId === statusConst.PLACED_ID
              ? statusConst.PLACED
              : params.row?.StatusId === statusConst.PLANNED_ID
                ? statusConst.PLANNED
                : params.row?.StatusId === statusConst.IN_PRODUCTION_ID
                  ? statusConst.IN_PRODUCTION
                  : params.row?.StatusId === statusConst.LOADING_ID
                    ? statusConst.LOADING
                    : params.row?.StatusId === statusConst.DELIVERED_ID
                      ? statusConst.DELIVERED
                      : params.row?.StatusId === statusConst.HOLD_ID
                        ? statusConst.ON_HOLD
                        : params.row?.StatusId === statusConst.INTERRUPTED_ID
                          ? statusConst.INTERRUPTED
                          : params.row?.StatusId === statusConst.PRODUCED_ID
                            ? statusConst.PRODUCED
                            : ""}
        </span>
      ),
    },
    {
      field: "DeliveryDate",
      headerName: "Delivery Date",
      width: 150,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row?.IsDelayed === true
                ? "rgba(255, 0, 0, 0.7)"
                : params.row?.DeliveryDateStatusId === statusConst.NA_ID
                  ? "#0674b9"
                  : params.row?.DeliveryDateStatusId === statusConst.REQUESTED_ID
                    ? "#e1e319"
                    : "#5fba47",
          }}
        >
          {params.row?.DeliveryDate}
        </span>
      ),
    },
    {
      field: "DeliveryDateStatusId",
      headerName: "Delivery Date Status",
      width: 180,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row?.IsDelayed === true
                ? "rgba(255, 0, 0, 0.7)"
                : params.row?.DeliveryDateStatusId === statusConst.NA_ID
                  ? "#0674b9"
                  : params.row?.DeliveryDateStatusId === statusConst.REQUESTED_ID
                    ? "#e1e319"
                    : "#5fba47",
          }}
        >
          {params.row?.DeliveryDateStatusId === statusConst.NA_ID
            ? statusConst.NA
            : params.row?.DeliveryDateStatusId === statusConst.REQUESTED_ID
              ? statusConst.REQUESTED
              : statusConst.VALIDATED}
        </span>
      ),
    },
    {
      field: "SelectionId",
      headerName: "Selection",
      width: 150,
      renderCell: (params) => (
        <span>{params.row?.SelectionId === 1 ? "Partially" : "Fully"}</span>
      ),
    },
    {
      field: "TheoreticalWeight",
      headerName: "Theoretical Weight",
      width: 150,
      renderCell: (params) => <span>{params.row?.TheoreticalWeight}</span>,
    },
    {
      field: "ActualWeight",
      headerName: "Actual Weight (KG)",
      width: 150,
      renderCell: (params) => <span>{params.row?.ActualWeight}</span>,
    },
    {
      field: "DrawingNumber",
      headerName: "Drawings No.",
      width: 200,
      renderCell: (params) => <span>{params.row?.DrawingNumber}</span>,
    },
    {
      field: "DrawingRevisionNumber",
      headerName: "Drawing Revision No.",
      width: 160,
      renderCell: (params) => <span>{params.row?.DrawingRevisionNumber}</span>,
    },

    {
      field: "SentBy",
      headerName: "Sent By",
      width: 200,
      renderCell: (params) => <span>{params.row?.SentBy}</span>,
    },
    {
      field: "CreationDateTime",
      headerName: "Creation Date & Time",
      width: 160,
      renderCell: (params) => (
        <span>
          {/* Date */}
          {params.row?.CreationDateTime
            ? new Date(params.row?.CreationDateTime)?.toLocaleDateString()
            : null}{" "}
          {/* Time */}
          {params.row?.CreationDateTime?.slice(11, 19)}
        </span>
      ),
    },
  ];

  const SalesOrdersCombinationData = {
    Date: dateSelected,
    SalesOrders: selectedSalesOrder,
  };

  const createCombination = () => {
    setplanning(true);
    createCombinationSales(SalesOrdersCombinationData).then((x) => {
      if (x.status || x.statusCode) {
        if (x.status) setBEerror(x.error)
        if (x.statusCode) setBEerror("Something went wrong. Please contact your company administrator or pilecubes support team.");
        setOpenErrorModal(true);
      } else {
        navigate(`/saas/productionPlanning/Assigning?id=${x}`);

      }
      setplanning(false);
    });
  };

  const loadData = {
    Id: loadId,
    Date: dateSelected,
    TrailerId: trailerId,
    DockId: dockId,
    SalesOrders: selectedSalesOrder,
  };

  const [error, setError] = useState("");

  return (
    <div
      style={{
        minHeight: "90vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      {/* {disabled ? (
        <Grid
          item
          container
          bgcolor={"rgba(225, 227, 25, 0.5)"}
          xs={12}
          marginTop={1}
          padding={1}
        >
          <Grid item md={4}></Grid>
          <Grid
            item
            md={6}
            style={{
              fontFamily: "Muli",
              fontSize: "17px",
              fontWeight: "bold",
              textAlign: "left",
              color: "#101650",
            }}
          >
            <Grid>
              {localStorage.getItem("IsSubscribedBefore") === "true" ?
                "Please subscribe before you start working"
                :
                planType === "Pro" &&
                  localStorage.getItem("isTeamMember") === "false"
                  ? "Upgrade subscription required (Pro+)"
                  : planType === "Core" &&
                    localStorage.getItem("isTeamMember") === "false"
                    ? "Upgrade subscription required (Pro or Pro+)"
                    : localStorage.getItem("isGuest") === "true"
                      ? "Please subscribe to create/edit or archive/re-activate project"
                      : localStorage.getItem("isTeamMember") === "true" &&
                        !UserPermissions?.find((x) => x.Name === "Project Manager") &&
                        !UserPermissions?.find((x) => x.Name === "Admin")
                        ? "You don't have permission to create/edit or archive/re-activate a project"
                        : localStorage.getItem("isTeamMember") === "true" &&
                          (UserPermissions?.find((x) => x.Name === "Project Manager") ||
                            UserPermissions?.find((x) => x.Name === "Admin"))
                          ? companyPlan === "Core"
                            ? "Upgrade subscription for Company required (Pro or Pro+)"
                            : companyPlan === "Pro"
                              ? "Upgrade subscription for Company required (Pro+)"
                              : ""
                          : ""}
            </Grid>
          </Grid>

          <Grid item md={2} container>
            {localStorage.getItem("isTeamMember") &&
              localStorage.getItem("IsSubscribedBefore") !== "true" ? (
              <Grid item xs={8}></Grid>
            ) : (
              <Grid
                item
                onClick={() => {
                  props.setSetupProfile(true);
                  props.setSetupMenu(true);
                  navigate("/saas/profileSettings");
                }}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
              >
                <button
                  style={{
                    padding: "5px",
                    borderRadius: "5px",
                    backgroundColor: "#101650",
                    fontFamily: "Muli",
                    fontSize: "13px",
                    textAlign: "center",
                    border: 0,
                    color: "#fff",
                  }}
                >
                  Subscribe
                </button>
              </Grid>
            )}
            <Grid
              item
              paddingLeft={1}
              onClick={() => {
                setDisabled(false);
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
            >
              <CloseIcon fontSize="medium" />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )} */}
      {disabled ? (
        <Grid
          item
          container
          bgcolor={"rgba(225, 227, 25, 0.5)"}
          xs={12}
          padding={1}
          paddingTop={{ xs: "3%", md: "1%" }}
        >
          <Grid item xs={4} md={4.5}></Grid>
          <Grid
            item
            xs={7}
            md={5.5}
            style={{
              fontFamily: "Muli",
              fontSize: "15px",
              textAlign: "left",
              color: "#000",
            }}
          >
            The Load limit is {error}
          </Grid>
          <Grid item xs={1} md={1} container>
            <Grid
              item
              paddingLeft={1}
              onClick={() => {
                setDisabled(false);
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
            >
              <Close fontSize="medium" />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <Grid container paddingTop={5} paddingLeft={2.5}>
        <Grid item container md={11.95} paddingBottom={2} paddingLeft={3.5}>
          <Grid item xs={12} sm={7} md={7} container>
            <Grid
              item
              xs={12}
              sx={{
                fontFamily: "Muli",
                fontSize: 18,
                fontWeight: "bold",
                lineHeight: 1.44,
                textAlign: "left",
                color: "#101650",
              }}
            >
              {localStorage.getItem("ProjectName")}
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                fontFamily: "Muli",
                fontSize: 18,
                lineHeight: 1.44,
                textAlign: "left",
                color: "#101650",
              }}
            >
              Sales Orders
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            sm={5}
            container
            direction={"row-reverse"}
            paddingRight={{ md: 2 }}
          >
            {factoryId ? (
              <Grid item md={1.5} xs={3}>
                {isLoading === true ? (
                  <button
                    disabled={
                      selectedSalesOrder.length === 0 || !selectedSalesOrder || !dockId || !trailerId
                        ?
                        true
                        : false
                    }
                    className={classes["MoveToProduction"]}
                    onClick={() => {
                      if (loadId)
                        updateLoad(loadData).then((x) => {
                          if (x.status) {
                            setError(x.error);
                            setDisabled(true);
                          } else
                            navigate(
                              `/saas/loading/ViewResult?LoadId=${loadId}`
                            );
                        });
                      else
                        createLoad(loadData).then((x) => {
                          if (x.status) {
                            setError(x.error);
                            setDisabled(true);
                          } else
                            navigate(`/saas/loading/ViewResult?LoadId=${x}`);
                        });
                    }}
                  >
                    {creatingload ? "Confirming..." : "Confirm Loading"}
                  </button>
                ) : (
                  <button
                    disabled={
                      selectedSalesOrder.length === 0 || !selectedSalesOrder || planning
                        ? true
                        : false
                    }
                    className={classes["MoveToProduction"]}
                    onClick={() => {
                      createCombination();
                    }}
                  >
                    {planning ? "Planning..." : "Continue Planning"}
                  </button>
                )}
              </Grid>
            ) : (
              <Grid item md={1.5} xs={3}>
                <button
                  disabled={
                    localStorage.getItem("IsSubscribedBefore") === "true"
                  }
                  onClick={() => {
                    navigate("/saas/salesOrderForm");
                  }}
                  className={classes["AddButton"]}
                >
                  Add
                </button>
              </Grid>
            )}
            <Grid item md={1.5} xs={3} paddingTop={factoryId ? 0.7 : 0}>
              <button
                className={classes["back-button"]}
                onClick={() => {
                  if (localStorage.getItem("ProjectName"))
                    localStorage.removeItem("ProjectName");
                  navigate(-1);
                }}
              >
                Go back
              </button>
            </Grid>
          </Grid>
        </Grid>
        {isLoading === true ? (
          <Grid item container md={12} paddingLeft="2%">
            <Grid item>
              <CustomSelect
                value={dockId}
                onChange={(option) => {
                  setDockId(option);
                }}
              >
                <StyledOption value={0} disabled>
                  Dock
                </StyledOption>
                {docks?.map((s) => (
                  <StyledOption key={s.Id} value={s.Id}>
                    {s.Code}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Grid>
            <Grid item>
              <CustomSelect
                value={trailerId}
                onChange={(option) => {
                  setTrailerId(option);
                }}
              >
                <StyledOption value={0} disabled>
                  Trailer
                </StyledOption>
                {trailers?.map((s) => (
                  <StyledOption key={s.Id} value={s.Id}>
                    {s.Code}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Grid>
          </Grid>
        ) : (
          ""
        )}

        <Grid
          item
          md={12}
          container
          direction={"row-reverse"}
          paddingRight={{ md: 2, xs: 0 }}
        >
          <Box
            sx={{
              p: 0.5,
              pb: 0,
            }}
          >
            <TextField
              variant="standard"
              fullWidth
              onChange={inputHandler}
              placeholder="Search this list…"
              InputProps={{
                startAdornment: (
                  <SearchIcon
                    fontSize="small"
                    htmlColor={"#707070"}
                    style={{ paddingLeft: 5 }}
                  />
                ),
              }}
              sx={{
                fontFamily: "Muli",
                backgroundColor: "white",
                borderRadius: 1,
                width: {
                  xs: 1,
                  sm: "auto",
                },
                m: (theme) => theme.spacing(1, 0.5, 1.5),
                "& .MuiSvgIcon-root": {
                  mr: 0.5,
                },
                "& .MuiInput-underline:before": {
                  borderBottom: 0,
                  borderColor: "white",
                },
              }}
            />
          </Box>

          <Grid item>
            <CustomSelect
              value={selectionId}
              onChange={(option) => {
                setSelectionId(option);
              }}
            >
              <StyledOption value={0} disabled>
                Selection
              </StyledOption>
              {selectionsList?.map((s) => (
                <StyledOption key={s.Id} value={s.Id}>
                  {s.Name}
                </StyledOption>
              ))}
            </CustomSelect>
          </Grid>
          <Grid item>
            <CustomSelect
              value={statusId}
              onChange={(option) => {
                setStatusId(option);
              }}
            >
              <StyledOption value={0} disabled>
                Status
              </StyledOption>
              {statusesList?.map((s) => (
                <StyledOption key={s.Id} value={s.Id}>
                  {s.Name}
                </StyledOption>
              ))}
            </CustomSelect>
          </Grid>
          <Grid item>
            <CustomSelect
              value={deliveryStatus}
              onChange={(option) => {
                setDeliveryStatus(option);
              }}
            >
              <StyledOption value={0} disabled>
                Delivery Status
              </StyledOption>
              {deliveryStatuses?.map((s) => (
                <StyledOption key={s.Id} value={s.Id}>
                  {s.Name}
                </StyledOption>
              ))}
            </CustomSelect>
          </Grid>
          {localStorage.getItem("AccountType") === "1" ? (
            ""
          ) : (
            <Grid item>
              <CustomSelect
                value={customerId}
                onChange={(option) => {
                  setCustomerId(option);
                }}
              >
                <StyledOption value={null} disabled>
                  Customer Name
                </StyledOption>
                {customers?.map((s) => (
                  <StyledOption key={s.Id} value={s.Id}>
                    {s.Name}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Grid>
          )}

          <Grid item>
            <CustomSelect
              value={projectId}
              onChange={(option) => {
                setProjectId(option);
                localStorage.setItem(
                  "ProjectName",
                  projects.find((p) => p.Id === option)?.Name
                );
              }}
            >
              <StyledOption value={null} disabled>
                Projects
              </StyledOption>
              {projects?.map((s) => (
                <StyledOption key={s.Id} value={s.Id}>
                  {s.Name}
                </StyledOption>
              ))}
            </CustomSelect>
          </Grid>
          {selectionId ||
            statusId ||
            projectId ||
            customerId ||
            deliveryStatus ? (
            <Grid item marginTop={0.6}>
              <button
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={() => {
                  setSelectionId(0);
                  setStatusId(0);
                  setProjectId(null);
                  setDeliveryStatus(0);
                  setCustomerId(null);
                  localStorage.removeItem("ProjectName");
                }}
                className={classes["Rectangle-1224"]}
              >
                Clear filters
              </button>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        <div
          style={{
            height: 500,
            width: "96.5%",
            fontFamily: "Muli",
            backgroundColor: "white",
            marginTop: 10,
            marginLeft: "2%",
          }}
        >
          <DataGrid
            disableSelectionOnClick
            checkboxSelection={factoryId ? true : false}
            onSelectionModelChange={(ids) => {
              console.log(ids);
              let updateSalesOrder = selectedSalesOrder;
              ids.map((id) => {
                if (!updateSalesOrder?.filter((x) => x.Id === id)[0])
                  updateSalesOrder = updateSalesOrder.concat({ Id: id });
              });
              updateSalesOrder.map((id) => {
                if (!ids?.filter((x) => x === id.Id)[0]) {
                  updateSalesOrder = updateSalesOrder.filter(
                    (x) => x.Id !== id.Id
                  );
                }
              });
              setSelectedSalesOrder(updateSalesOrder);
            }}
            initialState={{
              pagination: {
                pageSize: 25,
              },
            }}
            pagination
            paginationMode="server"
            rows={salesOrderTable ? salesOrderTable : []}
            columns={columns}
            headerHeight={40}
            density={"compact"}
            showColumnRightBorder
            sx={{
              [`& .${gridClasses.row}`]: {
                color: "#0674b9",
                overflow: "auto",
                bgcolor: (theme) =>
                  theme.palette.mode === "light" ? grey[0] : grey[500],
              },

              [`& .${gridClasses.columnHeader}`]: {
                bgcolor: "rgb(211 225 238)",
                color: "rgb(17 24 84)",
              },
              [`& .${gridClasses.columnHeaderTitle}`]: {
                fontFamily: "Muli",
                fontWeight: "bold",
              },
              [`& .${gridClasses.footerContainer}`]: {
                bgcolor: "rgb(216 235 247)",
                minHeight: "30px",
              },
              [`& .${gridClasses.checkboxInput}`]: {
                color: "#707070",
                "&.Mui-checked": {
                  color: "#e1e319",
                },
              },
              fontFamily: "Muli",
              fontSize: "13px",
            }}
            disableColumnFilter
            isRowSelectable={(params) =>
              isLoading === true
                ? true
                : (params.row.StatusId === 3 && !params.row.IsInCombination) ||
                  params.row.StatusId === 8
                  ? true
                  : false
            }
            keepNonExistentRowsSelected
            getRowId={(row) => row.Id}
            rowCount={dataLength}
            hideFooter={props.paging}
            onPageChange={(newPage) => {
              if (pageSize > dataLength) {
                setPageNumber(1);
              } else setPageNumber(newPage + 1);
            }}
            onPageSizeChange={(newPage) => {
              setPageSize(newPage);
            }}
            pageSize={pageSize}
            loading={loadingData}
            components={{
              NoRowsOverlay: () => (
                <Stack className={classes["EmptyMessage"]}>
                  No order books to display
                </Stack>
              ),
            }}
          />
        </div>
        <Menu
          id="more-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={(e) => {
            e.preventDefault();
          }}
          disableScrollLock
        >
          <MenuItem
            onClick={() => {
              navigate(`/saas/salesOrderForm?id=${rowData?.row.Id}`);
            }}
            style={{
              fontFamily: "Muli",
              fontSize: "13px",
              color: "#444",
              display: "block",
            }}
          >
            View Sales Order
          </MenuItem>
          {localStorage.getItem("IsFreePlanActivated") === "true" ||
            localStorage.getItem("planIdManu")?.includes("Core") ? (
            <>
              <MenuItem
                onClick={() => {
                  navigate(
                    `/saas/Delivery/SaleOrderNote?salesOrderId=${rowData?.row.Id}`
                  );
                }}
                style={{
                  fontFamily: "Muli",
                  fontSize: "13px",
                  color: "#444",
                  display: "block",
                }}
              >
                Create Delivery Note
              </MenuItem>
            </>
          ) : (
            <></>
          )}

          <MenuItem
            onClick={() => {
              navigate(
                `/saas/Delivery/DeliveryCardList?salesOrderId=${rowData?.row.Id}`
              );
            }}
            style={{
              fontFamily: "Muli",
              fontSize: "13px",
              color: "#444",
              display: "block",
            }}
          >
            View Delivery Notes
          </MenuItem>
        </Menu>
      </Grid>
      <ErrorModal openErrorModal={openErrorModal} setOpenErrorModal={setOpenErrorModal} BEerror={BEerror} />
    </div>
  );
}
