import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import SaasBack from "../../../Assets/Images/Projects/bgTableProject.png";
import classes from "../../../Assets/Styles/projects.module.css";
import { TextField, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Stack } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuPic from "../../../Assets/Images/Table/menuPic.png";
import * as documentServices from "../../../Services/saas-services.proxy";
import { useSelector } from "react-redux";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import * as constStatus from "../../../../StatusConst";
import FileSubImg from "../../../Assets/Images/Table/FileSub.png";
import fileimg from "../../../Assets/Images/Table/file.png";
import noFileimg from "../../../Assets/Images/Table/noFile.png";
import { styled } from "@mui/system";
import * as proAndSubServices from "../../../Services/activities-services.proxy";
const getProjectList = proAndSubServices.getProjectList;
const getDocumentsData = documentServices.getDocumentsData;
const getDocumentFilters = documentServices.getDocumentFilters;

const DRAFT = constStatus.DRAFT;
const APPROVED = constStatus.APPROVED;
const APPROVED_AS_NOTED = constStatus.APPROVED_AS_NOTED;
const REVISE_AND_RESUBMIT = constStatus.REVISE_AND_RESUBMIT;
const REJECTED = constStatus.REJECTED;
const ARCHIVED_DOCUMENT = constStatus.ARCHIVED_DOCUMENT;
const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
    font-family: IBM Plex Sans, sans-serif;
    font-size: 10px;
    box-sizing: border-box;
    min-height: 32px;
    width: 9rem;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
    border-radius: 0.75em;
    margin: 0.5em;
    padding: 10px;
    text-align: left;
    line-height: 1.5;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  
    &:hover {
      background: ${theme.palette.mode === "dark" ? "" : grey[100]};
      border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }
  
    &.${selectUnstyledClasses.focusVisible} {
      outline: 3px solid ${
        theme.palette.mode === "dark" ? blue[600] : blue[100]
      };
    }
  
    &.${selectUnstyledClasses.expanded} {
      &::after {
        content: '▴';
      }
    }
  
    &::after {
      content: '▾';
      float: right;
    }
    `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
    font-family: Muli;
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 5px;
    margin: 10px 0;
    max-height: 250px !important;
    min-width: 11rem;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
    border-radius: 0.75em;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    overflow: auto;
    outline: 0px;
    `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
    list-style: none;
    padding: 8px;
    border-radius: 0.45em;
    cursor: default;
  
    &:last-of-type {
      border-bottom: none;
    }
  
    &.${optionUnstyledClasses.selected} {
      background-color: ${
        theme.palette.mode === "dark" ? blue[900] : blue[100]
      };
      color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
    }
  
    &.${optionUnstyledClasses.highlighted} {
      background-color: ${
        theme.palette.mode === "dark" ? grey[800] : grey[100]
      };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
  
    &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
      background-color: ${
        theme.palette.mode === "dark" ? blue[900] : blue[100]
      };
      color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
    }
  
    &.${optionUnstyledClasses.disabled} {
      color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }
  
    &:hover:not(.${optionUnstyledClasses.disabled}) {
      background-color: ${
        theme.palette.mode === "dark" ? grey[800] : grey[100]
      };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
    `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});
function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}
export default function SubProjectDocuments(props) {
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  const AccountType = localStorage.getItem("AccountType");
  const [documentData, setDocumentData] = useState([]);
  const [dataLength, setdataLength] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const [pageSize, setPageSize] = useState(25);
  //FILTERS
  const [discipline, setDiscipline] = useState(null);
  const [disciplines, setDisciplines] = useState([]);
  const [project, setProject] = useState(null);
  const [projects, setProjects] = useState([]);
  const [status, setStatus] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [approvalStatus, setApprovalStatus] = useState(null);
  const [approvalStatuses, setApprovalStatuses] = useState([]);
  const [type, setType] = useState(null);
  const [types, setTypes] = useState([]);
  const [creator, setCreator] = useState(null);
  const [creatores, setCreatores] = useState([]);

  const listData = {
    Pagination: {
      PageSize: pageSize,
      PageNumber: pageNumber,
    },
    Search: searchText,
    SortList: [],
    DropDownFilter: {
      ProjectId: project === 0 ? null : project,
      SubProjectId: props.subProjectId,
      DisciplineId: discipline === 0 ? null : discipline,
      StatusId: status === 0 ? null : status,
      ApprovalStatusId: approvalStatus === 0 ? null : approvalStatus,
      CreatorId: creator === 0 ? null : creator,
      Type: type === 0 ? null : type,
    },
    RFIId: null,
  };
  useEffect(() => {
    let apiTest = true;
    getDocumentFilters().then((x) => {
      if (apiTest) {
        setDisciplines(x.Discipline);
        setStatuses(x.Status);
        setApprovalStatuses(x.ApprovalStatus);
        setCreatores(x.Creators);
        setTypes(x.Types);
      }
    });
    getProjectList().then((x) => {
      setProjects(x);
    });
    return () => {
      apiTest = false;
    };
  }, []);
  useEffect(() => {
    let apiTest = true;
    setLoadingData(true);
    getDocumentsData(listData).then((x) => {
      if (apiTest) {
        setDocumentData(x.Documents);
        setdataLength(x.Total);
        setLoadingData(false);
      }
    });
    return () => {
      apiTest = false;
    };
  }, [
    searchText,
    pageNumber,
    pageSize,
    project,
    type,
    discipline,
    creator,
    status,
    approvalStatus,
  ]);

  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowData, setRowData] = useState();
  const [loadingData, setLoadingData] = useState(false);

  const handleClick = async (event, params) => {
    setRowData(params);

    setAnchorEl(event.target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const columns = [
    { field: "Id", hide: true, hideable: false },
    {
      field: "action",
      headerName: "Actions",

      renderCell: (params) => {
        return (
          <img
            src={MenuPic}
            alt="menu"
            onClick={(e) => handleClick(e, params)}
          ></img>
        );
      },
    },
    {
      field: "Name",
      width: 150,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.ApprovalStatusId === ARCHIVED_DOCUMENT
                ? "grey"
                : "#0674b9",
          }}
        >
          {params.row?.Name}
        </span>
      ),
    },
    {
      field: "No.",
      headerName: "Number",
      width: 150,
      resizeable: true,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.ApprovalStatusId === ARCHIVED_DOCUMENT
                ? "grey"
                : "#0674b9",
          }}
        >
          {params.row?.Number}
        </span>
      ),
    },
    {
      field: "Discipline",
      headerName: "Discipline Reference",
      width: 150,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.ApprovalStatusId === ARCHIVED_DOCUMENT
                ? "grey"
                : "#0674b9",
          }}
        >
          {params.row?.Discipline}
        </span>
      ),
    },
    {
      field: "SubProject",
      headerName: "SubProject Name",
      width: 150,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.ApprovalStatusId === ARCHIVED_DOCUMENT
                ? "grey"
                : "#0674b9",
          }}
        >
          {params.row?.SubProject}
        </span>
      ),
    },
    {
      field: "PhaseName",
      headerName: "Phase Name",
      width: 150,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.ApprovalStatusId === ARCHIVED_DOCUMENT
                ? "grey"
                : "#0674b9",
          }}
        >
          {params.row?.Phase}
        </span>
      ),
    },
    {
      field: "Project",
      headerName: "Project",
      width: 150,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.ApprovalStatusId === ARCHIVED_DOCUMENT
                ? "grey"
                : "#0674b9",
          }}
        >
          {params.row?.Project}
        </span>
      ),
    },
    {
      field: "Status",
      headerName: "Status",
      width: 150,

      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.ApprovalStatusId === ARCHIVED_DOCUMENT ||
              params.row.Status === "Draft"
                ? "grey"
                : "#0674b9",
          }}
        >
          {params.row?.Status}
        </span>
      ),
    },
    {
      field: "ApprovalStatus",
      headerName: "Approval Status",
      width: 150,

      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.ApprovalStatusId === ARCHIVED_DOCUMENT ||
              params.row.ApprovalStatusId === DRAFT
                ? "grey"
                : params.row.ApprovalStatusId === REJECTED
                ? "red"
                : "green",
          }}
        >
          {params.row?.ApprovalStatus}
        </span>
      ),
    },

    {
      field: "Type",
      headerName: "Type",
      width: 130,
      sortable: false,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.ApprovalStatusId === ARCHIVED_DOCUMENT
                ? "grey"
                : "#0674b9",
          }}
        >
          {params.row?.File?.Type}
        </span>
      ),
    },
    {
      field: "Size",
      headerName: "Size",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.ApprovalStatusId === ARCHIVED_DOCUMENT
                ? "grey"
                : "#0674b9",
          }}
        >
          {bytesToSize(params.row.File?.Size)}
        </span>
      ),
    },
    {
      field: "RevisionNumber",
      headerName: "Revision No.",
      width: 150,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.ApprovalStatusId === ARCHIVED_DOCUMENT
                ? "grey"
                : "#0674b9",
          }}
        >
          {params.row?.RevisionNumber}
        </span>
      ),
    },
    {
      field: "RevisionDate",
      headerName: "Revision Date",
      width: 150,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.ApprovalStatusId === ARCHIVED_DOCUMENT
                ? "grey"
                : "#0674b9",
          }}
        >
          {params.row?.RevisionDate?.slice(0, 10)}
        </span>
      ),
    },
    {
      field: "CreationDate",
      headerName: "Upload Date",
      width: 150,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.ApprovalStatusId === ARCHIVED_DOCUMENT
                ? "grey"
                : "#0674b9",
          }}
        >
          {params.row?.CreationDate?.slice(0, 10)}
        </span>
      ),
    },
    {
      field: "Creator",
      headerName: "Creator",
      width: 150,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.ApprovalStatusId === ARCHIVED_DOCUMENT
                ? "grey"
                : "#0674b9",
          }}
        >
          {params.row?.Creator}
        </span>
      ),
    },
    {
      field: "File",
      headerName: "File",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <img
          src={
            params.row.File && params.row.ApprovalStatusId !== ARCHIVED_DOCUMENT
              ? AccountType === "1"
                ? fileimg
                : FileSubImg
              : noFileimg
          }
          style={{ width: "20%" }}
          alt={"null"}
        />
      ),
    },
  ];
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <Grid container paddingTop={5} paddingLeft={2.5}>
        <Grid
          item
          container
          direction={"row"}
          md={12}
          paddingBottom={2}
          paddingLeft={4}
        >
          <Grid item xs={3} sm={7} md={7} container direction={"row"}>
            <div style={{ display: "flex", direction: "row", flex: 1 }}>
              <span className={classes["table-header"]}>All Documents</span>
              <Grid
                className={classes["Line-523"]}
                display={{ xs: "none", md: "inherit" }}
              ></Grid>
            </div>
          </Grid>

          <Grid
            item
            xs={7}
            md={5}
            sm={5}
            container
            direction={"row-reverse"}
            paddingRight={{ md: 5 }}
          >
            <Grid item md={1} xs={2.5} paddingLeft={1}>
              <button
                className={classes["back-button"]}
                onClick={() => {
                  props.setViewTable(false);
                }}
              >
                Go back
              </button>
            </Grid>
            <Grid item md={9} xs={6}>
              <Grid
                className={classes["Line-523"]}
                display={{ xs: "none", md: "inherit" }}
              ></Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={10} container direction={"row-reverse"}>
          <Grid
            item
            md={12}
            lg={12}
            marginTop={1}
            container
            direction={"row-reverse"}
          >
            <Grid item>
              <CustomSelect
                defaultValue={0}
                onChange={(option) => {
                  setCreator(option);
                }}
              >
                <StyledOption value={0} disabled>
                  Creator
                </StyledOption>
                {creatores.map((s) => (
                  <StyledOption key={s.Id} value={s.Id}>
                    {s.Name}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Grid>
            <Grid item>
              <CustomSelect
                defaultValue={0}
                onChange={(option) => {
                  setType(option);
                }}
              >
                <StyledOption value={0} disabled>
                  Type
                </StyledOption>
                {types.map((s) => (
                  <StyledOption key={s.Id} value={s.Id}>
                    {s.Name}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Grid>
            <Grid item>
              <CustomSelect
                defaultValue={0}
                onChange={(option) => {
                  setApprovalStatus(option);
                }}
              >
                <StyledOption value={0} disabled>
                  Approval Status
                </StyledOption>
                {approvalStatuses.map((s) => (
                  <StyledOption key={s.Id} value={s.Id}>
                    {s.Name}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Grid>
            <Grid item>
              <CustomSelect
                defaultValue={0}
                onChange={(option) => {
                  setStatus(option);
                }}
              >
                <StyledOption value={0} disabled>
                  Status
                </StyledOption>
                {statuses.map((s) => (
                  <StyledOption key={s.Id} value={s.Id}>
                    {s.Name}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Grid>
            <Grid item>
              <CustomSelect
                defaultValue={0}
                onChange={(option) => {
                  setProject(option);
                }}
              >
                <StyledOption value={0} disabled>
                  Project Reference
                </StyledOption>
                {projects.map((s) => (
                  <StyledOption key={s.Id} value={s.Id}>
                    {s.Name}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Grid>
            <Grid item>
              <CustomSelect
                defaultValue={0}
                onChange={(option) => {
                  setDiscipline(option);
                }}
              >
                <StyledOption value={0} disabled>
                  Discipline Reference
                </StyledOption>
                {disciplines.map((s) => (
                  <StyledOption key={s.Id} value={s.Id}>
                    {s.Name}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Grid>
            <Grid item marginTop={0.6}>
              <span
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={() => {
                  setStatus(null);
                  setCreator(null);
                  setApprovalStatus(null);
                  setProject(null);
                  setDiscipline(null);
                  setType(null);
                }}
                style={{
                  fontFamily: "Muli",
                  fontSize: 10,
                  color: "#707070",
                  paddingTop: 20,
                  paddingRight: 15,
                }}
              >
                Clear filters
              </span>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={2}
          container
          direction={"row-reverse"}
          paddingRight={{ md: 4, xs: 0 }}
        >
          <Box
            sx={{
              p: 0.5,
              pb: 0,
            }}
          >
            <TextField
              variant="standard"
              fullWidth
              onChange={inputHandler}
              placeholder="Search this list…"
              InputProps={{
                startAdornment: (
                  <SearchIcon
                    fontSize="small"
                    htmlColor={"#707070"}
                    style={{ paddingLeft: 5 }}
                  />
                ),
              }}
              sx={{
                fontFamily: "Muli",
                backgroundColor: "white",
                borderRadius: 1,
                width: {
                  xs: 1,
                  sm: "auto",
                },
                m: (theme) => theme.spacing(1, 0.5, 1.5),
                "& .MuiSvgIcon-root": {
                  mr: 0.5,
                },
                "& .MuiInput-underline:before": {
                  borderBottom: 0,
                  borderColor: "white",
                },
              }}
            />
          </Box>
        </Grid>

        <div
          style={{
            height: 500,
            width: "96.5%",
            fontFamily: "Muli",
            backgroundColor: "white",
            marginTop: 10,
            marginLeft: "2%",
          }}
        >
          <DataGrid
            initialState={{
              pagination: {
                pageSize: 25,
              },
            }}
            pagination
            paginationMode="server"
            rows={documentData ? documentData : []}
            columns={columns}
            headerHeight={40}
            density={"compact"}
            showColumnRightBorder
            sx={{
              [`& .${gridClasses.row}`]: {
                color: "#0674b9",
                overflow: "auto",
                bgcolor: (theme) =>
                  theme.palette.mode === "light" ? grey[0] : grey[500],
              },

              [`& .${gridClasses.columnHeader}`]: {
                bgcolor: "rgb(211 225 238)",
                color: "rgb(17 24 84)",
              },
              [`& .${gridClasses.columnHeaderTitle}`]: {
                fontFamily: "Muli",
                fontWeight: "bold",
              },

              [`& .${gridClasses.footerContainer}`]: {
                bgcolor: "rgb(216 235 247)",
                minHeight: "30px",
              },
              fontFamily: "Muli",
              fontSize: "13px",
            }}
            disableColumnFilter
            getRowId={(row) => row.Id}
            rowCount={dataLength}
            hideFooter={props.paging}
            onPageChange={(newPage) => {
              if (pageSize > dataLength) {
                setPageNumber(1);
              } else setPageNumber(newPage + 1);
            }}
            onPageSizeChange={(newPage) => {
              setPageSize(newPage);
            }}
            pageSize={pageSize}
            loading={loadingData}
            components={{
              NoRowsOverlay: () => (
                <Stack className={classes["EmptyMessage"]}>
                  No documents to display
                </Stack>
              ),
            }}
          />
        </div>

        <Menu
          id="more-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <MenuItem
            onClick={() => {
              window.open(rowData?.row?.File.URL);
            }}
            style={{
              fontFamily: "Muli",
              fontSize: "13px",
              color: "#444",
            }}
          >
            Download
          </MenuItem>
        </Menu>
      </Grid>
    </div>
  );
}
