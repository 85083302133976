import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import classes from "../DocumentModal.module.css";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import infoTip from "../../../../../Assets/Images/form/info.png";
import { IconButton } from "@mui/material";
import * as SaasServices from "../../../../Services/saas-services.proxy";
import { MenuItem } from "@mui/material";
import fileIcon from "../../../../Assets/Images/Table/file.png";
import fileIconSub from "../../../../Assets/Images/Table/FileSub.png";
import CircularProgress from "@mui/material/CircularProgress";
import { Tooltip } from "@mui/material";
import * as proAndSubServices from "../../../../Services/activities-services.proxy";

const getProjectList = proAndSubServices.getProjectList;
const getDocumentInputData = SaasServices.getDocumentInputData;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "30%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  height: "75%",
  width: "60%",
  overflow: "scroll",
};

function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}

const DocumentModal = (props) => {
  const AccountType = localStorage.getItem("AccountType");

  const [open, setOpen] = useState(false);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [discipline, setDiscipline] = useState("");
  const [project, setProject] = useState("");

  const [status, setStatus] = useState("");

  const [approval, setApproval] = useState("");

  const [number, setNumber] = useState("");
  const [version, setVersion] = useState("");

  const [type, setType] = useState("");
  const [size, setSize] = useState("");
  const [rfis, setRfis] = useState("");
  const [uploadDate, setUploadDate] = useState("");
  const [revision, setRevision] = useState("");
  const [revisionDate, setRevisionDate] = useState("");
  const [creator, setCreator] = useState("");

  const [documentId, setDocumentId] = useState(null);

  const [subProject, setSubProject] = useState(null);
  const [phaseName, setPhaseName] = useState(null);

  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const openTip = () => setTooltipIsOpen(true);
  const closeTip = () => setTooltipIsOpen(false);
  const BlueTip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      componentsProps={{ tooltip: { className: className } }}
    />
  ))(`
        color: #101650;
        background-color: rgba(6, 116, 185, 0.3);
        font-size: 10px;
        width:"10em;
     
    `);

  useEffect(() => {
    if (props.documentId) {
      setDocumentId(props.documentId);
    } else {
      setDocumentId(null);
    }
  }, [props, setDocumentId, documentId]);

  const [file, setFile] = useState(null);
  const [fileId, setFileId] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [subProjectId, setSubProjectId] = useState(null);
  const [disciplines, setDisciplines] = useState([]);
  const [projects, setProjects] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [approvals, setApprovals] = useState([]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setName("");
    setDescription("");
    setVersion("");
    setNumber("");
    setDiscipline("");
    setProject("");
    setStatus("");
    setApproval("");
    setType("");
    setSize("");
    setRfis("");
    setUploadDate("");
    setRevision("");
    setRevisionDate("");
    setCreator("");
    setFile(null);
    setFileId(null);
    setDocumentId(null);
    setOpen(false);
    props.setOpenModal(false);
  };
  useEffect(() => {
    if (props.openModal) {
      handleOpen(true);
    }
  }, [props.openModal]);

  useEffect(() => {
    setLoadingData(true);
    getDocumentInputData(documentId).then((x) => {
      if (documentId) {
        setName(x.Name);
        setDescription(x.Description);
        setVersion(x.Version);
        setNumber(x.Number);
        setDiscipline(x.DisciplineId);
        setProject(x.ProjectId);
        setStatus(x.StatusId);
        setApproval(x.ApprovalStatusId);
        //props.setStatusType(x.ApprovalStatusId);
        setSubProject(x.SubProject);
        setPhaseName(x.Phase);
        setSubProjectId(x.SubProjectId);
        setType(x.File.Type);
        setSize(bytesToSize(x.File.Size));
        setRfis(x.RFISNumber);
        setUploadDate(x.CreationDate);
        setRevision(x.RevisionNumber);
        setRevisionDate(x.RevisionDate);
        setCreator(x.Creator);
        setFile(x.File);
        setFileId(x.File.FileId);
        setLoadingData(false);
      }
      setDisciplines(x.Discipline);
      // setProjects(x.Projects);
      setStatuses(x.Statuses);
      setApprovals(x.ApprovalStatuses);
      getProjectList().then((x) => {
        setProjects(x);
      });
    });
  }, [documentId]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container direction={"column"}>
          <Grid
            item
            alignSelf="flex-end"
            paddingRight="19.5%"
            paddingTop="50px"
          >
            <CloseIcon
              fontSize="large"
              onClick={handleClose}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              style={{ color: "#fff" }}
            />
          </Grid>
          {loadingData ? (
            <Grid container alignItems={"center"} justifyContent={"center"}>
              <CircularProgress />
              <Grid
                item
                fontFamily={"Muli"}
                fontSize={15}
                fontWeight={"bold"}
                color={"rgb(25, 118, 210)"}
              >
                Loading Document...
              </Grid>
            </Grid>
          ) : (
            <Grid item>
              <Box sx={style} height={"75%"}>
                <Grid container alignItems="center" direction="column" gap={1}>
                  <Grid item className={classes.newDocument} paddingTop={1}>
                    View Document
                  </Grid>
                  <Grid
                    item
                    className={classes["Line-402"]}
                    alignSelf={"center"}
                    justifySelf={"center"}
                    width="90%"
                  ></Grid>
                  <Grid item container paddingLeft={"5%"} gap={1}>
                    <Grid
                      item
                      xs={12}
                      className={classes["Document-Information"]}
                      alignSelf="flex-start"
                    >
                      Document Information
                    </Grid>
                    {AccountType === "1" ? (
                      <Grid
                        item
                        className={classes["Line-403-Main"]}
                        width="176px"
                      />
                    ) : (
                      <Grid
                        item
                        className={classes["Line-403-Sub"]}
                        width="176px"
                      />
                    )}

                    <Grid item container direction={"row"}>
                      <Grid item container direction={"column"} md={6} xs={12}>
                        <Grid item>
                          <span className={classes["field-label"]}>*Name</span>
                        </Grid>
                        <Grid item>
                          <TextField
                            className={classes["Field-input"]}
                            value={name}
                            id="outlined-name"
                            size="small"
                            InputProps={{
                              style: { height: "30px" },
                            }}
                            disabled
                          />
                        </Grid>
                        <Grid item container direction={"column"}>
                          <Grid item>
                            <span className={classes["field-label"]}>
                              *Description
                            </span>
                          </Grid>
                          <Grid item>
                            <TextField
                              className={classes["Field-input"]}
                              value={description}
                              multiline
                              id="outlined-name"
                              size="small"
                              InputProps={{
                                style: { height: "30px" },
                              }}
                              disabled
                            />
                          </Grid>
                        </Grid>
                        <Grid item container direction={"row"}>
                          <Grid item container direction={"column"} xs={6}>
                            <Grid item>
                              <span className={classes["field-label"]}>
                                Version
                              </span>
                            </Grid>
                            <Grid item>
                              <TextField
                                value={version}
                                className={classes["Field-input2"]}
                                InputProps={{ style: { height: "30px" } }}
                                id="outlined-name"
                                size="small"
                                disabled
                              />
                            </Grid>
                          </Grid>
                          <Grid item container direction={"column"} xs={6}>
                            <Grid item>
                              <span className={classes["field-label"]}>
                                Number
                              </span>
                            </Grid>
                            <Grid item>
                              <TextField
                                value={number}
                                className={classes["Field-input2"]}
                                id="outlined-name"
                                size="small"
                                InputProps={{ style: { height: "30px" } }}
                                disabled
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item container direction={"column"}>
                          <Grid item>
                            <span className={classes["field-label"]}>
                              *Project Reference
                            </span>
                          </Grid>
                          <Grid item>
                            <TextField
                              select
                              value={project}
                              className={classes["Field-input"]}
                              id="outlined-name"
                              size="small"
                              InputProps={{ style: { height: "30px" } }}
                              disabled
                            >
                              {projects.map((option) => (
                                <MenuItem
                                  value={option.Id}
                                  key={option.Id}
                                  style={{ display: "block" }}
                                >
                                  {option.Name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                        </Grid>
                        <Grid item container direction={"column"}>
                          <Grid item>
                            <span className={classes["field-label"]}>
                              SubProject
                            </span>
                          </Grid>
                          <Grid
                            item
                            className={classes["Rectangle-1169"]}
                            width={"90%"}
                          >
                            {subProject ? subProject : "Subproject"}
                          </Grid>
                        </Grid>
                        <Grid item container direction={"column"}>
                          <Grid item>
                            <span className={classes["field-label"]}>
                              *Discipline
                            </span>
                          </Grid>
                          <Grid item>
                            <TextField
                              select
                              value={discipline}
                              disabled
                              className={classes["Field-input"]}
                              id="outlined-name"
                              size="small"
                              InputProps={{ style: { height: "30px" } }}
                            >
                              {disciplines.map((option) => (
                                <MenuItem
                                  value={option.Id}
                                  key={option.Id}
                                  style={{ display: "block" }}
                                >
                                  {option.Name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item container direction={"column"} md={6} xs={12}>
                        <Grid item container direction={"row"}>
                          <Grid item container direction={"column"} xs={6}>
                            <Grid item>
                              <span className={classes["field-label"]}>
                                *Status
                              </span>
                            </Grid>
                            <Grid item>
                              <TextField
                                select
                                value={status}
                                className={classes["Field-input2"]}
                                InputProps={{ style: { height: "30px" } }}
                                id="outlined-name"
                                size="small"
                                disabled
                              >
                                {statuses.map((option) => (
                                  <MenuItem
                                    value={option.Id}
                                    key={option.Id}
                                    style={{ display: "block" }}
                                  >
                                    {option.Name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                          </Grid>
                          <Grid item container direction={"column"} xs={6}>
                            <Grid item>
                              <span className={classes["field-label"]}>
                                *Approval Status
                              </span>
                            </Grid>
                            <Grid item>
                              <TextField
                                select
                                value={approval}
                                className={classes["Field-input2"]}
                                id="outlined-name"
                                size="small"
                                InputProps={{ style: { height: "30px" } }}
                                disabled
                              >
                                {approvals.map((option) => (
                                  <MenuItem
                                    value={option.Id}
                                    key={option.Id}
                                    style={{ display: "block" }}
                                  >
                                    {option.Name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item container direction={"row"}>
                          <Grid item container direction={"column"} xs={6}>
                            <Grid item>
                              <span className={classes["field-label2"]}>
                                Type
                              </span>
                            </Grid>
                            <Grid item>
                              <TextField
                                value={type}
                                disabled
                                className={classes["Field-input2"]}
                                id="outlined-name"
                                size="small"
                                InputProps={{ style: { height: "30px" } }}
                              />
                            </Grid>
                          </Grid>
                          <Grid item container direction={"column"} xs={6}>
                            <Grid item>
                              <span className={classes["field-label2"]}>
                                Size
                              </span>
                            </Grid>
                            <Grid item>
                              <TextField
                                value={size}
                                disabled
                                className={classes["Field-input2"]}
                                id="outlined-name"
                                size="small"
                                InputProps={{ style: { height: "30px" } }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item container direction={"row"}>
                          <Grid item container direction={"column"} xs={6}>
                            <Grid item>
                              <span className={classes["field-label2"]}>
                                Number of RFIs
                              </span>
                            </Grid>
                            <Grid item>
                              <TextField
                                value={rfis}
                                disabled
                                className={classes["Field-input2"]}
                                id="outlined-name"
                                size="small"
                                InputProps={{ style: { height: "30px" } }}
                              />
                            </Grid>
                          </Grid>
                          <Grid item container direction={"column"} xs={6}>
                            <Grid item>
                              <span className={classes["field-label2"]}>
                                Upload Date
                              </span>
                            </Grid>
                            <Grid item>
                              <TextField
                                value={uploadDate?.slice(0, 10)}
                                disabled
                                className={classes["Field-input2"]}
                                id="outlined-name"
                                size="small"
                                InputProps={{ style: { height: "30px" } }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item container direction={"row"}>
                          <Grid item container direction={"column"} xs={6}>
                            <Grid item>
                              <span className={classes["field-label2"]}>
                                Revision Number
                              </span>
                            </Grid>
                            <Grid item>
                              <TextField
                                value={revision}
                                disabled
                                className={classes["Field-input2"]}
                                InputProps={{ style: { height: "30px" } }}
                                id="outlined-name"
                                size="small"
                              />
                            </Grid>
                          </Grid>
                          <Grid item container direction={"column"} xs={6}>
                            <Grid item>
                              <span className={classes["field-label2"]}>
                                Revision Date
                              </span>
                            </Grid>
                            <Grid item>
                              <TextField
                                value={revisionDate?.slice(0, 10)}
                                disabled
                                className={classes["Field-input2"]}
                                id="outlined-name"
                                size="small"
                                InputProps={{ style: { height: "30px" } }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item container direction={"row"}>
                          <Grid item container direction={"column"} xs={6}>
                            <Grid item>
                              <span className={classes["field-label2"]}>
                                Creator
                              </span>
                            </Grid>
                            <Grid item>
                              <TextField
                                value={creator}
                                disabled
                                className={classes["Field-input2"]}
                                id="outlined-name"
                                size="small"
                                InputProps={{ style: { height: "30px" } }}
                              />
                            </Grid>
                          </Grid>
                          <Grid item container direction={"column"} xs={6}>
                            {file ? (
                              <Grid item container paddingTop={1} xs={2}>
                                {documentId ? (
                                  <Grid item marginRight={1}>
                                    <IconButton
                                      edge="end"
                                      onClick={openTip}
                                      onMouseOver={openTip}
                                      onMouseLeave={closeTip}
                                    >
                                      <BlueTip
                                        open={tooltipIsOpen}
                                        title={"Click on file to download"}
                                        placement="right"
                                      >
                                        <img
                                          width={"15px"}
                                          src={infoTip}
                                          alt={"close"}
                                        ></img>
                                      </BlueTip>
                                    </IconButton>
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                <Grid
                                  item
                                  container
                                  direction="row"
                                  alignContent="center"
                                  paddingLeft={1}
                                  paddingTop={1}
                                  xs={10}
                                >
                                  <Grid item xs={1.5}>
                                    <img
                                      src={
                                        AccountType === "1"
                                          ? fileIcon
                                          : fileIconSub
                                      }
                                      alt="file"
                                      width={15}
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <span
                                      onMouseOver={(e) => {
                                        e.target.style.color = "grey";
                                        e.target.style.cursor = "pointer";
                                      }}
                                      onMouseLeave={(e) => {
                                        e.target.style.color = "black";
                                      }}
                                      onClick={() => {
                                        window.open(file.URL);
                                      }}
                                    >
                                      {file.DisplayName.slice(0, 7)}
                                    </span>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ) : (
                              <Grid item></Grid>
                            )}
                          </Grid>
                        </Grid>
                        {subProject ? (
                          <Grid item container direction={"column"}>
                            <Grid item>
                              <span className={classes["field-label"]}>
                                Phase
                              </span>
                            </Grid>
                            <Grid
                              item
                              className={classes["Rectangle-1170"]}
                              width={"90%"}
                            >
                              {phaseName}
                            </Grid>
                          </Grid>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
        </Grid>
      </Modal>
    </div>
  );
};

export default DocumentModal;
