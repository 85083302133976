import React, { useState, useEffect } from "react";
import { Grid, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import addIcon from "../../../Assets/Images/addIcon.png";
import removeIcon from "../../../Assets/Images/removeIcon.png";
import * as shapesServices from "../../../Services/sales-order-services.proxy";
const getPublishedShapes = shapesServices.getPublishedShapes;

const styles = {
  title: {
    fontFamily: "Muli",
    fontSize: "18px",
    color: " #101650",
    fontWeight: "bold",
  },

  shapeRectangle: {
    fontWeight: "bold",
    lineHeight: 1.5,
    textAlign: "center",
    color: "#0674b9",
    backgroundColor: "#fff",
  },

  code: {
    fontFamily: "Muli",
    fontSize: "10px",
    fontWeight: "bold",
    textAlign: "left",
    color: "#0674b9",
  },
};

function ShapeLibraryList(props) {
  const [searchText, setSearchText] = useState(null);

  const [modify, setModify] = useState(false);

  const [toggleIcon, setToggleIcon] = useState();

  const [selectedShape, setSelectedShape] = useState([]);

  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };
  useEffect(() => {
    getPublishedShapes(searchText).then((x) => {
      props.setShapes(x.Shapes);
    });
  }, [searchText]);
  return (
    <Grid container xs={12} height={"fit-content"} item>
      <Grid
        item
        xs={12}
        sx={styles.title}
        fontSize={{ sm: "13px", lg: "18px" }}
      >
        Universal Shape Library
      </Grid>
      <Grid item xs={12} paddingTop={2}>
        <TextField
          size="small"
          fullWidth
          value={searchText}
          onChange={inputHandler}
          placeholder="Search this list…"
          InputProps={{
            startAdornment: (
              <SearchIcon
                fontSize="small"
                htmlColor={"#707070"}
                style={{ paddingLeft: 5 }}
              />
            ),
          }}
          sx={{
            fontFamily: "Muli",
            backgroundColor: "white",
            borderRadius: 1,

            "& .MuiInput-underline:before": {
              borderBottom: 0,

              borderColor: "white",
            },
          }}
        />
      </Grid>
      <Grid
        item
        container
        bgcolor={"#f2f2f2"}
        xs={12}
        gap={1}
        paddingBottom={5}
        paddingTop={2}
        justifyContent="center"
        alignItems="center"
      >
        {props.shapes.map((shape) => (
          <Grid
            key={shape.Id}
            item
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            xs={10}
          >
            <Grid
              item
              xs={4}
              sx={styles.shapeRectangle}
              alignSelf="flex-start"
              paddingRight={2}
              paddingLeft={2}
              onClick={() => {
                props.setSelectedShape(shape);
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
            >
              <img src={addIcon} />
            </Grid>
            <Grid
              item
              sx={styles.shapeRectangle}
              xs={10}
              container
              direction="row"
              height="150px"
              key={shape.id}
              marginBottom={2}
              padding={1}

              // onClick={() => {

              // }}
            >
              <Grid
                item
                alignSelf="center"
                justifySelf="center"
                paddingTop={4}
                paddingBottom={4}
                container
              >
                <img
                  src={shape?.File?.URL}
                  alt={"shapeImage"}
                  width={"100%"}
                  height={124}
                />
              </Grid>
              <Grid item xs={12} sx={styles.code}>
                Code:
                {shape.subCode ? shape.Code + shape.subCode : shape.Code}
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default ShapeLibraryList;
