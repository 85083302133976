import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import ChangePassword from "./Settings/ChangePassword";
import EditProfile from "./Settings/EditProfile";
import ManageSubscription from "./Settings/ManageSubscription";
import settings from "../../Assets/Images/Settings/settings.png";
import ContactUsPricing from "./ManageSubscription/ContactUsPricing";
import ManageDaysHours from "./Settings/ManageDaysHours";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../Assets/Styles/home.css";

const styles = {
  menu: {
    height: localStorage.getItem("isTeamMember") !== "true" ? 180 : 150,
    borderRadius: 4,
    border: "solid 1px #e9e7e7",
    backgroundColor: "#fff",
  },
  menuItem: {
    fontFamily: "Muli",
    padding: "3% 0 1.5% 7%",
    fontSize: 13,
    fontWeight: 300,
    lineHeight: 1.46,
    textAlign: "left",
    color: "#101650",
  },
  activeMenuItem: {
    fontFamily: "Muli",
    padding: "3% 0 1.5% 6.3%",
    fontSize: 13,
    fontWeight: 300,
    lineHeight: 1.46,
    textAlign: "left",
    color: "#101650",
    backgroundColor: "#e9e7e7",
    borderLeft:
      localStorage.getItem("isTeamMember") === "true"
        ? "solid #8BBFE24D"
        : localStorage.getItem("AccountType") === "1"
        ? "solid  #e1e319"
        : "solid  #2da4d0",
  },
  title: {
    height: 88,
    borderRadius: 4,
    border: "solid 1px #e9e7e7",
    backgroundColor: " #fff",
  },
  details: {
    borderRadius: 4,
    border: "solid 1px #fff",
    backgroundColor: "#fff",
  },
  titleDetails: {
    fontFamily: "Muli",
    fontSize: 20,
    fontWeight: 300,
    lineHeight: 1.5,
    textAlign: "left",
    color: "#101650",
  },
};

export default function Settings({
  active5,
  setActive5,
  active,
  setActive,
  setCn,
  cn,
  setProfileSrc,
  setRegister,
  setNaved,
  setSetupProfile,
}) {
  const navigate = useNavigate();

  useEffect(() => {
    let apiTest = true;
    if (localStorage.getItem("accessToken") && apiTest) {
      if (localStorage.getItem("isVerified") === "false") {
        if (localStorage.getItem("isTeamMember") === "true")
          navigate("/teamMemberRegistration");
        else {
          if (localStorage.getItem("AccountType") === "1") navigate("/main");
          if (localStorage.getItem("AccountType") === "2") navigate("/sub");
        }
      }
    } else navigate("/login");
    return () => {
      apiTest = false;
    };
  }, [navigate]);

  useEffect(() => {
    setRegister(true);
  }, [setRegister]);

  useEffect(() => {
    setNaved(false);
  }, [setNaved]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [item, setItem] = useState(true);
  const [item1, setItem1] = useState(false);
  const [item2, setItem2] = useState(false);
  const [item3, setItem3] = useState(false);
  const [manageSubscriptionPermissions, setManageSubscriptionPermissions] =
    useState({});

  const [showContactUsPricing, setShowContactUsPricing] = useState(false);
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  useEffect(() => {
    UserPermissions?.map((up) =>
      up.Permissions.map((p) => {
        if (p.Name === "Manage Subscription") {
          setManageSubscriptionPermissions(p);
        }
      })
    );
  }, [UserPermissions]);
  return (
    <div className="Rectangle779">
      {showContactUsPricing ? (
        <ContactUsPricing />
      ) : (
        <Grid container padding={"5% 0 0 10%"}>
          <Grid
            item
            xs={11.5}
            md={2.7}
            marginBottom={{ xs: 2, md: 0 }}
            sx={styles.menu}
            container
            rowSpacing={1}
            padding="1% 0 1% 0"
          >
            <Grid
              item
              xs={12}
              sx={item ? styles.activeMenuItem : styles.menuItem}
              onClick={() => {
                setItem(true);
                setItem1(false);
                setItem2(false);
                setItem3(false);
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
            >
              Edit My Profile
            </Grid>
            <Grid
              item
              xs={12}
              sx={item1 ? styles.activeMenuItem : styles.menuItem}
              onClick={() => {
                setItem1(true);
                setItem(false);
                setItem2(false);
                setItem3(false);
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
            >
              Change My Password
            </Grid>
            {localStorage.getItem("isTeamMember") === "false" ||
            manageSubscriptionPermissions?.Actions?.length > 0 ? (
              <Grid
                item
                xs={12}
                sx={item2 ? styles.activeMenuItem : styles.menuItem}
                onClick={() => {
                  setItem2(true);
                  setItem1(false);
                  setItem3(false);
                  setItem(false);
                }}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
              >
                Manage My Subscription
              </Grid>
            ) : (
              <></>
            )}
            {UserPermissions.find((x) => x.Name === "Engineer") ||
            UserPermissions.find((x) => x.Name === "Document Controller") ? (
              <></>
            ) : (
              <Grid
                item
                xs={12}
                sx={item3 ? styles.activeMenuItem : styles.menuItem}
                onClick={() => {
                  setItem3(true);
                  setItem1(false);
                  setItem2(false);
                  setItem(false);
                }}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
              >
                Manage Working Days & Hours
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sx={styles.menuItem}
              onClick={() => {
                setSetupProfile(false);
                navigate("/terms-and-conditions");
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
            >
              Terms and Conditions
            </Grid>
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid
            item
            md={item1 ? 5 : item2 ? 8 : 7}
            xs={11.5}
            container
            gap={4}
          >
            <Grid
              item
              xs={12}
              sx={styles.title}
              container
              alignItems="center"
              paddingLeft="3%"
            >
              <Grid
                item
                xs={item1 ? 3 : 2}
                md={item1 ? 2.5 : 1.5}
                lg={item1 ? 2 : 1.2}
                xl={item1 ? 2 : 1}
              >
                <img src={settings} alt={"settings"} />
              </Grid>
              <Grid item sx={styles.titleDetails}>
                {item1
                  ? "Change Password"
                  : item2
                  ? "Manage My Subscription"
                  : item3
                  ? "Manage Working Days & Hours"
                  : "Profile"}
              </Grid>
            </Grid>
            <Grid item xs={12} sx={styles.details}>
              {item1 ? (
                <ChangePassword />
              ) : item2 ? (
                <Grid item md={12} sx={styles.details}>
                  <ManageSubscription
                    setShowContactUsPricing={setShowContactUsPricing}
                  />
                </Grid>
              ) : item3 ? (
                <ManageDaysHours />
              ) : (
                <EditProfile
                  cn={cn}
                  setCn={setCn}
                  active5={active5}
                  setActive5={setActive5}
                  active={active}
                  setActive={setActive}
                  setProfileSrc={setProfileSrc}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
