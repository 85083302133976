import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Activities from "../Components/Execution/Activities";
import "../Assets/Styles/home.css";
export default function Execution({ setRegister, setNaved, setShowSaaSNavbar }) {
  const navigate = useNavigate();

  useEffect(() => {
    let apiTest = true;
    if (localStorage.getItem("accessToken") && apiTest) {
      if (localStorage.getItem("isVerified") === "false") {
        if (localStorage.getItem("isTeamMember") === "true")
          navigate("/teamMemberRegistration");
        else {
          if (localStorage.getItem("AccountType") === "1") navigate("/main");
          if (localStorage.getItem("AccountType") === "2") navigate("/sub");
        }
      }
    }
    else navigate('/login');
    return () => {
      apiTest = false;
    }
  }, [navigate]);

  useEffect(() => {
    setRegister(true);
  }, [setRegister]);
  useEffect(() => {
    setShowSaaSNavbar(true);
  }, [setShowSaaSNavbar]);

  useEffect(() => {
    setNaved(false);
  }, [setNaved]);
  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);
  return (
    <div className="Rectangle779">
      <Activities />
    </div>
  );
}
