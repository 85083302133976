import React, { useEffect } from "react";
import InventoryMainView from "./InventoryMainView";
const InventorySetup = (props) => {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);
  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  return <InventoryMainView />;
};

export default InventorySetup;
