import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Grid, TextField } from "@mui/material";
import axios from "axios";
import Button from "@mui/material/Button";
import whyPC from "../../Assets/Images/home/whypc.png";
import mail from "../../Assets/Images/home/whitemail.png";
import sentmail from "../../Assets/Images/home/SentWhite.png";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import Animation from './Animation';
import SentMobile from "./sentMobile";
function BookDemo({setBooked,setRegister}) {
  const [email, setEmail] = useState("");
  const [emailInValid, setEmailInValid] = useState(false);
  const [disabled, setIsDisabled] = useState(true);
  const [mailDemo,setMailDemo] = useState(false);

  useEffect(()=>{
    setRegister(false)
  },[setRegister])

  function sendDemo() {
    axios
      .post(`${process.env.REACT_APP_URL}/email/request-demo`, {
        FromAddress: email,
      })
      .then((res) => {
        setSent(true);
          setAnime(true);
          setTimeout(() => {
              setAnime(false);
          }, 3000);
      })
      .catch((err) => {
        return err;
      });
  }

  useEffect(()=>{setBooked(true)},[setBooked])

  const [sent,setSent] = useState(false);
  const [anime,setAnime] = useState(false);
  const navigate = useNavigate();
  return (
    <Box
      sx={{ flexGrow: 1 }}
      style={{
        backgroundImage: `url(${whyPC})`,
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        paddingBottom:"30%",
        marginTop: '15%'
      }}
    >
      <Grid container>
        <Grid item xs={12} style={{ marginLeft: "23%", marginTop: "5%"}}>
          {
            mailDemo?
            <img src={sentmail} alt="Logo" width='65%'/>
            :
            <img src={mail} alt="Logo" width='65%'/>
          }
        </Grid>
        <Grid item xs={12}>
          <CloseIcon onClick={()=>{setMailDemo(false);setBooked(false);navigate('/')}} htmlColor="#fff" style={{ marginLeft: "81%" }} />
        </Grid>
        <Grid item xs={12} container>
          <Grid item constainer className="boxDemo" style={{paddingTop:'5%',marginLeft:"13%"}}>
              {
                sent?
                <Grid item>
                  {
                    anime?
                    <Animation  />
                    :
                    <SentMobile setBooked={setBooked}/>
                  }
                </Grid>
                :
                <Grid item container direction={"column"} spacing={4} padding={'12% 7% 7% 7%'}>
                <Grid item container direction={"column"}>
                  <span
                    style={{
                      fontFamily: "Muli",
                      color: "#080707",
                      fontSize: 15,
                      lineHeight: 1.47,

                    }}
                  >
                    Enter email address*
                  </span>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    error={emailInValid}
                    helperText={
                      emailInValid ? "Please enter a valid email address " : ""
                    }
                    onChange={(e) => {
                      setEmail(e.target.value);
                      if (
                        (e.target.value === "") ||
                        !e.target.value.match(/^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/)
                      ) {
                        setEmailInValid(true);
                        setIsDisabled(true);
                      } else {
                        setIsDisabled(false);
                        setEmailInValid(false);
                      }
                    }}
                  />
                </Grid>
                <Grid item alignSelf={"center"}>
                  <Button
                    className="submitButton1"
                    disabled={disabled}
                    onMouseOver={(e) => {
                      e.target.style.backgroundColor = "#D8DA18";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#E1E319";
                    }}
                    style={{
                      backgroundColor: "#E1E319",
                      color: "#fff",
                      borderRadius: 7,
                      textTransform: "none",
                      fontWeight:'bold',
                      fontSize:16,
                      padding: '5%',
                      width:150
                    }}
                    onClick={(e) => {
                      setMailDemo(true);
                      sendDemo();
                    }}
                  >
                    Start booking
                  </Button>
                </Grid>
              </Grid>
              }
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
export default BookDemo;