import React, { useState } from "react";
import { Grid, MenuItem } from "@mui/material";
import classes from "../../../Assets/Styles/Delivery.module.css";
import DotsEditYellow from "../../../Assets/Images/dotsEditYellow.png";
import { Navigate, useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";

function ShipmentCard(props) {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (props.factoryFilter !== 0) {
      setAnchorEl(event.currentTarget);

    } else props.setHint(true);

  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid
      item
      container
      border="solid 1px #828282"
      height="170px"
      padding={0.5}
      className={classes["ShipmentCardDetails"]}
      alignContent="flex-start"
      borderRadius="5px"

    >
      <Grid
        item
        xs={12}
        container
        className={classes["ShipmentCardTitles"]}
        justifyContent="space-between"
        alignItems="center"
        direction="row"
      >
        <Grid item>
          {props.data.ShipmentNb}
        </Grid>


        <Grid item>
          <img
            src={DotsEditYellow}
            width={20}
            height={25}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            onClick={(e) => {
              if (props.disabledD === false) {
                if (props.factoryFilter !== 0) handleClick(e);
                else props.setHint(true);
              }
              else props.setHint(true);
              // props.setShipmentId(props.data.Id)
              // props.setOpenModal(true);
            }}
          />
        </Grid>
        <Grid item container direction="column" >
          {props.data.LoadNbs?.map((l) => (
            <Grid item >{l}</Grid>
          ))}
        </Grid>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          disableScrollLock={true}
        >
          <MenuItem
            style={{
              fontFamily: "Muli",
              fontSize: "11px",
              color: "#707070",
            }}
            onClick={() => {

              props.getShipmentInputData(props.data.Id);
              props.setOpenModal(true);
              handleClose();
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            style={{
              fontFamily: "Muli",
              fontSize: "11px",
              color: "#707070",
            }}
            onClick={() => {
              props.setOpenDeleteModal(true);
              props.setShipmentId(props.data.Id);
              handleClose();
            }}
          >
            Delete
          </MenuItem>
          <MenuItem
            style={{
              fontFamily: "Muli",
              fontSize: "11px",
              color: "#707070",
            }}
            onClick={() => {
              navigate(`/saas/Delivery/DeliveryNote?shipmentId=${props.data.Id}&shipmentNb=${props.data.ShipmentNb}&loadNb=${props.data.LoadNbs[0]}`)
              handleClose();
            }}
          >
            View Delivery Note
          </MenuItem>

        </Menu>
      </Grid>
      <Grid item container xs={12} justifyContent="space-between" paddingTop={1}>
        <Grid item>{props.data.TruckName} </Grid>
        <Grid item>{props.data.TrailerName}</Grid>
      </Grid>
      <Grid item xs={12}>
        {props.data.DriverName}
      </Grid>
      <Grid
        item
        container
        marginTop={1}
        height="70px"
        overflow="auto"
        alignContent="flex-start"
      >
        {props.data.SalesOrders?.map((s, index) => (
          <Grid item key={index}>
            {s.Code} - {s.Type}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default ShipmentCard;
