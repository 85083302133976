import React from "react";
import window from "../../../Components/Navbar/WindowDimension";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const data = [
  {
    name: "SEP",
    8: 35,
    10: 65,
    12: 70,
  },
  {
    name: "OCT",
    8: 45,
    10: 50,
    12: 55,
  },
  {
    name: "NOV",
    8: 60,
    10: 40,
    12: 40,
  },
  {
    name: "DEC",
    8: 25,
    10: 35,
    12: 65,
  },
  {
    name: "JAN",
    8: 70,
    10: 65,
    12: 40,
  },
  {
    name: "FEB",
    8: 65,
    10: 40,
    12: 60,
  },
];

export default function MyChart(props) {
  const { width } = window();

  return (
    <ComposedChart
      width={
        width > 900
          ? width < 1100
            ? 370
            : width < 1300
            ? 450
            : width < 1500
            ? 530
            : 600
          : 700
      }
      height={260}
      data={props.data}
      margin={{
        top: 20,
        right: 20,
        bottom: 20,
        left: 20,
      }}
    >
      <CartesianGrid stroke="#fff" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      {props.diameters?.map((dia, index) => (
        <Bar
          key={dia}
          dataKey={dia}
          barSize={10}
          fill={index === 0 ? "#e92d2d" : index === 1 ? "#6ec6fa" : "#024bde"}
        />
      ))}
    </ComposedChart>
  );
}
