import * as StockConst from "../../stockCountConst";
import axios from "axios";


const GET_STOCK_COUNTS = StockConst.GET_STOCK_COUNTS;
export const getStockCounts = (ZoneId)=>{
    const token = localStorage.getItem("accessToken");
    return axios
    .get( `${process.env.REACT_APP_URL + GET_STOCK_COUNTS }`,
    {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params:{
            ZoneId: ZoneId
        }
    }
    )
    .then((response)=>{
        return response.data;
    })
    .catch((error)=>{
        return error;
    })
}

const CREATE_STOCK_COUNT = StockConst.CREATE_STOCK_COUNT;
export const createStockCount = (Id,ZoneId,Title,Summary)=>{
    const token = localStorage.getItem("accessToken");
    return axios
    .post( `${process.env.REACT_APP_URL + CREATE_STOCK_COUNT }`,
    {
        Id: Id,
        ZoneId: ZoneId,
        Title: Title,
        Summary: Summary
    },
    {
        headers: {
            Authorization: `Bearer ${token}`
        },
    }
    )
    .then((response)=>{
        return response.data;
    })
    .catch((error)=>{
        return error;
    })
}

const GET_STOCK_COUNT_BY_ID = StockConst.GET_STOCK_COUNT_BY_ID;
export const getStockCountById = (StockCountId)=>{
    const token = localStorage.getItem("accessToken");
    return axios
    .get( `${process.env.REACT_APP_URL + GET_STOCK_COUNT_BY_ID }`,
    {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params:{
            StockCountId:StockCountId
        }
    }
    )
    .then((response)=>{
        return response.data;
    })
    .catch((error)=>{
        return error;
    })
}

const UPDATE_STOCK_COUNT = StockConst.UPDATE_STOCK_COUNT;
export const updateStockCount = (Id,ZoneId,Title,Summary)=>{
    const token = localStorage.getItem("accessToken");
    return axios
    .post( `${process.env.REACT_APP_URL + UPDATE_STOCK_COUNT }`,
    {
        Id: Id,
        ZoneId: ZoneId,
        Title: Title,
        Summary: Summary
    },
    {
        headers: {
            Authorization: `Bearer ${token}`
        },
    }
    )
    .then((response)=>{
        return response.data;
    })
    .catch((error)=>{
        return error;
    })
}


const INSERT_MATERIAL_BARCODES = StockConst.INSERT_MATERIAL_BARCODES;
export const insertMaterialBarcodes = (StockCountId,Barcodes)=>{
    const token = localStorage.getItem("accessToken");
    return axios
    .post( `${process.env.REACT_APP_URL + INSERT_MATERIAL_BARCODES }`,
    {
        StockCountId:StockCountId,
        Barcodes:Barcodes
    },
    {
        headers: {
            Authorization: `Bearer ${token}`
        },
    }
    )
    .then((response)=>{
        return response.data;
    })
    .catch((error)=>{
        return error;
    })
}

const GET_FILTERED_STOCK_ITEMS_OR_BUNDLES = StockConst.GET_FILTERED_STOCK_ITEMS_OR_BUNDLES;
export const getFilteredStockItemsOrBundles = (CategoryId,StockCountId )=>{
    const token = localStorage.getItem("accessToken");
    return axios
    .get( `${process.env.REACT_APP_URL + GET_FILTERED_STOCK_ITEMS_OR_BUNDLES }`,
    {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params:{
            CategoryId:CategoryId,
            StockCountId :StockCountId 

        }
    }
    )
    .then((response)=>{
        return response.data;
    })
    .catch((error)=>{
        return error;
    })
}