import React, { useState, useEffect } from "react";
import { styled, Box } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import classes from "../../../Assets/Styles/Subscription.module.css";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/Upload";
import IconButton from "@mui/material/IconButton";
import cancel from "../../../Assets/Images/Settings/cancel.png";
import * as profileServices from "../../../Services/profile-services.proxy";

const cancelSubscription = profileServices.cancelSubscription;

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  bgcolor: "#fff",
  border: "solid 1px #e9e7e7",

  outline: "none",
};

export default function CancelSubscription(props) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    props.setOpen(false);
  };
  useEffect(() => {
    if (props.open) {
      handleOpen(true);
    }
  }, [props.open]);

  return (
    <div>
      <StyledModal
        disableScrollLock
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
      >
        <Box sx={{ outline: "none" }}>
          <Grid
            container
            direction={"row-reverse"}
            bgcolor={"transparent"}
            marginBottom={0.5}
          >
            <IconButton
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onClick={() => {
                handleClose();
              }}
            >
              <CloseIcon htmlColor="#fff" />
            </IconButton>
          </Grid>
          <Box sx={style} width={{ md: "640px", xs: "80vw" }}>
            <Grid
              container
              direction={"column"}
              spacing={2}
              justifyContent={{ md: "center", xs: "" }}
              alignItems={{ md: "center", xs: "" }}
              paddingTop={2}
            >
              <Grid item fontSize={{ md: "20px" }}>
                <div className={classes["Modal-title"]}>
                  Your subscription will be cancelled.
                </div>
              </Grid>
              <Grid item width={{ md: "42.7vw", xs: "84vw" }}>
                <div className={classes["divider2"]}></div>
              </Grid>
              <Grid item marginLeft={{ md: 0, xs: 11 }}>
                <img src={cancel} alt={"attention"} />
              </Grid>
              <Grid
                item
                fontSize={{ md: "16px", xs: "8px" }}
                width={{ md: "540px", xs: 325 }}
              >
                <div className={classes.message}>
                  {" All the projects and account data will be safely stored, but you will have access to pilecubes saas until " +
                    new Date(
                      props.subscribedPlanEndDate
                    )?.toLocaleDateString() +
                    " and then you will need to subscribe again to your plan."}
                </div>
              </Grid>
              <Grid
                item
                fontSize={{ md: "16px", xs: "8px" }}
                width={{ md: 400, xs: 300 }}
                marginLeft={{ xs: 1.5, md: 0 }}
              >
                <div className={classes.message}>
                  Are you sure you want to cancel your subscription?
                </div>
              </Grid>
              <Grid
                item
                className={classes["Rectangle-972"]}
                marginLeft={2}
                marginTop={3}
                width={{ xs: 313, md: 640 }}
              >
                <Grid item md={11.7} container direction={"row-reverse"}>
                  <Grid item marginLeft={1}>
                    <button
                      className={classes.SaveModal}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                      onClick={() => {
                        setLoading(true);
                        cancelSubscription().then(() => {
                          props.setModify(!props.modify);
                          setLoading(false);
                          handleClose();
                        });
                      }}
                    >
                      {loading ? "Confirming" : "Confirm"}
                    </button>
                  </Grid>
                  <Grid item width={{ md: 226 }}>
                    <button
                      className={classes.exitModal}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      NO keep my subscription
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </StyledModal>
    </div>
  );
}
