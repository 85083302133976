import { React, useState, useEffect } from "react";
import { Grid, Box, Modal } from "@mui/material";
import classes from "../Create.module.css";
import * as subServices from "../../../../Services/subprojects-services.proxy";

const uploadProfile = subServices.uploadProfile;
const deleteFile = subServices.deleteFile;

const style = {
  position: "absolute",
  top: "70%",
  left: "50%",
  bottom: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  height: "400px",
  width: "500px",
};

function PictureModal(props) {
  const AccountType = localStorage.getItem("AccountType");
  const [open, setOpen] = useState(false);
  const [subImage, setSubImage] = useState(null);
  const [subImageURL, setSubImageURL] = useState(null);
  const [fileId, setFileId] = useState(null);
  const [previousFileId, setPreviousFileId] = useState(null);
  const handleOpen = () => {
    setOpen(true);
    props.setOpenModal(false);
  };
  const handleClose = () => {
    setSubImage(null);
    setSubImageURL(null);
    setOpen(false);
  };

  useEffect(() => {
    if (props.openModal) {
      handleOpen();
    }
  }, [props.openModal]);

  const onImageChange = (e) => {
    if (previousFileId) deleteFile(previousFileId, props.phaseId);
    if (fileId) setPreviousFileId(fileId);
    if (e.target.files[0]) {
      setSubImage(e.target.files[0]);
      setSubImageURL(URL.createObjectURL(e.target.files[0]));
      uploadProfile(e.target.files[0], props.phaseId).then((x) => {
        setFileId(x.FileId);
        props.setSubPicture(x.FileId);
      });
    }
  };
  return (
    <Modal
      disableScrollLock
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          marginTop={5}
          style={{ fontSize: 10, fontFamily: "Muli", color: "#101650" }}
        >
          <Grid item xs={8} style={{ fontSize: 10, fontFamily: "Muli" }}>
            Upload Subproject Picture:
          </Grid>
          <Grid item xs={2}>
            <label
              htmlFor="filePicker"
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              className={
                AccountType === "1"
                  ? classes.uploadButtonMC
                  : classes.uploadButtonSC
              }
            >
              Upload +
            </label>

            <input
              id="filePicker"
              style={{ visibility: "hidden" }}
              type="file"
              accept="image/*"
              name="file"
              onChange={onImageChange}
            />
          </Grid>
          <Grid item xs={10}>
            JPG,PNG,GIF ; Max Size: 16 MB
          </Grid>
          <Grid item xs={10} width="170px" height="235px" marginTop={3}>
            {subImageURL ? (
              <img src={subImageURL} alt={"phasseimg"} width="50%"></img>
            ) : (
              <Grid
                item
                xs={10}
                width="100%"
                height="100%"
                backgroundColor="#d9d2d2"
                marginTop={3}
              ></Grid>
            )}
          </Grid>
          {subImageURL ? (
            <Grid container direction={"row-reverse"} paddingRight={4}>
              <button
                className={
                  AccountType === "1"
                    ? classes.uploadButtonMC
                    : classes.uploadButtonSC
                }
                onMouseOver={(e) => (e.target.style.cursor = "pointer")}
                onClick={() => {
                  props.setSubPictureURL(subImageURL);
                  setSubImage(null);
                  setSubImageURL(null);
                  setOpen(false);
                }}
              >
                Save
              </button>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Box>
    </Modal>
  );
}

export default PictureModal;
