import React, { useEffect, useState, useMemo } from "react";
import { Grid } from "@mui/material";
import classes from "../../Assets/Styles/Activities.module.css";
import logo2 from "../../../Assets/Images/Navbar/logo.png";
import FileIcon from "../../Assets/Images/Table/file.png";
import * as activityServices from "../../Services/activities-services.proxy";
import { useLocation } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/system";
import infoTip from "../../../Assets/Images/form/info.png";

const getActivityByID = activityServices.getActivityByID;
const downloadActFile = activityServices.downloadActFile;
const SubmittalsEmailed = (props) => {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);
  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);
  const [activityDetails, setActivityDetails] = useState({});

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const ACTID = query.get("id");
  useEffect(() => {
    getActivityByID(ACTID).then((x) => {
      setActivityDetails(x);
    });
  }, [ACTID]);
  const renderDetails = (title, FieldData) => [
    <Grid item container xs={12} direction={"row"} gap={1}>
      <Grid item xs={4.5} className={classes["Data-title"]}>
        {title}
      </Grid>
      <Grid item xs={5} className={classes["Actual-data"]}>
        {FieldData}
      </Grid>
    </Grid>,
  ];
  const downloadTheAct = () => {
    downloadActFile(ACTID);
  };
  return (
    <Grid container paddingBottom={5}>
      <Grid item xs={12} paddingTop={3} paddingLeft={5}>
        <img src={logo2} alt={"logo"} />
      </Grid>
      <Grid container paddingTop={10} gap={2.5} paddingLeft={{ xs: 5 }}>
        <Grid item md={1} xs={0}></Grid>
        <Grid item xs={12} md={3.5}>
          <Grid item className={classes["Activity-Details2"]} marginBottom={4}>
            Activity Details
          </Grid>
          <Grid container gap={2.5}>
            {renderDetails("Project Name: ", activityDetails.Project)}
            {renderDetails("Phase Name: ", activityDetails.Phase)}
            {renderDetails("Subproject Name: ", activityDetails.SubProject)}
            {renderDetails("Discipline: ", activityDetails.Discipline)}
            {renderDetails("Code: ", activityDetails.Code)}
            {renderDetails("Type: ", activityDetails.Type)}
            {renderDetails("Predecessor:   ", activityDetails.Predecessor)}
            {renderDetails("Trade: ", activityDetails.Trade)}
            {renderDetails("Description: ", activityDetails.Description)}
            {renderDetails("Drawings Nb: ", activityDetails.DrawingsNumber)}
            {renderDetails("Nb of sheets: ", activityDetails.SheetsNumber)}
          </Grid>
        </Grid>
        <Grid container item xs={12} md={3.5} gap={2.5} paddingTop={7}>
          {renderDetails("Sheet Size: : ", activityDetails.SheetSize)}
          {renderDetails(
            "Planned Duration:",
            `${
              activityDetails?.PlannedDuration
                ? activityDetails?.PlannedDuration
                : ""
            }hr`
          )}
          {renderDetails("Submittal Status:", activityDetails.SubmittalStatus)}
          {renderDetails("Status: ", activityDetails.Status)}
          {renderDetails("Revision Nb: ", activityDetails.RevisionNumber)}
          {renderDetails("Creator: ", activityDetails.Creator)}
          <Grid item className={classes["Activity-Details2"]}>
            Activity Responsibility
          </Grid>
          {renderDetails("Team member name: ", activityDetails.TeamMemberName)}
          {renderDetails("Submitted by: ", activityDetails.SubmittedBy)}
          {renderDetails(
            "Submitted Date: ",
            activityDetails?.SubmittedDate?.slice(0, 10)
          )}
        </Grid>
        <Grid item xs={12} md={3.5}>
          <Grid item className={classes["Activity-Details2"]}>
            Submittal Content
          </Grid>
          {activityDetails?.Files?.map((file) => (
            <Grid
              container
              key={file.FileId}
              paddingTop={2}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onClick={() => {
                window.open(file.URL);
              }}
            >
              <Grid item md={1} xs={2}>
                <img src={FileIcon} alt={"file"} />
              </Grid>
              <Grid item md={11} xs={10} className={classes["Actual-data"]}>
                {file.DisplayName}
              </Grid>
            </Grid>
          ))}
          <BlueTip title={"Click on each file to download"} placement="right">
            <img
              width={"15px"}
              style={{ marginTop: "15px" }}
              src={infoTip}
              alt={"close"}
            ></img>
          </BlueTip>
        </Grid>
      </Grid>
      <Grid container item xs={11} direction={"row-reverse"} paddingRight={5}>
        <button
          className={classes["downloadALL"]}
          onClick={() => {
            downloadTheAct();
          }}
        >
          Download Response Form
        </button>
      </Grid>
    </Grid>
  );
};
const BlueTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
      color: #101650;
      background-color: rgba(6, 116, 185, 0.3);
      font-size: 13px;
      width:200px;
   
  `);
export default SubmittalsEmailed;
