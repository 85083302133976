import React, { useState, useEffect } from "react";
import { styled, Box } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import classes from "../../../Assets/Styles/profileDetails.module.css";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/Upload";
import IconButton from "@mui/material/IconButton";
import * as profileServices from "../../../Services/profile-services.proxy";
import intitialPicture from "../../../Assets/Images/ProfileDetails/profilePicture.png";
import TMIcon from "../../../../Assets/Images/Navbar/TMRevised.png";
import MainIcon from "../../../../Assets/Images/Navbar/mainIcon.png";
import SubIcon from "../../../../Assets/Images/Navbar/subIcon.png";
const updateProfilePicture = profileServices.updateProfilePicture;
const saveProfilePicture = profileServices.saveProfilePicture;
const removeProfilePicture = profileServices.removeProfilePicture;

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  bgcolor: "#fff",
  border: "solid 1px #e9e7e7",

  outline: "none",
};

export default function ProfileModal(props) {
  const [open, setOpen] = useState(false);
  const [fileId, setFileId] = useState(null);
  const [previousFileId, setPreviousFileId] = useState(null);
  const [profileSrc, setProfileSrc] = useState();
  const [file, setFile] = useState({});

  const handleOpen = () => setOpen(true);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const handleClose = () => {
    if (previousFileId) removeProfilePicture(previousFileId);
    setOpen(false);
    props.setOpen(false);
  };
  useEffect(() => {
    setProfileSrc(props.pPicture);
    setFileId(props.fileID);
  }, [props]);

  useEffect(() => {
    if (props.open) {
      handleOpen(true);
    }
  }, [props.open]);
  const onImageChange = (e) => {
    if (previousFileId) removeProfilePicture(previousFileId);
    if (fileId) setPreviousFileId(fileId);
    updateProfilePicture(e.target.files[0]).then((x) => {
      setFileId(x.FileId);
      setProfileSrc(x.URL);
      setFile(x);
    });
  };
  const saveProfile = () => {
    saveProfilePicture(fileId).then((x) => {
      localStorage.setItem("ProfileUrl", file?.URL);
      props.setPictureSrc(profileSrc);
      props.setProfileSrc(file?.URL);
      handleClose();
    });
  };
  const deleteProfile = () => {
    localStorage.removeItem("ProfileUrl");
    saveProfilePicture(null);
    setProfileSrc(intitialPicture);
    props.setPictureSrc(intitialPicture);
    if (localStorage.getItem("isTeamMember") === "true")
      props.setProfileSrc(TMIcon);
    else {
      if (localStorage.getItem("AccountType") === "1")
        props.setProfileSrc(MainIcon)
      if (localStorage.getItem("AccountType") === "2")
        props.setProfileSrc(SubIcon)
    }
    handleClose();
  };
  return (
    <div>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
      >
        <Box sx={{ outline: "none" }}>
          <Grid
            container
            direction={"row-reverse"}
            bgcolor={"transparent"}
            marginBottom={0.5}
          >
            <IconButton
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onClick={() => {
                handleClose();
              }}
            >
              <CloseIcon htmlColor="#fff" />
            </IconButton>
          </Grid>
          <Box sx={style} width={{ md: "640px", xs: "80vw" }}>
            <Grid
              container
              direction={"column"}
              spacing={2}
              justifyContent={{ md: "center" }}
              alignItems={{ md: "center" }}
              paddingTop={2}
              paddingLeft={{ xs: 0, md: 0 }}
            >
              {props.modalTitle !== "Delete Photo" && (
                <>
                  <Grid item marginLeft={{ xs: 10, md: 0 }}>
                    <div className={classes["Modal-title"]}>
                      {props.modalTitle}
                    </div>
                  </Grid>
                  <Grid item width={{ xs: "84.1vw", md: "42.7vw" }}>
                    <div className={classes["divider2"]}></div>
                  </Grid>
                  {props.modalTitle === "Update Photo" && (
                    <>
                      <Grid item>
                        <div className={classes["ModalDescription"]}>
                          You can upload a JPG, GIF or PNG file. Maximum file
                          size is 16 MB.
                        </div>
                      </Grid>
                      <Grid item>
                        <Grid
                          className={classes["Rectangle-971"]}
                          container
                          item
                          direction={"row"}
                          md={2.5}
                          xs={7}
                          marginLeft={{ xs: 8, md: 29 }}
                        >
                          <Grid item md={2} marginLeft={1} marginTop={0.6}>
                            <UploadIcon htmlColor="#529bd7" />
                          </Grid>
                          <Grid item md={9} xs={8} marginTop={1}>
                            <label
                              htmlFor="filePicker"
                              onMouseOver={(e) => {
                                e.target.style.cursor = "pointer";
                              }}
                            >
                              Upload Image
                            </label>

                            <input
                              id="filePicker"
                              style={{ visibility: "hidden" }}
                              type="file"
                              accept="image/*"
                              name="file"
                              onChange={onImageChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item marginLeft={{ xs: 13, md: 0 }}>
                        <div className={classes["ModalDescription"]}>
                          Current Photo:
                        </div>
                      </Grid>
                    </>
                  )}
                  <Grid item marginLeft={{ xs: 8, md: 0 }}>
                    <div
                      className={classes["Rectangle-966"]}
                      style={{
                        backgroundImage: `url(${profileSrc})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        marginBottom: 10,
                      }}
                    ></div>
                  </Grid>
                  {props.modalTitle === "Update Photo" && (
                    <Grid
                      item
                      className={classes["Rectangle-972"]}
                      marginLeft={2}
                      width={{ md: "41.7vw", xs: "80vw" }}
                      marginTop={2}
                    >
                      <Grid item md={11.7} container direction={"row-reverse"}>
                        <Grid item marginLeft={2}>
                          <button
                            className={classes.SaveModal}
                            onMouseOver={(e) => {
                              e.target.style.cursor = "pointer";
                            }}
                            onClick={() => saveProfile()}
                          >
                            Save
                          </button>
                        </Grid>
                        <Grid item>
                          <button
                            className={classes.exitModal}
                            onMouseOver={(e) => {
                              e.target.style.cursor = "pointer";
                            }}
                            onClick={() => {
                              handleClose();
                            }}
                          >
                            Cancel
                          </button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
              {props.modalTitle === "Delete Photo" && (
                <>
                  <Grid item>
                    <div className={classes["ModalDescription"]}>
                      Are you sure you want to delete the current photo?
                    </div>
                  </Grid>
                  <Grid
                    item
                    className={classes["Rectangle-972"]}
                    marginLeft={2}
                    marginTop={2}
                  >
                    <Grid
                      item
                      md={11.7}
                      container
                      direction={"row-reverse"}
                      width={{ md: "40.55vw", xs: "80vw" }}
                    >
                      <Grid item marginLeft={2}>
                        <button
                          className={classes.DeletePhoto}
                          onMouseOver={(e) => {
                            e.target.style.cursor = "pointer";
                          }}
                          onClick={() => deleteProfile()}
                        >
                          Delete Photo
                        </button>
                      </Grid>
                      <Grid item>
                        <button
                          className={classes.exitModal}
                          onMouseOver={(e) => {
                            e.target.style.cursor = "pointer";
                          }}
                          onClick={() => {
                            handleClose();
                          }}
                        >
                          Cancel
                        </button>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Box>
      </StyledModal>
    </div>
  );
}
