import React from "react";
import { Grid } from "@mui/material";
import classes from "../../../Assets/Styles/Activities.module.css";
import DownArrow from "../../../Assets/Images/Activity/arrow down.png";
import * as activityConst from "../../../../StatusConst";

import { Tooltip } from "@mui/material";
import { styled } from "@mui/system";
const ProgressBar = (props) => {
  return (
    <>
      <Grid
        container
        item
        xs={12}
        direction={"row"}
        gap={{ md: "14.2%", sm: "10.2%", xs: "7.6%" }}
        height={17}
        paddingLeft={{ lg: 9, md: 2, xs: 1.9 }}
      >
        {props.status === activityConst.DRAFT_ACTIVITY ? (
          <Grid item>
            <img src={DownArrow} alt={"arrow"} height={17} />
          </Grid>
        ) : (
          <Grid item width={23}></Grid>
        )}

        {props.status === activityConst.PLANNED ? (
          <Grid item>
            <img src={DownArrow} alt={"arrow"} height={17} />
          </Grid>
        ) : (
          <Grid item width={23}></Grid>
        )}
        {props.status === activityConst.IN_PROGRESS ? (
          <Grid item justifyContent={"center"}>
            <img src={DownArrow} alt={"arrow"} height={17} />
          </Grid>
        ) : (
          <Grid item width={23}></Grid>
        )}
        {props.status === activityConst.SUBMITTED_INTERNAL_APPROVAL_1 ? (
          <Grid item>
            <img src={DownArrow} alt={"arrow"} height={17} />
          </Grid>
        ) : (
          <Grid item width={23}></Grid>
        )}
        {props.status === activityConst.SUBMITTED_INTERNAL_APPROVAL_2 ? (
          <Grid item>
            <img src={DownArrow} alt={"arrow"} height={17} />
          </Grid>
        ) : (
          <Grid item width={23}></Grid>
        )}
        {props.status === activityConst.SUBMITTED_FOR_CONSULTANT ? (
          <Grid item>
            <img src={DownArrow} alt={"arrow"} height={17} />
          </Grid>
        ) : (
          <Grid item width={23}></Grid>
        )}
      </Grid>
      <Grid
        item
        height={22}
        container
        xs={12}
        direction={"row"}
        gap={{ md: "14.1%", sm: "10%", xs: "7.4%" }}
        className={classes["Rectangle-1607"]}
        paddingLeft={{ lg: 9, md: 2, xs: 2 }}
      >
        <BlueTip
          title={"Draft"}
          placement={
            props.status === activityConst.DRAFT_ACTIVITY ? "bottom" : "top"
          }
          popper={"true"}
          interactive={"true"}
        >
          <Grid
            item
            className={classes["Ellipse-grey"]}
            bgcolor={"#d9d2d2"}
          ></Grid>
        </BlueTip>
        <BlueTip
          title={"Planned"}
          popper={"true"}
          placement={props.status === activityConst.PLANNED ? "bottom" : "top"}
          interactive={"true"}
        >
          <Grid
            item
            className={classes["Ellipse-grey"]}
            bgcolor={"rgba(95, 186, 71, 0.7)"}
          ></Grid>
        </BlueTip>
        <BlueTip
          title={"In Progress"}
          popper={"true"}
          placement={
            props.status === activityConst.IN_PROGRESS ? "bottom" : "top"
          }
          interactive={"true"}
        >
          <Grid
            item
            className={classes["Ellipse-grey"]}
            bgcolor={"rgba(45, 164, 208, 0.5)"}
          ></Grid>
        </BlueTip>
        <BlueTip
          title={"Submitted for  1- Internal Approval"}
          popper={"true"}
          placement={
            props.status === activityConst.SUBMITTED_INTERNAL_APPROVAL_1
              ? "bottom"
              : "top"
          }
          interactive={"true"}
        >
          <Grid
            item
            className={classes["Ellipse-grey"]}
            bgcolor={"rgba(225, 227, 25, 0.7)"}
          ></Grid>
        </BlueTip>
        <BlueTip
          title={"Submitted for  2- Internal Approval"}
          popper={"true"}
          placement={
            props.status === activityConst.SUBMITTED_INTERNAL_APPROVAL_2
              ? "bottom"
              : "top"
          }
          interactive={"true"}
        >
          <Grid
            item
            className={classes["Ellipse-grey"]}
            bgcolor={"rgba(16, 22, 80, 0.3)"}
          ></Grid>
        </BlueTip>
        <BlueTip
          title={"Submitted to Consultant"}
          popper={"true"}
          placement={
            props.status === activityConst.SUBMITTED_FOR_CONSULTANT
              ? "bottom"
              : "top"
          }
          interactive={"true"}
        >
          <Grid
            item
            className={classes["Ellipse-grey"]}
            bgcolor={"rgba(16, 22, 80, 0.7)"}
          ></Grid>
        </BlueTip>
      </Grid>
    </>
  );
};

export default ProgressBar;
const BlueTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
        height:1px;
        background-color: #fff;
        font-family: Muli;
  font-size: 10px;
  font-style: italic;
  text-align: center;
  color: #101650;
    `);
