import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import "../Assets/Styles/home.css";
import pileYellow from "../../SAAS/Assets/Images/Notifications/pileYellow.png";
import pileBlue from "../../SAAS/Assets/Images/Notifications/pileBlue.png";
import pileGreen from "../../SAAS/Assets/Images/Notifications/pileGreen.png";
import contyellow from "../../SAAS/Assets/Images/Notifications/contyellow.png";
import contblue from "../../SAAS/Assets/Images/Notifications/contblue.png";
import contgreen from "../../SAAS/Assets/Images/Notifications/contgreen.png";
import DeleteNoti from "../../SAAS/Assets/Images/Notifications/deleteNoti.png";
import UnseenNoti from "../../SAAS/Assets/Images/Notifications/useenNoti.png";

import * as notificationsServices from "../../Services/notification-service.proxy";
import { useNavigate } from "react-router-dom";
import { userDataActions } from "../../store/index";
import { useDispatch } from "react-redux";
//import * as userDataServices from "../../Services/user-data-service.proxy";
//const getUserData = userDataServices.getUserData;
const getNotifications = notificationsServices.getNotifications;
const viewNotifications = notificationsServices.viewNotifications;
const deleteNoti = notificationsServices.deleteNoti;

const styles = {
  yourNotifications: {
    fontFamily: "Muli",
    fontSize: 18,
    fontWeight: "bold",
    lineHeight: 1.44,
    textAlign: "left",
    color: "#0674b9",
  },
  title: {
    fontFamily: "Muli",
    fontSize: 14,
    lineheight: 1.36,
    fontWeight: "bold",
    textAlign: "left",
    color: "#707070",
  },
  msg: {
    fontFamily: "Muli",
    fontSize: 14,
    lineheight: 1.36,
    overflow: "visible",
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word",
    textAlign: "left",
    color: "#707070",
  },
  date: {
    fontSize: 11,
    fontFamily: "Muli",
    fontStyle: "italic",
    lineHeight: 1.36,
    textAlign: "left",
    color: "#0674b9",
  },
};

export default function Notifications({ setRegister, setNaved }) {
  const navigate = useNavigate();

  useEffect(() => {
    let apiTest = true;
    if (localStorage.getItem("accessToken") && apiTest) {
      if (localStorage.getItem("isVerified") === "false") {
        if (localStorage.getItem("isTeamMember") === "true")
          navigate("/teamMemberRegistration");
        else {
          if (localStorage.getItem("AccountType") === "1") navigate("/main");
          if (localStorage.getItem("AccountType") === "2") navigate("/sub");
        }
      }
    } else navigate("/login");
    return () => {
      apiTest = false;
    };
  }, [navigate]);

  useEffect(() => {
    setRegister(true);
  }, [setRegister]);

  useEffect(() => {
    setNaved(false);
  }, [setNaved]);
  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);
  const dispatch = useDispatch();

  const [notifications, setNotifications] = useState([]);
  const [modify, setModify] = useState(false);

  useEffect(() => {
    getNotifications().then((x) => {
      setNotifications(x.Notifications);
    });
  }, [modify]);
  const viewingNotification = (Id, IsViewed) => {
    viewNotifications(Id).then((x) => {
      setModify(!modify);
      if (!IsViewed) dispatch(userDataActions.DecrementNotificationCounter());
    });
  };
  const deleteNotification = (Id, IsViewed) => {
    viewNotifications(Id).then((x) => {
      setModify(!modify);
      if (!IsViewed) dispatch(userDataActions.DecrementNotificationCounter());
      deleteNoti(Id).then(() => {
        setModify(!modify);
      });
    });
  };
  // const updateNotification = () => {
  //   getUserData().then((x) => {
  //     dispatch(userDataActions.setUserData(x.Notifications));
  //     dispatch(
  //       userDataActions.setNotificationDisplayCounter(
  //         x.NotificationDisplayCounter
  //       )
  //     );
  //   });
  // };

  return (
    <div className="Rectangle779">
      <Grid container padding="3% 0 0 10%" spacing={1}>
        <Grid item xs={12} sx={styles.yourNotifications}>
          Your Notifications
        </Grid>
        <Grid item xs={10} container alignItems="center">
          {notifications.map((n, index) => (
            <Grid
              item
              xs={12}
              key={index}
              gap={2}
              container
              bgcolor={n.IsViewed ? "#e9e7e7" : "#fff"}
              padding={1.5}
              alignItems="center"
            >
              {n.IsViewed === false ? (
                <Grid item md={0.2} sm={0.5} xs={0.8}>
                  <img src={UnseenNoti} alt={"unseen"} />
                </Grid>
              ) : (
                <Grid item md={0.2} sm={0.5} xs={0.8}></Grid>
              )}
              <Grid item md={0.8} sm={1.5} xs={1.5}>
                <img
                  src={
                    n.Link === null
                      ? index % 3 === 0
                        ? pileYellow
                        : (index + 2) % 3 === 0
                        ? pileBlue
                        : pileGreen
                      : index % 3 === 0
                      ? contyellow
                      : (index + 2) % 3 === 0
                      ? contblue
                      : contgreen
                  }
                  width={"40px"}
                  alt={"Noti"}
                ></img>
              </Grid>
              <Grid
                item
                sm={7}
                xs={5.5}
                md={9}
                container
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={(e) => {
                  if (n.Link) {
                    window.location.replace(n.Link);
                  }
                  if (n.IsViewed === false) {
                    viewingNotification(n.Id, n.IsViewed);
                  }
                }}
              >
                <Grid item xs={12} sx={styles.title}>
                  {n.Name}
                </Grid>
                <Grid item xs={12} sx={styles.msg}>
                  {n.Description}
                </Grid>
                <Grid item xs={12} sx={styles.date}>
                  {new Date(n.CreationDate).toString().slice(0, 21)}
                </Grid>
              </Grid>

              <Grid
                item
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={() => {
                  deleteNotification(n.Id, n.IsViewed);
                }}
                md={1}
                sm={1.5}
                xs={2}
              >
                <img src={DeleteNoti} alt={"delete"} />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
}