import React, { useEffect } from "react";
import whyPC from ".././Assets/Images/home/whypc.png";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import "../Assets/Styles/whyPileCubes.css";
function WhyPileCubes({ setRegister, setNaved, setTerms }) {

  useEffect(() => {
    setRegister(false)
  }, [setRegister])

  useEffect(() => {
    setNaved(false)
    setTerms(false)
  }, [setNaved])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <Box sx={{ flexGrow: 1 }} className="whyyy">
      <Grid container>
        <Grid item md={1.2} xs={1.5} sm={1} ></Grid>
        <Grid item md={1.4} className="sideBg">
          <div
            style={{
              backgroundImage: `url(${whyPC})`,
              backgroundSize: "cover",
              width: 168,
              height: window.innerHeight,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span className="infos" style={{ marginTop: "35%" }}>
              Questions ?
            </span>
            <a href={process.env.REACT_APP_INFO} style={{ textDecoration: 'none' }}><span className="infos" style={{ marginTop: "5%" }}>
              info@pilecubes.com
            </span></a>
          </div>
        </Grid>
        <Grid item md={7} xs={9.5} className="desc">
          <Grid item md={11}>
            <div className="descriptionTitle">
              At <span style={{ backgroundColor: '#e1e319' }}>Pilecubes</span>, we understand the challenges of managing construction projects.
            </div>
          </Grid>
          <Grid item md={11} style={{ marginTop: "3%" }}>
            <div className="description2">
              That&apos;s why we&apos;ve created a platform that streamlines the building process and eliminates the need for multiple systems and software, resulting in increased efficiency and cost savings.
            </div>
            <br />
            <div className="descriptionBg">
              <span className="description3">
                Pilecubes’ platform brings together main contractors, trade partners, and subcontractors, fostering seamless collaboration and communication, resulting in increased efficiency and reduced errors or delays.<br /><br /> We are committed to digitizing the entire supply chain process, from engineering to manufacturing to site operations, using cutting-edge technologies.
              </span>
            </div>
            <br />
            <div className="description2">
              Our engineering module allows engineering teams to work together, both on and off-site, internal or outsourced, to manage documents, drawings, revisions, and real-time submittals, eliminating the need for manual coordination and reducing the risk of errors.<br/><br/>

              Our manufacturing module seamlessly integrates offsite factories directly into the project site, providing unparalleled real-time order tracking and updates. This leads to improved control over the project schedule and budget, resulting in superior project outcomes.<br/><br/> Our cutting-edge supply chain management tools empower contractors to efficiently manage the sourcing of materials and track deliveries, ensuring on-time delivery and reducing the risk of delays and project overruns.<br /><br />

              Pilecubes is leading the charge in revolutionizing the construction industry. By digitizing the supply chain process, our platform increases efficiency, reduces costs, and improves communication and collaboration among all stakeholders.<br/><br/>

              With Pilecubes, projects are completed faster, more efficiently, and with a higher level of quality, resulting in a superior experience for everyone involved. Join us as we pave the way for the future of construction management.
            </div>
            <br /><br />
            <div style={{
              width: 200,
              height: 5,
              backgroundColor: '#eaec61'
            }}>
            </div>
          </Grid>
        </Grid>
        <Grid item md={2.4} xs={2}></Grid>
      </Grid>
    </Box>
  );
}

export default WhyPileCubes;
