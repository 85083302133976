import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import ScTable from "./ScTable";
import ScModal from "./ScModal";
import SaasBack from "../../../Assets/Images/ProfileDetails/SaaSbackground.png";
import ScAssignmentLog from "./ScAssignmentLog";
const ScTablView = (props) => {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  const [openModal, setOpenModal] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [modify, setModify] = useState(false);
  const [viewAssignmentLog, setViewAssignmentLog] = useState(false);
  const [contractorId, setContractorId] = useState(null);
  const [projectId, setProjectId] = useState(null);

  return (
    <div
      style={{
        minHeight: "87vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <Grid paddingLeft={5} paddingTop={5}>
        {viewAssignmentLog ? (
          <ScAssignmentLog
            projectId={projectId}
            paging={false}
            tableHeight={480}
            showViewAll={false}
            ShowGoBack={true}
            setOpenModal={setOpenModal}
            rowData={rowData}
            viewSearch={true}
            viewFilters={true}
            modify={modify}
            setModify={setModify}
            setViewAssignmentLog={setViewAssignmentLog}
          />
        ) : (
          <ScTable
            setContractorId={setContractorId}
            setProjectId={setProjectId}
            paging={false}
            tableHeight={480}
            showViewAll={false}
            ShowGoBack={true}
            setOpenModal={setOpenModal}
            setRowData={setRowData}
            viewSearch={true}
            viewFilters={true}
            modify={modify}
            setModify={setModify}
            setViewAssignmentLog={setViewAssignmentLog}
          />
        )}
      </Grid>
      <ScModal
        setContractorId={setContractorId}
        contractorId={contractorId}
        openModal={openModal}
        setOpenModal={setOpenModal}
        rowData={rowData}
        setRowData={setRowData}
        setModify={setModify}
        modify={modify}
      />
    </div>
  );
};

export default ScTablView;
