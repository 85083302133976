import React, { useState, useEffect } from "react";
import { Grid, Modal } from "@mui/material";
import classes from "../../../Assets/Styles/Activities.module.css";
import CautionIcon from "../../../Assets/Images/MileStones/caution.png";
const style = {
  position: "absolute",
  top: "50%",
  left: "55%",
  bottom: "30%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  outline: "none",
  height: "35%",
  borderRadius: 5,
  border: 0,
};

const ErrorModal = (props) => {
  const [open, setOpen] = useState(false);
  //const [, updateState] = React.useState();
  const handleOpen = () => {
    setOpen(true);
    props.setOpenModal(false);
  };
  useEffect(() => {
    if (props.openModal) {
      handleOpen(true);
    }
  }, [props.openModal]);
  const handleClose = () => {
    setOpen(false);
    props.setChosenId(null);
    props.setopenedCard([]);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableScrollLock
        keepMounted
      >
        <Grid
          container
          sx={style}
          item
          xs={8}
          md={4}
          paddingTop={4}
          paddingLeft={5}
          spacing={0}
        >
          <Grid item container xs={12} direction={"row"} gap={2}>
            <Grid item md={0.5} xs={1}>
              <img src={CautionIcon} alt="Caution" />
            </Grid>
            <Grid
              item
              xs={10}
              md={10}
              fontSize={{ xs: 15, md: 23 }}
              className={classes["Delete-this-comment"]}
            >
              Action cannot be permitted?
            </Grid>
          </Grid>
          <Grid item className={classes["deleteHint"]} xs={12}>
            {props.modalErrorMessage}
          </Grid>
          <Grid
            item
            container
            direction={"row-reverse"}
            gap={2}
            paddingTop={1}
            paddingRight={5.5}
          >
            <Grid
              item
              className={classes["CancelModal"]}
              paddingTop={1}
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

export default ErrorModal;
