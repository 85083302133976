import React, { useState, useEffect } from "react";
import SaasBack from "../../../Assets/Images/SaaSbackground.png";
import { Grid } from "@mui/material";
import classes from "../../../Assets/Styles/factoryTables.module.css";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { TextField } from "@mui/material";
import { styled } from "@mui/system";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import CloseIcon from '@mui/icons-material/Close';
import * as factoryServices from "../../../Services/factories-services.proxy";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuPic from "../../../Assets/Images/Table/menuPic.png";
import { Stack } from "@mui/material";

const getFactories = factoryServices.getFactoriesList;


function FactoryTableView(props) {
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  const navigate = useNavigate();
  const [searchText, setSearchText] = useState(null);
  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };


  const [typeId, setTypeId] = useState(null);
  const [types, setTypes] = useState([]);

  const [factoriesTableData, setFactoriesTableData] = useState([]);
  const [dataLength, setdataLength] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);


  const [anchorEl, setAnchorEl] = useState(null);
  const [rowData, setRowData] = useState(null);

  const [loadingData, setLoadingData] = useState(false);

  const handleClick = async (event, params) => {
    setRowData(params);

    setAnchorEl(event.target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const columns = [
    { field: "Id", hide: true, hideable: false },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      position: "sticky",
      renderCell: (params) => {
        return (
          <img
            src={MenuPic}
            alt="menu"
            onClick={(e) => handleClick(e, params)}
          ></img>
        );
      },
    },
    {
      field: "Code",
      headerName: "Factory Code",
      width: 200,
    },
    {
      field: "Name",
      headerName: "Factory Name",
      width: 200,
    },
    {
      field: "BaysNumber",
      headerName: "No. of Bays",
      width: 120,
    },
    {
      field: "Type",
      headerName: "Factory Type",
      width: 150,
    },
    {
      field: "MonthlyVolume",
      headerName: "Volume Monthly",
      width: 150,
    },
    {
      field: "DailyCapacity",
      headerName: "Default Daily Capacity",
      width: 170,
    },
    {
      field: "MinimumDeliveryDaysPerSalesorder",
      headerName: "Min Delivery Date",
      width: 150,
    },
    {
      field: "DeliveryRadiusCoverage",
      headerName: "Delivery Radius Coverage",
      width: 190,
    },
    {
      field: "Trade",
      headerName: "Factory Trade",
      width: 120,
    },
    {
      field: "EmployeesNumber",
      headerName: "No. of Employees",
      width: 150,
    },
    {
      field: "Country",
      headerName: "Factory Country",
      width: 150,
    },
    {
      field: "Address",
      headerName: "Factory Address",
      width: 250,
    },
    {
      field: "PhoneNumber",
      headerName: "Contact Phone Number",
      width: 180,
    },
    {
      field: "Website",
      headerName: "Website",
      width: 180,
    },
    {
      field: "Email",
      headerName: "Email",
      width: 250,
    },


  ];
  const listData = {
    Pagination: {
      PageSize: pageSize,
      PageNumber: pageNumber,
    },
    Search: searchText,
    SortList: [],
    TypeId: typeId,
  };

  useEffect(() => {
    let apiTest = true;
    setLoadingData(true);
    getFactories(listData).then((x) => {
      if (apiTest) {
        setFactoriesTableData(x.Factories);
        setTypes(x.Types)
        setdataLength(x.Total);
        setLoadingData(false);
      }
    });
    return () => {
      apiTest = false;
    };
  }, [pageSize, pageNumber, searchText, typeId]);

  const blue = {
    100: "#DAECFF",
    200: "#99CCF3",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
  };

  const grey = {
    100: "#E7EBF0",
    200: "#E0E3E7",
    300: "#CDD2D7",
    400: "#B2BAC2",
    500: "#A0AAB4",
    600: "#6F7E8C",
    700: "#3E5060",
    800: "#2D3843",
    900: "#1A2027",
  };

  const StyledButton = styled("button")(
    ({ theme }) => `
    font-family: Muli;
    font-size: 10px;
    box-sizing: border-box;
    height: 35px;
    width: 9rem;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
    border-radius: 0.75em;
    margin-top: 0.7em;
    padding: 10px;
    text-align: left;
    line-height: 1.5;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  
    &:hover {
      background: ${theme.palette.mode === "dark" ? "" : grey[100]};
      border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }
  
    &.${selectUnstyledClasses.focusVisible} {
      outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]};
    }
  
    &.${selectUnstyledClasses.expanded} {
      &::after {
        content: '▴';
      }
    }
  
    &::after {
      content: '▾';
      float: right;
    }
    cursor: pointer;
    `
  );

  const StyledListbox = styled("ul")(
    ({ theme }) => `
    font-family: "Muli";
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 5px;
    width: 9rem;
    height:120px;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
    border-radius: 0.75em;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    overflow: scroll;
    outline: 0px;
    
    `
  );

  const StyledOption = styled(OptionUnstyled)(
    ({ theme }) => `
    list-style: none;
    padding: 8px;
    border-radius: 0.45em;
    cursor: pointer;
  
    &:last-of-type {
      border-bottom: none;
    }
  
    &.${optionUnstyledClasses.selected} {
      background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
      color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
    }
  
    &.${optionUnstyledClasses.highlighted} {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
  
    &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
      background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
      color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
    }
  
    &.${optionUnstyledClasses.disabled} {
      color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
    }
  
    &:hover:not(.${optionUnstyledClasses.disabled}) {
      background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    }
    `
  );

  const StyledPopper = styled(PopperUnstyled)`
    z-index: 1;
  `;

  const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
    const components = {
      Root: StyledButton,
      Listbox: StyledListbox,
      Popper: StyledPopper,
      ...props.components,
    };

    return <SelectUnstyled {...props} ref={ref} components={components} />;
  });
  const [isDisabled, setIsDisabled] = useState(false);

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
        objectFit: "cover",
      }}
    >
      {isDisabled ? (
        <Grid
          item
          container
          bgcolor={"rgba(225, 227, 25, 0.5)"}
          md={12}
          paddingTop={2}
          paddingBottom={1}
        >
          <Grid item md={4}></Grid>
          <Grid
            item
            md={6}
            style={{
              fontFamily: "Muli",
              fontSize: "17px",
              fontWeight: "bold",
              textAlign: "left",
              color: "#101650",
            }}
          >
            <Grid>
              Please subscribe to Manufacturing plan to create more
            </Grid>
          </Grid>
          <Grid item md={2} container>
            <Grid
              item
              onClick={() => {
                props.setSetupProfile(true);
                props.setSetupMenu(true);
                navigate("/saas/profileSettings");
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
            >
              <button
                style={{
                  padding: "5px",
                  borderRadius: "5px",
                  backgroundColor: "#101650",
                  fontFamily: "Muli",
                  fontSize: "13px",
                  textAlign: "center",
                  border: 0,
                  color: "#fff",
                }}
              >
                Subscribe
              </button>
            </Grid>
            <Grid
              item
              paddingLeft={1}
              onClick={() => {
                setIsDisabled(false);
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
            >
              <CloseIcon fontSize="medium" />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <Grid container padding={{ xs: 1.5, sm: 3.5 }} rowSpacing={0.5}>
        <Grid item container direction="row">
          <Grid item xs={8.3} sm={9.8} md={10.9} className={classes["title"]}>
            All Factories
          </Grid>
          <Grid item xs={2} sm={1.2} md={0.6}>
            <button
              className={classes["back-button"]}
              onClick={() => {
                navigate(-1);
              }}
            >
              Go Back
            </button>
          </Grid>
          <Grid item xs={1.5} sm={1} md={0.5}>
            <button
              className={classes["add-button"]}
              onClick={() => {
                if (localStorage.getItem("IsFreePlanActivated") === "true" && dataLength === 1)
                  setIsDisabled(true);
                else
                  navigate("/saas/factory/create");
              }}
            >
              Add
            </button>
          </Grid>
        </Grid>
        <Grid item container direction="row-reverse">
          <Grid item>

            <TextField
              variant="standard"
              fullWidth
              onChange={inputHandler}
              placeholder="Search this list…"
              InputProps={{
                startAdornment: (
                  <SearchIcon
                    fontSize="small"
                    htmlColor={"#707070"}
                    style={{ paddingLeft: 5 }}
                  />
                ),
              }}
              sx={{
                fontFamily: "Muli",
                backgroundColor: "white",
                borderRadius: 1,
                width: {
                  xs: 1,
                  sm: "auto",
                },
                m: (theme) => theme.spacing(1, 0.5, 1.5),
                "& .MuiSvgIcon-root": {
                  mr: 0.5,
                },
                "& .MuiInput-underline:before": {
                  borderBottom: 0,
                  borderColor: "white",
                },
              }}
            />
          </Grid>
          <Grid item>
            <CustomSelect
              value={typeId}
              onChange={(option) => {
                setTypeId(option);
              }}
            >
              <StyledOption value={null} disabled>
                Type
              </StyledOption>
              {types.map((f) => (
                <StyledOption key={f.Id} value={f.Id}>
                  {f.Name}
                </StyledOption>
              ))}
            </CustomSelect>
          </Grid>
          {typeId ? (
            <Grid item marginTop={0.6}>
              <button
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={() => {
                  setTypeId(null);
                }}
                className={classes["Rectangle-1224"]}
              >
                Clear filters
              </button>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        <Grid item container xs={12}>
          <Grid
            container
            style={{
              height: "100vh",
              fontFamily: "Muli",
              backgroundColor: "white",
            }}
          >
            <DataGrid
              initialState={{
                pagination: {
                  pageSize: 25,
                },
              }}
              pagination
              paginationMode="server"
              rows={factoriesTableData ? factoriesTableData : []}
              columns={columns}
              headerHeight={40}
              density={"compact"}
              showColumnRightBorder
              sx={{
                [`& .${gridClasses.row}`]: {
                  color: "#0674b9",
                  overflow: "auto",
                  bgcolor: (theme) =>
                    theme.palette.mode === "light" ? grey[0] : grey[500],
                },

                [`& .${gridClasses.columnHeader}`]: {
                  bgcolor: "rgb(172 201 224)",
                  color: "rgb(17 24 84)",
                },
                [`& .${gridClasses.columnHeaderTitle}`]: {
                  fontFamily: "Muli",
                  fontWeight: "bold",
                },

                [`& .${gridClasses.footerContainer}`]: {
                  bgcolor: "rgb(216 235 247)",
                  minHeight: "30px",
                },
                fontFamily: "Muli",
                fontSize: "13px",
              }}
              disableColumnFilter
              getRowId={(row) => row.Id}
              rowCount={dataLength}
              hideFooter={props.paging}
              onPageChange={(newPage) => {
                if (pageSize > dataLength) {
                  setPageNumber(1);
                } else setPageNumber(newPage + 1);
              }}
              onPageSizeChange={(newPage) => {
                setPageSize(newPage);
              }}
              pageSize={pageSize}
              loading={loadingData}
              components={{
                NoRowsOverlay: () => (
                  <Stack className={classes["EmptyMessage"]}>
                    No rows to display
                  </Stack>
                ),
              }}
            />

            <Menu
              id="more-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}

              disableScrollLock
            >
              <MenuItem
                onClick={(event) => {
                  setRowData(rowData);
                  navigate(`/saas/factory/create?id=${rowData?.row.Id}`)
                  handleClose();
                }}
                style={{
                  fontFamily: "Muli",
                  fontSize: "13px",
                  color: "#444",
                  width: 100,
                  display: "block",
                }}
              >
                Edit
              </MenuItem>
           {localStorage.getItem("isSubscribedManu") ?

              <MenuItem
                onClick={(event) => {
                  setRowData(rowData);
                  // setOpenModal(true);
                  localStorage.setItem("FactoryName", rowData?.row.Name)
                  navigate(`/saas/baysList?id=${rowData?.row.Id}`);
                  handleClose();
                }}
                style={{
                  fontFamily: "Muli",
                  fontSize: "13px",
                  color: "#444",
                  width: 100,
                  display: "block",
                }}
              >
                View Bays
              </MenuItem>
              :
              ""
              }
            </Menu>
          </Grid>
        </Grid>

      </Grid>
    </div>
  );
}

export default FactoryTableView;
