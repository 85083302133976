import { Grid } from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { useNavigate, useLocation } from "react-router-dom";
import SaasBack from "../../../Assets/Images/ProfileDetails/SaaSbackground.png";
import RMNA from "../../../Assets/Images/ProductionLabel/rmmarked.png";
import RMA from "../../../Assets/Images/ProductionLabel/rmnotmarked.png";
import REMNA from "../../../Assets/Images/ProductionLabel/remmarked.png";
import REMA from "../../../Assets/Images/ProductionLabel/remnotmarked.png";
import FGNA from "../../../Assets/Images/ProductionLabel/fgmarked.png";
import FGA from "../../../Assets/Images/ProductionLabel/fgnotmarked.png";
import DNIcon from "../../../Assets/Images/ProductionLabel/DeliveryNoteIcon.png";
import DNIconA from "../../../Assets/Images/ProductionLabel/DeliveryNoteMarked.png";

import classes from "../../../Assets/Styles/ProductionLabel.module.css";
import { TextField, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Stack } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuPic from "../../../Assets/Images/Table/menuPic.png";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { styled } from "@mui/system";
import * as ProductionLabelServices from "../../../Services/production-label-services.proxy";
import { useSelector } from "react-redux";
import * as permissionFunctions from "../../../../ReusableFunctions";
const CheckIfPermitted = permissionFunctions.CheckIfPermitted;
const getProductionLabelList = ProductionLabelServices.getProductionLabelList;

export default function ProductionLabelTable(props) {
  useEffect(() => {
    props.setNaved(false);
  }, [props]);
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const [ProductionLabel, setProductionLabel] = useState([]);
  const [dataLength, setdataLength] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const [pageSize, setPageSize] = useState(25);
  const [modify, setModify] = useState(false);
  const [disabelEdit, setDisabelEdit] = useState(false);
  const navigate = useNavigate();
  //FILTERS
  const [size, setSize] = useState(0);
  const [labelType, setLabelType] = useState(1);

  const [sizes, setSizes] = useState([]);

  const [loadingData, setLoadingData] = useState(false);
  const listData = {
    Pagination: {
      PageSize: pageSize,
      PageNumber: pageNumber,
    },
    Search: searchText,
    SortList: [],
    LabelTagId: size === 0 ? null : size,
    LabelTypeId: labelType,
  };
  useEffect(() => {
    if (query?.get("typeId")) {
      setLabelType(query?.get("typeId"));
    }
  }, []);
  
  useEffect(() => {
    let apiTest = true;
    setLoadingData(true);
    getProductionLabelList(listData).then((x) => {
      if (apiTest) {
        setProductionLabel(x.Labels);

        setdataLength(x.Total);
        setSizes(x.LabelTags);
        setLoadingData(false);
      }
    });
    return () => {
      apiTest = false;
    };
  }, [searchText, pageNumber, pageSize, modify, size, labelType]);

  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowData, setRowData] = useState();

  const handleClick = async (event, params) => {
    setRowData(params);

    setAnchorEl(event.target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  useEffect(() => {
    if (UserPermissions) {
      CheckIfPermitted(
        UserPermissions,
        "Label Management",
        "Edit",
        setDisabelEdit
      );
    }
  }, [UserPermissions]);
  const columns = [
    {
      field: "action",
      headerName: "Actions",
      sortable: false,

      renderCell: (params) => {
        return (
          <img
            src={MenuPic}
            alt="menu"
            onClick={(e) => handleClick(e, params)}
          ></img>
        );
      },
    },
    {
      field: "Name",
      headerName: "Label Name",
      width: 300,
    },
    {
      field: "NumOfTags",
      headerName: "Label Size",
      width: 300,
      renderCell: (params) => {
        return (
          <Grid>
            {params.row.NumOfTags === null
              ? labelType === 4
                ? "A4"
                : "Custom Printing"
              : params.row.NumOfTags}
          </Grid>
        );
      },
    },
  ];
  return (
    <div
      style={{
        minHeight: "90vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <Grid container paddingTop={5} paddingLeft={2.5}>
        <Grid
          item
          container
          direction={"row"}
          paddingBottom={2}
          paddingLeft={4}
        >
          <Grid item xs={12} sm={3} md={2} direction={"row"}>
            <Grid item xs={12} className={classes["Production-Label"]}>
              Production Labels
            </Grid>
            <Grid item className={classes["Type"]}>
              {labelType === 1
                ? "Raw Materials"
                : labelType === 2
                ? "Remenant"
                : labelType === 3
                ? "Finished Goods"
                : labelType === 4
                ? "Delivery Note"
                : ""}
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={5}
            sm={5.5}
            direction={"row"}
            gap={{ sm: 0.55, xs: 0 }}
          >
            <Grid
              item
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onClick={() => {
                setLabelType(1);
              }}
            >
              <img src={labelType === 1 ? RMA : RMNA} alt={"img"} />
            </Grid>
            <Grid
              item
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onClick={() => {
                setLabelType(2);
              }}
            >
              <img src={labelType === 2 ? REMA : REMNA} alt={"img"} />
            </Grid>
            <Grid
              item
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onClick={() => {
                setLabelType(3);
              }}
            >
              <img src={labelType === 3 ? FGA : FGNA} alt={"img"} />
            </Grid>
            <Grid
              item
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onClick={() => {
                setLabelType(4);
              }}
            >
              <img
                src={labelType === 4 ? DNIconA : DNIcon}
                width={120}
                alt={"img"}
              />
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md={5}
            sm={3}
            container
            direction={"row"}
            paddingRight={{ md: 5 }}
          >
            <Grid
              item
              container
              xs={12}
              direction={"row-reverse"}
              paddingRight={{ sm: 7, md: 0 }}
            >
              <Grid item md={2} xs={2.5} paddingLeft={4}>
                <button
                  className={classes["cancelbut"]}
                  onClick={() => {
                    navigate("/saas/admin/productionLabel");
                  }}
                >
                  Add
                </button>
              </Grid>
              <Grid item md={1.5} xs={7} paddingLeft={1}>
                <button
                  className={classes["Go-Back"]}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Go back
                </button>
              </Grid>
            </Grid>
            <Grid item md={6} container direction={"row-reverse"}>
              <Grid
                item
                md={12}
                lg={12}
                marginTop={1}
                container
                direction={"row-reverse"}
              >
                <Grid item>
                  <CustomSelect
                    value={size}
                    onChange={(option) => {
                      setSize(option);
                    }}
                  >
                    <StyledOption value={0} disabled>
                      Size
                    </StyledOption>
                    {sizes.map((s) => (
                      <StyledOption key={s.Id} value={s.Id}>
                        {s.Name}
                      </StyledOption>
                    ))}
                  </CustomSelect>
                </Grid>

                {size ? (
                  <Grid item marginTop={0.6}>
                    <span
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                      onClick={() => {
                        setSize(0);
                      }}
                      style={{
                        fontFamily: "Muli",
                        fontSize: 10,
                        color: "#707070",
                        paddingTop: 25,
                        paddingRight: 15,
                      }}
                    >
                      Clear filter
                    </span>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <Grid item md={6} container direction={"row-reverse"}>
              <Box
                sx={{
                  p: 0.5,
                  pb: 0,
                }}
              >
                <TextField
                  variant="standard"
                  fullWidth
                  onChange={inputHandler}
                  placeholder="Search this list…"
                  InputProps={{
                    startAdornment: (
                      <SearchIcon
                        fontSize="small"
                        htmlColor={"#707070"}
                        style={{ paddingLeft: 5 }}
                      />
                    ),
                  }}
                  sx={{
                    fontFamily: "Muli",
                    backgroundColor: "white",
                    borderRadius: 1,
                    width: {
                      xs: 1,
                      sm: "auto",
                    },
                    m: (theme) => theme.spacing(1, 0.5, 1.5),
                    "& .MuiSvgIcon-root": {
                      mr: 0.5,
                    },
                    "& .MuiInput-underline:before": {
                      borderBottom: 0,
                      borderColor: "white",
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <div
          style={{
            height: 500,
            width: "96.5%",
            fontFamily: "Muli",
            backgroundColor: "white",
            marginTop: 10,
            marginLeft: "2%",
          }}
        >
          <DataGrid
            initialState={{
              pagination: {
                pageSize: 25,
              },
            }}
            pagination
            paginationMode="server"
            rows={ProductionLabel ? ProductionLabel : []}
            columns={columns}
            headerHeight={40}
            density={"compact"}
            showColumnRightBorder
            sx={{
              [`& .${gridClasses.row}`]: {
                color: "#0674b9",
                overflow: "auto",
                bgcolor: (theme) =>
                  theme.palette.mode === "light" ? grey[0] : grey[500],
              },

              [`& .${gridClasses.columnHeader}`]: {
                bgcolor: "rgb(211 225 238)",
                color: "rgb(17 24 84)",
              },
              [`& .${gridClasses.columnHeaderTitle}`]: {
                fontFamily: "Muli",
                fontWeight: "bold",
              },

              [`& .${gridClasses.footerContainer}`]: {
                bgcolor: "rgb(216 235 247)",
                minHeight: "30px",
              },
              fontFamily: "Muli",
              fontSize: "13px",
            }}
            disableColumnFilter
            getRowId={(row) => row.Id}
            rowCount={dataLength}
            hideFooter={props.paging}
            onPageChange={(newPage) => {
              if (pageSize > dataLength) {
                setPageNumber(1);
              } else setPageNumber(newPage + 1);
            }}
            onPageSizeChange={(newPage) => {
              setPageSize(newPage);
            }}
            pageSize={pageSize}
            loading={loadingData}
            components={{
              NoRowsOverlay: () => (
                <Stack className={classes["EmptyMessage"]}>
                  No Labels to display
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack className={classes["EmptyMessage"]}>
                  No Labels to display
                </Stack>
              ),
            }}
          />
        </div>

        <Menu
          id="more-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <MenuItem
            disabled={disabelEdit}
            onClick={() => {
              navigate(
                `/saas/admin/productionLabel/create?id=${rowData.row.Id}`
              );
            }}
            style={{
              fontFamily: "Muli",
              fontSize: "13px",
              color: "#444",
            }}
          >
            Edit
          </MenuItem>
        </Menu>
      </Grid>
    </div>
  );
}
const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
      font-family: IBM Plex Sans, sans-serif;
      font-size: 10px;
      box-sizing: border-box;
      min-height: 32px;
      width: 9rem;
      background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[800] : grey[300]
      };
      border-radius: 0.75em;
      margin: 0.5em;
      padding: 10px;
      text-align: left;
      line-height: 1.5;
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    
      &:hover {
        background: ${theme.palette.mode === "dark" ? "" : grey[100]};
        border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
      }
    
      &.${selectUnstyledClasses.focusVisible} {
        outline: 3px solid ${
          theme.palette.mode === "dark" ? blue[600] : blue[100]
        };
      }
    
      &.${selectUnstyledClasses.expanded} {
        &::after {
          content: '▴';
        }
      }
    
      &::after {
        content: '▾';
        float: right;
      }
      `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
      font-family: Muli;
      font-size: 0.875rem;
      box-sizing: border-box;
      padding: 5px;
      margin: 10px 0;
      min-width: 11rem;
      min-height:auto;
      background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[800] : grey[300]
      };
      border-radius: 0.75em;
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      overflow: auto;
      outline: 0px;
      `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
      list-style: none;
      padding: 8px;
      border-radius: 0.45em;
      cursor: default;
    
      &:last-of-type {
        border-bottom: none;
      }
    
      &.${optionUnstyledClasses.selected} {
        background-color: ${
          theme.palette.mode === "dark" ? blue[900] : blue[100]
        };
        color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
      }
    
      &.${optionUnstyledClasses.highlighted} {
        background-color: ${
          theme.palette.mode === "dark" ? grey[800] : grey[100]
        };
        color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      }
    
      &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
        background-color: ${
          theme.palette.mode === "dark" ? blue[900] : blue[100]
        };
        color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
      }
    
      &.${optionUnstyledClasses.disabled} {
        color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
      }
    
      &:hover:not(.${optionUnstyledClasses.disabled}) {
        background-color: ${
          theme.palette.mode === "dark" ? grey[800] : grey[100]
        };
        color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      }
      `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});
