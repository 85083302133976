import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { Rnd } from "react-rnd";
import classing from "../../../Assets/Styles/factory.module.css";
import * as factoryConsts from "../../../../FactoryConsts";
import MachineIcon from "../../../Assets/Images/Factory/mbhighres.png";
import HandlingIcon from "../../../Assets/Images/Factory/hdhighres.png";
import StorageIcon from "../../../Assets/Images/Factory/sbhighres.png";
import RemoveIcon from "../../../Assets/Images/ProductionLabel/remove.png";
import CautionIcon from "../../../Assets/Images/MileStones/caution.png";
import classes from "../../../Assets/Styles/factory.module.css";
import { useNavigate } from "react-router-dom";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/system";

import tooltipbg from "../../../Assets/Images/tootltipbg.png";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
    backgroundImage: `url(${tooltipbg})`,
    backgroundSize: "auto",
    backgroundRepeat: "no-repeat",
    color: "#707070",
    fontSize: "Muli",
    width: 220,
    height: 200,
  },
}));

const DesignBoard = (props) => {
  const [modify, setModify] = useState(false);
  const navigate = useNavigate();
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  return (
    <Grid container height={567} width={1000} bgcolor={"white"}>
      {props.modelData?.map((bay) => (
        <Rnd
          disableDragging={props.disabledEdit || props.disabledCreate}
          enableResizing={!props.disabledEdit && !props.disabledCreate}
          key={bay.Id}
          bounds={"parent"}
          default={{
            x: bay.x,
            y: bay.y,
            width: bay.Width,
            height: bay.Height,
          }}
          onDragStop={(e, d) => {
            bay.x = d.x;
            bay.y = d.y;
          }}
          onDrag={(e) => {
            e.stopImmediatePropagation();
          }}
          onResizeStop={(e, l, d, o, j) => {
            bay.Width = bay.Width + o.width;
            bay.Height = bay.Height + o.height;
            setModify(!modify);
          }}
          style={{
            border: "solid 1px #bcbcbc",
            backgroundColor: "#fcfafa",
            borderTopLeftRadius: "20px",
            borderBottomRightRadius: "20px",
          }}
          minWidth={51}
          minHeight={51}
          maxWidth={990}
          maxHeight={990}
        >
          <Grid container minWidth={bay.Width} minHeight={bay.Height}>
            <Grid
              item
              xs={10.5}
              className={classing["menu-st"]}
              paddingLeft={2}
            >
              {bay.Name}
            </Grid>
            <Grid
              item
              onClick={() => {
                if (!props.disabledEdit && !props.disabledCreate)
                  props.setModelData(
                    props.modelData.filter((x) => x.Id !== bay.Id)
                  );
              }}
            >
              <img src={RemoveIcon} alt={"remove"} />
            </Grid>
            {bay?.Blocks?.map((block) => (
              <Rnd
                disableDragging={props.disabledEdit || props.disabledCreate}
                enableResizing={!props.disabledEdit && !props.disabledCreate}
                key={block.Id}
                bounds={"parent"}
                default={{
                  x: block.x,
                  y: block.y,
                  width: block.Width,
                  height: block.Height,
                }}
                onDrag={(e) => {
                  e.stopImmediatePropagation();
                }}
                onDragStop={(e, d) => {
                  block.x = d.x;
                  block.y = d.y;
                }}
                onResizeStop={(e, l, d, o, j) => {
                  block.Width = block.Width + o.width;
                  block.Height = block.Height + o.height;
                  setModify(!modify);
                }}
                minWidth={35}
                minHeight={35}
                maxWidth={90}
                maxHeight={90}
              >
                <Grid item container>
                  <Grid
                    item
                    className={classing["menu-st"]}
                    container
                    alignItems={"center"}
                    justifyContent={"center"}
                    direction={"row"}
                    gap={0.1}
                    minWidth={60}
                  >
                    {block.TypeId === factoryConsts.MACHINE_BLOCK_ID
                      ? block.Code
                      : block.TypeId === factoryConsts.StORAGE_BLOCK_ID
                        ? block.Code + "/" + block.Category
                        : block.TypeId === factoryConsts.HANDLING_BLOCK_ID
                          ? block.Name
                          : ""}
                    <Grid
                      item
                      onClick={() => {
                        if (!props.disabledEdit && !props.disabledCreate) {
                          bay.Blocks = bay?.Blocks.filter(
                            (x) => x.Id !== block.Id
                          );
                          forceUpdate();
                        }
                      }}
                    >
                      <img src={RemoveIcon} alt={"remove"} />
                    </Grid>
                  </Grid>
                  {block.TypeId === factoryConsts.MACHINE_BLOCK_ID ? (
                    <HtmlTooltip
                      placement="right"
                      title={
                        <React.Fragment>
                          <Grid
                            container
                            padding={1}
                            gap={1}
                            fontFamily="Muli"
                            xs={12}
                          >
                            <Grid
                              item
                              backgroundColor="rgba(255, 0, 0, 0.48)"
                              height="45px"
                              width="100%"
                              padding={2}
                              color="#101650"
                              fontWeight="bold"
                              fontSize="10px"
                            >
                              <Grid
                                item
                                container
                                direction="row"
                                justifyContent="space-between"
                              >
                                <Grid item>
                                  {block.Code.length > 1
                                    ? block.Code.slice(0, 10) + "..."
                                    : block.Code}
                                </Grid>
                                <Grid item color="#fff">
                                  {block.StatusId === factoryConsts.ARCHIVED_ID
                                    ? "Archived"
                                    : block.StatusId === factoryConsts.ACTIVE_ID
                                      ? "Active"
                                      : block.StatusId === factoryConsts.ONHOLD_ID
                                        ? "OnHold"
                                        : ""}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item>Production Runs on the machine:</Grid>
                            <Grid item container direction="column" height="70px" wrap="nowrap" overflow="auto">
                              {block?.ProductionRuns.map((pr) => (
                                <Grid
                                  item
                                  fontWeight={"bold"}
                                  key={pr.Id}
                                  xs={12}
                                >
                                  {pr.Code}
                                </Grid>
                              ))}
                            </Grid>


                            <Grid item>Capacity:{block.Capacity} </Grid>
                            <Grid item>
                              <button
                                className={classes["goToProduction"]}
                                onClick={() => {
                                  navigate("/saas/productionPlanning/Calendar");
                                }}
                              >
                                Go to production planning
                              </button>
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      }
                    >
                      <Grid
                        item
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                        }}
                      >
                        <img
                          width={block.Width}
                          src={
                            block.TypeId === factoryConsts.HANDLING_BLOCK_ID
                              ? HandlingIcon
                              : block.TypeId === factoryConsts.MACHINE_BLOCK_ID
                                ? MachineIcon
                                : block.TypeId === factoryConsts.StORAGE_BLOCK_ID
                                  ? StorageIcon
                                  : ""
                          }
                          alt={"Icon"}
                        />
                      </Grid>
                    </HtmlTooltip>
                  ) : (
                    <Grid item>
                      <img
                        width={block.Width}
                        src={
                          block.TypeId === factoryConsts.HANDLING_BLOCK_ID
                            ? HandlingIcon
                            : block.TypeId === factoryConsts.MACHINE_BLOCK_ID
                              ? MachineIcon
                              : block.TypeId === factoryConsts.StORAGE_BLOCK_ID
                                ? StorageIcon
                                : ""
                        }
                        alt={"Icon"}
                      />
                    </Grid>
                  )}
                </Grid>
              </Rnd>
            ))}
            {bay?.Docks?.map((dock) => (
              <Rnd
                disableDragging={props.disabledEdit || props.disabledCreate}
                enableResizing={!props.disabledEdit && !props.disabledCreate}
                key={dock.Id}
                bounds={"parent"}
                default={{
                  x: dock.x,
                  y: dock.y,
                  width: dock.Width,
                  height: dock.Height,
                }}
                onDrag={(e) => {
                  e.stopImmediatePropagation();
                }}
                onDragStop={(e, d) => {
                  dock.x = d.x;
                  dock.y = d.y;
                }}
                onResizeStop={(e, l, d, o, j) => {
                  dock.Width = dock.Width + o.width;
                  dock.Height = dock.Height + o.height;
                  setModify(!modify);
                }}
                style={{
                  border: "solid 1px #bcbcbc",
                  backgroundColor: "#e9ebff",
                  borderTopLeftRadius: "20px",
                  borderBottomRightRadius: "20px",
                }}
                minWidth={51}
                minHeight={51}
                maxWidth={990}
                maxHeight={990}
              >
                <Grid item container>
                  <Grid
                    item
                    className={classing["menu-st"]}
                    container
                    alignItems={"center"}
                    justifyContent={"center"}
                    direction={"row"}
                    gap={0.1}
                    minWidth={60}
                  >
                    {dock.Code}
                    <Grid
                      item
                      onClick={() => {
                        if (!props.disabledEdit && !props.disabledCreate) {
                          bay.Docks = bay?.Docks.filter(
                            (x) => x.Id !== dock.Id
                          );
                          forceUpdate();
                        }
                      }}
                    >
                      <img src={RemoveIcon} alt={"remove"} />
                    </Grid>
                  </Grid>
                </Grid>
              </Rnd>
            ))}
          </Grid>
        </Rnd>
      ))}
    </Grid>
  );
};

export default DesignBoard;
const BlueTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
        color:#fff;
        background-color: #ea001e;
        opacity:0.4px;
        font-size: 13px;
        width:55px;
     
    `);
