import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import classes from "../../../Assets/Styles/profileDetails.module.css";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton } from "@mui/material";
import Fade from "@mui/material/Fade";
import { Divider } from "@mui/material";
import ProfileModal from "./ProfileModal";
import camera from "../../../Assets/Images/ProfileDetails/camera.png";
import pPicture from "../../../Assets/Images/ProfileDetails/profilePicture.png";

const ProfilePicture = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [item, setItem] = useState(false);
  const [item1, setItem1] = useState(false);
  const [item2, setItem2] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (!props.pPicture) {
      props.setPictureSrc(pPicture);
    }
  }, [props.pPicture]);
  return (
    <Grid container item>
      <Grid
        item
        style={{
          backgroundImage: `url(${props.pPicture})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className={classes["Ellipse-447"]}
        paddingLeft={14}
        paddingTop={13}
        md={1.6}
      >
        <Grid>
          <IconButton onClick={handleClick}>
            <img src={camera} alt={"edit"} id="fade-button" />
          </IconButton>
          <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem
              onClick={(e) => {
                setItem(true);
                setItem1(false);
                setItem2(false);
                setModalTitle("View Photo");
                handleClose();
                setOpenModal(true);
              }}
              onMouseOver={(e) => (e.target.style.fontWeight = "bold")}
              onMouseLeave={(e) => (e.target.style.fontWeight = "normal")}
              style={{
                fontFamily: "Muli",
                fontSize: "10px",
                color: "#0674b9",
                fontWeight: item ? "bold" : "normal",
              }}
            >
              View Photo
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={(e) => {
                setItem(false);
                setItem1(true);
                setItem2(false);
                setModalTitle("Update Photo");
                handleClose();
                setOpenModal(true);
              }}
              onMouseOver={(e) => (e.target.style.fontWeight = "bold")}
              onMouseLeave={(e) => (e.target.style.fontWeight = "normal")}
              style={{
                fontFamily: "Muli",
                fontSize: "10px",
                color: "#0674b9",
                fontWeight: item1 ? "bold" : "normal",
              }}
            >
              Update Photo
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={(e) => {
                setItem(false);
                setItem1(false);
                setItem2(true);
                setModalTitle("Delete Photo");
                handleClose();
                setOpenModal(true);
              }}
              onMouseOver={(e) => (e.target.style.fontWeight = "bold")}
              onMouseLeave={(e) => (e.target.style.fontWeight = "normal")}
              style={{
                fontFamily: "Muli",
                fontSize: "10px",
                color: "#0674b9",
                fontWeight: item2 ? "bold" : "normal",
              }}
            >
              Delete Photo
            </MenuItem>
          </Menu>
          <ProfileModal
            open={openModal}
            setOpen={setOpenModal}
            modalTitle={modalTitle}
            pPicture={props.pPicture}
            setPictureSrc={props.setPictureSrc}
            setProfileSrc={props.setProfileSrc}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProfilePicture;
