import React, { useState, useEffect } from "react";
import classes from "../../../Assets/Styles/profileDetails.module.css";
import { Grid } from "@mui/material";

import IconButton from "@mui/material/IconButton";
import pen from "../../../Assets/Images/ProfileDetails/pen.png";
import SloganModal from "./SloganModal";

const ProfileTitle = (props) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <Grid container item direction={"column"} spacing={1}>
      <Grid item marginLeft={8} fontSize={{ md: 24, xs: 18 }}>
        <div className={classes.profileTitle}>{props.companyName}</div>
      </Grid>

      <Grid item marginLeft={8} fontSize={{ xs: "8px", md: "16px" }}>
        {localStorage.getItem("isTeamMember") === "true" ? (
          <></>
        ) : (
          <div
            className={
              props.companyWebsite ? classes.websiteAfter : classes.SloganEdit
            }
          >
            {props.companyWebsite
              ? props.companyWebsite
              : "Add your company website"}
          </div>
        )}
      </Grid>
      <Grid container item direction={"row"}>
        {localStorage.getItem("isTeamMember") === "true" ? (
          <></>
        ) : (
          <Grid item xs={2} md={0.9}>
            <IconButton
              onClick={(e) => {
                setOpenModal(true);
              }}
            >
              <img src={pen} alt={"edit slogan"} />
            </IconButton>
          </Grid>
        )}

        <Grid item xs={8} xl={9} md={10} fontSize={{ xs: "2vw", md: "16px" }}>
          {localStorage.getItem("isTeamMember") === "true" ? (
            <></>
          ) : (
            <div className={classes.companySlogan}>
              {props.companySlogan
                ? props.companySlogan
                : "Share your awesome company slogan with pilecubes.(Or at least some description for your colleagues.)"}
            </div>
          )}
        </Grid>
        <Grid>
          <SloganModal
            open={openModal}
            setOpen={setOpenModal}
            companyWebsite={props.companyWebsite}
            companySlogan={props.companySlogan}
            setCompanySlogan={props.setCompanySlogan}
            setCompanyWebsite={props.setCompanyWebsite}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProfileTitle;
