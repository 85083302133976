//APIs
import * as constFile from "../pileCubesConst";
import axios from "axios";

const INPUT_API = constFile.INPUT_DATA;

export const getInputData = () => {
  return axios
    .get(`${process.env.REACT_APP_URL + INPUT_API}`, {
      params: { tenantId: null },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return (error.response.data);
    });
};


//Object Names
export const OBJECT_MANAGE_SUBSCRIPTION = 'Manage Subscription';
export const OBJECT_CLIENT = 'Client';
export const OBJECT_SUB_CONTRACTOR = 'Sub Contractor';
export const OBJECT_MAIN_CONTRACTOR = 'Main Contractor';
export const OBJECT_TEAM_MEMBER = 'Team Member';
export const OBJECT_USER_ROLE = 'User Role';
export const OBJECT_PROJECT = 'Project';
export const OBJECT_PHASE = 'Phase';
export const OBJECT_SUB_PROJECT = 'Sub Project';
export const OBJECT_DRAWING_ACTIVITY = 'Drawing Activity';
export const OBJECT_DOCUMENT = 'Document';
export const OBJECT_RFI = 'RFI';
export const OBJECT_SALES_ORDER = 'Sales Order';

//Permission/Actions
export const PermissionAction = {
  ALL: 'All',
  VIEW: 'View',
  SUBSCRIBE: 'Subscribe',
  CHANGE: 'Change',
  CANCEL: 'Cancel',
  ADD: 'Add',
  EDIT: 'Edit',
  INVITE: 'Invite',
  RESEND_INVITE: 'Resend Invite',
  SUSPEND: 'Suspend',
  REACTIVATE: 'Reactivate',
  ARCHIVE: 'Archive/Close',
  INVITE_ADMIN: 'Invite Admin/special roles',
  INVITE_PROJECT_MANAGER: 'Invite Project Manager',
  INVITE_ENGINEERING_MANAGER: 'Invite Engineering Manager',
  INVITE_TEAM_LEADER: 'Invite Team Leader',
  INVITE_ENGINEER: 'Invite Engineering',
  INVITE_DOCUMENT_CONTROLLER: 'Invite Document Controller',
  SEARCH: 'Search',
  CREATE: 'Create',
  JOIN: 'Join /Search published projects',
  ASSIGN_CONTRACTOR_RESPONSIBLITY: 'Assign Contractor Responsibility',
  ASSIGN_TEAM_MEMBER_RESPONSIBLITY: 'Assign Team Member Responsibility',
  ASSIGN_DEADLINE: 'Assign Deadline',
  APPROVE_DEADLINE: 'Approve Deadline',
  APPROVE_PLAN: 'Approve Plan',
  SUBMIT: 'Submit',
  REVIEW_SUBMITTALS: 'Review Submittals',
  SUBMIT_DRAWING_ACTIVITY: 'Submit Drawing Activity',
  UPDATE_STATUS: 'Update Status',
  UPLOAD: 'Upload',
  SAVE: 'Save',
  RAISE: 'Raise',
  TRANSMIT: 'Transmit',
  CHOOSE_SHAPE: 'Choose Shape',
  CREATE_BBS: 'Create BBS',
}

const TEST_INPUT = '/user/test-ability'
export const getTestAbility = (object, action) => {
  const token = localStorage.getItem("accessToken");
  return axios
    .post(`${process.env.REACT_APP_URL + TEST_INPUT}`,
      {
        Object: object,
        Action: action
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data;
    });
};