import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import backgroundImg from "../SAAS/Assets/Images/ProfileDetails/SaaSbackground.png";
import notFound from "../Assets/Images/Error page not found.png";
import { useNavigate } from "react-router-dom";

const style = {
  backgroundContainer: {
    backdropFilter: "blur(30px)",
    backgroundImage: `url(${backgroundImg})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100vw",
    minHeight: "85vh",
  },
};

export default function PageNotFound() {
  const navigate = useNavigate();

  useEffect(() => {
    let apiTest = true;
    if (localStorage.getItem("accessToken") && apiTest) {
      if (localStorage.getItem("isVerified") === "false") {
        if (localStorage.getItem("isTeamMember") === "true")
          navigate("/teamMemberRegistration");
        else {
          if (localStorage.getItem("AccountType") === "1") navigate("/main");
          if (localStorage.getItem("AccountType") === "2") navigate("/sub");
        }
      } else {
        if (localStorage.getItem("isGuest") === "true")
          navigate("/saas/invitations");
        else if (localStorage.getItem("IsScheduled") === "true")
          navigate("/scheduled");
        else if (
          localStorage.getItem("isSubscribed") === "true" ||
          localStorage.getItem("isSubscribedManu") === "true" ||
          localStorage.getItem("IsSubscribedBeforeManu") === "true" ||
          localStorage.getItem("isTeamMember") === "true" ||
          localStorage.getItem("IsFreePlanActivated") === "true" ||
          localStorage.getItem("IsSubscribedBefore") === "true"
        )
          navigate("/saas/admin");
        else navigate("/pricing");
      }
    }
    return () => {
      apiTest = false;
    };
  }, [navigate]);
  return (
    <Grid
      container
      sx={style.backgroundContainer}
      alignItems="center"
      justifyContent="center"
    >
      <img src={notFound} alt="not found" width="30%" />
    </Grid>
  );
}
