import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import scheduled from "../../Assets/Images/scheduled.png";

function Scheduled({ setRegister, setNaved, setTerms }) {
  const navigate = useNavigate();
  useEffect(() => {
    setRegister(false);
  }, [setRegister]);

  useEffect(() => {
    setNaved(false);
    setTerms(false);
  }, [setNaved]);

  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);

  useEffect(() => {
    let apiTest = true;
    if (localStorage.getItem("IsScheduled") === "false")
      if (localStorage.getItem("accessToken") && apiTest) {
        if (localStorage.getItem("isVerified") === "false") {
          if (localStorage.getItem("isTeamMember") === "true")
            navigate("/teamMemberRegistration");
          else {
            if (localStorage.getItem("AccountType") === "1") navigate("/main");
            if (localStorage.getItem("AccountType") === "2") navigate("/sub");
          }
        } else {
          if (localStorage.getItem("isGuest") === "true")
            navigate("/saas/invitations");
          else if (
            localStorage.getItem("isSubscribed") === "true" ||
            localStorage.getItem("isSubscribedManu") === "true" ||
            localStorage.getItem("IsSubscribedBeforeManu") === "true" ||
            localStorage.getItem("isTeamMember") === "true" ||
            localStorage.getItem("IsFreePlanActivated") === "true" ||
            localStorage.getItem("IsSubscribedBefore") === "true"
          )
            navigate("/saas/admin");
          else navigate("/pricing");
        }
      }
    return () => {
      apiTest = false;
    };
  }, [navigate]);

  return (
    <Grid
      container
      sx={{
        width: "100vw",
        minHeight: "100vh",
      }}
      alignItems="center"
      justifyContent="center"
      padding={{ xs: "10% 0 50% 0", md: "10% 0 20% 0" }}
    >
      <Grid item xs={12} textAlign="center">
        <img src={scheduled} alt="watch" />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          fontFamily: "Muli",
          fontSize: 30,
          fontWeight: "bold",
          lineHeight: 1,
          textAlign: "center",
          color: "#101650",
        }}
      >
        Your {localStorage.getItem("AccountType") === "1" ? "Main" : "Sub"}{" "}
        {localStorage.getItem("planId")} plan is scheduled
        <br />
        for the following duration
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          fontFamily: "Muli",
          fontSize: 20,
          lineHeight: 1,
          textAlign: "center",
          color: "#0674b9",
        }}
      >
        {localStorage.getItem("StartDate")?.slice(0, 10).replaceAll("-", "/")} -{" "}
        {localStorage.getItem("EndDate")?.slice(0, 10).replaceAll("-", "/")}{" "}
      </Grid>
    </Grid>
  );
}

export default Scheduled;
