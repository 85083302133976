import React, { useEffect, useState } from "react";

// import useWindowDimensions from "../../Components/Navbar/WindowDimension";
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import FailedImg from "../../../Assets/Images/Pricing/Failed.png";
import { useNavigate } from "react-router-dom";
function Failed({ setLoading, setRegister }) {
  useEffect(() => {
    setRegister(true);
  }, [setRegister]);

  const navigate = useNavigate();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        item
        container
        md={12}
        justifyContent={"center"}
        marginTop={{ xs: 17, md: 13 }}
      >
        <Grid item container md={12} justifyContent={"center"}>
          <div className="Payment-Failed">Payment Failed</div>
        </Grid>
        <Grid item container md={12} justifyContent={"center"}>
          <div className="Oh-no-your-payment-failed">
            Oh no, your payment failed{" "}
          </div>
        </Grid>
        <Grid
          item
          container
          md={12}
          justifyContent={"center"}
          marginTop={{ xs: 8, md: 4 }}
        >
          <img src={FailedImg} alt="Failed" />
        </Grid>
        <Grid item container md={12} justifyContent={"center"} marginTop={2}>
          <div className="Failed-Subscription">Failed Subscription</div>
        </Grid>
        <Grid item container md={12} justifyContent={"center"} marginTop={3}>
          <div className="Failed-Subscription-description">
            Your payment did not go through. Please contact your payment
            provider, or try another card.
          </div>
        </Grid>
        <Grid item container md={12} justifyContent={"center"} marginTop={3}>
          <button className="BackButton" onMouseOver={(e)=>e.target.style.cursor="pointer"} onClick={()=>navigate('/pricing')}>Back</button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Failed;
