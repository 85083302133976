import React, { useEffect, useState, useRef } from "react";
import "../../../Assets/Styles/Pricing.css";
import classes from "./Manu.module.css";
import useWindowDimensions from "../../../Components/Navbar/WindowDimension";
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import PlanTabs from "./PlanTabs";
import * as data from "../../../Services/pricing-data.proxy.js";
import Animation from "../../Home/Animation";
import MobilePlansDescription from "../../../SAAS/Components/Profile/ManageSubscription/MobilePlansDescriptions";
import PlansDescription from "../../../SAAS/Components/Profile/ManageSubscription/PlansDescription";
const PRICING_PLANS = data.getPricingPlans;
function EngineeringPlans({
  setLoading,
  accounType,
  isLoggedIn,
  setShowContactUsPricing,
}) {
  const { width } = useWindowDimensions();
  const [phoneView, setphoneView] = useState(false);
  useEffect(() => {
    if (width < 1000) {
      setphoneView(true);
    } else {
      setphoneView(false);
    }
  });
  const [pricingPlans, setPricingPlans] = useState();
  const [BillingId, setBillingId] = useState(1);

  const fetchProducts = () => {
    //setLoading(true);
    PRICING_PLANS(3, BillingId, setLoading).then((x) => setPricingPlans(x));
  };
  useEffect(() => {
    // setLoading(true);
    fetchProducts();
  }, [BillingId]);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid item container md={12} spacing={2}>
        <Grid
          item
          container
          md={12}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          paddingBottom={18}
        >
          <Grid
            item
            container
            xs={12}
            alignItems={"center"}
            justifyContent={"center"}
          >
            {pricingPlans ? (
              <PlanTabs
                setLoading={setLoading}
                accounType={accounType}
                isLoggedIn={isLoggedIn}
                setShowContactUsPricing={setShowContactUsPricing}
                setBillingId={setBillingId}
                pricingPlans={pricingPlans}
              />
            ) : (
              <Grid container justifyContent={"center"} alignItems={"center"}>
                <Animation />
              </Grid>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            container
            marginTop={2}
            dirction={"row"}
            gap={2}
            paddingLeft={"12%"}
          >
            <Grid item className={"SC-CircleActive"} marginTop={{ md: 0.7 }}>
              SC
            </Grid>

            <Grid item className={classes["Sub-ContractorC"]}>
              Sub Contractor
            </Grid>
          </Grid>
          {/*Manu Project comment on end*/}
          <Grid
            item
            paddingTop={3}
            xs={10}
            className="Manu-hint"
            paddingLeft={"3.5%"}
          >
            You can subscribe to an Engineering plan and a Manufacturing plan
            simultaneously with 2 different billing types and periods.
          </Grid>
          <Grid
            item
            paddingTop={1.5}
            xs={10}
            className="Manu-hint"
            paddingLeft={"3.5%"}
          >
            Once you are subscribed to an Engineering Plan, you will have the
            features of the Manufacturing Free Plan.
          </Grid>
        </Grid>
        {pricingPlans ? (
          phoneView ? (
            <Grid container alignItems={"center"} justifyContent={"center"}>
              <MobilePlansDescription Plans={pricingPlans} />
            </Grid>
          ) : (
            <PlansDescription Plans={pricingPlans} />
          )
        ) : (
          <Grid container justifyContent={"center"} alignItems={"center"}>
            <Animation />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default EngineeringPlans;
