import React, { useState, useEffect } from "react";
import { styled, Box } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import classes from "../../../../../Components/Pricing/ManufacturingPricing/Manu.module.css";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import checkSub from "../../../../../SAAS/Assets/Images/Settings/checkSub.jpg";
import ManuFreeIcon from "../../../../../Assets/Images/Pricing/ManuFreeIcon.png";
import { useNavigate } from "react-router-dom";
import * as subprojectServices from '../../../../Services/subprojects-services.proxy';

const deleteContract = subprojectServices.deleteContract;

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 25%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
    bgcolor: "#transparent",
    border: "0",
    width: "45%",
    height: "60%",
    outline: "none",
};

export default function DeleteModal(props) {

    const [open, setOpen] = useState(false);
    const [load, setLoad] = useState(false);
    const handleOpen = () => setOpen(true);

    const FreeManuFeatures = [
        "BBS creation",
        "Shape library access",
        "Tag design",
    ];

    const handleClose = () => {
        setOpen(false);
        props.setOpen(false);
    };

    useEffect(() => {
        if (props.open) {
            handleOpen(true);
        }
    }, [props.open]);

    return (
        <div>
            <StyledModal
                disableScrollLock
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={open}
                onClose={handleClose}
                BackdropComponent={Backdrop}
            >
                <Box sx={style}>
                    <Grid container direction={"row-reverse"}>
                        <IconButton
                            onMouseOver={(e) => {
                                e.target.style.cursor = "pointer";
                            }}
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <CloseIcon htmlColor="#fff" />
                        </IconButton>
                    </Grid>
                    <Grid bgcolor={"#fff"} container direction={"column"} paddingTop={2}>
                        <Grid item container justifyContent={"center"}>
                            <div className={classes["Modal-title"]}>
                                Delete Contract
                            </div>
                        </Grid>
                        <Grid
                            item
                            width={"100%"}
                            bgcolor={"#e9e7e7"}
                            height={1}
                            marginTop={1}
                        >
                            <div style={{ width: "100%", height: 1 }}></div>
                        </Grid>
                        <Grid
                            item
                            container
                            justifyContent={"center"}
                            alignItems={"center"}
                            className={classes.message}
                            paddingTop='4%'
                        >
                            <Grid item md={12} fontSize={16}>
                                Are you sure you want to delete this contract?
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            marginTop={3.1}
                            xs={10}
                            container
                            direction={"row-reverse"}
                            bgcolor={"#e9e7e7"}
                            padding={2}
                        >
                            <Grid item marginLeft={2}>
                                <button
                                    style={{ opacity: load ? 0.5 : 1 }}
                                    className={classes.SaveModal}
                                    onMouseOver={(e) => {
                                        e.target.style.cursor = "pointer";
                                    }}
                                    onClick={() => {
                                        setLoad(true)
                                        deleteContract(props.contractId).then(() => {
                                            setLoad(false);
                                            props.setShowContract(!props.showContract);
                                            props.setSubContSaved(!props.subContSaved);
                                            handleClose();
                                        })
                                    }}
                                >
                                    {load ? "Deleting" : "Delete"}
                                </button>
                            </Grid>
                            <Grid item>
                                <button
                                    className={classes.exitModal}
                                    onMouseOver={(e) => {
                                        e.target.style.cursor = "pointer";
                                    }}
                                    onClick={() => {
                                        handleClose();
                                    }}
                                >
                                    Cancel
                                </button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </StyledModal>
        </div>
    );
}
