import React from "react";
import { Grid } from "@mui/material";
import deliveryCard from "../../Assets/Images/DeliveryCard.png";
import { useNavigate } from "react-router-dom";
import * as salesOrderConst from "../../../salesOrderConst";
import classes from "../../Assets/Styles/Delivery.module.css";

export default function DeliveryNoteCard(props) {
  const navigate = useNavigate();
  return (
    <Grid
      item
      container
      gap={1}
      onMouseOver={(e) => {
        e.target.style.cursor = "pointer";
      }}
      onClick={() => {
        navigate(`/saas/Delivery/DeliveryNote?deliveryNoteId=${props.data.Id}`)

      }}

      sx={{
        backgroundImage: `url(${deliveryCard})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        fontFamily: "Muli",
      }}
      direction="column"
      // justifyContent="center"
      // padding="10% 10% 25% 20%"
      height="16rem"
      paddingLeft={3}
      paddingTop={7}
      xs={12}
      
    >
      <Grid item className={classes["DeliveryCode"]}>
        {props.data.Code}
      </Grid>
      <Grid item className={classes["CardDetailsBlue"]} paddingTop={1.5}>{props.data.Status}</Grid>
      <Grid item className={classes["CardDetailsBlue"]}>
        {props.data.AcknowledgementStatus }
      </Grid>
      <Grid item className={classes["CardDetailsBlue"]}>{props.data.CreationTime?.slice(0,10)}</Grid>
    </Grid>
  );
}
