import React, { useState, useEffect } from "react";
import ForgeViewer from "react-forge-viewer";
import { useLocation } from "react-router-dom";

export default function Viewer(props) {
  useEffect(() => {
    props.setShowSaaSNavbar(false);
  }, [props.setShowSaaSNavbar]);
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);
  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);
  const [view, setView] = useState(null);

  const handleViewerError = (error) => {
    console.log("Error loading viewer.");
  };
  const location = useLocation();
  const URN = location.state.Urn;
  const AccessToken = location.state.accessToken;
  const handleDocumentLoaded = (doc, viewables) => {
    if (viewables.length === 0) {
      console.error("Document contains no viewables.");
    } else {
      setView(viewables[0]);
    }
  };

  const handleDocumentError = (viewer, error) => {
    console.log("Error loading a document");
  };

  const handleModelLoaded = (viewer, model) => {
    console.log("Loaded model:", model);
  };

  const handleModelError = (viewer, error) => {
    console.log("Error loading the model.");
  };

  const getForgeToken = () => {
    return {
      access_token: AccessToken,
      expires_in: 3600,
      token_type: "Bearer",
    };
  };
  const handleTokenRequested = (onAccessToken) => {
    console.log("Token requested by the viewer.");
    if (onAccessToken) {
      let token = getForgeToken();
      if (token) onAccessToken(token.access_token, token.expires_in);
    }
  };
  return (
    <ForgeViewer
      version="6.0"
      urn={URN}
      view={view}
      headless={false}
      onViewerError={handleViewerError}
      onTokenRequest={handleTokenRequested}
      onDocumentLoad={handleDocumentLoaded}
      onDocumentError={handleDocumentError}
      onModelLoad={handleModelLoaded}
      onModelError={handleModelError}
    />
  );
}
