import React, { useEffect, useState } from "react";
import classes from "../../../Assets/Styles/ProductionLabel.module.css";
import { Grid, TextField } from "@mui/material";
import * as ProductionLabelConsts from "../../../../productionLabelConst";
import plusIcon from "../../../Assets/Images/ProductionLabel/plus.png";
import { styled } from "@mui/system";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import ShapeFormModal from "./ShapeFormModal";
import { useNavigate } from "react-router-dom";
const LabelControlSystem = (props) => {
  const [open, setOpen] = useState(false);
  const [fieldChosen, setFieldChosen] = useState();
  const navigate = useNavigate();
  return (
    <Grid container className={classes["controlSystem"]} alignItems={"center"}>
      <Grid item className={classes["Choose-options"]}>
        Choose the label size and name, you can also set label as default.
      </Grid>
      <Grid item container xs={12} paddingTop={1} alignItems={"center"}>
        <Grid item xs={5} className={classes["Production-Label"]}>
          Label
        </Grid>
        <Grid item container xs={7} direction={"row-reverse"} gap={1}>
          <Grid
            item
            className={
              props.asDefault
                ? classes["DefaultLabelChecked"]
                : classes["DefaultLabel"]
            }
            onClick={() => {
              props.setAsDefault(!props.asDefault);
            }}
          ></Grid>
          <Grid item className={classes["Set-as-default"]} paddingTop={0.7}>
            Set as default
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        className={classes["LabelType"]}
        marginTop={1}
      >
        {props.labelTypeId === ProductionLabelConsts.RAW_MATERIAL_ID
          ? "Raw Materials"
          : props.labelTypeId === ProductionLabelConsts.REMENANT_ID
          ? "Remnants"
          : props.labelTypeId === ProductionLabelConsts.FINISHED_GOODS_ID
          ? "Finished Goods"
          : ""}
      </Grid>

      <Grid
        item
        container
        xs={12}
        gap={props.labelSize === 0 ? 2.1 : 1}
        paddingTop={0.8}
      >
        <Grid item>
          <CustomSelect
            style={{ color: "#707070" }}
            value={props.labelSize}
            // disabled={cantSelectTM}
            onChange={(option) => {
              props.setLabelSize(option);
              props.setSelectedLabelRawsFields([]);
            }}
          >
            <StyledOption value={null} disabled>
              A4
            </StyledOption>
            {props.labelSizeOptions?.map((s) => (
              <StyledOption key={s} value={s}>
                {s + " Tags"}
              </StyledOption>
            ))}
          </CustomSelect>
        </Grid>
        <Grid item className={classes["or"]}>
          or
        </Grid>
        <Grid
          item
          className={
            props.labelSize === null
              ? classes["CustomPrinterActivated"]
              : classes["CustomPrinter"]
          }
          onClick={() => {
            props.setLabelSize(null);
            if (props.labelSize !== null) props.setSelectedLabelRawsFields([]);
          }}
        >
          Custom Printer
        </Grid>
      </Grid>

      <Grid item paddingTop={1} xs={12}>
        <Grid
          item
          style={{ fontFamily: "Muli", fontSize: "11px", color: "#101650" }}
        >
          Label Name:
        </Grid>
        <TextField
          fullWidth
          // label={props.labelName ? "" : "Label Name"}
          size={"small"}
          variant="outlined"
          sx={{ backgroundColor: "#fff" }}
          // InputLabelProps={{
          //   shrink: false,
          //   style: { fontFamily: "Muli", fontSize: "11px" },
          // }}
          InputProps={{
            style: { fontFamily: "Muli", fontSize: "11px" },
          }}
          value={props.labelName}
          error={props.labelNameError}
          helperText={props.labelNameError ? "Max Length 50 char" : ""}
          onChange={(e) => {
            props.setLabelName(e.target.value);
            if (e.target.value.length > 50) {
              props.setLabelNameError(true);
            } else {
              props.setLabelNameError(false);
            }
          }}
        />
      </Grid>

      <Grid item xs={6} className={classes["Production-Label"]} paddingTop={1}>
        Fields
      </Grid>
      <Grid container gap={0.5} paddingTop={1}>
        {props.labelRawsFields?.map((field) => (
          <Grid
            item
            xs={12}
            container
            alignItems={"center"}
            className={classes["RawsField"]}
            key={field.Id}
          >
            <Grid item xs={10.5}>
              {field.Name}
            </Grid>
            <Grid
              item
              paddingTop={0.5}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onClick={() => {
                if (
                  field.Name === "Heat Number" &&
                  (localStorage.getItem("IsFreePlanActivated") === "true" ||
                    localStorage.getItem("planIdManu")?.includes("Core"))
                ) {
                  props.setShowMessage(true);
                } else if (
                  // (field.Name === "POR ref" &&
                  //   localStorage.getItem("IsFreePlanActivated") === "true") ||
                  // localStorage.getItem("planIdManu")?.includes("Core")
                  field.Name === "Reference Run No" &&
                    (localStorage.getItem("IsFreePlanActivated") === "true" ||
                  localStorage.getItem("planIdManu")?.includes("Core"))
                ) {
                  props.setShowMessage(true);
                } else if (field.Name === "Hole Space") {
                  props.setSelectedLabelRawsFields(
                    props.selectedlabelRawsFields.concat({
                      Id: props.selectedlabelRawsFields.length + 1,
                      LabelRowId: field.Id,
                      Data: field.Name,
                      x: 0,
                      y: 0,
                      Width: 37,
                      Height: 37,
                    })
                  );
                } else if (field.Name === "Barcode") {
                  props.setSelectedLabelRawsFields(
                    props.selectedlabelRawsFields.concat({
                      Id: props.selectedlabelRawsFields.length + 1,
                      LabelRowId: field.Id,
                      Data: field.Name,
                      x: 0,
                      y: 0,
                      Width: 275,
                      Height: 100,
                    })
                  );
                } else if (field.Name === "Shape Selected") {
                  setFieldChosen(field);
                  setOpen(true);
                } else {
                  props.setSelectedLabelRawsFields(
                    props.selectedlabelRawsFields.concat({
                      Id: props.selectedlabelRawsFields.length + 1,
                      LabelRowId: field.Id,
                      Data: field.Name,
                      x: 0,
                      y: 0,
                      Width: 135,
                      Height: 36,
                    })
                  );
                }
              }}
            >
              <img src={plusIcon} alt={"plus"} />
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} container gap={4} paddingTop={4}>
        <button
          className={classes["cancelbut"]}
          onClick={() => {
            navigate("/saas/admin/productionLabel");
          }}
        >
          Cancel
        </button>
        <button
          className={classes["SaveBut"]}
          disabled={props.isDisabled}
          onClick={() => {
            props.save();
          }}
        >
          Save
        </button>
      </Grid>
      <ShapeFormModal
        open={open}
        setOpen={setOpen}
        setSelectedLabelRawsFields={props.setSelectedLabelRawsFields}
        selectedlabelRawsFields={props.selectedlabelRawsFields}
        fieldChosen={fieldChosen}
      />
    </Grid>
  );
};
const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
        font-family: Muli;
        font-size: 10px;
        box-sizing: border-box;
        width: 100%;
        background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
        border: 1px solid #f3f3f3;
        border-radius: 5px;
        text-align: left;
        color: #707070;
        padding:5px 7px 5px 5px ;
        border: solid 1px #bcbcbc;
        &:hover {
          background: ${theme.palette.mode === "dark" ? "" : grey[100]};
          border-color: ${
            theme.palette.mode === "dark" ? grey[700] : grey[400]
          };
        }
      
        &.${selectUnstyledClasses.focusVisible} {
          outline: 3px solid ${
            theme.palette.mode === "dark" ? blue[600] : blue[100]
          };
        }
      
        &.${selectUnstyledClasses.expanded} {
          &::after {
            content: '▴';
          }
          box-shadow: 0 3px 6px 0 #0674b9;
          background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
          border: solid 1px #0674b9;
         
        }
      
        &::after {
          content: '▾';
          float: right;
          padding-left:8px;
        }
        `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
        font-family: Muli;
        font-size: 10px;
        box-sizing: border-box;
        padding: 5px;
        margin: 10px 0;
        width:75px;
        max-height:250px !important;
        background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
        border: 1px solid ${
          theme.palette.mode === "dark" ? grey[800] : grey[300]
        };
        border-radius: 0.75em;
        color: #444;
        overflow: auto;
        outline: 0px;
        `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
        list-style: none;
        padding: 8px;
        border-radius: 0.45em;
        cursor: default;
      
        &:last-of-type {
          border-bottom: none;
        }
      
        &.${optionUnstyledClasses.selected} {
          background-color: ${
            theme.palette.mode === "dark" ? blue[900] : blue[100]
          };
          color: #0674b9;
        }
      
        &.${optionUnstyledClasses.highlighted} {
          background-color: ${
            theme.palette.mode === "dark" ? grey[800] : grey[100]
          };
          color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
        }
      
        &.${optionUnstyledClasses.highlighted}.${
    optionUnstyledClasses.selected
  } {
          background-color: ${
            theme.palette.mode === "dark" ? blue[900] : blue[100]
          };
          color: #0674b9;
        }
      
        &.${optionUnstyledClasses.disabled} {
          color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
        }
      
        &:hover:not(.${optionUnstyledClasses.disabled}) {
          background-color:#fff;
          color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
        }
        `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} ref={ref} components={components} />;
});
export default LabelControlSystem;
