import React, { useEffect, useState } from "react";

// import useWindowDimensions from "../../Components/Navbar/WindowDimension";
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import SuccessImg from "../../../Assets/Images/Pricing/Success.png";
import { useNavigate } from "react-router-dom";
function Success({ setRegister, setIsSubscribed, setNaved, setTerms }) {
  useEffect(() => {
    setRegister(true);
  }, [setRegister]);
  useEffect(() => {
    setNaved(false);
    setTerms(false)
  }, [setNaved]);
  useEffect(() => {
    localStorage.setItem("isSubscribed", "true");
    setIsSubscribed("true");
  });
  const navigate = useNavigate();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        item
        container
        md={12}
        justifyContent={"center"}
        marginTop={{ xs: 17, md: 6 }}
      >
        <Grid item container md={12} justifyContent={"center"}>
          <div className="Payment-Complete">Payment Complete</div>
        </Grid>
        <Grid item container md={12} justifyContent={"center"}>
          <div className="Congratulations">Congratulations</div>
        </Grid>
        <Grid
          item
          container
          md={12}
          justifyContent={"center"}
          marginTop={{ xs: 8, md: 4 }}
        >
          <img src={SuccessImg} alt="Success" />
        </Grid>
        <Grid item container md={12} justifyContent={"center"} marginTop={2}>
          <div className="Successful-Subscription">Successful Subscription</div>
        </Grid>
        <Grid item container md={12} justifyContent={"center"} marginTop={3}>
          <div className="Successful-Subscription-description">
            You have successfully subscribed to the
            {" "}   {localStorage.getItem("PlanChosen")}{" "}
            plan with Pilecubes
          </div>
        </Grid>
        <Grid item container md={12} justifyContent={"center"} marginTop={3}>
          <button
            className="getStarted1"
            onMouseOver={(e) => (e.target.style.cursor = "pointer")}
            onClick={() => navigate("/saas/admin")}
          >
            Get Started
          </button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Success;
