import React from "react";
import { Grid } from "@mui/material";
import sent from "../../../Assets/Images/sub/submail.png";
import close from "../../../Assets/Images/form/x.png";
import { useNavigate } from "react-router-dom";
const SuccessContact = () => {
  const navigate = useNavigate();
  const styles = {
    backgroundContainer: {
      boxShadow: "0 6px 12px 0 rgba(6, 116, 185, 0.5)",
      border: "solid 1px #eaf0f5",
      backgroundColor: "#fff",
    },

    thanksMessage: {
      fontFamily: "Muli",
      fontSize: "25px",
      fontWeight: "bold",
      color: "#0674b9",
    },
    messageText: {
      fontFamily: "Muli",
      color: "#101650",
      fontSize: "15px",
      textAlign: "center",
    },
  };
  return (
    <Grid
      item
      container
      direction={"column"}
      alignItems={"center"}
      marginTop={1}
      md={9}
      sx={styles.backgroundContainer}
      paddingBottom={15}
    >
      <Grid
        item
        md={12}
        xs={12}
        paddingRight={2}
        container
        onClick={(e) => {
          navigate("/");
        }}
        onMouseOver={(e) => {
          e.target.style.cursor = "pointer";
        }}
        direction={"row-reverse"}
        paddingTop={2}
      >
        <img src={close} />
      </Grid>
      <Grid item marginTop={5}>
        <img src={sent} alt="sent" />
      </Grid>
      <Grid item marginTop={7}>
        <div style={styles.thanksMessage}>Thank you for reaching out</div>
      </Grid>
      <Grid
        item
        marginTop={2}
        sx={styles.messageText}
        alignItems={"center"}
        width={252}
      >
        Pilecubes Team will respond soon to manage your subscription.
      </Grid>
    </Grid>
  );
};

export default SuccessContact;
