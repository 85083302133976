import React, { useState, useEffect } from "react";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import classes from "../../Table/Table.module.css";
import classes2 from "./TeamMemberModal.module.css";
import { Grid } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as saasServices from "../../../Services/saas-services.proxy";
import MenuPic from "../../../Assets/Images/Table/menuPic.png";
import { TextField, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Stack, CircularProgress } from "@mui/material";
import * as constFile from "../../../../StatusConst";

const getTMAssignedProjectList = saasServices.getTMAssignedProjectList;
const changeTMSubrojectStatus = saasServices.changeTMSubrojectStatus;

//Statuses
const INVITED = constFile.INVITED;
const ACTIVE = constFile.ACTIVE;
const SUSPENDED = constFile.SUSPENDED;

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

export default function TMAssignmentLog(props) {
  const [teamMemberTable, setTeamMemberTable] = useState([]);
  const [dataLength, setdataLength] = useState();
  const [modify, setModify] = useState(false);
  const [preInvite, setPreInvite] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const [pageSize, setPageSize] = useState(25);
  const listData = {
    TeamMemberId: props.rowData?.row.Id,
    Pagination: {
      PageSize: pageSize,
      PageNumber: pageNumber,
    },
    Search: searchText,
  };

  const [loadingData, setLoadingData] = useState(false);
  useEffect(() => {
    let apiTest = true;
    setLoadingData(true);
    getTMAssignedProjectList(listData).then((x) => {
      if (apiTest) {
        setTeamMemberTable(x.Projects);
        setdataLength(x.Total);
        setLoadingData(false);
      }
    });

    return () => {
      apiTest = false;
    };
  }, [pageSize, pageNumber, searchText, modify]);

  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowData, setRowData] = useState();

  const handleClick = async (event, params) => {
    setRowData(params);

    setAnchorEl(event.target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const columns = [
    { field: "id", hide: true, hideable: false },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      width: 160,
      renderCell: (params) => (
        <img
          src={MenuPic}
          alt="menu"
          onClick={(e) => {
            handleClick(e, params);
          }}
        ></img>
      ),
    },
    {
      field: "Project",
      width: 243,
    },
    {
      field: "Phase",
      headerName: "Phase",
      width: 250,
      sortable: false,
    },
    {
      field: "SubProject",
      headerName: "SubProject",
      width: 250,
      resizeable: true,
      sortable: false,
    },
    {
      field: "Discipline",
      headerName: "Discipline",
      width: 200,
      sortable: false,
    },
    {
      field: "Status",
      headerName: "Status",
      width: 400,
      sortable: false,
      renderCell: (params) => (
        <span
          style={{
            color:
              params.row.Status === "Active"
                ? "green"
                : params.row.Status === "Invited"
                ? "#0674b9"
                : params.row.Status === "Suspended"
                ? "red"
                : "grey",
          }}
        >
          {params.row?.Status}
        </span>
      ),
    },
  ];
  return (
    <Grid container>
      <Grid item xs={12} container alignItems="center" paddingBottom="3%">
        <Grid
          item
          xs={1.5}
          sx={{
            fontFamily: "Muli",
            fontSize: 18,
            fontWeight: "bold",
            lineHeight: 1.44,
            textAlign: "left",
            color: "#101650",
          }}
        >
          {props.rowData.row.Name ? props.rowData.row.Name : "Team Member Name"}
        </Grid>
        <Grid
          item
          xs={9.5}
          sx={{
            border: "solid 0.1px #fff",
            backgroundColor: "#fff",
            height: 0,
          }}
        ></Grid>
        <Grid item xs={1} paddingLeft="1.5%">
          <button
            className={classes2["GoBack"]}
            onClick={() => {
              props.setViewAssignmentLog(false);
            }}
          >
            Go back
          </button>
        </Grid>
      </Grid>
      <Grid
        item
        md={12}
        container
        direction={"row-reverse"}
        paddingRight={{ md: 4, xs: 0 }}
      >
        <Box
          sx={{
            p: 0.5,
            pb: 0,
          }}
        >
          <TextField
            variant="standard"
            fullWidth
            onChange={inputHandler}
            placeholder="Search this list…"
            InputProps={{
              startAdornment: (
                <SearchIcon
                  fontSize="small"
                  htmlColor={"#707070"}
                  style={{ paddingLeft: 5 }}
                />
              ),
            }}
            sx={{
              fontFamily: "Muli",
              backgroundColor: "white",
              borderRadius: 1,
              width: {
                xs: 1,
                sm: "auto",
              },
              m: (theme) => theme.spacing(1, 0.5, 1.5),
              "& .MuiSvgIcon-root": {
                mr: 0.5,
              },
              "& .MuiInput-underline:before": {
                borderBottom: 0,
                borderColor: "white",
              },
            }}
          />
        </Box>
      </Grid>

      <div
        style={{
          height: props.tableHeight,
          width: "98%",
          fontFamily: "Muli",
          backgroundColor: "white",
          marginTop: 10,
        }}
      >
        <DataGrid
          initialState={{
            pagination: {
              pageSize: 25,
            },
          }}
          pagination
          paginationMode="server"
          rows={teamMemberTable ? teamMemberTable : []}
          columns={columns}
          headerHeight={40}
          density={"compact"}
          showColumnRightBorder
          sx={{
            [`& .${gridClasses.row}`]: {
              color: "#0674b9",
              overflow: "hidden",
              bgcolor: (theme) =>
                theme.palette.mode === "light" ? grey[0] : grey[500],
            },

            [`& .${gridClasses.columnHeader}`]: {
              bgcolor: "rgb(172 201 224)",
              color: "rgb(17 24 84)",
            },
            [`& .${gridClasses.columnHeaderTitle}`]: {
              fontFamily: "Muli",
              fontWeight: "bold",
            },

            [`& .${gridClasses.footerContainer}`]: {
              bgcolor: "rgb(216 235 247)",
              minHeight: "30px",
            },
            fontFamily: "Muli",
            fontSize: "13px",
          }}
          disableColumnFilter
          getRowId={(row) => row.Id}
          rowCount={dataLength}
          hideFooter={props.paging}
          onPageChange={(newPage) => {
            if (pageSize > dataLength) {
              setPageNumber(1);
            } else setPageNumber(newPage + 1);
          }}
          onPageSizeChange={(newPage) => {
            setPageSize(newPage);
          }}
          pageSize={pageSize}
          //   loading={props.loading}
          components={{
            NoRowsOverlay: () => (
              <Stack className={classes["EmptyMessage"]}>
                {loadingData ? <CircularProgress /> : <></>}
                {loadingData
                  ? "Loading..."
                  : "No Assigned Projects to display."}
              </Stack>
            ),
          }}
        />
      </div>

      <Menu
        id="more-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableScrollLock
      >
        {rowData?.row.Status === "Invited" ? (
          <MenuItem
            onClick={() => {
              changeTMSubrojectStatus(rowData.id, INVITED);
              setPreInvite(true);
              setModify(!modify);
              handleClose();
            }}
            style={{
              fontFamily: "Muli",
              fontSize: "13px",
              color: "#444",
              width: 150,
              display: "block",
            }}
          >
            Re-Invite
          </MenuItem>
        ) : (
          <></>
        )}
        {rowData?.row.Status === "Active" ? (
          <MenuItem
            onClick={() => {
              changeTMSubrojectStatus(rowData.id, SUSPENDED);
              setModify(!modify);
              handleClose();
            }}
            style={{
              fontFamily: "Muli",
              fontSize: "13px",
              color: "#444",
              display: "block",
            }}
          >
            Suspend
          </MenuItem>
        ) : (
          <></>
        )}
      
        {rowData?.row.Status === "Suspended" ? (
          <MenuItem
            onClick={() => {
              changeTMSubrojectStatus(rowData.id, INVITED);
              setPreInvite(true);
              setModify(!modify);
              handleClose();
            }}
            style={{
              fontFamily: "Muli",
              fontSize: "13px",
              color: "#444",
              width: 150,
              display: "block",
            }}
          >
            Re-Invite
          </MenuItem>
        ) : (
          <></>
        )}
      </Menu>
    </Grid>
  );
}
