import React, { useState, useEffect } from "react";
import classes from "./TeamMember.module.css";
import {
  Grid,
  Button,
  Tooltip,
  Modal,
  Box,
  TextField,
  Select,
  FormControl,
  FormHelperText,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { MenuItem } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import * as SaasServices from "../../../../Services/saas-services.proxy";
import * as constFile from "../../../../../saasApiConst";
import fileIconM from "../../../../Assets/Images/Table/file.png";
import fileIconSub from "../../../../Assets/Images/Table/FileSub.png";
import infoTip from "../../../../../Assets/Images/form/info.png";
import * as teamMemberServices from "../../../../Services/team-member-services.proxy";

const getTMbyId = teamMemberServices.getTMbyId;

const getTeamMemberInputData = SaasServices.getTeamMemberInputData;
const uploadTeamMemberDocument = SaasServices.uploadTeamMemberDocument;
const deleteTeamMemberDocument = SaasServices.deleteTeamMemberDocument;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "30%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  height: "68%",
  width: "58%",
  overflow: "scroll",
};

const BlueTip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
          color: #101650;
          background-color: rgba(6, 116, 185, 0.3);
          font-size: 13px;
         
       
      `);

const TeamMemberModal = (props) => {
  const AccountType = localStorage.getItem("AccountType");

  const [open, setOpen] = React.useState(false);

  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");

  const [nameError, setNameError] = useState(false);
  const [nameErrorMessage, setNameErrorMessage] = useState(false);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState(false);

  const [role, setRole] = useState([]);
  const [roleError, setRoleError] = useState(false);

  const [project, setProject] = useState();
  const [phase, setPhase] = useState();

  const [subProject, setSubProject] = useState();
  const [status, setStatus] = useState("");
  const [statusName, setStatusName] = useState("");
  const [creator, setCreator] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [roles, setRoles] = useState([]);
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [fileId, setFileId] = useState(null);
  const [previousFileId, setPreviousFileId] = useState(null);
  const [fileIds, setFileIds] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [allFieldDisabled, setAllFieldDisabled] = useState(false);
  const [code, setCode] = useState(null);
  const [UserId, setUserId] = useState(null);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const openTip = () => setTooltipIsOpen(true);
  const closeTip = () => setTooltipIsOpen(false);

  // useEffect(() => {
  //   if (props.teamMemberId) {
  //     setUserId(props.teamMemberId);
  //   } else {
  //     setUserId(null);
  //   }
  // }, [props, UserId]);

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (props.openModal) {
      handleOpen(true);
    }
  }, [props.openModal]);
  useEffect(() => {
    if (UserId) {
      setAllFieldDisabled(true);
      getTMbyId(UserId).then((x) => {
        setName(x.Name);
        setEmail(x.Email);
        setRole(x.UserGroupPermissions);
        setStatus(x.Status);
        // setRoles(x.UserGroupPermissions)
        // setFileId(x.Name);
        setCreatedDate(x.CreationTime);
      });
    }
  }, [UserId]);
  useEffect(() => {
    getTeamMemberInputData(null).then((x) => {
      if (!UserId) {
        setCode(x.TeamMember.Code);
      }
      setRoles(x.GroupPermission);
    });
  }, [UserId]);
  useEffect(() => {
    if (
      !name ||
      !email ||
      role.length === 0 ||
      nameError ||
      emailError ||
      roleError
    )
      setIsDisabled(true);
    else setIsDisabled(false);
  });

  const handleClose = () => {
    setName("");
    setEmail("");
    setRole([]);
    setProject([]);
    setSubProject([]);
    setPhase([]);
    setCreator("");
    setCreatedDate("");
    setStatus("");
    setStatusName("");
    setFiles([]);
    setFileIds([]);
    setNameError(false);
    setEmailError(false);
    setRoleError(false);
    setAllFieldDisabled(false);
    setUserId(null);
    setOpen(false);
    props.setOpenModal(false);
    props.setTeamMemberId(null);
  };

  const handleRole = (event) => {
    const {
      target: { value },
    } = event;
    setRole(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleFileChange = (e) => {
    if (previousFileId) {
      deleteTeamMemberDocument(previousFileId);
    }
    if (e.target.files[0]) {
      if (fileId) setPreviousFileId(fileId);
      uploadTeamMemberDocument(e.target.files[0]).then((x) => {
        setFile(x);
        setFileId(x.FileId);
      });
    }
  };

  useEffect(() => {
    if (file) {
      let tempFile = null;
      tempFile = file;
      files.push(tempFile);
    }
  }, [file]);

  useEffect(() => {
    if (fileId) {
      let tempFileId = null;
      tempFileId = fileId;
      let fileData = { FileId: tempFileId };
      fileIds.push(fileData);
    }
  }, [fileId]);

  const deleteItem = (item) => {
    const index = files.indexOf(item);
    if (index > -1) {
      files.splice(index, 1);
      fileIds.splice(index, 1); // 2nd parameter means remove one item only
    }
  };

  const data = {
    Id: UserId,
    Code: UserId ? "" : code,
    Name: name,
    Email: email,
    ProfilePicture: fileId,
    Documents: fileIds,
    GroupPermissions: role,
  };

  const save = () => {
    let temp = data;
    let arr = props.teamMembers;
    arr.push(temp);
    props.setTeamMembers(arr);
    props.forceUpdate();
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container direction={"column"}>
          <Grid
            item
            alignSelf={"flex-end"}
            paddingRight="20.5%"
            paddingTop="70px"
          >
            <CloseIcon
              fontSize="large"
              onClick={handleClose}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              style={{ color: "#fff" }}
            />
          </Grid>
          <Grid item>
            <Box sx={style}>
              <Grid container alignItems="center" direction="column" gap={1}>
                <Grid item className={classes.newTeamMember} paddingTop={1}>
                  {UserId ? "Edit Team Member" : "New Team Member"}
                </Grid>
                <Grid
                  item
                  className={classes["Line-402"]}
                  alignSelf={"center"}
                  justifySelf={"center"}
                  width="90%"
                ></Grid>
                <Grid
                  item
                  container
                  width="90%"
                  gap={1}
                  alignSelf={"center"}
                  justifySelf={"center"}
                >
                  <Grid
                    item
                    className={classes["TM-Information"]}
                    alignSelf="flex-start"
                    xs={12}
                  >
                    Details
                  </Grid>
                  {AccountType === "1" ? (
                    <Grid
                      item
                      className={classes["Line-403-Main"]}
                      width="55px"
                    />
                  ) : (
                    <Grid
                      item
                      className={classes["Line-403-Sub"]}
                      width="55px"
                    />
                  )}
                  <Grid item container direction={"row"} spacing={2}>
                    <Grid
                      item
                      container
                      direction={"column"}
                      md={6}
                      xs={12}
                      spacing={1}
                    >
                      <Grid item container direction={"column"}>
                        <Grid item className={classes["field-label"]}>
                          *Name
                        </Grid>
                        <Grid item>
                          <TextField
                            disabled={allFieldDisabled}
                            fullWidth
                            value={name}
                            error={nameError}
                            helperText={nameError ? nameErrorMessage : ""}
                            id="outlined-name"
                            size="small"
                            InputProps={{ style: { height: "30px" } }}
                            onBlur={(e) => {
                              if (!e.target.value) {
                                setNameError(true);
                                setNameErrorMessage("Enter a name");
                              } else if (e.target.value.length > 50) {
                                setNameError(true);
                                setNameErrorMessage(
                                  " Name must be less than 50 char"
                                );
                              }
                            }}
                            onChange={(e) => {
                              if (!e.target.value) {
                                setNameError(true);
                                setNameErrorMessage("Enter a name");
                              } else if (e.target.value.length > 50) {
                                setNameError(true);
                                setNameErrorMessage(
                                  " Name must be less than 50 char"
                                );
                              } else setNameError(false);
                              setName(e.target.value);
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container direction={"column"}>
                        <Grid item className={classes["field-label"]}>
                          *Email
                        </Grid>
                        <Grid item>
                          <TextField
                            disabled={allFieldDisabled}
                            fullWidth
                            value={email}
                            error={emailError}
                            helperText={emailError ? emailErrorMessage : ""}
                            id="outlined-name"
                            size="small"
                            InputProps={{ style: { height: "30px" } }}
                            onBlur={(e) => {
                              if (!e.target.value) {
                                setEmailError(true);
                                setEmailErrorMessage("Enter an email address");
                              }
                            }}
                            onChange={(e) => {
                              if (!e.target.value) {
                                setEmailError(true);
                                setEmailErrorMessage("Enter an email address");
                              } else if (e.target.value.length > 64) {
                                setEmailError(true);
                                setEmailErrorMessage(
                                  "Email must be less than 64 char"
                                );
                              } else if (
                                !e.target.value.match(
                                  /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/
                                )
                              ) {
                                setEmailError(true);
                                setEmailErrorMessage(
                                  "Enter a valid email address"
                                );
                              } else setEmailError(false);
                              setEmail(e.target.value);
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container direction={"column"}>
                        <Grid item className={classes["field-label"]}>
                          *Role
                        </Grid>
                        <Grid item>
                          {props.teamMemberId ? (
                            <TextField
                              disabled={allFieldDisabled}
                              fullWidth
                              value={role}
                              size="small"
                              InputProps={{ style: { height: "30px" } }}
                            />
                          ) : (
                            <FormControl fullWidth error={roleError}>
                              <Select
                                value={role}
                                id="outlined-name"
                                size="small"
                                sx={{
                                  height: "30px",
                                  textOverflow: "ellipsis",
                                  width: "25vw",
                                }}
                                multiple
                                // inputProps={{ maxLength: 3 }}
                                onBlur={(e) => {
                                  if (e.target.value.length === 0)
                                    setRoleError(true);
                                }}
                                onChange={(e) => {
                                  setRoleError(false);
                                  handleRole(e);
                                }}
                              >
                                {roles.map((r) => (
                                  <MenuItem
                                    value={r.Id}
                                    key={r.Id}
                                    disabled={
                                      (props.UserRole === "Project Manager" &&
                                        r.Name === "Admin") ||
                                      (props.UserRole ===
                                        "Engineering Manager" &&
                                        (r.Name === "Admin" ||
                                          r.Name === "Project Manager")) ||
                                      (props.UserRole === "Team Leader" &&
                                        (r.Name === "Admin" ||
                                          r.Name === "Project Manager" ||
                                          r.Name === "Engineering Manager")) ||
                                      (props.UserRole !== "Admin" &&
                                        (r.Name !== "Project Manager" ||
                                          r.Name !== "Engineering Manager" ||
                                          r.Name !== "Team Leader" ||
                                          r.Name !== "Document Controller"))
                                        ? true
                                        : false
                                    }
                                  >
                                    {r.Name}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>
                                {roleError ? "Choose role" : ""}
                              </FormHelperText>
                            </FormControl>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item container direction={"column"}>
                        <Grid item className={classes["field-label"]}>
                          Project
                        </Grid>
                        <Grid item>
                          <TextField
                            disabled
                            fullWidth
                            value={props.projectName}
                            size="small"
                            InputProps={{ style: { height: "30px" } }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container direction={"column"}>
                        <Grid item className={classes["field-label"]}>
                          Phase
                        </Grid>
                        <Grid item>
                          <TextField
                            disabled
                            fullWidth
                            value={props.phaseName}
                            size="small"
                            InputProps={{ style: { height: "30px" } }}
                          />
                        </Grid>
                      </Grid>

                      <Grid item container direction={"column"}>
                        <Grid item className={classes["field-label"]}>
                          Subproject
                        </Grid>
                        <Grid item>
                          <TextField
                            disabled
                            fullWidth
                            value={props.subProjectName}
                            size="small"
                            InputProps={{ style: { height: "30px" } }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      direction={"column"}
                      md={6}
                      xs={12}
                      spacing={1}
                    >
                      <Grid item container direction="row" spacing={1.5}>
                        <Grid item container direction={"column"} xs={12}>
                          <Grid item className={classes["field-label2"]}>
                            Status
                          </Grid>
                          <Grid item>
                            <TextField
                              fullWidth
                              value={status}
                              disabled
                              className={classes["Field-input"]}
                              id="outlined-name"
                              size="small"
                              InputProps={{ style: { height: "30px" } }}
                            />
                          </Grid>
                        </Grid>
                        <Grid item container direction="row" spacing={2} xs={6}>
                          <Grid
                            item
                            xs={12}
                            container
                            alignItems="center"
                            marginTop="0.8%"
                          >
                            <Grid item xs={12}>
                              <Button
                                component="label"
                                style={{
                                  width: "100%",
                                  marginTop: "18px",
                                  textTransform: "none",
                                  fontFamily: "Muli",
                                  fontSize: "12px",
                                  lineHeight: 1.45,
                                  textAlign: "left",
                                  color: "#101650",
                                  backgroundColor: "white",
                                  borderRadius: "6px",
                                  border: "solid 1px #b8b8b8",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  height: "30px",
                                }}
                              >
                                <FileUploadOutlinedIcon
                                  htmlColor="#529bd7"
                                  style={{
                                    alignSelf: "center",
                                    paddingRight: "5%",
                                  }}
                                />
                                <span style={{ alignSelf: "center" }}>
                                  Upload Document
                                </span>
                                <input
                                  type="file"
                                  hidden
                                  disabled={allFieldDisabled}
                                  onChange={handleFileChange}
                                />
                              </Button>
                            </Grid>
                            <Grid item xs={1} paddingBottom={1}>
                              {files.length > 0 ? (
                                <Grid
                                  item
                                  marginRight={1}
                                  xs={1}
                                  paddingTop={3}
                                >
                                  <IconButton
                                    edge="end"
                                    onClick={openTip}
                                    onMouseOver={openTip}
                                    onMouseLeave={closeTip}
                                  >
                                    <BlueTip
                                      width="100px"
                                      open={tooltipIsOpen}
                                      title={"Click on file to download"}
                                      placement="right"
                                    >
                                      <img
                                        width={"15px"}
                                        src={infoTip}
                                        alt={"close"}
                                      ></img>
                                    </BlueTip>
                                  </IconButton>
                                </Grid>
                              ) : (
                                <></>
                              )}
                            </Grid>
                            {files?.map((item) => (
                              <Grid
                                container
                                item
                                xs={12}
                                key={item.FileId}
                                value={item.FileId}
                                paddingTop={1}
                              >
                                <Grid item xs={1.7} paddingLeft={1}>
                                  <img
                                    src={
                                      AccountType === "1"
                                        ? fileIconM
                                        : fileIconSub
                                    }
                                    alt="file"
                                    width={15}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={8}
                                  onMouseOver={(e) => {
                                    e.target.style.color = "grey";
                                    e.target.style.cursor = "pointer";
                                  }}
                                  onMouseLeave={(e) => {
                                    e.target.style.color = "black";
                                  }}
                                  onClick={() => {
                                    window.open(item.URL);
                                  }}
                                >
                                  <span>{item.DisplayName.slice(0, 10)}</span>
                                </Grid>
                                <Grid
                                  item
                                  xs={1}
                                  paddingLeft={2}
                                  paddingTop={0.5}
                                  onMouseOver={(e) => {
                                    e.target.style.cursor = "pointer";
                                  }}
                                  onClick={() => {
                                    deleteItem(item);
                                    setFile(null);
                                    setFileId(null);
                                    forceUpdate();
                                  }}
                                >
                                  <CloseIcon fontSize="small" />
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                        <Grid item container direction={"column"} xs={6}>
                          <Grid item className={classes["field-label2"]}>
                            Company Name
                          </Grid>
                          <Grid item>
                            <TextField
                              fullWidth
                              value={companyName}
                              disabled
                              className={classes["Field-input"]}
                              id="outlined-name"
                              size="small"
                              InputProps={{ style: { height: "30px" } }}
                            />
                          </Grid>
                        </Grid>
                        <Grid item container direction={"column"} xs={6}>
                          <Grid item className={classes["field-label2"]}>
                            Creator
                          </Grid>
                          <Grid item>
                            <TextField
                              fullWidth
                              value={creator}
                              disabled
                              className={classes["Field-input"]}
                              id="outlined-name"
                              size="small"
                              InputProps={{ style: { height: "30px" } }}
                            />
                          </Grid>
                        </Grid>
                        <Grid item container direction={"column"} xs={6}>
                          <Grid item className={classes["field-label2"]}>
                            Created Date
                          </Grid>
                          <Grid item>
                            <TextField
                              fullWidth
                              value={createdDate}
                              disabled
                              className={classes["Field-input"]}
                              id="outlined-name"
                              size="small"
                              InputProps={{ style: { height: "30px" } }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                justifyContent={"flex-end"}
                direction="row"
                alignItems={"flex-end"}
                paddingRight={"5%"}
                paddingTop={5}
                paddingBottom={3}
              >
                <Grid item paddingRight={2}>
                  <button
                    className={classes.Cancel}
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                      e.target.style.backgroundColor = "#f5f5f5";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#fff";
                    }}
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </button>
                </Grid>
                <Grid item>
                  <button
                    disabled={isDisabled}
                    className={classes.Invite}
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                      e.target.style.backgroundColor = "#242B64";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#0b5cab";
                    }}
                    style={{
                      backgroundColor: isDisabled ? "#bcbcbc" : "#0b5cab",
                      fontFamily: "Muli",
                      textTransform: "none",
                    }}
                    onClick={() => {
                      save();
                      setTimeout(() => {
                        handleClose();
                      }, [2500]);
                    }}
                  >
                    {UserId ? "Save" : "Invite"}
                  </button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};
export default TeamMemberModal;
