import React, { useState } from "react";
import { Grid } from "@mui/material";
import classes from "../../../Assets/Styles/Subscription.module.css";
import PlanDetails from "../ManageSubscription/PlansDetails";
export default function ManageSubscription(props) {
  const subscribedPlan = localStorage.getItem("companyPlanId");
  const subscribedPlanStartDate = localStorage.getItem("StartDate");
  const subscribedPlanEndDate = localStorage.getItem("EndDate");
  const subscribedPlanManu = localStorage.getItem("companyPlanIdManu");
  const subscribedPlanStartDateManu = localStorage.getItem("StartDateManuPlan");
  const subscribedPlanEndDateManu = localStorage.getItem("EndDateManuPlan");
  return (
    <Grid
      container
      item
      marginTop={1}
      marginLeft={{ sm: 2, xs: 0 }}
      gap={1.5}
      direction={"column"}
      paddingBottom={8}
    >
      <Grid item md={12}>
        <div className={classes["Change-Plan"]}>Change Plan</div>
      </Grid>
      {subscribedPlan || subscribedPlanManu ? (
        <Grid
          container
          direction={"row"}
          className={classes["helpText"]}
          md={9}
          xs={9}
        >
          <Grid item xs={3.7}>
            You are currently subscribed to:
          </Grid>

          <Grid item container direction={"row"} xs={7.5}>
            {subscribedPlan ? (
              <Grid item container direction={"row"} xs={12} gap={0.5}>
                <Grid
                  item
                  width={5}
                  height={5}
                  bgcolor={"#101650"}
                  marginTop={1}
                ></Grid>
                <div style={{ fontWeight: "bold" }}>Engineering {subscribedPlan} plan</div>
                for the following duration
                <div className={classes["planDate"]}>
                  {new Date(subscribedPlanStartDate).toLocaleDateString() +
                    "-" +
                    new Date(subscribedPlanEndDate).toLocaleDateString()}
                </div>
              </Grid>
            ) : (
              ""
            )}
            {subscribedPlanManu ? (
              <Grid item container direction={"row"} xs={12} gap={0.5}>
                <Grid
                  item
                  width={5}
                  height={5}
                  bgcolor={"#101650"}
                  marginTop={1}
                ></Grid>
                <div style={{ fontWeight: "bold" }}>Manufacturing {subscribedPlanManu} plan</div>
                for the following duration
                <div className={classes["planDate"]}>
                  {new Date(subscribedPlanStartDateManu).toLocaleDateString() +
                    "-" +
                    new Date(subscribedPlanEndDateManu).toLocaleDateString()}
                </div>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      ) : (
        ""
      )}

      <Grid item md={12}>
        <div className={classes["helpText"]}>
          You can upgrade or change to another plan, change your plan's billing
          cycle or cancel your plan below.
        </div>
      </Grid>
      <Grid item md={12}>
        <PlanDetails
          setShowContactUsPricing={props.setShowContactUsPricing}
          subscribedPlanEndDate={subscribedPlanEndDate}
        />
      </Grid>
    </Grid>
  );
}
