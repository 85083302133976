import React, { useEffect, useState } from "react";
import { Grid, TextField } from "@mui/material";
import classes from "../../../Assets/Styles/Activities.module.css";
import TMIcon from "../../../Assets/Images/Table/TmRevised.png";
import SubIcon from "../../../../Assets/Images/Navbar/subIcon.png";
import MainIcon from "../../../../Assets/Images/Navbar/mainIcon.png";
import DeleteCommentModal from "./DeleteCommentModal";
import * as commentServices from "../../../Services/activities-services.proxy";
const getCommentByActivity = commentServices.getCommentByActivity;
const addCommentOnActivity = commentServices.addCommentOnActivity;
const deleteComment = commentServices.deleteComment;
const UpdateCommentOnActivity = commentServices.UpdateCommentOnActivity;

const CommentSection = (props) => {
  const [addComment, setAddComment] = useState(null);
  const [editComment, setEditComment] = useState(null);
  const [open, setOpen] = useState(false);
  const [chosenId, setchosenId] = useState(null);

  const profile = null;
  const [commentText, setCommentText] = useState(null);
  const [commentTextError, setCommentTextError] = useState(null);
  const [commentTextErrorMessage, setCommentTextErrorMessage] = useState(null);
  const [commentEditText, setCommentEditText] = useState(null);
  const [commentEditTextError, setCommentEditTextError] = useState(null);
  const [commentEditTextErrorMessage, setCommentEditTextErrorMessage] =
    useState(null);
  const [comments, setcomments] = useState([]);
  const [modify, setModify] = useState(false);
  const [loadings, setLoadings] = useState(false);
  useEffect(() => {
    getCommentByActivity(props.activityId).then((x) => {
      setcomments(x);
    });
  }, [modify, props.activityId]);
  useEffect(() => {
    if (props.revised) {
      setcomments([]);
    }
  }, [props.revised]);
  const addTheComment = () => {
    setLoadings(true);
    addCommentOnActivity(props.activityId, commentText).then((x) => {
      setModify(!modify);
      setAddComment(false);
      setCommentText("");
      setLoadings(false);
    });
  };
  const deleteTheComment = () => {
    setLoadings(true);
    deleteComment(chosenId).then((x) => {
      setModify(!modify);
      setLoadings(false);
    });
  };
  const UpdateTheComment = () => {
    setLoadings(true);
    UpdateCommentOnActivity(chosenId, props.activityId, commentEditText).then(
      (x) => {
        setModify(!modify);
        setEditComment(false);
        setCommentEditText("");
        setchosenId(null);
        setLoadings(false);
      }
    );
  };
  return (
    <Grid container paddingBottom={5} paddingTop={2} direction={"column"}>
      <Grid item className={classes["Activity-Details"]}>
        Comments
      </Grid>
      <Grid container paddingTop={4}>
        <Grid item xs={2} md={0.7}>
          <img
            src={
              profile
                ? profile
                : localStorage.getItem("isTeamMember") === "true"
                ? TMIcon
                : localStorage.getItem("AccountType") === "1"
                ? MainIcon
                : SubIcon
            }
            alt={"Icon"}
          />
        </Grid>
        {addComment ? (
          <>
            <Grid item xs={7.8}>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: false,
                  className: classes.Addcomment,
                }}
                multiline
                InputProps={{ className: classes.DescriptionfieldDesign }}
                size="small"
                rows={6}
                label={commentText ? null : "Add Comment..."}
                value={commentText}
                variant="outlined"
                error={commentTextError}
                helperText={commentTextErrorMessage}
                onBlur={(e) => {
                  if (!e.target.value) {
                    setCommentTextError(true);
                    setCommentTextErrorMessage("Enter a Comment");
                  }
                }}
                onChange={(e) => {
                  setCommentText(e.target.value);
                  if (e.target.value.length > 500) {
                    setCommentTextError(true);
                    setCommentTextErrorMessage(
                      "Comment must be less than 500 char"
                    );
                  } else {
                    setCommentTextError(false);
                    setCommentTextErrorMessage(null);
                  }
                }}
              />
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              gap={2}
              paddingTop={1}
              paddingLeft={7.5}
            >
              <Grid item>
                <button
                  disabled={
                    !commentText || commentTextError || loadings ? true : false
                  }
                  className={classes["SaveButton"]}
                  onClick={() => {
                    addTheComment();
                  }}
                >
                  {loadings ? "Saving..." : "Save"}
                </button>
              </Grid>
              <Grid
                item
                className={classes["BackButton"]}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={() => {
                  setAddComment(false);
                }}
              >
                Cancel
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid
            item
            className={classes["Rectangle-1464"]}
            xs={7.8}
            height={"50px"}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            onClick={() => {
              setAddComment(true);
            }}
          >
            Add a comment...
          </Grid>
        )}
      </Grid>
      {comments.map((item) => (
        <Grid container paddingTop={4} key={item.Id}>
          <Grid item xs={2} md={0.7}>
            <img
              style={{ width: 50, borderRadius: 50 }}
              src={
                item.User.ProfilePicture
                  ? item.User.ProfilePicture.URL
                  : item.User.IsMainContractor === true
                  ? MainIcon
                  : item.User.IsTeamMember === true
                  ? TMIcon
                  : SubIcon
              }
              alt={"Icon"}
            />
          </Grid>
          {editComment && chosenId === item.Id ? (
            <>
              <Grid item xs={7.8}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: false,
                    className: classes.Addcomment,
                  }}
                  multiline
                  InputProps={{ className: classes.DescriptionfieldDesign }}
                  size="small"
                  rows={6}
                  label={commentEditText ? null : "Add Comment..."}
                  value={commentEditText}
                  variant="outlined"
                  error={commentEditTextError}
                  helperText={commentEditTextErrorMessage}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setCommentEditTextError(true);
                      setCommentEditTextErrorMessage("Enter a Comment");
                    }
                  }}
                  onChange={(e) => {
                    setCommentEditText(e.target.value);
                    if (e.target.value.length > 500) {
                      setCommentEditTextError(true);
                      setCommentEditTextErrorMessage(
                        "Comment must be less than 500 char"
                      );
                    } else {
                      setCommentEditTextError(false);
                      setCommentEditTextErrorMessage(null);
                    }
                  }}
                />
              </Grid>
              <Grid
                item
                container
                direction={"row"}
                gap={2}
                paddingTop={1}
                paddingLeft={7.5}
              >
                <Grid item>
                  <button
                    disabled={
                      !commentEditText || commentEditTextError || loadings
                        ? true
                        : false
                    }
                    className={classes["SaveButton"]}
                    onClick={() => {
                      UpdateTheComment();
                    }}
                  >
                    {loadings ? "Saving..." : "Save"}
                  </button>
                </Grid>
                <Grid
                  item
                  className={classes["BackButton"]}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={() => {
                    setEditComment(false);
                  }}
                >
                  Cancel
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid item container xs={7.8}>
              <Grid
                item
                container
                direction={"row"}
                className={classes["commentorName"]}
              >
                {item.User.Name}
                <Grid
                  item
                  className={classes["commentDate"]}
                  paddingLeft={{ md: 3, xs: 0 }}
                  paddingTop={0.5}
                >
                  {new Date(item.CreationTime).toString().slice(0, 21)}
                </Grid>
              </Grid>
              <Grid item className={classes["CommentDesc"]} xs={12}>
                {item.Content}
              </Grid>
              {item.User.Id === localStorage.getItem("UserId") ? (
                <Grid item container direction={"row"} paddingTop={1}>
                  <Grid
                    item
                    className={classes["Edit-Delete"]}
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                    onClick={() => {
                      setchosenId(item.Id);
                      setEditComment(true);
                      setCommentEditText(item.Content);
                    }}
                  >
                    Edit .
                  </Grid>
                  <Grid
                    item
                    className={classes["Edit-Delete"]}
                    paddingLeft={0.5}
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                    onClick={() => {
                      setchosenId(item.Id);
                      setOpen(true);
                    }}
                  >
                    Delete
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          )}
        </Grid>
      ))}
      <DeleteCommentModal
        openModal={open}
        setOpenModal={setOpen}
        deleteTheComment={deleteTheComment}
        loadings={loadings}
      />
    </Grid>
  );
};

export default CommentSection;
