import React, { useEffect, useState } from "react";
import { Grid, Box, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import mail from "../../../Assets/Images/main/email.png";
import sent from "../../../Assets/Images/main/emailSent.png";
import ReactInputVerificationCode from "react-input-verification-code";
import "../../../Assets/Styles/Verify.css";
import axios from "axios";
import * as constFile from "../../../pileCubesConst";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const VERIFY_CODE = constFile.VERIFY_CODE;

export default function Test({ setLoading, setIsVerified, setToken }) {
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [check, setCheck] = useState(true);
  const [codeVerified, setcodeVerified] = useState(false);
  const [showResend, SetShowResend] = useState(false);
  const token = localStorage.getItem("accessToken");

  const IsUserVerified = useSelector(
    (state) => state.userData.IsUserVerified
  );
  const IsUserSubscribed = useSelector(
    (state) => state.userData.IsUserSubscribed
  );
  const IsGuest = useSelector(
    (state) => state.userData.IsGuest
  );

  useEffect(() => {
    if (codeVerified) {
      setTimeout(() => {
        setToken(localStorage.getItem("accessToken"));
        localStorage.setItem("isVerified", "true");
        localStorage.setItem("isSubscribed", "false");
        setIsVerified(localStorage.getItem("isVerified"));
      }, 1900);
      setTimeout(() => {
        if (IsGuest === true) navigate("/saas/admin");
        else navigate("/pricing");
      }, 2000);
    }
  }, [codeVerified, navigate]);

  const resendCode = () => {
    axios
      .post(
        `${process.env.REACT_APP_URL}/email/resend-verification-code`,
        {
          //...data
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        return (res);
      })
      .catch((err) => {
        return (err);
      });
  };

  useEffect(() => {
    if (code) {
      checkCode(code);
    }
  }, [code]);

  const checkCode = (code) => {
    axios
      .post(
        `${process.env.REACT_APP_URL + VERIFY_CODE}`,
        {
          Code: code,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data === true) {
          setcodeVerified(true);
        } else {
          setCheck(false);
        }
      })
  };
  return (
    <Box sx={{ flexGrow: 1 }} className="RegBox" paddingBottom={20}>
      <Grid container direction={"column"}>
        <Grid item container direction={"row-reverse"}>
          <IconButton
            onClick={(e) => {
              navigate("/");
              localStorage.clear();
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Grid>
        {codeVerified ? (
          <Grid
            item
            container
            direction={"column"}
            alignItems={"center"}
            marginTop={1}
          >
            <Grid item>
              <img src={sent} alt="sent" />
            </Grid>
            <Grid item marginTop="15%">
              <div
                style={{
                  fontFamily: "Muli",
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "left",
                  color: "#101650",
                  width: 255,
                }}
              >
                Your email is now verified
              </div>
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            container
            direction={"column"}
            alignItems={"center"}
            padding={2}
          >
            <Grid item>
              <img src={mail} alt="mail" width="80%" />
            </Grid>
            <Grid
              item
              container
              direction={"column"}
              alignItems={"center"}
              padding={1}
            >
              <Grid item fontSize={{ md: "25px", xs: "20px" }}>
                <div
                  style={{
                    fontFamily: "Muli",
                    fontWeight: "bold",
                    lineHeight: 1.44,
                    textAlign: "left",
                    color: "#101650",
                  }}
                >
                  Email Verification
                </div>
              </Grid>
              <Grid item>
                <div
                  style={{
                    width: 235,
                    fontFamily: "Muli",
                    fontSize: 15,
                    lineHeight: 1,
                    textAlign: "center",
                    color: "#101650",
                  }}
                >
                  Enter the code sent to your email
                </div>
              </Grid>
              <Grid item>
                {check ? (
                  <div className="custom-styles">
                    <ReactInputVerificationCode
                      placeholder=""
                      length={6}
                      value={code}
                      onCompleted={(e) => setCode(e)}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      diplay: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className="custom-styles1">
                      <ReactInputVerificationCode
                        placeholder=""
                        length={6}
                        value={code}
                        onCompleted={(e) => checkCode(e)}
                      />
                    </div>
                    <div className="notMatch">
                      Code does not match, please try again.
                    </div>
                  </div>
                )}
              </Grid>
              <Grid item>
                <div
                  style={{
                    fontFamily: "Muli",
                    fontSize: 11,
                    lineHeight: 1.45,
                    textAlign: "left",
                    color: "#101650",
                    marginTop: "15%",
                  }}
                >
                  Didn't receive the Code?
                  <button
                    onMouseOver={(e) => (e.target.style.cursor = "pointer")}
                    style={{
                      borderRadius: 5,
                      border: " #fff",

                      fontFamily: "Muli",
                      fontSize: 11,
                      backgroundColor: "#fff",
                      color: "#0674b9",
                      textTransform: "none",
                    }}
                    onClick={(e) => {
                      resendCode();
                      SetShowResend(true);
                    }}
                  >
                    Resend Code
                  </button>
                </div>
                {showResend ? (
                  <span className="resendCode">
                    A code has been sent to your email.
                  </span>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
