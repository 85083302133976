import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import "../Assets/Styles/home.css";
import contyellow from "../../SAAS/Assets/Images/Notifications/contyellow.png";
import contblue from "../../SAAS/Assets/Images/Notifications/contblue.png";
import contgreen from "../../SAAS/Assets/Images/Notifications/contgreen.png";
import { userDataActions } from "../../store/index";
import { useDispatch } from "react-redux";

import * as userServices from "../../Services/user-data-service.proxy";
import { useNavigate } from "react-router-dom";
const getUserInvitationList = userServices.getUserInvitationList;
const UserAcceptInvitation = userServices.UserAcceptInvitation;

const styles = {
  yourNotifications: {
    fontFamily: "Muli",
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "left",
    color: "#0674b9",
  },
  title: {
    fontFamily: "Muli",
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "left",
    color: "#707070",
  },
  msg: {
    fontFamily: "Muli",
    fontSize: 14,
    lineheight: 1.36,

    textAlign: "left",
    color: "#707070",
  },
  date: {
    fontSize: 11,
    fontFamily: "Muli",
    fontStyle: "italic",
    textAlign: "left",
    color: "#0674b9",
  },
  Accept: {
    width: "fit-content",
    padding: 5,
    backgroundColor: "rgba(45, 164, 208, 0.7)",
    fontFamily: "Muli",
    fonSize: "12px",
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
    border: 0,
  },
  Accepted: {
    fontFamily: "Muli",
    fontSize: "12px",

    fontStyle: "italic",
    textAlign: "center",
    color: "#101650",
  },
};

export default function Invitations({ setRegister, setNaved }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    let apiTest = true;
    if (localStorage.getItem("accessToken") && apiTest) {
      if (localStorage.getItem("isVerified") === "false") {
        if (localStorage.getItem("isTeamMember") === "true")
          navigate("/teamMemberRegistration");
        else {
          if (localStorage.getItem("AccountType") === "1") navigate("/main");
          if (localStorage.getItem("AccountType") === "2") navigate("/sub");
        }
      }
    } else navigate("/login");
    return () => {
      apiTest = false;
    };
  }, [navigate]);

  useEffect(() => {
    setRegister(true);
  }, [setRegister]);

  useEffect(() => {
    setNaved(false);
  }, [setNaved]);

  const [invitationsList, setInvitationsList] = useState([]);
  const [modify, setModify] = useState(false);
  const [chosenId, setChosenId] = useState();

  const [loaddingButton, setLoadingButton] = useState(false);
  useEffect(() => {
    getUserInvitationList().then((x) => {
      setInvitationsList(x);
    });
  }, [modify]);
  const acceptInvitation = (PhaseId, SubProjectId, ProjectId) => {
    setLoadingButton(true);
    UserAcceptInvitation(PhaseId, SubProjectId).then((x) => {
      setModify(!modify);
      setLoadingButton(false);
      dispatch(userDataActions.DecrementInvitationsCounter())
      setTimeout(() => {
        if (
          localStorage.getItem("AccountType") === "1" &&
          localStorage.getItem("isTeamMember") === "false"
        ) {
          navigate(`/saas/projects/dashboard?id=${ProjectId}`);
        } else if (SubProjectId) {
          navigate(`/saas/projects/dashboard?id=${ProjectId}`);
        }
      }, 4000);
    });
  };
  return (
    <div className="Rectangle779">
      <Grid container padding="3% 0 0 10%" spacing={1}>
        <Grid item xs={12} sx={styles.yourNotifications}>
          Your Invitations
        </Grid>
        <Grid item xs={10} container alignItems="center">
          {invitationsList.map((n, index) => (
            <Grid
              item
              xs={12}
              key={index}
              gap={2}
              container
              bgcolor={"#fff"}
              padding={1.5}
              alignItems="center"
            >
              <Grid item md={0.8} sm={2} xs={2}>
                <img
                  src={
                    index % 3 === 0
                      ? contyellow
                      : (index + 2) % 3 === 0
                      ? contblue
                      : contgreen
                  }
                  width={"40px"}
                  alt={"Noti"}
                ></img>
              </Grid>
              <Grid item md={9} sm={7} xs={6} container>
                <Grid item xs={12} sx={styles.title}>
                  {localStorage.getItem("isTeamMember") === "true"
                    ? "Team Member Invitation"
                    : "Contractor Invitation"}
                </Grid>
                <Grid item xs={11} sx={styles.msg}>
                  {n.SubProject
                    ? "You've been invited to collaborate on Project: " +
                      n.Project +
                      ", Phase: " +
                      n.Phase +
                      ", SubProject: " +
                      n.SubProject +
                      "." +
                      " " +
                      n.InvitedBy +
                      " is waiting for you to join them on Pilecubes."
                    : "You've been invited to collaborate on Project: " +
                      n.Project +
                      ", Phase: " +
                      n.Phase +
                      "." +
                      " " +
                      n.InvitedBy +
                      " is waiting for you to join them on Pilecubes."}
                </Grid>
                <Grid item xs={12} sx={styles.date}>
                  {new Date(n.CreationTime).toString().slice(0, 21)}
                </Grid>
              </Grid>

              <Grid item sm={1} xs={2}>
                {n.StatusId === 1 ? (
                  <button
                    style={styles.Accept}
                    onMouseOver={(e) => {
                      e.target.style.cursor = "pointer";
                    }}
                    onClick={() => {
                      setChosenId(n.PhaseId);
                      acceptInvitation(n.PhaseId, n.SubProjectId, n.ProjectId);
                    }}
                  >
                    {loaddingButton && chosenId === n.PhaseId
                      ? "Accepting..."
                      : "Accept"}
                  </button>
                ) : (
                  <span style={styles.Accepted}>Accepted </span>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
}
