import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link, useNavigate, NavLink } from "react-router-dom";
import Logo from "../../Assets/Images/Navbar/logo1.png";
import classes from "../../Assets/Styles/NavSaas.module.css";
import CloseIcon from "@mui/icons-material/Close";
import SubIcon from "../../Assets/Images/NavSaas/sub.png";
import MainIcon from "../../Assets/Images/NavSaas/main.png";
import InvitesIcons from "../../SAAS/Assets/Images/Notifications/invites.png";
import factoryIcon from "../../SAAS/Assets/Images/Factory/factoryIcon.png";
import factoryIconActive from "../../SAAS/Assets/Images/Factory/factoryIconActive.png";
import { useSelector } from "react-redux";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import AssistanceModal from "../../SAAS/Components/Assitance/AssistanceModal";
import * as permissionFunctions from "../../ReusableFunctions";

const drawerWidth = 329;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const CheckIfPermitted = permissionFunctions.CheckIfPermitted;

export default function NavDrawerSaas(props) {
  const navigate = useNavigate();
  const IsTeamMember = useSelector((state) => state.userData.IsTeamMember);

  const [open, setOpen] = useState(false);

  const [showManage, setShowManage] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showProject, setShowProject] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );

  const CompanyName = useSelector((state) => state.userData.CompanyName);
  const [disableTH, setDisableTH] = useState(false);

  useEffect(() => {
    if (UserPermissions) {
      CheckIfPermitted(
        UserPermissions,
        "Pilecubes Request Team Help",
        "View",
        setDisableTH
      );
    }
  }, [UserPermissions]);
  return (
    <Box marginBottom={8}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        style={{ backgroundColor: "#fff", height: 75 }}
      >
        <Toolbar>
          <Grid container>
            <Grid item xs={6} paddingTop={2}>
              <Typography>
                <Link to="/saas/admin">
                  <img src={Logo} width="150rem" alt="Logo" />
                </Link>
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={6}
              direction={"row-reverse"}
              gap={1}
              paddingTop={1}
            >
              <IconButton
                style={{ color: "#101650" }}
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerOpen}
                sx={{ ...(open && { display: "none" }) }}
              >
                <MenuIcon />
              </IconButton>
              <Grid
                item
                paddingTop={1.5}
                sx={{ ...(open && { display: "none" }) }}
              >
                <IconButton
                  onClick={() => {
                    navigate("/saas/notifications");
                    localStorage.removeItem("FactoryActive");
                  }}
                >
                  {props.numOfNotification === 0 ? (
                    <NotificationsIcon
                      htmlColor="#e1e319"
                      sx={{ width: 22, height: 22 }}
                    />
                  ) : (
                    <Badge
                      color="error"
                      badgeContent={props.numOfNotification}
                      sx={{
                        "& .MuiBadge-badge": {
                          fontSize: 9,
                          height: 15,
                          minWidth: 15,
                          fontFamily: "Muli",
                          backgroundColor:
                            props.notificationTitle === "Pilecubes Team"
                              ? "green"
                              : "#d32f2f",
                        },
                      }}
                    >
                      <NotificationsIcon
                        htmlColor="#e1e319"
                        sx={{ width: 22, height: 22 }}
                      />
                    </Badge>
                  )}
                </IconButton>
              </Grid>
              <Grid
                sx={{ ...(open && { display: "none" }) }}
                paddingTop={2.3}
                item
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={() => {
                  navigate("/saas/invitations");
                  localStorage.removeItem("FactoryActive");
                }}
              >
                {props.numOfInvitations === 0 ? (
                  <img
                    src={InvitesIcons}
                    alt={"Invites"}
                    width="19px"
                    height="19px"
                  />
                ) : (
                  <Badge
                    color="error"
                    badgeContent={props.numOfInvitations}
                    sx={{
                      "& .MuiBadge-badge": {
                        fontSize: 9,
                        height: 15,
                        minWidth: 15,
                        fontFamily: "Muli",
                        backgroundColor: "#d32f2f",
                      },
                    }}
                  >
                    <img
                      src={InvitesIcons}
                      alt={"Invites"}
                      width="19px"
                      height="19px"
                      style={{ marginBottom: "5px" }}
                    />
                  </Badge>
                )}
              </Grid>
              <Grid
                sx={{ ...(open && { display: "none" }) }}
                paddingTop={1.5}
                item
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                onClick={() => {
                  navigate("/saas/factory");
                  localStorage.setItem("FactoryActive", true);
                }}
              >
                <img
                  src={
                    localStorage.getItem("FactoryActive") === "true"
                      ? factoryIconActive
                      : factoryIcon
                  }
                  width="35px"
                  height="36px"
                />
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="temporary"
        anchor="right"
        open={open}
        onEscapeKeyDown={handleDrawerClose}
        onBackdropClick={handleDrawerClose}
      >
        {showManage ? (
          <>
            <DrawerHeader>
              <IconButton
                onClick={() => {
                  setShowManage(false);
                }}
              >
                <ArrowBackIosIcon style={{ color: "#0674b9" }} />
              </IconButton>
              <ListItemText
                primary={"Manage"}
                className={classes["NavTitle"]}
              />

              <IconButton onClick={handleDrawerClose}>
                <CloseIcon style={{ color: "#0674b9" }} />
              </IconButton>
            </DrawerHeader>
            <div className={classes["Divi"]} />
            <List style={{ marginLeft: "3%" }}>
              {!UserPermissions?.find((x) => x.Name === "Admin") &&
                !UserPermissions?.find((x) => x.Name === "Project Manager") ? (
                UserPermissions?.map((up) =>
                  up.Permissions.map((p) => {
                    if (p.Name === "Client") {
                      return (
                        <>
                          <ListItem
                            button
                            onClick={(e) => {
                              navigate("/saas/admin/client");
                              localStorage.removeItem("FactoryActive");
                              handleDrawerClose();
                            }}
                          >
                            <ListItemText
                              primary={
                                <Typography className={classes["DrawerItem"]}>
                                  Clients
                                </Typography>
                              }
                            />
                          </ListItem>
                          <div className={classes["Divi"]} />
                        </>
                      );
                    } else return "";
                  })
                )
              ) : (
                <>
                  <ListItem
                    button
                    onClick={(e) => {
                      navigate("/saas/admin/client");
                      localStorage.removeItem("FactoryActive");
                      handleDrawerClose();
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography className={classes["DrawerItem"]}>
                          Clients
                        </Typography>
                      }
                    />
                  </ListItem>
                  <div className={classes["Divi"]} />
                </>
              )}

              {UserPermissions?.map((up) =>
                up.Permissions.map((p) => {
                  if (p.Name === "Document") {
                    return (
                      <>
                        <ListItem
                          button
                          onClick={(e) => {
                            navigate("/saas/admin/document");
                            localStorage.removeItem("FactoryActive");
                            handleDrawerClose();
                          }}
                        >
                          <ListItemText
                            primary={
                              <Typography className={classes["DrawerItem"]}>
                                Documents
                              </Typography>
                            }
                          />
                        </ListItem>
                        <div className={classes["Divi"]} />
                      </>
                    );
                  } else return "";
                })
              )}

              {IsTeamMember === true &&
                !UserPermissions?.find((x) => x.Name === "Admin") &&
                !UserPermissions?.find((x) => x.Name === "Project Manager") &&
                !UserPermissions?.find(
                  (x) => x.Name === "Engineering Manager"
                ) ? (
                UserPermissions?.map((up) =>
                  up.Permissions.map((p) => {
                    if (p.Name === "Main Contractor") {
                      return localStorage.getItem("AccountType") === "2" ? (
                        <>
                          <ListItem
                            button
                            onClick={(e) => {
                              navigate("/saas/admin/Mc");
                              localStorage.removeItem("FactoryActive");
                              handleDrawerClose();
                            }}
                          >
                            <ListItemText
                              primary={
                                <Typography className={classes["DrawerItem"]}>
                                  Main Contractors
                                </Typography>
                              }
                            />
                          </ListItem>
                          <div className={classes["Divi"]} />
                        </>
                      ) : (
                        <></>
                      );
                    } else return "";
                  })
                )
              ) : (
                <>
                  {localStorage.getItem("AccountType") === "2" ? (
                    <>
                      <ListItem
                        button
                        onClick={(e) => {
                          navigate("/saas/admin/Mc");
                          localStorage.removeItem("FactoryActive");
                          handleDrawerClose();
                        }}
                      >
                        <ListItemText
                          primary={
                            <Typography className={classes["DrawerItem"]}>
                              Main Contractors
                            </Typography>
                          }
                        />
                      </ListItem>
                      <div className={classes["Divi"]} />
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
              {IsTeamMember === true &&
                !UserPermissions?.find((x) => x.Name === "Admin") &&
                !UserPermissions?.find((x) => x.Name === "Project Manager") &&
                !UserPermissions?.find(
                  (x) => x.Name === "Engineering Manager"
                ) ? (
                UserPermissions?.map((up) =>
                  up.Permissions.map((p) => {
                    if (p.Name === "Sub Contractor") {
                      return (
                        <>
                          <ListItem
                            button
                            onClick={(e) => {
                              navigate("/saas/admin/Sc");
                              localStorage.removeItem("FactoryActive");
                              handleDrawerClose();
                            }}
                          >
                            <ListItemText
                              primary={
                                <Typography className={classes["DrawerItem"]}>
                                  Sub Contractors
                                </Typography>
                              }
                            />
                          </ListItem>
                          <div className={classes["Divi"]} />
                        </>
                      );
                    } else return "";
                  })
                )
              ) : (
                <>
                  <ListItem
                    button
                    onClick={(e) => {
                      navigate("/saas/admin/Sc");
                      localStorage.removeItem("FactoryActive");
                      handleDrawerClose();
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography className={classes["DrawerItem"]}>
                          Sub Contractors
                        </Typography>
                      }
                    />
                  </ListItem>
                  <div className={classes["Divi"]} />
                </>
              )}
              {(IsTeamMember === true &&
                !UserPermissions?.find((x) => x.Name === "Admin") &&
                !UserPermissions?.find((x) => x.Name === "Project Manager") &&
                !UserPermissions?.find(
                  (x) => x.Name === "Engineering Manager"
                ) &&
                !UserPermissions?.find((x) => x.Name === "Team Leader")) ||
                (!localStorage.getItem("planId") &&
                  !localStorage.getItem("planIdManu")) ? (
                localStorage.getItem("IsFreePlanActivated") === "true" &&
                  !localStorage.getItem("planId") ? (
                  ""
                ) : (
                  UserPermissions?.map((up) =>
                    up.Permissions.map((p) => {
                      if (p.Name === "Team Member") {
                        return (
                          <>
                            <ListItem
                              button
                              onClick={(e) => {
                                navigate("/saas/admin/TeamMember");
                                localStorage.removeItem("FactoryActive");
                                handleDrawerClose();
                              }}
                            >
                              <ListItemText
                                primary={
                                  <Typography className={classes["DrawerItem"]}>
                                    Team Members
                                  </Typography>
                                }
                              />
                            </ListItem>
                            <div className={classes["Divi"]} />
                          </>
                        );
                      } else return "";
                    })
                  )
                )
              ) : (
                <>
                  <ListItem
                    button
                    onClick={(e) => {
                      navigate("/saas/admin/TeamMember");
                      localStorage.removeItem("FactoryActive");
                      handleDrawerClose();
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography className={classes["DrawerItem"]}>
                          Team Members
                        </Typography>
                      }
                    />
                  </ListItem>
                  <div className={classes["Divi"]} />
                </>
              )}

              {(IsTeamMember === true &&
                !UserPermissions?.find((x) => x.Name === "Admin")) ||
                (!localStorage.getItem("planId") &&
                  !localStorage.getItem("planIdManu")) ? (
                localStorage.getItem("IsFreePlanActivated") === "true" &&
                  !localStorage.getItem("planId") ? (
                  ""
                ) : (
                  UserPermissions?.map((up) =>
                    up.Permissions.map((p) => {
                      if (p.Name === "User Role") {
                        return (
                          <>
                            <ListItem
                              button
                              onClick={(e) => {
                                navigate("/saas/admin/UserRoles");
                                localStorage.removeItem("FactoryActive");
                                handleDrawerClose();
                              }}
                            >
                              <ListItemText
                                primary={
                                  <Typography className={classes["DrawerItem"]}>
                                    User Roles
                                  </Typography>
                                }
                              />
                            </ListItem>
                            <div className={classes["Divi"]} />
                          </>
                        );
                      } else return "";
                    })
                  )
                )
              ) : (
                <>
                  <ListItem
                    button
                    onClick={(e) => {
                      navigate("/saas/admin/UserRoles");
                      localStorage.removeItem("FactoryActive");
                      handleDrawerClose();
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography className={classes["DrawerItem"]}>
                          User Roles
                        </Typography>
                      }
                    />
                  </ListItem>
                  <div className={classes["Divi"]} />
                </>
              )}
              {UserPermissions?.map((up) =>
                up.Permissions.map((p) => {
                  if (
                    p.Name === "Label Management" &&
                    localStorage.getItem("AccountType") === "2"
                  ) {
                    return (
                      <>
                        <ListItem
                          button
                          onClick={(e) => {
                            navigate("saas/admin/productionLabel");
                            handleDrawerClose();
                          }}
                        >
                          <ListItemText
                            primary={
                              <Typography className={classes["DrawerItem"]}>
                                Production Label
                              </Typography>
                            }
                          />
                        </ListItem>
                        <div className={classes["Divi"]} />
                      </>
                    );
                  } else return "";
                })
              )}
              {UserPermissions?.map((up) =>
                up.Permissions.map((p) => {
                  if (
                    p.Name === "Inventory Set-Up" &&
                    localStorage?.getItem("AccountType") === "2" &&
                    (localStorage.getItem("isSubscribedManu") || localStorage.getItem("IsFreePlanActivated") === "true")
                  ) {
                    return (
                      <>
                        <ListItem
                          button
                          onClick={(e) => {
                            navigate("/saas/admin/inventory");
                            handleDrawerClose();
                          }}
                        >
                          <ListItemText
                            primary={
                              <Typography className={classes["DrawerItem"]}>
                                Inventory Setup
                              </Typography>
                            }
                          />
                        </ListItem>
                        <div className={classes["Divi"]} />
                      </>
                    );
                  } else return "";
                })
              )}
              {UserPermissions?.map((up) =>
                up.Permissions.map((p) => {
                  if (
                    p.Name === "Trucks, Trailers, Drivers" &&
                    localStorage?.getItem("AccountType") === "2" &&
                    localStorage?.getItem("isSubscribedManu") &&
                    !localStorage?.getItem("planIdManu")?.includes("Core")
                  ) {
                    return (
                      <>
                        <ListItem
                          button
                          onClick={(e) => {
                            navigate("/saas/Admin/Transport");
                            handleDrawerClose();
                          }}
                        >
                          <ListItemText
                            primary={
                              <Typography className={classes["DrawerItem"]}>
                                Trucks And Trailers
                              </Typography>
                            }
                          />
                        </ListItem>
                        <div className={classes["Divi"]} />
                      </>
                    );
                  } else return "";
                })
              )}
            </List>
          </>
        ) : showProfile ? (
          <>
            <DrawerHeader>
              <IconButton
                onClick={() => {
                  setShowProfile(false);
                }}
              >
                <ArrowBackIosIcon
                  style={{ color: "#0674b9" }}
                  fontSize="small"
                />
              </IconButton>
              <ListItemText
                primary={
                  <ListItem>
                    <ListItemIcon>
                      <img
                        src={
                          localStorage.getItem("AccountType") === "1"
                            ? MainIcon
                            : SubIcon
                        }
                        alt={"login"}
                        style={{
                          marginTop: "1%",

                          width: 30,
                        }}
                      ></img>
                    </ListItemIcon>
                    <ListItemText
                      primary={CompanyName}
                      className={classes["CompanyNameComplete"]}
                    />
                  </ListItem>
                }
                className={classes["NavTitle"]}
              />

              <IconButton onClick={handleDrawerClose}>
                <CloseIcon style={{ color: "#0674b9" }} />
              </IconButton>
            </DrawerHeader>
            <div className={classes["Divi"]} />
            <List style={{ marginLeft: "3%" }}>
              {localStorage.getItem("AccountType") === "2" ? (
                <>
                  <ListItem
                    button
                    onClick={(e) => {
                      props.setSetupProfile(true);
                      props.setSetupMenu(false);
                      navigate("/saas/profileDetails");
                      localStorage.removeItem("FactoryActive");
                      handleDrawerClose();
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography className={classes["DrawerItem"]}>
                          Profile
                        </Typography>
                      }
                    />
                  </ListItem>
                  <div className={classes["Divi"]} />
                </>
              ) : (
                <></>
              )}
              <ListItem
                button
                onClick={(e) => {
                  props.setSetupProfile(true);
                  props.setSetupMenu(true);
                  navigate("/saas/profileSettings");
                  localStorage.removeItem("FactoryActive");
                  handleDrawerClose();
                }}
              >
                <ListItemText
                  primary={
                    <Typography className={classes["DrawerItem"]}>
                      Settings
                    </Typography>
                  }
                />
              </ListItem>
              <div className={classes["Divi"]} />
              <ListItem
                button
                disabled={disableTH}
                onClick={(e) => {
                  setOpenModal(true);
                  handleDrawerClose();
                }}
              >
                <ListItemText
                  primary={
                    <Typography className={classes["DrawerItem"]}>
                      Implementation Support
                    </Typography>
                  }
                />
              </ListItem>
              <div className={classes["Divi"]} />
              <div className={classes["Divi"]} />
              <ListItem
                button
                onClick={(e) => {
                  localStorage.clear();
                  props.setAccountName("");
                  props.setIsVerified("");
                  props.setIsSubscribed("");
                  props.setToken("");
                  props.setAccount();
                  navigate("/");
                  props.setIsLoggedIn(false);
                }}
              >
                <ListItemText
                  primary={
                    <Typography className={classes["DrawerItem"]}>
                      Logout
                    </Typography>
                  }
                />
              </ListItem>
              <div className={classes["Divi"]} />
            </List>
          </>
        ) : showProject ? (
          <>
            <DrawerHeader>
              <IconButton
                onClick={() => {
                  setShowProject(false);
                }}
              >
                <ArrowBackIosIcon style={{ color: "#0674b9" }} />
              </IconButton>
              <ListItemText
                primary={"Project"}
                className={classes["NavTitle"]}
              />

              <IconButton onClick={handleDrawerClose}>
                <CloseIcon style={{ color: "#0674b9" }} />
              </IconButton>
            </DrawerHeader>
            <div className={classes["Divi"]} />
            <List style={{ marginLeft: "3%" }}>
              <ListItem
                button
                onClick={(e) => {
                  navigate("/saas/projects/created");
                  localStorage.removeItem("FactoryActive");
                  handleDrawerClose();
                }}
              >
                <ListItemText
                  primary={
                    <Typography className={classes["DrawerItem"]}>
                      Created Projects
                    </Typography>
                  }
                />
              </ListItem>
              <div className={classes["Divi"]} />
              <ListItem
                button
                onClick={(e) => {
                  navigate("/saas/projects/assigned");
                  localStorage.removeItem("FactoryActive");
                  handleDrawerClose();
                }}
              >
                <ListItemText
                  primary={
                    <Typography className={classes["DrawerItem"]}>
                      Assigned Projects
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </>
        ) : (
          <>
            <DrawerHeader>
              <IconButton
                onClick={handleDrawerClose}
                style={{ marginLeft: "90%" }}
              >
                <CloseIcon style={{ color: "#0674b9" }} />
              </IconButton>
            </DrawerHeader>
            <div className={classes["Divi"]} />
            <List style={{ marginLeft: "3%" }}>
              <ListItem
                button
                onClick={(e) => {
                  setShowManage(true);
                }}
              >
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontWeight: "normal" }}
                      className={classes["DrawerItem"]}
                    >
                      Manage
                    </Typography>
                  }
                />
                <IconButton style={{ marginLeft: "93%" }}>
                  <ArrowForwardIosIcon htmlColor="#0674b9" fontSize="small" />
                </IconButton>
              </ListItem>
              <div className={classes["Divi"]} />
              <ListItem
                button
                onClick={(e) => {
                  navigate("/saas/projects");
                  localStorage.removeItem("FactoryActive");
                  setShowProject(true);
                }}
              >
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontWeight: "normal" }}
                      className={classes["DrawerItem"]}
                    >
                      Projects
                    </Typography>
                  }
                />
                <IconButton style={{ marginLeft: "93%" }}>
                  <ArrowForwardIosIcon htmlColor="#0674b9" fontSize="small" />
                </IconButton>
              </ListItem>
              <div className={classes["Divi"]} />
              {UserPermissions?.map((up) =>
                up.Permissions.map((p) => {
                  if (p.Name === "Drawing Activity") {
                    return (
                      <>
                        <ListItem
                          button
                          onClick={(e) => {
                            navigate("/saas/execution");
                            localStorage.removeItem("FactoryActive");
                            handleDrawerClose();
                          }}
                        >
                          <ListItemText
                            primary={
                              <Typography
                                style={{ fontWeight: "normal" }}
                                className={classes["DrawerItem"]}
                              >
                                Activity
                              </Typography>
                            }
                          />
                        </ListItem>
                        <div className={classes["Divi"]} />
                      </>
                    );
                  } else return "";
                })
              )}
              <ListItem
                button
                onClick={(e) => {
                  navigate("/saas/sales");
                  localStorage.removeItem("FactoryActive");
                  handleDrawerClose();
                }}
              >
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontWeight: "normal" }}
                      className={classes["DrawerItem"]}
                    >
                      Sales Order
                    </Typography>
                  }
                />
              </ListItem>
              <div className={classes["Divi"]} />
              {localStorage?.getItem("AccountType") === "2" &&
                localStorage?.getItem("isSubscribedManu") ? (
                <>
                  <ListItem
                    button
                    onClick={(e) => {
                      navigate("/saas/productionPlanning/Calendar");
                      handleDrawerClose();
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          style={{ fontWeight: "normal" }}
                          className={classes["DrawerItem"]}
                        >
                          Production
                        </Typography>
                      }
                    />
                  </ListItem>
                  <div className={classes["Divi"]} />
                  {localStorage.getItem("IsFreePlanActivated") === "true" ||
                    localStorage.getItem("planIdManu")?.includes("Core") ? (
                    ""
                  ) : (
                    <ListItem
                      button
                      onClick={(e) => {
                        navigate("/saas/admin/inventoryManagement");
                        handleDrawerClose();
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            style={{ fontWeight: "normal" }}
                            className={classes["DrawerItem"]}
                          >
                            Inventory
                          </Typography>
                        }
                      />
                    </ListItem>
                  )}
                  <div className={classes["Divi"]} />
                  {localStorage?.getItem("IsFreePlanActivated") === "true" ||
                    localStorage?.getItem("planIdManu")?.includes("Core") ? (
                    ""
                  ) : (
                    <ListItem
                      button
                      onClick={(e) => {
                        navigate("/saas/Loading/Calendar");
                        handleDrawerClose();
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            style={{ fontWeight: "normal" }}
                            className={classes["DrawerItem"]}
                          >
                            Loading
                          </Typography>
                        }
                      />
                    </ListItem>
                  )}
                  <div className={classes["Divi"]} />
                  {localStorage?.getItem("IsFreePlanActivated") === "true" ||
                    localStorage?.getItem("planIdManu")?.includes("Core") ? (
                    ""
                  ) : (
                    <ListItem
                      button
                      onClick={(e) => {
                        navigate("/saas/Delivery/Calendar");
                        handleDrawerClose();
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            style={{ fontWeight: "normal" }}
                            className={classes["DrawerItem"]}
                          >
                            Delivery
                          </Typography>
                        }
                      />
                    </ListItem>
                  )}
                  <div className={classes["Divi"]} />
                </>
              ) : (
                <></>
              )}
              <ListItem
                button
                onClick={() => {
                  setShowProfile(true);
                }}
              >
                <Grid item container paddingTop={1}>
                  <Grid item xs={0.5} paddingTop={0.1}>
                    <ListItemIcon>
                      <img
                        onClick={() => {
                          navigate("/login");
                        }}
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                        }}
                        src={
                          localStorage.getItem("AccountType") === "1"
                            ? MainIcon
                            : SubIcon
                        }
                        alt={"login"}
                        style={{
                          width: 30,
                        }}
                      ></img>
                    </ListItemIcon>
                  </Grid>
                  <Grid item xs={11}>
                    <ListItemText
                      primary={CompanyName}
                      className={classes["CompanyNameComplete"]}
                    />
                  </Grid>
                  <Grid item xs={0.5} paddingTop={0.5}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon
                        htmlColor="#0674b9"
                        fontSize="small"
                      />
                    </ListItemIcon>
                  </Grid>
                </Grid>
              </ListItem>
              <div className={classes["Divi"]} />
            </List>
          </>
        )}
      </Drawer>
      <div>
        <Grid item>
          <AssistanceModal openModal={openModal} setOpenModal={setOpenModal} />
        </Grid>
      </div>
    </Box>
  );
}
