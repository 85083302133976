import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchProject from "./SearchProject";
import ProjectList from "./ProjectList";
import classes from "../../Assets/Styles/projects.module.css";
import SaasBack from "../../Assets/Images/ProfileDetails/SaaSbackground.png";
import * as projectServices from "../../Services/projects-services.proxy";
const getJoinableProjects = projectServices.getJoinableProjects;
export default function Join(props) {
  const [searchText, setSearchText] = useState(null);
  const [searched, setSearched] = useState(false);
  const [noProjects, setNoProjects] = useState(false);

  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);
  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);
  const [projects, setProjects] = useState([]);
  const seacrhProjects = () => {
    if (searchText)
      getJoinableProjects(searchText).then((x) => {
        if (x.Projects.length > 0) {
          setProjects(x.Projects);
          setSearched(true);
        } else {
          setNoProjects(true);
        }
      });
    else {
      setNoProjects(true);
    }
  };
  const navigate = useNavigate();
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      <Grid container alignItems={"center"} justifyContent={"center"}>
        <Grid item lg={12} container direction={"row"} paddingTop={3}>
          {searched ? (
            <Grid
              item
              lg={3}
              paddingTop={3}
              alignItems={"center"}
              justifyContent={"center"}
              className={classes["Page-Title"]}
            >
              Projects
            </Grid>
          ) : (
            <></>
          )}

          <Grid
            item
            lg={searched ? 9 : 12}
            container
            direction={"row-reverse"}
            paddingTop={3}
            paddingRight={5}
          >
            <button
              className={classes["back-button"]}
              onClick={() => {
                if (searched) {
                  setSearched(false);
                } else {
                  navigate(-1);
                }
              }}
            >
              Go back
            </button>
          </Grid>
        </Grid>
        <Grid
          item
          md={12}
          container
          alignItems={"center"}
          justifyContent={"center"}
          paddingTop={searched ? 8 : 20}
        >
          {searched ? (
            <ProjectList
              setJoin={props.setJoin}
              projects={projects}
              edit={props.edit}
              setEdit={props.setEdit}
              view={props.view}
              setView={props.setView}
            />
          ) : (
            <SearchProject
              searchText={searchText}
              setSearchText={setSearchText}
              seacrhProjects={seacrhProjects}
              noProjects={noProjects}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
}
