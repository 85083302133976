import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import Animation from "../Home/Animation";
import * as data from "../../Services/pricing-data.proxy.js";
import useWindowDimensions from "../../Components/Navbar/WindowDimension";
import * as CheckOut from "../../Services/create-checkout-session.proxy";
import { useNavigate } from "react-router-dom";
import * as pricingRequestService from "../../Services/pricing-data.proxy";
const requestCustomSubscription =
  pricingRequestService.requestCustomSubscription;
const PRICING_PLANS = data.getPricingPlans;

const CREATE_CHECKOUT_SESSION = CheckOut.createCheckoutSession;
export default function TabPlanSc({
  setLoading,
  accounType,
  isLoggedIn,
  subscriptionTypeId,
  setSubscriptionTypeId,
  billingId,
  setBillingId,
  setShowContactUsPricing,
}) {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [directions, setDirections] = useState("row");
  const [phoneView, setPhoneView] = useState(false);
  const [pricingPlans, setPricingPlans] = useState([]);

  const fetchProducts = (SubscriptionTypeId, BillingId) => {
    //setLoading(true);
    PRICING_PLANS(SubscriptionTypeId, BillingId, setLoading).then((x) =>
      setPricingPlans(x.Products)
    );
  };
  useEffect(() => {
    // setLoading(true);
    fetchProducts(subscriptionTypeId, billingId);
  }, [billingId, subscriptionTypeId]);
  const createCheckoutSession = (pricingId) => {
    //setLoading(true);
    CREATE_CHECKOUT_SESSION(pricingId, setLoading).then((x) => window.open(x));
  };

  useEffect(() => {
    if (width < 1000) {
      setDirections("column");
      setPhoneView(true);
    } else {
      setDirections("row");
      setPhoneView(false);
    }
  });
  const requestSubscription = () => {
    requestCustomSubscription(
      localStorage.getItem("UserId"),
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      2
    ).then((x) => {
      setShowContactUsPricing(true);
    });
  };
  const planForm = (planDetails, defaultValue) => {
    return (
      <TabsUnstyled
        defaultValue={
          isLoggedIn ? (accounType === 2 ? defaultValue : null) : defaultValue
        }
      >
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            item
            container
            md={12}
            direction={"row"}
            marginLeft={{ xs: 0.5, md: 0 }}
            style={{
              overflowX: "scroll",
              overflowY: "hidden",
              minHeight: 570,
            }}
          >
            <TabsList1 style={{ flexDirection: directions, gap: 50 }}>
              {planDetails.map((plan) => (
                <Tab1
                  key={plan.PriceId}
                  value={plan.PriceId}
                  style={{ marginTop: 38 }}
                  disabled={
                    isLoggedIn ? (accounType === 2 ? false : true) : false
                  }
                >
                  <Grid item container md={4} xs={12}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid
                        item
                        container
                        md={12}
                        marginTop={5.5}
                        paddingBottom={20}
                      >
                        <Grid item container md={12} spacing={1} marginLeft={3}>
                          <Grid item md={12}>
                            <div className="Core">{plan.Name}</div>
                          </Grid>
                          <Grid item container md={12} direction={"row"}>
                            <Grid item md={6} xs={6} container>
                              <div className="price">
                                {plan.Name === "Pro +" ? (
                                  <div className="Custom-Pricing">
                                    Custom Pricing
                                  </div>
                                ) : (
                                  <div className="price">
                                    {plan.CurrencySymbol + "" + plan.Price}
                                  </div>
                                )}
                              </div>
                            </Grid>
                            <Grid item md={6} xs={6} marginTop={2}>
                              <div className="duration">
                                / {plan.BillingPlan}
                              </div>
                            </Grid>
                          </Grid>
                          <Grid item md={12}>
                            <div className="name">{plan.Type}</div>
                          </Grid>
                        </Grid>
                        <Grid item md={12} marginTop={2} marginLeft={1.5}>
                          {plan.Name === "Pro +" ? (
                            <div
                              className="chooseButton"
                              onClick={(e) => {
                                localStorage.setItem(
                                  "SubscriptionTypeId",
                                  subscriptionTypeId
                                );
                                if (localStorage.getItem("UserId")) {
                                  requestSubscription();
                                } else setShowContactUsPricing(true);
                              }}
                            >
                              Contact Us
                            </div>
                          ) : (
                            <div
                              className="chooseButton"
                              onClick={(e) => {
                                if (isLoggedIn) {
                                  localStorage.setItem("PlanChosen", plan.Name);
                                  // e.target.style.backgroundColor = "#101650";
                                  createCheckoutSession(plan.PriceId);
                                } else {
                                  navigate("/login");
                                }
                              }}
                            >
                              Choose Plan
                            </div>
                          )}
                        </Grid>

                        <Grid item md={12} marginTop={2} marginLeft={3}>
                          {plan.ProductDescription.map((option, index) => (
                            <div className="description" key={index}>
                              {option.IsChecked ? (
                                <div
                                  className="check"
                                  style={{ marginRight: 10.3 }}
                                ></div>
                              ) : null}
                              {option.Description}
                            </div>
                          ))}
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Tab1>
              ))}
            </TabsList1>
          </Grid>
        </Box>
      </TabsUnstyled>
    );
  };
  return (
    <>
      {pricingPlans.length > 0 ? (
        <TabsUnstyled defaultValue={0}>
          <TabsList
            style={{
              marginLeft: phoneView ? "0%" : "25%",
              marginBottom: phoneView ? 20 : 0,
            }}
          >
            <Tab onClick={() => setBillingId(1)}>Monthly</Tab>
            <Tab onClick={() => setBillingId(2)}>Annually</Tab>
          </TabsList>
          <TabPanel value={0}>{planForm(pricingPlans, 8)}</TabPanel>
          <TabPanel value={1}>{planForm(pricingPlans, 11)} </TabPanel>
        </TabsUnstyled>
      ) : (
        <Box
          style={{
            marginTop: phoneView ? "0" : "15%",
            marginLeft: phoneView ? "-15%" : "30%",
          }}
        >
          <Animation />
        </Box>
      )}
    </>
  );
}
const Tab = styled(TabUnstyled)`
  font-family: Muli;
  color: #101650;
  cursor: pointer;
  font-size: 20px;

  background-color: transparent;
  width: 128px;
  height: 39px;

  margin: 6px 14px;
  border: none;
  border-radius: 19px;

  &:focus {
    color: #101650;
    border-radius: 19px;

    outline-offset: 2px;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: rgba(45, 164, 208, 0.3);
    color: #0674b9;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: Muli;
  font-size: 20px;
`;

const TabsList = styled(TabsListUnstyled)`
  min-width: 320px;
  background-color: #fff;

  display: flex;
  align-items: center;
  margin-left: 20%;
  align-content: space-between;
`;
const Tab1 = styled(TabUnstyled)`
  font-family: Muli;
  color: #101650;
  cursor: pointer;
  height: 495px;
  width: 297px;
  border: solid 1px rgba(206, 210, 225, 0.97);
  background-color: transparent;

  .check {
    display: inline-block;
    &:after {
      content: "";
      display: block;
      width: 7px;
      height: 12px;
      border: solid rgba(206, 210, 225, 0.97);
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
  .chooseButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16rem;
    height: 40px;
    font-family: Muli;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.44;
    text-align: center;
    border: 0px;
    color: rgba(6, 116, 185, 0.5);
    background-color: #f2f2f2;
    cursor: unset;
    pointer-events: none;
  }

  &:hover {
    box-shadow: 0 7px 19px 0 #2da4d0;
    .check {
      display: inline-block;
      &:after {
        content: "";
        display: block;
        width: 7px;
        height: 12px;
        border: solid #2da4d0;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }

  &.${tabUnstyledClasses.selected} {
    color: #0674b9;
    box-shadow: 0 7px 19px 0 #2da4d0;
    .check {
      display: inline-block;
      &:after {
        content: "";
        display: block;
        width: 7px;
        height: 12px;
        border: solid #2da4d0;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
    .chooseButton {
      width: 16rem;
      height: 40px;
      font-family: Muli;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.44;
      text-align: center;
      border: 0px;
      color: #fff;
      background-color: #0b5cab;
      cursor: pointer;
      pointer-events: visible;
    }
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 1;
    cursor: not-allowed;
  }
`;

const TabsList1 = styled(TabsListUnstyled)`
  background-color: #fff;
  display: flex;
  width: 100%;
`;
