import { Button, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FactoryCard from "../Components/Factory/FactoryCard";
import "../Assets/Styles/home.css";
import * as factoryServices from "../Services/factories-services.proxy";
import classes from "../Assets/Styles/factory.module.css";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import * as permissionFunctions from "../../ReusableFunctions";

const CheckIfPermitted = permissionFunctions.CheckIfPermitted;
const getFactoriesCards = factoryServices.getFactoriesCards;

export default function Factory(props) {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);
  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isDisabled, setIsDisabled] = useState(false);
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );
  const navigate = useNavigate();
  const data0 = {
    Name: "Factory Name",
    Code: "code",
    BaysNumber: 0,
    BlocksNumber: 0,
  };
  const [disabledCreation, setDisabledCreation] = useState(false);
  const [factories, setFactories] = useState([]);
  const [total, setTotal] = useState(0);
  const [disableTH, setDisableTH] = useState(false);

  useEffect(() => {
    let apiTest = true;
    setLoading(true);
    getFactoriesCards().then((x) => {
      if (apiTest) {
        setFactories(x.Factories);
        setTotal(x.Total);
      }
      setLoading(false);
    });
    return () => {
      apiTest = false;
    };
  }, []);
  useEffect(() => {
    if (UserPermissions) {
      CheckIfPermitted(
        UserPermissions,
        "Factory Set-Up",
        "Create",
        setDisabledCreation
      );
      CheckIfPermitted(
        UserPermissions,
        "Pilecubes Request Team Help",
        "View",
        setDisableTH
      );
    }
  }, [UserPermissions]);
  return (
    <Grid className="Rectangle779">
      {isDisabled ? (
        <Grid
          item
          container
          bgcolor={"rgba(225, 227, 25, 0.5)"}
          md={12}
          paddingTop={2}
          paddingBottom={1}
        >
          <Grid item md={4}></Grid>
          <Grid
            item
            md={6}
            style={{
              fontFamily: "Muli",
              fontSize: "17px",
              fontWeight: "bold",
              textAlign: "left",
              color: "#101650",
            }}
          >
            <Grid>Please subscribe to Manufacturing plan to create more</Grid>
          </Grid>
          <Grid item md={2} container>
            <Grid
              item
              onClick={() => {
                props.setSetupProfile(true);
                props.setSetupMenu(true);
                navigate("/saas/profileSettings");
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
            >
              <button
                style={{
                  padding: "5px",
                  borderRadius: "5px",
                  backgroundColor: "#101650",
                  fontFamily: "Muli",
                  fontSize: "13px",
                  textAlign: "center",
                  border: 0,
                  color: "#fff",
                }}
              >
                Subscribe
              </button>
            </Grid>
            <Grid
              item
              paddingLeft={1}
              onClick={() => {
                setIsDisabled(false);
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
            >
              <CloseIcon fontSize="medium" />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <Grid item xs={12} container rowSpacing={1.5} padding="2.5% 0 0 8%">
        <Grid item xs={12} container alignItems="center">
          <Grid
            item
            xs={2.5}
            sm={2}
            lg={1}
            sx={{
              fontFamily: "Muli",
              fontSize: 18,
              fontWeight: "bold",
              lineHeight: 1.44,
              textAlign: "left",
              color: "#101650",
            }}
          >
            Factory
          </Grid>
          <Grid
            item
            xs={5.5}
            sm={5.5}
            lg={9.5}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>
          <Grid
            item
            xs={5}
            sm={2.5}
            lg={1.2}
            onMouseOver={(e) => (e.target.style.cursor = "pointer")}
            paddingLeft={1.5}
            sx={{
              fontFamily: "Muli",
              fontSize: 14,
              lineHeight: 1.36,
              textAlign: "left",
              color: "#101650",
            }}
            onClick={() => {
              navigate("/saas/factoriesList");
            }}
          >
            View All Factories
          </Grid>
       
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            disabled={disabledCreation}
            onClick={() => {
              if (
                localStorage.getItem("IsFreePlanActivated") === "true" &&
                total === 1
              )
                setIsDisabled(true);
              else navigate("/saas/factory/create");
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#101650")}
            onMouseLeave={(e) => (e.target.style.backgroundColor = "#e1e319")}
            style={{
              borderRadius: 5,
              backgroundColor: "#e1e319",
              fontFamily: "Muli",
              fontSize: 12,
              fontWeight: "bold",
              lineHeight: 1.5,
              textAlign: "left",
              color: "#fff",
              textTransform: "none",
              padding: "1.5% 2% 1.5% 2%",
            }}
          >
            + Create Factory
          </Button>
        </Grid>
 
        {loading ? (
          <Grid item marginTop="10%">
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} container columnSpacing={2} rowSpacing={2}>
              {factories?.length > 0 ? (
                <>
                  {factories.map((d, index) => (
                    <Grid
                      item
                      xs={10}
                      sm={4.5}
                      md={3.5}
                      lg={2.5}
                      xl={2.2}
                      key={d.Id}
                    >
                      <FactoryCard data={d} index={index} />
                    </Grid>
                  ))}
                </>
              ) : (
                <Grid item xs={10} sm={4.5} md={3.5} lg={2.5} xl={2.2}>
                  <FactoryCard data={data0} index={-1} />
                </Grid>
              )}
            </Grid>
            {factories?.length === 0 ? (
              <Grid
                item
                xs={12}
                sx={{
                  fontFamily: "Muli",
                  fontSize: 12,
                  lineHeight: 1.5,
                  textAlign: "left",
                  color: "#101650",
                  paddingBottom: "30px",
                }}
              >
                This Space is empty. Create a Factory to get started.
              </Grid>
            ) : (
              <></>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}
