import React, { useState, useEffect } from "react";
import { styled, Box } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import classes from "../../../Assets/Styles/profileDetails.module.css";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/Upload";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import * as profileServices from "../../../Services/profile-services.proxy";
const updateCompanyInfo = profileServices.updateCompanyInfo;
const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  bgcolor: "#fff",
  border: "solid 1px #e9e7e7",

  outline: "none",
};

export default function SloganModal(props) {
  const [open, setOpen] = useState(false);
  const [emptyFields, setEmptyFields] = useState(true);

  const handleOpen = () => setOpen(true);
  const [companyWebsite, setCompanyWebsite] = useState(
    "Add your Company Website"
  );
  const [companySlogan, setCompanySlogan] = useState(
    "Share your awesome company slogan with pilecubes.(Or at least some description for your colleagues.)"
  );
  const [companyWebsiteError, setCompanyWebsiteError] = useState(false);
  const [sloganInvalid, setSloganInvalid] = useState(false);
  const handleClose = () => {
    if (!emptyFields) {
      setCompanyWebsite("");
      setCompanySlogan("");
      setCompanyWebsiteError(false);
    }
    setOpen(false);
    props.setOpen(false);
  };
  useEffect(() => {
    if (props.open) {
      handleOpen(true);
    }
  }, [props.open]);

  useEffect(() => {
    
    setCompanySlogan(props.companySlogan);
    setCompanyWebsite(props.companyWebsite);
  }, [props]);

  const fillCompanyInfo = () => {
    updateCompanyInfo(companySlogan, companyWebsite).then((x) => {
      props.setCompanySlogan(companySlogan);
      props.setCompanyWebsite(companyWebsite);
    });
  };
  return (
    <div>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
      >
        <Box sx={{ outline: "none" }}>
          <Grid
            container
            direction={"row-reverse"}
            bgcolor={"transparent"}
            marginBottom={0.5}
          >
            <IconButton
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              onClick={() => {
                handleClose();
              }}
            >
              <CloseIcon htmlColor="#fff" />
            </IconButton>
          </Grid>
          <Box sx={style} width={{ md: "640px", xs: "80vw" }}>
            <Grid
              container
              direction={"column"}
              spacing={2}
              paddingTop={2}
              marginLeft={{ md: 1 }}
            >
              <Grid item container direction={"column"} marginTop={1}>
                <Grid item>
                  <span className={classes.Fiedlables}>Company Website</span>
                </Grid>
                <Grid item>
                  <TextField
                    id="outlined-name"
                    value={companyWebsite}
                    size="small"
                    error={companyWebsiteError}
                    helperText={
                      companyWebsiteError ? "Enter a valid website" : ""
                    }
                    className={classes.companyWebsiteField}
                    onChange={(e) => {
                      setCompanyWebsite(e.target.value);
                      if (
                        e.target.value !== "" &&
                        !e.target.value.match(
                          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
                        )
                      ) {
                        setCompanyWebsiteError(true);
                      } else {
                        setEmptyFields(false);
                        setCompanyWebsiteError(false);
                      }
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item container direction={"column"}>
                <Grid item>
                  <span className={classes.Fiedlables}>Company Slogan</span>
                </Grid>
                <Grid item>
                  <TextField
                    sx={{ height: "20%" }}
                    value={companySlogan}
                    error={sloganInvalid}
                    multiline
                    rows={4}
                    helperText={
                      sloganInvalid
                        ? "Slogan/Description must be less than 255 characters"
                        : ""
                    }
                    size="medium"
                    className={classes.companySloganField}
                    onChange={(e) => {
                      setCompanySlogan(e.target.value);
                      if (
                        e.target.value !== "" &&
                        e.target.value.length > 255
                      ) {
                        setSloganInvalid(true);
                      } else {
                        setEmptyFields(false);
                        setSloganInvalid(false);
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              md={11}
              container
              direction={"row-reverse"}
              marginTop={2}
              paddingBottom={2}
            >
              <Grid item marginLeft={2}>
                <button
                  disabled={emptyFields}
                  className={classes.SaveModal}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={() => {
                    if(companyWebsiteError===false && sloganInvalid===false){
                    fillCompanyInfo();
                    handleClose();
                    }
                  }}
                >
                  Save
                </button>
              </Grid>
              <Grid item>
                <button
                  className={classes.exitModal}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cancel
                </button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </StyledModal>
    </div>
  );
}
