import React, { useState, useEffect } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import classes from "../../../Assets/Styles/Activities.module.css";
import * as activityServices from "../../../Services/activities-services.proxy";
import * as SalesOrderServices from "../../../Services/sales-order-services.proxy";

const getProjectList = activityServices.getProjectList;
const getProjectListData = SalesOrderServices.getProjectListData;
const getPhasesAndSubs = activityServices.getPhasesAndSubs;

function SubprojectsList(props) {
  const [projects, setProjects] = useState([]);
  const [openedCard, setopenedCard] = useState([]);
  const [viewTable, setViewTable] = useState(false);
  const [projectDetails, setProjectDetails] = useState([]);

  const [disableSelection, setDisableSelection] = useState(false);
  const [projectDetailsLoading, setProjectDetailsLoading] = useState(false);

  const [modify, setModify] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("AccountType") === "2") {
      getProjectListData().then((x) => {
        setProjects(x);
      });
    } else {
      getProjectList().then((x) => {
        setProjects(x);
      });
    }
  }, []);
  const getProjectDetails = (projectId) => {
    setProjectDetailsLoading(true);
    getPhasesAndSubs(projectId).then((x) => {
      setProjectDetails(x.Sections);
      setProjectDetailsLoading(false);
    });
  };
  useEffect(() => {
    if (
      (props.SALES_ORDER_ID && props.projectId) ||
      (props.SalesOrderDetailsInfo && props.projectId)
    ) {
      getProjectDetails(props.projectId);
      setDisableSelection(true);
    } else if (props.projectId) {
      getProjectDetails(props.projectId);
    }
  }, [props.SALES_ORDER_ID, props.projectId, props.SalesOrderDetailsInfo]);
  const renderSubProjects = (parentArray) => {
    return parentArray?.map((parent) => (
      <Grid container key={parent.Id}>
        <Grid
          item
          className={classes["Rectangle-1467"]}
          xs={11.5}
          container
          direction={"row"}
          color={
            props.subProjectId === parent.Id
              ? "#fff"
              : openedCard?.includes(parent.Id)
              ? "#0674b9"
              : "rgba(6, 116, 185, 0.5)"
          }
          bgcolor={props.subProjectId === parent.Id ? "#0674b9" : "#fff"}
          onClick={() => {
            if (!disableSelection) {
              props.setSubProjectId(parent.Id);
              props.setSubcontractorError(false);
              props.setSubContractorsEmpty(false);
              props.setCustomerCompanyEmpty(false);
              props.setCustomerOrderReferenceError(false);
              props.setDrawingNbError(false);
              props.setBarStandardError(false);
              props.setBarCoatingError(false);
              props.setBarGradeError(false);
              props.setCustomerCompanyName(null);
              props.setSubcontractor(null);
              props.setFactoryNb(null);
              props.setModify(!props.modify);
            }
          }}
        >
          <Grid
            item
            xs={2}
            md={1}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            onClick={(e) => {
              if (openedCard?.includes(parent.Id)) {
                let arrayIDs = openedCard;
                arrayIDs = arrayIDs.filter(function (itemphase) {
                  return itemphase !== parent.Id;
                });
                setopenedCard(arrayIDs);
              } else {
                let arrayIDs = openedCard;

                setopenedCard(arrayIDs.concat(parent.Id));
              }
            }}
          >
            {parent.Sections?.length > 0 ? (
              openedCard?.includes(parent.Id) ? (
                "-"
              ) : (
                "+"
              )
            ) : (
              <></>
            )}
          </Grid>
          <Grid item paddingLeft={2.5} xs={9} md={10}>
            {parent.Name}
          </Grid>
        </Grid>
        {openedCard?.includes(parent.Id) ? (
          renderSubProjects(parent.Sections)
        ) : (
          <></>
        )}
      </Grid>
    ));
  };

  return (
    // <Grid item container gap={1} marginTop={4}>
    // <Grid item
    // sx={{
    //   fontFamily:"Muli",
    //   color:"#101650",
    //   fontWeight:"bold"
    // }}
    // fontSize={{sm:"13px",lg:"16px"}}
    // >
    //   Select Subproject to get started
    // </Grid>
    // <Grid item container backgroundColor="#f2f2f2" height="800px" >

    // </Grid>
    // </Grid>
    <Grid
      container
      xs={12}
      height={"fit-content"}
      item
      marginLeft={1}
      marginTop={2}
    >
      <Grid
        item
        xs={12}
        className={classes["page-title"]}
        fontSize={{ sm: "13px", lg: "16px" }}
      >
        Select a Project
      </Grid>
      <Grid
        item
        container
        bgcolor={"#f2f2f2"}
        xs={12}
        gap={1}
        paddingBottom={5}
        paddingLeft={1.5}
        paddingTop={1}
      >
        {projects.map((project) => (
          <Grid container key={project.Id}>
            <Grid
              item
              className={classes["Rectangle-1467"]}
              xs={11.5}
              container
              direction={"row"}
              onClick={() => {
                if (props.salesOrderNb > 0) console("");
                else {
                  if (!disableSelection) {
                    getProjectDetails(project.Id);
                    props.setProjectId(project.Id);
                  }
                }
              }}
              color={props.projectId === project.Id ? "#fff" : "#0674b9"}
              bgcolor={props.projectId === project.Id ? "#0674b9" : "#fff"}
            >
              <Grid item paddingLeft={2.5}>
                {project.Name}
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid container height={"fit-content"} item xs={12} paddingTop={2}>
        <Grid
          item
          xs={12}
          className={classes["page-title"]}
          fontSize={{ sm: "13px", lg: "18px" }}
        >
          Select subproject to get started
        </Grid>
        <Grid
          item
          container
          bgcolor={"#f2f2f2"}
          xs={12}
          gap={1}
          paddingBottom={5}
          paddingLeft={1.5}
          paddingTop={1}
        >
          {projectDetailsLoading ? (
            <Grid item container justifyContent="center" marginTop={5}>
              <CircularProgress />
            </Grid>
          ) : (
            <>
              {projectDetails?.map((phase) => (
                <Grid container key={phase.Id}>
                  <Grid
                    item
                    className={classes["Rectangle-1466"]}
                    xs={11.5}
                    container
                    direction={"row"}
                    color={"#0674b9"}
                  >
                    <Grid
                      item
                      xs={2}
                      md={1}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                      }}
                      onClick={(e) => {
                        if (openedCard?.includes(phase.Id)) {
                          let arrayIDs = openedCard;
                          arrayIDs = arrayIDs.filter(function (itemphase) {
                            return itemphase !== phase.Id;
                          });
                          setopenedCard(arrayIDs);
                        } else {
                          let arrayIDs = openedCard;

                          setopenedCard(arrayIDs.concat(phase.Id));
                        }
                      }}
                    >
                      {phase.Sections?.length > 0 ? (
                        openedCard?.includes(phase.Id) ? (
                          "-"
                        ) : (
                          "+"
                        )
                      ) : (
                        <></>
                      )}
                    </Grid>
                    <Grid item paddingLeft={2.5} xs={9} md={10}>
                      {phase.Name}
                    </Grid>
                  </Grid>
                  {openedCard?.includes(phase.Id) ? (
                    renderSubProjects(phase.Sections)
                  ) : (
                    <></>
                  )}
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SubprojectsList;
