import React, { useState } from "react";
import Logo from "../../../Assets/Images/Navbar/logo1.png";
import { Grid } from "@mui/material";
import BgMain from "../../../Assets/Images/main/BgMain.png";
import check from "../../../Assets/Images/home/check.png";
import yellowCheck from "../../../Assets/Images/Pricing/checkActive.png";
import MainContractor from "../Forms/MainContractorForm";
export default function ContractorGrid({
  setLoading,
  account,
  setToken,
  isVerified,
  setIsVerified,
  setAccountName,
  inviterTenantId,
  projectId,
  subProjectId,
  tenantId,
  IsManufacturing
}) {
  const styles = {
    backgroundContainer: {
      backgroundImage: `url(${BgMain})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-Repeat",
      backgroundSize: "cover",
      minHeight: "100vh",
    },

    ProfileText: {
      fontFamily: "Muli",
      fontSize: "36px",
      fontWeight: "bold",
      color: "#101650",
    },
    YouGetText: {
      fontFamily: "Muli",
      color: "#181818",
      fontSize: "15px",
    },
  };

  return (
    <>
      <div style={styles.backgroundContainer}>
        <Grid
          className="mainR"
          container
          rowGap={4}
          paddingLeft={{ md: 15, xs: 1 }}
          paddingTop={2.5}
        >
          <Grid item xs={12} paddingLeft={{ md: 10 }} className="registerLogo">
            <a href="/">
              <img src={Logo} alt="logo" width="15%" />
            </a>
          </Grid>
          <Grid item container rowSpacing={{ xs: 2 }} justifyContent={"center"}>
            <Grid
              item
              md={12}
              lg={4}
              container
              direction={"column"}
              spacing={3}
              paddingLeft={{ xs: 3 }}
            >
              {tenantId ? (
                <Grid
                  item
                  style={{
                    fontFamily: "Muli",
                    fontSize: 19,
                    fontWeight: "bold",
                    lineHeight: 1.47,
                    textAlign: "left",
                    color: "#e1e319",
                  }}
                >
                  Guest Registration
                </Grid>
              ) : (
                <></>
              )}
              <Grid item sx={styles.ProfileText}>
                Main Contractor
              </Grid>
              {tenantId ? (
                <>
                  {
                    IsManufacturing ?
                      <Grid
                        item
                        sx={styles.YouGetText}
                        container
                        spacing={{ md: 3, xs: 1 }}
                      >
                        <Grid item container spacing={1}>
                          <Grid item> As a guest, you get: </Grid>
                        </Grid>
                        <Grid item container spacing={1}>
                          <Grid item>
                            <img src={yellowCheck} alt="check"></img>
                          </Grid>

                          <Grid item> Sales Order </Grid>
                        </Grid>
                        <Grid item container spacing={1}>
                          <Grid item>
                            <img src={yellowCheck} alt="check"></img>
                          </Grid>
                          <Grid item> Track Order Progress </Grid>
                        </Grid>
                      </Grid>
                      :
                      <Grid
                        item
                        sx={styles.YouGetText}
                        container
                        spacing={{ md: 3, xs: 1 }}
                      >
                        <Grid item container spacing={1}>
                          <Grid item> As a guest, you get: </Grid>
                        </Grid>
                        <Grid item container spacing={1}>
                          <Grid item>
                            <img src={yellowCheck} alt="check"></img>
                          </Grid>

                          <Grid item> Access to assigned projects </Grid>
                        </Grid>
                        <Grid item container spacing={1}>
                          <Grid item>
                            <img src={yellowCheck} alt="check"></img>
                          </Grid>
                          <Grid item> Manage internal teams </Grid>
                        </Grid>
                        <Grid item container spacing={1}>
                          <Grid item> With subscription, you get: </Grid>
                        </Grid>
                        <Grid item container spacing={1}>
                          <Grid item>
                            <img src={check} alt="check"></img>
                          </Grid>

                          <Grid item> Manage clients </Grid>
                        </Grid>
                        <Grid item container spacing={1}>
                          <Grid item>
                            <img src={check} alt="check"></img>
                          </Grid>
                          <Grid item> Up to 1000 drawings </Grid>
                        </Grid>
                      </Grid>
                  }
                </>
              ) : (
                <Grid
                  item
                  sx={styles.YouGetText}
                  container
                  spacing={{ md: 3, xs: 1 }}
                >
                  <Grid item container spacing={1}>
                    <Grid item> With subscription, you get: </Grid>
                  </Grid>
          
                  <Grid item container spacing={1}>
                    <Grid item>
                      <img src={check} alt="check"></img>
                    </Grid>
                    <Grid item> Manage multiple engineering disciplines </Grid>
                  </Grid>
                  <Grid item container spacing={1}>
                    <Grid item>
                      <img src={check} alt="check"></img>
                    </Grid>
                    <Grid item> Invite multiple subcontractors </Grid>
                  </Grid>
                  <Grid item container spacing={1}>
                    <Grid item>
                      <img src={check} alt="check"></img>
                    </Grid>
                    <Grid item> Manage internal teams </Grid>
                  </Grid>
                  <Grid item container spacing={1}>
                    <Grid item>
                      <img src={check} alt="check"></img>
                    </Grid>
                    <Grid item> Manage multiple projects </Grid>
                  </Grid>
                </Grid>
              )}
              {
                tenantId ?
                  <></>
                  :
                  <Grid item sx={styles.YouGetText}>
                    Already a member?{" "}
                    <a
                      href="/login"
                      onMouseOver={(e) => (e.target.style.fontWeight = "bold")}
                      onMouseLeave={(e) => (e.target.style.fontWeight = "normal")}
                      style={{ textDecoration: "none", color: "#0674b9" }}
                    >
                      Login
                    </a>
                  </Grid>
              }

            </Grid>
            <Grid
              item
              md={12}
              lg={5}
              marginLeft={{ xs: 2 }}
              paddingBottom={{ xs: 12 }}
            >
              <MainContractor
                setLoading={setLoading}
                account={account}
                setToken={setToken}
                isVerified={isVerified}
                setIsVerified={setIsVerified}
                setAccountName={setAccountName}
                inviterTenantId={inviterTenantId}
                projectId={projectId}
                subProjectId={subProjectId}
                tenantId={tenantId}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
