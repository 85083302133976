import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import SaasBack from "../../../../Assets/Images/Projects/bgTableProject.png";
import classes from "../../../../Assets/Styles/projects.module.css";
import { TextField, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Stack } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as constFile from "../../../../../projectConst";
import MenuPic from "../../../../Assets/Images/Table/menuPic.png";
import * as subServices from "../../../../Services/subprojects-services.proxy";
import logo from "../../../../Assets/Images/Table/logo.png";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

const getSubprojects = subServices.getSubProjects;
const changeStatus = subServices.changeStatus;

//Statuses
const ACTIVE = constFile.PACTIVE;
const ARCHIVED = constFile.PARCHIVED;

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};
export default function Phases(props) {
  const UserPermissions = useSelector(
    (state) => state.userData.UserPermissions
  );

  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    props.setRegister(true);
  }, [props.setRegister]);

  useEffect(() => {
    props.setNaved(false);
  }, [props.setNaved]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [subprojectsTable, setSubprojectsTable] = useState([]);
  const [dataLength, setdataLength] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const [pageSize, setPageSize] = useState(25);
  const [phaseId, setPhaseId] = useState(localStorage.getItem("phaseId"));
  const [projectId, setProjectId] = useState(localStorage.getItem("idp"));
  const [phaseName, setPhaseName] = useState(localStorage.getItem("phaseName"));

  const navigate = useNavigate();

  const SublistData = {
    Pagination: {
      PageSize: pageSize,
      PageNumber: pageNumber,
    },
    Search: searchText,
    PhaseId: phaseId,
  };

  const [modify, setModify] = useState(false);

  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    let apiTest = true;
    setLoadingData(true);
    getSubprojects(SublistData).then((x) => {
      if (apiTest) {
        setSubprojectsTable(x.SubProjects);
        setdataLength(x.Total);
        setLoadingData(false);
      }
    });
    return () => {
      apiTest = false;
    };
  }, [searchText, modify, pageNumber, pageSize]);

  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setSearchText(lowerCase);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowData, setRowData] = useState();
  const handleClick = async (event, params) => {
    setRowData(params);

    setAnchorEl(event.target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const columns = [
    {
      field: "action",
      headerName: "Actions",
      sortable: false,

      renderCell: (params) => {
        return (
          <img
            src={MenuPic}
            alt="menu"
            onClick={(e) => handleClick(e, params)}
          ></img>
        );
      },
    },
    {
      field: "Name",
      headerName: "Subproject Name",
      width: 200,
      renderCell: (params) => (
        <span
          style={{
            color: params.row.Status === "Archived" ? "grey" : "#0674b9",
          }}
        >
          {params.row?.Name}
        </span>
      ),
    },
    {
      field: "Type",
      headerName: "Subproject Type",
      width: 140,
      resizeable: true,
      renderCell: (params) => (
        <span
          style={{
            color: params.row.Status === "Archived" ? "grey" : "#0674b9",
          }}
        >
          {params.row?.Type}
        </span>
      ),
    },
    {
      field: "Picture",
      headerName: "Subproject Picture",
      width: 130,
      renderCell: (params) => (
        <img
          src={params.row?.Picture ? params.row?.Picture?.URL : logo}
          alt="menu"
          width="25%"
        ></img>
      ),
    },
    {
      field: "Code",
      headerName: "Subproject Code",
      width: 120,
      renderCell: (params) => (
        <span
          style={{
            color: params.row.Status === "Archived" ? "grey" : "#0674b9",
          }}
        >
          {params.row?.Code}
        </span>
      ),
    },
    {
      field: "Abbreviation",
      headerName: "Subproject Abbreviation",
      width: 200,
      renderCell: (params) => <span>{params.row?.Abbreviation}</span>,
    },
    {
      field: "Description",
      headerName: "Subproject Description",
      width: 200,
      renderCell: (params) => (
        <span
          style={{
            color: params.row.Status === "Archived" ? "grey" : "#0674b9",
          }}
        >
          {params.row?.Description}
        </span>
      ),
    },
    {
      field: "ResponsibilityId",
      headerName: "Subproject Responsibility",
      width: 180,
      renderCell: (params) => (
        <span
          style={{
            color: params.row.Status === "Archived" ? "grey" : "#0674b9",
          }}
        >
          {params.row?.ResponsibilityId === (1 || "1")
            ? "Inhouse"
            : localStorage.getItem("AccountType") === "1"
              ? "SubContracted"
              : "Outsourced"}
        </span>
      ),
    },
    {
      field: "Status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => (
        <span
          style={{
            color: params.row.Status === "Archived" ? "grey" : "green",
          }}
        >
          {params.row?.Status.slice(0, 10)}
        </span>
      ),
    },
    {
      field: "StartDate",
      headerName: "Start Date",
      width: 150,
      renderCell: (params) => (
        <span
          style={{
            color: params.row.Status === "Archived" ? "grey" : "#0674b9",
          }}
        >
          {new Date(params.row?.StartDate).toLocaleDateString()}
        </span>
      ),
    },
    {
      field: "EndDate",
      headerName: "End Date",
      width: 150,
      renderCell: (params) => (
        <span
          style={{
            color: params.row.Status === "Archived" ? "grey" : "#0674b9",
          }}
        >
          {new Date(params.row?.EndDate).toLocaleDateString()}
        </span>
      ),
    },
  ];
  return (
    <div
      style={{
        minHeight: "90vh",
        backgroundImage: `url(${SaasBack})`,
        backgroundSize: "cover",
      }}
    >
      {disabled ? (
        <Grid
          item
          container
          bgcolor={"rgba(225, 227, 25, 0.5)"}
          xs={12}
          marginTop={1}
          padding={1}
        >
          <Grid item md={4}></Grid>
          <Grid
            item
            md={6}
            style={{
              fontFamily: "Muli",
              fontSize: "17px",
              fontWeight: "bold",
              textAlign: "left",
              color: "#101650",
            }}
          >
            <Grid>
              {localStorage.getItem("IsSubscribedBefore") === "true" ?
                "Please subscribe before you start working"
                :
                (localStorage.getItem("isGuest") === "true" &&
                  localStorage.getItem("AccountType") !== "1") ||
                  (localStorage.getItem("isParentCreator") === (false || "false") &&
                    localStorage.getItem("AccountType") !== "1")
                  ? "Yo don't have permission to create/edit/archive/re-activate subprojects"
                  : (localStorage.getItem("isTeamMember") === "true" &&
                    !UserPermissions?.find(
                      (x) => x.Name === "Project Manager"
                    ) &&
                    !UserPermissions?.find((x) => x.Name === "Admin") &&
                    !UserPermissions?.find(
                      (x) => x.Name === "Engineering Manager"
                    )) ||
                    localStorage.getItem("isParentCreator") === (false || "false")
                    ? "You don't have permission to create/edit or archive/re-activate a subproject"
                    : localStorage.getItem("isTeamMember") === "true" &&
                      UserPermissions?.find((x) => x.Name === "Engineering Manager")
                      ? "You don't have permission to archive or reactivate a subproject"
                      : "You don't have permission to edit a subproject"}
            </Grid>
          </Grid>

          <Grid item md={2} container>
            {localStorage.getItem("isTeamMember") &&
              localStorage.getItem("IsSubscribedBefore") !== "true" ? (
              <Grid item xs={8}></Grid>
            ) : (
              <Grid
                item
                onClick={() => {
                  props.setSetupProfile(true);
                  props.setSetupMenu(true);
                  navigate("/saas/profileSettings");
                }}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
              >
                <button
                  style={{
                    padding: "5px",
                    borderRadius: "5px",
                    backgroundColor: "#101650",
                    fontFamily: "Muli",
                    fontSize: "13px",
                    textAlign: "center",
                    border: 0,
                    color: "#fff",
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    props.setSetupProfile(true);
                    props.setSetupMenu(true);
                    navigate("/saas/profileSettings");
                  }}
                >
                  Subscribe
                </button>
              </Grid>
            )}
            <Grid
              item
              paddingLeft={1}
              onClick={() => {
                setDisabled(false);
              }}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
            >
              <CloseIcon fontSize="medium" />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <Grid container paddingTop={5} paddingLeft={2.5}>
        <Grid item xs={12} container alignItems="center" paddingLeft="1%">
          <Grid
            item
            xs={2}
            md={2}
            sx={{
              fontFamily: "Muli",
              fontSize: 18,
              fontWeight: "bold",
              lineHeight: 1.44,
              textAlign: "left",
              color: "#101650",
            }}
          >
            {phaseName} - Subprojects
          </Grid>
          <Grid
            item
            xs={7}
            md={8}
            sx={{
              border: "solid 0.1px #fff",
              backgroundColor: "#fff",
              height: 0,
            }}
          ></Grid>

          <Grid item xs={1.3} md={1.2} paddingLeft={5}>
            <button
              className={classes["back-button"]}
              onClick={() => {
                if (localStorage.getItem("isParentCreator")) {
                  localStorage.removeItem("isParentCreator");
                  localStorage.removeItem("PhaseCreator");
                }
                if (localStorage.getItem("phaseName"))
                  localStorage.removeItem("phaseName");
                if (localStorage.getItem("phaseId"))
                  localStorage.removeItem("phaseId");
                localStorage.removeItem("subId");
                localStorage.removeItem("viewSubPro");
                navigate(-1);
              }}
            >
              Go back
            </button>
          </Grid>
          <Grid item xs={1.3} md={0.7}>
            <button
              disabled={localStorage.getItem("IsSubscribedBefore") === "true"}
              className={classes["AddButton"]}
              onClick={() => {
                if (
                  ((localStorage.getItem("isGuest") === "true" ||
                    localStorage.getItem("isParentCreator") ===
                    (false || "false")) &&
                    localStorage.getItem("AccountType") !== "1") ||
                  (localStorage.getItem("isTeamMember") === "true" &&
                    !UserPermissions?.find(
                      (x) => x.Name === "Project Manager"
                    ) &&
                    !UserPermissions?.find((x) => x.Name === "Admin") &&
                    !UserPermissions?.find(
                      (x) => x.Name === "Engineering Manager"
                    ))
                ) {
                  for (let i = 0; i < UserPermissions?.length; i++) {
                    for (
                      let j = 0;
                      j < UserPermissions[i].Permissions.length;
                      j++
                    ) {
                      if (
                        UserPermissions[i].Permissions[j].Name === "Sub Project"
                      ) {
                        if (
                          UserPermissions[i].Permissions[j].Actions.find(
                            (a) => a.Name === "Create"
                          ) &&
                          localStorage.getItem("isGuest") !== "true"
                        ) {
                          localStorage.removeItem("subId");
                          navigate(
                            `/saas/projects/subprojects?id=${projectId}`
                          );
                          setDisabled(false);
                          break;
                        } else {
                          setDisabled(true);
                        }
                      } else {
                        setDisabled(true);
                      }
                    }
                  }
                } else {
                  localStorage.removeItem("subId");
                  // localStorage.setItem("viewSubPro", true);
                  navigate(`/saas/projects/subprojects?id=${projectId}`);
                }
              }}
            >
              Add
            </button>
          </Grid>
        </Grid>
        <Grid
          item
          md={10}
          xs={11.5}
          container
          direction={"row-reverse"}
          marginLeft={{ md: "14%", xs: 0 }}
          paddingTop="1%"
        >
          <Box
            sx={{
              p: 0.5,
              pb: 0,
            }}
          >
            <TextField
              variant="standard"
              fullWidth
              onChange={inputHandler}
              placeholder="Search this list…"
              InputProps={{
                startAdornment: (
                  <SearchIcon
                    fontSize="small"
                    htmlColor={"#707070"}
                    style={{ paddingLeft: 5 }}
                  />
                ),
              }}
              sx={{
                fontFamily: "Muli",
                backgroundColor: "white",
                borderRadius: 1,
                width: {
                  xs: 1,
                  sm: "auto",
                },
                m: (theme) => theme.spacing(1, 0.5, 1.5),
                "& .MuiSvgIcon-root": {
                  mr: 0.5,
                },
                "& .MuiInput-underline:before": {
                  borderBottom: 0,
                  borderColor: "white",
                },
              }}
            />
          </Box>
        </Grid>

        <div
          style={{
            height: 500,
            width: "98.5%",
            fontFamily: "Muli",
            backgroundColor: "white",
            marginTop: 10,
          }}
        >
          <DataGrid
            initialState={{
              pagination: {
                pageSize: 25,
              },
            }}
            pagination
            paginationMode="server"
            rows={subprojectsTable ? subprojectsTable : []}
            columns={columns}
            headerHeight={40}
            density={"compact"}
            showColumnRightBorder
            sx={{
              [`& .${gridClasses.row}`]: {
                color: "#0674b9",
                overflow: "auto",
                bgcolor: (theme) =>
                  theme.palette.mode === "light" ? grey[0] : grey[500],
              },

              [`& .${gridClasses.columnHeader}`]: {
                bgcolor: "rgb(211 225 238)",
                color: "rgb(17 24 84)",
              },
              [`& .${gridClasses.columnHeaderTitle}`]: {
                fontFamily: "Muli",
                fontWeight: "bold",
              },

              [`& .${gridClasses.footerContainer}`]: {
                bgcolor: "rgb(216 235 247)",
                minHeight: "30px",
              },
              fontFamily: "Muli",
              fontSize: "13px",
              borderRadius: 0,
              border: 0,
            }}
            disableColumnFilter
            getRowId={(row) => row.Id}
            rowCount={dataLength}
            hideFooter={props.paging}
            onPageChange={(newPage) => {
              if (pageSize > dataLength) {
                setPageNumber(1);
              } else setPageNumber(newPage + 1);
            }}
            onPageSizeChange={(newPage) => {
              setPageSize(newPage);
            }}
            pageSize={pageSize}
            loading={loadingData}
            components={{
              NoRowsOverlay: () => (
                <Stack className={classes["EmptyMessage"]}>
                  No subprojects to display
                </Stack>
              ),
            }}
          />
        </div>

        <Menu
          id="more-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          {
            rowData?.row.StatusId === ARCHIVED ? ""
              :
              <MenuItem
                onClick={(event) => {
                  if (rowData?.row.IsCreator &&
                    localStorage.getItem("IsSubscribedBefore") !== "true") {
                    localStorage.setItem("subId", rowData?.row.Id);
                    localStorage.setItem("subCreator", rowData?.row.IsCreator);
                    localStorage.removeItem("viewSubPro");
                    navigate(`/saas/projects/subprojects?id=${projectId}`);
                  } else {
                    setDisabled(true);
                  }
                }}
                style={{
                  fontFamily: "Muli",
                  fontSize: "13px",
                  color: "#444",
                  display: "block",
                  textAlign: "center",
                }}
              >
                Edit
              </MenuItem>
          }

          <MenuItem
            onClick={() => {
              navigate(`/saas/projects/subprojects?id=${projectId}`);
              localStorage.setItem("subId", rowData?.row.Id);
              localStorage.setItem("viewSubPro", true);
              localStorage.setItem(
                "PhaseCreator",
                localStorage.getItem("isParentCreator")
              );
              if (!rowData?.row.IsCreator)
                localStorage.setItem("noCreator", true);
            }}
            style={{
              fontFamily: "Muli",
              fontSize: "13px",
              color: "#444",
              textAlign: "center",
              display: "block",
            }}
          >
            View
          </MenuItem>
          {
            localStorage.getItem("IsSubscribedBefore") === "true" || localStorage.getItem("isGuest") === "true" ? <></>
              :
              <>
                {rowData?.row.Status === "Archived" ? (
                  <MenuItem
                    onClick={() => {
                      if (
                        (localStorage.getItem("isGuest") === "true" &&
                          localStorage.getItem("AccountType") !== "1") ||
                        (localStorage.getItem("isTeamMember") === "true" &&
                          !UserPermissions?.find(
                            (x) => x.Name === "Project Manager"
                          ) &&
                          !UserPermissions?.find((x) => x.Name === "Admin") &&
                          !UserPermissions?.find(
                            (x) => x.Name === "Engineering Manager"
                          )) ||
                        (localStorage.getItem("isTeamMember") === "true" &&
                          UserPermissions?.find(
                            (x) => x.Name === "Engineering Manager"
                          )) ||
                        (localStorage.getItem("isParentCreator") ===
                          (false || "false") &&
                          localStorage.getItem("AccountType") !== "1") ||
                        (!rowData?.row.IsCreator &&
                          localStorage.getItem("AccountType") !== "1")
                      )
                        setDisabled(true);
                      else {
                        changeStatus(rowData.id, ACTIVE).then(() => {
                          setModify(!modify);
                          handleClose();
                        })
                      }
                    }}
                    style={{
                      fontFamily: "Muli",
                      fontSize: "13px",
                      color: "#444",
                      textAlign: "center",
                      display: "block",
                    }}
                  >
                    Re-Activate
                  </MenuItem>
                ) : (
                  <MenuItem
                    onClick={() => {
                      if (
                        (localStorage.getItem("isGuest") === "true" &&
                          localStorage.getItem("AccountType") !== "1") ||
                        (localStorage.getItem("isTeamMember") === "true" &&
                          !UserPermissions?.find(
                            (x) => x.Name === "Project Manager"
                          ) &&
                          !UserPermissions?.find((x) => x.Name === "Admin") &&
                          !UserPermissions?.find(
                            (x) => x.Name === "Engineering Manager"
                          )) ||
                        (localStorage.getItem("isTeamMember") === "true" &&
                          UserPermissions?.find(
                            (x) => x.Name === "Engineering Manager"
                          )) ||
                        (localStorage.getItem("isParentCreator") ===
                          (false || "false") &&
                          localStorage.getItem("AccountType") !== "1") ||
                        (!rowData?.row.IsCreator &&
                          localStorage.getItem("AccountType") !== "1")
                      )
                        setDisabled(true);
                      else {
                        changeStatus(rowData.id, ARCHIVED).then(() => {
                          setModify(!modify);
                          handleClose();
                        })
                      }
                    }}
                    style={{
                      fontFamily: "Muli",
                      fontSize: "13px",
                      color: "#444",
                      textAlign: "center",
                      display: "block",
                    }}
                  >
                    Archive
                  </MenuItem>
                )}</>
          }

        </Menu>
        {/* <Grid
          item
          md={11.82}
          xs={11.82}
          textAlign={"center"}
          fontFamily={"Muli"}
          color={"#0674b9"}
          padding={1}
          fontWeight={"bold"}
          bgcolor="rgb(216 235 247)"
        >
          View Archived
        </Grid> */}
      </Grid>
    </div>
  );
}
