import { Grid } from "@mui/material";
import React from "react";
import QRCode from "react-qr-code";
const styles = {
  container: {
    borderStyle: "dashed",
    borderColor: "#707070",
    borderWidth: "1px",
  },
  label: {
    fontFamily: "Muli",
    fontSize: "12px",
    lineHeight: 1.33,
    textAlign: "left",
    color: "#101650",
  },
  data: {
    fontFamily: "Muli",
    fontSize: "12px",
    fontWeight: "bold",
    lineHeight: 1.33,
    textAlign: "left",
    color: "#101650",
  },
};

export default function DeliveryNoteForm(props) {
  return (
    <Grid
      item
      xs={12}
      sx={{
        width: { xs: "100%", md: "835px" },
        backgroundColor: "#fff",
        marginTop: "3%",
        padding: "1%",
      }}
    >
      {/* companyProfile */}
      <Grid
        item
        xs={12}
        container
        alignItems="flex-start"
        justifyContent="space-between"
        direction="row"
      >
        {props.deliveryDetails.CompanyDetails.CompanyProfilePic ? (
          <Grid
            item
            sx={styles.container}
            width={{ xs: "auto", sm: "250px" }}
            padding="1%"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Grid item container direction="column" gap={1}>
              <Grid item sx={styles.label}>
                Company Profile Pic.
              </Grid>
              <Grid item sx={styles.data} width="150px" height="150px">
                <img
                  src={props.deliveryDetails.CompanyDetails.CompanyProfilePic}
                  width="150px"
                  alt="img"
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}

        <Grid item direction="column">
          <Grid
            item
            container
            marginBottom="1%"
            sx={styles.container}
            width={{ xs: "auto", sm: "250px" }}
            padding="1%"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Grid item container direction="column" gap={1}>
              <Grid item sx={styles.label}>
                Company Name
              </Grid>
              <Grid item sx={styles.data}>
                {props.deliveryDetails.CompanyDetails.CompanyName}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            marginBottom="1%"
            sx={styles.container}
            width={{ xs: "auto", sm: "250px" }}
            padding="1%"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Grid item container direction="column" gap={1}>
              <Grid item sx={styles.label}>
                Company Email
              </Grid>
              <Grid item sx={styles.data}>
                {props.deliveryDetails.CompanyDetails.CompanyEmail}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            marginBottom="1%"
            sx={styles.container}
            width={{ xs: "auto", sm: "250px" }}
            padding="1%"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Grid item container direction="column" gap={1}>
              <Grid item sx={styles.label}>
                Company Address
              </Grid>
              <Grid item sx={styles.data}>
                {props.deliveryDetails.CompanyDetails.CompanyAddress}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            sx={styles.container}
            width={{ xs: "auto", sm: "250px" }}
            padding="1%"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Grid item container direction="column" gap={1}>
              <Grid item sx={styles.label}>
                Company Website
              </Grid>
              <Grid item sx={styles.data}>
                {props.deliveryDetails.CompanyDetails.CompanyWebsite}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* order */}
      <Grid
        item
        xs={12}
        container
        alignItems="flex-start"
        justifyContent="space-between"
        marginTop="1%"
      >
        <Grid item direction="column">
          <Grid
            item
            marginBottom="5%"
            sx={styles.container}
            direction="row"
            width={{ xs: "auto", sm: "450px" }}
            padding="1%"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Grid item xs={12} container direction="row">
              <Grid item xs={6} container direction="column" gap={1}>
                <Grid item sx={styles.label}>
                  Delivery Note Code
                </Grid>
                <Grid item sx={styles.data}>
                  {props.deliveryDetails.DeliveryNote.Code}
                </Grid>
                <Grid item sx={styles.label}>
                  Delivery Status
                </Grid>
                <Grid item sx={styles.data}>
                  {props.deliveryDetails.DeliveryNote.Satuts}
                </Grid>
              </Grid>
              <Grid item xs={6} container direction="column" gap={1}>
                <Grid item sx={styles.label}>
                  Delivery Date
                </Grid>
                <Grid item sx={styles.data}>
                  {new Date(
                    props.deliveryDetails.DeliveryNote.Date
                  )?.toLocaleString()}
                </Grid>
                <Grid item sx={styles.label}>
                  Acknowledgement Status
                </Grid>
                <Grid item sx={styles.data}>
                  {props.deliveryDetails.DeliveryNote.AcknowledgementStatus}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            marginBottom="1%"
            sx={styles.container}
            direction="row"
            width={{ xs: "auto", sm: "450px" }}
            padding="1%"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Grid item xs={12} container direction="row">
              <Grid item xs={6} container direction="column" gap={1}>
                <Grid item sx={styles.label}>
                  Sale Order Code
                </Grid>
                <Grid item sx={styles.data}>
                  {props.deliveryDetails.SalesOrder.Code}
                </Grid>
                <Grid item sx={styles.label}>
                  Contact Person
                </Grid>
                <Grid item sx={styles.data}>
                  {props.deliveryDetails.SalesOrder.ContactPerson}
                </Grid>
              </Grid>
              <Grid item xs={6} container direction="column" gap={1}>
                <Grid item sx={styles.label}>
                  Customer Company Name
                </Grid>
                <Grid item sx={styles.data}>
                  {props.deliveryDetails.SalesOrder.CustomerCompanyName}
                </Grid>
                <Grid item sx={styles.label}>
                  Phone Number
                </Grid>
                <Grid item sx={styles.data}>
                  {props.deliveryDetails.SalesOrder.PhoneNumber}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            marginBottom="1%"
            sx={styles.container}
            direction="row"
            width={{ xs: "auto", sm: "450px" }}
            padding="1%"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Grid item xs={12} container direction="row">
              <Grid item xs={6} container direction="column" gap={1}>
                <Grid item sx={styles.label}>
                  Project Name
                </Grid>
                <Grid item sx={styles.data}>
                  {props.deliveryDetails.SalesOrder.Project}
                </Grid>
              </Grid>
              <Grid item xs={6} container direction="column" gap={1}>
                <Grid item sx={styles.label}>
                  Phase Name
                </Grid>
                <Grid item sx={styles.data}>
                  {props.deliveryDetails.SalesOrder.Phase}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            marginBottom="1%"
            sx={styles.container}
            direction="row"
            width={{ xs: "auto", sm: "450px" }}
            padding="1%"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Grid item xs={12} container direction="row">
              <Grid item xs={6} container direction="column" gap={1}>
                <Grid item sx={styles.label}>
                  Subproject Name
                </Grid>
                <Grid item sx={styles.data}>
                  {props.deliveryDetails.SalesOrder.Subproject}
                </Grid>
                <Grid item sx={styles.label}>
                  Customer Order Ref.
                </Grid>
                <Grid item sx={styles.data}>
                  {props.deliveryDetails.SalesOrder.CustomerRefNumber}
                </Grid>
              </Grid>
              <Grid item xs={6} container direction="column" gap={1}>
                <Grid item sx={styles.label}>
                  Phase Address
                </Grid>
                <Grid item sx={styles.data}>
                  {props.deliveryDetails.SalesOrder.PhaseAddress}
                </Grid>
                <Grid item sx={styles.label}>
                  Drawing No.
                </Grid>
                <Grid item sx={styles.data}>
                  {props.deliveryDetails.SalesOrder.DrawingNumber}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          sx={styles.container}
          width="150px"
          height="170px"
          padding="1%"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Grid item container direction="column" gap={1}>
            <Grid item sx={styles.label}>
              QR Code
            </Grid>
            <Grid item sx={styles.data}>
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={props.deliveryDetails.DeliveryNote.QrCode}
                viewBox={`0 0 256 256`}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* linesData */}
      <Grid
        item
        xs={12}
        direction="column"
        padding="1%"
        container
        alignItems="flex-start"
        justifyContent="flex-start"
        sx={styles.container}
      >
        {props.deliveryDetails.Members?.map((mem) => (
          <Grid item container gap={1} key={mem}>
            <Grid item container direction={"row"}>
              <Grid item xs={4}>
                <Grid item sx={styles.label}>
                  Member Name:
                </Grid>
                <Grid item sx={styles.data}>
                  {mem.Name}
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid item sx={styles.label}>
                  Member Quantity:
                </Grid>
                <Grid item sx={styles.data}>
                  {mem.Quantity}
                </Grid>
              </Grid>
            </Grid>
            {mem.Lines.map((line) => (
              <Grid item container direction={"row"} gap={3.2} key={line}>
                <Grid item xs={3} container>
                  <Grid item sx={styles.label} xs={12}>
                    Shape:
                  </Grid>
                  <Grid item sx={styles.data}>
                    <img src={line.Shape} width={100} alt={"img"} />
                  </Grid>
                </Grid>
                <Grid item xs={3} container direction={"column"}>
                  <Grid item sx={styles.label}>
                    Bar Mark No.:
                  </Grid>
                  <Grid item sx={styles.data}>
                    {line.BarMarkNo}
                  </Grid>
                  <Grid item sx={styles.label}>
                    Bar Dia(mm):
                  </Grid>
                  <Grid item sx={styles.data}>
                    {line.BarDiameter}
                  </Grid>
                  <Grid item sx={styles.label}>
                    Bar Ref Grp:
                  </Grid>
                  <Grid item sx={styles.data}>
                    {line.RefGroupAbbreviation}
                  </Grid>
                </Grid>
                <Grid item xs={3} container direction={"column"}>
                  <Grid item sx={styles.label}>
                    Bars Quantity:
                  </Grid>
                  <Grid item sx={styles.data}>
                    {line.Quantity}
                  </Grid>
                  <Grid item sx={styles.label}>
                    Total No. Of Bars:
                  </Grid>
                  <Grid item sx={styles.data}>
                    {line.TotalNumberOfBars}
                  </Grid>
                  <Grid item sx={styles.label}>
                    Total Weight Of Bars(kg):
                  </Grid>
                  <Grid item sx={styles.data}>
                    {line.ActualWeight}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
      {/* membersData */}
      <Grid
        item
        xs={12}
        container
        alignItems="flex-start"
        justifyContent="space-between"
        marginTop="1%"
      >
        <Grid item direction="column">
          <Grid
            item
            marginBottom="1%"
            sx={styles.container}
            direction="row"
            width={{ xs: "auto", sm: "450px" }}
            padding="1%"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            {props.deliveryDetails.Members?.map((m) => (
              <Grid key={m} item xs={12} container direction="row">
                <Grid item xs={6} container direction="column" gap={1}>
                  <Grid item sx={styles.label}>
                    Member Name
                  </Grid>
                  <Grid item sx={styles.data}>
                    {m.Name}
                  </Grid>
                </Grid>
                <Grid item xs={6} container direction="column" gap={1}>
                  <Grid item sx={styles.label}>
                    Member Weight(kg)
                  </Grid>
                  <Grid item sx={styles.data}>
                    {m.Lines.reduce(function (prev, current) {
                      return prev + +current.ActualWeight;
                    }, 0)}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          item
          sx={styles.container}
          width="200px"
          padding="1%"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Grid item container direction="column" gap={1}>
            <Grid item sx={styles.label}>
              Total Weight(kg)
            </Grid>
            <Grid item sx={styles.data}>
              {props.deliveryDetails.Members.flatMap((x) => x.Lines).reduce(
                function (prev, current) {
                  return prev + +current.ActualWeight;
                },
                0
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* DriverData */}
      <Grid
        item
        xs={12}
        container
        alignItems="flex-start"
        justifyContent="space-between"
        marginTop="1%"
      >
        <Grid item direction="column">
          {localStorage.getItem("planIdManu")?.includes("Pro") ? (
            <Grid
              item
              marginBottom="1%"
              sx={styles.container}
              direction="row"
              width={{ xs: "100%", sm: "450px" }}
              padding="1%"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Grid item xs={12} container direction="row">
                <Grid item xs={6} container direction="column" gap={1}>
                  <Grid item sx={styles.label}>
                    Truck Code
                  </Grid>
                  <Grid item sx={styles.data}>
                    {props.deliveryDetails.Delivery.TruckCode}
                  </Grid>
                  <Grid item sx={styles.label}>
                    Driver Name
                  </Grid>
                  <Grid item sx={styles.data}>
                    {props.deliveryDetails.Delivery.DriverName}
                  </Grid>
                </Grid>
                <Grid item xs={6} container direction="column" gap={1}>
                  <Grid item sx={styles.label}>
                    License Plate No.
                  </Grid>
                  <Grid item sx={styles.data}>
                    {props.deliveryDetails.Delivery.LicensePlateNumber}
                  </Grid>
                  <Grid item sx={styles.label}>
                    Driver ID Number
                  </Grid>
                  <Grid item sx={styles.data}>
                    {props.deliveryDetails.Delivery.DriverIdNumber}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
        <Grid
          item
          sx={styles.container}
          width="200px"
          padding="1%"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Grid item container direction="column" gap={1}>
            <Grid item sx={styles.label}>
              Supplier Signature
            </Grid>
            <Grid item sx={styles.data}></Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* RecieverData */}
      <Grid
        item
        xs={12}
        container
        alignItems="flex-start"
        justifyContent="space-between"
        marginTop="1%"
      >
        <Grid item direction="column">
          <Grid
            item
            marginBottom="1%"
            sx={styles.container}
            direction="row"
            width={{ xs: "auto", sm: "450px" }}
            padding="1%"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Grid item xs={12} container direction="row">
              <Grid item xs={6} container direction="column" gap={1}>
                <Grid item sx={styles.label}>
                  Reciept Date
                </Grid>
                <Grid item sx={styles.data}>
                  {props.deliveryDetails.ReceiptDate}
                </Grid>
              </Grid>
              <Grid item xs={6} container direction="column" gap={1}>
                <Grid item sx={styles.label}>
                  Receiver Name
                </Grid>
                <Grid item sx={styles.data}>
                  {props.deliveryDetails.ReceiverName}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          sx={styles.container}
          width="200px"
          padding="1%"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Grid item container direction="column" gap={1}>
            <Grid item sx={styles.label}>
              Receiver Signature
            </Grid>
            <Grid item sx={styles.data}>
              ReceiverSign
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
