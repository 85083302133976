import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  MenuItem,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableBody,
} from "@mui/material";

import AddValueIcon from "../../../../../Assets/Images/Factory/plusIcon.png";
import RemoveIcon from "../../../../../Assets/Images/SalesOrder/RemoveIcon.png";
import classes from "../../../../../Assets/Styles/InventorySetup.module.css";
import * as inventoryServices from "../../../../../Services/inventory-setup-services.proxy";

const createInventoryValue = inventoryServices.createInventoryValue;
const updateInventoryValue = inventoryServices.updateInventoryValue;

const Form1 = (props) => {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [emptyField, setEmptyField] = useState(false);
  const [emptyFieldMessage, setEmptyFieldMessage] = useState("");
  const [isdisabled, setIsDisabled] = useState(false);
  const [saving, setSaving] = useState(false);

  const checkAllFilled = () => {
    console.log(InventoryValueData);

    let empty = false;
    props.itemValuesData[0]?.Types.map((item) => {
      if (item.Diameters.length === 0 || !item.Diameters) {
        empty = true;
        setEmptyFieldMessage(
          "There is an empty field(s) in item Type " + item.Name
        );
        setEmptyField(true);
      }
      item.Diameters?.map((dia) => {
        if (
          (!dia.CommercialLength && item.Name !== "Coil") ||
          !dia.MaxScrapLength
        ) {
          empty = true;
          setEmptyFieldMessage(
            "There is an empty field(s) in item Type " + item.Name
          );
          setEmptyField(true);
        }
        dia.Values.map((val) => {
          if (!val.Length || !val.Weight) {
            empty = true;
            setEmptyFieldMessage(
              "There is an empty field(s) in item Type " + item.Name
            );
            setEmptyField(true);
          }
        });
      });
    });
    if (!empty) {
      setEmptyField(false);
      save();
    }
  };
  const InventoryValueData = {
    Types: props.itemValuesData?.filter((x) => x.Name === "Raw Material")[0]
      ?.Types,
  };
  const save = () => {
    console.log(InventoryValueData);
    setSaving(true);
    if (InventoryValueData.Types[0].Diameters[0].Id) {
      updateInventoryValue(InventoryValueData).then(() => {
        if (props.itemValuesData.filter((y) => y.Name === "Remnant")[0]) {
          props.setItemCategory(
            props.itemValuesData.filter((y) => y.Name === "Remnant")[0].Id
          );
          props.setForm1Showed(false);
        } else {
          props.setActiveTab(props.activeTab + 1);
        }
        setSaving(false);
      });
    } else {
      createInventoryValue(InventoryValueData).then(() => {
        if (props.itemValuesData.filter((y) => y.Name === "Remnant")[0]) {
          props.setItemCategory(
            props.itemValuesData.filter((y) => y.Name === "Remnant")[0].Id
          );
          props.setForm1Showed(false);
        } else {
          props.setActiveTab(props.activeTab + 1);
        }
        setSaving(false);
      });
    }
  };
  return (
    <>
      <Grid item xs={12}>
        <button
          className={classes["cancelbut"]}
          onClick={() => {
            props.setActiveTab(props.activeTab - 1);
          }}
        >
          Go Back
        </button>
      </Grid>
      <Grid item xs={2.3}>
        <Grid item className={classes["Item-Category"]}>
          Item Category
        </Grid>
        <Grid item>
          <TextField
            value={props.itemCategory}
            fullWidth
            select
            disabled
            SelectProps={{
              MenuProps: { disableScrollLock: true },
            }}
            InputProps={{
              style: {
                color: "#707070",
                height: "28px",
                fontSize: 12,
                fontFamily: "Muli",
              },
            }}
            onChange={(e) => {
              props.setItemCategory(e.target.value);
            }}
          >
            {props.itemValuesData.map((r) => (
              <MenuItem value={r.Id} key={r.Id}>
                {r.Name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid item xs={2.3} marginLeft={2.5}>
        <Grid item className={classes["Item-Category"]}>
          Item Type
        </Grid>
        <Grid item>
          <TextField
            value={props.itemSelectedId}
            fullWidth
            select
            SelectProps={{
              MenuProps: { disableScrollLock: true },
            }}
            InputProps={{
              style: {
                color: "#707070",
                height: "28px",
                fontSize: 12,
                fontFamily: "Muli",
              },
            }}
            onChange={(e) => {
              props.setItemSelectedId(e.target.value);
              props.setItemSelectedData(
                props.itemValuesData
                  ?.filter((x) => x.Id === props.itemCategory)[0]
                  ?.Types?.filter((x) => x.Id === e.target.value)[0]
              );
              let ItemTypeSelec = props.itemValuesData
                ?.filter((x) => x.Id === props.itemCategory)[0]
                ?.Types?.filter((x) => x.Id === e.target.value)[0];
              props.setDiaValuesSelected(
                ItemTypeSelec?.Diameters?.map((val) => val.DiameterId)
              );
              props.setDiaLengthSelected(
                ItemTypeSelec?.Diameters[0]?.Values
                  ? ItemTypeSelec?.Diameters[0]?.Values?.map((val) => {
                      let item = { Length: val.Length, Weight: 0 };
                      return item;
                    })
                  : [{ Length: 0, Weight: 0 }]
              );
            }}
          >
            {props.itemValuesData
              ?.filter((x) => x.Id === props.itemCategory)[0]
              ?.Types?.map((r) => (
                <MenuItem value={r.Id} key={r.Id}>
                  {r.Name}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
      </Grid>
      {props.itemSelectedData ? (
        <>
          <Grid item xs={2.3} marginLeft={2.5}>
            <Grid item className={classes["Item-Category"]}>
              list of Dia Values in mm
            </Grid>
            <Grid item>
              <TextField
                value={props.diaValuesSelected}
                fullWidth
                select
                SelectProps={{
                  MenuProps: { disableScrollLock: true },
                  multiple: true,
                }}
                InputProps={{
                  style: {
                    color: "#707070",
                    height: "28px",
                    fontSize: 12,
                    fontFamily: "Muli",
                  },
                }}
                onChange={(e) => {
                  e.target.value.map((diaS) => {
                    if (
                      !props.itemSelectedData.Diameters.filter(
                        (lm) => lm.DiameterId === diaS
                      )[0]
                    ) {
                      let diaName = props.listOfDiaValues.filter(
                        (diaR) => diaR.Id === diaS
                      )[0].Name;
                      props.itemSelectedData.Diameters.push({
                        DiameterId: diaS,
                        Name: diaName,

                        WeightPerMeter: +(
                          Math.PI *
                          Math.pow(+diaName / 2, 2) *
                          0.00785
                        ).toFixed(3),
                        CommercialLength: 0,
                        MaxScrapLength: 0,
                        Values:
                          props.itemSelectedData?.Diameters[0]?.Values?.length >
                          0
                            ? props.itemSelectedData.Diameters[0].Values.map(
                                (lgth) => {
                                  return { Length: lgth.Length, Weight: 0 };
                                }
                              )
                            : [{ Length: 0, Weight: 0 }],
                      });
                    }
                  });
                  props.itemSelectedData?.Diameters?.map((dia) => {
                    if (
                      !e.target.value.filter(
                        (dias) => dias === dia.DiameterId
                      )[0]
                    ) {
                      let ItemToRemove = props.itemSelectedData;
                      ItemToRemove.Diameters = ItemToRemove.Diameters.filter(
                        (DiaTr) => dia.DiameterId !== DiaTr.DiameterId
                      );
                      props.setItemSelectedData(ItemToRemove);
                    }
                  });
                  props.setDiaValuesSelected(e.target.value);
                }}
              >
                {props.listOfDiaValues?.map((r) => (
                  <MenuItem value={r.Id} key={r.Id}>
                    {r.Name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          {props.itemSelectedData.Name === "Coil" ? (
            <></>
          ) : (
            <Grid item xs={12}>
              <Grid item xs={1.2} className={classes["Item-Category"]}>
                *Values Length per Dia in mm
              </Grid>
              <Grid item container direction={"row"} xs={12} gap={1}>
                {props.diaLengthSelected.map((diaLength, index) => (
                  <Grid
                    item
                    xs={1.5}
                    container
                    direction={"row"}
                    key={index}
                    gap={0.8}
                  >
                    {index !== 0 ? (
                      <Grid
                        xs={1}
                        item
                        paddingTop={0.5}
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                        }}
                        onClick={() => {
                          props.itemSelectedData?.Diameters.map((firstItem) => {
                            firstItem.Values = firstItem.Values.filter(
                              (val, indx) => index !== indx
                            );
                          });
                          props.setDiaLengthSelected(
                            props.diaLengthSelected.filter(
                              (val, indx) => index !== indx
                            )
                          );
                        }}
                      >
                        <img src={RemoveIcon} width={15} alt={"add"} />
                      </Grid>
                    ) : (
                      ""
                    )}
                    <Grid item xs={8}>
                      <TextField
                        value={diaLength.Length}
                        fullWidth
                        type={"number"}
                        InputProps={{
                          style: {
                            color: "#707070",
                            height: "28px",
                            fontSize: 12,
                            fontFamily: "Muli",
                          },
                          inputProps: { min: 0 },
                        }}
                        onChange={(e) => {
                          diaLength.Length = e.target.value;
                          props.itemSelectedData?.Diameters.map((firstItem) => {
                            firstItem.Values.map((val, indx) => {
                              if (index === indx) {
                                val.Length = e.target.value;
                              }
                            });
                          });
                          forceUpdate();
                        }}
                      ></TextField>
                    </Grid>
                    {index === props.diaLengthSelected.length - 1 ? (
                      <Grid
                        xs={1}
                        item
                        paddingTop={0.5}
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                        }}
                        onClick={() => {
                          props.itemSelectedData?.Diameters.map((firstItem) => {
                            firstItem.Values = firstItem.Values.concat({
                              Length: 0,
                              Weight: 0,
                            });
                          });
                          props.setDiaLengthSelected(
                            props.diaLengthSelected.concat({
                              Length: 0,
                              Weight: 0,
                            })
                          );

                          console.log(
                            props.itemValuesData,
                            props.itemSelectedData
                          );
                        }}
                      >
                        <img src={AddValueIcon} width={15} alt={"add"} />
                      </Grid>
                    ) : (
                      <Grid item width={15}></Grid>
                    )}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} className={classes["TableTitle"]} paddingTop={3}>
            Characteristics
          </Grid>
          <Grid item overflow={"auto"}>
            <TableContainer sx={{ width: "100%" }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{
                  borderCollapse: "separate",
                  borderSpacing: "0px 0px",
                  border: 0,
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={styles.tableCellhead}>
                      Dia Selected (mm)
                    </TableCell>
                    {props.itemSelectedData?.Diameters?.map((Dia, index) => (
                      <TableCell sx={styles.tableCellhead2}>
                        <Grid item>{Dia.Name}</Grid>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell sx={styles.tableCellhead}>
                      Weight / Meter (kg/m)
                    </TableCell>
                    {props.itemSelectedData?.Diameters?.map((Dia, index) => (
                      <TableCell sx={styles.tableCellhead2}>
                        <Grid item>{Dia.WeightPerMeter}</Grid>
                      </TableCell>
                    ))}
                  </TableRow>
                  {props.itemSelectedData.Name === "Coil" ? (
                    ""
                  ) : (
                    <TableRow>
                      <TableCell sx={styles.tableCellhead}>
                        Commercial length (mm)
                      </TableCell>
                      {props.itemSelectedData?.Diameters?.map((Dia, index) => (
                        <TableCell sx={styles.tableCellhead2}>
                          <TextField
                            variant="standard"
                            value={Dia.CommercialLength}
                            fullWidth
                            label={"Length"}
                            type={"number"}
                            InputLabelProps={{
                              style: styles.tfInput,
                            }}
                            InputProps={{
                              disableUnderline: true,
                              style: styles.tfInput,
                              inputProps: { min: 0 },
                            }}
                            onChange={(e) => {
                              console.log(props.itemSelectedData);
                              Dia.CommercialLength = +e.target.value;
                              forceUpdate();
                            }}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  )}

                  <TableRow>
                    <TableCell sx={styles.tableCellhead}>
                      Max Scrap Length (mm)
                    </TableCell>
                    {props.itemSelectedData?.Diameters?.map((Dia, index) => (
                      <TableCell sx={styles.tableCellhead2}>
                        <TextField
                          variant="standard"
                          value={Dia.MaxScrapLength}
                          fullWidth
                          label={"Length"}
                          type={"number"}
                          InputLabelProps={{
                            style: styles.tfInput,
                          }}
                          InputProps={{
                            disableUnderline: true,
                            style: styles.tfInput,
                            inputProps: { min: 0 },
                          }}
                          onChange={(e) => {
                            Dia.MaxScrapLength = +e.target.value;
                            forceUpdate();
                          }}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                  {props.itemSelectedData.Name === "Coil" ? (
                    <>
                      {props.diaLengthSelected?.map((lent, indexLength) => (
                        <TableRow key={indexLength}>
                          <TableCell sx={styles.tableCellhead}>
                            Coil Weight
                          </TableCell>

                          {props.itemSelectedData?.Diameters?.map(
                            (Dia, index) => (
                              <TableCell sx={styles.tableCellhead2} key={index}>
                                <TextField
                                  variant="standard"
                                  value={
                                    Dia.Values.filter(
                                      (val1, indexLen) =>
                                        indexLength === indexLen
                                    )[0]?.Weight
                                  }
                                  fullWidth
                                  label={"Weight"}
                                  type={"number"}
                                  InputLabelProps={{
                                    style: styles.tfInput,
                                  }}
                                  InputProps={{
                                    disableUnderline: true,
                                    style: styles.tfInput,
                                    inputProps: { min: 0 },
                                  }}
                                  onChange={(e) => {
                                    Dia.Values.filter(
                                      (val1, indexLen) =>
                                        indexLength === indexLen
                                    )[0].Weight = +e.target.value;
                                    Dia.Values.filter(
                                      (val1, indexLen) =>
                                        indexLength === indexLen
                                    )[0].Length =
                                      (+e.target.value / Dia.WeightPerMeter) *
                                      1000;
                                    forceUpdate();
                                  }}
                                />
                              </TableCell>
                            )
                          )}
                        </TableRow>
                      ))}
                      {props.diaLengthSelected?.map((lent, indexLength) => (
                        <TableRow key={indexLength}>
                          <TableCell sx={styles.tableCellhead2}>
                            Length(m):
                          </TableCell>

                          {props.itemSelectedData?.Diameters?.map(
                            (Dia, index) => (
                              <TableCell sx={styles.tableCellhead2}>
                                <Grid item>
                                  {(
                                    Dia.Values.filter(
                                      (val1, indexLen) =>
                                        indexLength === indexLen
                                    )[0].Length / 1000
                                  ).toFixed(3)}
                                </Grid>
                              </TableCell>
                            )
                          )}
                        </TableRow>
                      ))}
                    </>
                  ) : (
                    <>
                      <TableRow>
                        <TableCell sx={styles.tableCellhead3}>
                          <>
                            <Grid
                              item
                              container
                              xs={11}
                              direction={"row-reverse"}
                            >
                              Bundle Weight:<br/> (kg)
                            </Grid>
                            <Grid item container xs={10}>
                              Length: <br/>(mm)
                            </Grid>
                          </>
                        </TableCell>
                        {props.itemSelectedData?.Diameters?.map(
                          (Dia, index) => (
                            <TableCell sx={styles.tableCellhead2}></TableCell>
                          )
                        )}
                      </TableRow>
                      {props.diaLengthSelected?.map((lent, indexLength) => (
                        <TableRow key={indexLength}>
                          <TableCell sx={styles.tableCellhead2}>
                            {lent?.Length}
                          </TableCell>

                          {props.itemSelectedData?.Diameters?.map(
                            (Dia, index) => (
                              <TableCell sx={styles.tableCellhead2} key={index}>
                                <TextField
                                  variant="standard"
                                  value={
                                    Dia.Values.filter(
                                      (val1, indexLen) =>
                                        indexLength === indexLen
                                    )[0]?.Weight
                                  }
                                  fullWidth
                                  label={"Weight"}
                                  type={"number"}
                                  InputLabelProps={{
                                    style: styles.tfInput,
                                  }}
                                  InputProps={{
                                    disableUnderline: true,
                                    style: styles.tfInput,
                                    inputProps: { min: 0 },
                                  }}
                                  onChange={(e) => {
                                    Dia.Values.filter(
                                      (val1, indexLen) =>
                                        indexLength === indexLen
                                    )[0].Weight = +e.target.value;
                                    forceUpdate();
                                  }}
                                />
                              </TableCell>
                            )
                          )}
                        </TableRow>
                      ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid
            item
            container
            xs={12}
            direction={"row-reverse"}
            gap={1}
            paddingTop={5}
            paddingRight={1.5}
          >
            <button
              className={classes["SaveBut"]}
              disabled={isdisabled}
              onClick={() => {
                checkAllFilled();
              }}
            >
              {saving ? "Saving..." : "Save"}
            </button>

            <Grid
              item
              fontFamily={"Muli"}
              fontSize={12}
              fontWeight={"bold"}
              color={"#ea001e"}
            >
              {emptyField ? emptyFieldMessage : ""}
            </Grid>
          </Grid>
        </>
      ) : (
        ""
      )}
    </>
  );
};
const styles = {
  tableCell: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    lineHeight: 1.17,
    textAlign: "left",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 157,
  },
  tableCellhead: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    textAlign: "left",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 112,
  },
  tableCellhead3: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#0674b9",
    textAlign: "center",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 75,
    padding: "0px 0px 10px 0px",
    background:
      "linear-gradient(to top right, #fff calc(50% - 1px), rgba(206, 210, 225, 0.97) , #fff calc(50% + 1px))",
  },
  tableCellhead2: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#0674b9",
    textAlign: "center",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 75,
  },
  tableCellheadPr: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    textAlign: "left",
    maxHeight: "20px",
    width: 266,
  },
  tableCellBody: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    lineHeight: 1.17,
    textAlign: "left",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 75,
    paddingTop: 3.5,
  },
  tableCellheadBody: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    textAlign: "left",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    maxHeight: "20px",
    width: 140,
  },
  tableCellheadPrBody: {
    backgroundColor: "#fff",
    fontFamily: "Muli",
    fontSize: 12,
    color: "#707070",
    textAlign: "left",
    maxHeight: "20px",
    borderRight: "solid 1px rgba(206, 210, 225, 0.97)",
    width: 180,
  },
  tfInput: {
    color: "#707070",
    height: "28px",
    fontSize: 12,
    fontFamily: "Muli",
  },
};
export default Form1;
